var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("新增主播"),
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("关联家族"), prop: "familyId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: _vm.$t("下拉选择家族") },
                  model: {
                    value: _vm.ruleForm.familyId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "familyId", $$v)
                    },
                    expression: "ruleForm.familyId",
                  },
                },
                _vm._l(_vm.familyList, function (item) {
                  return _c("el-option", {
                    key: item.familyId,
                    attrs: { label: item.familyName, value: item.familyId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("主播昵称"), prop: "nickName" } },
            [
              _c("el-input", {
                attrs: { maxlength: "12", "show-word-limit": "" },
                model: {
                  value: _vm.ruleForm.nickName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "nickName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.nickName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("登录账号"), prop: "accLogin" } },
            [
              _c("el-input", {
                attrs: { maxlength: "12", "show-word-limit": "" },
                model: {
                  value: _vm.ruleForm.accLogin,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "accLogin",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.accLogin",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("联系方式"), prop: "contactInfo" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20", "show-word-limit": "" },
                model: {
                  value: _vm.ruleForm.contactInfo,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "contactInfo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.contactInfo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("登录密码"),
                prop: "password",
                required: "",
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("6-12位") },
                model: {
                  value: _vm.ruleForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "password", $$v)
                  },
                  expression: "ruleForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("确认密码"),
                prop: "confirmPassword",
                required: "",
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("6-12位") },
                model: {
                  value: _vm.ruleForm.confirmPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "confirmPassword", $$v)
                  },
                  expression: "ruleForm.confirmPassword",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }