<template>
  <el-dialog title="添加" width="1200px" top="8vh" :visible.sync="visible" @closed="handleClosed">
    <el-form ref="validateForm" v-loading="loading" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item label="聊天室分类" prop="roomType">
        <el-select v-model="ruleForm.roomType" placeholder="请选择聊天室分类" style="width: 300px;">
          <el-option v-for="item in roomTypesTwo" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="聊天室名称" prop="roomList">
        <el-select multiple v-lazy-load-dropdown="handleScrollDropdown" remote filterable style="width: 300px;"
          :remote-method="searchChatByName" @focus="searchChatByName('')" v-model="ruleForm.roomList"
          placeholder="请选择聊天室">
          <el-option :value-key="item.id" v-for="item in chatList" :key="item.id" :label="item.roomName"
            :value="item.id" />
        </el-select>
      </el-form-item>


      <el-form-item label="助手名称" prop="assistantList">
        <el-select multiple @change="setAssistantList" v-lazy-load-dropdown="handleScrollDropdownByChat" remote
          filterable style="width: 300px;" :remote-method="searchByName" @focus="searchByName('')"
          v-model="ruleForm.assistantList" placeholder="请选择选择机器人">
          <el-option :value-key="item.id" v-for="(item, index) in memberInfo" :key="index" :label="item.name"
            :value="item.id" />
        </el-select>
        <el-button icon="el-icon-plus" type="success" style="margin-left: 10px;" class="filter-item"
          @click="addPush">添加</el-button>
      </el-form-item>

      <el-form-item label="机器人列表" prop="tableData">
        <el-table :data="ruleForm.tableData" stripe style="width: 100%;margin-top: 20px;">
          <el-table-column prop="name" label="助手名称" width="180">
          </el-table-column>
          <el-table-column prop="nickname" label="用户昵称" width="180">
          </el-table-column>
          <el-table-column prop="avatar" label="用户头像" width="180">
            <template slot-scope="scope">
              <el-image fix="cover" :src="scope.row.avatar" style="width: 40px; height: 40px; border-radius: 50%" />
            </template>
          </el-table-column>
          <el-table-column prop="activePeriod" label="活跃时段">
          </el-table-column>
          <el-table-column prop="imageDescription" label="形象描述">
          </el-table-column>
          <el-table-column prop="status" label="状态">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="handleDeleteActivityType(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getLiveRoomList, getReportManList, getConfigAdd, getConfigUpdate } from "@/api/chat";
import { roomTypes } from '@/libs/options'
import { ruleForm } from '@/views/agent-rebate/Config/pageData';


const getDefaultForm = () => ({
  tableData: [],
  assistantList: [],
  roomList: [],
  roomType: '',
})

let chatItems = [];
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  directives: {
    'lazy-load-dropdown': {
      bind(el, binding) {
        const SELECT_DOM = el.querySelector('.el-select-dropdown .el-scrollbar__wrap')
        SELECT_DOM.addEventListener('scroll', () => {
          if (SELECT_DOM.scrollTop + SELECT_DOM.clientHeight >= SELECT_DOM.scrollHeight) {
            binding.value()
          }
        })
      }
    }
  },
  data() {
    return {
      tableData: [],
      assistantList: [],
      roomList: [],
      roomTypesTwo: roomTypes,
      emitName: 'create',
      loading: false,
      visible: false,
      memberInfo: [],
      adminInfo: [],
      params: {
        "roomType": '',
        "roomList": [],
        "assistantList": []
      },
      chatPage: {
        roomType: 1,
        currentPage: 1,
        pageSize: 20,
        roomName: ''
      },
      queryMemberPage: {
        currentPage: 1,
        pageSize: 20,
        name: ''
      },
      pickerOptions: {
        disabledDate(date) {
          return date.getTime() < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0)
        }
      },
      chatList: [],
      rules: {
        assistantList: [{ required: true, message: '请选择助手', trigger: 'blur' }],
        roomType: [{ required: true, message: '请选择聊天室类型', trigger: 'change' }],
        roomList: [{ required: true, message: '请选择聊天室', trigger: 'change' }],
        tableData: [{ required: true, message: '请选择机器人', trigger: 'change' }],
      }
    }
  },
  methods: {
    handleDeleteActivityType(row) {
      this.$confirm(this.$t('确认删除?'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        this.ruleForm.assistantList = this.ruleForm.assistantList.filter((item) => {
          if (item != row.id) {
            return item;
          }
        })
        this.getTableData()
        this.$message.success(this.$t('删除成功'))
      }).catch(() => { })
    },
    getTableData() {
      this.ruleForm.tableData = this.memberInfo.filter((item) => {
        if (this.ruleForm.assistantList.includes(item.id)) {
          return item;
        }
      })
    },
    addPush() {
      this.getTableData()
    },
    open(row) {
      this.visible = true;
      this.queryMemberPage.currentPage = 1;
      this.queryMemberPage.name = '';
      if (row) {
        this.roomList = JSON.parse(row.roomList);
        this.assistantList = JSON.parse(row.assistantList);
        this.emitName = 'update';
        this.ruleForm.roomType = row.roomType;
        this.ruleForm.id = row.id;
        this.roomList.forEach((item, index) => {
          this.ruleForm.roomList.push(item.id)
        });
        this.assistantList.forEach((item, index) => {
          this.ruleForm.assistantList.push(item.id)
        });
        this.chatList = [];
        this.handleScrollDropdown()
        this.searchChatByName();
        this.sendUserChange()
      } else {
        this.emitName = 'create';
      }
    },
    setAssistantList(data) {
      console.log(data);
    },
    async queryMember(loading) {
      await getReportManList(this.queryMemberPage).then(([items, err]) => {
        if (!err) {
          if (items.length === 0) {
            if (!loading) {
              this.memberInfo = [];
            }
          } else {
            if (!loading) {
              this.memberInfo = items;
            } else {
              let obj2 = {}
              this.memberInfo = this.memberInfo.concat(items).reduce((prev, item) => {
                obj2[item.id] ? '' : obj2[item.id] = true && prev.push(item);
                return prev;
              }, [])
            }
          }
        }
      }).finally(() => {
      })
    },
    searchByName(name) {
      this.queryMemberPage.currentPage = 1;
      this.queryMemberPage.name = name;
      this.queryMember()
    },
    searchChatByName(name) {
      this.chatPage.currentPage = 1;
      this.chatPage.roomName = name;
      this.getChatList()
    },
    getChatList(loading) {
      getLiveRoomList(this.chatPage).then((res) => {
        chatItems = res[2].data;
        if (chatItems.code == 200) {
          if (chatItems.items && chatItems.items.length) {
            if (!loading) {
              this.chatList = chatItems.items;
            } else {
              this.chatList = this.chatList.concat(chatItems.items)
            }
          } else {
            if (!loading) {
              this.chatList = [];
            }
          }
        }
      })
    },
    async sendUserChange(status) {
      this.queryMemberPage.currentPage = 1;
      this.memberInfo = [];
      this.adminInfo = [];
      await this.queryMember()
      this.addPush()
    },
    handleScrollDropdown() {
      this.queryMemberPage.currentPage += 1;
      this.queryMember(true)
    },
    handleScrollDropdownByChat(name) {
      this.chatPage.currentPage += 1;
      this.chatPage.memberName = name;
      this.getChatList()
    },
    handleSubmitBefor() {
      this.$refs.validateForm.validate(valid => {
        if (valid) {
          if (this.ruleForm.id) {
            this.params.id = this.ruleForm.id;
          }
          let roomListArr = [];
          let assistantListArr = [];
          this.params.roomType = this.ruleForm.roomType;
          this.chatList.forEach((item, index) => {
            this.ruleForm.roomList.forEach((itemtw) => {
              if (item.id == itemtw) {
                roomListArr.push({ id: item.id, name: item.roomName })
              }
            })

          })
          this.memberInfo.forEach((item, index) => {
            this.ruleForm.tableData.forEach((items, indexs) => {
              if (item.id == items.id) {
                assistantListArr.push({ id: item.id, name: item.name })
              }
            })
          })
          this.params.assistantList = JSON.stringify(assistantListArr)
          this.params.roomList = JSON.stringify(roomListArr)
          if (this.emitName == 'update') {
            getConfigUpdate(this.params).then(res => {
              this.$message.success(this.$t('编辑成功'))
              this.visible = false;
              this.$emit('finsh')
            })
          } else {
            getConfigAdd(this.params).then(res => {
              this.$message.success(this.$t('添加成功'))
              this.visible = false;
              this.$emit('finsh')
            })
          }
        }
      })

    },
  }
}
</script>
