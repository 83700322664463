<template>
  <el-dialog
    :title="$t('修改会员')"
    :visible.sync="visible"
    width="800px"
    :close-on-click-modal="false"
    @open="onOpen"
    @closed="handleClosed"
  >
    <el-form
      ref="validateFormUserInfoEdit"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      label-suffix=":"
    >
      <el-form-item :label="$t('头像')">
        <DUpload
          v-model="ruleForm.avatar"
          folder="icon"
          :width="50"
          :height="50"
          :on-success="handleSuccess"
        />
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('会员账号')">
            <el-input v-model="visibleForm.memberName" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('会员状态')" prop="freezeStatus">
            <!-- <el-select v-model="ruleForm.freezeStatus" style="width: 100%">
              <el-option
                v-for="item in userStatus"
                :key="item.key"
                :label="item.label"
                :value="item.key"
              />
            </el-select> -->
            {{ ["启用", "停用"][ruleForm.freezeStatus] }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('会员昵称')" prop="nickname">
            <el-input v-model="ruleForm.nickname" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('会员等级')" prop="vipLevel">
            <el-select
              v-model="ruleForm.vipLevel"
              :placeholder="$t('请选择会员等级')"
              style="width: 100%"
              :disabled="!permission.UpdateVip"
            >
              <el-option
                v-for="item in $parent.vipLevels"
                :key="item.vipLevel"
                :label="`VIP${item.vipLevel}`"
                :value="item.vipLevel"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <template v-if="more">
        <el-row>
          <el-col :span="12">
            <el-form-item label="真实姓名">
              <el-input v-model="ruleForm.realname" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="ruleForm.pho1" style="width: 58px" /> -
              <el-input v-model="ruleForm.pho2" style="width: 188px" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col v-for="(item, i) in cardNumbers" :key="item.id" :span="12">
            <el-form-item :label="$t('银行卡')">
              <div flex="main:justify cross:center">
                <span>{{ item.cardNumber }}</span>
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  circle
                  @click="handleChangeCard(i)"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('测试用户设置')">
              <el-radio
                v-model="memberType"
                :label="4"
                :disabled="[1, 4].includes(ruleForm.memberType)"
                >{{ $t("设置为测试账号") }}</el-radio
              >
              <el-radio
                v-model="memberType"
                :label="1"
                :disabled="[1, 4].includes(ruleForm.memberType)"
                >{{ $t("设置为内部账号") }}</el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('用户生日')" prop="birthday">
              <el-date-picker
                v-model="ruleForm.birthday"
                type="date"
                placeholder="选择日期"
                value-format="timestamp"
                :clearable="false"
                >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <div v-else class="action-form" @click="handleToggle">
        <p>{{ $t("更多内容") }}</p>
        <i class="el-icon-arrow-down" />
      </div>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('注册来源')" prop="registerResource">
            <el-input v-model="visibleForm.registerResource" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('上级代理')" prop="agencyMemberName">
            <el-input
              v-model="visibleForm.agencyMemberName"
              :disabled="!permission.UpdateAgent"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('聊天室')" prop="chatRoomStatus">
            <div v-loading="chatRoomStatusLoading">
              <el-switch
                v-model="ruleForm.chatRoomStatus"
                active-value="1"
                inactive-value="0"
                :active-text="$t('启用')"
                :inactive-text="$t('禁用')"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="_handleChangeChatStatus"
              />
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- <el-form-item :label="$t('锁帖禁评')" prop="muteStatus">
            <div>
              <el-switch
                v-model="ruleForm.muteStatus"
                :active-value="0"
                :inactive-value="1"
                :active-text="$t('启用')"
                :inactive-text="$t('禁用')"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="handleBatchLimit('muteStatus')"
              />
            </div>
          </el-form-item> -->
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('彩讯禁言')" prop="xsFreezeStatus">
            <div>
              <el-switch
                v-model="ruleForm.xsFreezeStatus"
                :active-value="0"
                :inactive-value="1"
                :active-text="$t('启用')"
                :inactive-text="$t('禁用')"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="handleBatchLimit('xsFreezeStatus')"
              />
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('发红包')" prop="canSendRedPacket">
            <div>
              <el-switch
                v-model="ruleForm.canSendRedPacket"
                :active-value="1"
                :inactive-value="0"
                :active-text="$t('启用')"
                :inactive-text="$t('禁用')"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('专家打赏')" prop="canReward">
            <div>
              <el-switch
                v-model="ruleForm.canReward"
                :active-value="1"
                :inactive-value="0"
                :active-text="$t('启用')"
                :inactive-text="$t('禁用')"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t("关闭") }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t("保存") }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from "@/mixins/form-dialog";
import DUpload from "@/components/DUpload";
import { getBanks } from "@/api/member";
import { cmdDetails } from "@/api/chat";
import { userStatus } from "@/libs/options.js";
import { validNickName } from "@/utils/validate";
import { imOperator, getImToken } from "@/api/chat";
import { bacthRecommendLimit } from "@/api/interactive";
import { mapGetters } from "vuex";
const getDefaultForm = () => ({
  avatar: "",
  xsFreezeStatus: 0,
  muteStatus: 0,
  nickname: "",
  realname: "",
  phone: "",
  pho1: "",
  pho2: "",
  vipLevel: null,
  memberType: 0,
  chatRoomStatus: "1",
  canSendRedPacket:"",
  canReward:"",
  birthday:'',
});
const validateBirthday = (rule, value, callback) => {
  if (value && value > Date.now()) {
    callback(new Error("生日不能超过当前时间"));
  } else {
    callback();
  }
};
export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm,'validateFormUserInfoEdit')],
  data() {
    const validNickname = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t("请输入昵称")));
      } else if (!validNickName(value)) {
        callback(new Error(this.$t("请输入2~12位")));
      } else {
        callback();
      }
    };

    return {
      emitName: "update",
      more: false,
      loading: false,
      chatRoomStatusLoading: false,
      cardNumbers: [],
      userStatus,
      visibleForm: {},
      memberType: 0,
      rules: {
        freezeStatus: [
          {
            required: true,
            message: this.$t("请选择用户状态"),
            trigger: "change",
          },
        ],
        nickname: [{ validator: validNickname, trigger: "blur" }],
        vipLevel: [
          {
            required: true,
            message: this.$t("请选择用户等级"),
            trigger: "change",
          },
        ],
        chatRoomStatus: [
          { required: true, message: this.$t("请选择"), trigger: "change" },
        ],
        birthday:[
            { validator:validateBirthday, trigger: "change" },
        ]
      },
    };
  },
  computed: {
    ...mapGetters(["permissionSensitive"]),
    permission() {
      return this.permissionSensitive(this.$route.path, this.$options.name);
    },
  },
  methods: {
    open(row) {
      this.ruleForm.memberId = row.memberId;
      this.ruleForm.avatar = row.avatar;
      this.ruleForm.freezeStatus = row.freezeStatus;
      this.ruleForm.xsFreezeStatus = row.xsFreezeStatus; // 列表里面本来有一个freezeStatus 这里可能重复 所以必须要区别
      this.ruleForm.muteStatus = row.muteStatus;
      this.ruleForm.nickname = row.nickname;
      this.ruleForm.vipLevel = row.vipLevel;
      this.ruleForm.realname = row.realname;
      this.ruleForm.birthday = row.birthday;
      this.ruleForm.phone = row.phone;
      this.ruleForm.canSendRedPacket = row.canSendRedPacket;
      this.ruleForm.canReward = row.canReward;
      let phoList = (row.phone || "").split("_");
      this.ruleForm.pho1 = phoList[1] ? phoList[0] : "+86";
      this.ruleForm.pho2 = phoList[1] ? phoList[1] : phoList[0];
      this.ruleForm.memberType = row.memberType;
      this.memberType = row.memberType;
      this.visibleForm.memberName = row.memberName;
      this.visibleForm.registerResource = row.registerResource;
      this.visibleForm.agencyMemberName = row.agencyMemberName;
      this.visible = true;
    },
    beforeSubmit() {
      
      this.ruleForm.memberType = this.memberType;
      if (!this.ruleForm.pho2) {
        this.ruleForm.phone = null;
        return true;
      }

      this.ruleForm.phone =
        (this.ruleForm.pho1 || "") + "_" + (this.ruleForm.pho2 || "");
      if (/^(?:(?:\+|0)\d+)?_(?:1[0-9]{10})$/.test(this.ruleForm.phone)) {
        return true;
      } else this.$message.error(this.$t("请输入有效的区号加手机号"));


      if(this.ruleForm.birthday&&this.ruleForm.birthday>Date.now()){
        this.$message.error(this.$t("生日不能大于当前时间"));
        return false
      }
    },

    // freezeStatus禁言，muteStatus锁贴 都是 0是启用 1是锁定
    handleBatchLimit(flag) {
      let data = {};
      let msg = "";
      if (flag == "muteStatus") {
        data = { muteStatus: this.ruleForm.muteStatus };
        msg = `确定对用户所有帖子${this.ruleForm.muteStatus ? "" : "解除"}锁贴`;
      }
      if (flag == "xsFreezeStatus") {
        data = { freezeStatus: this.ruleForm.xsFreezeStatus };
        msg = `确定对用户${this.ruleForm.xsFreezeStatus ? "" : "解除"}禁言`;
      }
      data.memberIds = [this.ruleForm.memberId];

      this.$confirm(msg, "系统提示", { type: "warning" })
        .then(() => {
          bacthRecommendLimit(data).then(([data, err]) => {
            if (!err) {
              this.$message.success("操作成功");
              this.handleFetch();
            }
          });
        })
        .catch(() => {
          if (flag == "muteStatus") {
            this.ruleForm.muteStatus = this.ruleForm.muteStatus == 0 ? 1 : 0;
          }
          if (flag == "xsFreezeStatus") {
            this.ruleForm.xsFreezeStatus =
              this.ruleForm.xsFreezeStatus == 0 ? 1 : 0;
          }
        });
    },

    _getUserBanks() {
      getBanks({
        memberId: this.ruleForm.memberId,
      }).then(([data, err]) => {
        this.loading = false;
        if (!err) {
          this.cardNumbers = data;
          this.ruleForm.cardNumbers = data.map((o) => ({
            cardNumber: o.cardNumber,
            id: o.id,
            deleted: o.deleted,
          }));
          this.more = true;
        }
      });
    },
    _getImToken() {
      getImToken({tokenType:1}).then(([data, err, response]) => {
        if (!err) {
          this.$store.commit("user/SET_IM_TOKEN", data);
        }
      });
    },
    _getUserChatRoomState() {
      const params = {
        cmd: 2001,
        groupId:'*',
        memberId: this.ruleForm.memberId,
      };
      cmdDetails(params).then(([data, err]) => {
        if (!err) {
          if (Number(data)) {
            this.ruleForm.chatRoomStatus = "0";
          } else {
            this.ruleForm.chatRoomStatus = "1";
          }
        }
        this.chatRoomStatusLoading = false;
      });
    },

    _handleChangeChatStatus(val) {
      this.$confirm(
        `${this.$t("确认")}${
          val === "1" ? this.$t("启用") : this.$t("禁言")
        }${this.$t("用户聊天室")}`,
        this.$t("系统提示"),
        { type: "warning" }
      )
        .then(() => {
          const data = {
            cmd: val === "1" ? 2003 : 2001,
            groupId: "*",
            groupName: "*",
            toNickname: this.ruleForm.nickname,
            to: this.ruleForm.memberId,
          };
          imOperator(data).then(([_, err]) => {
            if (!err) {
              this.$message.success(this.$t("用户聊天室状态已更新"));
            }
          });
        })
        .catch(() => {
          this.ruleForm.chatRoomStatus =
            this.ruleForm.chatRoomStatus === "0" ? "1" : "0";
        });
    },

    handleChangeCard(i) {
      const number = this.cardNumbers[i].cardNumber;
      this.cardNumbers.splice(i, 1);
      this.ruleForm.cardNumbers.forEach((item) => {
        if (item.cardNumber === number) {
          item.deleted = 1;
        }
      });
    },
    handleSuccess(data) {
      this.ruleForm.avatar = data + "";
    },
    onOpen() {
      this.chatRoomStatusLoading = true;
      if (!this.$store.getters.imToken) {
        this._getImToken();
      }
      this._getUserChatRoomState();
    },

    handleToggle() {
      this.loading = true;
      this._getUserBanks();
    },

    implementationClosed() {
      this.more = false;
      this.cardNumbers = [];
      this.visibleForm = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.action-form {
  text-align: center;
  color: #3894ff;
  padding-bottom: 20px;
  cursor: pointer;

  & > p {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
</style>
