import { $t } from '@/lang'
export default [
  {
    title: $t('订单号'),
    key: 'orderNo'
  },
  {
    title: $t('账号'),
    key: 'memberName'
  },
  {
    title: $t('真实姓名'),
    key: 'realName'
  },
  {
    title: $t('VIP等级'),
    key: 'vipLevel'
  },
  {
    title: $t('充值金额'),
    key: 'amount'
  },
  {
    title: $t('转账姓名'),
    key: 'payingName'
  },
  {
    title: $t('收款人'),
    key: 'receivingName'
  },
  {
    title: $t('收款银行'),
    key: 'receivingBank'
  },
  {
    title: $t('收款银行账号'),
    key: 'receivingCardNo'
  },
  {
    title: $t('附言'),
    key: 'postscript'
  },
  {
    title: $t('发起时间'),
    key: 'createTime'
  },
  {
    title: $t('订单状态'),
    key: 'status'
  }
]
