const CanChooseColumns = function() {
  return [
    {
      title: this.$t('Member_Expert.LevelTab.专家等级'),
      key: 'positionLevel',
      align: 'center'
    },
    {
      title: this.$t('Member_Expert.LevelTab.别名'),
      key: 'positionName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <el-tag color={row.flagColor} style={{ color: 'white', borderColor: row.flagColor }}>{ row.positionName }</el-tag>
          )
        }
      }
    },
    {
      title: this.$t('Member_Expert.LevelTab.发布次数'),
      key: 'publishNum',
      align: 'center'
    },
    {
      title: this.$t('Member_Expert.LevelTab.胜率') + '(%)',
      key: 'winrate',
      align: 'center'
    },
    {
      title: this.$t('Member_Expert.LevelTab.返还率') + '(%)',
      key: 'returnrate',
      align: 'center'
    },
    {
      title: this.$t('Member_Expert.LevelTab.抽佣') + '(%)',
      key: 'commission',
      align: 'center'
    },
    {
      title: this.$t('状态'),
      key: 'positionStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.positionStatus === 1 ? this.$t('Member_Expert.LevelTab.需要审核') : this.$t('Member_Expert.LevelTab.无需审核') }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 160,
      component: {
        render: (h, row) => {
          const { Update } = this.permission
          return (Update && <el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={() => this.$refs.ActionDialogContorl.open(row, this.tenantCode)}>{ this.$t('编辑') }</el-button>)
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
