<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model.trim="search.couponId"
        type="number"
        oninput="if(this.value.length>8){this.value=this.value.slice(0,8);}"
        clearable
        :placeholder="$t('请输入卡券ID')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model.trim="search.couponTitle"
        maxlength="20"
        clearable
        :placeholder="$t('请输入卡券名称')"
        class="filter-item"
        style="width: 140px"
      />
      <el-select v-model="search.couponType" clearable :placeholder="$t('优惠类型')" class="filter-item">
        <el-option v-for="(val, key) in couponTypes" :key="key" :label="val" :value="key" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button type="success" icon="el-icon-plus" class="filter-item" @click="onCreate()">添加卡券</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <add-dialog ref="AddDialogControl" @create="handleCreate" />
    <SendDialog ref="SendDialogControl" @send="handleSend" />
    <DetailDialog ref="DetailDialogControl" />
  </div>
</template>

<script>
import AddDialog from './AddDialog.vue'
import SendDialog from './SendDialog.vue'
import DetailDialog from './DetailDialog.vue'
import { getList, addInfo, upStatus, couponSend } from '@/api/card.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getLangLottery } from '@/api/lottery'
import { getLangMatch } from '@/api/match'
import GetColumns from './columns'
import dayjs from 'dayjs'
import { couponTypes } from '@/libs/options'
// let search = {}
export default {
  name: 'CardManager',
  components: { AddDialog, SendDialog, DetailDialog },
  mixins: [TablePageMixin(getList)],
  data() {
    return {
      times: [],
      search: {},
      columns: [],
      langLottery: [], // 彩票多语言信息
      langMatch: [], // 竞彩多语言信息
      defaultColumns: GetColumns.call(this),
      defaultAgentType: [0, 1, 2, 3, 4, 5, 6],
      langs: ['zh_CN', 'en_US', 'vi_VN'], //
      popMatch: {}, // 存放竞彩类型的数据对应的范围涉及比赛的信息
      couponTypes
    }
  },
  async created() {
    await this.getLangLottery()
    await this.getLangMatch()
  },
  methods: {
    async getLangLottery() {
      const res = await getLangLottery()
      if (!res[0]) return
      this.langLottery = res[0].reduce((s, a) => {
        return [...s, ...a.lotteryList]
      }, [])
      this.langLottery.forEach(p => {
        const list = p.name.split(',').filter(c => !!c)
        list.forEach((c, i) => {
          p[c.slice(0, 5)] = c.slice(5)
          p.id = p.lotteryId
        })
      })
    },
    async getLangMatch() {
      const res = await getLangMatch({
        sportType: 1,
        currentPage: 1,
        pageSize: 100,
        startTime: dayjs().format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD'),
        type: 1,
        status: 1,
        tenantCode: this.$store.getters.tenantCode
      })
      if (!res[0]) return
      // (res[0].competitionName + ' : ' + res[0].homeTeamName + ' VS ' + res[0].awayTeamName)

      res[0].forEach(p => {
        const list = p.competitionName.split(',').filter(c => !!c)
        list.forEach((c, i) => (p[c.slice(0, 5)] = c.slice(5) + ' : ' + p.homeTeamName.split(',')[i].slice(5) + ' VS ' + p.awayTeamName.split(',')[i].slice(5)))
        this.langs = list.map(c => c.slice(0, 5))
        p.id = p.matchId
      })
      this.langMatch = res[0]
    },
    getPopMatch(p) {
      return this.popMatch[p]
    },
    onCreate() {
      this.$refs.AddDialogControl.open()
    },
    handleCreate({ field, cancel, close }) {
      const titleList = []
      field.couponTitleList.forEach(e => {
        if (e.couponTitle) {
          titleList.push(e.lang + ':' + e.couponTitle)
        }
      })
      field.couponTitle = titleList.toString()
      if (field.couponStockType === -1) {
        field.couponStock = -1
      }
      delete field.couponTitleList
      delete field.couponTime
      delete field.couponStockType
      addInfo({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('保存成功'))
        } else {
          cancel()
        }
      })
    },
    handleSend({ field, cancel, close }) {
      couponSend({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('操作成功'))
        } else {
          cancel()
        }
      })
    },

    hanldeUpdate({ field, cancel, close }) {
      upStatus({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('保存成功'))
        } else {
          cancel()
        }
      })
    },
    _handleChangeStatus({ couponId, couponStatus }) {
      return new Promise((resolve) => {
        this.$confirm(couponStatus === 0 ? this.$t('确定上架该抵用券?') : this.$t('确定下架该抵用券?'), this.$t('系统提示'), {
          center: true,
          type: 'warning'
        }).then(() => {
          upStatus({
            couponId: Number(couponId),
            couponStatus: couponStatus === 0 ? 1 : 0
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => { })
      })
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
<style type="scss" scoped>
.filter-container {
  display: flex;
  gap: 8px;
}

.filter-container .filter-item+.filter-item {
  margin-left: 0;
}

.scope-pop {
  /* display: flex;
  flex-direction: column; */
  /* gap: 8px; */
  height: 400px;
  max-width: 400px;
  overflow-y: scroll;
}
</style>
