var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: { placeholder: "时间类型" },
              model: {
                value: _vm.search.timeType,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "timeType", $$v)
                },
                expression: "search.timeType",
              },
            },
            [
              _c("el-option", { attrs: { label: "投注时间", value: 0 } }),
              _c("el-option", { attrs: { label: "结算时间", value: 1 } }),
            ],
            1
          ),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "400px" },
            attrs: {
              type: "datetimerange",
              "range-separator": _vm.$t("至"),
              "start-placeholder": _vm.$t("开始日期"),
              "end-placeholder": _vm.$t("结束日期"),
              "value-format": "timestamp",
              clearable: "",
              "default-time": ["00:00:00", "23:59:59"],
              "picker-options": _vm.pickerOptions,
            },
            model: {
              value: _vm.times,
              callback: function ($$v) {
                _vm.times = $$v
              },
              expression: "times",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetTimes($event, 0)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("今天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetTimes($event, 1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("昨天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetTimes($event, 7)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("_7天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetTimes($event, 30)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("本月")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetTimes($event, 60)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("上月")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: { clearable: "", placeholder: _vm.$t("系列") },
              on: { change: _vm._getLotterys },
              model: {
                value: _vm.search.seriesTag,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "seriesTag", $$v)
                },
                expression: "search.seriesTag",
              },
            },
            _vm._l(_vm.seriesList, function (item) {
              return _c("el-option", {
                key: item.seriesTag,
                attrs: { label: item.seriesName, value: item.seriesTag },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: {
                clearable: "",
                placeholder: _vm.$t("彩种"),
                disabled: !_vm.search.seriesTag,
              },
              on: { change: _vm._getPlayList },
              model: {
                value: _vm.search.lotteryId,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "lotteryId", $$v)
                },
                expression: "search.lotteryId",
              },
            },
            _vm._l(_vm.lotterysList, function (item) {
              return _c("el-option", {
                key: item.lotteryId,
                attrs: { label: item.name, value: item.lotteryId },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: {
                clearable: "",
                placeholder: _vm.$t("玩法"),
                disabled: !_vm.search.lotteryId,
              },
              model: {
                value: _vm.search.playClassTag,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "playClassTag", $$v)
                },
                expression: "search.playClassTag",
              },
            },
            _vm._l(_vm.playsList, function (item) {
              return _c("el-option", {
                key: item.playClassTag,
                attrs: { label: item.playClassName, value: item.playClassTag },
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "140px" },
            attrs: { clearable: "", placeholder: _vm.$t("期号") },
            model: {
              value: _vm.search.issue,
              callback: function ($$v) {
                _vm.$set(_vm.search, "issue", $$v)
              },
              expression: "search.issue",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "140px" },
            attrs: { clearable: "", placeholder: _vm.$t("订单号") },
            model: {
              value: _vm.search.orderNo,
              callback: function ($$v) {
                _vm.$set(_vm.search, "orderNo", $$v)
              },
              expression: "search.orderNo",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: {
                multiple: "",
                "collapse-tags": "",
                clearable: "",
                placeholder: _vm.$t("会员类型"),
              },
              model: {
                value: _vm.search.memberType,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "memberType", $$v)
                },
                expression: "search.memberType",
              },
            },
            _vm._l(_vm.memberTypes, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: { clearable: "", placeholder: "是否推单订单" },
              model: {
                value: _vm.search.isPush,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "isPush", $$v)
                },
                expression: "search.isPush",
              },
            },
            [
              _c("el-option", { attrs: { label: "是", value: 1 } }),
              _c("el-option", { attrs: { label: "否", value: 0 } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: { clearable: "", placeholder: _vm.$t("状态") },
              model: {
                value: _vm.search.status,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "status", $$v)
                },
                expression: "search.status",
              },
            },
            _vm._l(_vm.bettingStatus, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key },
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "140px" },
            attrs: { clearable: "", placeholder: _vm.$t("会员账号") },
            model: {
              value: _vm.search.memberName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "memberName", $$v)
              },
              expression: "search.memberName",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "140px" },
            attrs: { clearable: "", placeholder: "投注超过额度" },
            model: {
              value: _vm.search.amount,
              callback: function ($$v) {
                _vm.$set(_vm.search, "amount", _vm._n($$v))
              },
              expression: "search.amount",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "140px" },
            attrs: { clearable: "", placeholder: "盈利超过额度" },
            model: {
              value: _vm.search.profit,
              callback: function ($$v) {
                _vm.$set(_vm.search, "profit", _vm._n($$v))
              },
              expression: "search.profit",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: { clearable: "", placeholder: _vm.$t("订单状态") },
              model: {
                value: _vm.search.paymentType,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "paymentType", $$v)
                },
                expression: "search.paymentType",
              },
            },
            _vm._l(_vm.paymentType, function (val, key) {
              return _c("el-option", {
                key: key,
                attrs: { label: val, value: key },
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "140px" },
            attrs: { clearable: "", placeholder: _vm.$t("所属上级") },
            model: {
              value: _vm.search.agencyMemberName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "agencyMemberName", $$v)
              },
              expression: "search.agencyMemberName",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: { clearable: "", placeholder: "是否后台推单" },
              model: {
                value: _vm.search.isBackgroundPush,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "isBackgroundPush", $$v)
                },
                expression: "search.isBackgroundPush",
              },
            },
            [
              _c("el-option", { attrs: { label: "是", value: 1 } }),
              _c("el-option", { attrs: { label: "否", value: 0 } }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleSearchWrap },
            },
            [_vm._v(_vm._s(_vm.$t("查询")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-refresh-right" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("重置")))]
          ),
        ],
        1
      ),
      _c(
        "d-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "DTable",
          attrs: {
            data: _vm.items,
            columns: _vm.columns,
            "original-columns": _vm.defaultColumns,
            options: Object.assign({}, _vm.options, { size: "small" }),
            "row-key": "key",
            "selection-row": "",
            "default-sort": { prop: "createTime", order: "descending" },
            "tree-props": { children: "children", hasChildren: "hasChildren" },
            lazy: "",
            load: _vm.loadDetail,
            selectable: _vm.selectable,
          },
          on: {
            refresh: _vm.handleFetch,
            "column-change": _vm.handleColumnChange,
            "sort-change": _vm.handleSortChange,
            select: _vm.handleSelect,
            "select-all": _vm.handleSelect,
          },
          scopedSlots: _vm._u([
            {
              key: "right",
              fn: function () {
                return [
                  _vm.permission.Push
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleOpenPush },
                        },
                        [_vm._v("六合推单")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        plain: "",
                        type: "primary",
                        loading: _vm.excelLoading1,
                        icon: "el-icon-download",
                      },
                      on: { click: _vm._handleExport },
                    },
                    [_vm._v(_vm._s(_vm.$t("导出Excel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        plain: "",
                        type: "primary",
                        loading: _vm.excelLoading2,
                        icon: "el-icon-download",
                      },
                      on: { click: _vm._handleCountExport },
                    },
                    [_vm._v(_vm._s(_vm.$t("汇总导出Excel")))]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _vm._v(" " + _vm._s(_vm.$t("总投注额")) + "："),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.statistical ? _vm.statistical.countAmount : "***")
                ),
              ]),
              _vm._v(" " + _vm._s(_vm.$t("有效投注额")) + "："),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.statistical ? _vm.statistical.realAmount : "***")
                ),
              ]),
              _vm._v(" " + _vm._s(_vm.$t("中奖金额")) + "："),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.statistical ? _vm.statistical.countWin : "***")
                ),
              ]),
              _vm._v(" " + _vm._s(_vm.$t("盈亏")) + "："),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.statistical ? _vm.statistical.countProfit : "***")
                ),
              ]),
              _vm._v(" " + _vm._s(_vm.$t("总用户")) + "："),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.statistical ? _vm.statistical.countMember : "***")
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-link",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm._getBetsTotal },
                },
                [_vm._v("查看结果")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("UserDialog", { ref: "UserDialogControl" }),
      _c("VisibleDialog", { ref: "VisibleDialogControl" }),
      _c("SettlementDialog", {
        ref: "SettlementDialogControl",
        on: { settle: _vm.handleSettle, issueSettle: _vm.handleIssueSettle },
      }),
      _c("CancelDialog", {
        ref: "CancelDialogControl",
        on: { cancel: _vm.handleCancel, issueCancel: _vm.handleIssueCancel },
      }),
      _c("OrderPushDialog", {
        ref: "OrderPushDialogControl",
        on: { push: _vm.handleOrderPush },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }