import request from '@/plugins/axios'

// 新增修改Abpay配置
export const upAbpayConfig = (data) => {
  return request({
    url: '/paymentAbpayConfig/add',
    method: 'post',
    data
  })
}

// 查询Abpay配置列表
export function queryAbpayConfig(params = {}) {
  return request({
    url: '/paymentAbpayConfig/list',
    method: 'get',
    params
  })
}

// 查询Abpay配置列表
export function queryAbpayConfigInfo(data = {}) {
  return request({
    url: '/paymentAbpayConfig/info',
    method: 'post',
    data
  })
}
