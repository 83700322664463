<template>
  <el-dialog
    title="总开关修改"
    width="550px"
    :visible.sync="visible"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" label-width="120px">
      <el-form-item label="总启停设置">
        <el-radio-group v-model="ruleForm.status">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
        <p style="color: red; font-size: 12px">设置开启/关闭，会影响所有平台彩种启停状态</p>
      </el-form-item>
      <el-form-item label="总显示隐藏设置">
        <el-radio-group v-model="ruleForm.showStatus">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">隐藏</el-radio>
        </el-radio-group>
        <p style="color: red; font-size: 12px">设置显示/隐藏，会影响所有平台彩种的显示状态</p>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>

import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  status: '',
  showStatus: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update'
    }
  },
  methods: {
    open(params) {
      this.ruleForm = { ...this.ruleForm, ...params }
      this.visible = true
    }
  }
}
</script>
