<template>
  <el-dialog
    title="请确认账单"
    :visible.sync="visible"
    width="380px"
    :close-on-click-modal="false"
    class="border-form-dialog"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      :show-message="false"
      label-width="95px"
    >
      <el-form-item label="租户名称">{{ ruleForm.tenantName }}</el-form-item>
      <el-form-item label="抽成合计">{{ incomeTotal }}</el-form-item>
      <el-form-item label="费用合计">{{ costToal }}</el-form-item>
      <el-form-item label="应收账款">{{ ruleForm.receivableAccount }}</el-form-item>
      <el-form-item v-if="emitName === 'review'" label="实收账款">{{ ruleForm.receiptsAccount }}</el-form-item>
      <el-form-item v-else label="实收账款" prop="receiptsAccount">
        <el-input v-model="ruleForm.receiptsAccount" placeholder="请输入实收账款" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="ruleForm.remark" type="textarea" :rows="5" :readonly="emitName === 'review'" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button v-if="emitName === 'confirm'" type="primary" :loading="submitting" @click="handleSubmit">
        确认
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  receiptsAccount: null,
  betsShareIncome: 0,
  giftShareIncome: 0,
  payroomShareIncome: 0,
  beautyCost: 0,
  serverCost: 0,
  maintenanceCost: 0,
  remark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'review',
      rules: {
        receiptsAccount: [
          { required: true, message: '请输入', trigger: 'blur' },
          { pattern: /^\+?[1-9][0-9]*$/, message: '请输入正确整数', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    incomeTotal() {
      return parseFloat(this.ruleForm.betsShareIncome) + parseFloat(this.ruleForm.giftShareIncome) + parseFloat(this.ruleForm.payroomShareIncome)
    },
    costToal() {
      return parseFloat(this.ruleForm.beautyCost) + parseFloat(this.ruleForm.serverCost) + parseFloat(this.ruleForm.maintenanceCost)
    }
  },
  methods: {
    open(row, emitName) {
      this.emitName = emitName
      this.ruleForm = { ...this.ruleForm, ...row }
      this.visible = true
    }

  }
}
</script>
