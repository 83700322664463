import { validURL } from '@/utils/validate'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('Member_Expert.ExpertTab.专家头像'),
      key: 'avatar',
      align: 'center',
      width: 80,
      component: {
        render: (h, row) => {
          if (validURL(row.avatar)) {
            return (<el-image fix='cover' src={row.avatar} lazy style='width: 40px; height: 40px; border-radius: 50%;' />)
          } else {
            return (<img src={require('@/assets/public_images/public-avatar.png')} style='width: 40px; height: 40px; border-radius: 50%;' />)
          }
        }
      }
    },
    {
      title: this.$t('Member_Expert.ExpertTab.专家账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('Member_Expert.ExpertTab.专家昵称'),
      key: 'nickname',
      align: 'center'
    },
    {
      title: this.$t('Member_Expert.ExpertTab.擅长联赛'),
      key: 'greatGames',
      align: 'center'
    },
    {
      key: 'tenofWinrate',
      align: 'center',
      sortable: 'custom',
      renderHeader: (h) => {
        return (
          <div style='display: inline-block; vertical-align: inherit;'>
            <p>{ this.$t('Member_Expert.ExpertTab.总胜率') }</p>
            <p>{ this.$t('Member_Expert.ExpertTab.近10_近7_近3') }</p>
          </div>
        )
      },
      component: {
        render: (h, row) => {
          return (<div>
            <p>{ parseInt(row.winrate) }%</p>
            <p>{ parseInt(row.tenofWinrate) }%,{ parseInt(row.sevenofWinrate) }%,{ parseInt(row.threeofWinrate) }%</p>
          </div>)
        }
      }
    },
    {
      key: 'tenofReturnrate',
      align: 'center',
      sortable: 'custom',
      renderHeader: (h) => {
        return (
          <div style='display: inline-block; vertical-align: inherit;'>
            <p>{ this.$t('Member_Expert.ExpertTab.总返奖率') }</p>
            <p>{ this.$t('Member_Expert.ExpertTab.近10_近7_近3') }</p>
          </div>
        )
      },
      component: {
        render: (h, row) => {
          return (<div>
            <p>{ parseInt(row.returnrate) }%</p>
            <p>{ parseInt(row.tenofReturnrate) }%,{ parseInt(row.sevenofReturnrate) }%,{ parseInt(row.threeofReturnrate) }%</p>
          </div>)
        }
      }
    },
    {
      title: this.$t('Member_Expert.ExpertTab.最高连胜'),
      key: 'linkwin',
      align: 'center',
      sortable: 'custom'
    },
    {
      title: this.$t('Member_Expert.ExpertTab.推荐次数'),
      key: 'artcileNum',
      align: 'center',
      sortable: 'custom',
      component: {
        render: (h, row) => {
          return (
            <span
              class='primary-type cursor-pointer hover-underline'
              onClick={() => this.$refs.HistoryDialogControl.open(row)}
            >{ row.artcileNum }</span>)
        }
      }
    },
    {
      title: this.$t('状态'),
      key: 'expertStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          const { Status } = this.permission
          return (
            <d-switch v-model={row.expertStatus} disabled={!Status} activeValue={1} inactiveValue={0} beforeChange={() => this._handleUpdateStatus(row)}>
              <span slot='open'>{ this.$t('正常') }</span>
              <span slot='close'>{ this.$t('禁用') }</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 160,
      component: {
        render: (h, row) => {
          const { Update } = this.permission
          return (Update && <el-button type='success' size='mini' icon='el-icon-edit-outline' onClick={() => this.$refs.UpdateDialogControl.open(row, this.positionList)}>{ this.$t('编辑') }</el-button>)
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
