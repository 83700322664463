import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  // 绕过权限机制
  if (route.hidden || route.meta?.noauth) return true
  return roles.some(role => role.permission === route.path || (route.path === '/' && role.permission === '/dashboard'))
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      const role = roles.find(r => tmp.path === r.permission || (tmp.path === '/' && r.permission === '/dashboard'))
      if (role && role.permission !== '/dashboard') {
        tmp.meta.title = role.text
        if (tmp.children) {
          tmp.children = filterAsyncRoutes(tmp.children, role.children)
        }
      }

      res.push(tmp)
    }
  })
  return res
}

const state = {
  routes: [],
  addRoutes: [],
  homeRoute: '/dashboard'
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes

    // 如果是代理后台，则不需要constantRoutes
    if (process.env.NODE_ENV.includes('prox')) state.routes = routes
    else  {
      state.homeRoute = routes[0].children[0].path
      state.routes = constantRoutes.concat(routes)
    }
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      // asyncRoutes 是完整的路由配置，而 roles 是用户的角色权限信息
      //@@@@取消权限过滤，记得要改回去
      const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      // const accessedRoutes = asyncRoutes
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
