<template>
  <el-dialog title="添加图库" :visible.sync="visible" width="46%" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="六合彩种" prop="lotteryId">
        <el-select v-model="ruleForm.lotteryId" class="filter-item" disabled placeholder="选择六合彩种" style="width: 100%">
          <el-option label="快乐六合彩" :value="1255" />
        </el-select>
      </el-form-item>
      <el-form-item label="期数" prop="issue">
        <el-select v-model="ruleForm.issue" filterable class="filter-item" placeholder="期数" style="width: 100%">
          <el-option v-for="(item,index) in openPrizeList" :key="index" :label="`${item.issue}期`" :value="item.issue" />
        </el-select>
      </el-form-item>
      <el-form-item label="图库名称" prop="pictureId" >
        <el-select v-model="ruleForm.pictureId" filterable maxlength="20" class="filter-item"  placeholder="请输入图库名称查询，最多20个字符" style="width: 100%">
          <el-option v-for="(item,index) in pictureListArr" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="上传图片" prop="url">
        <el-upload
          class="upload-drag"
          drag
          :accept="accept"
          :data="uploadFileParams"
          :headers="$headers()"
          :action="action"
          :on-success="onSuccess"
          :before-upload="onBeforeUpload"
          :before-remove="onBeforeRemove"
          :file-list="fileList"
          list-type="picture"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { pictureList,openPrize} from '@/api/interactive'

const getDefaultForm = () => ({
  issue:"",
  url:"",
  lotteryId: 1255,
  pictureId:'',
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  props: {
    accept: {
      type: String,
      default: 'image/jpeg,image/png,image/jpg,image/webp'
    },
    maxSize: {
      default: 0.5,
      type: Number
    }
  },
  computed: {
    uploadFileParams() {
      return { type: 'image', folder: 'lh-image' }
    },

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    }
  },
  data() {
    return {
      emitName: 'create',
      fileList: [],
      issuesOptions:[],
      pictureListArr:[],
      openPrizeList:[],
      rules: {
        lotteryId: [
          { required: true, message: '请选择六合彩种', trigger: 'change' },
        ],
        issue: [
          { required: true, message: '请选择期数', trigger: 'change' },
        ],
        pictureId: [
          { required: true, message: '请选择图库名称', trigger: 'change' },
        ],
        url: [{ required: true, message: '请上传图片' }]
      }
    }
  },
  created(){
    this.getPictureList()
    this.getOpenPrize()
  },
  methods: {
    // 获取期数
    async getOpenPrize(){
      const params = {
        currentPage:1,
        pageSize:1000,
        lotteryId:this.ruleForm.lotteryId
      }
      let [data, err] = await openPrize(params)
      if(!err) this.openPrizeList = data
    },
    // 获取图库
    async getPictureList(){
      const params = {
        pictureTag:"KLLH01",
      }
      let [data, err] = await pictureList(params)
      if(!err) this.pictureListArr = data.map(item=>{
        return {
          name:item.name,
          id:+item.id
        }
      })
    },
    open() {
      this.visible = true
    },
    onSuccess(res, file) {
      this.ruleForm.url = res.data[0]
      this.fileList = [{ name: file.name, url: file.url }]
      this.$refs.validateForm.validateField('url')
    },
    onBeforeUpload(file) {
      const isAccept = this.accept.split(',').some(o => file.type === o)
      const isMax = file.size / 1024 / 1024 < this.maxSize
      if (!isAccept) {
        this.$message.error('上传图片只能是 JPG,PNG 格式!')
      }
      if (!isMax) {
        this.$message.error('上传图片大小不能超过 500KB!')
      }

      return isAccept && isMax
    },
    onBeforeRemove() {
      this.ruleForm.url = ''
      this.fileList = []
      this.$refs.validateForm.validateField('url')
      return true
    },
  }
}
</script>
