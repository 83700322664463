import Cookies from 'js-cookie'

const TokenKey = 'X-AUTH-TOKEN'

const ImTokenKey = 'X-AUTH-IM-TOKEN'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { sameSite: 'strict' })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getImToken() {
  return Cookies.get(ImTokenKey)
}

export function setImToken(token) {
  return Cookies.set(ImTokenKey, token, { sameSite: 'strict' })
}

export function removeImToken() {
  return Cookies.remove(ImTokenKey)
}

export function setSession(key, value) {
  sessionStorage.setItem(key, value)
}

export function getSession(key) {
  return sessionStorage.getItem(key)
}

export function clearSession() {
  sessionStorage.clear()
}
