import { createService } from "@/plugins/axios";
import { getImToken } from "@/utils/auth";

// 获取房间历史信息
export function getRoomMessage(params, url) {
  const request = createService(url, '200', true);
  return request({
    url: "/im/history",
    method: "get",
    params,
    headers: {
      "X-AUTH-TOKEN": getImToken(),
    },
  });
}
