<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <component :id="id" :is="activeName" ref="AliveComponent" />
    </template>
  </div>
</template>
<script>
import ManageTab from './ManageTab'
import ClassificationTab from './ClassificationTab'
import RewardTab from './RewardTab'
import SigninConfigTab from './SigninConfigTab'
import SigninRecordTab from './SigninRecordTab'
import { parentMixin } from '@/mixins/tab-items'

export default {
  components: {
    ManageTab,
    ClassificationTab,
    RewardTab,
    SigninConfigTab,
    SigninRecordTab
  },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      tabs: this.$t('taskTabs'),
      id:''
    }
  },
  created(){
    this.id = this.$route.query.id
  },
}
</script>

<style lang="scss">
.amount-total{
  display: flex;
  gap: 32px;
  font-size: 14px;
  margin-top:16px;
}
</style>
