<template>
  <div class="app-container">
    <!-- <h4 v-if="agentName">{{ agentName }}</h4> -->
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        type="daterange"
        class="filter-item"
        style="width: 260px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        clearable
        :picker-options="timeOption"
      />
      <el-input
        v-model.trim="search.memberName"
        maxlength="20"
        clearable
        :placeholder="$t('请输入代理账号')"
        class="filter-item"
        style="width: 140px"
        
      />
      <el-select v-model="search.settlementStatus" placeholder="查询状态" class="filter-item">
      <el-option v-for="item in statusOptions" :label="item.label" :value="item.value"  :key="item.value"></el-option>
    </el-select>
    <el-select v-model="search.amountStatus" placeholder="代理返佣是否大于0" class="filter-item">
      <el-option v-for="item in yesOrNoOptions" :label="item.label" :value="item.value"  :key="item.value"></el-option>
    </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleLoad">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置')}} </el-button>
      
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      :selectable="row => row.settlementStatus === 0"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
      @selection-change="handleSelectionChange"
    > 
    <template slot="left">
        <el-button class="filter-item" type="primary"  v-if="permission.BatchAudit" @click="handleBatchCheck">{{$t('批量审核')}}</el-button>
      </template>
      <template slot="right">
        <!-- <el-button v-if="permission.BatchAudit" type="success" :disabled="selection.length === 0" @click="$refs.BatchCheckDialogControl.open()">一键审核</el-button> -->
        <el-button class="filter-item" type="primary" icon="el-icon-download" @click="handleExport">{{$t('导出')}}</el-button>
      </template>
      <template slot="description" >
        <pre class="description-container"> 合计:代理人数{{summeryData.rebateMembers || 0}}   总佣金{{summeryData.totalRebateAmount|| 0}}   已派发佣金{{summeryData.rebateAmount || 0}}</pre> 
        </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <user-dialog ref="UserDialogControl" />
    <BatchCheckDialog ref="BatchCheckDialogControl" @update="_handleUpdateBatch" />
    <el-dialog :visible="currentRow != null" :title="$t('审核结果')" width="30%" center @close="currentRow = null">
      <p class="op-dialog">
        <!-- <span>审核结果</span> -->
        <el-switch
          v-model="appAvl"
          size="large"
          :active-value="1"
          :inactive-value="2"
          active-text="通过结算"
          inactive-text="拒绝结算"
        />

      </p>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="currentRow = null">{{ $t('取消') }}</el-button>
          <el-button type="primary" @click="approve">
            {{ $t('确定') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import tableStaticConfigMx from '@/views/mixins/table-static-config.js'
import BatchCheckDialog from './components/BatchCheckDialog.vue'
import { getAgencyRebateReport, handleAgency, batchAgencyCheck, approve,getRebateCount,handleExportAgency } from '@/api/agent-rebate.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getDefaultTimes, parseTime } from '@/utils'
import UserDialog from '@/views/components/UserDialog'
import { agencyTypes } from '@/libs/options'
import {  exportExcel, filterParam } from "@/utils";
export default {
  name: 'AgentRebateReport',
  components: { BatchCheckDialog, UserDialog },
  mixins: [TablePageMixin(getAgencyRebateReport), tableStaticConfigMx],
  data() {
    return {
      agentName: '',
      times: getDefaultTimes(),
      search: {},
      defaultColumns: GetColumns.call(this),
      columns: [],
      selection: [],
      staticInfo: null,
      timeOption: {
        onPick: ({ maxDate, minDate }) => {
          this.minDate = minDate
          this.maxDate = maxDate
        },
        disabledDate: (time) => {
          if (new Date(parseTime(time, '{y}-{m}-{d} 00:00:00')).valueOf() > Date.now()) {
            return true
          }
          const dateTime = new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000).getTime() - 8.64e7
          if (dateTime > time.getTime()) {
            return true
          }
          if (this.minDate && !this.maxDate) {
            const range = 31 * 24 * 3600 * 1000 // 要更改禁用的日期范围只要修改这里的代码就可以
            const minTime = this.minDate.getTime()
            const newTime = time.getTime()
            return newTime > (minTime + range) || newTime < (minTime - range)
          }
          return false
        }
      },
      minDate: '',
      maxDate: '',
      agencyTypes,
      appAvl: false, // 是否审核通过
      currentRow: null,// 当前行
      statusOptions:[
        {label: '全部', value: ''},
        {label: '未结算支付', value: 0},
        {label: '已结算支付', value: 1},
        {label: '拒绝结算', value: 2},
      ],
      yesOrNoOptions: [
      {label: '全部', value: ''},
        { label: '是', value: 1 },
        { label: '否', value: 0 }
      ],
      auditDialogVisible: false, // 审核弹窗
      summeryData:{}
    }
  },
  created() {
    const agencyType = this.$store.getters.agencyType
    if (agencyType) {
      this.agentName = this.agencyTypes.find(e => e.key === agencyType)?.label + this.$t('代理')
    }
    
  },
  methods: {
    goDetail({ memberId, month: monthTime }) {
      this.$router.push({ name: 'AgentRebateReportDetail', query: { memberId, monthTime }})
    },
    _handleAgency(params) {
      handleAgency(params).then(([_, err]) => {
        if (!err) {
          this.handleFetch()
          this.$message.success(this.$t('操作成功'))
        }
      })
    },
    _handlePass({ memberId, month }, flag) {
      this.$confirm(this.$t('确认通过?'), { type: 'warning' }).then(() => {
        this._handleAgency({ flag, memberId, month })
      }).catch(() => { })
    },

    _handleReject({ memberId, month }, flag) {
      this.$prompt('拒绝原因', {
        center: true,
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        inputValidator: (val) => {
          if (val === null) val = ''
          val = val.replace(/\s+/g, '')
          return val.length > 0
        },
        inputErrorMessage: this.$t('请输入拒绝原因')
      }).then(({ value }) => {
        this._handleAgency({ flag, memberId, month, remark: value })
      }).catch(() => { })
    },

    _handleUpdateBatch({ field, close, cancel }) {
      console.log(field)
      const data = this.selection.map(o => ({ agencyIds: [o.memberId], dataTime: o.dataTime, settlementStatus: field.flag }))
      batchAgencyCheck(data).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('批量操作成功!'))
        } else {
          cancel()
        }
      })
    },

    handleSelectionChange(selection) {
      this.selection = selection
    },

    implementationGetParams() {
      return this.formatIntervalTime()
    },
    // implementationFetched() {
    //   const search = this.formatIntervalTime()
    //   statistics(search).then(([data, err]) => {
    //     if (!err && data) this._initstatic(data)
    //   })
    // },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    // _initstatic(data) { // 初始化统计信息
    //   const statics = {
    //     sumRecharge: { columnName: 'sumRecharge', val: 0 }, // 充值金额
    //     sumWithdraw: { columnName: 'sumWithdraw', val: 0 }, // 提现金额
    //     validBet: { columnName: 'validBet', val: 0 }, // 有效投注金额
    //     reward: { columnName: 'reward', val: 0 }, // 中奖金额
    //     negativeProfit: { columnName: 'negativeProfit', val: 0 }, // 负盈利金额
    //     sumBetBalance: { columnName: 'sumBetBalance', val: 0 }, // 未结算订单金额
    //     sumTeamNum: { columnName: 'sumTeamNum', val: 0 }, // 团队人数
    //     oneAchievement: { columnName: 'oneAchievement', val: 0 }, // 一级代理业绩
    //     twoAchievement: { columnName: 'twoAchievement', val: 0 }, // 二级代理业绩
    //     sumBonus: { columnName: 'sumBonus', val: 0 } // 代理返佣
    //   }
    //   for (const key in statics) {
    //     if (data[key]) statics[key].val = data[key]
    //   }
    //   this.staticInfo = { ...statics }
    // }
    async approve() {
      let res = await approve({
        agencyIds: [this.currentRow.memberId], // 代理ID集合
        settlementStatus: this.appAvl, // 结算状态 1-通过结算 2-拒绝结算
        dataTime: this.currentRow.dataTime// 代理佣金统计时间 打码量租户 yyyy-MM-dd， 负盈利租户 yyyy-MM

      })
      let isOk = res[2] && res[2].data && res[2].data.success
      this.currentRow = null

      if (isOk) setTimeout(() => this.$message.success(this.$t('审核成功')), 500)
      else setTimeout(() => this.$message.error(this.$t('审核失败')), 500)
    },
    // _initstatic(data) { // 初始化统计信息
    //   const statics = {
    //     sumRecharge: { columnName: 'sumRecharge', val: 0 }, // 充值金额
    //     sumWithdraw: { columnName: 'sumWithdraw', val: 0 }, // 提现金额
    //     validBet: { columnName: 'validBet', val: 0 }, // 有效投注金额
    //     reward: { columnName: 'reward', val: 0 }, // 中奖金额
    //     negativeProfit: { columnName: 'negativeProfit', val: 0 }, // 负盈利金额
    //     sumBetBalance: { columnName: 'sumBetBalance', val: 0 }, // 未结算订单金额
    //     sumTeamNum: { columnName: 'sumTeamNum', val: 0 }, // 团队人数
    //     oneAchievement: { columnName: 'oneAchievement', val: 0 }, // 一级代理业绩
    //     twoAchievement: { columnName: 'twoAchievement', val: 0 }, // 二级代理业绩
    //     sumBonus: { columnName: 'sumBonus', val: 0 } // 代理返佣
    //   }
    //   for (const key in statics) {
    //     if (data[key]) statics[key].val = data[key]
    //   }
    //   this.staticInfo = { ...statics }
    // }
    getFormParams(){
      return filterParam({
        startTime: this.times && this.times.length >= 2 && this.times[0],
        endTime: this.times && this.times.length >= 2 && this.times[1],
        ...this.search
      })
    },
    async getAgencyTotalData(){
      const params = this.getFormParams()
      const [data] = await getRebateCount(params)
      this.summeryData = data || {}
    },
    handleLoad(){
      this.handleSearch()
      this.getAgencyTotalData()
    },
    async handleExport(){
      const params = { ...this.params, ...this.getFormParams(), tenantCode:this.$store.getters.tenantCode}
       const [data, err, blobData] =  await  handleExportAgency(params)
       exportExcel(blobData, `佣金报表-${this.$parseTime(new Date())}`)
       if(!err) this.$message.success(this.$t('导出成功'))
    },
    handleBatchCheck() {
      if(!this.selection.length) return this.$message.warning(this.$t('请先选择数据'))
      this.$refs.BatchCheckDialogControl.open()
    }
  }
}
</script>
<style lang="scss">
.op-dialog {
  display: flex;
  align-items: center;
  justify-content: center;

}
.description-container{
  height: 35px;
  line-height: 35px;
  color: red;
  margin:0 auto;
}
</style>
