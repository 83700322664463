function getPermission (items) {
  return items.reduce((p, v) => {
    p[v.permission] = true;
    return p;
  }, {});
}

const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  notice: state => state.app.notice,
  size: state => state.app.size,
  device: state => state.app.device,
  allLang: state => state.app.allLang,
  lang: state => state.app.lang,
  agencyDataConfigs: state => state.app.agencyDataConfigs,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  totalUnReadNum: state => state.tagsView.totalUnReadNum,
  unReadNumSocket: state => state.tagsView.unReadNumSocket,

  token: state => state.user.token,
  imToken: state => state.user.imToken,
  avatar: state => state.user.info.logo,
  name: state => state.user.info.nickName,
  tenantCode: state => state.user.info.tenantCode,
  operatorId: state => state.user.info.operatorId,
  operatorType: state => state.user.info.operatorType,
  agencyType: state => state.user.info.agencyType,
  info: state => state.user.info,
  remark: state => state.user.remark,

  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  homeRoute: state => state.permission.homeRoute,

  permissionSensitive: state => (url, name) => {
    if (url.startsWith('/redirect/') || !name) return {};
    const _parentUrl = url.split('_')[0];
    const _parent = state.user.roles.find(o => o.permission === _parentUrl);
    
    if (!_parent) return {};

    let sensitive = _parent.children.find(o => o.permission === url);
    
    if (!sensitive || !sensitive.children.length) return {};

    if (sensitive.hasChildren) {
      sensitive = sensitive.children.find(o => o.permission === name);
    }
    if (!sensitive) return {};
    return getPermission(sensitive.children);
  }
};
export default getters;
