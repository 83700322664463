var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "系统提示",
        width: "560px",
        visible: _vm.visible,
        "append-to-body": "",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.handleColse,
        closed: _vm.handleClosed,
      },
    },
    [
      _c("div", { staticStyle: { "text-align": "center", margin: "20px" } }, [
        _vm._v(_vm._s(_vm.tipTitle)),
      ]),
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "谷歌验证码", prop: "googleCode" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.googleCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "googleCode", $$v)
                  },
                  expression: "ruleForm.googleCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 确认 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }