import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('借款订单编号'),
      key: 'orderNo',
      align: 'center'
    },
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('当前借款次数'),
      key: 'borrowTimes',
      align: 'center'
    },
    {
      title: this.$t('总借呗额度'),
      key: 'totalAmount',
      align: 'center'
    },
    {
      title: this.$t('已使用额度'),
      key: 'usedAmount',
      align: 'center'
    },
    {
      title: this.$t('当前额度'),
      key: 'nowAmount',
      align: 'center'
    },
    {
      title: this.$t('借款金额'),
      key: 'borrowAmount',
      align: 'center'
    },
    {
      title: this.$t('剩余额度'),
      key: 'remainAmount',
      align: 'center'
    },
    {
      title: this.$t('应还金额'),
      key: 'remainRepayAmount',
      align: 'center'
    },
    {
      title: this.$t('打码倍数'),
      key: 'rollingTimes',
      align: 'center'
    },
    {
      title: this.$t('申请时间'),
      key: 'applyTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.applyTime) }</span>)
        }
      }
    },
    {
      title: this.$t('审核时间'),
      key: 'approvalTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.approvalTime) }</span>)
        }
      }
    },
    {
      title: this.$t('审核人'),
      key: 'approvalUser',
      align: 'center'
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
