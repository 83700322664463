<template>
  <el-dialog
    :title="title"
    width="700px"
    :visible.sync="visible"
    @open="onOpen"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model.trim="ruleForm.title" maxlength="10" placeholder="请输入平台公告标题" />
      </el-form-item>
      <el-form-item label="发送方式" prop="sendType">
        <el-select v-model="ruleForm.sendType" clearable placeholder="请选择发送方式" @change="onChange">
          <el-option v-for="(item, key) in $parent.sendTypeOptions" :key="key" :label="item" :value="key * 1" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.sendType === 1"
        label="接收租户简称"
        prop="tenantList"
      >
        <el-input v-model.trim="ruleForm.tenantList" placeholder="请输入接收租户的简称, 多个租户简称之间以英文逗号隔开,例如: 简称1,简称2" />
      </el-form-item>
      <el-form-item label-width="0" prop="content">
        <tinymce ref="tinymce" v-model="ruleForm.content" :height="200" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import FormDialogMixin from '@/mixins/form-dialog'
import { getNoticeDetail } from '@/api/tenant'

const getDefaultForm = () => ({
  title: '',
  sendType: null,
  tenantList: '',
  content: ''
})

export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      loading: false,
      rules: {
        title: [{ required: true, message: '请输入', trigger: 'blur' }],
        sendType: [{ required: true, message: '请选择', trigger: 'change' }],
        content: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? '发送新平台公告' : '修改平台公告信息'
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.emitName = 'update'
        this.ruleForm.id = row.id
      }
      this.visible = true
    },
    onChange() {
      this.ruleForm.tenantList = ''
    },

    onOpen() {
      if (this.emitName === 'create') return
      this.loading = true
      getNoticeDetail({ id: this.ruleForm.id }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.ruleForm = { ...this.ruleForm, ...data }
          this.$nextTick(() => {
            this.$refs.tinymce.setContent(data.content)
          })
        }
      })
    }
  }
}
</script>
