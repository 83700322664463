var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("cascader-select", {
        attrs: { options: _vm.cascaderOptions, label: false },
        on: { change: _vm.changeValue },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("d-switch", {
            staticClass: "filter-item",
            attrs: { "active-value": 1, "inactive-value": 3 },
            model: {
              value: _vm.allStatus,
              callback: function ($$v) {
                _vm.allStatus = $$v
              },
              expression: "allStatus",
            },
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "400px" },
            attrs: {
              type: "datetimerange",
              "range-separator": "~",
              "start-placeholder": "开始时间",
              "end-placeholder": "结束时间",
              "value-format": "yyyy-MM-dd HH:mm:ss",
              clearable: "",
              "default-time": ["00:00:00", "23:59:59"],
            },
            model: {
              value: _vm.times,
              callback: function ($$v) {
                _vm.times = $$v
              },
              expression: "times",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("保存")]
          ),
          _c(
            "span",
            { staticClass: "filter-item", staticStyle: { color: "#F40B0B" } },
            [
              _vm._v(
                "当前功能一般应用在三方游戏维护或作废时，一键操作三方游戏的开关"
              ),
            ]
          ),
        ],
        1
      ),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          options: _vm.options,
          "filter-column": _vm.filterColumn,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }