<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="380px"
    :close-on-click-modal="false"
    class="border-form-dialog"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      :show-message="false"
      label-width="95px"
    >
      <div class="receivable-item">
        <h2>应缴费用</h2>
        {{ ruleForm.receivableAccount }}
      </div>
      <el-form-item label="彩票抽成">{{ ruleForm.betsShareIncome }}</el-form-item>
      <el-form-item label="礼物抽成">{{ ruleForm.giftShareIncome }}</el-form-item>
      <el-form-item label="房费抽成">{{ ruleForm.payroomShareIncome }}</el-form-item>
      <el-form-item label="美颜费用" prop="beautyCost">
        <el-input v-model="ruleForm.beautyCost" />
      </el-form-item>
      <el-form-item label="服务器费用" prop="serverCost">
        <el-input v-model="ruleForm.serverCost" />
      </el-form-item>
      <el-form-item label="维护费用" prop="maintenanceCost">
        <el-input v-model="ruleForm.maintenanceCost" />
      </el-form-item>
      <el-form-item label="其他费用" prop="additionalCost">
        <el-input v-model="ruleForm.additionalCost" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="ruleForm.remark" type="textarea" :rows="5" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        确认
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  beautyCost: '',
  serverCost: '',
  maintenanceCost: '',
  additionalCost: '',
  remark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        beautyCost: [
          { required: true, message: '请输入', trigger: 'blur' },
          { pattern: /^\+?[1-9][0-9]*$/, message: '请输入正确整数', trigger: 'blur' }
        ],
        serverCost: [
          { required: true, message: '请输入', trigger: 'blur' },
          { pattern: /^\+?[1-9][0-9]*$/, message: '请输入正确整数', trigger: 'blur' }
        ],
        maintenanceCost: [
          { required: true, message: '请输入', trigger: 'blur' },
          { pattern: /^\+?[1-9][0-9]*$/, message: '请输入正确整数', trigger: 'blur' }
        ],
        additionalCost: [
          { required: true, message: '请输入', trigger: 'blur' },
          { pattern: /^\+?[1-9][0-9]*$/, message: '请输入正确整数', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    title() {
      return `${this.ruleForm.tenantName}${this.ruleForm.statementDate}账单`
    }
  },
  methods: {
    open(row) {
      this.ruleForm = { ...this.ruleForm, ...row }
      this.visible = true
    }

  }
}
</script>

<style lang="scss" scoped>
.receivable-item {
  text-align: center;
  border: 1px solid #ccc;
  padding-bottom: 16px;
  h2 {
    font-size: 20px;
  }
}
</style>
