var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.emitName === "create" ? _vm.$t("创建线路") : _vm.$t("编辑线路"),
        visible: _vm.visible,
        width: "430px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("线路名称"), prop: "servername" } },
            [
              _c("el-input", {
                attrs: { maxlength: "10" },
                model: {
                  value: _vm.ruleForm.servername,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "servername", $$v)
                  },
                  expression: "ruleForm.servername",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("推流域名"), prop: "serverurl" } },
            [
              _c("el-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.ruleForm.serverurl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "serverurl", $$v)
                  },
                  expression: "ruleForm.serverurl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("鉴权KEY(主)"), prop: "primarykey" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("请勿输入空格") },
                model: {
                  value: _vm.ruleForm.primarykey,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "primarykey", $$v)
                  },
                  expression: "ruleForm.primarykey",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("鉴权KEY(备)"), prop: "backupkey" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("请勿输入空格") },
                model: {
                  value: _vm.ruleForm.backupkey,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "backupkey", $$v)
                  },
                  expression: "ruleForm.backupkey",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确定")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }