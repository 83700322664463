import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import { $t } from '@/lang'

/* Router Modules */
import tenantRouter from './modules/tenant'
import financeRouter from './modules/finance'
import versionRouter from './modules/version'
import configRouter from './modules/config'
import activityRouter from './modules/activity'
import announcementRouter from './modules/announcement'
import lotteryRouter from './modules/lottery'
import ordersRouter from './modules/orders'
import interactiveRouter from './modules/interactive'
import liveRouter from './modules/live'
import memberRouter from './modules/member'
import agentRouter from './modules/agent'
import cashRouter from './modules/cash'
import statisticalRouter from './modules/statistical'
import permissionRouter from './modules/permission'
import sportsRouter from './modules/sports'
import cardRouter from './modules/card'
import orderManage from './modules/orderManage'
import vipRewardManage from './modules/vipRewardManage'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    tagView: false
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/dashboard'),
        name: 'Dashboard',
        meta: { title: $t('系统首页'), icon: 'dashboard', affix: false, noCache: true }
      },
      {
        path: '/qr-mgr',
        name: 'qrMgr',
        component: () => import('@/views/qr-mgr/index.vue'),
        meta: { title: $t('首页'), icon: 'dashboard', noCache: true, noauth: true },
        hidden: true
      }
    ]
  },
  tenantRouter,
  financeRouter,
  versionRouter,
  configRouter,
  activityRouter,
  cardRouter,
  announcementRouter,
  lotteryRouter,
  interactiveRouter,
  liveRouter,
  sportsRouter,
  orderManage,
  memberRouter,
  vipRewardManage,
  agentRouter,
  ordersRouter,
  cashRouter,
  statisticalRouter,
  permissionRouter,

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
