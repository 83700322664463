<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="daterange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetDate($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 7)">{{ $t('_7天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 30)">{{ $t('本月') }}</el-button>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Export" type="primary" :loading="excelLoading" icon="el-icon-download" @click="_handleExport">{{ $t("导出") }}</el-button>
    </div>
    <el-table v-show="resources.length" :data="resources" border>
      <el-table-column prop="time" align="center" label="时间">
        <template slot-scope="{ row }">{{ row.startTime }}~{{ row.endTime }}</template>
      </el-table-column>
      <el-table-column prop="registerNumber" align="center" label="注册人数" />
      <el-table-column prop="firstNumber" align="center" label="首存人数" />
      <el-table-column prop="totalDepositNumber" align="center" label="总存款人数" />
      <el-table-column prop="totalDepositAmount" align="center" label="总存款金额" />
      <el-table-column prop="totalNumber" align="center" label="投注人数" />
      <el-table-column prop="betAmount" align="center" label="有效投注" />
      <el-table-column prop="winAmount" align="center" label="派奖" />
      <el-table-column prop="profitAmount" align="center" label="盈亏" />
    </el-table>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="false"
    />
  </div>
</template>
<script>
import { tenantBetReport, tenantBetCount, tenantBetReportDownload } from '@/api/statistical'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getDefaultTimes, exportExcel } from '@/utils'
import { seriesTypes } from '@/libs/options'
export default {
  name: 'GameTab',
  mixins: [TablePageMixin(tenantBetReport)],
  data() {
    return {
      times: getDefaultTimes('n'),
      search: {},
      columns: GetColumns.call(this),
      resources: [],
      seriesTypes,
      excelLoading: false
    }
  },
  methods: {
    implementationFetched() {
      tenantBetCount({...this.implementationGetParams()}).then(([data, err]) => {
        if (!err) {
          this.resources = [data]
        }
      })
    },

    implementationGetParams() {
      return this.formatIntervalTime()
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    _handleExport() {
      this.excelLoading = true
      tenantBetReportDownload({ ...this.implementationGetParams() }).then(([data, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `投注统计-${this.$parseTime(new Date())}.csv`)
        }
      }).finally(()=>{
        this.excelLoading = false
      })
    }
  }
}
</script>
