<template>
  <el-dialog
    title="禁言限制"
    :visible.sync="visible"
    width="560px"
    :close-on-click-modal="false"
    class="border-form-dialog"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      :show-message="false"
      hide-required-asterisk
      label-width="180px"
    >
      <el-form-item label="限制类型" prop="roomForbidType">
        <el-radio-group v-model="ruleForm.roomForbidType">
          <el-radio :label="1">在该房间限时禁言</el-radio>
          <el-radio :label="2">在所有房间限时禁言</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="限制时间" prop="expiryDatee">
        <div flex="cross:center">
          <el-form-item prop="expiryDatee">
            <el-date-picker
              v-model="ruleForm.expiryDatee"
              type="datetime"
              placeholder="选择结束时间"
              style="width: 190px"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :disabled="ruleForm.forbidStatus === 2"
            />
          </el-form-item>
          <el-form-item prop="forbidStatus" style="margin-left: 10px">
            <el-checkbox
              v-model="ruleForm.forbidStatus"
              :true-label="2"
              :false-label="1"
              @change="onChangeStatus"
            >永久</el-checkbox>
          </el-form-item>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        确认
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  roomForbidType: 1,
  expiryDatee: '',
  forbidStatus: 1
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validExporyDatee = (rule, value, callback) => {
      if (value === '' && this.ruleForm.forbidStatus === 1) {
        callback(new Error('请选择'))
      } else {
        callback()
      }
    }
    return {
      emitName: 'update',
      rules: {
        expiryDatee: [{ validator: validExporyDatee, trigger: 'change' }]
      }
    }
  },
  methods: {
    open({ roomId, memberId }) {
      this.ruleForm.roomId = roomId
      this.ruleForm.memberId = memberId
      this.visible = true
    },
    onChangeStatus(status) {
      if (status === 2) {
        this.ruleForm.expiryDatee = ''
        this.$refs.validateForm.clearValidate(['expiryDatee'])
      }
    }
  }
}
</script>
