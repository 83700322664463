var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-wrapper" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            accept: _vm.accept,
            data: _vm.data,
            action: _vm.action,
            headers: _vm.$headers(),
            "show-file-list": false,
            "on-success": _vm.handleAvatarSuccess,
            "before-upload": _vm.beforeAvatarUpload,
          },
        },
        [
          _vm.currentValue
            ? _c("img", {
                staticClass: "avatar",
                style: _vm.styles,
                attrs: { src: _vm.currentValue },
              })
            : _c(
                "div",
                { staticClass: "avatar-uploader-icon", style: _vm.styles },
                [
                  _c("i", { staticClass: "el-icon-plus" }),
                  _c("p", [_vm._v(_vm._s(_vm.text))]),
                ]
              ),
        ]
      ),
      _vm.tips
        ? _c("span", [
            _vm._v(
              " 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。 "
            ),
          ])
        : _vm._e(),
      _vm.tipText
        ? _c("span", [_vm._v(" " + _vm._s(_vm.tipText) + " ")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }