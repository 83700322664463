var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      Array.isArray(_vm.permissionTabs) && _vm.permissionTabs.length
        ? [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _vm._l(_vm.permissionTabs, function (tab) {
                  return _c("el-tab-pane", {
                    key: tab.name,
                    attrs: { label: tab.label, name: tab.name },
                  })
                }),
                _c("el-tab-pane", {
                  attrs: { label: "ABPay", name: "Abpay" },
                  on: {
                    click: function ($event) {
                      _vm.activeName = "Abpay"
                    },
                  },
                }),
                _c("el-tab-pane", {
                  attrs: { label: "提现方式", name: "Withdraw" },
                  on: {
                    click: function ($event) {
                      _vm.activeName = "Withdraw"
                    },
                  },
                }),
              ],
              2
            ),
            _c("Payment" + _vm.activeName, {
              ref: "AliveComponent",
              tag: "component",
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }