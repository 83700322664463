<template>
  <div class="app-container">
    <cascader-select
      v-model="value"
      :options="cascaderOptions"
      @change="onChange"
    />

    <div class="wrapper-button">
      <template v-if="permission.OpenClose && statusOptions">
        <label>总开关：</label>
        <el-button type="success" plain size="small" @click="onStatus">
          {{ statusOptions.status === 1 ? '开启' : '关闭' }}/{{ statusOptions.showStatus === 1 ? '显示' : '隐藏' }}
        </el-button>
      </template>
      <template>
        <label>提前封盘时间：</label>
        <el-input-number v-model="stopbetTime" :min="0" :max="3600" :step="5" step-strictly size="small" /><span v-if="permission.StopTime" class="units_">秒</span>
        <el-button type="success" plain size="small" @click="_handleUpdateStopBetTime">确认</el-button>
      </template>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <SwitchDialog ref="SwitchDialogControl" @update="_handleUpdateStatus" />

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CascaderSelect from '@/components/CascaderSelect'
import TablePageMixin from '@/mixins/table-page-mixin'
import navigationMixin from '../navigationMixin'
import SwitchDialog from './SwitchDialog'
import { getLotteryNavigation, getLotteryTenantList, updateTenantStatus, updateLotteryTotalStatus, getStopBetsTime, updateStopBetTimeSecond, lotteryTotalStatus } from '@/api/lottery'
import GetColumns from './columns'

export default {
  name: 'LotteryContorl',
  components: { CascaderSelect, SwitchDialog },
  mixins: [TablePageMixin(getLotteryTenantList, false), navigationMixin(getLotteryNavigation)],
  data() {
    return {
      value: [],
      cascaderOptions: [],
      filterColumn: false,
      columns: GetColumns.call(this),
      statusOptions: null,
      stopbetTime: null
    }
  },
  computed: {
    ...mapGetters(['operatorType'])
  },
  created() {
    this.onFetch()
  },
  methods: {
    onStatus() {
      this.$refs.SwitchDialogControl.open({ ...this.statusOptions, lotteryId: this.value[1] })
    },
    onChange([seriesTag, lotteryId]) {
      this._getLotteryStatus(lotteryId)
      this._getStopBetsTime(lotteryId)
      this.handleFetch()
    },

    _getLotteryStatus(lotteryId) {
      lotteryTotalStatus({ lotteryId }).then(([data, err]) => {
        if (!err) {
          this.statusOptions = data
        }
      })
    },
    _getStopBetsTime(lotteryId) {
      getStopBetsTime({ lotteryId }).then(([data, err]) => {
        if (!err) this.stopbetTime = data || 0
      })
    },
    _handleUpdate({ tenantCode, platformStatus, platformShowStatus }) {
      this.$confirm(`确认修改此租户吗？`, { type: 'warning' }).then(() => {
        updateTenantStatus({ lotteryId: this.value[1], tenantCode, platformStatus, platformShowStatus }, this.operatorType).then(([_, err]) => {
          if (!err) {
            this.$message.success('修改成功')
            this.handleFetch()
          }
        })
      })
    },

    _handleUpdateStatus({ field, close, cancel }) {
      updateLotteryTotalStatus({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('修改成功')
          this._getLotteryStatus(this.value[1])
        } else {
          cancel()
        }
      })
    },
    _handleUpdateStopBetTime() {
      this.$confirm(`确认修改封盘时间为 ${this.stopbetTime} 秒吗???`, { type: 'warning' }).then(() => {
        updateStopBetTimeSecond({ stopBetTimeSecond: this.stopbetTime * 1, lotteryId: this.value[1] }).then(([_, err]) => {
          if (!err) {
            this.$message.success('修改成功!!!')
          }
        })
      })
    },
    implementationGetParams() {
      return { lotteryId: this.value[1] }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-button {
  .el-button {
    margin: 0 10px;
  }
}
</style>
