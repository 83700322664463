import request from '@/plugins/axios'

// VIP红包配置信息
export function vipRedEnvelopeConfig(data) {
  return request({
    url: '/vip-red-envelope/config/update',
    method: 'post',
    data
  })
}

// 获取VIP红包配置信息
export function getVipRedEnvelopeConfig(data) {
  return request({
    url: '/vip-red-envelope/config/info',
    method: 'post',
    data
  })
}

// 获取用户等级奖励信息
export function getAppmemberlevelAwardCollection(data) {
  return request({
    url: '/app-member-level/award/collection',
    method: 'post',
    data
  })
}

// 修改用户等级奖励信息
export function setAppmemberlevelAwardCollection(data) {
  return request({
    url: '/app-member-level/award/update',
    method: 'post',
    data
  })
}

// VIP红包领取记录
export function queryPageIssueAwardList(data) {
  return request({
    url: '/vip-red-envelope/queryPageIssueAwardList',
    method: 'post',
    data
  })
}

// 会员红包统计总量
export function queryIssueAwardStatistic(data) {
  return request({
    url: '/vip-red-envelope/queryIssueAwardStatistic',
    method: 'post',
    data
  })
}

// 借款审核列表
export function borrowLoanApprovalList(data) {
  return request({
    url: '/borrow/loan/approval/list',
    method: 'post',
    data
  })
}

// 借款审核
export function borrowLoanApproval(data) {
  return request({
    url: '/borrow/loan/approval',
    method: 'post',
    data
  })
}

// 借款记录列表
export function borrowLoanRecordList(data) {
  return request({
    url: '/borrow/loan/record/list',
    method: 'post',
    data
  })
}

// 查询借呗配置
export function borrowGetConfigDetail(params) {
  return request({
    url: '/borrow/get/config/detail',
    method: 'get',
    params
  })
}

// 编辑借呗配置
export function borrowEditConfig(data) {
  return request({
    url: '/borrow/edit/config',
    method: 'post',
    data
  })
}

// 还款记录列表
export function borrowRepayRecordList(data) {
  return request({
    url: '/borrow/repay/record/list',
    method: 'post',
    data
  })
}

// 借呗会员管理列表
export function borrowMemberConfigList(data) {
  return request({
    url: '/borrow/member/config/list',
    method: 'post',
    data
  })
}

// 修改借款额度接口
export function borrowUpdateMemberAmount(data) {
  return request({
    url: '/borrow/update/member/amount',
    method: 'post',
    data
  })
}

// 启禁用借款状态
export function borrowEnabledMemberStatus(data) {
  return request({
    url: '/borrow/enabled/member/status',
    method: 'post',
    data
  })
}

// 借款审核列表统计
export function borrowLoanApprovalCount(data) {
  return request({
    url: '/borrow/loan/approval/count',
    method: 'post',
    data
  })
}

// 借款记录列统计表
export function borrowLoanRecordCount(data) {
  return request({
    url: '/borrow/loan/record/count',
    method: 'post',
    data
  })
}

// 还款记录列表统计
export function borrowRepayRecordCount(data) {
  return request({
    url: '/borrow/repay/record/count',
    method: 'post',
    data
  })
}

// 获取用户借款信息
export function queryUserBorrowInfo(data) {
  return request({
    url: '/borrow/member/info',
    method: 'post',
    data
  })
}
