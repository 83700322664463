const CanChooseColumns = function () {
  return [
    {
      title: "跟单订单号",
      key: "orderNo",
      align: "center",
    },
    {
      title: "推单订单号",
      key: "guruOrderNo",
      align: "center",
    },
    {
      title: "会员账号",
      key: "memberName",
      align: "center",
    },
    {
      title: "昵称",
      key: "nickname",
      align: "center",
    },
    // {
    //   title: "账号类型",
    //   key: "memberType",
    //   align: "center",
    //   component: {
    //     render: (h, row) => {
    //       switch (row.memberType) {
    //         case 1:
    //           return <span>内部账号</span>;
    //         case 2:
    //           return <span>普通账号</span>;
    //         case 3:
    //           return <span>普通账号</span>;
    //         default:
    //           return <span>{row.memberType}</span>;
    //       }
    //     },
    //   },
    // },
    {
      title: "游戏类型",
      key: "computed_smallPlayType",
      align: "center",
    },
    {
      title: "方案宣言",
      key: "planSpeech",
      align: "center",
      width: 300,
      isRichText:true
    },

    {
      title: "跟单金额",
      key: "orderAmount",
      align: "center",
    },
    {
      title: "跟单中奖",
      key: "winAmount",
      align: "center",
    },
    {
      title: "跟单盈亏",
      key: "profitAmount",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span class={this.$parseRedOrGreen(row.profitAmount)}>
              {row.profitAmount}
            </span>
          );
        },
      },
    },
    {
      title: "佣金比例",
      key: "followerOrderRate",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>{row.followerOrderRate * 100}%</span>;
        },
      },
    },
    {
      title: "跟单佣金",
      key: "result",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span>
              {/* {Number(row.profitAmount)*Number(row.followerOrderRate)} */}
              {row.commission}
            </span>
          );
        },
      },
    },
    {
      title: "跟单结算",
      key: "followerSettlementAmount",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span class={this.$parseRedOrGreen(row.followerSettlementAmount)}>
              {row.followerSettlementAmount}
            </span>
          );
        },
      },
    },
    {
      title: "时间",
      key: "applyTime",
      align: "center",
      width: "300",
      component: {
        render: (h, row) => {
          return (
            <div>
              <div>发单时间：{this.$parseTime(row.createTime)}</div>
              <div>截止时间：{this.$parseTime(row.endTime)}</div>
              <div>结算时间：{this.$parseTime(row.settlementTime)}</div>
            </div>
          );
        },
      },
    },
    {
      title: "状态",
      key: "orderStatus",
      align: "center",
      width: 200,
    },
    {
      title: this.$t("操作"),
      key: "actions",
      align: "center",
      width: 100,
      fixed: "right",
      component: {
        render: (h, row) => {
          const { Check } = this.permission;
          return (
            <div>
              {Check && (
                <el-button
                  type="primary"
                  size="mini"
                  onClick={() => this.checkDetails(row)}
                >
                  查看详情
                </el-button>
              )}
            </div>
          );
        },
      },
    },
  ];
};

export default function (keys = []) {
  return CanChooseColumns.call(this);
}
