
import { ladderConfig, ladderOptions } from './components/utils'

const CanChooseColumns = function() {
  return [
    {
      title: '租户简称',
      key: 'tenantCode',
      align: 'center'
    },
    {
      title: '租户名称',
      key: 'tenantName',
      align: 'center'
    },
    {
      title: '租户组别',
      key: 'tagGroupName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-link type="primary" onClick={() => this.$refs.GroupNameDialogControl.open(row) }>{ row.tagGroupName || '无' }</el-link>)
        }
      }
    },
    {
      title: '代理模式',
      key: 'agencyType',
      align: 'center',
      component: {
        render: (h, row) => {
          let agencyType = ''
          if (row.agencyType) {
            const typeInfo = this.agencyTypes.find(p => p.key === row.agencyType)
            if (typeInfo) {
              agencyType = typeInfo.label
            }
          }
          return (<span>{ agencyType }</span>)
        }
      }
    },
    {
      title: '开户时间',
      key: 'openDate',
      align: 'center'
    },
    {
      title: '状态',
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.status === 0) {
            return (<el-tag type='success'>正常</el-tag>)
          } else {
            return (<el-tag type='danger'>停站</el-tag>)
          }
        }
      }
    },
    {
      title: '阶梯抽成比例',
      key: 'division',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <el-popover
              placement='bottom'
              width='600'
              trigger='click'
              popper-class='division-popper'>
              <el-row class='division'>
                {
                  ladderConfig.map(config => (
                    <el-col span={8}>
                      <p class='division-title'>{ config.label }</p>
                      <el-row>
                        <el-col span={12} class='division-line'>
                          <div class='division-title'>梯度区间</div>
                          {
                            ladderOptions.map(option => (
                              <div>{ row[`${option}Level${config.key}Charges`] }</div>
                            ))
                          }
                        </el-col>
                        <el-col span={12}>
                          <div class='division-title'>抽成比例</div>
                          {
                            ladderOptions.map(option => (
                              <div>{ row[`${option}Level${config.key}Percent`] }%</div>
                            ))
                          }
                        </el-col>
                      </el-row>
                    </el-col>
                  ))
                }
              </el-row>
              <el-link slot='reference' type='primary'>查看</el-link>
            </el-popover>
          )
        }
      }
    },
    {
      title: '美颜费用',
      key: 'beautyCost',
      align: 'center'
    },
    {
      title: '服务器费用',
      key: 'serverCost',
      align: 'center'
    },
    {
      title: '维护费用',
      key: 'maintenanceCost',
      align: 'center'
    },
    {
      title: '后台开关',
      key: 'blackStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.blackStatus}
              disable={!this.permission.BlackStatus}
              beforeChange={this._handleChangeBlackStatus.bind(this, row)}>
              <span slot='close'>关闭</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: '操作',
      align: 'center',
      key: 'actions',
      width: 280,
      component: {
        render: (h, row) => {
          const { ShareSet, FunctionSet, StartStop } = this.permission
          return (
            <span>
              { ShareSet && <el-button type='primary' size='mini' onClick={this.$refs.ConfigDialogControl.open.bind(this, row)}>抽成配置</el-button> }
              { FunctionSet && <el-button type='warning' size='mini' onClick={this.$refs.UpdateDialogControl.open.bind(this, row)}>功能配置</el-button> }
              {
                StartStop &&
                <el-button
                  type={row.status === 0 ? 'danger' : 'success'}
                  size='mini'
                  onClick={this._handleChangeStatus.bind(this, row)}>{ row.status === 0 ? '停站' : '恢复' }</el-button>
              }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return CanChooseColumns.call(this)
}
