var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "table-dialog",
      attrs: {
        title: _vm.title,
        width: "610px",
        top: "5vh",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _vm.row
        ? _c("table", [
            _c("colgroup", [_c("col", { attrs: { width: "165" } }), _c("col")]),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "is-right" }, [
                  _vm._v(_vm._s(_vm.$t("彩种")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.lotteryName))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "is-right" }, [
                  _vm._v(">" + _vm._s(_vm.$t("期号")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.issue))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "is-right" }, [
                  _vm._v(">" + _vm._s(_vm.$t("开奖号码")) + "："),
                ]),
                _c(
                  "td",
                  [
                    _c("LotteryNumber", {
                      attrs: {
                        result: _vm.row.lotteryNo,
                        "series-tag": _vm.row.seriesTag,
                        "lottery-id": _vm.row.lotteryId,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c("td", { staticClass: "is-right" }, [
                  _vm._v(">" + _vm._s(_vm.$t("玩法")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.playName))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "is-right" }, [
                  _vm._v(">" + _vm._s(_vm.$t("玩法分类")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.playClassName))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "is-right" }, [
                  _vm._v(">" + _vm._s(_vm.$t("内容")) + "："),
                ]),
                _c("td", [
                  _c("div", { staticStyle: { "line-height": "24px" } }, [
                    _vm._v(_vm._s(_vm.row.numbers.replace())),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "is-right" }, [
                  _vm._v(">" + _vm._s(_vm.$t("投注金额")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.amount))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "is-right" }, [
                  _vm._v(">" + _vm._s(_vm.$t("状态")) + "："),
                ]),
                _c(
                  "td",
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          type: _vm.getStatus(_vm.row.status).type,
                        },
                      },
                      [_vm._v(_vm._s(_vm.getStatus(_vm.row.status).label))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c("td", { staticClass: "is-right" }, [
                  _vm._v(">" + _vm._s(_vm.$t("中奖金额")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.winAmount))]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(">" + _vm._s(_vm.$t("关闭"))),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }