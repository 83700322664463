var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.wrapClasses },
    [
      _vm.seriesTag === "20"
        ? _vm._l(JSON.parse(_vm.result), function (l, i) {
            return _c("p", { key: i }, [_vm._v(_vm._s(l.replace(/,/g, "-")))])
          })
        : _vm._l(_vm.result.split(","), function (number, key) {
            return _c(
              "div",
              {
                key: key,
                staticClass: "number",
                class: [
                  (_vm.seriesTag === "12" || _vm.seriesTag === "13") &&
                    "number_" + parseInt(number),
                  "number_" + _vm.getLhcColor(number),
                ],
              },
              [_vm.seriesTag !== "13" ? [_vm._v(_vm._s(number))] : _vm._e()],
              2
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }