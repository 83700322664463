<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive>
        <component :is="activeName" ref="AliveComponent" />
      </keep-alive>
    </template>
  </div>
</template>
<script>
import IndexTab from './IndexTab'
import ConfigTab from './ConfigTab'
import AgencyUserTab from './AgencyUserTab'
import TransferTab from './TransferTab'
import { parentMixin } from '@/mixins/tab-items'

export default {
  components: {
    IndexTab,
    ConfigTab,
    AgencyUserTab,
    TransferTab
  },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      tabs: this.$t('agencyRebateDataTabs'),
      activeName: 'IndexTab'
    }
  }
}
</script>

