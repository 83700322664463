<template>
  <div class="filter-container-item datePicker">
    <span v-if="isRequired" class="requiredIcon">*</span>
    <el-date-picker
      v-model="timesVal"
      :clearable="!isRequired"
      type="datetimerange"
      style="width: 400px"
      range-separator="至"
      :value-format="isTimestamp ? 'timestamp' : 'yyyy-MM-dd HH:mm:ss'"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :default-time="['00:00:00', '23:59:59']"
    />
    <span v-if="isShortcutShow">
      <el-button
        class="filter-item"
        @click="handleSetTimes($event, 0, isTimestamp)"
        >今天</el-button
      >
      <el-button
        class="filter-item"
        @click="handleSetTimes($event, 1, isTimestamp)"
        >昨天</el-button
      >
      <el-button
        class="filter-item"
        @click="handleSetTimes($event, 7, isTimestamp)"
        >7天</el-button
      >
      <el-button
        class="filter-item"
        @click="handleSetTimes($event, 30, isTimestamp)"
        >本月</el-button
      >
      <el-button
        class="filter-item"
        @click="handleSetTimes($event, 60, isTimestamp)"
        >上月</el-button
      >
    </span>
  </div>
</template>

<script>

export default {
  name: "MyDatePicker",
  model: {
    prop: "value",
    event: "onTimeChange",
  },
  props: {
    isTimestamp: {
      default: true,
      type: Boolean,
    },
    value: {
      default:[]
    },
    isShortcutShow: {
      default: true,
      type: Boolean,
    },
    isRequired:{
      default: false,
      type: Boolean,
    }
  },
  computed: {
    timesVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("onTimeChange", val);
      },
    },
  },
  // mounted() {
  //   console.log('%c@@@@this.isTimestamp','background: gray; color: white; font-size: 16px;',this.isTimestamp);
  // },

  methods: {
    handleSetTimes(e, day = 0, isTimestamp = true) {
      let start = new Date();
      let end = new Date();
      const oneDayMilli = 3600 * 1000 * 24;
      const todayDayNum = new Date().getDay();
      switch (day) {
        case 0: // 今日
          start = this.$parseTime(start, "{y}-{m}-{d} 00:00:00");
          end = this.$parseTime(end, "{y}-{m}-{d} 23:59:59");
          break;
        case 1: // 昨日
          start = this.$parseTime(
            start.valueOf() - oneDayMilli,
            "{y}-{m}-{d} 00:00:00"
          );
          end = this.$parseTime(
            end.valueOf() - oneDayMilli,
            "{y}-{m}-{d} 23:59:59"
          );
          break;
        case 7: // 7天
          start = this.$parseTime(
            start.valueOf() - oneDayMilli * 6,
            "{y}-{m}-{d} 00:00:00"
          );
          end = this.$parseTime(end, "{y}-{m}-{d} 23:59:59");
          break;
        case 3: // 本周
          start = this.$parseTime(
            start.valueOf() - oneDayMilli * (todayDayNum - 1),
            "{y}-{m}-{d} 00:00:00"
          );
          end = this.$parseTime(end, "{y}-{m}-{d} 23:59:59");
          break;
        case 14: // 上周
          start = this.$parseTime(
            start.valueOf() - oneDayMilli * (todayDayNum + 6),
            "{y}-{m}-{d} 00:00:00"
          );
          end = this.$parseTime(
            end.valueOf() - oneDayMilli * todayDayNum,
            "{y}-{m}-{d} 23:59:59"
          );
          break;
        case 30: // 本月
          start = this.$parseTime(start, "{y}-{m}-01 00:00:00");
          end = this.$parseTime(end, "{y}-{m}-{d} 23:59:59");
          break;
        case 60: // 上月
          start.setDate(1);
          end.setDate(1);
          start = this.$parseTime(start - oneDayMilli, "{y}-{m}-01 00:00:00");
          end = this.$parseTime(end - oneDayMilli, "{y}-{m}-{d} 23:59:59");
          break;
      }
      if (isTimestamp) {
        start = new Date(start).getTime();
        end = new Date(end).getTime();
      }
      this.timesVal = [start, end];
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-container-item {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}
.datePicker {
  gap: 5px;
}
.requiredIcon{
  color:red;
  font-size: 20px;
  margin-right: -10px;
}
</style>
