<template>
  <el-dialog
    :title="emitName === 'create' ? '新建群公告' : '编辑群公告'"
    width="700px"
    top="8vh"
    :visible.sync="visible"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model.trim="ruleForm.title" maxlength="20" placeholder="请输入标题，最长20个字符" />
      </el-form-item>
      <el-form-item label="聊天室" prop="roomType">
        <el-radio-group v-model="ruleForm.roomType">
          <el-radio :label="0">全部</el-radio>
          <el-radio :label="1">聊天室类型</el-radio>
        </el-radio-group>
        <el-select v-if="ruleForm.roomType === 1" @change="handleChange" style="margin-left: 20px; width: 300px;" v-model="ruleForm.isRoom" placeholder="请选择聊天室类型">
          <el-option v-for="item in roomTypes" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.roomType === 1" label="适用范围" prop="roomSeries">
        <el-radio-group v-model="ruleForm.roomSeries">
          <el-radio :label="0">无限</el-radio>
          <el-radio :label="1">适用范围</el-radio>
        </el-radio-group>
        <el-select v-if="ruleForm.roomSeries === 1" :loading="loading" style="margin-left: 32px; width: 300px;" v-model="ruleForm.roomList" filterable multiple collapse-tags placeholder="请选择适用范围聊天室">
          <el-option v-for="item in roomList" :key="item.roomId" :label="item.roomName" :value="item.roomId" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('是否启用')" prop="enable">
        <el-radio-group v-model="ruleForm.enable">
          <el-radio :label="1">{{ $t('启用') }}</el-radio>
          <el-radio :label="0">{{ $t('关闭') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="公告详情" prop="content">
          <tinymce ref="tinymce" v-model="ruleForm.content" :height="200" />
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import FormDialogMixin from '@/mixins/form-dialog'
import { roomTypes } from '@/libs/options'
import chatRoomListMixin from './../mixins/chatRoomList'

const getDefaultForm = () => ({
  id: '',
  title: '',
  roomType: 0,
  roomSeries: 0,
  isRoom: '',
  roomList: [],
  enable: 1,
  content: ''
})

export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm), chatRoomListMixin],
  data() {
    return {
      emitName: 'create',
      loading: false,
      roomList: [],
      roomTypes,
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        roomType: [{ required: true, message: '请选择聊天室', trigger: 'change' }],
        isRoom: [{ required: true, message: '请选择适用范围', trigger: 'change' }],
        enable: [{ required: true, message: '请选择是否启用', trigger: 'change' }],
        content: [{ required: true, message: '请输入公告详情', trigger: 'blur' }]
      }
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.emitName = 'update'
        this.ruleForm.id = row.id
        this.ruleForm.title = row.title
        this.ruleForm.enable = row.enable
        if (row.isRoom === 0) {
          this.ruleForm.roomType = 0
        } else {
          this.ruleForm.roomType = 1
          this.ruleForm.isRoom = row.isRoom
          this.handleChangeRoomType()
        }
        this.ruleForm.roomSeries = row.roomSeries
        if (row.roomList) {
          this.ruleForm.roomList = row.roomList.split(',')
        }
        this.ruleForm.content = row.content
      }
      this.visible = true
    },
    handleChange() {
      this.ruleForm.roomList = []
      this.handleChangeRoomType()
    },
    async handleChangeRoomType () {
      if (this.ruleForm.isRoom) {
        this.loading = true
        this.roomList = await this._getChatRoomList({ roomType: this.ruleForm.isRoom }) || []
        this.loading = false
      } else {
        this.roomList = []
      }
    },
    handleSubmitBefor () {
      if (this.ruleForm.roomType === 1 && !this.ruleForm.isRoom) {
        return this.$message.error('请选择聊天室类型')
      }
      if (this.ruleForm.roomSeries === 1 && this.ruleForm.roomList.length === 0) {
        return this.$message.error('请选择适用范围聊天室')
      }
      this.handleSubmit()
    }
  }
}
</script>
