<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="70%"
    :title="title"
    @open="handleRefresh"
    @closed="onClosed"
  >
    <el-table
      v-loading="loading"
      :data="items"
      v-bind="options"
      style="height: 400px; overflow-y: auto;"
    >
      <el-table-column prop="ruleType" :label="$t('Expert_Plan.比赛类型')" align="center">
        <template slot-scope="{ row }">{{ row.ruleType === 1 ? $t('Expert_Plan.足球') : $t('Expert_Plan.篮球') }}</template>
      </el-table-column>
      <el-table-column prop="articleSchedules" :label="$t('Expert_Plan.过关方式')" align="center">
        <template slot-scope="{ row }">{{ row.articleSchedules === 1 ? $t('Expert_Plan.单场') : $t('Expert_Plan.串联') }}</template>
      </el-table-column>
      <el-table-column prop="goldnum" :label="$t('Expert_Plan.是否收费')" align="center">
        <template slot-scope="{ row }">{{ parseInt(row.goldnum) === 0 ? $t('Expert_Plan.免费') : row.goldnum }}</template>
      </el-table-column>
      <el-table-column prop="publishTime" :label="$t('发布时间')" align="center">
        <template slot-scope="{ row }">{{ row.publishTime | parseTime }}</template>
      </el-table-column>
      <el-table-column prop="articleStatus" :label="$t('状态')" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.articleStatus === 1" class="success-type">{{ $t('Expert_Plan.未审') }}</span>
          <span v-if="row.articleStatus === 2" class="info-type">{{ $t('通过') }}</span>
          <span v-if="row.articleStatus === 3" class="error-type">{{ $t('禁止') }}</span>
          <span v-if="row.articleStatus === 4" class="disable-type">{{ $t('取消') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="details" :label="$t('Expert_Plan.方案详情')" align="center">
        <template slot-scope="{ row }">
          <span class="primary-type cursor-pointer" @click="$refs.VisibleDialogControl.open(row, false)">{{ $t('Expert_Plan.方案详情') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="redBlack" :label="$t('Expert_Plan.结果')" align="center">
        <template slot-scope="{ row }">{{ row.redBlack === 1 ? $t('Expert_Plan.红') : $t('Expert_Plan.黑') }}</template>
      </el-table-column>
      <el-table-column prop="actions" :label="$t('操作')" align="center">
        <template slot-scope="{ row }">
          <el-button type="danger" size="mini" @click="_handleDelete(row)">{{ $t('删除') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <VisibleDialog ref="VisibleDialogControl" append-to-body />

  </el-dialog>
</template>
<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getArticleExperById, doDelExpertArticleById } from '@/api/expert'
import VisibleDialog from '@/views/expert/plan/VisibleDialog'
export default {
  components: { VisibleDialog },
  mixins: [VDialogMixin, TablePageMixin(getArticleExperById, false)],
  data: () => ({
    nickname: '',
    parameter: {},
    params: {
      currentPage: 1,
      pageSize: 10
    }
  }),
  computed: {
    title() {
      return `${this.$t('Member_Expert.ExpertTab.专家')} ${this.nickname} ${this.$t('Member_Expert.ExpertTab.历史推荐查询')}`
    }
  },
  methods: {
    open({ expertId, nickname, tenantCode }) {
      this.nickname = nickname
      this.parameter.id = expertId
      this.parameter.tenantCode = tenantCode
      this.visible = true
    },
    onClosed() {
      this.nickname = ''
      this.items = []
      this.parameter = {}
    },
    _handleDelete({ id, tenantCode }) {
      this.$confirm(this.$t('Member_Expert.ExpertTab.确定删除此方案'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        doDelExpertArticleById({
          id,
          tenantCode
        }).then(([data, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    implementationGetParams() {
      return { ...this.parameter }
    }
  }
}
</script>
