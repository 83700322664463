<template>
  <el-dialog
    :title="$t('新增用户详情')"
    :visible.sync="visible"
    width="70%"
    :close-on-click-modal="false"
    @open="handleRefresh"
    top="8vh"
    @closed="handleClosed"
  >
    <div v-loading="loading">
      <el-button plain type="primary" style="float: right; margin-bottom: 10px;" :loading="excelLoading" icon="el-icon-download" @click="_handleExport">{{ $t('导出Excel') }}</el-button>
      <el-table :data="items" v-bind="options" style="max-height: 60vh;overflow-y: auto">
        <el-table-column :label="$t('会员账号')" prop="memberName" align="center" />
        <el-table-column :label="$t('会员ID')" prop="memberId" align="center" />
        <el-table-column :label="$t('注册来源')" prop="registerResource" align="center" />
        <el-table-column :label="$t('注册域名')" prop="h5ReferDomain" align="center" />
        <el-table-column :label="$t('最后活跃时间')" prop="createTime" align="center">
          <template slot-scope="{ row }">{{ row.createTime | parseTime }}</template>
        </el-table-column>
      </el-table>
      <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    </div>
  </el-dialog>
</template>
<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import { newMemberPageList, channelNewMemberPageListDownload } from '@/api/statistical'
import { exportExcel } from '@/utils'
export default {
  mixins: [VDialogMixin, TablePageMixin(newMemberPageList, false)],
  data() {
    return {
      search: {},
      excelLoading: false
    }
  },
  methods: {
    open(row, { channelId, lineId }) {
      this.search.day = row.day
      this.search.channelId = channelId
      this.search.lineId = lineId
      this.visible = true
    },
    handleClosed() {
      this.items = []
      this.search = {}
    },
    implementationGetParams() {
      return { ...this.search }
    },
    _handleExport() {
      this.excelLoading = true
      channelNewMemberPageListDownload(this.implementationGetParams()).then(([_, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `渠道新增用户详情-${this.$parseTime(new Date())}.csv`)
        }
      }).finally(()=>{
        this.excelLoading = false
      })
    }
  }
}
</script>

