<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" @open="handleOpen" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" label-width="120px" label-suffix=":">
      <el-form-item
        :label="$t('会员账号')"
        prop="memberList"
        :rules="[
          { required: true, message: $t('请输入会员账号组'), trigger: 'blur' },
          { pattern: /^([a-zA-Z0-9]{4,20})+(,[a-zA-Z0-9]{4,20})*$/, message: $t('请输入正确的数据'), trigger: 'blur' }
        ]"
      >
        <el-input v-model="ruleForm.memberList" :autosize="{ minRows: 8, maxRows: 8}" type="textarea" :placeholder="$t('数据格式')" />
      </el-form-item>
      <template v-if="batchType === 0">
        <el-form-item :label="$t('会员等级')" prop="vipLevel" :rules="[{ required: true, message: $t('请选择VIP等级'), trigger: 'change' }]">
          <el-select v-model="ruleForm.vipLevel" :placeholder="$t('请选择会员等级')" style="width: 100%;">
            <el-option v-for="item in Levels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('锁定等级')" prop="vipStatus" :rules="[{ required: true, message: $t('请选择是否锁定等级'), trigger: 'change' }]">
          <el-radio-group v-model="ruleForm.vipStatus">
            <el-radio :label="1">{{ $t('锁定') }}</el-radio>
            <el-radio :label="0">{{ $t('不锁定') }}</el-radio>
          </el-radio-group>
          <p class="danger-type">{{ $t('锁定后') }}</p>
        </el-form-item>
      </template>
      <template v-else-if="batchType === 1">
        <el-form-item :label="$t('会员状态')" prop="freezeStatus" :rules="[{ required: true, message: $t('请选择会员状态'), trigger: 'change' }]">
          <el-radio-group v-model="ruleForm.freezeStatus">
            <el-radio :label="0">{{ $t('启用') }}</el-radio>
            <el-radio :label="1">{{ $t('停用') }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item :label="$t('新代理')" prop="agencyMemberName" :rules="[{ required: true, message: $t('请输入新代理'), trigger: 'blur' }]">
          <el-input v-model="ruleForm.agencyMemberName" />
        </el-form-item>
      </template>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getAllVipLevels } from '@/api/app'
import { $t } from '@/lang'

const getDefaultForm = () => ({
  memberList: ''
})

const typesArray = [
  { title: $t('会员等级'), name: 'update-level', key: 'level' },
  { title: $t('用户状态'), name: 'update-status', key: 'status' },
  { title: $t('上级代理'), name: 'update-agency', key: 'agency' }
]

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      batchType: 0,
      Levels: []
    }
  },
  computed: {
    title() {
      return `${this.$t('批量修改')}${typesArray[this.batchType].title}`
    },
    emitName() {
      return typesArray[this.batchType].name
    }
  },
  methods: {
    /**
     * @{Array} rows 需批量编辑的用户
     * @{Number} batchType 批量编辑类型 0:会员等级,1:用户状态,2:上级代理
     */
    open(rows, batchType) {
      if (Array.isArray(rows) && rows.length) {
        this.ruleForm.memberList = rows.map(o => o.memberName).join(',')
      }
      this.batchType = batchType
      this.visible = true
    },
    handleOpen() {
      if (this.batchType === 0) {
        getAllVipLevels().then(([data, err]) => {
          this.Levels = data
        })
      }
    }
  }
}
</script>
