<template>
  <el-dialog
    :title="$t('流播放功能')"
    :visible.sync="visible"
    width="480px"
    top="5vh"
    class="border-form-dialog"
    :close-on-click-modal="false"
    @open="onOpen"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      :show-message="false"
      label-suffix=":"
      label-width="160px"
    >
      <el-form-item :label="$t('流媒体地址')" prop="streamMediaUrl">
        <el-input v-model.number="ruleForm.streamMediaUrl" />
      </el-form-item>
      <el-form-item :label="$t('彩种')" prop="lotkindId">
        <el-select v-model="ruleForm.lotkindId" style="width: 100%">
          <el-option v-for="item in $parent.lotteryOptions" :key="item.lotteryId" :label="item.name" :value="item.lotteryId" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('频道')" prop="unionId">
        <el-checkbox-group v-model="ruleForm.unionId">
          <el-checkbox v-for="item in $parent.channelOptions" :key="item.unionId" :label="item.unionId">{{ item[`channelName${getLanguageKey()}`] }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!-- <el-form-item label="标签" prop="labelId">
        <el-select v-model="ruleForm.labelId" style="width: 100%">
          <el-option v-for="item in $parent.labelOptions" :key="item.labelId" :label="item.labelName" :value="item.labelId" />
        </el-select>
      </el-form-item> -->
      <el-form-item :label="$t('封面')" prop="cover">
        <el-upload
          class="thumbnail-uploader"
          accept="image/jpeg,image/png,image/jpg,image/webp"
          :action="action"
          :data="{ type: 'image', folder: 'ad-photo' }"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleThumbnailSuccess"
        >
          <img v-if="ruleForm.cover" :src="ruleForm.cover" class="thumbnail">
          <i v-else class="el-icon-plus thumbnail-uploader-icon" />
        </el-upload>
      </el-form-item>
    </el-form>
    <p style="font-size: 12px; line-height: 30px">{{ $t('下播后的主播，可通过后台填入流媒体地址进行虚拟开播，与正常开播效果相同') }}</p>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确认') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getRoomDetail } from '@/api/live'
import { getLanguageKey } from '@/lang/index'
const getDefaultForm = () => ({
  streamMediaUrl: '',
  lotkindId: '',
  unionId: '',
  // labelId: '',
  cover: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      loading: false,
      emitName: 'update',
      rules: {
        streamMediaUrl: [{ required: true, message: this.$t('请输入'), trigger: 'blur' }],
        lotkindId: [{ required: true, message: this.$t('请选择'), trigger: 'change' }],
        channelId: [{ required: true, message: this.$t('请选择'), trigger: 'change' }],
        // labelId: [{ required: true, message: '请选择', trigger: 'change' }],
        cover: [{ required: true, message: this.$t('请上传'), trigger: 'change' }]
      }
    }
  },
  computed: {

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    }
  },
  methods: {
    getLanguageKey,
    open(row) {
      this.ruleForm.roomId = row.roomId
      this.visible = true
    },
    onOpen() {
      this.loading = true
      getRoomDetail({ roomId: this.ruleForm.roomId }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.ruleForm = Object.assign({}, { ...data, unionId: (data.unionId || '').split(','), streamMediaUrl: data.clientAddress })
        }
      })
    },
    handleThumbnailSuccess(res) {
      this.ruleForm.cover = res.data[0]
      this.$refs.validateForm.validateField('cover')
    }
  }
}
</script>

<style lang="scss" scoped>

.thumbnail-uploader {

  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409EFF;
    }
  }
  &-icon {
    font-size: 30px;
    color: #8c939d;
    width: 150px;
    height: 264px;
    line-height: 264px;
    text-align: center;
  }

  .thumbnail {
    width: 150px;
    height: 264px;
    display: block;
  }
}

</style>
