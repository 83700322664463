<template>
  <el-dialog
    :title="title"
    width="800px"
    :visible.sync="visible"
    :close-on-click-modal="false"
    top="5vh"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" class="table-ruleForm">
      <el-form-item :label="$t('广告标题')" prop="title">
        <el-input v-model="ruleForm.title" maxlength="16" :placeholder="$t('请输入广告标题')" />
      </el-form-item>
      <el-form-item :label="$t('展示位置')" prop="position">
        <el-select v-model="ruleForm.position" @change="handleChangePostion" :placeholder="$t('请选择展示位置')" :style="{ width: '100%' }">
          <el-option
            v-for="item in $parent.positionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('展示时间')" prop="dateTime">
        <el-date-picker
          v-model="ruleForm.dateTime"
          :default-time="['00:00:00', '23:59:59']"
          type="datetimerange"
          :range-separator="$t('至')"
          :start-placeholder="$t('开始时间')"
          :end-placeholder="$t('结束时间')"
          :style="{ width: '100%' }"
        />
      </el-form-item>
      <el-form-item :label="$t('展示终端')" prop="displayTerminal">
        <el-checkbox-group v-model="ruleForm.displayTerminal">
          <el-checkbox
            v-for="item of terminalList"
            :key="item.attributeName"
            :label="item.attributeName"
            :style="{ 'vertical-align': 'top' }"
          >{{ item.label }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('是否启用')" prop="status">
        <el-radio-group v-model="ruleForm.status" :disabled="!$parent.permission.StartStop">
          <el-radio :label="1">{{ $t('启用') }}</el-radio>
          <el-radio :label="2">{{ $t('关闭') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('跳转方式')" prop="jumpType">
        <el-radio-group v-model="ruleForm.jumpType" @change="ruleForm.appPageId = []">
          <el-radio :label="1">{{ $t('链接') }}</el-radio>
          <el-radio :label="5">{{ $t('APP功能页') }}</el-radio>
          <!-- <el-radio :label="2">{{ $t('活动') }}</el-radio>
          <el-radio :label="3">{{ $t('任务') }}</el-radio> -->
          <el-radio :label="4">{{ $t('签到') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="ruleForm.jumpType === 1" :label="$t('跳转链接')">
        <el-input v-model="ruleForm.outUrl" :placeholder="$t('请输入跳转链接地址')" />
      </el-form-item>
      <el-form-item v-if="ruleForm.jumpType === 5" label="APP功能页" prop="appPageId">
        <el-cascader v-model="ruleForm.appPageId" :options="appPageList" @change="handleChangeAppPage" style="width: 300px" clearable />
      </el-form-item>
      <el-form-item :label="$t('语言')" prop="lang">
        <el-checkbox-group v-model="ruleForm.lang">
          <el-checkbox v-for="item of allLang" :key="item.lang" :label="item.lang">{{ $t(item.name) }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('广告详情')" prop="detailType">
        <el-radio-group v-model="ruleForm.detailType" :disabled="detailTypeDisabled">
          <el-radio :label="1">{{ $t('富文本') }}</el-radio>
          <el-radio :label="2">{{ $t('图片') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="ruleForm.detailType === 1" prop="richText">
        <tinymce ref="tinymce" v-model="ruleForm.richText" :height="250" />
      </el-form-item>
      <el-form-item v-show="ruleForm.detailType === 2" prop="pic">
        <SingleImage2 :value="ruleForm.pic" accept="image/jpeg,image/png,image/jpg,image/gif,image/svg" folder="ad-photo" :style="{ marginTop: '10px' }" @input="output" />
        <div> <i class="el-icon-warning" /> 提示：建议尺寸
          <template v-if="ruleForm.position == 8 || ruleForm.position == 9">
            108*35
          </template>
          <template v-else-if="[10,11,12,13].includes(ruleForm.position)">
            70*70
          </template> 
          <template v-else>
            358*70
          </template>
          ，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('关 闭') }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">{{ $t('保 存') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import SingleImage2 from '@/components/Upload/SingleImage2.vue'
import { getCpadActivityList, getActivityTypeList } from '@/api/activity'
import { terminalList } from './utils.js'
import { mapState } from 'vuex'
import { appPageList } from '@/libs/options'
import { getAppPageParams } from '@/libs/universal'
import { getLotteryNavigationHot } from '@/api/config'
import { getLiveRoomList } from "@/api/chat"
import Tinymce from '@/components/Tinymce'

const getDefaultForm = () => ({
  title: '',
  position: '',
  dateTime: [],
  displayTerminal: [],
  status: 1,
  pic: '',
  remark: '',
  jumpType: 1,
  appPageId: [],
  outUrl: '',
  lang: [],
  seriesTag: null,
  activityId: null,
  activityType: null,
  lotteryId: null,
  gameId: null,
  roomId: null,
  roomName: null,
  richText: '',
  detailType: 1
})

export default {
  components: { SingleImage2, Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      // activityOption: [],
      // activityTypes: [],
      terminalList,
      detailTypeDisabled: false,
      rules: {
        title: [{ required: true, message: this.$t('请输入', { text: this.$t('标题') }), trigger: 'blur' }],
        position: [{ required: true, message: this.$t('请选择', { text: this.$t('展示位置') }), trigger: 'change' }],
        dateTime: [{ type: 'array', required: true, message: this.$t('请选择', { text: this.$t('开始时间') }), trigger: 'change' }],
        jumpType: [{ required: true, message: this.$t('请选择', { text: this.$t('跳转方式') }), trigger: 'change' }],
        displayTerminal: [{ required: true, message: this.$t('请选择', { text: this.$t('展示终端') }), trigger: 'change' }],
        appPageId: [{ type: 'array', required: true, message: this.$t('请选择跳转'), trigger: 'change' }],
        lang: [{ required: true, message: this.$t('请选择语言'), trigger: 'change' }]
      },
      isLoadAppPage: false,
      appPageList
    }
  },
  computed: {
    ...mapState('app', ['allLang']),
    title() {
      return `${this.emitName === 'create' ? this.$t('新建') : this.$t('编辑')} ${this.$t('广告')}`
    }
  },
  async created() {
    await this.handleOpen()
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm.id = row.id
        this.ruleForm.sort = row.sort
        this.ruleForm.title = row.title
        this.ruleForm.position = row.position
        this.ruleForm.dateTime = [new Date(row.startDate), new Date(row.endDate)]
        this.ruleForm.status = row.status
        this.ruleForm.pic = row.pic
        this.ruleForm.remark = row.remark
        this.ruleForm.jumpType = row.jumpType
        this.ruleForm.seriesTag = row.seriesTag
        this.ruleForm.activityId = row.activityId
        this.ruleForm.activityType = row.activityType
        this.ruleForm.lotteryId = row.lotteryId
        this.ruleForm.gameId = row.gameId
        this.ruleForm.roomId = row.roomId
        this.ruleForm.roomName = row.roomName
        this.ruleForm.richText = row.richText
        this.ruleForm.detailType = row.detailType === 1 ? 1 : 2
        this.ruleForm.appPageId = []
        if (row.jumpType === 5) {
          this.ruleForm.appPageId = getAppPageParams(row.seriesTag, row.activityId, row.lotteryId, row.roomId)
        }
        this.ruleForm.outUrl = row.outUrl

        for (const item of terminalList) {
          if (row[item.attributeName] === 2) {
            this.ruleForm.displayTerminal.push(item.attributeName)
          }
        }
        if (row.lang) {
          this.ruleForm.lang = row.lang.split(',')
        }

        // this.onChangejumpType(row.jumpType, false)
        this.handleChangePostion()
        this.emitName = 'update'
      }
      this.visible = true
    },
    async handleOpen() {
      if (this.isLoadAppPage) return
      this.isLoadAppPage = true
      let list1 = []
      let list2 = []
      let list3 = []
      let list4 = []
      const [[items1, err1], [items2, err2], [items3, err3], [items4, err4]] = await Promise.all([getLotteryNavigationHot(), getCpadActivityList({ jumpType: 0, tenantCode: this.ruleForm.tenantCode }), getActivityTypeList({ currentPage: 1, pageSize: 100, isShow: 1 }), getLiveRoomList({ roomType: 1 })])
      if (!err1) {
        list1 = items1
      }
      if (!err2) {
        list2 = items2
      }
      if (!err3) {
        if (items3.length > 0) {
          const list = []
          items3.forEach(e => {
            const typeNameList = e.typeName.split(',')
            list.push({
              id: e.id,
              typeName: typeNameList[0].split(':')[1]
            })
          })
          list3 = list
        }
      }
      if (!err4) {
        list4 = items4
      }
      this.appPageList.forEach(e => {
        e.children.forEach(v => {
          const item = list1.find(r => r.seriesTag === v.value)
          if (item) {
            v.children = item.lotteryList.map(o => { 
              return {
                value: o.lotteryId,
                label: o.name,
                gameId: o.gameId,
                lotteryId: o.lotteryId
              }
            })
          }
          if (v.value === '9999002' && list2.length > 0) {
            v.children = list2.map(o => {
              return {
                value: o.id,
                label: o.activityTitle,
                activityId: o.id,
                activityType: o.jumpType
              }
            })
          }
          if (v.value === '9999014' && list3.length > 0) {
            v.children = list3.map(o => {
              return {
                value: o.id,
                label: o.typeName,
                activityId: o.id
              }
            })
          }
          if (v.value === '9999007' && list4.length > 0) {
            v.children = list4.map(o => {
              return {
                value: o.roomId,
                label: o.roomName,
                roomId: o.roomId,
                roomName: o.roomName
              }
            })
          }
          if (v.children && v.children.length === 0) {
            delete v.children
          }
        })
      })
    },
    output(v) {
      this.ruleForm.pic = v
      this.$refs.validateForm.validateField('pic')
    },
    handleChangePostion() {
      if (this.ruleForm.position) {
        if ([8, 9].includes(this.ruleForm.position)) {
          this.detailTypeDisabled = false
        } else {
          this.detailTypeDisabled = true
          if ([16, 18, 19, 20].includes(this.ruleForm.position)) {
            this.ruleForm.detailType = 1
          } else {
            this.ruleForm.detailType = 2
          }
        }
      }
    },
    handleChangeAppPage(pageId) {
      this.ruleForm.seriesTag = null
      this.ruleForm.lotteryId = null
      this.ruleForm.gameId = null
      this.ruleForm.activityId = null
      this.ruleForm.activityType = null
      this.ruleForm.roomId = null
      this.ruleForm.roomName = null
      if (pageId.length >= 2) {
        this.ruleForm.seriesTag = pageId[1]
      }
      if (pageId.length >= 3) {
        const node1 = this.appPageList.find(e => e.value === pageId[0])
        if (node1 && node1.children) {
          const node2 = node1.children.find(e => e.value === pageId[1])
          if (node2 && node2.children) {
            const node3 = node2.children.find(e => e.value === pageId[2])
            if (node3) {
              this.ruleForm.lotteryId = node3.lotteryId || null
              this.ruleForm.gameId = node3.gameId || null
              this.ruleForm.activityId = node3.activityId || null
              this.ruleForm.activityType = node3.activityType || null
              this.ruleForm.roomId = node3.roomId || null
              this.ruleForm.roomName = node3.roomName || null
            }
          }
        }
      }
    },
    handleSubmitBefor() {
      if (this.ruleForm.detailType === 1 && this.ruleForm.richText === '') {
        return this.$message.error('请输入广告详情')
      }
      if (this.ruleForm.detailType === 2 && this.ruleForm.pic === '') {
        return this.$message.error('请上传广告详情图片')
      }
      this.handleSubmit()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-radio {
  margin-right: 20px;
}
</style>
