<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <el-select v-model="search.groupId" clearable :placeholder="$t('组别')" class="filter-item" style="width: 140px">
          <el-option v-for="item in groupList" :key="item.id" :label="item.groupName" :value="item.id" />
        </el-select>
        <el-input v-model="search.operatorName" clearable :placeholder="$t('账号')" class="filter-item" style="width: 140px" />
        <el-input v-model="search.createUser" clearable :placeholder="$t('创建人')" class="filter-item" style="width: 140px" />
        <el-input v-model="search.updateUser" clearable :placeholder="$t('修改人')" class="filter-item" style="width: 140px" />
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
        <el-button v-if="permission.Add" class="filter-item" type="success" icon="el-icon-plus" @click="onCreate">
          {{ $t('添加账号') }}
        </el-button>
      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleSearch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <CreateDialog ref="CreateDialogControl" @create="handleAddOperator" />
    <UpdateDialog ref="UpdateDialogControl" @update="handleUpdateOperator" />
    <RemarkDialog ref="RemarkDialogControl" @update="handleUpdateRemark" />
    <ValidGoogleVisible ref="ValidGoogleVisibleControl" />

  </div>
</template>
<script>
import { getOperatorList, addOperator, updateOperator, updateOperatorStatus, addOperatorRemark, getGroupList, checkGoogleKey } from '@/api/permission'
import TablePageMixin from '@/mixins/table-page-mixin'
import { CreateDialog, UpdateDialog } from './components'
import RemarkDialog from '@/views/components/RemarkDialog'
import ValidGoogleVisible from '@/views/components/ValidGoogleVisible'
import GetColumns from './columns'

export default {
  name: 'PermissionUsers',
  components: {
    UpdateDialog,
    CreateDialog,
    RemarkDialog,
    ValidGoogleVisible
  },
  mixins: [TablePageMixin(getOperatorList)],
  data() {
    return {
      search: {},
      groupList: [],
      columns: [],
      defaultColumns: GetColumns.call(this)
    }
  },

  mounted() {
    this.fetchGroupList()
  },

  methods: {
    onCreate() {
      this.$refs.CreateDialogControl.open()
    },

    fetchGroupList() {
      getGroupList({ currentPage: 1, pageSize: 100 }).then(([data, err]) => {
        if (!err) {
          this.groupList = data || []
        }
      })
    },

    handleAddOperator({ field, cancel, close }) { // 新增账号
      delete field.checkPassword
      addOperator({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
          this.$refs.ValidGoogleVisibleControl.open(data)
        } else {
          cancel()
        }
      })
    },

    handleUpdateOperator({ field, cancel, close }) { // 编辑账号
      updateOperator({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.handleRefresh()
          this.$refs.ValidGoogleVisibleControl.open(data)
        } else {
          cancel()
        }
      })
    },

    handleChangeStatus({ operatorId, operatorName, status }) {
      return new Promise((resolve) => {
        this.$confirm(status === 1 ? this.$t('确认启用此账户') : this.$t('确认冻结此账户'), this.$t('系统提示'), { type: 'warning' }).then(() => {
          updateOperatorStatus({
            operatorId,
            operatorName,
            status: ~status + 2
          }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          }).catch(() => {})
        })
      })
    },

    handleUpdateRemark({ field, cancel, close }) {
      addOperatorRemark({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
