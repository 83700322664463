import Layout from '@/layout'

export default {
  path: '/Card',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Card',
  alwaysShow: true,
  meta: { title: '任务管理', icon: 'finance' },
  children: [
    {
      path: '/Card_Task',
      component: () => import('@/views/card'),
      name: 'CardTask',
      meta: { title: '卡券管理', noCache: true }
    }

  ]
}

