var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "" + _vm.$t("批量提现") + _vm.okText,
        visible: _vm.visible,
        width: "420px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: { model: _vm.ruleForm, "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("操作说明") } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3 },
                model: {
                  value: _vm.ruleForm.notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "notes", $$v)
                  },
                  expression: "ruleForm.notes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.okText) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }