<template>
  <el-dialog
    title="数据读取配置"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" v-loading="loading" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="滚球" prop="gunqiu">
        <el-radio v-model="ruleForm.gunqiu" :label="0">手动配置（不配置不显示）</el-radio>
        <el-radio v-model="ruleForm.gunqiu" :label="1">自动获取（优先手动，无数据读取三方数据）</el-radio>
      </el-form-item>
      <el-form-item label="精选" prop="jingxuan">
        <el-radio v-model="ruleForm.jingxuan" :label="0">手动配置（不配置不显示）</el-radio>
        <el-radio v-model="ruleForm.jingxuan" :label="1">自动获取（优先手动，无数据读取三方数据）</el-radio>
      </el-form-item>
      <el-form-item label="即将" prop="jijiang">
        <el-radio v-model="ruleForm.jijiang" :label="0">手动配置（不配置不显示）</el-radio>
        <el-radio v-model="ruleForm.jijiang" :label="1">自动获取（优先手动，无数据读取三方数据）</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { sportconfigList } from '@/api/match'

const getDefaultForm = () => ({
  gunqiu: 0,
  jingxuan: 0,
  jijiang: 0
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      loading: true,
      rules: {
        gunqiu: [{ required: true, message: '请选择滚球配置', trigger: 'change' }],
        jingxuan: [{ required: true, message: '请选择精选配置', trigger: 'change' }],
        jijiang: [{ required: true, message: '请选择即将配置', trigger: 'change' }]
      }
    }
  },
  methods: {
    open() {
      this.loading = true
      sportconfigList().then(([items, err]) => {
        if (!err) {
          this.ruleForm.gunqiu = items.find(e => e.configKey === 'gunqiu')?.configValue
          this.ruleForm.jingxuan = items.find(e => e.configKey === 'jingxuan')?.configValue
          this.ruleForm.jijiang = items.find(e => e.configKey === 'jijiang')?.configValue
        }
      }).finally(() => {
        this.loading = false
      })
      this.visible = true
    }
  }
}
</script>
