<template>
  <div :class="{fullscreen:fullscreen}" class="tinymce-container" :style="{width:containerWidth}">
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <div v-if="disabled" class="tinymce-disabled" />
  </div>
</template>

<script>
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
import plugins from './plugins'
import toolbar from './toolbar'
// import load from './dynamicLoadScript'
import { getToken } from '@/utils/auth'

// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
// const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.5/tinymce.min.js'

export default {
  name: 'Tinymce',
  props: {
    id: {
      type: String,
      default: function() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: [Array, String],
      required: false,
      default: ''
    },
    menubar: {
      type: String,
      default: ''
    },
    statusbar: {
      type: Boolean,
      default: false
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    },
    maxlength: {
      type: Number,
      default: 500
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        'en': 'en',
        'zh': 'zh_CN',
        'es': 'es_MX',
        'ja': 'ja'
      }
    }
  },
  computed: {
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) { // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    }
  },
  watch: {
    value(val) {
      if (val === '' || val === null) {
        this.hasChange = false
      }
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    }
  },
  mounted() {
    this.init()
  },
  // activated() {
  //   if (window.tinymce) {
  //     this.initTinymce()
  //   }
  // },
  // deactivated() {
  //   this.destroyTinymce()
  // },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    init() {
      // dynamic load tinymce from cdn
      // load(tinymceCDN, (err) => {
      //   if (err) {
      //     this.$message.error(err.message)
      //     return
      //   }
      //   this.initTinymce()
      // })
      this.initTinymce()
    },
    initTinymce() {
      const _this = this
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        language: this.languageTypeList['zh'],
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar ? this.toolbar : toolbar,
        toolbar_mode: 'floating',
        menubar: this.menubar,
        statusbar: this.statusbar,
        plugins,
        paste_data_images: true,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        default_link_target: '_blank',
        link_title: false,
        ax_wordlimit_num: this.maxlength,
        paste_as_text: false,
        paste_enable_default_filters: false,
        paste_remove_styles_if_webkit: false,
        ax_wordlimit_callback: (editor, txt, num) => {
          this.$message.error('当前字数：' + txt.length + '，限制字数：' + num)
        },
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: editor => {
          if (_this.value) {
            editor.setContent(_this.value)
          }
          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            if ((this.value === '' || this.value === null) && !this.hasChange) {
              this.hasChange = false
            } else {
              this.hasChange = true
            }
            this.$emit('input', editor.getContent())
          })
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
          editor.on('focus', (e) => {
            _this.hasChange = true
          })
        },
        images_upload_handler: (blobInfo, success, failure, progress) => {
          var xhr, formData
          xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_FILE_UPLOAD}`)
          xhr.setRequestHeader('X-AUTH-TOKEN', getToken())
          xhr.setRequestHeader('X-TIMESTAMP', ((new Date()).getTime()))
          xhr.setRequestHeader('Request-Encrypt', false)
          xhr.setRequestHeader('X-TENANT-CODE', this.$store.getters.tenantCode)
          xhr.upload.onprogress = function(e) {
            progress(e.loaded / e.total * 100)
          }
          xhr.onload = function() {
            var json
            if (xhr.code < 200 || xhr.code >= 300) {
              failure('HTTP Error: ' + xhr.status)
              return
            }
            json = JSON.parse(xhr.responseText)
            if (!json || json.code !== '200') {
              failure(json ? json.msg : '上传失败!')
              return
            }
            success(json.data[0])
          }
          xhr.onerror = function() {
            failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status)
          }
          formData = new FormData()
          formData.append('type', 'image')
          formData.append('folder', 'chat-image')
          formData.append('file', blobInfo.blob(), blobInfo.filename())
          xhr.send(formData)
        }
      })
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    }
  }
}
</script>

<style lang="scss">
.tinymce-container{
  position: relative;
  overflow: hidden;
  line-height: normal;
  .mce-container {
    box-sizing: inherit;
  }
  .mce-tinymce {
    border-color: #f3f3f3;
    border-radius: 6px;
  }
  .mce-branding {
    display: none;
  }
  .mce-ico {
    font-size: 14px;
  }
  .mce-toolbar-grp {
    padding: 8px;
    background-color: #f2f2f2;
  }
  p{ width: 100%; overflow: hidden;
    img{ max-width: 300px !important; }
    ol, li{ margin: 0; padding: 0;}
  }
  .mce-toolbar {
    .mce-btn-group {
      border: 1px solid #d9d9d9;
      margin: 0 4px;
      padding: 0;
      .mce-btn {
        margin: 0;
        border: none;
        &+.mce-btn {
          border-left: 1px solid #d9d9d9;
        }
      }
    }
  }

  .tinymce-textarea {
    visibility: hidden;
    z-index: -1;
  }

  .tinymce-disabled {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #ccc;
    opacity: 0.2;
  }
}

.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}
.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}
.editor-upload-btn {
  display: inline-block;
}
</style>
