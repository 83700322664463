<template>
  <el-dialog title="Google验证" width="560px" :visible.sync="visible" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" @opened="handleOpened" @closed="handleClosed">
    <el-form label-width="120px" label-suffix=":">
      <el-form-item label="二维码">
        <div v-if="ruleForm.googleQrcode" id="qrcode" />
      </el-form-item>
      <el-form-item label="密钥">
        <p>{{ ruleForm.googleKey }}</p>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="close">
        确认
      </el-button>
    </div>
  </el-dialog>
</template>
<script>

export default {
  data() {
    return {
      visible: false,
      qrcode: null,
      ruleForm: {
        googleQrcode: '',
        googleKey: ''
      }
    }
  },
  methods: {
    open({ googleQrcode, googleKey }) {
      this.ruleForm.googleKey = googleKey
      this.ruleForm.googleQrcode = googleQrcode
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleOpened() {
      const QRCode = window.QRCode
      this.qrcode = new QRCode(document.getElementById('qrcode'), {
        text: this.ruleForm.googleQrcode,
        width: 300,
        height: 300
      })
    },
    handleClosed() {
      this.ruleForm.googleQrcode = ''
      this.ruleForm.googleKey = ''
      this.qrcode.clear()
    }
  }
}
</script>
