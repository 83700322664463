import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('发送类型'),
      key: 'transType',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.defaultSendType[row.transType] }</span>)
        }
      }
    },
    // {
    //   title: this.$t('状态'),
    //   key: 'sendResult',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (<span>{ this.defaultStatus[row.sendResult] }</span>)
    //     }
    //   }
    // },
    {
      title: this.$t('短信商'),
      key: 'smsChannel',
      align: 'center'
    },
    {
      title: this.$t('行动电话'),
      key: 'phone',
      align: 'center'
    },
    {
      title: this.$t('简讯码'),
      key: 'captcha',
      align: 'center',
      width: 120
    },
    {
      title: this.$t('发送时间'),
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: this.$t('发送IP地址'),
      key: 'ip',
      align: 'center'
    },
    {
      title: this.$t('发送结果'),
      key: 'sendResultDesc',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$t(row.sendResultDesc) }</span>)
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
