export default [
  {
    'id': 1,
    'name': 'USD',
    'icon': 'https://static.lt9b.com/ad-photo/834f7c5c23a54951b630938de0c31864.png',
    'curryIcon': 'https://static.lt9b.com/ad-photo/834f7c5c23a54951b630938de0c31864.png'
  },
  {
    'id': 2,
    'name': 'CNY',
    'icon': 'https://static.lt9b.com/ad-photo/72dc2f9a28194d9fb98c203277206ae8.png',
    'curryIcon': 'https://static.lt9b.com/ad-photo/72dc2f9a28194d9fb98c203277206ae8.png'
  },
  {
    'id': 3,
    'name': 'USDT',
    'icon': 'https://static.lt9b.com/ad-photo/c705343860ec4d0cb324036bd3ea39ae.png',
    'curryIcon': 'https://static.lt9b.com/ad-photo/c705343860ec4d0cb324036bd3ea39ae.png'
  },
  {
    'id': 4,
    'name': 'VND',
    'icon': 'https://static.lt9b.com/ad-photo/5681e76481d54930aa1c0cf8fa8fccaf.png',
    'curryIcon': 'https://static.lt9b.com/ad-photo/5681e76481d54930aa1c0cf8fa8fccaf.png'
  },
  {
    'id': 5,
    'name': 'INR',
    'icon': 'https://static.lt9b.com/ad-photo/54fe854ec63e4bd58cfc77a1121787f4.png',
    'curryIcon': 'https://static.lt9b.com/ad-photo/54fe854ec63e4bd58cfc77a1121787f4.png'
  },
  {
    'id': 6,
    'name': 'JPY',
    'icon': 'https://static.lt9b.com/ad-photo/8e255938cfeb4425a8f2cfd79ffb87ba.png',
    'curryIcon': 'https://static.lt9b.com/ad-photo/8e255938cfeb4425a8f2cfd79ffb87ba.png'
  },
  {
    'id': 7,
    'name': 'KRW',
    'icon': 'https://static.lt9b.com/ad-photo/c04d069824f54b8cb792b10d3b4a4959.png',
    'curryIcon': 'https://static.lt9b.com/ad-photo/c04d069824f54b8cb792b10d3b4a4959.png'
  },
  {
    'id': 8,
    'name': 'HKD',
    'icon': 'https://static.lt9b.com/ad-photo/4a88fe9e366f41c2a6882d04e595bd36.png',
    'curryIcon': 'https://static.lt9b.com/ad-photo/4a88fe9e366f41c2a6882d04e595bd36.png'
  },
  {
    'id': 9,
    'name': 'MYR',
    'icon': 'https://static.lt9b.com/ad-photo/74a0798f068e4d2095ab55b782b7f50f.png',
    'curryIcon': 'https://static.lt9b.com/ad-photo/74a0798f068e4d2095ab55b782b7f50f.png'
  }
]
