<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <div class="text">会员账号:</div>
        <el-input
          v-model="search.memberName"
          class="filter-item"
          placeholder="请输入会员账号查询"
          style="width: 160px"
        />
        <div class="text">推单订单号:</div>
        <el-input
          v-model="search.orderNo"
          class="filter-item"
          placeholder="请输入推单订单号查询"
          style="width: 160px"
        />
        <div>账号类型:</div>
        <el-select
          v-model="search.memberType"
          class="filter-item"
          placeholder="选择类型"
          style="width: 140px"
          default
          clearable
        >
          <el-option label="全部" :value="null" />
          <el-option label="内部账号" :value="[1]" />
          <el-option label="普通账号" :value="[2, 3]" />
        </el-select>
        <div>游戏类型:</div>
        <el-select
          v-model="search.smallPlayType"
          class="filter-item"
          placeholder="选择类型"
          style="width: 140px"
          clearable
        >
          <el-option label="全部" :value="null" />
          <el-option
            v-for="(item, index) in smallPlayType"
            :label="item.label"
            :value="item.value"
            :key="index"
          />
        </el-select>
        <div>状态:</div>
        <el-select
          v-model="search.orderStatus"
          class="filter-item"
          placeholder="选择类型"
          style="width: 140px"
          clearable
        >
          <el-option label="全部" :value="null" />
          <el-option label="待确认" :value="0" />
          <el-option label="待开奖" :value="1" />
          <el-option label="已盈利" :value="2" />
          <el-option label="未盈利" :value="3" />
          <el-option label="已撤单" :value="4" />
        </el-select>
      </div>
    </div>
    <div class="filter-container">
      <MyDatePicker v-model="times" :handleSetTimes="handleSetTimes" />
    </div>
    <div class="filter-container buttonLine">
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleSearch"
      >
        查询
      </el-button>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-refresh-right"
        @click="resetSearch"
        >{{ $t("重置") }}</el-button
      >
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="computed_items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />
    <!-- 弹窗 -->
    <el-dialog v-if="isCheckDetailsShow" fullscreen visible>
      <DetailsPage
      :rowData="rowData"
      @close="closeDetailsPage"
    ></DetailsPage>
    </el-dialog>
   
  </div>
</template>
<script>
import DetailsPage from "./DetailsPage.vue";
import TablePageMixin from "@/mixins/table-page-mixin";
import GetColumns from "./columns";
import { getRecList } from "@/api/orderManage";
import { parseTime } from "@/utils";
import MyDatePicker from "@/components/MyDatePicker";
import { getDefaultTimes,dealNumber } from "@/utils";
export default {
  name: "RecList",
  componentName: "RecList",
  components: {
    MyDatePicker,
    DetailsPage,
  },
  mixins: [TablePageMixin(getRecList)],
  data() {
    return {
      dealNumber,
      smallPlayType: this.$t("smallPlayType"),
      times: getDefaultTimes("timestamp", 0, 0, true),
      columns: GetColumns.call(this),
      filterColumn: false,
      selection: [],
      tableData: {
        pageSize: 0,
      },
      search: {},
      dialogFormVisible: false,
      dialogType: null,
      dialogForm: {
        applyDesc: "",
      },
      isCheckDetailsShow: false,
      rowData: {},
    };
  },
  computed: {
    isFollowList() {
      return this.$route.path == "/OrderManage_FollowList";
    },
    dialogText() {
      switch (this.dialogType) {
        case 1:
          return "确认审核通过吗？";
        case 2:
          return "确认审核拒绝吗？";
        case 3:
          return "确认取消大神推单资格吗？";
        default:
          break;
      }
    },
    dialogText2() {
      switch (this.dialogType) {
        case 2:
          return "拒绝原因";
        case 3:
          return "取消原因";
        default:
          break;
      }
    },
    computed_items() {
      let arr = this.items.map((row) => {
        switch (row.memberType) {
          case 1:
            row.memberType = "内部账号";
            break;
          case 2:
          case 3:
            row.memberType = "普通账号";
            break;
          default:
            break;
        }
        switch (row.smallPlayType) {
          case 40:
            row.computed_smallPlayType = "香港六合彩";
            break;
          case 41:
            row.computed_smallPlayType = "新澳门六合彩";
            break;
          case 99:
            row.computed_smallPlayType = "竞彩足球";
            break;
          case 1502:
            row.computed_smallPlayType = "滚球";
            break;
          default:
          row.computed_smallPlayType = row.smallPlayType;
            break;
        }
        switch (row.orderStatus) {
          case 0:
            row.orderStatus = "待确认";
            break;
          case 1:
            row.orderStatus = "待开奖";
            break;
          case 2:
            row.orderStatus = "已盈利";
            break;
          case 3:
            row.orderStatus = "未盈利";
            break;
          case 4:
            row.orderStatus = "已撤单";
            break;
          default:
            break;
        }
        return row;
      });
      return arr;
    },
  },
  methods: {
    implementationGetParams() {
      return this.formatIntervalTime(false, "beginTime", "endTime");
    },
    closeDetailsPage() {
      this.isCheckDetailsShow = false;
    },
    checkDetails(row) {
      this.rowData = row;
      this.isCheckDetailsShow = true;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.filter-container-item {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  font-size:12px;
  div {
    flex-shrink: 0;
  }
}
.buttonLine {
  display: flex;
  /* justify-content: end; */
}
</style>
