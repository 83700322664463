const CanChooseColumns = function() {
  return [
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <p class='primary-type cursor-pointer hover-underline' onClick={this._onDetails.bind(this, row)}>{ row.memberName }</p>
          )
        }
      }
    },
    {
      title: this.$t('会员昵称'),
      key: 'memberNickName',
      align: 'center'
    },
    {
      title: this.$t('彩种'),
      key: 'lotteryName',
      align: 'center'
    },
    {
      title: this.$t('投注金额'),
      key: 'amount',
      align: 'center'
    },
    {
      title: this.$t('中奖金额'),
      key: 'winAmount',
      sortable: 'custom',
      align: 'center'
    },
    {
      title: this.$t('待开奖金额'),
      key: 'willAmount',
      sortable: 'custom',
      align: 'center'
    },
    {
      title: this.$t('用户盈亏'),
      key: 'profit',
      sortable: 'custom',
      align: 'center'
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
