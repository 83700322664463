import { filterColumns } from '@/utils'
import { validURL } from '@/utils/validate'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('VIP等级'),
      key: 'vipLevel',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>VIP{row.vipLevel}</div>
          )
        }
      }
    },
    {
      title: this.$t('VIP福利'),
      key: 'upgradeLevelMinPlayAmount',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              {(row.awardList||[]).map(item=>{
                return <div>{item.name}</div>
              })}
            </div>
          )
        }
      }
    },
    {
      title: this.$t('VIP福利详情'),
      key: 'keepLevelMinPlayAmount',
      align: 'center',
      minWidth: 360,
      component: {
        render: (h, row) => {
          
          return (
            <div>
              {(row.awardList||[]).map(item=>{
             
                let res;
               
                try {
                  res=JSON.parse(item.content)
                } catch (error) {
                  console.log("JSON.parse",error);
                }
                return <div>{item.name} {item.awardCode==='BIRTHDAY_RED_ENVELOPE'&&!res.open?"暂无": res?.value || '暂无'}{this.setUnit(item)}</div>
              })}
            </div>
          )
        }
      }
      
    },
    
    {
      title: this.$t('最后操作时间'),
      key: 'updateTime',
      align: 'center',
      minWidth: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作员'),
      key: 'operatorName',
      align: 'center',
      minWidth: 160,
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      minWidth: 200,
      component: {
        render: (h, row) => {
          // const { Update, Delete } = this.permission
          return (
            <span>
              {  <el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={() => this.$refs.ActionDialogControl.open(row)}>{this.$t('编辑')}</el-button> }
              {/* {  <el-buxtton type='danger' size='mini' icon='el-icon-delete' onClick={this.handleDeleteAccount.bind(this, row.id)}>{this.$t('删除')}</el-button> } */}
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
