const CanChooseColumns = function() {
  return [
    {
      title: this.$t('任务ID'),
      key: 'id',
      align: 'center'
    },
    {
      title: this.$t('任务分类'),
      key: 'activityType',
      align: 'center',
      component: {
        render: (h, row) => {
          const activityType = this.activityTypeList.find(e => e.id === row.activityType)
          if (activityType) {
            const vnodes = []
            activityType.typeNameLang.split(',').forEach(e => {
              const typeLang = e.split(':')[0]
              const typeName = e.split(':')[1]
              const lang = this.allLang.find(v => v.lang === typeLang)
              if (lang) {
                vnodes.push(<div>{ this.$t(lang.name) + ':' + typeName }</div>)
              }
            })
            return (<span>{ vnodes }</span>)
          }
          return ''
        }
      }
    },
    {
      title: this.$t('任务标题'),
      key: 'activityTitle',
      align: 'center',
      component: {
        render: (h, row) => {
          const vnodes = []
          row.activityTitle.split(',').forEach(e => {
            const titleLang = e.split(':')[0]
            const activityTitle = e.split(':')[1]
            const lang = this.allLang.find(v => v.lang === titleLang)
            if (lang) {
              vnodes.push(<div>{ this.$t(lang.name) + ':' + activityTitle }</div>)
            }
          })
          return (<span>{ vnodes }</span>)
        }
      }
    },
    {
      title: this.$t('任务时间'),
      key: 'activityBeginDate',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          if (row.activityBeginDate + 4 * 365 * 24 * 60 * 60 * 1000 === row.activityEndDate) {
            return (<span>持续开启</span>)
          } else {
            return (<span>{ this.$parseTime(row.activityBeginDate, '{y}-{m}-{d}') + ' ~ ' + this.$parseTime(row.activityEndDate, '{y}-{m}-{d}') }</span>)
          }
        }
      }
    },
    {
      title: this.$t('状态'),
      key: 'activityStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          const activityStatus = this.$t('taskStatus').find(e => e.key === row.activityStatus)
          return (<span>{ activityStatus?.label }</span>)
        }
      }
    },
    {
      title: this.$t('任务内容'),
      key: 'isDaily',
      align: 'center',
      component: {
        render: (h, row) => {
          const isDaily = this.$t('taskType').find(e => e.key === row.isDaily)
          const modeType = this.$t('taskMethod').find(e => e.key === row.modeType)
          if (isDaily && modeType) {
            return (
              <div>
                <div>{ isDaily.label + '-' + modeType.label }</div>
                <el-link type='primary' onClick={ () => this.$refs.DetailDialogControl.open(row.id) }>{ this.$t('查看') }/{ this.$t('编辑内容') }</el-link>
              </div>
            )
          }
          return (<el-link type='primary' onClick={ () => this.$refs.DetailDialogControl.open(row.id) }>{ this.$t('查看') }/{ this.$t('编辑内容') }</el-link>)
        }
      }
    },
    {
      title: this.$t('排序'),
      key: 'sort',
      align: 'center'
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 270,
      component: {
        render: (h, row) => {
          const { Update, Start, End } = this.permission
          return (
            <div>
              { Start && row.activityStatus === 0 && <el-button type='success' size='small' icon='el-icon-check' onClick={this.handleCloseActivity.bind(this, row)}>{ this.$t('开启') }</el-button> }
              { End && row.activityStatus === 1 && <el-button type='danger' size='small' icon='el-icon-close' onClick={this.handleCloseActivity.bind(this, row)}>{ this.$t('结束') }</el-button> }
              { Update && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.ActionDialogControl.open(row) } }>{ this.$t('编辑') }</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
