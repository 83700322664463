const CanChooseColumns = function() {
  return [
    {
      title: '订单号',
      key: 'id',
      align: 'center'
    },
    {
      title: '用户账号',
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('商品封面'),
      key: 'productCover',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image src={ row.productCover } lazy></el-image>)
        }
      }
    },
    {
      title: this.$t('商品名称'),
      key: 'productName',
      align: 'center',
      width: 280,
    },
    {
      title: this.$t('商品价格（积分）'),
      key: 'productPrice',
      align: 'center',
    },
    {
      title: this.$t('商品数量'),
      key: 'quantity',
      align: 'center',
    },
    {
      title: this.$t('订单总价（积分）'),
      key: 'amount',
      align: 'center',
    },
    {
      title: this.$t('商品类型'),
      key: 'productType',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.productType === 1 ? '实物商品' : '虚拟商品' }</span>)
        }
      }
    },
    {
      title: this.$t('下单时间'),
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: this.$t('订单状态'),
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.status === 1 ? '待发货' : '已完成' }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'operate',
      align: 'center',
      width: 270,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission
          return (
            /*<div>
              { Update && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.detailDialog.open(row) } }>{ this.$t('修改') }</el-button> }
              { Delete && <el-button type='danger' size='small' icon='el-icon-delete' onClick={() => this.handleDelete(row)}>{ this.$t('删除') }</el-button> }
            </div>*/
            <div>
              { <el-button type='success' size='small' icon='el-icon-location-information' v-show={row.productType === 1 && row.status === 1} onClick={ () => this.handleSendGoods(row) }>{ this.$t('发货') }</el-button> }
              { <el-button type='primary' size='small' icon='el-icon-s-check' onClick={ () => { this.$refs.detailDialog.open(row) } }>{ this.$t('查看详情') }</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
