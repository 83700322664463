const CanChooseColumns = function() {
  return [
    {
      title: '发帖人',
      key: 'nickname',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>[{ this.xsLottery[row.lotteryId] }]</p>
              <span class='primary-type'>{ row.nickname }</span>
            </div>
          )
        }
      }
    },
    {
      title: '标题',
      key: 'title'
    },
    {
      title: '创建时间',
      key: 'createTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: '浏览数',
      key: 'viewNum',
      align: 'center',
      width: 125
    },
    {
      title: '点赞数',
      key: 'admireNum',
      align: 'center',
      width: 125
    },
    {
      title: '状态',
      key: 'status',
      align: 'center',
      width: 320,
      component: {
        render: (h, row) => {
          return (
            <div class='recommend-status'>
              {
                row.topViewStatus === 1
                  ? <span style='color: red'>置顶</span>
                  : <span>未设置</span>
              }
              {
                row.lockStatus === 1
                  ? <span style='color: red'>锁定</span>
                  : <span>未锁</span>
              }
              <span>
                {
                  row.loginViewStatus === 1
                    ? <p style='color: red'>登录可见</p>
                    : <p>非登录可见</p>
                }
                {
                  row.replyViewStatus === 1
                    ? <p style='color: red'>回复可见</p>
                    : <p>非回复可见</p>
                }
              </span>
              {
                row.payViewStatus === 1
                  ? <span style='color: red'>充值可见</span>
                  : <span>非充值可见</span>
              }
            </div>
          )
        }
      }
    },
    {
      title: '操作',
      align: 'center',
      key: 'actions',
      width: 120,
      component: {
        render: (h, row) => {
          return (
            this.permission.Add && <button class='el-button el-button--mini el-button--orange' onClick={this.handleRecover.bind(this, row)}>
              <i class='el-icon-check' />
              <span>恢复</span>
            </button>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
