<template>
  <el-dialog :title="$t('变赔记录')" :visible.sync="visible" width="75%" :close-on-click-modal="false" @open="onOpen">
    <div class="filter-container-item">
      <el-date-picker
        v-model="times"
        type="daterange"
        class="filter-item"
        style="width: 360px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="yyyy-MM-dd"
      />
      <el-button class="filter-item" @click="handleSetDate($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 7)">{{ $t('_7天') }}</el-button>
      <el-button type="primary" icon="el-icon-search" @click="handleRefresh">
        {{ $t('查询') }}
      </el-button>
    </div>
    <div class="filter-container-item">
      <el-select v-model="search.sportType" :placeholder="$t('比赛类型')" style="width: 100px">
        <el-option :label="$t('足球')" :value="1" />
        <el-option :label="$t('篮球')" :value="2" disabled />
      </el-select>
      <el-input v-model="search.competitionName" :placeholder="$t('赛事名称')" style="width: 160px" />
      <el-input v-model="search.issueNum" :placeholder="$t('赛事编号')" clearable style="width: 160px" />
      <el-input v-model="search.operatorName" :placeholder="$t('操作人')" clearable style="width: 160px" />
    </div>

    <el-table
      v-loading="loading"
      :data="items"
      v-bind="options"
    >
      <el-table-column prop="issueNum" :label="$t('赛事编号')" align="center" />
      <el-table-column prop="competitionName" :label="$t('联赛')" align="center" />
      <el-table-column prop="teamName" :label="$t('对阵')" align="center">
        <template slot-scope="{ row }">
          {{ row.homeTeamName }} VS {{ row.awayTeamName }}
        </template>
      </el-table-column>
      <el-table-column prop="changeContent" :label="$t('修改赔率')" align="center" />
      <el-table-column prop="updateTime" :label="$t('操作时间')" align="center">
        <template slot-scope="{ row }">
          {{ row.updateTime | parseTime }}
        </template>
      </el-table-column>
      <el-table-column prop="operatorName" :label="$t('操作人')" align="center" />
    </el-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

  </el-dialog>
</template>
<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getOddsLog } from '@/api/lottery'

export default {
  mixins: [VDialogMixin, TablePageMixin(getOddsLog, false)],
  data() {
    return {
      times: null,
      search: { sportType: 1 },
      params: {
        currentPage: 1,
        pageSize: 10
      }
    }
  },
  methods: {
    open(tenantCode) {
      this.search.tenantCode = tenantCode
      this.visible = true
    },
    onOpen() {
      this.handleRefresh()
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    }
  }
}
</script>
