<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="months"
        type="monthrange"
        class="filter-item"
        range-separator="至"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        format="yyyy年MM月"
        value-format="yyyy-MM"
      />
      <el-input v-model="search.tenantName" clearable placeholder="租户名称" class="filter-item" style="width: 200px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleRefresh">查询</el-button>
      <el-button v-if="permission.Excel" type="success" icon="el-icon-download" class="filter-item" style="float: right">导出</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <ConfirmDialog ref="ConfirmDialogControl" @confirm="_handleConfirm" />
    <UpdateDialog ref="UpdateDialogControl" @update="_handleUpdate" />
  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import ConfirmDialog from './ConfirmDialog'
import UpdateDialog from './UpdateDialog'
import { getTenantReportList, confirmReceipt, updateReport } from '@/api/tenant'

export default {
  name: 'TenantReport',
  components: { ConfirmDialog, UpdateDialog },
  mixins: [TablePageMixin(getTenantReportList)],
  data() {
    return {
      months: [],
      filterColumn: false,
      columns: GetColumns.call(this),
      search: {}
    }
  },
  methods: {
    onAction(row, emitName) {
      this.$refs.ConfirmDialogControl.open(row, emitName)
    },
    _handleConfirm({ field, cancel, close }) {
      confirmReceipt({
        id: field.id,
        receiptsAccount: field.receiptsAccount,
        remark: field.remark
      }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('操作成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleUpdate({ field, cancel, close }) {
      updateReport({
        id: field.id,
        additionalCost: field.additionalCost,
        beautyCost: field.beautyCost,
        maintenanceCost: field.maintenanceCost,
        serverCost: field.serverCost,
        remark: field.remark
      }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('操作成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    implementationGetParams() {
      const [startTime, endTime] = this.months || []
      return { ...this.search, startTime, endTime }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
