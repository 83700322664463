<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="search.paychannelId" :placeholder="$t('平台名称')" clearable class="filter-item" style="width: 150px">
        <el-option v-for="item in platList" :key="item.id" :label="item.payPlatformName" :value="item.id" />
      </el-select>
      <el-select v-model="search.paywayId" :placeholder="$t('支付方式')" clearable class="filter-item" style="width: 150px">
        <el-option v-for="item in payMetodList" :key="item.paywayId" :label="item.paywayName" :value="item.paywayId" />
      </el-select>
      <el-input v-model="search.operatorName" :placeholder="$t('操作员')" class="filter-item" style="width: 150px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate()">
        {{ $t('添加第三方账户') }}
      </el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <edit-dialog ref="EditDialogControl" @create="handleCreateAccount" @update="hanldeUpdateAccount" />
  </div>
</template>
<script>
import EditDialog from './EditDialog'
import { getAllVipLevels } from '@/api/app'
import {
  getPayThirdAccountList,
  addPayThirdAccount,
  updatePayThirdAccount,
  delPayThirdAccount,
  getPayWayThirdConfList,
  getPaymentWayConfList
} from '@/api/cash'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { payTypes } from '@/libs/options'
export default {
  name: 'Three',
  componentName: 'Three',
  components: { EditDialog },
  mixins: [TablePageMixin(getPayThirdAccountList)],
  data() {
    return {
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
      platList: [],
      payMetodList: [],
      vipLevels: [],
      payTypes
    }
  },
  created() {
    this.implementationTenantChanged()
  },
  methods: {
    onCreate() {
      this.$refs.EditDialogControl.open()
    },
    implementationTenantChanged() {
      const params = {
        pageSize: 100,
        currentPage: 1
      }
      getPayWayThirdConfList(params).then(([data, err]) => {
        if (!err) {
          this.platList = data
        }
      })

      getPaymentWayConfList(params).then(([data, err]) => {
        if (!err) {
          this.payMetodList = data
        }
      })

      getAllVipLevels().then(([data, err]) => {
        if (!err) {
          this.vipLevels = data
        }
      })
    },
    handleCreateAccount({ field, close }) {
      const params = { ...field, vipLevel: field.vipLevel + '' }
      addPayThirdAccount(params).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('添加成功'))
        }
      })
    },

    hanldeUpdateAccount({ field, close }) {
      updatePayThirdAccount({ ...field, vipLevel: field.vipLevel + '' }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('保存成功'))
        }
      })
    },

    handleChangeStatus({ id, status, rateType }) {
      return new Promise((resolve) => {
        this.$confirm(status === 1 ? this.$t('确认启用此账户?') : this.$t('确认备用此账户?'), this.$t('系统提示'), { type: 'warning' }).then(() => {
          updatePayThirdAccount({
            id,
            status: ~status + 2,
            rateType
          }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },

    handleDeleteAccount(id) {
      this.$confirm(this.$t('确认删除此账户?'), this.$t('系统提示'), { type: 'error' }).then(() => {
        delPayThirdAccount(id).then(([data, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
