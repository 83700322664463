var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "table-dialog",
      attrs: {
        title: _vm.okText,
        width: "610px",
        top: "5vh",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c("table", [
        _c("colgroup", [_c("col", { attrs: { width: "165" } }), _c("col")]),
        _c("tbody", [
          _c("tr", [
            _c("td", { staticClass: "is-right" }, [
              _vm._v(_vm._s(_vm.$t("彩种")) + "："),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.ruleForm.lotteryName))]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "is-right" }, [
              _vm._v(_vm._s(_vm.$t("期号")) + "："),
            ]),
            _c("td", [_vm._v(_vm._s(_vm.ruleForm.issue))]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("取消"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.okText))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }