import emitter from './emitter'
import { mapGetters } from 'vuex'
const parentMixin = {
  mixins: [emitter],
  data() {
    return {
      activeName: 'ForecastTab',
      permissionTabs: [],
      whiteList:[
        'RobotOptionsTab',
        'ScheduledTasksTab'
      ]
    }
  },
  watch: {
    activeName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`)
    }
  },
  created() {
    this.getPermissionTabs()
  },
  computed: {
    ...mapGetters(['roles'])
  },
  methods: {
    getPermissionTabs() {
      let tabs = []
      //@@@@取消权限过滤，记得要改回去 //this.tabs是zh-CN的object.js中定义的，当用户有权限的时候，要求getOperatorMenuList返回的permission字段相同，才可以显示该tab
      // tabs = this.tabs
      if (process.env.NODE_ENV.includes('prox') || this.$route.meta.noauth) {
        tabs = this.tabs
      } else {
        const _parentUrl = this.$route.path.split('_')[0]
        const _parent = this.roles.find(o => o.permission === _parentUrl)

        
        if (!_parent) return
        const sensitive = _parent.children.find(o => o.permission === this.$route.path)
        if (!sensitive) return

        const permissionTabs = sensitive.children.map(o => o.permission)
        
        if (this.tabs) {
          tabs = this.tabs.filter(o => permissionTabs.includes(o.name))
        }else{
          tabs = {}
          permissionTabs.forEach((item)=>{
            if (this.whiteList.includes(item)) {
              tabs[item] = true;
            }
          })

        }
      }

      const tab = this.$route.query.tab
      if (!tab) {
        this.activeName = Array.isArray(tabs) && tabs.length && tabs[0].name
      } else {
        this.activeName = tab
      }
      this.permissionTabs = tabs
    }
  }
}

export {
  parentMixin
}
