<template>
  <div>
    <div v-if="seriesTag === '10' && isManualMode" class="lottery-animation">
      <div class="lottery-animation-numbers item">
        <el-input
          v-for="(item, i) in openNumbers"
          :key="i"
          v-model.trim="item.num"
          :disabled="item.type === 3"
          size="small"
          :placeholder="item.placeholder"
          maxlength="2"
        />
      </div>
      <div class="lottery-animation-btn item">
        <el-button
          v-for="(item, i) in openNumbers"
          :key="i"
          v-model="openNumbers[i]"
          :disabled="item.type === 3"
          :class="[
            { confirm: item.type === 1 },
            { edit: item.type === 2 },
            { wait: item.type === 3 },
          ]"
          @click="openPrize(i)"
          >{{ item.text }}</el-button
        >
      </div>
    </div>
    <div class="lottery-before" v-if="!isManualMode">
      <label class="lottery-before-label">{{ label }}</label>
      <div class="lottery-before-numbers">
        <el-input
          v-for="(item, i) in openNumbers"
          :key="i"
          v-model.trim="item.num"
          :disabled="['40', '41'].includes(lotteryId)"
          size="small"
        />
      </div>
    </div>
    <h3>
      结果对比<el-button
        type="primary"
        class="filter-item"
        style="margin-left: 20px"
        @click="$emit('handleCompared')"
        >{{ $t("获取结果") }}</el-button
      >
    </h3>
    <div
      v-for="(item, index) in showResultComparedList"
      :key="index"
      class="lottery-before"
    >
      <label class="lottery-before-label">{{ item.sourceName }}</label>
      <div class="lottery-before-numbers">
        <el-input
          v-for="(itemNum, i) in item.lotteryNo.split(',')"
          :key="i"
          :value="itemNum"
          readonly
          size="small"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isRepeat } from "@/libs/universal.js";
import { manualOpenPrizeShow } from "@/api/lottery.js";

// const RULES = {
//   '10': { len: 7, repeat: false, regExp: /^(([1-9]|[1-4][0-9]|50)[,]){5}([1-9]|1[0-2])[,]([1-9]|1[0-2])$/ },
//   '11': { len: 5, regExp: /^(([1-9]|[1-6][0-9]|70)[,]){5}([1-9]|1[0-9]|2[0-5])$/ },
//   '12': { len: 10, repeat: false, regExp: /^(([1-9]|[1-6][0-9]|70)[,]){5}([1-9]|1[0-9]|2[0-5])$/ },
//   '13': { len: 3, repeat: false, regExp: /^((0[1-9]|[1-6][0-9])[,]){5}(0[1-9]|1[0-9]|2[0-6])$/ },
//   '15': { len: 6, repeat: false, regExp: /^(([1-9]|[1-4][0-9]|50)[,]){5}([1-9]|1[0-2])[,]([1-9]|1[0-2])$/ },
//   '21': { len: 3, repeat: false, regExp: /^((0[1-9]|[1-6][0-9])[,]){5}(0[1-9]|1[0-9]|2[0-6])$/ }
// }

const length = {
  10: 7,
  11: 5,
  12: 10,
  13: 3,
  15: 3,
  21: 3
};

import { $t } from "@/lang";

export default {
  props: {
    label: {
      type: String,
      default: $t("预开奖"),
    },
    seriesTag: {
      type: String,
      required: true,
    },
    lotteryId: {
      type: String,
      required: true,
    },
    result: {
      type: String,
      default: "",
    },
    issue: {
      type: String,
      default: "",
    },
    isManualMode: {
      type: Boolean,
      default: false,
    },
    showResultComparedList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      seriesRules: null,
      openNumbers: [],
      holderList: ["平一", "平二", "平三", "平四", "平五", "平六", "特码"],
      lotteryNo: "",
    };
  },
  computed: {
    ...mapGetters(["operatorType"]),
  },
  methods: {
    validateNumber() {
      // const { regExp } = RULES[this.seriesTag]
      // console.log(this.openNumbers)
      const numbers = this.openNumbers
        .filter((e) => e.num !== "")
        .map((e) => {
          return e.num;
        });
      const tmp = numbers.join(",");
      return new Promise((resolve, reject) => {
        if (length[this.seriesTag] !== numbers.length) {
          reject("开奖号码个数不对");
        } else if (
          ["39", "40", "41"].includes(this.lotteryId) &&
          this.openNumbers.some((e) => e.type !== 2)
        ) {
          reject("所有号码请全部点击确认");
        } else if (this.seriesTag === "10") {
          let isValide = false;
          numbers.forEach((e) => {
            if (e.length !== 2 || Number(e) < 1 || Number(e) > 49) {
              isValide = true;
              return;
            }
          });
          if (isValide) {
            reject("开奖号码格式不合法");
          } else {
            const { ispass } = isRepeat(numbers);
            if (!ispass) {
              reject("开奖号码重复");
            } else {
              resolve(tmp);
            }
          }
        } else {
          resolve(tmp);
        }
        // if (!regExp.test(tmp)) {
        //   reject('开奖号码格式不合法')
        // } else {
        // const { ispass } = isRepeat(this.openNumbers)
        // if (!repeat && !ispass) {
        //   reject('开奖号码重复')
        // } else {
        //   resolve(tmp)
        // }
        // }
      });
    },
    init() {
      this.openNumbers = [];
      const len = length[this.seriesTag];
      let lotteryNoArray = [];
      if (this.seriesTag === "10" && this.lotteryNo) {
        lotteryNoArray = this.lotteryNo.split(",");
      }
      let isConfirm = true;
      for (let i = 0; i < len; i++) {
        const number = {
          num: "",
        };
        if (this.seriesTag === "10") {
          number.placeholder = this.holderList[i];
          number.text = "取消";
          number.type = 3;
          if (isConfirm) {
            if (lotteryNoArray.length > i) {
              number.num = lotteryNoArray[i];
              number.text = "修改";
              number.type = 2;
            } else {
              number.text = "确认";
              number.type = 1;
              isConfirm = false;
            }
          }
        }
        this.openNumbers.push(number);
      }
    },
    openPrize(evntDex) {
      let errMsg = "";
      const lotteryNoArray = [];
      this.openNumbers.forEach((item, index) => {
        if (item.type !== 3 && item.num) {
          // if (item.type === 2 || evntDex >= index) {

          // }
          errMsg = this.lhcValidate(item, index, evntDex);
          lotteryNoArray.push(item.num);
        }
      });

      if (errMsg !== "") {
        this.$message.error(errMsg);
        return;
      }
      const { ispass } = isRepeat(lotteryNoArray);
      if (!ispass) {
        this.$message.error("开奖号码重复");
        return;
      }
      const params = {
        lotteryId: this.lotteryId,
        issue: this.issue,
        lotteryNo: lotteryNoArray.join(","),
      };

      manualOpenPrizeShow(params, this.operatorType).then(([data, err]) => {
        if (!err) {
          let message = `【${this.holderList[evntDex]}】开奖成功，13秒后开启下一个号码`;
          if (this.openNumbers[evntDex].type === 2) {
            message = `修改【${this.holderList[evntDex]}】成功`;
          }
          let timeOut = 13000;
          if (evntDex === 6) {
            message = `【${this.holderList[evntDex]}】开奖成功`;
            timeOut = 100;
          }
          this.$message.success(message);
          if (this.openNumbers[evntDex].type !== 2) {
            this.openNumbers[evntDex].text = "修改";
            this.openNumbers[evntDex].type = 2;
            setTimeout(() => {
              this.lotteryNo = params.lotteryNo;
              if (evntDex < this.openNumbers.length - 1) {
                // setTimeout(() => (this.openNumbers[evntDex + 1].type = 1), 12000)
                if (this.openNumbers[evntDex + 1].type === 3) {
                  this.openNumbers[evntDex + 1].type = 1;
                  this.openNumbers[evntDex + 1].text = "确认";
                }
              } else {
                // setTimeout(() => (this.$emit('canSumbmit')), 12000)
                this.$emit("canSumbmit");
              }
            }, timeOut);
          }
          if (evntDex === this.openNumbers.length - 1) {
            this.$emit("canSumbmit");
          }
        }
      });
    },
    lhcValidate(item, index, evntDex) {
      const placeholder = this.holderList[index];
      if (
        item.num === "" ||
        Number(item.num) < 1 ||
        Number(item.num) > 49 ||
        item.num.length !== 2
      ) {
        if (evntDex < index && item.type === 1) {
          return "";
        }
        return placeholder + "号码格式不合法";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.lottery-animation {
  padding: 0 30px 0 10px;
  .item {
    display: flex;
    justify-content: space-between;
    ::v-deep .el-input {
      width: 65px;
      height: 50px;
      input {
        text-align: center;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
      }
      &:last-child {
        margin-left: 20px;
      }
    }
    ::v-deep .el-button {
      width: 65px;
      color: #fff;
      &.edit {
        background: rgb(233, 157, 66);
      }
      &.confirm {
        background: rgb(129, 179, 55);
      }
      &.wait {
        background: rgb(154, 154, 154);
      }
      + .el-button {
        margin-left: 0;
      }
      &:last-child {
        margin-left: 20px;
      }
    }
    &:last-child {
      margin-top: 20px;
    }
  }
}
.lottery-before {
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  &-label {
    font-weight: normal;
    flex: 0 1 150px;
  }
  &-numbers {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 10px 0 0;
    border-left: 1px solid #ddd;
    ::v-deep .el-input {
      width: 48px;
      margin-left: 10px;
      margin-bottom: 5px;
      .el-input__inner {
        height: 48px;
        padding: 0;
        text-align: center;
      }
    }
  }
}
</style>
