<template>
    <el-dialog title="卡券详情" :visible.sync="visible" width="600px" top="5vh" :close-on-click-modal="false">
      <el-form ref="validateForm" label-width="190px" label-suffix=":">
        <el-form-item :label="$t('优惠类型')">
          {{ couponTypes[row.couponType] }}
        </el-form-item>
        <el-form-item :label="$t('卡券名称')">
          {{ row.couponTitle }}
        </el-form-item>
        <template v-if="row.couponType === 3">
          <el-form-item :label="$t('折扣方式')">
            {{ row.couponDiscountType === 0 ? '指定金额' : '指定折扣' }}
            <el-form-item v-if="row.couponDiscountType === 0">
              {{ row.couponAmount }}
            </el-form-item>
            <el-form-item v-else>
              {{ row.couponDiscountRate }}折
            </el-form-item>
          </el-form-item>
          <el-form-item :label="$t('使用标准')">
            {{ row.couponStandard }}
          </el-form-item>
        </template>
        <el-form-item :label="$t('生效时间')">
          {{ row.couponValidType === 0 ? '领取后生效' : '固定有效期' }}
          <el-form-item v-if="row.couponValidType === 1">
            {{ $parseTime(row.couponValidStart, '{y}-{m}-{d} {h}:{i}') }}-{{ $parseTime(row.couponValidEnd, '{y}-{m}-{d} {h}:{i}') }}
          </el-form-item>
        </el-form-item>
        <el-form-item v-if="row.couponValidType === 0" :label="$t('卡券有效期(时)')">
          {{ row.couponExpired }}
        </el-form-item>
        <el-form-item :label="$t('卡券库存')">
          {{ row.couponStock === -1 ? '无限' : row.couponStock }}
        </el-form-item>
        <template v-if="row.couponType === 4">
          <el-form-item :label="$t('卡券面值')">
            {{ row.couponAmount }}
          </el-form-item>
          <el-form-item :label="$t('提现打码量')">
            {{ row.couponStandardWithdrawRolling }}倍
          </el-form-item>
          <el-form-item :label="$t('打码量稽查（使用标准）')">
            {{ row.couponStandardRolling }}
          </el-form-item>
          <el-form-item :label="$t('指定打码平台')">
            {{ row.codingPlatform === '-1' ? '无限制' : '仅限以下勾选平台' }}
          </el-form-item>
        </template>
        <template v-else-if="row.couponType === 5">
          <el-form-item :label="$t('卡券面值')">
            {{ row.couponAmount }}
          </el-form-item>
          <el-form-item :label="$t('提现打码量')">
            {{ row.couponStandardWithdrawRolling }}倍
          </el-form-item>
          <el-form-item :label="$t('使用标准：充值金额')">
            {{ row.couponStandardDepositAmount }}以上
          </el-form-item>
          <el-form-item :label="$t('打码量稽查')">
            {{ row.couponStandardRolling }}
          </el-form-item>
          <el-form-item :label="$t('指定打码平台')">
            {{ row.codingPlatform === '-1' ? '无限制' : row.codingPlatformName }}
          </el-form-item>
        </template>
        <template v-else-if="row.couponType === 6">
          <el-form-item :label="$t('优惠面值：订单中奖加奖')">
            {{ row.discountAmount }}倍
          </el-form-item>
          <el-form-item :label="$t('使用标准')">
            <el-form-item :label="$t('最小下注')" class="betAmount">
              {{ row.couponStandardMin }}
            </el-form-item>
            <el-form-item :label="$t('最大下注')" class="betAmount">
              {{ row.couponStandardMax }}
            </el-form-item>
          </el-form-item>
        </template>
        <el-form-item v-if="row.couponType === 6" :label="$t('使用方式')">
          <span>{{ couponScopeModes[row.couponScopeMode] }}</span>
        </el-form-item>
        <el-form-item v-if="row.couponType === 3" :label="$t('使用方式')">
          <span>游戏下注优惠</span><span style="margin-left: 20px;">{{ couponScopeModes[row.couponScopeMode] }}</span>
        </el-form-item>
        <el-form-item v-if="row.couponType === 3 || row.couponType === 6" :label="$t('适用范围')">
          {{ row.couponScope === '-1' ? '无限制' : row.couponScopeName }}
        </el-form-item>
        <el-form-item v-if="row.couponType === 5" :label="$t('适用范围')">
          {{ row.couponScope === '-1' ? '无限制' : row.couponScopeName }}
        </el-form-item>
        <el-form-item :label="$t('备注')">
          {{ row.couponRemark }}
        </el-form-item>
        <el-form-item :label="$t('上架状态')">
          {{ row.couponStatus === 1 ? '上架' : '下架' }}
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="visible = false">{{ $t('确定') }}</el-button>
      </div>
    </el-dialog>
  </template>
  
  <script>
  import { couponTypes, couponScopeModes } from '@/libs/options'
  export default {
    data() {
      return {
        visible: false,
        row: {},
        couponTypes,
        couponScopeModes
      }
    },
    methods: {
      async open(row) {
        this.row = row
        this.visible = true
      }
    }
  }
  </script>
  
  <style lang="scss">
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  
  .star {
    position: absolute;
    left: -83px;
    color: #ff4949;
  }
  .amount {
    position: absolute;
    width: 145px;
    top: -33px;
    margin-left: 205px;
    &.discount {
      width: 125px;
    }
    + span {
      position: absolute;
      right: 55px;
      top: -33px;
    }
  }
  
  .rechargeAmount {
    label {
      width: 176px !important;
    }
  }
  
  .couponAwards {
    label {
      width: 217px !important;
    }
  }
  
  .betAmount {
    label {
      width: auto !important;
      margin-bottom: 18px !important;
    }
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-form-item__error {
      top: 67%;
    }
  }
  
  .lang-pop {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  </style>
  