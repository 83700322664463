var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "1000px",
      },
      on: { close: _vm._close },
    },
    [
      _c(
        "div",
        { staticClass: "list_" },
        _vm._l(_vm.list, function (item) {
          return _c("span", { key: item.id, staticClass: "l_" }, [
            _vm._v(_vm._s(item.cardBin)),
          ])
        }),
        0
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogFormVisible = false
                },
              },
            },
            [_vm._v("关 闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }