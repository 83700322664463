var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("确认操作"),
        visible: _vm.visible,
        center: "",
        width: "400px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: { model: _vm.ruleForm, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "type", $$v)
                    },
                    expression: "ruleForm.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("删除当前消息")) + " "),
                  ]),
                  _c(
                    "el-radio",
                    { attrs: { label: 2 } },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("删除消息并禁言")) + " "),
                      _c("el-input", {
                        staticStyle: { width: "30%" },
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.ruleForm.min,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "min", $$v)
                          },
                          expression: "ruleForm.min",
                        },
                      }),
                      _vm._v(" 分钟 "),
                    ],
                    1
                  ),
                  _c("el-radio", { attrs: { label: 3 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("删除消息并永久禁言")) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.close } },
            [_vm._v(" " + _vm._s(_vm.$t("关闭")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "medium",
                loading: _vm.submitting,
              },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确认")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }