<template>
  <div v-loading="loading" class="display-icon">
    <div v-for="item in items" :key="item.level1Value" class="display-icon-list">
      <h2 class="display-icon-title">{{ item.level1Desc }}</h2>
      <div v-for="child in item.level2List" :key="child.level2Value" class="display-icon-child">
        <el-upload
          class="avatar-uploader"
          accept="image/png,image/jpeg,image/webp"
          :action="action"
          :data="{ type: 'image', folder: 'icon' }"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="(...args) => handleUploadSuccess(args, child)"
          :before-upload="beforeIconUpload"
        >
          <el-image v-if="child.iconUrl" :src="child.iconUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon" />
        </el-upload>
        <p class="name">{{ child.level2Desc }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { accountChangeGetIcons, accountChangeUpdateIcon } from '@/api/config'

export default {
  name: 'IconTab',
  componentName: 'IconTab',
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.loading = true
      accountChangeGetIcons().then(([items, err]) => {
        if (!err) {
          this.items = items
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleUploadSuccess([res], child) {
      const iconUrl = res.data[0]
      accountChangeUpdateIcon({ ...child, iconUrl }).then(([_, err]) => {
        if (!err) {
          child.iconUrl = iconUrl
          this.$message.success('更新图标成功')
        }
      })
    },

    beforeIconUpload(file) {
      const isJPG = file.type === 'image/png' || file.type === 'image/jpeg'|| file.type === 'image/webp'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传彩种图标只能是 PNG、JPG、WEBP 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传彩种图标大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>
<style lang="scss" scoped>
.display-icon {
  padding-left: 60px;
  &-title {
    font-size: 18px;
  }
  &-item {
    padding-left: 40px;
  }
  &-child {
    display: inline-block;
    margin-right: 30px;
    text-align: center;
    .name {
      text-align: center;
      margin: 10px 0 20px 0;
      &.parent {
        font-weight: bold;
      }
    }
  }
  h4 {
    font-size: 14px;
  }
  .avatar-uploader .el-upload  {
    border: 1px dashed #3894FF;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
  .avatar {
    width: 60px;
    height: 60px;
    display: block;
  }
}
</style>
