<template>
  <div class="rankpage">
    <el-row v-loading="loading" :gutter="24" class="basic_config_content">
      <el-form
        ref="ruleForm"
        :model="ruleForm[item.smallType]"
        v-for="(item, index) in arr"
        :key="index"
      >
        <el-col :span="12">
          <div class="titleLine">
            <div class="title">{{ item.title }}</div>
          </div>
          <el-form-item>
            <Tinymce
              ref="tinymce"
              v-model="ruleForm[item.smallType].explainContent"
              :height="350"
            />
          </el-form-item>

          <el-form-item class="action-button">
            <el-button
              type="danger"
              :loading="submitting"
              @click="() => handleSubmit(item)"
              >{{ $t("保 存") }}</el-button
            >
            <!-- <el-button @click="$refs['ruleForm'].resetFields()">{{
        $t("重 置")
      }}</el-button> -->
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import { guruExplainSearch, guruExplainAdd } from "@/api/orderManage";
import Tinymce from "@/components/Tinymce";
export default {
  components: { Tinymce },
  data() {
    return {
      loading: false,
      submitting: false,
      ruleForm: {
        2: { explainContent: "" },
        3: { explainContent: "" },
        4: { explainContent: "" },
        5: { explainContent: "" },
        6: { explainContent: "" },
        7: { explainContent: "" },
        8: { explainContent: "" },
      },
      // 小的分类: 1 推单规则说明配置,2 体育中奖,3 体育命中,4 体育盈利,5 彩票中奖,6 彩票命中,7 彩票盈利, 8 上榜规则,示例值(1)
      arr: [
        {
          title: "中奖排行（体育竞彩）",
          smallType: 2,
        },
        {
          title: "中奖排行（六合彩）",
          smallType: 5,
        },
        {
          title: "命中排行（体育竞彩）",
          smallType: 3,
        },
        {
          title: "命中排行（六合彩）",
          smallType: 6,
        },
        {
          title: "盈利率排行（体育竞彩）",
          smallType: 4,
        },
        {
          title: "盈利率排行（六合彩）",
          smallType: 7,
        },
        {
          title: "上榜规则",
          smallType: 8,
        },
      ],
    };
  },
  created() {
    this.handleSearch();
  },
  mounted() {},
  methods: {
    handleSearch() {
      this.loading = true;
      guruExplainSearch({ explainType: 2 }).then(([data, err]) => {
        this.loading = false;
        if (!err) {
          data.forEach((element) => {
            if (element.explainType == 2) {
              if (this.ruleForm?.[element.smallType]) {
                this.ruleForm[element.smallType].explainContent =
                  element.explainContent;
              }
            }
          });
          // if (data) this.ruleForm = data;
        }
      });
    },
    handleSubmit(item) {
      let reqData = {
        explainContent: this.ruleForm?.[item.smallType]?.explainContent,
        explainType: 2,
        contentFormat: 1,
        smallType: item.smallType,
      };
      this.submitting = true;
      guruExplainAdd(reqData).then(([_, err]) => {
        this.submitting = false;
        if (!err) {
          this.$message.success(this.$t("保存成功"));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.titleLine {
  display: flex;
  margin: 40px 0 10px;
  align-items: flex-end;
  line-height: 25px;
  .title {
    width: 240px;
    text-align: right;
    font-weight: 500;
    font-size: 20px;
  }
}
.desc {
  text-wrap: nowrap;
  margin-left: 10px;
  color: #999;
  font-size: 12px;
}
::v-deep .el-form-item__content {
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  align-items: center;
  .inlineText {
    flex-shrink: 0;
    /* flex-grow: 1; */
  }
}
::v-deep .el-input {
  min-width: 60px;
  max-width: 100px;
}
/* .rankpage {
  display: flex;
  flex-wrap: wrap;
} */
</style>
