<template>
  <div class="app-container">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="140px" label-suffix=":" v-loading="loading">
      <el-form-item :label="$t('入口开关')" prop="entrySwitch">
        <d-switch v-model="ruleForm.entrySwitch" :active-value="1" :inactive-value="0" active-text="开" inactive-text="关" />
        <span style="margin-left: 20px;">关闭入口后,前台不再显示借呗功能入口,正常情况下请勿关闭入口</span>
      </el-form-item>
      <el-form-item :label="$t('打码要求')" prop="rollingSwitch" class="dmyq">
        <d-switch v-model="ruleForm.rollingSwitch" :active-value="1" :inactive-value="0" active-text="开" inactive-text="关" />
        <span style="margin-left: 20px;">打码倍数：</span>
        <el-form-item prop="rollingTimes" label-width="0">
          <el-input v-model="ruleForm.rollingTimes" style="width: 100px;" @input="ruleForm.rollingTimes = ruleForm.rollingTimes.replace(/[^\d]/g, '')" />
          <span style="margin-left: 20px;">开启后借款的资金需要满足打码倍数要求才可以进行提现</span>
        </el-form-item>
      </el-form-item>

      <el-form-item :label="$t('平台借款上限')" prop="platformAmount">
        <el-input v-model="ruleForm.platformAmount" :placeholder="$t('请输入平台借款上限')" style="width: 150px;" />
        <span style="margin-left: 20px;">平台最高借款数量.会员借款总额达到这个数值,不能再借,会员还款后可以继续借</span>
      </el-form-item>

      <el-form-item :label="$t('平台每日借款上限')" prop="dailyAmount">
        <el-input v-model="ruleForm.dailyAmount" :placeholder="$t('请输入平台每日借款上限')" style="width: 150px;" />
        <span style="margin-left: 20px;">平台最高借款数量.会员借款总额达到这个数值,不能再借,会员还款后可以继续借</span>
      </el-form-item>

      <el-form-item :label="$t('用户每日借款次数')" prop="dailyTimes">
        <el-input v-model="ruleForm.dailyTimes" placeholder="-1代表不限制" style="width: 150px;" />
        <span style="margin-left: 20px;">用户每日最多借款次数，会员借款次数达到这个数值，当日不能再发起借款</span>
      </el-form-item>

      <el-form-item :label="$t('用户借款总次数')" prop="totalTimes">
        <el-input v-model="ruleForm.totalTimes" placeholder="-1代表不限制" style="width: 150px;" />
        <span style="margin-left: 20px;">用户最多借款次数，会员借款次数达到这个数值，不能再发起借款</span>
      </el-form-item>

      <el-form-item :label="$t('系统自动审核')" prop="autoApproval">
        <d-switch v-model="ruleForm.autoApproval" :active-value="1" :inactive-value="0" active-text="开" inactive-text="关" />
        <span style="margin-left: 20px;">开启后对于有可用借呗额度的订单将会自动审核通过,否则需要人工审核通过</span>
      </el-form-item>

      <el-form-item :label="$t('借呗说明')" prop="remark">
        <el-input type="textarea" v-model="ruleForm.remark" :placeholder="$t('请输入借呗说明')" maxlength="300" rows="8" show-word-limit style="max-width: 640px;" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" v-if="permission.Update" :loading="submitting" @click="handleSubmit">
          {{ $t('保存') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { borrowGetConfigDetail, borrowEditConfig } from '@/api/vipRewardMange'
import { mapGetters } from 'vuex'
export default {
  name: 'ConfigTab',
  data() {
    const validateDailyAmount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入借款上限'))
      } else {
        const num = Number(value)
        if (isNaN(num) || num < 1 || num > 100000000) {
          callback(new Error('借款上限有效值区间：1~100000000'))
        }
        callback()
      }
    }
    const validateRollingTimes = (rule, value, callback) => {
      if (value !== '' && value !== null) {
        const num = Number(value)
        if (isNaN(num) || num < 1 || num > 100) {
          callback(new Error('打码倍数有效区间：1~100'))
        }
      }
      callback()
    }
    const validateDailyTimes = (rule, value, callback) => {
      if (value !== '' && value !== null) {
        const num = Number(value)
        if (isNaN(num) || (num < 1 && num != -1) || num > 1000000) {
          callback(new Error('借款次数有效区间：-1或1-1000000'))
        }
      }
      callback()
    }
    return {
      ruleForm: {
        entrySwitch: 0,
        rollingSwitch:  0,
        rollingTimes: '',
        platformAmount: '',
        dailyAmount: '',
        dailyTimes: '',
        totalTimes: '',
        autoApproval:  0,
        remark: ''
      },
      loading: true,
      rules: {
        platformAmount: [{ required: true, message: '请输入平台借款上限', trigger: 'blur' },{ validator: validateDailyAmount, trigger: 'blur' }],
        dailyAmount: [{ required: true, message: '请输入平台每日借款上限', trigger: 'change' },{ validator: validateDailyAmount, trigger: 'blur' }],
        dailyTimes: [{ required: true, message: '请输入用户每日借款次数', trigger: 'change' },{ validator: validateDailyTimes, trigger: 'blur' }],
        totalTimes: [{ required: true, message: '请输入用户借款总次数', trigger: 'change' },{ validator: validateDailyTimes, trigger: 'blur' }],
        rollingTimes: [{ required: true, message: '请输入打码倍数', trigger: 'blur' },{ validator: validateRollingTimes, trigger: 'blur' }]
      },
      submitting: false
    }
  },
  created() {
    this.getInfos()
  },
  computed: {
    ...mapGetters(['permissionSensitive']),
    permission() {
      return this.permissionSensitive(this.$route.path, this.$options.name)
    }
  },
  methods: {
    handleSubmit() {
      this.$refs['validateForm'].validate((valid) => {
        if (valid) {
          this.submitting = true
          borrowEditConfig(this.ruleForm).then(([_, err]) => {
            if (!err) {
              this.$message.success(this.$t('保存成功'))
            }
          }).finally(() => {
            this.submitting = false
          })
        }
      })
    },
    getInfos() {
      borrowGetConfigDetail().then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.ruleForm = data
          this.ruleForm.rollingTimes = this.ruleForm.rollingTimes || ''
          this.ruleForm.entrySwitch === 1 ? 1 : 0
          this.ruleForm.rollingSwitch === 1 ? 1 : 0
          this.ruleForm.autoApproval === 1 ? 1 : 0
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dmyq {
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
  }
}
</style>