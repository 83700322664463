<template>
  <el-form
    ref="validateForm"
    :model="ruleForm"
    :rules="rules"
    label-width="120px"
    class="system-form"
  >

    <h2>超级管理员</h2>
    <el-form-item label="账号" prop="operatorName">
      <el-input v-model="ruleForm.operatorName" />
    </el-form-item>
    <el-form-item label="昵称" prop="nickName">
      <el-input v-model="ruleForm.nickName" />
    </el-form-item>
    <el-form-item label="密码" prop="password" required>
      <el-input v-model="ruleForm.password" />
    </el-form-item>
    <el-form-item label="确认密码" prop="confirmPassword" required>
      <el-input v-model="ruleForm.confirmPassword" />
    </el-form-item>
  </el-form>
</template>
<script>
import { validAccountAndPassword } from '@/utils/validate'
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (!validAccountAndPassword(value)) {
          callback('格式不合法')
        } else {
          if (this.ruleForm.checkPassword !== '') {
            this.$refs.validateForm.validateField('comfirmPassword')
          }
        }
        callback()
      }
    }

    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    return {
      ruleForm: {
        operatorName: '',
        nickName: '',
        password: '',
        confirmPassword: ''
      },
      rules: {
        operatorName: [
          { required: 'true', message: '请输入账号', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9]{6,12}$/, message: '格式不合法', trigger: 'blur' }
        ],
        nickName: [
          { required: 'true', message: '请输入昵称', trigger: 'blur' },
          { pattern: /^[\S]{2,12}$/, message: '格式不合法', trigger: 'blur' }
        ],
        password: [{ validator: validatePass, trigger: 'blur' }],
        confirmPassword: [{ validator: validateCheckPass, trigger: 'blur' }]
      }
    }
  },
  methods: {
    onValidate(cb) {
      return new Promise(resolve => {
        this.$refs.validateForm.validate((valid) => {
          if (valid) {
            cb && cb(this.ruleForm)
            resolve()
          }
        })
      })
    }
  }
}
</script>
