var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "450px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "85px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort" } },
            [
              _c("el-input", {
                staticStyle: { width: "310px" },
                attrs: {
                  type: "number",
                  placeholder: _vm.$t("请输入1-99的整数，由大到小"),
                  clearable: "",
                },
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sort", $$v)
                  },
                  expression: "ruleForm.sort",
                },
              }),
            ],
            1
          ),
          _vm.ruleForm.items && _vm.ruleForm.items.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("商城类目"), prop: "categoryName" } },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        title: "商城类目多语言配置",
                        trigger: "click",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "310px" },
                        attrs: {
                          slot: "reference",
                          value: _vm.ruleForm.categoryName,
                          readonly: "",
                        },
                        slot: "reference",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "lang-pop",
                          staticStyle: { width: "310px" },
                        },
                        _vm._l(_vm.ruleForm.items, function (item) {
                          return _c("el-input", {
                            key: item.langCode,
                            attrs: {
                              placeholder: _vm.$t(item.langName),
                              maxlength: "20",
                            },
                            model: {
                              value: item.categoryName,
                              callback: function ($$v) {
                                _vm.$set(item, "categoryName", $$v)
                              },
                              expression: "item.categoryName",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }