var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-container-item" },
          [
            _c("el-date-picker", {
              staticClass: "filter-item",
              staticStyle: { width: "360px" },
              attrs: {
                type: "daterange",
                "range-separator": _vm.$t("至"),
                "start-placeholder": _vm.$t("开始日期"),
                "end-placeholder": _vm.$t("结束日期"),
                "value-format": "yyyy-MM-dd",
                "picker-options": _vm.pickerOptions,
              },
              model: {
                value: _vm.times,
                callback: function ($$v) {
                  _vm.times = $$v
                },
                expression: "times",
              },
            }),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handleSearch },
              },
              [_vm._v(_vm._s(_vm.$t("查询")))]
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary", icon: "el-icon-refresh-right" },
                on: { click: _vm.resetSearch },
              },
              [_vm._v(_vm._s(_vm.$t("重置")))]
            ),
          ],
          1
        ),
      ]),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          "original-columns": _vm.defaultColumns,
          options: _vm.options,
        },
        on: {
          refresh: _vm.handleFetch,
          "column-change": _vm.handleColumnChange,
        },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("div", { staticClass: "filter-container" }, [
                  _c(
                    "div",
                    { staticClass: "filter-container-item" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: _vm.$t("状态"), clearable: "" },
                          model: {
                            value: _vm.search.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "status", $$v)
                            },
                            expression: "search.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: _vm.$t("赛前"), value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: _vm.$t("赛中"), value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: _vm.$t("结束"), value: 3 },
                          }),
                        ],
                        1
                      ),
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "160px" },
                        attrs: {
                          placeholder: _vm.$t("赛事名称"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.search.competitionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "competitionName", $$v)
                          },
                          expression: "search.competitionName",
                        },
                      }),
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: {
                          placeholder: _vm.$t("球队名称"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.search.teamName,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "teamName", $$v)
                          },
                          expression: "search.teamName",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("ChangeOddsDialog", {
        ref: "ChangeOddsDialogControl",
        on: { update: _vm._handleUpdateOdds },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }