<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.memberName" class="filter-item" :placeholder="$t('Expert_Plan.专家账号')" style="width: 160px" />
      <el-input v-model="search.nickName" class="filter-item" :placeholder="$t('Expert_Plan.专家昵称')" style="width: 160px" />
      <el-select v-model="search.articleStatus" class="filter-item" :placeholder="$t('Expert_Plan.方案状态')" clearable style="width: 160px">
        <el-option
          v-for="item in articleStatusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select v-model="search.sortByFiled" class="filter-item" :placeholder="$t('Expert_Plan.排序方式')" clearable style="width: 160px">
        <el-option :label="$t('发布时间')" value="a.create_time" />
        <el-option :label="$t('Expert_Plan.返还率')" value="a.returnrate" />
      </el-select>
      <el-button type="primary" class="filter-item" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <VisibleDialog ref="VisibleDialogControl" @update="_handleUpdate" />

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getExpertArticleList, doUpdateExpertArticleStatus, updateExpertArticleDetailById } from '@/api/expert'
import VisibleDialog from './VisibleDialog'

const insetparams = { sortByFiled: 'a.create_time' }

export default {
  name: 'MutualProgram',
  components: { VisibleDialog },
  mixins: [TablePageMixin(getExpertArticleList)],
  data() {
    return {
      tenant: null,
      filterColumn: false,
      columns: GetColumns.call(this).filter(e => !e.isHide),
      search: { sortByFiled: 'a.create_time' },
      articleStatusList: this.$t('articleStatusList'),
      tenantCode: this.$store.getters.tenantCode
    }
  },
  methods: {
    _handleUpdate({ field, cancel, close }) {
      updateExpertArticleDetailById({
        id: field.id,
        title: field.title,
        newBuyNum: field.newBuyNum,
        newView: field.newView,
        content: field.content,
        tenantCode: this.tenantCode,
        isTranslation: field.isTrans ? 1 : 0,
        translationName: field.isTranslation ? field.translationName : ''
      }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleUpdateStatus({ id, tenantCode }, articleStatus) {
      const confirm = articleStatus === 2 ? this.$t('Expert_Plan.确认通过此方案') : this.$t('Expert_Plan.确认禁止此方案')
      this.$confirm(confirm, this.$t('系统提示'), { type: 'warning' }).then(() => {
        doUpdateExpertArticleStatus({
          id,
          articleStatus,
          tenantCode
        }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    implementationGetParams() {
      return { ...this.search, ...insetparams, tenantCode: this.tenantCode }
    }
  }
}
</script>
