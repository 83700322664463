<template>
  <el-dialog :title="$t('添加限制')" width="700px" :visible.sync="visible" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
      <el-form-item :label="$t('限制内容')" prop="ip">
        <el-input v-model.trim="ruleForm.ip" :placeholder="$t('请输入限制内容')" />
      </el-form-item>
      <el-form-item :label="$t('限制说明')" prop="remark">
        <el-input v-model="ruleForm.remark" type="textarea" maxlength="240" show-word-limit :autosize="{ minRows: 4, maxRows: 8}" :placeholder="$t('请输入限制说明')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('取 消') }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">{{ $t('确 定') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { IP } from '@/utils/validation.js'

const getDefaultForm = () => ({
  ip: '',
  remark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        ip: [
          { required: true, message: this.$t('请出入限制内容'), trigger: 'blur' },
          { pattern: IP, message: this.$t('请输入正确IP'), trigger: 'blur' }
        ],
        remark: [
          { required: true, message: this.$t('请出入限制说明'), trigger: 'blur' },
          { min: 2, max: 240, message: this.$t('请输入2~240个字符'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm.id = row.id
        this.ruleForm.ip = row.ip
        this.ruleForm.remark = row.remark
        this.emitName = 'update'
      }

      this.visible = true
    }
  }
}
</script>
