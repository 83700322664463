<template>
  <el-dialog
    :title="`${ params.issue }期 ${$t('结算统计')}`"
    width="600px"
    :visible.sync="visible"
    @open="onOpen"
    @closed="onClosed"
  >
    <div v-loading="loading">
      <ul v-if="resource" class="statistics-table">
        <li>
          <label>{{ $t('总订单数') }}</label>
          <span>{{ resource.orderNum }}</span>
        </li>
        <li>
          <label>{{ $t('总投注金额') }}</label>
          <span>{{ resource.betsAmount }}</span>
        </li>
        <li>
          <label>{{ $t('总中奖单数') }}</label>
          <span>{{ resource.winOrderNum }}</span>
        </li>
        <li>
          <label>{{ $t('总中奖金额') }}</label>
          <span>{{ resource.winAmount }}</span>
        </li>
        <li>
          <label>{{ $t('总下注人数') }}</label>
          <span>{{ resource.betsPeopleNum }}</span>
        </li>
        <li>
          <label>{{ $t('总盈亏') }}</label>
          <span>{{ resource.betsAmount - resource.winAmount }}</span>
        </li>
      </ul>
      <div v-else class="statistics-table-empty">{{ $t('暂无数据') }}</div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('关闭') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getDrawLotteryStatistics } from '@/api/lottery'
export default {
  data() {
    return {
      visible: false,
      loading: false,
      params: {},
      resource: {}
    }
  },
  methods: {
    open({ lotteryId, issue }) {
      this.params = { lotteryId, issue }
      this.visible = true
    },
    onOpen() {
      this.loading = true
      getDrawLotteryStatistics({ ...this.params }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.resource = data
        }
      })
    },
    close() {
      this.visible = false
    },
    onClosed() {
      this.params = {}
      this.resource = {}
    }
  }
}
</script>

<style lang="scss" scoped>
.statistics-table {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  & > li {
    padding: 0 10px;
    list-style: none;
    flex: 0 50%;
    line-height: 30px;
    display: flex;
    align-items: center;
    &:nth-child(4n+1),
    &:nth-child(4n+2) {
      background-color: #f4f4f4;
    }
    & > label {
      flex: 0 50%;
      font-weight: initial;
    }
    & > span {
      flex: 0 50%;
    }
  }

  &-empty {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
