<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7)">{{ $t('_7天') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <div class="filter-container">
      <el-select v-model="search.memberType" clearable :placeholder="$t('用户类型')" class="filter-item" style="width:160px">
        <el-option v-for="item in memberTypes" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-input v-model="search.memberName" clearable :placeholder="$t('会员账号')" class="filter-item" style="width: 150px" />
      <el-input v-model="search.postscript" clearable :placeholder="$t('附言')" class="filter-item" style="width: 150px" />
      <el-input v-model="search.proxyCode" :placeholder="$t('所属代理')" clearable class="filter-item" style="width: 150px" />
      <el-select v-model="search.rechargeTimes" :placeholder="$t('充值状态')" clearable class="filter-item" style="width: 150px">
        <el-option v-for="item in rechargeStatus" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-select v-model="search.payPlatformCode" :placeholder="$t('平台名称')" clearable class="filter-item" style="width: 150px">
        <el-option v-for="item in payThirdConfList" :key="item.id" :label="item.payPlatformName" :value="item.payPlatformCode" />
      </el-select>
    </div>
    <div class="filter-container">
      <el-select v-model="search.paywayId" :placeholder="$t('支付方式')" clearable class="filter-item" style="width: 150px">
        <el-option v-for="item in paymentWayConfList" :key="item.paywayId" :label="item.paywayName" :value="item.paywayId" />
      </el-select>
      <el-select v-model="search.status" :placeholder="$t('支付状态')" clearable class="filter-item" style="width: 150px">
        <el-option v-for="item in paymentStatus" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-input v-model="search.orderNo" clearable :placeholder="$t('订单号')" class="filter-item" style="width: 150px" />
      <el-input v-model="search.thirdNo" clearable :placeholder="$t('平台流水号')" class="filter-item" style="width: 150px" />
      <el-input v-model="search.operatorName" clearable :placeholder="$t('操作员')" class="filter-item" style="width: 150px" />
    </div>

    <d-table
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template #left>
        <template v-if="statistical">
          {{ $t('订单总额') }}：{{ statistical.sumAmount || '0.000' }}<template v-if="statistical.sumAmountGroupByStatus.length>0">，</template>
          <span v-for="(item,index) in statistical.sumAmountGroupByStatus" :key="item.status">{{ item.status | filterStatus }}：{{ item.sum }}
            <template v-if="statistical.sumAmountGroupByStatus.length-1>index">，</template>
          </span>
          {{ $t('赠送金额') }}：{{ statistical.giveAmount || '0.000' }}
        </template>
      </template>
      <template #right>
        <el-button plain type="primary" :loading="excelLoading1" icon="el-icon-download" @click="_handleExport">{{ $t('导出Excel') }}</el-button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <UserDialog ref="UserDialogControl" />
  </div>
</template>
<script>
import { getPaymentOrderList, compensateOnlineOrder, getPayWayThirdConfList, getPaymentWayConfList, thirdOrderDownload } from '@/api/cash'
import UserDialog from '@/views/components/UserDialog'
import TablePageMixin from '@/mixins/table-page-mixin'
import { paymentStatus } from '@/libs/options'
import GetColumns from './columns'
import { getDefaultTimes, exportExcel } from '@/utils'
const defaultParams = () => ({
  orderType: 0
})

export default {
  name: 'CashThirdParty',
  components: { UserDialog },
  filters: {
    filterStatus(status) {
      const tmp = paymentStatus.find(o => o.key === status)
      return tmp && tmp.label
    }
  },
  mixins: [TablePageMixin(getPaymentOrderList, true)],
  data() {
    return {
      times: getDefaultTimes(),
      search: defaultParams(),
      excelLoading1:false,
      paymentStatus,
      columns: [],
      defaultColumns: GetColumns.call(this),
      paymentWayConfList: [],
      payThirdConfList: [],
      statistical: null,
      rechargeStatus: this.$t('rechargeStatus'),
      memberTypes: this.$t('memberTypes'),
    }
  },
  mounted() {
    this.implementationTenantChanged()
  },
  methods: {
    _onDetails({ memberId }) {
      if (!this.permission.Details) return
      this.$refs.UserDialogControl.open(memberId)
    },

    _handleExport() {
      const query = {
        ...this.implementationGetParams(),
        ...defaultParams()
      }
      this.excelLoading1 = true
      thirdOrderDownload(query).then(([data, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `第三方充值-${this.$parseTime(new Date())}.csv`)
        }
      }).finally(()=>{
        this.excelLoading1 = false
      })
    },

    handleAgain(row) {
      this.$confirm(this.$t('确认进行补单'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        compensateOnlineOrder({
          orderNo: row.orderNo,
          status: '0000'
        }).then(([_, err]) => {
          if (!err) {
            this.handleFetch()
            this.$message.success(this.$t('操作成功'))
          }
        }).catch(() => {})
      })
    },

    implementationTenantChanged() {
      const params = { pageSize: 100, currentPage: 1 }
      getPaymentWayConfList(params).then(([data, err]) => {
        if (!err) {
          this.paymentWayConfList = data
        }
      })
      getPayWayThirdConfList(params).then(([data, err]) => {
        if (!err) {
          this.payThirdConfList = data
        }
      })
    },

    implementationFetched({ sumAmount, sumAmountGroupByStatus = [], giveAmount }) {
      this.statistical = { giveAmount, sumAmount, sumAmountGroupByStatus: sumAmountGroupByStatus.filter(e => e.sum !== undefined) }
    },

    implementationGetParams() {
      return this.formatIntervalTime()
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
