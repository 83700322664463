
import axios, { isCancel } from "axios";
import store from "@/store";
import { MessageBox, Message } from "element-ui";
import { getToken } from "@/utils/auth";
import { getLang, $t } from "@/lang/index";
import { filterResponseStatus } from "./error-response";
import { Base64 } from "@/views-prox/utils/base64Confg.js";
import { generate16DigitId } from "@/utils/index";

const base64 = new Base64();
import sm4 from "@/views-prox/utils/sm4.js";
import md5 from "js-md5";
const getQuery = (name) => {
  const match = location.hash.match(new RegExp(name + "=([^&]+)"));
  return match && match.length == 2 ? match[1] : "";
};
const noGetAuthList = [new RegExp("backmanager/login")];
function createError(e) {
  let err;
  if (typeof e === "string") {
    err = createError(new Error(e));
  } else {
    err = {
      name: e.name,
      message: e.message,
      stack: e.stack,
      isCancel: isCancel(e),
    };
  }
  return err;
}

function handleErrorCode(res, response) {
  const xBgReqIdMsg = 'X-BG-REQ-ID是: ' + response.config.headers['X-BG-REQ-ID'];
  if (res.code === "401") {
    MessageBox.confirm(
      "您已注销，可以取消以保留在该页面上，或者再次登录, " + xBgReqIdMsg,
      "系统提示",
      {
        confirmButtonText: "重新登入",
        cancelButtonText: "取消",
        type: "warning",
      }
    )
      .then(() => {
        store.dispatch("user/logout").then(() => {
          location.reload();
        });
      })
      .catch(() => {});
  } else if (res.code === "403") {
    MessageBox.confirm(
      "你暂无权限进行此操作，请联系系统管理员，或者选择其他账号登录, " + xBgReqIdMsg,
      "系统提示",
      {
        confirmButtonText: "重新登入",
        type: "warning",
      }
    )
      .then(() => {
        store.dispatch("user/logout").then(() => {
          location.reload();
        });
      })
      .catch(() => {});
  } else {
    Message({
      message: (res.msg || "Error") + xBgReqIdMsg,
      type: "error",
    });
    // console.error('res.message')
  }
}

// create an axios instance
export default function createService(
  url,
  successCode = "200",
  toChtatRoom = false
) {
  const service = axios.create({
    baseURL: (url || process.env.VUE_APP_BASE_API), // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 60000,
  });

  // request interceptor
  service.interceptors.request.use(
    (config) => {
      // 添加 X-BG-REQ-ID header
      const uuid = generate16DigitId()
      config.headers['X-BG-REQ-ID'] = uuid;
      config.headers["X-TIMESTAMP"] = new Date().getTime();      
      //'X-DEVICE-ID': 'H5|' + client.getFingerprint(),
      config.headers["X-DEVICE-ID"] = "H5|" + new Date().getTime();
      config.headers["Request-Encrypt"] = !getQuery("decrypt"); //这个是后端控制 前端没有用
      if (process.env.NODE_ENV == "prox") {
        config.headers["Request-Encrypt"] = false;
      }
      if (toChtatRoom) {
        return config;
      }
      let args = { get: config.params, post: config.data }[config.method];
      if (args) {
        //todo:这里会影响到其他13位的参数
        Object.entries(args).forEach(([k, v]) => {
          // 时间戳转北京时间
          if (/^\d{13}$/.test(v) && k !== "orderNo") {
            args[k] = v - (new Date().getTimezoneOffset() + 480) * 60 * 1000;
          }
        });
      }

      config.headers["lang"] = getLang();
      if (store.getters.token) {
        config.headers["X-AUTH-TOKEN"] = getToken();
      }
      // if (store.getters.tenantCode) {
      //   config.headers['X-TENANT-CODE'] = store.getters.tenantCode||'CSZH'
      // }
      config.headers["X-TENANT-CODE"] = store.getters.tenantCode || "CSZH";
      let params = config.data || config.params;
      if (params && typeof params === "object" && params.length > 0) {
        const headerLang = params[0].headerLang;
        if (headerLang) {
          config.headers["lang"] = headerLang;
          params.forEach((e) => {
            delete e.headerLang;
          });
        }
      } else if (params && params.headerLang) {
        config.headers["lang"] = params.headerLang;
        delete params.headerLang;
      }
      return config;
    },
    (error) => {
      // do something with request error
      console.error(error); // for debug
      return Promise.reject(error);
    }
  );

  // response interceptor
  service.interceptors.response.use(
    (response) => {  
      if (response.headers["response-encrypt"] == "true") {
        let deData = null;
        if (!noGetAuthList.some((it) => it.test(response.config.url))) {
          let timestamp = getToken();
          let key32 = md5(timestamp).toUpperCase();
          //sm4解密
          const msg = base64.base64ToHex(response.data); //base64转16进制
          deData = JSON.parse(sm4.decrypt(msg, key32));
        }
        response.data = deData;
      }
      if (response.config.responseType === "blob") {
        if (response.headers["content-type"] !== "application/json") {
          return Promise.resolve([null, null, response.data]);
        } else {
          return response.data.text().then((result) => {
            const blobResponse = JSON.parse(result);
            handleErrorCode(blobResponse, response);
            return Promise.resolve([
              null,
              createError(blobResponse.msg),
              response.data,
            ]);
          });
        }
      } else {
        // if (!response.data.code) {
        //   return response.data
        // }
        const res = response.data;
        if (res.code !== successCode && res.code !== +successCode) {
          // interceptorsError(res.code, store, res.msg)
          handleErrorCode(res, response);
          return [res.data, createError(res.msg || ""), response];
        } else {
          return Promise.resolve([res.data || res.items, null, response]);
        }
      }
    },
    (error) => {
      const xBgReqId = error.config.headers['X-BG-REQ-ID'];
      if (error && error.response) {
        error = filterResponseStatus(error);
      }
      if (error.message.includes("timeout")) {
        error.message = $t("请求超时，请稍后重试");
      }
      Message({
        message: (error.response?.data?.msg || error.message || "Error") + `, X-BG-REQ-ID是: ${xBgReqId}`,
        type: "error",
      });
      console.error((error.response?.data?.msg || error.message || "Error") + `, X-BG-REQ-ID是: ${xBgReqId}`);
      return Promise.resolve([null, createError(error), error.response]);
    }
  );
  return service;
}
