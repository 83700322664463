<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="400px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="所属租户" prop="tenantCode">
        <el-select v-model="ruleForm.tenantCode" style="width: 100%" placeholder="请选择租户">
          <el-option v-for="item in $parent.tenants" :key="item.tenantCode" :label="item.tenantName" :value="item.tenantCode" />
        </el-select>
      </el-form-item>
      <el-form-item label="线路名称" prop="domainType">
        <el-select v-model="ruleForm.domainType" placeholder="域名类型">
          <el-option v-for="(val, key) in domainTypes" :key="key" :label="val" :value="+key" />
        </el-select>
      </el-form-item>
      <el-form-item label="线路名称" prop="domainName">
        <el-input v-model="ruleForm.domainName" maxlength="10" placeholder="请输入线路名称" />
      </el-form-item>
      <el-form-item label="域名" prop="domainUrl">
        <el-input v-model="ruleForm.domainUrl" placeholder="域名格式必须以http或https开头" />
      </el-form-item>
      <el-form-item label="线路状态">
        <d-switch v-model="ruleForm.status" :active-value="1" :inactive-value="0">
          <span slot="open">启用</span>
          <span slot="close">禁用</span>
        </d-switch>
      </el-form-item>
      <el-form-item label="前端排序" prop="sort">
        <el-input v-model="ruleForm.sort" placeholder="请输入前端排序" type="number" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="ruleForm.remark"
          style="width: 240px"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6 }"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { domainTypes } from '@/libs/options'

const getDefaultForm = () => ({
  tenantCode: '',
  domainType: '',
  domainName: '',
  domainUrl: '',
  status: 1,
  sort: '',
  type: 0,
  remark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      domainTypes,
      rules: {
        tenantCode: [{ required: true, message: '请选择用户分组', trigger: 'change' }],
        domainType: [{ required: true, message: '请选择域名类型', trigger: 'change' }],
        domainName: [{ required: true, message: '请输入线路名称', trigger: 'blur' }],
        domainUrl: [{ required: true, message: '请输入域名', trigger: 'blur' }
          // { pattern: url, message: '请输入正确的域名', trigger: 'blur' } 需要修复
        ],
        sort: [{ required: true, message: '请输入前端排序', trigger: 'blur' }]
      }
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? '新增线路' : '修改线路'
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm.id = row.id
        this.ruleForm.tenantCode = row.tenantCode
        this.ruleForm.domainType = row.domainType
        this.ruleForm.domainName = row.domainName
        this.ruleForm.domainUrl = row.domainUrl
        this.ruleForm.status = row.status
        this.ruleForm.sort = row.sort
        this.ruleForm.type = row.type
        this.ruleForm.remark = row.remark
        this.emitName = 'update'
      }
      this.visible = true
    }
  }
}
</script>
