<template>
  <el-dialog
    :title="$t('房费流水')"
    :visible.sync="visible"
    width="75%"
    top="5vh"
    @open="handleRefresh"
  >
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="yyyy-MM-dd HH:mm:ss"
        :disabled="!summary"
        :default-time="['00:00:00', '23:59:59']"
      />
      <div v-if="summary" style="float:right">{{ $t('开播时段') }}: {{ summary.onlineDate | parseTime }} {{ $t('至') }} {{ summary.offlineDate | parseTime }}</div>
    </div>

    <div class="filter-container">
      <el-input v-model="search.nickName" :placeholder="$t('会员昵称')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.memberName" :placeholder="$t('会员账号')" class="filter-item" style="width: 140px" />
      <el-button type="primary" class="filter-item" @click="handleRefresh">{{ $t('检索') }}</el-button>
      <div v-if="summary" style="float:right">{{ $t('房费总额') }}: {{ summary.sumPaySumamt }} {{ $t('主播收入') }}：{{ summary.anchorIncome }}</div>
    </div>

    <el-table v-loading="loading" :data="items" v-bind="options">
      <el-table-column :label="$t('会员昵称')" prop="nickName" align="center" />
      <el-table-column :label="$t('会员账号')" prop="memberName" align="center" />
      <el-table-column :label="$t('停留时间(分)')" prop="retentionTimes" align="center" />
      <el-table-column :label="$t('房费支出')" prop="paySumamt" align="center" />
      <el-table-column :label="$t('房费分成')" prop="payroomIncome" align="center">
        <template slot-scope="{ row }">{{ row.payroomIncome }}({{ row.payroomPercent }}%)</template>
      </el-table-column>
      <el-table-column :label="$t('离场时间')" prop="leavingTate" align="center" width="160">
        <template slot-scope="{ row }">{{ row.leavingTate | parseTime }}</template>
      </el-table-column>
    </el-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

  </el-dialog>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import StreamMixin from './stream'
import { getLiveBroadcastPayroomRecordList, getLiveBroadcastPayroomRecordSummary } from '@/api/live'

export default {
  mixins: [TablePageMixin(getLiveBroadcastPayroomRecordList, false), StreamMixin],
  methods: {
    implementationFetched() {
      getLiveBroadcastPayroomRecordSummary({
        ...this.implementationGetParams()
      }).then(([data, err]) => {
        if (!err) this.summary = data
      })
    }
  }
}
</script>
