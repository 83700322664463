<template>
  <div>
    <div class="filter-container">
      <div class="filter-container-item">
        <el-date-picker
          v-model="times"
          type="datetimerange"
          style="width: 400px;"
          class="filter-item"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
        />
        <el-input v-model="search.nickname" class="filter-item" placeholder="昵称" style="width: 160px" />
        <el-select v-model="search.freezeStatus" class="filter-item" placeholder="状态" clearable style="width: 140px">
          <el-option label="正常" :value="0" />
          <el-option label="删除" :value="1" />
        </el-select>
        <el-button class="filter-item" type="success" icon="el-icon-search" @click="handleSearch">查询</el-button>
        <el-button class="filter-item" type="success" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      @selection-change="handleSelectionChange"
    >
      <template slot="left">
        <button v-if="permission.BatchDel" class="el-button el-button--small el-button--orange" :disabled="!selection.length" @click="handleBatch(1)">批量删除</button>
        <button v-if="permission.BatchRecover" class="el-button el-button--small el-button--orange" :disabled="!selection.length" @click="handleBatch(0)">批量恢复</button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <EditDialog ref="EditDialogControl" @update="handleUpdate" />

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import EditDialog from './EditDialog'
import {
  getBackUserList,
  updateBackUser,
  batchUpdateBackUserStatus
} from '@/api/interactive'
import { getDefaultTimes } from '@/utils'

export default {
  name: 'BackTab',
  componentName: 'BackTab',
  components: { EditDialog },
  mixins: [TablePageMixin(getBackUserList)],
  data() {
    return {
      times: getDefaultTimes(),
      filterColumn: false,
      search: {},
      columns: GetColumns.call(this),
      selection: []

    }
  },
  methods: {
    handleBatch(freezeStatus) {
      batchUpdateBackUserStatus({
        freezeStatus,
        memberIds: this.selection.map(o => o.memberId)
      }).then(([data, err]) => {
        if (!err) {
          this.$message.success('批量操作成功')
          this.handleFetch()
        }
      })
    },
    handleUpdate({ field, cancel, close }) {
      updateBackUser({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success('编辑成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    handleSelectionChange(selection) {
      this.selection = selection
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    }
  }
}
</script>
