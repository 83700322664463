<template>
  <div>
    <el-form ref="validateForm" v-loading="loading" :model="ruleForm" :rules="rules">
      <el-form-item>
        <el-radio-group v-model="headerLang" style="margin-right: 6px;" @change="onOpen">
          <el-radio-button v-for="(item, index) in allLang" :key="index" :label="item.lang">{{ item.name
          }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="desc">
        <tinymce ref="tinymce" v-model="ruleForm.explain" style="width:calc(100% - 100px)" :height="300" />
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-popconfirm :title="$t('确定保存配置信息吗?')" @confirm="handleSubmit">
        <el-button slot="reference" type="primary" :loading="submitting">
          {{ $t('保存配置') }}
        </el-button>
      </el-popconfirm>

    </div>
  </div>
</template>
<script>
import Tinymce from '@/components/Tinymce'
import FormDialogMixin from '@/mixins/form-dialog'
import { getLotteryInfo, updateLotteryExplain } from '@/api/lottery'
import { mapGetters } from 'vuex'
const getDefaultForm = () => ({
  name: '',
  explain: '',
  headerLang: ''
})

export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      loading: false,
      emitName: 'update',
      rules: {
        explain: [
          { required: true, message: this.$t('请输入VIP等级'), trigger: 'blur' }
        ]
      },
      headerLang: ''
    }
  },
  computed: {
    ...mapGetters(['allLang'])
  },
  created() {
    this.headerLang = this.allLang[0].lang
    this.onOpen()
  },
  methods: {
    onOpen() {
      this.loading = true
      getLotteryInfo({
        lotteryId: this.$parent.lotteryId,
        headerLang: this.headerLang
      }, this.$parent.operatorType).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.ruleForm = data
          this.ruleForm.headerLang = this.headerLang
          this.$nextTick(() => {
            this.$refs.tinymce.hasInit && this.$refs.tinymce.setContent(data.explain || '')
          })
        }
      })
    },
    handleSubmit() {
      updateLotteryExplain({
        lotteryId: this.ruleForm.lotteryId,
        explain: this.ruleForm.explain,
        headerLang: this.ruleForm.headerLang
      }, this.operatorType).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('修改成功'))
        } else {
          this.submitting = false
        }
      })
    }
  }
}
</script>
