var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "210px" },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "basic_config_content",
          attrs: { gutter: 96 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("h2", [_vm._v(_vm._s(_vm.$t("登录注册")))]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("同IP每日注册个数"),
                    prop: "ipRegistNum",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.ipRegistNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "ipRegistNum", _vm._n($$v))
                      },
                      expression: "ruleForm.ipRegistNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("同IP每日登录个数"),
                    prop: "ipLoginNum",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.ipLoginNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "ipLoginNum", _vm._n($$v))
                      },
                      expression: "ruleForm.ipLoginNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("前端输入密码错误冻结次数"),
                    prop: "passwordWrongNum",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.passwordWrongNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "passwordWrongNum", _vm._n($$v))
                      },
                      expression: "ruleForm.passwordWrongNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("后台Google验证"),
                    prop: "googleStatus",
                  },
                },
                [
                  _c(
                    "d-switch",
                    {
                      model: {
                        value: _vm.ruleForm.googleStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "googleStatus", $$v)
                        },
                        expression: "ruleForm.googleStatus",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v(_vm._s(_vm.$t("开启"))),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v(_vm._s(_vm.$t("关闭"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "C端图形验证码", prop: "isVerifi" } },
                [
                  _c(
                    "d-switch",
                    {
                      model: {
                        value: _vm.ruleForm.isVerifi,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isVerifi", $$v)
                        },
                        expression: "ruleForm.isVerifi",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v(_vm._s(_vm.$t("开启"))),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v(_vm._s(_vm.$t("关闭"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "真实姓名开关", prop: "realNameSwitch" } },
                [
                  _c(
                    "d-switch",
                    {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.realNameSwitch,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "realNameSwitch", $$v)
                        },
                        expression: "ruleForm.realNameSwitch",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v(_vm._s(_vm.$t("开启"))),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v(_vm._s(_vm.$t("关闭"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "新设备安全验证",
                    prop: "newCheckDeviceSwitch",
                  },
                },
                [
                  _c(
                    "d-switch",
                    {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.newCheckDeviceSwitch,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "newCheckDeviceSwitch", $$v)
                        },
                        expression: "ruleForm.newCheckDeviceSwitch",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v(_vm._s(_vm.$t("开启"))),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v(_vm._s(_vm.$t("关闭"))),
                      ]),
                    ]
                  ),
                  _vm._v(
                    " 提示：开启时，已绑定手机号码的账号更换设备账号密码登录时需要进行手机验证码验证 "
                  ),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("购彩配置")))]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("是否允许用户撤单"),
                    prop: "cancelOrderStatus",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.cancelOrderStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "cancelOrderStatus", $$v)
                        },
                        expression: "ruleForm.cancelOrderStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v(_vm._s(_vm.$t("启用"))),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(_vm._s(_vm.$t("关闭"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("投注默认一注额度"),
                    prop: "betAmount",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.betAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "betAmount", _vm._n($$v))
                        },
                        expression: "ruleForm.betAmount",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("￥")])],
                    2
                  ),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("代理返点")))]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("一级返佣比例"),
                    prop: "oneLevelRakeBack",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.oneLevelRakeBack,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "oneLevelRakeBack", $$v)
                        },
                        expression: "ruleForm.oneLevelRakeBack",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("二级返佣比例"),
                    prop: "twoLevelRakeBack",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.twoLevelRakeBack,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "twoLevelRakeBack", $$v)
                        },
                        expression: "ruleForm.twoLevelRakeBack",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("结算方式"), prop: "settlementType" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.settlementType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "settlementType", $$v)
                        },
                        expression: "ruleForm.settlementType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(_vm._s(_vm.$t("人工"))),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v(_vm._s(_vm.$t("自动"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h2", [_vm._v("游戏返水")]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "返水周期设置",
                    prop: "lotteryBackCalculateDay",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.lotteryBackCalculateDay,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "lotteryBackCalculateDay", $$v)
                        },
                        expression: "ruleForm.lotteryBackCalculateDay",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "每天" } }),
                      _c("el-option", { attrs: { value: 1, label: "周一" } }),
                      _c("el-option", { attrs: { value: 2, label: "周二" } }),
                      _c("el-option", { attrs: { value: 3, label: "周三" } }),
                      _c("el-option", { attrs: { value: 4, label: "周四" } }),
                      _c("el-option", { attrs: { value: 5, label: "周五" } }),
                      _c("el-option", { attrs: { value: 6, label: "周六" } }),
                      _c("el-option", { attrs: { value: 7, label: "周日" } }),
                    ],
                    1
                  ),
                  _vm._v(" #返水周期，筛选每天、周一..到周日 "),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "返水时间",
                    prop: "lotteryBackCalculateTime",
                  },
                },
                [
                  _c("el-time-picker", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      "picker-options": {
                        selectableRange: "00:00:00 - 23:59:59",
                      },
                      placeholder: "返水时间",
                      "value-format": "HH:mm:ss",
                    },
                    model: {
                      value: _vm.ruleForm.lotteryBackCalculateTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "lotteryBackCalculateTime", $$v)
                      },
                      expression: "ruleForm.lotteryBackCalculateTime",
                    },
                  }),
                  _vm._v(" #时钟筛选，设置具体返水时间 "),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("国家区号")))]),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("区号"), prop: "regins" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder:
                        "$t('登录注册')区号前需加上+号，支持填入多个国家区号，用英文逗号分开，例：+86,+84",
                    },
                    model: {
                      value: _vm.ruleForm.regins,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "regins", $$v)
                      },
                      expression: "ruleForm.regins",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("维护")))]),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("前端维护"), prop: "maintainStatus" },
                },
                [
                  _c(
                    "d-switch",
                    {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.maintainStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "maintainStatus", $$v)
                        },
                        expression: "ruleForm.maintainStatus",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v(_vm._s(_vm.$t("开启"))),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v(_vm._s(_vm.$t("关闭"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("维护说明"), prop: "maintainInfo" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      maxlength: "100",
                      "show-word-limit": "",
                      placeholder: _vm.$t("请输入内容"),
                    },
                    model: {
                      value: _vm.ruleForm.maintainInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "maintainInfo", $$v)
                      },
                      expression: "ruleForm.maintainInfo",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v("域名配置")]),
              _c(
                "el-form-item",
                { attrs: { label: "心水域名", prop: "favoriteDomain" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.favoriteDomain,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "favoriteDomain", $$v)
                      },
                      expression: "ruleForm.favoriteDomain",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "聊天API域名", prop: "chatApiDomain" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.chatApiDomain,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "chatApiDomain", $$v)
                      },
                      expression: "ruleForm.chatApiDomain",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "聊天Socket域名", prop: "chatSocketDomain" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.chatSocketDomain,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "chatSocketDomain", $$v)
                      },
                      expression: "ruleForm.chatSocketDomain",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "直播开奖域名", prop: "liveDrawingDomain" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.liveDrawingDomain,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "liveDrawingDomain", $$v)
                      },
                      expression: "ruleForm.liveDrawingDomain",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("h2", [_vm._v(_vm._s(_vm.$t("存取款配置")))]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("提款、入款开始处理时间"),
                    prop: "startTime",
                  },
                },
                [
                  _c("el-time-select", {
                    attrs: {
                      placeholder: _vm.$t("开始时间"),
                      "picker-options": {
                        start: "00:00",
                        step: "01:00",
                        end: "24:00",
                        maxTime: _vm.ruleForm.endTime,
                      },
                    },
                    model: {
                      value: _vm.ruleForm.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "startTime", $$v)
                      },
                      expression: "ruleForm.startTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("提款、入款结束处理时间"),
                    prop: "endTime",
                  },
                },
                [
                  _c("el-time-select", {
                    attrs: {
                      placeholder: _vm.$t("结束时间"),
                      "picker-options": {
                        start: "00:00",
                        step: "01:00",
                        end: "24:00",
                        minTime: _vm.ruleForm.startTime,
                      },
                    },
                    model: {
                      value: _vm.ruleForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "endTime", $$v)
                      },
                      expression: "ruleForm.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("提款最低额度"),
                    prop: "withdrawMinAmount",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.withdrawMinAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "withdrawMinAmount", $$v)
                        },
                        expression: "ruleForm.withdrawMinAmount",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("￥")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("提款最高额度"),
                    prop: "withdrawMaxAmount",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.withdrawMaxAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "withdrawMaxAmount", $$v)
                        },
                        expression: "ruleForm.withdrawMaxAmount",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("￥")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("2次充值时间的间隔"),
                    prop: "rechargeInterval",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.rechargeInterval,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "rechargeInterval",
                            _vm._n($$v)
                          )
                        },
                        expression: "ruleForm.rechargeInterval",
                      },
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(_vm._s(_vm.$t("秒"))),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("2次提款时间的间隔"),
                    prop: "withdrawInterval",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.withdrawInterval,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "withdrawInterval",
                            _vm._n($$v)
                          )
                        },
                        expression: "ruleForm.withdrawInterval",
                      },
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(_vm._s(_vm.$t("秒"))),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("提款次数只记录成功次数"),
                    prop: "recordWithdrawStatus",
                  },
                },
                [
                  _c(
                    "d-switch",
                    {
                      model: {
                        value: _vm.ruleForm.recordWithdrawStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "recordWithdrawStatus", $$v)
                        },
                        expression: "ruleForm.recordWithdrawStatus",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v(_vm._s(_vm.$t("开启"))),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v(_vm._s(_vm.$t("关闭"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("存款未处理记录失效时间"),
                    prop: "rechargeInvalidTime",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.rechargeInvalidTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "rechargeInvalidTime", $$v)
                        },
                        expression: "ruleForm.rechargeInvalidTime",
                      },
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(_vm._s(_vm.$t("小时"))),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("提款未处理记录失效时间"),
                    prop: "withdrawInvalidTime",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.withdrawInvalidTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "withdrawInvalidTime", $$v)
                        },
                        expression: "ruleForm.withdrawInvalidTime",
                      },
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(_vm._s(_vm.$t("小时"))),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("新增修改银行卡不能提款时间"),
                    prop: "bankWithdrawForbid",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.bankWithdrawForbid,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "bankWithdrawForbid",
                            _vm._n($$v)
                          )
                        },
                        expression: "ruleForm.bankWithdrawForbid",
                      },
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(_vm._s(_vm.$t("分钟"))),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("绑定银行卡数量"),
                    prop: "bankBindNum",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.bankBindNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "bankBindNum", _vm._n($$v))
                      },
                      expression: "ruleForm.bankBindNum",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("直播")))]),
              _c(
                "el-form-item",
                { attrs: { label: "直播开关", prop: "liveShow" } },
                [
                  _c(
                    "d-switch",
                    {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.liveShow,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "liveShow", $$v)
                        },
                        expression: "ruleForm.liveShow",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v(_vm._s(_vm.$t("开启"))),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v(_vm._s(_vm.$t("关闭"))),
                      ]),
                    ]
                  ),
                  _vm._v(" 关闭后用户进入首页:不显示直播入口 "),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("主播联系方式金额"),
                    prop: "anchorContactAmount",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.anchorContactAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "anchorContactAmount", $$v)
                      },
                      expression: "ruleForm.anchorContactAmount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("广播礼物金额"),
                    prop: "broadcastGiftAmount",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.broadcastGiftAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "broadcastGiftAmount", $$v)
                      },
                      expression: "ruleForm.broadcastGiftAmount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("免费时长设置"), prop: "freeTime" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "5-300" },
                      model: {
                        value: _vm.ruleForm.freeTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "freeTime", _vm._n($$v))
                        },
                        expression: "ruleForm.freeTime",
                      },
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(_vm._s(_vm.$t("秒"))),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("图形验证码错误次数")))]),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("错误次数"), prop: "imgCaptchaNum" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "5-300" },
                    model: {
                      value: _vm.ruleForm.imgCaptchaNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "imgCaptchaNum", _vm._n($$v))
                      },
                      expression: "ruleForm.imgCaptchaNum",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v("欧洲杯精选")]),
              _c(
                "el-form-item",
                { attrs: { label: "欧洲杯精选开关", prop: "eurocupStatus" } },
                [
                  _c(
                    "d-switch",
                    {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.eurocupStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "eurocupStatus", $$v)
                        },
                        expression: "ruleForm.eurocupStatus",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v(_vm._s(_vm.$t("开启"))),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v(_vm._s(_vm.$t("关闭"))),
                      ]),
                    ]
                  ),
                  _vm._v(" 关闭后不显示欧洲杯精选入口 "),
                ],
                1
              ),
              _c("h2", [_vm._v("新版首页在线人数设置")]),
              _c(
                "el-form-item",
                { attrs: { label: "虚拟人数", prop: "virtualUserCount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "虚拟人数" },
                    model: {
                      value: _vm.ruleForm.virtualUserCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "virtualUserCount", _vm._n($$v))
                      },
                      expression: "ruleForm.virtualUserCount",
                    },
                  }),
                  _vm._v(" 提示：首页在线人数=虚拟人数+真实在线人数 "),
                ],
                1
              ),
              _c("h2", [_vm._v("新版资源配置")]),
              _c(
                "el-form-item",
                { attrs: { label: "YouTube", prop: "youtubeAddress" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "YouTube" },
                    model: {
                      value: _vm.ruleForm.youtubeAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "youtubeAddress", $$v)
                      },
                      expression: "ruleForm.youtubeAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Facebook", prop: "facebookAddress" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Facebook" },
                    model: {
                      value: _vm.ruleForm.facebookAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "facebookAddress", $$v)
                      },
                      expression: "ruleForm.facebookAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Telegram", prop: "telegramAddress" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Telegram" },
                    model: {
                      value: _vm.ruleForm.telegramAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "telegramAddress", $$v)
                      },
                      expression: "ruleForm.telegramAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "action-button" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("保 存")))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$refs["ruleForm"].resetFields()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("重 置")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }