<template>
  <div class="bodys">
    <el-form
      ref="validateForm"
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      label-width="220px"
    >
      <el-form-item label="AI聊天开关" prop="aiChatEnabled">
        <d-switch
          v-model="ruleForm.aiChatEnabled"
          :inactive-value="0"
          :active-value="1"
        />
      </el-form-item>

      <template v-if="ruleForm.aiChatEnabled">
        <el-form-item label="机器人自动聊天最大回合" prop="maxReplies">
          <el-input
            v-model="ruleForm.maxReplies"
            style="width: 420px"
            placeholder="请输入聊天最大回合"
            @input="validateInput('maxReplies')"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="机器人自动聊天最小延迟" prop="minDelaySeconds">
          <el-input
            v-model="ruleForm.minDelaySeconds"
            style="width: 420px"
            placeholder="请输入最小延迟"
            @input="validateInput('minDelaySeconds')"
          >
            <template slot="append">秒</template>
          </el-input>
        </el-form-item>

        <el-form-item label="机器人自动聊天最大延迟" prop="maxDelaySeconds">
          <el-input
            v-model="ruleForm.maxDelaySeconds"
            style="width: 420px"
            placeholder="请输入最大延迟"
            @input="validateInput('maxDelaySeconds')"
          >
            <template slot="append">秒</template>
          </el-input>
        </el-form-item>
      </template>

      <el-form-item>
        <el-button type="primary" :loading="submitting" @click="handleSubmit">
          保存
        </el-button>
        <!-- AI聊天室管理
        AI机器人配置
        聊天室快捷问题
        聊天室快定时消息
        客服相关接口 -->
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { setAiChatDetailList,getAiChatDetailList } from "@/api/interactive";
export default {
  name: "aiReportOptionTab",
  componentName: "aiReportOptionTab",
  watch: {
    optionData(newVal) {
      this.ruleForm = { ...newVal };
    },
  },

  data() {
    const validMaxReplies = (rule, value, callback) => {
      if (!value) {
        callback(new Error("机器人自动聊天最大回复次数不能为空"));
      } else if (value < 0) {
        callback(new Error("限制只能输入大于0的数值，单位为秒"));
      } else if (value > 500) {
        callback(new Error("机器人自动聊天最大回复次数不能超过500"));
      } else {
        callback();
      }
    };

    const validMinDelaySeconds = (rule, value, callback) => {
      if (!value) {
        callback(new Error("机器人自动聊天最小延迟不能为空"));
      } else if (value < 3) {
        callback(new Error("限制只能输入大于等于3的数值，单位为秒"));
      } else if (value > this.ruleForm.maxDelaySeconds) {
        callback(new Error("机器人聊天最小延迟不能大于最大延迟"));
      } else {
        callback();
      }
    };

    const validMaxDelaySeconds = (rule, value, callback) => {
      if (!value) {
        callback(new Error("机器人自动聊天最大延迟不能为空"));
      } else if (value < 0) {
        callback(new Error("限制只能输入大于0的数值，单位为秒"));
      } else if (value < this.ruleForm.minDelaySeconds) {
        callback(new Error("机器人聊天最大延迟不能小于最小延迟"));
      } else {
        callback();
      }
    };

    return {
      loading: false,
      submitting: false,
      ruleForm: {
        aiChatEnabled: 0,
        maxReplies: "",
        minDelaySeconds: "",
        maxDelaySeconds: "",
      },
      rules: {
        aiChatEnabled: [
          { required: true, message: "请设置AI聊天状态", trigger: "change" },
        ],
        maxReplies: [
          { required: true, validator: validMaxReplies, trigger: "change" },
        ],
        minDelaySeconds: [
          {
            required: true,
            validator: validMinDelaySeconds,
            trigger: "change",
          },
        ],
        maxDelaySeconds: [
          {
            required: true,
            validator: validMaxDelaySeconds,
            trigger: "change",
          },
        ],
      },
    };
  },
  activated() {
    getAiChatDetailList().then((res) => {
      if (res && res[2].status == "200") {
        this.optionData = res[2].data.data;
        this.ruleForm = { ...this.optionData };
      }
    });
  },
  methods: {
    handleSubmit() {
      this.$refs["validateForm"].validate((valid) => {
        if (valid) {
          let params = this.ruleForm;
          if (!this.ruleForm.aiChatEnabled) {
            params = { aiChatEnabled: 0 };
          }
          setAiChatDetailList(params).then((res) => {
            // 不明的这里res为什么是个数组格式
            if (res[2].status == 200) {
              this.$message.success(this.$t("编辑成功"));
            }
          });
        } else {
          return false;
        }
      });
    },
    validateInput(str) {
      this.ruleForm[str] = this.ruleForm[str].replace(/[^0-9]/g, "")*1;
    },
  },
};
</script>

<style>
.bodys {
  padding: 30px;
}
</style>
