<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <span>VIP福利名称：</span>
        <el-input
        v-model="search.name"
        maxlength="20"
        clearable
        class="filter-item"
        style="width: 200px"
      />
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询')
          }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置')
          }}</el-button>
           <!-- <el-button icon="el-icon-plus" class="filter-item" type="success" @click="_onCreate">{{ $t('添加等级') }}</el-button> -->
      </div>
    </div>
    <d-table ref="DTable" v-loading="loading" :data="tableData" :columns="defaultColumns" :original-columns="defaultColumns"
      :options="options" :filterColumn="false" >
      <template #left>
        <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="_onCreate">
          {{ $t('添加') }}
        </el-button>
      </template>

      </d-table>
    <!-- <ActionDialog ref="ActionDialogControl" @create="handleCreateLevel" />
    <edit-dialog ref="EditDialogControl" @create="handleCreate" @update="hanldeUpdate" /> -->
    <ActionDialog ref="ActionDialogControl" @create="handleCreateLevel" @update="handleUpdateLevel" />
  
  </div>
</template>
<script>
import { getAppmemberlevelAwardCollection, setAppmemberlevelAwardCollection } from '@/api/vipRewardMange'
import { getAppAward,createAppAward,updateAppAward,deleteAppAward,addManagerVipLevel,deleteManagerVipLevel } from "@/api/member";
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns2'
import ActionDialog from './ActionDialog'
import { getAllVipLevels } from '@/api/app'
export default {
  name: 'LevelTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(getAppAward)],
  data() {
    return {
      search: {},
      defaultColumns: GetColumns.call(this),
      vipLevels: [],
    }
  },
  computed: {
    tableData() {
      return this.items.filter(item => item.awardCode !== 'ACTIVITY_CENTER')
    }
  },
  mounted() {
    this.implementationBeforeFetch()
  },
  methods: {
    _onCreate() {
      this.$refs.ActionDialogControl.open()
    },
    implementationBeforeFetch() {
      getAllVipLevels().then(([data, err]) => {
        if (!err) {
          this.vipLevels = data
        }
      })
    },
    handleUpdateLevel({ field, cancel, close }) {
      updateAppAward(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('修改成功')
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    handleDeleteAccount(id) {
      this.$confirm(this.$t('确认删除此设置?'), this.$t('系统提示'), { type: 'error' }).then(() => {
        deleteAppAward({ id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    handleCreate({ field, cancel, close }) {
      // createAgencyUser(field).then(([data, _err]) => {
      //   if (!_err) {
      //     close()
      //     this.$message.success(this.$t('添加成功'))
      //     this.handleFetch()
      //   } else {
      //     cancel()
      //   }
      // })
    },

    handleCreateLevel({ field, cancel, close }) {
      createAppAward({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('添加成功'))
        } else {
          cancel()
        }
      })
    },
  }
}
</script>
