<template>
  <div class="app-container">
    <statistics-member />
    <el-row :gutter="24">
      <el-col :span="12">
        <statistics-lottery />
      </el-col>
      <el-col :span="12">
        <statistics-third />
      </el-col>
    </el-row>

  </div>
</template>
<script>
import StatisticsMember from './statistics-member'
import StatisticsLottery from './statistics-lottery'
import StatisticsThird from './statistics-third'
export default {
  name: 'Tenant',
  components: { StatisticsMember, StatisticsLottery, StatisticsThird }
}
</script>
