import { withdrawStatus, rateTypes } from '@/libs/options'
import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('订单号'),
      key: 'orderNo',
      align: 'center'
    },
    {
      title: this.$t('拾取时间'),
      key: 'updateTime',
      align: 'center',
      width: 130,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          return (<clip-button text={row.memberName} classes='primary-type cursor-pointer' onClick={this._onDetails.bind(this, row)}></clip-button>)
        }
      }
    },
    {
      title: this.$t('所属代理'),
      key: 'proxyCode',
      align: 'center'
    },
    {
      title: this.$t('VIP等级'),
      key: 'vipLevel',
      align: 'center',
      width: 80,
      component: {
        render: (h, row) => {
          return (<span>VIP{row.vipLevel}</span>)
        }
      }
    },
    {
      title: this.$t('累计充值'),
      key: 'sumRecharge',
      align: 'center'
    },
    {
      title: this.$t('累计提现'),
      key: 'sumWithdraw',
      align: 'center'
    },
    {
      title: this.$t('申请金额'),
      key: 'amount',
      align: 'center',
      component: {
        render: (h, row) => {
          const amount = parseFloat(row.amount).toFixed(2)
          return (<clip-button text={amount} styles={{ fontWeight: 'bolder' }} classes={this.$parseColor(row.amount)}></clip-button>)
        }
      }
    },
    {
      title: this.$t('货币汇率'),
      align: 'center',
      component: {
        render: (h, row) => {
          const rateType = rateTypes.find(e => e.key === row.rateType)
          if (rateType) {
            return (
              <div>
                <p>{ rateType.label }</p>
                <span>{ row.paymentRate }</span>
              </div>
            )
          }
          return (
            <span>{ row.paymentRate }</span>
          )
        }
      }
    },
    {
      title: this.$t('货币金额'),
      key: 'paymentAmount',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span class={this.$parseColor(row.paymentAmount)}>{row.paymentAmount}</span>
          )
        }
      }
    },
    {
      title: this.$t('会员备注'),
      key: 'memberRemark',
      align: 'center',
      showOverflowTooltip: true
    },
    {
      title: this.$t("收款信息"),
      key: "receiveing",
      align: "center",
      width: 160,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{row.receivingName}</p>
              <p>{row.abpayType==null?row.receivingBank:['abpay-划转提现','abpay-微信提现','abpay-支付宝提现','abpay-银行卡提现'][row.abpayType]}</p>
              <p>
                {row.receivingCardNo.startsWith("http")
                  ? ""
                  : row.receivingCardNo}
              </p>
              {row.walletUrl&&<el-image 
               style="width: 100px; height: 100px"
               src={row.walletUrl}
               preview-src-list={[row.walletUrl]}>
             </el-image>}
            </div>
          );
        },
      },
    },
    {
      title: this.$t('订单状态'),
      key: 'status',
      align: 'center',
      width: 80,
      component: {
        render: (h, row) => {
          const tmp = withdrawStatus.find(o => o.key === row.status)
          return (<span>{ tmp && tmp.label || '' }</span>)
        }
      }
    },
    {
      title: this.$t('操作时间'),
      key: 'operationTime',
      align: 'center',
      width: 130,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.operationTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作说明'),
      key: 'remark',
      align: 'center'
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          const { Yes, NOT } = this.permission
          return (
            <span>
              { Yes && <el-button type='success' size='small' onClick={this.$refs.AloneDialogControl.open.bind(this, row, 4)}>{ this.$t('通过') }</el-button> }
              { NOT && <el-button type='danger' size='small' onClick={this.$refs.AloneDialogControl.open.bind(this, row, 3)}>{ this.$t('拒绝') }</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
