var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("cascader-select", {
        attrs: {
          options: _vm.playClass,
          "parent-label": _vm.$t("一级玩法"),
          "child-label": _vm.$t("二级玩法"),
        },
        on: { change: _vm.onChangeSelect },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c(
        "d-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "DTable",
          attrs: {
            "row-key": "attributeTag",
            data: _vm.items,
            columns: _vm.columns,
            "original-columns": _vm.defaultColumns,
            options: _vm.options,
          },
          on: { "column-change": _vm.handleColumnChange },
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _vm.permission.Lottery
                ? _c(
                    "el-button",
                    {
                      attrs: { plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.PresentDialogControl.open()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("彩种介绍设置")))]
                  )
                : _vm._e(),
              _vm.permission.SetOdds
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.NumberDialogControl.open("odds")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("批量设置赔率")))]
                  )
                : _vm._e(),
              _vm.permission.Min
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.NumberDialogControl.open(
                            "minBetsMoney"
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("最小金额批量设置")))]
                  )
                : _vm._e(),
              _vm.permission.Max
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.NumberDialogControl.open(
                            "maxBetsMoney"
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("最大金额批量设置")))]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "success", plain: "" },
                  on: { click: _vm._handleSave },
                },
                [_vm._v(_vm._s(_vm.$t("全部保存")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("PresentDialog", {
        ref: "PresentDialogControl",
        on: { update: _vm._handleUpdatePresent },
      }),
      _c("NumberDialog", {
        ref: "NumberDialogControl",
        on: { update: _vm._handleUpdateSize },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }