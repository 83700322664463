var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        { attrs: { type: "border-card" } },
        _vm._l(_vm.agencyMemberType, function (item, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { label: item.label } },
            [
              _c("ConfigItem", {
                key: index,
                attrs: { "agency-member-type": item.value },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }