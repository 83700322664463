import { rateTypes } from '@/libs/options'
import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('序号'),
      align: 'center',
      key: 'sort'
    },
    {
      title: this.$t('平台名称'),
      key: 'payPlatformName',
      align: 'center'
    },
    // {
    //   title: this.$t('通道名称'),
    //   key: 'paywayName',
    //   align: 'center'
    // },
    // {
    //   title: this.$t('通道编码'),
    //   key: 'payPlatformName',
    //   align: 'center'
    // },
    {
      title: this.$t('平台标识'),
      key: 'payPlatformCode',
      align: 'center'
    },
    {
      title: this.$t('货币种类'),
      key: 'rateType',
      align: 'center',
      component: {
        render: (h, row) => {
          const rateType = rateTypes.find(v => v.key === row.rateType)
          let rateTypeStr = ''
          if (rateType) {
            rateTypeStr = rateType.label
          }
          return (
            <span>{ rateTypeStr }</span>
          )
        }
      }
    },
    {
      title: this.$t('支付方式'),
      key: 'paywayName',
      align: 'center'
    },
    {
      title: this.$t('付款类型'),
      key: 'type',
      align: 'center',
      component: {
        render: (h, row) => {
          const payType = this.payTypes.find(e => e.key === row.type)
          return (<span>{ payType?.label }</span>)
        }
      }
    },
    {
      title: this.$t("充值金额类型"),
      key: "isType",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>{row.isType ? "充值固定金额" : "充值范围金额"}</span>;
        },
      },
    },
    {
      title: this.$t('最低额度'),
      key: 'minAmount',
      align: 'center'
    },
    {
      title: this.$t('最高额度'),
      key: 'maxAmount',
      align: 'center'
    },
    {
      title: this.$t("充值固定金额"),
      key: "periodAmount",
      align: "center",
    },
    {
      title: this.$t('渠道码'),
      key: 'payChannel',
      align: 'center'
    },
    {
      title: this.$t('VIP等级'),
      key: 'vipLevel',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>VIP{ row.vipLevel }</span>)
        }
      }
    },
    {
      title: this.$t('是否启用'),
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          if (this.permission.StartStop) {
            return (
              <d-switch v-model={row.status} beforeChange={() => this.handleChangeStatus(row)}>
                <span slot='close'>{this.$t('备用')}</span>
              </d-switch>
            )
          } else {
            return row.status === 0 ? this.$t('启用') : this.$t('备用')
          }
        }
      }
    },
    {
      title: this.$t('修改时间'),
      key: 'updateTime',
      align: 'center',
      width: 125,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('创建时间'),
      key: 'createTime',
      align: 'center',
      width: 125,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作员'),
      align: 'center',
      key: 'operatorName'
    },

    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 180,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission
          return (
            <span>
              { Update && <el-button type='primary' icon='el-icon-edit-outline' size='mini' onClick={() => this.$refs.EditDialogControl.open(row)}>{this.$t('编辑')}</el-button> }
              { Delete && <el-button type='danger' icon='el-icon-delete' size='mini' onClick={this.handleDeleteAccount.bind(this, row.id)}>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
