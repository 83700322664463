import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('日期'),
      key: 'date',
      align: 'center'
    },
    {
      title: this.$t('主播账号'),
      key: 'accLogin',
      align: 'center'
    },
    {
      title: this.$t('主播昵称'),
      key: 'nickName',
      align: 'center'
    },
    {
      title: this.$t('所属家族'),
      key: 'familyName',
      align: 'center'
    },
    {
      title: this.$t('开播时间'),
      key: 'onlineDate',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.onlineDate) }</span>)
        }
      }
    },
    {
      title: this.$t('下播时间'),
      key: 'offlineDate',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.offlineDate) }</span>)
        }
      }
    },
    {
      title: this.$t('开播时长'),
      key: 'onlineTime',
      align: 'center'
    },
    {
      title: this.$t('房费分成'),
      key: 'payroomIncome',
      align: 'center'
    },
    {
      title: this.$t('礼物分成'),
      key: 'giftIncome',
      align: 'center'
    },
    {
      title: this.$t('投注分成'),
      key: 'betsIncome',
      align: 'center'
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 240,
      component: {
        render: (h, row) => {
          return (
            <span>
              <el-button type='text' disabled={!parseFloat(row.payroomIncome)} onClick={() => this.$refs.RoomStreamDialogControl.open(row)}>{this.$t('房费流水')}</el-button>
              <el-button type='text' disabled={!parseFloat(row.giftIncome)} onClick={() => this.$refs.GiftStreamDialogControl.open(row)}>{this.$t('礼物流水')}</el-button>
              <el-button type='text' disabled={!parseFloat(row.betsIncome)} onClick={() => this.$refs.BetStreamDialogControl.open(row)}>{this.$t('投注流水')}</el-button>
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
