import { filterColumns } from '@/utils'

const CanChooseColumns = function() {
  return [
    {
      title: this.$t('代理等级'),
      key: 'level',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.level + this.$t('级代理') }</span>)
        }
      }
    },
    {
      title: this.$t('升级要求推广人数'),
      key: 'requireSpreadNum',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.level==0? 0: row.requireSpreadNum }</span>)
        }
      }
    },
    {
      title: this.$t('升级要求投注金额'),
      key: 'requireBetAmount',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.level==0? 0: row.requireBetAmount }</span>)
        }
      }
    },
    {
      title: this.$t('升级要求充值金额'),
      key: 'requireRechargeAmount',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.level==0? 0: row.requireRechargeAmount }</span>)
        }
      }
    },
    {
      title: this.$t('等级对应一级返佣点'),
      key: 'oneLevelAgencyRebate',
      align: 'center'
    },
    {
      title: this.$t('等级对应二级返佣点'),
      key: 'twoLevelAgencyRebate',
      align: 'center'
    },
    {
      title: this.$t('结算方式'),
      key: 'paymentType',
      align: 'center',
      component: {
        render: (h, row) => {
          const type = this.paymentTypes.find(p => p.value === row.paymentType)
          if (type) {
            return (<span>{ type.label }</span>)
          } else {
            return ''
          }
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission
          return (
            <span>
              { Update && <el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={ () => this.onCreate(row) }>{this.$t('编辑')}</el-button> }
              { Delete && <el-button type='danger' size='mini' icon='el-icon-delete' onClick={ this.handleDelete.bind(this, row.id) }>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
