import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-link type='primary' onClick={this._onDetails.bind(this, row)}>{ row.memberName }</el-link>)
        }
      }
    },
    {
      title: this.$t('变动类型'),
      key: 'transDesc',
      align: 'center',
      width: 150
    },
    {
      title: this.$t('变动说明'),
      key: 'transDetail',
      align: 'center',
      width: 150
    },
    {
      title: this.$t('变动金额'),
      key: 'rolling',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span class={this.$parseColor(row.rolling)}>{ row.rolling }</span>)
        }
      }
    },
    // {
    //   title: this.$t('变动前金额'),
    //   key: 'oldBalance',
    //   align: 'center'
    // },
    // {
    //   title: this.$t('变动后金额'),
    //   key: 'newBalance',
    //   align: 'center'
    // },
    {
      title: this.$t('变动前所需打码量'),
      key: 'oldWithdrawRolling',
      align: 'center'
    },
    {
      title: this.$t('变动后所需打码量'),
      key: 'newWithdrawRolling',
      align: 'center'
    },
    {
      title: this.$t('变动时间'),
      key: 'createTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: this.$t('订单号'),
      key: 'transSeq',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <el-tooltip
              placement='right'
              content={row.transSeq}
            >
              <span class='ellipsis-text'>{ row.transSeq }</span>
            </el-tooltip>
          )
        }
      }
    },
    // {
    //   title: '备注',
    //   key: 'remark',
    //   align: 'center'
    // },
    {
      title: this.$t('操作员'),
      align: 'center',
      key: 'operatorName'
    },
    {
      title: this.$t('操作员备注'),
      key: 'operatorRemark',
      align: 'center'
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
