import request from '@/plugins/axios'

// 跟单配置
export function postFollowbetAddConfig(data = {}) {
  return request({
    url: '/followbet/addConfig',
    method: 'post',
    data
  })
}

// 加载跟单配置
export function getFollowbetConfig(data = {}) {
  return request({
    url: '/guru/rule/config/search',
    method: 'POST',
    data
  })
}
// 跟单配置
export function PostGuruRuleAdd(data = {}) {
  return request({
    url: '/guru/rule/add',
    method: 'POST',
    data
  })
}

//大神申请列表
export function getGuruApplyList(data = {}) {
  return request({
    url: '/guru/application/list',
    method: 'POST',
    data
  })
}

//大神审核
export function reviewGuruApply(data = {}) {
  return request({
    url: '/guru/application/check',
    method: 'POST',
    data
  })
}

//推单订单列表
export function getRecList(data = {}) {
  return request({
    url: '/guru/order/list',
    method: 'post',
    data
  })
}

// //推单订单列表——查看详情
// export function reviewGuruApply(data = {}) {
//   return request({
//     url: '/followbet/guru/apply/audit',
//     method: 'POST',
//     data
//   })
// }


//跟单订单列表
export function getFollowOrderList(data = {}) {
  return request({
    url: '/guru/follower/order/list',
    method: 'POST',
    data
  })
}

// //跟单订单列表——查看详情
// export function reviewGuruApply(data = {}) {
//   return request({
//     url: '/followbet/guru/apply/audit',
//     method: 'POST',
//     data
//   })
// }

export function guruOrderCommentList(data = {}) {
  return request({
    url: '/guru/order/comment/list',
    method: 'POST',
    data
  })
}
export function guruOrderCommentDelete(data = {}) {
  return request({
    url: '/guru/order/comment/delete',
    method: 'POST',
    data
  })
}


export function guruOrderCountList(data = {}) {
  return request({
    url: '/guru/order/count/list',
    method: 'POST',
    data
  })
}


export function guruManageList(data = {}) {
  return request({
    url: '/guru/recommend/list',
    method: 'POST',
    data
  })
}
export function guruRecommendADD(data = {}) {
  return request({
    url: '/guru/recommend/add',
    method: 'POST',
    data
  })
}


export function guruRecommendDelete(data = {}) {
  return request({
    url: '/guru/recommend/delete',
    method: 'POST',
    data
  })
}
//推单说明 排行榜说明 配置查询
export function guruExplainSearch(data = {}) {
  return request({
    url: '/guru/rule/explain/search',
    method: 'POST',
    data
  })
}
//推单说明 排行榜说明 配置
export function guruExplainAdd(data = {}) {
  return request({
    url: '/guru/explain/add',
    method: 'POST',
    data
  })
}


//推单说明 注单详情---彩票
export function getGuruOrderLotteryDetail(params = {}) {
  return request({
    url: '/guru/order/lottery/detail',
    method: 'GET',
    params
  })
}
//推单说明 注单详情---竞彩足球 //orderNo
export function getGuruOrderFootballDetail(params = {}) {
  return request({
    url: '/guru/order/football/detail',
    method: 'GET',
    params
  })
}
export function getGamePmTyDetail(data = {}) {
  return request({
    url: '/gamePmTy/detail',
    method: 'POST',
    data
  })
}

export function getOrderSimpleList(data = {}) {
  return request({
    url: '/guru/follower/order/simple/list',
    method: 'POST',
    data
  })
}
export function getApplicationCheckHistory(data = {}) {
  return request({
    url: '/guru/application/check/history',
    method: 'POST',
    data
  })
}

//大神查询
export function getGuruList(data = {}) {
  return request({
    url: '/guru/list',
    method: 'POST',
    data
  })
}
//大神修改
export function upGuru(data = {}) {
  return request({
    url: '/guru/application/update',
    method: 'POST',
    data
  })
}
//大神订单科技生成
export function addGuruOrders(data = {}) {
  return request({
    url: '/guru/order/tech/create',
    method: 'POST',
    data
  })
}
