<template>
  <div class="chat-page container">
    <!-- 左侧 -->
    <div class="room-card">
      <div class="room-card-head">
        <el-input
          v-model="searchWord"
          :placeholder="$t('请输入', { text: $t('用户昵称') })"
          class="room-search"
          @keyup.enter.native="_filterRoomList"
        >
          <el-button slot="append" @click="_filterRoomList">{{
            $t("查找")
          }}</el-button>
        </el-input>
      </div>
      <!-- 左侧房间列表-->
      <div ref="scroller" v-loading="roomLoading" class="room-card-body">
        <div class="card-body__inner" style="padding: 0">
          <chat-room
            v-for="item in showRoomList"
            :key="item.id"
            :item="item"
            :active-room-id="activeNickname"
            :can-edit="false"
            @enterRoom="_enterRoom"
          />
        </div>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="card">
      <div class="usernames">{{ activeNickname }}</div>
      <el-divider></el-divider>
      <!-- 聊天室 -->
      <div ref="chatScroller" class="card-body">
        <div class="card-body__inner">
          <chat-item
            v-for="item in filterChatList"
            :key="item.serverMsgId"
            :item="item"
            :content-type="item.contentType"
            :permission="permission"
            :me="item.memberType == 5"
            :operatorInfo="operatorInfo"
            @on-action="_handleActionItem"
          >
          </chat-item>
        </div>
      </div>
      <div class="card-footer">
        <!-- <el-upload
          :accept="accept"
          :data="{ type: 'image', folder: 'chat-image' }"
          :action="action"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleImageSuccess"
          :before-upload="handleBeforeUpload"
        >
          <i class="el-icon-picture" />
        </el-upload> -->
        <!-- 表情包弹窗气泡 -->
        <el-popover
          v-model="emoShow"
          placement="top-start"
          width="350"
          trigger="click"
        >
          <div class="emo-pop">
            <div
              v-for="emoItem of emojiArr"
              :key="emoItem"
              class="emoItem"
              @click="addEmo(emoItem)"
            >
              {{ emoItem }}
            </div>
          </div>
          <img
            slot="reference"
            class="emo-img"
            :src="
              require(`@/assets/public_images/${
                emoShow ? 'icon-chat-keyboard' : 'icon-chat-emoji'
              }.png`)
            "
          />
        </el-popover>
        <!-- 输入框  -->
        <el-input
          v-model="content"
          :placeholder="$t('输入信息')"
          @keyup.enter.native="_sendNormalMessage"
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 10 }"
        />

        <i
          class="el-icon-s-promotion"
          style="color: #e91e63"
          @click="_sendNormalMessage"
        />
      </div>
      <SetUserDialog
        ref="SetUserDialogControl"
        :operatorInfo="operatorInfo"
        @change="_getOeratorInfo"
        @submit="submitCustomer"
      />
    </div>

    <div style="width: 150px; margin-left: 20px">
      <el-link
        type="primary"
        @click="
          () => {
            this.$refs.SetUserDialogControl.open();
          }
        "
        >个人信息设置</el-link
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { getImToken, getCustomerPageList } from "@/api/chat";
import { getPlatformDomainImList } from "@/api/permission.js";
import { getRoomMessage } from "@/api/chatNew";
import SocketMixin from "../../../interactive/Chat/socket";
import WebSocketWithHeartbeat from "../../../interactive/Chat/socketNew";
import ChatItem from "./components/chat-item";
import ChatRoom from "./components/chat-room";
import ActionChatRoomDialog from "./components/ActionChatRoomDialog.vue";
import SetUserDialog from "./components/SetUserDialog.vue";
import emojiArr from "./emojiArr.json";
import { createUniqueString } from "@/utils";
import { selectOperator } from "@/api/config";
import TablePageMixin from "@/mixins/table-page-mixin";
import store from "@/store";
export default {
  name: "CustomerChatTab",
  components: {
    ChatItem,
    ChatRoom,
    ActionChatRoomDialog,
    SetUserDialog,
  },
  mixins: [SocketMixin(), TablePageMixin(false, false)],
  props: {
    accept: {
      type: String,
      default: "image/jpeg,image/png,image/jpg",
    },
  },
  data() {
    return {
      VirtualCount: null,
      content: "",
      beforeMessage: null,
      activeNickname: "",
      activeRoomName: "",
      roomType: 1,
      roomName: "",
      searchContent: "",
      roomTypes: [
        { label: "互动大厅", value: 1, type: "lottery" },
        { label: "赛事-滚球", value: 2, type: "sport" },
        { label: "赛事-竞彩", value: 5, type: "sport" },
        { label: "热门彩种", value: 3, type: "lottery" },
        { label: "直播", value: 4, type: "live" },
      ],
      roomList: [],
      showRoomList: [],
      showRoomListMap: [],
      lastInputContent: "",
      replayInfo: undefined,
      itemSource: [],
      chatList: [],
      filterChatList: [],
      roomLoading: false,
      sendingMsg: [],
      emoShow: false,
      emojiArr,
      socket: null,
      socketLeft: null,
      msgId: null,
      msgLoading: false,
      msgLoadFinish: false,
      domainImData: {},
      deleteList: {},
      searchWord: "",
      roomId: "",
      from: "",
      operatorInfo: {},
      totalUnReadNum: 0,
      lastReadId: {},
    };
  },
  computed: {
    ...mapState("user", ["info"]),
    ...mapGetters(["tenantCode", "operatorId"]),

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`;
    },
    unReadNumSocket() {
      return this.$store.getters.unReadNumSocket || 0;
    },
  },
  watch: {
    content(newVal) {
      this.content = newVal;
      this.lastInputContent = newVal;
    },
  },
  async created() {
    await this.getConfig();
    // 获取左侧用户列表
    await this.getCustomerList();
    // 连上roomMessageSocket,及时更新左右列表
    this._contentLeft(this.roomId);
  },
  mounted() {
    this.$refs.chatScroller.addEventListener("scroll", this.handleScroll);
    // 查询当前登录（后台）用户客服信息
    this._getOeratorInfo();
  },
  destroyed() {
    if (this.socket) this.socket.close();
    if (this.socketLeft) this.socketLeft.close();
    this._closewebsocket();
    if (this.unReadNumSocket) this.unReadNumSocket.connect();
  },
  methods: {
    // 查询当前登录（后台）用户客服信息
    _getOeratorInfo() {
      selectOperator().then((res) => {
        if (res[2].data.success) {
          this.operatorInfo = res[2].data.data;
        }
      });
    },
    // 处理左侧房间列表数据格式，主要是extras
    handleRoomMessage(item, cb) {
      let result = item.replace(/\\n/g, "\n");
      result = result.replace(/\\/g, "");
      result = result.replace(/"{/g, "{");
      result = result.replace(/}"/g, "}");
      result = result.replace(/\n/g, "");
      try {
        item = JSON.parse(result);
      } catch (err) {
        console.log("roomMessage解析失败", err, item);
        // item ={}
        // return
        // extras里有富文本会解析失败 extras在timestamp字段前
        let result = item.split(',"extras')[0] + "}";
        const start = item.indexOf("extras");
        const end = item.indexOf("timestamp");
        let extras = '{"' + item.substring(start, end).trim();
        try {
          extras = extras.slice(0, -2);
          extras = extras + "}";
          extras = JSON.parse(extras);
          extras = JSON.parse(extras.extras);
          // console.log("extras", extras);
          let result2 = '{"timestamp' + item.split("timestamp")[1];
          result = JSON.parse(result);
          result2 = JSON.parse(result2);
          item = { ...result, ...result2, extras };
          console.log(item);
        } catch (error) {
          item = {};
        }
      }
      cb && cb(item);
      return item;
    },
    // 更新总未读消息
    reFreshUnReadMessage() {
      this.totalUnReadNum = 0;
      this.showRoomList.map((x) => {
        this.totalUnReadNum += x.messageSize;
      });
      store.dispatch("tagsView/addTotalUnReadNum", this.totalUnReadNum);
    },
    // 查询左侧用户信息列表
    getCustomerList(nickname = "") {
      // 左侧用户列表
      getCustomerPageList({
        tenantCode: "",
        nickname: nickname,
        pageNumber: 1,
      }).then((res) => {
        if (res[2]) {
          let list = [];
          res[2].data.items.map((item) => {
            this.handleRoomMessage(item, (item) => {
              list.push(item);
            });
          });
          this.showRoomList = list;
          console.log("showRoomList", this.showRoomList);
          this.reFreshUnReadMessage();
        }
      });
    },
    // 获取房历史间消息
    _getRoomMessage(scrollBottom = 0) {
      if (this.msgLoading || this.msgLoadFinish) return;
      this.msgLoading = true;
      getRoomMessage(
        { room: `CSR_${this.from}`, id: this.msgId },
        this.domainImData.imApi
      )
        .then(([list]) => {
          if (list.length) {
            let arr = list
              .map((item) => {
                // extras中有msgType和id字段会和item中的相互覆盖
                let obj = {
                  ...item,
                  ...JSON.parse(item.extras || "{}"),
                  msgSendStatus: 0,
                  vipLevel: item.vipLevel,
                  isRead:
                    item.id < this.lastReadId[this.from] ||
                    item.id == this.lastReadId[this.from] ||
                    false,
                };
                obj.id = item.id;
                return obj;
              })
              .filter((item) => item.msgType != -2);
            this.chatList = [...arr, ...this.chatList];
            this.filterChatList = [...arr, ...this.filterChatList];
            console.log(this.filterChatList, this.filterChatList.length - 1);
            console.log(
              "历史消息最后一条extras",
              this.filterChatList[this.filterChatList.length - 1]?.extras &&
                JSON.parse(
                  this.filterChatList[this.filterChatList.length - 1]?.extras
                )
            );
            console.log("历史消息列表", this.filterChatList);
            if (this.msgId === null) {
              this.$nextTick(() => {
                this.$refs.chatScroller.scrollTop =
                  this.$refs.chatScroller.scrollHeight;
              });
            } else {
              this.$nextTick(() => {
                this.$refs.chatScroller.scrollTop =
                  this.$refs.chatScroller.scrollHeight - scrollBottom;
              });
            }
            this.msgId = list[0].id;
          } else {
            this.msgLoadFinish = true;
          }
        })
        .finally(() => {
          this.msgLoading = false;
        });
    },
    // 排序，先看messageSize,再看timestamp
    roomSort(list) {
      list.sort((a, b) => {
        if (a.messageSize !== b.messageSize) {
          return b.messageSize - a.messageSize;
        }
        return b.timestamp - a.timestamp;
      });
    },
    //链接左侧房间更新socket
    _contentLeft(roomId) {
      if (this.unReadNumSocket) this.unReadNumSocket.close();
      if (this.socketLeft) this.socket.close();
      this.socketLeft = new WebSocketWithHeartbeat(
        roomId,
        (data) => {
          console.log("leftMessage", data);
          let isFind = false;
          this.showRoomList.some((item, index) => {
            if (item.from === data.from) {
              if (data.extras) data.extras = JSON.parse(data.extras);
              this.showRoomList.splice(index, 1, data);
              isFind = true;
              return true;
            }
          });
          if (isFind) {
            this.roomSort(this.showRoomList);
            this.reFreshUnReadMessage();
          } else if (data.msgType != -2) {
            this.getCustomerList();
          }
        },
        this.domainImData.imSocket,
        "isLeft"
      );
    },
    //链接右侧消息soket
    _connect(roomId) {
      if (this.socket) this.socket.close();
      this.socket = new WebSocketWithHeartbeat(
        roomId,
        (data) => {
          console.log("rightMessage", data);
          const extras = JSON.parse(data.extras || "{}");
          // 进入房间时收到 取extras中的id,小于该id皆是已读
          if (data.msgType == -2) {
            this.lastReadId[data.from] = JSON.parse(data.extras)?.id;
            this.filterChatList.map((x) => {
              x.isRead =
                x.id < this.lastReadId[this.from] ||
                x.id == this.lastReadId[this.from] ||
                false;
            });
          }
          if (!data.cmd) {
            let obj = {
              ...data,
              ...extras,
              vipLevel: data.vipLevel,
              isRead:
                data.id < this.lastReadId[this.from] ||
                data.id == this.lastReadId[this.from] ||
                false,
            };
            this.chatList.push(obj);
            this.filterChatList.push(obj);
            this.chatList = this.chatList.filter((item) => item.msgType != 10);
            this.filterChatList = this.filterChatList.filter(
              (item) => item.msgType != -2
            );
          }
        },
        this.domainImData.imSocket
      );

      this._clear();
      this.msgId = null;
      this.msgLoading = false;
      this.msgLoadFinish = false;
      this._getRoomMessage();
    },
    // 发送消息
    _sendNormalMessage() {
      if (!this.content) return;
      if (!this.activeNickname)
        return this.$message.error("请先进入聊天室再发言");
      const data = {
        to: "CSR_" + this.from,
        cmd: null,
        msgType: 0,
        chatType: 1,
        content: this.content,
      };
      this.socket.send(JSON.stringify(data));
      this.replayInfo = undefined;
      this.lastInputContent = "";
      this.content = "";
    },
    // 客服只有删除操作
    _handleActionItem(item, actionType) {
      // 删除
      if (actionType === "del") {
        this.$confirm(this.$t("是否删除消息?"), this.$t("系统提示"), {
          type: "warning",
        })
          .then(() => {
            const extras = JSON.stringify({
              groupId: this.roomId,
              id: item.id,
            });
            this.socket.send(
              JSON.stringify({
                chatType: -1,
                cmd: 21001,
                extras: extras,
                msgType: item.msgType,
                to: this.roomId,
              })
            );
            this.filterChatList = this.filterChatList.filter(
              (p) => p.id !== item.id
            );
            this.chatList = this.chatList.filter((p) => p.id !== item.id);
            this.$message.success(this.$t("删除消息成功"));
            this.getCustomerList();
          })
          .catch(() => {});
      }
    },
    // 获取接口域名配置
    async getConfig() {
      await getPlatformDomainImList().then(([data, err]) => {
        if (!err && data) {
          this.domainImData = data;
          this.init();
          return new Promise((resolve, reject) => {
            resolve();
          });
        }
      });
    },
    // 滚动加载更多
    handleScroll() {
      if (this.$refs.chatScroller.scrollTop <= 200) {
        const scrollBottom =
          this.$refs.chatScroller.scrollHeight -
          this.$refs.chatScroller.scrollTop;
        this._getRoomMessage(scrollBottom);
      }
    },
    // 选择进入房间
    _enterRoom(item) {
      this.activeNickname = item.nickname;
      this.showRoomList.map((x, index) => {
        this.$set(this.showRoomList[index], "check", item.from === x.from);
      });
      this.from = item.from;
      this.roomId = `CSR_${item.from}`;
      this._connect(this.roomId);
    },
    // 聊天室本地搜索
    _filterRoomList() {
      if (this.searchWord) {
        this.showRoomList = this.showRoomList.filter(
          (p) => p.nickname.indexOf(this.searchWord) > -1
        );
      } else {
        this.getCustomerList();
      }
    },
    // 清除聊天信息
    _clear() {
      this.chatList = [];
      this.filterChatList = [];
    },
    // 选择表情到输入框
    addEmo(emo) {
      this.content += emo;
    },
    // 初始化
    async init() {
      await this._getImToken();
    },
    // 获取授权token
    async _getImToken() {
      const [data, err] = await getImToken({ tokenType: 1 });
      if (!err) {
        this.$store.commit("user/SET_IM_TOKEN", data);
      }
    },
    submitCustomer({ field, cancel, close }) {
      console.log("fieldfieldfield", field);
    },
    handleImageSuccess(data, file) {
      let reader = new FileReader();
      const that = this;
      reader.onload = function (e) {
        let txt = e.target.result;
        let img = document.createElement("img");
        img.src = txt;
        img.onload = function () {
          const msgContent = {
            uid: that.operatorId,
            nickname: that.$t("管理员"),
            vipLevel: 60,
            vipLog:
              "https://static.lt9b.com/icon/e044bd3fce1c41d4bfe8bd2897913d66.png",
            msgType: 102,
            imgW: img.width,
            imgH: img.height,
            imgUrl: data.data[0],
            time: new Date().getTime(),
            msgTempId: createUniqueString(),
          };
          that._sendRoomMessage(msgContent);
        };
      };
      reader.readAsDataURL(file.raw);
    },
    handleBeforeUpload(file) {
      const isAccept = this.accept.split(",").some((o) => file.type === o);
      const isMax = file.size / 1024 / 1024 < 2;
      if (!isAccept) {
        this.$message.error(this.$t("上传图片只能是 JPG、PNG、JEPG 格式!"));
      }
      if (!isMax) {
        this.$message.error(this.$t("上传图片大小不能超过 2MB!"));
      }
      return isAccept && isMax;
    },
    onEnjoy(enjoy) {
      this.content = this.content + enjoy;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-page {
  padding-top: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: start !important;

  .room-card {
    width: 40%;
    height: 70vh;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
      0 5px 15px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;

    .room-type {
      min-width: 100px;
      max-width: 110px;

      ::v-deep {
        .el-input__inner {
          border-radius: 24px;
        }
      }
    }

    .room-search {
      width: 320px;

      ::v-deep {
        .el-input__inner {
          border-radius: 24px 0px 0px 24px;
        }

        .el-input-group__append {
          border-radius: 0px 24px 24px 0px;
        }
      }
    }

    &-head {
      padding: 1.25rem 0.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-body {
      flex: 1 1 auto;
      position: relative;
      overflow-y: hidden;

      &__inner {
        padding: 0.5rem 1.5rem;
        min-height: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
      }
    }

    &-body:hover,
    &-body:active,
    &-body:focus {
      overflow-y: auto;
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 1.25rem 0.5rem;

      // border-top: 1px solid rgba(0,0,0,.05);
      i,
      svg {
        cursor: pointer;
        font-size: 20px;
        margin: 0 10px;
      }
    }
  }

  .card {
    width: 100%;
    height: 70vh;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
      0 5px 15px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;
    margin-left: 24px;

    &-head {
      padding: 1.25rem 1.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-body {
      flex: 1 1 auto;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;

      &__inner {
        padding: 0.5rem 1.5rem;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      padding: 1.25rem 0.5rem;
      border-top: 1px solid rgba(0, 0, 0, 0.05);

      i,
      svg {
        cursor: pointer;
        font-size: 20px;
        margin: 0 10px;
      }

      .emo-img {
        cursor: pointer;
        width: 1.3rem;
        margin-right: 10px;
      }
    }
  }

  .search-content {
    width: 40%;

    ::v-deep {
      .el-input__inner {
        border-radius: 24px 0px 0px 24px;
      }

      .el-input-group__append {
        border-radius: 0px 24px 24px 0px;
      }
    }
  }

  .chat-btn {
    position: fixed;
    top: 130px;
    right: 153px;
  }
}
</style>
<style lang="scss">
.emo-pop {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;

  // align-items: center;
  // justify-content: flex-start;
  .emoItem {
    width: 2rem;
    height: 2rem;
    font-size: 1.22rem;
    text-align: center;
  }
}

.usernames {
  width: 100%;
  padding: 20px 0 0 0;
  text-align: center;
  font-weight: bold;
}
.el-textarea__inner {
  resize: none;
}
</style>
