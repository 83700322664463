export const rulesAmountBlur = [
  { required: true, message: '此项必填', trigger: 'blur' },
  { pattern: /^(0|[1-9][0-9]*)$/, message: '需大于等于0整数', trigger: 'blur' }
]

export const rulesPercentBlur = [
  { required: true, message: '此项必填', trigger: 'blur' },
  { pattern: /^(?:[1-9]?\d|100)$/, message: '需0-100且最多三位小数', trigger: 'blur' }
]

export const rulesAmountFloatBlur = [
  { required: true, message: '此项必填', trigger: 'blur' },
  { pattern: /^(0|[1-9][0-9]*|[0-9]*.[1-9][0-9]*|[1-9]*.[0-9][0-9]*|[0-9]*.[0-9][1-9]*)$/, message: '需大于等于0', trigger: 'blur' }
]

export const rulesChange = [{ required: true, message: '此项必填', trigger: 'change' }]

export const encoderuleForm = (field) => {
  return new Promise((resolve, reject) => {
    const { robAmtMin, robAmtMax, items, redpacketTime, showTime, receipts, lang, ...other } = field
    const [startTime, endTime] = redpacketTime
    let showStartTime = null
    let showEndTime = null
    if (other.redpacketType === 3) {
      showStartTime = showTime[0]
      showEndTime = showTime[1]
    }
    const day = Math.ceil((endTime - startTime) / (24 * 3600 * 1000))

    const robVip = items.reduce((acc, cur, index) => {
      const { vipBegin, vipEnd, rangeAMinAmount, rangeAMaxAmount, rangeARate, rangeBMinAmount, rangeBMaxAmount, rangeBRate } = cur
      Array.of('AMin', 'AMax', 'BMin', 'BMax').forEach(o => {
        const tmp = parseFloat(cur[`range${o}Amount`])
        if (tmp > +robAmtMax || tmp < +robAmtMin) {
          reject(tmp)
        }
      })
      acc[`robVip${index + 1}Rang`] = `${vipBegin},${vipEnd}`
      acc[`robVip${index + 1}Amt1`] = `${rangeAMinAmount},${rangeAMaxAmount},${rangeARate}`
      acc[`robVip${index + 1}Amt2`] = `${rangeBMinAmount},${rangeBMaxAmount},${rangeBRate}`
      return acc
    }, {})

    resolve({
      id: 0,
      tenantCode: 'CSZH',
      ...other,
      ...robVip,
      robAmtMin,
      robAmtMax,
      startTime,
      endTime,
      redpacketStatus: 1,
      lang: lang.toString(),
      robNumsDay: 1,
      robNumsTotal: Math.pow(day, receipts),
      requireBet: '1',
      showStartTime,
      showEndTime
    })
  })
}

export const decoderuleForm = (data) => {
  const { startTime, endTime, showStartTime, showEndTime, robNumsTotal, ...other } = data

  let params = {
    id: other.id,
    tenantCode: other.tenantCode,
    redpacketType: other.redpacketType,
    activityTypeId: other.activityTypeId,
    redpacketTitle: other.redpacketTitle,
    redpacketContent: other.redpacketContent,
    redpacketTime: [startTime, endTime],
    showTime: [],
    receipts: robNumsTotal > 1 ? 1 : 0,
    robAmtMin: other.robAmtMin,
    robAmtMax: other.robAmtMax,
    showAmtMin: other.showAmtMin,
    showAmtMax: other.showAmtMax,
    requireBetAmt: other.requireBetAmt,
    requireRechargeAmt: other.requireRechargeAmt,
    requireTime: other.requireTime,
    popup: other.popup,
    lang: [],
    redpacketBanner: other.redpacketBanner,
    redpacketBody: other.redpacketBody,
    items: [],
    requireBet: '1',
    showSeconds: other.showSeconds
  }
  if (other.lang) {
    params.lang = other.lang.split(',')
  }
  if (other.redpacketType === 3) {
    params.showTime = [showStartTime, showEndTime]
  }
  Array.of(1, 2, 3).forEach(key => {
    if (other[`robVip${key}Rang`]) {
      const [vipBegin, vipEnd] = data[`robVip${key}Rang`].split(',')
      const [rangeAMinAmount, rangeAMaxAmount, rangeARate] = data[`robVip${key}Amt1`].split(',')
      const [rangeBMinAmount, rangeBMaxAmount, rangeBRate] = data[`robVip${key}Amt2`].split(',')
      params.items.push({
        key,
        vipBegin: +vipBegin,
        vipEnd: +vipEnd,
        rangeARate,
        rangeAMinAmount,
        rangeAMaxAmount,
        rangeBRate,
        rangeBMinAmount,
        rangeBMaxAmount
      })
    }
  })

  return params
}
