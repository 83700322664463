<template>
  <div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <div class="filter-container">
          <el-input v-model.trim="search.ip" clearable :placeholder="$t('请输入IP')" class="filter-item" style="width: 200px" />
          <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleRefresh">{{ $t('查询') }}</el-button>
          <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
          <el-button
            v-if="permission.Add"
            class="filter-item"
            type="success"
            icon="el-icon-plus"
            @click="$refs.ActionDialogControl.open()"
          >{{ $t('添加限制') }}</el-button>
        </div>
      </template>
    </d-table>
    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" @update="_handleUpdate" />
  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { getLimitIPList, createLimitIP, updateLimitIP, deleteLimitIP } from '@/api/interactive'
export default {
  name: 'BlockIpTab',
  componentName: 'BlockIpTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(getLimitIPList)],
  data() {
    return {
      columns: [],
      defaultColumns: GetColumns.call(this),
      search: {}
    }
  },
  methods: {
    _handleDelete({ id, ip }) {
      this.$confirm(this.$t('确定删除{ip}这条IP限制?', { text: ip }), { type: 'warning' }).then(() => {
        deleteLimitIP({ id }).then(([_, err]) => {
          if (!err) {
            this.handleFetch()
            this.$message.success(this.$t('删除成功'))
          }
        })
      }).catch(() => {})
    },

    _handleCreate({ field, cancel, close }) {
      createLimitIP({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('创建成功！'))
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      updateLimitIP({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('修改成功'))
        } else {
          cancel()
        }
      })
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },

    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
