<template>
  <el-dialog :title="titleObj.title" width="600px" :visible.sync="dialogFormVisible" @close="_close">
    <div class="demo-image__lazy look-img">
      <el-image v-for="url in imgLists" :key="url" :src="url" lazy />
    </div>
  </el-dialog>
</template>

<script>
import ElDialogMx from '@/views/mixins/el-dialog-mx.js'
export default {
  mixins: [ElDialogMx],
  data() {
    return {
      imgLists: []
    }
  },
  methods: {
    init(list, name) {
      this.dialogFormVisible = true
      this.titleObj.title = `查看 ${name} 发布的图片`
      this.imgLists = [...list]
    }
  }
}
</script>

<style lang="scss" scoped>
  .demo-image__lazy{
    text-align: center;
    max-height: 70vh;
    overflow-y: auto;
    .el-image{ width: 100%;
      img{ max-width: 100%; vertical-align: bottom;}
    }
  }
  .look-img >>>.el-image__error{ padding: 5px 0;}
</style>
