<script>
export default {
  name: 'RenderComponent',
  props: {
    /**
     * @description 传入的render函数
     */
    renderFunction: {
      type: Function,
      required: true
    },
    /**
     * @description 传入的scope
     */
    scope: {
      type: Object,
      default: null
    },
    /**
     * @description 传入的当前选中行
     */
    currentRowIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleRender(h, scope, currentRowIndex) {
      if (typeof scope === 'string') {
        return this.renderFunction(h, scope, currentRowIndex)
      }
      return this.renderFunction(h, scope.row, scope.$index, scope.$index === currentRowIndex, scope.column)
    }
  },
  render(h) {
    return this.handleRender(h, this.scope, this.currentRowIndex)
  }
}
</script>
