var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "460px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("国际化key"), prop: "i18nKey" } },
            [
              _vm.emitName == "create"
                ? _c("el-input", {
                    staticStyle: { width: "240px" },
                    model: {
                      value: _vm.ruleForm.i18nKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "i18nKey", $$v)
                      },
                      expression: "ruleForm.i18nKey",
                    },
                  })
                : _vm._e(),
              _vm.emitName == "update"
                ? _c("span", { staticStyle: { width: "240px" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.i18nKey)),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("国际化value"), prop: "i18nValue" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                model: {
                  value: _vm.ruleForm.i18nValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "i18nValue", $$v)
                  },
                  expression: "ruleForm.i18nValue",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("国际化对应语言"), prop: "lang" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                model: {
                  value: _vm.ruleForm.lang,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "lang", $$v)
                  },
                  expression: "ruleForm.lang",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }