var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "400px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("支付方式名称"), prop: "paywayName" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                model: {
                  value: _vm.ruleForm.paywayName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "paywayName", $$v)
                  },
                  expression: "ruleForm.paywayName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("支付方式标识"), prop: "paywayCode" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                model: {
                  value: _vm.ruleForm.paywayCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "paywayCode", $$v)
                  },
                  expression: "ruleForm.paywayCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("付款类型"), prop: "type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    clearable: "",
                    placeholder: _vm.$t("请选择付款类型"),
                  },
                  model: {
                    value: _vm.ruleForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "type", $$v)
                    },
                    expression: "ruleForm.type",
                  },
                },
                _vm._l(_vm.$parent.payTypes, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("支付方式图标"), prop: "icon" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                model: {
                  value: _vm.ruleForm.icon,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "icon", $$v)
                  },
                  expression: "ruleForm.icon",
                },
              }),
              _c("DUpload", {
                attrs: {
                  folder: "bank-icon",
                  "on-success": _vm.handleSuccess,
                  accept: "image/png,image/webp",
                },
                model: {
                  value: _vm.ruleForm.icon,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "icon", $$v)
                  },
                  expression: "ruleForm.icon",
                },
              }),
              _c("span", [_vm._v(_vm._s(_vm.$t("只支持.png,.webp格式")))]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("全屏支付图标"), prop: "icon" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                model: {
                  value: _vm.ruleForm.bigIcon,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "bigIcon", $$v)
                  },
                  expression: "ruleForm.bigIcon",
                },
              }),
              _c("DUpload", {
                attrs: {
                  folder: "bank-icon",
                  "on-success": _vm.handleBigIconSuccess,
                  accept: "image/png,image/webp",
                },
                model: {
                  value: _vm.ruleForm.bigIcon,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "bigIcon", $$v)
                  },
                  expression: "ruleForm.bigIcon",
                },
              }),
              _c(
                "span",
                { staticStyle: { width: "184px", display: "inline-block" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("提示：支持上传PNG,WEBP图片，单张大小不超过2MB")
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { type: "number", placeholder: _vm.$t("1-999") },
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sort", $$v)
                  },
                  expression: "ruleForm.sort",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }