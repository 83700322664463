var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detailsPage" },
    [
      _c(
        "div",
        { staticClass: "titleLine" },
        [
          _c("span", [_vm._v("订单详情")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c("h4", [_vm._v("订单状态:" + _vm._s(_vm.rowData.orderStatus))]),
      _c("div", { staticClass: "contentSec" }, [
        _c("div", [
          _vm._v("推单订单号:" + _vm._s(_vm.rowData.orderNo || "---")),
        ]),
        _c("div", [
          _vm._v(
            "游戏类型:" + _vm._s(_vm.rowData.computed_smallPlayType || "---")
          ),
        ]),
        _c("div", [
          _vm._v(
            "发单时间:" +
              _vm._s(_vm.$parseTime(_vm.rowData.createTime) || "---")
          ),
        ]),
        _c("div", [
          _vm._v("会员账号:" + _vm._s(_vm.rowData.memberName || "---")),
        ]),
        _c("div", [
          _vm._v("会员昵称:" + _vm._s(_vm.rowData.nickname || "---")),
        ]),
        _c("div", [
          _vm._v("账号类型:" + _vm._s(_vm.rowData.memberType || "---")),
        ]),
        _c("div", [
          _vm._v("自购金额:" + _vm._s(_vm.rowData.selfAmount || "---")),
        ]),
        _c("div", [
          _vm._v(
            _vm._s(_vm.computed_wanfa) +
              ":" +
              _vm._s(_vm.rowData.betInformation || "---")
          ),
        ]),
        _c("div", [
          _vm._v(
            " 抽佣:" +
              _vm._s(
                _vm.rowData.bonusRateGuru
                  ? _vm.rowData.bonusRateGuru * 100 + "%"
                  : "---"
              ) +
              " "
          ),
        ]),
      ]),
      _c("div", [
        _vm._v(" 方案宣言: "),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.rowData.planSpeech) } }),
      ]),
      _c("h5", [_vm._v("方案详情")]),
      _c("hr"),
      _vm.rowData.smallPlayType == 40 || _vm.rowData.smallPlayType == 41
        ? _c(
            "el-table",
            {
              staticClass: "textAlignCenter",
              staticStyle: { width: "80%" },
              attrs: { data: _vm.planDetails, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "玩法", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row.betsContent))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2080093240
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "投注", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(" @" + _vm._s(scope.row.odds) + "//"),
                            _c("span", { staticClass: "xiazhu" }, [
                              _vm._v("下注" + _vm._s(scope.row.amount)),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  581305450
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "输赢", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("orderStatus")[scope.row.status]
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  569307272
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.rowData.smallPlayType == 99
        ? _c(
            "el-table",
            {
              staticClass: "textAlignCenter",
              staticStyle: { width: "80%" },
              attrs: { data: _vm.planDetails, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "场次/对阵" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.competitionName)),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                " 周" +
                                  _vm._s(
                                    _vm.$parseTime(
                                      scope.row.matchTime +
                                        new Date().getTimezoneOffset() *
                                          60 *
                                          1000,
                                      "{a}"
                                    )
                                  ) +
                                  _vm._s(scope.row.matchNumber.substring(1)) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.homeTeamName) +
                                  "VS" +
                                  _vm._s(scope.row.awayTeamName)
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1031861178
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "投注", prop: "betContent" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(
                          scope.row.betContent.split(","),
                          function (betContentItem, ii) {
                            return _c("div", { key: ii }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.betFormat(
                                      betContentItem,
                                      scope.row.rqCount
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          }
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  3081198970
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "赛果" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(_vm.parseScore(scope.row.betResult))),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  718073623
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "输赢" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("orderStatus")[scope.row.status]
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  569307272
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.rowData.smallPlayType == 1502
        ? _c(
            "el-table",
            {
              staticClass: "textAlignCenter",
              staticStyle: { width: "80%" },
              attrs: { data: _vm.planDetails, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "场次" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _c("div", [_vm._v(_vm._s(scope.row.sportName))]),
                            _c("div", [
                              _vm._v(
                                " 周" +
                                  _vm._s(
                                    _vm.$parseTime(
                                      scope.row.beginTime +
                                        new Date().getTimezoneOffset() *
                                          60 *
                                          1000,
                                      "{a}"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.$parseTime(
                                      scope.row.beginTime,
                                      "{m}-{d}"
                                    )
                                  ) +
                                  ")"
                              ),
                            ]),
                            _c("div", [_vm._v(_vm._s(scope.row.matchName))]),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  937303921
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "对阵" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(scope.row.matchInfo.split("v")[0])),
                          ]),
                          _c("div", [_vm._v("VS")]),
                          _c("div", [
                            _vm._v(_vm._s(scope.row.matchInfo.split("v")[1])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3268908184
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "投注" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {}, [_vm._v(_vm._s(scope.row.playName))]),
                          _vm._v(" "),
                          _c("span", {}, [
                            _vm._v(_vm._s(scope.row.playOptionName)),
                          ]),
                          _vm._v(" "),
                          _c("span", {}, [
                            _vm._v("@" + _vm._s(scope.row.oddFinally)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3081202085
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("hr"),
      _c("h4", [_vm._v("订单结算")]),
      _c("div", { staticClass: "contentSec" }, [
        _c("div", [
          _vm._v("中奖金额:¥" + _vm._s(_vm.rowData.hitAmount || "---")),
        ]),
        _c("div", [
          _vm._v("自购盈亏:¥" + _vm._s(_vm.rowData.selfOrderProfit || "---")),
        ]),
        _c("div", [
          _vm._v("跟单数量:" + _vm._s(_vm.rowData.followOrderNum || "---")),
        ]),
        _c("div", [
          _vm._v("跟单金额:¥" + _vm._s(_vm.rowData.followAmount || "---")),
        ]),
        _c("div", [
          _vm._v("跟单中奖:¥" + _vm._s(_vm.rowData.followHitAmount || "---")),
        ]),
        _c("div", [
          _vm._v("跟单盈亏:¥" + _vm._s(_vm.rowData.followOrderProfit || "---")),
        ]),
        _c("div", [
          _vm._v("跟单佣金:¥" + _vm._s(_vm.rowData.guruCommission || "---")),
        ]),
        _c("div", [
          _vm._v(
            "平台抽佣:¥" + _vm._s(_vm.rowData.platformCommission || "---")
          ),
        ]),
        _c("div", { staticClass: "isred" }, [
          _vm._v(
            " 结算金额:¥" +
              _vm._s(
                Number(_vm.rowData.selfOrderProfit) +
                  Number(_vm.rowData.guruCommission) -
                  Number(_vm.rowData.platformCommission) || "---"
              ) +
              " "
          ),
        ]),
        _c("div", [
          _vm._v(
            "截止时间:" + _vm._s(_vm.$parseTime(_vm.rowData.endTime) || "---")
          ),
        ]),
        _c("div", [
          _vm._v(
            "结算时间:" +
              _vm._s(_vm.$parseTime(_vm.rowData.settlementTime) || "---")
          ),
        ]),
      ]),
      _c("hr"),
      _c("h4", [_vm._v("跟单详情")]),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns2,
          options: null,
          "filter-column": null,
        },
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }