import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n)

const messages = {
  'en-US': {
    ...enLocale
  },
  'zh-CN': {
    ...zhLocale
  }
}
const files = require.context('./', true, /\.js$/)
files.keys().forEach((key) => {
  if (key !== './index.js') {
    const path = key.replace('./', '').split('/')[0]
    if (messages[path] === undefined) {
      messages[path] = {}
    }
    Object.assign(messages[path], files(key).default || files(key))
  }
})

// 多语言
export const language = [{
  lang: 'zh-CN',
  name: '中文',
  wosLang: 'zh_CN',
  elLang: zhLocale
}, {
  lang: 'en-US',
  name: 'English', // 英语
  wosLang: 'en_US',
  elLang: enLocale
}]

const langKey = 'LANG_KEY'
export function getLang() {
  let lang = localStorage.getItem(langKey)
  if (messages[lang] === undefined) {
    lang = navigator.language || navigator.browserLanguage || language[0].lang
  }
  if (!language.some(e => e.lang === lang)) {
    lang = language[0].lang
  }
  localStorage.setItem(langKey, lang)
  return lang
}

export function getLanguageKey() {
  const lang = getLang()
  switch (lang) {
    case 'zh-CN':
      return ''
    case 'en-US':
      return 'EN'
    case 'vi-VN':
      return 'VN'
  }
}

export function setLang(value) {
  localStorage.setItem(langKey, value)
}

const lang = getLang()

export const getLangObject = () => language.find(e => e.lang === lang)

const i18n = new VueI18n({
  locale: lang,
  messages,
  silentTranslationWarn: true
})

export default i18n

// 重新封装，供js调用
export function $t(args) {
  // eslint-disable-next-line no-useless-call
  return i18n.tc.call(i18n, args)
}
