var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("Interactive_Match.ForecastTab.直播链接配置"),
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Interactive_Match.ForecastTab.链接"),
                prop: "liveUrl",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.liveUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "liveUrl", _vm._n($$v))
                  },
                  expression: "ruleForm.liveUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Interactive_Match.ForecastTab.热门直播位置"),
                prop: "liveHot",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.liveHot,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "liveHot", _vm._n($$v))
                  },
                  expression: "ruleForm.liveHot",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "直播图", prop: "livePicture" } },
            [
              _c("SingleImage2", {
                style: { marginTop: "10px" },
                attrs: { value: _vm.ruleForm.livePicture, folder: "ad-photo" },
                on: { input: _vm.output },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确定")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }