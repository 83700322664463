var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "210px" },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "basic_config_content",
          attrs: { gutter: 24 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("h2", [_vm._v("心水推荐")]),
              _c(
                "el-form-item",
                { attrs: { label: "发帖限制VIP等级", prop: "xsVipSend" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.xsVipSend,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "xsVipSend", $$v)
                        },
                        expression: "ruleForm.xsVipSend",
                      },
                    },
                    _vm._l(_vm.Levels, function (level) {
                      return _c("el-option", {
                        key: level.id,
                        attrs: {
                          value: level.vipLevel,
                          label: "VIP" + level.vipLevel,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "评论限制VIP等级", prop: "xsVipReply" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.xsVipReply,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "xsVipReply", $$v)
                        },
                        expression: "ruleForm.xsVipReply",
                      },
                    },
                    _vm._l(_vm.Levels, function (level) {
                      return _c("el-option", {
                        key: level.id,
                        attrs: {
                          value: level.vipLevel,
                          label: "VIP" + level.vipLevel,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "每日限制发贴次数", prop: "xsSendNum" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.xsSendNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "xsSendNum", $$v)
                      },
                      expression: "ruleForm.xsSendNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "每日限制评论次数", prop: "xsReplyNum" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.xsReplyNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "xsReplyNum", $$v)
                      },
                      expression: "ruleForm.xsReplyNum",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v("六合图库")]),
              _c(
                "el-form-item",
                { attrs: { label: "评论限制VIP等级", prop: "lhcVipReply" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.lhcVipReply,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "lhcVipReply", $$v)
                        },
                        expression: "ruleForm.lhcVipReply",
                      },
                    },
                    _vm._l(_vm.Levels, function (level) {
                      return _c("el-option", {
                        key: level.id,
                        attrs: {
                          value: level.vipLevel,
                          label: "VIP" + level.vipLevel,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "每日限制评论次数", prop: "lhcReplyNum" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.lhcReplyNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "lhcReplyNum", $$v)
                      },
                      expression: "ruleForm.lhcReplyNum",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v("晒单圈")]),
              _c(
                "el-form-item",
                { attrs: { label: "发言限制VIP等级", prop: "circleVipSend" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.circleVipSend,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "circleVipSend", $$v)
                        },
                        expression: "ruleForm.circleVipSend",
                      },
                    },
                    _vm._l(_vm.Levels, function (level) {
                      return _c("el-option", {
                        key: level.id,
                        attrs: {
                          value: level.vipLevel,
                          label: "VIP" + level.vipLevel,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "评论限制VIP等级", prop: "circleVipReply" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.circleVipReply,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "circleVipReply", $$v)
                        },
                        expression: "ruleForm.circleVipReply",
                      },
                    },
                    _vm._l(_vm.Levels, function (level) {
                      return _c("el-option", {
                        key: level.id,
                        attrs: {
                          value: level.vipLevel,
                          label: "VIP" + level.vipLevel,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "每日限制发送次数", prop: "circleSendNum" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.circleSendNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "circleSendNum", $$v)
                      },
                      expression: "ruleForm.circleSendNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "每日限制评论次数", prop: "circleReplyNum" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.circleReplyNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "circleReplyNum", $$v)
                      },
                      expression: "ruleForm.circleReplyNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户删除帖子", prop: "delCircleStatus" } },
                [
                  _c(
                    "d-switch",
                    {
                      attrs: {
                        size: "large",
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#3894FF",
                        "inactive-color": "#F26161",
                      },
                      model: {
                        value: _vm.ruleForm.delCircleStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "delCircleStatus", $$v)
                        },
                        expression: "ruleForm.delCircleStatus",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("开启"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("关闭"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("h2", [_vm._v(_vm._s(_vm.$t("跟单大厅")))]),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("VIP等级推单"), prop: "vipPushOrder" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("请选择") },
                      model: {
                        value: _vm.ruleForm.vipPushOrder,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "vipPushOrder", $$v)
                        },
                        expression: "ruleForm.vipPushOrder",
                      },
                    },
                    _vm._l(_vm.Levels, function (level) {
                      return _c("el-option", {
                        key: level.id,
                        attrs: {
                          value: level.vipLevel,
                          label: "VIP" + level.vipLevel,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("每日限制推送次数"),
                    prop: "pushOrderNum",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.pushOrderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "pushOrderNum", $$v)
                      },
                      expression: "ruleForm.pushOrderNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("VIP等级打赏"), prop: "vipReward" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("请选择") },
                      model: {
                        value: _vm.ruleForm.vipReward,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "vipReward", $$v)
                        },
                        expression: "ruleForm.vipReward",
                      },
                    },
                    _vm._l(_vm.Levels, function (level) {
                      return _c("el-option", {
                        key: level.id,
                        attrs: {
                          value: level.vipLevel,
                          label: "VIP" + level.vipLevel,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("每日限制打赏次数"),
                    prop: "rewardNum",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.rewardNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "rewardNum", $$v)
                      },
                      expression: "ruleForm.rewardNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("每日测试账号打赏次数"),
                    prop: "rewardNumTestUser",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.rewardNumTestUser,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "rewardNumTestUser", $$v)
                      },
                      expression: "ruleForm.rewardNumTestUser",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("单次最高打赏金额"),
                    prop: "maxRewardAmount",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.maxRewardAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "maxRewardAmount", $$v)
                        },
                        expression: "ruleForm.maxRewardAmount",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("￥")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("跟单中奖分红上限"),
                    prop: "bonusLimit",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.bonusLimit,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "bonusLimit", $$v)
                        },
                        expression: "ruleForm.bonusLimit",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("推单赔率下限"), prop: "oddsLimit" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.oddsLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "oddsLimit", $$v)
                      },
                      expression: "ruleForm.oddsLimit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("平台抽取分红金额"),
                    prop: "systemBonus",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.systemBonus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "systemBonus", $$v)
                        },
                        expression: "ruleForm.systemBonus",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("互动大厅&彩票聊天室")))]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("VIP等级发言"),
                    prop: "lotteryChatVipSpeak",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("请选择") },
                      model: {
                        value: _vm.ruleForm.lotteryChatVipSpeak,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "lotteryChatVipSpeak", $$v)
                        },
                        expression: "ruleForm.lotteryChatVipSpeak",
                      },
                    },
                    _vm._l(_vm.Levels, function (level) {
                      return _c("el-option", {
                        key: level.id,
                        attrs: {
                          value: level.vipLevel,
                          label: "VIP" + level.vipLevel,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("文本字数限制"),
                    prop: "lotteryChatWordLimit",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "0-9999" },
                    model: {
                      value: _vm.ruleForm.lotteryChatWordLimit,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "lotteryChatWordLimit",
                          _vm._n($$v)
                        )
                      },
                      expression: "ruleForm.lotteryChatWordLimit",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("体育赛事聊天室")))]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("VIP等级发言"),
                    prop: "sportsChatVipSpeak",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("请选择") },
                      model: {
                        value: _vm.ruleForm.sportsChatVipSpeak,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "sportsChatVipSpeak", $$v)
                        },
                        expression: "ruleForm.sportsChatVipSpeak",
                      },
                    },
                    _vm._l(_vm.Levels, function (level) {
                      return _c("el-option", {
                        key: level.id,
                        attrs: {
                          value: level.vipLevel,
                          label: "VIP" + level.vipLevel,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("文本字数限制"),
                    prop: "sportsChatWordLimit",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "0-9999" },
                    model: {
                      value: _vm.ruleForm.sportsChatWordLimit,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "sportsChatWordLimit",
                          _vm._n($$v)
                        )
                      },
                      expression: "ruleForm.sportsChatWordLimit",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("直播聊天室")))]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("VIP等级发言"),
                    prop: "liveChatVipSpeak",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("请选择") },
                      model: {
                        value: _vm.ruleForm.liveChatVipSpeak,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "liveChatVipSpeak", $$v)
                        },
                        expression: "ruleForm.liveChatVipSpeak",
                      },
                    },
                    _vm._l(_vm.Levels, function (level) {
                      return _c("el-option", {
                        key: level.id,
                        attrs: {
                          value: level.vipLevel,
                          label: "VIP" + level.vipLevel,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("文本字数限制"),
                    prop: "liveChatWordLimit",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "0-9999" },
                    model: {
                      value: _vm.ruleForm.liveChatWordLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "liveChatWordLimit", _vm._n($$v))
                      },
                      expression: "ruleForm.liveChatWordLimit",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "action-button" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("保 存")))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$refs["ruleForm"].resetFields()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("重 置")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }