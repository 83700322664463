<template>
  <el-dialog title="编辑用户" :visible.sync="visible" width="500px" top="5vh" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="账号">
        <el-input v-model="ruleForm.operatorName" disabled style="width: 300px;" />
      </el-form-item>
      <el-form-item label="昵称" prop="nickName">
        <el-input v-model="ruleForm.nickName" style="width: 300px;" />
      </el-form-item>
      <el-form-item label="用户分组">
        <el-select v-model="ruleForm.groupId" placeholder="请选择用户分组" style="width: 300px;">
          <el-option v-for="item in groupList" :key="item.id" :label="item.groupName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="修改密码" prop="password">
        <el-input v-model="ruleForm.password" type="password" :placeholder="$t('请输入密码(不填即为不修改)')" style="width: 300px;" />
      </el-form-item>
      <el-form-item label="谷歌验证码" prop="googleCode">
        <el-input v-model.trim="ruleForm.googleCode" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { validAccountAndPassword } from '@/utils/validate'
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  operatorName: '',
  nickName: '',
  groupId: null,
  password: undefined,
  googleCode: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value && !validAccountAndPassword(value)) {
        callback(new Error(this.$t('请输入6~12位英文数字密码')))
      } else {
        callback()
      }
    }
    return {
      emitName: 'update',
      rules: {
        nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        password: [{ validator: validatePassword, trigger: 'blur' }],
        googleCode: [
          { required: true, message: '请输入谷歌验证码', trigger: 'blur' },
          { pattern: /^[0-9]{6}$/, message: '请输入6位谷歌验证码', trigger: ['change', 'blur'] }
        ]
      }
    }
  },
  computed: {
    groupList() {
      return this.$parent.groupList
    }
  },
  methods: {
    open({ tenantCode, operatorId, operatorName, nickName, groupId }) {
      this.ruleForm.tenantCode = tenantCode
      this.ruleForm.operatorId = operatorId
      this.ruleForm.operatorName = operatorName
      this.ruleForm.nickName = nickName
      this.ruleForm.groupId = groupId
      this.visible = true
    }
  }
}
</script>
