export default {
  今天: '今天',
  昨天: '昨天',
  _7天: '7天',
  本周: '本周',
  上周: '上周',
  本月: '本月',
  上月: '上月',
  至: '至',
  开始日期: '开始日期',
  结束日期: '结束日期',
  查询: '查询',
  批量修改: '批量修改',
  批量修改会员等级: '批量修改会员等级',
  批量修改用户状态: '批量修改用户状态',
  批量修改上级代理: '批量修改上级代理',
  用户类型: '用户类型',
  VIP等级: 'VIP等级',
  用户状态: '用户状态',
  在线状态: '在线状态',
  启用: '启用',
  停用: '停用',
  在线: '在线',
  离线: '离线',
  关键词: '关键词',
  会员账号: '会员账号',
  所属上级: '所属上级',
  用户余额大于: '用户余额大于',
  充值金额大于: '充值金额大于',
  充值金额小于: '充值金额小于',
  最近多少天未充值: '最近多少天未充值',
  最近多少天未登陆: '最近多少天未登陆',
  最近多少天登陆过: '最近多少天登陆过',
  备注内容: '备注内容',
  内部账号: '内部账号',
  注册账户: '注册账户',
  充值用户: '充值用户',
  测试账户: '测试账户',
  总余额: '总余额',
  总人数: '总人数',
  确认: '确认',
  此用户: '此用户',
  系统提示: '系统提示',
  操作成功: '操作成功!',
  添加成功: '添加成功!',
  发送成功: '发送成功',
  请输入少于100条数据: '请输入少于100条数据!',
  代理等级: '代理等级',
  所属代理: '所属代理',
  会员等级: '会员等级',
  账号余额: '账号余额',
  会员昵称: '会员昵称',
  注册时间: '注册时间',
  最后登录: '最后登录',
  在线情况: '在线情况',
  状态: '状态',
  查看: '查看',
  操作: '操作',
  暂无: '暂无',
  锁定: '锁定',
  未锁定: '未锁定',
  账变: '账变',
  注单: '注单',
  修改: '修改',
  修改密码: '修改密码',
  添加备注: '添加备注',
  上级代理: '上级代理',
  关闭: '关闭',
  保存: '保存',
  新代理: '新代理',
  请输入会员账号组: '请输入会员账号组',
  请输入正确的数据: '请输入正确的数据',
  数据格式: '数据格式：会员1,会员2,会员3 使用逗号隔开;每次修改的数量不得超过100条',
  请选择VIP等级: '请选择VIP等级',
  请选择会员等级: '请选择会员等级',
  请选择是否锁定等级: '请选择是否锁定等级',
  锁定等级: '锁定等级',
  不锁定: '不锁定',
  锁定后: '锁定后，用户充值金额投注金额达到等级时，等级不会改变',
  会员状态: '会员状态',
  请选择会员状态: '请选择会员状态',
  请输入新代理: '请输入新代理',
  修改会员: '修改会员',
  头像: '头像',
  银行卡: '银行卡',
  测试用户设置: '测试用户设置',
  设置为测试账号: '设置为测试账号',
  设置为内部账号: '设置为内部账号',
  更多内容: '更多内容',
  注册来源: '注册来源',
  聊天室: '聊天室',
  敏感词过滤: '敏感词过滤',
  禁用: '禁用',
  请输入昵称: '请输入昵称',
  '请输入2~12位': '请输入2~12位',
  请选择用户状态: '请选择用户状态',
  请选择用户等级: '请选择用户等级',
  请输入正确手机号码: '请输入正确手机号码',
  请选择: '请选择{text}',
  用户聊天室状态已更新: '用户聊天室状态已更新',
  禁言: '禁言',
  用户聊天室: '用户聊天室',
  修改用户等级: '修改用户等级',
  用户等级: '用户等级',
  锁定状态: '锁定状态',
  请选择锁定状态: '请选择锁定状态',
  查看会员详细信息: '查看会员详细信息',
  基本信息: '基本信息',
  登录账号: '登录账号',
  类别: '类别',
  等级: '等级',
  会员余额: '会员余额',
  出款所需打码量: '出款所需打码量',
  当前打码量: '当前打码量',
  总打码量: '总打码量',
  真实姓名: '真实姓名',
  银行地址: '银行地址',
  银行账号: '银行账号',
  注册IP: '注册IP',
  注册设备: '注册设备',
  最后登录时间: '最后登录时间',
  最后登录IP: '最后登录IP',
  最后登录设备: '最后登录设备',
  第三方余额: '第三方余额',
  联系方式: '联系方式',
  提现信息: '提现信息',
  人工加款: '人工加款',
  人工减款: '人工减款',
  总提款次数: '总提款次数',
  总提款金额: '总提款金额',
  首次提款: '首次提款',
  最高提款: '最高提款',
  当日提款金额: '当日提款金额',
  当日提款次数: '当日提款次数',
  充值信息: '充值信息',
  总充值次数: '总充值次数',
  总充值金额: '总充值金额',
  首次充值: '首次充值',
  最高充值: '最高充值',
  当日充值金额: '当日充值金额',
  当日充值次数: '当日充值次数',
  备注: '备注',
  用户账号: '用户账号',
  密码类型: '密码类型',
  登录密码: '登录密码',
  提款密码: '提款密码',
  新密码: '新密码',
  确认密码: '确认密码',
  请输入密码: '请输入密码',
  '请输入6-12位数字字母组合密码': '请输入6-12位数字字母组合密码',
  '请输入6位数字提款密码': '请输入6位数字提款密码',
  请再次输入密码: '请再次输入密码',
  两次输入密码不一致: '两次输入密码不一致!',
  修改上级代理: '修改上级代理',
  当前账号: '当前账号',
  旧代理: '旧代理',
  无: '无',
  发送个人站内信: '发送个人站内信',
  接收人: '接收人',
  站内信标题: '站内信标题',
  站内信内容: '站内信内容',
  请输入站内信标题: '请输入站内信标题',
  请输入站内信内容: '请输入站内信内容',
  用户账户: '用户账户',
  银行账户: '银行账户',
  正常: '正常',
  黑名单: '黑名单',
  保存成功: '保存成功!',
  确认删除此银行: '确认删除此银行?',
  删除成功: '删除成功!',
  银行名称: '银行名称',
  绑定时间: '绑定时间',
  修改时间: '修改时间',
  编辑: '编辑',
  删除: '删除',
  上次修改时间: '上次修改时间',
  请输入真实姓名: '请输入真实姓名',
  请输入银行账户: '请输入银行账户',
  请输入银行名称: '请输入银行名称',
  请选择状态: '请选择状态',
  转账姓名: '转账姓名',
  充值状态: '充值状态',
  订单状态: '订单状态',
  充值成功: '充值成功',
  已拒绝: '已拒绝',
  待处理: '待处理',
  收款银行: '收款银行',
  收款姓名: '收款姓名',
  收款卡号: '收款卡号',
  订单号: '订单号',
  操作员: '操作员',
  订单总额: '订单总额',
  元: '元',
  导出Excel: '导出Excel',
  会员信息: '会员信息',
  充值金额: '充值金额',
  赠送金额: '赠送金额',
  货币汇率: '货币汇率',
  货币金额: '货币金额',
  收款信息: '收款信息',
  操作信息: '操作信息',
  操作说明: '操作说明',
  账号: '账号',
  多次充值: '多次充值',
  二次充值: '二次充值',
  发起: '发起',
  结束: '结束',
  附言: '附言',
  确认条件: '确认条件',
  连接中: '连接中',
  停止接收: '停止接收',
  开始接收: '开始接收',
  收款钱包地址: '收款钱包地址',
  充值方式: '充值方式',
  数字货币: '数字货币',
  通过所选: '通过所选',
  拒绝所选: '拒绝所选',
  导出所选: '导出所选',
  充值审核列表: '充值审核列表',
  收款人: '收款人',
  收款银行账号: '收款银行账号',
  发起时间: '发起时间',
  充值平台金额: '充值平台金额',
  操作人: '操作人',
  通过: '通过',
  拒绝: '拒绝',
  审核通过: '审核通过',
  拒绝通过: '拒绝通过',
  批量审核通过: '批量审核通过',
  批量拒绝通过: '批量拒绝通过',
  申请时间: '申请时间',
  累计充值: '累计充值',
  累计提现: '累计提现',
  会员备注: '会员备注',
  申请金额: '申请金额',
  操作时间: '操作时间',
  提现金额小于: '提现金额小于',
  提现金额大于: '提现金额大于',
  导出选中: '导出选中',
  导出TXT: '导出TXT',
  提现订单列表: '提现订单列表',
  拾取成功: '拾取成功',
  确认批量拾取选中订单: '确认批量拾取选中订单?',
  批量拾取成功: '批量拾取成功',
  总提现金额: '总提现金额',
  平台币金额: '平台币金额',
  拾取: '拾取',
  已拾取: '已拾取',
  他人拾取: '他人拾取',
  第三方代付: '第三方代付',
  批量通过: '批量通过',
  批量拒绝: '批量拒绝',
  导出EXCEL: '导出EXCEL',
  提现审核表: '提现审核表',
  商户ID: '商户ID',
  商户订单号: '商户订单号',
  持卡人: '持卡人',
  卡号: '卡号',
  代付金额: '代付金额',
  开户行: '开户行',
  开户网点: '开户网点',
  拾取时间: '拾取时间',
  提现: '提现',
  批量提现: '批量提现',
  平台名称: '平台名称',
  支付方式: '支付方式',
  支付状态: '支付状态',
  平台流水号: '平台流水号',
  确认进行补单: '确认进行补单',
  来源: '来源',
  实付平台金额: '实付平台金额',
  创建时间: '创建时间',
  补单记录: '补单记录',
  补单: '补单',
  变动类型: '变动类型',
  账变金额: '账变金额',
  变动金额: '变动金额',
  变动前金额: '变动前金额',
  变动后金额: '变动后金额',
  变动前所需打码量: '变动前所需打码量',
  变动后所需打码量: '变动后所需打码量',
  变动时间: '变动时间',
  操作员备注: '操作员备注',
  批量处理: '批量处理',
  批量加款: '批量加款',
  批量扣款: '批量扣款',
  人工扣款: '人工扣款',
  人工线下充值: '人工线下充值',
  批量处理信息数据格式不正确: '批量处理信息数据格式不正确',
  批量用户存在重复: '批量用户存在重复',
  批量操作成功: '批量操作成功',
  提现金额: '提现金额',
  封停: '封停',
  加款: '加款',
  扣款: '扣款',
  扣款操作: '扣款操作',
  加款操作: '加款操作',
  账户余额: '账户余额',
  增加金额: '增加金额',
  请输入需要增加的金额: '请输入需要增加的金额',
  计入打码量: '计入打码量',
  是: '是',
  否: '否',
  打码量倍数: '打码量倍数',
  请输入打码量倍数: '请输入打码量倍数',
  类型: '类型',
  礼金: '礼金',
  现金: '现金',
  扣款金额: '扣款金额',
  请输入需要扣除的金额: '请输入需要扣除的金额',
  操作原因: '操作原因',
  请输入操作原因: '请输入操作原因',
  请输入金额: '请输入金额',
  请输入正确金额: '请输入正确金额',
  请输入正确打码量倍数: '请输入正确打码量倍数',
  请选择计入打码量: '请选择计入打码量',
  '每行的数据格式：会员账号,金额 使用逗号或者空格隔开': '每行的数据格式：会员账号,金额 使用逗号或者空格隔开',
  如: '如',
  使用空格隔开: '使用空格隔开',
  使用逗号隔开: '使用逗号隔开',
  请输入批量处理信息: '请输入批量处理信息',
  批量增加打码量: '批量增加打码量',
  批量减少打码量: '批量减少打码量',
  加码: '加码',
  减码: '减码',
  打码量: '打码量',
  所需打码量: '所需打码量',
  增加打码量: '增加打码量',
  请输入需增加的打码量: '请输入需增加的打码量',
  请输入需减少的打码量: '请输入需减少的打码量',
  减少打码量: '减少打码量',
  开奖方式: '开奖方式',
  开奖倒计时: '开奖倒计时',
  期号: '期号',
  开奖号码: '开奖号码',
  待开奖: '待开奖',
  预开奖号码: '预开奖号码',
  开奖时间: '开奖时间',
  平台投注数: '平台投注数',
  平台盈利亏损: '平台盈利亏损',
  手动开奖: '手动开奖',
  重新开奖: '重新开奖',
  下载重开未扣: '下载重开未扣',
  预开奖: '预开奖',
  投注详情: '投注详情',
  统计: '统计',
  修改成功: '修改成功',
  撤销开奖成功: '撤销开奖成功!',
  重新开奖成功: '重新开奖成功!',
  接口开奖: '接口开奖',
  平台开奖: '平台开奖',
  杀号开奖: '杀号开奖',
  独立随机开奖: '独立随机开奖',
  独立杀号开奖: '独立杀号开奖',
  开奖方式修改: '开奖方式修改',
  彩种类型: '彩种类型',
  官方开奖: '官方开奖',
  独立开奖: '独立开奖',
  开奖获取: '开奖获取',
  开启规则: '开启规则',
  日投注收益: '日投注收益',
  最低的: '最低的',
  家: '家',
  _7日盈利为7日投注额度的: '7日盈利为7日投注额度的',
  '请输入0-100的数，且最多有两位小数!': '请输入0-100的数，且最多有两位小数!',
  开奖要求: '开奖要求',
  投注100最低可中: '投注100最低可中',
  投注100最高可中: '投注100最高可中',
  杀号时间最多: '杀号时间最多',
  取消: '取消',
  '取 消': '取 消',
  确定: '确定',
  请输入: '请输入{text}',
  '请输入0~100的数，且最多有3位小数': '请输入0~100的数，且最多有3位小数',
  '请输入1~99的数': '请输入1~99的数',
  '请输入0~99999的数，且最多有3位小数': '请输入0~99999的数，且最多有3位小数',
  最高可中金额需大于最低可中金额: '最高可中金额需大于最低可中金额',
  '请输入1~7的数值': '请输入1~7的数值',
  确认结算: '确认结算',
  期开奖: '期开奖',
  重开方式: '重开方式',
  重开号码: '重开号码',
  _1本功能: '1、本功能正对所选彩种和期号对开奖错误进行【撤销开奖】/【重新开奖】/【重开结果】；',
  _2撤销开奖: '2、撤销开奖：对已中奖用户，收回该期所有已派发奖金（用户余额不足时，可扣除金额到负数）；并将订单设为已撤单状态退还投注金额',
  _3重新开奖: '3、重新开奖：对已中奖用户，收回该期所有已派发奖金（用户余额不足时，可扣除金额到负数）；在通过填写的新开奖号码进行开奖结算，对中奖的用户进行派奖；',
  撤销开奖: '撤销开奖',
  期重新开奖: '期重新开奖',
  请选择重开方式: '请选择重开方式',
  结算统计: '结算统计',
  总订单数: '总订单数',
  总投注金额: '总投注金额',
  总中奖单数: '总中奖单数',
  总中奖金额: '总中奖金额',
  总下注人数: '总下注人数',
  总盈亏: '总盈亏',
  暂无数据: '暂无数据',
  系列: '系列',
  彩种: '彩种',
  玩法: '玩法',
  会员类型: '会员类型',
  '投注超过额度(大于10)': '投注超过额度(大于10)',
  '盈利超过额度(大于10)': '盈利超过额度(大于10)',
  总投注额: '总投注额',
  有效投注额: '有效投注额',
  中奖金额: '中奖金额',
  盈亏: '盈亏',
  总用户: '总用户',
  结算成功: '结算成功',
  期号结算成功: '期号结算成功',
  撤单成功: '撤单成功',
  期号撤单成功: '期号撤单成功',
  昵称: '昵称',
  彩种期号: '彩种期号',
  玩法分类: '玩法分类',
  内容: '内容',
  金额: '金额',
  投注: '投注',
  中奖: '中奖',
  用户盈亏: '用户盈亏',
  时间: '时间',
  更新: '更新',
  结算: '结算',
  撤单: '撤单',
  期号结算: '期号结算',
  期号撤单: '期号撤单',
  用户: '用户',
  投注额度: '投注额度',
  赔率: '赔率',
  校验: '校验',
  请输入开奖号码: '请输入开奖号码',
  投注金额: '投注金额',
  订单: '订单',
  开奖查询: '开奖查询',
  备注信息操作: '备注信息操作',
  备注信息: '备注信息',
  请输入内容: '请输入内容',
  选中: '选中',
  条: '条',
  合计金额: '合计金额',
  全选: '全选',
  拾取选中: '拾取选中',
  彩票统计: '彩票统计',
  派奖: '派奖',
  总计: '总计',
  今日更新数据: '今日更新数据',
  更新时间: '更新时间',
  在线人数: '在线人数',
  新注册在线数: '新注册在线数',
  更新注册并充值人数时间: '更新注册并充值人数时间',
  注册并充值人数: '注册并充值人数',
  充值人数: '充值人数',
  提现人数: '提现人数',
  送礼人数: '送礼人数',
  付费人数: '付费人数',
  投注人数: '投注人数',
  注册人数: '注册人数',
  礼物消费: '礼物消费',
  房费消费: '房费消费',
  彩票投注: '彩票投注',
  游戏投注: '游戏投注',
  第三方额度统计: '第三方额度统计',
  平台: '平台',
  总额度: '总额度',
  已使用: '已使用',
  剩余: '剩余',
  登录注册: '登录注册',
  同IP每日注册个数: '同IP每日注册个数',
  同IP每日登录个数: '同IP每日登录个数',
  前端输入密码错误冻结次数: '前端输入密码错误冻结次数',
  后台Google验证: '后台Google验证',
  开启: '开启',
  购彩配置: '购彩配置',
  是否允许用户撤单: '是否允许用户撤单',
  投注默认一注额度: '投注默认一注额度',
  一级返佣比例: '一级返佣比例',
  二级返佣比例: '二级返佣比例',
  结算方式: '结算方式',
  保存结算方式: '保存结算方式',
  人工: '人工',
  自动: '自动',
  代理返点: '代理返点',
  国家区号: '国家区号',
  区号: '区号',
  前端维护: '前端维护',
  维护说明: '维护说明',
  维护: '维护',
  '提款、入款开始处理时间': '提款、入款开始处理时间',
  存取款配置: '存取款配置',
  开始时间: '开始时间',
  '提款、入款结束处理时间': '提款、入款结束处理时间',
  结束时间: '结束时间',
  提款最低额度: '提款最低额度',
  提款最高额度: '提款最高额度',
  '2次充值时间的间隔': '2次充值时间的间隔',
  '2次提款时间的间隔': '2次提款时间的间隔',
  提款次数只记录成功次数: '提款次数只记录成功次数',
  每日最高提现次数: '每日最高提现次数',
  存款未处理记录失效时间: '存款未处理记录失效时间',
  提款未处理记录失效时间: '提款未处理记录失效时间',
  新增修改银行卡不能提款时间: '新增修改银行卡不能提款时间',
  绑定银行卡数量: '绑定银行卡数量',
  主播联系方式金额: '主播联系方式金额',
  广播礼物金额: '广播礼物金额',
  免费时长设置: '免费时长设置',
  直播: '直播',
  '保 存': '保 存',
  '确 定': '确 定',
  '重 置': '重 置',
  秒: '秒',
  次: '次',
  小时: '小时',
  分钟: '分钟',
  购彩聊天室: '购彩聊天室',
  VIP等级发言: 'VIP等级发言',
  VIP等级发言发图: 'VIP等级发言发图',
  文本字数限制: '文本字数限制',
  直播聊天室: '直播聊天室',
  跟单大厅: '跟单大厅',
  VIP等级推单: 'VIP等级推单',
  每日限制推送次数: '每日限制推送次数',
  VIP等级打赏: 'VIP等级打赏',
  每日限制打赏次数: '每日限制打赏次数',
  每日测试账号打赏次数: '每日测试账号打赏次数',
  单次最高打赏金额: '单次最高打赏金额',
  跟单中奖分红上限: '跟单中奖分红上限',
  推单赔率下限: '推单赔率下限',
  平台抽取分红金额: '平台抽取分红金额',
  '请输入0-9999': '请输入0-9999',
  下载配置: '下载配置',
  下载地址: '下载地址',
  例如: '例如',
  IOS下载地址: 'IOS下载地址',
  Android下载地址: 'Android下载地址',
  下载地址1: '下载地址1',
  下载地址2: '下载地址2',
  下载地址3: '下载地址3',
  app弹窗下载地址: 'app弹窗下载地址',
  推送配置: '推送配置',
  极光配置: '极光配置',
  请输入极光appkey: '请输入极光appkey',
  请输入极光masterSecre: '请输入极光masterSecre',
  客服配置: '客服配置',
  客服Telegram: '客服Telegram',
  第三方客服: '第三方客服',
  第三方聊天室: '第三方聊天室',
  追加人数: '追加人数',
  真实人数: '真实人数',
  聊天室内容: '聊天室内容',
  聊天室图标: '聊天室图标',
  聊天室描述: '聊天室描述',
  敏感词: '敏感词',
  敏感词列表: '敏感词列表',
  一行代表一个敏感词: '一行代表一个敏感词',
  确认操作: '确认操作',
  聊天室类型: '聊天室类型',
  跟单: '跟单',
  可赢: '可赢',
  已截止: '已截止',
  截止: '截止',
  删除当前消息: '删除当前消息',
  删除消息并禁言: '删除消息并禁言',
  聊天室名称: '聊天室名称',
  请输入地址: '请输入地址',
  查找: '查找',
  简述: '简述',
  'H5/Web版本': 'H5/Web版本',
  H5地址: 'H5地址',
  WEB地址: 'WEB地址',
  体育首页跳转: '体育首页跳转',
  赛事记录: '赛事记录',
  足球专家: '足球专家',
  热门预测: '热门预测',
  短信配置: '短信配置',
  短信运营商: '短信运营商',
  权重: '权重',
  模板ID: '模板ID',
  请上传图标: '请上传图标',
  '上传图标只能是 JPG、PNG 格式!': '上传图标只能是 JPG、PNG 格式!',
  '上传图标大小不能超过 2MB!': '上传图标大小不能超过 2MB!',
  配置: '配置',
  '输入0.001~99999999 输入汇率x平台币=当前货币金额': '输入0.001~99999999 输入汇率x平台币=当前货币金额',
  美元: '美元',
  越南盾: '越南盾',
  卢比: '卢比',
  人民币: '人民币',
  日元: '日元',
  韩元: '韩元',
  港币: '港币',
  马来西亚币: '马来西亚币',
  热门推荐: '热门推荐',
  热门游戏排序: '热门游戏排序',
  排序: '排序',
  '彩种/厂商选择': '彩种/厂商选择',
  热门系列: '热门系列',
  游戏: '游戏',
  首页气泡文字: '首页气泡文字',
  娱乐首页推荐排序: '娱乐首页推荐排序',
  体育首页推荐排序: '体育首页推荐排序',
  游戏选择: '游戏选择',
  请选择游戏: '请选择游戏',
  请至少选择一个热门游戏: '请至少选择一个热门游戏',
  关于我们: '关于我们',
  足球说明: '足球说明',
  彩票类: '彩票类',
  第三方游戏: '第三方游戏',
  广告图位置: '广告图位置',
  标题: '标题',
  添加广告: '添加广告',
  全部保存: '全部保存',
  新增成功: '新增成功',
  编辑成功: '编辑成功',
  确认删除标题: '确认删除标题',
  广告图: '广告图',
  '暂无数据无法保存！': '暂无数据无法保存！',
  '确认批量修改公告排序?': '确认批量修改公告排序?',
  '批量修改成功！': '批量修改成功！',
  首页推荐页: '首页推荐页',
  首页直播页: '首页直播页',
  个人中心: '个人中心',
  游戏中心: '游戏中心',
  安卓: '安卓',
  展示位置: '展示位置',
  广告标题: '广告标题',
  语言: '语言',
  展示终端: '展示终端',
  是否启用: '是否启用',
  编辑时间: '编辑时间',
  请输入标题: '请输入标题',
  请输入跳转链接地址: '请输入跳转链接地址',
  请选择展示位置: '请选择展示位置',
  跳转方式: '跳转方式',
  链接: '链接',
  活动: '活动',
  请选择跳转活动: '请选择跳转活动',
  '关 闭': '关 闭',
  新增: '新增',
  广告: '广告',
  跳转链接: '跳转链接',
  活动ID: '活动ID',
  活动标题: '活动标题',
  活动BANNER: '活动BANNER',
  内页BANNER: '内页BANNER',
  活动Banner: '活动Banner',
  活动引导: '活动引导',
  跳转页面: '跳转页面',
  活动时段: '活动时段',
  活动状态: '活动状态',
  持续开启: '持续开启',
  新建活动: '新建活动',
  '创建成功!': '创建成功!',
  '确定删除活动?': '确定删除活动?',
  链接地址: '链接地址',
  请输入链接地址信息: '请输入链接地址信息',
  语言选择: '语言选择',
  活动名称: '活动名称',
  活动引导说明: '活动引导说明',
  活动时间: '活动时间',
  定时开启: '定时开启',
  限20字以内: '限20字以内',
  限50字以内: '限50字以内',
  文件大小控制在100K以内: '文件大小控制在100K以内',
  点击上传: '点击上传',
  纯图片: '纯图片',
  图配文: '图配文',
  请填写活动标题: '请填写活动标题',
  请填写活动引导说明: '请填写活动引导说明',
  请选择跳转页面: '请选择跳转页面',
  请填写链接地址: '请填写链接地址',
  请选择活动Banner: '请选择活动Banner',
  请加入活动内页: '请加入活动内页',
  请选择语言: '请选择语言',
  创建活动: '创建活动',
  编辑活动: '编辑活动',
  活动内页: '活动内页',
  请选择公告类型: '请选择公告类型',
  添加公告: '添加公告',
  首页推荐: '首页推荐',
  直播间: '直播间',
  充值: '充值',
  主页记录: '主页记录',
  ABPay大厅跑马灯: 'ABPay大厅跑马灯',
  确认批量修改公告: '确认批量修改公告?',
  公告类型: '公告类型',
  公告标题: '公告标题',
  是否弹出: '是否弹出',
  公告时间: '公告时间',
  公告内容: '公告内容',
  请选择标题类型: '请选择标题类型',
  请选择公告时间: '请选择公告时间',
  编辑广告: '编辑广告',
  创建广告: '创建广告',
  请选择发送方式: '请选择发送方式',
  请输入单个会员账户: '请输入单个会员账户',
  发送站内信: '发送站内信',
  单个会员发送: '单个会员发送',
  指定会员列表发送: '指定会员列表发送',
  按VIP等级发送: '按VIP等级发送',
  充值会员发送: '充值会员发送',
  未充值会员发送: '未充值会员发送',
  '的站内信？': '的站内信？',
  发送方式: '发送方式',
  已读回执: '已读回执',
  单个会员显示回执: '单个会员显示回执',
  已读: '已读',
  未读: '未读',
  '请输入接收会员的账号, 多个账号之间以英文逗号隔开,例如:账号1,账号2': '请输入接收会员的账号, 多个账号之间以英文逗号隔开,例如:账号1,账号2',
  '请输入接收会员的账号, 例如:账号1': '请输入接收会员的账号, 例如:账号1',
  会员VIP等级: '会员VIP等级',
  '请输入会员VIP等级，多个等级以英文逗号隔开, 例如: 4,5,6': '请输入会员VIP等级，多个等级以英文逗号隔开, 例如: 4,5,6',
  编辑站内信: '编辑站内信',
  新增站内信: '新增站内信',
  请选择发送类型: '请选择发送类型',
  请输入行动电话后四位: '请输入行动电话后四位',
  忘记密码: '忘记密码',
  注册: '注册',
  短信登录: '短信登录',
  发送失败: '发送失败',
  发送类型: '发送类型',
  行动电话: '行动电话',
  简讯码: '简讯码',
  发送时间: '发送时间',
  发送IP地址: '发送IP地址',
  发送结果: '发送结果',
  赔率设置: '赔率设置',
  玩法设置: '玩法设置',
  玩法分类设置: '玩法分类设置',
  玩法说明设置: '玩法说明设置',
  彩种说明设置: '彩种说明设置',
  保存配置: '保存配置',
  重置成功: '重置成功',
  彩票系列: '彩票系列',
  直播配置: '直播配置',
  开奖说明: '开奖说明',
  官方直播: '官方直播',
  '确认重置所有玩法基础数据吗?': '确认重置所有玩法基础数据吗?',
  请选择彩种: '请选择彩种',
  一级玩法: '一级玩法',
  二级玩法: '二级玩法',
  彩种介绍设置: '彩种介绍设置',
  批量设置赔率: '批量设置赔率',
  最小金额批量设置: '重置成功',
  最大金额批量设置: '最大金额批量设置',
  '确认修改属性值为text的信息': '确认修改属性值为 {text} 的信息',
  确认修改所有属性值信息: '确认修改所有属性值信息',
  属性: '属性',
  最小投注额: '最小投注额',
  最大投注额: '最大投注额',
  编码: '编码',
  直播间开关: '直播间开关',
  保存修改: '保存修改',
  批量设置: '批量设置',
  最小金额: '最小金额',
  最大金额: '最大金额',
  请输入VIP等级: '请输入VIP等级',
  确认修改所有玩法信息: '确认修改所有玩法信息',
  确认修改玩法playName的信息: '确认修改玩法 {text} 的信息',
  彩种名称: '彩种名称',
  玩法分组名称: '玩法分组名称',
  序号: '序号',
  请输入彩种名称: '请输入彩种名称',
  选择是否开启官方直播: '选择是否开启官方直播',
  '直播视频': '直播视频',
  '请输入直播视频url': '请输入直播视频url',
  '确认修改属性值为{playClassName}的信息': '确认修改属性值为 {text} 的信息',
  最大可选号码: '最大可选号码',
  '确认修改属性值为{groupName}的信息': '确认修改属性值为{groupName}的信息',
  玩法规则: '玩法规则',
  举例说明: '举例说明',
  期: '期',
  '确认修改{name}的状态吗?': '确认修改 {text}的状态吗?',
  启停: '启停',
  显示隐藏: '显示隐藏',
  显示: '显示',
  隐藏: '隐藏',
  赛事名称: '赛事名称',
  球队名称: '球队名称',
  球队: '球队',
  赛前: '赛前',
  赛中: '赛中',
  确认取消此赛事: '确认取消此赛事',
  联赛: '联赛',
  滚球联赛: '滚球联赛',
  滚球赛事: '滚球赛事',
  滚球球队: '滚球球队',
  滚球盘口: '滚球盘口',
  主队: '主队',
  客队: '客队',
  开赛时间: '开赛时间',
  比赛状态: '比赛状态',
  '上半场/下半场': '上半场/下半场',
  赔率改变: '赔率改变',
  变赔: '变赔',
  无效: '无效',
  未: '未',
  胜: '胜',
  平: '平',
  负: '负',
  变赔记录: '变赔记录',
  比赛类型: '比赛类型',
  赛事编号: '赛事编号',
  足球: '足球',
  篮球: '篮球',
  对阵: '对阵',
  修改赔率: '修改赔率',
  变赔: '变赔',
  '玩法平赔率 格式不合法': '玩法平赔率 格式不合法',
  暂未更新赔率: '暂未更新赔率',
  '赔率格式不合法!': '赔率格式不合法!',
  聊天室人数: '聊天室人数',
  增加人数: '增加人数',
  清屏: '清屏',
  输入信息: '输入信息',
  管理员: '管理员',
  '上传图片只能是 JPG、PNG、JEPG 格式!': '上传图片只能是 JPG、PNG、JEPG 格式!',
  '上传图片大小不能超过 2MB!': '上传图片大小不能超过 2MB!',
  删除消息: '删除消息',
  禁言并删除消息: '禁言并删除消息',
  本单总投注金额: '本单总投注金额',
  '第{n}期': '第 {text} 期',
  添加限制: '添加限制',
  请输入限制内容: '请输入限制内容',
  限制说明: '限制说明',
  限制内容: '限制内容',
  请输入限制说明: '请输入限制说明',
  请出入限制内容: '请出入限制内容',
  请输入正确IP: '请输入正确IP',
  请出入限制说明: '请出入限制说明',
  '请输入2~240个字符': '请输入2~240个字符',
  最后操作时间: '最后操作时间',
  请输入IP: '请输入IP',
  '确定删除{ip}这条IP限制?': '确定删除 {text} 这条IP限制?',
  请输入手机号或号段: '请输入手机号或号段',
  '确定删除{phone}这条限制?': '确定删除 {text} 这条限制?',
  封IP: '封IP',
  封手机: '封手机',
  直播状态: '直播状态',
  频道选择: '频道选择',
  当前彩种: '当前彩种',
  主播昵称: '主播昵称',
  房间主题: '房间主题',
  所属家族: '所属家族',
  '查 询': '查 询',
  当前房间合计人数: '当前房间合计人数',
  房间热度设定: '房间热度设定',
  免费超清: '免费超清',
  高清: '高清',
  普清: '普清',
  是否结束当前流媒体播放: '是否结束当前流媒体播放?',
  '是否将主播踢下线并禁播（可在主播管理解除禁播）': '是否将主播踢下线并禁播（可在主播管理解除禁播）?',
  '确认置顶此直播间?': '确认置顶此直播间?',
  '确认取消置顶此直播间?': '确认取消置顶此直播间?',
  房间彩种: '房间彩种',
  分辨率: '分辨率',
  频道: '频道',
  房间价格: '房间价格',
  房间热度: '房间热度',
  房间人数: '房间人数',
  观看人次: '观看人次',
  '房费分成(预计)': '房费分成(预计)',
  '礼物分成(预计)': '礼物分成(预计)',
  '有效投注分成(预计)': '有效投注分成(预计)',
  置顶状态: '置顶状态',
  直播中: '直播中',
  已下播: '已下播',
  置顶: '置顶',
  结束播放: '结束播放',
  流播放: '流播放',
  踢下线: '踢下线',
  会员列表: '会员列表',
  房间编辑: '房间编辑',
  礼物金额加成值: '礼物金额加成值',
  房费金额加成值: '房费金额加成值',
  投注金额加成值: '投注金额加成值',
  观看人次加成值: '观看人次加成值',
  加成值大于等于1: '加成值大于等于1',
  倍: '倍',
  热度组成如下: '热度组成如下',
  '(单次礼物合计*n)+(单次房费合计*n)+(单次投注合计*n)+(单次观看人次*n)+人工热度': '(单次礼物合计*n)+(单次房费合计*n)+(单次投注合计*n)+(单次观看人次*n)+人工热度',
  请输入正确: '请输入正确',
  请上传: '请上传',
  '请输入0-99999': '请输入0-99999',
  '请输入0-60': '请输入0-60',
  请输入房间主题: '请输入房间主题',
  '请输入5-3600': '请输入5-3600',
  编辑房间: '编辑房间',
  封面: '封面',
  '最大可输入99999': '最大可输入99999',
  '单个房间机器人数量上限(0-60)': '单个房间机器人数量上限(0-60)',
  '单位为秒（5-3600）': '单位为秒（5-3600）',
  机器人数量: '机器人数量',
  机器人进入间隔: '机器人进入间隔',
  机器人是否投注: '机器人是否投注',
  机器人是否送礼: '机器人是否送礼',
  流播放功能: '流播放功能',
  流媒体地址: '流媒体地址',
  '下播后的主播，可通过后台填入流媒体地址进行虚拟开播，与正常开播效果相同': '下播后的主播，可通过后台填入流媒体地址进行虚拟开播，与正常开播效果相同',
  频道名称: '频道名称',
  新建频道: '新建频道',
  '删除频道后，原来归属于该频道下的房间只会出现在热门频道内!': '删除频道后，原来归属于该频道下的房间只会出现在热门频道内!',
  请谨慎操作: '请谨慎操作',
  频道图标: '频道图标',
  中文名称: '中文名称',
  英文名称: '英文名称',
  越南名称: '越南名称',
  数字越大排序越前: '数字越大排序越前',
  建议输入2个汉字: '建议输入2个汉字',
  请输入英文名称: '请输入英文名称',
  请输入越南名称: '请输入越南名称',
  '提示：频道图标建议30*30px，格式为PNG': '提示：频道图标建议30*30px，格式为PNG',
  请输入排序: '请输入排序',
  请输入正确排序: '请输入正确排序',
  请输入中文名称: '请输入中文名称',
  请上传频道图标: '请上传频道图标',
  频道创建: '频道创建',
  频道编辑: '频道编辑',
  礼物名称: '礼物名称',
  创建礼物: '创建礼物',
  '确认启用?': '确认启用?',
  '确认禁用?': '确认禁用?',
  礼物图标: '礼物图标',
  礼物属性: '礼物属性',
  连击礼物: '连击礼物',
  动画礼物: '动画礼物',
  礼物价格: '礼物价格',
  动画地址: '动画地址',
  礼物状态: '礼物状态',
  动画状态: '动画状态',
  联动状态: '联动状态',
  '编 辑': '编 辑',
  建议图标规则: '建议图标规则',
  '1.尺寸：160x160px': '1.尺寸：160x160px',
  '2.格式：PNG': '2.格式：PNG',
  '3.大小：1M内': '3.大小：1M内',
  礼物动画: '礼物动画',
  支持3位小数: '支持3位小数',
  请选择礼物属性: '请选择礼物属性',
  '请上传动画文件(仅支持10M内的svga格式动画)': '请上传动画文件(仅支持10M内的svga格式动画)',
  请上传礼物图标: '请上传礼物图标',
  请输入礼物价格: '请输入礼物价格',
  请输入正确礼物价格: '请输入正确礼物价格',
  请输入正确序号: '请输入正确序号',
  请上传礼物动画: '请上传礼物动画',
  '您上载的档案格式不对！': '您上载的档案格式不对！',
  礼物创建: '礼物创建',
  礼物编辑: '礼物编辑',
  礼物分类编辑: '礼物分类编辑',
  分类名称: '分类名称',
  分类状态: '分类状态',
  方案名称: '方案名称',
  震动值: '震动值',
  方案状态: '方案状态',
  '0-20之间；用英文逗号隔开；以0结束': '0-20之间；用英文逗号隔开；以0结束',
  主播账号: '主播账号',
  家族名称: '家族名称',
  新增主播: '新增主播',
  '确认启用此主播账号吗?': '确认启用此主播账号吗?',
  '确认禁用该主播账号吗???禁用后该账号退出后将不可再登录!': '确认禁用该主播账号吗???禁用后该账号退出后将不可再登录!',
  '确认启用此主播开播权限?': '确认启用此主播开播权限?',
  '确认禁用此主播开播权限?': '确认禁用此主播开播权限?',
  账号状态: '账号状态',
  开播权限: '开播权限',
  '6-12位': '6-12位',
  '请输入6~12位英文数字': '请输入6~12位英文数字',
  关联家族: '关联家族',
  下拉选择家族: '下拉选择家族',
  请选择家族: '请选择家族',
  请输入登录账号: '请输入登录账号',
  '登录账号长度为6-12位': '登录账号长度为6-12位',
  请输入联系方式: '请输入联系方式',
  '联系方式长度为2-20位': '联系方式长度为2-20位',
  编辑主播: '编辑主播',
  家族账号: '家族账号',
  新增家族: '新增家族',
  '是否删除该家族!!!': '是否删除该家族!!!',
  关联主播数: '关联主播数',
  礼物分成比例: '礼物分成比例',
  投注分成比例: '投注分成比例',
  房费分成比例: '房费分成比例',
  分成配置: '分成配置',
  登陆密码: '登陆密码',
  可提现账号类型: '可提现账号类型',
  支付宝: '支付宝',
  微信: '微信',
  选择类型: '选择类型',
  '0-10字': '0-10字',
  请输入开户行: '请输入开户行',
  持卡人姓名: '持卡人姓名',
  提现姓名: '提现姓名',
  提现帐号: '提现帐号',
  '6-24字': '6-24字',
  礼物收入分成: '礼物收入分成',
  '0-99支持1位小数': '0-99支持1位小数',
  '请输入家族名称!': '请输入家族名称!',
  '请选择可提现帐户类型!': '请选择可提现帐户类型!',
  '请输入姓名!': '请输入姓名!',
  '请输入提现帐号!': '请输入提现帐号!',
  '请输入正确的帐号!': '请输入正确的帐号!',
  '提现帐号,最短10位,最大25位!': '提现帐号,最短10位,最大25位!',
  请输入礼物收入分成: '请输入礼物收入分成',
  请输入正确分成: '请输入正确分成',
  请输入投注分成: '请输入投注分成',
  请输入登录密码: '请输入登录密码',
  请输入家族账号: '请输入家族账号',
  请输入房费分成: '请输入房费分成',
  投注分成: '投注分成',
  '0.1-100支持1位小数': '0.1-100支持1位小数',
  请正确输入房费分成: '请正确输入房费分成',
  请正确输入投注分成: '请正确输入投注分成',
  请正确输入礼物分成: '请正确输入礼物分成',
  编辑家族: '编辑家族',
  打款成功: '打款成功',
  '此操作会立即结算从上次提现到当前点击确认的时间范围内家族收入，如果该家族有主播还在开播，当次开播的收入会纳入下次提现': '此操作会立即结算从上次提现到当前点击确认的时间范围内家族收入，如果该家族有主播还在开播，当次开播的收入会纳入下次提现',
  可提现账户类型: '可提现账户类型',
  提现账号: '提现账号',
  未结算金额: '未结算金额',
  本次结算金额: '本次结算金额',
  明细: '明细',
  确认打款: '确认打款',
  明细详情: '明细详情',
  日期: '日期',
  礼物分成汇总: '礼物分成汇总',
  投注分成汇总: '投注分成汇总',
  房费分成汇总: '房费分成汇总',
  总分成汇总: '总分成汇总',
  开播时段: '开播时段',
  '礼物收入/分成': '礼物收入/分成',
  '投注收入/分成': '投注收入/分成',
  '房费收入/分成': '房费收入/分成',
  总计分成: '总计分成',
  结算时间: '结算时间',
  '家族分成(礼物/投注/房费)': '家族分成(礼物/投注/房费)',
  可提现金额: '可提现金额',
  打款状态: '打款状态',
  未打款: '未打款',
  已打款: '已打款',
  当前已打款金额: '当前已打款金额',
  投注合计: '投注合计',
  礼物合计: '礼物合计',
  房费合计: '房费合计',
  时长合计: '时长合计',
  开播时间: '开播时间',
  下播时间: '下播时间',
  开播时长: '开播时长',
  房费分成: '房费分成',
  礼物分成: '礼物分成',
  投注流水: '投注流水',
  礼物流水: '礼物流水',
  房费流水: '房费流水',
  检索: '检索',
  待开奖投注金额: '待开奖投注金额',
  有效投注金额: '有效投注金额',
  主播收入: '主播收入',
  投注时间: '投注时间',
  礼物总额: '礼物总额',
  礼物金额: '礼物金额',
  房费总额: '房费总额',
  '停留时间(分)': '停留时间(分)',
  房费支出: '房费支出',
  离场时间: '离场时间',
  巡查账号: '巡查账号',
  新增巡查: '新增巡查',
  '确定更改可见状态???': '确定更改可见状态???',
  确认启用此用户: '确认启用此用户',
  确认禁用此用户: '确认禁用此用户',
  可见状态: '可见状态',
  再次输入新密码: '再次输入新密码',
  创建巡查: '创建巡查',
  区域号: '区域号',
  密码: '密码',
  '6-11位数字': '6-11位数字',
  '2-12位': '2-12位',
  请输入账号: '请输入账号',
  操作日志: '操作日志',
  执行时间: '执行时间',
  目标类型: '目标类型',
  目标账号: '目标账号',
  老妹儿: '老妹儿',
  房间名称: '房间名称',
  指定会员: '指定会员',
  全部禁言: '全部禁言',
  全部禁入: '全部禁入',
  房间禁言: '房间禁言',
  房间禁入: '房间禁入',
  '确认解除此会员账号?': '确认解除此会员账号?',
  当前状态: '当前状态',
  限制时段: '限制时段',
  解除: '解除',
  限制类型: '限制类型',
  请输入关键词: '请输入关键词',
  限制时间: '限制时间',
  选择结束时间: '选择结束时间',
  永久: '永久',
  请选择结束时间: '请选择结束时间',
  请选择限制类型: '请选择限制类型',
  刷新线路配置: '刷新线路配置',
  新建线路: '新建线路',
  '确定删除name?': '确定删除 {text} ?',
  确认启用此域名: '确认启用此域名',
  确认停用此域名: '确认停用此域名',
  线路名: '线路名',
  推流域名: '推流域名',
  '推流鉴权KEY(主)': '推流鉴权KEY(主)',
  '推流鉴权KEY(备)': '推流鉴权KEY(备)',
  播放域名: '播放域名',
  添加播放: '添加播放',
  点击查看: '点击查看',
  创建线路: '创建线路',
  编辑线路: '编辑线路',
  线路名称: '线路名称',
  '鉴权KEY(主)': '鉴权KEY(主)',
  '鉴权KEY(备)': '鉴权KEY(备)',
  请勿输入空格: '请勿输入空格',
  请输入线路名称: '请输入线路名称',
  请输入推流域名: '请输入推流域名',
  请选择播放协议: '请选择播放协议',
  '请输入鉴权KEY': '请输入鉴权KEY',
  '请输入播放域名': '请输入播放域名',
  请输入简称: '请输入简称',
  创建播放域名: '创建播放域名',
  编辑播放域名: '编辑播放域名',
  播放域名简称: '播放域名简称',
  播放协议: '播放协议',
  对应推流域名: '对应推流域名',
  简称: '简称',
  '播放鉴权KEY(主)': '播放鉴权KEY(主)',
  '播放鉴权KEY(备)': '播放鉴权KEY(备)',
  添加VIP等级: '添加VIP等级',
  '确认删除此VIP等级?': '确认删除此VIP等级?',
  VIP图标: 'VIP图标',
  所需充值金额: '所需充值金额',
  晋级彩金: '晋级彩金',
  '购彩返利(%)': '购彩返利(%)',
  创建VIP等级: '创建VIP等级',
  编辑VIP等级: '编辑VIP等级',
  '请输入VIP等级(唯一)': '请输入VIP等级(唯一)',
  请输入升级所需充值金额: '请输入升级所需充值金额',
  请输入晋级彩金: '请输入晋级彩金',
  请输入购彩返利: '请输入购彩返利',
  购彩返利: '购彩返利',
  等级图标: '等级图标',
  '请上传动画文件(支持SVGA)': '请上传动画文件(支持SVGA)',
  请输入有效金额: '请输入有效金额',
  请输入有效晋级彩金: '请输入有效晋级彩金',
  请输入有效数字: '请输入有效数字',
  请上传等级图标: '请上传等级图标',
  请输入有效等级: '请输入有效等级',
  请输入代理账号: '请输入代理账号',
  请输入代理昵称: '请输入代理昵称',
  代理: '代理',
  '确认通过?': '确认通过?',
  请输入拒绝原因: '请输入拒绝原因',
  '批量操作操作!': '批量操作操作!',
  代理账号: '代理账号',
  代理昵称: '代理昵称',
  负盈利金额: '负盈利金额',
  未结算订单金额: '未结算订单金额',
  团队人数: '团队人数',
  一级代理业绩: '一级代理业绩',
  二级代理业绩: '二级代理业绩',
  代理返佣: '代理返佣',
  确认批量通过审核: '确认批量通过审核',
  '通 过': '通 过',
  '拒 绝': '拒 绝',
  负盈利: '负盈利 ',
  请选择游戏类型: '请选择游戏类型',
  返水总开关: '返水总开关',
  '确定修改返水总开关状态?': '确定修改返水总开关状态?',
  '请勾选需要修改的数据！': '请勾选需要修改的数据！',
  '确认修改 lotteryName 的返水信息?': '确认修改 {text} 的返水信息?',
  '确认批量修改勾选的返水信息?': '确认批量修改勾选的返水信息?',
  '返水比例请输入0-100的数，且最多有三位小数的数值': '返水比例请输入0-100的数，且最多有三位小数的数值',
  返水比例: '返水比例',
  '请输入0-100的数，且最多有三位小数！': '请输入0-100的数，且最多有三位小数！',
  请选择代理等级: '请选择代理等级',
  级代理: '级代理',
  添加返佣配置: '添加返佣配置',
  '确认删除此配置?': '确认删除此配置?',
  升级要求推广人数: '升级要求推广人数',
  升级要求投注金额: '升级要求投注金额',
  升级要求充值金额: '升级要求充值金额',
  等级对应一级返佣点: '等级对应一级返佣点',
  等级对应二级返佣点: '等级对应二级返佣点',
  编辑返佣配置: '编辑返佣配置',
  请输入代理ID: '请输入代理ID',
  代理ID格式不正确: '代理ID格式不正确',
  请输入升级要求推广人数: '请输入升级要求推广人数',
  升级要求推广人数格式不正确: '升级要求推广人数格式不正确',
  请输入升级要求投注金额: '请输入升级要求投注金额',
  升级要求投注金额格式不正确: '升级要求投注金额格式不正确',
  请输入升级要求充值金额: '请输入升级要求充值金额',
  升级要求充值金额格式不正确: '升级要求充值金额格式不正确',
  请输入等级对应一级返佣点: '请输入等级对应一级返佣点',
  等级对应一级返佣点格式不正确: '等级对应一级返佣点格式不正确',
  请输入等级对应二级返佣点: '请输入等级对应二级返佣点',
  等级对应二级返佣点格式不正确: '等级对应二级返佣点格式不正确',
  卖出倍数: '卖出倍数',
  联赛编号: '联赛编号',
  过关方式: '过关方式',
  定位胆: '定位胆',
  胆: '胆',
  '串关/倍数': '串关/倍数',
  订单信息: '订单信息',
  快速购彩: '快速购彩',
  倍数: '倍数',
  单关: '单关',
  周: '周',
  已取消: '已取消',
  异常订单: '异常订单',
  扣款失败: '扣款失败',
  处理失败: '处理失败',
  已撤单: '已撤单',
  已中奖: '已中奖',
  未中奖: '未中奖',
  开奖中: '开奖中',
  打和: '打和',
  待审核: '待审核',
  充值失败: '充值失败',
  支付失败: '支付失败',
  等待支付: '等待支付',
  支付成功: '支付成功',
  银行卡转银行卡: '银行卡转银行卡',
  微信转银行卡: '微信转银行卡',
  支付宝转银行卡: '支付宝转银行卡',
  提现失败: '提现失败',
  提现成功: '提现成功',
  代付中: '代付中',
  代付成功: '代付成功',
  代付失败: '代付失败',
  未审核: '未审核',
  审核拒绝: '审核拒绝',
  进行中: '进行中',
  待开始: '待开始',
  已结束: '已结束',
  未开赛: '未开赛',
  上半场: '上半场',
  中场: '中场',
  下半场: '下半场',
  加时赛: '加时赛',
  点球决战: '点球决战',
  完场: '完场',
  推迟: '推迟',
  中断: '中断',
  腰斩: '腰斩',
  待定: '待定',
  用户名称: '用户名称',
  注单号: '注单号',
  玩家输赢: '玩家输赢',
  玩家数量: '玩家数量',
  游戏名称: '游戏名称',
  有效投注额度: '有效投注额度',
  输赢: '输赢',
  公司账号: '公司账号',
  第三方账号: '第三方账号',
  支付平台设置: '支付平台设置',
  支付方式设置: '支付方式设置',
  收款方式: '收款方式',
  银行卡号: '银行卡号',
  备用: '备用',
  添加银行账户: '添加银行账户',
  添加USDT地址: '添加USDT地址',
  确认删除此账户: '确认删除此账户',
  确认停用此用户: '确认停用此用户',
  '确定重置此账户额度上限?': '确定重置此账户额度上限?',
  支付名称: '支付名称',
  币种: '币种',
  最低额度: '最低额度',
  最高额度: '最高额度',
  上限额度: '上限额度',
  已收款: '已收款',
  赠送比例: '赠送比例',
  重置上限: '重置上限',
  支付方式图标: '支付方式图标',
  '便于区分和记忆，用于前端配置收款账户使用': '便于区分和记忆，用于前端配置收款账户使用',
  请选择收款方式: '请选择收款方式',
  请选择类型: '请选择类型',
  默认100: '默认100',
  前端展示图标: '前端展示图标',
  数字货币钱包类型: '数字货币钱包类型',
  数字货币钱包地址: '数字货币钱包地址',
  '转换平台币的汇率；例如:6 充值50 获得300平台币': '转换平台币的汇率；例如:6 充值50 获得300平台币',
  '当前收款账户支持的最低充值金额，默认为100元': '当前收款账户支持的最低充值金额，默认为100元',
  当前收款账户收款最高额度: '当前收款账户收款最高额度',
  当前收款账户收款上限额度: '当前收款账户收款上限额度',
  按照充值金额得比例额外赠送给用户的金额: '按照充值金额得比例额外赠送给用户的金额',
  '数值越小，排序越靠前': '数值越小，排序越靠前',
  '数值越大，排序越靠前': '数值越大，排序越靠前',
  不启用: '不启用',
  地址: '地址',
  汇率: '汇率',
  请输入支付名称: '请输入支付名称',
  请选择支付方式图标: '请选择支付方式图标',
  请输入汇率: '请输入汇率',
  请输入正确汇率: '请输入正确汇率',
  请输入正确额度: '请输入正确额度',
  请输入最高额度: '请输入最高额度',
  请输入赠送比例: '请输入赠送比例',
  请输入正确比例: '请输入正确比例',
  请输入序号: '请输入序号',
  请输入上限额度: '请输入上限额度',
  新增支付账户: '新增支付账户',
  编辑支付账户: '编辑支付账户',
  收款账户分配到那种方式: '收款账户分配到那种方式',
  货币种类: '货币种类',
  银行开户名: '银行开户名',
  银行卡的卡号: '银行卡的卡号',
  '开户行名称 例如：中国银行': '开户行名称 例如：中国银行',
  '设置0为不限制': '设置0为不限制',
  请选择货币种类: '请选择货币种类',
  请输入收款姓名: '请输入收款姓名',
  请输入收款卡号: '请输入收款卡号',
  请输入收款银行: '请输入收款银行',
  新增银行账户: '新增银行账户',
  编辑银行账户: '编辑银行账户',
  支付方式名称: '支付方式名称',
  支付方式标识: '支付方式标识',
  添加支付方式: '添加支付方式',
  '确认删除此支付方式?': '确认删除此支付方式?',
  '只支持.jpg、.png格式': '只支持.jpg、.png格式',
  请输入支付方式名称: '请输入支付方式名称',
  请输入支付方式标识: '请输入支付方式标识',
  请上传支付方式图标: '请上传支付方式图标',
  编辑支付方式: '编辑支付方式',
  图标: '图标',
  平台标识: '平台标识',
  商户名: '商户名',
  商户号: '商户号',
  添加支付平台: '添加支付平台',
  '确认删除此设置?': '确认删除此设置?',
  网关: '网关',
  渠道码: '渠道码',
  最小代付金额: '最小代付金额',
  最大代付金额: '最大代付金额',
  最大代付金额需要大于最小代付金额: '最大代付金额需要大于最小代付金额',
  回调地址: '回调地址',
  RSA公钥: 'RSA公钥',
  RSA私钥: 'RSA私钥',
  IP白名单: 'IP白名单',
  绑定支付平台: '绑定支付平台',
  请输入平台名称: '请输入平台名称',
  请输入平台标识: '请输入平台标识',
  请输入商户名: '请输入商户名',
  请输入网关: '请输入网关',
  请输入商户号: '请输入商户号',
  请输入回调地址: '请输入回调地址',
  添加第三方账户: '添加第三方账户',
  '确认删除此账户?': '确认删除此账户?',
  绑定第三方账户: '绑定第三方账户',
  商户名称: '商户名称',
  请选择支付平台: '请选择支付平台',
  请选择支付方式: '请选择支付方式',
  请填写最低额度: '请填写最低额度',
  请填写最高额度: '请填写最高额度',
  开始期号: '开始期号',
  结束期号: '结束期号',
  中奖总额: '中奖总额',
  待开奖金额: '待开奖金额',
  请选择系列: '请选择系列',
  请输入开始期号: '请输入开始期号',
  请输入结束期号: '请输入结束期号',
  请完善搜寻信息: '请完善搜寻信息',
  入款总额: '入款总额',
  出款总额: '出款总额',
  赠送总额: '赠送总额',
  回收总额: '回收总额',
  收益总额: '收益总额',
  '收益总额=入款金额-出款金额-赠送总额+回收总额': '收益总额=入款金额-出款金额-赠送总额+回收总额',
  充值入款: '充值入款',
  第三方入款: '第三方入款',
  提现出款: '提现出款',
  系统赠送: '系统赠送',
  系统回收: '系统回收',
  入款合计: '入款合计',
  出款合计: '出款合计',
  账面收益: '账面收益',
  人: '人',
  活动回收: '活动回收',
  人数: '人数',
  机器人分红: '机器人分红',
  机器人被打赏: '机器人被打赏',
  跟单分红抽成: '跟单分红抽成',
  合计: '合计',
  优惠明细: '优惠明细',
  VIP礼金: 'VIP礼金',
  活动礼金: '活动礼金',
  注册赠送: '注册赠送',
  分享赠送: '分享赠送',
  分享返水: '分享返水',
  彩票返水: '彩票返水',
  第三方游戏返水: '第三方游戏返水',
  注册地区: '注册地区',
  设备: '设备',
  IP地址: 'IP地址',
  地区: '地区',
  渠道管理: '渠道管理',
  '渠道/链接': '渠道/链接',
  首充详情: '首充详情',
  首次充值金额: '首次充值金额',
  首次充值时间: '首次充值时间',
  渠道标识: '渠道标识',
  活跃用户: '活跃用户',
  新增会员: '新增会员',
  首充金额: '首充金额',
  过滤IP数: '过滤IP数',
  过滤UDID数: '过滤UDID数',
  次日留存: '次日留存',
  三日留存: '三日留存',
  周留存: '周留存',
  月留存: '月留存',
  新增text: '新增{text}',
  苹果: '苹果',
  笔数: '笔数',
  活跃用户详情: '活跃用户详情',
  会员ID: '会员ID',
  活跃次数: '活跃次数',
  最后活跃时间: '最后活跃时间',
  创建渠道: '创建渠道',
  返回统计: '返回统计',
  渠道状态: '渠道状态',
  渠道名: '渠道名',
  渠道域名: '渠道域名',
  '是否修改渠道状态?': '是否修改渠道状态?',
  '是否重置密码?': '是否重置密码?',
  '是否删除该渠道?': '是否删除该渠道?',
  '删除后该渠道下的推广链接不在统计新增会员！': '删除后该渠道下的推广链接不在统计新增会员！',
  提示: '提示',
  推广链接数量: '推广链接数量',
  重置密码: '重置密码',
  管理链接: '管理链接',
  新建渠道: '新建渠道',
  最长20位字母数字: '最长20位字母数字',
  '例:www.123.com或123.com': '例:www.123.com或123.com',
  创建: '创建',
  请输入渠道名: '请输入渠道名',
  '请输入6~20位英文数字': '请输入6~20位英文数字',
  请输入渠道域名: '请输入渠道域名',
  渠道域名不合法: '渠道域名不合法',
  '渠道【channelName】链接列表': '渠道【{text}】链接列表',
  添加新链接: '添加新链接',
  链接名: '链接名',
  邀请码: '邀请码',
  推广链接: '推广链接',
  绑定概率: '绑定概率',
  过滤IP: '过滤IP',
  过滤UDID: '过滤UDID',
  是否删除该链接: '是否删除该链接',
  删除后该链接不在统计新增会员: '删除后该链接不在统计新增会员',
  组别: '组别',
  修改人: '修改人',
  创建人: '创建人',
  冻结: '冻结',
  添加账号: '添加账号',
  编辑用户: '编辑用户',
  用户分组: '用户分组',
  请选择用户分组: '请选择用户分组',
  '请输入6~12位英文数字密码(不填即为不修改)': '请输入6~12位英文数字密码(不填即为不修改)',
  新增账号: '新增账号',
  '请输入6~12位英文数字账号': '请输入6~12位英文数字账号',
  权限组名: '权限组名',
  添加权限组: '添加权限组',
  '确认关闭此分组?': '确认关闭此分组?',
  '确认启用此分组?': '确认启用此分组?',
  '确认删除此分组?': '确认删除此分组?',
  权限修改: '权限修改',
  新增权限组: '新增权限组',
  全部: '全部',
  权限组名称: '权限组名称',
  '2-20个字符': '2-20个字符',
  列表显示: '列表显示',
  菜单: '菜单',
  权限: '权限',
  敏感权限: '敏感权限',
  请输入权限组名: '请输入权限组名',
  '请输入2-20个字符': '请输入2-20个字符',
  编辑权限组: '编辑权限组',
  用户登录日志: '用户登录日志',
  后台登录日志: '后台登录日志',
  登录系统: '登录系统',
  登录地区: '登录地区',
  登录域名: '登录域名',
  登录浏览器: '登录浏览器',
  登录时间: '登录时间',
  登录结果: '登录结果',
  登录成功: '登录成功',
  请选择租户: '请选择租户',
  登录设备: '登录设备',
  添加白名单: '添加白名单',
  确定删除此IP: '确定删除此IP',
  请输入IP地址: '请输入IP地址',
  访问IP: '访问IP',
  系统首页: '系统首页',
  平台公告: '平台公告',
  首页: '首页',
  退出登录: '退出登录',
  '已更换筛选条件!': '已更换筛选条件!',
  请选择代理类型: '请选择代理类型',
  一级: '一级',
  二级: '二级',
  贡献返佣: '贡献返佣',
  复制: '复制',
  '类别/等级': '类别/等级',
  用户ID: '用户ID',
  刷新: '刷新',
  调回余额: '调回余额',
  返水总金额: '返水总金额',
  代理总金额: '代理总金额',
  被打赏总金额: '被打赏总金额',
  打赏总金额: '打赏总金额',
  收取红包总金额: '收取红包总金额',
  发送红包总金额: '发送红包总金额',
  活动领取总金额: '活动领取总金额',
  推单分红总金额: '推单分红总金额',
  其他信息: '其他信息',
  调回成功: '调回成功',
  刷新name成功: '刷新{text}成功',
  复制成功: '复制成功',
  复制失败: '复制失败',
  添加银行: '添加银行',
  成功: '成功',
  '确认删除name吗？': '确认删除 {text} 吗？',
  'name 删除成功！': '{text} 删除成功！',
  '确认修改name启用状态吗？': '确认修改{text}启用状态吗？',
  '启用状态修改成功！': '启用状态修改成功！',
  修改name信息: '修改 {text} 信息',
  银行: '银行',
  货币: '货币',
  请选择货币: '请选择货币',
  上传图标: '上传图标',
  '请求超时，请稍后重试': '请求超时，请稍后重试',
  重新登入: '重新登入',
  '你暂无权限进行此操作，请联系系统管理员，或者选择其他账号登录': '你暂无权限进行此操作，请联系系统管理员，或者选择其他账号登录',
  '您已注销，可以取消以保留在该页面上，或者再次登录': '您已注销，可以取消以保留在该页面上，或者再次登录',
  中文: '中文',
  英语: '英语',
  越南语: '越南语',
  印度语: '印度语',
  日语: '日语',
  马来西亚语: '马来西亚语',
  或: '或',
  APP功能页面: 'APP功能页面',
  查看详情: '查看详情',
  代付配置: '代付配置',
  添加代付配置: '添加代付配置',
  绑定代付配置: '绑定代付配置',
  请输入m_n区间数字: '请输入{text}区间数字',
  体育项目: '体育项目',
  数据来源: '数据来源',
  是否关联: '是否关联',
  任务: '任务',
  任务ID: '任务ID',
  任务标题: '任务标题',
  任务状态: '任务状态',
  任务分类: '任务分类',
  新增任务: '新增任务',
  编辑任务: '编辑任务',
  至少需要录入一种语言的任务名称: '至少需要录入一种语言的任务名称',
  请选择VIP限制等级: '请选择VIP限制等级',
  请选择限制充值的公司账号: '请选择限制充值的公司账号',
  请选择限制充值的第三方账号: '请选择限制充值的第三方账号',
  任务时间: '任务时间',
  任务内容: '任务内容',
  编辑内容: '编辑内容',
  请输入任务名称: '请输入任务名称',
  任务开关: '任务开关',
  请选择任务分类: '请选择任务分类',
  请选择任务时间: '请选择任务时间',
  新增分类: '新增分类',
  修改分类: '修改分类',
  至少需要录入一种语言的任务分类名称: '至少需要录入一种语言的任务分类名称',
  确定删除任务分类: '确定删除任务分类？',
  分类ID: '分类ID',
  请输入分类名称: '请输入分类名称',
  请输入分类排序: '请输入分类排序',
  奖励编号: '奖励编号',
  任务类型: '任务类型',
  任务方式: '任务方式',
  编号: '编号',
  抵用券奖励: '抵用券奖励',
  彩金奖励: '彩金奖励',
  周累计天数: '周累计天数',
  领取时间: '领取时间',
  发放方式: '发放方式',
  输入0为不限制: '输入0为不限制',
  总充值金额大于等于: '总充值金额≥',
  今日充值总额大于等于: '今日充值总额≥',
  总投注金额大于等于: '总投注金额≥',
  今日投注金额大于等于: '今日投注金额≥',
  VIP限制: 'VIP限制',
  开启限制: '开启限制',
  不限制: '不限制',
  是否限制方式: '是否限制方式',
  不限制方式: '不限制方式',
  限制方式: '限制方式',
  限制: '限制',
  任务目标: '任务目标',
  参与资格: '参与资格',
  充值成功金额: '充值成功金额',
  有效充值单数: '有效充值单数',
  充值金额大于等于: '充值金额≥',
  才算有效充值单数: '才算有效充值单数',
  任务奖励: '任务奖励',
  任务天数: '任务天数',
  累计一天: '累计一天',
  累计两天: '累计两天',
  累计三天: '累计三天',
  累计四天: '累计四天',
  累计五天: '累计五天',
  累计六天: '累计六天',
  累计七天: '累计七天',
  全勤奖: '全勤奖',
  选择抵用券: '选择抵用券',
  彩金: '彩金',
  打码倍数: '打码倍数',
  请输入总充值金额大于等于: '请输入总充值金额≥',
  请输入今日充值总额大于等于: '请输入今日充值总额≥',
  请输入总投注金额大于等于: '总投注金额≥',
  请输入今日投注金额大于等于: '请输入今日投注金额≥',
  请输入充值成功金额: '请输入充值成功金额',
  请输入有效充值单数: '请输入有效充值单数',
  请输入充值金额大于等于才算有效充值单数: '请输入充值金额≥才算有效充值单数',
  请选择抵用券: '请选择抵用券',
  请输入彩金: '请输入彩金',
  请输入打码倍数: '请输入打码倍数',
  累计签到: '累计签到',
  签到时间: '签到时间',
  签到任务配置: '签到任务配置',
  签到活动按月更新: '签到活动按月更新',
  签到任务: '签到任务',
  签到任务分类: '签到任务分类',
  选择签到任务所属分类: '选择签到任务所属分类',
  可签到条件限制: '可签到条件限制',
  当日有效投注: '当日有效投注',
  签到奖励配置: '签到奖励配置',
  签到日期: '签到日期',
  签到奖励: '签到奖励',
  累计签到奖励配置: '累计签到奖励配置',
  满足条件奖励自动发放: '满足条件奖励自动发放',
  _14天: '14天',
  _15天: '15天',
  _21天: '21天',
  请选择选项: '请选择选项',
  全勤: '全勤',
  巴西币: '巴西币',
  累计签到天数: '累计签到天数',
  n号: '{n}号',
  奖励类型: '奖励类型',
  官方跳转: '官方跳转',
  官彩: '官彩',
  平台私彩: '平台私彩',
  '请输入官网名称,最多10个字符': '请输入官网名称,最多10个字符',
  '请输入官网URL': '请输入官网URL',
  请输入当日充值金额: '请输入当日充值金额',
  请输入当日充值次数: '请输入当日充值次数',
  请输入当日有效投注: '请输入当日有效投注',
  请选择签到任务分类: '请选择签到任务分类',
  分类排序: '分类排序',
  观看时长大于等于: '观看时长≥',
  送礼金额大于等于: '送礼金额≥',
  房费金额大于等于: '房费金额≥',
  发送消息大于等于: '发送消息≥',
  任务名称: '任务名称',
  新增图标: '新增图标',
  编辑图标: '编辑图标',
  'vip奖励描述': 'vip奖励描述',
  '打码要求': '打码要求',
  '入口开关': '入口开关',
  '红包说明': '红包说明',
  '为空表示该vip没有周红包': '为空表示该vip没有周红包',
  '周红包': '周红包',
  'VIP等级': 'VIP等级',
  '请输入VIP等级': '请输入VIP等级',
  '周红包有效投注': '周红包有效投注',
  '为空表示不需要有效投注': '为空表示不需要有效投注',
  '为空表示该vip没有月红包': '为空表示该vip没有月红包',
  '请输入借呗额度': '请输入借呗额度',
  '借呗额度': '借呗额度',
  '月红包充值金额': '月红包充值金额',
  '月红包有效投注': '月红包有效投注',
  '月红包': '月红包',
  '周红包充值金额': '周红包充值金额',
  '编辑红包条件': '编辑红包条件',
  客服对话: "客服对话"

}
