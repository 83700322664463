import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('彩种'),
      key: 'lotteryName',
      align: 'center'
    },
    {
      title: this.$t('类型'),
      key: 'gameType',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (<span>{ this.defaultGameType[row.gameType] }</span>)
        }
      }
    },
    {
      title: this.$t('返水比例'),
      key: 'backRate',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-input v-model={ row.backRate } size='small' clearable placeholder={this.$t('请输入0-100的数，且最多有三位小数！')}><template slot='append'>%</template></el-input>)
        }
      }
    },
    {
      title: this.$t('状态'),
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span>
              <d-switch
                v-model={row.status}
                active-value={1}
                inactive-value={0}
              />
            </span>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          const { Update } = this.permission
          return (
            <div>
              { Update && <el-link type='primary' onClick={() => this._handleUpdate(row)}>{this.$t('保存')}</el-link> }
            </div>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
