<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">{{ $t('新增分类') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <ActionDialog ref="ActionDialogControl" @create="handleSaveActivityType" @update="handleSaveActivityType" />
  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import { getActivityTypeList, AddUpdateActivityType } from '@/api/activity'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { mapState } from 'vuex'

function getParams(field) {
  const { typeNameList, ...other } = field
  let params = { ...other }
  const list = []
  const lang = []
  typeNameList.forEach(e => {
    if (e.typeName) {
      list.push(e.lang + ':' + e.typeName)
      lang.push(e.lang)
    }
  })
  if (list.length === 0) {
    return false
  }
  params.typeName = list.toString()
  params.lang = lang.toString()
  return params
}

export default {
  name: 'ClassificationTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(getActivityTypeList)],
  data() {
    return {
      search: {
        isShow: 1
      },
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  computed: {
    ...mapState('app', ['allLang'])
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogControl.open()
    },
    handleSaveActivityType({ field, cancel, close }) {
      const params = getParams(field)
      if (params === false) {
        this.$message.warning(this.$t('至少需要录入一种语言的任务分类名称'))
        cancel()
        return
      }
      AddUpdateActivityType(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    handleDeleteActivityType(row) {
      row.isDelete = 1
      this.$confirm(this.$t('确定删除任务分类?'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        AddUpdateActivityType(row).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
