export default {
  inserted: function(el) {
    el.addEventListener('keypress', function(e) {
      e = e || window.event
      if (e.isComposing) {
        e.preventDefault()
      }
      let charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      if (!/\d/.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
    })
  }
}
