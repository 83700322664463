var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "cancel-dialog",
      attrs: {
        title: "投注内容",
        width: "1000px",
        top: "5vh",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "my-table",
          attrs: { border: "", "border-color": "#ccc", "border-collaspse": "" },
        },
        [
          _c("tr", [
            _c("th", [_vm._v("类型")]),
            _c("th", [_vm._v("属性")]),
            _c("th", [_vm._v("投注")]),
            _c("th", [_vm._v("属性")]),
            _c("th", [_vm._v("投注")]),
            _c("th", [_vm._v("属性")]),
            _c("th", [_vm._v("投注")]),
            _c("th", [_vm._v("属性")]),
            _c("th", [_vm._v("投注")]),
            _c("th", [_vm._v("属性")]),
            _c("th", [_vm._v("投注")]),
          ]),
          _vm._l(_vm.sportList, function (item, index) {
            return _c(
              "tr",
              { key: index },
              [
                item.index === 0
                  ? _c("td", { attrs: { rowspan: item.rowSpan } }, [
                      _vm._v(_vm._s(item.name)),
                    ])
                  : _vm._e(),
                _vm._l(item.odds, function (oddsItem, oddsIndex) {
                  return [
                    _c("td", { key: oddsIndex + "a" }, [
                      _vm._v(_vm._s(oddsItem.name)),
                      _c("p", [_vm._v(_vm._s(oddsItem.odds))]),
                    ]),
                    _c(
                      "td",
                      { key: oddsIndex + "b" },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: oddsItem.checked,
                            callback: function ($$v) {
                              _vm.$set(oddsItem, "checked", $$v)
                            },
                            expression: "oddsItem.checked",
                          },
                        }),
                      ],
                      1
                    ),
                    item.emptyIndex === oddsIndex
                      ? [
                          _c("td", { key: oddsIndex + "c" }),
                          _c("td", { key: oddsIndex + "d" }),
                          _c("td", { key: oddsIndex + "e" }),
                          _c("td", { key: oddsIndex + "f" }),
                        ]
                      : _vm._e(),
                  ]
                }),
              ],
              2
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "danger", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }