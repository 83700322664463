var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "400px" },
            attrs: {
              clearable: "",
              type: "daterange",
              "range-separator": _vm.$t("至"),
              "start-placeholder": _vm.$t("开始日期"),
              "end-placeholder": _vm.$t("结束日期"),
              "value-format": "yyyy-MM-dd",
              "picker-options": _vm.pickerOptions,
            },
            model: {
              value: _vm.times,
              callback: function ($$v) {
                _vm.times = $$v
              },
              expression: "times",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetDate($event, 0)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("今天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetDate($event, 1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("昨天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetDate($event, 7)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("_7天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleSearch },
            },
            [_vm._v(_vm._s(_vm.$t("查询")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-refresh-right" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("重置")))]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.resources.length,
              expression: "resources.length",
            },
          ],
          attrs: { data: _vm.resources, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "time", align: "center", label: "时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("parseTime")(row.startTime, "{y}-{m}-{d}")
                      ) +
                        "~" +
                        _vm._s(_vm._f("parseTime")(row.endTime, "{y}-{m}-{d}"))
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "proceeds", align: "center", label: "入款总额" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "operatorAdd",
              align: "center",
              label: "人工加款总额",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "otherInall",
              align: "center",
              label: "其他加款总额",
            },
          }),
          _c(
            "el-table-column",
            { attrs: { prop: "payOut", align: "center", label: "出款总额" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("出款总额")]),
                  _c(
                    "el-tooltip",
                    {
                      staticStyle: {
                        cursor: "pointer",
                        "vertical-align": "middle",
                        "margin-left": "2px",
                        "margin-bottom": "2px",
                      },
                      attrs: {
                        effect: "dark",
                        content: "出款总额：只计算审核通过并出款成功的订单",
                        placement: "top",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-question",
                        staticStyle: { "font-size": "17px" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: { prop: "promotion", align: "center", label: "优惠总额" },
          }),
          _c(
            "el-table-column",
            { attrs: { prop: "income", align: "center" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("账面收益总额")]),
                  _c(
                    "el-tooltip",
                    {
                      staticStyle: {
                        cursor: "pointer",
                        "vertical-align": "middle",
                        "margin-left": "2px",
                        "margin-bottom": "2px",
                      },
                      attrs: {
                        effect: "dark",
                        content: "账面收益金额=入款金额-出款金额+其他补款",
                        placement: "bottom",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-question",
                        staticStyle: { "font-size": "17px" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "realIncome",
                align: "center",
                label: "实际收益总额",
              },
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("实际收益")]),
                  _c(
                    "el-tooltip",
                    {
                      staticStyle: {
                        cursor: "pointer",
                        "vertical-align": "middle",
                        "margin-left": "2px",
                        "margin-bottom": "2px",
                      },
                      attrs: {
                        effect: "dark",
                        content:
                          "实际收益金额=账面收益金额-优惠总额+活动回收总额",
                        placement: "bottom",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-question",
                        staticStyle: { "font-size": "17px" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          options: _vm.options,
          "filter-column": false,
        },
      }),
      _vm.total > _vm.params.pageSize
        ? _c("pagination", {
            attrs: {
              page: _vm.params.currentPage,
              limit: _vm.params.pageSize,
              total: _vm.total,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.params, "currentPage", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.params, "pageSize", $event)
              },
              pagination: _vm.handleFetch,
            },
          })
        : _vm._e(),
      _c("sale-dialog", { ref: "SaleDialogControl" }),
      _c("RecycleDialog", { ref: "RecycleDialogControl" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }