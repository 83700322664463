<template>
  <div>
    <comMenu title="娱乐版-首页顶部导航" width="600px" :is-name="false" :is-icon="false" :menu-list="menuList" :num="4" home-type="entertainment" :position="1" />
    <comMenu title="体育版-首页顶部导航" width="600px" :is-name="false" :is-icon="false" :menu-list="menuList" :num="4" home-type="sport" :position="1" />
    <comMenu title="六合版-首页顶部导航" width="600px" :is-name="false" :is-icon="false" :menu-list="menuList" :num="4" home-type="liuhe" :position="1" />
  </div>
</template>
<script>
import comMenu from '../components/comMenu.vue'

export default {
  name: 'NewActivityTab',
  componentName: 'NewActivityTab',
  components: { comMenu },
  data() {
    return {
      menuList: [
        { key: 'function.live', label: '直播' },
        { key: 'function.lottery', label: '彩讯' },
        { key: 'function.sports', label: '体讯' },
        { key: 'function.push', label: '推单' }
      ]
    }
  }
}
</script>
