<template>
  <el-dialog :title="$t('Interactive_Match.ForecastTab.滚球编辑')" :visible.sync="visible" width="500px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('滚球盘口')" prop="sourceType">
        <el-select v-model="ruleForm.sourceType" :placeholder="$t('滚球盘口')" clearable style="width: 100%;">
          <el-option v-for="(item, index) in sourceTypeOptions" :key="index" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('滚球联赛')" prop="inPlayLeague">
        <el-autocomplete
          v-model="ruleForm.inPlayLeague"
          :fetch-suggestions="queryLeagueSearch"
          :placeholder="$t('滚球联赛')"
          style="width: 100%;"
          value-key="name"
          @select="handleSelectLeague"
        />
      </el-form-item>
      <el-form-item :label="$t('滚球赛事')" prop="inPlayGame">
        <el-autocomplete
          v-model="ruleForm.inPlayGame"
          :fetch-suggestions="queryGameSearch"
          :placeholder="$t('滚球赛事')"
          style="width: 100%;"
          value-key="name"
          @select="handleSelectGame"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getThirdCompetitionList, getThirdCompetitionMatch } from '@/api/match'

const getDefaultForm = () => ({
  sourceType: undefined,
  status: 1,
  thirdMatchId: undefined,
  competitionId: undefined,
  inPlayLeague: undefined,
  inPlayGame: undefined
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {

      emitName: 'update',
      sourceTypeOptions: this.$t('sourceType'),
      rules: {
        sourceType: [
          { required: true, message: this.$t('请选择', { text: this.$t('滚球盘口') }), trigger: 'change' }
        ],
        inPlayLeague: [
          { required: true, message: this.$t('请选择', { text: this.$t('滚球联赛') }), trigger: 'change' }
        ],
        inPlayGame: [
          { required: true, message: this.$t('请选择', { text: this.$t('滚球赛事') }), trigger: 'change' }
        ]
      }
    }
  },

  methods: {
    open(row) {
      this.ruleForm.sportType = row.sportType
      this.ruleForm.matchId = row.matchId
      this.ruleForm.tenantCode = row.tenantCode
      if (row.thirdMatchId) {
        this.ruleForm.inPlayLeague = row.thirdCompetitionName
        this.ruleForm.inPlayGame = row.thirdHomeName + ' vs ' + row.thirdAwayName
        this.ruleForm.thirdMatchId = row.thirdMatchId
      }

      this.visible = true
    },
    queryLeagueSearch(queryString, cb) {
      if (!this.ruleForm.sourceType) {
        this.$message.error(this.$t('请选择', { text: this.$t('滚球盘口') }))
        return
      }

      getThirdCompetitionList({
        sourceType: this.ruleForm.sourceType,
        tenantCode: this.ruleForm.tenantCode,
        competitionName: queryString
      }).then(([data, err]) => {
        if (!err) {
          const items = data.map(p => {
            return {
              id: p.competitionId,
              name: p.competitionNameZh
            }
          })
          cb(items)
        }
      })
    },
    handleSelectLeague(item) {
      this.ruleForm.inPlayLeague = item.name
      this.ruleForm.competitionId = item.id
    },
    queryGameSearch(queryString, cb) {
      if (!this.ruleForm.sourceType) {
        this.$message.error(this.$t('请选择', { text: this.$t('滚球盘口') }))
        return
      }

      if (!this.ruleForm.competitionId) {
        this.$message.error(this.$t('请选择', { text: this.$t('滚球联赛') }))
        return
      }

      getThirdCompetitionMatch({
        sourceType: this.ruleForm.sourceType,
        tenantCode: this.ruleForm.tenantCode,
        competitionId: this.ruleForm.competitionId,
        teamName: queryString
      }).then(([data, err]) => {
        if (!err) {
          const items = data.map(p => {
            return {
              id: p.thirdMatchId,
              name: p.homeTeamNameZh + ' vs ' + p.awayTeamNameZh
            }
          })
          cb(items)
        }
      })
    },
    handleSelectGame(item) {
      this.ruleForm.inPlayGame = item.name
      this.ruleForm.thirdMatchId = item.id
    }
  }
}
</script>
