var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "210px" },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "basic_config_content",
          attrs: { gutter: 24 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c("div", { staticClass: "titleLine" }, [
                _c("div", { staticClass: "title" }, [_vm._v("推单入口配置")]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "推单总开关", prop: "followBetMainSwitch" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.followBetMainSwitch,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "followBetMainSwitch", $$v)
                        },
                        expression: "ruleForm.followBetMainSwitch",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("开启"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "desc" }, [
                    _vm._v(
                      " 至少选择一个，所有的入口都会隐藏，包括发单的下注按钮 "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "滚球热门推荐",
                    prop: "rollerBallPopularSwitch",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.rollerBallPopularSwitch,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "rollerBallPopularSwitch", $$v)
                        },
                        expression: "ruleForm.rollerBallPopularSwitch",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("开启"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "desc", staticStyle: { opacity: "0" } },
                    [_vm._v("x")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "竞彩足球推荐",
                    prop: "lotteryPopularSwitch",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.lotteryPopularSwitch,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "lotteryPopularSwitch", $$v)
                        },
                        expression: "ruleForm.lotteryPopularSwitch",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("开启"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "desc", staticStyle: { opacity: "0" } },
                    [_vm._v("x")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "六合彩热门推荐",
                    prop: "maxSixPopularSwitch",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.maxSixPopularSwitch,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "maxSixPopularSwitch", $$v)
                        },
                        expression: "ruleForm.maxSixPopularSwitch",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("开启"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "desc", staticStyle: { opacity: "0" } },
                    [_vm._v("x")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "titleLine" }, [
                _c("div", { staticClass: "title" }, [_vm._v("大神申请配置")]),
                _c("div", { staticClass: "desc" }, [
                  _vm._v("满足条件会员才可以发起申请成为大神"),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "VIP等级", prop: "guruVipLevelLimit" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.guruVipLevelLimit,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "guruVipLevelLimit", $$v)
                        },
                        expression: "ruleForm.guruVipLevelLimit",
                      },
                    },
                    _vm._l(100, function (level) {
                      return _c("el-option", {
                        key: level,
                        attrs: { value: level - 1, label: "VIP" + (level - 1) },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("h2", [_vm._v("平台佣金配置")]),
              _c(
                "el-form-item",
                { attrs: { label: "平台抽取佣金", prop: "platformRate" } },
                [
                  _c("PercentInput", {
                    model: {
                      value: _vm.ruleForm.platformRate,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "platformRate", $$v)
                      },
                      expression: "ruleForm.platformRate",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "titleLine" }, [
                _c("div", { staticClass: "title" }, [_vm._v("发单展示配置")]),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    " 满足条件会员的发单才会展示热门跟单列表上，我的关注不受影响 "
                  ),
                ]),
              ]),
              _c(
                "el-form-item",
                {
                  attrs: { label: "7日内至少连中", prop: "sevenDaysLeastHit" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.sevenDaysLeastHit,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "sevenDaysLeastHit", $$v)
                      },
                      expression: "ruleForm.sevenDaysLeastHit",
                    },
                  }),
                  _c("div", { staticClass: "inlineText" }, [_vm._v("单")]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "7日内至少盈利率",
                    prop: "sevenDaysLeastProfit",
                  },
                },
                [
                  _c("PercentInput", {
                    model: {
                      value: _vm.ruleForm.sevenDaysLeastProfit,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "sevenDaysLeastProfit", $$v)
                      },
                      expression: "ruleForm.sevenDaysLeastProfit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "发单最低投注金额",
                    prop: "orderLeastAmount",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.orderLeastAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "orderLeastAmount", $$v)
                      },
                      expression: "ruleForm.orderLeastAmount",
                    },
                  }),
                  _c("div", { staticClass: "inlineText" }, [_vm._v("元")]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最低抽佣盈利率", prop: "leastProfitRate" } },
                [
                  _c("PercentInput", {
                    model: {
                      value: _vm.ruleForm.leastProfitRate,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "leastProfitRate", $$v)
                      },
                      expression: "ruleForm.leastProfitRate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发单佣金比例", prop: "allowGuruRate" } },
                [
                  _c(
                    "div",
                    { staticClass: "elInputs" },
                    _vm._l(10, function (index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c("PercentInput", {
                            model: {
                              value: _vm.ruleForm.allowGuruRate[index - 1],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.allowGuruRate,
                                  index - 1,
                                  $$v
                                )
                              },
                              expression: "ruleForm.allowGuruRate[index - 1]",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "六合彩每期支持大神发单",
                    prop: "lotteryGuruIssueOrderNum",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.lotteryGuruIssueOrderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "lotteryGuruIssueOrderNum", $$v)
                      },
                      expression: "ruleForm.lotteryGuruIssueOrderNum",
                    },
                  }),
                  _c("div", { staticClass: "inlineText" }, [_vm._v("次")]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "体育竞彩每次支持大神发单",
                    prop: "sportGuruIssueOrderNum",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.sportGuruIssueOrderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "sportGuruIssueOrderNum", $$v)
                      },
                      expression: "ruleForm.sportGuruIssueOrderNum",
                    },
                  }),
                  _c("div", { staticClass: "inlineText" }, [_vm._v("次")]),
                ],
                1
              ),
              _c("h2", [_vm._v("跟单配置")]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "跟单最低投注金额",
                    prop: "leastFollowAmount",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.leastFollowAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "leastFollowAmount", $$v)
                      },
                      expression: "ruleForm.leastFollowAmount",
                    },
                  }),
                  _vm._v("元 "),
                ],
                1
              ),
              _c("div", { staticClass: "titleLine" }, [
                _c("div", { staticClass: "title" }, [_vm._v("评论条件")]),
                _c("div", { staticClass: "desc" }, [
                  _vm._v("满足条件会员才可以评论"),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "是否跟单才能评论", prop: "onlyFollower" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.onlyFollower,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "onlyFollower", $$v)
                        },
                        expression: "ruleForm.onlyFollower",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("开启"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "desc" }, [
                    _vm._v("该发单的用户不受这个条件影响"),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "VIP等级", prop: "commentVipLevel" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.commentVipLevel,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "commentVipLevel", $$v)
                        },
                        expression: "ruleForm.commentVipLevel",
                      },
                    },
                    _vm._l(100, function (level) {
                      return _c("el-option", {
                        key: level,
                        attrs: { value: level - 1, label: "VIP" + (level - 1) },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "action-button" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("保 存 配 置")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }