var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "table-dialog",
      attrs: {
        title: _vm.title,
        width: "610px",
        top: "5vh",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c("table", [
        _c("colgroup", [_c("col", { attrs: { width: "165" } }), _c("col")]),
        _c(
          "tbody",
          [
            _vm.emitName === "settle"
              ? [
                  _c("tr", [
                    _c("td", { staticClass: "is-right" }, [
                      _vm._v(_vm._s(_vm.$t("订单号")) + "："),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.ruleForm.orderNo))]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "is-right" }, [
                      _vm._v(_vm._s(_vm.$t("用户")) + "："),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.ruleForm.memberName))]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "is-right" }, [
                      _vm._v(_vm._s(_vm.$t("昵称")) + "："),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.ruleForm.memberNickName))]),
                  ]),
                ]
              : _vm._e(),
            _c("tr", [
              _c("td", { staticClass: "is-right" }, [
                _vm._v(_vm._s(_vm.$t("彩种")) + "："),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.ruleForm.lotteryName))]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "is-right" }, [
                _vm._v(_vm._s(_vm.$t("期号")) + "："),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.ruleForm.issue))]),
            ]),
            _vm.emitName === "settle"
              ? [
                  _c("tr", [
                    _c("td", { staticClass: "is-right" }, [
                      _vm._v(_vm._s(_vm.$t("玩法")) + "："),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.ruleForm.playName))]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "is-right" }, [
                      _vm._v(_vm._s(_vm.$t("投注额度")) + "："),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.ruleForm.amount))]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "is-right" }, [
                      _vm._v(_vm._s(_vm.$t("赔率")) + "："),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.ruleForm.odds))]),
                  ]),
                ]
              : _vm._e(),
            _c("tr", [
              _c("td", { staticClass: "is-right" }, [
                _vm._v(_vm._s(_vm.$t("开奖号码")) + "："),
              ]),
              _c(
                "td",
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.lotteryNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "lotteryNo", $$v)
                        },
                        expression: "ruleForm.lotteryNo",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "append" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleCheck($event)
                            },
                          },
                          slot: "append",
                        },
                        [_vm._v(_vm._s(_vm.$t("校验")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.validateNumber && _vm.validateRulst && _vm.emitName === "settle"
              ? [
                  _c("tr", [
                    _c("td", { staticClass: "is-right" }, [
                      _vm._v(_vm._s(_vm.$t("中奖金额")) + "："),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.validateRulst.winAmount))]),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("取消"))),
          ]),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: !_vm.validateNumber,
                loading: _vm.submitting,
              },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("保存")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }