<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <el-input v-model="search.nickname" placeholder="会员昵称" clearable class="filter-item" style="width: 200px;" />
        <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleRefresh">查询</el-button>
      </div>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >

      <template v-if="query" slot="left">
        <span>房间主题：{{ query.roomName }}</span>,
        <span>主播昵称：{{ query.nickName }}</span>,
        <span>开播时间：{{ query.onlineDate | parseTime }}</span>,
        <span>房间价格：{{ query.payAmount }}/分</span>,
        <span>当前房间人数：{{ onlineNum }}</span>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <DebarDialog ref="DebarDialogControl" @update="_handleForbidEnter" />
    <ForbiddenDialog ref="ForbiddenDialogControl" @update="_handleForbidWords" />
  </div>
</template>

<script>
import { getLiveManagementMemberList, getLiveManagementMemberOnline, forbidEnter, forbidWords } from '@/api/live'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import DebarDialog from './DebarDialog'
import ForbiddenDialog from './ForbiddenDialog'
export default {
  name: 'LiveManagementUser',
  components: { DebarDialog, ForbiddenDialog },
  mixins: [TablePageMixin(getLiveManagementMemberList, false)],
  data() {
    return {
      search: {},
      query: null,
      onlineNum: '',
      columns: [],
      defaultColumns: GetColumns.call(this)
    }
  },
  created() {
    const { params: { roomId }, query } = this.$route
    this.search.roomId = roomId
    this.query = query
    this.handleFetch()
  },
  methods: {
    _handleForbidEnter({ field, cancel, close }) {
      forbidEnter({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('禁入成功')
        } else {
          cancel()
        }
      })
    },
    _handleForbidWords({ field, cancel, close }) {
      forbidWords({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('禁言成功')
        } else {
          cancel()
        }
      })
    },

    implementationFetched() {
      getLiveManagementMemberOnline({ roomId: this.search.roomId }).then(([data, err]) => {
        if (!err) {
          this.onlineNum = data
        }
      })
    },

    implementationGetParams() {
      return { roomId: this.search.roomId }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
