<template>
  <el-dialog
    title="一键锁帖"
    width="300px"
    :visible.sync="visible"
    @closed="onClosed"
  >
    <div class="lock-dialog">
      <el-button
        v-for="button in buttons"
        :key="button.value"
        :type="button.type"
        :loading="submitting && active === button.value"
        :disabled="submitting && active !== button.value"
        @click="handleSumbit(button.value)"
      >{{ button.label }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
export default {
  name: 'LockDialog',
  mixins: [VDialogMixin],
  data: () => ({
    submitting: false,
    active: null,
    buttons: [
      { value: 41, label: '澳门六合彩', type: 'orange' },
      { value: 40, label: '香港六合彩', type: 'orange' },
      { value: 1255, label: '快乐6合', type: 'orange' },
      { value: '', label: '全部锁帖', type: 'danger' }
    ]
  }),
  methods: {
    handleSumbit(lotteryId) {
      this.active = lotteryId
      this.$emit('update', {
        field: lotteryId,
        cancel: () => {
          this.submitting = false
        },
        close: this.close
      })
    },
    onClosed() {
      this.submitting = false
      this.active = null
    }
  }
}
</script>

<style lang="scss" scoped>
.lock-dialog {
  button {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
}
</style>
