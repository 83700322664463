import request from '@/plugins/axios'

// 新增提现类型配置
export const createWithdrawTypeConf = (data) => {
  return request({
    url: '/paymentWithdrawTypeConf/add',
    method: 'post',
    data
  })
}
// 新增提现类型配置
export const upWithdrawTypeConf = (data) => {
  return request({
    url: '/paymentWithdrawTypeConf/update',
    method: 'post',
    data
  })
}

// 查询提现类型配置列表
export function queryWithdrawTypeConf(params = {}) {
  return request({
    url: '/paymentWithdrawTypeConf/list',
    method: 'get',
    params
  })
}