<template>
  <el-row v-loading="loading" :gutter="24" class="basic_config_content">
    <el-col v-for="(value, name) in ruleForm" :key="name" :span="12">
      <h2>{{ name }}{{ $t('配置') }}</h2>
      <el-form :ref="`${name}RuleForm`" :model="ruleForm" label-width="210px">
        <el-form-item v-for="(child, key) in value" :key="key" :label="key" :prop="`${name}.${key}`" :rules="rules">
          <el-input v-model="ruleForm[name][key]" />
        </el-form-item>
        <el-form-item class="action-button">
          <el-button type="danger" :loading="submitting" @click="handleSubmit(name)">{{ $t('保 存') }}</el-button>
          <el-button @click="resetFields(name)">{{ $t('重 置') }}</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import capitalize from 'lodash/capitalize'
import { getThirdGameSet, updateThirdGameSet } from '@/api/config'
export default {
  name: 'ThirdTab',
  componentName: 'ThirdTab',
  data() {
    return {
      loading: false,
      submitting: false,
      rules: [{ required: true, message: this.$t('请输入'), trigger: 'blur' }],
      ruleForm: {
        AG: {
          apiUrl: '',
          md5Key: '',
          desKey: '',
          forwardUrl: '',
          cagentValue: ''
        },
        MG: {
          apiUrl: '',
          operatorToken: '',
          secretKey: ''
        },

        KY: {
          apiUrl: '',
          md5Key: '',
          desKey: '',
          recordUrl: '',
          agent: '',
          lineCode: ''
        },

        TY: {
          apiUrl: '',
          apiKey: '',
          secretKey: '',
          betTemplate: ''
        },
        JDB: {
          key: '',
          iv: '',
          apiUrl: '',
          apiDc: '',
          apiParent: '',
          apiProxy: ''
        },
        AWC: {
          apiUrl: '',
          recordUrl: ''
        }
      }
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.loading = true
      getThirdGameSet().then(([data, err]) => {
        this.loading = false
        if (!err) {
          // for (let key in data) {
          //   if (data[key]) {
          //     for (let name in this.ruleForm[key]) {
          //       this.ruleForm[key][name] = data[key][name]
          //     }
          //   }
          // }
          Object.keys(data).forEach(key => {
            // 去除不能配置的属性
            if (!data[key]) data[key] = {};
            ['id', 'createTime', 'updateTime', 'tenantCode', 'dm'].forEach(item => {
              if (data[key] && data[key][item]) delete data[key][item]
            })
          })
          // 重新设置属性解决el-input不能编辑的问题
          this.ruleForm = Object.assign({}, this.ruleForm, data)
        }
      })
    },
    handleSubmit(name) {
      this.$refs[`${name}RuleForm`][0].validate((valid) => {
        if (valid) {
          this._updateConfig(name)
        }
      })
    },
    _updateConfig(name) {
      this.submitting = true
      const data = { ...this.ruleForm[name] }
      updateThirdGameSet(data, capitalize(name)).then(([_, err]) => {
        this.submitting = false
        if (!err) {
          this.$message.success(this.$t('请保存成功输入'))
        }
      })
    },
    resetFields(name) {
      this.$refs[`${name}RuleForm`][0].resetFields()
    }
  }
}
</script>
