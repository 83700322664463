<template>
  <div id="app">
    <router-view />
    <LoginTipsDialog v-if="$store.getters.info.operatorId" />
  </div>
</template>

<script>
import LoginTipsDialog from "@/views/components/loginTipsDialog.vue";
export default {
  components: { LoginTipsDialog },
  name: "App",
};
</script>
