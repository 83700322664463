import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span class='primary-type cursor-pointer'>{ row.memberName }</span>)
        }
      }
    },
    {
      title: this.$t('所属代理'),
      key: 'agencyMemberName',
      align: 'center'
    },
    {
      title: this.$t('会员等级'),
      key: 'vipLevel',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>VIP{row.vipLevel}</span>)
        }
      }
    },
    {
      title: this.$t('账号余额'),
      key: 'balance',
      align: 'center'
    },
    {
      title: this.$t('打码量'),
      key: 'sumRolling',
      align: 'center'
    },
    {
      title: this.$t('所需打码量'),
      key: 'withdrawRolling',
      align: 'center'
    },
    {
      title: this.$t('会员备注'),
      key: 'remark',
      align: 'center'
    },
    {
      title: this.$t('状态'),
      key: 'freezeStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.freezeStatus === 0 ? this.$t('正常') : this.$t('封停') }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      component: {
        render: (h, row) => {
          const { Sum, Subtract } = this.permission
          return (
            <span>
              { Sum && <el-button type='success' size='mini' onClick={() => this.$refs.AloneDialogControl.open(row, 6)}>{ this.$t('加码') }</el-button> }
              { Subtract && <el-button type='danger' size='mini' onClick={() => this.$refs.AloneDialogControl.open(row, 7)}>{ this.$t('减码') }</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
