<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="search.redpacketStatus" clearable :placeholder="$t('活动状态')" class="filter-item" style="width: 140px">
        <el-option v-for="item in activityStatus" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-input v-model="search.id" clearable maxlength="30" :placeholder="$t('活动ID')" class="filter-item" style="width: 200px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" type="primary" class="filter-item" @click="$router.push('/Activity_RedTab_Record')">{{ $t('红包活动记录') }}</el-button>
      <el-button v-if="permission.Add" style="float:right" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">{{ $t('新建活动') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="handleCreateActivity" @update="handleUpdateActivity" />
  </div>
</template>
<script>
import { activityStatus } from '@/libs/options'
import TablePageMixin from '@/mixins/table-page-mixin'
import { redpacketList, redpacketAdd, redpacketUpdate, redpacketClose, getActivityConfTypeList } from '@/api/activity'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { mapState } from 'vuex'
import { encoderuleForm } from './utils'

export default {
  name: 'RedTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(redpacketList)],
  data() {
    return {
      search: {},
      filterColumn: false,
      activityStatus,
      columns: GetColumns.call(this),
      activityCategory: [],
      redpacketType: { 2: '活动红包', 3: '红包雨' }
    }
  },
  computed: {
    ...mapState('app', ['allLang'])
  },
  created() {
    getActivityConfTypeList({ currentPage: 1, pageSize: 100 }).then(([items, err]) => {
      if (!err) {
        if (items.length > 0) {
          const list = []
          items.forEach(e => {
            const typeNameList = e.typeName.split(',')
            list.push({
              id: e.id,
              typeName: typeNameList[0].split(':')[1],
              typeNameLang: e.typeName
            })
          })
          this.activityCategory = list
        }
      }
    })
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogControl.open(null, 'create')
    },
    handleCreateActivity({ field, cancel, close }) {
      encoderuleForm(field).then(res => {
        redpacketAdd(res).then(([_, err]) => {
          if (!err) {
            close()
            this.$message.success('创建成功')
            this.handleRefresh()
          } else {
            cancel()
          }
        })
      }).catch(() => {
        cancel()
        this.$message.error('红包金额区间不合法')
      })
    },

    handleUpdateActivity({ field, cancel, close }) {
      encoderuleForm(field).then(res => {
        redpacketUpdate(res).then(([_, err]) => {
          if (!err) {
            close()
            this.$message.success('修改成功')
            this.handleFetch()
          } else {
            cancel()
          }
        })
      }).catch((e) => {
        cancel()
        this.$message.error('红包金额区间不合法')
      })
    },

    handleCloseActivity({ id }) {
      this.$confirm(this.$t('确定关闭活动?'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        redpacketClose({ id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
