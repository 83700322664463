import { rateTypes } from '@/libs/options'
import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('订单号'),
      key: 'orderNo',
      align: 'center'
    },
    {
      title: this.$t('申请时间'),
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span class='primary-type cursor-pointer' onClick={this._onDetails.bind(this, row)}>{ row.memberName }</span>)
        }
      }
    },
    {
      title: this.$t('所属代理'),
      key: 'proxyCode',
      align: 'center'
    },
    {
      title: this.$t('VIP等级'),
      key: 'vipLevel',
      align: 'center',
      width: 80,
      component: {
        render: (h, row) => {
          return (<span>VIP{row.vipLevel}</span>)
        }
      }
    },
    {
      title: this.$t('总充值金额'),
      key: 'sumRecharge',
      align: 'center'
    },
    {
      title: this.$t('总提现金额'),
      key: 'sumWithdraw',
      align: 'center'
    },
    {
      title: this.$t('会员备注'),
      key: 'remark',
      align: 'center'
    },
    {
      title: this.$t('收款银行'),
      key: 'receivingBank',
      align: 'center'
    },
    {
      title: this.$t('货币汇率'),
      align: 'center',
      component: {
        render: (h, row) => {
          const rateType = rateTypes.find(e => e.key === row.rateType)
          if (rateType) {
            return (
              <div>
                <p>{ rateType.label }</p>
                <span>{ row.paymentRate }</span>
              </div>
            )
          }
          return (
            <span>{ row.paymentRate }</span>
          )
        }
      }
    },
    {
      title: this.$t('平台币金额'),
      key: 'amount',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span class={this.$parseColor(row.amount)}>{row.amount}</span>
          )
        }
      }
    },
    {
      title: this.$t('货币金额'),
      key: 'paymentAmount',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span class={this.$parseColor(row.paymentAmount)}>{row.paymentAmount}</span>
          )
        }
      }
    },
    // {
    //   title: '申请金额',
    //   key: 'amount',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (
    //         <p>
    //           <span class={this.$parseColor(row.amount)}>{row.amount}</span>
    //           <el-button type='text' size='mini' onClick={(e) => this.clip(row.amount, e)}>复制</el-button>
    //         </p>)
    //     }
    //   }
    // },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          if (row.status === 1) {
            return (<el-button type='success' onClick={ () => { this._handleCollect(row) }}>{this.$t('拾取')}</el-button>)
          } else if (row.status === 2) {
            return (<span>{ row.operatorName === this.name ? this.$t('已拾取') : this.$t('他人拾取') }</span>)
          }
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
