<template>
  <el-dialog :title="title" width="610px" top="5vh" :visible.sync="visible" class="table-dialog" @closed="handleClosed">

    <table v-if="row">
      <colgroup>
        <col width="165">
        <col>
      </colgroup>
      <tbody>
        <tr>
          <td class="is-right">{{ $t('彩种') }}：</td>
          <td>{{ row.lotteryName }}</td>
        </tr>
        <tr>
          <td class="is-right">>{{ $t('期号') }}：</td>
          <td>{{ row.issue }}</td>
        </tr>
        <tr>
          <td class="is-right">>{{ $t('开奖号码') }}：</td>
          <td>
            <LotteryNumber :result="row.lotteryNo" :series-tag="row.seriesTag" :lottery-id="row.lotteryId" />
          </td>
        </tr>
        <tr>
          <td class="is-right">>{{ $t('玩法') }}：</td>
          <td>{{ row.playName }}</td>
        </tr>
        <tr>
          <td class="is-right">>{{ $t('玩法分类') }}：</td>
          <td>{{ row.playClassName }}</td>
        </tr>
        <tr>
          <td class="is-right">>{{ $t('内容') }}：</td>
          <td>
            <div style="line-height: 24px">{{ row.numbers.replace() }}</div>
          </td>
        </tr>
        <tr>
          <td class="is-right">>{{ $t('投注金额') }}：</td>
          <td>{{ row.amount }}</td>
        </tr>
        <tr>
          <td class="is-right">>{{ $t('状态') }}：</td>
          <td><el-link :underline="false" :type="getStatus(row.status).type">{{ getStatus(row.status).label }}</el-link></td>
        </tr>
        <tr>
          <td class="is-right">>{{ $t('中奖金额') }}：</td>
          <td>{{ row.winAmount }}</td>
        </tr>
      </tbody>
    </table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">>{{ $t('关闭') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import LotteryNumber from '@/components/LotteryNumber'
export default {
  components: { LotteryNumber },
  mixins: [VDialogMixin],
  data() {
    return {
      row: null
    }
  },
  computed: {
    title() {
      return this.row && `${this.$t('订单')}${this.row.orderNo}${this.$t('开奖查询')}` || ''
    }
  },
  methods: {
    open(row) {
      this.row = row
      this.visible = true
    },
    handleClosed() {
      this.row = null
    },
    getStatus(status) {
      return this.$parent.bettingStatus.find(o => o.key === status)
    }
  }
}
</script>

