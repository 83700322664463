var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.permission.Add
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: {
                    click: function ($event) {
                      return _vm.onCreate()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("添加代理推广")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          "original-columns": _vm.defaultColumns,
          options: _vm.options,
        },
        on: {
          refresh: _vm.handleFetch,
          "column-change": _vm.handleColumnChange,
        },
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("edit-dialog", {
        ref: "EditDialogControl",
        on: { create: _vm.handleCreate, update: _vm.hanldeUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }