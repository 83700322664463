var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加卡券",
        visible: _vm.visible,
        width: "1100px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          key: _vm.ruleForm.couponType,
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "190px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("优惠类型"), prop: "couponType" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "350px" },
                  attrs: { placeholder: _vm.$t("优惠类型") },
                  model: {
                    value: _vm.ruleForm.couponType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "couponType", $$v)
                    },
                    expression: "ruleForm.couponType",
                  },
                },
                _vm._l(_vm.couponTypes, function (val, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: val, value: +key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("卡券名称"), prop: "couponTitle" } },
            [
              _vm.ruleForm.couponTitleList.length > 0
                ? _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        title: "卡券名称多语言配置",
                        trigger: "click",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          slot: "reference",
                          value: _vm.ruleForm.couponTitleList[0].couponTitle,
                          readonly: "",
                        },
                        slot: "reference",
                      }),
                      _c(
                        "div",
                        { staticClass: "lang-pop" },
                        _vm._l(_vm.ruleForm.couponTitleList, function (item) {
                          return _c("el-input", {
                            key: item.lang,
                            staticStyle: { width: "350px" },
                            attrs: { placeholder: item.name },
                            model: {
                              value: item.couponTitle,
                              callback: function ($$v) {
                                _vm.$set(item, "couponTitle", $$v)
                              },
                              expression: "item.couponTitle",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.ruleForm.couponType === 3
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("折扣方式") } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        directives: [
                          {
                            name: "removeAriaHidden",
                            rawName: "v-removeAriaHidden",
                          },
                        ],
                        model: {
                          value: _vm.ruleForm.couponDiscountType,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "couponDiscountType", $$v)
                          },
                          expression: "ruleForm.couponDiscountType",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v(_vm._s(_vm.$t("指定金额"))),
                        ]),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("指定折扣"))),
                        ]),
                      ],
                      1
                    ),
                    _vm.ruleForm.couponDiscountType === 0
                      ? _c(
                          "el-form-item",
                          { attrs: { prop: "couponAmount" } },
                          [
                            _c("el-input", {
                              staticClass: "amount",
                              attrs: {
                                placeholder: _vm.$t("设置优惠金额"),
                                maxlength: "11",
                              },
                              model: {
                                value: _vm.ruleForm.couponAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "couponAmount", $$v)
                                },
                                expression: "ruleForm.couponAmount",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "el-form-item",
                          { attrs: { prop: "couponDiscountRate" } },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "amount",
                                attrs: { placeholder: _vm.$t("设置折扣") },
                                model: {
                                  value: _vm.ruleForm.couponDiscountRate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "couponDiscountRate",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.couponDiscountRate",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("折"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("使用标准"),
                      prop: "couponStandard",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "350px" },
                      attrs: {
                        placeholder: _vm.$t("最低投注金额"),
                        maxlength: "8",
                      },
                      model: {
                        value: _vm.ruleForm.couponStandard,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "couponStandard", $$v)
                        },
                        expression: "ruleForm.couponStandard",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("生效时间") } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.couponValidType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "couponValidType", $$v)
                    },
                    expression: "ruleForm.couponValidType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v("领取后生效"),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("固定有效期"),
                  ]),
                ],
                1
              ),
              _vm.ruleForm.couponValidType === 1
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "couponStartTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00", "23:59:59"],
                        },
                        model: {
                          value: _vm.ruleForm.couponTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "couponTime", $$v)
                          },
                          expression: "ruleForm.couponTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.ruleForm.couponValidType === 0
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("卡券有效期(时)"),
                    prop: "couponExpired",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "230px" },
                    attrs: { maxlength: "8" },
                    model: {
                      value: _vm.ruleForm.couponExpired,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "couponExpired", $$v)
                      },
                      expression: "ruleForm.couponExpired",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("卡券库存"), prop: "couponStockType" } },
            [
              _c(
                "el-radio-group",
                {
                  directives: [
                    { name: "removeAriaHidden", rawName: "v-removeAriaHidden" },
                  ],
                  model: {
                    value: _vm.ruleForm.couponStockType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "couponStockType", $$v)
                    },
                    expression: "ruleForm.couponStockType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("无限")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("设置库存")]),
                ],
                1
              ),
              _vm.ruleForm.couponStockType === 0
                ? _c("el-input", {
                    staticStyle: { width: "350px", "margin-left": "20px" },
                    attrs: { maxlength: "8", placeholder: "库存数量" },
                    model: {
                      value: _vm.ruleForm.couponStock,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "couponStock", $$v)
                      },
                      expression: "ruleForm.couponStock",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.ruleForm.couponType === 4
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: _vm.$t("卡券面值"), prop: "couponAmount" },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "350px" },
                      attrs: { maxlength: "11" },
                      model: {
                        value: _vm.ruleForm.couponAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "couponAmount", $$v)
                        },
                        expression: "ruleForm.couponAmount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("提现打码量"),
                      prop: "couponStandardWithdrawRolling",
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "350px" },
                        attrs: { type: "text", maxlength: "3" },
                        model: {
                          value: _vm.ruleForm.couponStandardWithdrawRolling,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "couponStandardWithdrawRolling",
                              $$v
                            )
                          },
                          expression: "ruleForm.couponStandardWithdrawRolling",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("倍")])],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("打码量稽查（使用标准）"),
                      prop: "couponStandardRolling",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "350px" },
                      attrs: {
                        placeholder: _vm.$t("打码量稽查"),
                        maxlength: "8",
                      },
                      model: {
                        value: _vm.ruleForm.couponStandardRolling,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "couponStandardRolling", $$v)
                        },
                        expression: "ruleForm.couponStandardRolling",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("指定打码平台") } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        directives: [
                          {
                            name: "removeAriaHidden",
                            rawName: "v-removeAriaHidden",
                          },
                        ],
                        model: {
                          value: _vm.codingPlatform,
                          callback: function ($$v) {
                            _vm.codingPlatform = $$v
                          },
                          expression: "codingPlatform",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: -1 } }, [
                          _vm._v("无限制"),
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("仅限以下勾选平台"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm.ruleForm.couponType === 5
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: _vm.$t("卡券面值"), prop: "couponAmount" },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "350px" },
                      attrs: { maxlength: "11" },
                      model: {
                        value: _vm.ruleForm.couponAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "couponAmount", $$v)
                        },
                        expression: "ruleForm.couponAmount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("提现打码量"),
                      prop: "couponStandardWithdrawRolling",
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "350px" },
                        attrs: { type: "text", maxlength: "3" },
                        model: {
                          value: _vm.ruleForm.couponStandardWithdrawRolling,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "couponStandardWithdrawRolling",
                              $$v
                            )
                          },
                          expression: "ruleForm.couponStandardWithdrawRolling",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("倍")])],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("使用标准：充值金额"),
                      prop: "couponStandardDepositAmount",
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "350px" },
                        attrs: {
                          placeholder: _vm.$t("充值金额"),
                          maxlength: "11",
                        },
                        model: {
                          value: _vm.ruleForm.couponStandardDepositAmount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "couponStandardDepositAmount",
                              $$v
                            )
                          },
                          expression: "ruleForm.couponStandardDepositAmount",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("以上")])],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("打码量稽查"),
                      prop: "couponStandardRolling",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "350px" },
                      attrs: {
                        placeholder: _vm.$t("打码量稽查"),
                        maxlength: "8",
                      },
                      model: {
                        value: _vm.ruleForm.couponStandardRolling,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "couponStandardRolling", $$v)
                        },
                        expression: "ruleForm.couponStandardRolling",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("指定打码平台") } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        directives: [
                          {
                            name: "removeAriaHidden",
                            rawName: "v-removeAriaHidden",
                          },
                        ],
                        model: {
                          value: _vm.codingPlatform,
                          callback: function ($$v) {
                            _vm.codingPlatform = $$v
                          },
                          expression: "codingPlatform",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: -1 } }, [
                          _vm._v("无限制"),
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("仅限以下勾选平台"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm.ruleForm.couponType === 6
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("优惠面值：订单中奖加奖"),
                      prop: "discountAmount",
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "350px" },
                        attrs: { maxlength: "11" },
                        model: {
                          value: _vm.ruleForm.discountAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "discountAmount", $$v)
                          },
                          expression: "ruleForm.discountAmount",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("倍")])],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("使用标准") } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "betAmount",
                        attrs: {
                          label: _vm.$t("最小下注"),
                          prop: "couponStandardMin",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "130px" },
                          attrs: { maxlength: "8" },
                          model: {
                            value: _vm.ruleForm.couponStandardMin,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "couponStandardMin", $$v)
                            },
                            expression: "ruleForm.couponStandardMin",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "betAmount",
                        attrs: {
                          label: _vm.$t("最大下注"),
                          prop: "couponStandardMax",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "130px" },
                          attrs: { maxlength: "8" },
                          model: {
                            value: _vm.ruleForm.couponStandardMax,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "couponStandardMax", $$v)
                            },
                            expression: "ruleForm.couponStandardMax",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.ruleForm.couponType === 6
            ? _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("使用方式"), prop: "couponScopeMode" },
                },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.ruleForm.couponScopeMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "couponScopeMode", $$v)
                        },
                        expression: "ruleForm.couponScopeMode",
                      },
                    },
                    _vm._l(_vm.couponScopeModes, function (val, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: val, value: +key },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.ruleForm.couponType === 3
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("使用方式") } },
                [
                  _c(
                    "el-radio-group",
                    {
                      directives: [
                        {
                          name: "removeAriaHidden",
                          rawName: "v-removeAriaHidden",
                        },
                      ],
                      model: {
                        value: _vm.ruleForm.couponUsageMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "couponUsageMode", $$v)
                        },
                        expression: "ruleForm.couponUsageMode",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("游戏下注优惠"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-left": "20px" },
                      model: {
                        value: _vm.ruleForm.couponScopeMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "couponScopeMode", $$v)
                        },
                        expression: "ruleForm.couponScopeMode",
                      },
                    },
                    _vm._l(_vm.couponScopeModes, function (val, key) {
                      return _c("el-option", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: +key !== 2,
                            expression: "+key !== 2",
                          },
                        ],
                        key: key,
                        attrs: { label: val, value: +key },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.ruleForm.couponType === 3 || _vm.ruleForm.couponType === 6
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("适用范围"), prop: "couponScope" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      directives: [
                        {
                          name: "removeAriaHidden",
                          rawName: "v-removeAriaHidden",
                        },
                      ],
                      model: {
                        value: _vm.couponScope,
                        callback: function ($$v) {
                          _vm.couponScope = $$v
                        },
                        expression: "couponScope",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: -1 } }, [
                        _vm._v("无限制"),
                      ]),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("适用范围"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    ([4, 5].includes(_vm.ruleForm.couponType) &&
                      _vm.codingPlatform === 0) ||
                    ([3, 6].includes(_vm.ruleForm.couponType) &&
                      _vm.couponScope === 0),
                  expression:
                    "([4,5].includes(ruleForm.couponType) && codingPlatform === 0) || ([3,6].includes(ruleForm.couponType) && couponScope === 0)",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadGameListLoading,
                  expression: "loadGameListLoading",
                },
              ],
            },
            [
              _c(
                "el-tabs",
                { attrs: { type: "border-card" } },
                _vm._l(_vm.filterGameList, function (item, index) {
                  return _c(
                    "el-tab-pane",
                    { key: index, attrs: { label: item.label } },
                    _vm._l(item.children, function (item1, index1) {
                      return _c(
                        "el-collapse",
                        {
                          key: index1,
                          model: {
                            value: item.collapse,
                            callback: function ($$v) {
                              _vm.$set(item, "collapse", $$v)
                            },
                            expression: "item.collapse",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              attrs: { name: item1.value },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              indeterminate:
                                                item1.indeterminate,
                                              autofocus: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleCheckedChange(
                                                  item1
                                                )
                                              },
                                            },
                                            model: {
                                              value: item1.checked,
                                              callback: function ($$v) {
                                                _vm.$set(item1, "checked", $$v)
                                              },
                                              expression: "item1.checked",
                                            },
                                          },
                                          [_vm._v(_vm._s(item1.label))]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              item1.children &&
                              item1.children.length > 0 &&
                              item.value === "1000005"
                                ? _vm._l(
                                    item1.children,
                                    function (item2, index2) {
                                      return _c(
                                        "el-collapse",
                                        {
                                          key: index2,
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                          model: {
                                            value: item1.collapse,
                                            callback: function ($$v) {
                                              _vm.$set(item1, "collapse", $$v)
                                            },
                                            expression: "item1.collapse",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-collapse-item",
                                            {
                                              attrs: { name: item2.value },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "title",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "el-checkbox",
                                                          {
                                                            attrs: {
                                                              indeterminate:
                                                                item2.indeterminate,
                                                              autofocus: "",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleCheckedChange(
                                                                  item2,
                                                                  item1
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item2.checked,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item2,
                                                                    "checked",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item2.checked",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item2.label
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              item2.children &&
                                              item2.children.length > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "20px",
                                                      },
                                                    },
                                                    _vm._l(
                                                      item2.children,
                                                      function (item3, index3) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: index3,
                                                            attrs: {
                                                              autofocus: "",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleCheckedChange(
                                                                  item3,
                                                                  item2,
                                                                  item1
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item3.checked,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item3,
                                                                    "checked",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item3.checked",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item3.label
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _c(
                                    "div",
                                    { staticStyle: { "margin-left": "20px" } },
                                    _vm._l(
                                      item1.children,
                                      function (item2, index2) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: index2,
                                            attrs: { autofocus: "" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleCheckedChange(
                                                  item2,
                                                  item1
                                                )
                                              },
                                            },
                                            model: {
                                              value: item2.checked,
                                              callback: function ($$v) {
                                                _vm.$set(item2, "checked", $$v)
                                              },
                                              expression: "item2.checked",
                                            },
                                          },
                                          [_vm._v(_vm._s(item2.label))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.couponType === 5
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("适用范围"), prop: "couponScope" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      directives: [
                        {
                          name: "removeAriaHidden",
                          rawName: "v-removeAriaHidden",
                        },
                      ],
                      model: {
                        value: _vm.couponScope,
                        callback: function ($$v) {
                          _vm.couponScope = $$v
                        },
                        expression: "couponScope",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: -1 } }, [
                        _vm._v("无限制"),
                      ]),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("适用范围"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.couponScope === 0 && _vm.ruleForm.couponType === 5,
                  expression: "couponScope === 0 && ruleForm.couponType === 5",
                },
              ],
            },
            [
              _c("el-cascader", {
                staticStyle: { width: "350px" },
                attrs: {
                  options: _vm.payList,
                  props: { multiple: true },
                  placeholder: "输入搜索",
                  "collapse-tags": "",
                  filterable: "",
                  clearable: "",
                },
                model: {
                  value: _vm.couponScopeList,
                  callback: function ($$v) {
                    _vm.couponScopeList = $$v
                  },
                  expression: "couponScopeList",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("备注"), prop: "couponRemark" } },
            [
              _c("el-input", {
                staticStyle: { width: "350px" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 4, maxRows: 6 },
                  maxlength: "500",
                },
                model: {
                  value: _vm.ruleForm.couponRemark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "couponRemark", $$v)
                  },
                  expression: "ruleForm.couponRemark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("上架状态"), prop: "couponStatus" } },
            [
              _c(
                "d-switch",
                {
                  attrs: { activeValue: 1, inactiveValue: 0 },
                  model: {
                    value: _vm.ruleForm.couponStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "couponStatus", $$v)
                    },
                    expression: "ruleForm.couponStatus",
                  },
                },
                [
                  _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                    _vm._v("上架"),
                  ]),
                  _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                    _vm._v("下架"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("关闭"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v(_vm._s(_vm.$t("保存")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }