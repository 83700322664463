<template>
  <div>
    <div class="filter-container">
      <div class="filter-container-item" align="right">
        <el-button v-if="permission.Delete" type="danger" icon="el-icon-delete" @click="_handleDeleteMaxLevel">
          {{ $t('Member_Expert.LevelTab.删除最大等级') }}
        </el-button>

        <el-button v-if="permission.Add" type="success" icon="el-icon-plus" style="float: right" @click="onCreate">
          {{ $t('新增text',{text:$t('Member_Expert.LevelTab.专家等级')}) }}
        </el-button>

      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogContorl" @create="_handleCreate" @update="_handleUpdate" />

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getExpertPositionList, saveExpertPosition, deleteMaxLevelPosition } from '@/api/expert'
import ActionDialog from './components/ActionDialog'
import { mapGetters } from 'vuex'

export default {
  name: 'LevelTab',
  componentName: 'LevelTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(getExpertPositionList)],
  data() {
    return {
      filterColumn: false,
      columns: GetColumns.call(this),
      search: {}
    }
  },
  computed: {
    ...mapGetters(['operatorType', 'tenantCode', 'operatorId'])
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogContorl.open(undefined, this.tenantCode)
    },

    _handleCreate({ field, cancel, close }) {
      saveExpertPosition({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      saveExpertPosition({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    _handleDeleteMaxLevel() {
      let params = {}
      params.tenantCode = this.tenantCode
      this.$confirm(this.$t('Member_Expert.LevelTab.删除等级后不可恢复'), this.$t('确认删除', { text: '' }), { type: 'warning', center: true }).then(() => {
        deleteMaxLevelPosition(params).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleRefresh()
          }
        })
      }).catch(() => {})
    },

    implementationGetParams() {
      return { ...this.search, tenantCode: this.tenantCode, operatorId: this.operatorId }
    }
  }
}
</script>
