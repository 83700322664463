import moment from "moment";

function payType(type) {
  if (type == 0) {
    return "USDT";
  } else if (type == 1) {
    return "银行卡汇款";
  } else if (type == 2) {
    return "第三方充值";
  } else {
    return "微信充值";
  }
}
const mapType = {
  1: "早盘",
  2: "滚球",
  3: "冠军",
};

const withDrawalType = ["划转提现", "微信提现", "支付宝提现", "银行卡提现"];

function getResult(msgType, extras, dealItem) {
  switch (msgType) {
    case 207:
      return [
        {
          name: "会员账号",
          value: extras.memberName,
          needCopy: extras.memberName,
        },
        { name: "订单号", value: extras.orderNo, needCopy: extras.orderNo },
        { name: "投注金额", value: extras.amount },
        { name: extras.lotteryName, value: `第${extras.issue}期` },
        { name: extras.playClassName + "-" + extras.betsContent, value: "" },
        {
          name: "投注时间",
          value: this.$parseTime(new Date(extras.betTime * 1)),
        },
      ];
    case 208:
      switch (extras.orderType) {
        case 1502: // 9B体育
          let jsonExtras =
            typeof extras === "string" ? JSON.parse(extras) : extras;
          let showData =
            jsonExtras.betRecordList === "string"
              ? JSON.parse(jsonExtras.betRecordList)[0]
              : jsonExtras.detailList
              ? jsonExtras.detailList[0]
              : JSON.parse(jsonExtras.betRecordList)[0];
          let mapType = {
            1: "早盘",
            2: "滚球",
            3: "冠军",
          };
          return [
            {
              name: "会员账号",
              value: extras.memberName,
              needCopy: extras.memberName,
            },
            { name: "订单号", value: extras.orderNo, needCopy: extras.orderNo },
            { name: "投注金额", value: extras.orderAmount },
            {
              name: showData.matchInfo,
              value:
                showData.sportName +
                " " +
                mapType[showData.matchType] +
                " " +
                showData.matchName +
                " " +
                "欧洲盘",
            },
            {
              name: "投注时间",
              value: moment(Number(extras.orderTime * 1)).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            },
          ];
          break;
        case 2000: // 沙巴体育
          let jsonExtrasSB =
            typeof extras === "string" ? JSON.parse(extras) : extras;
          const getOddsType = (oddsType) => {
            if (oddsType == 1) {
              return ("马来盘");
            }
            if (oddsType == 2) {
              return ("香港盘");
            }
            if (oddsType == 3) {
              return ("欧洲盘");
            }
            if (oddsType == 4) {
              return ("印度尼西亚盘");
            }
            if (oddsType == 5) {
              return ("美国盘");
            }
          };
          return [
            {
              name: "会员账号",
              value: extras.memberName,
              needCopy: extras.memberName,
            },
            { name: "订单号", value: extras.transId, needCopy: extras.transId },
            { name: "投注金额", value: Number(extras.stake).toFixed(2) },
            {
              name:
                typeof extras.hometeamname === "string"
                  ? JSON.parse(extras.hometeamname)[1].name +
                    "vs" +
                    JSON.parse(extras.awayteamname)[1].name
                  : extras.hometeamname2 + "vs" + extras.awayteamname2,
              value:
                JSON.parse(extras.sportname)[1].name +
                " " +
                "滚球" +
                " " +
                JSON.parse(jsonExtrasSB.leaguename)[1].name +
                " " +
                getOddsType(jsonExtrasSB.oddsType),
            },
            {
              name: "投注时间",
              value: moment(extras.transactionTime * 1).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            },
          ];
        case 2200: // IM体育
          let showDataIm =
            typeof dealItem.detailItems === "string"
              ? JSON.parse(dealItem.detailItems)[0]
              : dealItem.detailItems[0];
          let gameType = {
            HK: "香港盘",
            EURO: "欧洲盘",
            MALAY: "马来盘",
            INDO: "印度盘",
          };
          return [
            {
              name: "会员账号",
              value: extras.memberName,
              needCopy: extras.memberName,
            },
            { name: "订单号", value: extras.betId, needCopy: extras.betId },
            { name: "投注金额", value: Number(extras.stakeAmount).toFixed(2) },
            {
              name:
                typeof dealItem.detailItems === "string"
                  ? JSON.parse(dealItem.detailItems)[0].HomeTeamName +
                    " vs " +
                    JSON.parse(dealItem.detailItems)[0].AwayTeamName
                  : dealItem.detailItems[0].HomeTeamName +
                      "vs" +
                      typeof dealItem.detailItems ===
                    "string"
                  ? JSON.parse(dealItem.detailItems)[0].AwayTeamName
                  : dealItem.detailItems[0].AwayTeamName,
              value:
                showDataIm.SportsName +
                " " +
                showDataIm.CompetitionName +
                " " +
                gameType[dealItem.oddsType],
            },
            {
              name: "投注时间",
              value: moment(extras.lastUpdatedDate * 1).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            },
          ];
        case 99: // 竞彩足球
          return [
            {
              name: "会员账号",
              value: extras.memberName,
              needCopy: extras.memberName,
            },
            { name: "订单号", value: extras.orderNo, needCopy: extras.orderNo },
            { name: "投注金额", value: extras.betAmount },
            {
              name: "竞彩足球",
              value: extras.bunchTypeName,
            },
            {
              name: "投注时间",
              value: extras.createTime,
            },
          ];
        case 2700: // FB体育
          let typeMap = {
            1: "足球",
            3: "篮球",
            5: "网球",
          };
          let game = {
            1: "马来盘",
            2: "香港盘",
            3: "欧洲盘",
            4: "印尼盘",
            5: "美国盘",
          };
          let jsonExtrasFB =
            typeof dealItem.betList === "string"
              ? JSON.parse(dealItem.betList)[0]
              : dealItem.betList[0];

          return [
            {
              name: "会员账号",
              value: extras.memberName,
              needCopy: extras.memberName,
            },
            { name: "订单号", value: extras.orderId, needCopy: extras.orderId },
            { name: "投注金额", value: Number(extras.stakeAmount).toFixed(2) },
            {
              name: jsonExtrasFB.matchName,
              value:
                typeMap[jsonExtrasFB.sportId] +
                " " +
                "滚球" +
                " " +
                jsonExtrasFB.tournamentName +
                " " +
                game[jsonExtrasFB.oddsFormat],
            },
            {
              name: "投注时间",
              value: moment(extras.createTime * 1).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            },
          ];
        default:
          return [
            {
              name: "会员账号",
              value: extras.memberName,
              needCopy: extras.memberName,
            },
            { name: "订单号", value: extras.orderNo, needCopy: extras.orderNo },
            { name: "投注金额", value: extras.betAmount },
            {
              name: "竞彩足球",
              value: extras.bunchTypeName,
            },
            {
              name: "投注时间",
              value: extras.createTime,
            },
          ];
          break;
      }

    case 209:
      return [
        {
          name: "会员账号",
          value: extras.memberName,
          needCopy: extras.memberName,
        },
        { name: "订单号", value: extras.orderNo, needCopy: extras.orderNo },
        { name: "投注金额", value: extras.bet },
        { name: extras.gameName, value: "" },
        {
          name: "投注时间",
          value: this.$parseTime(new Date(extras.etime * 1)),
        },
      ];
    case 210:
      return [
        {
          name: "会员账号",
          value: extras.memberName,
          needCopy: extras.memberName,
        },
        { name: "订单号", value: extras.orderNo, needCopy: extras.orderNo },
        { name: "充值方式", value: payType(extras.type) },
        { name: "充值金额", value: extras.amount },
        { name: "赠送金额", value: extras.giveAmount },
        {
          name: "充值时间",
          value: this.$parseTime(new Date(extras.updateTime * 1)),
        },
      ];
    case 211:
      return [
        {
          name: "会员账号",
          value: extras.memberName,
          needCopy: extras.memberName,
        },
        { name: "订单号", value: extras.orderNo, needCopy: extras.orderNo },
        {
          name: "提现方式",
          value: extras.receivingCardNo,
        },
        { name: "申请金额", value: extras.paymentAmount },
        {
          name: "提现时间",
          value: this.$parseTime(new Date(extras.updateTime * 1)),
        },
      ];
    case 212:
      return [];
    default:
      break;
  }
}

export default getResult;
