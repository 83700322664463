<template>
  <el-dialog
    :title="title"
    :visible="dialogFormVisible"
    width="1000px"
    @close="_close"
  >
    <div class="list_">
      <span v-for="item of list" :key="item.id" class="l_">{{ item.cardBin }}</span>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      title: '',
      list: []
    }
  },
  methods: {
    initlist(item) {
      const { bankNumbersList, name } = item
      this.title = name + ' 所有卡段展示'
      this.dialogFormVisible = true
      this.list = [...bankNumbersList]
    },
    _close() {
      this.dialogFormVisible = false
      this.title = ''
      this.list = []
    }
  }
}
</script>

<style lang="scss" scoped>
  .list_{ overflow: hidden; width: 100%;
    .l_{ display: inline-block; width: 12%; text-align: center; line-height: 26px; border-bottom: 1px solid #dfe6ec;}
  }
</style>
