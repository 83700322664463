import request from '@/plugins/axios'

// export function backManagerUserAccess({ user_id, tenant_code, time }) {
//   return request({
//     url: '/app/v1/backManagerUserAccess',
//     method: 'post',
//     data: {
//       user_id,
//       tenant_code
//     },
//     headers: {
//       sign: GenerateSign(tenant_code, time),
//       time
//     }
//   })
// }

export function getImToken(params) {
  return request({
    url: '/backmanager/getImToken',
    method: 'get',
    params
  })
}

export function getUserAuth(params) {
  return request({
    url: '/app/v1/room/user/acl',
    method: 'get',
    params
  })
}

export function setUserAuth(data) {
  return request({
    url: '/app/v1/room/user/acl',
    method: 'post',
    data
  })
}

export function getVirtualUserCount(params) {
  return request({
    url: '/app/v1/room/fakeUserCount',
    method: 'get',
    params
  })
}

export function setVirtualUserCount(data) {
  return request({
    url: '/app/v1/room/fakeUserCount',
    method: 'post',
    data
  })
}

export function getLiveRoomList(data) {
  return request({
    url: '/live/liveRoom/liveRoomList',
    method: 'post',
    data
  })
}

export function getReportManList(data) {
  return request({
    url: '/ai/assistant/pageList',
    method: 'post',
    data
  })
}

export function getDetailRoom(params) {
  return request({
    url: '/ai/assistant/detailRoom',
    method: 'get',
    params
  })
}

export function AIsaveChatRoom(data) {
  return request({
    url: '/ai/assistant/saveChatRoom',
    method: 'post',
    data
  })
}

export function getConfigAdd(data) {
  return request({
    url: '/ai/chat/robot/config/add',
    method: 'post',
    data
  })
}

export function getConfigUpdate(data) {
  return request({
    url: '/ai/chat/robot/config/update',
    method: 'post',
    data
  })
}


export function addRoom(data) {
  return request({
    url: '/live/liveRoom/addRoom',
    method: 'post',
    data
  })
}

export function editRoom(data) {
  return request({
    url: '/live/liveRoom/editRoom',
    method: 'post',
    data
  })
}

export function deleteRoom(data) {
  return request({
    url: '/live/liveRoom/deleteRoom',
    method: 'post',
    data
  })
}

export function getSensitiveInfo() {
  return request({
    url: '/live/im/sensitiveInfo',
    method: 'get'
  })
}

export function addSensitive(data) {
  return request({
    url: '/live/im/addSensitive',
    method: 'post',
    data
  })
}

export function stopRoomWords(data) {
  return request({
    url: '/live/liveManagement/roomWords',
    method: 'post',
    data
  })
}

export function imOperator(params) {
  return request({
    url: '/live/im/operatorCmd',
    method: 'get',
    params
  })
}
export function cmdDetails(data) {
  return request({
    url: '/live/im/cmd/details',
    method: 'post',
    data
  })
}
export function onlineNum(data) {
  return request({
    url: '/live/im/onlineNum',
    method: 'post',
    data
  })
}

export function imCmdMemberList(data) {
  return request({
    url: '/live/im/cmd/memberList',
    method: 'post',
    data
  })
}


export function getMessagePageList(data) {
  return request({
    url: '/chat/schedule/message/pageList',
    method: 'post',
    data
  })
}

export function delRobotPageList(data) {
  return request({
    url: '/chat/schedule/message/del',
    method: 'post',
    data
  })
}

export function addRobotPageList(data) {
  return request({
    url: '/chat/schedule/message/add',
    method: 'post',
    data
  })
}

export function updateRobotPageList(data) {
  return request({
    url: '/chat/schedule/message/update',
    method: 'post',
    data
  })
}


export function getRobotPageList(data) {
  return request({
    url: '/ai/chat/robot/config/pageList',
    method: 'post',
    data
  })
}

// 获取客服分页列表信息 
export function getCustomerPageList(data) {
  return request({
    url: '/customer/im/pageList',
    method: 'post',
    data
  })
}



export function delRobot(data) {
  return request({
    url: '/ai/chat/robot/config/del',
    method: 'post',
    data
  })
}

export function imOperatorCmd(params) {
  return request({
    url: '/live/im/operatorCmd',
    method: 'get',
    params
  })
}

// 聊天室公告列表
export function roomAnnouncementQueryList(data) {
  return request({
    url: '/room/announcement/queryList',
    method: 'post',
    data
  })
}

// 新增聊天室公告
export function liveLiveAnnouncementAdd(data) {
  return request({
    url: '/live/liveAnnouncement/add',
    method: 'post',
    data
  })
}

// 编辑聊天室公告
export function liveLiveAnnouncementUpdate(data) {
  return request({
    url: '/live/liveAnnouncement/update',
    method: 'post',
    data
  })
}

// 聊天室公告启停
export function liveliveAnnouncementRoomStatus(data) {
  return request({
    url: '/live/liveAnnouncement/roomStatus',
    method: 'post',
    data
  })
}

// 聊天室公告删除
export function liveLiveAnnouncementRoomDelete(data) {
  return request({
    url: '/live/liveAnnouncement/roomDelete',
    method: 'post',
    data
  })
}
