<template>
  <el-dialog :title="title" width="610px" top="5vh" :visible.sync="visible" class="table-dialog" @closed="handleClosed">
    <table>
      <colgroup>
        <col width="165">
        <col>
      </colgroup>
      <tbody>
        <template v-if="emitName === 'settle'">
          <tr>
            <td class="is-right">{{ $t('订单号') }}：</td>
            <td>{{ ruleForm.orderNo }}</td>
          </tr>
          <tr>
            <td class="is-right">{{ $t('用户') }}：</td>
            <td>{{ ruleForm.memberName }}</td>
          </tr>
          <tr>
            <td class="is-right">{{ $t('昵称') }}：</td>
            <td>{{ ruleForm.memberNickName }}</td>
          </tr>
        </template>
        <tr>
          <td class="is-right">{{ $t('彩种') }}：</td>
          <td>{{ ruleForm.lotteryName }}</td>
        </tr>
        <tr>
          <td class="is-right">{{ $t('期号') }}：</td>
          <td>{{ ruleForm.issue }}</td>
        </tr>
        <template v-if="emitName === 'settle'">
          <tr>
            <td class="is-right">{{ $t('玩法') }}：</td>
            <td>{{ ruleForm.playName }}</td>
          </tr>
          <tr>
            <td class="is-right">{{ $t('投注额度') }}：</td>
            <td>{{ ruleForm.amount }}</td>
          </tr>
          <tr>
            <td class="is-right">{{ $t('赔率') }}：</td>
            <td>{{ ruleForm.odds }}</td>
          </tr>
        </template>
        <tr>
          <td class="is-right">{{ $t('开奖号码') }}：</td>
          <td>
            <el-input v-model="ruleForm.lotteryNo">
              <el-button slot="append" @click.stop="handleCheck">{{ $t('校验') }}</el-button>
            </el-input>
          </td>
        </tr>
        <template v-if="validateNumber && validateRulst && emitName === 'settle'">
          <tr>
            <td class="is-right">{{ $t('中奖金额') }}：</td>
            <td>{{ validateRulst.winAmount }}</td>
          </tr>
        </template>
      </tbody>
    </table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('取消') }}</el-button>
      <el-button type="primary" :disabled="!validateNumber" :loading="submitting" @click="handleSubmit">{{ $t('保存') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import { checkBets } from '@/api/orders'

export default {
  mixins: [VDialogMixin],
  data() {
    return {
      submitting: false,
      ruleForm: {},
      emitName: 'settle',
      validateNumber: false,
      validateRulst: null
    }
  },
  computed: {
    title() {
      return this.emitName === 'settle' ? this.$t('结算') : this.$t('期号结算')
    }
  },
  methods: {
    open({ orderNo, memberName, memberNickName, lotteryName, issue, playName, amount, odds }, emitName) { // 保存
      this.emitName = emitName
      this.ruleForm = { orderNo, memberName, memberNickName, lotteryName, issue, playName, amount, odds }
      this.visible = true
    },
    close() {
      this.submitting = false
      this.visible = false
    },
    handleClosed() {
      this.ruleForm = {}
    },
    handleSubmit() {
      this.submitting = true
      this.$emit(this.emitName, {
        field: this.ruleForm,
        close: this.close,
        cancel: () => { this.submitting = false }
      })
    },
    handleCheck() {
      const { orderNo, lotteryNo, tenantCode } = this.ruleForm
      if (!lotteryNo) {
        this.$message.error(this.$t('请输入开奖号码'))
        return
      }
      checkBets({ orderNo, lotteryNo, tenantCode }).then(([data, err]) => {
        if (!err) {
          this.validateNumber = true
          this.validateRulst = data
        }
      })
    }
  }
}
</script>

