<template>
  <div class="clip-warpper" :class="clip && 'clip-warpper-success' || 'clip-warpper-default'">
    <span class="clip-warpper-text" :class="classes" :style="textStyles" @click.stop="handleClick">{{ text }}</span>
    <button class="clip-warpper-button" @click.stop="handleClip">{{ clip ? '已复制' : '复制' }}</button>
  </div>
</template>

<script>
import Clipboard from 'clipboard'

const CLIP_COLORS = [
  '#FF0000',
  '#E6A23C',
  '#409EFF',
  '#67C23A',
  '#F810BE',
  '#10F8F0'
]

export default {
  name: 'ClipButton',
  props: {
    text: {
      type: [Number, String],
      required: true
    },
    classes: {
      type: String,
      default: ''
    },
    styles: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    clip: false,
    clipCount: 0
  }),
  computed: {
    textStyles() {
      return {
        ...this.styles,
        color: this.clip && CLIP_COLORS[(this.clipCount - 1) % 6]
      }
    }
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt)
    },
    handleClip() {
      const clipboard = new Clipboard(event.target, {
        text: () => this.text
      })
      clipboard.on('success', () => {
        this.clip = true
        this.clipCount += 1
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        clipboard.destroy()
      })
      clipboard.onClick(event)
    }
  }
}
</script>

<style lang="scss" scoped>
$classfix: clip-warpper;
.#{$classfix} {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-button {
    border: 1px solid #999;
    background-color: transparent;
    width: 48px;
    padding: 2px 0;
    text-align: center;
    cursor: pointer;
    color: #3894FF;
    font-size: 12px;
    outline: 0;
  }
  &-default {
    .#{$classfix}-button {
      &:hover {
        color: white;
        border-color:#3894FF;
        background-color: #3894FF;
      }
    }
  }
  &-success {
    .#{$classfix}-text {
      text-decoration: underline;
    }
    .#{$classfix}-button {
      color:#999;
      &:hover {
        color:#3894FF;
        border-color:#3894FF;
      }
    }
  }
}
</style>
