var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-container-item", attrs: { align: "right" } },
          [
            _vm.permission.Delete
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "danger", icon: "el-icon-delete" },
                    on: { click: _vm._handleDeleteMaxLevel },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Member_Expert.LevelTab.删除最大等级")) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.permission.Add
              ? _c(
                  "el-button",
                  {
                    staticStyle: { float: "right" },
                    attrs: { type: "success", icon: "el-icon-plus" },
                    on: { click: _vm.onCreate },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("新增text", {
                            text: _vm.$t("Member_Expert.LevelTab.专家等级"),
                          })
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          options: _vm.options,
          "filter-column": _vm.filterColumn,
        },
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("ActionDialog", {
        ref: "ActionDialogContorl",
        on: { create: _vm._handleCreate, update: _vm._handleUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }