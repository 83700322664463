import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('支付方式名称'),
      key: 'paywayName',
      align: 'center'
    },
    {
      title: this.$t('支付方式标识'),
      key: 'paywayCode',
      align: 'center'
    },
    {
      title: this.$t('付款类型'),
      key: 'type',
      align: 'center',
      component: {
        render: (h, row) => {
          const payType = this.payTypes.find(e => e.key === row.type)
          return (<span>{ payType?.label }</span>)
        }
      }
    },
    {
      title: this.$t('图标'),
      key: 'icon',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image src={ row.icon } fix='cover' style='width: 40px;height: 40px;border-radius: 4px' />)
        }
      }
    },
    {
      title: this.$t('全屏支付图标'),
      key: 'icon',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image src={ row.bigIcon } fix='cover' style='width: 40px;height: 40px;border-radius: 4px' />)
        }
      }
    },
    {
      title: '排序',
      key: 'sort',
      align: 'center'

    },
    {
      title: this.$t('修改时间'),
      key: 'updateTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('创建时间'),
      key: 'createTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作员'),
      align: 'center',
      key: 'operatorName'
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 240,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission
          return (
            <span>
              { Update && <el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={() => this.$refs.EditDialogControl.open(row)}>{this.$t('编辑')}</el-button> }
              { Delete && <el-button type='danger' size='mini' icon='el-icon-delete' onClick={this.handleDeleteAccount.bind(this, row.paywayId)}>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
