var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "abpay" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "140px",
          },
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "basic_config_content",
              attrs: { gutter: 96 },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("h2", [_vm._v(_vm._s(_vm.$t("充值入口开关")))]),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("ABPay充值入口") } },
                    [
                      _c(
                        "div",
                        { staticClass: "config-item" },
                        [
                          _c(
                            "d-switch",
                            {
                              model: {
                                value: _vm.ruleForm.rechargeEntrance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "rechargeEntrance",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.rechargeEntrance",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "open" }, slot: "open" },
                                [_vm._v(_vm._s(_vm.$t("开启")))]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "close" }, slot: "close" },
                                [_vm._v(_vm._s(_vm.$t("关闭")))]
                              ),
                            ]
                          ),
                          _vm._v(" * VIP等级: "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: _vm.$t("VIP等级"),
                                clearable: "",
                              },
                              model: {
                                value: _vm.ruleForm.rechargeVip,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "rechargeVip", $$v)
                                },
                                expression: "ruleForm.rechargeVip",
                              },
                            },
                            _vm._l(_vm.vipLevels, function (item) {
                              return _c("el-option", {
                                key: item.vipLevel,
                                attrs: {
                                  label: "VIP" + item.vipLevel,
                                  value: item.vipLevel,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" 以上显示入口 "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("微信直充入口") } },
                    [
                      _c(
                        "div",
                        { staticClass: "config-item" },
                        [
                          _c(
                            "d-switch",
                            {
                              model: {
                                value: _vm.ruleForm.rechargeWechatEntrance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "rechargeWechatEntrance",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.rechargeWechatEntrance",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "open" }, slot: "open" },
                                [_vm._v(_vm._s(_vm.$t("开启")))]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "close" }, slot: "close" },
                                [_vm._v(_vm._s(_vm.$t("关闭")))]
                              ),
                            ]
                          ),
                          _vm._v(" * VIP等级: "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: _vm.$t("VIP等级"),
                                clearable: "",
                              },
                              model: {
                                value: _vm.ruleForm.rechargeWechatVip,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "rechargeWechatVip",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.rechargeWechatVip",
                              },
                            },
                            _vm._l(_vm.vipLevels, function (item) {
                              return _c("el-option", {
                                key: item.vipLevel,
                                attrs: {
                                  label: "VIP" + item.vipLevel,
                                  value: item.vipLevel,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" 以上显示入口 "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("支付宝直充入口") } },
                    [
                      _c(
                        "div",
                        { staticClass: "config-item" },
                        [
                          _c(
                            "d-switch",
                            {
                              model: {
                                value: _vm.ruleForm.rechargeAlipayEntrance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "rechargeAlipayEntrance",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.rechargeAlipayEntrance",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "open" }, slot: "open" },
                                [_vm._v(_vm._s(_vm.$t("开启")))]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "close" }, slot: "close" },
                                [_vm._v(_vm._s(_vm.$t("关闭")))]
                              ),
                            ]
                          ),
                          _vm._v(" * VIP等级: "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: _vm.$t("VIP等级"),
                                clearable: "",
                              },
                              model: {
                                value: _vm.ruleForm.rechargeAlipayVip,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "rechargeAlipayVip",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.rechargeAlipayVip",
                              },
                            },
                            _vm._l(_vm.vipLevels, function (item) {
                              return _c("el-option", {
                                key: item.vipLevel,
                                attrs: {
                                  label: "VIP" + item.vipLevel,
                                  value: item.vipLevel,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" 以上显示入口 "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("银行卡直充入口") } },
                    [
                      _c(
                        "div",
                        { staticClass: "config-item" },
                        [
                          _c(
                            "d-switch",
                            {
                              model: {
                                value: _vm.ruleForm.rechargeBankEntrance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "rechargeBankEntrance",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.rechargeBankEntrance",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "open" }, slot: "open" },
                                [_vm._v(_vm._s(_vm.$t("开启")))]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "close" }, slot: "close" },
                                [_vm._v(_vm._s(_vm.$t("关闭")))]
                              ),
                            ]
                          ),
                          _vm._v(" * VIP等级: "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: _vm.$t("VIP等级"),
                                clearable: "",
                              },
                              model: {
                                value: _vm.ruleForm.rechargeBankVip,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "rechargeBankVip", $$v)
                                },
                                expression: "ruleForm.rechargeBankVip",
                              },
                            },
                            _vm._l(_vm.vipLevels, function (item) {
                              return _c("el-option", {
                                key: item.vipLevel,
                                attrs: {
                                  label: "VIP" + item.vipLevel,
                                  value: item.vipLevel,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" 以上显示入口 "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("el-form-item", [
                    _c("div", { staticClass: "config-item paixu" }, [
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("微信排序: ")]),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "60px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.rechargeOrder[0],
                                callback: function ($$v) {
                                  _vm.$set(_vm.rechargeOrder, 0, $$v)
                                },
                                expression: "rechargeOrder[0]",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: 1, value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: 2, value: 2 },
                              }),
                              _c("el-option", {
                                attrs: { label: 3, value: 3 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("支付宝排序: ")]),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "60px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.rechargeOrder[1],
                                callback: function ($$v) {
                                  _vm.$set(_vm.rechargeOrder, 1, $$v)
                                },
                                expression: "rechargeOrder[1]",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: 1, value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: 2, value: 2 },
                              }),
                              _c("el-option", {
                                attrs: { label: 3, value: 3 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("银行卡排序: ")]),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "60px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.rechargeOrder[2],
                                callback: function ($$v) {
                                  _vm.$set(_vm.rechargeOrder, 2, $$v)
                                },
                                expression: "rechargeOrder[2]",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: 1, value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: 2, value: 2 },
                              }),
                              _c("el-option", {
                                attrs: { label: 3, value: 3 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("h2", [_vm._v(_vm._s(_vm.$t("ABPay充值限制")))]),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("单笔充值金额限制") } },
                    [
                      _c(
                        "div",
                        { staticClass: "config-item" },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "120px" },
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.ruleForm.minAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "minAmount", $$v)
                              },
                              expression: "ruleForm.minAmount",
                            },
                          }),
                          _vm._v(" ~ "),
                          _c("el-input", {
                            staticClass: "filter-item",
                            staticStyle: { width: "120px" },
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.ruleForm.maxAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "maxAmount", $$v)
                              },
                              expression: "ruleForm.maxAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("h2", [_vm._v(_vm._s(_vm.$t("提现入口开关")))]),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("ABPay提现入口") } },
                    [
                      _c(
                        "div",
                        { staticClass: "config-item" },
                        [
                          _c(
                            "d-switch",
                            {
                              model: {
                                value: _vm.ruleForm.withdrawEntrance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "withdrawEntrance",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.withdrawEntrance",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "open" }, slot: "open" },
                                [_vm._v(_vm._s(_vm.$t("开启")))]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "close" }, slot: "close" },
                                [_vm._v(_vm._s(_vm.$t("关闭")))]
                              ),
                            ]
                          ),
                          _vm._v(" * VIP等级: "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: _vm.$t("VIP等级"),
                                clearable: "",
                              },
                              model: {
                                value: _vm.ruleForm.withdrawVip,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "withdrawVip", $$v)
                                },
                                expression: "ruleForm.withdrawVip",
                              },
                            },
                            _vm._l(_vm.vipLevels, function (item) {
                              return _c("el-option", {
                                key: item.vipLevel,
                                attrs: {
                                  label: "VIP" + item.vipLevel,
                                  value: item.vipLevel,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" 以上显示入口 "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("微信提现入口") } },
                    [
                      _c(
                        "div",
                        { staticClass: "config-item" },
                        [
                          _c(
                            "d-switch",
                            {
                              model: {
                                value: _vm.ruleForm.withdrawWechatEntrance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "withdrawWechatEntrance",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.withdrawWechatEntrance",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "open" }, slot: "open" },
                                [_vm._v(_vm._s(_vm.$t("开启")))]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "close" }, slot: "close" },
                                [_vm._v(_vm._s(_vm.$t("关闭")))]
                              ),
                            ]
                          ),
                          _vm._v(" * VIP等级: "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: _vm.$t("VIP等级"),
                                clearable: "",
                              },
                              model: {
                                value: _vm.ruleForm.withdrawWechatVip,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "withdrawWechatVip",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.withdrawWechatVip",
                              },
                            },
                            _vm._l(_vm.vipLevels, function (item) {
                              return _c("el-option", {
                                key: item.vipLevel,
                                attrs: {
                                  label: "VIP" + item.vipLevel,
                                  value: item.vipLevel,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" 以上显示入口 "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("支付宝提现入口") } },
                    [
                      _c(
                        "div",
                        { staticClass: "config-item" },
                        [
                          _c(
                            "d-switch",
                            {
                              model: {
                                value: _vm.ruleForm.withdrawAlipayEntrance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "withdrawAlipayEntrance",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.withdrawAlipayEntrance",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "open" }, slot: "open" },
                                [_vm._v(_vm._s(_vm.$t("开启")))]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "close" }, slot: "close" },
                                [_vm._v(_vm._s(_vm.$t("关闭")))]
                              ),
                            ]
                          ),
                          _vm._v(" * VIP等级: "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: _vm.$t("VIP等级"),
                                clearable: "",
                              },
                              model: {
                                value: _vm.ruleForm.withdrawAlipayVip,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "withdrawAlipayVip",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.withdrawAlipayVip",
                              },
                            },
                            _vm._l(_vm.vipLevels, function (item) {
                              return _c("el-option", {
                                key: item.vipLevel,
                                attrs: {
                                  label: "VIP" + item.vipLevel,
                                  value: item.vipLevel,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" 以上显示入口 "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("银行卡提现入口") } },
                    [
                      _c(
                        "div",
                        { staticClass: "config-item" },
                        [
                          _c(
                            "d-switch",
                            {
                              model: {
                                value: _vm.ruleForm.withdrawBankEntrance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "withdrawBankEntrance",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.withdrawBankEntrance",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "open" }, slot: "open" },
                                [_vm._v(_vm._s(_vm.$t("开启")))]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "close" }, slot: "close" },
                                [_vm._v(_vm._s(_vm.$t("关闭")))]
                              ),
                            ]
                          ),
                          _vm._v(" * VIP等级: "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: _vm.$t("VIP等级"),
                                clearable: "",
                              },
                              model: {
                                value: _vm.ruleForm.withdrawBankVip,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "withdrawBankVip", $$v)
                                },
                                expression: "ruleForm.withdrawBankVip",
                              },
                            },
                            _vm._l(_vm.vipLevels, function (item) {
                              return _c("el-option", {
                                key: item.vipLevel,
                                attrs: {
                                  label: "VIP" + item.vipLevel,
                                  value: item.vipLevel,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" 以上显示入口 "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("el-form-item", [
                    _c("div", { staticClass: "config-item paixu" }, [
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("微信排序: ")]),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "60px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.withdrawalOrder[0],
                                callback: function ($$v) {
                                  _vm.$set(_vm.withdrawalOrder, 0, $$v)
                                },
                                expression: "withdrawalOrder[0]",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: 1, value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: 2, value: 2 },
                              }),
                              _c("el-option", {
                                attrs: { label: 3, value: 3 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("支付宝排序: ")]),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "60px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.withdrawalOrder[1],
                                callback: function ($$v) {
                                  _vm.$set(_vm.withdrawalOrder, 1, $$v)
                                },
                                expression: "withdrawalOrder[1]",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: 1, value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: 2, value: 2 },
                              }),
                              _c("el-option", {
                                attrs: { label: 3, value: 3 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("银行卡排序: ")]),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "60px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.withdrawalOrder[2],
                                callback: function ($$v) {
                                  _vm.$set(_vm.withdrawalOrder, 2, $$v)
                                },
                                expression: "withdrawalOrder[2]",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: 1, value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: 2, value: 2 },
                              }),
                              _c("el-option", {
                                attrs: { label: 3, value: 3 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("h2", [
                    _vm._v(_vm._s(_vm.$t("ABPay提现加赠（仅在提现显示）"))),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("用户使用ABpay提现显示加赠"),
                        "label-width": "150",
                        prop: "withdrawGive",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "120px" },
                        model: {
                          value: _vm.ruleForm.withdrawGive,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "withdrawGive", $$v)
                          },
                          expression: "ruleForm.withdrawGive",
                        },
                      }),
                      _vm._v(" % "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("h2", [_vm._v(_vm._s(_vm.$t("VIP用户充值加赠")))]),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("VIP用户ABPay充值加赠"),
                "label-width": "220px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "config-item" },
                [
                  _c("p", [_vm._v("用户达到等级取最高配置值")]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.submitting },
                      on: {
                        click: function ($event) {
                          return _vm.ruleForm.giveRequestList.unshift({})
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("添加")))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._l(_vm.ruleForm.giveRequestList, function (item, index) {
            return _c(
              "div",
              { key: "give" + index, staticClass: "config-item ml16 mb16" },
              [
                _vm._v(" * VIP等级: "),
                _c(
                  "el-select",
                  {
                    staticClass: "filter-item",
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: _vm.$t("VIP等级"), clearable: "" },
                    model: {
                      value: item.vipLevel,
                      callback: function ($$v) {
                        _vm.$set(item, "vipLevel", $$v)
                      },
                      expression: "item.vipLevel",
                    },
                  },
                  _vm._l(_vm.vipLevels, function (vip) {
                    return _c("el-option", {
                      key: vip.vipLevel,
                      attrs: {
                        label: "VIP" + vip.vipLevel,
                        value: vip.vipLevel,
                      },
                    })
                  }),
                  1
                ),
                _vm._v(" 以上充值加赠 "),
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "120px" },
                  attrs: { clearable: "" },
                  model: {
                    value: item.vipGive,
                    callback: function ($$v) {
                      _vm.$set(item, "vipGive", $$v)
                    },
                    expression: "item.vipGive",
                  },
                }),
                _vm._v(" % "),
                _vm.ruleForm.giveRequestList.length > 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "warning", loading: _vm.submitting },
                        on: {
                          click: function ($event) {
                            return _vm.ruleForm.giveRequestList.splice(index, 1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("删除")))]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c("h2", [_vm._v(_vm._s(_vm.$t("提现用户等级")))]),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("其他渠道提现用户等级配置"),
                "label-width": "220px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "config-item" },
                [
                  _c("p", [_vm._v("用户达到等级取最高配置值")]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.submitting },
                      on: {
                        click: function ($event) {
                          return _vm.ruleForm.vipWithdrawRequestList.unshift({})
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("添加")))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._l(_vm.ruleForm.vipWithdrawRequestList, function (item, index) {
            return _c(
              "div",
              { key: "with" + index, staticClass: "config-item ml16 mb16" },
              [
                _vm._v(" * VIP等级: "),
                _c(
                  "el-select",
                  {
                    staticClass: "filter-item",
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: _vm.$t("VIP等级"), clearable: "" },
                    model: {
                      value: item.vipLevel,
                      callback: function ($$v) {
                        _vm.$set(item, "vipLevel", $$v)
                      },
                      expression: "item.vipLevel",
                    },
                  },
                  _vm._l(_vm.vipLevels, function (vip) {
                    return _c("el-option", {
                      key: vip.vipLevel,
                      attrs: {
                        label: "VIP" + vip.vipLevel,
                        value: vip.vipLevel,
                      },
                    })
                  }),
                  1
                ),
                _vm._v(" 以上每日可提现 "),
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "120px" },
                  attrs: { clearable: "" },
                  model: {
                    value: item.vipNumber,
                    callback: function ($$v) {
                      _vm.$set(item, "vipNumber", $$v)
                    },
                    expression: "item.vipNumber",
                  },
                }),
                _vm._v(" 次（-1代表不限制） 每日可提现 "),
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "120px" },
                  attrs: { clearable: "" },
                  model: {
                    value: item.vipAmount,
                    callback: function ($$v) {
                      _vm.$set(item, "vipAmount", $$v)
                    },
                    expression: "item.vipAmount",
                  },
                }),
                _vm._v(" ￥（-1代表不限制） "),
                _vm.ruleForm.vipWithdrawRequestList.length > 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "warning", loading: _vm.submitting },
                        on: {
                          click: function ($event) {
                            return _vm.ruleForm.vipWithdrawRequestList.splice(
                              index,
                              1
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("删除")))]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("ABPay渠道提现用户等级配置"),
                "label-width": "220px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "config-item" },
                [
                  _c("p", [_vm._v("用户达到等级取最高配置值")]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.submitting },
                      on: {
                        click: function ($event) {
                          return _vm.ruleForm.withdrawRequestList.unshift({})
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("添加")))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._l(_vm.ruleForm.withdrawRequestList, function (item, index) {
            return _c(
              "div",
              { key: "vip" + index, staticClass: "config-item ml16 mb16" },
              [
                _vm._v(" * VIP等级: "),
                _c(
                  "el-select",
                  {
                    staticClass: "filter-item",
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: _vm.$t("VIP等级"), clearable: "" },
                    model: {
                      value: item.vipLevel,
                      callback: function ($$v) {
                        _vm.$set(item, "vipLevel", $$v)
                      },
                      expression: "item.vipLevel",
                    },
                  },
                  _vm._l(_vm.vipLevels, function (vip) {
                    return _c("el-option", {
                      key: vip.vipLevel,
                      attrs: {
                        label: "VIP" + vip.vipLevel,
                        value: vip.vipLevel,
                      },
                    })
                  }),
                  1
                ),
                _vm._v(" 以上每日可提现 "),
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "120px" },
                  attrs: { clearable: "" },
                  model: {
                    value: item.vipNumber,
                    callback: function ($$v) {
                      _vm.$set(item, "vipNumber", $$v)
                    },
                    expression: "item.vipNumber",
                  },
                }),
                _vm._v(" 次（-1代表不限制） 每日可提现 "),
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "120px" },
                  attrs: { clearable: "" },
                  model: {
                    value: item.vipAmount,
                    callback: function ($$v) {
                      _vm.$set(item, "vipAmount", $$v)
                    },
                    expression: "item.vipAmount",
                  },
                }),
                _vm._v(" ￥（-1代表不限制） "),
                _vm.ruleForm.withdrawRequestList.length > 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "warning", loading: _vm.submitting },
                        on: {
                          click: function ($event) {
                            return _vm.ruleForm.withdrawRequestList.splice(
                              index,
                              1
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("删除")))]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "action-button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("保 存")))]
              ),
              _c("el-button", { on: { click: _vm.getAbpayConfig } }, [
                _vm._v(_vm._s(_vm.$t("重 置"))),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }