import request from '@/plugins/axios'

/* 广告 ************************************************************************/
// 查询banner/广告图列表
export function getCpadList(data = {}) {
  return request({
    url: '/cpad/list',
    method: 'post',
    data
  })
}

// 添加banner/广告图
export function addCpad(data = {}) {
  return request({
    url: '/cpad/add',
    method: 'post',
    data
  })
}

// 获取广告详情
export function getCpadDetail(params = {}) {
  return request({
    url: '/cpad/detail',
    method: 'get',
    params
  })
}

// 查询活动下拉框列表
export function getCpadActivityList(params = {}) {
  return request({
    url: '/cpad/jump/activity/list',
    method: 'get',
    params
  })
}

// 修改banner/广告图
export function updateCpad(data = {}) {
  return request({
    url: '/cpad/update',
    method: 'post',
    data
  })
}

// 批量修改广告图
export function batchUpdateCpad(data = {}) {
  return request({
    url: '/cpad/batch/update',
    method: 'post',
    data
  })
}

// 删除banner/广告图
export function deleteCpad(data = {}) {
  return request({
    url: '/cpad/delete',
    method: 'post',
    data
  })
}

// 其他活动

export function getActivityCommonList(data) {
  return request({
    url: '/activity/common/list',
    method: 'post',
    data
  })
}

export function createActivityCommon(data) {
  return request({
    url: '/createActivity/conf',
    method: 'post',
    data
  })
}

export function updateActivityCommon(data) {
  return request({
    url: '/updateActivity/conf',
    method: 'post',
    data
  })
}

export function closeActivityCommon(data) {
  return request({
    url: '/closeActivity/conf',
    method: 'post',
    data
  })
}

export function deleteActivityCommon(params) {
  return request({
    url: '/activity/conf/delete',
    method: 'post',
    params
  })
}

export function getActivityLinkageList(data) {
  return request({
    url: '/activity/jump/page/list',
    method: 'post',
    data
  })
}

// 任务管理列表
export function getActivityList(data) {
  return request({
    url: '/activity/list',
    method: 'post',
    data
  })
}

// 新增任务活动
export function addActivity(data) {
  return request({
    url: '/activity/activityAdd',
    method: 'post',
    data
  })
}

// 编辑任务活动
export function updateActivity(data) {
  return request({
    url: '/activity/activityUpdate',
    method: 'post',
    data
  })
}

// 任务活动状态修改
export function updateActivityStatus(data) {
  return request({
    url: '/activity/activityStatus',
    method: 'post',
    data
  })
}

// 活动分类列表
export function getActivityConfTypeList(params) {
  return request({
    url: '/activity/conf/type/list',
    method: 'get',
    params
  })
}

// 新增-编辑活动分类
export function addUpdateActivityConfType(data) {
  return request({
    url: '/activity/conf/type/addUpdate',
    method: 'post',
    data
  })
}

// 查询任务内容详情
export function getActivityDetail(params) {
  return request({
    url: '/activity/detail',
    method: 'get',
    params
  })
}

// 新增-编辑任务活动内容
export function addUpdateActivityDetail(data) {
  return request({
    url: '/activity/activityDetailAddUpdate',
    method: 'post',
    data
  })
}

// 任务分类列表
export function getActivityTypeList(params) {
  return request({
    url: '/activityType/list',
    method: 'get',
    params
  })
}

// 会员任务活动奖励记录按查询条件导出
export function activityMemberDownload(params) {
  return request({
    url: '/activity/member/download',
    method: 'get',
    params,
    responseType: 'blob',
    timeout: 600000
  });
}

// 新增-编辑任务分类
export function AddUpdateActivityType(data) {
  return request({
    url: '/activity/activityTypeAddUpdate',
    method: 'post',
    data
  })
}

// 会员任务活动奖励记录
export function getActivityMemberList(data) {
  return request({
    url: '/activity/member/list',
    method: 'post',
    data
  })
}

// 签到任务信息
export function getCheckinDetail(params) {
  return request({
    url: '/checkin/detail',
    method: 'get',
    params
  })
}

// 修改签到任务
export function updateCheckin(data) {
  return request({
    url: '/checkin/update',
    method: 'post',
    data
  })
}

// 签到记录列表
export function getCheckinMemberList(data) {
  return request({
    url: '/checkin/member/list',
    method: 'post',
    data
  })
}

// 任务目标列表
export function getActivityTargetList(params) {
  return request({
    url: '/activityTarget/list',
    method: 'get',
    params
  })
}

// 查询红包活动列表
export function redpacketList(data) {
  return request({
    url: '/redpacket/list',
    method: 'post',
    data
  })
}

// 添加红包活动
export function redpacketAdd(data) {
  return request({
    url: '/redpacket/add',
    method: 'post',
    data
  })
}

// 修改红包活动
export function redpacketUpdate(data) {
  return request({
    url: '/redpacket/update',
    method: 'post',
    data
  })
}

// 获取红包活动详情
export function redpacketDetail(data) {
  return request({
    url: '/redpacket/detail',
    method: 'post',
    data
  })
}

// 查询红包领取列表
export function redpacketMemberList(data) {
  return request({
    url: '/redpacket/member/list',
    method: 'post',
    data
  })
}

// 删除红包活动
export function redpacketClose(data) {
  return request({
    url: '/redpacket/close',
    method: 'post',
    data
  })
}

// 查询聊天室发送红包列表
export function sendList(data) {
  return request({
    url: '/send/list',
    method: 'post',
    data
  })
}

// 查询聊天室发送红包统计
export function sendStatistics(data) {
  return request({
    url: '/send/statistics',
    method: 'post',
    data
  })
}

// 查询聊天室领取红包列表
export function receiveList(data) {
  return request({
    url: '/receive/list',
    method: 'post',
    data
  })
}

// 查询聊天室领取红包统计
export function receiveStatistics(data) {
  return request({
    url: '/receive/statistics',
    method: 'post',
    data
  })
}

// 聊天室红包设置查询
export function roomGetConf(data) {
  return request({
    url: '/room/getConf',
    method: 'post',
    data
  })
}

// 新增或修改聊天室红包配置
export function roomConfig(data) {
  return request({
    url: '/room/config',
    method: 'post',
    data
  })
}

// 新活动-查询
export function activityNewList(data) {
  return request({
    url: '/activity/new/list',
    method: 'post',
    data
  })
}

// 新活动-创建
export function activityNewCreate(data) {
  return request({
    url: '/activity/new/create',
    method: 'post',
    data
  })
}

// 新活动-修改
export function activityNewEdit(data) {
  return request({
    url: '/activity/new/edit',
    method: 'post',
    data
  })
}

// 新活动-详情
export function activityNewDetail(data) {
  return request({
    url: '/activity/new/detail',
    method: 'post',
    data
  })
}

// 新活动-删除单个规则
export function activityNewDeleteRule(data) {
  return request({
    url: '/activity/new/deleteRule',
    method: 'post',
    data
  })
}

// 新活动-删除所有规则
export function activityNewDeleteRuleList(data) {
  return request({
    url: '/activity/new/deleteRuleList',
    method: 'post',
    data
  })
}

// 新活动-关闭/开启
export function activityNewOpenOrClose(data) {
  return request({
    url: '/activity/new/openOrClose',
    method: 'post',
    data
  })
}

// 新活动-活动奖励记录
export function activityNewSearchRewardRecord(data) {
  return request({
    url: '/activity/new/searchRewardRecord',
    method: 'post',
    data
  })
}
