<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.tenantCode" placeholder="租户简称" class="filter-item" clearable style="width: 160px;" />
      <el-input v-model="search.tenantName" placeholder="租户名称" class="filter-item" clearable style="width: 160px;" />
      <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleFetch">查询</el-button>
      <el-button v-if="permission.Add" icon="el-icon-plus" type="success" style="float: right" @click="onCreate">创建租户</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <CreateDialog ref="CreateDialogControl" @create="_handleCreateTenant" />
    <ConfigDialog ref="ConfigDialogControl" @update="_handleUpdateConfig" />
    <UpdateDialog ref="UpdateDialogControl" @update="_handleUpdateTenant" />
    <ValidGoogleVisible ref="ValidGoogleVisibleControl" />
    <GroupNameDialog ref="GroupNameDialogControl" @update="_handleUpdateGroupName" />

  </div>
</template>

<script>
import { getTenantList, addTenant, updateDivideIntoConf, updateBlackStatus, updateStatus, updateConfig, tenantUpdateGroup, tenantGroupList } from '@/api/tenant'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { CreateDialog, ConfigDialog, UpdateDialog, GroupNameDialog } from './components'
import ValidGoogleVisible from '@/views/components/ValidGoogleVisible'
import { agencyTypes } from '@/libs/options'
export default {
  name: 'TenantManage',
  components: { CreateDialog, ConfigDialog, UpdateDialog, ValidGoogleVisible, GroupNameDialog },
  mixins: [TablePageMixin(getTenantList)],
  data() {
    return {
      search: {},
      filterColumn: false,
      agencyTypes,
      groupList: [],
      columns: GetColumns.call(this)
    }
  },
  created() {
    tenantGroupList({ currentPage: 1, pageSize: 200 }).then(([items, err]) => {
      if (!err) {
        this.groupList = items
      }
    })
  },
  methods: {
    onCreate() {
      this.$refs.CreateDialogControl.open()
    },
    _handleCreateTenant({ field, cancel, close }) {
      addTenant({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success('新增成功')
          this.$refs.ValidGoogleVisibleControl.open(data)
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    _handleUpdateConfig({ field, cancel, close }) {
      updateDivideIntoConf({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('操作成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleUpdateTenant({ field, cancel, close }) {
      updateConfig({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('操作成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleChangeBlackStatus({ blackStatus, tenantCode, tenantId }) {
      return new Promise((resolve) => {
        this.$confirm(
          blackStatus === 1 ? '确认启用租户所有后台账号???' : '关闭后，租户所有后台账号将无法登录???',
          '系统提示',
          {
            center: true,
            type: 'warning'
          }).then(() => {
          updateBlackStatus({
            tenantCode,
            tenantId,
            blackStatus: ~blackStatus + 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
            }
          })
        }).catch(() => {})
      })
    },
    _handleChangeStatus({ status, tenantCode, tenantId }) {
      this.$confirm(
        `确认${status === 1 ? '恢复' : '停站'}此站点`, '系统提示', {
          type: 'warning'
        }).then(() => {
        updateStatus({
          tenantCode,
          tenantId,
          status: ~status + 2
        }).then(([_, err]) => {
          if (!err) {
            this.handleFetch()
            this.$message.success('操作成功')
          }
        })
      }).catch(() => {})
    },
    _handleUpdateGroupName({ field, cancel, close }) {
      tenantUpdateGroup({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('操作成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>

<style lang="scss">
.division {
  line-height: 32px;
  text-align: center;
  font-size: 12px;

  & > .el-col:not(:last-child) {
    border-right: 1px solid #ddd;
  }

  &-popper {
    padding: 0;
  }
  &-title {
    border-bottom: 1px solid #ddd;
  }
  &-line {
    border-right: 1px solid #ddd;
  }
}
</style>
