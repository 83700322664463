import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: '图标',
      key: 'icon',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (<el-image style='width: 60px; height: 60px' lazy src={row.icon} />)
        }
      }
    },
    {
      title: '银行',
      key: 'name',
      align: 'center',
      width: 120
    },
    {
      title: '银行卡字段',
      key: 'bankNumbersList',
      align: 'center',
      className: 'list-table',
      component: {
        render: (h, row) => {
          let l = row.bankNumbersList.length
          let arr = l > 15 ? row.bankNumbersList.slice(0, 15) : [...row.bankNumbersList]
          return h('div', { class: { card_code_warp: true, clearfix: true }}, arr.map((item, index) => {
            const a = h('b', {
              class: {
                card_code: true
              }
            }, `${item.cardBin}`)
            const b = h('b', {
              class: {
                card_code: true
              },
              style: {
                cursor: 'pointer',
                color: '#3894FF'
              },
              on: {
                click: () => {
                  this.$refs.lookBankCardBin.initlist(row)
                }
              }
            }, '查看更多')
            let ele = a
            if (l > 15) ele = index < arr.length - 1 ? a : b
            return ele
          }))
        }
      }

    },
    {
      title: '绑定数量',
      key: 'bindNum',
      align: 'center',
      width: 80
    },
    {
      title: '状态',
      key: 'binding',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.binding}
              before-change={ () => this._editStatus(row) }
              active-value={1}
              inactive-value={0}
              active-color='#3894FF'
              inactive-color='#F26161'
            >
              <span slot='close'>停用</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          const { Delete, Update } = this.permission
          return (
            <span>
              { Update && <el-link type='primary' onClick={ () => this.openBankDialog(row) }>编辑</el-link> }
              { Delete && <el-link type='danger' onClick={ () => this._delete(row.id, row.name) }>删除</el-link> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
