import Vue from 'vue'
import 'default-passive-events'

import VueNativeSock from 'vue-native-websocket'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './styles/element-variables.scss'
import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
import './permission' // permission control

import * as filters from './filters' // global filters
import plugins from './plugins'
import i18n from './lang'

Vue.use(plugins)
Element.Dialog.props.closeOnClickModal = false
Vue.use(Element, {
  size: 'small',
  i18n: (key, value) => i18n.t(key, value)
})

Vue.use(VueNativeSock, 'ws://localhost:9090', {
  connectManually: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 3000
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

Vue.config.devtools = true


new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
