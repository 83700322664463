var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "900px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "160px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("会员账号"), prop: "memberName" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 6 },
                model: {
                  value: _vm.ruleForm.memberName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "memberName", $$v)
                  },
                  expression: "ruleForm.memberName",
                },
              }),
              _c("div", { staticStyle: { "line-height": "1.5" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "每行的数据格式：会员账号,金额 使用逗号或者空格隔开"
                      )
                    ) +
                    " "
                ),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("如")) + "：aaaaaa 300 "),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10px", color: "red" } },
                  [_vm._v(_vm._s(_vm.$t("使用空格隔开")))]
                ),
                _c("br"),
                _vm._v(" bbbbbb,200 "),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10px", color: "red" } },
                  [_vm._v(_vm._s(_vm.$t("使用逗号隔开")))]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("操作原因") } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  placeholder: _vm.$t("请输入操作原因"),
                },
                model: {
                  value: _vm.ruleForm.operatorRemark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "operatorRemark", $$v)
                  },
                  expression: "ruleForm.operatorRemark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }