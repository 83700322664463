import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span class='primary-type cursor-pointer hover-underline' onClick={this._onDetails.bind(this, row)}>{ row.memberName }</span>)
        }
      }
    },
    {
      title: this.$t('所属代理'),
      key: 'agencyMemberName',
      align: 'center'
    },
    {
      title: this.$t('VIP等级'),
      key: 'vipLevel',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>VIP{row.vipLevel}</span>)
        }
      }
    },
    {
      title: this.$t('充值金额'),
      key: 'sumRecharge',
      align: 'center'
    },
    {
      title: this.$t('提现金额'),
      key: 'sumWithdraw',
      align: 'center'
    },
    {
      title: this.$t('人工加款'),
      key: 'sumOperatorAdd',
      align: 'center'
    },
    {
      title: this.$t('人工减款'),
      key: 'sumOperatorSubtract',
      align: 'center'
    },
    {
      title: this.$t('账号余额'),
      key: 'balance',
      align: 'center'
    },
    {
      title: this.$t('会员备注'),
      key: 'remark',
      align: 'center'
    },
    {
      title: this.$t('状态'),
      key: 'status',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (<span>{ row.freezeStatus === 0 ? this.$t('正常') : this.$t('封停') }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      component: {
        render: (h, row) => {
          const { Sum, Subtract } = this.permission
          return (
            <span>
              { Sum && <el-button type='success' size='small' onClick={() => this.$refs.AloneDialogControl.open(row, 174)}>{ this.$t('加款') }</el-button> }
              { Subtract && <el-button type='danger' size='small' onClick={() => this.$refs.AloneDialogControl.open(row, 5)}>{ this.$t('扣款') }</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
