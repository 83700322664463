import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('房间主题'),
      key: 'roomName',
      align: 'center'
    },
    {
      title: this.$t('彩种类型'),
      key: 'lottypeId',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.livePreLotteryType.find(e => e.key === row.lottypeId)?.label }</span>)
        }
      }
    },
    {
      title: this.$t('彩种/赛事'),
      key: 'lotteryName',
      align: 'center'
    },
    {
      title: this.$t('分辨率'),
      key: 'displayStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.displayStatusList[row.displayStatus] }</span>)
        }
      }
    },
    {
      title: this.$t('主播昵称'),
      key: 'nickName',
      align: 'center'
    },
    {
      title: this.$t('所属家族'),
      key: 'familyName',
      align: 'center'
    },
    {
      title: this.$t('预约状态'),
      key: 'liveStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.preStatus.find(e => e.key === row.liveStatus)?.label }</span>)
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
