<template>
  <el-dialog
    :title="`${ruleForm.name}${$t('彩种介绍设置')}`"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="50%"
    @open="onOpen"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" v-loading="loading" :model="ruleForm" :rules="rules">
      <el-form-item>
        <el-radio-group v-model="headerLang" style="margin-right: 6px;" @change="onOpen">
          <el-radio-button v-for="(item,index) in allLang" :key="index" :label="item.lang">{{ item.name }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="desc">
        <tinymce ref="tinymce" v-model="ruleForm.explain" :height="300" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import Tinymce from '@/components/Tinymce'
import FormDialogMixin from '@/mixins/form-dialog'
import { getLotteryInfo } from '@/api/lottery'
import { mapGetters } from 'vuex'
const getDefaultForm = () => ({
  name: '',
  explain: '',
  headerLang: ''
})

export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      loading: false,
      emitName: 'update',
      rules: {
        explain: [
          { required: true, message: this.$t('请输入VIP等级'), trigger: 'blur' }
        ]
      },
      headerLang: ''
    }
  },
  computed: {
    ...mapGetters(['allLang'])
  },
  created() {
    this.headerLang = this.allLang[0].lang
  },
  methods: {
    onOpen() {
      this.loading = true
      getLotteryInfo({
        lotteryId: this.$parent.lotteryId,
        headerLang: this.headerLang
      }, this.$parent.operatorType).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.ruleForm = data
          this.ruleForm.headerLang = this.headerLang
          this.$nextTick(() => {
            this.$refs.tinymce.hasInit && this.$refs.tinymce.setContent(data.explain || '')
          })
        }
      })
    }
  }
}
</script>
