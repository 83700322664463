<template>
  <div v-if="remark">
    <el-dialog :visible.sync="visible" width="500px" title="异常登录提示">
      <div class="loginTips">
        <div v-for="(item, index) in remark.split(',')" :key="index">
          {{ item }}
        </div>
        <p>请确认是否被人操作，否则请立即修改密码!!!</p>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" @click="openPassword">修改密码</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('修改密码')"
      :visible.sync="passwordVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form label-width="120px" label-suffix=":">
        <el-form-item :label="$t('账号')">
          {{ ruleForm.operatorName }}
        </el-form-item>
        <el-form-item :label="$t('昵称')">
          {{ ruleForm.nickName }}
        </el-form-item>
        <el-form-item :label="$t('用户分组')">
          {{ groupName }}
        </el-form-item>
        <el-form-item :label="$t('修改密码')">
          <el-input
            v-model.trim="ruleForm.password"
            type="password"
            :placeholder="$t('请输入6~12位英文数字密码')"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item label="谷歌验证码" prop="googleCode">
          <el-input v-model.trim="ruleForm.googleCode" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="passwordVisible = false">
          {{ $t("关闭") }}
        </el-button>
        <el-button type="primary" :loading="submitting" @click="submitPassword">
          {{ $t("保存") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { validAccountAndPassword } from "@/utils/validate";
import { getGroupList, updateOperator } from "@/api/permission";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      visible: true,
      passwordVisible: false,
      submitting: false,
      groupName: "",
      ruleForm: {
        operatorId: "",
        operatorName: "",
        nickName: "",
        groupId: null,
        password: "",
        googleCode: ''
      },
    };
  },
  computed: {
    ...mapGetters(["info","remark"]),
  },
  created() {
    this.ruleForm.operatorId = this.info.operatorId;
    this.ruleForm.operatorName = this.info.operatorName;
    this.ruleForm.nickName = this.info.nickName;
    this.ruleForm.groupId = this.info.groupId;
    this.fetchGroupList();
  },
  methods: {
    fetchGroupList() {
      getGroupList({ currentPage: 1, pageSize: 100 }).then(([items, err]) => {
        if (!err) {
          this.groupName = items.find(
            (e) => e.id === this.ruleForm.groupId
          )?.groupName;
        }
      });
    },
    openPassword() {
      this.visible = false;
      this.passwordVisible = true;
    },
    submitPassword() {
      if (this.ruleForm.password === "") {
        this.$message.warning("请输入6~12位英文数字密码");
        return;
      }
      if (!validAccountAndPassword(this.ruleForm.password)) {
        this.$message.warning("请输入6~12位英文数字密码");
        return;
      }
      if (this.ruleForm.googleCode === "") {
        this.$message.warning("请输入谷歌验证码");
        return;
      }
      if (!/^[0-9]{6}$/.test(this.ruleForm.googleCode)) {
        this.$message.warning("请输入6位数字的谷歌验证码");
        return;
      }
      this.submitting = true;
      updateOperator(this.ruleForm)
        .then(([data, err]) => {
          if (!err) {
            this.$message.success(this.$t("保存成功"));
            this.passwordVisible = false;
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.loginTips {
  text-align: center;
  > div {
    margin-top: 10px;
  }
  > p {
    color: red;
    margin-top: 25px;
    margin-bottom: 20px;
  }
}
</style>
