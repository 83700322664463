import { filterColumns } from '@/utils'

const CanChooseColumns = function() {
  return [
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p class='primary-type cursor-pointer hover-underline' onClick={this._onDetails.bind(this, row)}>{row.memberName}</p>
              {/* <i
                class='el-icon-chat-dot-square cursor-pointer'
                style='color: #FF8C00'
                onClick={this._onSend.bind(this, row)}
              /> */}
            </div>
          )
        }
      }
    },
    
    {
      title: this.$t('VIP等级'),
      key: 'level',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (
            // <div class='primary-type cursor-pointer hover-underline' onClick={this._onUpdateVip.bind(this, row)}>
            //   <span>VIP{row.vipLevel}</span>
            //   <p>{row.vipStatus ? this.$t('锁定') : this.$t('未锁定')}</p>
            //   {this.permission.UpdateVip && <i class='el-icon-edit' />}
            // </div>
            <div class=''>
              <span>VIP{row.vipLevel}</span>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('晋级礼金'),
      key: 'dqdqdwq',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{row.dqdqdwq || this.$t('暂无')}</p>
              {<i class='el-icon-edit primary-type cursor-pointer' onClick={() => this.$refs.ProxyDialogControl.open(row)} />}
              {/* {this.permission.UpdateAgent && <i class='el-icon-edit primary-type cursor-pointer' onClick={() => this.$refs.ProxyDialogControl.open(row)} />} */}
            </div>
          )
        }
      }
    },
    {
      title: this.$t('晋级礼金'),
      key: 'dbuqbdquw',
      align: 'center',
    },
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
