<template>
  <div class="dashboard-container">
    <tenant v-if="operatorType === 2" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tenant from './tenant'
import store from '@/store'
export default {
  name: 'Dashboard',
  components: { tenant },
  computed: {
    ...mapGetters(['operatorType'])
  },
  async beforeRouteEnter(to, from, next) {
    let info = await store.state.info
    if (info && info.tenantCode === 'DOMAINQR' && to.path == '/dashboard') {
      next('/qr-mgr')
      return
    }
    next()
  }
}
</script>

<style lang="scss">
.dashboard-container {
  .el-card__header {
    padding: 0 20px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dashboard-head {
    padding: 10px;
    text-align: right;
  }
  .el-table__footer-wrapper tbody td {
    color: #FF2525;
  }
}

</style>
