<template>
  <el-form
    ref="validateForm"
    :model="ruleForm"
    :rules="rules"
    label-width="100px"
    class="tenant-form"
  >
    <el-form-item label="名称" prop="tenantName">
      <el-input v-model="ruleForm.tenantName" />
    </el-form-item>
    <el-form-item label="简称" prop="tenantCode">
      <el-input v-model="ruleForm.tenantCode" />
    </el-form-item>
    <el-form-item :label="$t('租户组别')">
      <el-select v-model="ruleForm.tagGroupId" placeholder="请选择租户组别" style="width: 100%;">
        <el-option v-for="item in groupList" :key="item.id" :value="item.id" :label="item.groupName" />
      </el-select>
    </el-form-item>
    <el-form-item label="代理模式" prop="agencyType">
      <el-select v-model="ruleForm.agencyType" clearable placeholder="请选择代理模式" style="width: 100%">
        <el-option v-for="(item, index) of agencyTypes" :key="index" :label="item.label" :value="item.key" />
      </el-select>
    </el-form-item>
    <el-form-item label="维护费用" prop="maintenanceCost" :rules="amountRules">
      <el-input v-model="ruleForm.maintenanceCost" placeholder="请输入金额" />
    </el-form-item>
    <el-form-item label="美颜费用" prop="beautyCost" :rules="amountRules">
      <el-input v-model="ruleForm.beautyCost" placeholder="请输入金额" />
    </el-form-item>
    <el-form-item label="服务器费用" prop="serverCost" :rules="amountRules">
      <el-input v-model="ruleForm.serverCost" placeholder="请输入金额" />
    </el-form-item>
    <template v-for="config in ladderConfig">
      <el-form-item :key="config.key + 'Charges'" :label="`${config.label}抽成金额`">
        <el-row type="flex" :gutter="12">
          <el-col v-for="(name, i) in ladderOptions" :key="i" :span="8">
            <el-form-item :prop="`${name}Level${config.key}Charges`" :rules="amountRules">
              <el-input v-model="ruleForm[`${name}Level${config.key}Charges`]" :placeholder="`抽成金额(${i + 1}级)`" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :key="config.key + 'Percent'" :label="`${config.label}抽成比例`">
        <el-row type="flex" :gutter="12">
          <el-col v-for="(name, i) in ladderOptions" :key="i" :span="8">
            <el-form-item :prop="`${name}Level${config.key}Percent`" :rules="percentRules">
              <el-input v-model="ruleForm[`${name}Level${config.key}Percent`]" :placeholder="`抽成比例(${i + 1}级)`">
                <template slot="suffix">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
    </template>
  </el-form>
</template>
<script>
import { ladderConfig, ladderOptions, amountRules, percentRules } from '../utils'
import { agencyTypes } from '@/libs/options'
const getDefaultForm = () => ({
  tenantName: '',
  tenantCode: '',
  tagGroupId: '',
  maintenanceCost: '',
  beautyCost: '',
  serverCost: '',
  oneLevelBetsCharges: '',
  oneLevelBetsPercent: '',
  oneLevelGiftCharges: '',
  oneLevelGiftPercent: '',
  oneLevelPayroomCharges: '',
  oneLevelPayroomPercent: '',
  twoLevelBetsCharges: '',
  twoLevelBetsPercent: '',
  twoLevelGiftCharges: '',
  twoLevelGiftPercent: '',
  twoLevelPayroomCharges: '',
  twoLevelPayroomPercent: '',
  threeLevelBetsCharges: '',
  threeLevelBetsPercent: '',
  threeLevelGiftCharges: '',
  threeLevelGiftPercent: '',
  threeLevelPayroomCharges: '',
  threeLevelPayroomPercent: ''
})

export default {
  props: {
    groupList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      ruleForm: getDefaultForm(),
      ladderConfig,
      ladderOptions,
      amountRules,
      percentRules,
      agencyTypes,
      rules: {
        tenantName: [{ required: true, message: '请输入租户名称', trigger: 'blur' }],
        tenantCode: [
          { required: true, message: '请输入租户简称', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)[A-Z0-9]{2,10}$/, message: '请输入2~10位数字和大写英文字符', trigger: 'blur' }
        ],
        agencyType: [{ required: true, message: '请选择代理模式', trigger: 'blur' }]
      }
    }
  },
  deactivated() {
    this.reset()
  },
  methods: {
    onValidate(cb) {
      return new Promise(resolve => {
        this.$refs.validateForm.validate((valid) => {
          if (valid) {
            cb && cb(this.ruleForm)
            resolve()
          }
        })
      })
    },
    reset() {
      this.ruleForm = getDefaultForm()
      this.$refs.validateForm.resetFields()
    }
  }
}
</script>
