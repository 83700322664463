<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive><component :is="activeName"  :optionData="optionData" /></keep-alive>
      </template>
  </div>
</template>

<script>
import CustomerChatTab from './CustomerChatTab'
import CustomerQuestionTab from './CustomerQuestionTab'
import CustomerManageTab from './CustomerManageTab'
import { parentMixin } from '@/mixins/tab-items'

export default {
  name: 'InteractiveAIChat',
  components: { CustomerChatTab,CustomerQuestionTab,CustomerManageTab },
  mixins: [parentMixin],
  data() {
    return {
      activeName:'CustomerChatTab',
      tabs: [
      { label: '客服对话', name: 'CustomerChatTab' },
        { label: '快捷问题设置', name: 'CustomerQuestionTab' },
        { label: '客服基础管理', name: 'CustomerManageTab' },
      ],
      optionData:{}
    }
  },
  methods: {
  
  }
}
</script>
