<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.memberName" :placeholder="$t('会员账号')" class="filter-item" style="width: 150px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" :loading="loading" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-dropdown
        v-if="permission.Sum || permission.Subtract"
        trigger="click"
        placement="bottom-start"
        class="filter-item"
        @command="handleCommand"
      >
        <el-button type="success">
          {{ $t('批量处理') }}<i class="el-icon-arrow-down el-icon--right" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-if="permission.Sum" :command="174">{{ $t('批量加款') }}</el-dropdown-item>
          <el-dropdown-item v-if="permission.Subtract" :command="5">{{ $t('批量扣款') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />
    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <AloneDialog ref="AloneDialogControl" @update="_handleUpdateAlone" />
    <BatchDialog ref="BatchDialogControl" @update="_handleUpdateBatch" />
    <UserDialog ref="UserDialogControl" />
  </div>
</template>
<script>
import { getMemberAccountList, updateAccountBalance } from '@/api/cash'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { AloneDialog, BatchDialog } from './components'
import UserDialog from '@/views/components/UserDialog'
import { $t } from '@/lang'

const getTransRemark = (transType) => {
  let remark = ''
  switch (transType) {
    case 4:
      remark = $t('人工加款')
      break
    case 5:
      remark = $t('人工扣款')
      break
    case 174:
      remark = $t('活动加款')
      break
    case 98:
      remark = $t('人工线下充值')
      break
    case 254:
      remark = $t('微信扫码')
    case 255:
      remark = $t('其他补款')
      break
  }

  return remark
}

export default {
  name: 'CashMemberExtra',
  components: { UserDialog, AloneDialog, BatchDialog },
  mixins: [TablePageMixin(getMemberAccountList, false)],
  data() {
    return {
      columns: [],
      defaultColumns: GetColumns.call(this),
      search: {
        memberName: ''
      }
    }
  },
  methods: {
    _handleUpdateAlone({ field, close, cancel }) {
      const remark = getTransRemark(field.transType)
      let params = {
        totalNum: 1,
        transDetail: remark,
        transType: field.transType,
        effecRolling: field.effecRolling,
        rollingRate: field.rollingRate,
        remark,
        operatorRemark: field.operatorRemark,
        list: [{ memberName: field.memberName, amount: field.transType === 5 ? Number('-' + field.amount) : Number(field.amount) }]
      }

      updateAccountBalance(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    _handleUpdateBatch({ field, cancel, close }) {
      const tmp = field.memberName.split(/[\n]/).filter(Boolean)
      const isValide = tmp.every(o => /^((\w{1,65})[\s|,]((\d|[1-9][0-9]*)+(.\d{1,20})?))$/.test(o))
      
      if (!isValide) {
        this.$message.error(this.$t('批量处理信息数据格式不正确'))
        cancel()
        return
      }

      const objTmp = tmp.map(o => {
        return o.split(/[\s|,]/g).filter(Boolean)
      })

      const memberNameList = objTmp.map(o => o[0])
      const DeduplicationList = Array.from(new Set(memberNameList))

      if (memberNameList.length !== DeduplicationList.length) {
        this.$message.error(this.$t('批量用户存在重复'))
        cancel()
        return
      }

      const remark = getTransRemark(field.transType)

      let params = {
        totalNum: objTmp.length,
        transDetail: remark,
        transType: field.transType,
        effecRolling: field.effecRolling,
        rollingRate: field.rollingRate,
        remark,
        operatorRemark: field.operatorRemark,
        list: objTmp.map(o => {
          const [memberName, amount] = o
          return {
            memberName,
            amount: field.transType === 5 ? parseFloat('-' + amount) : parseFloat(amount)
          }
        })
      }

      updateAccountBalance(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('批量操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleCommand(command) {
      this.$refs.BatchDialogControl.open(command)
    },
    _onDetails({ memberId }) {
      if (!this.permission.Details) return
      this.$refs.UserDialogControl.open(memberId)
    },

    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    handleExtra({ account, balance }, type) {
      this.$refs.ExtraDialogControl.open({ account, balance, type })
    }
  }
}
</script>
