import Layout from '@/layout'

const sportsRouter = {
  path: '/Sports',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Sports',
  alwaysShow: true,
  meta: { title: '体育管理', icon: 'sports-fill' },
  children: [
    {
      path: '/Sports_ExpertIndex',
      component: () => import('@/views/expert/index'),
      name: 'ExpertIndex',
      meta: { title: '专家管理', noCache: true }
    },
    {
      path: '/Sports_ExpertPlan',
      component: () => import('@/views/expert/plan'),
      name: 'ExpertPlan',
      meta: { title: '专家方案', noCache: true }
    },
    {
      path: '/Sports_Match',
      component: () => import('@/views/match/index'),
      name: 'MatchtIndex',
      meta: { title: '赛事管理', noCache: true }
    },
    {
      path: '/Sports_MatchIndex',
      component: () => import('@/views/lottery/match'),
      name: 'LotteryMatch',
      meta: { title: '竞彩管理', noCache: true }
    }
    // {
    //   path: '/Expert_Plan',
    //   component: () => import('@/views/expert/plan'),
    //   name: 'ExpertPlan',
    //   meta: { title: '专家方案', noCache: true }
    // }
  ]
}

export default sportsRouter
