<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-item">
        {{ $t("彩票系列") }}：
        <el-cascader
          v-model="search.lotteryIds"
          collapse-tags
          placeholder="请输入选择游戏系列查询"
          :options="cascaderOptions"
          :props="{ multiple: true }"
          filterable
        />
      </div>
      <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleRefresh">{{ $t("查 询")
      }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t("重置")
      }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <LiveEditDialog ref="LiveEditDialogControl" @update="_handleUpdate" />
  </div>
</template>

<script>
import { lotteryConfigGetPage, updateLotteryConfig } from '@/api/live'
import { getLotteryNavigationList } from '@/api/lottery'
import navigationMixin from '@/views/lottery/navigationMixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import LiveEditDialog from './LiveEditDialog.vue'
import GetColumns from './columns'
import { mapGetters } from 'vuex'
export default {
  name: 'LiveLotteryConfig',
  components: { LiveEditDialog },
  mixins: [TablePageMixin(lotteryConfigGetPage), navigationMixin(getLotteryNavigationList)],
  data() {
    return {
      search: {},
      columns: [],
      summary: null,
      cascaderOptions: [],
      defaultColumns: GetColumns.call(this)
    }
  },
  created() {
    this.onFetch()
  },
  computed: {
    ...mapGetters(['operatorType'])
  },
  methods: {
    implementationGetParams() {
      let lotteryIds = []
      if (this.search.lotteryIds && this.search.lotteryIds.length > 0) {
        this.search.lotteryIds.forEach(item => { // 获取数组中第二个 是彩种id
          lotteryIds.push(item[1])
        })
      }
      return { ...this.search, lotteryIds }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    implementationFetched() {

    },
    _handleUpdate({ field, cancel, close }) {
      if (field.officialStatus == 0) { // 官方直播开关
        field.officialVideoPath = null
      }
      if (field.explanationStatus == 0) { // 开奖说明
        field.explanationType = null
      }
      if (field.explanationType == 1) { // 说明类型
        field.lotteryType = null
      }
      if (field.lotteryType != 10) {
        field.officialName = null
        field.officialPath = null
      }

      updateLotteryConfig({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('编辑成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    }
  }
}
</script>
