var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("变赔"),
        width: "1050px",
        top: "5vh",
        visible: _vm.visible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.onClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: { model: _vm.ruleFrom },
        },
        [
          _c(
            "div",
            { staticClass: "change-odds" },
            [
              _c(
                "div",
                { staticClass: "change-odds-item change-odds-header" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("类型")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("属性")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("变赔")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("属性")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("变赔")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("属性")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("变赔")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("属性")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("变赔")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("属性")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("变赔")))]),
                ]
              ),
              _vm._l(_vm.ruleFrom, function (item, name) {
                return _c(
                  "div",
                  { key: name, staticClass: "change-odds-item" },
                  [
                    _c(
                      "label",
                      { staticClass: "change-odds-item__label" },
                      [
                        _vm._v(_vm._s(item.label) + " "),
                        name === "rqChange" && _vm.rq
                          ? [_vm._v("(" + _vm._s(_vm.rq) + ")")]
                          : _vm._e(),
                        name === "bsChange" && _vm.daxiaoqiu.name
                          ? [_vm._v("(" + _vm._s(_vm.daxiaoqiu.name) + ")")]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "change-odds-item__content" },
                      _vm._l(item.children, function (child, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "item" },
                          [
                            child
                              ? [
                                  _c("div", { staticClass: "label" }, [
                                    _c("p", { staticClass: "player" }, [
                                      _vm._v(_vm._s(child.label)),
                                    ]),
                                    _c("span", { staticClass: "odd" }, [
                                      _vm._v(_vm._s(child.origin)),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "input" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              name +
                                              ".children." +
                                              index +
                                              ".value",
                                            "show-message": false,
                                            rules: {
                                              pattern:
                                                /^([\-]?(\d*))([.]\d{0,2})?$/,
                                              message:
                                                child.label +
                                                " " +
                                                _vm.$t("玩法平赔率 格式不合法"),
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: child.disabled },
                                            model: {
                                              value: child.value,
                                              callback: function ($$v) {
                                                _vm.$set(child, "value", $$v)
                                              },
                                              expression: "child.value",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c("div", { staticClass: "label" }),
                                  _c("div", { staticClass: "input" }),
                                ],
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确定")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }