import SocketEmmiterMixin from '@/mixins/im-socket-emmiter-mixin'
import protobuf from 'protobufjs'
import chatProto from './chatProto.json'

export default () => ({
  mixins: [SocketEmmiterMixin()],
  data() {
    return {
      isConnecting: false,
      count: null,
      items: [],
      textDecoder: new TextDecoder(),
      textEncoder: new TextEncoder()
    }
  },
  methods: {
    pingMessage() {
      const root = protobuf.Root.fromJSON(chatProto)
      const proto = root.lookupType('Proto')
      const token = `{"mid":"${this.operatorId}", "room_id":"${this.roomClientType}://${this.activeRoomId}", "platform":"web", "accepts":[1000,1001,1002]}`
      const bodyBuf = this.textEncoder.encode(token)
      const message = proto.create({
        ver: 1,
        op: 2,
        seq: new Date().getTime(),
        body: bodyBuf
      })
      return proto.encode(message).finish()
    },
    formatMessage(data) {
      if (data) {
        const root = protobuf.Root.fromJSON(chatProto)
        const proto = root.lookupType('Proto')
        let message = proto.decode(new Uint8Array(data))
        let messageItem = {}
        switch (message.op) {
          case 8:
            // auth reply ok
            // send a heartbeat to server
            this.startHeartCheck()
            break
          case 3:
            // receive a heartbeat from server
            break
          case 9:
            messageItem = JSON.parse(this.textDecoder.decode(message.body))
            if (messageItem.msgType === 204) {
              this.items = this.items.filter(p => p.msgId !== messageItem.message_id)
              this.itemSource = this.itemSource.filter(p => p.msgId !== messageItem.message_id)
            } else if (messageItem.uid !== this.operatorId) {
              this.items.unshift(messageItem)
              this.itemSource.unshift(messageItem)
            } else {
              const itemIndex = this.items.findIndex(p => p.msgTempId === messageItem.msgTempId)
              if (itemIndex > -1) {
                this.items[itemIndex].msgId = messageItem.msgId
                delete this.items[itemIndex].msgTempId
              }

              const index = this.itemSource.findIndex(p => p.msgTempId === messageItem.msgTempId)
              if (index > -1) {
                this.items[index].msgId = messageItem.msgId
                delete this.items[index].msgTempId
              }
            }
            this.$nextTick(() => {
              this._setScrollTop()
            })

            break
          default:
            var msgBody = this.textDecoder.decode(data.body)
            break
        }
      }
    },
    // _setUsersCount(count) {
    //   this.count = count
    // },
    // _messageBroadcast({ clientMsgId, content, contentType, serverMsgId, from: { passToken: { userId, userName, userType, tenantCode }}}) {
    //   this.items.unshift({ clientMsgId, content, contentType, serverMsgId, userId, userName, userType, tenantCode })
    //   this.$nextTick(() => {
    //     this._setScrollTop()
    //   })
    // },

    // _messageCancel({ serverMsgId }) {
    //   const index = this.items.findIndex(o => o.serverMsgId === serverMsgId)
    //   if (index > -1) this.items.splice(index, 1)
    // },

    // _messageBroadcastOk({ serverMsgId }) {
    //   if (this.beforeMessage) {
    //     this.items.unshift({ ...this.beforeMessage, serverMsgId })
    //     this.beforeMessage = null
    //     this.$nextTick(() => {
    //       this._setScrollTop()
    //     })
    //   }
    // },
    _setScrollTop() {
      const scoller = this.$refs.scroller
      scoller.scrollTop = scoller.scrollHeight
    }
  }
})
