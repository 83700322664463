<template>
  <div>
    <div v-if="operatorType === 1" class="filter-container">
      <el-select
        v-model="search.tenantCode"
        :placeholder="$t('请选择租户')"
        @change="handleRefresh"
      >
        <el-option
          v-for="item in tenants"
          :key="item.tenantCode"
          :value="item.tenantCode"
          :label="item.tenantName"
        />
      </el-select>
    </div>
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-input v-model="search.memberName" clearable :placeholder="$t('账号')" class="filter-item" style="width: 197px" />
      <el-select v-model="search.vipLevel" clearable :placeholder="$t('VIP等级')" class="filter-item" style="width: 197px">
        <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
      </el-select>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template #left>
      <el-input v-model="search.device" clearable :placeholder="$t('登录设备')" class="filter-item" style="width: 197px" />
        <el-input v-model="search.loginIp" clearable :placeholder="$t('IP地址')" class="filter-item" style="width: 197px" />
        <el-input v-model="search.address" clearable :placeholder="$t('登录地区')" class="filter-item" style="width: 197px" />
        <el-input v-model="search.domainOrVersion" clearable :placeholder="$t('登录域名/版本号')" class="filter-item" style="width: 197px" />
        <el-button type="primary" icon="el-icon-search" class="filter-item" :disabled="operatorType === 1 && !search.tenantCode" @click="handleSearch">{{ $t('查询') }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { getAppLog } from '@/api/permission'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getAllVipLevels } from '@/api/app'
import GetColumns from './columns'

export default {
  name: 'User',
  componentName: 'User',
  mixins: [TablePageMixin(getAppLog, false)],
  data() {
    return {
      times: null,
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
      vipLevels: []
    }
  },
  computed: {
    ...mapState('app', ['tenants']),
    ...mapGetters(['operatorType'])
  },
  created() {
    if (this.operatorType === 1) {
      this.getTenantList()
    } else {
      this.implementationTenantChanged()
      this.handleRefresh()
    }
  },
  methods: {
    ...mapActions('app', ['getTenantList']),
    implementationTenantChanged() {
      getAllVipLevels().then(([data, err]) => {
        if (!err) {
          this.vipLevels = data
        }
      })
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
