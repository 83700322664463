import { requiredBlur, requiredChange, requirePercent0_100, requireIntegerNormal, integerNormal, integer1_9999, requireUrl } from '@/utils/validation.js'

export const getRuleForm = () => ({
  ipRegistNum: null,
  ipLoginNum: null,
  passwordWrongNum: null,
  googleStatus: 0,
  isVerifi: 0,
  newCheckDeviceSwitch: 0,
  cancelOrderStatus: 0,
  betAmount: null,
  oneLevelRakeBack: '',
  twoLevelRakeBack: '',
  settlementType: 1,
  regins: '',
  maintainStatus: 0,
  maintainInfo: '',
  startTime: '',
  endTime: '',
  withdrawMinAmount: '',
  withdrawMaxAmount: '',
  rechargeInterval: null,
  withdrawInterval: null,
  withdrawaDailyNum: null,
  recordWithdrawStatus: 0,
  rechargeInvalidTime: '',
  withdrawInvalidTime: '',
  bankWithdrawForbid: null,
  bankBindNum: null,
  anchorContactAmount: '',
  broadcastGiftAmount: '',
  freeTime: null,
  eurocupStatus: 0,
  favoriteDomain: '',
  chatApiDomain: '',
  chatSocketDomain: '',
  liveDrawingDomain: ''
})

const rules = function() {
  var validateMinMoney = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('此项必填'))
    } else {
      if (!integerNormal.test(value)) {
        callback(new Error('请输入0~9,999,999整数'))
      } else {
        if (this.ruleForm.withdrawMaxAmount !== '' && value - this.ruleForm.withdrawMaxAmount >= 0) {
          callback(new Error('提款最低额度必须小于最大额度'))
        }
      }
      callback()
    }
  }
  var validateMaxMoney = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('此项必填'))
    } else {
      if (!integerNormal.test(value)) {
        callback(new Error('请输入0~9,999,999整数'))
      } else {
        if (this.ruleForm.withdrawMinAmount !== '' && +this.ruleForm.withdrawMinAmount - value >= 0) {
          callback(new Error('提款最大额度必须大于最小额度'))
        }
      }
      callback()
    }
  }
  return {
    ipRegistNum: requireIntegerNormal,
    passwordWrongNum: requireIntegerNormal,
    ipLoginNum: requireIntegerNormal,
    googleStatus: requiredChange,
    isVerifi: requiredChange,
    newCheckDeviceSwitch: requiredChange,
    cancelOrderStatus: requiredChange,
    betAmount: requireIntegerNormal,
    oneLevelRakeBack: requirePercent0_100,
    twoLevelRakeBack: requirePercent0_100,
    settlementType: requiredChange,
    lotteryBackCalculateDay: requiredChange,
    lotteryBackCalculateTime: requiredChange,
    regins: requiredBlur,
    maintainInfo: requiredBlur,
    startTime: requiredChange,
    endTime: requiredChange,
    withdrawMinAmount: [{ validator: validateMinMoney, trigger: 'blur' }],
    withdrawMaxAmount: [{ validator: validateMaxMoney, trigger: 'blur' }],
    rechargeInterval: requireIntegerNormal,
    withdrawInterval: requireIntegerNormal,
    recordWithdrawStatus: requiredChange,
    withdrawaDailyNum: requireIntegerNormal,
    rechargeInvalidTime: requireIntegerNormal,
    withdrawInvalidTime: requireIntegerNormal,
    bankWithdrawForbid: requireIntegerNormal,
    bankBindNum: requireIntegerNormal,
    anchorContactAmount: [
      { required: true, message: '请输入', trigger: 'blur' },
      { pattern: integer1_9999, message: '1-9999', trigger: 'blur' }
    ],
    broadcastGiftAmount: [
      { required: true, message: '请输入', trigger: 'blur' },
      { pattern: integer1_9999, message: '1-9999', trigger: 'blur' }
    ],
    freeTime: [
      ...requiredBlur,
      { pattern: /^([5-9]|[1-9]\d|[1-2]\d{2}|300)$/, message: '最大不得超过300', trigger: 'blur' }
    ],
    eurocupStatus: requiredChange,
    favoriteDomain: requireUrl,
    chatApiDomain: requireUrl,
    chatSocketDomain: requiredBlur,
    liveDrawingDomain: requireUrl
  }
}

export const getRules = function() {
  return rules.call(this)
}
