<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="search.sendType" clearable placeholder="请选择发送方式" class="filter-item" style="width: 160px">
        <el-option v-for="(item, key) in sendTypeOptions" :key="key" :label="item" :value="key" />
      </el-select>
      <el-input v-model.trim="search.title" maxlength="10" clearable placeholder="请输入标题" class="filter-item" style="width: 200px" />
      <el-input v-model.trim="search.tenantCode" maxlength="10" clearable placeholder="请输入租户简称" class="filter-item" style="width: 200px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleRefresh">查询</el-button>
      <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onAction()">发送平台公告</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" @update="_handleUpdate" />
  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { getNoticeList, createNotice, updateNotice, deleteNotice } from '@/api/tenant'

const sendTypeOptions = { 1: '指定租户发送', 2: '全部租户发送' }

export default {
  name: 'TenantNotice',
  components: { ActionDialog },
  mixins: [TablePageMixin(getNoticeList)],
  data() {
    return {
      filterColumn: false,
      columns: GetColumns.call(this),
      search: {},
      sendTypeOptions
    }
  },
  methods: {
    onAction(row) {
      this.$refs.ActionDialogControl.open(row)
    },
    _handleCreate({ field, close, cancel }) {
      createNotice({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success('添加成功!')
        } else {
          cancel()
        }
      })
    },
    _handleUpdate({ field, close, cancel }) {
      updateNotice({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success('编辑成功!')
        } else {
          cancel()
        }
      })
    },
    _handleDelete({ id, title }) {
      this.$confirm(`确定删除 ${title} 这条公告？`, { type: 'warning' }).then(() => {
        deleteNotice({ id }).then(([_, err]) => {
          if (!err) {
            this.handleFetch()
            this.$message.success('删除成功!')
          }
        })
      })
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
