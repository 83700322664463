const CanChooseColumns = function() {
  return [
    {
      title: '排序',
      key: 'sort',
      align: 'center'
    },
    {
      title: this.$t('商品封面'),
      key: 'cover',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image style='width: 100px; height: 100px' src={ row.cover } lazy></el-image>)
        }
      }
    },
    {
      title: this.$t('商品名称'),
      key: 'name',
      align: 'center',
      width: 280,
    },
    {
      title: this.$t('所属类目'),
      key: 'categoryName',
      align: 'center',
      width: 280,
      component: {
        render: (h, row) => {
          const categoryName = row.categoryName.join('，');
          return (<span>{ categoryName }</span>)
        }
      }
    },
    {
      title: this.$t('商品标题'),
      key: 'title',
      align: 'center',
      width: 350,
    },
    {
      title: this.$t('商品价格'),
      key: 'price',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.price }</span>)
        }
      }
    },
    {
      title: this.$t('商品可用库存'),
      key: 'stock',
      align: 'center',
      component: {
        render: (h, row) => {
          const stock = row.stock
          return (<span>{ stock === -1 ? '无限' : stock }</span>)
        }
      }
    },
    {
      title: this.$t('商品状态'),
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          const bool = row.status === 0
          return (<span style={{ color: bool ? '#34C758' : '' }}>{ bool ? '上架' : '下架' }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'operate',
      align: 'center',
      width: 270,
      component: {
        render: (h, row) => {
          const { Update, Delete, Close } = this.permission
          return (
            /*<div>
              { Close && row.status !== 1 &&
                <el-button type='danger' size='small' icon='el-icon-close' onClick={this.handleOnOrOff.bind(this, row, 0)}>{ this.$t('下架') }</el-button> ||
                <el-button type='success' size='small' icon='el-icon-check' onClick={this.handleOnOrOff.bind(this, row, 1)}>{ this.$t('上架') }</el-button>
              }
              { Update && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.goodsDialog.open(row) } }>{ this.$t('修改') }</el-button> }
              { Delete && <el-button type='danger' size='small' icon='el-icon-delete' onClick={() => this.handleDelete(row)}>{ this.$t('删除') }</el-button> }
            </div>*/
            <div>
              { row.status === 0 &&
                <el-button type='danger' size='small' icon='el-icon-close' onClick={this.handleOnOrOff.bind(this, row, 1)}>{ this.$t('下架') }</el-button> ||
                <el-button type='success' size='small' icon='el-icon-check' onClick={this.handleOnOrOff.bind(this, row, 0)}>{ this.$t('上架') }</el-button>
              }
              { <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.goodsDialog.open(row) } }>{ this.$t('修改') }</el-button> }
              { <el-button type='danger' size='small' icon='el-icon-delete' onClick={() => this.handleDelete(row)}>{ this.$t('删除') }</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
