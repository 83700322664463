<template>
  <div class="date-filter">
    <el-button
      v-for="button in buttons"
      :key="button.key"
      :type="button.key === activeValue && 'primary' || 'default'"
      @click="_click(button.key)"
    >{{ button.label }}</el-button>
    <i v-if="refresh" class="el-icon-refresh-right cursor-pointer" :class="[ loading && `el-icon-refresh-loading` ]" @click="$emit('refresh')" />
  </div>
</template>
<script>
import { $t } from '@/lang'

const BUTTONS = $t('dateFilterButtons')

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    refresh: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Array,
      default: () => {
        return BUTTONS
      }
    }
  },
  data() {
    return {
      activeValue: this.value
    }
  },
  methods: {
    _click(value) {
      this.activeValue = value
      this.$emit('input', value)
    }
  }
}
</script>
<style lang="scss" scoped>
.date-filter {
  display: inline-flex;
  align-items: center;
  i {
    margin-left: 10px;
    font-size: 20px;
  }
}
</style>
