<template>
  <el-dialog
    :title="this.$t('平台公告')"
    :visible.sync="notice.visible"
    append-to-body
    top="5vh"
    custom-class="custom-dialog"
    :close-on-click-modal="false"
    width="800px"
    @open="onOpen"
  >
    <div class="notice-dialog">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <div v-for="item in items" :key="item.id" class="title-item" :class="[activeItem && activeItem.id === item.id && 'active']" @click="handleClick(item)">
          <svg-icon icon-class="message" />
          {{ item.title }}
        </div>
      </el-scrollbar>
      <div v-if="activeItem" class="notice-content">
        <p>{{ activeItem.title }}<span>{{ activeItem.createTime | parseTime }}</span></p>
        <div v-html="activeItem.content" />
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { getNoticeList, updateNoticeRead } from '@/api/app'

export default {
  data() {
    return {
      items: [],
      activeItem: null
    }
  },
  computed: {
    ...mapGetters(['notice'])
  },
  methods: {
    onOpen() {
      getNoticeList({ currentPage: 1, pageSize: 100 }).then(([data, err]) => {
        if (!err) {
          this.items = data
          this.activeItem = data[0] || null
          if (this.activeItem && !this.activeItem.read) this._updateRead(this.activeItem.id)
        }
      })
    },
    _updateRead(id) {
      updateNoticeRead(id).then(([data, err]) => {
        if (!err) {
          this.items.forEach(item => {
            if (item.id === id) {
              item.read = true
            }
          })
        }
      })
    },
    handleClick(item) {
      this.activeItem = item
      if (!item.read) {
        this._updateRead(item.id)
      }

      if (this.getNoticeAllRead()) {
        this.$store.dispatch('app/toggleNoticeHot')
      }
    },
    getNoticeAllRead() {
      return this.items.every(o => !!o.read)
    }
  }
}
</script>

<style lang="scss">

.notice-dialog {
  .notice-content img {
    width: 100%;
  }
}

</style>

<style lang="scss" scoped>
.notice-dialog {
  display: flex;
  min-height: 350px;
  border-top: 1px solid #ddd;
  .el-scrollbar {
    width: 200px;
    border-right: 1px solid #ddd;
    &__wrap {
      overflow-x: hidden;
    }
    .title-item {
      border-bottom: 1px solid #ddd;
      line-height: 50px;
      padding-left: 12px;
      cursor: pointer;
      &.active {
        color: #fff;
        background-color: cornflowerblue;
      }
    }
  }
  .notice-content {
    flex: 1;
    padding: 20px;
    & > p {
      text-align: center;
      font-size: 18px;
      position: relative;
      line-height: 2;
      & > span {
        position: absolute;
        font-size: 12px;
        right: 0;
        bottom: 0;
      }
    }
  }
}
</style>
