const CanChooseColumns = function () {
  return [
    {
      title: "标题",
      key: "title",
      align: "center",
      width: 100,
      component: {
        render: (h, row) => {
          return <span>{row.title}</span>;
        },
      },
    },
    {
      title: "回复内容",
      key: "replyContent",
      align: "center",
      component: {
        render: (h, row) => {
          return <span domPropsInnerHTML={row.replyContent}></span>;
        },
      },
    },
    {
      title: "排序",
      key: "sort",
      align: "center",
      width: 160,
    },
    {
      title: "创建时间",
      key: "expiredTime",
      align: "center",
      width: 160,
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.createTime)}</span>;
        },
      },
    },
    {
      title: "操作人",
      key: "updateUser",
      align: "center",
      width: 100,
    },
    {
      title: "操作",
      align: "center",
      key: "actions",
      width: 250,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission;
          return (
            <div>
              {Update && (
                <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-edit"
                  onClick={() => {
                    this.$refs.EditDialogControl.open(row);
                  }}
                >
                  {this.$t("编辑")}
                </el-button>
              )}
              {Delete && (
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  onClick={() => this.handleDelete(row)}
                >
                  {this.$t("删除")}
                </el-button>
              )}
            </div>
          );
        },
      },
    },
  ];
};

export default function () {
  return CanChooseColumns.call(this);
}
