import Layout from '@/layout'

const manageRouter = {
  path: '/Statistical',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Statistical',
  alwaysShow: true,
  meta: { title: '统计数据', icon: 'statistical' },
  children: [
    {
      path: '/Statistical_Risk',
      component: () => import('@/views/statistical/Risk'),
      name: 'RiskStatistical',
      meta: { title: '投注风控' }
    },
    {
      path: '/Statistical_CashThirdTotal',
      component: () => import('@/views/statistical/Cash_Third_Total'),
      name: 'StatisticalCashThirdTotal',
      meta: { title: '三方充值统计' }
    },
    {
      path: '/Statistical_Account',
      component: () => import('@/views/statistical/Account'),
      name: 'AccountStatistical',
      meta: { title: '出入账统计', noCache: true }
    },
    {
      path: '/Statistical_Register',
      component: () => import('@/views/statistical/Register'),
      name: 'RegisterStatistical',
      meta: { title: '注册统计', noCache: true }
    },
    // {
    //   path: '/Statistical_People',
    //   component: () => import('@/views/statistical/People'),
    //   name: 'PeopleStatistical',
    //   meta: { title: '人数统计', noCache: true }
    // },
    {
      path: '/Statistical_Channel',
      component: () => import('@/views/statistical/Channel'),
      name: 'Channel',
      meta: { title: '渠道统计', noCache: true }
    },
    {
      path: '/Statistical_Channel_Mannge',
      component: () => import('@/views/statistical/Channel/manage'),
      name: 'ChannelStatisticalMannge',
      meta: { title: '渠道管理', noCache: true },
      hidden: true
    },
    {
      path: '/Statistical_Game',
      component: () => import('@/views/statistical/Game'),
      name: 'StatisticalGame',
      meta: { title: '游戏统计', noCache: true }
    }
  ]
}

export default manageRouter
