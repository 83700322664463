var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, width: "700px", visible: _vm.visible },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            open: _vm.onOpen,
            closed: _vm.onClosed,
          },
        },
        [
          _vm.params
            ? _c("lottery-before", {
                ref: "lotteryBeforehand",
                attrs: {
                  "series-tag": _vm.params.seriesTag,
                  "show-result-compared-list": _vm.showResultComparedList,
                  "lottery-id": _vm.params.lotteryId,
                  result: _vm.result,
                  isManualMode: _vm.isManualMode,
                  issue: _vm.params.issue,
                },
                on: { handleCompared: _vm.getShowResultCompared },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [
                _vm._v(_vm._s(_vm.$t("取消"))),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v(_vm._s(_vm.$t("确认结算")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确定进行" + _vm.title + "结算吗？",
            visible: _vm.dialogVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.lotteryNo
            ? _c(
                "div",
                { staticClass: "result" },
                [
                  _c(
                    "div",
                    [
                      _vm._v(" 开奖结果："),
                      _vm._l(_vm.lotteryNo.split(","), function (item, index) {
                        return _c("span", { key: index }, [
                          _vm._v(_vm._s(item)),
                          index < _vm.lotteryNo.split(",").length - 1
                            ? _c("span", [_vm._v(",")])
                            : _vm._e(),
                        ])
                      }),
                    ],
                    2
                  ),
                  _vm.showResultComparedList.every(function (e) {
                    return e.lotteryNo === _vm.lotteryNo
                  })
                    ? _c("div", { staticClass: "info" }, [
                        _vm._v(" 开奖信息与其他平台开奖结果对比一致 "),
                      ])
                    : _vm._l(
                        _vm.showResultComparedList,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _vm._v(" " + _vm._s(item.sourceName) + "："),
                              _vm._l(
                                item.lotteryNo.split(","),
                                function (item2, index2) {
                                  return _c(
                                    "span",
                                    {
                                      key: index2,
                                      class: {
                                        red:
                                          item2 !==
                                          _vm.lotteryNo.split(",")[index2],
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item2)),
                                      index2 <
                                      item.lotteryNo.split(",").length - 1
                                        ? _c("span", [_vm._v(",")])
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        }
                      ),
                  _c("div", { staticClass: "tips" }, [
                    _vm._v("tips：确认开奖结算后，将会按照开奖结果进行派奖。"),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.submitting, type: "primary" },
                  on: { click: _vm.handleDialogSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }