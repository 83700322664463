<template>
  <div v-loading="loading" class="tenant-lottery">
    <div v-for="item in items" :key="item.seriesTag" class="series-item">
      <div class="series-item__name">
        {{ item.seriesName }}
        <el-radio-group v-model="item.status"  v-if="item.seriesTag !== '000'">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="3">停用</el-radio>
          <el-radio :label="0">关闭隐藏</el-radio>
        </el-radio-group>
      </div>
      <div class="series-item__body">
        <el-row
          v-for="child in item.list"
          :key="child.lotteryId"
          class="series-item__list"
        >
          <el-col :span="10" class="series-item--label">
            <label>{{ child.name }}</label>
          </el-col>
          <el-col :span="14" class="series-item--status">
            <el-radio-group v-model="child.status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="item.seriesTag !== '000'?0:3">{{ item.seriesTag !== '000'?'关闭':'停用' }}</el-radio>
          <el-radio :label="0" v-if="item.seriesTag == '000'">关闭隐藏</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
// 非三方游戏
// 系列状态：1，开启 3.关闭【状态维护】
// 彩种状态：1.开启 ，0，关闭

// 三方游戏 （没有总的三方游戏系列开关，三方游戏即系列）
// 游戏状态“：1，开启 3.关闭【状态维护】

//1开启 3 停用 0 2 关闭隐藏
export default {
  name: 'Lottery',
  data: () => ({
    loading: false,
    items: []
  }),
  methods: {
    fetch(server, tenantCode) {
      this.loading = true
      server({ tenantCode }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          let items = []
          const { lotteryList, gameList } = data
          lotteryList.forEach((o) => {
            // o.status=o.status==1?1:3
            o.status=o.status==2?0:o.status
            o.list.forEach(it=>{
              // it.status=it.status==1?1:0
              it.status=it.status==2?0:it.status
            })
            items.push(o)
          })

          gameList.length &&
            items.push({
              seriesName: '第三方游戏',
              seriesTag: '000',
              list: gameList.map((o) => ({
                lotteryId: o.seriesTag,
                name: o.seriesName,
                // status: o.status==1?1:3
                status: o.status
              }))
            })
          this.items = items
        }
      })
    },
    onValidate(cb) {
      let field = {
        lotteryIds: [],
        seriesTag: [],
        lotteryStatusRequests: [],
        seriesStatusRequests: []
      }
      this.items.forEach((o) => {
        if (o.seriesTag === '000') {
          const seriesStatus = o.list.map(({lotteryId,status}) => ({seriesTag:lotteryId,status}))
          field.seriesStatusRequests.push(...seriesStatus)
          field.seriesTag = field.seriesStatusRequests
          field.seriesTag = JSON.parse(JSON.stringify(field.seriesTag)).map(a => a.seriesTag)
        } else {
          const lotteryStatus = o.list.map(({lotteryId,status}) => ({lotteryId,status}))
          field.seriesStatusRequests.push({seriesTag:o.seriesTag,status:o.status})
          field.lotteryStatusRequests = field.lotteryStatusRequests.concat(lotteryStatus)
          field.lotteryIds = JSON.parse(JSON.stringify(field.lotteryStatusRequests)).map(a => a.lotteryId)
        }
      })
      return new Promise((resolve) => {
        // console.log(field)
        cb && cb(field)
        resolve(field)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.series-item {
  &__name {
    line-height: 36px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 16px;
  }
  &__body {
    border-top: 1px solid #dfe4ed;
    border-bottom: 0;
    border-left: 0;
    overflow: hidden;
  }
  &__list {
    width: 50%;
    float: left;
    border-bottom: 1px solid #dfe4ed;
    border-left: 1px solid #dfe4ed;
    border-right: 1px solid #dfe4ed;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    &:nth-child(2n) {
      border-left: 0;
    }
  }
  &--label {
    text-align: right;
    padding-right: 10px;
  }
  &--status {
    padding-left: 10px;
    border-left: 1px solid #dfe4ed;
  }
}
</style>
