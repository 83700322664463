<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive>
        <component :is="activeName" />
      </keep-alive>
    </template>

    <UserDialog ref="UserDialogControl" />

  </div>
</template>
<script>
import { getAllVipLevels } from '@/api/app'
import UserDialog from '@/views/components/UserDialog'
import ReceiveTab from './withdrawal-collect'
import CheckTab from './withdrawal-check'
import ListTab from './withdrawal-list'
import { parentMixin } from '@/mixins/tab-items'

export default {
  name: 'CashWithdrawal',
  components: {
    UserDialog,
    ReceiveTab,
    CheckTab,
    ListTab
  },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      vipLevels: [],
      tabs: this.$t('withdrawalTabs')
    }
  },
  created() {
    this.implementationParentTenantChanged()
  },

  methods: {
    implementationParentTenantChanged(tenantCode) {
      getAllVipLevels(tenantCode && [tenantCode] || {}).then(([data, err]) => {
        if (!err) {
          this.vipLevels = data
        }
      })
    }
  }
}
</script>
