<template>
  <div>
    <el-card shadow="never" :body-style="{ padding: 0, margin: '-1px' }">
      <template slot="header">
        <span>{{ $t('第三方游戏统计') }}</span>
        <date-filter :value="params.timeType" :loading="loading" @input="_input" @refresh="_get" />
      </template>

      <el-table v-loading="loading" :data="items" size="medium" border>
        <el-table-column prop="name" :label="$t('游戏平台')" align="center">
          <template slot-scope="scope">
            {{ seriesPlayTypes[scope.row.seriesPlayType] }}
          </template>
        </el-table-column>
        <el-table-column prop="betAmount" :label="$t('投注')" align="center" />
        <el-table-column prop="winAmount" :label="$t('派奖')" align="center" />
        <el-table-column prop="profitAmount" :label="$t('盈亏')" align="center" />
      </el-table>
    </el-card>
  </div>
</template>
<script>
import DateFilter from '../../components/DateFilter'
import { tenantThirdReportList } from '@/api/dashboard'
import { seriesPlayTypes } from '@/libs/options'
export default {
  components: { DateFilter },
  data() {
    return {
      loading: false,
      items: [],
      params: {
        timeType: 'today'
      },
      seriesPlayTypes
    }
  },
  created() {
    this._get()
  },
  methods: {
    _get() {
      this.loading = true
      tenantThirdReportList({ timeType: this.params.timeType }).then(([data, err, response]) => {
        this.loading = false
        if (!err) {
          this.items = data || []
          this.implementationFetched(response.data)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    _input(value) {
      this.params.timeType = value
      this._get()
    }
  }
}
</script>
