<template>
  <el-dialog :title="$t('新增账号')" :visible.sync="visible" status-icon width="500px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('账号')" prop="operatorName" required>
        <el-input v-model="ruleForm.operatorName" />
      </el-form-item>
      <el-form-item :label="$t('昵称')" prop="nickName" required>
        <el-input v-model="ruleForm.nickName" />
      </el-form-item>
      <el-form-item :label="$t('用户分组')" prop="groupId">
        <el-select v-model="ruleForm.groupId" style="width: 100%">
          <el-option v-for="item in groupList" :key="item.id" :label="item.groupName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('密码')" prop="password" required>
        <el-input v-model="ruleForm.password" type="password" />
      </el-form-item>
      <el-form-item :label="$t('确认密码')" prop="checkPassword" required>
        <el-input v-model="ruleForm.checkPassword" type="password" />
      </el-form-item>
      <el-form-item :label="$t('账号状态')" required>
        <el-radio-group v-model="ruleForm.status">
          <el-radio :label="0">{{ $t('正常') }}</el-radio>
          <el-radio :label="1">{{ $t('冻结') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="谷歌验证码" prop="googleCode">
        <el-input v-model.trim="ruleForm.googleCode" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { validAccountAndPassword, validNickName } from '@/utils/validate'
import { checkOperatorName, checkNickName } from '@/api/permission'
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  operatorName: '',
  nickName: '',
  groupId: null,
  password: '',
  checkPassword: '',
  status: 0,
  googleCode: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validCheckAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入账号')))
      } else if (!validAccountAndPassword(value)) {
        callback(new Error(this.$t('请输入6~12位英文数字账号')))
      } else {
        this.onValidOperatorName(value).then(error => {
          if (error) {
            callback(new Error(error))
          } else {
            callback()
          }
        })
      }
    }

    const validCheckName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入昵称')))
      } else if (!validNickName(value)) {
        callback(new Error(this.$t('请输入2~12位')))
      } else {
        this.onValidNickName(value).then(error => {
          if (error) {
            callback(new Error(error))
          } else {
            callback()
          }
        })
      }
    }

    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入密码')))
      } else {
        if (this.ruleForm.checkPassword !== '') {
          this.$refs.validateForm.validateField('checkPassword')
        }
        callback()
      }
    }

    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请再次输入密码')))
      } else if (value !== this.ruleForm.password) {
        callback(new Error(this.$t('两次输入密码不一致')))
      } else {
        callback()
      }
    }
    return {
      emitName: 'create',
      rules: {
        operatorName: [{ validator: validCheckAccount, trigger: 'blur' }],
        nickName: [{ validator: validCheckName, trigger: 'blur' }],
        groupId: [{ required: true, message: this.$t('请选择用户分组'), trigger: 'change' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        checkPassword: [{ validator: validateCheckPass, trigger: 'blur' }],
        googleCode: [
          { required: true, message: '请输入谷歌验证码', trigger: 'blur' },
          { pattern: /^[0-9]{6}$/, message: '请输入6位谷歌验证码', trigger: ['change', 'blur'] }
        ]
      }
    }
  },
  computed: {
    groupList() {
      return this.$parent.groupList
    }
  },
  methods: {
    onValidOperatorName(operatorName) {
      return new Promise((resolve) => {
        checkOperatorName({ operatorName }).then(([, err]) => {
          if (err) {
            resolve(err.message)
          }
          resolve()
        })
      })
    },
    onValidNickName(nickName) {
      return new Promise((resolve) => {
        checkNickName({ nickName }).then(([, err]) => {
          if (err) {
            resolve(err.message)
          }
          resolve()
        })
      })
    }
  }
}
</script>
