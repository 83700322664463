var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("cascader-select", {
        attrs: { options: _vm.cascaderOptions },
        on: { change: _vm.onChange },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm.lotteryInfo
        ? _c("div", { staticClass: "draw-messsge" }, [
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("span", { staticClass: "filter-item" }, [
                  _vm._v(_vm._s(_vm.$t("开奖方式")) + "："),
                ]),
                _vm.permission.Update
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          plain: "",
                          icon: "el-icon-setting",
                        },
                        on: { click: _vm.onUpdate },
                      },
                      [_vm._v(_vm._s(_vm.$t("修改")))]
                    )
                  : _vm._e(),
                _vm.permission.Revoke
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          plain: "",
                          icon: "el-icon-refresh-left",
                        },
                        on: { click: _vm.onRevokeRecord },
                      },
                      [_vm._v(_vm._s(_vm.$t("撤销开奖记录")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.lotteryInfo.issue) +
                    _vm._s(_vm.$t("期")) +
                    " " +
                    _vm._s(_vm.$t("开奖倒计时")) +
                    " "
                ),
                _vm.lotteryInfo.lotteryTime
                  ? _c("d-count-down", {
                      attrs: {
                        time: +_vm.lotteryInfo.lotteryTime,
                        systemTime: +_vm.lotteryInfo.systemTime,
                      },
                      on: { finish: _vm.finish },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (props) {
                              return [
                                _vm._v(
                                  _vm._s(props.hours) +
                                    ":" +
                                    _vm._s(props.minutes) +
                                    ":" +
                                    _vm._s(props.seconds)
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2938114484
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "el-table",
        _vm._b(
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { data: _vm.items },
          },
          "el-table",
          _vm.options,
          false
        ),
        [
          _c("el-table-column", {
            attrs: {
              prop: "issue",
              label: _vm.$t("期号"),
              width: "120",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "result",
              label: _vm.$t("开奖号码"),
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.lotteryStatus === 1
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("待开奖")))])
                      : _vm._e(),
                    row.lotteryStatus !== 1 && row.result
                      ? _c("lottery-number", {
                          attrs: {
                            "series-tag": _vm.seriesTag,
                            "lottery-id": row.lotteryId,
                            result: row.result,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "result",
              label: _vm.$t("预开奖号码"),
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.manualLotteryNo
                      ? _c("lottery-number", {
                          attrs: {
                            "series-tag": _vm.seriesTag,
                            "lottery-id": row.lotteryId,
                            result: row.manualLotteryNo,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "lotteryTime",
              label: _vm.$t("开奖时间"),
              width: "160",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("parseTime")(scope.row.lotteryTime))),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderNum",
              label: _vm.$t("平台投注数"),
              width: "120",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "profit",
              label: _vm.$t("平台盈利亏损"),
              width: "160",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.profit - 0 < 0
                      ? _c("span", { staticClass: "danger-type" }, [
                          _vm._v(_vm._s(row.profit)),
                        ])
                      : _c("span", { staticClass: "info-type" }, [
                          _vm._v(_vm._s(row.profit)),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("操作"), width: "340", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm.operatorType === 1 &&
                    row.lotteryStatus === 1 &&
                    row.lotteryId == 40
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              disabled: row.disabled,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.manualOpenPrizeShowTip(row)
                              },
                            },
                          },
                          [_vm._v("即将开奖")]
                        )
                      : _vm._e(),
                    _vm.seriesTag == "10"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.isManualMode = true
                                _vm.$refs.ManualOpenDialogControl.open(
                                  row,
                                  _vm.lotteryName,
                                  _vm.seriesTag,
                                  true
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("手动开奖")))]
                        )
                      : _vm._e(),
                    _vm.operatorType === 1 &&
                    row.lotteryStatus === 2 &&
                    _vm.seriesTag !== "20"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.ReopenLotteryDialogControl.open(
                                  row,
                                  _vm.lotteryName,
                                  _vm.seriesTag
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("重新开奖")))]
                        )
                      : _vm._e(),
                    row.result
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.revokeLottery(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("撤销开奖")))]
                        )
                      : _vm._e(),
                    _vm.operatorType === 1 && row.redrawStatus === 1
                      ? _c(
                          "el-button",
                          { attrs: { type: "danger", size: "mini" } },
                          [_vm._v(_vm._s(_vm.$t("下载重开未扣")))]
                        )
                      : _vm._e(),
                    _vm.permission.Pretreatment &&
                    row.lotteryStatus === 1 &&
                    _vm.lotteryInfo &&
                    _vm.lotteryInfo.lotteryType != 10 &&
                    _vm.seriesTag !== "20"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.isManualMode = false
                                _vm.$refs.ManualOpenDialogControl.open(
                                  row,
                                  _vm.lotteryName,
                                  _vm.seriesTag
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("预开奖")))]
                        )
                      : _vm._e(),
                    _vm.permission.Details && _vm.operatorType === 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.BetviewDialogControl.open(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("投注详情")))]
                        )
                      : _vm._e(),
                    _vm.permission.Statistics
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.StatisticsDialogControl.open(
                                  row
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("统计")))]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("LotteryMethodDialog", {
        ref: "LotteryMethodDialogControl",
        on: { update: _vm._handleUpdateLottery },
      }),
      _c("ManualOpenDialog", {
        ref: "ManualOpenDialogControl",
        on: { update: _vm._handleManual },
      }),
      _vm.operatorType === 1
        ? _c("ReopenLotteryDialog", {
            ref: "ReopenLotteryDialogControl",
            on: {
              cancelOpen: _vm._handleCancelOpen,
              reOpen: _vm._handleReOpen,
            },
          })
        : _vm._e(),
      _c("StatisticsDialog", { ref: "StatisticsDialogControl" }),
      _c("BetviewDialog", { ref: "BetviewDialogControl" }),
      _c("RevokeRecordDialog", { ref: "RevokeRecordDialogControl" }),
      _c(
        "el-dialog",
        {
          staticStyle: { "font-size": "16px" },
          attrs: { width: "400px", visible: _vm.revokeConfirm },
        },
        [
          _c("span", [
            _c("i", {
              staticClass: "el-icon-error",
              staticStyle: { color: "#f04134", "margin-right": "5px" },
            }),
            _vm._v(
              "确定进行" +
                _vm._s(_vm.revokeLotteryName) +
                " " +
                _vm._s(_vm.confirmRow.issue) +
                " 期重新开奖吗？"
            ),
          ]),
          _c(
            "p",
            {
              staticStyle: {
                color: "rgba(217, 0, 27, 0.647058823529412)",
                "margin-top": "20px",
              },
            },
            [_vm._v(" tips：点击确认，将会清除已开奖信息及扣回派奖金额。 ")]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.revokeConfirm = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.revokeLotterySubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }