<template>
  <el-dialog :title="emitName=='create'?'添加问题':'编辑问题'" :visible.sync="visible" width="800px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="标题" required prop="title">
        <el-input v-model="ruleForm.title" maxlength="50"/>
      </el-form-item>
      <el-form-item label="排序" required prop="sort">
        <el-input v-model="ruleForm.sort" />
      </el-form-item>
      <el-form-item label="回复内容" prop="replyContent">
        <Tinymce ref="tinymce" v-model="ruleForm.replyContent" :height="200" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import DUpload from '@/components/DUpload'
import Tinymce from '@/components/Tinymce'
const getDefaultForm = () => ({
  title: "",
  sort: "",
  replyContent: ""
})

export default {
  components: { DUpload, Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        title: [
          { required: true, message: '请输入问题标题', trigger: 'change' }
        ],
        replyContent: [
          { required: true, message: '请输入回复内容', trigger: 'change' }
        ],
        sort: [
          { pattern: /^([0-9]|[1-9][0-9]*)$/, message: '排序顺序格式不正确(数字)', trigger: 'change' },
          { required: true, message: '请输入排序顺序(数字)', trigger: 'change' }
        ]
      },
    }
  },
  methods: {
    open(row) {
      this.visible = true
      if (row) {
        this.emitName = 'update'
        const rows = JSON.parse(JSON.stringify(row));
        this.ruleForm = {...rows}
      }else{
        this.emitName = 'create'
      }
    },
    handleSuccess(data) {
      this.ruleForm.headUrl = data + ''
      this.$refs.validateForm.validateField('headUrl')
    }
  }
}
</script>
