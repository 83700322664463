const CanChooseColumns = function() {
  return [
    {
      title: this.$t('主播昵称'),
      key: 'nickName',
      align: 'center'
    },
    {
      title: this.$t('主播账号'),
      key: 'accLogin',
      align: 'center'
    },
    {
      title: this.$t('所属家族'),
      key: 'familyName',
      align: 'center'
    },
    {
      title: this.$t('联系方式'),
      key: 'contactInfo',
      align: 'center'
    },
    {
      title: this.$t('账号状态'),
      key: 'accStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.accStatus}
              active-value={1}
              inactive-value={9}
              beforeChange={this._handleChangeStatus.bind(this, row)}
              disabled={!this.permission.AccountStartStop}
            >
              <span slot='open'>{this.$t('正常')}</span>
              <span slot='close'>{this.$t('禁用')}</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('开播权限'),
      key: 'forbidStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.forbidStatus}
              active-value={1}
              inactive-value={9}
              beforeChange={this._handleChangePermissionStatus.bind(this, row)}
              disabled={!this.permission.LiveStartStop}
            >
              <span slot='open'>{this.$t('正常')}</span>
              <span slot='close'>{this.$t('禁用')}</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 200,
      component: {
        render: (h, row) => {
          const { Update, UpdatePassword } = this.permission
          return (
            <span>
              { Update &&
                <el-button
                  type='danger'
                  size='mini'
                  icon='el-icon-setting'
                  onClick={() => this.$refs.ChangeDialogControl.open(row)}>{this.$t('修改密码')}</el-button> }
              { UpdatePassword &&
                <el-button
                  type='primary'
                  size='mini'
                  icon='el-icon-edit-outline'
                  onClick={() => this.$refs.UpdateDialogControl.open(row)}>{this.$t('编辑')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
