<template>
  <div>
    <div class="filter-container">
      <div class="filter-container-item">
        <el-date-picker
          v-model="times"
          type="daterange"
          style="width: 400px"
          class="filter-item"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :clearable="false"
          :picker-options="pickerOptions"
        />
        <el-button class="filter-item" @click="handleSetDate($event, 0)">今天</el-button>
        <el-button class="filter-item" @click="handleSetDate($event, 1)">昨天</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </div>
      <div class="filter-container-item">
        <el-select v-model="search.source" class="filter-item" placeholder="选择类型" style="width: 140px">
          <el-option label="全部" :value="null" />
          <el-option label="香港" value="SJTK" />
          <el-option label="澳门" value="AMTK" />
        </el-select>
        <el-input v-model="search.pictureName" class="filter-item" placeholder="图库名称" style="width: 160px" />
        <el-input v-model="search.nickName" class="filter-item" placeholder="评论昵称" style="width: 160px" />
      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      @selection-change="handleSelectionChange"
    >
      <template slot="left">
        <button v-if="permission.Delete" class="el-button el-button--small el-button--orange" :disabled="selection.length === 0" @click="handleBatchDelete(2)">批量限制</button>
        <button v-if="permission.Delete" class="el-button el-button--small el-button--orange" :disabled="selection.length === 0" @click="handleBatchDelete(1)">批量删除</button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

  </div>
</template>
<script>

import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getPictureCommentList, delComment } from '@/api/interactive'
import { parseTime } from '@/utils'

export default {
  name: 'CommentTab',
  componentName: 'CommentTab',
  mixins: [TablePageMixin(getPictureCommentList)],
  data() {
    return {
      times: [parseTime(new Date(), '{y}-{m}-{d}'), parseTime(new Date(), '{y}-{m}-{d}')],
      columns: GetColumns.call(this),
      filterColumn: false,
      selection: [],
      search: {
        source: null
      }
    }
  },
  methods: {

    handleDelete(id, type, tenantCode) {
      this.$confirm(`确定删除${type === 2 ? '并限制' : ''}此评论`, '系统提示', { type: 'warning' }).then(() => {
        const params = [{
          id,
          type,
          tenantCode
        }]
        delComment(params).then(([data, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    handleBatchDelete(type) {
      if (this.selection.length === 0) {
        this.$message.warning('请选择评论')
        return
      }
      this.$confirm(`确定删除${type === 2 ? '并限制' : ''}选中的评论`, '系统提示', { type: 'warning' }).then(() => {
        let params = this.selection.map(o => {
          return {
            id: o.id,
            type,
            tenantCode: this.$parent.tenant.tenantCode
          }
        })

        delComment(params).then(([_, err]) => {
          if (!err) {
            this.$message.success('批量操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    handleSelectionChange(selection) {
      this.selection = selection
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    }
  }
}
</script>
