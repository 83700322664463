import VisibilityChangeMixin from "@/mixins/visibility-change";
import Pagination from "@/components/Pagination";
import { filterParam } from "@/utils";
import { getToken } from "@/utils/auth";
import { mapGetters } from "vuex";
import store from "@/store";
import { getPlatformDomainList } from '@/api/permission'
let intervalIndex = null;
const filterFieldType = {
  vipLevel: (a, b) => a === b,
  memberName: (a, b) => a === b,
  realName: (a, b) => a === b,
  payingName: (a, b) => a === b,
  postscript: (a, b) => a === String(b),
  receivingBank: (a, b) => a === b,
  greaterThanAmount: (a, b) => a < b,
  lessThanAmount: (a, b) => a > b,
  receivingCardNo: (a, b) => a === b,
  type: (a, b) => a === b,
  receivingCardNos: (a, b) => a.includes(b),
};

function getPageResource(resource, { currentPage, pageSize }) {
  return resource.slice((currentPage - 1) * pageSize, currentPage * pageSize);
}

function multiFilter(array, filters) {
  const filterKeys = Object.keys(filters);
  if (!filterKeys.length) return array;
  return array.filter((item) => {
    return filterKeys.every((key) => {
      const fuc = filterFieldType[key];
      if (key === "greaterThanAmount" || key === "lessThanAmount") {
        return fuc(filters[key], item.amount);
      }

      if (key === "receivingCardNos") {
        return fuc(filters[key], item.receivingCardNo);
      }
      return fuc(filters[key], item[key]);
    });
  });
}

export default (CTRL_TYPE) => ({
  mixins: [VisibilityChangeMixin],
  components: { Pagination },
  data() {
    return {
      loading: false,
      isConnecting: false,
      messages: [],
      options: {
        stripe: true,
        border: true,
        fix: true,
        highlightCurrentRow: true,
      },
      confirmSearch: {},
      params: {
        pageSize: 20,
        currentPage: 1,
      },
      socket: {
        isConnected: false,
        domainUrl: ''
      },
    };
  },
  watch: {
    "socket.isConnected"(val, old) {
      if (val && !old) {
        this.isConnecting = false;
      }
    },
  },
  sockets: {
    onopen(event) {
      this.socket.isConnected = true;
      if (intervalIndex) {
        clearInterval(intervalIndex);
      }
      intervalIndex = setInterval(() => {
        this.$socket && this.$socket.send("ping");
      }, 10000);
    },
    onmessage({ data }) {
      console.log("onmessage");
      const tmp = JSON.parse(data);
      const { ctrlType, Msg } = tmp;
      if (ctrlType === CTRL_TYPE) {
        const msg = JSON.parse(Msg);
        //租户过滤
        if (store.getters.tenantCode != msg.tenantCode) return;
        const index = this.messages.findIndex((o) => o.orderNo === msg.orderNo);
        if (index > -1) {
          const same = this.messages[index].status === msg.status;
          if (!same) {
            if (ctrlType === 100) {
              this.messages.splice(index, 1);
            } else {
              this.messages.splice(index, 1, {
                ...this.messages[index],
                ...msg,
              });
            }
          }
        } else {
          if (msg.status === "1111" || msg.status === 1) {
            this.messages.push(msg);
          }
        }
      }
    },
    onclose(event) {
      this.socket.isConnected = false;
      clearInterval(intervalIndex);
      if (event.code === 1006) {
        this.handleConnect();
      }
    },
  },
  created() {
    this.handleColumnChange()
    this.handlePlatformDomainList()
  },
  computed: {
    ...mapGetters(["permissionSensitive", "tenantCode", "operatorId"]),
    permission() {
      return this.permissionSensitive(this.$route.path, this.$options.name);
    },
    resource() {
      return multiFilter(this.messages, filterParam(this.confirmSearch));
    },
    items() {
      return getPageResource(this.resource, this.params);
    },
    total() {
      return this.resource.length;
    },
  },
  beforeDestroy() {
    this.$disconnect();
  },
  methods: {
    handleFilter() {
      this.confirmSearch = this.implementationGetParams();
      this.$message.success(this.$t("已更换筛选条件!"));
    },
    handlePlatformDomainList() {
      getPlatformDomainList({currentPage: 1, pageSize: 100, status: 1, domainType: 2}).then(([items, err]) => {
        if (!err) {
          const list = items.filter(e => e.tenantCode === this.tenantCode)
          if (list.length === 0) {
            this.$message.error('未配置socket域名')
          } else {
            this.socket.domainUrl = list[0].domainUrl
          }
        }
      })
    },
    // formatMessage({ Msg, ctrlType }) {
    //   if (ctrlType === CTRL_TYPE) {
    //     const msg = JSON.parse(Msg)
    //     const index = this.messages.findIndex(o => o.orderNo === msg.orderNo)
    //     if (index > -1) {
    //       const same = this.messages[index].status === msg.status
    //       if (!same) {
    //         if (ctrlType === 100) {
    //           this.messages.splice(index, 1)
    //         } else {
    //           this.messages.splice(index, 1, { ...this.messages[index], ...msg })
    //         }
    //       }
    //     } else {
    //       if (msg.status === '1111' || msg.status === 1) {
    //         this.messages.push(msg)
    //       }
    //     }
    //   }
    // },

    handleSocketFetch({ currentPage, pageSize }) {
      this.params.currentPage = currentPage;
      this.params.pageSize = pageSize;
    },

    handleConnect() {
      if (this.$socket) {
        this.$disconnect();
      }

      this.params.currentPage = 1;
      if (this.socket.isConnected) {
        this.$disconnect();
        // this._closewebsocket()
        return;
      }
      this.isConnecting = true;
      this.$connect(`${this.socket.domainUrl}/liveOperatorWs/${getToken()}`);

      // backManagerUserAccess({
      //   user_id: this.operatorId,
      //   tenant_code: this.tenantCode,
      //   time: parseTime(Date.now(), '{y}-{m}-{d}')
      // }).then(([data, err]) => {
      //   if (!err) {
      //     this.isConnecting = false
      //     this.$connect(`${process.env.VUE_APP_OPERATOR_WEBSOCKET}${data.authToken}`)
      //   }
      // }).catch(() => {
      //   this.isConnecting = false
      // })
    },

    handleFixedRefresh() {
      this.messages = [];
      this.$disconnect();
      // this._closewebsocket()

      setTimeout(() => {
        this.handleConnect();
      }, 300);
    },

    handleColumnChange(keys) {
      this.columns = this.implementationGetColumns(keys);
    },
  },
});
