<template>
  <div>
    <comMenu title="娱乐版-心水百宝箱" menu-name="百宝箱" :menu-list="menuList" :num="8" home-type="entertainment" :position="5" />
    <comMenu title="体育版-心水百宝箱" menu-name="百宝箱" :menu-list="menuList" :num="8" home-type="sport" :position="5" />
    <comMenu title="六合版-心水百宝箱" menu-name="百宝箱" :menu-list="menuList" :num="8" home-type="liuhe" :position="5" />
  </div>
</template>
<script>
import comMenu from '../components/comMenu.vue'

export default {
  name: 'XinshuiTab',
  componentName: 'XinshuiTab',
  components: { comMenu },
  data() {
    return {
      menuList: [
        { key: 'baibao.kaijiangxianchang', label: '开奖现场' },
        { key: 'baibao.ziliaotongji', label: '资讯统计' },
        { key: 'baibao.chaxunzhushou', label: '查询助手' },
        { key: 'baibao.ziliaojiaoliu', label: '资料交流' },
        { key: 'baibao.saidanquan', label: '晒单圈' },
        { key: 'baibao.jihuadashen', label: '计划大神' },
        { key: 'baibao.gaoshuoluntan', label: '高手论坛' },
        { key: 'baibao.gongjubaoxiang', label: '工具宝箱' },
        { key: 'baibao.gongshishahao', label: '公式杀号' },
        { key: 'baibao.tiaomazhushou', label: '挑码助手' },
        { key: 'baibao.shuxingcankao', label: '属性参考' },
        { key: 'baibao.lisikaijiang', label: '历史开奖' },
        { key: 'baibao.fenxiangzhuanqian', label: '分享赚钱' },
        { key: 'baibao.xinshuituijian', label: '六合图库/心水推荐' },
        { key: 'baibao.tuijiandanbao', label: '推荐担保' }
      ]
    }
  }
}
</script>
