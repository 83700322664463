const CanChooseColumns = function() {
  return [
    {
      title: this.$t('访问IP'),
      key: 'ipAddress',
      align: 'center'
    },
    {
      title: this.$t('备注'),
      key: 'remark',
      align: 'center'
    },
    {
      title: this.$t('操作时间'),
      key: 'updateTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return <span>{ this.$parseTime(row.updateTime) }</span>
        }
      }
    },
    {
      title: this.$t('操作人'),
      key: 'operatorName',
      align: 'center',
      width: 160
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      component: {
        render: (h, row) => {
          const { Del, Update } = this.permission
          return (
            <span>
              { Update && <el-button type='success' icon='el-icon-edit' size='mini' onClick={() => this.$refs.EditDialogControl.open(row)}>{this.$t('修改')}</el-button> }
              { Del && <el-button type='danger' icon='el-icon-delete' size='mini' onClick={() => this.handleDelete(row)}>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
