var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "validateForm",
      staticClass: "tenant-form",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "名称", prop: "tenantName" } },
        [
          _c("el-input", {
            model: {
              value: _vm.ruleForm.tenantName,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "tenantName", $$v)
              },
              expression: "ruleForm.tenantName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "简称", prop: "tenantCode" } },
        [
          _c("el-input", {
            model: {
              value: _vm.ruleForm.tenantCode,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "tenantCode", $$v)
              },
              expression: "ruleForm.tenantCode",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("租户组别") } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "请选择租户组别" },
              model: {
                value: _vm.ruleForm.tagGroupId,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "tagGroupId", $$v)
                },
                expression: "ruleForm.tagGroupId",
              },
            },
            _vm._l(_vm.groupList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { value: item.id, label: item.groupName },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "代理模式", prop: "agencyType" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { clearable: "", placeholder: "请选择代理模式" },
              model: {
                value: _vm.ruleForm.agencyType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "agencyType", $$v)
                },
                expression: "ruleForm.agencyType",
              },
            },
            _vm._l(_vm.agencyTypes, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.label, value: item.key },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "维护费用",
            prop: "maintenanceCost",
            rules: _vm.amountRules,
          },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入金额" },
            model: {
              value: _vm.ruleForm.maintenanceCost,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "maintenanceCost", $$v)
              },
              expression: "ruleForm.maintenanceCost",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "美颜费用",
            prop: "beautyCost",
            rules: _vm.amountRules,
          },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入金额" },
            model: {
              value: _vm.ruleForm.beautyCost,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "beautyCost", $$v)
              },
              expression: "ruleForm.beautyCost",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "服务器费用",
            prop: "serverCost",
            rules: _vm.amountRules,
          },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入金额" },
            model: {
              value: _vm.ruleForm.serverCost,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "serverCost", $$v)
              },
              expression: "ruleForm.serverCost",
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.ladderConfig, function (config) {
        return [
          _c(
            "el-form-item",
            {
              key: config.key + "Charges",
              attrs: { label: config.label + "抽成金额" },
            },
            [
              _c(
                "el-row",
                { attrs: { type: "flex", gutter: 12 } },
                _vm._l(_vm.ladderOptions, function (name, i) {
                  return _c(
                    "el-col",
                    { key: i, attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: name + "Level" + config.key + "Charges",
                            rules: _vm.amountRules,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "抽成金额(" + (i + 1) + "级)",
                            },
                            model: {
                              value:
                                _vm.ruleForm[
                                  name + "Level" + config.key + "Charges"
                                ],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  name + "Level" + config.key + "Charges",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm[`${name}Level${config.key}Charges`]",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              key: config.key + "Percent",
              attrs: { label: config.label + "抽成比例" },
            },
            [
              _c(
                "el-row",
                { attrs: { type: "flex", gutter: 12 } },
                _vm._l(_vm.ladderOptions, function (name, i) {
                  return _c(
                    "el-col",
                    { key: i, attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: name + "Level" + config.key + "Percent",
                            rules: _vm.percentRules,
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "抽成比例(" + (i + 1) + "级)",
                              },
                              model: {
                                value:
                                  _vm.ruleForm[
                                    name + "Level" + config.key + "Percent"
                                  ],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    name + "Level" + config.key + "Percent",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm[`${name}Level${config.key}Percent`]",
                              },
                            },
                            [_c("template", { slot: "suffix" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }