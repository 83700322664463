var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.awardName || "编辑",
        visible: _vm.visible,
        "close-on-click-modal": false,
        width: "400px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: { "label-suffix": ":", model: _vm.ruleForm },
        },
        _vm._l(_vm.formItems, function (item, key) {
          return _c(
            "el-form-item",
            {
              key: item.prop + key,
              attrs: {
                "label-width": item.labelWidth || "140px",
                label: item.label,
                prop: item.prop,
                rules: _vm.getFormItemRules(item.prop),
              },
            },
            [
              item.html === "span"
                ? _c("div", { class: item.class }, [
                    _c("span", [_vm._v(_vm._s(item.prefix))]),
                    _vm._v(_vm._s(_vm.setSpanCont(item)) + " "),
                    _c("span", [_vm._v(_vm._s(_vm.getUnit(item.unit)))]),
                  ])
                : item.html === "input"
                ? _c("el-input", {
                    attrs: { placeholder: item.placeholder },
                    model: {
                      value: _vm.ruleForm[item.prop],
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, item.prop, $$v)
                      },
                      expression: "ruleForm[item.prop]",
                    },
                  })
                : item.html === "switch"
                ? _c("el-switch", {
                    model: {
                      value: _vm.ruleForm[item.prop],
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, item.prop, $$v)
                      },
                      expression: "ruleForm[item.prop]",
                    },
                  })
                : item.html === "radio"
                ? _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm[item.prop],
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, item.prop, $$v)
                        },
                        expression: "ruleForm[item.prop]",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("增加"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("减少"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }