var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        width: "700px",
        top: "5vh",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("标题"), prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("请输入站内信标题") },
                model: {
                  value: _vm.ruleForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("发送方式"), prop: "sendType" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.emitName === "update",
                    placeholder: _vm.$t("请选择发送方式"),
                  },
                  model: {
                    value: _vm.ruleForm.sendType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "sendType", $$v)
                    },
                    expression: "ruleForm.sendType",
                  },
                },
                _vm._l(_vm.$parent.sendTypeOptions, function (item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item, value: +key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.sendType === 1 || _vm.ruleForm.sendType === 2
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("接收人"), prop: "memberList" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.emitName === "update",
                      placeholder:
                        (_vm.ruleForm.sendType === 2 &&
                          _vm.$t(
                            "请输入接收会员的账号, 多个账号之间以英文逗号隔开,例如:账号1,账号2"
                          )) ||
                        _vm.$t("请输入接收会员的账号, 例如:账号1"),
                    },
                    model: {
                      value: _vm.ruleForm.memberList,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "memberList", $$v)
                      },
                      expression: "ruleForm.memberList",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.ruleForm.sendType === 3
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("会员VIP等级"), prop: "vipLevel" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.emitName === "update",
                      placeholder: _vm.$t(
                        "请输入会员VIP等级，多个等级以英文逗号隔开, 例如: 4,5,6"
                      ),
                    },
                    model: {
                      value: _vm.ruleForm.vipLevel,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "vipLevel", $$v)
                      },
                      expression: "ruleForm.vipLevel",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "", "label-width": "0", prop: "content" } },
            [
              _c("tinymce", {
                ref: "tinymce",
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("取消"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("确 定")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }