import { filterColumns } from "@/utils";
const CanChooseColumns = function () {
  return [
    {
      title: this.$t("订单号"),
      key: "orderNo",
      align: "center",
      component: {
        render: (h, row) => {
          return (<span>
            <p>订单号：{ row.orderNo }</p>
            <p>{ this.$t("投注时间") }：{ this.$parseTime(row.createTime) }</p>
            <p>{ this.$t("更新时间") }：{ this.$parseTime(row.updateTime) }</p>
          </span>)
        }
      }
    },
    {
      title: this.$t("彩种期号"),
      key: "issue",
      align: "center",
      component: {
        render: (h, row) => {
          return (<div>
            <p>{row.lotteryName}</p>
            <p>{row.issue}</p>
          </div>)
        }
      }
    },
    {
      title: this.$t("内容"),
      key: "numbers",
      align: "center"
    },
    {
      title: '原注单金额',
      key: "amount",
      align: "center",
      component: {
        render: (h, row) => {
          return (<div>
            <p>{ this.$t("投注")}：{row.amount }</p>
            <p>{this.$t("抵用")}：{row.couponAmount }</p>
          </div>)
        }
      }
    },
    {
      title: this.$t("用户盈亏"),
      key: "profit",
      align: "center",
      component: {
        render: (h, row) => {
          const label = this.bettingStatus.find((o) => o.key === row.status)?.label || ''
          return (<span>
            <p style={row.status === 10 ? "color:#F59A23" : ""}>{ label }</p>
            <p>中奖：{ row.winAmount }</p>
            <p>盈亏：{ row.profit }</p>
          </span>)
        }
      }
    },
    {
      title: '推单金额倍数\n\r（原注单金额）',
      key: "times",
      align: "center",
      component: {
        render: (h, row) => {
          return (<span>
            <el-input type='number' v-model={row.times} style='width: 100px' />倍
          </span>)
        }
      }
    },
    {
      title: '推单金额',
      key: "betAmount",
      align: "center",
      component: {
        render: (h, row) => {
          return (<span>
            投注金额：{ Number(row.amount) * Number(row.times) }
          </span>)
        }
      }
    },
    {
      title: '推单方案宣言',
      key: "planSpeech",
      align: "center",
      component: {
        render: (h, row) => {
          return (<span>
            <el-input type='textarea' autosize={{minRows: 3, maxRows: 6}} v-model={row.planSpeech} />
          </span>)
        }
      }
    }
  ]
}

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}
