var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: { model: _vm.ruleForm, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-right": "6px" },
                  on: { change: _vm.onOpen },
                  model: {
                    value: _vm.headerLang,
                    callback: function ($$v) {
                      _vm.headerLang = $$v
                    },
                    expression: "headerLang",
                  },
                },
                _vm._l(_vm.allLang, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: index, attrs: { label: item.lang } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "desc" } },
            [
              _c("tinymce", {
                ref: "tinymce",
                staticStyle: { width: "calc(100% - 100px)" },
                attrs: { height: 300 },
                model: {
                  value: _vm.ruleForm.explain,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "explain", $$v)
                  },
                  expression: "ruleForm.explain",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-popconfirm",
            {
              attrs: { title: _vm.$t("确定保存配置信息吗?") },
              on: { confirm: _vm.handleSubmit },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "reference",
                    type: "primary",
                    loading: _vm.submitting,
                  },
                  slot: "reference",
                },
                [_vm._v(" " + _vm._s(_vm.$t("保存配置")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }