import request from '@/plugins/axios'
/* 公告 ************************************************************************/
// 查询公告列表
export function getAnnouncementList(data = {}) {
  return request({
    url: '/announcement/list',
    method: 'post',
    data
  })
}

// 获取公告详情
export function getAnnouncementDetail(query = {}) {
  return request({
    url: '/announcement/detail',
    method: 'get',
    params: query
  })
}

// 新增公告
export function addAnnouncement(data = {}) {
  return request({
    url: '/announcement/create',
    method: 'post',
    data
  })
}

// 修改公告
export function updateAnnouncement(data = {}) {
  return request({
    url: '/announcement/update',
    method: 'post',
    data
  })
}

// 批量修改公告
export function batchUpdateAnnouncement(data = {}) {
  return request({
    url: '/announcement/batch/update',
    method: 'post',
    data
  })
}

// 删除公告
export function deleteAnnouncement(data = {}) {
  return request({
    url: '/announcement/delete',
    method: 'post',
    data
  })
}

/* 站内信 ************************************************************************/
// 查询站内信列表
export function getStationletterList(data = {}) {
  return request({
    url: '/stationletter/list',
    method: 'post',
    data
  })
}

// 获取站内信详情
export function getStationletterDetail(query = {}) {
  return request({
    url: '/stationletter/detail',
    method: 'get',
    params: query
  })
}

// 发送(新增)站内信
export function addStationletter(data = {}) {
  return request({
    url: '/stationletter/create',
    method: 'post',
    data
  })
}

// 更新站内信
export function updateStationletter(data = {}) {
  return request({
    url: '/stationletter/update',
    method: 'post',
    data
  })
}

// 删除站内信
export function deleteStationletter(data = {}) {
  return request({
    url: '/stationletter/delete',
    method: 'post',
    data
  })
}

/* 简讯管理 ************************************************************************/
// 查询简讯列表
export function getBriefList(data = {}) {
  return request({
    url: '/sms/list',
    method: 'post',
    data
  })
}

/* APP推送 ************************************************************************/
// 获取推送计划
export function getAppPushList(params = {}) {
  return request({
    url: '/jgPush/getRecordList',
    method: 'get',
    params
  })
}

// 获取推送任务详情
export function getAppPushDetails(params = {}) {
  return request({
    url: '/jgPush/getPlanList',
    method: 'get',
    params
  })
}

// 新建推送任务
export function addAppPush(data = {}) {
  return request({
    url: '/jgPush/save',
    method: 'post',
    data
  })
}

// 修改推送任务
export function editAppPush(data = {}) {
  return request({
    url: '/jgPush/editPushTask',
    method: 'post',
    data
  })
}

// 撤销推送任务
export function cancleAppPush(data = {}) {
  const { pushType, recordId } = data
  return request({
    url: `/jgPush/repealPushTask?recordId=${recordId}&pushType=${pushType}`,
    method: 'post',
    data: {}
  })
}

/* over */
