<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="500px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      :disabled="emitName === 'details'"
      label-width="120px"
      label-suffix=":"
    >
      <el-form-item :label="'排序'" prop="name">
        <el-input v-model="ruleForm.sort" style="width: 300px" type="number"   :placeholder="$t('1-999')" />
      </el-form-item>
      <el-form-item :label="'钱包名称'" prop="name">
        <el-input v-model="ruleForm.name" style="width: 300px" maxlength="20" :placeholder="$t('限20字以内')" />
      </el-form-item>
      <el-form-item :label="'钱包所需货币'" prop="rateType">
        <el-select v-model="ruleForm.rateType" :placeholder="'钱包所需货币'" :style="{ width: '300px' }">
          <el-option v-for="(item, index) in currencies" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>

      <el-form-item :label="'支付方式图标'" prop="icon">
        <el-upload
          class="banner-uploader"
          accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
          :action="action"
          :data="{ type: 'image', folder: 'ad-photo' }"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleBannerSuccess"
        >
          <img v-if="ruleForm.icon" :src="ruleForm.icon" class="banner">
          <div v-else class="banner-uploader-txt">
            <p>{{ $t('文件大小控制在100K以内') }}</p>
            <button class="el-button el-button--small el-button--orange" @click.self.prevent>{{ $t('点击上传') }}</button>
          </div>
        </el-upload>
      </el-form-item>

    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button v-if="emitName !== 'details'" type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import currencies from '../cash'
const getDefaultForm = () => ({
  currencies,
  sort:null,
  name: '',
  rateType: '',
  icon: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      currencies,
      emitName: '',
      rules: {
        name: [{ required: true, message: this.$t('请填写钱包名称'), trigger: 'blur' }]
      }
    }
  },
  computed: {

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    title() {
      return this.emitName === 'create' ? this.$t('新增图标') : this.$t('编辑图标')
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        Object.assign(this.ruleForm, row)
        this.emitName = 'update'
      }
      this.visible = true
    },
    handleBannerSuccess(res) {
      this.ruleForm.icon = res.data[0]
    }
  }
}
</script>

<style lang="scss">
.banner-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.border_ {
  padding: 5px 10px;
  box-sizing: border-box;
}

.banner-uploader .el-upload:hover {
  border-color: #409EFF;
}

.banner-uploader-txt {
  font-size: 14px;
  color: #8c939d;
  width: 300px;
  height: 128px;
  text-align: center;

  p {
    padding-top: 20px;
  }
}

.banner {
  width: 300px;
  height: 128px;
  display: block;
}
</style>
