<template>
  <div>
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        type="daterange"
        class="filter-item"
        style="width: 360px"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始时间')"
        :end-placeholder="$t('结束时间')"
        value-format="yyyy-MM-dd"
        :clearable="false"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetDate($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 7)">{{ $t('_7天') }}</el-button>
      <el-button type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template #left>
        <div class="filter-container">
          <el-select v-model="search.sportType" :placeholder="$t('体育项目')" clearable style="width: 120px" class="filter-item">
            <el-option :label="$t('Expert_Plan.足球')" :value="1" />
            <el-option :label="$t('Expert_Plan.篮球')" :value="2" />
          </el-select>
          <el-select v-model="search.status" :placeholder="$t('状态')" clearable style="width: 120px" class="filter-item">
            <el-option :label="$t('Interactive_Match.ForecastTab.赛前')" :value="1" />
            <el-option :label="$t('Interactive_Match.ForecastTab.赛中')" :value="2" />
            <el-option :label="$t('Interactive_Match.ForecastTab.结束')" :value="3" />
          </el-select>
          <el-input v-model="search.competitionName" :placeholder="$t('Interactive_Match.ForecastTab.赛事名称')" clearable style="width: 160px" class="filter-item" />
          <el-input v-model="search.teamName" :placeholder="$t('Interactive_Match.ForecastTab.球队名称')" clearable style="width: 160px" class="filter-item" />
        </div>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <UpdateDialog ref="UpdateDialogControl" @update="_handleUpdate" />
    <PredictDialog ref="PredictDialogControl" @update="_handlePredictDialog" />
    <AnalysisDialog ref="AnalysisDialogControl" @update="_handleAnalysisUpdate" />
    <ConfigLiveDialog ref="ConfigLiveDialogControl" @update="_configLive" />

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { UpdateDialog, PredictDialog, AnalysisDialog, ConfigLiveDialog } from './components'
import { getMatchList, updateMatch, predictionDetailUpdate, updateIntelligence, updateLiveConfig, updateRelationStatus ,updateFootballMatchLiveUrl} from '@/api/match'
import { getDefaultTimes } from '@/utils'
import { mapGetters } from 'vuex'

const insetparams = { sportType: undefined }

function parseAnalysis(str) {
  return str.split(/\r?\n/).filter(Boolean)
}

export default {
  name: 'ForecastTab',
  componentName: 'ForecastTab',
  components: { UpdateDialog, PredictDialog, AnalysisDialog, ConfigLiveDialog },
  mixins: [TablePageMixin(getMatchList, true, insetparams)],
  data() {
    return {
      times: getDefaultTimes('date'),
      columns: [],
      defaultColumns: GetColumns.call(this),
      search: {},
      sportMatchStatus: this.$t('sportMatchStatus'),
      basketMatchStatus: this.$t('basketMatchStatus')
    }
  },
  computed: {
    ...mapGetters(['tenantCode'])
  },
  methods: {
    eidthLiveUrl(row){
      let _this=this;
      this.$prompt('编辑直播地址', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue:row.liveUrl,
        }).then(({ value }) => {
          let reqData={liveUrl:value,matchId:row.matchId}
          updateFootballMatchLiveUrl(reqData).then(([_, err]) => {
            if (!err) {
              _this.$message.success(_this.$t('操作成功'))
              _this.handleFetch()
            } else {
              _this.$message.error(_this.$t('操作失败'))
            }
          })
        })
    },
    /**
     * 直播配置
     * @private
     */
    _configLive({ field, cancel, close }) {
      updateLiveConfig({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _getLogs() {
      if (this.$P$ && !this.$parent.tenant) {
        this.$message.error(this.$t('请选择租户'))
        return
      }
      this.$refs.ChangeDialogControl.open()
    },
    _handleUpdate({ field, cancel, close }) {
      updateRelationStatus({
        thirdMatchId: field.thirdMatchId,
        matchId: field.matchId,
        tenantCode: this.tenantCode,
        sourceType: field.sourceType,
        status: field.status
      }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    _handlePredictDialog({ field, cancel, close }) {
      predictionDetailUpdate({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    _handleInvalid({ matchId, matchTime, tenantCode, sportType }) {
      this.$confirm(this.$t('Interactive_Match.ForecastTab.确认取消此赛事'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        updateMatch({
          matchId,
          matchTime,
          newsStatus: 0,
          tenantCode,
          sportType
        }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    _handleAnalysisUpdate({ field, cancel, close }) {
      updateIntelligence([{
        ...field,
        homeFavorableInfo: parseAnalysis(field.homeFavorableInfo),
        awayFavorableInfo: parseAnalysis(field.awayFavorableInfo),
        homeAdverseInfo: parseAnalysis(field.homeAdverseInfo),
        awayAdverseInfo: parseAnalysis(field.awayAdverseInfo),
        neutralInfo: parseAnalysis(field.neutralInfo)
      }]).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
        } else {
          cancel()
        }
      })
    },
    getScoreText(homeScore, awayScore) {
      if (homeScore > awayScore) {
        return this.$t('胜')
      } else if (homeScore < awayScore) {
        return this.$t('负')
      } else {
        return this.$t('平')
      }
    },
    implementationGetParams() {
      return {
        ...this.formatIntervalTime(),
        type: 1,
        tenantCode: this.tenantCode
      }
    },
    _handleUpdateStatus(row) {
      updateRelationStatus({
        thirdMatchId: row.thirdMatchId,
        matchId: row.matchId,
        tenantCode: this.tenantCode,
        sourceType: row.sourceType,
        status: row.isRelation
      }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
        }
      })
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
<style>
.liveUrlcell{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color:blue;
  text-decoration: underline;
}
</style>