import request from '@/plugins/axios'

/* 银行卡管理 ***************************************************************************************/
// 银行卡管理列表
export function getSupportBankList(data = {}) {
  return request({
    url: '/supportBank/List',
    method: 'post',
    data
  })
}

// 获取所有银行卡名称
export function getAllBankName(params = {}) {
  return request({
    url: '/supportBank/getAllBankName',
    method: 'get',
    params
  })
}

// 通过银行名称查询银行具体信息
export function getBySupportBankName(data = {}) {
  return request({
    url: '/supportBank/getBySupportBankName',
    method: 'post',
    data
  })
}

// 新增卡段
export function addCardBin(data = {}) {
  return request({
    url: '/supportBank/addCardBin',
    method: 'post',
    data
  })
}

// 卡段模糊查询银行卡段信息
export function getLikeNumbers(params = {}) {
  return request({
    url: '/supportBank/likeNumbers',
    method: 'get',
    params
  })
}

// 校验银行名称是否重复
export function checkBankName(params = {}) {
  return request({
    url: '/supportBank/checkBankName',
    method: 'get',
    params
  })
}

// 新增银行
export function saveSupportBank(data = {}) {
  return request({
    url: '/supportBank/save',
    method: 'post',
    data
  })
}

// 编辑银行
export function updateSupportBank(data = {}) {
  return request({
    url: '/supportBank/update',
    method: 'post',
    data
  })
}

// 银行启用禁用
export function updateBankStatus(data) {
  return request({
    url: '/supportBank/uptStatus',
    method: 'post',
    data
  })
}

// 删除银行
export function deleteSupportBank(data) {
  return request({
    url: '/supportBank/delete',
    method: 'post',
    data
  })
}

// 第三方

export function thirdAmountRecordList() {
  return request({
    url: '/platform/thirdAmount/thirdAmountRecordList',
    method: 'get'
  })
}

export function thirdOperateRecordList(data) {
  return request({
    url: '/platform/thirdAmount/thirdOperateRecordList',
    method: 'post',
    data
  })
}

export function addBalance(data) {
  return request({
    url: '/platform/thirdAmount/addBalance',
    method: 'post',
    data
  })
}

export function paymentwalletList(params) {
  return request({
    url: '/paymentwallet/list',
    method: 'get',
    params
  })
}

export function paymentwalletDel(data) {
  return request({
    url: '/paymentwallet/del',
    method: 'post',
    data
  })
}

export function addBankCard(data) {
  return request({
    url: '/bankCard/addBankCard',
    method: 'post',
    data
  })
}