import request from '@/plugins/axios'

export function getMatchList(data) {
  return request({
    url: '/platform/sport/filter/match',
    method: 'post',
    data
  })
}
// 根据条件查询比赛信息-提供给任务目标配置
export function getLangMatch(data) {
  return request({
    url: '/sport/filter/match/task',
    method: 'post',
    data
  })
}

export function updateMatch(data) {
  return request({
    url: '/platform/sport/update/hot',
    method: 'post',
    data
  })
}

export function predictionMatchDetail(data) {
  return request({
    url: '/platform/prediction/matchDetail',
    method: 'post',
    data
  })
}

export function predictionDetailUpdate(data) {
  return request({
    url: '/platform/prediction/detail/update',
    method: 'post',
    data
  })
}

export function updateIntelligence(data) {
  return request({
    url: '/platform/prediction/intelligence/update',
    method: 'post',
    data
  })
}

export function getIntelligenceList(data) {
  return request({
    url: '/platform/prediction/getIntelligenceList',
    method: 'post',
    data
  })
}

export function updateRelationStatus(data) {
  return request({
    url: '/platform/football/third/update/relation/status',
    method: 'post',
    data
  })
}

// 体育赛事
export function getCompetitionList(data) {
  return request({
    url: '/platform/sport/filter/competition',
    method: 'post',
    data
  })
}

export function updateCompetition(data) {
  return request({
    url: '/platform/sport/competition/update',
    method: 'post',
    data
  })
}

// 预测匹配列表
export function getPredictionMatchList(data) {
  return request({
    url: '/prediction/match/list',
    method: 'post',
    data
  })
}

export function getThirdMatchList(data) {
  return request({
    url: '/platform/' + (data.sportType === 2 ? 'basketball' : 'football') + '/third/match/list',
    method: 'post',
    data
  })
}

export function getThirdCompetitionList(data) {
  return request({
    url: '/platform/football/third/competition/list',
    method: 'post',
    data
  })
}

export function getThirdCompetitionMatch(data) {
  return request({
    url: '/platform/football/third/competition/match/list',
    method: 'post',
    data
  })
}

// 设置滚球列表的热点排序
export function setSort(data) {
  return request({
    url: '/platform/' + (data.sportType === 2 ? 'basketball' : 'football') + '/third/update/sort',
    method: 'post',
    data
  })
}

export function updateFootballMatchLiveUrl(data) {
  return request({
    url: '/football/match/update',
    method: 'post',
    data
  })
}

export function sportconfigList(params) {
  return request({
    url: '/sportconfig/list',
    method: 'get',
    params
  })
}

export function sportconfigUpdate(data) {
  return request({
    url: '/sportconfig/update',
    method: 'post',
    data
  })
}