<template>
  <div>
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-input v-model="search.operatorName" clearable :placeholder="$t('账号')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.system" clearable :placeholder="$t('登录系统')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.loginIp" clearable :placeholder="$t('IP地址')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.address" clearable :placeholder="$t('登录地区')" class="filter-item" style="width: 140px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />
  </div>
</template>
<script>
import { getBackManagerLog } from '@/api/permission'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'

export default {
  name: 'System',
  componentName: 'System',
  mixins: [TablePageMixin(getBackManagerLog)],
  data() {
    return {
      times: null,
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this)
    }
  },
  methods: {
    implementationGetParams() {
      return this.formatIntervalTime()
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
