<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.categoryName" maxlength="20" :placeholder="$t('类目名称')" class="filter-item" style="width: 310px" clearable />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
<!--      <el-button v-if="permission.Add" style="float:right" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">{{ $t('新建类目') }}</el-button>-->
      <el-button style="float:right" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">{{ $t('新建类目') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <CategoryDialog ref="categoryDialog" @create="handleCreate" @update="handleUpdate" />

    <!--商品迁移弹窗-->
    <el-dialog title="商品迁移" :visible.sync="dialogFormVisible" @close="cancel" width="420px">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="当前商品类目：" :label-width="formLabelWidth">
          <span>{{ form.categoryName }}</span>
        </el-form-item>
        <el-form-item label="商品数量：" :label-width="formLabelWidth">
          <span>{{ form.quantity }}</span>
        </el-form-item>
        <el-form-item label="迁移商品类目：" :label-width="formLabelWidth" prop="categoryNewId">
          <el-select v-model="form.categoryNewId" :placeholder="$t('请输入类目名称查询')" class="filter-item" style="width: 220px" clearable filterable>
            <el-option v-for="item in category" :disabled="item.categoryName === form.categoryName" :key="item.id" :value="item.id" :label="item.categoryName" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm('form')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import { getCategoryList, createGategory, moveGategory, getGategoryDetail, updateGategory, deleteGategory } from '@/api/points'
import GetColumns from './columns'
import CategoryDialog from './CategoryDialog'
import { mapState } from 'vuex'

const insetparams = {
  categoryName: '',
  langCode: '',
}

export default {
  name: 'CategoryManage',
  components: { CategoryDialog },
  mixins: [TablePageMixin(getCategoryList, true, insetparams)],
  data() {
    return {
      search: {},
      filterColumn: false,
      columns: GetColumns.call(this),
      category: [],
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        category: '',
        quantity: '',
        categoryNewId: '',
      },
      formLabelWidth: '130px',
      rules: {
        categoryNewId: [{ required: true, message: this.$t('请输入类目名称查询'), trigger: 'change' }],
      }
    }
  },
  computed: {
    ...mapState('app', ['allLang'])
  },
  created() {
    this.updateCategoryList()
  },
  methods: {
    updateCategoryList() {
      getCategoryList({ categoryName: '', langCode: '', currentPage: 1, pageSize: 50 }).then(([items, err]) => {
        this.category = items
      })
    },
    getParams(field) {
      const arr = []
      const items = field.items
      for (const el of items) {
        arr.push(el.categoryName)
      }
      field.nameCn = arr[0]
      field.nameEn = arr[1]
      field.nameVn = arr[2]
      field.nameIn = arr[3]
      field.nameJp = arr[4]
      field.nameMy = arr[5]
      delete field.items
      delete field.categoryName
    },

    // 点击 新建类目
    onCreate() {
      this.$refs.categoryDialog.open()
    },

    // 点击 新建类目-保存
    handleCreate({ field, cancel, close }) {
      this.getParams(field)
      createGategory(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新建成功!'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    // 点击 迁移
    async handleMove(row) {
      this.form = row
      // 商品数量
      await getGategoryDetail({ categoryId: row.id }).then(res => {
        this.form.quantity = res[0]?.quantity
      })
      // 更新迁移商城类目
      this.updateCategoryList()
      this.dialogFormVisible = true
    },

    // 点击 迁移-确定
    confirm(formName) {
      if (this.form.quantity === 0) {
        this.$message({
          message: '类目商品为0，不需要迁移',
          type: 'error'
        });
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          moveGategory({ categoryOldId: this.form.id, categoryNewId: this.form.categoryNewId }).then(([_, err]) => {
            if (!err) {
              this.$message.success(this.$t('迁移成功!'))
              this.handleFetch()
            }
          })
          this.dialogFormVisible = false
        } else {
          return false
        }
      })
    },

    // 点击 迁移-取消
    cancel() {
      this.$refs["form"].resetFields();
      this.dialogFormVisible = false
    },

    // 点击 修改-保存
    handleUpdate({ field, cancel, close }) {
      this.getParams(field)
      updateGategory(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('修改成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    // 点击 删除-确定
    handleDelete({ id }) {
      this.$confirm(this.$t(<p class="tip">请确认当前类目下没有商品才能进行删除！</p>), this.$t('确定删除商城类目吗？'), { type: 'warning' }).then(() => {
        deleteGategory({ id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item--small {
    .el-form-item__error {
      padding-top: 4px;
    }
  }
}

.tip {
  color: #ff4949
}
</style>
