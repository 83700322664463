<template>
  <el-dialog
    title="系统提示"
    width="560px"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
    @closed="handleClosed"
  >
    <div style="text-align: center;margin: 20px;">{{ tipTitle }}</div>
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="160px" label-suffix=":">
      <el-form-item label="谷歌验证码" prop="googleCode">
        <el-input v-model="ruleForm.googleCode" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        确认
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  googleCode: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'validate',
      tipTitle:'',
      rules: {
        googleCode: [
          { required: true, message: '请输入管理员谷歌验证码', trigger: 'blur' },
          { pattern: /^\d{6}$/, message: '请输入6位数字', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(ruleForm, tipTitle) {
      this.ruleForm = ruleForm
      this.tipTitle = tipTitle
      this.visible = true
    },
    handleColse() {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
