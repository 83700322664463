var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-container-item" },
          [
            _c("span", [_vm._v("VIP等级：")]),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: _vm.$t("VIP等级") },
                model: {
                  value: _vm.search.vipLevel,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "vipLevel", $$v)
                  },
                  expression: "search.vipLevel",
                },
              },
              _vm._l(_vm.vipLevels, function (item) {
                return _c("el-option", {
                  key: item.vipLevel,
                  attrs: { label: "VIP" + item.vipLevel, value: item.vipLevel },
                })
              }),
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handleSearch },
              },
              [_vm._v(_vm._s(_vm.$t("查询")))]
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary", icon: "el-icon-refresh-right" },
                on: { click: _vm.resetSearch },
              },
              [_vm._v(_vm._s(_vm.$t("重置")))]
            ),
          ],
          1
        ),
      ]),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.defaultColumns,
          "original-columns": _vm.defaultColumns,
          options: _vm.options,
          filterColumn: false,
        },
      }),
      _c("ActionDialog", {
        ref: "ActionDialogControl",
        attrs: { allRewardList: _vm.allRewardList },
        on: { create: _vm.handleCreateLevel, update: _vm.handleUpdateLevel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }