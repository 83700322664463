<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="search.level" :placeholder="$t('请选择代理等级')" class="filter-item" @change="handleSearch">
        <el-option v-for="(item, index) in defaultAgentType" :key="index" :label="item + $t('级代理')" :value="item" />
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate()">
        {{ $t('添加返佣配置') }}
      </el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template #left>
        <div class="filter-container">
          <span class="filter-item">
            {{ $t('结算方式') }}:
          </span>
          <el-radio-group v-model="paymentType" class="filter-item">
            <el-radio v-for="(item,index) in paymentTypes" :key="index" :label="item.value"> {{ item.label }}</el-radio>
          </el-radio-group>
          <el-button type="primary" class="filter-item" @click="handleSavePaymentType">
            {{ $t('保存结算方式') }}
          </el-button>
        </div>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <edit-dialog ref="EditDialogControl" @create="handleCreate" @update="hanldeUpdate" />
  </div>
</template>

<script>
import EditDialog from './EditDialog.vue'
import { privateRebateConfigList, privateRebateConfigAdd, privateRebateConfigEdit, privateRebateConfigDelete, updatePaymentType } from '@/api/agent-rebate.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'

export default {
  name: 'IndexTab',
  components: { EditDialog },
  mixins: [TablePageMixin(privateRebateConfigList)],
  data() {
    return {
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
      defaultAgentType: [0, 1, 2, 3, 4, 5, 6],
      paymentType: 1,
      paymentTypes: this.$t('paymentTypes'),
      currentPaymentType: 1
    }
  },
  methods: {
    onCreate(row) {
      this.$refs.EditDialogControl.open(row, this.$store.getters.tenantCode)
    },
    handleSavePaymentType() {
      updatePaymentType({ paymentType: this.paymentType }).then(([_, _err]) => {
        if (!_err) {
          this.handleRefresh()
          this.$message.success(this.$t('保存成功'))
        }
      })
    },
    handleCreate({ field, cancel, close }) {
      privateRebateConfigAdd({ ...field, paymentType: this.currentPaymentType }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('保存成功'))
        } else {
          cancel()
        }
      })
    },

    hanldeUpdate({ field, cancel, close }) {
      privateRebateConfigEdit({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('保存成功'))
        } else {
          cancel()
        }
      })
    },

    handleDelete(id) {
      this.$confirm(this.$t('确认删除此配置?'), this.$t('系统提示'), { type: 'error' }).then(() => {
        privateRebateConfigDelete({ id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    implementationFetched(data) {
      if (data.items) {
        if (data.items.length > 0) {
          const firstItem = data.items[0]
          this.paymentType = firstItem.paymentType
          this.currentPaymentType = firstItem.paymentType
        }
      }
    }
  }
}
</script>
