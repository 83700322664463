import { requiredBlur, requiredChange, validateTimeInterval, stringLengcheck } from '@/utils/validation.js'
export const ruleForm = {
  memberId: '',
  memberName: '',
  nickname: '',
  forbiddenStatus: '0',
  relieveTime: '',
  content: ''
}

// form表单验证
export const rules = {
  memberName: requiredBlur,
  nickname: stringLengcheck({ min: 2, max: 10 }),
  forbiddenStatus: requiredChange,
  relieveTime: validateTimeInterval(),
  content: stringLengcheck({ min: 2, max: 50 })
}

export const typeOptions = { 0: '用户账户', 1: '用户昵称' }
