<template>
  <div class="app-container">

    <!-- <DropCollapse multiple @change="handleChangeTenant" /> -->

    <div class="filter-container">
      <div class="filter-container-item">
        <el-date-picker
          v-model="times"
          type="daterange"
          class="filter-item"
          style="width: 360px"
          :range-separator="$t('至')"
          :start-placeholder="$t('开始日期')"
          :end-placeholder="$t('结束日期')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        />
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </div>

    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template #left>
        <div class="filter-container">
          <div class="filter-container-item">
            <el-select v-model="search.status" class="filter-item" :placeholder="$t('状态')" clearable style="width: 120px">
              <el-option :label="$t('赛前')" :value="1" />
              <el-option :label="$t('赛中')" :value="2" />
              <el-option :label="$t('结束')" :value="3" />
            </el-select>
            <el-input v-model="search.competitionName" class="filter-item" :placeholder="$t('赛事名称')" clearable style="width: 160px" />
            <el-input v-model="search.teamName" class="filter-item" :placeholder="$t('球队名称')" clearable style="width: 200px" />
          </div>
        </div>
      </template>

      <!-- <template #right>
        <el-button v-if="permission.Update" type="success" icon="el-icon-tickets" @click="_getLogs">变赔记录</el-button>
      </template> -->
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <!-- <ChangeDialog ref="ChangeDialogControl" /> -->
    <ChangeOddsDialog ref="ChangeOddsDialogControl" @update="_handleUpdateOdds" />

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { ChangeOddsDialog } from './components'
import { getMatchList, updateMatch, updateMatchBaseOdds } from '@/api/lottery'
import { getDefaultTimes } from '@/utils'

const insetparams = { sportType: 1 }

export default {
  name: 'LotteryMatch',
  components: { ChangeOddsDialog },
  mixins: [TablePageMixin(getMatchList, true, insetparams)],
  data() {
    return {
      tenant: null,
      times: getDefaultTimes('date', -10, 10),
      columns: [],
      defaultColumns: GetColumns.call(this),
      search: {}
    }
  },
  methods: {
    _handleUpdate({ field, cancel, close }) {
      updateMatch({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleUpdateOdds({ field, cancel, close }) {
      updateMatchBaseOdds({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    _handleInvalid({ matchId, matchTime, tenantCode }) {
      this.$confirm(this.$t('确认取消此赛事'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        updateMatch({
          matchId,
          matchTime,
          lotteryStatus: 0,
          tenantCode
        }).then(([data, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    handleChangeTenant([tenant]) {
      this.tenant = tenant
      this.search.tenantCode = tenant.tenantCode
      this.handleRefresh()
    },
    implementationGetParams() {
      return {
        ...this.formatIntervalTime(),
        type: 2
      }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
