<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <span>VIP等级：</span>
        <el-select v-model="search.vipLevel" clearable :placeholder="$t('VIP等级')" class="filter-item"
          style="width: 160px">
          <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`"
            :value="item.vipLevel" />
        </el-select>
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询')
          }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置')
          }}</el-button>
        <!-- <el-button icon="el-icon-plus" class="filter-item" type="success" @click="_onCreate">{{ $t('添加等级') }}</el-button> -->
      </div>
    </div>
    <d-table ref="DTable" v-loading="loading" :data="items" :columns="defaultColumns" :original-columns="defaultColumns"
      :options="options" :filterColumn="false">

    </d-table>
    <!-- <ActionDialog ref="ActionDialogControl" @create="handleCreateLevel" />
    <edit-dialog ref="EditDialogControl" @create="handleCreate" @update="hanldeUpdate" /> -->
    <ActionDialog ref="ActionDialogControl" :allRewardList="allRewardList" @create="handleCreateLevel"
      @update="handleUpdateLevel" />

  </div>
</template>
<script>
import { getAppmemberlevelAwardCollection, setAppmemberlevelAwardCollection } from '@/api/vipRewardMange'
import { getAppAward, EditVipReward, updateAppAward, deleteAppAward, getAllAppAwardList } from "@/api/member";
import { getVIPAwards } from "@/api/member";
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { getAllVipLevels } from '@/api/app'
export default {
  name: 'LevelTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(getVIPAwards)],
  data() {
    return {
      search: {},
      defaultColumns: GetColumns.call(this),
      vipLevels: [],
      allRewardList: [],
    }
  },
  mounted() {
    this.implementationBeforeFetch()
  },
  methods: {
    _onCreate() {
      this.$refs.ActionDialogControl.open()
    },
    implementationBeforeFetch() {
      getAppAward({ currentPage: 1, pageSize: 50 }).then(([data, err]) => {
        if (!err) {
          this.allRewardList = data.filter(item => item.awardCode !== 'ACTIVITY_CENTER')
        }
      })
      getAllVipLevels().then(([data, err]) => {
        if (!err) {
          this.vipLevels = data
        }
      })
    },
    handleUpdateLevel({ field, cancel, close }) {
      
      setAppmemberlevelAwardCollection(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('修改成功')
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    handleDeleteAccount(id) {
      this.$confirm(this.$t('确认删除此设置?'), this.$t('系统提示'), { type: 'error' }).then(() => {
        deleteAppAward({ id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },
    handleCreate({ field, cancel, close }) {
      // createAgencyUser(field).then(([data, _err]) => {
      //   if (!_err) {
      //     close()
      //     this.$message.success(this.$t('添加成功'))
      //     this.handleFetch()
      //   } else {
      //     cancel()
      //   }
      // })
    },
    setUnit(item){
      const obj = {"GAME_REBATE":"%","POINTS_COLLECTION":"倍"}
      return obj[item.awardCode] || ''
    },
    handleCreateLevel({ field, cancel, close }) {
      //EditVipReward接口需要确认不同福利类型的content，/vipAward/create，
      field.awardList = field.awardList.filter((item) => field.checkList.includes(item.awardCode))
      console.log("@@@EditVipReward", field);
      try {
        field.awardList.forEach(element => {
          let parsedContent = element.content?JSON.parse(element.content):{}
         
          let { value, bgColor, bgImage,betsLimit,rechargeLimit,open,originValue } = element
          const changeValue = originValue===value?0:1
          parsedContent = {
            ...parsedContent,
            value, bgColor, bgImage,betsLimit,rechargeLimit,open,changeValue
          }
          element.content = JSON.stringify(parsedContent)
        });
      } catch (error) {
        console.log(error);
        cancel()
      }
      EditVipReward({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('编辑成功'))
        } else {
          cancel()
        }
      })
    },
  }
}
</script>
