<template>
  <el-dialog :title="title" :visible.sync="visible" width="460px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="160px" label-suffix=":">
      <!-- <el-form-item v-if="emitName === 'create'" label="代理ID" prop="agencyId">
        <el-input v-model="ruleForm.agencyId" style="width: 240px" />
      </el-form-item> -->

      <el-form-item :label="$t('代理账号')" prop="memberName">
        <el-input v-model="ruleForm.memberName" style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('代理线名称')" prop="domainName">
        <el-input v-model="ruleForm.domainName" style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('代理域名')" prop="domainUrl">
        <el-input v-model="ruleForm.domainUrl" style="width: 240px" />
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
// import { stringCE3_12 } from '@/utils/validation.js'
const getDefaultForm = () => ({
  memberName: '',
  domainName: '',
  domainUrl: ''
})
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        memberName: [
          { required: true, message: '代理账号', trigger: 'blur' }
          // { pattern: stringCE3_12, message: '代理账号格式不正确', trigger: 'blur' }
        ],
        domainName: [
          { required: true, message: '代理线名称', trigger: 'blur' }
        ],
        domainUrl: [
          { required: true, message: '代理域名', trigger: 'blur' }
        ]

      }
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? '添加代理线路' : '编辑代理线路'
    }
  },
  methods: {
    open(row, tenantCode) {
      this.ruleForm.tenantCode = tenantCode
      if (row) {
        Object.assign(this.ruleForm, row)
        this.emitName = 'update'
      } else {
        this.emitName = 'create'
      }
      this.visible = true
    }
  }
}
</script>
