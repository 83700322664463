var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("cascader-select", {
        attrs: {
          options: _vm.playClass,
          "parent-label": _vm.$t("一级玩法"),
          "child-label": _vm.$t("二级玩法"),
        },
        on: { change: _vm.onChangeSelect },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c(
        "d-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "DTable",
          attrs: {
            "row-key": "attributeTag",
            "drag-row": _vm.permission.Sort,
            "index-row": "",
            data: _vm.items,
            columns: _vm.columns,
            "original-columns": _vm.defaultColumns,
            options: _vm.options,
          },
          on: { "column-change": _vm.handleColumnChange },
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-right": "6px" },
                  on: { change: _vm.handleRefresh },
                  model: {
                    value: _vm.params.headerLang,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "headerLang", $$v)
                    },
                    expression: "params.headerLang",
                  },
                },
                _vm._l(_vm.allLang, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: index, attrs: { label: item.lang } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success", plain: "" },
                  on: { click: _vm._handleSave },
                },
                [_vm._v(_vm._s(_vm.$t("全部保存")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }