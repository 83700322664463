var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "400px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c("el-form-item", { attrs: { label: _vm.$t("会员账号") } }, [
            _c("p", [_vm._v(_vm._s(_vm.ruleForm.memberName))]),
          ]),
          _c("el-form-item", { attrs: { label: _vm.$t("当前打码量") } }, [
            _c("p", [_vm._v(_vm._s(_vm.ruleForm.sumRolling))]),
          ]),
          _c("el-form-item", { attrs: { label: _vm.$t("出款所需打码量") } }, [
            _c("p", [_vm._v(_vm._s(_vm.ruleForm.withdrawRolling))]),
          ]),
          _vm.transType === 6
            ? _c(
                "el-form-item",
                { attrs: { label: "变动类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.transType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "transType", $$v)
                        },
                        expression: "ruleForm.transType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 6 } }, [
                        _vm._v("出款打码量"),
                      ]),
                      _c("el-radio", { attrs: { label: 2012 } }, [
                        _vm._v("总打码量"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.transType === 7
            ? _c(
                "el-form-item",
                { attrs: { label: "变动类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.transType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "transType", $$v)
                        },
                        expression: "ruleForm.transType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 7 } }, [
                        _vm._v("出款打码量"),
                      ]),
                      _c("el-radio", { attrs: { label: 2013 } }, [
                        _vm._v("总打码量"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.transType === 6
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("增加打码量"), prop: "rolling" } },
                  [
                    _c("el-input", {
                      attrs: { label: "$t('请输入需增加的打码量')" },
                      model: {
                        value: _vm.ruleForm.rolling,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "rolling", $$v)
                        },
                        expression: "ruleForm.rolling",
                      },
                    }),
                    _vm.ruleForm.transType === 2012
                      ? _c("div", [_vm._v("默认增加到手动打码量")])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.transType === 7
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("减少打码量"), prop: "rolling" } },
                  [
                    _c("el-input", {
                      attrs: { label: _vm.$t("请输入需减少的打码量") },
                      model: {
                        value: _vm.ruleForm.rolling,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "rolling", $$v)
                        },
                        expression: "ruleForm.rolling",
                      },
                    }),
                    _vm.ruleForm.transType === 2013
                      ? _c("div", [
                          _vm._v(
                            "只能减少手动打码量余额 " +
                              _vm._s(_vm.sumManualRolling)
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("操作原因") } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: _vm.$t("请输入操作原因"),
                },
                model: {
                  value: _vm.ruleForm.operatorRemark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "operatorRemark", $$v)
                  },
                  expression: "ruleForm.operatorRemark",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }