var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "msg-item",
      class: _vm.me && _vm.item.msgType !== 105 && "msg-item-r",
    },
    [
      _vm.item.msgType === 201
        ? [
            _c("div", { staticClass: "muteMsg" }, [
              _vm._v(" " + _vm._s(_vm.item.msg) + " "),
            ]),
          ]
        : _vm.item.msgType == 206
        ? _c("div", { staticClass: "receive-red" }, [
            _c("img", {
              staticClass: "receive-img",
              attrs: { src: require("@/assets/public_images/red-packet.png") },
            }),
            _c("span", { staticClass: "van-ellipsis" }, [
              _vm._v(_vm._s(_vm.item.receiveNickName)),
            ]),
            _vm._v(" 领取了 "),
            _c("span", { staticClass: "van-ellipsis" }, [
              _vm._v(_vm._s(_vm.item.sendNickName)),
            ]),
            _vm._v(" 发的"),
            _c("span", { staticClass: "text-red" }, [_vm._v("红包")]),
          ])
        : _vm.item.msgType !== 110 &&
          _vm.item.msgType !== 204 &&
          _vm.item.msgType !== 10
        ? [
            _c(
              "div",
              {
                staticClass: "msg-avatar",
                on: {
                  click: function ($event) {
                    return _vm._doAction("reply")
                  },
                },
              },
              [
                _c("el-avatar", {
                  attrs: { size: "medium", src: _vm.item.avatar },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "msg-wrapper" }, [
              !_vm.me
                ? _c("div", { staticClass: "msg-user" }, [
                    _c("div", {
                      staticClass: "msg-vip",
                      class:
                        "vip-" +
                        (_vm.item.nickname == "管理员"
                          ? 60
                          : _vm.item.vipLevel),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "user-name",
                        on: {
                          click: function ($event) {
                            return _vm._doAction("reply")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.item.nickname) + "：")]
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "msg-body", class: _vm.me && "my-body" },
                [
                  _vm.item.msgType === 1
                    ? [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: _vm.item.content, fit: "cover" },
                        }),
                      ]
                    : [103, 104].includes(_vm.item.msgType)
                    ? [
                        _c("div", { staticClass: "order-wrap" }, [
                          _c("div", { staticClass: "order-state" }, [
                            _vm._v(" " + _vm._s(_vm.$t("跟单")) + " »"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "order-name" },
                            [
                              _c("span", [_vm._v(_vm._s(_vm.shareOrderName))]),
                              _vm.item.lotteryOrderBean && !_vm.betEnd
                                ? [
                                    _vm.item.lotteryOrderBean.lotteryTime
                                      ? _c("d-count-down", {
                                          attrs: {
                                            time: +_vm.item.lotteryOrderBean
                                              .lotteryTime,
                                          },
                                          on: { finish: _vm.finish },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (props) {
                                                  return [
                                                    props.hours === "00" &&
                                                    props.minutes === "00" &&
                                                    props.seconds === "00"
                                                      ? _c("div", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("已截止")
                                                              ) +
                                                              " "
                                                          ),
                                                        ])
                                                      : _c("div", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("截止")
                                                              ) +
                                                              ": " +
                                                              _vm._s(
                                                                props.hours
                                                              ) +
                                                              ":" +
                                                              _vm._s(
                                                                props.minutes
                                                              ) +
                                                              ":" +
                                                              _vm._s(
                                                                props.seconds
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1462128832
                                          ),
                                        })
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm.betEnd
                                ? [_vm._v(" " + _vm._s(_vm.$t("已截止")) + " ")]
                                : _vm.item.orderBean
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.parseTime(
                                            _vm.item.orderBean.betTime,
                                            "{m}-{d} {h}:{i}"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "order-predict" }, [
                            _vm.shareResult === "noResult"
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(_vm.$t("可赢")) + " "),
                                ])
                              : _vm._e(),
                            _c("img", {
                              attrs: { src: _vm.shareCurrencyIcon, alt: "" },
                            }),
                            _c("span", { class: _vm.shareResult }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    +_vm.shareShowWinLossAmount > 0
                                      ? "+" + _vm.shareShowWinLossAmount
                                      : _vm.shareShowWinLossAmount
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "order-type" }, [
                            _vm._v(_vm._s(_vm.shareTitle)),
                          ]),
                          _c("div", { staticClass: "order-detail" }, [
                            _vm._v(_vm._s(_vm.shareContent)),
                            _vm.shareScore
                              ? _c("span", { staticClass: "order-score" }, [
                                  _vm._v(_vm._s(_vm.shareScore)),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "order-bet" }, [
                            _vm._v(_vm._s(_vm.$t("投注"))),
                            _c("img", {
                              attrs: { src: _vm.shareCurrencyIcon, alt: "" },
                            }),
                            _vm._v(_vm._s(_vm.shareBetAmount)),
                          ]),
                          _vm.shareResultIcon
                            ? _c("div", { staticClass: "order-result" }, [
                                _c("img", {
                                  attrs: { src: _vm.shareResultIcon },
                                }),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    : _vm.item.msgType === 205
                    ? _c(
                        "div",
                        {
                          staticClass: "red-packet",
                          class: {
                            "empty-packet": _vm.item.receiveStatus !== 1,
                          },
                        },
                        [
                          _c("div", { staticClass: "packet-content" }, [
                            _vm._v(" " + _vm._s(_vm.item.receiveStatus) + " "),
                            _c("img", {
                              staticClass: "packet-img",
                              attrs: {
                                src:
                                  _vm.item.receiveStatus !== 1
                                    ? require("@/assets/public_images/empty-packet.png")
                                    : require("@/assets/public_images/red-packet.png"),
                                alt: "",
                              },
                            }),
                            _c("div", {}, [
                              _c(
                                "div",
                                { staticClass: "packet-title van-ellipsis" },
                                [_vm._v(_vm._s(_vm.item.redTitle))]
                              ),
                              _c("div", { staticClass: "tip" }, [
                                _vm._v(_vm._s(_vm.receiveStatusMsg(_vm.item))),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "type" }, [
                            _vm._v("拼手气红包"),
                          ]),
                        ]
                      )
                    : _vm.msgTypeList.includes(_vm.item.msgType)
                    ? [
                        _c("cardItem", {
                          attrs: { item: _vm.item, me: _vm.me },
                          on: { _doAction: _vm._doAction },
                        }),
                      ]
                    : [
                        _c("div", { staticClass: "msg-content" }, [
                          _vm.item.msgType === 0 && _vm.item.replayInfo
                            ? _c("span", { staticClass: "atName" }, [
                                _vm._v(
                                  " " +
                                    _vm._s("@" + _vm.item.replayInfo.nickname) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" " + _vm._s(_vm.showMessage) + " "),
                        ]),
                      ],
                  _c("i", {
                    staticClass: "el-icon-circle-close",
                    class: _vm.me && "my-close",
                    on: {
                      click: function ($event) {
                        return _vm._doAction("del")
                      },
                    },
                  }),
                ],
                2
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }