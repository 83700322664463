<template>
  <el-dialog :title="!isDownloading? '导出操作' : ''" width="600px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :visible.sync="visible" @closed="closeDialog">
    <div v-show="!isDownloading">
      <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-position="top">
        <el-form-item label="密码设置" prop="password">
          <el-radio-group v-model="setPassword" @change="changeSetPassword">
            <el-radio :label="true">设置</el-radio>
            <el-radio :label="false">不设置</el-radio>
          </el-radio-group>
          <el-input v-model="ruleForm.password" placeholder="请输入6位数字或字母组合" maxlength="6" :disabled="!setPassword" style="width: 200px; margin-left: 30px;" />
        </el-form-item>
        <el-form-item label="谷歌验证码" prop="googleCode">
          <el-input v-model="ruleForm.googleCode" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right;">
        <el-button type="primary" @click="submit">
          确认
        </el-button>
        <el-button @click="closeDialog">
          取消
        </el-button>
      </div>
    </div>
    <div v-show="isDownloading">
      <div class="title">
        {{ showTitle }} <i v-show="isDownloading && !hasError && !isFinish" class="el-icon-loading" />
      </div>
      <div class="downloadBtn">
        <el-button v-show="hasError" size="medium" type="primary" @click="handleRetry">
          重试
        </el-button>
        <el-button v-show="isFinish || hasError" size="medium" @click="closeDialog">
          {{ isFinish? '下载' : '关闭' }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { exportAgencyReportDataDetailList } from '@/api/agent-rebate'
import { exportExcel } from '@/utils'
const getDefaultForm = () => ({
  googleCode: '',
  password: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validPassword = (rule, value, callback) => {
      if (!value && this.setPassword) {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      emitName: 'create',
      setPassword: false,
      isDownloading: false,
      hasError: false,
      percentage: 0,
      isFinish: 0,
      progressInterval: undefined,
      blobData: undefined,
      checkAll: false,
      isIndeterminate: false,
      search: {},
      // total: 0,
      rules: {
        password: [{ validator: validPassword, trigger: ['blur', 'change'] }],
        googleCode: [
          { required: true, message: '请输入谷歌验证码', trigger: 'blur' },
          { pattern: /^[0-9]{6}$/, message: '请输入6位谷歌验证码', trigger: ['change', 'blur'] }
        ]
      }
    }
  },
  computed: {
    showTitle() {
      if (!this.isDownloading) {
        return ''
      } else if (this.hasError) {
        return '数据导出失败,请重试或关闭'
      } else if (!this.isFinish) {
        return '数据正在导出, 请稍后'
      } else {
        return '数据导出成功!'
      }
    }
  },
  destroyed() {
    this.clearProgressInterval()
  },
  methods: {
    open(search) {
      this.search = search
      this.isDownloading = false
      this.setPassword = false
      this.ruleForm.password = ''
      this.ruleForm.googleCode = ''
      this.isIndeterminate = true
      this.reset()
      this.visible = true
    },
    closeDialog() {
      if (this.isFinish && this.blobData) {
        let extension = 'csv'
        if (this.blobData.type === 'application/octet-stream') {
          extension = 'zip'
        }
        exportExcel(this.blobData, `代理数据${this.$parseTime(new Date(), '{y}{m}{d}{h}{i}{s}')}.${extension}`)
        this.blobData = undefined
      }
      this.clearProgressInterval()
      this.close()
    },
    clearProgressInterval() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval)
      }
    },
    handleRetry() {
      this.clearProgressInterval()
      this.reset()
      this.isDownloading = false
    },
    reset() {
      this.hasError = false
      this.percentage = 0
      this.setPassword = false
      this.blobData = undefined
      this.isFinish = false
    },
    changeSetPassword() {
      if (!this.setPassword) {
        this.ruleForm.password = ''
      }
    },
    submit() {
      this.$refs['validateForm'].validate(valid => {
        if (valid) {
          this.isDownloading = true
          this.isFinish = false
          const params = {
            ...this.search,
            googleCode: this.ruleForm.googleCode,
            password: this.ruleForm.password
          }

          exportAgencyReportDataDetailList(params).then(([data, error, blobData]) => {
            if (!error) {
              this.blobData = blobData
              // this.percentage = 100
              this.isFinish = true
              // this.clearProgressInterval()
              // exportExcel(blobData, `用户中心汇总导出-${this.$parseTime(new Date())}.xlsx`)
            } else {
              this.hasError = true
            }
          })
        }
      })
    }
  }
}
</script>
  <style lang="scss" scoped>
    .title {
      margin: auto;
      text-align: center;
      margin-bottom: 30px;
      font-size: 25px;
    }

    .progress {
      margin: auto;
      margin-bottom: 30px;
      width: 80%;
    }

    .downloadBtn {
      text-align: center;
    }

    .exportField {
      width: 100px;
    }

    .password {
      display: inline;
    }
  </style>

