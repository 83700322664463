import { numerical, integerNormal } from '@/utils/validation.js'

export const getRuleForm = () => ({
  activityId: '',
  rechargeTotal: '',
  rechargeDaily: '',
  betsTotal: null,
  betsDaily: '',
  vipLimitType: 0, // VIP限制：1开启限制 0不限制
  vipLimitList: [],
  isDaily: 1,
  modeType: 0,
  target: 0,
  accountType: 1,
  paywayType: 1,
  paywayIdList: [],
  thirdPaywayType: 1,
  thirdPaywayIdList: [],
  betsType: 1,
  betsType3: 0,
  betsType3Ball: 1,
  betsCompetitionIdsType1: 0,
  betsCompetitionIdsType2: 0,
  betsCompetitionIdsList1: [],
  betsCompetitionIdsList2: [],
  rechargeAmount: '',
  rechargeTimes: '',
  rechargeTimesAmount: '',
  betsAmount: '',
  betsTimes: '',
  betsTimesAmout: '',
  inviteNums: '',
  inviteRechargeAmount: '',
  inviteBetsAmount: '',
  liveSeeMinites: '',
  liveGiftAmount: '',
  liveRoomAmount: '',
  liveMsgNums: '',
  followBetsTimes: '',
  followBetsAmount: '',
  postsNums: '',
  postsCommentNums: '',
  postsLikeNums: '',
  postsSeeNums: '',
  oneCoupanList: [],
  twoCoupanList: [],
  threeCoupanList: [],
  fourCoupanList: [],
  fiveCoupanList: [],
  sixCoupanList: [],
  sevenCoupanList: [],
  fullCoupanList: [],
  oneAmount: '',
  twoAmount: '',
  threeAmount: '',
  fourAmount: '',
  fiveAmount: '',
  sixAmount: '',
  sevenAmount: '',
  fullAmount: '',
  oneRolling: '',
  twoRolling: '',
  threeRolling: '',
  fourRolling: '',
  fiveRolling: '',
  sixRolling: '',
  sevenRolling: '',
  fullRolling: ''
})

const rules = function() {
  const amountRule = { required: true, message: this.$t('请输入彩金'), trigger: 'blur' }
  const rollingRule = { required: true, message: this.$t('请输入打码倍数'), trigger: 'blur' }
  const requireNumerical = { pattern: numerical, message: '不小于0且最多三位小数的数', trigger: 'blur' }
  const requireIntegerNormal = { pattern: integerNormal, message: '请输入0~9,999,999整数', trigger: 'blur' }
  return {
    rechargeTotal: [{ required: true, message: this.$t('请输入总充值金额大于等于'), trigger: 'blur' }, requireNumerical],
    rechargeDaily: [{ required: true, message: this.$t('请输入今日充值总额大于等于'), trigger: 'blur' }, requireNumerical],
    betsTotal: [{ required: true, message: this.$t('请输入总投注金额大于等于'), trigger: 'blur' }, requireNumerical],
    betsDaily: [{ required: true, message: this.$t('请输入今日投注金额大于等于'), trigger: 'blur' }, requireNumerical],
    rechargeAmount: [{ required: true, message: this.$t('请输入充值成功金额'), trigger: 'blur' }, requireNumerical],
    rechargeTimes: [{ required: true, message: this.$t('请输入有效充值单数'), trigger: 'blur' }, requireIntegerNormal],
    rechargeTimesAmount: [{ required: true, message: this.$t('请输入充值金额大于等于才算有效充值单数'), trigger: 'blur' }, requireNumerical],
    betsAmount: [{ required: true, message: this.$t('请输入有效投注金额'), trigger: 'blur' }, requireNumerical],
    betsTimes: [{ required: true, message: this.$t('请输入有效注单数'), trigger: 'blur' }, requireIntegerNormal],
    betsTimesAmout: [{ required: true, message: this.$t('请输入注单投注金额大于才算有效注单数'), trigger: 'blur' }, requireNumerical],
    inviteNums: [{ required: true, message: this.$t('请输入邀请有效好友'), trigger: 'blur' }, requireIntegerNormal],
    inviteRechargeAmount: [{ required: true, message: this.$t('请输入有效好友条件充值金额大于等于'), trigger: 'blur' }, requireNumerical],
    inviteBetsAmount: [{ required: true, message: this.$t('请输入有效好友条件投注金额大于等于'), trigger: 'blur' }, requireNumerical],
    liveSeeMinites: [{ required: true, message: this.$t('请输入观看时长大于等于'), trigger: 'blur' }, requireIntegerNormal],
    liveGiftAmount: [{ required: true, message: this.$t('请输入送礼金额大于等于'), trigger: 'blur' }, requireNumerical],
    liveRoomAmount: [{ required: true, message: this.$t('请输入房费金额大于等于'), trigger: 'blur' }, requireNumerical],
    liveMsgNums: [{ required: true, message: this.$t('请输入发送消息大于等于'), trigger: 'blur' }, requireIntegerNormal],
    followBetsTimes: [{ required: true, message: this.$t('请输入跟单注单数大于等于'), trigger: 'blur' }, requireIntegerNormal],
    followBetsAmount: [{ required: true, message: this.$t('请输入跟单金额大于等于'), trigger: 'blur' }, requireNumerical],
    postsNums: [{ required: true, message: this.$t('请输入发帖数量大于等于'), trigger: 'blur' }, requireIntegerNormal],
    postsCommentNums: [{ required: true, message: this.$t('请输入评论数大于等于'), trigger: 'blur' }, requireIntegerNormal],
    postsLikeNums: [{ required: true, message: this.$t('请输入点赞数大于等于'), trigger: 'blur' }, requireIntegerNormal],
    postsSeeNums: [{ required: true, message: this.$t('请输入观看数大于等于'), trigger: 'blur' }, requireIntegerNormal],
    oneAmount: [amountRule, requireNumerical],
    oneRolling: [rollingRule, requireIntegerNormal],
    twoAmount: [amountRule, requireNumerical],
    twoRolling: [rollingRule, requireIntegerNormal],
    threeAmount: [amountRule, requireNumerical],
    threeRolling: [rollingRule, requireIntegerNormal],
    fourAmount: [amountRule, requireNumerical],
    fourRolling: [rollingRule, requireIntegerNormal],
    fiveAmount: [amountRule, requireNumerical],
    fiveRolling: [rollingRule, requireIntegerNormal],
    sixAmount: [amountRule, requireNumerical],
    sixRolling: [rollingRule, requireIntegerNormal],
    sevenAmount: [amountRule, requireNumerical],
    sevenRolling: [rollingRule, requireIntegerNormal],
    fullAmount: [amountRule, requireNumerical],
    fullRolling: [rollingRule, requireIntegerNormal]
  }
}

export const getRules = function() {
  return rules.call(this)
}

const DetailParams = function(field) {
  const {
    vipLimitType,
    vipLimitList,
    target,
    accountType,
    paywayType,
    paywayIdList,
    thirdPaywayType,
    thirdPaywayIdList,
    betsType3,
    betsType3Ball,
    betsCompetitionIdsType1,
    betsCompetitionIdsType2,
    betsCompetitionIdsList1,
    betsCompetitionIdsList2,
    oneCoupanList,
    twoCoupanList,
    threeCoupanList,
    fourCoupanList,
    fiveCoupanList,
    sixCoupanList,
    sevenCoupanList,
    fullCoupanList,
    oneAmount,
    twoAmount,
    threeAmount,
    fourAmount,
    fiveAmount,
    sixAmount,
    sevenAmount,
    fullAmount,
    oneRolling,
    twoRolling,
    threeRolling,
    fourRolling,
    fiveRolling,
    sixRolling,
    sevenRolling,
    fullRolling,
    ...other
  } = field
  let params = { ...other }
  params.vipLimit = ''
  if (vipLimitType === 1 && vipLimitList.length === 0) {
    this.$message.error(this.$t('请选择VIP限制等级'))
    return false
  } else if (vipLimitType === 1) {
    params.vipLimit = vipLimitList.toString()
  }

  switch (params.modeType) {
    case 0:
      params.paywayId = '-1' // 账号id, 多条以英文逗号分隔。 0标识不限制 -1标识未选择
      params.thirdPaywayId = '-1' // 账号id, 多条以英文逗号分隔。 0标识不限制 -1标识未选择
      if (target === 1) {
        if (accountType === 1) {
          if (paywayType === 1 && paywayIdList.length === 0) {
            this.$message.error(this.$t('请选择限制充值的公司账号'))
            return false
          } else if (paywayType === 1) {
            params.paywayId = paywayIdList.toString()
            params.paywayName = this.chinese + ':' + this.companyAccountList.filter(e => paywayIdList.indexOf(e.id) > -1).map(e => e.accountName).toString()
          } else {
            params.paywayId = '0'
          }
        } else if (accountType === 2) {
          if (thirdPaywayType === 1 && thirdPaywayIdList.length === 0) {
            this.$message.error(this.$t('请选择限制充值的第三方账号'))
            return false
          } else if (thirdPaywayType === 1) {
            params.thirdPaywayId = thirdPaywayIdList.toString()
            params.thirdPaywayName = this.chinese + ':' + this.thirdAccountList.filter(e => thirdPaywayIdList.indexOf(e.payPlatformCode) > -1).map(e => e.payPlatformName).toString()
          } else {
            params.thirdPaywayId = '0'
          }
        }
      }
      break
    case 1:
      switch (params.betsType) {
        case 1:
        case 2:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
          params.betsCompetitionIds = params.betsType + ':0'
          if (betsCompetitionIdsType1 === 1) {
            if (betsCompetitionIdsList1.length === 0) {
              this.$message.error(this.$t('请选择任务目标的限制项'))
              return false
            }
            params.betsCompetitionIds = params.betsType + ':' + betsCompetitionIdsList1.toString()
            getCompetitionNames(this, params, betsCompetitionIdsList1)
          }
          break
        case 3:
          params.betsCompetitionIds = params.betsType + ':-1;-1'
          if (betsType3 === 1) {
            if (betsType3Ball === 1) {
              params.betsCompetitionIds = params.betsType + ':0;-1'
              if (betsCompetitionIdsType1 === 1) {
                if (betsCompetitionIdsList1.length === 0) {
                  this.$message.error(this.$t('请选择任务目标的限制项'))
                  return false
                }
                params.betsCompetitionIds = params.betsType + ':' + betsCompetitionIdsList1.toString() + ';-1'
                getCompetitionNames(this, params, betsCompetitionIdsList1)
              }
            } else if (betsType3Ball === 2) {
              params.betsCompetitionIds = params.betsType + ':-1;0'
              if (betsCompetitionIdsType2 === 1) {
                if (betsCompetitionIdsList2.length === 0) {
                  this.$message.error(this.$t('请选择任务目标的限制项'))
                  return false
                }
                params.betsCompetitionIds = params.betsType + ':-1;' + betsCompetitionIdsList2.toString()
                getCompetitionNames(this, params, betsCompetitionIdsList2)
              }
            }
          }
          break
      }
      break
    case 2:
      break
    case 3:
      break
    case 4:
      break
    case 5:
      break
  }

  if (params.isDaily === 0) {
    params.oneCoupan = oneCoupanList.toString()
    params.oneAmountRolling = oneAmount + ',' + oneRolling
    params.twoCoupan = twoCoupanList.toString()
    params.twoAmountRolling = twoAmount + ',' + twoRolling
    params.threeCoupan = threeCoupanList.toString()
    params.threeAmountRolling = threeAmount + ',' + threeRolling
    params.fourCoupan = fourCoupanList.toString()
    params.fourAmountRolling = fourAmount + ',' + fourRolling
    params.fiveCoupan = fiveCoupanList.toString()
    params.fiveAmountRolling = fiveAmount + ',' + fiveRolling
    params.sixCoupan = sixCoupanList.toString()
    params.sixAmountRolling = sixAmount + ',' + sixRolling
    params.sevenCoupan = sevenCoupanList.toString()
    params.sevenAmountRolling = sevenAmount + ',' + sevenRolling
    params.fullCoupan = fullCoupanList.toString()
    params.fullAmountRolling = fullAmount + ',' + fullRolling
  } else {
    params.oneCoupan = oneCoupanList.toString()
    params.oneAmountRolling = oneAmount + ',' + oneRolling
    params.twoCoupan = ''
    params.twoAmountRolling = '0.000,1'
    params.threeCoupan = ''
    params.threeAmountRolling = '0.000,1'
    params.fourCoupan = ''
    params.fourAmountRolling = '0.000,1'
    params.fiveCoupan = ''
    params.fiveAmountRolling = '0.000,1'
    params.sixCoupan = ''
    params.sixAmountRolling = '0.000,1'
    params.sevenCoupan = ''
    params.sevenAmountRolling = '0.000,1'
    params.fullCoupan = ''
    params.fullAmountRolling = '0.000,1'
  }
  return params
}

const getCompetitionNames = (_this, params, betsList) => {
  const selTargetList = _this.targetList.filter(e => betsList.indexOf(e.targetId) > -1)
  const chineseNameList = selTargetList.map(e => e[_this.chinese])
  const englishNameList = selTargetList.map(e => e[_this.english])
  params.betsCompetitionNames = ''
  if (chineseNameList.length > 0) {
    params.betsCompetitionNames = _this.chinese + ':' + chineseNameList.toString()
  }
  if (englishNameList.length > 0) {
    if (params.betsCompetitionNames !== '') {
      params.betsCompetitionNames += ';'
    }
    params.betsCompetitionNames += _this.english + ':' + englishNameList.toString()
  }
}

export const getSaveDetailParams = function(field) {
  return DetailParams.call(this, field)
}
