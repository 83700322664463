<template>
  <div>
    <div class="filter-container">
      <el-select v-model="search.timeType" class="filter-item" style="width:160px">
        <el-option label="创建时间" :value="0" />
        <el-option label="操作时间" :value="1" />
      </el-select>
      <el-date-picker
        v-model="times"
        clearable
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7)">{{ $t('_7天') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearchWrap">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <div class="filter-container">
      <el-select v-model="search.memberType" clearable :placeholder="$t('用户类型')" class="filter-item" style="width:160px">
        <el-option v-for="item in memberTypes" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-select v-model="search.vipLevel" :placeholder="$t('用户等级')" clearable class="filter-item" style="width: 140px">
        <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
      </el-select>
      <el-input
        v-model="search.memberName"
        clearable
        :placeholder="$t('会员账号')"
        class="filter-item"
        style="width: 140px"
      />
      <el-select v-model="search.status" :placeholder="$t('订单状态')" clearable class="filter-item" style="width: 140px">
        <el-option v-for="item in withdrawStatus" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-input
        v-model="search.receivingBank"
        clearable
        :placeholder="$t('收款银行')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input v-model="search.orderNo" clearable :placeholder="$t('订单号')" class="filter-item" style="width: 200px" />
    </div>
    <div class="filter-container">
      <el-input
        v-model="search.operatorName"
        clearable
        :placeholder="$t('操作员')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model="search.lessThanAmount"
        clearable
        :placeholder="$t('提现金额小于')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model="search.greaterThanAmount"
        clearable
        :placeholder="$t('提现金额大于')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input v-model="search.proxyCode" :placeholder="$t('所属代理')" clearable class="filter-item" style="width: 140px" />
      <el-select v-model="search.paymentAgentId" placeholder="代付平台" clearable class="filter-item" style="width: 200px">
        <el-option v-for="item in agentList" :key="item.id" :label="item.payPlatformName" :value="item.id" />
      </el-select>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      selection-row
      row-key="orderNo"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
      @selection-change="handleSelectionChange"
    >
      <template slot="left">
        {{ $t('订单总额') }}：{{ sumAmount }}<template v-if="sumAmountGroupByStatus.length > 0">，</template>
        <span v-for="(item, index) in sumAmountGroupByStatus" :key="item.label">{{ item.status | labelFilter }}：{{
          item.sum
        }}<template v-if="sumAmountGroupByStatus.length - 1 > index">，</template></span>
      </template>
      <template slot="right">
        <el-button type="primary" plain @click="handleSelectAll">全&nbsp;&nbsp;&nbsp;&nbsp;选</el-button>
        <el-dropdown v-if="permission.Excel || permission.Txt" placement="top" trigger="click" @command="onCommandExport">
          <el-button type="primary" plain :loading="exportLoading" :disabled="!selection.length">{{ $t('导出选中') }}<i
            class="el-icon-arrow-down el-icon--right"
          /></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="permission.Excel" command="excel">{{ $t('导出Excel') }}</el-dropdown-item>
            <el-dropdown-item v-if="permission.Txt" command="txt">{{ $t('导出TXT') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

  </div>
</template>
<script>
import { withdrawStatus } from '@/libs/options'
import { getWithdrawList, getAgentListByTenantCode, editStatus } from '@/api/cash'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getDefaultTimes, getTodayTimestamps } from "@/utils";
import exportExcelTemplate from './exportExcelTemplate'

export default {
  name: 'ListTab',
  componentName: 'ListTab',
  filters: {
    labelFilter(status) {
      const tmp = withdrawStatus.find(o => o.key === status)
      return tmp && tmp.label
    }
  },
  mixins: [TablePageMixin(getWithdrawList,false)],
  data() {
    return {
      timer: null,
      exportLoading: false,
      selection: [],
      withdrawStatus,
      times: getDefaultTimes(),
      search: {},
      search: {
        orderNo: '',
        timeType: 0
      },
      sumAmount: '',
      sumAmountGroupByStatus: [],
      columns: [],
      defaultColumns: GetColumns.call(this),
      agentList: [],
      memberTypes: this.$t('memberTypes'),
    }
  },
  computed: {
    vipLevels() {
      return this.$parent.vipLevels
    }
  },
  created() {
    this.getAgentList()
  },
  activated() {
    const { orderNo, startTime } = this.$route.query;
    if (orderNo) this.search.orderNo = orderNo;
    if (startTime) {
      console.log(getTodayTimestamps(startTime).startOfDay,getTodayTimestamps(startTime).endOfDay)
      this.search.startTime = getTodayTimestamps(startTime).startOfDay;
      this.times.splice(0, 1, getTodayTimestamps(startTime).startOfDay);
      this.search.endTime = getTodayTimestamps(startTime).endOfDay;
      this.times.splice(1, 1,  getTodayTimestamps(startTime).endOfDay);
    }
    if (this.search.orderNo) {
      this.search.orderType = 1
    }
    this.handleSearch()
  },
  methods: {
    handleSearchWrap() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.handleSearch()
      }, 500)      
    },
    _onDetails({ memberId }) {
      if (!this.permission.Details) return
      this.$parent.$refs.UserDialogControl.open(memberId, this.permission.Phone)
    },
    handleSelectionChange(selection) {
      this.selection = selection
    },
    handleSelectAll() {
      this.$refs.DTable.$refs.elTable.toggleAllSelection()
    },

    onCommandExport(command) {
      if (command === 'excel') {
        this._exportExcel()
      } else if (command === 'txt') {
        this._exportTxt()
      }
    },
    _handleCancel(row) {
      this.$confirm(`请确认已通知三方不处理该笔提现订单，避免重复出款！！！确认后订单改为待审核，并由拾取人再次操作。`, '取消代付', { type: 'warning' }).then(() => {
        const data = {
          orderNo: row.orderNo,
          tenantCode: row.tenantCode,
          status: 2
        }
        editStatus(data).then(([_, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },
    _handleSuccess(row) {
      this.$confirm(`确认成功后将会完成代付流程，请谨慎操作！`, '确认代付', { type: 'warning' }).then(() => {
        const data = {
          orderNo: row.orderNo,
          tenantCode: row.tenantCode,
          status: 8
        }
        editStatus(data).then(([_, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },
    _exportExcel() {
      this.exportLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = exportExcelTemplate.map(oo => oo.title)
        const tKeys = exportExcelTemplate.map(oo => oo.key)
        const data = this.formatJson(tKeys)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename || this.$t('提现订单列表')
        })
        this.$refs.DTable.$refs.elTable.clearSelection()
        this.exportLoading = false
      })
    },

    _exportTxt() {
      import('@/vendor/Export2Excel').then(excel => {
        excel.export_table_to_txt({ data: this.selection })
        this.$refs.DTable.$refs.elTable.clearSelection()
      })
    },

    formatJson(filterVal) {
      return this.selection.map(v => filterVal.map(j => {
        if (j === 'createTime' || j === 'updateTime') {
          return this.$parseTime(v[j])
        } else if (j === 'status') {
          const tmp = this.withdrawStatus.find(o => o.key === v[j])
          return tmp && tmp.label || ''
        } else {
          return v[j] && String(v[j]) || ''
        }
      }))
    },
    implementationFetched({ sumAmount, sumAmountGroupByStatus }) {
      this.sumAmount = sumAmount || '0.000'

      const sumAmountGroup = [
        { fn: o => o.status === 4 || o.status === 8, status: 4 },
        { fn: o => o.status === 3 || o.status === 9, status: 3 },
        { fn: o => o.status === 7, status: 7 }
      ]

      this.sumAmountGroupByStatus = sumAmountGroup.map(o => {
        const filter = sumAmountGroupByStatus.filter(o.fn)
        const sum = filter.reduce((c, v) => {
          return (parseFloat(c) + parseFloat(v.sum)).toFixed(3)
        }, 0)
        return {
          sum,
          status: o.status
        }
      }).filter(t => !!t.sum)
    },
    getAgentList() {
      getAgentListByTenantCode().then(([data, err]) => {
        if (!err) {
          this.agentList = data || []
        }
      })
    },
    implementationGetParams() {
      if (Array.isArray(this.times) && this.times.length) {
        const [startTime, endTime] = this.times
        return {
          ...this.search,
          startTime,
          endTime
        }
      }
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
