/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout'

export default {
  path: '/Live',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Live',
  alwaysShow: true,
  meta: { title: '直播管理', icon: 'live' },
  children: [
    {
      path: '/Live_Management',
      name: 'LiveManagement',
      component: () => import('@/views/live/management'),
      meta: { title: '直播间管理', noCache: true }
    },
    {
      path: '/Live_Management_User/:roomId',
      name: 'LiveManagementUser',
      component: () => import('@/views/live/management-user'),
      hidden: true,
      meta: { title: '房间会员管理', noCache: true, activeMenu: '/Live_Management' }
    },
    {
      path: '/Live_Channel',
      name: 'LiveChannel',
      component: () => import('@/views/live/channel'),
      meta: { title: '频道管理', noCache: true }
    },
    {
      path: '/Live_Channel_Type',
      name: 'LiveChannelType',
      component: () => import('@/views/live/channel-type'),
      meta: { title: '频道分类', noCache: true }
    },
    {
      path: '/Live_Pre',
      name: 'LivePre',
      component: () => import('@/views/live/pre'),
      meta: { title: '预开播管理', noCache: true }
    },
    {
      path: '/Live_Cover',
      name: 'LiveCover',
      component: () => import('@/views/live/cover'),
      meta: { title: '彩票直播封面管理"', noCache: true }
    },
    // {
    //   path: '/Live_Label',
    //   name: 'LiveLabel',
    //   component: () => import('@/views/live/label'),
    //   meta: { title: '标签管理', noCache: true }
    // },
    {
      path: '/Live_Gift',
      name: 'LiveGift',
      component: () => import('@/views/live/gift'),
      meta: { title: '礼物管理', noCache: true }
    },
    {
      path: '/Live_Anchor',
      name: 'LiveAnchor',
      component: () => import('@/views/live/anchor'),
      meta: { title: '主播管理', noCache: true }
    },
    {
      path: '/Live_Family',
      name: 'LiveFamily',
      component: () => import('@/views/live/family'),
      meta: { title: '家族管理', noCache: true }
    },
    {
      path: '/Live_Family_Withdraw',
      name: 'LiveFamilyOrders',
      component: () => import('@/views/live/family-orders'),
      meta: { title: '家族提现管理', noCache: true }
    },
    {
      path: '/Live_Family_History',
      name: 'LiveFamilyOrderHistory',
      component: () => import('@/views/live/family-orders/order'),
      hidden: true,
      meta: { title: '家族提现明细', noCache: true, activeMenu: '/Live_Family_Withdraw' }
    },
    {
      path: '/Live_Broadcast_Details',
      name: 'LiveBroadcast',
      component: () => import('@/views/live/broadcast'),
      meta: { title: '开播明细查询', noCache: true }
    },
    {
      path: '/Live_Inspection',
      name: 'LiveInspection',
      component: () => import('@/views/live/inspection'),
      meta: { title: '直播间巡查管理', noCache: true }
    },
    {
      path: '/Live_Behavior_Restriction',
      name: 'LiveRestriction',
      component: () => import('@/views/live/restriction'),
      hidden: true,
      meta: { title: '行为限制管理', noCache: true }
    },
    
    {
      path: '/Live_Line',
      name: 'LiveServer',
      component: () => import('@/views/live/server'),
      meta: { title: '直播线路管理', noCache: true }
    }
  ]
}
