var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("入口开关"), prop: "openFlag" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-text": "开",
                  "inactive-text": "关",
                },
                model: {
                  value: _vm.ruleForm.openFlag,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "openFlag", $$v)
                  },
                  expression: "ruleForm.openFlag",
                },
              }),
              _c(
                "span",
                { staticStyle: { "padding-left": "20px", color: "#999999" } },
                [
                  _vm._v(
                    "关闭入口后,前台不再显示vip红包功能入口,正常情况下请勿关闭入口"
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "dmyq",
              attrs: { label: _vm.$t("打码要求"), prop: "rollingOpenFlag" },
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-text": "开",
                  "inactive-text": "关",
                },
                model: {
                  value: _vm.ruleForm.rollingOpenFlag,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "rollingOpenFlag", $$v)
                  },
                  expression: "ruleForm.rollingOpenFlag",
                },
              }),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "rollingFactor",
                    label: "打码倍数",
                    "label-width": "100px",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    on: {
                      input: function ($event) {
                        _vm.ruleForm.rollingFactor =
                          _vm.ruleForm.rollingFactor.replace(/[^\d]/g, "")
                      },
                    },
                    model: {
                      value: _vm.ruleForm.rollingFactor,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "rollingFactor", $$v)
                      },
                      expression: "ruleForm.rollingFactor",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: { "padding-left": "20px", color: "#999999" },
                    },
                    [_vm._v("开启后发的红包,需要满足打码倍数要求才能进行提现.")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("红包说明"),
                prop: "redEnvelopeDescription",
              },
            },
            [
              _c("tinymce", {
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.redEnvelopeDescription,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "redEnvelopeDescription", $$v)
                  },
                  expression: "ruleForm.redEnvelopeDescription",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("vip奖励描述"), prop: "awardDescription" },
            },
            [
              _c("tinymce", {
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.awardDescription,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "awardDescription", $$v)
                  },
                  expression: "ruleForm.awardDescription",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }