<template>
  <div class="app-container">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane v-for="tab of tabs" :key="tab.name" :label="tab.label" :name="tab.name" />
    </el-tabs>
    <component :is="activeName" />
  </div>
</template>

<script>
import { parentMixin } from '@/mixins/tab-items'
import GoodsConfig from './GoodsConfig'
import CategoryManage from './CategoryManage'
import OrderManage from './OrderManage'
import BaseConfig from './BaseConfig'

export default {
  name: 'pointsManage',
  components: { GoodsConfig, CategoryManage, OrderManage, BaseConfig },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      activeName: 'GoodsConfig',
      permissionTabs: [],
      tabs: [
        { label: this.$t('积分商品配置'), name: 'GoodsConfig' },
        { label: this.$t('商城类目管理'), name: 'CategoryManage' },
        { label: this.$t('积分订单管理'), name: 'OrderManage' },
        { label: this.$t('积分基础配置'), name: 'BaseConfig' }
      ]
    }
  },
  created() {
    this.activeName = this.$route.query.tab || 'GoodsConfig'
  }
}
</script>
