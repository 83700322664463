import { validURL } from '@/utils/validate'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('排序'),
      key: 'sortBy',
      align: 'center'
    },
    {
      title: this.$t('频道名称'),
      key: 'channelName',
      align: 'center',
      component: {
        render: (h, row) => {
          const vnodes = []
          row.items.forEach(e => {
            const lang = this.allLang.find(r => r.lang === e.langCode)
            if (lang && e.channelName) {
              vnodes.push(<div>{ this.$t(lang.name) + ':' + e.channelName }</div>)
            }
          })
          return (<span>{ vnodes }</span>)
        }
      }
    },
    {
      title: this.$t('频道图标'),
      key: 'channelIcon',
      align: 'center',
      component: {
        render: (h, row) => {
          if (validURL(row.channelIcon)) {
            return <el-image src={row.channelIcon} style='width: 45px; height: 45px' fit='cover' lazy></el-image>
          }
        }
      }
    },
    {
      title: this.$t('频道列表名称'),
      key: 'channelDesc',
      align: 'center',
      component: {
        render: (h, row) => {
          const vnodes = []
          row.items.forEach(e => {
            const lang = this.allLang.find(r => r.lang === e.langCode)
            if (lang && e.displayName) {
              vnodes.push(<div>{ this.$t(lang.name) + ':' + e.displayName }</div>)
            }
          })
          return (<span>{ vnodes }</span>)
        }
      }
    },
    {
      title: this.$t('数据来源'),
      key: 'dataSource',
      align: 'center',
      component: {
        render: (h, row) => {
          let dataSourceName = this.dataSource.find(e => e.key === '10').label
          if (row.dataSource !== '10') {
            dataSourceName = this.dataSource.find(e => e.key === '20').label
            const dataLive = this.dataSourceLive.find(e => e.key === row.dataSource)
            if (dataLive) {
              dataSourceName += '-' + dataLive.label
            }
          }
          return (<span>{ dataSourceName }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 200,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission
          return (
            <span>
              { Update && <el-button type='primary' icon='el-icon-edit-outline' onClick={() => this.$refs.ActionDialogControl.open(row)}>{this.$t('编辑')}</el-button> }
              { Delete && row.isDeletable === 0 && <el-button type='danger' icon='el-icon-delete' onClick={() => this._handleDelete(row)}>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return CanChooseColumns.call(this)
}
