var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            "label-position": "top",
            "label-width": "80px",
            model: _vm.ruleForm,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.title, prop: "isShow" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.isShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "isShow", $$v)
                    },
                    expression: "ruleForm.isShow",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("显示"))),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("隐藏"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("展示层级"), prop: "showGroups" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.showGroups,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "showGroups", $$v)
                    },
                    expression: "ruleForm.showGroups",
                  },
                },
                _vm._l(_vm.showGroups, function (item, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: item.value } },
                    [_vm._v(" " + _vm._s(item.label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("展示数据"), prop: "showDataTypes" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.showDataTypes,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "showDataTypes", $$v)
                    },
                    expression: "ruleForm.showDataTypes",
                  },
                },
                _vm._l(_vm.showDataTypes, function (item, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: item.value } },
                    [_vm._v(" " + _vm._s(item.label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: _vm.$t("相关权限") } }, [
            _c(
              "div",
              [
                _c("span", { staticStyle: { "margin-right": "6px" } }, [
                  _vm._v(_vm._s(_vm.$t("点击数据查看详情"))),
                ]),
                _c(
                  "d-switch",
                  {
                    attrs: {
                      size: "large",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.ruleForm.isShowDataDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "isShowDataDetail", $$v)
                      },
                      expression: "ruleForm.isShowDataDetail",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                      _vm._v("开启"),
                    ]),
                    _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                      _vm._v("禁止"),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("span", { staticStyle: { "margin-right": "32px" } }, [
                  _vm._v(_vm._s(_vm.$t("查看直属下级"))),
                ]),
                _c(
                  "d-switch",
                  {
                    attrs: {
                      size: "large",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.ruleForm.isShowNextLevel,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "isShowNextLevel", $$v)
                      },
                      expression: "ruleForm.isShowNextLevel",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                      _vm._v("开启"),
                    ]),
                    _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                      _vm._v("禁止"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v(_vm._s(_vm.$t("保存配置")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }