import Layout from '@/layout'

export default {
  path: '/Member',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Member',
  alwaysShow: true,
  meta: { title: '会员管理', icon: 'member' },
  children: [
    {
      path: '/Member_Users',
      component: () => import('@/views/member/Users'),
      name: 'MemberUsers',
      meta: { title: '用户管理' }
    },
    {
      path: '/Member_Bank',
      component: () => import('@/views/member/Bank'),
      name: 'MemberBank',
      meta: { title: '银行卡管理', noCache: true }
    },
    {
      path: '/Member_BankManage',
      component: () => import('@/views/member/BankManage'),
      name: 'MemberBankManage',
      meta: { title: '银行管理', noCache: true }
    },
    {
      path: '/Member_Slpay',
      component: () => import('@/views/member/Slpay'),
      name: 'MemberSlpay',
      meta: { title: '钱包地址管理', noCache: true }
    }
  ]
}

