<template>
  <div>
    <div class="filter-container">
      <el-select v-model="search.vipLevel" :placeholder="$t('VIP等级')" clearable class="filter-item" style="width: 120px">
        <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
      </el-select>
      <el-input v-model="search.memberName" clearable :placeholder="$t('会员账号')" class="filter-item" style="width: 120px" />
      <el-input v-model="search.realName" clearable :placeholder="$t('真实姓名')" class="filter-item" style="width: 120px" />
      <el-input v-model="search.payingName" clearable :placeholder="$t('转账姓名')" class="filter-item" style="width: 120px" />
      <el-input v-model="search.postscript" clearable :placeholder="$t('附言')" class="filter-item" style="width: 120px" />
      <el-button class="filter-item" type="primary" @click="handleFilter">
        {{ $t('确认条件') }}
      </el-button>
      <el-button v-if="permission.Receive" :disabled="this.socket.domainUrl === ''" :loading="isConnecting" :type="socket.isConnected ? 'danger' : 'primary'" @click="handleConnect">
        {{ isConnecting ? $t('连接中') : socket.isConnected ? $t('停止接收') : $t('开始接收') }}
      </el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <div class="filter-container">
      <el-input v-model="search.receivingBank" clearable :placeholder="$t('收款银行')" class="filter-item" style="width: 120px" />
      <el-input v-model="search.greaterThanAmount" clearable :placeholder="$t('充值金额大于')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.lessThanAmount" clearable :placeholder="$t('充值金额小于')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.receivingCardNo" clearable :placeholder="$t('收款钱包地址')" class="filter-item" style="width: 240px" />
      <el-select v-model="search.type" :placeholder="$t('充值方式')" clearable class="filter-item" style="width: 120px">
        <el-option :label="$t('数字货币')" :value="0" />
        <el-option :label="$t('银行卡')" :value="1" />
      </el-select>
    </div>

    <div class="filter-container">
      <el-checkbox-group v-model="search.receivingCardNos" size="medium">
        <el-checkbox v-for="item in PayReceivingAccountList" :key="item.id" :label="item.receivingCardNo" border>
          <p>{{ item.reveivingName }}</p>
          <p>{{ item.receivingBank }}</p>
          <p>{{ item.receivingCardNo }}</p>
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      row-key="orderNo"
      @refresh="handleFixedRefresh"
      @column-change="handleColumnChange"
      @selection-change="handleSelectionChange"
    >
      <div slot="left">{{ $t('选中') }}{{ selection.length }}{{ $t('条') }}  {{ $t('合计金额') }}：{{ multipleSelectionRecharge }}元</div>
      <template slot="right">
        <el-button type="primary" plain @click="handleSelectAll">{{ $t('全选') }}</el-button>
        <el-button v-if="permission.Yes" type="primary" :disabled="!selection.length" plain @click="_onAudit(true)">{{ $t('通过所选') }}</el-button>
        <el-button v-if="permission.NOT" type="danger" :disabled="!selection.length" plain @click="_onAudit(false)">{{ $t('拒绝所选') }}</el-button>
        <el-button v-if="permission.Excel" type="primary" :disabled="!selection.length" plain :loading="exportLoading" @click="handleExcelSelect">{{ $t('导出所选') }}(excel)</el-button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleSocketFetch" />

    <BatchDialog ref="BatchDialogControl" @update="_handleBatchAudit" />
    <AloneDialog ref="AloneDialogControl" @update="_handleAloneAudit" />
    <ImagePreview ref="ImagePreviewControl" />

  </div>
</template>
<script>
import clip from '@/utils/clipboard'
import SocketEmmiterMixin from '@/mixins/socket-emmiter'
import { auditPayOrder, batchAuditPayOrder, getPayReceivingAccountList } from '@/api/cash'
import GetColumns from './columns'
import { rechargeStatus } from '@/libs/options'
import { AloneDialog, BatchDialog } from './components'
import ImagePreview from '@/components/ImagePreview'

import exportExcelTemplate from './exportExcelTemplate'

// const insetparams = {
//   orderType: '1',
//   status: '1111'
// }
export default {
  name: 'CheckTab',
  componentName: 'CheckTab',
  components: { AloneDialog, BatchDialog, ImagePreview },
  mixins: [SocketEmmiterMixin(100)],
  data() {
    return {
      exportLoading: false,
      selection: [],
      columns: [],
      defaultColumns: GetColumns.call(this),
      search: {
        receivingCardNos: []
      },
      defaultSearch: { receivingCardNo: [] },
      PayReceivingAccountList: [],
      rechargeStatus
    }
  },
  computed: {
    multipleSelectionRecharge() {
      if (this.selection.length) { return this.selection.map(o => Number(o.amount)).reduce((prev, curr) => prev + curr) } else return 0
    },
    vipLevels() {
      return this.$parent.vipLevels
    }
  },
  mounted() {
    this.implementationTenantChanged()
  },
  methods: {
    // 重置条件
    resetSearch() {
      this.search = this.$options.data.call(this).search
    },
    clip(text, e) {
      e.stopPropagation()
      clip(text, e)
    },
    _onDetails({ memberId }) {
      if (!this.permission.Details) return
      this.$parent.$refs.UserDialogControl.open(memberId, this.permission.Phone)
    },
    _onAudit(type) {
      this.$refs.BatchDialogControl.open(this.selection, type)
    },

    _handleBatchAudit({ field, cancel, close }) {
      const data = field.data.map(o => ({ ...o, remark: field.remark }))
      batchAuditPayOrder(data).then(([_, err]) => {
        if (!err) {
          close()
        } else {
          cancel()
        }
      })
    },

    _handleAloneAudit({ field, cancel, close }) {
      auditPayOrder({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
        } else {
          cancel()
        }
      })
    },

    handleSelectionChange(selection) {
      this.selection = selection
    },
    handleSelectAll() {
      this.$refs.DTable.$refs.elTable.toggleAllSelection()
    },
    handleExcelSelect() {
      this._export()
    },

    _export() {
      this.exportLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = exportExcelTemplate.map(oo => oo.title)
        const tKeys = exportExcelTemplate.map(oo => oo.key)
        const data = this.formatJson(tKeys)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename || this.$t('充值审核列表')
        })
        this.$refs.DTable.$refs.elTable.clearSelection()
        this.exportLoading = false
      })
    },

    formatJson(filterVal, jsonData) {
      return this.selection.map(v => filterVal.map(j => {
        if (j === 'createTime' || j === 'updateTime') {
          return this.$parseTime(v[j])
        } else if (j === 'status') {
          const tmp = this.rechargeStatus.find(o => o.key === v[j])
          return tmp && tmp.label || ''
        } else {
          return v[j] && String(v[j]) || ''
        }
      }))
    },

    implementationTenantChanged() {
      getPayReceivingAccountList({ currentPage: 1, pageSize: 100, status: 0 }).then(([data, err]) => {
        if (!err) {
          this.PayReceivingAccountList = data
        }
      })
    },

    implementationGetParams() {
      return { ...this.search, receivingCardNos: this.search.receivingCardNos.join(',') }
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
