var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "图片预览",
        width: "35%",
        top: "5vh",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [_vm.src ? _c("img", { attrs: { src: _vm.src, width: "100%" } }) : _vm._e()]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }