<template>
  <el-dialog :title="title" :visible.sync="visible" width="460px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="160px" label-suffix=":">

      <!-- <el-form-item :label="$t('域名分组')" prop="domainType">
        <el-select
          v-model="ruleForm.domainType"
          clearable
          :placeholder="$t('域名分组')"
          class="filter-item"
          style="width: 240px"
        >
          <el-option label="所有" :value="0" />
          <el-option label="9b下载页" :value="1" />
        </el-select>
      </el-form-item> -->

      <el-form-item :label="$t('名称')" prop="domainName">
        <el-input v-model="ruleForm.domainName" :placeholder="$t('请输入名称以便后续跟踪')" style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('链接')" prop="domainUrl">

        <el-input v-model="ruleForm.domainUrl" :placeholder="$t('请输入链接以便生成二维码')" style="width: 240px" />
        <!-- <input type="file" accept="image/*" @input="decodeQRCode"> -->
        <el-upload
          :before-upload="decodeQRCode"
          action="/api/upload"
          style="margin-top: 8px;"
          :limit="1"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">可以上传二维码图片以提取链接地址</div>
        </el-upload>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import Qrcode from 'qrcode-reader'
const getDefaultForm = () => ({
  domainType: 1,
  domainUrl: ''
})
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  // props: ['scopeList'],
  data() {
    return {
      emitName: 'create',
      rules: {
        domainName: [
          { required: true, message: '名称', trigger: 'blur' }
        ],
        domainType: [
          { required: true, message: '域名分组', trigger: 'blur' }
        ],
        domainUrl: [
          { required: true, message: '二维码生产所需链接', trigger: 'blur' }
        ]

      },
      fileList: []
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? '添加链接' : '编辑链接'
    }

  },

  methods: {

    open(row, tenantCode) {
      this.ruleForm.domainUrl = ''
      this.visible = true
    },
    decodeQRCode(file) {
      // const file = event.target.files[0]
      if (file) {
        const qr = new Qrcode()

        const reader = new FileReader()
        reader.onload = (e) => {
          const image = new Image()
          image.src = e.target.result
          image.onload = () => {
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            canvas.width = image.width
            canvas.height = image.height
            context.drawImage(image, 0, 0, image.width, image.height)
            const imageData = context.getImageData(0, 0, image.width, image.height)
            qr.callback = (a, res) => {
              if (res && res.result) {
                Object.assign(this.ruleForm, { domainUrl: res.result })
                // this.$set(this.ruleForm, { domainUrl: res.result })
              }
              else {
                this.$message.info(this.$t('没有解析到有效的内容'))
              }
            }
            qr.decode(imageData)
          }
        }
        reader.readAsDataURL(file)
      }
      return false
    }
  }
}
</script>
<style lang="scss">
.lang-pop {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
