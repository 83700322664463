var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-tab", staticStyle: { width: "800px" } },
    [
      _c("h3", [_vm._v(_vm._s(_vm.$t("热门推荐")))]),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            margin: "10px 0",
            "align-items": "center",
          },
        },
        [
          _c(
            "h5",
            { staticStyle: { margin: "0" } },
            [
              _vm._v(" " + _vm._s(_vm.$t("热门游戏排序")) + "："),
              _c(
                "el-select",
                {
                  on: { change: _vm.getSetting },
                  model: {
                    value: _vm.selType,
                    callback: function ($$v) {
                      _vm.selType = $$v
                    },
                    expression: "selType",
                  },
                },
                _vm._l(_vm.typeList, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { value: +item.key, label: item.lable },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", loading: _vm.submitting },
                  on: { click: _vm.addItems },
                },
                [_vm._v(_vm._s(_vm.$t("添 加")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("保 存")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "elTable",
          attrs: {
            border: "",
            data: _vm.items,
            "row-key": "rowKey",
            "drag-row": true,
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { type: "index", align: "center", width: "60" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.$t("排序")))]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("svg-icon", {
                attrs: { "icon-class": "drag", "class-name": "drag-handler" },
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { label: _vm.$t("彩种/厂商选择"), align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { clearable: "", filterable: "" },
                        on: {
                          change: function (value) {
                            return _vm.onChangeSeries(value, $index)
                          },
                        },
                        model: {
                          value: row.seriesTag,
                          callback: function ($$v) {
                            _vm.$set(row, "seriesTag", $$v)
                          },
                          expression: "row.seriesTag",
                        },
                      },
                      _vm._l(_vm.cpSeriesList, function (series) {
                        return _c("el-option", {
                          key: series.seriesTag,
                          attrs: {
                            value: series.seriesTag,
                            label: series.seriesName,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("游戏选择"), align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          clearable: "",
                          filterable: "",
                          placeholder: _vm.$t("请选择游戏"),
                        },
                        on: {
                          change: function (value) {
                            return _vm.onChangeLottery(value, $index)
                          },
                        },
                        model: {
                          value: row.lotteryId,
                          callback: function ($$v) {
                            _vm.$set(row, "lotteryId", $$v)
                          },
                          expression: "row.lotteryId",
                        },
                      },
                      _vm._l(row.lotteryList, function (lottery) {
                        return _c("el-option", {
                          key: lottery.lotteryId,
                          attrs: {
                            value: lottery.lotteryId,
                            label: lottery.name,
                            disabled: _vm.checkPass(
                              row.seriesTag,
                              lottery.lotteryId
                            ),
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.selType === 1
            ? _c("el-table-column", {
                attrs: { label: _vm.$t("图标"), align: "center", width: "140" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("d-upload", {
                            attrs: {
                              folder: "icon",
                              "on-success": function () {
                                var args = [],
                                  len = arguments.length
                                while (len--) args[len] = arguments[len]

                                return _vm.handleUploadSuccess(args, row)
                              },
                              width: 86,
                              height: 86,
                            },
                            model: {
                              value: row.hotIcon,
                              callback: function ($$v) {
                                _vm.$set(row, "hotIcon", $$v)
                              },
                              expression: "row.hotIcon",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2766640765
                ),
              })
            : _vm._e(),
          _vm.selType === 1
            ? _c("el-table-column", {
                attrs: { label: _vm.$t("操作"), align: "center", width: "140" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  row.seriesTag = null
                                  row.lotteryId = null
                                  row.hotIcon = null
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1404645517
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.items.length > 20
        ? _c(
            "div",
            { staticStyle: { padding: "10px 0" }, attrs: { align: "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", loading: _vm.submitting },
                  on: { click: _vm.addItems },
                },
                [_vm._v(_vm._s(_vm.$t("添 加")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("保 存")))]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }