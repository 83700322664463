<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.id" clearable maxlength="30" :placeholder="$t('任务ID')" class="filter-item"
        style="width: 140px" />
      <el-input v-model="search.activityTitle" clearable maxlength="30" :placeholder="$t('任务标题')" class="filter-item"
        style="width: 140px" />
      <el-select v-model="search.activityStatus" clearable :placeholder="$t('任务状态')" class="filter-item"
        style="width: 140px">
        <el-option v-for="item in $t('taskStatus')" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-select v-model="search.activityType" clearable :placeholder="$t('任务分类')" class="filter-item"
        style="width: 140px">
        <el-option v-for="item in activityTypeList" :key="item.id" :value="item.id" :label="item.typeName" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询')
        }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置')
        }}</el-button>
      <el-button v-if="permission.Add" style="float:right" type="success" icon="el-icon-plus" class="filter-item"
        @click="onCreate">{{ $t('新增任务') }}</el-button>
    </div>
    
    <d-table ref="DTable" v-loading="loading" :data="items" :columns="columns" :options="options"
      :filter-column="filterColumn" />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total"
      @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="handleCreateActivity" @update="handleUpdateActivity" />
    <DetailDialog ref="DetailDialogControl" @create="handleAddUpdateActivityDetail" />
  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import { getActivityList, addActivity, updateActivity, updateActivityStatus, addUpdateActivityDetail, getActivityTypeList } from '@/api/activity'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import DetailDialog from './DetailDialog'
import { getSaveDetailParams } from './params'
import { mapState } from 'vuex'

export default {
  name: 'ManageTab',
  components: { ActionDialog, DetailDialog },
  mixins: [TablePageMixin(getActivityList)],
  props: {
    id:''
  },
  data() {
    return {
      search: {
        id:''
      },
      filterColumn: false,
      columns: GetColumns.call(this),
      activityTypeList: []
    }
  },
  computed: {
    ...mapState('app', ['allLang'])
  },
  created() {
    if (!this.search.id) {
      this.search.id = this.$props.id;
    }
    this.getTypeList()
  },
  mounted(){
    this.$nextTick(()=>{
      setTimeout(() => {
        this.handleSearch()
      });
    })
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogControl.open()
    },
    handleCreateActivity({ field, cancel, close }) {
      const params = this.getSaveParams(field)
      if (params === false) {
        cancel()
        return
      }
      addActivity(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    handleUpdateActivity({ field, cancel, close }) {
      const params = this.getSaveParams(field)
      if (params === false) {
        cancel()
        return
      }
      updateActivity(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('修改成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    getSaveParams(field) {
      const { activityTitleList, activityTimeType, activityDate, ...other } = field
      let params = { ...other }
      const list = []
      const lang = []
      activityTitleList.forEach(e => {
        if (e.activityTitle) {
          list.push(e.lang + ':' + e.activityTitle)
          lang.push(e.lang)
        }
      })
      if (list.length === 0) {
        this.$message.warning(this.$t('至少需要录入一种语言的任务名称'))
        return false
      }
      params.activityTitle = list.toString()
      params.lang = lang.toString()

      if (activityTimeType === 0) {
        params.activityBeginDate = activityDate[0]
        params.activityEndDate = activityDate[1]
      } else {
        params.activityBeginDate = new Date().valueOf()
        params.activityEndDate = new Date().valueOf() + 4 * 365 * 24 * 60 * 60 * 1000
      }
      return params
    },

    handleCloseActivity({ id, activityStatus }) {
      this.$confirm(`${this.$t('确定')} ${activityStatus === 1 ? this.$t('结束') : this.$t('开启')} ${this.$t('任务')}?`, this.$t('系统提示'), { type: 'warning' }).then(() => {
        updateActivityStatus({ id, activityStatus: activityStatus === 1 ? 0 : 1 }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },

    handleAddUpdateActivityDetail({ field, cancel, close }) {
      const params = getSaveDetailParams.call(this.$refs.DetailDialogControl, field)
      if (params === false) {
        cancel()
        return
      }
      addUpdateActivityDetail(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    getTypeList() {
      getActivityTypeList({ currentPage: 1, pageSize: 100, isShow: 1 }).then(([_, err, data]) => {
        if (!err) {
          const { items } = data.data
          if (items.length > 0) {
            const list = []
            items.forEach(e => {
              const typeNameList = e.typeName.split(',')
              list.push({
                id: e.id,
                typeName: typeNameList[0].split(':')[1],
                typeNameLang: e.typeName
              })
            })
            this.activityTypeList = list
          }
        }
      })
    },

    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
