var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("Member_Expert.ExpertTab.编辑专家"),
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.ExpertTab.专家简介"),
                prop: "introduction",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "500",
                  clearable: "",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.ruleForm.introduction,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "introduction", $$v)
                  },
                  expression: "ruleForm.introduction",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.ExpertTab.专家等级"),
                prop: "positionId",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$t("请选择", {
                      text: _vm.$t("Member_Expert.ExpertTab.专家等级"),
                    }),
                  },
                  model: {
                    value: _vm.ruleForm.positionId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "positionId", $$v)
                    },
                    expression: "ruleForm.positionId",
                  },
                },
                _vm._l(_vm.positionList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.positionName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.ExpertTab.总胜率"),
                prop: "initialWinrate",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("请输入m_n区间数字", { text: "1-100" }),
                },
                scopedSlots: _vm._u([
                  {
                    key: "suffix",
                    fn: function () {
                      return [_vm._v("%")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.ruleForm.initialWinrate,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "initialWinrate", _vm._n($$v))
                  },
                  expression: "ruleForm.initialWinrate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.ExpertTab.总返奖率"),
                prop: "initialReturnrate",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("请输入m_n区间数字", { text: "1-100" }),
                },
                scopedSlots: _vm._u([
                  {
                    key: "suffix",
                    fn: function () {
                      return [_vm._v("%")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.ruleForm.initialReturnrate,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "initialReturnrate", _vm._n($$v))
                  },
                  expression: "ruleForm.initialReturnrate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.ExpertTab.最高连胜"),
                prop: "initialLinkwin",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("请输入m_n区间数字", { text: "1-10" }),
                },
                model: {
                  value: _vm.ruleForm.initialLinkwin,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "initialLinkwin", _vm._n($$v))
                  },
                  expression: "ruleForm.initialLinkwin",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.ExpertTab.推荐次数"),
                prop: "initialArtcileNum",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("请输入m_n区间数字", { text: "1-10" }),
                },
                model: {
                  value: _vm.ruleForm.initialArtcileNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "initialArtcileNum", _vm._n($$v))
                  },
                  expression: "ruleForm.initialArtcileNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.ExpertTab.粉丝数"),
                prop: "initialFansNum",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("请输入m_n区间数字", { text: "1-10" }),
                },
                model: {
                  value: _vm.ruleForm.initialFansNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "initialFansNum", _vm._n($$v))
                  },
                  expression: "ruleForm.initialFansNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.ExpertTab.擅长联赛"),
                prop: "greatGameIds",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    multiple: "",
                    filterable: "",
                    "multiple-limit": 3,
                    placeholder: _vm.$t("请选择", {
                      text: _vm.$t("Member_Expert.ExpertTab.擅长联赛"),
                    }),
                  },
                  on: { change: _vm.onChangeGames },
                  model: {
                    value: _vm.ruleForm.greatGameIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "greatGameIds", $$v)
                    },
                    expression: "ruleForm.greatGameIds",
                  },
                },
                _vm._l(_vm.competitionList, function (item) {
                  return _c("el-option", {
                    key: item.competitionId,
                    attrs: {
                      label: item.shortCompetitionName,
                      value: item.competitionId,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确定")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }