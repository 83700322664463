<template>
  <div class="app-container">
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <div slot="left" class="filter-container">
        <el-select v-model="search.roomStatus" :placeholder="$t('直播状态')" clearable class="filter-item" style="width: 120px;">
          <el-option :label="$t('直播中')" :value="1" />
          <el-option :label="$t('已下播')" :value="9" />
        </el-select>
        <el-input v-model="search.accLogin" :placeholder="$t('主播账号')" clearable class="filter-item" style="width: 150px;" />
        <el-input v-model="search.nickName" :placeholder="$t('主播昵称')" clearable class="filter-item" style="width: 160px;" />
        <el-input v-model="search.familyName" :placeholder="$t('家族名称')" clearable class="filter-item" style="width: 180px;" />
        <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleRefresh">{{ $t('查询') }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </div>
      <el-button v-if="permission.Add" slot="right" type="success" icon="el-icon-plus" @click="onCreate">{{ $t('新增主播') }}</el-button>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <CreateDialog ref="CreateDialogControl" @create="_handleCreate" />
    <UpdateDialog ref="UpdateDialogControl" @update="_handleUpdate" />
    <ChangeDialog ref="ChangeDialogControl" @change="_handleChange" />
  </div>
</template>

<script>
import {
  getAnchorList,
  doSaveAnchor,
  doUpdateAnchor,
  doUpdateAnchorPassword,
  doUpdateAnchorStatus,
  doUpdateAnchorRoomStatus
} from '@/api/live'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { CreateDialog, UpdateDialog, ChangeDialog } from './components'
export default {
  name: 'LiveAnchor',
  components: { CreateDialog, UpdateDialog, ChangeDialog },
  mixins: [TablePageMixin(getAnchorList)],
  data() {
    return {
      search: {},
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  methods: {
    onCreate() {
      this.$refs.CreateDialogControl.open()
    },
    _handleCreate({ field, cancel, close }) {
      doSaveAnchor({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      doUpdateAnchor({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleChange({ field, cancel, close }) {
      doUpdateAnchorPassword({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('修改成功'))
        } else {
          cancel()
        }
      })
    },
    _handleChangeStatus({ anchorAccno, accLogin, accStatus }) {
      let message = ''
      if (accStatus === 9) message = this.$t('确认启用此主播账号吗?')
      if (accStatus === 1) message = this.$t('确认禁用该主播账号吗???禁用后该账号退出后将不可再登录!')

      return new Promise((resolve) => {
        this.$confirm(message, this.$t('系统提示'), {
          center: true,
          type: 'warning'
        }).then(() => {
          doUpdateAnchorStatus({
            anchorAccno,
            accLogin,
            accStatus: accStatus === 9 ? 1 : 9
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    _handleChangePermissionStatus({ anchorAccno, accLogin, forbidStatus }) {
      // 确认禁播该主播账号吗？禁用后该账号将立即下播且不可再开播
      return new Promise((resolve) => {
        this.$confirm(forbidStatus === 9 ? this.$t('确认启用此主播开播权限?') : this.$t('确认禁用此主播开播权限?'), this.$t('系统提示'), {
          center: true,
          type: 'warning'
        }).then(() => {
          doUpdateAnchorRoomStatus({
            anchorAccno,
            accLogin,
            roomStatus: forbidStatus === 9 ? 1 : 9
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
