<template>
  <el-dialog :title="titleObj.title" width="1000px" :visible="dialogFormVisible" @close="_close">
    <el-form ref="ruleForm" v-loading="dialogloading" :model="ruleForm" :rules="rules" label-width="80px" class="demo-ruleForm">
      <el-form-item label="图标" prop="icon">
        <div class="avatar-upload" @click="imagecropperShow = true">
          <img v-if="ruleForm.icon !== ''" :src="ruleForm.icon" alt="logo">
          <span v-else class="text_">+</span>
        </div>
      </el-form-item>
      <el-form-item label="银行" prop="name">
        <!-- <el-select v-model="ruleForm.name" :disabled="titleObj.type !== 'add'" filterable placeholder="请选择银行">
          <el-option v-for="(item, index) in bankList" :key="index" :label="item" :value="item" />
        </el-select> -->
        <el-input v-model="ruleForm.name" :disabled="titleObj.type !== 'add'" placeholder="请输入银行名称" clearable />
      </el-form-item>
      <el-form-item label="联行号" prop="bankCode">
        <el-input v-model="ruleForm.bankCode" maxlength="16" :disabled="titleObj.type !== 'add'" placeholder="请输入银行联行号" clearable />
      </el-form-item>
      <el-form-item label="卡段" prop="fBankNumbersList">
        <div class="search-box clearfix">
          <el-input v-model.trim="cardBinText" class="float-lt search-text" placeholder="请输入卡段" clearable />
          <el-button v-if="titleObj.type !== 'add'" type="primary" plain class="float-lt" @click="_getLikeNumbers()">查询</el-button>
          <el-button type="primary" plain class="float-lt" @click="_addCardBin">新增卡段</el-button>
          <div v-if="likeNumbersList.length > 0" class="likeNumbers-list-box">
            <ul class="likeNumbers-list">
              <li v-for="item of likeNumbersList" :key="item.id" class="li_">
                {{ item.cardBin }}
                <i
                  :class="[ruleForm.fBankNumbersList.includes(item.id) ? 'el-icon-refresh-right' : 'el-icon-delete']"
                  :title="ruleForm.fBankNumbersList.includes(item.id) ? '恢复' : '删除'"
                  @click="bankCardBinHandler(item.id, ruleForm.fBankNumbersList.includes(item.id) ? 1 : 0)"
                />
              </li>
            </ul>
            <el-button type="primary" size="small" class="float-lt" @click="closeLikeNumbersList">收  起</el-button>
          </div>
        </div>
        <ul v-if="titleObj.type === 'add'" class="bank-numbers-list">
          <li v-for="(item, i) in ruleForm.fBankNumbersList" :key="i" class="li_">
            <label class="float-lt">{{ ruleForm.name }}</label>
            <span class="float-lt">{{ item }}</span>
            <span class="float-rt remove_" @click="bankCardBinHandler(item, undefined)">
              <i class="el-icon-delete" title="删除" />
            </span>
          </li>
        </ul>
        <ul v-else class="bank-numbers-list">
          <li v-for="item of bankNumbersList" :key="item.id" class="li_">
            <label class="float-lt">{{ ruleForm.name }}</label>
            <span class="float-lt">{{ item.cardBin }}</span>
            <span
              :class="['float-rt', ruleForm.fBankNumbersList.includes(item.id) ? 'reset_' : 'remove_' ]"
              @click="bankCardBinHandler(item.id, ruleForm.fBankNumbersList.includes(item.id) ? 1 : 0)"
            >
              <!-- {{ ruleForm.fBankNumbersList.includes(item.id) ? '恢复' : '删除' }} -->
              <i
                :class="[ruleForm.fBankNumbersList.includes(item.id) ? 'el-icon-refresh-right' : 'el-icon-delete']"
                :title="ruleForm.fBankNumbersList.includes(item.id) ? '恢复' : '删除'"
              />
            </span>
          </li>
        </ul>
        <pagination v-if="titleObj.type !== 'add'" :auto-scroll="false" :page.sync="page.currentPage" :limit.sync="page.pageSize" :total="page.total" @pagination="handleFetch" />
      </el-form-item>
    </el-form>
    <image-cropper
      v-show="imagecropperShow"
      :key="imagecropperKey"
      :width="100"
      :height="100"
      folder="head"
      :url="actionUrl"
      lang-type="en"
      @close="close"
      @crop-upload-success="cropSuccess"
    />
    <div slot="footer" class="dialog-footer">
      <el-button @click="_close()">取 消</el-button>
      <el-button type="primary" :loading="btnloading" @click="_submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import ElDialogMx from '@/views/mixins/el-dialog-mx.js'
import ImageCropper from '@/components/ImageCropper'
import Pagination from '@/components/Pagination'
import { requiredBlur } from '@/utils/validation.js'
import { getBySupportBankName, getAllBankName, addCardBin, checkBankName, saveSupportBank, updateSupportBank, getLikeNumbers } from '@/api/finance'
let pageDefaultParams = { currentPage: 1, pageSize: 20, total: 0 }
let ruleFormData = { name: '', icon: '', bankCode: '', fBankNumbersList: [] }
export default {
  components: { ImageCropper, Pagination },
  mixins: [ElDialogMx],
  data() {
    return {
      imagecropperShow: false,
      imagecropperKey: 0,
      bankList: [], // 银行卡名称列表
      bankNumbersList: [], // 用于编辑银行卡的时候 显示卡段列表
      cardBinText: '', // 查询卡段
      likeNumbersList: [], // 卡段查询卡段列表
      page: Object.assign({}, pageDefaultParams),
      ruleForm: Object.assign({}, ruleFormData),
      rules: {
        icon: requiredBlur,
        name: requiredBlur,
        bankCode: [
          ...requiredBlur,
          { type: 'number', message: '银行联行号必须为数字', trigger: 'blur' }
        ],
        fBankNumbersList: []
      }
    }
  },
  computed: {
    actionUrl() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    }
  },
  methods: {
    _submit() {
      let params
      let { name, icon, bankCode, fBankNumbersList } = this.ruleForm
      if (this.titleObj.type === 'add') { // 新增银行卡
        checkBankName({ name }).then(([data, err, response]) => { // 新增之前检测银行名称是否存在
          if (!err && response.data.code === '200') {
            params = []
            fBankNumbersList.forEach((item) => {
              params.push({ name, icon, bankCode, cardBin: item })
            })
            this._submits(params)
          }
        })
      } else { // 编辑银行卡
        params = { name, icon, bankCode, cardBinList: fBankNumbersList }
        this._submits(params)
      }
    },

    _submits(params) { // 新增或修改银行卡提交
      // console.log(params)
      if (params !== undefined) {
        let submits = this.titleObj.type === 'add' ? saveSupportBank : updateSupportBank
        submits(params).then(([data, err]) => {
          if (!err) {
            this.$emit('changesuccess', true)
            const msg = this.titleObj.type === 'add' ? '新增' : '修改'
            this.$message.success(`${name} ${msg}成功！`)
            this._close()
          }
        })
      }
    },

    bankCardBinHandler(id, type) { // 编辑模式--删除或恢复
      if (this.titleObj.type === 'add') {
        this.ruleForm.fBankNumbersList.splice(this.ruleForm.fBankNumbersList.findIndex(item => item === id), 1)
      } else {
        if (type === 0) { // 删除
          const list = []
          list.push(id)
          this.ruleForm.fBankNumbersList = [...this.ruleForm.fBankNumbersList, ...list]
        } else if (type === 1) { // 恢复
          this.ruleForm.fBankNumbersList.splice(this.ruleForm.fBankNumbersList.findIndex(item => item === id), 1)
        }
      }
    },

    handleFetch(v) { // 分页回调
      this.init(this.ruleForm.name)
    },

    init(name) { // 初始化form
      this.dialogFormVisible = true
      this.rules.fBankNumbersList = this.titleObj.type === 'add' ? [{ required: true, message: '请添加卡段', trigger: 'change' }] : []
      if (name !== undefined) {
        const { currentPage, pageSize } = this.page
        const params = Object.assign({}, { currentPage, pageSize }, { name })
        this.dialogloading = true
        getBySupportBankName(params).then(([data, err, response]) => {
          this.dialogloading = false
          if (!err) {
            const { totalNum, items } = response.data
            const { name, icon, bankCode } = items[0]
            this.bankNumbersList = [...items]
            this.page.total = +totalNum
            this.ruleForm = Object.assign({}, this.ruleForm, { name, icon, bankCode: bankCode * 1 })
          }
        })
      }
    },

    _getAllBankName() { // 获取所有银行卡名称
      if (this.bankList.length === 0) {
        getAllBankName({}).then(([data, err]) => {
          if (!err) this.bankList = data['data']
        })
      }
    },

    _addCardBin() { // 新增或编辑银行卡中新增卡段
      if (this.titleObj.type === 'add') { // 新增银行卡 - 新增卡段
        this.$refs['ruleForm'].validateField('name', nameError => { // 验证银行卡名称是否正确
          if (!nameError) {
            if (this.checkCardBinText()) {
              if (this.ruleForm.fBankNumbersList.includes(this.cardBinText)) {
                this.$message.error(`${this.cardBinText} 这个卡段已存在！`)
              } else {
                const list = []
                list.push(this.cardBinText)
                this.ruleForm.fBankNumbersList = [...this.ruleForm.fBankNumbersList, ...list]
                this.cardBinText = ''
              }
            }
          } else {
            return false
          }
        })
      } else { // 编辑银行卡 - 新增卡段
        if (this.checkCardBinText()) {
          const { name, bankCode, icon } = this.ruleForm
          const params = { name, bankCode, icon, cardBin: this.cardBinText }
          addCardBin(params).then(([data, err]) => {
            if (!err) {
              this.cardBinText = ''
              this.page.currentPage = 1
              this.init(this.ruleForm.name)
              this.$message.success('新增卡段成功')
            }
          })
        }
      }
    },

    checkCardBinText() { // 检验卡段
      let flag = true
      if (typeof this.cardBinText === 'number' && this.cardBinText !== '') {
        if (!this.ruleForm.fBankNumbersList.includes(this.cardBinText)) { // 卡段不存在
          flag = true
        } else {
          flag = false
          this.$message.warning('卡段已存在')
        }
      } else {
        flag = false
        this.$message.warning('请输入数字类型的卡段')
      }
      return flag
    },

    _getLikeNumbers() { // 获取卡段模糊查询的列表
      if (this.checkCardBinText()) { // 检验输入的卡段是否合法
        const params = { name: this.ruleForm.name, cardBin: this.cardBinText }
        getLikeNumbers(params).then(([data, err]) => {
          if (!err) {
            if (data.length === 0) {
              this.$message.warning('输入查询的卡段不存在')
            } else {
              this.likeNumbersList = [...data]
            }
          }
        })
      }
    },

    closeLikeNumbersList() { // 收起卡段查询列表
      this.likeNumbersList = []
      this.cardBinText = ''
    },

    cropSuccess(imageurl) { // 图片上传成功
      this.imagecropperShow = false
      this.imagecropperKey = this.imagecropperKey + 1
      this.ruleForm.icon = imageurl
      this.$refs['ruleForm'].clearValidate('icon')
    },

    close() { // 图片上传弹框关闭
      this.imagecropperShow = false
    },

    _close() { // 关闭弹框
      this.page = Object.assign({}, pageDefaultParams)
      this.ruleForm = Object.assign({}, ruleFormData)
      this.$refs['ruleForm'].resetFields()
      this.bankNumbersList = []
      this.likeNumbersList = []
      this.cardBinText = ''
      this.btnloading = false
      this.dialogloading = false
      this.dialogFormVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .avatar-upload{ border-radius: 0; margin: 0;}
  .el-select{ width: 360px;}
  .el-input{ width: 360px;}
  .search-text{ width: 200px; margin-right: 10px;}
  .search-box{ position: relative;
    .likeNumbers-list-box{ position: absolute; left: 0; top: 100%; z-index: 2; width: 100%; background: #fff;
      box-shadow: 0 0 5px 5px rgba(0,0,0,0.1); margin: 0 !important; padding-bottom: 35px; overflow: hidden;
      .likeNumbers-list{ padding: 0 5px 5px 5px; margin: 0; box-sizing: border-box;list-style: none;
        overflow-y: auto; overflow-x: hidden; max-height: 340px; width: 101.3%;
        .li_{ display: inline-block; width: 13.5%; line-height: 26px; padding: 0 5px;
          margin-left: 0.916%; margin-top: 5px; background: #f4f4f4; box-sizing: border-box;
          &:nth-child(7n+1){ margin-left: 0;}
          i{ float: right; cursor: pointer; margin-top: 6px;
            &.el-icon-refresh-right{ color: #4285F4;}
            &.el-icon-delete{ color: red;}
          }
        }
      }
      .el-button{ width: 100%; position: absolute; left: 0; bottom: 0; z-index: 3;}
    }
  }
  .bank-numbers-list{ width: 100%; padding: 0; margin-top: 15px; list-style: none; overflow: hidden;
    .li_{ display: inline-block; width: 50%; height: 36px; line-height: 36px;
      border: 1px solid #dfe6ec; border-top: none; padding: 0 5px; box-sizing: border-box;
      &:nth-child(2n+2){ border-left: none;}
      &:nth-of-type(1),&:nth-of-type(2){ border-top: 1px solid #dfe6ec;}
      label{ font-weight: normal; width: 40%; border-right: 1px solid #dfe6ec; margin-right: 5px;}
      span.float-rt{ cursor: pointer;
        &.remove_{ color: red;}
        &.reset_{ color: #4285F4;}
        i{ font-size: 18px;}
      }
    }
  }
</style>
