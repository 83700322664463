import Layout from '@/layout'

const manageRouter = {
  path: '/Tenant',
  component: Layout,
  redirect: '/Tenant_Manage',
  name: 'Tenant',
  alwaysShow: true,
  meta: { title: '租户管理', icon: 'platform' },
  children: [
    {
      path: '/Tenant_Manage',
      component: () => import('@/views/tenant/manage'),
      name: 'TenantManage',
      meta: { title: '租户管理', noCache: true }
    },
    {
      path: '/Tenant_Group',
      component: () => import('@/views/tenant/group'),
      name: 'TenantGroup',
      meta: { title: '租户分组', noCache: true }
    },
    {
      path: '/Tenant_Report',
      component: () => import('@/views/tenant/report'),
      name: 'TenantReport',
      meta: { title: '租户报表', noCache: true }
    },
    {
      path: '/Tenant_Notice',
      component: () => import('@/views/tenant/notice'),
      name: 'TenantNotice',
      meta: { title: '平台短消息', noCache: true }
    },
    {
      path: '/Tenant_Users',
      component: () => import('@/views/tenant/users'),
      name: 'TenantUsers',
      meta: { title: '租户账号列表', noCache: true }
    },
    {
      path: '/Tenant_Assign',
      component: () => import('@/views/tenant/assign'),
      name: 'TenantAssign',
      meta: { title: '租户权限分配', noCache: true }
    }
  ]
}

export default manageRouter
