<template>
  <div class="app-container">

    <!-- <DropCollapse @change="handleChangeTenant" /> -->

    <div class="filter-container">
      <div class="filter-container-item">
        <el-date-picker
          v-model="times"
          clearable
          type="datetimerange"
          style="width: 400px;"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
        />
        <el-button class="filter-item" @click="handleSetTimes($event, 1)">昨天</el-button>
        <el-button class="filter-item" @click="handleSetTimes($event, 7)">7天</el-button>
        <el-button class="filter-item" @click="handleSetTimes($event, 30)">本月</el-button>
        <el-button class="filter-item" @click="handleSetTimes($event, 60)">上月</el-button>
        <el-select v-model="search.platfromId" class="filter-item" placeholder="平台名称" clearable style="width: 150px">
          <el-option v-for="item in payThirdConfList" :key="item.id" :label="item.payPlatformName" :value="item.id" />
        </el-select>
        <el-select v-model="search.paywayId" class="filter-item" placeholder="支付方式" clearable style="width: 150px">
          <el-option
            v-for="item in paymentWayConfList"
            :key="item.paywayId"
            :label="item.paywayName"
            :value="item.paywayId"
          />
        </el-select>
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </div>
      <div class="stats-region">
        <div class="stats-region-first">列表合计</div>
        <div v-for="item of countInfos" :key="item" class="stats-region-item">
          <label>{{ item.split('-')[0] }}</label>
          <div>{{ item.split('-')[1] }}</div>
        </div>
      </div>
      <div>
        <!-- <div class="stats-region-first"> 列表合计 </div>
        <div class="stats-region-item" v-for="item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"><label>成功用户数</label>
          <span>130</span>
        </div> -->
        <!-- <el-select v-model="search.payPlatformCode" placeholder="平台名称" clearable style="width: 150px">
          <el-option v-for="item in payThirdConfList" :key="item.id" :label="item.payPlatformName"
            :value="item.payPlatformCode" />
        </el-select>
        <el-select v-model="search.paywayId" placeholder="支付方式" clearable style="width: 150px">
          <el-option v-for="item in paymentWayConfList" :key="item.paywayId" :label="item.paywayName"
            :value="item.paywayId" />
        </el-select> -->
        <!-- <el-select v-model="search.status" placeholder="支付状态" clearable style="width: 120px">
          <el-option v-for="item in paymentStatus" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
        <el-input v-model="search.orderNo" clearable placeholder="订单号" style="width: 150px" />
        <el-input v-model="search.thirdNo" clearable placeholder="平台流水号" style="width: 150px" />
        <el-input v-model="search.operatorName" clearable placeholder="操作员" style="width: 140px" /> -->
      </div>
    </div>

    <d-table
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template #left>
        <p v-if="tenant" slot="left" class="platformName">租户:{{ tenant.tenantName }}&nbsp;({{ tenant.tenantCode }})</p>
        <template v-if="statistical" />
      </template>
      <template #right>
        <el-button
          plain
          type="primary"
          :loading="excelLoading1"
          icon="el-icon-download"
          @click="_handleExport"
        >导出Excel</el-button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <UserDialog ref="UserDialogControl" />
  </div>
</template>
<script>
import { getCashThirdTotal, getThirdTotal, paymentOrderTotalDownload } from '@/api/statistical'
import { getPayWayThirdConfList, getPaymentWayConfList } from '@/api/cash'
import UserDialog from '@/views/components/UserDialog'
import TablePageMixin from '@/mixins/table-page-mixin'
import { paymentStatus } from '@/libs/options'
import GetColumns from './columns'
import { getDefaultTimes, exportExcel } from '@/utils'
import dayjs from 'dayjs'
const defaultParams = () => ({
  platfromId: null,
  paywayId: null
})

export default {
  name: 'CashThirdTotal',
  components: { UserDialog },
  filters: {
    filterStatus(status) {
      const tmp = paymentStatus.find(o => o.key === status)
      return tmp && tmp.label
    }
  },
  mixins: [TablePageMixin(getCashThirdTotal, true)],
  data() {
    return {
      tenant: null,
      times: getDefaultTimes(),
      excelLoading1:false,
      search: defaultParams(),
      paymentStatus,
      columns: [],
      defaultColumns: GetColumns.call(this),
      paymentWayConfList: [],
      payThirdConfList: [],
      statistical: null,
      countInfos: []
    }
  },
  created() {
    this.handleSetTimes(null, 1)
    this.getcountInfos()
  },
  mounted() {
    this.$T$ && this.implementationTenantChanged()
  },
  methods: {
    _onDetails({ memberId, tenantCode }) {
      if (!this.permission.Details) return
      this.$refs.UserDialogControl.open(memberId, tenantCode, this.permission.Phone)
    },

    _handleExport() {
      const query = {
        ...this.implementationGetParams(),
        ...defaultParams(),
        tenantCode: this.$P$ ? this.tenant.tenantCode : this.$store.getters.tenantCode
      }
      this.excelLoading1 = true
      paymentOrderTotalDownload(query).then(([data, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `三方充值统计-${this.$parseTime(new Date())}.csv`)
        }
      }).finally(()=>{
        this.excelLoading1 = false
      })
    },

    // handleAgain(row) {
    //   this.$confirm('确认进行补单', '系统提示', { type: 'warning' }).then(() => {
    //     compensateOnlineOrder({
    //       orderNo: row.orderNo,
    //       status: '0000',
    //       tenantCode: row.tenantCode
    //     }).then(([data, err]) => {
    //       if (!err) {
    //         this.handleFetch()
    //         this.$message.success('操作成功')
    //       }
    //     }).catch(() => { })
    //   })
    // },

    handleChangeTenant([tenant]) {
      this.tenant = tenant
      this.search = defaultParams()
      this.search.tenantCode = tenant.tenantCode
      this.handleRefresh()
      this.implementationTenantChanged(tenant.tenantCode)
    },
    implementationTenantChanged(tenantCode) {
      const params = { pageSize: 100, currentPage: 1, tenantCode }
      getPaymentWayConfList(params).then(([data, err]) => {
        if (!err) {
          this.paymentWayConfList = data
        }
      })
      getPayWayThirdConfList(params).then(([data, err]) => {
        if (!err) {
          this.payThirdConfList = data
        }
      })
    },
    getcountInfos(info = {}) {
      this.countInfos = [`成功用户数-${info.successNumber}`, `成功金额-${info.successAmount}`, `失败金额-${info.failAmount}`, ` 待处理金额-${info.pendAmount}`,
        `总金额-${info.totalAmount}`, `成功订单数-${info.successOrder}`, `失败订单数-${info.failOrder}`, `待处理订单数- ${info.pendOrder}`, `总订单数-${info.totalOrder}`,
        `订单成功率(%)-${info.successRate}`].map(p => p.replace('undefined', ''))
    },
    async implementationFetched({ sumAmount, sumAmountGroupByStatus = [] }) {
      this.statistical = { sumAmount, sumAmountGroupByStatus }
      let res = await getThirdTotal(this.implementationGetParams())
      res = res[2].data
      if (!res.data || !res.success || !res.data) return
      let info = res.data
      this.getcountInfos(info)
    },

    implementationGetParams() {
      let params = this.formatIntervalTime()
      if (params.startTime) params.startTime = dayjs(params.startTime).format('YYYY-MM-DD HH:mm:ss')
      if (params.endTime) params.endTime = dayjs(params.endTime).format('YYYY-MM-DD HH:mm:ss')
      return params
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
<style type="scss" scoped>
.stats-region {
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  border: 1px solid #ccc;

  font-size: 12px;
  margin-top: 16px;
  margin-bottom: 16px;

}

.stats-region-first {
  background-color: #f0f0f0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  box-sizing: border-box;
}

.stats-region-item {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-left: 1px solid #ccc;
  box-sizing: border-box;
}

.stats-region-item label {
  border-bottom: 1px solid #ccc;
  padding-left: 8px;
  line-height: 30px;
}

.stats-region-item div {
  /* padding: 6px; */
  line-height: 30px;
  height: 30px;
}
</style>
