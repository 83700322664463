const CanChooseColumns = function() {
  return [
    {
      title: this.$t('平台'),
      key: 'tenantName',
      align: 'center'
    },
    {
      title: this.$t('启停'),
      key: 'platformStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch v-model={row.platformStatus} active-value={1} inactive-value={0} disabled={!this.permission.StartStop}>
              <span slot='close'>{this.$t('停用')}</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('显示隐藏'),
      key: 'platformShowStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch v-model={row.platformShowStatus} active-value={1} inactive-value={0} disabled={!this.permission.OpenClose}>
              <span slot='open'>{this.$t('显示')}</span>
              <span slot='close'>{this.$t('隐藏')}</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          return (<el-link type='primary' onClick={this._handleUpdate.bind(this, row)}>{this.$t('保存修改')}</el-link>)
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
