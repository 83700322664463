<template>
  <el-dialog
    :title="emitName === 'create' ? '新建组别' : '修改组别'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="400px"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('租户组别')">
        <el-input v-model="ruleForm.groupName" placeholder="输入租户组别名称，最长20个字符" maxlength="20" />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">{{ $t('关闭') }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">{{ $t('保存') }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  groupId: undefined,
  groupName: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {}
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm.groupId = row.id
        this.ruleForm.groupName = row.groupName
        this.emitName = 'update'
      }
      this.visible = true
    }
  }
}
</script>
