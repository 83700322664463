var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      this.listItem.msgType == 212
        ? [
            _c(
              "el-card",
              {
                staticClass: "box-div",
                attrs: { "body-style": { padding: "0px" } },
              },
              [
                _c("div", { staticClass: "box-left" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.listItem.msgType == 212
                          ? "任务分享"
                          : _vm.taskTypeMap[_vm.listItem.isDaily].name
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "setDisplay setPd",
                    staticStyle: { "min-height": "30px" },
                  },
                  [
                    _c("span", [
                      _vm.listItem.isReceiveAward == 1
                        ? _c("span", { staticClass: "mainText mainSize" }, [
                            _vm._v(_vm._s(_vm.listItem.memberName) + "完成"),
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "mainText mainSize setBold" }, [
                        _vm._v(_vm._s(_vm.listItem.activityTitle)),
                      ]),
                    ]),
                    _vm.listItem.isReceiveAward != 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "background-color": "#c62430" },
                            attrs: { type: "danger", round: "" },
                            on: {
                              click: function ($event) {
                                return _vm.getActivityReward(_vm.listItem)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.listItem.isAchieveGoal == 1 &&
                                    _vm.listItem.isAchieveBase != 0
                                    ? "领取奖励"
                                    : "去完成"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("el-divider"),
                _c(
                  "div",
                  { staticClass: "setDisplayCol setPd" },
                  [
                    _vm._l(
                      _vm.typeMapList[_vm.listItem.modeType],
                      function (item, index) {
                        return _vm.listItem.isReceiveAward != 1
                          ? _c("div", { key: index }, [
                              _c("div", { staticClass: "smallText" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                              _c(
                                "div",
                                { staticClass: "setPdTop setDisplay" },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "60%" } },
                                    [
                                      _c("el-progress", {
                                        attrs: {
                                          "stroke-width": 8,
                                          "stroke-linecap": "butt",
                                          percentage:
                                            _vm.listItem[item.currentKey] /
                                              _vm.listItem[item.totalKey] >
                                            1
                                              ? 100
                                              : (_vm.listItem[item.currentKey] /
                                                  _vm.listItem[item.totalKey]) *
                                                  100 || 0,
                                          "show-text": false,
                                          color: "#c62430",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#c62430" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.listItem[item.currentKey] * 1
                                          ) +
                                            " / " +
                                            _vm._s(
                                              _vm.listItem[item.totalKey] * 1
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e()
                      }
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "setDisplay",
                        staticStyle: {
                          margin: "10px 0px",
                          "justify-content": "start",
                        },
                      },
                      [
                        _c("div", { staticClass: "smallText" }, [
                          _vm._v("任务奖励:"),
                        ]),
                        _c("div", { staticClass: "coulist" }, [
                          _vm.listItem.couponTitles
                            ? _c("div", [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("抵扣券"),
                                ]),
                                _c("span", { staticClass: "val" }, [
                                  _vm._v(_vm._s(_vm.listItem.couponTitles)),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.listItem.amountRolling &&
                          !!Number(_vm.listItem.amountRolling.split(",")[0])
                            ? _c("div", [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("彩金"),
                                ]),
                                _c("span", { staticClass: "val" }, [
                                  _vm._v(
                                    "+" +
                                      _vm._s(
                                        _vm.listItem.amountRolling.split(",")[0]
                                      )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.listItem.fullCouponTitles
                            ? _c("div", [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("全勤抵扣券"),
                                ]),
                                _c("span", { staticClass: "val" }, [
                                  _vm._v(_vm._s(_vm.listItem.fullCouponTitles)),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.listItem.fullAmountRolling &&
                          !!Number(_vm.listItem.fullAmountRolling.split(",")[0])
                            ? _c("div", [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("全勤彩金"),
                                ]),
                                _c("span", { staticClass: "val" }, [
                                  _vm._v(
                                    "+" +
                                      _vm._s(
                                        _vm.listItem.fullAmountRolling.split(
                                          ","
                                        )[0]
                                      )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "setfont" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.parseTime(
                              _vm.item.timestamp,
                              "{y}-{m}-{d} {h}:{i}"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        : _vm.listItem.msgType == 213
        ? [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.listItem.extras.content) },
            }),
          ]
        : _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", { staticClass: "mainText mainSize fontBold fl" }, [
                    _vm._v(_vm._s(_vm.listData.statusTitle)),
                  ]),
                  _c("span", { staticClass: "smallText fr" }, [
                    _vm._v(_vm._s(_vm.listData.statusText)),
                  ]),
                ]
              ),
              _vm._l(_vm.listData.list, function (items, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    items.type == 1
                      ? [
                          _c("div", { staticClass: "setDisplay" }, [
                            _c("span", { staticClass: "smallText" }, [
                              _vm._v(_vm._s(items.label)),
                            ]),
                            _c("div", [
                              _c(
                                "span",
                                { staticClass: "mainText smallSize" },
                                [_vm._v(" " + _vm._s(items.value))]
                              ),
                              _c("i", {
                                staticClass: "el-icon-document-copy smallSize",
                              }),
                            ]),
                          ]),
                        ]
                      : items.type == 2
                      ? [
                          _c("div", { staticClass: "setDisplay" }, [
                            _c("span", { staticClass: "smallText" }, [
                              _vm._v(_vm._s(items.label)),
                            ]),
                            _c("div", [
                              _c(
                                "span",
                                { staticClass: "mainText smallSize" },
                                [_vm._v(_vm._s(items.value))]
                              ),
                              _c("div", { staticClass: "iconWidth" }),
                            ]),
                          ]),
                        ]
                      : items.type == 3
                      ? [
                          _c("div", { staticClass: "setDisplay" }, [
                            _c("span", { staticClass: "smallSize mainText" }, [
                              _vm._v(_vm._s(items.label)),
                            ]),
                            _c("div", [
                              _c(
                                "span",
                                { staticClass: "mainText smallSize" },
                                [_vm._v(_vm._s(items.value))]
                              ),
                              _c("div", { staticClass: "iconWidth" }),
                            ]),
                          ]),
                        ]
                      : items.type == 4
                      ? [
                          _c("div", { staticClass: "setDisplay" }, [
                            _c("span", { staticClass: "mainText smallSize" }, [
                              _vm._v(_vm._s(items.label)),
                            ]),
                            _c("div", { staticClass: "iconWidth" }),
                          ]),
                        ]
                      : items.type == 5
                      ? [
                          _c("div", { staticClass: "setDisplay" }, [
                            _c("span", { staticClass: "mainText smallSize" }, [
                              _vm._v(_vm._s(items.label)),
                            ]),
                            _c("div", [
                              _c(
                                "span",
                                { staticClass: "smallText smallSize" },
                                [_vm._v(" 场次: ")]
                              ),
                              _c(
                                "span",
                                { staticClass: "mainText smallSize" },
                                [_vm._v(_vm._s(items.value))]
                              ),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
            ],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }