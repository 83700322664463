<template>
  <el-dialog :title="$t('优惠明细')" :visible.sync="visible" top="5vh" custom-class="custom-dialog" width="600px" @open="onOpen" @closed="handleClosed">
    <el-table v-loading="loading" :data="items" border size="small">
      <el-table-column prop="type" align="center" :label="$t('类型')" />
      <el-table-column prop="acount" align="center" :label="$t('金额')" />
      <el-table-column prop="member" align="center" :label="$t('人数')" />
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">
        {{ $t('关闭') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import { getPromotionReportDetail } from '@/api/statistical'
import { $t } from '@/lang'

const columns = [
  { type: $t('VIP礼金'), key: 'countVip' },
  { type: $t('VIP周红包'), key: 'vipWeek' },
  { type: $t('VIP月红包'), key: 'vipMonth' },
  { type: $t('活动礼金'), key: 'countActivity' },
  { type: $t('注册赠送'), key: 'countRegister' },
  { type: $t('充值赠送'), key: 'rechargeReward' },
  { type: $t('分享赠送'), key: 'countAward' },
  { type: $t('分享返水'), key: 'countRebate' },
  { type: $t('彩票返水'), key: 'countLottery' },
  { type: $t('第三方游戏返水'), key: 'countGames' },
  { type: $t('代理返佣'), key: 'proxyCommission' },
  { type: $t('优惠券使用'), key: 'coupon' },
  { type: $t('合计'), key: 'count' }
]

export default {
  mixins: [VDialogMixin],
  data() {
    return {
      params: {},
      items: [],
      loading: false
    }
  },
  methods: {
    open(startTime) {
      this.params = {
        startTime: this.$parseTime(startTime, '{y}-{m}-{d}')
      }
      this.visible = true
    },
    onOpen() {
      this.loading = true
      getPromotionReportDetail(this.params).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.items = columns.map(o => ({
            type: o.type,
            acount: data[o.key] === undefined ? data[o.key + 'Amount'] : data[o.key],
            member: data[`${o.key}Member`]
          }))
        }
      })
    },
    handleClosed() {
      this.loading = false
      this.items = []
    }
  }
}
</script>
