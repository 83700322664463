var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "任务内容",
        visible: _vm.visible,
        width: "50%",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.handleOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          staticStyle: { height: "70vh", "overflow-y": "auto" },
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c("h3", [
            _vm._v(_vm._s(_vm.$t("参与资格"))),
            _c("span", { staticClass: "sign-tips" }, [
              _vm._v(_vm._s(_vm.$t("输入0为不限制"))),
            ]),
          ]),
          _c(
            "el-form-item",
            { staticClass: "tiaojian" },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "总充值金额大于等于", prop: "rechargeTotal" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.rechargeTotal,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "rechargeTotal", $$v)
                      },
                      expression: "ruleForm.rechargeTotal",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "今日充值总额大于等于",
                    prop: "rechargeDaily",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.rechargeDaily,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "rechargeDaily", $$v)
                      },
                      expression: "ruleForm.rechargeDaily",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "tiaojian" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "总投注金额大于等于", prop: "betsTotal" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.betsTotal,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "betsTotal", $$v)
                      },
                      expression: "ruleForm.betsTotal",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "今日投注金额大于等于", prop: "betsDaily" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.betsDaily,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "betsDaily", $$v)
                      },
                      expression: "ruleForm.betsDaily",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("VIP限制"), prop: "vipLimitType" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.vipLimitType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "vipLimitType", $$v)
                    },
                    expression: "ruleForm.vipLimitType",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("开启限制")))]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.ruleForm.vipLimitType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "vipLimitType", $$v)
                    },
                    expression: "ruleForm.vipLimitType",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("不限制")))]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ruleForm.vipLimitType === 1,
                  expression: "ruleForm.vipLimitType === 1",
                },
              ],
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.vipLimitList,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "vipLimitList", $$v)
                    },
                    expression: "ruleForm.vipLimitList",
                  },
                },
                _vm._l(_vm.vipLevelList, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.vipLevel, attrs: { label: item.vipLevel } },
                    [_vm._v(_vm._s("VIP" + item.vipLevel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("任务类型"), prop: "isDaily" } },
            _vm._l(_vm.$t("taskType"), function (item) {
              return _c(
                "el-radio",
                {
                  key: item.key,
                  attrs: { label: item.key },
                  model: {
                    value: _vm.ruleForm.isDaily,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "isDaily", $$v)
                    },
                    expression: "ruleForm.isDaily",
                  },
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("任务方式"), prop: "modeType" } },
            _vm._l(_vm.$t("taskMethod"), function (item) {
              return _c(
                "el-radio",
                {
                  key: item.key,
                  attrs: { label: item.key },
                  model: {
                    value: _vm.ruleForm.modeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "modeType", $$v)
                    },
                    expression: "ruleForm.modeType",
                  },
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            1
          ),
          _c("h3", [_vm._v(_vm._s(_vm.$t("任务目标")))]),
          _vm.ruleForm.modeType === 0
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("是否限制方式") } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.ruleForm.target,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "target", $$v)
                            },
                            expression: "ruleForm.target",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("不限制方式")))]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.ruleForm.target,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "target", $$v)
                            },
                            expression: "ruleForm.target",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("限制方式")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ruleForm.target === 1,
                          expression: "ruleForm.target === 1",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "rwmb rwmb1" },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "zhradio",
                              attrs: { label: 1 },
                              model: {
                                value: _vm.ruleForm.accountType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "accountType", $$v)
                                },
                                expression: "ruleForm.accountType",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("公司账号")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.ruleForm.accountType === 2,
                                label: 0,
                              },
                              model: {
                                value: _vm.ruleForm.paywayType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "paywayType", $$v)
                                },
                                expression: "ruleForm.paywayType",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("不限制")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.ruleForm.accountType === 2,
                                label: 1,
                              },
                              model: {
                                value: _vm.ruleForm.paywayType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "paywayType", $$v)
                                },
                                expression: "ruleForm.paywayType",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("限制")))]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ruleForm.paywayType === 1,
                                  expression: "ruleForm.paywayType === 1",
                                },
                              ],
                              staticClass: "czfs",
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.paywayIdList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "paywayIdList",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.paywayIdList",
                                  },
                                },
                                _vm._l(_vm.companyAccountList, function (item) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: item.id,
                                      attrs: {
                                        disabled:
                                          _vm.ruleForm.accountType === 2,
                                        label: item.id,
                                      },
                                    },
                                    [_vm._v(_vm._s(item.accountName))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "rwmb rwmb2" },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "zhradio",
                              attrs: { label: 2 },
                              model: {
                                value: _vm.ruleForm.accountType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "accountType", $$v)
                                },
                                expression: "ruleForm.accountType",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("第三方账号")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.ruleForm.accountType === 1,
                                label: 0,
                              },
                              model: {
                                value: _vm.ruleForm.thirdPaywayType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "thirdPaywayType", $$v)
                                },
                                expression: "ruleForm.thirdPaywayType",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("不限制")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.ruleForm.accountType === 1,
                                label: 1,
                              },
                              model: {
                                value: _vm.ruleForm.thirdPaywayType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "thirdPaywayType", $$v)
                                },
                                expression: "ruleForm.thirdPaywayType",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("限制")))]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ruleForm.thirdPaywayType === 1,
                                  expression: "ruleForm.thirdPaywayType === 1",
                                },
                              ],
                              staticClass: "czfs",
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.thirdPaywayIdList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "thirdPaywayIdList",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.thirdPaywayIdList",
                                  },
                                },
                                _vm._l(_vm.thirdAccountList, function (item) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: item.payPlatformCode,
                                      attrs: {
                                        disabled:
                                          _vm.ruleForm.accountType === 1,
                                        label: item.payPlatformCode,
                                      },
                                    },
                                    [_vm._v(_vm._s(item.payPlatformName))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("充值成功金额"),
                        prop: "rechargeAmount",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        model: {
                          value: _vm.ruleForm.rechargeAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "rechargeAmount", $$v)
                          },
                          expression: "ruleForm.rechargeAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "tiaojian" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("有效充值单数"),
                            prop: "rechargeTimes",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.rechargeTimes,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "rechargeTimes", $$v)
                              },
                              expression: "ruleForm.rechargeTimes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("充值金额大于等于"),
                            prop: "rechargeTimesAmount",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.rechargeTimesAmount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "rechargeTimesAmount",
                                  $$v
                                )
                              },
                              expression: "ruleForm.rechargeTimesAmount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "my-label-title" }, [
                        _vm._v(_vm._s(_vm.$t("才算有效充值单数"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.ruleForm.modeType === 1
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("投注类型") } },
                    _vm._l(this.$t("betsType"), function (item) {
                      return _c(
                        "el-radio",
                        {
                          key: item.key,
                          attrs: { label: item.key },
                          on: { change: _vm.handleBetsType },
                          model: {
                            value: _vm.ruleForm.betsType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "betsType", $$v)
                            },
                            expression: "ruleForm.betsType",
                          },
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ruleForm.betsType === 3,
                          expression: "ruleForm.betsType === 3",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.ruleForm.betsType3,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "betsType3", $$v)
                            },
                            expression: "ruleForm.betsType3",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("不限制赛事")))]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.ruleForm.betsType3,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "betsType3", $$v)
                            },
                            expression: "ruleForm.betsType3",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("限制赛事")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.ruleForm.betsType !== 3 ||
                            _vm.ruleForm.betsType3 === 1,
                          expression:
                            "ruleForm.betsType !== 3 || ruleForm.betsType3 === 1",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "rwmb rwmb1" },
                        [
                          _c(
                            "el-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ruleForm.betsType === 3,
                                  expression: "ruleForm.betsType === 3",
                                },
                              ],
                              staticClass: "zhradio",
                              attrs: { label: 1 },
                              model: {
                                value: _vm.ruleForm.betsType3Ball,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "betsType3Ball", $$v)
                                },
                                expression: "ruleForm.betsType3Ball",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("足球")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.ruleForm.betsType3Ball === 2,
                                label: 0,
                              },
                              model: {
                                value: _vm.ruleForm.betsCompetitionIdsType1,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "betsCompetitionIdsType1",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.betsCompetitionIdsType1",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("不限制")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.ruleForm.betsType3Ball === 2,
                                label: 1,
                              },
                              model: {
                                value: _vm.ruleForm.betsCompetitionIdsType1,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "betsCompetitionIdsType1",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.betsCompetitionIdsType1",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("限制")))]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ruleForm.betsCompetitionIdsType1 === 1,
                                  expression:
                                    "ruleForm.betsCompetitionIdsType1 === 1",
                                },
                              ],
                              staticClass: "sfxz",
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.betsCompetitionIdsList1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "betsCompetitionIdsList1",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.betsCompetitionIdsList1",
                                  },
                                },
                                _vm._l(
                                  _vm.targetList.filter(function (e) {
                                    return e.typeId !== 3 || e.sportType === 1
                                  }),
                                  function (item, index) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: index,
                                        attrs: {
                                          disabled:
                                            _vm.ruleForm.betsType3Ball === 2,
                                          label: item.targetId,
                                        },
                                      },
                                      [_vm._v(_vm._s(item.targetName))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleForm.betsType === 3,
                              expression: "ruleForm.betsType === 3",
                            },
                          ],
                          staticClass: "rwmb rwmb1",
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "zhradio",
                              attrs: { label: 2 },
                              model: {
                                value: _vm.ruleForm.betsType3Ball,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "betsType3Ball", $$v)
                                },
                                expression: "ruleForm.betsType3Ball",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("篮球")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.ruleForm.betsType3Ball === 1,
                                label: 0,
                              },
                              model: {
                                value: _vm.ruleForm.betsCompetitionIdsType2,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "betsCompetitionIdsType2",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.betsCompetitionIdsType2",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("不限制")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled: _vm.ruleForm.betsType3Ball === 1,
                                label: 1,
                              },
                              model: {
                                value: _vm.ruleForm.betsCompetitionIdsType2,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "betsCompetitionIdsType2",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.betsCompetitionIdsType2",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("限制")))]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ruleForm.betsCompetitionIdsType2 === 1,
                                  expression:
                                    "ruleForm.betsCompetitionIdsType2 === 1",
                                },
                              ],
                              staticClass: "sfxz",
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.betsCompetitionIdsList2,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "betsCompetitionIdsList2",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.betsCompetitionIdsList2",
                                  },
                                },
                                _vm._l(
                                  _vm.targetList.filter(function (e) {
                                    return e.sportType === 2
                                  }),
                                  function (item, index) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: index,
                                        attrs: {
                                          disabled:
                                            _vm.ruleForm.betsType3Ball === 1,
                                          label: item.targetId,
                                        },
                                      },
                                      [_vm._v(_vm._s(item.targetName))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("有效投注金额"),
                        prop: "betsAmount",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        model: {
                          value: _vm.ruleForm.betsAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "betsAmount", $$v)
                          },
                          expression: "ruleForm.betsAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "tiaojian" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("有效注单数"),
                            prop: "betsTimes",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.betsTimes,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "betsTimes", $$v)
                              },
                              expression: "ruleForm.betsTimes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("注单投注金额大于等于"),
                            prop: "betsTimesAmout",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.betsTimesAmout,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "betsTimesAmout", $$v)
                              },
                              expression: "ruleForm.betsTimesAmout",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "my-label-title" }, [
                        _vm._v(_vm._s(_vm.$t("才算有效注单数"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.ruleForm.modeType === 2
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("邀请有效好友"),
                        prop: "inviteNums",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        model: {
                          value: _vm.ruleForm.inviteNums,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "inviteNums", $$v)
                          },
                          expression: "ruleForm.inviteNums",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", {
                    attrs: { label: _vm.$t("有效好友条件") },
                  }),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("充值金额≥"),
                        prop: "inviteRechargeAmount",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.inviteRechargeAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "inviteRechargeAmount", $$v)
                          },
                          expression: "ruleForm.inviteRechargeAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("投注金额≥"),
                        prop: "inviteBetsAmount",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.inviteBetsAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "inviteBetsAmount", $$v)
                          },
                          expression: "ruleForm.inviteBetsAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.ruleForm.modeType === 3
            ? _c(
                "div",
                [
                  _c("el-form-item", { staticClass: "tiaojian" }, [
                    _c("span", { staticClass: "my-label-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "满足所有条件即可发放奖励，配置为0则不作为任务目标"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("观看时长大于等于"),
                        prop: "liveSeeMinites",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "auto" },
                          model: {
                            value: _vm.ruleForm.liveSeeMinites,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "liveSeeMinites", $$v)
                            },
                            expression: "ruleForm.liveSeeMinites",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(_vm._s(_vm.$t("分钟"))),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("送礼金额大于等于"),
                        prop: "liveGiftAmount",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.liveGiftAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "liveGiftAmount", $$v)
                          },
                          expression: "ruleForm.liveGiftAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("房费金额大于等于"),
                        prop: "liveRoomAmount",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.liveRoomAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "liveRoomAmount", $$v)
                          },
                          expression: "ruleForm.liveRoomAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("发送消息大于等于"),
                        prop: "liveMsgNums",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "auto" },
                          model: {
                            value: _vm.ruleForm.liveMsgNums,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "liveMsgNums", $$v)
                            },
                            expression: "ruleForm.liveMsgNums",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(_vm._s(_vm.$t("条"))),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.ruleForm.modeType === 4
            ? _c(
                "div",
                [
                  _c("el-form-item", { staticClass: "tiaojian" }, [
                    _c("span", { staticClass: "my-label-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "满足所有条件即可发放奖励，配置为0则不作为任务目标"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("跟单注单数≥"),
                        prop: "followBetsTimes",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.followBetsTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "followBetsTimes", $$v)
                          },
                          expression: "ruleForm.followBetsTimes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("跟单金额≥"),
                        prop: "followBetsAmount",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.followBetsAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "followBetsAmount", $$v)
                          },
                          expression: "ruleForm.followBetsAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.ruleForm.modeType === 5
            ? _c(
                "div",
                [
                  _c("el-form-item", { staticClass: "tiaojian" }, [
                    _c("span", { staticClass: "my-label-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "满足所有条件即可发放奖励，配置为0则不作为任务目标"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("发帖数量≥"), prop: "postsNums" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.postsNums,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "postsNums", $$v)
                          },
                          expression: "ruleForm.postsNums",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("评论数≥"),
                        prop: "postsCommentNums",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.postsCommentNums,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "postsCommentNums", $$v)
                          },
                          expression: "ruleForm.postsCommentNums",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("点赞数≥"),
                        prop: "postsLikeNums",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.postsLikeNums,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "postsLikeNums", $$v)
                          },
                          expression: "ruleForm.postsLikeNums",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("观看数≥"), prop: "postsSeeNums" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.postsSeeNums,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "postsSeeNums", $$v)
                          },
                          expression: "ruleForm.postsSeeNums",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("h3", [_vm._v(_vm._s(_vm.$t("任务奖励")))]),
          _vm.ruleForm.isDaily === 0
            ? _c("el-form-item", { staticClass: "tiaojian" }, [
                _c("table", { staticClass: "table" }, [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("任务天数")))]),
                    _c("td", [_vm._v(_vm._s(_vm.$t("任务奖励")))]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(" " + _vm._s(_vm.$t("累计一天")) + " ")]),
                    _c(
                      "td",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("选择抵用券"),
                              prop: "oneCoupanList",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { multiple: "", "collapse-tags": "" },
                                model: {
                                  value: _vm.ruleForm.oneCoupanList,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "oneCoupanList", $$v)
                                  },
                                  expression: "ruleForm.oneCoupanList",
                                },
                              },
                              _vm._l(_vm.couponList, function (item) {
                                return _c("el-option", {
                                  key: item.couponId,
                                  attrs: {
                                    value: item.couponId,
                                    label: item.couponTitle,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: _vm.$t("彩金"), prop: "oneAmount" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.oneAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "oneAmount", $$v)
                                },
                                expression: "ruleForm.oneAmount",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("打码倍数"),
                              prop: "oneRolling",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.oneRolling,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "oneRolling", $$v)
                                },
                                expression: "ruleForm.oneRolling",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(" " + _vm._s(_vm.$t("累计两天")) + " ")]),
                    _c(
                      "td",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("选择抵用券"),
                              prop: "twoCoupanList",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { multiple: "", "collapse-tags": "" },
                                model: {
                                  value: _vm.ruleForm.twoCoupanList,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "twoCoupanList", $$v)
                                  },
                                  expression: "ruleForm.twoCoupanList",
                                },
                              },
                              _vm._l(_vm.couponList, function (item) {
                                return _c("el-option", {
                                  key: item.couponId,
                                  attrs: {
                                    value: item.couponId,
                                    label: item.couponTitle,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: _vm.$t("彩金"), prop: "twoAmount" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.twoAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "twoAmount", $$v)
                                },
                                expression: "ruleForm.twoAmount",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("打码倍数"),
                              prop: "twoRolling",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.twoRolling,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "twoRolling", $$v)
                                },
                                expression: "ruleForm.twoRolling",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(" " + _vm._s(_vm.$t("累计三天")) + " ")]),
                    _c(
                      "td",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("选择抵用券"),
                              prop: "threeCoupanList",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { multiple: "", "collapse-tags": "" },
                                model: {
                                  value: _vm.ruleForm.threeCoupanList,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "threeCoupanList",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.threeCoupanList",
                                },
                              },
                              _vm._l(_vm.couponList, function (item) {
                                return _c("el-option", {
                                  key: item.couponId,
                                  attrs: {
                                    value: item.couponId,
                                    label: item.couponTitle,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("彩金"),
                              prop: "threeAmount",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.threeAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "threeAmount", $$v)
                                },
                                expression: "ruleForm.threeAmount",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("打码倍数"),
                              prop: "threeRolling",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.threeRolling,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "threeRolling", $$v)
                                },
                                expression: "ruleForm.threeRolling",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(" " + _vm._s(_vm.$t("累计四天")) + " ")]),
                    _c(
                      "td",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("选择抵用券"),
                              prop: "fourCoupanList",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { multiple: "", "collapse-tags": "" },
                                model: {
                                  value: _vm.ruleForm.fourCoupanList,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "fourCoupanList",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.fourCoupanList",
                                },
                              },
                              _vm._l(_vm.couponList, function (item) {
                                return _c("el-option", {
                                  key: item.couponId,
                                  attrs: {
                                    value: item.couponId,
                                    label: item.couponTitle,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("彩金"),
                              prop: "fourAmount",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.fourAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "fourAmount", $$v)
                                },
                                expression: "ruleForm.fourAmount",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("打码倍数"),
                              prop: "fourRolling",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.fourRolling,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "fourRolling", $$v)
                                },
                                expression: "ruleForm.fourRolling",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(" " + _vm._s(_vm.$t("累计五天")) + " ")]),
                    _c(
                      "td",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("选择抵用券"),
                              prop: "fiveCoupanList",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { multiple: "", "collapse-tags": "" },
                                model: {
                                  value: _vm.ruleForm.fiveCoupanList,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "fiveCoupanList",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.fiveCoupanList",
                                },
                              },
                              _vm._l(_vm.couponList, function (item) {
                                return _c("el-option", {
                                  key: item.couponId,
                                  attrs: {
                                    value: item.couponId,
                                    label: item.couponTitle,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("彩金"),
                              prop: "fiveAmount",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.fiveAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "fiveAmount", $$v)
                                },
                                expression: "ruleForm.fiveAmount",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("打码倍数"),
                              prop: "fiveRolling",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.fiveRolling,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "fiveRolling", $$v)
                                },
                                expression: "ruleForm.fiveRolling",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(" " + _vm._s(_vm.$t("累计六天")) + " ")]),
                    _c(
                      "td",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("选择抵用券"),
                              prop: "sixCoupanList",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { multiple: "", "collapse-tags": "" },
                                model: {
                                  value: _vm.ruleForm.sixCoupanList,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sixCoupanList", $$v)
                                  },
                                  expression: "ruleForm.sixCoupanList",
                                },
                              },
                              _vm._l(_vm.couponList, function (item) {
                                return _c("el-option", {
                                  key: item.couponId,
                                  attrs: {
                                    value: item.couponId,
                                    label: item.couponTitle,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: _vm.$t("彩金"), prop: "sixAmount" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.sixAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "sixAmount", $$v)
                                },
                                expression: "ruleForm.sixAmount",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("打码倍数"),
                              prop: "sixRolling",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.sixRolling,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "sixRolling", $$v)
                                },
                                expression: "ruleForm.sixRolling",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(" " + _vm._s(_vm.$t("累计七天")) + " ")]),
                    _c(
                      "td",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("选择抵用券"),
                              prop: "sevenCoupanList",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { multiple: "", "collapse-tags": "" },
                                model: {
                                  value: _vm.ruleForm.sevenCoupanList,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sevenCoupanList",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sevenCoupanList",
                                },
                              },
                              _vm._l(_vm.couponList, function (item) {
                                return _c("el-option", {
                                  key: item.couponId,
                                  attrs: {
                                    value: item.couponId,
                                    label: item.couponTitle,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("彩金"),
                              prop: "sevenAmount",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.sevenAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "sevenAmount", $$v)
                                },
                                expression: "ruleForm.sevenAmount",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("打码倍数"),
                              prop: "sevenRolling",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.sevenRolling,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "sevenRolling", $$v)
                                },
                                expression: "ruleForm.sevenRolling",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(" " + _vm._s(_vm.$t("全勤奖")) + " ")]),
                    _c(
                      "td",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("选择抵用券"),
                              prop: "fullCoupanList",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { multiple: "", "collapse-tags": "" },
                                model: {
                                  value: _vm.ruleForm.fullCoupanList,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "fullCoupanList",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.fullCoupanList",
                                },
                              },
                              _vm._l(_vm.couponList, function (item) {
                                return _c("el-option", {
                                  key: item.couponId,
                                  attrs: {
                                    value: item.couponId,
                                    label: item.couponTitle,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("彩金"),
                              prop: "fullAmount",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.fullAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "fullAmount", $$v)
                                },
                                expression: "ruleForm.fullAmount",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("打码倍数"),
                              prop: "fullRolling",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.fullRolling,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "fullRolling", $$v)
                                },
                                expression: "ruleForm.fullRolling",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("选择抵用券"),
                      prop: "oneCoupanList",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { multiple: "", "collapse-tags": "" },
                        model: {
                          value: _vm.ruleForm.oneCoupanList,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "oneCoupanList", $$v)
                          },
                          expression: "ruleForm.oneCoupanList",
                        },
                      },
                      _vm._l(_vm.couponList, function (item) {
                        return _c("el-option", {
                          key: item.couponId,
                          attrs: {
                            value: item.couponId,
                            label: item.couponTitle,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "tiaojian" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("彩金"), prop: "oneAmount" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.ruleForm.oneAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "oneAmount", $$v)
                            },
                            expression: "ruleForm.oneAmount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("打码倍数"),
                          prop: "oneRolling",
                        },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.ruleForm.oneRolling,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "oneRolling", $$v)
                            },
                            expression: "ruleForm.oneRolling",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _vm.emitName !== "details"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }