<template>
  <el-dialog
    :title="$t('Expert_Plan.方案详情')"
    :visible.sync="visible"
    width="55%"
    top="5vh"
    :append-to-body="appendToBody"
    :close-on-click-modal="false"
    @open="onOpen"
    @closed="handleClosed"
  >

    <el-form ref="validateForm" v-loading="loading" :model="ruleForm" :rules="rules" label-width="170px" label-suffix=":">
      <el-form-item :label="$t('Expert_Plan.方案单号')">{{ ruleForm.id }}</el-form-item>
      <!-- <el-form-item :label="$t('Expert_Plan.比赛类型')">{{ ruleForm.ruleType === 1 ? $t('Expert_Plan.足球') : $t('Expert_Plan.篮球') }}</el-form-item> -->
      <el-form-item :label="$t('Expert_Plan.过关方式')">{{ ruleForm.articleSchedules === 1 ? $t('Expert_Plan.单场') : $t('Expert_Plan.串联') }}</el-form-item>
      <el-form-item :label="$t('Expert_Plan.是否收费')">{{ parseInt(ruleForm.goldnum) === 0 ? $t('Expert_Plan.免费') : ruleForm.goldnum }}<span v-if="ruleForm.goldnum > 0 && ruleForm.isRefund === 1" style="margin-left:30px">不中退</span></el-form-item>
      <el-form-item :label="$t('Expert_Plan.阅读量')" prop="newView">
        <el-input v-if="edit" v-model.number="ruleForm.newView" :placeholder="$t('请输入',{text:$t('整数')})" style="width: 300px">
          <template slot="prepend">{{ ruleForm.viewNum ? ruleForm.viewNum : 0 }}+</template>
        </el-input>
        <span>{{ ruleForm.newView + ruleForm.viewNum }}</span>
      </el-form-item>
      <el-form-item :label="$t('Expert_Plan.销售量')" prop="newBuyNum">
        <el-input v-if="edit" v-model.number="ruleForm.newBuyNum" :placeholder="$t('请输入',{text:$t('整数')})" style="width: 300px">
          <template slot="prepend">{{ ruleForm.buynum }}+</template>
        </el-input>
        <span>{{ ruleForm.newBuyNum + ruleForm.buynum }}</span>
      </el-form-item>
      <el-form-item :label="$t('Expert_Plan.赛事选择')">
        <ul v-if="ruleForm.matchList && ruleForm.matchList.length > 0" class="matchList">
          <li v-for="item in ruleForm.matchList" :key="item.matchId">
            <span>{{ item.matchTime | parseTime('{m}-{d} {h}:{i}') }}</span>
            <!-- {{ item.competitionName }} -->
            <span>{{ item.homeTeamName }} VS {{ item.awayTeamName }}</span>
            <span>选项：{{ item.betting }}</span>
            <!-- <span class="danger-type">{{ item.ruleAttributeName }}</span> -->
          </li>
        </ul>
      </el-form-item>
      <!-- <el-form-item v-if="$parent.tenantCode !== 'TYCC'" :label="$t('Expert_Plan.原文标题')">
        {{ ruleForm.originalTitle }}
      </el-form-item> -->
      <el-form-item :label="$t('Expert_Plan.标题')" prop="title">
        <el-input v-if="edit" v-model="ruleForm.title" maxlength="200" />
        <span v-else>{{ ruleForm.title }}</span>
      </el-form-item>
      <!-- <el-form-item v-if="$parent.tenantCode !== 'TYCC'" :label="$t('Expert_Plan.原文内容')">
        <div v-html="ruleForm.originalContent" />
      </el-form-item> -->
      <el-form-item :label="$t('Expert_Plan.内容')" prop="content">
        <el-input v-model="ruleForm.content" :autosize="{minRows:4,maxRows:20}" :rows="4" type="textarea" />
      </el-form-item>
      <!-- <el-form-item v-if="$parent.tenantCode !== 'TYCC'" :label="$t('Expert_Plan.拾取状态')" prop="content">
        <el-checkbox v-model="ruleForm.isTrans">{{ $t('Expert_Plan.确认拾取') }}</el-checkbox>
        <el-input v-show="ruleForm.isTrans" v-model="ruleForm.translationName" :placeholder="$t('请输入', { text: $t('Expert_Plan.译者名') })" style="width: 300px;margin-left: 20px;" />
      </el-form-item> -->
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button v-if="edit" type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>

  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getExpertArticleDetailById } from '@/api/expert'

const getDefaultForm = () => ({
  title: '',
  newView: '',
  newBuyNum: '',
  content: '',
  goldnum: 0,
  translationName: '',
  isTrans: false,
  isTranslation: 1
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  props: {
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      emitName: 'update',
      params: {},
      edit: false,
      loading: false,
      rules: {
        title: [{ required: true, message: this.$t('请输入', { text: this.$t('Expert_Plan.终端标题') }), trigger: 'blur' }],
        newView: [{ pattern: /^\d+$/, message: this.$t('请输入', { text: this.$t('整数') }), trigger: 'blur' }],
        newBuyNum: [{ pattern: /^\d+$/, message: this.$t('请输入', { text: this.$t('整数') }), trigger: 'blur' }]
      }
    }
  },
  methods: {
    open(row, edit = true) {
      this.edit = edit
      this.params.id = row.id
      this.params.tenantCode = row.tenantCode
      this.visible = true
    },
    onOpen() {
      this.loading = true
      getExpertArticleDetailById({ ...this.params }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          data.isTrans = data.isTranslation === 1
          this.ruleForm = data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
ul.matchList {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ddd;
  li {
    padding: 0 15px;
    line-height: 48px;
    &:not(:last-child) {
      border-bottom: 1px solid #ddd
    }
    span:not(:first-child){
      margin-left: 20px;
    }
  }
}
</style>
