import { filterColumns } from "@/utils";
let statusOptions = ["待发送", "发送成功", "发送失败"];
const CanChooseColumns = function () {
  return [
    {
      title: "消息内容",
      key: "messageContent",
      align: "center",
      component: {
        render: (h, row) => {
          return <div domPropsInnerHTML={row.messageContent}></div>;
        },
      },
    },
    {
      title: "发送用户",
      key: "senderUserName",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <div>
              <div>
              {row.senderType == 1
                  ? "会员用户" 
                  : "后台用户" }
              </div>
              <div>
                {row.senderUserName}
              </div>
            </div>
          );
        },
      },
    },
    {
      title: "聊天室",
      key: "roomName",
      align: "center",
    },
    {
      title: "预计发送时间",
      key: "scheduledSendTime",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span>
              {row.scheduledSendTime
                ? this.$parseTime(row.scheduledSendTime)
                : ""}
            </span>
          );
        },
      },
    },
    {
      title: "实际发送时间",
      key: "actualSendTime",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span>
              {row.actualSendTime ? this.$parseTime(row.actualSendTime) : ""}
            </span>
          );
        },
      },
    },
    {
      title: "发送状态",
      key: "sendStatus",
      align: "center",
      component: {
        render: (h, row) => {
          return <div>{statusOptions[row.sendStatus]}</div>;
        },
      },
    },
    {
      title: "操作",
      key: "actions",
      align: "center",
      width: 200,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission;
          return (
            <div>
              {Update && row.sendStatus == 0 && (
                <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-edit"
                  onClick={() => {
                    this.$refs.ActionDialogControl.open(row);
                  }}
                >
                  {this.$t("编辑")}
                </el-button>
              )}
              {Delete && (
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  onClick={() => this.handleDelete(row)}
                >
                  {this.$t("删除")}
                </el-button>
              )}
            </div>
          );
        },
      },
    },
  ];
};

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}
