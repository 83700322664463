import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: '会员账号',
      key: 'memberName',
      align: 'center'
    },
    {
      title: '类型',
      key: 'transTypeName',
      align: 'center'
    },
    {
      title: '变动金额',
      key: 'amount',
      align: 'center',
      type: 'amount'
    },
    {
      title: '创建时间',
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.createTime)}</span>
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
