var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加定时消息",
        width: "1200px",
        top: "8vh",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "消息内容", prop: "messageContent" } },
            [
              _c("Tinymce", {
                ref: "tinymce",
                attrs: { maxlength: 500, height: 200 },
                model: {
                  value: _vm.ruleForm.messageContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "messageContent", $$v)
                  },
                  expression: "ruleForm.messageContent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发送用户", prop: "senderUserId" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { input: _vm.sendUserChange },
                  model: {
                    value: _vm.ruleForm.senderType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "senderType", $$v)
                    },
                    expression: "ruleForm.senderType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("会员用户")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("后台用户")]),
                ],
                1
              ),
              _vm.ruleForm.senderType == 1
                ? [
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "lazy-load-dropdown",
                            rawName: "v-lazy-load-dropdown",
                            value: _vm.handleScrollDropdown,
                            expression: "handleScrollDropdown",
                          },
                        ],
                        key: 1,
                        staticStyle: { "margin-left": "20px", width: "300px" },
                        attrs: {
                          "remote-method": _vm.searchByName,
                          filterable: "",
                          remote: "",
                          placeholder: "请选择会员用户",
                        },
                        on: {
                          change: _vm.setAdminList,
                          focus: function ($event) {
                            return _vm.searchByName("")
                          },
                        },
                        model: {
                          value: _vm.ruleForm.senderUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "senderUserId", $$v)
                          },
                          expression: "ruleForm.senderUserId",
                        },
                      },
                      _vm._l(_vm.memberInfo, function (item) {
                        return _c("el-option", {
                          key: item.memberId,
                          attrs: {
                            label: item.memberName,
                            value: item.memberId,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                : [
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "lazy-load-dropdown",
                            rawName: "v-lazy-load-dropdown",
                            value: _vm.handleScrollDropdown,
                            expression: "handleScrollDropdown",
                          },
                        ],
                        key: 0,
                        staticStyle: { "margin-left": "20px", width: "300px" },
                        attrs: {
                          placeholder: "请选择会员用户",
                          "remote-method": _vm.searchByName,
                          filterable: "",
                          remote: "",
                        },
                        on: {
                          change: _vm.setUserList,
                          focus: function ($event) {
                            return _vm.searchByName("")
                          },
                        },
                        model: {
                          value: _vm.ruleForm.senderUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "senderUserId", $$v)
                          },
                          expression: "ruleForm.senderUserId",
                        },
                      },
                      _vm._l(_vm.adminInfo, function (item) {
                        return _c("el-option", {
                          key: item.memberId,
                          attrs: { label: item.nickname, value: item.memberId },
                        })
                      }),
                      1
                    ),
                  ],
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: "聊天室", prop: "roomId" } },
            [
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "lazy-load-dropdown",
                      rawName: "v-lazy-load-dropdown",
                      value: _vm.handleScrollDropdown,
                      expression: "handleScrollDropdown",
                    },
                  ],
                  staticStyle: { width: "300px" },
                  attrs: {
                    remote: "",
                    filterable: "",
                    "remote-method": _vm.searchChatByName,
                    placeholder: "请选择聊天室类型",
                  },
                  on: {
                    change: _vm.setChatList,
                    focus: function ($event) {
                      return _vm.searchChatByName("")
                    },
                  },
                  model: {
                    value: _vm.ruleForm.roomId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "roomId", $$v)
                    },
                    expression: "ruleForm.roomId",
                  },
                },
                _vm._l(_vm.chatList, function (item) {
                  return _c("el-option", {
                    key: item.roomId,
                    attrs: { label: item.roomName, value: item.roomId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发送方式", prop: "sendMethod" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { input: _vm.sendMethodChange },
                  model: {
                    value: _vm.ruleForm.sendMethod,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "sendMethod", $$v)
                    },
                    expression: "ruleForm.sendMethod",
                  },
                },
                [
                  _c(
                    "el-radio",
                    { attrs: { "value-key": "send0", label: 0 } },
                    [_vm._v("立即发送")]
                  ),
                  _c(
                    "el-radio",
                    { attrs: { "value-key": "send1", label: 1 } },
                    [_vm._v("定时发送")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.sendMethod == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "预计发送时间",
                    required: "",
                    prop: "scheduledSendTime",
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "请选择预计发送时间",
                      "picker-options": _vm.pickerOptions,
                      "disabled-date": _vm.disabledDate,
                    },
                    model: {
                      value: _vm.ruleForm.scheduledSendTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "scheduledSendTime", $$v)
                      },
                      expression: "ruleForm.scheduledSendTime",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }