<template>
  <el-dialog :title="$t('撤单')" width="610px" top="5vh" :visible.sync="visible" class="cancel-dialog" @closed="handleClosed">

    <template v-if="ruleForm">
      <el-table :data="ruleForm.recordList" stripe border fit highlight-current-row>
        <el-table-column :label="$t('赛事编号')" prop="matchNumber" align="center">
          <template slot-scope="{ row }">
            {{ $t('周') }}{{ row.matchTime | parseTime('{a}') }}{{ row.matchNumber }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('联赛')" prop="competitionName" align="center" />
        <el-table-column :label="$t('对阵')" prop="teamName" align="center">
          <template slot-scope="{ row }">
            {{ row.homeTeamName }} VS {{ row.awayTeamName }}
          </template>
        </el-table-column>
      </el-table>
      <div class="cancel-dialog-footer">
        <span v-if="[2,3,4,5,6,7,8].every(o => ruleForm[`bunch${o}`] === 0)">{{ $t('单关') }}</span>
        <span v-for="t in [2,3,4,5,6,7,8]" v-show="ruleForm[`bunch${t}`] > 0" :key="t">{{ `${t}*1:${ruleForm[`bunch${t}`]}` }}</span>
      </div>
      <div class="cancel-dialog-footer">{{ ruleForm.times }}{{ $t('倍') }}  {{ ruleForm.betAmount }}</div>
    </template>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('取 消') }}</el-button>
      <el-button type="danger" :loading="submitting" @click="handleSubmit">{{ $t('撤单') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
export default {
  mixins: [VDialogMixin],
  data() {
    return {
      ruleForm: null,
      submitting: false,
      emitName: 'cancel'
    }
  },
  methods: {
    open(row) {
      this.ruleForm = row
      this.visible = true
    },
    handleClosed() {
      this.submitting = false
      this.ruleForm = null
    },
    handleSubmit() {
      this.submitting = true
      this.$emit(this.emitName, {
        field: {
          orderNo: this.ruleForm.orderNo,
          memberId: this.ruleForm.memberId,
          tenantCode: this.$P$ && this.ruleForm.tenantCode || undefined
        },
        close: this.close,
        cancel: () => { this.submitting = false }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cancel-dialog {
  &-footer {
    text-align: center;
    line-height: 36px;
    border: 1px solid #dfe6ec;
    border-top: 0;
    & > span {
      padding: 0 3px;
    }
  }
}
</style>
