var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: "60%",
        title: "功能配置",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.onClosed,
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "彩种配置", name: "Lottery" } },
            [_c("Lottery", { ref: "TenantFunctionLottery" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "功能配置", name: "Function" } },
            [_c("Function", { ref: "TenantFunctionFunction" })],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 确认 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }