<template>
  <div class="d-table-container">
    <div class="d-table-header" flex="main:justify cross:center">
      <div><slot name="left" /></div>
      <div>
        <slot name="right" />
        <d-table-config
          v-if="filterColumn"
          :check-list="_columns"
          :columns="originalColumns"
          @refresh="handleSettingRefresh"
          @change-column="handleColumnChange"
        />
      </div>
    </div>
    <slot name="description" />
    <el-table
      ref="elTable"
      :key="key"
      v-bind="options"
      :row-key="rowKey"
      :show-summary="showSummary"
      :summary-method="summaryMethod"
      :data="data"
      :empty-text="emptyText"
      :span-method="spanMethod"
      :row-class-name="rowClassName"
      :default-sort="defaultSort"
      @select="handleSelect"
      @select-all="handleSelectAll"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
      @cell-mouse-enter="handleCellMouseEnter"
      @cell-mouse-leave="handleCellMouseLeave"
      @row-click="handleRowClick"
      :lazy="lazy"
      :load="load"
      :tree-props="treeProps"
    >
      <el-table-column
        v-if="dragRow"
        align="center"
        width="50"
      >
        <template #header>#</template>
        <svg-icon icon-class="drag" class-name="drag-handler" />
      </el-table-column>
      <el-table-column
        v-if="selectionRow"
        type="selection"
        :selectable="selectable"
        align="center"
        width="50"
      />
      <el-table-column
        v-if="indexRow"
        type="index"
        align="center"
      >
        <template #header>{{ $t('序号') }}</template>
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column
        v-for="(column, index) of columns.filter(e => !e.hide)"
        :key="index"
        :prop="handleAttribute(column.key, null)"
        v-bind="column"
      >
        <template slot="header">
          <span>{{ handleAttribute(column.title, '') }}</span>
          <el-tooltip v-if="column.tips" effect="dark" style="cursor: pointer; vertical-align: middle; margin-left: 2px;" :content="column.tips" placement="top">
            <i class="el-icon-question" style="font-size: 18px;" />
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <render-custom-component
            v-if="column.component && column.component.name"
            v-model="scope.row[column.key]"
            :component-name="column.component.name"
            :props="column.component.props ? column.component.props : null"
            :current-row-index="currentRowIndex"
            :scope="scope"
          />
          <render-component
            v-else-if="column.component && column.component.render"
            :render-function="column.component.render"
            :current-row-index="currentRowIndex"
            :scope="scope"
          />
          <span
            v-else-if="column.isRichText"
            class="html_cell"
            v-html="scope.row[column.key]"
          />
          <span v-else>{{ scope.row[column.key] }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import DTableConfig from '@/components/DTableConfig'
import renderComponent from './components/renderComponent.vue'
import renderCustomComponent from './components/renderCustomComponent.vue'
import base from './mixin/base'
import tree from './mixin/tree'
import util from './mixin/util'
export default {
  name: 'DTable',
  components: {
    DTableConfig,
    renderComponent,
    renderCustomComponent
  },
  mixins: [base, tree, util]
}
</script>
<style lang="scss">
.table-container {
  position: relative;
  .el-input-number.is-controls-right .el-input__inner {
    padding-left: 5px;
    padding-right: 35px;
  }
}

.d-table {
  &-container {
    position: relative;
  }
  &-body {
    overflow: hidden;
  }
  &-header {
    font-size: 14px;
    line-height: 40px;
    width: 100%;
    & > div {
      margin-bottom: 6px;
    }
  }
}
</style>
