<template>
  <div class="app-container">
    <div class="filter-container">
      <el-radio-group v-model="search.dateType">
        <el-radio-button v-for="(item, key) in dateTypes" :key="key" :label="item.value"> {{ item.label }} </el-radio-button>
      </el-radio-group>
    </div>
    <div class="filter-container">
      <el-date-picker
        v-show="search.dateType === 1"
        v-model="dataTime"
        type="date"
        class="filter-item"
        style="width: 260px;"
        :placeholder="$t('选择日期')"
        value-format="timestamp"
        :picker-options="pickerOptions"
      />
      <el-input v-model.trim="search.memberName" maxlength="20" clearable :placeholder="$t('请输入代理账号')" class="filter-item" style="width: 140px" />
      <el-select v-model="search.dataLevel" :placeholder="$t('数据层级')" class="filter-item">
        <el-option v-for="(item, index) in dataGroups" :key="index" :label="item.label" :value="item.value" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">查询</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :options="options"
      :filter-column="filterColumn"
      selection-row
      :columns="columns"
      @selection-change="handleSelectionChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <DataDetailDialog ref="DataDetailDialogControls" />
    <user-dialog ref="UserDialogControl" />
    <ManualDetailDialog ref="ManualDetailDialogControls" />
    <OrderDetailDialog ref="OrderDetailDialogControl" />
  </div>
</template>

<script>
import { getAgencyReportDataList } from '@/api/agent-rebate.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import { parseTime } from '@/utils'
import GetColumns from './columns'
import DataDetailDialog from './components/DataDetailDialog.vue'
import UserDialog from '@/views/components/UserDialog'
import ManualDetailDialog from './components/ManualDetailDialog.vue'
import OrderDetailDialog from './components/OrderDetailDialog.vue'
export default {
  name: 'IndexTab',
  components: { DataDetailDialog, UserDialog, ManualDetailDialog, OrderDetailDialog },
  mixins: [TablePageMixin(getAgencyReportDataList)],
  data() {
    return {
      search: {
        dateType: 1,
        dataLevel: 3
      }, // 查询参数
      dataTime: new Date().getTime(),
      dateTypes: this.$t('dateTypes'),
      dataGroups: this.$t('dataGroups'),
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  methods: {
    handleSelectionChange(selection) { // 批量选择
      this.selection = [...selection]
    },

    implementationGetParams() {
      const dataTime = parseTime(this.dataTime, '{y}-{m}-{d}')
      return { ...this.search, dataTime }
    },
    openDetailsDialog(type, row) {
      if([51, 52, 53].includes(type)) {
        this.$refs.ManualDetailDialogControls.open(type, this.implementationGetParams(), this.dataTime, row.memberId, row.oneLevelAgencyId)
      } else {
        this.$refs.DataDetailDialogControls.open(type, this.implementationGetParams(), this.dataTime, row.memberId, row.oneLevelAgencyId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.total-switch{ line-height: 40px; display: inline-block; height: 40px;}
</style>

