var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("查看会员详细信息"),
        visible: _vm.visible,
        width: "950px",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.onClosed,
      },
    },
    [
      _c(
        "table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "message-table",
          staticStyle: { width: "100%" },
          attrs: { cellspacing: "0", cellpadding: "0", border: "0" },
        },
        [
          _c("colgroup", [
            _c("col", { attrs: { width: "120" } }),
            _c("col", { attrs: { width: "120" } }),
            _c("col", { attrs: { width: "270" } }),
            _c("col", { attrs: { width: "130" } }),
            _c("col", { attrs: { width: "240" } }),
          ]),
          _c(
            "tbody",
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "label",
                    attrs: {
                      rowspan:
                        8 +
                        ((_vm.row.bankList && _vm.row.bankList.length) || 0),
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("基本信息")) + "：")]
                ),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("登录账号")) + "："),
                ]),
                _c("td", [
                  _c("div", { attrs: { flex: "cross:center main:justify" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.row.memberName))]),
                    _c(
                      "span",
                      [
                        _vm.row.freezeStatus === 1
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  plain: "",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleUpdateFreezeStatus(0)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("启用")))]
                            )
                          : _vm._e(),
                        _vm.row.freezeStatus === 0
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  plain: "",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleUpdateFreezeStatus(1)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("禁用")))]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCopy(
                                  _vm.row.memberName,
                                  $event
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("复制")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("所属上级")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.agencyMemberName))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("会员昵称")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.nickname))]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("类别/等级/更新时间")) + "："),
                ]),
                _c("td", [
                  _c(
                    "div",
                    { attrs: { flex: "cross:center main:justify" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("getMemberType")(_vm.row.memberType))
                        ),
                      ]),
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            type: "success",
                            size: "small",
                            effect: "plain",
                          },
                        },
                        [_vm._v("VIP" + _vm._s(_vm.row.vipLevel))]
                      ),
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("parseTime")(_vm.row.updateTime))),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("会员余额")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.balance))]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("出款所需打码量")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.withdrawRolling))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("总打码量")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.sumRolling))]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("真实姓名")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.realName))]),
              ]),
              _vm._l(_vm.row.bankList, function (item) {
                return _c("tr", { key: item.cardNumber }, [
                  _c("td", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("银行地址")) + "："),
                  ]),
                  _c("td", [_vm._v(_vm._s(item.bank))]),
                  _c("td", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("银行账号")) + "："),
                  ]),
                  _c("td", [_vm._v(_vm._s(item.cardNumber))]),
                ])
              }),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("注册时间")) + "："),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm._f("parseTime")(_vm.row.createTime))),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("注册IP")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.registerIp))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("注册设备")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.registerDevice))]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("注册来源")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.registerResource))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("最后登录时间")) + "："),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm._f("parseTime")(_vm.row.latestLoginTime))),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("最后登录IP")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.latestLoginIp))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("最后登录设备")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.latestLoginDevice))]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("用户ID")) + "："),
                ]),
                _c(
                  "td",
                  [
                    _vm._v(_vm._s(_vm.params.memberId)),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCopy(_vm.params.memberId, $event)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("复制")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("第三方余额")) + "："),
                ]),
                _c("td", { attrs: { colspan: "4" } }, [
                  _c(
                    "div",
                    { attrs: { flex: "cross:center main:justify" } },
                    [
                      _c(
                        "p",
                        { staticClass: "balance" },
                        _vm._l(_vm.thirdGameList, function (game) {
                          return _c(
                            "span",
                            { key: game.key },
                            [
                              _vm._v(" " + _vm._s(game.label) + ": "),
                              _c("em", [_vm._v(_vm._s(_vm.row[game.key]))]),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRefreshBalance(
                                        game.value,
                                        game.key,
                                        game.label
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("刷新")))]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", size: "mini" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleRecall($event)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("调回余额")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label", attrs: { rowspan: "4" } }, [
                  _vm._v(_vm._s(_vm.$t("提现信息")) + "："),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("当日提款金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.row.dailyWithdraw) + " " + _vm._s(_vm.$t("元"))
                  ),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("总提款金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.row.sumWithdraw) + " " + _vm._s(_vm.$t("元"))
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("当日提款次数")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.dailyWithdrawTimes))]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("总提款次数")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.sumWithdrawTimes))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("首次提款")) + "："),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.row.firstWithdrawAmount) + _vm._s(_vm.$t("元"))
                  ),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("最高提款")) + "："),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.row.maxWithdrawAmount) + _vm._s(_vm.$t("元"))
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("人工减款")) + "："),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.row.sumOperatorSubtract) + _vm._s(_vm.$t("元"))
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label", attrs: { rowspan: "4" } }, [
                  _vm._v(_vm._s(_vm.$t("充值信息")) + "："),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("当日充值金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.row.dailyRecharge) + _vm._s(_vm.$t("元"))),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("总充值金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.row.sumRecharge) + _vm._s(_vm.$t("元"))),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("当日充值次数")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.dailyRechargeTimes))]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("总充值次数")) + "："),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.row.sumRechargeTimes))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("首次充值")) + "："),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.row.firstRechargeAmount) + _vm._s(_vm.$t("元"))
                  ),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("最高充值")) + "："),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.row.maxRechargeAmount) + _vm._s(_vm.$t("元"))
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("人工加款")) + "："),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.row.sumOperatorAdd) + _vm._s(_vm.$t("元"))),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label", attrs: { rowspan: "5" } }, [
                  _vm._v(_vm._s(_vm.$t("其他信息")) + "："),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("推单分红总金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.row.sumRecommendBouns) + _vm._s(_vm.$t("元"))
                  ),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("活动领取总金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.row.sumPromotion) + _vm._s(_vm.$t("元"))),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("发送红包总金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.row.sumRedPackage) + _vm._s(_vm.$t("元"))),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("收取红包总金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.row.sumRecvRedPackage) + _vm._s(_vm.$t("元"))
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("打赏总金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.row.sumTips) + _vm._s(_vm.$t("元"))),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("被打赏总金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.row.sumRecvTips) + _vm._s(_vm.$t("元"))),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("代理总金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.row.totalRebate) + _vm._s(_vm.$t("元"))),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("返水总金额")) + "："),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm.row.sumRebate) + _vm._s(_vm.$t("元"))),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("抵用券总额/数量")) + "："),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.row.couponAmountTotal) +
                      _vm._s(_vm.$t("元")) +
                      " / " +
                      _vm._s(_vm.row.couponNumTotal)
                  ),
                ]),
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("抽奖券数量/次数")) + "："),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("备注")) + "："),
                ]),
                _c("td", { attrs: { colspan: "4" } }, [
                  _vm._v(_vm._s(_vm.row.remark)),
                ]),
              ]),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("关闭")) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }