function normalAllCheckedValue(children, value) {
  if (Array.isArray(children) && children.length) {
    children.forEach(child => {
      child.checked = value
      child.children = normalAllCheckedValue(child.children, value)
    })
  }
  return children
}

function getParentChecked(items) {
  return !items.every(o => o.checked === false)
}

function changeChildrenChecked(items, ids, value, parent = true) {
  const [a, b, c, d] = ids
  items.forEach((item, i) => {
    if (item.id === a) {
      if (ids.length === 2) {
        item.children.forEach(child => {
          if (child.id === b) {
            child.checked = value
          }
        })
      }

      if (ids.length === 3) {
        item.children.forEach(child => {
          if (child.id === b) {
            child.children.forEach(son => {
              if (son.id === c) {
                son.checked = value
              }
            })

            if (parent) child.checked = getParentChecked(child.children)
          }
        })
      }

      if (ids.length === 4) {
        item.children.forEach(child => {
          if (child.id === b) {
            child.children.forEach(son => {
              if (son.id === c) {
                son.children.forEach(grand => {
                  if (grand.id === d) {
                    grand.checked = value
                  }
                })
                if (parent) son.checked = getParentChecked(son.children)
              }
            })

            child.checked = getParentChecked(child.children)
          }
        })
      }

      item.checked = getParentChecked(item.children)
    }
  })
}

function getChildren(children) {
  if (Array.isArray(children) && children.length) {
    return children.reduce((p, v) => {
      if (v.hasChildren) {
        p = p.concat(getChildren(v.children))
      } else {
        p = p.concat(v.children)
      }
      return p
    }, [])
  }
  return []
}

function getCheckedIds(menus) {
  return menus.reduce((p, v) => {
    if (v.checked) {
      p.push(v.id)
      if (Array.isArray(v.children) && v.children.length) {
        p = p.concat(getCheckedIds(v.children))
      }
    }
    return p
  }, [])
}

function checkedAllChildren(items, value, id) {
  items.forEach(item => {
    if (item.id === id) {
      item.children.forEach(o => {
        if (o.hasChildren) {
          o.children = o.children.map(oo => {
            return { ...oo, checked: value }
          })
        } else {
          o.checked = value
        }
      })
    }
  })
}

function checkedAllGrandChildren(items, value, id) {
  items.forEach(item => {
    if (item.id === id) {
      item.children.forEach(o => {
        if (o.hasChildren) {
          o.children.forEach(oo => {
            oo.children = oo.children.map(ooo => {
              return { ...ooo, checked: value }
            })
          })
        } else {
          o.children.forEach(oo => {
            oo.checked = value
          })
        }
      })
    }
  })
}

export {
  normalAllCheckedValue,
  changeChildrenChecked,
  getChildren,
  getCheckedIds,
  checkedAllChildren,
  checkedAllGrandChildren
}
