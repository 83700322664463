var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "140px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("入口开关"), prop: "entrySwitch" } },
            [
              _c("d-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-text": "开",
                  "inactive-text": "关",
                },
                model: {
                  value: _vm.ruleForm.entrySwitch,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "entrySwitch", $$v)
                  },
                  expression: "ruleForm.entrySwitch",
                },
              }),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v(
                  "关闭入口后,前台不再显示借呗功能入口,正常情况下请勿关闭入口"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "dmyq",
              attrs: { label: _vm.$t("打码要求"), prop: "rollingSwitch" },
            },
            [
              _c("d-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-text": "开",
                  "inactive-text": "关",
                },
                model: {
                  value: _vm.ruleForm.rollingSwitch,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "rollingSwitch", $$v)
                  },
                  expression: "ruleForm.rollingSwitch",
                },
              }),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v("打码倍数："),
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "rollingTimes", "label-width": "0" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    on: {
                      input: function ($event) {
                        _vm.ruleForm.rollingTimes =
                          _vm.ruleForm.rollingTimes.replace(/[^\d]/g, "")
                      },
                    },
                    model: {
                      value: _vm.ruleForm.rollingTimes,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "rollingTimes", $$v)
                      },
                      expression: "ruleForm.rollingTimes",
                    },
                  }),
                  _c("span", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v(
                      "开启后借款的资金需要满足打码倍数要求才可以进行提现"
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("平台借款上限"), prop: "platformAmount" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: _vm.$t("请输入平台借款上限") },
                model: {
                  value: _vm.ruleForm.platformAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "platformAmount", $$v)
                  },
                  expression: "ruleForm.platformAmount",
                },
              }),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v(
                  "平台最高借款数量.会员借款总额达到这个数值,不能再借,会员还款后可以继续借"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("平台每日借款上限"), prop: "dailyAmount" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: _vm.$t("请输入平台每日借款上限") },
                model: {
                  value: _vm.ruleForm.dailyAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "dailyAmount", $$v)
                  },
                  expression: "ruleForm.dailyAmount",
                },
              }),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v(
                  "平台最高借款数量.会员借款总额达到这个数值,不能再借,会员还款后可以继续借"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("用户每日借款次数"), prop: "dailyTimes" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: "-1代表不限制" },
                model: {
                  value: _vm.ruleForm.dailyTimes,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "dailyTimes", $$v)
                  },
                  expression: "ruleForm.dailyTimes",
                },
              }),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v(
                  "用户每日最多借款次数，会员借款次数达到这个数值，当日不能再发起借款"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("用户借款总次数"), prop: "totalTimes" } },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: "-1代表不限制" },
                model: {
                  value: _vm.ruleForm.totalTimes,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "totalTimes", $$v)
                  },
                  expression: "ruleForm.totalTimes",
                },
              }),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v(
                  "用户最多借款次数，会员借款次数达到这个数值，不能再发起借款"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("系统自动审核"), prop: "autoApproval" } },
            [
              _c("d-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-text": "开",
                  "inactive-text": "关",
                },
                model: {
                  value: _vm.ruleForm.autoApproval,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "autoApproval", $$v)
                  },
                  expression: "ruleForm.autoApproval",
                },
              }),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v(
                  "开启后对于有可用借呗额度的订单将会自动审核通过,否则需要人工审核通过"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("借呗说明"), prop: "remark" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "640px" },
                attrs: {
                  type: "textarea",
                  placeholder: _vm.$t("请输入借呗说明"),
                  maxlength: "300",
                  rows: "8",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _vm.permission.Update
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.submitting },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }