<template>
  <el-dialog
    title="订单详情"
    :visible.sync="dialogVisible"
    width="650px">
    <ul>
      <li>
        <strong>用户账号：</strong><span>{{ memberName }}</span>
      </li>
      <li class="productCover">
        <strong>商品封面：</strong> <el-image :src="productCover" lazy></el-image>
      </li>
      <li>
        <strong>商品名称：</strong> <span>{{ productName }}</span>
      </li>
      <li>
        <strong>商品价格：</strong> <span>{{ productPrice }}积分 ×1</span>
      </li>
      <li>
        <strong>订单总价：</strong> <span>{{ amount }}积分</span>
      </li>
      <li>
        <strong>商品类型：</strong> <span>{{ productType }}</span>
      </li>
      <li>
        <strong>下单时间：</strong> <span>{{ createTime }}</span>
      </li>
      <li>
        <strong>订单状态：</strong> <span>{{ status }}</span>
      </li>
      <template v-if="productType === '实物商品' && status === '已完成'">
        <li>
          <strong>收货地址：</strong> <span>{{ deliveryAddress }}</span>
        </li>
        <li>
          <strong>快递公司：</strong> <span>{{ deliveryProvider }}</span>
        </li>
        <li>
          <strong>订单编号：</strong> <span>{{ deliveryOrder }}</span>
        </li>
      </template>
    </ul>
    <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      memberName: '',
      productCover: '',
      productName: '',
      productPrice: '',
      amount: '',
      productType: '',
      createTime: '',
      status: '',
      deliveryAddress: '',
      deliveryProvider: '',
      deliveryOrder: null,
    };
  },
  methods: {
    open(row) {
      this.dialogVisible = true
      this.memberName = row.memberName
      this.productCover = row.productCover
      this.productName = row.productName
      this.productPrice = row.productPrice
      this.amount = row.amount
      this.productType = row.productType === 1 ? '实物商品' : '虚拟商品'
      this.createTime = this.$parseTime(row.createTime)
      this.status = row.status === 1 ? '待发货' : '已完成'
      this.deliveryAddress = row.deliveryAddress
      this.deliveryProvider = row.deliveryProvider
      this.deliveryOrder = row.deliveryOrder
    },
  }
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0 0 0 10px;
  li {
    list-style: none;
    line-height: 2.8;
    &.productCover {
      strong {
        vertical-align: top;
      }
      ::v-deep .el-image {
        img {
          width: 100px;
          height: 100px;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
