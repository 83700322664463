<template>
  <el-dialog
    :title="$t('新增家族')"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item :label="$t('家族名称')" prop="familyName">
        <el-input v-model.trim="ruleForm.familyName" maxlength="10" show-word-limit />
      </el-form-item>
      <el-form-item :label="$t('可提现账号类型')" prop="accountType">
        <el-select v-model="ruleForm.accountType" :placeholder="$t('选择类型')">
          <el-option :label="$t('支付宝')" :value="1" />
          <el-option :label="$t('微信')" :value="2" />
          <el-option :label="$t('银行卡')" :value="3" />
        </el-select>
      </el-form-item>
      <template v-if="ruleForm.accountType == 3">
        <el-form-item :label="$t('银行名称')" prop="bankName">
          <el-input v-model="ruleForm.bankName" :placeholder="$t('0-10字')" maxlength="10" />
        </el-form-item>
        <el-form-item :label="$t('开户行')" prop="bankAddress">
          <el-input v-model="ruleForm.bankAddress" :placeholder="$t('请输入开户行')" maxlength="30" />
        </el-form-item>
      </template>
      <el-form-item
        :label="ruleForm.accountType == 3 ? $t('持卡人姓名'): $t('提现姓名')"
        prop="accountName"
      >
        <el-input
          v-model="ruleForm.accountName"
          :placeholder="$t('0-10字')"
          maxlength="10"
          show-word-limit
        />
      </el-form-item>
      <el-form-item :label="$t('提现帐号')" prop="accountNo">
        <el-input v-model="ruleForm.accountNo" maxlength="24" :placeholder="$t('6-24字')" />
      </el-form-item>
      <el-form-item :label="$t('礼物收入分成')" prop="giftPercent">
        <el-input v-model="ruleForm.giftPercent" :placeholder="$t('0-99支持1位小数')">
          <span slot="append">%</span>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('投注分成')" prop="betsPercent">
        <el-input v-model="ruleForm.betsPercent" :placeholder="$t('0-99支持1位小数')">
          <span slot="append">%</span>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('房费分成比例')" prop="payroomPercent">
        <el-input v-model="ruleForm.payroomPercent" :placeholder="$t('0-99支持1位小数')">
          <span slot="append">%</span>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('家族账号')" prop="familyOwerLogin">
        <el-input
          v-model="ruleForm.familyOwerLogin"
          :placeholder="$t('6-12位')"
          show-word-limit
          maxlength="12"
        />
      </el-form-item>
      <el-form-item :label="$t('登陆密码')" prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          :placeholder="$t('6-12位')"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  familyName: '',
  accountType: null,
  bankName: '',
  bankAddress: '',
  accountName: '',
  accountNo: '',
  giftPercent: '',
  betsPercent: '',
  payroomPercent: '',
  familyOwerLogin: '',
  password: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        familyName: [{ required: true, message: this.$t('请输入家族名称'), trigger: 'blur' }],
        accountType: [{ required: true, message: this.$t('请选择可提现帐户类型!'), trigger: 'change' }],
        bankName: [{ required: true, message: this.$t('请输入银行名称'), trigger: 'blur' }],
        bankAddress: [{ required: true, message: this.$t('请输入开户行'), trigger: 'blur' }],
        accountName: [{ required: true, message: this.$t('请输入姓名!'), trigger: 'blur' }],
        accountNo: [
          { required: true, message: this.$t('请输入提现帐号!'), trigger: 'blur' },
          { pattern: /^[\w\s\D{1,25}]/, message: this.$t('请输入正确的帐号!'), trigger: 'blur' },
          { min: 10, message: this.$t('提现帐号,最短10位,最大25位!'), trigger: 'blur' }
        ],
        giftPercent: [
          { required: true, message: this.$t('请输入礼物收入分成'), trigger: 'blur' },
          { pattern: /^([1-9][0-9]?|0)(\.\d)?$/, message: this.$t('请输入正确分成'), trigger: 'blur' }
        ],
        betsPercent: [
          { required: true, message: this.$t('请输入投注分成'), trigger: 'blur' },
          { pattern: /^([1-9][0-9]?|0)(\.\d)?$/, message: this.$t('请输入正确分成'), trigger: 'blur' }
        ],
        payroomPercent: [
          { required: true, message: this.$t('请输入房费分成'), trigger: 'blur' },
          { pattern: /^([1-9][0-9]?|0)(\.\d)?$/, message: this.$t('请输入正确分成'), trigger: 'blur' }
        ],
        familyOwerLogin: [
          { required: true, message: this.$t('请输入家族账号'), trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9]{6,12}$/, message: this.$t('6-12英文数字'), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('请输入登录密码'), trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9]{6,12}$/, message: this.$t('6-12英文数字'), trigger: 'blur' }
        ]
      }
    }
  }
}
</script>

