const CanChooseColumns = function() {
  return [
    {
      title: this.$t('数据来源'),
      key: 'sourceType',
      align: 'center',
      width: 150,
      component: {
        render: (h, row) => {
          const type = this.sourceType.find(p => p.value === row.sourceType)
          return (<span>{type?.label}</span>)
        }
      }
    },
    {
      title: this.$t('体育项目'),
      key: 'sportType',
      align: 'center',
      width: 150,
      component: {
        render: (h, row) => {
          return (<span>{ this.sportTypes[row.sportType] }</span>)
        }
      }
    },
    {
      title: this.$t('Interactive_Match.LeagueTab.联赛'),
      key: 'competitionNameZh',
      width: 300,
      align: 'center'
    },
    {
      title: this.$t('球队'),
      key: 'team',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{(row.homeTeamNameZh + ' vs ' + row.awayTeamNameZh)}</span>)
        }
      }
    },
    {
      title: this.$t('Interactive_Match.ForecastTab.开赛时间'),
      key: 'matchTime',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          return (<span>{this.$parseTime(row.matchTime)}</span>)
        }
      }
    },
    {
      title: this.$t('Interactive_Match.ForecastTab.热门位置'),
      key: 'position',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (<span>体育-热门</span>)
        }
      }
    },
    {
      title: this.$t('Interactive_Match.ForecastTab.热点排序'),
      key: 'hotOrder',
      align: 'center',
      width: 120,
      // sortable: true,
      component: {
        render: (h, row) => {
          return (<span>{row.hotOrder}</span>)
        }
      }
    },

    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <span>
              <el-button type='success' icon='el-icon-edit-outline' onClick={() => { this.currentRow = row; this.sortVal = row.hotOrder; this.visibleSetSort = true }}>编辑</el-button>
            </span >
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
