var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        width: "800px",
        visible: _vm.visible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.onClosed,
      },
    },
    [
      _vm.params
        ? _c("lottery-before", {
            ref: "lotteryBeforehand",
            attrs: {
              label: _vm.$t("重开号码"),
              "series-tag": _vm.params.seriesTag,
              "lottery-id": _vm.params.lotteryId,
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "radio_box" },
        [
          _c("label", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.$t("重开方式"))),
          ]),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.openWay,
                callback: function ($$v) {
                  _vm.openWay = $$v
                },
                expression: "openWay",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c(
                "el-radio",
                { key: item.val, attrs: { label: item.val } },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("ul", { staticClass: "tips" }, [
        _c("li", [_vm._v(_vm._s(_vm.$t("_1本功能")))]),
        _c("li", [_vm._v(_vm._s(_vm.$t("_2撤销开奖")))]),
        _c("li", [_vm._v(_vm._s(_vm.$t("_3重新开奖")))]),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("取消"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("确认结算")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }