import { filterColumns } from '@/utils'

const CanChooseColumns = function() {
  return [
    // {
    //   title: this.$t('租户'),
    //   key: 'tenantCode',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (<span>{ row.level + this.$t('级代理') }</span>)
    //     }
    //   }
    // },
    {
      title: '代理线名称',
      key: 'domainName',
      align: 'center'
    },
    {
      title: '代理账号',
      key: 'memberName',
      align: 'center'
    },
    {
      title: '代理域名',
      key: 'domainUrl',
      align: 'center'
    },
    {
      title: '代理推广码',
      key: 'memberAccno',
      align: 'center'
    },

    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          // const { Update, Delete } = this.permission
          return (
            <span>
              {/* {<el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={ () => this.onCreate(row) }>{this.$t('编辑')}</el-button> } */}
              {<el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={() => this.handleDelete(row)}>{row.isDelete == false ? this.$t('停用') : this.$t('启用')}</el-button>}
              {<el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={() => this.onCreate(row)}>{this.$t('编辑')}</el-button>}
              {/* {<el-button type='danger' size='mini' icon='el-icon-delete' onClick={this.handleDelete.bind(this, row.id)}>{this.$t('删除')}</el-button>} */}
              {/* { Update && <el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={ () => this.onCreate(row) }>{this.$t('编辑')}</el-button> }
              { Delete && <el-button type='danger' size='mini' icon='el-icon-delete' onClick={ this.handleDelete.bind(this, row.id) }>{this.$t('删除')}</el-button> } */}
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
