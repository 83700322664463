// 卡券管理
import request from '@/plugins/axios'
// 列表
export const getList = data => {
  return request({
    url: '/coupon/list',
    method: 'post',
    data
  })
}

// 添加卡券
export const addInfo = data => {
  return request({
    url: '/coupon/create',
    method: 'post',
    data
  })
}

// 修改卡券状态
export const upStatus = data => {
  return request({
    url: '/coupon/updateStatus',
    method: 'post',
    data
  })
}

// 发送卡券
export const couponSend = data => {
  return request({
    url: '/coupon/send',
    method: 'post',
    data
  })
}

// 会员卡券中心列表
export const getListMember = data => {
  return request({
    url: '/coupon/member/list',
    method: 'post',
    data
  })
}
// 卡券统计
export const getListStatistic = data => {
  return request({
    url: '/coupon/statistic/list',
    method: 'post',
    data
  })
}
// 查询指定比赛信息
export function getInfoMatch(params = {}) {
  return request({
    url: '/football/match',
    method: 'get',
    params
  })
}

// 卡券状态作废
export function couponInvalid(data) {
  return request({
    url: '/coupon/invalid',
    method: 'post',
    data
  })
}

