import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('房间主题'),
      key: 'roomName',
      align: 'center'
    },
    {
      title: this.$t('彩种类型'),
      key: 'lottypeId',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.livePreLotteryType.find(e => e.key === row.lottypeId)?.label }</span>)
        }
      }
    },
    {
      title: this.$t('彩种/赛事'),
      key: 'lotteryName',
      align: 'center'
    },
    {
      title: this.$t('分辨率'),
      key: 'displayStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.displayStatusList[row.displayStatus] }</span>)
        }
      }
    },
    {
      title: this.$t('主播昵称'),
      key: 'nickName',
      align: 'center'
    },
    {
      title: this.$t('所属家族'),
      key: 'familyName',
      align: 'center'
    },
    {
      title: this.$t('直播状态'),
      key: 'roomStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.roomStatus === 1) {
            return (<el-tag type='success' effect='plain'>{this.$t('直播中')}</el-tag>)
          } else {
            return (<el-tag type='danger' effect='plain'>{this.$t('已下播')}</el-tag>)
          }
        }
      }
    },
    {
      title: this.$t('置顶状态'),
      key: 'topStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.topStatus}
              disabled={!this.permission.Top}
              activeValue={1}
              inactiveValue={2}
              beforeChange={this._handleChangeTopStatus.bind(this, row)}>
              <span slot='open'>{this.$t('置顶')}</span>
              <span slot='close'>{this.$t('关闭')}</span>
            </d-switch>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
