var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑",
        visible: _vm.visible,
        width: "500px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("活动分类"), prop: "activityCategoryId" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { loading: _vm.categoryLoading },
                  on: { change: _vm.handleChangeCategory },
                  model: {
                    value: _vm.ruleForm.activityCategoryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "activityCategoryId", $$v)
                    },
                    expression: "ruleForm.activityCategoryId",
                  },
                },
                _vm._l(_vm.activityCategoryList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { value: item.id, label: item.typeName },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("活动标题"), prop: "activityId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { loading: _vm.activityLoading },
                  on: { change: _vm.handleChangeActivity },
                  model: {
                    value: _vm.ruleForm.activityId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "activityId", $$v)
                    },
                    expression: "ruleForm.activityId",
                  },
                },
                _vm._l(_vm.activityList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { value: item.id, label: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "d-switch",
                {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                },
                [
                  _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                    _vm._v("启用"),
                  ]),
                  _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                    _vm._v("禁用"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动图片", prop: "activityImage" } },
            [
              _c("d-upload", {
                attrs: {
                  folder: "icon",
                  text: "上传活动图片",
                  "tip-text":
                    "提示：建议尺寸358*70，支持上传PNG、JPG、GIF、SVG图片，大小不超过2MB。",
                  "on-success": _vm.handleIconSuccess,
                },
                model: {
                  value: _vm.ruleForm.activityImage,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "activityImage", $$v)
                  },
                  expression: "ruleForm.activityImage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }