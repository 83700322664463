var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "border-form-dialog",
      attrs: {
        title: _vm.$t("房间热度设定"),
        visible: _vm.visible,
        width: "480px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "show-message": false,
            "label-suffix": ":",
            "label-width": "175px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("礼物金额加成值"),
                prop: "giftAmountAddition",
              },
            },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    size: "medium",
                    placeholder: _vm.$t("加成值大于等于1"),
                  },
                  model: {
                    value: _vm.ruleForm.giftAmountAddition,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "giftAmountAddition", _vm._n($$v))
                    },
                    expression: "ruleForm.giftAmountAddition",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.$t("倍"))),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("房费金额加成值"),
                prop: "roomRateAddition",
              },
            },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    size: "medium",
                    placeholder: _vm.$t("加成值大于等于1"),
                  },
                  model: {
                    value: _vm.ruleForm.roomRateAddition,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "roomRateAddition", _vm._n($$v))
                    },
                    expression: "ruleForm.roomRateAddition",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.$t("倍"))),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("投注金额加成值"),
                prop: "betAmountAddition",
              },
            },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    size: "medium",
                    placeholder: _vm.$t("加成值大于等于1"),
                  },
                  model: {
                    value: _vm.ruleForm.betAmountAddition,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "betAmountAddition", _vm._n($$v))
                    },
                    expression: "ruleForm.betAmountAddition",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.$t("倍"))),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("观看人次加成值"),
                prop: "viewersNumAddition",
              },
            },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    size: "medium",
                    placeholder: _vm.$t("加成值大于等于1"),
                  },
                  model: {
                    value: _vm.ruleForm.viewersNumAddition,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "viewersNumAddition", _vm._n($$v))
                    },
                    expression: "ruleForm.viewersNumAddition",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.$t("倍"))),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", { staticStyle: { "font-size": "12px", "line-height": "28px" } }, [
        _vm._v(" " + _vm._s(_vm.$t("热度组成如下")) + "："),
        _c("br"),
        _vm._v(
          "+ " +
            _vm._s(
              _vm.$t(
                "(单次礼物合计*n)+(单次房费合计*n)+(单次投注合计*n)+(单次观看人次*n)+人工热度"
              )
            ) +
            " "
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确认")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }