<template>
  <div>
    <div class="filter-container">
      <el-select
        v-model="search.vipLevel"
        :placeholder="$t('VIP等级')"
        clearable
        class="filter-item"
        style="width: 140px"
      >
        <el-option
          v-for="item in vipLevels"
          :key="item.vipLevel"
          :label="`VIP${item.vipLevel}`"
          :value="item.vipLevel"
        />
      </el-select>
      <el-input
        v-model="search.lessThanAmount"
        clearable
        :placeholder="$t('提现金额小于')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model="search.greaterThanAmount"
        clearable
        :placeholder="$t('提现金额大于')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model="search.memberName"
        clearable
        :placeholder="$t('会员账号')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model="search.receivingBank"
        clearable
        :placeholder="$t('收款银行')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model="search.proxyCode"
        :placeholder="$t('所属代理')"
        clearable
        class="filter-item"
        style="width: 140px"
      />
      <el-button type="primary" class="filter-item" @click="handleFilter">
        {{ $t("确认条件") }}
      </el-button>
      <el-button
        v-if="permission.Receive"
        class="filter-item"
        :disabled="this.socket.domainUrl === ''"
        :loading="isConnecting"
        :type="socket.isConnected ? 'danger' : 'success'"
        @click="handleConnect"
      >
        {{
          isConnecting
            ? $t("连接中")
            : socket.isConnected
              ? $t("停止接收")
              : $t("开始接收")
        }}
      </el-button>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-refresh-right"
        @click="resetSearch"
      >{{ $t("重置") }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      row-key="orderNo"
      @refresh="handleFixedRefresh"
      @column-change="handleColumnChange"
      @selection-change="handleSelectionChange"
    >
      <div slot="left">
        {{ $t("选中") }}：{{ selection.length }}{{ $t("条") }}
        {{ $t("合计金额") }}：{{ selectionAmount }}{{ $t("元") }}
      </div>
      <template slot="right">
        <el-button type="primary" plain @click="handleSelectAll">{{
          $t("全选")
        }}</el-button>
        <el-button
          v-if="permission.Receive"
          type="primary"
          plain
          :disabled="!selection.length"
          :loading="exportLoading"
          @click="_handleBatchCollect"
        >{{ $t("拾取选中") }}</el-button>
      </template>
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleSocketFetch"
    />
  </div>
</template>
<script>
import clip from '@/utils/clipboard'
import { mapGetters } from 'vuex'
import { postWithdrawPick, postWithdrawBatchPick } from '@/api/cash'
import SocketEmmiterMixin from '@/mixins/socket-emmiter'
import GetColumns from './columns'

// const insetparams = { status: 1 }

export default {
  name: 'ReceiveTab',
  componentName: 'ReceiveTab',
  mixins: [SocketEmmiterMixin(101)],
  data() {
    return {
      search: {},
      filterColumn: false,
      exportLoading: false,
      selection: [],
      columns: [],
      defaultColumns: GetColumns.call(this)
    }
  },
  computed: {
    ...mapGetters(['name']),
    vipLevels() {
      return this.$parent.vipLevels
    },
    selectionAmount() {
      if (!this.selection.length) return 0
      return this.selection
        .map((o) => Number(o.amount))
        .reduce((p, v) => p + v)
    }
  },
  methods: {
    // 重置条件
    resetSearch() {
      this.search = this.$options.data.call(this).search
    },
    _handleCollect({ orderNo, tenantCode }) {
      postWithdrawPick({
        orderNo,
        status: 2,
        remark: '',
        tenantCode
      }).then(([_, err]) => {
        if (!err) {
          this.$message.success(this.$t('拾取成功'))
        }
      })
    },

    _handleBatchCollect() {
      this.$confirm(this.$t('确认批量拾取选中订单'), this.$t('系统提示'), {
        type: 'warning'
      })
        .then(() => {
          const data = this.selection.map((o) => ({
            orderNo: o.orderNo,
            status: 2,
            remark: '',
            tenantCode: o.tenantCode
          }))
          postWithdrawBatchPick(data).then(([_, err]) => {
            if (!err) {
              this.$message.success(this.$t('批量拾取成功'))
            }
          })
        })
        .catch(() => {})
    },

    _onDetails({ memberId }) {
      if (!this.permission.Details) return
      this.$parent.$refs.UserDialogControl.open(
        memberId,
        this.permission.Phone
      )
    },
    clip(text, e) {
      e.stopPropagation()
      clip(text, e)
    },

    handleSelectionChange(selection) {
      this.selection = selection
    },
    handleSelectAll() {
      this.$refs.DTable.$refs.elTable.toggleAllSelection()
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
