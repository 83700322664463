<template>
  <el-dialog
    :title="$t('新建渠道')"
    :visible.sync="visible"
    width="392px"
    :close-on-click-modal="false"
    class="border-form-dialog"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" :show-message="false" label-width="110px">
      <el-form-item :label="$t('渠道名')" prop="memberName">
        <el-input v-model="ruleForm.memberName" :placeholder="$t('最长20位字母数字')" maxlength="20" />
      </el-form-item>
      <el-form-item :label="$t('渠道域名')" prop="channelHost">
        <el-input v-model="ruleForm.channelHost" :placeholder="$t('例:www.123.com或123.com')" />
      </el-form-item>
      <el-form-item :label="$t('状态')" prop="channelStatus">
        <el-switch v-model="ruleForm.channelStatus" active-color="#13ce66" :active-value="1" :inactive-value="0" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('创建') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
// import { validURL } from '@/utils/validate'

const getDefaultForm = () => ({
  memberName: '',
  channelHost: '',
  channelStatus: 1
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validName = (rule, value, callback) => {
      if (value === '')
      {
        callback(new Error(this.$t('请输入渠道名')))
      } else if (!/^[a-zA-Z0-9]{6,20}$/.test(value))
      {
        callback(new Error(this.$t('请输入6~20位英文数字')))
      } else {
        callback()
      }
    }
    const validAdmin = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入渠道域名')))
      }
      // else if (!validURL(value)) {
      //   callback(new Error(this.$t('渠道域名不合法')))
      // }
      else {
        callback()
      }
    }
    return {
      emitName: 'create',
      rules: {
        memberName: [{ validator: validName, trigger: 'blur' }],
        channelHost: [{ validator: validAdmin, trigger: 'blur' }]
      }
    }
  }
}
</script>
