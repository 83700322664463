<template>
  <el-dialog
    :title="emitName === 'create' ? $t('新增text', { text: $t('Member_Expert.LevelTab.专家等级') }) : $t('编辑text', { text: $t('Member_Expert.LevelTab.专家等级') })"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item
        :label="$t('Member_Expert.LevelTab.专家等级')"
        prop="positionLevel"
        :placeholder="$t('请输入m_n区间数字',{text:'1-100'})"
      >
        <el-input v-model.number="ruleForm.positionLevel" />
      </el-form-item>
      <el-form-item :label="$t('Member_Expert.LevelTab.等级名称')" prop="positionName">
        <el-input v-model="ruleForm.positionName" />
      </el-form-item>

      <el-form-item :label="$t('Member_Expert.LevelTab.等级色值')" prop="flagColor">
        <el-color-picker
          v-model="ruleForm.flagColor"
          :predefine="[
            '#BD9AFF',
            '#FF7E15',
            '#4FD072',
            '#2E8CE4',
            '#FF47D7',
            '#F2B600',
            '#FC4A8D',
            '#796FFE',
            '#9FCC17',
            '#05C6CD',
          ]"
        />
      </el-form-item>

      <el-form-item :label="$t('Member_Expert.LevelTab.发布次数')" prop="publishNum">
        <el-input v-model.number="ruleForm.publishNum" />
      </el-form-item>
      <el-form-item :label="$t('Member_Expert.LevelTab.胜率')" prop="winrate">
        <el-input v-model="ruleForm.winrate" :placeholder="$t('Member_Expert.LevelTab.请输入1_100区间最多三位小数')" />
      </el-form-item>
      <el-form-item :label="$t('Member_Expert.LevelTab.返还率')" prop="returnrate">
        <el-input v-model="ruleForm.returnrate" :placeholder="$t('Member_Expert.LevelTab.请输入1_100区间最多三位小数')" />
      </el-form-item>
      <el-form-item :label="$t('Member_Expert.LevelTab.抽佣')" prop="commission">
        <el-input v-model="ruleForm.commission" :placeholder="$t('Member_Expert.LevelTab.请输入1_100区间最多三位小数')" />
      </el-form-item>
      <el-form-item :label="$t('状态')" prop="positionStatus">
        <el-switch
          v-model="ruleForm.positionStatus"
          :active-text="$t('Member_Expert.LevelTab.需要审核')"
          :inactive-text="$t('Member_Expert.LevelTab.无需审核')"
          :active-value="1"
          :inactive-value="0"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  positionLevel: '',
  positionName: '',
  flagColor: '#BD9AFF',
  publishNum: null,
  winrate: '',
  returnrate: '',
  commission: '',
  positionStatus: 0
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        positionLevel: [
          { required: true, message: this.$t('请输入', { text: this.$t('Member_Expert.LevelTab.专家等级') }), trigger: 'blur' },
          { pattern: /^\d+$/, message: this.$t('请输入m_n区间数字', { text: '1-100' }), trigger: 'blur' }
        ],
        positionName: [{ required: true, message: this.$t('请输入', { text: this.$t('Member_Expert.LevelTab.等级名称') }), trigger: 'blur' }],
        flagColor: [{ required: true, message: this.$t('请选择', { text: this.$t('Member_Expert.LevelTab.等级色值') }), trigger: 'change' }],
        publishNum: [
          { required: true, message: this.$t('请输入', { text: this.$t('Member_Expert.LevelTab.发布次数') }), trigger: 'blur' },
          { pattern: /^\d+$/, message: this.$t('Member_Expert.LevelTab.格式不规范'), trigger: 'blur' }
        ],
        winrate: [
          { required: true, message: this.$t('请输入', { text: this.$t('Member_Expert.LevelTab.胜率') }), trigger: 'blur' },
          { pattern: /^(\d|[1-9]\d)(.\d{1,3})?|100(.0{1,3})?$/, message: this.$t('Member_Expert.LevelTab.请输入1_100区间最多三位小数'), trigger: 'blur' }
        ],
        returnrate: [
          { required: true, message: this.$t('请输入', { text: this.$t('Member_Expert.LevelTab.返还率') }), trigger: 'blur' },
          { pattern: /^(\d|[1-9]\d)(.\d{1,3})?|100(.0{1,3})?$/, message: this.$t('Member_Expert.LevelTab.请输入1_100区间最多三位小数'), trigger: 'blur' }
        ],
        commission: [
          { required: true, message: this.$t('请输入', { text: this.$t('Member_Expert.LevelTab.抽佣') }), trigger: 'blur' },
          { pattern: /^(\d|[1-9]\d)(.\d{1,3})?|100(.0{1,3})?$/, message: this.$t('Member_Expert.LevelTab.请输入1_100区间最多三位小数'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(row, tenantCode) {
      this.emitName = 'create'
      this.ruleForm.tenantCode = tenantCode
      if (row) {
        this.ruleForm.id = row.id
        this.ruleForm.positionLevel = row.positionLevel
        this.ruleForm.positionName = row.positionName
        this.ruleForm.flagColor = row.flagColor
        this.ruleForm.publishNum = row.publishNum
        this.ruleForm.winrate = row.winrate
        this.ruleForm.returnrate = row.returnrate
        this.ruleForm.commission = row.commission
        this.ruleForm.positionStatus = row.positionStatus
        this.ruleForm.tenantCode = row.tenantCode

        this.emitName = 'update'
      }

      this.visible = true
    }
  }
}
</script>
