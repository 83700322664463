<template>
  <el-dialog title="个人信息设置" :visible.sync="visible" width="800px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="客服名称" required prop="name">
        <el-input v-model="ruleForm.name" max-length="10" />
      </el-form-item>
      <el-form-item label="客服图标"  prop="avatar">
          <d-upload v-model="ruleForm.avatar" folder="head" :on-success="handleSuccess" />
          只支持.jpg、.png格式，大小为2MB以下，不设置时默认系统头像
      </el-form-item>
      
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { validNickName } from '@/utils/validate'
import DUpload from '@/components/DUpload'
import { updateOperatorEdit } from "@/api/config";
import { getImToken } from "@/api/chat";

const getDefaultForm = () => ({
  name: '',
  avatar: 'https://static.lt9b.com/circle/098af53339a646069888be6632131bca.png',
})

export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm)],
  props:{
    operatorInfo: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    const validNickname = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入客服名称'))
      } else if (!validNickName(value)) {
        callback(new Error('请输入2~6位中文'))
      } else {
        callback()
      }
    }

    return {
      emitName: 'update',
      rules: {
        name: [
          { validator: validNickname, trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: '请上传头像', trigger: 'change' }
        ],
      },
    }
  },
  methods: {
    open(row) {
      this.visible = true
      this.ruleForm.name = this.operatorInfo?.name || '在线客服'
      this.ruleForm.avatar = this.operatorInfo?.avatar
    },
    handleSubmit(){
      this.$refs.validateForm.validate(valid => {
        if (valid) {
          updateOperatorEdit(this.ruleForm).then(([data, _err]) => {
            if (!_err) {
              this._getImToken();
              this.$message.success(this.$t('编辑成功'))
              this.visible = false
              this.$emit('change')
            }
          })
        } else {
          return false
        }
      })
    },
    _getImToken() {
      getImToken({tokenType:1}).then(([data, err, response]) => {
        if (!err) {
          this.$store.commit("user/SET_IM_TOKEN", data);
        }
      });
    },
    // 图片上传成功
    handleSuccess(data) {
      this.ruleForm.avatar = data[0]
      this.$refs.validateForm.validateField('avatar')
    }
  }
}
</script>
