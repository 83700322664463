<template>
  <el-dialog :title="titleObj.title" :visible.sync="visible" width="400px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('图标')" prop="icon">
        <el-input v-show="false" v-model="ruleForm.icon" />
        <DUpload
          v-model="ruleForm.icon"
          folder="bank-icon"
          :on-success="handleSuccess"
        />
        <span>{{ $t('只支持.jpg、.png格式') }}</span>
      </el-form-item>
      <el-form-item :label="$t('银行')" prop="name">
        <el-input v-model="ruleForm.name" :disabled="titleObj.type !== 'add'" :placeholder="$t('请输入银行名称')" clearable />
      </el-form-item>
      <el-form-item :label="$t('货币')" prop="rateType">
        <el-select v-model="ruleForm.rateType" :placeholder="$t('请选择货币')" style="width:100%;">
          <el-option v-for="item in $parent.rateTypes" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import DUpload from '@/components/DUpload'
const getDefaultForm = () => ({
  id: 0,
  name: '',
  icon: '',
  rateType: ''
})
export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'save',
      action: `${process.env.VUE_APP_FILE_UPLOAD}`,
      rules: {
        icon: [{ required: true, message: this.$t('请上传图标'), trigger: 'blur' }],
        name: [{ required: true, message: this.$t('请输入银行名称'), trigger: 'blur' }],
        rateType: [{ required: true, message: this.$t('请选择货币'), trigger: 'change' }]
      },
      titleObj: { title: '', type: '' }
    }
  },
  methods: {
    open(row) {
      if (row) {
        this.ruleForm.id = row.id
        this.ruleForm.name = row.name
        this.ruleForm.rateType = row.rateType
        this.ruleForm.icon = row.icon
      }
      this.visible = true
    },
    handleSuccess(data) {
      this.ruleForm.icon = data + ''
    }
  }
}
</script>
<style lang="scss">
.icon-uploader {
  line-height: 1;
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409EFF;
    }
  }

  img {
    width: 120px;
    height: 120px;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
}

</style>
