<template>
  <div class="app-container">
    <div class="filter-container">
      <el-form
        ref="validateForm"
        inline
        :model="search"
        class="inline-form"
        :rules="rules"
        :show-message="false"
        hide-required-asterisk
      >
        <el-form-item prop="seriesTag" class="filter-item">
          <el-select v-model="search.seriesTag" clearable :placeholder="$t('系列')" style="width: 120px" @change="_getLotterys">
            <el-option v-for="item in seriesList" :key="item.seriesTag" :label="item.seriesName" :value="item.seriesTag" />
          </el-select>
        </el-form-item>
        <el-form-item prop="lotteryId" class="filter-item">
          <el-select v-model="search.lotteryId" clearable :placeholder="$t('彩种')" style="width: 120px" @change="_getPlayList">
            <el-option v-for="item in lotterysList" :key="item.lotteryId" :label="item.name" :value="item.lotteryId" />
          </el-select>
        </el-form-item>
        <el-select v-model="search.playClassTag" clearable :placeholder="$t('玩法')" class="filter-item" style="width: 120px">
          <el-option v-for="item in playsList" :key="item.playClassTag" :label="item.playClassName" :value="item.playClassTag" />
        </el-select>

        <el-form-item prop="startIssue" class="filter-item">
          <el-input v-model="search.startIssue" clearable :placeholder="$t('开始期号')" style="width: 140px" />
        </el-form-item>
        <span class="filter-item" style="font-size: 13px">{{ $t('至') }}</span>
        <el-form-item prop="endIssue" class="filter-item">
          <el-input v-model="search.endIssue" clearable :placeholder="$t('结束期号')" style="width: 140px" />
        </el-form-item>
        <el-input v-model="search.nickName" clearable :placeholder="$t('会员昵称')" class="filter-item" style="width: 140px" />
        <el-input v-model="search.memberName" clearable :placeholder="$t('会员账号')" class="filter-item" style="width: 140px" />
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </el-form>

    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
      @sort-change="handleSortChange"
    >
      <template v-if="statistics" #left>
        <span>{{ $t('总投注额') }}：{{ statistics.sumAmount }}&nbsp;&nbsp;</span>
        <span>{{ $t('中奖总额') }}：{{ statistics.winAmount }}&nbsp;&nbsp;</span>
        <span>{{ $t('盈亏') }}：{{ statistics.profit }}&nbsp;&nbsp;</span>
        <span>{{ $t('待开奖金额') }}：{{ statistics.willAmount }}</span>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <user-dialog ref="UserDialogControl" />
  </div>
</template>

<script>
import { getSeries, getLotterys, getPlayList } from '@/api/orders'
import { getSingleBetList } from '@/api/statistical'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import UserDialog from '@/views/components/UserDialog'

const SORT_NO_MESSAGE = {
  winAmount: {
    descending: 1,
    ascending: 2
  },
  willAmount: {
    descending: 3,
    ascending: 4
  },
  profit: {
    descending: 5,
    ascending: 6
  }
}

const defaultParams = () => ({
  seriesTag: '',
  lotteryId: null,
  startIssue: '',
  endIssue: ''
})

export default {
  name: 'RiskStatistical',
  components: { UserDialog },
  mixins: [TablePageMixin(getSingleBetList, false)],
  data() {
    return {
      search: defaultParams(),
      columns: [],
      defaultColumns: GetColumns.call(this),
      seriesList: [],
      lotterysList: [],
      playsList: [],
      statistics: null,
      rules: {
        seriesTag: [{ required: true, message: this.$t('请选择系列'), trigger: 'change' }],
        lotteryId: [{ required: true, message: this.$t('请选择彩种'), trigger: 'change' }],
        startIssue: [{ required: true, message: this.$t('请输入开始期号'), trigger: 'blur' }],
        endIssue: [{ required: true, message: this.$t('请输入结束期号'), trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.implementationTenantChanged()
  },
  methods: {
    _onDetails({ memberId }) {
      this.$refs.UserDialogControl.open(memberId)
    },

    validateSearchParams() {
      return new Promise((resolve) => {
        this.$refs.validateForm.validate((valid) => {
          if (valid) {
            resolve()
          } else {
            this.$message.warning(this.$t('请完善搜寻信息'))
          }
        })
      })
    },

    handleSearch() {
      this.validateSearchParams().then(() => {
        this.handleRefresh()
      })
    },

    handleSortChange({ prop, order }) {
      this.validateSearchParams().then(() => {
        this.search.orderNo = SORT_NO_MESSAGE[prop][order]
        this.handleRefresh()
      })
    },

    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    implementationTenantChanged() {
      getSeries().then(([data, err]) => {
        if (!err) {
          this.seriesList = data
        }
      })
    },
    implementationFetched({ sumAmount, profit, willAmount, winAmount }) {
      this.statistics = { sumAmount, profit, willAmount, winAmount }
    },
    _getLotterys(seriesTag) {
      if (this.search.lotteryId !== undefined) this.search.lotteryId = ''
      if (!seriesTag) return
      let params = { seriesTag }
      getLotterys(params).then(([data, err]) => {
        if (!err) {
          this.lotterysList = data
        }
      })
    },

    _getPlayList(lotteryId) {
      if (this.search.playClassTag !== undefined) this.search.playClassTag = ''
      if (!lotteryId) return
      let params = { lotteryId }
      getPlayList(params).then(([data, err]) => {
        if (!err) {
          if (data && Array.isArray(data)) {
            this.playsList = data.reduce((p, v) => {
              p = p.concat(v.playClassList)
              return p
            }, [])
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.inline-form.el-form--inline {
  .el-form-item {
    margin-bottom: 0;
    margin-right: 0;
  }
}
</style>
