var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.titleObj.title,
        width: "600px",
        visible: _vm.dialogFormVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm._close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "账户", prop: "memberName" } },
            [
              _c("el-input", {
                attrs: { disabled: "", placeholder: "请输入账户" },
                model: {
                  value: _vm.ruleForm.memberName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "memberName", $$v)
                  },
                  expression: "ruleForm.memberName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "昵称", prop: "nickname" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "10",
                  "show-word-limit": "",
                  disabled: "",
                  placeholder: "请输入昵称",
                },
                model: {
                  value: _vm.ruleForm.nickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "nickname", $$v)
                  },
                  expression: "ruleForm.nickname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "forbiddenStatus" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.forbiddenStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "forbiddenStatus", $$v)
                    },
                    expression: "ruleForm.forbiddenStatus",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("禁言")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("未禁言")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "时间", required: "", prop: "relieveTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetime",
                  placeholder: "请选择解禁时间",
                },
                model: {
                  value: _vm.ruleForm.relieveTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "relieveTime", $$v)
                  },
                  expression: "ruleForm.relieveTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "content" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "50",
                  "show-word-limit": "",
                  rows: 4,
                  placeholder: "请输入备注",
                },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogFormVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnloading },
              on: {
                click: function ($event) {
                  return _vm.handleSubmit("ruleForm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }