import Layout from '@/layout'
const routers = {
  path: '/Interactive',
  component: Layout,
  redirect: '/Interactive_Seal',
  name: 'Interactive',
  alwaysShow: true,
  meta: { title: '交互管理', icon: 'interaction' },
  children: [
    {
      path: '/Interactive_Recommend',
      component: () => import('@/views/interactive/Recommend'),
      name: 'InteractiveRecommend',
      meta: { title: '心水推荐' }
    },
    {
      path: '/RecommendRelease',
      component: () => import('@/views/interactive/Recommend/children/Release'),
      name: 'RecommendRelease',
      meta: { title: '发帖', noCache: true, activeMenu: '/Interactive_Recommend' },
      hidden: true
    },
    {
      path: '/RecommendEdit',
      component: () => import('@/views/interactive/Recommend/children/Release'),
      name: 'RecommendEdit',
      meta: { title: '编辑帖子', noCache: true, activeMenu: '/Interactive_Recommend' },
      hidden: true
    },
    {
      path: '/RecommendAudit',
      component: () => import('@/views/interactive/Recommend/children/Audit'),
      name: 'RecommendAudit',
      meta: { title: '审核帖子', noCache: true, activeMenu: '/Interactive_Recommend' },
      hidden: true
    },
    {
      path: '/RecommendHistory',
      component: () => import('@/views/interactive/Recommend/children/History'),
      name: 'RecommendHistory',
      meta: { title: '历史帖子', noCache: true, activeMenu: '/Interactive_Recommend' },
      hidden: true
    },
    {
      path: '/Interactive_Stock',
      component: () => import('@/views/interactive/Stock'),
      name: 'InteractiveStock',
      meta: { title: '六合图库' }
    },
    // {
    //   path: '/Interactive_Follow',
    //   component: () => import('@/views/interactive/Follow'),
    //   name: 'InteractiveFollow',
    //   meta: { title: '跟单管理' }
    // },
    {
      path: '/Interactive_ShareLap',
      component: () => import('@/views/interactive/ShareLap'),
      name: 'InteractiveShareLap',
      meta: { title: '社区广场' }
    },
    {
      path: '/ShareLapRelease',
      component: () => import('@/views/interactive/ShareLap/components/Release'),
      name: 'ShareLapRelease',
      meta: { title: '发圈', noCache: true, activeMenu: '/Interactive_ShareLap' },
      hidden: true
    },
    {
      path: '/Interactive_Chat',
      component: () => import('@/views/interactive/Chat'),
      name: 'InteractiveChat',
      meta: { title: '聊天室', noCache: true },
    },
    {
      path: '/Interactive_AIChat',
      component: () => import('@/views/interactive/AiChat'),
      name: 'InteractiveAIChat',
      meta: { title: 'AI聊天管理', noCache: true }
    },
    {
      path: '/Interactive_CustomerService',
      component: () => import('@/views/interactive/Customer'),
      name: 'CustomerService',
      meta: { title: '客服管理', noCache: true }
    },
    {
      path: '/Interactive_Seal',
      component: () => import('@/views/interactive/Seal'),
      name: 'InteractiveSeal',
      meta: { title: '封IP/手机', noCache: true }
    },
    {
      path: '/Interactive_Limit',
      component: () => import('@/views/interactive/Limit'),
      name: 'InteractiveLimit',
      meta: { title: '行为限制管理', noCache: true, activeMenu: '/Interactive_Chat' },
      hidden: true
    },
    {
      path: '/Interactive_Notice',
      component: () => import('@/views/interactive/Notice'),
      name: 'InteractiveNotice',
      meta: { title: '群公告管理', noCache: true, activeMenu: '/Interactive_Chat' },
      hidden: true
    },
    {
      path: '/Interactive_ScheduledTasks',
      name: 'InteractiveScheduledTasks',
      component: () => import('@/views/live/scheduledTasks'),
      hidden: true,
      meta: { title: '定时消息管理', noCache: true, activeMenu: '/Interactive_Chat' }
    },
    {
      path: '/Interactive_RobotOptions',
      name: 'InteractiveRobotOptions',
      component: () => import('@/views/live/robotOptions'),
      hidden: true,
      meta: { title: '机器人配置', noCache: true, activeMenu: '/Interactive_Chat' }
    },
  ]
}

export default routers
