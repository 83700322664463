var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "msg-item",
      class: _vm.me && _vm.item.msgType !== 105 && "msg-item-r",
    },
    [
      _vm.item.msgType !== 110 &&
      _vm.item.msgType !== 204 &&
      _vm.item.msgType !== 10
        ? [
            _c(
              "div",
              {
                staticClass: "msg-avatar",
                on: {
                  click: function ($event) {
                    return _vm._doAction("reply")
                  },
                },
              },
              [
                _c("el-avatar", {
                  attrs: { size: "medium", src: _vm.item.avatar },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "msg-wrapper" }, [
              !_vm.me
                ? _c("div", { staticClass: "msg-user" }, [
                    _c("div", {
                      staticClass: "msg-vip",
                      class:
                        "vip-" +
                        (_vm.item.nickname == "管理员"
                          ? 60
                          : _vm.item.vipLevel),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "user-name",
                        on: {
                          click: function ($event) {
                            return _vm._doAction("reply")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.item.nickname) + "： ")]
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "msg-body", class: _vm.me && "my-body" },
                [
                  _vm.item.msgType === 1
                    ? [
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: {
                                  src: _vm.item.content,
                                  previewSrcList: [_vm.item.content],
                                  fit: "cover",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "setfont",
                              staticStyle: { "margin-top": "5px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.parseTime(
                                      _vm.item.timestamp,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  ) +
                                  " "
                              ),
                              _vm.me
                                ? _c("el-image", {
                                    attrs: {
                                      src: _vm.item.isRead
                                        ? require("@/assets/public_images/hasRead.png")
                                        : require("@/assets/public_images/unRead.png"),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _vm.msgTypeList.includes(_vm.item.msgType)
                    ? [
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("cardItem", {
                                attrs: {
                                  permission: _vm.permission,
                                  item: _vm.item,
                                  me: _vm.me,
                                },
                                on: { _doAction: _vm._doAction },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "setfont",
                                  staticStyle: { "margin-top": "5px" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.parseTime(
                                          _vm.item.timestamp,
                                          "{y}-{m}-{d} {h}:{i}"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _vm.me
                                    ? _c("el-image", {
                                        attrs: {
                                          src: _vm.item.isRead
                                            ? require("@/assets/public_images/hasRead.png")
                                            : require("@/assets/public_images/unRead.png"),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : [
                        _vm.me
                          ? [
                              _c("div", { staticClass: "msg-content setdis" }, [
                                _c("div", { staticClass: "setfont" }, [
                                  _vm._v(
                                    _vm._s(_vm.item.nickname || "在线客服")
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { padding: "7px 0" } },
                                  [_vm._v(" " + _vm._s(_vm.showMessage) + " ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "setfont" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.parseTime(
                                            _vm.item.timestamp,
                                            "{y}-{m}-{d} {h}:{i}"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _vm.me
                                      ? _c("el-image", {
                                          attrs: {
                                            src: _vm.item.isRead
                                              ? require("@/assets/public_images/hasRead.png")
                                              : require("@/assets/public_images/unRead.png"),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          : [
                              _c("div", { staticClass: "msg-content setdis" }, [
                                _c("div", [
                                  _vm._v(" " + _vm._s(_vm.showMessage) + " "),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "setfont" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.parseTime(
                                            _vm.item.timestamp,
                                            "{y}-{m}-{d} {h}:{i}"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _vm.me
                                      ? _c("el-image", {
                                          attrs: {
                                            src: _vm.item.isRead
                                              ? require("@/assets/public_images/hasRead.png")
                                              : require("@/assets/public_images/unRead.png"),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                      ],
                  _c("i", {
                    staticClass: "el-icon-circle-close",
                    class: _vm.me && "my-close",
                    on: {
                      click: function ($event) {
                        return _vm._doAction("del")
                      },
                    },
                  }),
                ],
                2
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }