import { filterColumns } from "@/utils";
const CanChooseColumns = function () {
  return [
    {
      title: '订单信息',
      key: "orderNo",
      align: "center",
      component: {
        render: (h, row) => {
          return (<span>
            <p>订单号：{ row.orderNo }</p>
            <p>{ this.$t("投注时间") }：{ this.$parseTime(row.createTime) }</p>
            <p>{ this.$t("更新时间") }：{ this.$parseTime(row.updateTime) }</p>
          </span>)
        }
      }
    },
    {
      title: this.$t('串关/倍数'),
      key: 'passType',
      align: 'center',
      component: {
        render: (h, row) => {
          const angle = [2, 3, 4, 5, 6, 7, 8].every(o => row[`bunch${o}`] === 0)
          return (
            <div>
              {
                row.passType.split(',').map(o => (
                  <p>{ this.$parent.passTypeOptions[o] }</p>
                ))
              }
              {
                angle
                  ? <div>{this.$t('单关')}:{ row.betCount }</div>
                  : <div>
                    {
                      [2, 3, 4, 5, 6, 7, 8].map(o => {
                        return (row[`bunch${o}`] > 0 && <p>{o}*1:{ row[`bunch${o}`] }</p>)
                      })
                    }
                  </div>
              }
            </div>
          )
        }
      }
    },
    {
      title: '原注单金额',
      key: "amount",
      align: "center",
      component: {
        render: (h, row) => {
          return (<div>
            <p>倍数：{ row.times }</p>
            <p>抵用：{ row.couponAmount }</p>
            <p>投注：{ row.betAmount }</p>
          </div>)
        }
      }
    },
    {
      title: this.$t("用户盈亏"),
      key: "profitAmount",
      align: "center",
      component: {
        render: (h, row) => {
          const label = this.ftBettingStatus.find((o) => o.key === row.status)?.label || ''
          return (<span>
            <p style={row.status === 10 ? "color:#F59A23" : ""}>{ label }</p>
            <p>中奖：{ row.winAmount }</p>
            <p>盈亏：{ row.profitAmount }</p>
          </span>)
        }
      }
    },
    {
      title: '推单金额倍数\n\r（原注单金额）',
      key: "times",
      align: "center",
      component: {
        render: (h, row) => {
          return (<span>
            <el-input type='number' v-model={row.times} style='width: 100px' />倍
          </span>)
        }
      }
    },
    {
      title: '推单金额',
      key: "betAmount",
      align: "center",
      component: {
        render: (h, row) => {
          return (<span>
            投注金额：{ Number(row.betAmount) * Number(row.times) }
          </span>)
        }
      }
    },
    {
      title: '推单方案宣言',
      key: "planSpeech",
      align: "center",
      component: {
        render: (h, row) => {
          return (<span>
            <el-input type='textarea' autosize={{minRows: 3, maxRows: 6}} v-model={row.planSpeech} />
          </span>)
        }
      }
    }
  ]
}

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}
