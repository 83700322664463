<template>
  <div class="app-container">
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :drag-row="true"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      row-key="id"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <el-button type="success" plain size="small" @click="_batchUpdateCpad()">{{ $t('全部保存') }}</el-button>
      </template>
    </d-table>
  </div>
</template>

<script>
import TableSortMixin from '@/mixins/table-sort-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getCpadList, batchUpdateCpad, addCpad, updateCpad, deleteCpad } from '@/api/activity'

export default {
  name: 'CityTab',
  componentName: 'CityTab',
  mixins: [TablePageMixin(getCpadList), TableSortMixin()],
  data() {
    return {
      defaultColumns: GetColumns.call(this), // 默认表格
      columns: [],
      search: {}
    }
  },
  methods: {
    _onCreate() {
      this.$refs.ActionDialogControl.open()
    },

    _handleCreate({ field, cancel, close }) {
      addCpad(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      updateCpad(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    _handleDelete({ id, title }) { // 删除广告
      this.$confirm(`${this.$t('确认删除标题')} ${title} ${this.$t('广告图')}`, { type: 'warning' }).then(() => {
        const params = { id }
        deleteCpad(params).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    _batchUpdateCpad() {
      if (this.items.length === 0) {
        this.$message.warning(this.$t('暂无数据无法保存！'))
        return
      }
      this.$confirm(this.$t('确认批量修改公告排序?'), { type: 'warning' }).then(() => {
        const dataList = this.items.map((o, i) => ({
          id: o.id,
          status: o.status,
          sort: this.total - (this.params.currentPage - 1) * this.params.pageSize - i
        }))

        batchUpdateCpad({ dataList }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('批量修改成功！'))
            this.handleRefresh()
          }
        })
      })
    },

    implementationGetParams() {
      return { ...this.search }
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
