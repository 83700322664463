<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="500px"
    top="5vh"
    :close-on-click-modal="false"
    class="border-form-dialog"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="165px">
      <el-form-item :label="$t('排序')" prop="sortBy">
        <el-input v-model.number="ruleForm.sortBy" :placeholder="$t('数字越大排序越前')" show-word-limit maxlength="4" />
      </el-form-item>
      <el-form-item v-if="ruleForm.items && ruleForm.items.length > 0" :label="$t('频道名称')" prop="channelName" label-width="165px">
        <el-popover placement="bottom" title="频道名称多语言配置" trigger="click">
          <el-input slot="reference" :value="channelName" readonly />
          <div class="lang-pop" style="width: 291px;">
            <el-input v-for="item of ruleForm.items" :key="item.langCode" v-model="item.channelName" :placeholder="$t(item.channelDesc)" />
          </div>
        </el-popover>
      </el-form-item>
      <el-form-item :label="$t('频道图标')" prop="channelIcon">
        <div class="upload">
          <el-upload
            accept="image/png,image/jpg,image/jpeg,image/webp"
            :action="action"
            :data="{ type: 'image', folder: 'icon' }"
            :headers="$headers()"
            :show-file-list="false"
            :on-success="_handleSuccess"
          >
            <img v-if="ruleForm.channelIcon" :src="ruleForm.channelIcon" class="image">
            <i v-else class="el-icon-upload" />
          </el-upload>
          <i class="el-icon-warning" /> 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。
        </div>
      </el-form-item>
      <el-form-item v-if="ruleForm.items && ruleForm.items.length > 0" :label="$t('频道列表名称')" prop="displayName" label-width="165px">
        <el-popover placement="bottom" title="频道列表名称多语言配置" trigger="click">
          <el-input slot="reference" :value="displayName" readonly />
          <div class="lang-pop" style="width: 291px;">
            <el-input v-for="item of ruleForm.items" :key="item.langCode" v-model="item.displayName" :placeholder="$t(item.channelDesc)" />
          </div>
        </el-popover>
      </el-form-item>
      <el-form-item :label="$t('数据来源')" prop="dataSource">
        <el-radio v-for="item of $parent.dataSource" :key="item.key" v-model="ruleForm.dataSource" :label="item.key">{{ item.label }}</el-radio>
        <div>
          <el-select v-show="ruleForm.dataSource === '20'" v-model="ruleForm.dataSourceLive">
            <el-option v-for="item in $parent.dataSourceLive" :key="item.key" :value="item.key" :label="item.label" />
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">
        {{ $t('确认') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  unionId: '',
  sortBy: null,
  channelIcon: '',
  dataSource: '10',
  dataSourceLive: '20',
  items: [],
  channelName: '',
  displayName: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        sortBy: [
          { required: true, message: this.$t('请输入排序'), trigger: 'blur' },
          { pattern: /^(0|[1-9][0-9]*)$/, message: this.$t('请输入正确排序'), trigger: 'blur' }
        ],
        channelName: [{ required: true, message: this.$t('请输入中文频道名称'), trigger: 'blur' }],
        channelIcon: [
          { required: true, message: this.$t('请上传频道图标'), trigger: 'change' }
        ],
        displayName: [{ required: true, message: this.$t('请输入中文频道列表名称'), trigger: 'blur' }]
      }
    }
  },
  computed: {
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    title() {
      return this.emitName === 'create' ? this.$t('频道创建') : this.$t('频道编辑')
    },
    channelName() {
      if (this.ruleForm.items.length > 0) {
        return this.ruleForm.items.find(e => e.langCode === 'zh_CN').channelName
      }
      return ''
    },
    displayName() {
      if (this.ruleForm.items.length > 0) {
        return this.ruleForm.items[0].displayName
      }
      return ''
    }
  },
  watch: {
    channelName(val) {
      this.ruleForm.channelName = val
    },
    displayName(val) {
      this.ruleForm.displayName = val
    }
  },
  methods: {
    open(row) {
      const _this = this
      _this.emitName = 'create'
      const items = _this.$parent.allLang.map(e => { return { langCode: e.lang, channelDesc: e.name, channelId: '', channelName: '', displayName: '' } })
      if (row) {
        _this.ruleForm.unionId = row.unionId
        items.forEach(e => {
          const formLang = row.items.find(r => r.langCode === e.langCode)
          if (formLang) {
            e.channelId = formLang.channelId
            e.channelName = formLang.channelName
            e.displayName = formLang.displayName
          }
        })
        _this.ruleForm.sortBy = row.sortBy
        _this.ruleForm.channelIcon = row.channelIcon
        if (row.dataSource === '10') {
          _this.ruleForm.dataSource = row.dataSource
        } else {
          _this.ruleForm.dataSource = '20'
          _this.ruleForm.dataSourceLive = row.dataSource
        }
        _this.emitName = 'update'
      }
      _this.ruleForm.items = items
      _this.visible = true
    },
    _handleSuccess(res) {
      this.ruleForm.channelIcon = res.data[0]
      this.$refs.validateForm.validateField('channelIcon')
    },
    handleSubmitBefor() {
      let msg = ''
      this.ruleForm.items.forEach(e => {
        if (this.ruleForm.items.some(r => r.langCode !== e.langCode && r.channelName === e.channelName)) {
          msg = '每个语言的频道名称不能相同，请检查'
          return
        }
        if (e.channelName === '' || e.channelName === null) {
          msg = `${e.channelDesc}的频道名称不能为空`
          return
        }
      })
      if (msg !== '') {
        this.$message.error(msg)
        return
      }
      this.handleSubmit()
    }
  }
}
</script>

<style lang="scss" scoped>
.upload {
  display: flex;
  align-items: flex-start;
  color: #BCBCBC;
  > div {
    margin-top: 5px;
  }
  > i {
    margin-top: 9px;
    margin-left: 25px;
    margin-right: 5px;
  }
  ::v-deep .el-upload {
     border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .image {
    width: 50px;
    height: 50px;
  }

}

.lang-pop {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
