var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("新增账号"),
        visible: _vm.visible,
        "status-icon": "",
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("账号"),
                prop: "operatorName",
                required: "",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.operatorName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "operatorName", $$v)
                  },
                  expression: "ruleForm.operatorName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("昵称"), prop: "nickName", required: "" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.nickName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "nickName", $$v)
                  },
                  expression: "ruleForm.nickName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("用户分组"), prop: "groupId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  model: {
                    value: _vm.ruleForm.groupId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "groupId", $$v)
                    },
                    expression: "ruleForm.groupId",
                  },
                },
                _vm._l(_vm.groupList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.groupName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("密码"), prop: "password", required: "" },
            },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.ruleForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "password", $$v)
                  },
                  expression: "ruleForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("确认密码"),
                prop: "checkPassword",
                required: "",
              },
            },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.ruleForm.checkPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "checkPassword", $$v)
                  },
                  expression: "ruleForm.checkPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("账号状态"), required: "" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("正常"))),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("冻结"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "谷歌验证码", prop: "googleCode" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.googleCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "googleCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.googleCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }