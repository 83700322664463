export default {
  mounted() {
    this.visibilitychange()
  },
  methods: {
    visibilitychange() {
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
          this.hiddenTime = new Date().valueOf()
        } else {
          let visibleTime = new Date().valueOf()
          if ((visibleTime - this.hiddenTime) / 1000 > 10) {
            if (this.$socket && (this.$socket.readyState === 2 || this.$socket.readyState === 3)) {
              setTimeout(() => {
                this.handleConnect()
              }, 1000)
            }
          } else {
            console.log('还没有到断开的时间')
          }
        }
      })
    }
  }
}
