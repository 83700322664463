<template>
  <el-dialog
    :title="$t('撤销开奖记账记录')"
    width="80%"
    :visible.sync="visible"
    :close-on-click-modal="false"
    @open="onOpen"
  >
    <div class="filter-container">
      <el-select
        v-model="search.lotteryId"
        clearable
        :placeholder="$t('彩种类型')"
        class="filter-item"
      >
        <el-option v-for="item in lotteryTypes" :key="item.lotteryId" :label="item.name" :value="item.lotteryId" />
      </el-select>
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 400px"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始时间')"
        :end-placeholder="$t('结束时间')"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="false"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </el-dialog>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getCancelIssuePage, cancelDownloadRecord } from '@/api/lottery'
import { getDefaultTimes, exportExcel } from '@/utils'

export default {
  name: 'RevokeRecordDialog',
  mixins: [TablePageMixin(getCancelIssuePage, false)],
  data() {
    return {
      times: getDefaultTimes(),
      lotteryTypes: [],
      columns: GetColumns.call(this),
      search: {},
      visible: false
    }
  },
  methods: {
    open(lotteryId, lotteryList) {
      this.search.lotteryId = lotteryId
      this.lotteryTypes = lotteryList
      this.columns = GetColumns.call(this)
      this.visible = true
    },
    onOpen() {
      this.handleRefresh()
    },
    downloadRecord(row) {
      cancelDownloadRecord({
        lotteryId: row.lotteryId,
        issue: row.issue,
        startTime: this.search.startTime,
        endTime: this.search.endTime
      }).then(([data, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `撤销开奖记账记录-${this.$parseTime(new Date())}.csv`)
        }
      })
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    }
  }
}
</script>
