<template>
  <div class="top-tab">
    <home />
    <game />
  </div>
</template>
<script>
import home from './components/home'
import game from './components/game'

export default {
  components: { home, game }
}
</script>

<style lang="scss" scoped>
.top-tab {
  width: 800px;
}
</style>
