var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("添加限制"),
        width: "700px",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("限制内容") } },
            [
              _c(
                "el-row",
                { attrs: { type: "flex", align: "center" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "regin" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: _vm.$t("请选择"),
                              },
                              model: {
                                value: _vm.ruleForm.region,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "region", $$v)
                                },
                                expression: "ruleForm.region",
                              },
                            },
                            _vm._l(_vm.Regins, function (item, i) {
                              return _c(
                                "el-option",
                                {
                                  key: item.code + i,
                                  attrs: { value: item.code },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.city))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#909399",
                                        "font-size": "12px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.code))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "phone" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: _vm.$t("请输入限制内容") },
                            model: {
                              value: _vm.ruleForm.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("限制说明"), prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "240",
                  "show-word-limit": "",
                  autosize: { minRows: 4, maxRows: 8 },
                  placeholder: _vm.$t("请输入限制说明"),
                },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("取 消"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("确 定")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }