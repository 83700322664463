<template>
  <el-dialog
    :title="emitName === 'create' ? $t('创建播放域名'): $t('编辑播放域名')"
    :visible.sync="visible"
    width="450px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item :label="$t('播放域名简称')" prop="servername">
        <el-input v-model="ruleForm.servername" maxlength="10" />
      </el-form-item>
      <el-form-item :label="$t('播放域名')" prop="serverurl">
        <el-input v-model="ruleForm.serverurl" placeholder="">
          <template slot="append">/live</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('鉴权KEY(主)')" prop="primarykey">
        <el-input v-model="ruleForm.primarykey" :placeholder="$t('请勿输入空格')" />
      </el-form-item>
      <el-form-item :label="$t('鉴权KEY(备)')" prop="backupkey">
        <el-input v-model="ruleForm.backupkey" :placeholder="$t('请勿输入空格')" />
      </el-form-item>
      <el-form-item :label="$t('播放协议')" prop="protocol">
        <el-radio-group v-model="ruleForm.protocol">
          <el-radio :label="1">RTMP</el-radio>
          <el-radio :label="2">FLV</el-radio>
          <el-radio :label="3">HLS</el-radio>
          <el-radio :label="4">UDP</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  pull: true,
  servername: '',
  serverurl: '',
  primarykey: '',
  backupkey: '',
  protocol: null
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      rules: {
        servername: [
          { required: true, message: this.$t('请输入简称'), trigger: 'blur' }
        ],
        serverurl: [
          { required: true, message: this.$t('请输入播放域名'), trigger: 'blur' }
        ],
        primarykey: [
          { required: true, message: this.$t('请输入鉴权KEY'), trigger: 'blur' }
        ],
        backupkey: [
          { required: true, message: this.$t('请输入鉴权KEY'), trigger: 'blur' }
        ],
        protocol: [
          { required: true, message: this.$t('请选择播放协议'), trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    open(row, emitName, fn) {
      if (emitName === 'update') {
        this.ruleForm.tliveid = row.tliveid
        this.ruleForm.ptliveid = row.ptliveid
        this.ruleForm.servername = row.servername
        this.ruleForm.serverurl = row.serverurl.split('/live')[0]
        this.ruleForm.primarykey = row.primarykey
        this.ruleForm.backupkey = row.backupkey
        this.ruleForm.protocol = row.protocol
      } else {
        this.ruleForm.ptliveid = row.tliveid
      }
      this.ruleForm.fn = fn
      this.emitName = emitName
      this.visible = true
    }
  }
}
</script>
