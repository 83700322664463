<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model.trim="search.categoryName" :placeholder="$t('频道分类')" clearable class="filter-item" style="width: 200px;" />
      <el-select
        v-model="search.channelUnionId"
        filterable
        clearable
        collapse-tags
        class="filter-item"
        placeholder="所属频道"
      >
        <el-option
          v-for="item in channelList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" style="float:right" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">{{ $t('新增分类') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="handleSaveChannelType" @update="handleSaveChannelType" />

    <DataManageDialog ref="DataManageDialogControl" />
    <el-dialog
      width="400px"
      :visible="deleteConfirm"
      style="font-size: 16px;"
    >
      <span><i class="el-icon-error" style="color: #F04134; margin-right: 5px;" />确定删除频道分类吗？</span>
      <p style="color: rgba(217, 0, 27, 0.647058823529412);margin-top: 20px;">tips：删除分类后，当前分类视频只会在频道列表展示。</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteConfirm = false">取 消</el-button>
        <el-button type="primary" @click="deleteSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import { getLiveChannelList, liveCategoryList, liveCategoryAdd, liveCategoryUpdate, liveCategoryDelete } from '@/api/live'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import DataManageDialog from './DataManageDialog'
import { mapState } from 'vuex'
import { dataSource, dataSourceLive } from '@/libs/options'

function getParams(field) {
  let params = { ...field }
  if (params.dataSource === '20') {
    params.dataSource = params.dataSourceLive
  }
  if (params.dataSource === '10' && params.lotteryIds.length > 0) {
    params.lotteryIds = params.lotteryIds.map(e => e[1])
  }
  delete params.categoryName
  delete params.dataSourceLive
  return params
}

export default {
  name: 'LiveChannel',
  components: { ActionDialog, DataManageDialog },
  mixins: [TablePageMixin(liveCategoryList)],
  data() {
    return {
      search: {
        isShow: 1
      },
      filterColumn: false,
      columns: GetColumns.call(this),
      channelList: [],
      dataSource,
      dataSourceLive,
      deleteConfirm: false,
      deleteUnionId: ''
    }
  },
  computed: {
    ...mapState('app', ['allLang'])
  },
  created() {
    this.getChannelList()
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogControl.open()
    },
    handleSaveChannelType({ field, cancel, close }) {
      const params = getParams(field)
      params.channelName = this.channelList.find(e => e.unionId === params.value).label
      let service = liveCategoryAdd
      if (field.unionId) {
        service = liveCategoryUpdate
      }
      service(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    getChannelList() {
      getLiveChannelList({ currentPage: 1, pageSize: 200 }).then(([items, err]) => {
        if (!err && items && items.length > 0) {
          this.channelList = items.map(e => {
            return {
              value: e.unionId,
              label: e.items.find(e => e.langCode === 'zh_CN').channelName,
              items: e.items
            }
          })
        }
      })
    },
    handleDeleteChannelType(unionId) {
      this.deleteUnionId = unionId
      this.deleteConfirm = true
    },
    deleteSubmit() {
      liveCategoryDelete({ unionId: this.deleteUnionId }).then(([_, err]) => {
        if (!err) {
          this.$message.success(this.$t('删除成功'))
          this.handleFetch()
        }
        this.deleteConfirm = false
      })
    }
  }
}
</script>
