import { $t } from '@/lang'
export default [
  {
    title: $t('商户ID'),
    key: 'merchantID'
  },
  {
    title: $t('商户订单号'),
    key: 'orderNo'
  },
  {
    title: $t('持卡人'),
    key: 'receivingName'
  },
  {
    title: $t('卡号'),
    key: 'receivingCardNo'
  },
  {
    title: $t('代付金额'),
    key: 'amount'
  },
  {
    title: $t('开户行'),
    key: 'receivingBank'
  },
  {
    title: $t('开户网点'),
    key: 'receivingOutlets'
  }
]
