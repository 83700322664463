<template>
  <el-dialog :visible.sync="visible" title="操作记录" width="75%" @open="handleRefresh">
    <el-table v-loading="loading" :data="items" v-bind="options">
      <el-table-column prop="cerateTime" label="时间" align="center">
        <template slot-scope="{ row }">{{ row.cerateTime | parseTime }}</template>
      </el-table-column>
      <el-table-column prop="tenantCode" label="租户" align="center">
        <template slot-scope="{ row }">{{ getTenantName(row.tenantCode) }}({{ row.tenantCode }})</template>
      </el-table-column>
      <el-table-column prop="operateName" label="操作人员" align="center" />
      <el-table-column prop="plusAmount" label="加款" align="center" />
      <el-table-column prop="lessAmount" label="减款" align="center" />
    </el-table>
    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import VDialogMixin from '@/mixins/v-dialog-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import { thirdOperateRecordList } from '@/api/finance'
export default {
  mixins: [VDialogMixin, TablePageMixin(thirdOperateRecordList, false)],
  computed: {
    ...mapState('app', ['tenants'])
  },
  methods: {
    getTenantName(code) {
      const tmp = this.tenants.find(o => o.tenantCode === code)
      return tmp && tmp.tenantName || ''
    }
  }
}
</script>
