<template>
  <div class="app-container">

    <div class="filter-container">
      <el-input v-model="search.messageContent" clearable placeholder="输入消息内容" class="filter-item"
        style="width: 200px" />
      <el-select v-model="search.sendStatus" placeholder="请选择发送状态" clearable class="filter-item">
        <el-option v-for="(item, key) in cmd" :key="key" :label="item" :value="+key" />
      </el-select>
      <el-button icon="el-icon-search" type="primary" class="filter-item" :loading="loading" @click="handleSearch">{{
        $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置')
        }}</el-button>
      <el-button v-if="permission.Add" icon="el-icon-plus" type="success" class="filter-item"
        @click="$refs.ActionDialogControl.open()">添加定时消息</el-button>
    </div>

    <d-table ref="DTable" v-loading="loading" :data="items" :columns="columns" :original-columns="defaultColumns"
      :options="options" @refresh="handleFetch" @column-change="handleColumnChange" />

    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" />
  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import ActionDialog from './ActionDialog'
import GetColumns from './columns'
import { getMessagePageList, addRobotPageList, delRobotPageList, updateRobotPageList } from '@/api/chat'
import { parentMixin } from '@/mixins/tab-items'
export default {
  name: 'LiveScheduledTasks',
  components: { ActionDialog },
  mixins: [TablePageMixin(getMessagePageList),parentMixin],
  data() {
    return {
      defaultColumns: GetColumns.call(this),
      columns: [],
      search: {
        sendStatus: '',
        messageContent: ''
      },
      cmd: {
        0: '待发送',
        1: '发送成功',
        2: '发送失败',
      }
    }
  },
  methods: {
    _handleCreate({ field, cancel, close }) {
      const params = { ...field }
      delete params.memberName
      delete params.limitType
      delete params.roomType
      if (field.id) {
        updateRobotPageList(params).then(([_, err]) => {
          if (!err) {
            close()
            this.$message.success('编辑成功')
            this.handleRefresh()
          } else {
            cancel()
          }
        })
      } else {
        addRobotPageList(params).then(([_, err]) => {
          if (!err) {
            close()
            this.$message.success('添加成功')
            this.handleRefresh()
          } else {
            cancel()
          }
        })
      }

    },
    handleDelete({ id }) {
      this.$confirm(`${this.$t('确认删除?')}`, { type: 'warning' }).then(() => {
        const params = { id }
        delRobotPageList(params).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },
    implementationGetParams() {
      return { ...this.search }
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
