import Layout from '@/layout'

export default {
  path: '/Finance',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Finance',
  alwaysShow: true,
  meta: { title: '财务管理', icon: 'finance' },
  children: [
    {
      path: '/Finance_Bank',
      component: () => import('@/views/finance/bank'),
      name: 'FinanceBank',
      meta: { title: '银行管理', noCache: true }
    },
    {
      path: '/Finance_Third',
      component: () => import('@/views/finance/third'),
      name: 'FinanceThird',
      meta: { title: '第三方加减款', noCache: true }
    }
  ]
}

