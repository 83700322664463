const CanChooseColumns = function() {
  return [
    {
      title: this.$t('渠道名'),
      key: 'channelName',
      align: 'center'
    },
    {
      title: this.$t('渠道域名'),
      key: 'channelHost',
      align: 'center'
    },
    {
      title: this.$t('推广链接数量'),
      align: 'center',
      key: 'channelLineNum'
    },
    {
      title: this.$t('创建时间'),
      align: 'center',
      key: 'createTime',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: this.$t('更新时间'),
      align: 'center',
      key: 'updateTime',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('状态'),
      align: 'center',
      key: 'channelStatus',
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <el-switch
              v-model={row.channelStatus}
              onChange={value => this.handleChangeStatus(value, row)}
              inactive-color='#ff4949'
              activeValue={1}
              inactiveValue={0} />
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      component: {
        render: (h, row) => {
          return (
            <span>
              <el-button type='primary' onClick={this.handleReset.bind(this, row)}>{this.$t('重置密码')}</el-button>
              <el-button type='primary' onClick={() => this.$refs.LinkDialogControl.open(row)}>{this.$t('管理链接')}</el-button>
            </span>
          )
        }
      }
    },
    {
      title: this.$t('编辑'),
      align: 'center',
      key: 'edit',
      width: 120,
      component: {
        render: (h, row) => {
          return (<el-button type='danger' onClick={this.handleDelete.bind(this, row)}>{this.$t('删除')}</el-button>)
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
