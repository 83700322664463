<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="search.activityStatus" clearable :placeholder="$t('活动状态')" class="filter-item" style="width: 140px">
        <el-option v-for="item in activityStatus" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-input v-model="search.id" clearable maxlength="30" :placeholder="$t('活动ID')" class="filter-item" style="width: 200px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" style="float:right" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">{{ $t('新建活动') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="handleCreateActivity" @update="handleUpdateActivity" />
  </div>
</template>
<script>
import { activityStatus } from '@/libs/options'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getActivityCommonList, createActivityCommon, updateActivityCommon, closeActivityCommon, deleteActivityCommon, getActivityConfTypeList } from '@/api/activity'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { mapState } from 'vuex'

function getParams(field) {
  const { activityDate, appPageId, lang, ...other } = field
  let params = { ...other }
  if (params.activityTimeType === 0) {
    params.activityBeginDate = activityDate[0]
    params.activityEndDate = activityDate[1]
  } else {
    params.activityBeginDate = new Date().valueOf()
    params.activityEndDate = new Date().valueOf() + 10 * 365 * 24 * 60 * 60 * 1000
  }
  if (params.type === '') {
    params.type = 0
  }

  params.lang = lang.toString()
  return params
}

export default {
  name: 'ConfigTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(getActivityCommonList)],
  data() {
    return {
      search: {},
      filterColumn: false,
      activityStatus,
      columns: GetColumns.call(this),
      activityCategory: []
    }
  },
  computed: {
    ...mapState('app', ['allLang'])
  },
  created() {
    getActivityConfTypeList({ currentPage: 1, pageSize: 100 }).then(([items, err]) => {
      if (!err) {
        if (items.length > 0) {
          const list = []
          items.forEach(e => {
            const typeNameList = e.typeName.split(',')
            list.push({
              id: e.id,
              typeName: typeNameList[0].split(':')[1],
              typeNameLang: e.typeName
            })
          })
          this.activityCategory = list
        }
      }
    })
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogControl.open()
    },

    handleCreateActivity({ field, cancel, close }) {
      createActivityCommon({ ...getParams(field) }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('创建成功!'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    handleUpdateActivity({ field, cancel, close }) {
      updateActivityCommon({ ...getParams(field) }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('修改成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleCloseActivity({ id }, switchStatus) {
      this.$confirm(`${this.$t('确定')} ${switchStatus === 0 ? this.$t('关闭') : this.$t('开启')} ${this.$t('活动')}???`, this.$t('系统提示'), { type: 'warning' }).then(() => {
        closeActivityCommon({ id, switchStatus }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    handleDeleteActivity({ id }) {
      this.$confirm(this.$t('确定删除活动?'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        deleteActivityCommon({ id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
