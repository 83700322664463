<template>
  <el-dialog
    title="六合推单（为专家创建推单历史）"
    width="90vw"
    top="5vh"
    :visible.sync="visible"
    class="cancel-dialog"
    @closed="handleClosed"
  >
    <div class="app-container">
      <div class="filter-container">
        <el-input v-model="memberName" clearable placeholder="输入专家账号，最长20个字符" class="filter-item" style="width: 300px" />
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
        <span>会员账号：{{ memberInfo.memberName }}</span>
        <span>会员昵称：{{ memberInfo.nickName }}</span>
        <span>账号类型：{{ guruTypeLabel }}</span>
      </div>
      <d-table
        ref="DTable"
        :data="items"
        :columns="columns"
      />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getGuruList } from "@/api/orderManage";
import { bettingStatus, memberTypes } from "@/libs/options";
import GetColumns from './columns'
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  memberId: '',
  lotteryGuruOrderCreateRequestList: []
})
export default {
  mixins: [FormDialogMixin(getDefaultForm, '')],
  data() {
    return {
      emitName: "push",
      memberName: '',
      memberInfo: {},
      columns: GetColumns.call(this),
      items: [],
      bettingStatus,
      memberTypes
    }
  },
  computed: {
    guruTypeLabel() {
      return this.memberTypes.find(e => e.key === this.memberInfo.guruType)?.label 
    }
  },
  methods: {
    open(list) {
      this.items = []
      list.forEach(e => {
        this.items.push({ ...e, times: '', planSpeech: '' })
      })
      this.visible = true;
    },
    handleSearch() {
      getGuruList({ memberName: this.memberName }).then(([items, err]) => {
        this.memberInfo = {}
        if (!err) {
          if (items && items.length > 0) {
            this.memberInfo = items[0]
          } else {
            this.$message.error('当前会员账号不存在')
          }
        }
      })
    },
    handleSubmitBefor() {
      if (!this.memberInfo.memberId) {
        return this.$message.error('请确定创建推单的专家')
      }
      let msg = ''
      this.items.forEach((e, i) => {
        if (e.times === '') {
          msg = `请输入第${i+1}行的推单金额倍数`
          return
        }
        if (Number(e.times) <= 0) {
          msg = `第${i+1}行的推单金额倍数必须是正整数`
          return
        }
        if (e.planSpeech === '') {
          msg = `请输入第${i+1}行的推单方案宣言`
          return
        }
        this.ruleForm.lotteryGuruOrderCreateRequestList.push({ 
          memberId: this.memberInfo.memberId,
          deductBatchNumber: e.deductBatchNumber,
          createTime: e.createTime,
          times: e.times,
          planSpeech: e.planSpeech
         })
      })
      if (msg !== '') {
        return this.$message.error(msg)
      }
      this.ruleForm.memberId = this.memberInfo.memberId
      this.handleSubmit()
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-container > span {
  display: inline-block;
  width: 150px;
  margin-left: 20px;
}
</style>
