const CanChooseColumns = function() {
  return [
    {
      title: '期数',
      key: 'issue',
      align: 'center'
    },
    {
      title: '名称',
      key: 'pictureName',
      align: 'center'
    },
    {
      title: '查看',
      key: 'url',
      align: 'center',
      component: {
        render: (h, row) => {
          let url = row.url
          if (!url) {
            return <span>无图</span>
          }
          if (this.search.source === 'SJTK') {
            url = url.replace('https://laipusun.cn', 'https://jltkfiles.com')
          } else {
            url = url.replace('https://cnmsy.com.cn', 'https://amtkfiles.com')
          }
          return <span class='primary-type cursor-pointer' style='text-decoration: underline' onClick={() => this.$refs.ImagePreviewControl.open(url)}>查看</span>
        }
      }
    },
    {
      title: '所属图库类别',
      key: 'updateStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.pictureCategoryRelRequestList) {
            const categoryNames = row.pictureCategoryRelRequestList.map(e => e.pictureCategoryName)
            return <span>{ categoryNames.toString() }</span>
          }
          return ''
        }
      }
    },
    {
      title: '更新状态',
      key: 'updateStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            row.updateStatus === 1 && <el-tag type='danger'>未更新</el-tag> || <el-tag type='success'>已更新</el-tag>
          )
        }
      }
    },
    {
      title: '来源',
      key: 'source',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span>{this.sources.find(e => e.value === row.source)?.label}</span>
          )
        }
      }
    },
    {
      title: '启停',
      key: 'status',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          row.status = row.status === 0 ? 0 : 1
          if (this.permission.Open) {
            return (
              <d-switch v-model={row.status} activeValue={1} inactiveValue={0} beforeChange={this.handleChangeStatus.bind(this, row)}>
                <span slot='open'>启用</span>
                <span slot='close'>停用</span>
              </d-switch>
            )
          }
          return <span>{ this.commonStatus[row.status] }</span>
        }
      }
    },
    {
      title: '操作',
      align: 'center',
      key: 'actions',
      width: 280,
      component: {
        render: (h, row) => {
          const { Upload, Delete } = this.permission
          return (
            <span>
              { Upload && <el-button type='success' size='mini' icon='el-icon-edit' onClick={() => this.$refs.UpdateDialogControl.open(row, row.updateStatus === 1 ? 2 : 1)}>{ row.updateStatus === 1 ? '上传' : '替换' }</el-button> }
              { Comment && row.updateStatus === 2 && <el-button type='primary' size='mini' icon='el-icon-s-comment' onClick={() => this.$refs.CommentDialogControl.open(row)}>{ '评论' }</el-button> }
              { Delete && <el-button type='danger' size='mini' icon='el-icon-delete' onClick={this.handleDelete.bind(this, row)}>删除</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
