<template>
  <el-dialog :title="$t('修改用户等级')" :visible.sync="visible" @open="onOpen" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('用户等级')" prop="vipLevel">
        <el-select v-model="ruleForm.vipLevel" :placeholder="$t('请选择会员等级')" style="width: 100%;">
          <el-option v-for="item in Levels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" :disabled="ruleForm.vipLevel === item.vipLevel" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('锁定状态')" prop="vipStatus">
        <el-radio-group v-model="ruleForm.vipStatus">
          <el-radio :label="1">{{ $t('锁定') }}</el-radio>
          <el-radio :label="0">{{ $t('不锁定') }}</el-radio>
        </el-radio-group>
        <p class="error-type">{{ $t('锁定后') }}</p>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getAllVipLevels } from '@/api/app'

const getDefaultForm = () => ({
  vipLevel: null,
  vipStatus: 0
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      Levels: [],
      rules: {
        vipLevel: [
          { required: true, message: this.$t('请选择用户等级'), trigger: 'change' }
        ],
        vipStatus: [
          { required: true, message: this.$t('请选择锁定状态'), trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    open({ memberId, vipLevel, vipStatus }) {
      this.ruleForm = { memberId, vipLevel, vipStatus }
      this.visible = true
    },
    onOpen() {
      getAllVipLevels().then(([data, err]) => {
        this.Levels = data
      })
    }
  }
}
</script>
