var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "border-form-dialog",
      attrs: {
        title: _vm.$t("编辑房间"),
        visible: _vm.visible,
        width: "520px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("房间主题"), prop: "roomName" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.disabled,
                  "show-word-limit": "",
                  maxlength: "12",
                },
                model: {
                  value: _vm.ruleForm.roomName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "roomName", $$v)
                  },
                  expression: "ruleForm.roomName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("分辨率"), prop: "displayStatus" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.ruleForm.displayStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "displayStatus", $$v)
                    },
                    expression: "ruleForm.displayStatus",
                  },
                },
                _vm._l(_vm.$parent.displayStatusList, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: +key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("彩种类型"), prop: "lottypeId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: _vm.$t("彩种类型"),
                  },
                  on: { change: _vm.handleChangeLottertType },
                  model: {
                    value: _vm.ruleForm.lottypeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "lottypeId", $$v)
                    },
                    expression: "ruleForm.lottypeId",
                  },
                },
                _vm._l(_vm.$parent.livePreLotteryType, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("彩种/赛事"),
                prop: "lotkindId",
                id: "lotkindId",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    remote: "",
                    "collapse-tags": "",
                    disabled:
                      _vm.disabled ||
                      _vm.ruleForm.lottypeId === undefined ||
                      _vm.ruleForm.lottypeId === "" ||
                      _vm.ruleForm.lottypeId === null ||
                      _vm.ruleForm.lottypeId === 4,
                    "reserve-keyword": "",
                    placeholder: "彩种/赛事",
                    "remote-method": _vm.remoteMethod,
                    loading: _vm.lotkindIdLoading,
                  },
                  model: {
                    value: _vm.ruleForm.lotkindId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "lotkindId", $$v)
                    },
                    expression: "ruleForm.lotkindId",
                  },
                },
                _vm._l(_vm.lotteryList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("频道"), prop: "channelUnionId" } },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.ruleForm.channelUnionId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "channelUnionId", $$v)
                    },
                    expression: "ruleForm.channelUnionId",
                  },
                },
                _vm._l(_vm.$parent.channelList, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.channelUnionId.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("直播推荐") } },
                _vm._l(
                  _vm.$parent.channelList.filter(function (e) {
                    return _vm.ruleForm.channelUnionId.includes(e.value)
                  }),
                  function (item) {
                    return _c(
                      "div",
                      { key: item.value, staticClass: "channel-type" },
                      [
                        _vm._v(" " + _vm._s(item.label) + "频道 "),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              disabled: _vm.disabled,
                              filterable: "",
                              clearable: "",
                              "collapse-tags": "",
                              placeholder: "请输入频道分类搜索",
                            },
                            model: {
                              value: item.categoryUnionId,
                              callback: function ($$v) {
                                _vm.$set(item, "categoryUnionId", $$v)
                              },
                              expression: "item.categoryUnionId",
                            },
                          },
                          _vm._l(
                            _vm.$parent.categoryList.filter(function (e) {
                              return e.channelUnionId === item.value
                            }),
                            function (category) {
                              return _c("el-option", {
                                key: category.value,
                                attrs: {
                                  label: category.label,
                                  value: category.value,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                0
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("人工热度"), prop: "artificialHotNum" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("最大可输入99999") },
                model: {
                  value: _vm.ruleForm.artificialHotNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "artificialHotNum", _vm._n($$v))
                  },
                  expression: "ruleForm.artificialHotNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "直播方式", prop: "serverSource" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "直播方式" },
                  model: {
                    value: _vm.ruleForm.serverSource,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "serverSource", $$v)
                    },
                    expression: "ruleForm.serverSource",
                  },
                },
                _vm._l(_vm.serverSource, function (val, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: val, value: Number(key) },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.serverSource === 2
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("流媒体地址") } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "输入https链接，http链接会导致h5无法显示",
                    },
                    model: {
                      value: _vm.streamMediaUrl,
                      callback: function ($$v) {
                        _vm.streamMediaUrl = $$v
                      },
                      expression: "streamMediaUrl",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.ruleForm.serverSource === 4
            ? _c(
                "el-form-item",
                { attrs: { label: "点播视频地址" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "输入https链接，http链接会导致h5无法显示",
                    },
                    model: {
                      value: _vm.streamMediaUrl,
                      callback: function ($$v) {
                        _vm.streamMediaUrl = $$v
                      },
                      expression: "streamMediaUrl",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.ruleForm.serverSource === 3
            ? _c("el-form-item", { attrs: { label: "推流地址" } }, [
                _vm._v(" " + _vm._s(_vm.streamUrl) + " "),
              ])
            : _vm._e(),
          _vm.ruleForm.serverSource === 3
            ? _c("el-form-item", { attrs: { label: "推流码" } }, [
                _vm._v(" " + _vm._s(_vm.streamKey) + " "),
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("直播封面"), prop: "cover" } },
            [
              _vm.disabled
                ? _c("img", {
                    staticClass: "thumbnail",
                    attrs: { src: _vm.ruleForm.cover },
                  })
                : _c(
                    "el-upload",
                    {
                      staticClass: "thumbnail-uploader",
                      attrs: {
                        accept: "image/jpeg,image/png,image/jpg,image/webp",
                        action: _vm.action,
                        data: { type: "image", folder: "ad-photo" },
                        headers: _vm.$headers(),
                        "show-file-list": false,
                        "on-success": _vm.handleThumbnailSuccess,
                      },
                    },
                    [
                      _vm.ruleForm.cover
                        ? _c("img", {
                            staticClass: "thumbnail",
                            attrs: { src: _vm.ruleForm.cover },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus thumbnail-uploader-icon",
                          }),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确认")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }