<template>
  <el-dialog
    :title="$t('发送个人站内信')"
    width="45%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="visible"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('接收人')">
        <el-input v-model="ruleForm.memberList" disabled />
      </el-form-item>
      <el-form-item :label="$t('站内信标题')">
        <el-input v-model="ruleForm.title" />
      </el-form-item>
      <el-form-item :label="$t('站内信内容')">
        <tinymce v-if="visible" ref="tinymce" v-model="ruleForm.content" :height="235" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import Tinymce from '@/components/Tinymce'

const getDefaultForm = () => ({
  memberList: '',
  title: '',
  sendType: 1,
  content: ''
})

export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        title: [{ required: true, message: this.$t('请输入站内信标题'), trigger: 'blur' }],
        content: [{ required: true, message: this.$t('请输入站内信内容'), trigger: 'blur' }]
      }
    }
  },
  methods: {
    open(memberName) {
      this.ruleForm.memberList = memberName
      this.visible = true
    },
    implementationClosed() {
    },
    close() {
      this.$refs.tinymce.setContent('')
      this.submitting = false
      this.visible = false
    }
  }
}
</script>
