<template>
  <el-dialog
    :title="`${$t('对应推流域名')}  ${serverurl}`"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="75%"
    @open="handleRefresh"
    @closed="handleClosed"
  >
    <el-table v-loading="loading" :data="items" v-bind="options">
      <el-table-column prop="servername" :label="$t('简称')" align="center" width="100" />
      <el-table-column prop="serverurl" :label="$t('播放域名')" align="center" />
      <el-table-column prop="primarykey" :label="$t('播放鉴权KEY(主)')" align="center" />
      <el-table-column prop="backupkey" :label="$t('播放鉴权KEY(备)')" align="center" />
      <el-table-column prop="protocol" :label="$t('播放协议')" align="center">
        <template slot-scope="{ row }">
          <template v-if="row.protocol === 1">RTMP</template>
          <template v-else-if="row.protocol === 2">FLV</template>
          <template v-else-if="row.protocol === 3">HLS</template>
          <template v-else-if="row.protocol === 4">UDP</template>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" :label="$t('创建时间')" align="center" width="130">
        <template slot-scope="{ row }">{{ row.createTime | parseTime }}</template>
      </el-table-column>
      <el-table-column :label="$t('操作信息')" align="center" width="140">
        <template slot-scope="{ row }">
          <div>{{ $t('操作人') }}:{{ row.operatorName }}</div>
          <div>{{ row.updateTime | parseTime }}</div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('启用')" width="100" align="center">
        <template slot-scope="{ row }">
          <d-switch
            v-model="row.status"
            :active-value="true"
            :inactive-value="false"
            size="default"
            :disabled="!$parent.permission.StartStop"
            :before-change="() => _onStatus(row)"
          >
            <span slot="open" />
            <span slot="close" />
          </d-switch>
        </template>
      </el-table-column>

      <el-table-column :label="$t('操作')" width="200" align="center">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            @click="_onUpdate(row)"
          >{{ $t('编辑') }}</el-button>
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="_onDel(row)"
          >{{ $t('删除') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import { getAllPullServers, doDisableServer } from '@/api/live'
import TablePageMixin from '@/mixins/table-page-mixin'
export default {
  mixins: [TablePageMixin(getAllPullServers, false)],
  data() {
    return {
      visible: false,
      serverurl: '',
      params: {},
      parameter: null
    }
  },
  methods: {
    open({ tliveid, serverurl }) {
      this.serverurl = serverurl
      this.parameter = { tliveid }
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleClosed() {
      this.serverurl = ''
      this.parameter = null
    },
    _onUpdate(row) {
      this.$parent.$refs.ActionPlayDialogControl.open(row, 'update', this.handleFetch)
    },
    _onStatus({ tliveid, status }) {
      return new Promise((resolve) => {
        this.$confirm(status === false ? this.$t('确认启用此域名') : this.$t('确认停用此域名'), this.$t('系统提示'), { type: 'warning' }).then(() => {
          doDisableServer({
            tliveid,
            status: ~status + 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    _onDel(row) {
      this.$parent._handleDelete(row)
    },
    implementationGetParams() {
      return { ...this.parameter }
    }
  }
}
</script>
