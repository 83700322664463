<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model.trim="search.memberName" clearable :placeholder="$t('会员账号')" class="filter-item" style="width: 200px" />
      <el-input v-model.trim="search.walletName" clearable :placeholder="$t('钱包名称')" class="filter-item" style="width: 200px" />
      <el-input v-model.trim="search.walletCard" clearable :placeholder="$t('钱包地址')" class="filter-item" style="width: 200px" />
      <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleRefresh">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>
<script>
import { paymentwalletList, paymentwalletDel } from '@/api/finance'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
export default {
  name: 'FinanceSlpay',
  mixins: [TablePageMixin(paymentwalletList)],
  data() {
    return {
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this)
    }
  },
  methods: {
    // 删除银行
    _delete(id) {
      this.$confirm(('确认删除该钱包吗？'), {
        type: 'warning'
      }).then(() => {
        paymentwalletDel({ id }).then(([data, err]) => {
          if (!err) {
            this.handleRefresh()
            this.$message.success('删除成功！')
          }
        })
      })
    },

    implementationGetParams() {
      return { ...this.search }
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
