<template>
  <el-dialog :title="title" :visible.sync="visible" width="500px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('聊天室图标')" prop="roomIcon">
        <DUpload
          v-model="ruleForm.roomIcon"
          folder="icon"
          :width="60"
          :height="60"
          :on-success="handleSuccess"
        />
      </el-form-item>
      <el-form-item :label="$t('聊天室类型')" prop="roomType">
        <el-select v-model="ruleForm.roomType" disabled>
          <el-option
            v-for="item in roomTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('聊天室名称')" prop="roomName">
        <el-input v-model="ruleForm.roomName" maxlength="12" show-word-limit :placeholder="$t('请输入', {text: $t('聊天室名称')})" />
      </el-form-item>
      <el-form-item :label="$t('聊天室描述')" prop="roomInfo">
        <el-input v-model="ruleForm.roomInfo" type="textarea" maxlength="30" show-word-limit :placeholder="$t('请输入', {text: $t('聊天室描述')})" :rows="6" />
      </el-form-item>
      <el-form-item :label="$t('发红包')" prop="canSendRedPacket">
        <el-switch
              v-model="ruleForm.canSendRedPacket"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
      </el-form-item>
      
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button v-if="emitName ==='update'" type="danger" style="float: left; margin-left: 24px;" size="medium" @click="_deleteRoom">
        {{ $t('删除') }}
      </el-button>
      <el-button size="medium" @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" size="medium" :loading="submitting" @click="handleSubmit">
        {{ $t('确认') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { deleteRoom } from '@/api/chat'
import DUpload from '@/components/DUpload'
const getDefaultForm = () => ({
  roomIcon: '',
  roomName: '',
  roomInfo: '',
  roomId: '',
  canSendRedPacket:''
})

export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      roomTypes: this.$t('roomTypes'),
      rules: {
        rooymTpe: [
          { required: true, message: this.$t('请选择', { text: this.$t('聊天室类型') }), trigger: 'blur' }
        ],
        roomName: [
          { required: true, message: this.$t('请输入', { text: this.$t('聊天室名称') }), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    title() {
      const actionName = this.emitName === 'create' ? this.$t('添加') : this.$t('编辑')
      return actionName + this.$t('聊天室')
    }
  },
  methods: {
    open(row) {
      this.ruleForm.roomType = this.$parent.roomType
      if (row) {
        this.emitName = 'update'
        this.ruleForm.id = row.id
        this.ruleForm.roomIcon = row.roomIcon
        this.ruleForm.roomName = row.roomName
        this.ruleForm.roomInfo = row.roomInfo
        this.ruleForm.canSendRedPacket=row.canSendRedPacket
        this.ruleForm.roomId = row.roomId
      } else {
        this.emitName = 'create'
      }
      this.visible = true
    },
    handleSuccess(data) {
      this.ruleForm.roomIcon = data + ''
    },
    _deleteRoom() {
      this.$confirm(this.$t('确认删除当前聊天室，删除后聊天室的会员全部踢出聊天室，请谨慎操作'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        deleteRoom({ id: this.ruleForm.id }).then(([data, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.visible = false
            this.$emit('deleteRoom')
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
