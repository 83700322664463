<template>
  <div class="app-container">
    <el-tabs v-model="search.appType" type="card" @tab-click="_handleTabClick">
      <el-tab-pane label="Android版本" name="1" />
      <el-tab-pane label="IOS版本" name="2" />
    </el-tabs>

    <el-button
      v-if="permission.AddVersion"
      type="success"
      icon="el-icon-plus"
      :style="{ position: 'absolute', top: '12px', right: '12px' }"
      @click="onAction"
    >创建版本</el-button>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" />

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import methodMixins from '../common/methodMixins'
import GetColumns from '../common/columns'
import ActionDialog from '../common/ActionDialog'
import { getVersionList } from '@/api/version'

export default {
  name: 'VersionAnchor',
  components: { ActionDialog },
  mixins: [TablePageMixin(getVersionList), methodMixins],
  data() {
    return {
      search: { servicePlatform: 2, appType: '1' },
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  }
}
</script>
