<template>
  <el-dialog :title="title" :visible.sync="visible" width="460px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="160px" label-suffix=":">
      <!-- <el-form-item v-if="emitName === 'create'" label="代理ID" prop="agencyId">
        <el-input v-model="ruleForm.agencyId" style="width: 240px" />
      </el-form-item> -->

      <el-form-item :label="$t('代理账号')" prop="memberName">
        <el-input v-model="ruleForm.memberName" style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('代理下会员人数')" prop="agencyMembers">
        <el-input v-model="ruleForm.agencyMembers" style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('代理总返佣金额')" prop="rebateAmount">
        <el-input v-model="ruleForm.rebateAmount" style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('排序')" prop="sort">
        <el-input v-model="ruleForm.sort" style="width: 240px" />
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { integer1, numerical, stringCE3_12 } from '@/utils/validation.js'
const getDefaultForm = () => ({
  memberName: '',
  agencyMembers: '',
  rebateAmount: '',
  sort: ''
})
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        memberName: [
          { required: true, message: this.$t('代理账号'), trigger: 'blur' },
          { pattern: stringCE3_12, message: this.$t('代理账号格式不正确'), trigger: 'blur' }
        ],
        agencyMembers: [
          { required: true, message: this.$t('代理下会员人数'), trigger: 'blur' },
          { pattern: integer1, message: this.$t('代理下会员人数格式不正确'), trigger: 'blur' }
        ],
        rebateAmount: [
          { required: true, message: this.$t('代理总返佣金额'), trigger: 'blur' },
          { pattern: numerical, message: this.$t('代理总返佣金额格式不正确'), trigger: 'blur' }
        ],
        sort: [
          { required: true, message: this.$t('排序'), trigger: 'blur' },
          { pattern: integer1, message: this.$t('排序格式不正确'), trigger: 'blur' }
        ]

      }
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? this.$t('添加代理推广') : this.$t('编辑代理推广')
    }
  },
  methods: {
    open(row, tenantCode) {
      this.ruleForm.tenantCode = tenantCode
      if (row) {
        // this.ruleForm.id = row.id
        // this.ruleForm.level = row.level
        // this.ruleForm.requireSpreadNum = row.requireSpreadNum
        // this.ruleForm.requireBetAmount = row.requireBetAmount
        // this.ruleForm.requireRechargeAmount = row.requireRechargeAmount
        // this.ruleForm.oneLevelAgencyRebate = row.oneLevelAgencyRebate
        // this.ruleForm.twoLevelAgencyRebate = row.twoLevelAgencyRebate
        Object.assign(this.ruleForm, row)
        this.emitName = 'update'
      } else {
        this.emitName = 'create'
      }
      this.visible = true
    }
  }
}
</script>
