<template>
  <el-dialog
    :title="$t('审核不通过原因')"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="400px"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="60px" label-suffix=":">
      <el-form-item :label="$t('备注')" prop="remark">
        <el-input type="textarea" v-model="ruleForm.remark" :rows="8" :placeholder="$t('请输入审核不通过原因')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  orderNo: '',
  status: 2,
  remark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        remark: [{ required: true, message: this.$t('请输入审核不通过原因'), trigger: 'blur' }]
      }
    }
  },
  methods: {
    open(row) {
      this.ruleForm.orderNo = row.orderNo
      this.visible = true
    }
  }
}
</script>
