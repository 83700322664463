<template>
  <div class="app-container">
    <el-form ref="validateForm" v-loading="loading" :model="ruleForm" :rules="rules" label-width="160px" label-suffix=":">
      <el-form-item label="帖子类型">
        <el-radio-group v-model="type">
          <el-radio :label="1">后台圈子</el-radio>
          <el-radio :label="0">代用户圈子</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="type === 0">
        <el-form-item label="用户账号" prop="memberName">
          <el-input v-model="ruleForm.memberName" placeholder="用户账号" style="width: 500px" />
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="后台用户" prop="nickname">
          <el-input v-model="ruleForm.nickname" placeholder="后台用户昵称" style="width: 500px" />
        </el-form-item>
      </template>
      <el-form-item label="正文内容" prop="content">
        <el-input v-model="ruleForm.content" :autosize="{ minRows: 6 }" type="textarea" style="width: 500px" maxlength="255" show-word-limit placeholder="正文内容" />
      </el-form-item>
      <el-form-item label="上传图片">
        <el-upload
          class="banner-uploader"
          accept="image/jpeg,image/png,image/jpg,image/webp"
          :action="action"
          :data="data"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleUploadSuccess"
        >
          <img v-if="ruleForm.url" :src="ruleForm.url" class="banner">
          <div v-else class="banner-uploader-txt">
            <p>文件大小控制在100K以内</p>
            <button class="el-button el-button--small el-button--orange" @click.self.prevent>点击上传</button>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="danger" size="large" style="width: 140px" @click="goback">取消</el-button>
        <el-button type="success" size="large" style="width: 140px" @click="handleSubmit">确认</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>
<script>
import {
  sendAgentArticle,
  sendArticle
} from '@/api/interactive'
export default {
  name: 'ShareLapRelease',
  data() {
    return {
      tenantCode: null,
      type: 0,
      loading: false,
      ruleForm: {
        memberName: '',
        nickname: '',
        content: '',
        url: '',
        imgList: []
      },
      rules: {
        nickname: [
          { required: true, message: '请输入后台用户昵称', trigger: 'blur' }
        ],
        memberName: [
          { required: true, message: '请输入用户账号', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入正文内容', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    data() {
      return { type: 'image', folder: 'ad-photo' }
    }

  },
  created() {
    const { code, lotteryId } = this.$route.query
    this.ruleForm.lotteryId = lotteryId
    if (code) this.tenantCode = code
  },
  methods: {
    goback() {
      this.$router.go(-1)
    },
    handleUploadSuccess(res) {
      this.ruleForm.url = res.data[0]
      this.ruleForm.imgList.push(res.data[0])
    },
    handleSubmit() {
      this.$refs.validateForm.validate(valid => {
        if (valid) {
          const handle = this.type === 0 ? sendAgentArticle : sendArticle
          delete this.ruleForm.url
          this.ruleForm.tenantCode = this.$store.getters.tenantCode
          if (this.$P$ && this.tenantCode) this.ruleForm.tenantCode = this.tenantCode
          handle(this.ruleForm).then(([data, err]) => {
            if (!err) {
              this.$message.success('操作成功')
              this.goback()
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.banner-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.border_{ padding: 5px 10px; box-sizing: border-box; }
.banner-uploader .el-upload:hover {
    border-color: #409EFF;
  }
.banner-uploader-txt {
  font-size: 14px;
  color: #8c939d;
  width: 375px;
  height: 128px;
  text-align: center;
  p {
    padding-top: 20px;
  }
}
.banner {
  width: 375px;
  height: 128px;
  display: block;
}

</style>
