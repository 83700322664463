import request from '@/plugins/axios'

export function getExpertList(data) {
  return request({
    url: '/platform/pageExpert',
    method: 'post',
    data
  })
}

export function createExpert(data) {
  return request({
    url: '/platform/doAddExpert',
    method: 'post',
    data
  })
}

export function getExpertDetailById(data) {
  return request({
    url: '/platform/getExpertDetailById',
    method: 'post',
    data
  })
}

export function getArticleExperById(data) {
  return request({
    url: '/platform/pageArticleByExpertId',
    method: 'post',
    data
  })
}

export function updateExpert(data) {
  return request({
    url: '/platform/doUpdateInitExpert',
    method: 'post',
    data
  })
}

export function doDelExpertArticleById(data) {
  return request({
    url: '/platform/doDelExpertArticleById',
    method: 'post',
    data
  })
}

export function updateExpertStatus(data) {
  return request({
    url: '/platform/doUpdateExpertStatus',
    method: 'post',
    data
  })
}

export function getExpertPositionList(data) {
  return request({
    url: '/platform/pagePosition',
    method: 'post',
    data
  })
}

export function deleteMaxLevelPosition(data) {
  return request({
    url: '/platform/doDelMaxLevel',
    method: 'post',
    data
  })
}

export function saveExpertPosition(data) {
  return request({
    url: '/platform/doSaveExpertPosition',
    method: 'post',
    data
  })
}

export function getExpertArticleDetailById(data) {
  return request({
    url: '/platform/getExpertArticleDetailById',
    method: 'post',
    data
  })
}

// 推荐方案
export function getExpertArticleList(data) {
  return request({
    url: '/platform/pageExpertArticle',
    method: 'post',
    data,
    timeout: 20000
  })
}

export function doUpdateExpertArticleStatus(data) {
  return request({
    url: '/platform/doUpdateExpertArticleStatus',
    method: 'post',
    data
  })
}

export function updateExpertArticleDetailById(data) {
  return request({
    url: '/platform/doUpdateExpertArticleInfo',
    method: 'post',
    data
  })
}
