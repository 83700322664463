var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.permission.Creata
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.ActionDialogControl.open()
                    },
                  },
                },
                [_vm._v("加款扣款")]
              )
            : _vm._e(),
          _vm.permission.Details
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.OperatorDialogControl.open()
                    },
                  },
                },
                [_vm._v("操作记录")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            _vm._b(
              {
                attrs: {
                  data: _vm.list,
                  "show-summary": "",
                  "summary-method": _vm._getSummaries,
                },
              },
              "el-table",
              _vm.options,
              false
            ),
            [
              _c("el-table-column", {
                attrs: { prop: "tenantCode", label: "租户", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(row.tenantName) +
                            "(" +
                            _vm._s(row.tenantCode) +
                            ")"
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._l(_vm.customizeColumus, function (value, tag) {
                return _c("el-table-column", {
                  key: tag,
                  attrs: {
                    label: value.label,
                    prop: value.prop,
                    align: "center",
                  },
                })
              }),
              _c("el-table-column", {
                attrs: { prop: "total", label: "总入款", align: "center" },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("ActionDialog", {
        ref: "ActionDialogControl",
        on: { update: _vm.handleUpdate },
      }),
      _c("OperatorDialog", {
        ref: "OperatorDialogControl",
        on: { update: _vm.handleUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }