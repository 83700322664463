<template>
  <el-dialog title="添加图库集合" :visible.sync="visible" width="46%" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="六合彩种" prop="pictureTag">
        <el-select v-model="ruleForm.pictureTag" class="filter-item" disabled placeholder="选择六合彩种" style="width: 100%">
          <el-option label="快乐六合彩" value="KLLH01" />
        </el-select>
      </el-form-item>
      <el-form-item label="图库名称" prop="pictureName" >
        <el-input v-model="ruleForm.pictureName" maxlength="20" placeholder="请输入图库名称，最长20个字符" />
      </el-form-item>
      <el-form-item :label="$t('图库类别')" prop="pictureCategoryRelRequestList">
        <el-select 
          v-model="pictureCategoryRelRequestList" 
          multiple
          collapse-tags 
          filterable 
          placeholder="请输入图库类别查询，最多20个字"
          maxlength="20"
          style="width: 100%;"
        >
          <el-option v-for="item in $parent.picList" :key="item.id" :label="item.categoryName" :value="item.id" />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  pictureTag:'KLLH01',
  pictureName: '',
  pictureCategoryRelRequestList: []
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      pictureCategoryRelRequestList: [],
      rules: {
        pictureTag: [
          { required: true, message: '请选择六合彩种', trigger: 'change' },
        ],
        pictureName: [
          { required: true, message: '请填写名称', trigger: 'blur' },
          { pattern: /^([^\s]){1,50}$/, message: '最多50个空格以外字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    handleSubmitBefor() {
      this.ruleForm.pictureCategoryRelRequestList = []
      this.pictureCategoryRelRequestList.forEach(e => {
        const category = this.$parent.picList.find(v => v.id === e)
        if (category) {
          this.ruleForm.pictureCategoryRelRequestList.push({ pictureName: category.categoryName, pictureCategoryId: category.id })
        }
      })
      this.handleSubmit()
    }
  }
}
</script>
