import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
export default (getDefaultForm, validateForm = 'validateForm') => ({
  data() {
    return {
      visible: false,
      submitting: false,
      ruleForm: getDefaultForm()
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.ruleForm.url = ''
      this.fileList = []
      this.visible = false
      setTimeout(() => {
        this.submitting = false
      }, 500)
    },
    implementationClosed() {
      //
    },
    handleClosed() {
      this.implementationClosed()
      this.ruleForm = getDefaultForm()
      // if (validateForm) {
      //   this.$refs[validateForm].resetFields()
      // }
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields();
      }
    },
    handleSubmit: debounce(function() {
      if (this.beforeSubmit && !this.beforeSubmit()) return false
      if (!validateForm) {
        this._handleDoEmit()
        return
      }
      
      this.$refs[validateForm].validate(valid => {
        if (valid) {
          this._handleDoEmit()
        } else {
          return false
        }
      })
    }, 300, {
      'leading': true,
      'trailing': false
    }),
    _handleDoEmit() {
      this.submitting = true
      this.$emit(this.emitName, {
        field: cloneDeep(this.ruleForm),
        cancel: () => {
          this.submitting = false
        },
        close: this.close
      })
    }
  }
})
