export default {
  memberTypes: [
    { key: 1, label: '内部账号' },
    { key: 2, label: '注册账户' },
    { key: 3, label: '充值账户' },
    { key: 4, label: '测试账户' }
  ],
  memberType: [
    { value: [1], label: '内部账号' },
    { value: [2,3], label: '普通账号' },
  ],
  smallPlayType:[
    {
      label: "滚球",
      value: 1502,
    },
    {
      label: "竞彩足球",
      value: 99,
    },
    {
      label: "香港六合彩",
      value: 40,
    },
    {
      label: "新澳门六合彩",
      value: 41,
    },
  ],
  bigPlayType:[
    {
      label: "竞彩体育",
      value: 1,
    },
    {
      label: "六合彩",
      value: 2,
    },
  ],
  // 2:待开奖 5:未中奖 6:已中奖  10:已撤单 11:处理失败 12:扣款失败 20:异常订单 21:已取消比赛订单
  orderStatus:{
    2:'待开奖', 5:'未中奖', 6:'已中奖' , 10:'已撤单', 11:'处理失败', 12:'扣款失败', 20:'异常订单', 21:'已取消比赛订单'
  },
  // [
  //   {
  //     label: "待开奖",
  //     value: 2,
  //   },
  //   {
  //     label: "未中奖",
  //     value: 5,
  //   },
  //   {
  //     label: "已中奖",
  //     value: 6,
  //   },
  //   {
  //     label: "已撤单",
  //     value: 10,
  //   },
  //   {
  //     label: "处理失败",
  //     value:11,
  //   },
  //   {
  //     label: "扣款失败",
  //     value: 12,
  //   },
  //   {
  //     label: "异常订单",
  //     value: 20,
  //   },
  //   {
  //     label: "已取消比赛订单",
  //     value: 21,
  //   },
  // ],
  depositTabs: [
    { label: '充值订单审核', name: 'CheckTab' },
    { label: '充值订单列表', name: 'ListTab' }
  ],
  rechargeStatus: [
    { key: 0, label: '全部' },
    { key: 1, label: '首次充值' },
    { key: 2, label: '二次充值' },
    { key: 3, label: '多次充值' }
  ],
  withdrawalTabs: [
    { label: '提现订单拾取', name: 'ReceiveTab' },
    { label: '提现订单审核', name: 'CheckTab' },
    { label: '提现订单列表', name: 'ListTab' }
  ],
  dateFilterButtons: [
    { label: '今日', key: 'today' },
    { label: '昨日', key: 'yesterday' },
    { label: '本月', key: 'thisMonth' },
    { label: '上月', key: 'lastMonth' }
  ],
  configBasicTabs: [
    { label: '基础配置', name: 'BaseTab' },
    { label: '交互配置', name: 'InteractionTab' },
    { label: '地址配置', name: 'AddressTab' },
    { label: '第三方游戏配置', name: 'ThirdTab' },
    // { label: 'Carsh配置', name: 'CarshTab' },
    { label: '汇率配置', name: 'RateTab' }
  ],
  orderManage_config: [
    { label: '推单规则配置', name: 'OrderManage_RulesConfig_index' },
    { label: '推单说明配置', name: 'OrderManage_RulesConfig_explain' },
    { label: '排行榜说明配置', name: 'OrderManage_RulesConfig_ranking' }
  ],
  smsVendorOptions: [
    { signname: '云之讯', smsVendor: 'YUNZHIXUN', sortOrder: 0 },
    { signname: '阿里', smsVendor: 'ALI', sortOrder: 1 },
    { signname: '极光', smsVendor: 'JIGUANG', sortOrder: 2 },
    { signname: '短信宝', smsVendor: 'DUANXINBAO', sortOrder: 3 },
    { signname: '网易盾', smsVendor: 'YIDUN', sortOrder: 4 },
    { signname: '闪速码', smsVendor: 'SHANSUMA', sortOrder: 5 },
    { signname: '腾讯云', smsVendor: 'TENCENT', sortOrder: 6 },
    { signname: '大狗', smsVendor: 'DAGOU', sortOrder: 7 }
  ],
  localesOptions: [
    { name: 'Zn', label: '中文' },
    { name: 'Vi', label: '越南' },
    { name: 'En', label: '英文' }
  ],
  displayTabs: [
    { label: '首页顶部导航', name: 'TopTab' },
    { label: '首页推荐', name: 'HomeTab' },
    { label: '娱乐热门', name: 'CasinoTab' },
    { label: '娱乐城', name: 'CityTab' },
    { label: '彩种图标配置', name: 'IconTab' },
    { label: '账变图标配置', name: 'IconAmountTab' },
    { label: '富文本配置', name: 'ContentTab' },
    { label: '更多按钮配置', name: 'FooterMenuTab' },
    { label: '首页金刚区(六合)', name: 'LiuheTab' },
    { label: '心水百宝箱', name: 'XinshuiTab' },
    { label: '新版首页设置', name: 'NewHomeTab' },
    { label: '新版滚球顶部导航设置', name: 'NewBallTopTab' },
    { label: '新版活动配置', name: 'NewActivityTab' }
  ],
  activityTabs: [
    { label: '普通活动', name: 'ConfigTab' },
    { label: '红包活动', name: 'RedTab' },
    { label: '活动分类', name: 'ClassificationTab' },
    { label: '新版活动', name: 'NewConfigTab' }
  ],
  taskTabs: [
    { label: '任务管理', name: 'ManageTab' },
    { label: '任务分类', name: 'ClassificationTab' },
    { label: '奖励记录', name: 'RewardTab' },
    { label: '签到配置', name: 'SigninConfigTab' },
    { label: '签到记录', name: 'SigninRecordTab' }
  ],
  agencyRebateDataTabs: [
    { label: '代理数据', name: 'IndexTab' },
    { label: '代理数据配置', name: 'ConfigTab' },
    { label: '代理用户', name: 'AgencyUserTab' },
    { label: '移动代理线记录', name: 'TransferTab' }
  ],
  agencyRebateConfigTabs: [
    { label: '代理返佣配置', name: 'IndexTab' },
    { label: '代理说明配置', name: 'RemarkTab' },
    { label: '代理推广', name: 'PromotionTab' }
  ],
  paymentTypes: [
    { value: 1, label: '人工' },
    { value: 2, label: '自动' }
  ],
  dateTypes: [
    { value: 1, label: '按天' },
    { value: 2, label: '本周' },
    { value: 3, label: '上周' },
    { value: 4, label: '本月' },
    { value: 5, label: '上月' },
    // { value: 6, label: '上上月' }
  ],
  timeDimension: [
    { value: 1, label: '按天' },
    { value: 2, label: '最近七日' },
    { value: 3, label: '上周' },
    { value: 4, label: '本月' },
    { value: 5, label: '上月' },
  ],
  dataGroups: [
    { value: 3, label: '直属+2层下级' },
    { value: 1, label: '直属下级' },
    { value: 2, label: '二级下级' }// carlos 说隐藏团队数据选项
    // { value: 0, label: '团队数据' }
  ],
  showGroups: [
    { value: 'isShowTeam', label: '团队数据' },
    { value: 'isShowOneLevel', label: '直属数据' },
    { value: 'isShowTwoLevel', label: '二层数据' }
  ],
  showDataTypes: [
    { value: 'isShowMember', label: '人数' },
    { value: 'isShowRegister', label: '注册' },
    { value: 'isShowFirstRecharge', label: '首充' },
    { value: 'isShowRecharge', label: '充值' },
    { value: 'isShowWithdraw', label: '提现' },
    { value: 'isShowProfit', label: '出入款盈亏' },
    { value: 'isShowLotteryMember', label: '彩票投注人数' },
    { value: 'isShowLotteryBet', label: '彩票有效投注' },
    { value: 'isShowLotteryProfit', label: '彩票盈亏' },
    { value: 'isShowSportMember', label: '竞彩体育投注人数' },
    { value: 'isShowSportBet', label: '竞彩体育有效投注' },
    { value: 'isShowSportProfit', label: '竞彩体育盈亏' },
    { value: 'isShowNativeSportMember', label: '原生体育投注人数' },
    { value: 'isShowNativeSportBet', label: '原生体育有效投注' },
    { value: 'isShowNativeSportProfit', label: '原生体育盈亏' },
    { value: 'isShowThirdMember', label: '第三方投注人数' },
    { value: 'isShowThirdBet', label: '第三方有效投注' },
    { value: 'isShowThirdProfit', label: '第三方盈亏' },
    { value: 'isShowActivity', label: '活动礼金' },
    { value: 'isShowCashback', label: '返水' },
    { value: 'isShowRebate', label: '返佣' },
    { value: 'isShowAchievements', label: '负盈利' }
  ],
  agencyMemberType: [
    { value: 0, label: '普通用户' },
    { value: 1, label: '代理用户' }
  ],
  detailTypes: [
    { value: 1, label: '注册' },
    { value: 2, label: '首充' },
    { value: 3, label: '充值' },
    { value: 4, label: '提现' },
    { value: 5, label: '手动加减款' },
    { value: 6, label: '活动礼金' },
    { value: 7, label: '返水' },
    { value: 8, label: '代理返佣' },
    { value: 9, label: '彩票' },
    { value: 10, label: '竞彩体育' },
    { value: 11, label: '原生体育' },
    { value: 12, label: '三方游戏' }
  ],
  displayTypeList: [
    { key: 1, lable: '娱乐热门' },
    { key: 2, lable: '棋牌' },
    { key: 3, lable: '老虎机' },
    { key: 4, lable: '真人' },
    { key: 5, lable: '捕鱼' },
    { key: 6, lable: '彩票' }
  ],
  keywordsMaps: [
    { key: 1, label: '会员昵称' },
    { key: 2, label: '会员姓名' },
    { key: 3, label: '手机号码' },
    { key: 4, label: '最后登录IP' },
    { key: 5, label: '注册IP' },
    { key: 6, label: '注册地' },
    { key: 7, label: '银行账号' }
  ],
  defaultGameType: {
    1: '彩票',
    2: '棋牌',
    3: '老虎机',
    4: '捕鱼',
    5: '真人',
    6: '赛马',
    7: '体育'
  },
  carshStatus: [
    { key: 1, label: '待扣款', type: 'primary' },
    { key: 2, label: '待开奖', type: 'primary' },
    { key: 3, label: '未中奖', type: 'info' },
    { key: 4, label: '已中奖', type: 'danger' }
  ],
  responseStatus: {
    请求错误: '请求错误',
    '未授权，请登录': '未授权，请登录',
    拒绝访问: '拒绝访问',
    请求地址出错: '请求地址出错',
    服务器内部错误: '服务器内部错误',
    请求超时: '请求超时',
    服务未实现: '服务未实现',
    网关错误: '网关错误',
    服务不可用: '服务不可用',
    网关超时: '网关超时',
    HTTP版本不受支持: 'HTTP版本不受支持',
    '暂停服务，请稍后再试！': '暂停服务，请稍后再试！',
    '该接口暂停服务，请稍后再试': '该接口暂停服务，请稍后再试'
  },
  Member_Expert: {
    专家列表: '专家列表',
    专家等级管理: '专家等级管理',
    ExpertTab: {
      专家: '专家',
      专家头像: '专家头像',
      专家账号: '专家账号',
      专家昵称: '专家昵称',
      擅长联赛: '擅长联赛',
      总胜率: '总胜率',
      总返奖率: '总返奖率',
      近10_近7_近3: '近10 近7 近3',
      最高连胜: '最高连胜',
      推荐次数: '推荐次数',
      编辑专家: '编辑专家',
      专家简介: '专家简介',
      专家等级: '专家等级',
      粉丝数: '粉丝数',
      历史推荐查询: '历史推荐查询',
      确认启用此专家: '确认启用此专家?',
      确认禁用此专家: '确认禁用此专家?',
      确定删除此方案: '确定删除此方案?'
    },
    LevelTab: {
      专家等级: '专家等级',
      别名: '别名',
      发布次数: '发布次数',
      胜率: '胜率',
      返还率: '返还率',
      抽佣: '抽佣',
      需要审核: '需要审核',
      无需审核: '无需审核',
      等级名称: '等级名称',
      等级色值: '等级色值',
      删除最大等级: '删除最大等级',
      删除等级后不可恢复: '删除等级后不可恢复',
      请输入1_100区间最多三位小数: '请输入1-100区间最多三位小数',
      格式不规范: '格式不规范'
    }
  },
  Expert_Plan: {
    原文标题: '原文标题',
    终端标题: '终端标题',
    标题: '标题',
    内容: '内容',
    原文内容: '原文内容',
    终端内容: '终端内容',
    方案状态: '方案状态',
    拾取状态: '拾取状态',
    译者名: '译者名',
    选项: '选项',
    确认拾取: '确认拾取',
    专家账号: '专家账号',
    专家昵称: '专家昵称',
    排序方式: '排序方式',
    比赛类型: '比赛类型',
    过关方式: '过关方式',
    是否收费: '是否收费',
    用户头像: '用户头像',
    免费: '免费',
    收费: '收费',
    不中退: '不中退',
    销量: '销量',
    阅读量: '阅读量',
    方案详情: '方案详情',
    方案单号: '方案单号',
    销售量: '销售量',
    赛事选择: '赛事选择',
    推荐理由: '推荐理由',
    返还率: '返还率',
    足球: '足球',
    篮球: '篮球',
    单场: '单场',
    串联: '串联',
    操作信息: '操作信息',
    确认通过此方案: '确认通过此方案?',
    确认禁止此方案: '确认禁止此方案?',
    未审: '未审',
    红: '红',
    黑: '黑',
    结果: '结果',
    方案类型: '方案类型'
  },
  Interactive_Match: {
    赛事列表: '赛事列表',
    联赛设置: '联赛设置',
    '体育-热门': '体育-热门',
    '体育-首页': '体育-首页',
    ForecastTab: {
      赛事名称: '赛事名称',
      球队名称: '球队名称',
      联赛: '联赛',
      简称: '简称',
      开赛时间: '开赛时间',
      热点排序: '热点排序',
      设置排序: '设置排序',
      主队: '主队',
      比分: '比分',
      客队: '客队',
      半场: '半场',
      热门位置: '热门位置',
      稳场位置: '稳场位置',
      无效: '无效',
      情报分析: '情报分析',
      预测编辑: '预测编辑',
      滚球编辑: '滚球编辑',
      直播链接: '直播链接',
      预测内容编辑: '预测内容编辑',
      重要提示: '重要提示',
      防冷: '防冷',
      主状态: '主状态',
      客状态: '客状态',
      攻防: '攻防',
      伤停: '伤停',
      规律: '规律',
      上半场: '上半场',
      亚盘规律: '亚盘规律',
      赛果规律: '赛果规律',
      盘口情况: '盘口情况',
      基本面: '基本面',
      分析场次: '分析场次',
      欧盘让球规律: '欧盘让球规律',
      本次基本面分析: '本次基本面分析',
      热门赛事位置: '热门赛事位置',
      热门直播位置: '热门直播位置',
      赛前: '赛前',
      赛中: '赛中',
      结束: '结束',
      确认取消此赛事: '确认取消此赛事?',
      直播链接配置: '直播链接配置',
      链接: '链接',
      请输入链接: '请输入链接'
    },
    LeagueTab: {
      是否推荐: '是否推荐',
      热门赛事: '热门赛事',
      展示赛事: '展示赛事',
      赛事名称: '赛事名称',
      联赛: '联赛',
      简称: '简称',
      支持推荐: '支持推荐',
      推荐玩法: '推荐玩法',
      赛事展示: '赛事展示',
      联赛等级: '联赛等级',
      编辑赛事: '编辑赛事',
      热门推荐: '热门推荐',
      联赛类型: '联赛类型'
    }
  },
  articleStatusList: [
    { value: 1, label: '未审', type: 'success' },
    { value: 2, label: '上架', type: 'info' },
    { value: 3, label: '下架', type: 'danger' },
    { value: 4, label: '取消', type: 'disable' }
  ],
  sportMatchStatus: {
    '0': { label: '异常', type: 'danger' },
    '1': { label: '未开赛', type: 'disable' },
    '2': { label: '上半场', type: 'info' },
    '3': { label: '中场', type: 'info' },
    '4': { label: '下半场', type: 'info' },
    '5': { label: '加时赛', type: 'info' },
    '7': { label: '点球决战', type: 'info' },
    '8': { label: '结束', type: 'success' },
    '9': { label: '推迟', type: 'danger' },
    '10': { label: '中断', type: 'danger' },
    '11': { label: '腰斩', type: 'danger' },
    '12': { label: '取消', type: 'danger' },
    '13': { label: '待定', type: 'danger' }
  },
  basketMatchStatus: {
    // 1-未开赛，2-第一节，3-第一节完，
    // 4-第二节，5-第二节完，6-第三节，
    // 7-第三节完，8-第四节，9-加时，10-完场，11-中断，
    // 12-取消，13-延期，14-腰斩，15-待定
    '0': { label: '异常', type: 'danger' },
    '1': { label: '未开赛', type: 'disable' },
    '2': { label: '第一节', type: 'info' },
    '3': { label: '第一节完', type: 'info' },
    '4': { label: '第二节', type: 'info' },
    '5': { label: '第三节', type: 'info' },
    '7': { label: '第三节完', type: 'info' },
    '8': { label: '第四节', type: 'info' },
    '9': { label: '加时', type: 'info' },
    '10': { label: '完场', type: 'success' },
    '11': { label: '中断', type: 'danger' },
    '12': { label: '取消', type: 'danger' },
    '13': { label: '延期', type: 'danger' },
    '14': { label: '腰斩', type: 'danger' },
    '15': { label: '待定', type: 'danger' }
  },
  positionList: [
    { label: '主队有利情报', value: 'homeFavorableInfo' },
    { label: '客队有利情报', value: 'awayFavorableInfo' },
    { label: '主队不利情报', value: 'homeAdverseInfo' },
    { label: '客队不利情报', value: 'awayAdverseInfo' },
    { label: '中立情报', value: 'neutralInfo' }
  ],
  recommendPlayMaps: {
    '1': '胜平负',
    '2': '让球胜平负',
    '3': '比分',
    '4': '总进球数',
    '5': '半全场'
  },
  footballRecommendPlayMaps: {
    '1': '胜平负',
    '2': '让球胜平负',
    '11': '独赢',
    '12': '让球',
    '14': '大小'
  },
  basketRecommendPlayMaps: {
    '6': '胜负',
    '7': '让分胜负',
    '8': '大小分',
    '11': '独赢',
    '13': '让分',
    '14': '大小'
  },
  sourceType: [
    { label: 'OB', value: 1 }
  ],
  roomTypes: [
    { label: '彩票', value: 1, type: 'lottery' },
    { label: '赛事', value: 2, type: 'sport' },
    // { label: '客服', value: 3, type: 'service' },
    { label: '直播', value: 4, type: 'live' }
    // { label: 'WOS', value: 5, type: 'service' }
  ],
  activityCategory: [
    {
      type: '0',
      title: '彩票'
    },
    {
      type: '1',
      title: '体育赛事'
    },
    {
      type: '2',
      title: '真人荷官'
    },
    {
      type: '3',
      title: '棋牌游戏'
    },
    {
      type: '4',
      title: '捕鱼游戏'
    },
    {
      type: '5',
      title: '竞技游戏'
    },
    {
      type: '6',
      title: '老虎机'
    },
    {
      type: '7',
      title: '区块链'
    }
  ],
  marketType: [
    { name: '欧洲盘', value: 'EU' },
    { name: '香港盘', value: 'HK' }
  ],
  taskStatus: [
    { key: 3, label: '待开始' },
    { key: 1, label: '进行中' },
    { key: 0, label: '已结束' }
  ],
  taskType: [
    { key: 1, label: '日任务' },
    { key: 0, label: '周任务' },
    { key: 2, label: '新手任务' },
    { key: 3, label: '长期任务' }
  ],
  taskMethod: [
    { key: 0, label: '充值任务' },
    { key: 1, label: '投注任务' },
    { key: 2, label: '邀请任务' },
    { key: 3, label: '直播任务' },
    { key: 4, label: '跟单任务' },
    { key: 5, label: '发帖任务' }
  ],
  rewardType: [
    { key: 0, label: '普通奖励' },
    { key: 1, label: '累计奖励' }
  ],
  betsType: [
    { key: 1, label: '彩票' },
    { key: 2, label: '竞彩足球' },
    // { key: 3, label: '原生体育' },
    { key: 4, label: '体育' },
    { key: 5, label: '真人' },
    { key: 10, label: '棋牌' },
    { key: 6, label: '捕鱼' },
    { key: 7, label: '老虎机' },
    { key: 8, label: '竞技' },
    { key: 9, label: '区块链' }
  ]
}
