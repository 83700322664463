<template>
  <el-dialog
    :title="ruleForm.transType === 5 ? $t('批量扣款') : $t('批量加款')"
    :visible.sync="visible"
    width="900px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-position="right" label-width="160px" label-suffix=":">
      <el-form-item :label="$t('会员账号')" prop="memberName">
        <el-input v-model="ruleForm.memberName" type="textarea" :rows="6" />
        <div style="line-height: 1.5">
          {{ $t('每行的数据格式：会员账号,金额 使用逗号或者空格隔开') }}
          <br>
          {{ $t('如') }}：aaaaaa 300
          <span style="font-size: 10px; color: red;">{{ $t('使用空格隔开') }}</span>
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bbbbbb,200
          <span style="font-size: 10px; color: red;">{{ $t('使用逗号隔开') }}</span>
        </div>
      </el-form-item>
      <template v-if="ruleForm.transType !== 5">
        <el-form-item :label="$t('打码量倍数')" prop="rollingRate">
          <el-input v-model="ruleForm.rollingRate" :placeholder="$t('请输入打码量倍数')" />
        </el-form-item>
        <el-form-item :label="$t('类型')" prop="transType">
          <el-select v-model="ruleForm.transType">
            <el-option :label="$t('礼金')" :value="174" />
            <el-option disabled :label="$t('现金(现金加款不支持批量操作)')" :value="4" />
          </el-select>
          <span style="margin-left:10px;font-size: 10px; color: red;">{{ $t('现金加款用于线下充值，只能单个操作') }}</span>
        </el-form-item>
      </template>
      <el-form-item :label="$t('操作原因')">
        <el-input v-model="ruleForm.operatorRemark" type="textarea" :rows="3" :placeholder="$t('请输入操作原因')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  memberName: '',
  operatorRemark: '',
  rollingRate: '1',
  effecRolling: 1,
  transType: null
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        memberName: [
          { required: true, message: this.$t('请输入批量处理信息'), trigger: 'blur' }
        ],
        effecRolling: [
          { required: true, message: this.$t('请选择计入打码量'), trigger: 'change' }
        ],
        rollingRate: [
          { required: true, message: this.$t('请输入打码量倍数'), trigger: 'blur' },
          { pattern: /^\d+(\.\d+)?$/, message: this.$t('请输入正确打码量倍数'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(transType) {
      this.ruleForm.transType = transType
      this.visible = true
    }
  }
}
</script>
