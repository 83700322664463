<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="730px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item :label="$t('支付名称')" prop="accountName">
        <el-input v-model="ruleForm.accountName" style="width: 240px" />
        <span class="validate-tip">{{
          $t("便于区分和记忆，用于前端配置收款账户使用")
        }}</span>
      </el-form-item>
      <el-form-item :label="$t('支付方式图标')" prop="paywayId">
        <el-select
          v-model="ruleForm.paywayId"
          :placeholder="$t('请选择收款方式')"
          style="width: 240px"
        >
          <el-option
            v-for="item in paymentWayConfList"
            :key="item.paywayId"
            :label="item.paywayName"
            :value="item.paywayId"
          />
        </el-select>
        <span class="validate-tip">{{ $t("前端展示图标") }}</span>
      </el-form-item>
      <el-form-item :label="$t('类型')" prop="receivingTypeId">
        <el-select
          v-model="ruleForm.receivingTypeId"
          :placeholder="$t('请选择类型')"
          style="width: 240px"
          @change="onChangeType"
        >
          <el-option
            v-for="(value, type) in receivingTypes"
            :key="type"
            :label="value"
            :value="type"
          />
        </el-select>
        <span class="validate-tip">{{ $t("数字货币钱包类型") }}</span>
      </el-form-item>
      <el-form-item :label="$t('地址')" prop="receivingCardNo">
        <el-input v-model="ruleForm.receivingCardNo" style="width: 240px" />
        <span class="validate-tip">{{ $t("数字货币钱包地址") }}</span>
      </el-form-item>
      <!-- <el-form-item :label="$t('汇率')" prop="exchangeRate">
        <el-input v-model="ruleForm.exchangeRate" style="width: 240px" />
        <span class="validate-tip">{{ $t('转换平台币的汇率；例如:6 充值50 获得300平台币') }}</span>
      </el-form-item> -->
      <el-form-item :label="$t('充值金额类型')" prop="isType">
        <el-select
          v-model="ruleForm.isType"
          :placeholder="$t('请选择充值金额类型')"
          style="width: 240px"
        >
          <el-option :label="$t('充值范围金额')" :value="0" />
          <el-option :label="$t('充值固定金额')" :value="1" />
        </el-select>
        <span class="validate-tip">{{ $t("收款账户分配到那种方式") }}</span>
      </el-form-item>
      <template v-if="ruleForm.isType == 0">
        <el-form-item :label="$t('最低额度')" prop="minAmount">
          <el-input
            v-model="ruleForm.minAmount"
            style="width: 240px"
            :placeholder="$t('默认100')"
          />
          <span class="validate-tip">{{
            $t("当前收款账户支持的最低充值金额，默认为100元")
          }}</span>
        </el-form-item>
        <el-form-item :label="$t('最高额度')" prop="maxAmount">
          <el-input v-model="ruleForm.maxAmount" style="width: 240px" />
          <span class="validate-tip">{{ $t("当前收款账户收款最高额度") }}</span>
        </el-form-item>
      </template>
      <template v-if="ruleForm.isType == 1">
        <el-form-item :label="$t('固定额度')" prop="periodAmount">
          <el-input v-model="ruleForm.periodAmount" style="width: 240px" />
          <span class="validate-tip">{{
            $t(`当前收款账户支持的固定金额，多个金额以","隔开`)
          }}</span>
        </el-form-item>
      </template>
      <!-- <el-form-item :label="$t('最低额度')" prop="minAmount">
        <el-input v-model="ruleForm.minAmount" style="width: 240px" :placeholder="$t('默认100')" />
        <span class="validate-tip">{{ $t('当前收款账户支持的最低充值金额，默认为100元') }}</span>
      </el-form-item>
      <el-form-item :label="$t('最高额度')" prop="maxAmount">
        <el-input v-model="ruleForm.maxAmount" style="width: 240px" />
        <span class="validate-tip">{{ $t('当前收款账户收款最高额度') }}</span>
      </el-form-item> -->
      <el-form-item :label="$t('上限额度')" prop="limitAccount">
        <el-input
          v-model="ruleForm.limitAccount"
          style="width: 240px"
          :placeholder="$t('设置0为不限制')"
        />
        <span class="validate-tip">{{ $t("当前收款账户收款上限额度") }}</span>
      </el-form-item>
      <el-form-item :label="$t('赠送比例')" prop="givePercent">
        <el-input
          v-model.number="ruleForm.givePercent"
          style="width: 240px"
          placeholder="0-100"
          ><template slot="suffix">%</template></el-input
        >
        <span class="validate-tip">{{
          $t("按照充值金额得比例额外赠送给用户的金额")
        }}</span>
      </el-form-item>
      <el-form-item :label="$t('序号')" prop="sort">
        <el-input v-model.number="ruleForm.sort" style="width: 240px" />
        <span class="validate-tip">{{ $t("数值越小，排序越靠前") }}</span>
      </el-form-item>
      <el-form-item :label="$t('VIP等级')" prop="vipLevel">
        <el-select
          v-model="ruleForm.vipLevel"
          multiple
          collapse-tags
          style="width: 240px"
        >
          <el-option
            v-for="item in vipLevels"
            :key="item.vipLevel"
            :label="`VIP${item.vipLevel}`"
            :value="item.vipLevel"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('货币种类')" prop="rateType">
        <el-select
          v-model="ruleForm.rateType"
          collapse-tags
          :placeholder="$t('请选择货币种类')"
          style="width: 240px"
        >
          <el-option
            v-for="item in rateTypes"
            :key="item.key"
            :label="item.label"
            :value="item.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('是否启用')" prop="status">
        <el-radio-group v-model="ruleForm.status">
          <el-radio :label="0">{{ $t("启用") }}</el-radio>
          <el-radio :label="1">{{ $t("不启用") }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t("关闭") }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t("保存") }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from "@/mixins/form-dialog";
import { rateTypes } from "@/libs/options";
const receivingTypes = {
  7: "TRC-20",
  8: "ERC-20",
};

const getDefaultForm = () => ({
  accountName: "",
  paywayId: "",
  receivingTypeId: "",
  receivingBank: "",
  receivingCardNo: "",
  // exchangeRate: '',
  minAmount: "",
  maxAmount: "",
  limitAccount: "",
  givePercent: 0,
  sort: null,
  vipLevel: [],
  status: 0,
  rateType: [],
});

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: "create",
      receivingTypes,
      rateTypes: rateTypes,
      rules: {
        accountName: [
          {
            required: true,
            message: this.$t("请输入支付名称"),
            trigger: "blur",
          },
        ],
        paywayId: [
          {
            required: true,
            message: this.$t("请选择支付方式图标"),
            trigger: "change",
          },
        ],
        receivingTypeId: [
          { required: true, message: this.$t("请选择类型"), trigger: "change" },
        ],
        receivingCardNo: [
          { required: true, message: this.$t("请输入地址"), trigger: "blur" },
        ],
        // exchangeRate: [
        //   { required: true, message: this.$t('请输入汇率'), trigger: 'blur' },
        //   { pattern: /^\d+(\.\d+)?$/, message: this.$t('请输入正确汇率'), trigger: 'blur' }
        // ],
        minAmount: [
          {
            pattern: /^\d+(\.\d+)?$/,
            message: this.$t("请输入正确额度"),
            trigger: "blur",
          },
        ],
        maxAmount: [
          {
            pattern: /^\d+(\.\d+)?$/,
            message: this.$t("请输入正确额度"),
            trigger: "blur",
          },
        ],
        limitAccount: [
          {
            required: true,
            message: this.$t("请输入上限额度"),
            trigger: "blur",
          },
          {
            pattern: /^(0|[1-9][0-9]*)$/,
            message: this.$t("请输入正确额度"),
            trigger: "blur",
          },
        ],
        givePercent: [
          {
            required: true,
            message: this.$t("请输入赠送比例"),
            trigger: "blur",
          },
          {
            pattern: /^(\d|[1-9][0-9]|100)$/,
            message: this.$t("请输入正确比例"),
            trigger: "blur",
          },
        ],
        vipLevel: [
          {
            required: true,
            type: "array",
            message: this.$t("请选择VIP等级"),
            trigger: "change",
          },
        ],
        sort: [
          { required: true, message: this.$t("请输入序号"), trigger: "blur" },
          {
            pattern: /^(0|[1-9][0-9]*)$/,
            message: this.$t("请输入正确序号"),
            trigger: "blur",
          },
        ],
        rateType: [
          {
            required: true,
            message: this.$t("请选择货币种类"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.emitName === "create"
        ? this.$t("新增支付账户")
        : this.$t("编辑支付账户");
    },
    vipLevels() {
      return this.$parent.vipLevels;
    },
    paymentWayConfList() {
      return this.$parent.paymentWayConfList;
    },
  },
  methods: {
    open(row) {
      this.emitName = "create";
      if (row) {
        this.ruleForm.id = row.id;
        this.ruleForm.accountName = row.accountName;
        this.ruleForm.receivingTypeId = row.receivingTypeId;
        this.ruleForm.paywayId = row.paywayId;
        this.ruleForm.receivingBank = row.receivingBank;
        this.ruleForm.receivingCardNo = row.receivingCardNo;
        // this.ruleForm.exchangeRate = row.exchangeRate
        this.ruleForm.maxAmount = Number(row.maxAmount);
        this.ruleForm.minAmount = Number(row.minAmount);
        this.ruleForm.limitAccount = Number(row.limitAccount);
        this.ruleForm.givePercent = parseFloat(row.givePercent);
        this.ruleForm.vipLevel = row.vipLevel
          ? row.vipLevel.split(",").map((o) => Number(o))
          : [];
        this.ruleForm.status = row.status;
        this.ruleForm.sort = row.sort;
        this.ruleForm.rateType = Number(row.rateType);
        this.emitName = "update";
      }
      this.visible = true;
    },

    onChangeType(type) {
      this.ruleForm.receivingBank = receivingTypes[type];
    },
  },
};
</script>

<style lang="scss" scoped>
.validate-tip {
  padding-left: 6px;
}
</style>
