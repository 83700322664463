import { numerical } from '@/utils/validation'

export const ladderOptions = ['one', 'two', 'three']

export const ladderConfig = [
  { label: '彩票', key: 'Bets' },
  { label: '礼物', key: 'Gift' },
  { label: '房费', key: 'Payroom' }
]

export const amountRules = [
  { required: true, message: '请输入', trigger: 'blur' },
  { pattern: numerical, message: '请输入正确金额', trigger: 'blur' }
]

export const percentRules = [
  { required: true, message: '请输入', trigger: 'blur' },
  { pattern: /^((\d|[1-9]\d)(\.\d{1})?|100(\.0)?)$/, message: '请输入正确比例', trigger: 'blur' }
]
