var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-tab", style: { width: _vm.width } },
    [
      _c("h3", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "elTable",
          attrs: {
            border: "",
            data: _vm.items,
            "row-key": "rowKey",
            "drag-row": true,
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { type: "index", align: "center", width: "60" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.$t("排序")))]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("svg-icon", {
                attrs: { "icon-class": "drag", "class-name": "drag-handler" },
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { label: _vm.menuName, align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { clearable: "" },
                        model: {
                          value: row.code,
                          callback: function ($$v) {
                            _vm.$set(row, "code", $$v)
                          },
                          expression: "row.code",
                        },
                      },
                      _vm._l(_vm.menuList, function (item) {
                        return _c("el-option", {
                          key: item.key,
                          attrs: {
                            value: item.key,
                            label: item.label,
                            disabled: _vm.items.some(function (e) {
                              return e.code === item.key && e.code !== row.code
                            }),
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.isName
            ? _c("el-table-column", {
                attrs: { label: "名称", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-input", {
                            model: {
                              value: row.name,
                              callback: function ($$v) {
                                _vm.$set(row, "name", $$v)
                              },
                              expression: "row.name",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3754565964
                ),
              })
            : _vm._e(),
          _vm.isIcon
            ? _c("el-table-column", {
                attrs: { label: _vm.$t("图标"), align: "center", width: "140" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("d-upload", {
                            attrs: {
                              folder: "icon",
                              "on-success": function () {
                                var args = [],
                                  len = arguments.length
                                while (len--) args[len] = arguments[len]

                                return _vm.handleUploadSuccess(args, row)
                              },
                              width: 86,
                              height: 86,
                            },
                            model: {
                              value: row.icon,
                              callback: function ($$v) {
                                _vm.$set(row, "icon", $$v)
                              },
                              expression: "row.icon",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3114851790
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: _vm.$t("操作"), align: "center", width: "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            row.code = null
                            row.name = null
                            row.icon = null
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { padding: "10px 0" }, attrs: { align: "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", loading: _vm.submitting },
              on: {
                click: function ($event) {
                  return _vm.handleSubmit()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("保 存")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }