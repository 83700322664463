var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "发布新版本",
        width: "700px",
        visible: _vm.visible,
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "130px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "发布平台", prop: "appType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.appType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "appType", $$v)
                    },
                    expression: "ruleForm.appType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("Android版"),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("IOS版")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "版本名称", prop: "versionName" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "10",
                  "show-word-limit": "",
                  placeholder: "请输入版本名称",
                },
                model: {
                  value: _vm.ruleForm.versionName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "versionName", $$v)
                  },
                  expression: "ruleForm.versionName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "版本号", prop: "version" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入版本号" },
                model: {
                  value: _vm.ruleForm.version,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "version", $$v)
                  },
                  expression: "ruleForm.version",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文件大小", prop: "appSize" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入文件大小" },
                  model: {
                    value: _vm.ruleForm.appSize,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "appSize", $$v)
                    },
                    expression: "ruleForm.appSize",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("MB")])],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "娱乐版下载地址", prop: "appDownurl" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入下载地址" },
                  model: {
                    value: _vm.ruleForm.appDownurl,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "appDownurl", $$v)
                    },
                    expression: "ruleForm.appDownurl",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "app-uploader",
                          attrs: {
                            action: _vm.action,
                            data: { type: "apk", folder: "apk" },
                            headers: _vm.$headers(),
                            "show-file-list": false,
                            "on-progress": _vm.onProgressUpload,
                            "on-success": _vm.onSuccessUpload,
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              icon: "el-icon-upload",
                              loading: _vm.uploading,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "体育版下载地址", prop: "sportsAppDownurl" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入下载地址" },
                  model: {
                    value: _vm.ruleForm.sportsAppDownurl,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "sportsAppDownurl", $$v)
                    },
                    expression: "ruleForm.sportsAppDownurl",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "app-uploader",
                          attrs: {
                            action: _vm.action,
                            data: { type: "apk", folder: "apk" },
                            headers: _vm.$headers(),
                            "show-file-list": false,
                            "on-progress": _vm.onProgressUpload,
                            "on-success": _vm.onSportsSuccessUpload,
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              icon: "el-icon-upload",
                              loading: _vm.uploading,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "曾道人版下载地址", prop: "zdrAppDownurl" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入下载地址" },
                  model: {
                    value: _vm.ruleForm.zdrAppDownurl,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "zdrAppDownurl", $$v)
                    },
                    expression: "ruleForm.zdrAppDownurl",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "app-uploader",
                          attrs: {
                            action: _vm.action,
                            data: { type: "apk", folder: "apk" },
                            headers: _vm.$headers(),
                            "show-file-list": false,
                            "on-progress": _vm.onProgressUpload,
                            "on-success": _vm.onZdrSuccessUpload,
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              icon: "el-icon-upload",
                              loading: _vm.uploading,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "强制更新", prop: "force" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.force,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "force", $$v)
                    },
                    expression: "ruleForm.force",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: 9 } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "发布时间" } }, [
            _c(
              "div",
              { attrs: { flex: "cross:center" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "publishStatus" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.publishStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "publishStatus", $$v)
                          },
                          expression: "ruleForm.publishStatus",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("立即发布"),
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("定时发布"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.ruleForm.publishStatus === 2
                  ? _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "20px" },
                        attrs: { prop: "publishTime" },
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            "value-format": "timestamp",
                            placeholder: "选择日期",
                            "picker-options": _vm.pickerOptions,
                          },
                          model: {
                            value: _vm.ruleForm.publishTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "publishTime", $$v)
                            },
                            expression: "ruleForm.publishTime",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "版本说明", prop: "versionInfo" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 5,
                  maxlength: "200",
                  "show-word-limit": "",
                  placeholder: "请输入版本说明，多条信息请使用#分割",
                },
                model: {
                  value: _vm.ruleForm.versionInfo,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "versionInfo", $$v)
                  },
                  expression: "ruleForm.versionInfo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }