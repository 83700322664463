var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.handleOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("会员账号"),
                prop: "memberList",
                rules: [
                  {
                    required: true,
                    message: _vm.$t("请输入会员账号组"),
                    trigger: "blur",
                  },
                  {
                    pattern: /^([a-zA-Z0-9]{4,20})+(,[a-zA-Z0-9]{4,20})*$/,
                    message: _vm.$t("请输入正确的数据"),
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: {
                  autosize: { minRows: 8, maxRows: 8 },
                  type: "textarea",
                  placeholder: _vm.$t("数据格式"),
                },
                model: {
                  value: _vm.ruleForm.memberList,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "memberList", $$v)
                  },
                  expression: "ruleForm.memberList",
                },
              }),
            ],
            1
          ),
          _vm.batchType === 0
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("会员等级"),
                      prop: "vipLevel",
                      rules: [
                        {
                          required: true,
                          message: _vm.$t("请选择VIP等级"),
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: _vm.$t("请选择会员等级") },
                        model: {
                          value: _vm.ruleForm.vipLevel,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "vipLevel", $$v)
                          },
                          expression: "ruleForm.vipLevel",
                        },
                      },
                      _vm._l(_vm.Levels, function (item) {
                        return _c("el-option", {
                          key: item.vipLevel,
                          attrs: {
                            label: "VIP" + item.vipLevel,
                            value: item.vipLevel,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("锁定等级"),
                      prop: "vipStatus",
                      rules: [
                        {
                          required: true,
                          message: _vm.$t("请选择是否锁定等级"),
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.vipStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "vipStatus", $$v)
                          },
                          expression: "ruleForm.vipStatus",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("锁定"))),
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v(_vm._s(_vm.$t("不锁定"))),
                        ]),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "danger-type" }, [
                      _vm._v(_vm._s(_vm.$t("锁定后"))),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm.batchType === 1
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("会员状态"),
                      prop: "freezeStatus",
                      rules: [
                        {
                          required: true,
                          message: _vm.$t("请选择会员状态"),
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.freezeStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "freezeStatus", $$v)
                          },
                          expression: "ruleForm.freezeStatus",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v(_vm._s(_vm.$t("启用"))),
                        ]),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("停用"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("新代理"),
                      prop: "agencyMemberName",
                      rules: [
                        {
                          required: true,
                          message: _vm.$t("请输入新代理"),
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.agencyMemberName,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "agencyMemberName", $$v)
                        },
                        expression: "ruleForm.agencyMemberName",
                      },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }