import Layout from '@/layout';

const manageRouter = {
  path: '/Cash',
  component: Layout,
  redirect: 'noRedirect',
  name: 'CashManage',
  alwaysShow: true,
  meta: { title: '现金管理', icon: 'cash' },
  children: [
    {
      path: '/Cash_Deposit',
      component: () => import('@/views/cash/deposit'),
      name: 'CashDeposit',
      meta: { title: '充值管理' }
    },
    {
      path: '/Cash_Withdrawal',
      component: () => import('@/views/cash/withdrawal'),
      name: 'CashWithdrawal',
      meta: { title: '提现管理' }
    },
    {
      path: '/Cash_Third_Party',
      component: () => import('@/views/cash/third-party'),
      name: 'CashThirdParty',
      meta: { title: '第三方充值管理' }
    },
    {
      path: '/Cash_Payment_Config',
      component: () => import('@/views/cash/payment-config'),
      name: 'CashPaymentConfig',
      meta: { title: '支付配置' }
    },
    {
      path: '/Cash_Member_Change',
      component: () => import('@/views/cash/member-change'),
      name: 'CashMemberChange',
      meta: { title: '会员账变记录' }
    },
    {
      path: '/Cash_Points_Change',
      component: () => import('@/views/cash/points-change'),
      name: 'CashPointsChange',
      meta: { title: '积分账变记录' }
    },
    {
      path: '/Cash_Chip_Records',
      component: () => import('@/views/cash/chip-records'),
      name: 'CashChipRecords',
      meta: { title: '打码量记录' }
    },
    {
      path: '/Cash_Member_Extra',
      component: () => import('@/views/cash/member-extra'),
      name: 'CashMemberExtra',
      meta: { title: '会员加款扣款' }
    },
    {
      path: '/Cash_Member_Subtract',
      component: () => import('@/views/cash/member-subtract'),
      name: 'CashMemberSubtract',
      meta: { title: '会员加减可提' }
    },
    {
      path: '/Cash_Member_Compensate',
      component: () => import('@/views/cash/member-compensate'),
      name: 'CashMemberCompensate',
      meta: { title: '三方异常记录' }
    }
  ]
};

export default manageRouter;
