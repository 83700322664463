var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "第三方加减款",
        visible: _vm.visible,
        width: "500px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "租户" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.ruleForm.tenantCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "tenantCode", $$v)
                    },
                    expression: "ruleForm.tenantCode",
                  },
                },
                _vm._l(_vm.tenants, function (item) {
                  return _c("el-option", {
                    key: item.tenantCode,
                    attrs: { value: item.tenantCode, label: item.tenantName },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "第三方游戏", prop: "thirdGameTag" } },
            [
              _c(
                "el-select",
                {
                  on: { change: _vm._getAmount },
                  model: {
                    value: _vm.ruleForm.thirdGameTag,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "thirdGameTag", $$v)
                    },
                    expression: "ruleForm.thirdGameTag",
                  },
                },
                _vm._l(_vm.$parent.customizeColumus, function (value, tag) {
                  return _c("el-option", {
                    key: tag,
                    attrs: { value: tag, label: value.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.tenantCode && _vm.ruleForm.thirdGameTag
            ? _c("el-form-item", { attrs: { label: "余额" } }, [
                _vm._v(_vm._s(_vm.amount)),
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "操作类型", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "type", $$v)
                    },
                    expression: "ruleForm.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "0" } }, [_vm._v("加款")]),
                  _c("el-radio", { attrs: { label: "1" } }, [_vm._v("扣款")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "操作金额", prop: "amount" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "amount", $$v)
                  },
                  expression: "ruleForm.amount",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }