<template>
  <div class="app-container">
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
    >
      <template #left>
        <div class="filter-container">
          <el-input v-model="search.title" clearable placeholder="公告标题" class="filter-item" style="width: 200px" />
          <el-button icon="el-icon-search" type="primary" class="filter-item" :loading="loading" @click="handleSearch">{{ $t('查询') }}</el-button>
          <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
        </div>
      </template>
      <template #right>
        <el-button type="primary" class="filter-item" @click="_handleOpen()">{{ $t('新建群公告+') }}</el-button>
      </template>
    </d-table>
    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" @update="_handleUpdate" />
  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import ActionDialog from './ActionDialog'
import GetColumns from './columns'
import { roomAnnouncementQueryList, liveLiveAnnouncementAdd, liveLiveAnnouncementUpdate, liveliveAnnouncementRoomStatus, liveLiveAnnouncementRoomDelete } from '@/api/chat'
import { roomTypes } from '@/libs/options'

export default {
  name: 'InteractiveNotice',
  components: { ActionDialog },
  mixins: [TablePageMixin(roomAnnouncementQueryList)],
  data() {
    return {
      defaultColumns: GetColumns.call(this),
      columns: [],
      roomTypes
    }
  },
  methods: {
    _handleOpen(row) {
      this.$refs.ActionDialogControl.open(row)
    },
    _handleDelete({ id }) {
      this.$confirm('确认删除当前群公告?', { type: 'warning' }).then(() => {
        liveLiveAnnouncementRoomDelete({ id }).then(([_, err]) => {
          if (!err) {
            this.$message.success('删除成功')
            this.handleRefresh()
          }
        })
      })
    },
    _handleCreate({ field, cancel, close }) {
      field.roomList = field.roomList.toString()
      if (field.roomType === 0) {
        field.isRoom = 0
      }
      delete field.roomType
      liveLiveAnnouncementAdd(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('添加成功')
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    _handleUpdate({ field, cancel, close }) {
      field.roomList = field.roomList.toString()
      if (field.roomType === 0) {
        field.isRoom = 0
      }
      delete field.roomType
      liveLiveAnnouncementUpdate(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('编辑成功')
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    handleUpdateEnable({ id, enable }) {
      return new Promise((resolve) => {
        this.$confirm(`确认${enable === 0 ? '启用' : '停用'}群公告?`, { type: 'warning' }).then(() => {
          enable = enable === 0 ? 1 : 0
          liveliveAnnouncementRoomStatus({ id, enable }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
            }
          })
        })
      })
    },
    implementationGetParams() {
      return { ...this.search }
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
