<template>
  <!-- 自己 msg-item-r居右 -->
  <div class="msg-item" :class="me && item.msgType !== 105 && 'msg-item-r'">
    <!-- 非删除消息 -->
    <template
      v-if="item.msgType !== 110 && item.msgType !== 204 && item.msgType !== 10"
    >
      <div class="msg-avatar" @click="_doAction('reply')">
        <el-avatar size="medium" :src="item.avatar" />
      </div>
      <div class="msg-wrapper">
        <!-- vip等级 -->
        <div v-if="!me" class="msg-user">
          <div
            class="msg-vip"
            :class="'vip-' + (item.nickname == '管理员' ? 60 : item.vipLevel)"
          />
          <div class="user-name" @click="_doAction('reply')">
            {{ item.nickname }}：
          </div>
        </div>

        <div class="msg-body" :class="me && 'my-body'">
          <!-- 图片消息 -->
          <template v-if="item.msgType === 1">
            <div>
              <div>
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item.content"
                  :previewSrcList="[item.content]"
                  fit="cover"
                />
              </div>
              <div class="setfont" style="margin-top: 5px">
                {{ parseTime(item.timestamp, "{y}-{m}-{d} {h}:{i}") }}
                <el-image
                  v-if="me"
                  :src="
                    item.isRead
                      ? require('@/assets/public_images/hasRead.png')
                      : require('@/assets/public_images/unRead.png')
                  "
                ></el-image>
              </div>
            </div>
          </template>

          <template v-else-if="msgTypeList.includes(item.msgType)">
            <div>
              <div>
                <cardItem
                  :permission="permission"
                  :item="item"
                  :me="me"
                  @_doAction="_doAction"
                ></cardItem>
                <div class="setfont" style="margin-top: 5px">
                  {{ parseTime(item.timestamp, "{y}-{m}-{d} {h}:{i}") }}
                  <el-image
                    v-if="me"
                    :src="
                      item.isRead
                        ? require('@/assets/public_images/hasRead.png')
                        : require('@/assets/public_images/unRead.png')
                    "
                  ></el-image>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <template v-if="me">
              <div class="msg-content setdis">
                <div class="setfont">{{ item.nickname || "在线客服" }}</div>
                <div style="padding: 7px 0">
                  {{ showMessage }}
                </div>
                <div class="setfont">
                  {{ parseTime(item.timestamp, "{y}-{m}-{d} {h}:{i}") }}
                  <el-image
                    v-if="me"
                    :src="
                      item.isRead
                        ? require('@/assets/public_images/hasRead.png')
                        : require('@/assets/public_images/unRead.png')
                    "
                  ></el-image>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="msg-content setdis">
                <div>
                  {{ showMessage }}
                </div>
                <div class="setfont">
                  {{ parseTime(item.timestamp, "{y}-{m}-{d} {h}:{i}") }}
                  <el-image
                    v-if="me"
                    :src="
                      item.isRead
                        ? require('@/assets/public_images/hasRead.png')
                        : require('@/assets/public_images/unRead.png')
                    "
                  ></el-image>
                </div>
              </div>
            </template>
          </template>

          <i
            class="el-icon-circle-close"
            :class="me && 'my-close'"
            @click="_doAction('del')"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
// import ChatShare from './chat-share'
import avatar01 from "@/assets/avatar_images/avatar.webp";
import DCountDown from "@/components/DCountDown";
import WinIcon from "@/assets/public_images/win.png";
import LossIcon from "@/assets/public_images/loss.png";
import { parseTime } from "@/utils";
import clip from "@/utils/clipboard";
import cardItem from "./card-item.vue";
// 彩票注单 207
// 足彩注单 208
// 第三方注单 209
// 充值订单 210
// 提现订单 211
// 任务分享 212
// 富文本-定时消息 213
// 任务分享-1v1客服弹框任务分享 214
// 在线客服快速问列表 215(1v1客服,客服举例列表)
// 在线客服快速问 216(1v1客服,用户问)
// 在线客服快速回答 217(1v1客服,客服回答)
// 在线客服公告 218（1v1客服，公告）
// 在线客服公告 219（1v1客服，欢迎）
export default {
  components: { DCountDown, cardItem },
  props: {
    permission: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    operatorInfo: {
      type: Object,
      default: () => ({}),
    },
    contentType: {
      type: String,
      default: "text",
    },
    me: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      msgTypeList: [207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217],
      parseTime,
      avatarImage: avatar01,
      marketType: this.$t("marketType"),
      betEnd: false,
    };
  },
  computed: {
    showMessage() {
      if (this.item.msgType === 0 && this.item.replayInfo && !this.me) {
        return this.item.content.replace(
          `@${this.item.replayInfo.nickname}`,
          ""
        );
      }
      return this.item.content;
    },
    shareOrderName() {
      if (this.item.lotteryOrderBean) {
        return this.item.lotteryOrderBean.lotteryName;
      } else if (this.item.orderBean) {
        if (this.item.orderBean.lotteryName) {
          return this.item.orderBean.lotteryName;
        }
        const market = this.marketType.find(
          (p) => p.value === this.item.orderBean.marketType
        );
        if (market) {
          return market.name;
        }
      }
      return this.item.lotteryName;
    },
    shareCurrencyIcon() {
      if (
        this.item.lotteryOrderBean &&
        this.item.lotteryOrderBean.currencyIcon
      ) {
        return this.item.lotteryOrderBean.currencyIcon;
      } else if (
        this.item.orderBean &&
        [103, 104].includes(this.item.msgType)
      ) {
        return this.item.orderBean.lotteryIcon;
      } else if (this.item.orderBean && this.item.orderBean.currencyIcon) {
        return this.item.orderBean.currencyIcon;
      }
      return "https://liveossuat.ltlive.net/image/ad-photo/2022-08-09/89705f3a-091d-4c55-b287-e63448522877.png";
    },
    shareResult() {
      if (this.item.lotteryOrderBean) {
        if (this.item.lotteryOrderBean.status === 6) {
          return "win";
        } else if (this.item.lotteryOrderBean.status === 5) {
          return "loss";
        } else if (this.item.lotteryOrderBean.status === 7) {
          return "draw";
        }
      } else if (this.item.orderBean) {
        const detailList = this.item.orderBean.detailList;
        if (detailList && detailList.length > 0) {
          const status = detailList[0].betResult;
          if (status === "2") {
            return "draw";
          } else if (status === "3") {
            return "loss";
          } else if (status === "4") {
            return "win";
          } else if (status === "5") {
            return "winhalf";
          } else if (status === "6") {
            return "losshalf";
          }
        }
      }
      return "noResult";
    },
    shareResultIcon() {
      if (this.shareResult === "win" || this.shareResult === "winhalf") {
        return WinIcon;
      } else if (
        this.shareResult === "loss" ||
        this.shareResult === "losshalf"
      ) {
        return LossIcon;
      }
      return undefined;
    },
    shareContent() {
      if (this.item.lotteryOrderBean) {
        return this.item.lotteryOrderBean.betsContent;
      } else if (
        this.item.orderBean &&
        [103, 104].includes(this.item.msgType)
      ) {
        return this.item.orderBean.betsContent;
      } else if (
        this.item.orderBean &&
        this.item.orderBean.detailList &&
        this.item.orderBean.detailList.length > 0
      ) {
        const detail = this.item.orderBean.detailList[0];
        return `${detail.marketValue} @${detail.oddFinally}`;
      }
      return "";
    },
    shareTitle() {
      if (this.item.lotteryOrderBean) {
        return this.item.lotteryOrderBean.issue;
      } else if (
        this.item.orderBean &&
        [103, 104].includes(this.item.msgType)
      ) {
        return this.item.orderBean.issue;
      } else if (
        this.item.orderBean &&
        this.item.orderBean.detailList &&
        this.item.orderBean.detailList.length > 0
      ) {
        const detail = this.item.orderBean.detailList[0];
        return detail.playName;
      }
      return "";
    },
    shareScore() {
      if (
        this.item.orderBean &&
        this.item.orderBean.detailList &&
        this.item.orderBean.detailList.length > 0
      ) {
        const detail = this.item.orderBean.detailList[0];
        return `(${detail.scoreBenchmark.replace(":", "-")})`;
      }
      return "";
    },
    shareBetAmount() {
      if (this.item.lotteryOrderBean) {
        return this.item.lotteryOrderBean.amount;
      } else if (
        this.item.orderBean &&
        [103, 104].includes(this.item.msgType)
      ) {
        return this.item.orderBean.amount;
      } else if (this.item.orderBean) {
        const amount = this.item.orderBean.orderAmountTotal;
        return amount;
      }
      return "0";
    },
    shareShowWinLossAmount() {
      if (this.item.lotteryOrderBean) {
        const bean = this.item.lotteryOrderBean;
        if (this.shareResult === "win" || this.shareResult === "noResult") {
          return bean.amount * bean.odds;
        } else if (this.shareResult === "loss") {
          return -bean.amount;
        } else if (this.shareResult === "draw") {
          return this.amount;
        }
      } else if (
        this.item.orderBean &&
        [103, 104].includes(this.item.msgType)
      ) {
        return this.item.orderBean.expectWinAmount;
      } else if (this.item.orderBean) {
        const bean = this.item.orderBean;
        if (this.shareResult === "win" || this.shareResult === "noResult") {
          return bean.maxWinAmount;
        } else if (this.shareResult === "loss") {
          return -bean.orderAmountTotal;
        } else if (this.shareResult === "winhalf") {
          return bean.maxWinAmount / 2 - bean.amount;
        } else if (this.shareResult === "losshalf") {
          return -bean.orderAmountTotal / 2;
        }
      }
      return "";
    },
  },
  methods: {
    _doAction(actionType) {
      if (
        this.$props.me &&
        this.$props.item.msgType !== 105 &&
        actionType != "del"
      ) {
        actionType = "myactive";
      }
      this.$emit("on-action", this.item, actionType);
    },

    finish() {
      this.betEnd = true;
    },
    handleCopy(text, event) {
      clip(text, event);
    },
    receiveStatusMsg(item) {
      const msg = {
        1: "领取红包",
        2: "已被领完",
        3: "已领取",
        4: "已过期",
      };
      return msg[item.receiveStatus] || "红包出错辣！";
    },
  },
};
</script>

<style lang="scss">
.box-card {
  width: 300px;
}

.msg-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 20px;

  .msg-avatar {
    width: 30px;
    height: 30px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .msg-wrapper {
    width: 400px;

    .msg-user {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      cursor: pointer;

      .msg-vip {
        width: 32px;
        height: 14px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @for $i from 0 through 60 {
          &.vip-#{$i} {
            background-image: url("~@/assets/vip/icon-vip#{$i}.png");
          }
        }

        // &.vip-0 {
        //     background-image: url('~@/assets/vip/icon-vip0.png');
        // }
        // &.vip-1 {
        //     background-image: url(~@/assets/vip/icon-vip1.png);
        // }
        // &.vip-2 {
        //     background-image: url(~@/assets/vip/icon-vip2.png);
        // }
        // &.vip-3 {
        //     background-image: url(~@/assets/vip/icon-vip3.png);
        // }
        // &.vip-4 {
        //     background-image: url(~@/assets/vip/icon-vip4.png);
        // }
        // &.vip-5 {
        //     background-image: url(~@/assets/vip/icon-vip5.png);
        // }
        // &.vip-6 {
        //     background-image: url(~@/assets/vip/icon-vip6.png);
        // }
        // &.vip-7 {
        //     background-image: url(~@/assets/vip/icon-vip7.png);
        // }
        // &.vip-8 {
        //     background-image: url(~@/assets/vip/icon-vip8.png);
        // }
        // &.vip-9 {
        //     background-image: url(~@/assets/vip/icon-vip9.png);
        // }
      }

      .user-name {
        margin-left: 6px;
        color: #c62430;
      }
    }

    .msg-content {
      max-width: 254px;
      min-width: 150px;
      padding: 6px 8px;
      background-color: #f7f7f7;
      border-radius: 10px;
      font-size: 14px;
      color: #333;
      word-break: break-all;
      word-wrap: break-word;
    }

    // .msg-content:after{
    //     /* (B1-1) ATTACH TRANSPARENT BORDERS */
    //     content: "";
    //     border: 20px solid transparent;

    //     /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
    //     position: absolute;
    //     box-sizing: border-box;
    //     position: relative;
    //     right: -10px;
    //     top: 9px;
    // }

    // .msg-content::after {
    //   /* (C2-1) LEFT TRIANGLE */
    //   border-right-color: #f7f7f7;
    //   border-left: 0;
    //   /* (C2-2) POSITION AT LEFT */
    //   left: -20px; top: 50%;
    //   margin-top: -20px;
    // }

    .msg-body {
      display: flex;
      // flex-wrap: wrap;
      i {
        font-size: 24px;
        padding-left: 10px;
        cursor: pointer;
      }
    }

    .atName {
      color: #c62430;
    }

    .order-wrap {
      position: relative;
      width: 268px;
      height: 144px;
      padding: 20px 20px 0;
      background: url(~@/assets/public_images/chat-order-bg.png) no-repeat
        center;
      background-size: cover;
      border-radius: 6px;
      text-align: center;

      .order-state {
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 900;
        font-size: 14px;
        line-height: 18px;
        color: #ddd;
      }

      .order-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 18px;
        font-size: 12px;
        color: #999;
      }

      .order-predict {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        margin-top: 6px;
        height: 22px;

        img {
          width: 15px;
          height: 15px;
        }

        span {
          font-family: "Impact";
          color: #c62430;
          font-size: 18px;
        }
      }

      .order-type {
        margin-top: 6px;
        font-size: 12px;
        color: #333;
      }

      .order-detail {
        margin-top: 8px;
        font-size: 12px;
        color: #333;
        font-weight: 600;

        span {
          color: #c62430;
        }
      }

      .order-bet {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        margin-top: 6px;
        color: #666;

        img {
          width: 12px;
          height: 12px;
        }
      }

      .order-result {
        display: flex;
        position: relative;
        left: 171px;
        top: -86px;
      }

      .loss {
        color: #7fb000 !important;
      }
    }
  }

  // 自己发消息
  &.msg-item-r {
    flex-direction: row-reverse;

    .msg-wrapper {
      .msg-user {
        display: none;
      }

      .icon-replay {
        display: inline-block;
        margin-right: 12px;
        transform: rotate(90deg);

        &.replay-loading {
          animation: rotate 1s ease infinite;
        }

        @keyframes rotate {
          0% {
            transform: rotate(90deg);
          }

          100% {
            transform: rotate(450deg);
          }
        }
      }

      .msg-content {
        display: inline-block;
        background-color: #c62430;
        color: #fff;
      }

      .atName {
        color: #fff;
      }

      // .msg-content::after {
      //   /* (B1-1) ATTACH TRANSPARENT BORDERS */
      //   content: "";
      //   border: 20px solid transparent;

      //   /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
      //   position: absolute;
      //   /* (C3-1) RIGHT TRIANGLE */
      //   border-left-color: #c62430;
      //   border-right: 0;
      //   /* (C3-2) POSITION AT RIGHT */
      //   right: -20px; top: 50%;
      //   margin-top: -20px;
      // }
    }
  }

  .red-packet {
    border-radius: 4px;
    background: #fa9d3b;
    color: #fff;
    width: 234px;

    .packet-content {
      display: flex;
      padding: 11px 0;
      margin: 0 15px;
      gap: 12px;
      border-bottom: 0.2px solid #ffffff3b;

      .packet-img {
        width: 32px;
      }

      .packet-title {
        font-size: 14px;
        font-weight: 600;
        max-width: 170px;
      }

      .tip {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .type {
      padding: 2px 15px;
      font-size: 10px;
      font-weight: 300;
    }
  }

  .empty-packet {
    background: #fde1c3;
  }

  .receive-red {
    text-align: center;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    span {
      max-width: 100px;
    }

    .receive-img {
      height: 12px;
      margin-right: 4px;
    }

    .text-red {
      color: #c62430;
    }

    .van-ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.msg-none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .msg-none-icon {
    height: 100px;
  }

  .msg-none-txt {
    font-weight: 500;
    font-size: 18px;
    color: #ddd;
  }
}

.muteMsg {
  display: flex;
  margin: auto;
  color: #c62430;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: 0.233333px;
}

.my-body {
  flex-direction: row-reverse;

  .my-close {
    margin-right: 10px;
  }
}

.box-div {
  min-width: 340px;
  min-height: 180px;
  border-radius: 10px;
  border: 0.5px solid var(--white, #fff);
  background: linear-gradient(179deg, #fff1d4 0.83%, #fff 70.28%);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}
.box-left {
  background-color: #c62430;
  padding: 0 10px;
  min-width: 50px;
  max-width: 90px;
  height: 20px;
  border-radius: 10px 0 10px 0;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.el-divider--horizontal {
  margin: 10px 0 !important;
}
.setfont {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .el-image {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }
}
</style>
