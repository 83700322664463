<template>
  <el-dialog :title="$t('编辑')" :visible.sync="visible" width="400px" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('用户账号')">
        <span>{{ ruleForm.username }}</span>
      </el-form-item>
      <el-form-item :label="$t('真实姓名')" prop="realName">
        <el-input v-model="ruleForm.realName" placeholder="请输入真实姓名" />
      </el-form-item>
      <el-form-item :label="$t('银行账户')" prop="cardNumber">
        <el-input v-model="ruleForm.cardNumber" placeholder="请输入银行账户" />
      </el-form-item>
      <el-form-item :label="$t('银行名称')" prop="bank">
        <!-- <el-input v-model="ruleForm.bank" placeholder="请输入银行名称" /> -->
        <el-select v-model="ruleForm.bank" filterable placeholder="请选择银行卡" style="width: 100%;">
          <el-option :label="item.name" :value="item.name" v-for="item in bankList" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('开户地址')" prop="bankAddress">
        <el-input v-model="ruleForm.bankAddress" placeholder="请输入开户地址" />
      </el-form-item>
      <el-form-item :label="$t('绑定时间')">
        <span>{{ ruleForm.bindTime | parseTime }}</span>
      </el-form-item>
      <el-form-item :label="$t('上次修改时间')">
        <span>{{ ruleForm.updateTime | parseTime }}</span>
      </el-form-item>
      <el-form-item :label="$t('状态')" prop="status">
        <el-radio-group v-model="ruleForm.status">
          <el-radio :label="0">{{ $t('正常') }}</el-radio>
          <el-radio :label="1">{{ $t('黑名单') }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getSupportBankList } from '@/api/finance'

const getDefaultForm = () => ({
  realName: '',
  cardNumber: '',
  bank: '',
  status: null,
  bankAddress: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  
  data() {
    return {
      bankList:[],
      emitName: 'update',
      rules: {
        realName: [{ required: true, message: this.$t('请输入真实姓名'), trigger: 'blur' }],
        cardNumber: [{ required: true, message: this.$t('请输入银行账户'), trigger: 'blur' }],
        bank: [{ required: true, message: this.$t('请输入银行名称'), trigger: 'blur' }],
        status: [{ required: true, message: this.$t('请选择状态'), trigger: 'change' }]
      }
    }
  },
  methods: {
    getBankList(rateType) {
      getSupportBankList({ rateType }).then(([data, err]) => {
        if (!err) {
          this.bankList = data
        }
      })
    },
    open({ id, username, realName, bank, cardNumber, status, bindTime, updateTime, memberId, rateType, bankAddress }) {
      this.ruleForm = { id, username, realName, bank, cardNumber, status, bindTime, updateTime, memberId, bankAddress }
      this.getBankList(rateType)
      this.visible = true
    }
  }
}
</script>
