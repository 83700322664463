import { filterColumns } from '@/utils'

const CanChooseColumns = function() {
  return [
    // {
    //   title: this.$t('租户'),
    //   key: 'tenantCode',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (<span>{ row.level + this.$t('级代理') }</span>)
    //     }
    //   }
    // },
    {
      title: '国际化key',
      key: 'i18nKey',
      align: 'center'
    },
    {
      title: '国际化value',
      key: 'i18nValue',
      align: 'center'
    },
    {
      title: '国际化对应语言',
      key: 'lang',
      align: 'center'
    },

    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          // const { Update, Delete } = this.permission
          return (
            <span>
              {/* {<el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={ () => this.onCreate(row) }>{this.$t('编辑')}</el-button> } */}
              {<el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={() => this.onCreate(row)}>{this.$t('编辑')}</el-button>}
              {<el-button type='danger' size='mini' icon='el-icon-delete' onClick={this.handleDelete.bind(this, row)}>{this.$t('删除')}</el-button>}
              {/* { Update && <el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={ () => this.onCreate(row) }>{this.$t('编辑')}</el-button> }
              { Delete && <el-button type='danger' size='mini' icon='el-icon-delete' onClick={ this.handleDelete.bind(this, row.id) }>{this.$t('删除')}</el-button> } */}
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
