<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="search.vipLevel" :placeholder="$t('VIP等级')" clearable class="filter-item" style="width: 120px">
        <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
      </el-select>
      <el-select v-model="search.receivingTypeId" :placeholder="$t('收款方式')" clearable class="filter-item" style="width: 120px">
        <el-option :label="$t('银行卡转银行卡')" value="1" />
        <el-option :label="$t('支付宝转银行卡')" value="3" />
      </el-select>
      <el-input v-model="search.receivingBank" clearable :placeholder="$t('收款银行')" class="filter-item" style="width: 120px" />
      <el-select v-model="search.status" clearable :placeholder="$t('状态')" class="filter-item" style="width: 120px">
        <el-option :label="$t('启用')" :value="0" />
        <el-option :label="$t('备用')" :value="1" />
      </el-select>
      <el-input v-model="search.reveivingName" clearable :placeholder="$t('收款姓名')" class="filter-item" style="width: 120px" />
      <el-input v-model="search.receivingCardNo" clearable :placeholder="$t('银行卡号')" class="filter-item" style="width: 150px" />
      <el-input v-model="search.operatorName" clearable :placeholder="$t('操作员')" class="filter-item" style="width: 120px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">{{ $t('添加银行账户') }}</el-button>
      <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreateDigital">{{ $t('添加USDT地址') }}</el-button>
      <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreateProxy">{{ $t('添加代客充值') }}</el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <edit-dialog ref="EditDialogControl" @create="handleCreate" @update="handleUpdate" />
    <ActionDigitalDialog ref="ActionDigitalDialogControl" @create="handleCreate" @update="handleUpdate" />
    <ProxyDialog ref="ProxyDialogControl" @create="handleCreate" @update="handleUpdate" />
  </div>
</template>
<script>
import EditDialog from './EditDialog'
import ActionDigitalDialog from './ActionDigitalDialog'
import ProxyDialog from './ProxyDialog'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { paymentTypes, payTypes } from '@/libs/options'
import { getAllVipLevels } from '@/api/app'
import {
  getPayReceivingAccountList,
  addPayReceivingAccount,
  updatePayReceivingAccount,
  deletePayReceivingAccount,
  updatePayMaxAmountVal,
  getPaymentWayConfList
} from '@/api/cash'

export default {
  name: 'Company',
  componentName: 'Company',
  components: { EditDialog, ActionDigitalDialog, ProxyDialog },
  mixins: [TablePageMixin(getPayReceivingAccountList)],
  data() {
    return {
      search: {},
      paymentTypes,
      payTypes,
      columns: [],
      defaultColumns: GetColumns.call(this),
      vipLevels: [],
      paymentWayConfList: []
    }
  },
  created() {
    this.implementationTenantChanged()
  },
  methods: {
    onCreate() {
      this.$refs.EditDialogControl.open()
    },

    onCreateDigital() {
      this.$refs.ActionDigitalDialogControl.open()
    },

    onCreateProxy() {
      this.$refs.ProxyDialogControl.open()
    },

    handleCreate({ field, cancel, close }) {
      let rateType = 3
      if (field.rateType) {
        rateType = field.rateType.toString()
      }
      addPayReceivingAccount({ ...field, minAmount: field.minAmount || 100, vipLevel: field.vipLevel.join(','), rateType }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('创建成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    handleUpdate({ field, cancel, close }) {
      let rateType = 3
      if (field.rateType) {
        rateType = field.rateType.toString()
      }
      updatePayReceivingAccount({ ...field, minAmount: field.minAmount || 100, vipLevel: field.vipLevel.toString(), rateType }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleDelete({ id, rateType }) {
      this.$confirm(this.$t('确认删除此账户'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        deletePayReceivingAccount({ id, rateType }).then(([_, err]) => {
          if (!err) {
            this.handleFetch()
            this.$message.success(this.$t('删除成功'))
          }
        })
      }).catch(() => {})
    },

    handleChangeStatus({ id, status, rateType }) {
      return new Promise((resolve) => {
        this.$confirm(status === 1 ? this.$t('确认启用此用户') : this.$t('确认停用此用户'), this.$t('系统提示'), { type: 'warning' }).then(() => {
          const params = { id, status: ~status + 2, rateType }
          updatePayReceivingAccount(params).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    handleResetCap({ id, rateType }) {
      this.$confirm(this.$t('确定重置此账户额度上限?'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        updatePayMaxAmountVal({ id, rateType }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('重置成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    implementationTenantChanged() {
      const params = {
        pageSize: 100,
        currentPage: 1
      }

      getPaymentWayConfList(params).then(([data, err]) => {
        if (!err) {
          this.paymentWayConfList = data
        }
      })

      getAllVipLevels().then(([data, err]) => {
        if (!err) {
          this.vipLevels = data
        }
      })
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
