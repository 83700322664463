import { render, staticRenderFns } from "./GiftStreamDialog.vue?vue&type=template&id=609cb2cc"
import script from "./GiftStreamDialog.vue?vue&type=script&lang=js"
export * from "./GiftStreamDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.jenkins/workspace/live3-test-h5-admin-js/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('609cb2cc')) {
      api.createRecord('609cb2cc', component.options)
    } else {
      api.reload('609cb2cc', component.options)
    }
    module.hot.accept("./GiftStreamDialog.vue?vue&type=template&id=609cb2cc", function () {
      api.rerender('609cb2cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/live/broadcast/components/GiftStreamDialog.vue"
export default component.exports