var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [214].includes(_vm.extras.msgType)
        ? [
            _c(
              "el-card",
              {
                staticClass: "box-div",
                attrs: { "body-style": { padding: "0px" } },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "box-left",
                    on: {
                      click: function ($event) {
                        return _vm.getActivityReward(_vm.extras)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.taskTypeMap[_vm.extras.isDaily].name) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "setDisplay setPd",
                    staticStyle: { "min-height": "30px" },
                    on: {
                      click: function ($event) {
                        return _vm.getActivityReward(_vm.extras)
                      },
                    },
                  },
                  [
                    _c("span", [
                      _c("span", { staticClass: "mainText mainSize setBold" }, [
                        _vm._v(_vm._s(_vm.extras.activityTitle)),
                      ]),
                    ]),
                    _vm.dealItem.isReceiveAward != 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "background-color": "#c62430" },
                            attrs: { type: "danger", round: "" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.extras.isAchieveGoal == 1
                                    ? "领取奖励"
                                    : "去完成"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _c(
                          "el-button",
                          {
                            staticStyle: { "background-color": "#c62430" },
                            attrs: { type: "danger", round: "" },
                          },
                          [_vm._v(" 已完成 ")]
                        ),
                  ],
                  1
                ),
                _c("el-divider"),
                _c(
                  "div",
                  {
                    staticClass: "setDisplayCol setPd",
                    on: {
                      click: function ($event) {
                        return _vm.getActivityReward(_vm.extras)
                      },
                    },
                  },
                  [
                    _vm._l(
                      _vm.typeMapList[_vm.extras.modeType],
                      function (item, index) {
                        return _vm.extras[item.totalKey] != 0
                          ? _c("div", { key: index }, [
                              _c("div", { staticClass: "smallText" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                              _c(
                                "div",
                                { staticClass: "setPdTop setDisplay" },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "60%" } },
                                    [
                                      _c("el-progress", {
                                        attrs: {
                                          "stroke-width": 8,
                                          "stroke-linecap": "butt",
                                          percentage:
                                            _vm.extras[item.currentKey] /
                                              _vm.extras[item.totalKey] >
                                            1
                                              ? 100
                                              : (_vm.extras[item.currentKey] /
                                                  _vm.extras[item.totalKey]) *
                                                  100 || 0,
                                          "show-text": false,
                                          color: "#c62430",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#c62430" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.extras[item.currentKey] * 1
                                          ) +
                                            " / " +
                                            _vm._s(
                                              _vm.extras[item.totalKey] * 1
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "setfont" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.parseTime(
                                            item.timestamp,
                                            "{y}-{m}-{d} {h}:{i}"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e()
                      }
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "setDisplay",
                        staticStyle: {
                          margin: "10px 0px",
                          "justify-content": "start",
                        },
                      },
                      [
                        _c("div", { staticClass: "smallText" }, [
                          _vm._v("任务奖励:"),
                        ]),
                        _c("div", { staticClass: "coulist" }, [
                          _vm.extras.couponTitles
                            ? _c("div", [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("抵扣券"),
                                ]),
                                _c("span", { staticClass: "val" }, [
                                  _vm._v(_vm._s(_vm.extras.couponTitles)),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.extras.amountRolling &&
                          !!Number(_vm.extras.amountRolling.split(",")[0])
                            ? _c("div", [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("彩金"),
                                ]),
                                _c("span", { staticClass: "val" }, [
                                  _vm._v(
                                    "+" +
                                      _vm._s(
                                        _vm.extras.amountRolling.split(",")[0]
                                      )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.extras.fullCouponTitles
                            ? _c("div", [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("全勤抵扣券"),
                                ]),
                                _c("span", { staticClass: "val" }, [
                                  _vm._v(_vm._s(_vm.extras.fullCouponTitles)),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.extras.fullAmountRolling &&
                          !!Number(_vm.extras.fullAmountRolling.split(",")[0])
                            ? _c("div", [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("全勤彩金"),
                                ]),
                                _c("span", { staticClass: "val" }, [
                                  _vm._v(
                                    "+" +
                                      _vm._s(
                                        _vm.extras.fullAmountRolling.split(
                                          ","
                                        )[0]
                                      )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        : _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  staticStyle: { "padding-bottom": "5px" },
                  attrs: { slot: "header" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpAndSearch(_vm.extras)
                    },
                  },
                  slot: "header",
                },
                [
                  _c("span", { staticClass: "mainText mainSize fontBold fl" }, [
                    _vm._v(_vm._s(_vm.titleMap[_vm.extras.msgType])),
                  ]),
                  [207].includes(_vm.extras.msgType)
                    ? _c("span", { staticClass: "smallText fr" }, [
                        _vm._v(
                          " " + _vm._s(_vm.lotteryStatusStr(_vm.extras)) + " "
                        ),
                      ])
                    : _vm._e(),
                  [208].includes(_vm.extras.msgType)
                    ? _c(
                        "span",
                        { staticClass: "smallText fr" },
                        [
                          _vm.extras.orderType == 1502
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm.item.orderStatus == "0"
                                      ? "未结算"
                                      : _vm.getBetResultName(
                                          _vm.item.orderStatus,
                                          _vm.item.profitAmount
                                        )
                                  )
                                ),
                              ]
                            : _vm._e(),
                          _vm.extras.orderType == 2700
                            ? [
                                _vm._v(
                                  _vm._s(
                                    !_vm.item.settleAmount
                                      ? "未结算"
                                      : _vm.item.settleAmount >= 0
                                      ? "赢"
                                      : "输"
                                  )
                                ),
                              ]
                            : _vm._e(),
                          _vm.extras.orderType == 2000
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm.getStatusName(_vm.item.ticketStatus)
                                  )
                                ),
                              ]
                            : _vm._e(),
                          _vm.extras.orderType == 99
                            ? [_vm._v(_vm._s(_vm.statusMap[_vm.extras.status]))]
                            : _vm._e(),
                          _vm.extras.orderType == 2200
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm.item.resultType == 0 ||
                                      !_vm.item.resultType
                                      ? "未结算"
                                      : _vm.item.resultType == 1
                                      ? "已结算"
                                      : "已取消"
                                  )
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  [209].includes(_vm.extras.msgType)
                    ? _c("span", { staticClass: "smallText fr" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.extras.profit > 0 ? "赢" : "输") +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  [210, 211].includes(_vm.extras.msgType)
                    ? _c("span", { staticClass: "smallText fr" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.extras.msgType == 210
                                ? _vm.statusRechange[_vm.extras.status]
                                : _vm.statusWithDrawal[_vm.extras.status]
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  ![207, 208, 209, 210, 211].includes(_vm.extras.msgType)
                    ? _c("span", { staticClass: "smallText fr" }, [
                        _vm._v(" " + _vm._s(_vm.extras.statusDesc) + " "),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._l(
                _vm.getResult(_vm.extras.msgType, _vm.extras, _vm.dealItem),
                function (item) {
                  return _c(
                    "div",
                    {
                      staticClass: "setDisplay",
                      on: {
                        click: function ($event) {
                          return _vm.jumpAndSearch(_vm.extras)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "smallText" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c("div", [
                        _c("span", { staticClass: "mainText smallSize" }, [
                          _vm._v(" " + _vm._s(item.value)),
                        ]),
                        item.needCopy
                          ? _c("i", {
                              staticClass: "el-icon-document-copy smallSize",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.clipboard(item.needCopy)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  )
                }
              ),
            ],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }