<template>
  <div class="app-container">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item :label="$t('积分总开关：')">
        <el-radio-group v-model="ruleForm.pointsSwitch" v-removeAriaHidden>
          <el-radio :label="0">{{ $t('开启') }}</el-radio>
          <el-radio :label="1">{{ $t('关闭') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('充值获取积分：')" class="getPoints">
        <el-radio-group v-model="ruleForm.rechargeSwitch" v-removeAriaHidden>
          <el-radio :label="0">{{ $t('开启') }}</el-radio>
          <el-radio :label="1">{{ $t('关闭') }}</el-radio>
        </el-radio-group>
        <el-form-item label="每充值1RMB获得" label-width="180px" prop="rechargePoints">
          <el-input v-model="ruleForm.rechargePoints" @input="getNumber('rechargePoints')" @blur="getPoints('rechargePoints')" :disabled="rechargeDisabled" style="width: 100px; margin-right: 7px;" maxlength="8" clearable />积分
        </el-form-item>
      </el-form-item>
      <el-form-item :label="$t('投注获取积分：')" class="getPoints">
        <el-radio-group v-model="ruleForm.betSwitch" v-removeAriaHidden>
          <el-radio :label="0">{{ $t('开启') }}</el-radio>
          <el-radio :label="1">{{ $t('关闭') }}</el-radio>
        </el-radio-group>
        <el-form-item label="每投注1RMB获得" label-width="180px" prop="betPoints">
          <el-input v-model="ruleForm.betPoints" @input="getNumber('betPoints')" @blur="getPoints('betPoints')" :disabled="betDisabled" style="width: 100px; margin-right: 7px;" maxlength="8" clearable />积分
        </el-form-item>
      </el-form-item>
      <el-form-item :label="$t('积分商城说明：')">
        <Tinymce v-model="ruleForm.introduction" :height="200" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">
          {{ $t('保存') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { createPoints, getBaseConfig } from '@/api/points'
import Tinymce from '@/components/Tinymce'
import { mapGetters } from 'vuex'

export default {
  name: 'BaseConfig',
  components: { Tinymce },
  data() {
    const validatePoints = (rule, value, callback) => {
      if (value !== '' && value !== null) {
        if (Number(value) <= 0) {
          callback(new Error('请输入大于0的数'))
        }
      }
      callback()
    }
    return {
      ruleForm: {
        pointsSwitch: 0,
        rechargeSwitch: 0,
        betSwitch: 0,
        rechargePoints: null,
        betPoints: null,
        introduction: '',
      },
      rules: {
        rechargePoints: [
          { validator: validatePoints, trigger: 'blur' }
        ],
        betPoints: [
          { validator: validatePoints, trigger: 'blur' }
        ],
      },
      submitting: false
    }
  },
  created() {
    this.getInfos()
  },
  computed: {
    rechargeDisabled() {
      return this.ruleForm.rechargeSwitch === 1
    },
    betDisabled() {
      return this.ruleForm.betSwitch === 1
    },
  },
  methods: {
    getNumber(param) {
      this.ruleForm[param] = this.ruleForm[param].replace(/[^\d.]/g, '')
    },
    getPoints(param) {
      this.ruleForm[param] = parseFloat(Number(this.ruleForm[param]).toFixed(1))
    },
    handleSubmit() {
      if (this.ruleForm.rechargePoints === '' && this.ruleForm.rechargeSwitch !== 1) {
        this.$message.error(this.$t('充值获取积分不能为空'))
        return
      }
      if (this.ruleForm.betPoints === '' && this.ruleForm.betSwitch !== 1) {
        this.$message.error(this.$t('投注获取积分不能为空'))
        return
      }
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.submitting = true
          createPoints(this.ruleForm).then(([_, err]) => {
            if (!err) {
              this.$message.success(this.$t('保存成功'))
            }
          }).finally(() => {
            this.submitting = false
          })
        }
      })
    },
    getInfos() {
      getBaseConfig().then(([data, err]) => {
        const id = data.id
        if (id) {
          if (!err) {
            this.ruleForm.pointsSwitch = data.pointsSwitch
            this.ruleForm.rechargeSwitch = data.rechargeSwitch
            this.ruleForm.betSwitch = data.betSwitch
            this.ruleForm.rechargePoints = data.rechargePoints
            this.ruleForm.betPoints = data.betPoints
            this.ruleForm.introduction = data.introduction
          }
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.getPoints {
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
  }

  .el-form-item--small {
    ::v-deep .el-form-item__label {
      font-weight: normal;
    }
  }

}
</style>
