const CanChooseColumns = function() {
  return [
    {
      title: this.$t('编号'),
      key: 'id',
      align: 'center'
    },
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('会员ID'),
      key: 'memberId',
      align: 'center'
    },
    {
      title: this.$t('任务ID'),
      key: 'activityId',
      align: 'center'
    },
    {
      title: this.$t('任务类型'),
      key: 'isDaily',
      align: 'center',
      component: {
        render: (h, row) => {
          const taskType = this.taskType.find(p => p.key === row.isDaily)
          if (taskType) {
            return (<span>{ taskType.label }</span>)
          }
          return ''
        }
      }
    },
    {
      title: this.$t('任务方式'),
      key: 'modeType',
      align: 'center',
      component: {
        render: (h, row) => {
          const taskMethod = this.taskMethod.find(p => p.key === row.modeType)
          if (taskMethod) {
            return (<span>{ taskMethod.label }</span>)
          }
          return ''
        }
      }
    },
    {
      title: this.$t('任务分类'),
      key: 'activityTypeName',
      align: 'center'
    },
    {
      title: this.$t('抵用券奖励'),
      key: 'couponAmount',
      align: 'center'
    },
    {
      title: this.$t('彩金奖励'),
      key: 'rewardAmount',
      align: 'center'
    },
    {
      title: this.$t('周累计天数'),
      key: 'checkinDays',
      align: 'center'
    },
    // {
    //   title: this.$t('发放方式'),
    //   key: 'type',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       const taskMethod = this.taskMethod.find(p => p.key === row.modeType)
    //       if (taskMethod) {
    //         return (<span>{ taskMethod.label }</span>)
    //       }
    //       return ''
    //     }
    //   }
    // },
    {
      title: this.$t('领取时间'),
      key: 'checkDate',
      align: 'center'
    },
    {
      title: this.$t('备注'),
      key: 'remark',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <el-tooltip
              placement='left'
              content={row.remark}
              popper-class='popper'
            >
              <span class='ellipsis-text'>{ row.remark }</span>
            </el-tooltip>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
