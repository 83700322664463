<template>
  <el-row v-loading="loading" :gutter="24" class="basic_config_content">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="210px"
    >
      <el-col :span="12">
        <h2>{{ $t("下载配置") }}</h2>
        <el-form-item :label="$t('下载地址')" prop="downloadUrl">
          <el-input
            v-model="ruleForm.downloadUrl"
            :placeholder="`${$t('例如')}: www.123.com/`"
          />
        </el-form-item>
        <el-form-item :label="$t('IOS娱乐版下载地址')" prop="iosUrl">
          <el-input
            v-model="ruleForm.iosUrl"
            :placeholder="`${$t('例如')}: www.123.com/`"
          />
        </el-form-item>
        <el-form-item :label="$t('IOS体育版下载地址')" prop="sportsIosUrl">
          <el-input
            v-model="ruleForm.sportsIosUrl"
            :placeholder="`${$t('例如')}: www.123.com/`"
          />
        </el-form-item>
        <el-form-item :label="$t('IOS曾道人版下载地址')" prop="liuheIosUrl">
          <el-input
            v-model="ruleForm.liuheIosUrl"
            :placeholder="`${$t('例如')}: www.123.com/`"
          />
        </el-form-item>
        <el-form-item :label="$t('Android娱乐版下载地址')" prop="androidUrl">
          <el-input
            v-model="ruleForm.androidUrl"
            :placeholder="`${$t('例如')}: www.123.com/`"
          />
        </el-form-item>
        <el-form-item :label="$t('Android体育版下载地址')" prop="sportsAndroidUrl">
          <el-input
            v-model="ruleForm.sportsAndroidUrl"
            :placeholder="`${$t('例如')}: www.123.com/`"
          />
        </el-form-item>
        <el-form-item :label="$t('Android曾道人版下载地址')" prop="liuheAndroidUrl">
          <el-input
            v-model="ruleForm.liuheAndroidUrl"
            :placeholder="`${$t('例如')}: www.123.com/`"
          />
        </el-form-item>
        <h2>{{ $t("app弹窗下载地址") }}</h2>
        <el-form-item :label="$t('下载地址1')" prop="downloadOne">
          <el-input
            v-model="ruleForm.downloadOne"
            :placeholder="`${$t('例如')}: www.123.com/`"
          />
        </el-form-item>
        <el-form-item :label="$t('下载地址2')" prop="downloadTwo">
          <el-input
            v-model="ruleForm.downloadTwo"
            :placeholder="`${$t('例如')}: www.123.com/`"
          />
        </el-form-item>
        <el-form-item :label="$t('下载地址3')" prop="downloadThree">
          <el-input
            v-model="ruleForm.downloadThree"
            :placeholder="`${$t('例如')}: www.123.com/`"
          />
        </el-form-item>

        <h2>{{ $t("推送配置") }}</h2>
        <el-form-item :label="$t('极光配置')" prop="pushAppKey">
          <el-input
            v-model="ruleForm.pushAppKey"
            :placeholder="$t('请输入极光appkey')"
          >
            <template slot="prepend">appkey</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="pushMasterSecret">
          <el-input
            v-model="ruleForm.pushMasterSecret"
            :placeholder="$t('请输入极光masterSecre')"
          >
            <template slot="prepend">masterSecre</template>
          </el-input>
        </el-form-item>

        <h2>{{ $t("推送配置") }}</h2>
        <el-form-item :label="$t('极光配置')" prop="pushAppKey">
          <el-input
            v-model="ruleForm.pushAppKey"
            placeholder="$t('请输入极光appkey')"
          >
            <template slot="prepend">appkey</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="pushMasterSecret">
          <el-input
            v-model="ruleForm.pushMasterSecret"
            placeholder="$t('请输入极光masterSecre')"
          >
            <template slot="prepend">masterSecre</template>
          </el-input>
        </el-form-item>

        <h2>{{ $t("客服配置") }}</h2>
        <el-form-item :label="$t('客服Telegram')" prop="customerQq">
          <el-input
            v-model="ruleForm.customerQq"
            :placeholder="`${$t('例如')}: 12346`"
          />
        </el-form-item>
        <el-form-item :label="$t('客服Telegram')">
          <el-form-item prop="qqShow">
            <el-switch
              v-model="ruleForm.qqShow"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('第三方客服')" prop="threeCustomer">
          <el-input
            v-model="ruleForm.threeCustomer"
            :placeholder="`${$t('例如')}: www.baidu.com`"
          />
        </el-form-item>
        <el-form-item :label="$t('第三方客服')">
          <el-form-item prop="threeShow">
            <el-switch
              v-model="ruleForm.threeShow"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('曾道人第三方客服')" prop="liuheCustomer">
          <el-input
            v-model="ruleForm.liuheCustomer"
            :placeholder="`${$t('例如')}: www.baidu.com`"
          />
        </el-form-item>
        <el-form-item :label="$t('曾道人第三方客服')">
          <el-form-item prop="liuheShow">
            <el-switch
              v-model="ruleForm.liuheShow"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
          </el-form-item>
        </el-form-item>
        <el-form-item label="1对1专属客服名称" prop="thirdName">
          <el-input
            v-model="ruleForm.thirdName"
            placeholder="1对1专属客服名称"
          />
        </el-form-item>
        <el-form-item label="1对1专属客服地址" prop="thirdCustomer">
          <el-input
            v-model="ruleForm.thirdCustomer"
            :placeholder="`${$t('例如')}: www.baidu.com`"
          />
        </el-form-item>
        <el-form-item label="1对1专属客服">
          <el-form-item prop="thirdShow">
            <el-switch
              v-model="ruleForm.thirdShow"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
          </el-form-item>
        </el-form-item>
        <el-form-item label="在线客服2" prop="whatappCustomer">
          <el-input
            v-model="ruleForm.whatappCustomer"
            :placeholder="`${$t('例如')}: www.baidu.com`"
          />
        </el-form-item>
        <el-form-item prop="whatappShow">
          <el-switch
            v-model="ruleForm.whatappShow"
            active-color="#3894FF"
            inactive-color="#F26161"
            :active-text="$t('开启')"
            :inactive-text="$t('关闭')"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item :label="$t('第三方聊天室')">
          <el-form-item prop="chatShow">
            <el-switch
              v-model="ruleForm.chatShow"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
          </el-form-item>
          <template v-if="ruleForm.chatShow === 1">
            <div class="chat-form-item">
              <el-form-item prop="chatImagePathUrl">
                <d-upload
                  v-model="ruleForm.chatImagePathUrl"
                  folder="head"
                  :on-success="handleSuccess"
                  :width="86"
                  :height="86"
                />
              </el-form-item>
              <div class="input">
                <el-form-item prop="chatName" style="margin-bottom: 18px">
                  <el-input
                    v-model.trim="ruleForm.chatName"
                    :placeholder="$t('聊天室名称')"
                    maxlength="20"
                  />
                </el-form-item>
                <el-form-item prop="chatPathUrl">
                  <el-input
                    v-model.trim="ruleForm.chatPathUrl"
                    :placeholder="$t('请输入地址')"
                  />
                </el-form-item>
              </div>
            </div>
            <el-form-item prop="chatRemark">
              <el-input
                v-model="ruleForm.chatRemark"
                :placeholder="$t('简述')"
                type="textarea"
                maxlength="100"
                show-word-limit
                :rows="3"
              />
            </el-form-item>
          </template>
        </el-form-item>

        <h2>{{ $t("H5/Web版本") }}</h2>
        <el-form-item :label="$t('H5地址')" prop="h5Url">
          <el-input v-model="ruleForm.h5Url" :placeholder="`${$t('例如')}: www.123.com/`" />
        </el-form-item>
        <el-form-item :label="$t('WEB地址')" prop="webUrl">
          <el-input v-model="ruleForm.webUrl" :placeholder="`${$t('例如')}: www.123.com/`" />
        </el-form-item>
        <el-form-item :label="$t('轻便版地址')" prop="lightweightAddress">
          <el-input v-model="ruleForm.lightweightAddress" :placeholder="`${$t('例如')}: www.123.com/`" />
        </el-form-item>

        <h2>{{ $t("体育首页跳转") }}</h2>
        <el-form-item :label="$t('赛事记录')">
          <el-form-item
            v-for="locale in locales"
            :key="locale.name"
            :prop="`competition${locale.name}Url`"
          >
            <el-input
              v-model="ruleForm[`competition${locale.name}Url`]"
              style="margin-bottom: 12px"
            >
              <template slot="append">{{ locale.label }}</template>
            </el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('足球专家')">
          <el-form-item
            v-for="locale in locales"
            :key="locale.name"
            :prop="`expert${locale.name}Url`"
          >
            <el-input
              v-model="ruleForm[`expert${locale.name}Url`]"
              style="margin-bottom: 12px"
            >
              <template slot="append">{{ locale.label }}</template>
            </el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('热门预测')">
          <el-form-item
            v-for="locale in locales"
            :key="locale.name"
            :prop="`predict${locale.name}Url`"
          >
            <el-input
              v-model="ruleForm[`predict${locale.name}Url`]"
              style="margin-bottom: 12px"
            >
              <template slot="append">{{ locale.label }}</template>
            </el-input>
          </el-form-item>
        </el-form-item>

        <el-form-item class="action-button">
          <el-button
            type="danger"
            :loading="submitting"
            @click="handleSubmit"
          >{{ $t("确 定") }}</el-button>
          <el-button @click="$refs.ruleForm.resetFields()">{{
            $t("重 置")
          }}</el-button>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <h2>{{ $t("分享赚钱") }}</h2>
        <el-form-item :label="$t('分享赚钱')">
          <el-form-item prop="shareShow">
            <el-switch
              v-model="ruleForm.shareShow"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('代理入口')">
          <el-form-item prop="shareAgentShow">
            <el-switch
              v-model="ruleForm.shareAgentShow"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
          </el-form-item>
        </el-form-item>
        <!-- <el-form-item :label="$t('微信分享')">
          <el-form-item prop="shareWxShow">
            <el-switch
              v-model="switchs.shareWxShow"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
            <p>
              <el-checkbox-group v-if="switchs.shareWxShow" v-model="shares.shareWxShow">
                <el-checkbox v-for="(lang, i) in langs" :key="lang" :label="i + 1">{{ lang }}</el-checkbox>
              </el-checkbox-group>
            </p>
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('QQ分享')">
          <el-form-item prop="shareQqShow">
            <el-switch
              v-model="switchs.shareQqShow"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
            <p>
              <el-checkbox-group v-if="switchs.shareQqShow" v-model="shares.shareQqShow">
                <el-checkbox v-for="(lang, i) in langs" :key="lang" :label="i + 1">{{ lang }}</el-checkbox>
              </el-checkbox-group>
            </p>
          </el-form-item>
        </el-form-item> -->
        <el-form-item :label="$t('Facebook分享')">
          <el-form-item prop="shareFacebookShow">
            <el-switch
              v-model="switchs.shareFacebookShow"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
            <p>
              <el-checkbox-group
                v-if="switchs.shareFacebookShow"
                v-model="shares.shareFacebookShow"
              >
                <el-checkbox
                  v-for="(lang, i) in langs"
                  :key="lang"
                  :label="i + 1"
                >{{ lang }}</el-checkbox>
              </el-checkbox-group>
            </p>
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('Telgram分享')">
          <el-form-item prop="shareTelegramShow">
            <el-switch
              v-model="switchs.shareTelegramShow"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
            <p>
              <el-checkbox-group
                v-if="switchs.shareTelegramShow"
                v-model="shares.shareTelegramShow"
              >
                <el-checkbox
                  v-for="(lang, i) in langs"
                  :key="lang"
                  :label="i + 1"
                >{{ lang }}</el-checkbox>
              </el-checkbox-group>
            </p>
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('Whatsapp分享')">
          <el-form-item prop="shareWhatappShow">
            <el-switch
              v-model="switchs.shareWhatappShow"
              active-color="#3894FF"
              inactive-color="#F26161"
              :active-text="$t('开启')"
              :inactive-text="$t('关闭')"
              :active-value="1"
              :inactive-value="0"
            />
            <p>
              <el-checkbox-group
                v-if="switchs.shareWhatappShow"
                v-model="shares.shareWhatappShow"
              >
                <el-checkbox
                  v-for="(lang, i) in langs"
                  :key="lang"
                  :label="i + 1"
                >{{ lang }}</el-checkbox>
              </el-checkbox-group>
            </p>
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('分享地址')" prop="shareAddress">
          <el-input v-model="ruleForm.shareAddress" />
        </el-form-item>
        <el-form-item class="action-button">
          <el-button
            type="danger"
            :loading="submitting"
            @click="handleSubmit"
          >{{ $t("确 定") }}</el-button>
          <el-button @click="$refs.ruleForm.resetFields()">{{
            $t("重 置")
          }}</el-button>
        </el-form-item>
      </el-col>
    </el-form>
    <el-col :span="12">
      <el-form
        ref="validateForm"
        :model="smsRuleForm"
        :rules="smsRules"
        label-width="180px"
      >
        <h2>{{ $t("短信配置") }}</h2>
        <el-form-item :label="$t('短信运营商')" prop="smsVendor">
          <el-select
            v-model="smsRuleForm.smsVendor"
            @change="_handleSmsVendorChange"
          >
            <el-option
              v-for="item in options"
              :key="item.smsVendor"
              :value="item.smsVendor"
              :label="item.signname"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('权重')" prop="sortOrder">
          <el-input v-model="smsRuleForm.sortOrder" />
        </el-form-item>
        <el-form-item label="AppID" prop="appid">
          <el-input v-model="smsRuleForm.appid" />
        </el-form-item>
        <template v-if="smsRuleForm.smsVendor === 'YUNZHIXUN'">
          <el-form-item label="Account Sid" prop="sid">
            <el-input v-model="smsRuleForm.sid" />
          </el-form-item>
          <el-form-item label="Auth Token" prop="token">
            <el-input v-model="smsRuleForm.token" />
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="AppKey" prop="appkey">
            <el-input v-model="smsRuleForm.appkey" />
          </el-form-item>
          <el-form-item label="AppSecret" prop="appsecret">
            <el-input v-model="smsRuleForm.appsecret" />
          </el-form-item>
          <el-form-item
            v-if="smsRuleForm.smsVendor === 'YIDUN'"
            label="AppID"
            prop="appid"
          >
            <el-input v-model="smsRuleForm.appid" />
          </el-form-item>
          <el-form-item v-else label="Signname" prop="signname">
            <el-input v-model="smsRuleForm.signname" />
          </el-form-item>
        </template>
        <el-form-item :label="$t('模板ID')" prop="template1">
          <el-input v-model="smsRuleForm.template1" />
        </el-form-item>
        <el-form-item :label="$t('模板ID')" prop="template2">
          <el-input v-model="smsRuleForm.template2" />
        </el-form-item>
        <el-form-item :label="$t('模板ID')" prop="template3">
          <el-input v-model="smsRuleForm.template3" />
        </el-form-item>

        <el-form-item class="action-button">
          <el-button
            type="danger"
            :loading="smsSubmitting"
            @click="handleSmsSubmit"
          >{{ $t("确 定") }}</el-button>
          <el-button @click="$refs.validateForm.resetFields()">{{
            $t("重 置")
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import DUpload from '@/components/DUpload'
import {
  requireUrl,
  requiredChange,
  requiredBlur,
  integer1_99,
  stringLengcheck
} from '@/utils/validation.js'
import {
  getAddressConfig,
  editAddressConfig,
  getSmsConfig,
  saveSmsConfig
} from '@/api/config.js'

const getDefaultSmsForm = () => ({
  smsVendor: '',
  appid: '',
  sid: '',
  token: '',
  appkey: '',
  appsecret: '',
  signname: '',
  sortOrder: null,
  template1: '',
  template2: '',
  template3: ''
})

export default {
  name: 'AddressTab',
  componentName: 'AddressTab',
  components: { DUpload },
  data() {
    return {
      loading: false,
      submitting: false,
      smsSubmitting: false,
      langs: ['中文', '英语', '越南语', '印度语', '日语', '马来西亚语'],
      switchs: {
        shareWxShow: 0,
        shareQqShow: 0,
        shareFacebookShow: 0,
        shareTelegramShow: 0,
        shareWhatappShow: 0
      },
      shares: {
        shareWxShow: [],
        shareQqShow: [],
        shareFacebookShow: [],
        shareTelegramShow: [],
        shareWhatappShow: []
      },

      ruleForm: {
        downloadUrl: '', // 下载地址
        iosUrl: '', // IOS下载地址
        sportsIosUrl:'',
        liuheIosUrl: '',
        androidUrl: '', // Android下载地址
        sportsAndroidUrl:'',
        liuheAndroidUrl: '',
        downloadOne: '',
        downloadTwo: '',
        downloadThree: '',
        pushAppKey: '', // 极光appkey
        pushMasterSecret: '', // 极光masterSecre
        customerQq: '', // 客服QQ
        qqShow: 0,
        threeCustomer: '', // 第三方客服
        threeShow: 0,
        liuheCustomer: '', // 第三方客服
        liuheShow: 0,
        thirdName: '', // 1对1专属客服
        thirdCustomer: '', // 1对1专属客服
        thirdShow: 0,
        whatappCustomer: '', // WhatsApp
        whatappShow: '', // WhatsApp
        chatShow: 0,
        chatImagePathUrl: '',
        chatName: '',
        chatPathUrl: '',
        chatRemark: '',
        h5Url: '', // H5地址
        webUrl: '', // WEB地址
        lightweightAddress: '', // 轻便版地址
        competitionZnUrl: '',
        competitionViUrl: '',
        competitionEnUrl: '',
        expertZnUrl: '',
        expertViUrl: '',
        expertEnUrl: '',
        predictZnUrl: '',
        predictViUrl: '',
        predictEnUrl: '',
        shareAddress: ''
      },
      smsRuleForm: getDefaultSmsForm(),
      smsConfigWithSmsVendor: {},
      options: this.$t('smsVendorOptions'),
      locales: this.$t('localesOptions'),
      smsConfig: [],
      rules: {
        downloadUrl: requireUrl,
        iosUrl: requiredBlur,
        androidUrl: requiredBlur,
        downloadOne: requireUrl,
        downloadTwo: requireUrl,
        downloadThree: requireUrl,
        pushAppKey: stringLengcheck({ min: 2, max: 30 }),
        pushMasterSecret: stringLengcheck({ min: 2, max: 30 }),
        customerQq: requiredBlur,
        threeCustomer: requireUrl,
        thirdName: requiredBlur,
        thirdCustomer: requireUrl,
        whatappCustomer: requireUrl,
        chatImagePathUrl: [
          { required: true, message: this.$t('请上传图标'), trigger: 'blur' }
        ],
        chatName: stringLengcheck({ max: 20 }),
        chatPathUrl: requiredBlur,
        chatRemark: stringLengcheck({ min: 2, max: 100 }),
        h5Url: requireUrl,
        webUrl: requireUrl,
        lightweightAddress: requireUrl,
        competitionZnUrl: requireUrl,
        competitionViUrl: requireUrl,
        competitionEnUrl: requireUrl,
        expertZnUrl: requireUrl,
        expertViUrl: requireUrl,
        expertEnUrl: requireUrl,
        predictZnUrl: requireUrl,
        predictViUrl: requireUrl,
        predictEnUrl: requireUrl
      },
      smsRules: {
        smsVendor: requiredChange,
        sortOrder: [
          ...requiredBlur,
          { pattern: integer1_99, message: '1-99', trigger: 'blur' }
        ],
        appid: requiredBlur,
        sid: requiredBlur,
        token: requiredBlur,
        appkey: requiredBlur,
        appsecret: requiredBlur,
        signname: requiredBlur,
        template1: requiredBlur,
        template2: requiredBlur,
        template3: requiredBlur
      }
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    async handleSearch() {
      this.loading = true
      Promise.all([getAddressConfig(), this._getSmsConfig()])
        .then(([config, tree]) => {
          this.loading = false
          const [data, err] = config
          const [items, err1] = tree
          if (!err && data) {
            this.ruleForm = data
            // 分享赚钱预处理
            Object.entries(this.shares).forEach(([k, v]) => {
              if (this.ruleForm[k] != '0') {
                this.switchs[k] = 1
                this.shares[k] = this.ruleForm[k]
                  .split(',')
                  .map((p) => Number(p))
              }
            })
          }

          if (!err1) {
            this.smsConfig = items || []
          }
        })
        .catch(() => {
          this.loading = false
        })
    },

    _getSmsConfig() {
      return getSmsConfig().then(([items, err]) => {
        if (!err) {
          this.smsConfig = items
        }
        return [items, err]
      })
    },

    handleSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // 分享赚钱的预处理
          Object.entries(this.switchs).forEach(([k, v]) => {
            this.ruleForm[k] = v === 0 ? 0 : this.shares[k].join(',')
          })
          this._updateConfig()
        }
      })
    },
    _updateConfig() {
      this.submitting = true
      editAddressConfig({ ...this.ruleForm }).then(([data, err]) => {
        this.submitting = false
        if (!err) {
          this.$message.success(this.$t('保存成功'))
        }
      })
    },
    _handleSmsVendorChange(smsVendor) {
      this.$refs.validateForm.resetFields()

      const config = this.smsConfig.find((o) => o.smsVendor === smsVendor)

      if (config) this.smsRuleForm = { ...this.smsRuleForm, ...config }
      else this.smsRuleForm = { ...this.smsRuleForm, ...getDefaultSmsForm() }

      this.smsRuleForm.smsVendor = smsVendor
    },
    handleSmsSubmit() {
      this.$refs['validateForm'].validate((valid) => {
        if (valid) {
          this.smsSubmitting = true
          saveSmsConfig({ ...this.smsRuleForm }).then(([_, err]) => {
            this.smsSubmitting = false
            if (!err) {
              this._getSmsConfig()
              this.$message.success(this.$t('保存成功'))
            }
          })
        }
      })
    },
    handleSuccess(data) {
      this.ruleForm.chatImagePathUrl = data + ''
      this.$refs.ruleForm.validateField('chatImagePathUrl')
    },
    beforeUpload(isAccept, isMax) {
      if (!isAccept) {
        this.$message.error(this.$t('上传图标只能是 JPG、PNG 格式!'))
      }
      if (!isMax) {
        this.$message.error(this.$t('上传图标大小不能超过 2MB!'))
      }
    }
  }
}
</script>
