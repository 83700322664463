<template>
  <div class="app-container">
    <div class="cascader-conatiner">
      <el-cascader
        v-model="value"
        :options="cascaderOptions"
        style="width: 400px"
        :placeholder="$t('请选择彩种')"
        @change="handleChange"
      />
    </div>
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane
          v-for="tab of permissionTabs"
          :key="tab.name"
          :label="tab.label"
          :name="tab.name"
        />
      </el-tabs>
    </template>

    <el-button v-if="operatorType !== 1" type="danger" icon="el-icon-refresh-right" class="reset-button" @click="handleReset">{{ $t('重 置') }}</el-button>

    <component :is="activeName" v-if="!loading" :lottery-id="lotteryId" :play-class="playClass" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parentMixin } from '@/mixins/tab-items'
import navigationMixin from '../navigationMixin'
import { getLotteryNavigationList, getPlayclass, resetTenant } from '@/api/lottery'
import OddsTab from './odds'
import PlayTab from './play'
import TyPeTab from './class'
import GroupTab from './group'
import LotterydescTab from './lotterydesc'
function parseClassList(options) {
  return options.map(o => ({
    value: o.playTag,
    label: o.playName,
    children: o.playClassList.map(t => ({
      value: t.playClassTag,
      label: t.playClassName
    }))
  }))
}

export default {
  name: 'LotteryManage',
  components: { OddsTab, PlayTab, TyPeTab, GroupTab, LotterydescTab },
  mixins: [parentMixin, navigationMixin(getLotteryNavigationList)],
  data() {
    return {
      value: [],
      cascaderOptions: [],
      playClass: [],
      loading: true,
      tabs: [
        { label: this.$t('赔率设置'), name: 'OddsTab' },
        { label: this.$t('玩法设置'), name: 'PlayTab' },
        { label: this.$t('玩法分类设置'), name: 'TyPeTab' },
        { label: this.$t('玩法说明设置'), name: 'GroupTab' },
        { label: this.$t('彩种说明设置'), name: 'LotterydescTab' }
      ]
    }
  },
  computed: {
    ...mapGetters(['operatorType']),
    lotteryId() {
      if (this.value.length) {
        return this.value[1]
      }
      return null
    }
  },

  created() {
    this.onFetch()
  },
  methods: {
    handleChange() {
      this.handleFetchPlayclass()
    },
    handleFetchPlayclass() {
      this.loading = true
      getPlayclass({ lotteryId: this.lotteryId }, this.operatorType).then(([items, err]) => {
        if (!err) {
          this.playClass = parseClassList(items)
          this.loading = false
        }
      })
    },
    handleReset() {
      this.$confirm(this.$t('确认重置所有玩法基础数据吗'), { type: 'warning' }).then(() => {
        resetTenant({ lotteryId: this.value[1] }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('重置成功'))
            this.$router.replace({ path: '/redirect' + this.$route.path })
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.cascader-conatiner {
  padding: 10px 0;
}
.reset-button {
  position: absolute;
  right: 12px;
  top: 69px;

}
</style>
