
<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <component :is="activeName" />
    </template>
  </div>
</template>
<script>
import BlockIpTab from './BlockIpTab'
import BlockPhoneTab from './BlockPhoneTab'
import { parentMixin } from '@/mixins/tab-items'

export default {
  name: 'InteractiveSeal',
  components: {
    BlockIpTab,
    BlockPhoneTab
  },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      activeName: 'BlockIpTab',
      tabs: [
        { label: this.$t('封IP'), name: 'BlockIpTab' },
        { label: this.$t('封手机'), name: 'BlockPhoneTab' }
      ]
    }
  }
}
</script>
