<template>
  <div class="app-container">
    <div class="tab-header" flex="main:justify cross:bottom">
      <span>{{ nickname }}</span>
      <el-button type="primary" size="small" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      @selection-change="handleSelectionChange"
    >
      <template slot="left">
        <button
          class="el-button el-button--small el-button--orange"
          :disabled="!selection.length"
          @click="handleBatchHidden"
        >批量隐藏</button>
        <button
          class="el-button el-button--small el-button--orange"
          :disabled="!selection.length"
          @click="handleBatchLimit1"
        >批量锁帖</button>
        <button
          class="el-button el-button--small el-button--orange"
          :disabled="!selection.length"
          @click="handleBatchLimit"
        >批量限制</button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import {
  getRecommendHistoryList,
  updateRecommendTop,
  updatePostTop,
  updateRecommendHiden,
  updateRecommendViewAndAdmire,
  bacthRecommendHidden,
  bacthRecommendLimit,
  bacthRecommendLock
} from '@/api/interactive'

export default {
  name: 'ListTab',
  componentName: 'ListTab',
  mixins: [TablePageMixin(getRecommendHistoryList, false)],
  data() {
    return {
      times: null,
      nickname: '',
      filterColumn: false,
      search: {},
      columns: GetColumns.call(this),
      selection: []
    }
  },
  created() {
    const { name, id, tenantCode } = this.$route.query
    this.search.memberId = id
    if (tenantCode) this.search.tenantCode = tenantCode
    this.nickname = name
  },
  mounted() {
    this.handleFetch()
  },
  methods: {
    onCommand(command, row) {
      switch (command) {
        case 0:
          this.goEdit(row)
          break
        case 1:
          this.handleUpdateTop(row)
          break
        case 2:
          this.handleUpdateHide(row)
          break
        case 3:
          this.handleBatchLimit1(row)
          break
        case 4:
          this.handlePostTop(row)
          break
        default:
          break
      }
    },

    goEdit(row) {
      this.$router.push({ name: 'RecommendEdit', query: { id: row.id, type: row.type, lotteryId: row.lotteryId }})
    },

    handleUpdateTop({ id, topViewStatus }) { // 加精
      let params = { id, topViewStatus: ~topViewStatus + 2 }
      this.$confirm(`确定${topViewStatus === 1 ? '取消' : ''}加精此帖子`, '系统提示', { type: 'warning' }).then(() => {
        updateRecommendTop(params).then(([data, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },
    handlePostTop({ id, topSort }) {
      let data = { id, isTop: (topSort < 4 ? 0 : 1) }// isTop 1是置顶 0是取消置顶
      this.$confirm(`确定${topSort === 1 ? '取消' : ''}置顶此帖子`, '系统提示', { type: 'warning' }).then(() => {
        updatePostTop(data).then(([data, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    handleUpdateHide({ id, tenantCode }) {
      let params = { postId: id }
      this.$confirm('确定隐藏此帖子', '系统提示', { type: 'warning' }).then(() => {
        updateRecommendHiden(params).then(([data, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },

    handleUpdateViewAndAdmire({ id, viewNum, admireNum, tenantCode }) {
      let params = { id, viewNum, admireNum }
      updateRecommendViewAndAdmire(params).then(([data, err]) => {
        if (!err) {
          this.$message.success('更新成功')
        }
      })
    },

    handleBatchHidden() {
      let data = {}
      data.postIds = this.selection.map(o => o.id)
      bacthRecommendHidden(data).then(([data, err]) => {
        if (!err) {
          this.$message.success('批量隐藏操作成功')
          this.handleFetch()
        }
      })
    },
    handleBatchLimit1({ id, lockStatus = 0 }) { // freezeStatus 彩讯禁言   muteStatus锁贴静评
      if (lockStatus != undefined) {
        lockStatus = (lockStatus + 1) % 2
      }
      let data = { lockStatus }
      data.postIds = id ? [id] : this.selection.map(o => o.id)
      this.$confirm(`确定${lockStatus === 0 ? '解锁' : '锁定'}帖子`, '系统提示', { type: 'warning' }).then(() => {
        bacthRecommendLock(data).then(([_, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    handleBatchLimit({ memberId, freezeStatus = 0 }) {
      if (freezeStatus != undefined) {
        freezeStatus = (freezeStatus + 1) % 2
      }
      let data = { freezeStatus }
      data.memberIds = memberId ? [memberId] : this.selection.map(o => o.memberId)
      this.$confirm('确定限制用户发帖', '系统提示', { type: 'warning' }).then(() => {
        bacthRecommendLimit(data).then(([data, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    handleSelectionChange(selection) {
      this.selection = selection
    },

    implementationGetParams() {
      return this.formatIntervalTime()
    }
  }
}
</script>
<style lang="scss">
.recommend-status {
  font-size: 12px;

  &>span {
    display: inline-block;
    padding: 0 10px;
    vertical-align: middle;
  }
}
</style>
