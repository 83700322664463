var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "回复评论", width: "600px", visible: _vm.visible },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户类型" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("后台发评论"),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v("代用户发评论"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.type === 0
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "用户账号", prop: "memberName" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "用户账号" },
                      model: {
                        value: _vm.ruleForm.memberName,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "memberName", $$v)
                        },
                        expression: "ruleForm.memberName",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "el-form-item",
                  { attrs: { label: "后台用户", prop: "nickName" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "后台用户昵称" },
                      model: {
                        value: _vm.ruleForm.nickName,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "nickName", $$v)
                        },
                        expression: "ruleForm.nickName",
                      },
                    }),
                  ],
                  1
                ),
              ],
          _c(
            "el-form-item",
            { attrs: { label: "评论内容", prop: "content" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: "3",
                  maxlength: "25",
                  "show-word-limit": "",
                  placeholder: "评论内容",
                },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.close } },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.submit } },
                [_vm._v("发布")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }