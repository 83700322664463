import { rateTypes } from "@/libs/options";
import { filterColumns } from "@/utils";
const CanChooseColumns = function () {
  return [
    {
      title: this.$t("会员账号"),
      key: "memberName",
      align: "center",
    },
    {
      title: this.$t("真实姓名"),
      key: "realName",
      align: "center",
    },
    {
      title: this.$t("钱包名称"),
      key: "walletName",
      align: "center",
    },

    {
      title: this.$t("钱包地址"),
      key: "walletCard",
      align: "center",
      component: {
        render: (h, row) => {
          if (row.walletCard.startsWith("http"))
            return (
             <div>
               <el-popover
                placement="center" 
                trigger="click" 
              >
                <el-image
                  src={row.walletCard}
                  fix="cover"
                  slot="reference"
                  style="width: 40px;height: 40px;border-radius: 4px"
                />
                <el-image
                  src={row.walletCard}
                  fix="cover" 
                  style="width: 340px;height: 340px;border-radius: 4px"
                />
              </el-popover>
              {row.walletUrl&&<el-image 
               style="width: 100px; height: 100px"
               src={row.walletUrl}
               preview-src-list={[row.walletUrl]}>
             </el-image>}
             </div>
            );
          return (<div>
            <div>{row.walletCard}</div>
           {row.walletUrl&&<el-image 
               style="width: 100px; height: 100px"
               src={row.walletUrl}
               preview-src-list={[row.walletUrl]}>
             </el-image>}
          </div>);
        },
      },
    },
    {
      title: this.$t("绑定时间"),
      key: "createTime",
      align: "center",
      width: 160,
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.createTime)}</span>;
        },
      },
    },
    {
      title: this.$t("修改时间"),
      key: "updateTime",
      align: "center",
      width: 160,
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.updateTime)}</span>;
        },
      },
    },
    {
      title: this.$t("会员状态"),
      align: "center",
      key: "status",
      component: {
        render: (h, row) => {
          return <span>{["启用", "停用"][row.status]}</span>;
        },
      },
    },
    {
      title: this.$t("操作"),
      key: "actions",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span>
              {this.permission.Delete && (
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  onClick={() => this._delete(row.id, row.name)}
                >
                  {this.$t("删除")}
                </el-button>
              )}
            </span>
          );
        },
      },
    },
  ];
};

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}
