var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加",
        width: "1200px",
        top: "8vh",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "聊天室分类", prop: "roomType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: { placeholder: "请选择聊天室分类" },
                  model: {
                    value: _vm.ruleForm.roomType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "roomType", $$v)
                    },
                    expression: "ruleForm.roomType",
                  },
                },
                _vm._l(_vm.roomTypesTwo, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "聊天室名称", prop: "roomList" } },
            [
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "lazy-load-dropdown",
                      rawName: "v-lazy-load-dropdown",
                      value: _vm.handleScrollDropdown,
                      expression: "handleScrollDropdown",
                    },
                  ],
                  staticStyle: { width: "300px" },
                  attrs: {
                    multiple: "",
                    remote: "",
                    filterable: "",
                    "remote-method": _vm.searchChatByName,
                    placeholder: "请选择聊天室",
                  },
                  on: {
                    focus: function ($event) {
                      return _vm.searchChatByName("")
                    },
                  },
                  model: {
                    value: _vm.ruleForm.roomList,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "roomList", $$v)
                    },
                    expression: "ruleForm.roomList",
                  },
                },
                _vm._l(_vm.chatList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      "value-key": item.id,
                      label: item.roomName,
                      value: item.id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "助手名称", prop: "assistantList" } },
            [
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "lazy-load-dropdown",
                      rawName: "v-lazy-load-dropdown",
                      value: _vm.handleScrollDropdownByChat,
                      expression: "handleScrollDropdownByChat",
                    },
                  ],
                  staticStyle: { width: "300px" },
                  attrs: {
                    multiple: "",
                    remote: "",
                    filterable: "",
                    "remote-method": _vm.searchByName,
                    placeholder: "请选择选择机器人",
                  },
                  on: {
                    change: _vm.setAssistantList,
                    focus: function ($event) {
                      return _vm.searchByName("")
                    },
                  },
                  model: {
                    value: _vm.ruleForm.assistantList,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "assistantList", $$v)
                    },
                    expression: "ruleForm.assistantList",
                  },
                },
                _vm._l(_vm.memberInfo, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      "value-key": item.id,
                      label: item.name,
                      value: item.id,
                    },
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { icon: "el-icon-plus", type: "success" },
                  on: { click: _vm.addPush },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "机器人列表", prop: "tableData" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "20px" },
                  attrs: { data: _vm.ruleForm.tableData, stripe: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "助手名称", width: "180" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "nickname",
                      label: "用户昵称",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "avatar", label: "用户头像", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-image", {
                              staticStyle: {
                                width: "40px",
                                height: "40px",
                                "border-radius": "50%",
                              },
                              attrs: { fix: "cover", src: scope.row.avatar },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "activePeriod", label: "活跃时段" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "imageDescription", label: "形象描述" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "状态" },
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeleteActivityType(
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }