<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.domainName" clearable :placeholder="$t('代理线名称')" class="filter-item" style="width: 250px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button type="success" icon="el-icon-plus" class="filter-item" @click="onCreate()">
        添加代理线路
      </el-button>
      <!-- <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate()">
        添加代理线路
      </el-button> -->
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <edit-dialog ref="EditDialogControl" @create="handleCreate" @update="hanldeUpdate" />
  </div>
</template>

<script>
import EditDialog from './EditDialog.vue'
import { getCodeList, addCode, editCode, deleteCode } from '@/api/agent-rebate-prox.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
// let search = {}
export default {
  name: 'ProxLine',
  components: { EditDialog },
  mixins: [TablePageMixin(getCodeList)],
  data() {
    return {
      times: [],
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
      defaultAgentType: [0, 1, 2, 3, 4, 5, 6]
    }
  },
  methods: {
    onCreate(row) {
      this.$refs.EditDialogControl.open(row, this.$store.getters.tenantCode)
    },
    handleCreate({ field, cancel, close }) {
      addCode({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('保存成功'))
        } else {
          cancel()
        }
      })
    },

    hanldeUpdate({ field, cancel, close }) {
      editCode({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('保存成功'))
        } else {
          cancel()
        }
      })
    },

    handleDelete(row) {
      let title = '确认' + (row.isDelete == true ? '启用' : '停用')
      this.$confirm(this.$t(title), this.$t('系统提示'), { type: 'warning' }).then(() => {
        let data = {
          'domainUrl': row.domainUrl,
          'tenantCode': row.tenantCode,
          'id': row.id,
          'isDelete': !row.isDelete
        }
        deleteCode(data).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t((row.isDelete == true ? '启用' : '停用') + '成功'))
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
<style type="scss" scoped>
.filter-container {
  display: flex;
  gap: 8px;
}

.filter-container .filter-item+.filter-item {
  margin-left: 0;
}
</style>
