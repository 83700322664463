const CanChooseColumns = function() {
  return [
    {
      title: '名称',
      key: 'versionName',
      align: 'center',
      width: 120
    },
    {
      title: '版本',
      key: 'version',
      align: 'center',
      width: 100
    },
    {
      title: '大小',
      key: 'appSize',
      align: 'center',
      width: 100
    },
    {
      title: '升级说明',
      key: 'versionInfo',
      align: 'center',
      width: 80,
      component: {
        render: (h, row) => {
          return (
            <el-popover
              placement='top'
              width='250'
              trigger='hover'
              effect='light'
              content={row.versionInfo}>
              <el-link slot='reference' type='primary'>查看</el-link>
            </el-popover>
          )
        }
      }
    },
    {
      title: '下载地址',
      key: 'appDownurl',
      align: 'center',
      minWidth: 250,
      component: {
        render: (h, row) => {
          return (<span>
            <p>娱乐版：{ row.appDownurl }</p>
            <p>体育版：{ row.sportsAppDownurl }</p>
            <p>曾道人版：{ row.zdrAppDownurl }</p>
          </span>)
        }
      }
    },
    {
      title: '强制更新',
      key: 'force',
      align: 'center',
      width: 78,
      component: {
        render: (h, row) => {
          return (<span>{ row.force === 0 ? '是' : '否' }</span>)
        }
      }
    },
    {
      title: '发布时间',
      key: 'publishTime',
      align: 'center',
      width: 150,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.publishTime) }</span>)
        }
      }
    },
    {
      title: '发布人',
      key: 'createUser',
      align: 'center'
    },
    {
      title: '发布状态',
      key: 'publishStatus',
      align: 'center',
      width: 110,
      component: {
        render: (h, row) => {
          if (row.publishStatus === 1) {
            return <el-tag type='success'>发布成功</el-tag>
          } else if (row.publishStatus === 2) {
            return <el-tag type='warning'>定时发布</el-tag>
          } else if (row.publishStatus === 9) {
            return <el-tag type='danger'>已取消</el-tag>
          }
        }
      }
    },
    {
      title: '修改人',
      key: 'updateUser',
      align: 'center'
    },
    {
      title: '最后修改时间',
      key: 'updateTime',
      align: 'center',
      width: 150,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (
            this.permission.Cancel &&
            row.cancelPublish === 9 &&
              <el-button type='danger' size='mini' icon='el-icon-warning-outline' onClick={ () => this._handleCancel(row) }>取消</el-button>)
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
