var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              maxlength: "30",
              placeholder: _vm.$t("活动ID"),
            },
            model: {
              value: _vm.search.activityId,
              callback: function ($$v) {
                _vm.$set(_vm.search, "activityId", $$v)
              },
              expression: "search.activityId",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              maxlength: "30",
              placeholder: _vm.$t("活动标题"),
            },
            model: {
              value: _vm.search.title,
              callback: function ($$v) {
                _vm.$set(_vm.search, "title", $$v)
              },
              expression: "search.title",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { clearable: "", placeholder: _vm.$t("活动状态") },
              model: {
                value: _vm.search.status,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "status", $$v)
                },
                expression: "search.status",
              },
            },
            _vm._l(_vm.activityStatus, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { value: item.key, label: item.label },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { clearable: "", placeholder: _vm.$t("活动分类") },
              model: {
                value: _vm.search.category,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "category", $$v)
                },
                expression: "search.category",
              },
            },
            _vm._l(_vm.categoryList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { value: item.id, label: item.typeName },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: _vm.$t("任务分类") },
              on: { change: _vm.changeTaskType },
              model: {
                value: _vm.search.taskType,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "taskType", $$v)
                },
                expression: "search.taskType",
              },
            },
            _vm._l(_vm.taskCategory, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { value: item.key, label: item.label },
              })
            }),
            1
          ),
          _vm.isShowTaskAction
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "120px" },
                  attrs: { clearable: "", placeholder: _vm.$t("任务行为") },
                  model: {
                    value: _vm.search.taskAction,
                    callback: function ($$v) {
                      _vm.$set(_vm.search, "taskAction", $$v)
                    },
                    expression: "search.taskAction",
                  },
                },
                _vm._l(_vm.filteredTaskActionList, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { value: item.key, label: item.label },
                  })
                }),
                1
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleSearch },
            },
            [_vm._v(_vm._s(_vm.$t("查询")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-refresh-right" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("重置")))]
          ),
          _vm.permission.Add
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/Activity_Awards_Record")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("活动奖励记录")))]
              )
            : _vm._e(),
          _vm.permission.Add
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { float: "right" },
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: { click: _vm.onCreate },
                },
                [_vm._v(_vm._s(_vm.$t("新建活动")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          options: _vm.options,
          "filter-column": _vm.filterColumn,
        },
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("ActionDialog", {
        ref: "ActionDialogControl",
        on: {
          create: _vm.handleCreateActivity,
          update: _vm.handleUpdateActivity,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }