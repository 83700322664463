<template>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="210px">
    <el-row v-loading="loading" :gutter="24" class="basic_config_content">

      <el-col :span="12">
        <h2>心水推荐</h2>
        <el-form-item label="发帖限制VIP等级" prop="xsVipSend">
          <el-select v-model="ruleForm.xsVipSend" placeholder="请选择">
            <el-option v-for="level in Levels" :key="level.id" :value="level.vipLevel" :label="`VIP${level.vipLevel}`" />
          </el-select>
        </el-form-item>
        <el-form-item label="评论限制VIP等级" prop="xsVipReply">
          <el-select v-model="ruleForm.xsVipReply" placeholder="请选择">
            <el-option v-for="level in Levels" :key="level.id" :value="level.vipLevel" :label="`VIP${level.vipLevel}`" />
          </el-select>
        </el-form-item>
        <el-form-item label="每日限制发贴次数" prop="xsSendNum">
          <el-input v-model="ruleForm.xsSendNum" />
        </el-form-item>
        <el-form-item label="每日限制评论次数" prop="xsReplyNum">
          <el-input v-model="ruleForm.xsReplyNum" />
        </el-form-item>

        <h2>六合图库</h2>
        <el-form-item label="评论限制VIP等级" prop="lhcVipReply">
          <el-select v-model="ruleForm.lhcVipReply" placeholder="请选择">
            <el-option v-for="level in Levels" :key="level.id" :value="level.vipLevel" :label="`VIP${level.vipLevel}`" />
          </el-select>
        </el-form-item>
        <el-form-item label="每日限制评论次数" prop="lhcReplyNum">
          <el-input v-model="ruleForm.lhcReplyNum" />
        </el-form-item>

        <h2>晒单圈</h2>
        <el-form-item label="发言限制VIP等级" prop="circleVipSend">
          <el-select v-model="ruleForm.circleVipSend" placeholder="请选择">
            <el-option v-for="level in Levels" :key="level.id" :value="level.vipLevel" :label="`VIP${level.vipLevel}`" />
          </el-select>
        </el-form-item>
        <el-form-item label="评论限制VIP等级" prop="circleVipReply">
          <el-select v-model="ruleForm.circleVipReply" placeholder="请选择">
            <el-option v-for="level in Levels" :key="level.id" :value="level.vipLevel" :label="`VIP${level.vipLevel}`" />
          </el-select>
        </el-form-item>
        <el-form-item label="每日限制发送次数" prop="circleSendNum">
          <el-input v-model="ruleForm.circleSendNum" />
        </el-form-item>
        <el-form-item label="每日限制评论次数" prop="circleReplyNum">
          <el-input v-model="ruleForm.circleReplyNum" />
        </el-form-item>
        <el-form-item label="用户删除帖子" prop="delCircleStatus">
          <d-switch v-model="ruleForm.delCircleStatus" size="large" :active-value="1" :inactive-value="0" active-color="#3894FF" inactive-color="#F26161">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </d-switch>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <h2>{{ $t('跟单大厅') }}</h2>
        <el-form-item :label="$t('VIP等级推单')" prop="vipPushOrder">
          <el-select v-model="ruleForm.vipPushOrder" :placeholder="$t('请选择')">
            <el-option v-for="level in Levels" :key="level.id" :value="level.vipLevel" :label="`VIP${level.vipLevel}`" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('每日限制推送次数')" prop="pushOrderNum">
          <el-input v-model="ruleForm.pushOrderNum" />
        </el-form-item>
        <el-form-item :label="$t('VIP等级打赏')" prop="vipReward">
          <el-select v-model="ruleForm.vipReward" :placeholder="$t('请选择')">
            <el-option v-for="level in Levels" :key="level.id" :value="level.vipLevel" :label="`VIP${level.vipLevel}`" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('每日限制打赏次数')" prop="rewardNum">
          <el-input v-model="ruleForm.rewardNum" />
        </el-form-item>
        <el-form-item :label="$t('每日测试账号打赏次数')" prop="rewardNumTestUser">
          <el-input v-model="ruleForm.rewardNumTestUser" />
        </el-form-item>
        <el-form-item :label="$t('单次最高打赏金额')" prop="maxRewardAmount">
          <el-input v-model="ruleForm.maxRewardAmount"><template slot="append">￥</template></el-input>
        </el-form-item>
        <el-form-item :label="$t('跟单中奖分红上限')" prop="bonusLimit">
          <el-input v-model="ruleForm.bonusLimit"><template slot="append">%</template></el-input>
        </el-form-item>
        <el-form-item :label="$t('推单赔率下限')" prop="oddsLimit">
          <el-input v-model="ruleForm.oddsLimit" />
        </el-form-item>
        <el-form-item :label="$t('平台抽取分红金额')" prop="systemBonus">
          <el-input v-model="ruleForm.systemBonus"><template slot="append">%</template></el-input>
        </el-form-item>

        <h2>{{ $t('互动大厅&彩票聊天室') }}</h2>
        <el-form-item :label="$t('VIP等级发言')" prop="lotteryChatVipSpeak">
          <el-select v-model="ruleForm.lotteryChatVipSpeak" :placeholder="$t('请选择')">
            <el-option v-for="level in Levels" :key="level.id" :value="level.vipLevel" :label="`VIP${level.vipLevel}`" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('VIP等级发言发图')" prop="lotteryChatVipPicture">
          <el-select v-model="ruleForm.lotteryChatVipPicture" :placeholder="$t('请选择')">
            <el-option v-for="level in Levels" :key="level.id" :value="level.vipLevel" :label="`VIP${level.vipLevel}`" />
          </el-select>
        </el-form-item> -->
        <el-form-item :label="$t('文本字数限制')" prop="lotteryChatWordLimit">
          <el-input v-model.number="ruleForm.lotteryChatWordLimit" placeholder="0-9999" />
        </el-form-item>
        <h2>{{ $t('体育赛事聊天室') }}</h2>
        <el-form-item :label="$t('VIP等级发言')" prop="sportsChatVipSpeak">
          <el-select v-model="ruleForm.sportsChatVipSpeak" :placeholder="$t('请选择')">
            <el-option v-for="level in Levels" :key="level.id" :value="level.vipLevel" :label="`VIP${level.vipLevel}`" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('文本字数限制')" prop="sportsChatWordLimit">
          <el-input v-model.number="ruleForm.sportsChatWordLimit" placeholder="0-9999" />
        </el-form-item>
        <h2>{{ $t('直播聊天室') }}</h2>
        <el-form-item :label="$t('VIP等级发言')" prop="liveChatVipSpeak">
          <el-select v-model="ruleForm.liveChatVipSpeak" :placeholder="$t('请选择')">
            <el-option v-for="level in Levels" :key="level.id" :value="level.vipLevel" :label="`VIP${level.vipLevel}`" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('文本字数限制')" prop="liveChatWordLimit">
          <el-input v-model.number="ruleForm.liveChatWordLimit" placeholder="0-9999" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item class="action-button">
      <el-button type="danger" :loading="submitting" @click="handleSubmit">{{ $t('保 存') }}</el-button>
      <el-button @click="$refs['ruleForm'].resetFields()">{{ $t('重 置') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getAllVipLevels } from '@/api/app'
import { requiredBlur, requireIntegerNormal, requiredChange, requirePercent0_100, integer0_9999 } from '@/utils/validation.js'
import { getInteractiveConfig, editInteractiveConfig } from '@/api/config.js'
export default {
  name: 'InteractionTab',
  componentName: 'InteractionTab',
  data() {
    return {
      loading: false,
      submitting: false,
      Levels: [],
      ruleForm: {
        xsVipSend: '', // 发帖限制VIP等级
        xsVipReply: '', // 评论限制VIP等级
        xsSendNum: '', // 每日限制发贴次数
        xsReplyNum: '', // 每日限制评论次数
        lhcReplyNum: '', // 评论限制VIP等级
        lhcVipReply: '', // 每日限制评论次数
        circleVipSend: '', // 晒单圈 发言限制VIP等级
        circleVipReply: '', // 晒单圈 评论限制VIP等级
        circleSendNum: '', // 晒单圈 每日限制发送次数
        circleReplyNum: '', // 晒单圈 每日限制评论次数
        delCircleStatus: 1, // 晒单圈 用户删除帖子
        lotteryChatVipSpeak: null,
        lotteryChatWordLimit: null,
        liveChatVipSpeak: null,
        liveChatWordLimit: null,
        vipPushOrder: '', // 推单限制VIP等级
        pushOrderNum: '', // 每日限制推送次数
        vipReward: '', // 打赏限制VIP等级
        rewardNum: '', // 每日限制打赏次数
        rewardNumTestUser: '', // 每日测试账号打赏次数
        maxRewardAmount: '', // 单次最高打赏金额
        bonusLimit: '', // 跟单中奖分红上限
        oddsLimit: '', // 推单赔率下限
        systemBonus: '' // 平台抽取分红比例限制
      },
      rules: {
        xsVipSend: requiredChange,
        xsVipReply: requiredChange,
        xsSendNum: requireIntegerNormal,
        xsReplyNum: requireIntegerNormal,
        lhcReplyNum: requiredChange,
        lhcVipReply: requireIntegerNormal,
        circleVipSend: requiredChange,
        circleVipReply: requiredChange,
        circleSendNum: requireIntegerNormal,
        circleReplyNum: requireIntegerNormal,
        delCircleStatus: requiredBlur,
        lotteryChatVipSpeak: requiredChange,
        lotteryChatWordLimit: [
          { required: true, message: this.$t('请输入'), trigger: 'blur' },
          { pattern: integer0_9999, message: this.$t('请输入0-9999'), trigger: 'blur' }
        ],
        liveChatVipSpeak: requiredChange,
        liveChatWordLimit: [
          { required: true, message: this.$t('请输入'), trigger: 'blur' },
          { pattern: integer0_9999, message: this.$t('请输入0-9999'), trigger: 'blur' }
        ],
        vipPushOrder: requiredChange,
        pushOrderNum: requireIntegerNormal,
        vipReward: requiredChange,
        rewardNum: requireIntegerNormal,
        rewardNumTestUser: requireIntegerNormal,
        maxRewardAmount: requireIntegerNormal,
        bonusLimit: requirePercent0_100,
        oddsLimit: requirePercent0_100,
        systemBonus: requirePercent0_100
      }
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.loading = true
      getInteractiveConfig().then(([data, err]) => {
        this.loading = false
        if (!err) {
          if (data) this.ruleForm = data
          this.implementationFetched()
        }
      })
    },
    handleSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) this._updateConfig()
      })
    },
    _updateConfig() {
      this.submitting = true
      this.ruleForm.lotteryChatVipPicture = this.ruleForm.lotteryChatVipSpeak
      editInteractiveConfig(this.ruleForm).then(([_, err]) => {
        this.submitting = false
        if (!err) {
          this.$message.success(this.$t('保存成功'))
        }
      })
    },
    implementationFetched() {
      getAllVipLevels().then(([data, err]) => {
        this.Levels = data
      })
    }
  }
}
</script>
