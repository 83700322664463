var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "" + _vm.params.issue + _vm.$t("期") + " " + _vm.$t("投注详情"),
        top: "5vh",
        width: "65%",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.onClosed,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("cascader-select", {
            attrs: { options: _vm.playClass, label: false, size: "mini" },
            on: { change: _vm.onChangeSelect },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _c(
            "div",
            { staticClass: "betview-warp" },
            _vm._l(_vm.resource, function (item) {
              return _c(
                "div",
                { key: item.attrsTag, staticClass: "betview-warp-item" },
                [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.attrsName)),
                  ]),
                  _c("div", { staticClass: "amount" }, [
                    _vm._v(_vm._s(item.betAmount)),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("关闭"))),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }