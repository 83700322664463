<template>
  <div>
    <!-- {{ extras.msgType }} -->
    <!-- <div style="width: 400px">{{dealItem }}</div> -->

    <!-- 212,214 -->
    <template v-if="[214].includes(extras.msgType)">
      <el-card class="box-div" :body-style="{ padding: '0px' }">
        <div class="box-left" @click="getActivityReward(extras)">
          {{ taskTypeMap[extras.isDaily].name }}
        </div>
        <div
          class="setDisplay setPd"
          style="min-height: 30px"
          @click="getActivityReward(extras)"
        >
          <span>
            <span class="mainText mainSize setBold">{{
              extras.activityTitle
            }}</span>
          </span>
          <el-button
            v-if="dealItem.isReceiveAward != 1"
            type="danger"
            style="background-color: #c62430"
            round
          >
            {{ extras.isAchieveGoal == 1 ? "领取奖励" : "去完成" }}
          </el-button>
          <el-button
            v-else
            type="danger"
            style="background-color: #c62430"
            round
          >
            已完成
          </el-button>
        </div>
        <el-divider></el-divider>
        <div class="setDisplayCol setPd" @click="getActivityReward(extras)">
          <div
            v-if="extras[item.totalKey] != 0"
            v-for="(item, index) in typeMapList[extras.modeType]"
            :key="index"
          >
            <div class="smallText">{{ item.name }}</div>
            <div class="setPdTop setDisplay">
              <div style="width: 60%">
                <el-progress
                  :stroke-width="8"
                  stroke-linecap="butt"
                  :percentage="
                    extras[item.currentKey] / extras[item.totalKey] > 1
                      ? 100
                      : (extras[item.currentKey] / extras[item.totalKey]) *
                          100 || 0
                  "
                  :show-text="false"
                  color="#c62430"
                ></el-progress>
              </div>
              <div>
                <span style="color: #c62430"
                  >{{ extras[item.currentKey] * 1 }} /
                  {{ extras[item.totalKey] * 1 }}</span
                >
              </div>
              <div class="setfont">
                {{ parseTime(item.timestamp, "{y}-{m}-{d} {h}:{i}") }}
              </div>
            </div>
          </div>
          <div
            style="margin: 10px 0px; justify-content: start"
            class="setDisplay"
          >
            <div class="smallText">任务奖励:</div>
            <div class="coulist">
              <div v-if="extras.couponTitles">
                <span class="title">抵扣券</span>
                <span class="val">{{ extras.couponTitles }}</span>
              </div>
              <div
                v-if="
                  extras.amountRolling &&
                  !!Number(extras.amountRolling.split(',')[0])
                "
              >
                <span class="title">彩金</span>
                <span class="val"
                  >+{{ extras.amountRolling.split(",")[0] }}</span
                >
              </div>
              <div v-if="extras.fullCouponTitles">
                <span class="title">全勤抵扣券</span>
                <span class="val">{{ extras.fullCouponTitles }}</span>
              </div>
              <div
                v-if="
                  extras.fullAmountRolling &&
                  !!Number(extras.fullAmountRolling.split(',')[0])
                "
              >
                <span class="title">全勤彩金</span>
                <span class="val"
                  >+{{ extras.fullAmountRolling.split(",")[0] }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </template>
    <!-- 207-211 -->
    <el-card v-else class="box-card">
      <div
        slot="header"
        class="clearfix"
        style="padding-bottom: 5px"
        @click="jumpAndSearch(extras)"
      >
        <span class="mainText mainSize fontBold fl">{{
          titleMap[extras.msgType]
        }}</span>
        <!-- 彩票 -->
        <span v-if="[207].includes(extras.msgType)" class="smallText fr">
          {{ lotteryStatusStr(extras) }}
        </span>
        <!-- 足彩 -->
        <span v-if="[208].includes(extras.msgType)" class="smallText fr">
          <!-- 9b体育 -->
          <template v-if="extras.orderType == 1502">{{
            item.orderStatus == "0"
              ? "未结算"
              : getBetResultName(item.orderStatus, item.profitAmount)
          }}</template>
          <!-- FB体育 -->
          <template v-if="extras.orderType == 2700">{{
            !item.settleAmount ? "未结算" : item.settleAmount >= 0 ? "赢" : "输"
          }}</template>
          <!-- sb体育 -->
          <template v-if="extras.orderType == 2000">{{
            getStatusName(item.ticketStatus)
          }}</template>
          <!-- 竞彩体育 -->
          <template v-if="extras.orderType == 99">{{
            statusMap[extras.status]
          }}</template>
          <!-- IM体育 -->
          <template v-if="extras.orderType == 2200">{{
            item.resultType == 0 || !item.resultType
              ? "未结算"
              : item.resultType == 1
              ? "已结算"
              : "已取消"
          }}</template>
        </span>
        <!-- 第三方 -->
        <span v-if="[209].includes(extras.msgType)" class="smallText fr">
          {{ extras.profit> 0 ? "赢" : "输" }}
        </span>
        <!-- 充值,提现 -->
        <span v-if="[210, 211].includes(extras.msgType)" class="smallText fr">
          {{
            extras.msgType == 210
              ? statusRechange[extras.status]
              : statusWithDrawal[extras.status]
          }}
        </span>
        <span
          v-if="![207, 208, 209, 210, 211].includes(extras.msgType)"
          class="smallText fr"
        >
          {{ extras.statusDesc }}
        </span>
      </div>

      <div
        class="setDisplay"
        @click="jumpAndSearch(extras)"
        v-for="item in getResult(extras.msgType, extras, dealItem)"
      >
        <span class="smallText">{{ item.name }}</span>
        <div>
          <span class="mainText smallSize"> {{ item.value }}</span>
          <i
            v-if="item.needCopy"
            class="el-icon-document-copy smallSize"
            @click.stop="clipboard(item.needCopy)"
          ></i>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
// 彩票注单 207
// 足彩注单 208
// 第三方注单 209
// 充值订单 210
// 提现订单 211
// 任务分享 212
// 富文本-定时消息 213
// 任务分享-1v1客服弹框任务分享 214
// 在线客服快速问列表 215(1v1客服,客服举例列表)
// 在线客服快速问 216(1v1客服,用户问)
// 在线客服快速回答 217(1v1客服,客服回答)
// 在线客服公告 218（1v1客服，公告）
// 在线客服公告 219（1v1客服，欢迎）
import { MessageBox, Message } from "element-ui";
import getResult from "../getStatusByMsgType";
import { parseTime } from "@/utils";

export default {
  name: "CardItems",
  components: {},
  props: {
    permission: {
      type: Object,
      default: () => ({}),
    },
    showMessage: {},
    item: {
      type: Object,
      default: () => ({}),
    },
    me: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getResult,
      parseTime,
      listData: [],
      taskTypeMap: {
        0: {
          name: "周任务",
          className: "day",
        },
        1: {
          name: "日任务",
          className: "day",
        },
        2: {
          name: "新手任务",
          className: "day",
        },
        3: {
          name: "长期任务",
          className: "day",
        },
      },
      titleMap: {
        207: "彩票注单",
        208: "足球注单",
        209: "第三方注单",
        210: "充值订单",
        211: "提现订单",
        212: "任务分享",
        213: "定时消息",
        214: "任务分享2-1v1客服弹框任务分享",
      },

      statusMap: {
        // 彩票
        1: "待扣款",
        2: "待开奖",
        5: "未中奖",
        6: "已中奖",
        7: "打和",
        10: "已撤单",
        11: "处理失败",
        12: "扣款失败",
        20: "异常订单",
      },
      statusWithDrawal: {
        // 提现
        0: "待扣款",
        1: "待处理",
        2: "处理中",
        3: "拒绝",
        4: "成功",
        5: "核对中",
        6: "批量处理中",
        7: "代付中",
        8: "代付成功",
        9: "代付失败",
      },
      statusRechange: {
        // 充值
        "0000": "充值成功",
        1111: "审核中",
        2222: "充值失败",
        3333: "审核成功",
      },
      statusThird: {},
      dealItem: {},
      extras: {},
      typeMapList: {
        0: [
          {
            name: "充值金额",
            currentKey: "dailyRecharge",
            totalKey: "rechargeAmount",
          },
          {
            name: "充值单数",
            currentKey: "dailyRechargeTimes",
            totalKey: "rechargeTimes",
            unit: "单",
          },
        ],
        1: [
          {
            name: "投注金额",
            currentKey: "memberBetsAmount",
            totalKey: "betsAmount",
          },
          {
            name: "投注单数",
            currentKey: "memberBetsTimes",
            totalKey: "betsTimes",
            unit: "单",
          },
        ],
        2: [
          {
            name: "邀请人数",
            currentKey: "memberInviteNums",
            totalKey: "inviteNums",
            unit: "人",
          },
        ],
        3: [
          {
            name: "观看时长",
            currentKey: "memberLiveSeeMinites",
            totalKey: "liveSeeMinites",
            unit: "分钟",
          },
          {
            name: "送礼金额",
            currentKey: "memberLiveGiftAmount",
            totalKey: "liveGiftAmount",
          },
          {
            name: "房费金额",
            currentKey: "memberLiveRoomAmount",
            totalKey: "liveRoomAmount",
          },
          {
            name: "发送消息",
            currentKey: "memberLiveMsgNums",
            totalKey: "liveMsgNums",
            unit: "条",
          },
        ],

        4: [
          {
            name: "跟单金额",
            currentKey: "memberFollowBetsAmount",
            totalKey: "followBetsAmount",
          },
          {
            name: "跟单单数",
            currentKey: "memberFollowBetsTimes",
            totalKey: "followBetsTimes",
            unit: "单",
          },
        ],
        5: [
          {
            name: "发帖数量",
            currentKey: "memberPostsNums",
            totalKey: "postsNums",
            unit: "个",
          },
          {
            name: "评论数",
            currentKey: "memberPostsCommentNums",
            totalKey: "postsCommentNums",
            unit: "条",
          },
          {
            name: "点赞数",
            currentKey: "memberPostsLikeNums",
            totalKey: "postsLikeNums",
            unit: "个",
          },
        ],
      },
      // 0 充值任务 1投注任务 2邀请任务 3直播任务 4跟单任务 5发帖任务 6绑定任务 7提现任务
      modeType: {
        0: "充值任务",
        1: "投注任务",
        2: "邀请任务",
        3: "直播任务",
        4: "跟单任务",
        5: "发帖任务",
        6: "绑定任务",
        7: "提现任务",
      },
    };
  },
  computed: {
    lotteryStatusStr() {
      return (extras) => {
        if (extras.status == 10) {
          return "已取消";
        } else if (extras.openStatus == 1) {
          return "未结算";
        } else if (extras.profit > 0) {
          return "赢";
        } else if (extras.profit < 0) {
          return "输";
        }
      };
    },
  },

  watch: {
    item: {
      immediate: true,
      handler(val) {
        this.dealItem = val;
        if (this.dealItem?.extras) {
          JSON.parse(val.extras);
          this.extras = JSON.parse(val.extras);
        }
      },
    },
  },
  // 方法集合
  methods: {
    GetPercent(num, total) {
      num = parseFloat(num);
      total = parseFloat(total);
      if (isNaN(num) || isNaN(total)) {
        return "-";
      }
      return total <= 0 ? 0 : Math.round((num / total) * 10000) / 100.0;
    },
    _doAction(type) {
      this.$emit("_doAction", type);
    },
    // 复制到剪切板
    clipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        // 成功提示
        this.$message.success("复制成功");
      });
    },
    // 任务跳转和搜索
    getActivityReward(data) {
      if (!this.$props.permission?.JumpTask) {
        MessageBox.confirm(
          "你暂无权限进行此操作，请联系系统管理员，或者选择其他账号登录",
          "系统提示",
          {
            confirmButtonText: "重新登入",
            type: "warning",
          }
        )
          .then(() => {})
          .catch(() => {});
      } else {
        this.$router.push({ name: "ActivityTask", query: { id: data.id } });
      }
    },
    // 注单跳转和搜索
    jumpAndSearch(extras) {
      switch (extras.msgType) {
        case 207:
          this.$router.push({
            name: "LotteryOrders",
            query: { orderNo: extras.orderNo, startTime: extras.betTime },
          });
          break;
        case 208:
          switch (extras.orderType) {
            case 1502:
              this.$router.push({
                name: "ThirdOrders",
                query: {
                  orderNo: extras.orderNo,
                  startTime: extras.orderTime,
                  lotteryName: "9B体育",
                },
              });
              break;
            case 2000:
              this.$router.push({
                name: "ThirdOrders",
                query: {
                  orderNo: extras.transId,
                  startTime: extras.transactionTime,
                  lotteryName: '沙巴体育'
                },
              });
              break;
            case 2200:
              this.$router.push({
                name: "ThirdOrders",
                query: {
                  orderNo: extras.betId,
                  startTime: extras.lastUpdatedDate,
                  lotteryName: 'IM体育'
                },
              });
              break;
            case 99:
              this.$router.push({
                name: "FootballOrders",
                query: {
                  orderNo: extras.orderNo,
                  startTime: extras.createTime,
                },
              });
              break;
            case 2700:
              this.$router.push({
                name: "ThirdOrders",
                query: {
                  orderNo: extras.orderId,
                  startTime: extras.createTime,
                  lotteryName: 'FB体育'
                },
              });
              break;
            default:
              this.$router.push({
                name: "FootballOrders",
                query: {
                  orderNo: extras.orderNo,
                  startTime: extras.createTime,
                },
              });
              break;
          }
          break;
        case 209:
          this.$router.push({
            name: "ThirdOrders",
            query: { orderNo: extras.orderNo, startTime: extras.etime, lotteryName: extras.lotteryName },
          });
          break;
        case 210:
          this.$router.push({
            name: "CashDeposit",
            query: {
              tab: "ListTab",
              orderNo: extras.orderNo,
              startTime: extras.updateTime,
            },
          });
          break;
        case 211:
          this.$router.push({
            name: "CashWithdrawal",
            query: {
              tab: "ListTab",
              orderNo: extras.orderNo,
              startTime: extras.updateTime,
            },
          });
          break;
        default:
          break;
      }
    },
    // 9B足球状态
    getBetResultName(s, profitAmount) {
      //注单状态 0未结算 1已结算 2取消(人工) 3待确认 4风控拒单 5 撤单(赛事取消) 新逻辑
      switch (s) {
        case 3:
          return "确认中";
        case 2:
          return "注单取消";
        case 4:
          return "风控拒单";
        case 5:
          return "赛事取消";
        case 1:
          if (profitAmount > 0) {
            return "赢";
          } else if (profitAmount < 0) {
            return "输";
          } else {
            return "和";
          }

        default:
          return t("RecordListFootball.未结算");
      }
    },
    // sb体育状态
    getStatusName(ticketStatus) {
      //安卓判断逻辑
      if (ticketStatus == "waiting") {
        return "等待中";
      }
      if (ticketStatus == "running") {
        return "进行中";
      }
      if (ticketStatus == "void") {
        return "作废";
      }
      if (ticketStatus == "refund") {
        return "退款";
      }
      if (ticketStatus == "reject") {
        return "已取消";
      }
      if (ticketStatus == "lose") {
        return "输";
      }
      if (ticketStatus == "won") {
        return "赢";
      }
      if (ticketStatus == "draw") {
        return "打和";
      }
      if (ticketStatus == "half won") {
        return "赢一半";
      }
      if (ticketStatus == "half lose") {
        return "输一半";
      }
      //val ticketStatus: String? = null,//注单状态
      //※half won/half
      //lose/won/lose/void/running/draw/reject/refund/waiting
      //d/waiting
      //waiting(等待中):
      //我们交易员因为可能因为赔率的转换等因
      //素，还未接受这张注单。
      //running(进行中):
      //此注单还没有结算。（注单还没有结算的状态
      //有可能是这场比赛还没有结算之类的情形.）
      //void(作废):
      //在注单为 running 的状态下，玩家下注注金
      //返回。原因可能为我们交易员对此场赛事有
      //些疑虑。可与我们联系询问发生什么状况。
      //refund(退款):
      //在注单为 running 的状态下，玩家下注注金
      //返回。原因有可能是赛事取消或发生什么意
      //外。
      //reject(已取消):
      //在注单为 waiting 的状态下，玩家下注注金
      //返回。可能状况很多。
      //lose(输):
      //此注单已结算且玩家输了此注单。
      //won(赢):
      //此注单已结算且玩家赢了此注单。
      //draw(和局):
      //此注单已结算且此注单为和局。
      //half won(半赢):
      //此注单已结算且玩家赢了下注额一半。
      //half lose(半输):
      //此注单已结算且玩家输了下注额一半。
    },
  },
};
</script>

<style lang="scss" scoped>
.coulist {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;

  .title {
    color: #333;
  }

  .val {
    font-size: 16px;
    margin-left: 10px;
    color: #c62430;
  }
}
.setfont {
  margin-top: 10px;
}
.box-div {
  min-height: 140px;
}
</style>
