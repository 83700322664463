<template>
  <div class="tenant-function">
    <el-tabs v-model="active" type="card">
      <el-tab-pane label="彩种配置" name="Lottery">
        <Lottery ref="TenantFunctionLottery" />
      </el-tab-pane>
      <el-tab-pane label="功能配置" name="Function">
        <Function ref="TenantFunctionFunction" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getLotteryStatusList } from '@/api/tenant'
import { Lottery, Function } from './functions'
export default {
  components: {
    Lottery,
    Function
  },
  data() {
    return {
      active: 'Lottery'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.TenantFunctionLottery.fetch(getLotteryStatusList)
    })
  },
  methods: {
    onValidate(cb) {
      return new Promise(resolve => {
        this.$refs.TenantFunctionLottery.onValidate(cb).then(() => {
          this.$refs.TenantFunctionFunction.onValidate(cb).then(() => {
            resolve()
          }).catch(() => {
            this.active = 'Function'
          })
        }).catch(() => {
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tenant-function {
  padding: 50px 20px 20px;
}
</style>
