<template>
  <el-dialog :title="$t('编辑')" :visible.sync="visible" :close-on-click-modal="false" width="800px"
    @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="130px" label-suffix=":">
      <el-form-item label-width="130px" :label="$t('VIP等级')">
        <span>{{ ruleForm.vipLevel }}</span>
      </el-form-item>
      <el-form-item label-width="130px" :label="$t('VIP福利')">
        <el-checkbox-group v-model="ruleForm.checkList">
          <el-checkbox :label="item.awardCode" v-for="(item, index) in allRewardList" :key="index"
            @change="onCheckBoxChanged">
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <div v-for="(item, index) in ruleForm.awardList" :key="index">
        <template v-if="item.isShow">
          <!-- 默认渲染 -->
          <div v-if="!isShowZhbYhb(item)" class="default-item">
            <el-form-item :label="item.name" :prop="'awardList.' + index + '.value'" :rules=getvalueRules(item)>
              <span class="str1" v-if="item.firstStr" v-html="item.firstStr"></span>
              <!-- 生日礼金 -->
              <template v-if="isShowSrlj(item)">
                <el-switch v-model="item.open"  active-text="开启"
                inactive-text="关闭" style="margin-right: 10px"></el-switch>
              </template>
              <!-- 生日礼金 end -->
              <el-input v-if="hideValueItem(item)" v-model="item.value" placeholder="请输入" clearable class="filter-item" @blur="handleBlur(item)"
                style="width: 150px">{{ item.unit }}</el-input>
            </el-form-item>
            <span class="str2" v-if="item.secondStr" v-html="item.secondStr"></span>
            <el-form-item label="" label-width="50px" :prop="'awardList.' + index + '.bgColor'" >
              <el-input v-model="item.bgColor" placeholder="色号" clearable class="filter-item"
                style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="图片上传"  :prop="'awardList.' + index + '.bgImage'">
              <d-upload v-model="item.bgImage" class="com-upload" folder="icon" :on-success="(...args) => handleUploadSuccess(args, item,index)"
                text="上传"></d-upload>
            </el-form-item>
          </div>
          <!-- 周红包、月红包渲染 -->
          <div class="week-month-red-envelope" v-else>
            <el-form-item :label="item.name" :prop="'awardList.' + index + '.value'" :rules=getvalueRules(item)>
              <el-input v-model="item.value" placeholder="请输入" clearable style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="有效投注" :prop="'awardList.' + index + '.betsLimit'">
              <el-input v-model="item.betsLimit" placeholder="请输入" clearable style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="有效充值" :prop="'awardList.' + index + '.rechargeLimit'">
              <el-input v-model="item.rechargeLimit" placeholder="请输入" clearable style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="文案色值" :prop="'awardList.' + index + '.bgColor'">
              <el-input v-model="item.bgColor" placeholder="请输入" clearable style="width: 150px"></el-input>
            </el-form-item>
            <el-form-item label="图片上传"  :prop="'awardList.' + index + '.bgImage'" >
            <d-upload v-model="item.bgImage" class="com-upload" folder="icon" :on-success="(...args) => handleUploadSuccess(args, item,index)"
              text="上传"></d-upload>
              </el-form-item>
          </div>
        </template>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import DUpload from '@/components/DUpload'
import {  EditVipReward, getAwardConfig } from "@/api/member";
import { ruleForm } from '@/views/agent-rebate/Config/pageData';
import { rewardTypes } from "@/views/vipRewardMange/constant";
const getDefaultForm = () => ({
  vipLevel: '',
  awardList: [],
  checkList: []
})
const validatePositiveNumberWithTwoDecimals = (rule, value, callback) => {
  if (value === null || value === '') {
    callback(new Error('该项不能为空'));
    return;
  }

  const num = Number(value);
  if (isNaN(num) || num <= 0) {
    callback(new Error('请输入一个正数'));
    return;
  }

  const decimalPart = (value.toString().split('.')[1] || '').length;
  if (decimalPart > 2) {
    callback(new Error('最多只能有两位小数'));
    return;
  }

  callback();
};
function validateHexColor(rule, value, callback) {
  const hexColorRegex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
  if (value === null || value === '') {
    callback(new Error('请输入色号'));
    return;
  }
  if (!hexColorRegex.test(value)) {
    callback(new Error('请输入有效的16进制颜色色值'));
  } else {
    callback();
  }
}


export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm)],
  props: {
    allRewardList: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      dataAwardList:[],
      checkList: [],
      filteredAwardList: [],
      emitName: 'create',
      fileds:{}
    }
  },
  computed: {
    rules(){
      return {
        awardList: this.allRewardList.map((item, index) => ( {
        // value: [
        //   { required: true, validator: this.getValidatorFn(item), trigger: 'blur' },
        // ],
        bgColor:[
          {required: true,validator: validateHexColor, trigger: 'blur' }
        ],
        bgImage:[
          {required: true, message: '请上传图片', trigger: ['blur', 'change'] }
        ],
        betsLimit: [
          { required: true, validator: validatePositiveNumberWithTwoDecimals, trigger: 'blur' }
        ],
        rechargeLimit: [
          { required: true, validator: validatePositiveNumberWithTwoDecimals, trigger: 'blur' }
        ]
      }))
      }
    }
  },

  methods: {
    getvalueRules(item) {
      const validator=  rewardTypes.includes(item.awardCode)? validatePositiveNumberWithTwoDecimals : null
      const required = rewardTypes.includes(item.awardCode)
      return [{required, validator, trigger: 'blur'}]
    },
    
    isShowZhbYhb(item) {
      return ["WEEK_RED_ENVELOPE","MONTH_RED_ENVELOPE"].includes(item.awardCode)
    },
    hideValueItem(item){
      return "WITHDRAWAL_LIMIT"!==item.awardCode
    },
    setFirstStr(item) {
      const content = JSON.parse(item.content)
      const transfer = {
        "WITHDRAWAL_LIMIT":`每日最高${content?.withdrawRequest}`
      }
      return transfer[item.awardCode] || ""
    },
    isShowSrlj(item){
      return item.awardCode === "BIRTHDAY_RED_ENVELOPE"
    },
    setSecondStr(item) {
      console.log("setSecondStr", item);
      const content = JSON.parse(item.content)
      const maxBackRate = content?.maxBackRate || ''
      const cc = maxBackRate&&content?.value?maxBackRate/content?.value:''
      const transfer = {
        "GAME_REBATE":`倍  最高返水配置${cc*100}%,当前等级最高返水${maxBackRate*100}%`,
        "POINTS_COLLECTION":`倍 充值一元获得${content?.rechargePoints}积分，投注一元获得${content?.betPoints}积分`
      }
      return transfer[item.awardCode] || ""
    },
    handleBlur(item){
      console.log('handleBlur', item);
      if(item.awardCode !== "GAME_REBATE") return
         const maxBack = this.fileds?.maxBack || ''
         const currentLevelMax = maxBack&&item?.value?(maxBack*item?.value).toFixed(4):''
         item.secondStr = `倍  最高返水配置${maxBack*100}%,当前等级最高返水${currentLevelMax*100}%`
    },
    initFilteredAwardList() {
     
      this.filteredAwardList = (this.dataAwardList || []).map(item => {
        let obj, value, bgImage, bgColor,rechargeLimit,betsLimit,open;
        try {
          obj = JSON.parse(item.content)
          value = obj?.value
          bgImage = obj?.bgImage
          bgColor = obj?.bgColor
          rechargeLimit = obj?.rechargeLimit
          betsLimit = obj?.betsLimit
          open = obj.open
        } catch (error) {
          value = item.content
        }
        return {
          ...item,
          originValue: value,
          value, bgImage, bgColor,
          isShow: true,
          betsLimit,
          rechargeLimit,
          open,
          firstStr:this.setFirstStr(item),
          secondStr:this.setSecondStr(item)
        }
      })
      this.ruleForm.awardList = this.filteredAwardList
      console.log("@@@@this.filterdAwardList", ...this.filteredAwardList);
    },
    onSubmit() {
      console.log('@@@@onSubmit', this.filteredAwardList);
      EditVipReward({ ...this.filteredAwardList }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('编辑成功'))
        } else {
          cancel()
        }
      })
    },
    onCheckBoxChanged() {
      this.ruleForm.checkList.forEach((code) => {
        let isExist = this.filteredAwardList.some(item => item.awardCode == code)
        if (!isExist) {
          this.filteredAwardList.push(
            {
              ...this.allRewardList.find((reward) => reward.awardCode == code)
              , isShow: true
            }
          )
        }
      })
      this.filteredAwardList.forEach((reward) => {
        if (!this.ruleForm.checkList.includes(reward.awardCode)) {
          reward.isShow = false
        } else {
          reward.isShow = true
        }
      })
      this.filteredAwardList.sort((a, b) => {
        const indexA = this.allRewardList.findIndex(reward => reward.awardCode === a.awardCode);
        const indexB = this.allRewardList.findIndex(reward => reward.awardCode === b.awardCode);
        return indexA - indexB;
      });
      console.log('oncheckboxchanged', this.filteredAwardList);
      
        this.filteredAwardList.forEach(item => {
          if(["GAME_REBATE","POINTS_COLLECTION"].includes(item.awardCode)){
            const content = JSON.parse(item.content||'{}')
          const maxBack = this.fileds?.maxBack || ''
          const currenntLevelMax = maxBack&&content?.value?maxBack *content?.value:''
          const transfer = {
            "GAME_REBATE":`倍  最高返水配置${maxBack*100}%,当前等级最高返水${currenntLevelMax*100}%`,
            "POINTS_COLLECTION":`倍 充值一元获得${this.fileds?.rechargePoints}积分，投注一元获得${this.fileds?.betPoints}积分`
          }
         
            this.$set(item,'secondStr',transfer[item.awardCode])
          }
          if(item.awardCode === "WITHDRAWAL_LIMIT"){
            this.$set(item,'firstStr',`每日最高${this.fileds.vipWithDrawlAmount}`)
          }
        })
        
      
    },
    validateNum(rule, value, callback) {
      if (value === null) {
        callback()
      } else if (!/^(\d{0,})$|(\d{0,}(\.\d{1,3}))$/.test(value) || value > 1000000) {
        callback(new Error('请输入有效数字!'))
      } else {
        callback()
      }
    },
    async getAwardFieldConfigData() {
      const params = {vipLevel: this.ruleForm.vipLevel}
     const [data, err] = await getAwardConfig(params)
        if (!err) {
          this.fileds = data?JSON.parse(data):{}
        }
   
    },
    handleRefresh() {
      this.$emit('refresh')
    },
    async open(row) {
      if (row) {
        // Object.keys(this.ruleForm).forEach(k => {
        //   this.ruleForm[k] = row[k]
        // })
        this.ruleForm.vipLevel=row?.vipLevel
        this.dataAwardList=row?.awardList
        console.log('@@@@awardList', row?.awardList);
        //根据row.awardList渲染checkList初始状态
        this.ruleForm.checkList = (row?.awardList || []).map(item => item.awardCode)
        await this.getAwardFieldConfigData()
        this.initFilteredAwardList()
      
      }
      this.visible = true
    },
    handleUploadSuccess([[data]], item,index) {
      console.log('handleUploadSuccess', data, item)
    
      this.$set(item, 'bgImage', data)
      this.$refs.validateForm.validateField('awardList.' + index + '.bgImage')
    },
  }
}
</script>

<style lang="scss" scoped>
.icon-uploader {
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409EFF;
    }
  }

  .uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 105px;
    height: 45px;
    line-height: 45px;
    text-align: center;
  }

  .icon {
    width: 105px;
    height: 45px;
    display: block;
  }
 
}
 
  .str1,.str2{
    width: 265px;
    height: 32px;
    line-height: 32px;
    margin:0 10px;
    white-space: nowrap;
  }
  .str2{
    margin-left: 0;
  }
  .default-item{
    display: flex;
    flex-wrap: wrap;
  }
  .week-month-red-envelope{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    
  }
</style>
