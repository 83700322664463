import { rateTypes } from '@/libs/options'
import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('图标'),
      key: 'icon',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image style='width: 60px; height: 60px' lazy src={row.icon} />)
        }
      }
    },
    {
      title: this.$t('银行'),
      key: 'name',
      align: 'center'
    },
    {
      title: this.$t('币种'),
      key: 'rateType',
      align: 'center',
      component: {
        render: (h, row) => {
          const rateType = rateTypes.find(v => v.key === row.rateType)
          let rateTypeStr = ''
          if (rateType) {
            rateTypeStr = rateType.label
          }
          return (
            <span>{ rateTypeStr }</span>
          )
        }
      }
    },
    {
      title: this.$t('状态'),
      key: 'binding',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.binding}
              before-change={ () => this._editStatus(row) }
              active-value={1}
              inactive-value={0}
              active-color='#3894FF'
              inactive-color='#F26161'
            >
              <span slot='close'>{this.$t('停用')}</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      component: {
        render: (h, row) => {
          const { Delete, Update } = this.permission
          return (
            <span>
              { Update && <el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={ () => this.openBankDialog(row) }>{this.$t('编辑')}</el-button> }
              { Delete && <el-button type='danger' size='mini' icon='el-icon-delete' onClick={ () => this._delete(row.id, row.name) }>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
