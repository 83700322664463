var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "310px" },
            attrs: {
              maxlength: "20",
              placeholder: _vm.$t("订单号"),
              clearable: "",
            },
            model: {
              value: _vm.search.id,
              callback: function ($$v) {
                _vm.$set(_vm.search, "id", $$v)
              },
              expression: "search.id",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "310px" },
            attrs: {
              maxlength: "20",
              placeholder: _vm.$t("会员账号"),
              clearable: "",
            },
            model: {
              value: _vm.search.memberName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "memberName", $$v)
              },
              expression: "search.memberName",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "310px" },
            attrs: {
              maxlength: "20",
              placeholder: _vm.$t("商品名称"),
              clearable: "",
            },
            model: {
              value: _vm.search.productName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "productName", $$v)
              },
              expression: "search.productName",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: { placeholder: _vm.$t("商品类型"), clearable: "" },
              model: {
                value: _vm.search.productType,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "productType", $$v)
                },
                expression: "search.productType",
              },
            },
            _vm._l(_vm.productType, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { value: item.key, label: item.label },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: { placeholder: _vm.$t("订单状态"), clearable: "" },
              model: {
                value: _vm.search.status,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "status", $$v)
                },
                expression: "search.status",
              },
            },
            _vm._l(_vm.goodsOrderStatus, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { value: item.key, label: item.label },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleSearch },
            },
            [_vm._v(_vm._s(_vm.$t("查询")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-refresh-right" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("重置")))]
          ),
        ],
        1
      ),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          options: _vm.options,
          "filter-column": _vm.filterColumn,
        },
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "发货", visible: _vm.dialogVisible, width: "450px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "收货地址：",
                    "label-width": _vm.formLabelWidth,
                    prop: "deliveryAddress",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("请输入收货地址"),
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.deliveryAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deliveryAddress", $$v)
                      },
                      expression: "form.deliveryAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "快递公司：",
                    "label-width": _vm.formLabelWidth,
                    prop: "deliveryProvider",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("请输入快递公司名称"),
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.deliveryProvider,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deliveryProvider", $$v)
                      },
                      expression: "form.deliveryProvider",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "订单编号：",
                    "label-width": _vm.formLabelWidth,
                    prop: "deliveryOrder",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("请输入订单编号"),
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.deliveryOrder,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deliveryOrder", $$v)
                      },
                      expression: "form.deliveryOrder",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm("form")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DetailDialog", { ref: "detailDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }