const CanChooseColumns = function() {
  return [
    {
      title: '管理员账号',
      key: 'operatorName',
      align: 'center',
      width: 100
    },
    {
      title: '操作类型',
      key: 'type',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return <span>{ this.operatorTypes[row.type] }</span>
        }
      }
    },
    {
      title: '操作名称',
      key: 'target',
      align: 'center'
    },
    {
      title: '操作内容',
      key: 'content',
      align: 'center',
      width: 260,
      component: {
        render: (h, row) => {
          return (
            <el-tooltip
              placement='right'
              content={row.content}
              popper-class='popper-class'
            >
              <span>{ (row.content && row.content.length > 120) ? row.content.substr(0, 120) + '...' : row.content }</span>
            </el-tooltip>
          )
        }
      }
      // component: {
      //   render: (h, row) => {
      //     if (row.content && row.content.length > 200) {
      //       return <span title={ row.content }>{ row.content.substr(0, 200) + '...' }</span>
      //     } else {
      //       return <span>{ row.content }</span>
      //     }
      //   }
      // }
    },
    {
      title: '操作来源',
      key: 'serverId',
      align: 'center'
    },
    {
      title: '渠道来源',
      key: 'channel',
      align: 'center'
    },
    {
      title: '操作时间',
      key: 'createTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return <span>{ this.$parseTime(row.createTime) }</span>
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
