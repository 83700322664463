import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
    },
    {
      title: this.$t('变动类型'),
      key: 'changeType',
      align: 'center',
      component: {
        render: (h, row) => {
          switch (row.changeType) {
            case 1:
              return <span>投注</span>
              break
            case 2:
              return <span>充值</span>
              break
            case 3:
              return <span>任务领取</span>
              break
            default:
              return <span>商城兑换</span>
          }
        }
      }
    },
    {
      title: this.$t('变动说明'),
      key: 'sourceType',
      align: 'center',
    },
    {
      title: this.$t('变动积分'),
      key: 'changeQuantity',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span class={this.$parseColor(row.changeQuantity)}>{ row.changeQuantity }</span>)
        }
      }
    },
    {
      title: this.$t('变动前积分'),
      key: 'changeBefore',
      align: 'center'
    },
    {
      title: this.$t('变动后积分'),
      key: 'changeAfter',
      align: 'center'
    },
    {
      title: this.$t('变动时间'),
      key: 'updateTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('订单号'),
      key: 'sourceId',
      align: 'center',
    },
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
