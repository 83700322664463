<template>
  <el-dialog :title="$t('活动回收')" :visible.sync="visible" top="5vh" custom-class="custom-dialog" width="600px" @open="onOpen" @closed="handleClosed">
    <el-table v-loading="loading" :data="items" border size="small">
      <el-table-column property="type" align="center" :label="$t('类型')" />
      <el-table-column property="acount" align="center" :label="$t('金额')" />
      <el-table-column property="member" align="center" :label="$t('人数')" />
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">
        {{ $t('关闭') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import { getRobotReturnReportDetail } from '@/api/statistical'
import { $t } from '@/lang'

const columns = [
  { type: $t('机器人分红'), key: 'robotBonus' },
  { type: $t('机器人被打赏'), key: 'robotReward' },
  { type: $t('跟单分红抽成'), key: 'platformBonus' },
  { type: $t('合计'), key: 'robotIncome' }
]

export default {
  mixins: [VDialogMixin],
  data() {
    return {
      params: {},
      items: [],
      loading: false
    }
  },
  methods: {
    open(startTime) {
      this.params = {
        startTime: this.$parseTime(startTime, '{y}-{m}-{d}')
      }
      this.visible = true
    },
    onOpen() {
      this.loading = true
      getRobotReturnReportDetail(this.params).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.items = columns.map(o => ({
            type: o.type,
            acount: data[o.key],
            member: data[`${o.key}Member`]
          }))
        }
      })
    },
    handleClosed() {
      this.loading = false
      this.items = []
    }
  }
}
</script>
