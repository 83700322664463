import { filterColumns } from '@/utils'
import { parseTime } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('账号'),
      key: 'memberName',
      align: 'center',
      width: 160
    },
    {
      title: this.$t('VIP等级'),
      key: 'vipLevel',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (<span>VIP{row.vipLevel}</span>)
        }
      }
    },
    {
      title: this.$t('登录设备'),
      key: 'device',
      align: 'center'
    },
    {
      title: this.$t('登录域名'),
      key: 'h5ReferDomain',
      align: 'center'
    },
    {
      title: this.$t('版本号'),
      key: 'version',
      align: 'center'
    },
    {
      title: this.$t('IP地址'),
      key: 'loginIp',
      align: 'center'
    },
    {
      title: this.$t('登录地区'),
      key: 'address',
      align: 'center'
    },
    {
      title: this.$t('登录时间'),
      key: 'loginTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return <span>{ parseTime(row.loginTime) }</span>
        }
      }
    },
    {
      title: this.$t('登录结果'),
      key: 'loginResultDesc',
      align: 'center'
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
