export default {
  data() {
    return {
      staticInfo: null // 统计信息
    }
  },
  methods: {
    getSummaries(param) { // 表格下方的合计
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('总计')
          return
        }
        if (this.staticInfo) {
          for (let i in this.staticInfo) {
            if (this.staticInfo[i].columnName === column.property) {
              sums[index] = this.staticInfo[i].val
              break
            } else {
              sums[index] = '--'
            }
          }
        }
      })
      return sums
    }
  }
}

