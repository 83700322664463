import { addVersion, cancelPublishVersion } from '@/api/version'
export default {
  methods: {
    onAction() {
      this.$refs.ActionDialogControl.open(this.search.appType, this.search.tenantCode)
    },
    _handleCreate({ field, cancel, close }) {
      addVersion({
        ...field,
        tenantCode: this.search.tenantCode,
        servicePlatform: this.search.servicePlatform
      }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success('创建成功')
        } else {
          cancel()
        }
      })
    },
    _handleCancel({ appId, servicePlatform, appType }) {
      this.$confirm('确认取消此版本发布???', '提示', {
        center: true,
        type: 'warning'
      }).then(() => {
        cancelPublishVersion({
          appId,
          servicePlatform,
          appType,
          publishStatus: 9,
          tenantCode: this.search.tenantCode
        }).then(([_, err]) => {
          if (!err) {
            this.$message.success('取消成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    _handleTabClick({ name }) {
      this.search.appType = name
      this.handleRefresh()
    },
    implementationGetParams() {
      return { ...this.search, appType: +this.search.appType }
    }
  }
}
