var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "帖子类型" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("后台圈子")]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v("代用户圈子"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.type === 0
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "用户账号", prop: "memberName" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "500px" },
                      attrs: { placeholder: "用户账号" },
                      model: {
                        value: _vm.ruleForm.memberName,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "memberName", $$v)
                        },
                        expression: "ruleForm.memberName",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "el-form-item",
                  { attrs: { label: "后台用户", prop: "nickname" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "500px" },
                      attrs: { placeholder: "后台用户昵称" },
                      model: {
                        value: _vm.ruleForm.nickname,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "nickname", $$v)
                        },
                        expression: "ruleForm.nickname",
                      },
                    }),
                  ],
                  1
                ),
              ],
          _c(
            "el-form-item",
            { attrs: { label: "正文内容", prop: "content" } },
            [
              _c("el-input", {
                staticStyle: { width: "500px" },
                attrs: {
                  autosize: { minRows: 6 },
                  type: "textarea",
                  maxlength: "255",
                  "show-word-limit": "",
                  placeholder: "正文内容",
                },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上传图片" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "banner-uploader",
                  attrs: {
                    accept: "image/jpeg,image/png,image/jpg,image/webp",
                    action: _vm.action,
                    data: _vm.data,
                    headers: _vm.$headers(),
                    "show-file-list": false,
                    "on-success": _vm.handleUploadSuccess,
                  },
                },
                [
                  _vm.ruleForm.url
                    ? _c("img", {
                        staticClass: "banner",
                        attrs: { src: _vm.ruleForm.url },
                      })
                    : _c("div", { staticClass: "banner-uploader-txt" }, [
                        _c("p", [_vm._v("文件大小控制在100K以内")]),
                        _c(
                          "button",
                          {
                            staticClass:
                              "el-button el-button--small el-button--orange",
                            on: {
                              click: function ($event) {
                                if ($event.target !== $event.currentTarget) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                          },
                          [_vm._v("点击上传")]
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "140px" },
                  attrs: { type: "danger", size: "large" },
                  on: { click: _vm.goback },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "140px" },
                  attrs: { type: "success", size: "large" },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }