<template>
  <el-dialog
    :title="$t('租户组别')"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="400px"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('租户组别')">
        <el-select v-model="ruleForm.groupId" clearable placeholder="请选择租户组别">
          <el-option v-for="item in $parent.groupList" :key="item.id" :value="item.id" :label="item.groupName" />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">{{ $t('关闭') }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">{{ $t('保存') }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  tenantCode: '',
  groupId: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {}
    }
  },
  methods: {
    open(row) {
      this.ruleForm.tenantCode = row.tenantCode
      this.ruleForm.groupId = row.tagGroupId
      this.visible = true
    }
  }
}
</script>
