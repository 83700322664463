var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-col",
        { staticStyle: { "padding-right": "30px" }, attrs: { span: 12 } },
        [
          _vm.permission.Add
            ? _c(
                "button",
                {
                  staticClass: "el-button el-button--orange el-button--small",
                  staticStyle: { "margin-bottom": "20px" },
                  on: { click: _vm.createLabel },
                },
                [_vm._v("添加心水标签")]
              )
            : _vm._e(),
          _c(
            "el-table",
            { attrs: { border: "", stripe: "", data: _vm.rankList } },
            [
              _c("el-table-column", {
                attrs: { label: "称号", prop: "rankName", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "点赞数", prop: "admire", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(row.minAdmire) + "~" + _vm._s(row.maxAdmire)
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm.permission.Update
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-edit",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.EditRankDialogControl.open(
                                      row
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm.permission.Del
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-delete",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeleteRank(row.id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { staticStyle: { "padding-right": "30px" }, attrs: { span: 12 } },
        [
          _vm.permission.Add
            ? _c(
                "button",
                {
                  staticClass: "el-button el-button--orange el-button--small",
                  staticStyle: { "margin-bottom": "20px" },
                  on: { click: _vm.createType },
                },
                [_vm._v("添加大神分类")]
              )
            : _vm._e(),
          _c(
            "el-table",
            { attrs: { border: "", stripe: "", data: _vm.typeItems } },
            [
              _c("el-table-column", {
                attrs: { label: "分类", prop: "name", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm.permission.Update
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-edit",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.EditTypeDialogControl.open(
                                      row
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm.permission.Del
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-delete",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeleteType(row.id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("EditTypeDialog", {
        ref: "EditTypeDialogControl",
        on: { create: _vm._handleCreateType, update: _vm._handleUpdateType },
      }),
      _c("EditRankDialog", {
        ref: "EditRankDialogControl",
        on: { create: _vm._handleCreateRank, update: _vm._handleUpdateRank },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }