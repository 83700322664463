<template>
  <div class="currency-page">
    <div class="currency-list">
      <!-- <div class="currency-item">
        <span >请选择货币</span>
      </div> -->
      <div
        v-for="currency in currencies "
        :key="currency.id"
        class="currency-item"
        :class="selectedCurrency.id == currency.id ? 'active' : ''"
        @click="selectCurrency(currency)"
      >
        <img :src="currency.icon" class="currency-icon">
        <span class="currency-text">{{ currency.name }}</span>
      </div>
    </div>
    <div v-if="selectedCurrency" class="currency-details">
      <form class="currency-form">
        <div class="form-row">
          <label class="form-label">1.</label>
          <el-input v-model="amount1" />
        </div>
        <div class="form-row">
          <label class="form-label">2.</label>
          <el-input v-model="amount2" />
        </div>
        <div class="form-row">
          <label class="form-label">3.</label>
          <el-input v-model="amount3" />
        </div>
        <div class="form-row">
          <label class="form-label">4.</label>
          <el-input v-model="amount4" />
        </div>
        <div class="form-row">
          <label class="form-label">5.</label>
          <el-input v-model="amount5" />
        </div>
        <div class="form-row">
          <label class="form-label">6.</label>
          <el-input v-model="amount6" />
        </div>
        <!-- <div v-for="( item, i ) of amountList " :key="i" class="form-row">
          <label class="form-label">{{ i + 1 }}.</label>
          <el-input :value="item.amount" @change="" />
        </div> -->

        <div class="form-actions">
          <el-button class="form-button form-button-save" @click="addPaymentAmount()">保存</el-button>
          <el-button class="form-button form-button-cancel">取消</el-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { getPaymentAmount, addPaymentAmount } from '@/api/top-up'
import currencies from './cash'
export default {
  data() {
    return {
      // 暂时写死货币
      currencies: currencies,
      selectedCurrency: null,
      // amountList: [...Array(6)].map(() => ({})),
      amount1: null,
      amount2: null,
      amount3: null,
      amount4: null,
      amount5: null,
      amount6: null
    }
  },
  created() {
    this.selectCurrency(this.currencies[0])
  },
  methods: {
    async selectCurrency(currency) {
      this.selectedCurrency = currency
      this.amount1 = null
      this.amount2 = null
      this.amount3 = null
      this.amount4 = null
      this.amount5 = null
      this.amount6 = null
      // 获取快捷金额
      let res = await getPaymentAmount({ rateType: currency.id })
      // res=res[2].data
      if (!res[2] || !res[2].data.success) return

      // this.amountList = [...Array(6)].map(() => ({}))
      // // (res[2].data.data || []).forEach((p, i) => this.amountList[i] = p)
      for (var i = 0; i < (res[2].data.data || []).length; i++) {
        // this.amountList[i] = res[2].data.data[i]
        this['amount' + (i + 1)] = res[2].data.data[i].amount
      }
      // this.amountList.splice(0, res[2].data.data.length, ...res[2].data.data)
    },
    aa() {
      this.$forceUpdate()
    },
    // 保存快捷金额
    async addPaymentAmount() {
      let amountList = [this.amount1, this.amount2, this.amount3, this.amount4, this.amount5, this.amount6].map(p => ({ amount: p }))
      let parmas = amountList.filter(p => p.amount && (/^[1-9]\d*(.\d+)?$/).test(p.amount)).map(p => ({
        amount: Number(p.amount),
        rateType: this.selectedCurrency.id
      }))
      let res = await addPaymentAmount(parmas)
      this.selectCurrency(this.selectedCurrency)
    }
    // // 获取货币列表
    // async getCurry() {
    //   let res = await getCurry()
    //   console.log(res)
    // }
  }
}
</script>

<style lang="scss">
.currency-page {
  // display: flex;
  // gap: 20px;
  // position: relative;
  display: grid;
  grid-template-columns: 220px 1fr;
  grid-template-rows: 1fr;
  justify-content: start;
}

.currency-list {
  width: 200px;
  border: 1px solid #ccc;
}

.currency-details {
  height: 100%;
}

.currency-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
}

.currency-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.active {
  background-color: #a8F8F8;
}

.currency-details {
  // flex: 1;
  width: 400px;
  border: 1px solid #ccc;
}

.currency-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;

}

.form-row {
  display: flex;
  align-items: center;
}

.form-label {
  // width: 100px;
  margin-right: 16px;
  font-weight: normal;
}

.form-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
}

.form-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.form-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-button-save {
  background-color: #42b983;
  color: #fff;
}

.form-button-cancel {
  background-color: #ccc;
  color: #fff;
}
</style>
