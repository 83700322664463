<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="yyyy-MM-dd HH:mm:ss"
        clearable
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0, false)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1, false)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7, false)">{{ $t('_7天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30, false)">{{ $t('本月') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60, false)">{{ $t('上月') }}</el-button>
    </div>
    <div class="filter-container">
      <el-input v-model="search.memberName" clearable placeholder="会员账号" class="filter-item" style="width: 200px" />
      <el-select v-model="search.memberType" clearable placeholder="账号类型" class="filter-item" style="width: 150px">
        <el-option v-for="(val, key) in memberType" :key="key" :value="key" :label="val" />
      </el-select>
      <el-input v-model="search.roomName" clearable placeholder="聊天室名称" class="filter-item" style="width: 200px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <template v-if="statistical" slot="left">
        合计人数：<span style="margin-right: 15px;">{{ statistical.peopleTotal || 0 }}</span>
        红包金额：<span style="margin-right: 15px;">{{ statistical.amountTotal || 0 }}</span>
        发送次数：<span>{{ statistical.sendCount || 0 }}</span>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <UserDialog ref="UserDialogControl" />
  </div>
</template>
<script>
import { memberType } from '@/libs/options'
import TablePageMixin from '@/mixins/table-page-mixin'
import { sendList, sendStatistics } from '@/api/activity'
import GetColumns from './columns'
import { getDefaultTimes } from '@/utils'
import UserDialog from '@/views/components/UserDialog'

export default {
  name: 'ConfigTab',
  components: { UserDialog },
  mixins: [TablePageMixin(sendList, false)],
  data() {
    return {
      times: getDefaultTimes('timestamp', 0, 0, false),
      filterColumn: false,
      memberType,
      columns: GetColumns.call(this),
      statistical: {
        peopleTotal: 0,
        amountTotal: 0,
        sendCount: 0
      }
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.handleRefresh()
      sendStatistics({ ...this.implementationGetParams() }).then(([data, err]) => {
        if (!err) {
          this.statistical = data
        }
      })
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    },
    handleReceiveTab(id) {
      this.$parent.handleReceiveTab(id)
    }
  }
}
</script>
