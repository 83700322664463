var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-room" }, [
    _c(
      "div",
      {
        class: { "chat-room-icon": _vm.canEdit },
        on: { click: _vm._clickRoomIcon },
      },
      [_c("el-avatar", { attrs: { size: 58, src: _vm.getRoomIcon() } })],
      1
    ),
    _c(
      "div",
      { staticClass: "chat-room-info", on: { click: _vm._enterRoom } },
      [
        _c("div", { staticClass: "chat-room-title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.item.roomName) +
              " (" +
              _vm._s(_vm.item.roomNumber + (_vm.item.realCount || 0)) +
              ") "
          ),
        ]),
        _c("div", { staticClass: "chat-room-desc" }, [
          _vm._v(" " + _vm._s(_vm.item.roomInfo) + " "),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeRoomId == _vm.item.roomId,
            expression: "activeRoomId == item.roomId",
          },
        ],
        staticClass: "chat-room-enter",
      },
      [_c("i", { staticClass: "el-icon-right" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }