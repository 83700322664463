import { filterColumns } from '@/utils'

const CanChooseColumns = function() {
  return [
    {
      title: '抵用券ID',
      key: 'couponId',
      align: 'center'
    },
    {
      title: '优惠类型',
      key: 'couponType',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{this.couponTypes[row.couponType]}</span>
        }
      }
    },
    {
      title: '卡券名称',
      key: 'couponTitle',
      align: 'center'
    },
    {
      title: '面额',
      key: 'couponAmount',
      align: 'center',
      component: {
        render: (h, row) => {
          const couponAmount = row.couponAmount
          switch (row.couponType) {
            case 3:
              return <span>{row.couponDiscountType === 1 ? row.couponDiscountRate + '折' : couponAmount}</span>;
              break;
            case 6:
              return <span>加奖{couponAmount}倍</span>;
              break;
            default:
              return <span>{couponAmount}</span>;
          }
        }
      }
    },
    {
      title: '使用标准',
      key: 'couponStandard',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.couponType === 6) {
            return <span>最小下注{row.couponStandardMin}，最大下注{row.couponStandardMax}</span>;
          } else {
            return <span>{row.couponStandard}</span>;
          }
        }
      }
    },

    {
      title: '库存',
      key: 'couponStock',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{row.couponStock === -1 ? '无限' : row.couponStock}</span>
        }
      }
    },
    {
      title: '累计发放金额',
      key: 'couponAmountTotal',
      align: 'center',
      component: {
        render: (h, row) => {
          const couponAmountTotal = row.couponAmountTotal
          switch (row.couponType) {
            case 3:
              return <span>{row.couponDiscountType === 1 ? '-' : couponAmountTotal}</span>;
              break;
            case 6:
              return <span>-</span>;
              break;
            default:
              return <span>{couponAmountTotal}</span>;
          }
        }
      }
    },
    {
      title: '累计发放张数',
      key: 'couponTotal',
      align: 'center'
    },
    {
      title: '累计使用金额',
      key: 'couponAmountUse',
      align: 'center'
    },
    {
      title: '累计使用张数',
      key: 'couponUse',
      align: 'center'
    }

  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
