<template>
  <el-dialog
    :title="emitName === 'create' ? $t('创建VIP等级') : $t('编辑VIP等级')"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="430px"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('VIP等级')" prop="vipLevel">
        <el-input v-model.number="ruleForm.vipLevel" :disabled="ruleForm.vipLevel === 0" :placeholder="$t('请输入VIP等级(唯一)')" />
      </el-form-item>
      <el-form-item :label="$t('充值金额')" prop="rechargeUpgradeAmount">
        <el-input v-model="ruleForm.rechargeUpgradeAmount"  :disabled="ruleForm.vipLevel === 0" :placeholder="$t('请输入升级所需充值金额')" />
      </el-form-item>
      <el-form-item :label="$t('升级有效投注')" prop="upgradeLevelMinPlayAmount">
        <el-input v-model="ruleForm.upgradeLevelMinPlayAmount" :disabled="ruleForm.vipLevel === 0" :placeholder="$t('升级所需有效投注金额(输入0代表不需要)')" />
      </el-form-item>
      <el-form-item :label="$t('保级有效投注')" prop="keepLevelMinPlayAmount">
        <el-input v-model="ruleForm.keepLevelMinPlayAmount" :disabled="ruleForm.vipLevel === 0" :placeholder="$t('保级所需有效投注金额(输入0代表不需要)')" />
      </el-form-item>
      <el-form-item :label="$t('保级计算周期')" prop="keepLevelDays">
        <el-input v-model="ruleForm.keepLevelDays" :disabled="ruleForm.vipLevel === 0" :placeholder="$t('保级计算天数(输入0代表不需要)')" >
          <span slot="suffix">天</span>
        </el-input>
      </el-form-item>
      <!-- <el-form-item :label="$t('晋级彩金')" prop="upgradeBouns">
        <el-input v-model="ruleForm.upgradeBouns" :placeholder="$t('请输入晋级彩金')" />
      </el-form-item>
      <el-form-item :label="$t('购彩返利')" prop="rebateRatio">
        <el-input v-model="ruleForm.rebateRatio" :placeholder="$t('请输入购彩返利')">
          <span slot="suffix">%</span>
        </el-input>
      </el-form-item> -->
      <el-form-item :label="$t('VIP等级图标')" prop="vipLog">
        <el-upload
          class="icon-uploader"
          accept="image/png,image/webp"
          :action="action"
          :data="{ type: 'image', folder: 'icon' }"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="uploadSuccess"
        >
          <img v-if="ruleForm.vipLog" :src="ruleForm.vipLog" class="icon">
          <i v-else class="el-icon-upload uploader-icon" />
        </el-upload>
      </el-form-item>
      <!-- <el-form-item :label="$t('礼物动画')" prop="vipSvg">
        <el-input
          v-model="ruleForm.vipSvg"
          :placeholder="$t('请上传动画文件(支持SVGA)')"
        >
          <el-upload
            slot="append"
            :action="action"
            :headers="$headers()"
            :data="{ type: 'svg', folder: 'icon' }"
            :before-upload="beforeSvgaUpload"
            :on-success="uploadSvgaSuccess"
            :show-file-list="false"
          >
            <i class="el-icon-upload" />
          </el-upload>
        </el-input>
      </el-form-item> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  vipLevel: null,
  rechargeUpgradeAmount: null,
  upgradeBouns: '',
  rebateRatio: '',
  vipLog: '',
  vipSvg: '',
  upgradeLevelMinPlayAmount: '',
  keepLevelMinPlayAmount: '',
  keepLevelDays: '',
})
const validateMaxValue = (maxValue,msg) => {
  return (rule, value, callback) => {
    if (value > maxValue) {
      callback(new Error(msg));
    } else {
      callback();
    }
  };
};
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        vipLevel: [
          { required: true, message: this.$t('请输入VIP等级'), trigger: 'blur' },
          { pattern: /^[1-9]\d*|0$/, message: this.$t('请输入有效等级'), trigger: 'blur' },
          { validator: validateMaxValue(99,this.$t(`VIP等级最大为99`)), trigger: 'blur'
          }
        ],
        rechargeUpgradeAmount: [
          { required: true, message: this.$t('请输入升级所需充值金额'), trigger: 'blur' },
          { pattern: /^(0|[1-9]\d*)(\.\d{1,3})?$/, message: this.$t('请输入有效金额'), trigger: 'blur' },
          { validator: validateMaxValue(99999999,this.$t(`充值金额最大为99999999`)), trigger: 'blur'
        }
        ],
        upgradeLevelMinPlayAmount: [
          { required: true, message: this.$t('请输入升级所需有效投注金额'), trigger: 'blur' },
          { pattern: /^(0|[1-9]\d*)(\.\d{1,3})?$/, message: this.$t('请输入有效金额'), trigger: 'blur' },
          { validator: validateMaxValue(99999999,this.$t(`金额最大为99999999`)), trigger: 'blur'
        }
        ],
        keepLevelMinPlayAmount: [
          { required: true, message: this.$t('请输入保级所需有效投注金额'), trigger: 'blur' },
          { pattern: /^(0|[1-9]\d*)(\.\d{1,3})?$/, message: this.$t('请输入有效金额'), trigger: 'blur' },
          { validator: validateMaxValue(99999999,this.$t(`金额最大为99999999`)), trigger: 'blur'
        }
        ],
        keepLevelDays: [
          { required: true, message: this.$t('请输入保级计算天数'), trigger: 'blur' },
          { pattern: /^([0-9]\d*)$/, message: this.$t('请输入有效数字'), trigger: 'blur' },
          { validator: validateMaxValue(99999999,this.$t(`天数最大为99999999`)), trigger: 'blur'
        }
        ],
        // upgradeBouns: [
        //   { required: true, message: this.$t('请输入晋级彩金'), trigger: 'blur' },
        //   { pattern: /^(\d*)+(.[0-9]{1,3})?$/, message: this.$t('请输入有效晋级彩金'), trigger: 'blur' }
        // ],
        // rebateRatio: [
        //   { required: true, message: this.$t('请输入购彩返利'), trigger: 'blur' },
        //   { pattern: /^(\d{1,2}(\.\d{1})?|100|100.0)$/, message: this.$t('请输入有效数字'), trigger: 'blur' }
        // ],
        vipLog: [{ required: true, message: this.$t('请上传等级图标'), trigger: 'change' }]
      }
    }
  },
  computed: {

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    }
  },
  methods: {
    open(row) {
      if (row) {
        this.ruleForm.id = row.id
        this.ruleForm.vipLevel = row.vipLevel
        this.ruleForm.rechargeUpgradeAmount = row.rechargeUpgradeAmount
        this.ruleForm.upgradeBouns = row.upgradeBouns
        this.ruleForm.rebateRatio = row.rebateRatio
        this.ruleForm.vipLog = row.vipLog
        this.ruleForm.vipSvg = row.vipSvg
        this.ruleForm.upgradeLevelMinPlayAmount = row.upgradeLevelMinPlayAmount
        this.ruleForm.keepLevelMinPlayAmount = row.keepLevelMinPlayAmount
        this.ruleForm.keepLevelDays = row.keepLevelDays
        this.emitName = 'update'
      } else {
        this.emitName = 'create'
      }
      this.visible = true
    },
    uploadSuccess(res) {
      this.ruleForm.vipLog = res.data[0]
      this.$refs.validateForm.validateField('vipLog')
    },
    beforeSvgaUpload(file) {
      const name = file.name
      const point = name.lastIndexOf('.')
      const isImage = name.substr(point) === '.svga'
      if (!isImage) {
        this.$message({
          type: 'warning',
          message: this.$t('您上载的档案格式不对！')
        })
        return false
      }
      return true
    },
    uploadSvgaSuccess(res) {
      this.ruleForm.vipSvg = res.data[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-uploader {
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409EFF;
    }
  }

  .uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 105px;
    height: 45px;
    line-height: 45px;
    text-align: center;
  }

  .icon {
    width: 105px;
    height: 45px;
    display: block;
  }
}
</style>
