import Layout from '@/layout'

export default {
  path: '/OrderManage',
  component: Layout,
  redirect: 'noRedirect',
  name: 'OrderManage',
  alwaysShow: true,
  meta: { title: '推单管理', icon: 'orders' },
  children: [
    {
      path: '/OrderManage_ApplyList',
      component: () => import('@/views/orderManage/applyList/'),
      name: 'OrderManageApplyList',
      meta: { title: '大神申请列表' }
    },
    {
      path: '/OrderManage_RecList',
      component: () => import('@/views/orderManage/recList/'),
      name: 'RecList',
      meta: { title: '推单订单列表' }
    },
    {
      path: '/OrderManage_FollowList',
      component: () => import('@/views/orderManage/followOrderList/'),
      name: 'FollowList',
      meta: { title: '跟单订单列表'}
    },
    {
      path: '/OrderManage_Reviews',
      component: () => import('@/views/orderManage/recOrderReviewManage'),
      name: 'OrderReviews',
      meta: { title: '推单评论管理'}
    },
    {
      path: '/OrderManage_Stastics',
      component: () => import('@/views/orderManage/recOrderStastics'),
      name: 'OrderStastics',
      meta: { title: '大神推单统计' }
    },
    {
      path: '/OrderManage_RecMaster',
      component: () => import('@/views/orderManage/recMasterManage'),
      name: 'RecMaster',
      meta: { title: '推荐大神管理' }
    },
    {
      path: '/OrderManage_RulesConfig',
      component: () => import('@/views/orderManage/rulesConfig/'),
      name: 'RulesConfig',
      meta: { title: '推单规则配置' }
    }
  ]
}

