const CanChooseColumns = function() {
  return [
    {
      title: this.$t('分类ID'),
      key: 'id',
      align: 'center'
    },
    {
      title: this.$t('分类名称'),
      key: 'typeName',
      align: 'center',
      component: {
        render: (h, row) => {
          const vnodes = []
          row.typeName.split(',').forEach(e => {
            const typeLang = e.split(':')[0]
            const typeName = e.split(':')[1]
            const lang = this.allLang.find(v => v.lang === typeLang)
            if (lang) {
              vnodes.push(<div>{ this.$t(lang.name) + ':' + typeName }</div>)
            }
          })
          return (<span>{ vnodes }</span>)
        }
      }
    },
    {
      title: '图标',
      key: 'icon',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image style='width:48px;height:48px' src={row.icon} lazy></el-image>)
        }
      }
    },
    {
      title: '选中图标',
      key: 'selectedIcon',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image style='width:48px;height:48px' src={row.selectedIcon} lazy></el-image>)
        }
      }
    },
    {
      title: this.$t('排序'),
      key: 'sort',
      align: 'center'
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission

          return (
            <div>
              { Update && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.ActionDialogControl.open(row) } }>{ this.$t('编辑') }</el-button> }
              { Delete && <el-button type='danger' size='small' icon='el-icon-delete' onClick={ () => this.handleDeleteActivityType(row)}>{ this.$t('删除') }</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
