<template>
  <el-dialog :title="$t('确认操作')" :visible.sync="visible" center width="400px">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules">
      <el-form-item prop="type">
        <el-radio-group v-model="ruleForm.type">
          <el-radio :label="1"> {{ $t('删除当前消息') }} </el-radio>
          <el-radio :label="2"> {{ $t('删除消息并禁言') }}  <el-input v-model="ruleForm.min" size="mini" style="width: 30%;" /> 分钟 </el-radio>
          <el-radio :label="3"> {{ $t('删除消息并永久禁言') }} </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="medium" @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" size="medium" :loading="submitting" @click="handleSubmit">
        {{ $t('确认') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>

const getDefaultForm = () => ({
  type: 1,
  min: undefined,
  roomId: '',
  memberId: '',
  msgId: ''
})
import FormDialogMixin from '@/mixins/form-dialog'
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validateType = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error(this.$t('请输入选择删除类型')))
      } else if (value === 2 && !this.ruleForm.min) {
        callback(new Error(this.$t('请输入输入禁言时间')))
      } else if (value === 2 && this.ruleForm.min) {
        const numerical = /^([1-9]|[1-9]\d|[1][0-1]\d|120)$/
        if (!numerical.test(this.ruleForm.min)) {
          callback(new Error('请输入1-120以内的整数'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      tenantCode: '',
      emitName: 'submit',
      rules: {
        type: [
          { required: true, message: '请输入选择删除类型', trigger: 'blur' },
          { validator: validateType, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open({ from, roomId, id,msgType, nickname }) {
      this.ruleForm.type = 1
      this.ruleForm.roomId = roomId
      this.ruleForm.memberId = from
      this.ruleForm.msgType = msgType
      this.ruleForm.id = id
      this.ruleForm.nickname = nickname
      this.ruleForm.min = undefined
      this.visible = true
    }
  }
}
</script>
