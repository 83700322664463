var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticStyle: { "margin-left": "6px" } },
    [
      _c("el-button", {
        staticStyle: { "margin-right": "6px" },
        attrs: { icon: "el-icon-refresh-right" },
        on: {
          click: function ($event) {
            return _vm.$emit("refresh")
          },
        },
      }),
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-end",
            trigger: "click",
            "popper-class": "d-table-fixed-popper",
          },
        },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm._checkList,
                callback: function ($$v) {
                  _vm._checkList = $$v
                },
                expression: "_checkList",
              },
            },
            _vm._l(_vm.columns, function (column) {
              return _c(
                "el-checkbox",
                {
                  key: column.key,
                  staticStyle: { display: "block" },
                  attrs: { label: column.key },
                },
                [_vm._v(_vm._s(column.title))]
              )
            }),
            1
          ),
          _c("el-button", {
            attrs: { slot: "reference", icon: "el-icon-s-unfold" },
            slot: "reference",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }