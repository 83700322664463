<template>
  <div class="navbar">
    <div class="left-menu">
      <hamburger
        id="hamburger-container"
        :is-active="sidebar.opened"
        class="hamburger-container"
        @toggleClick="toggleSideBar"
      />

      <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    </div>

    <div class="right-menu">
      <template v-if="!isProx">
        <div v-if="$store.getters.tenantCode !== 'system'" class="tip-container right-menu-item hover-effect flex" @click="_go('/Interactive_CustomerService')">
          <svg-icon icon-class="message" />
          <span>{{ $t('客服对话') }}</span>
          <span v-if="totalUnReadNum" class="redPoint">{{ totalUnReadNum >98 ? 99:totalUnReadNum }}<span v-if="totalUnReadNum > 98">+</span></span>
        </div>
        <div v-if="orders" class="tip-container right-menu-item hover-effect flex" @click="_go('/Cash_Withdrawal')">
          <svg-icon icon-class="withdrawal" />
          <span>{{ $t('提现') }}<em>({{ orders.withdraw }}+)</em></span>
        </div>

        <div v-if="orders" class="tip-container right-menu-item hover-effect" @click="_go('/Cash_Deposit')">
          <svg-icon icon-class="deposit" />
          <span>{{ $t('充值') }}<em>({{ orders.recharge }}+)</em></span>
        </div>

        <template v-if="device !== 'mobile'">
          <div class="right-menu-item hover-effect">
            <el-badge :is-dot="notice.isDot">
              <svg-icon icon-class="message" font-size="24px" @click="toggleNotice" />
            </el-badge>
          </div>

          <lang-select class="right-menu-item hover-effect" />

        </template>
      </template>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <el-image :src="head" fit="cover" class="user-avatar" />
          <span class="user-name">{{ name }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link v-if="$store.getters.homeRoute === '/dashboard'" to="/">
            <el-dropdown-item>{{ this.$t('首页') }}</el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="logout">{{ this.$t('退出登录') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import LangSelect from '@/components/LangSelect'
import { validURL } from '@/utils/validate'
import defaultAvatar from '@/assets/public_images/public-avatar.png'
import { getTopData, getNewNotice } from '@/api/app'
import { getImToken,getCustomerPageList } from "@/api/chat";
import { passRouter } from '@/utils/permission'
import store from "@/store"
import SocketMixin from "../../views/interactive/Chat/socket";
import WebSocketWithHeartbeat from "../../views/interactive/Chat/socketNew";
import TablePageMixin from "@/mixins/table-page-mixin";
import { getPlatformDomainImList } from "@/api/permission.js";

export default {
  components: {
    Breadcrumb,
    Hamburger,
    LangSelect
  },
  data() {
    return {
      orders: null,
      times: null,
      isProx: process.env.NODE_ENV.includes('prox'),
      nickname:'',
      timer:null,
      roomId: "",
      socketLeft: null,
      domainImData:{},
      showRoomList: [],
    }
  },
  mixins: [SocketMixin(), TablePageMixin(false, false)],
  computed: {
    ...mapGetters([
      'sidebar',
      'notice',
      'avatar',
      'name',
      'device',
      'permission_routes',
      'operatorType'
    ]
  ),
    head() {
      return validURL(this.avatar) ? this.avatar : defaultAvatar
    },
    totalUnReadNum(){
      return this.$store.getters.totalUnReadNum || 0
    },
    unReadNumSocket(){
      return this.$store.getters.unReadNumSocket || 0
    }
  },
  async created(){
    if (!process.env.NODE_ENV.includes('prox')) {
      // this.timer = setInterval(this.getCustomerList,10000)

      // 采用socket更新会在进入客服页面造成同个socket同时调用两次,暂时采用轮询
      this.getCustomerList()
      await this.getConfig();
      this._contentLeft(this.roomId);
    }
  },
  mounted() {
    if (this.operatorType === 2) {
      this.fetchNoice()
      this.fetchTopData()

      this.times = setInterval(() => {
        this.fetchTopData()
      }, 100000)
    }
  },
  destroyed(){
    if(this.timer) clearInterval(this.timer)
    if (this.socketLeft) this.socketLeft.close();
  },
  methods: {
    // 获取接口域名配置
    async getConfig() {
      await getPlatformDomainImList().then(([data, err]) => {
        if (!err && data) {
          this.domainImData = data;
          this.init();
          return new Promise((resolve, reject) => {
            resolve();
          });
        }
      });
    },
    // 初始化
    async init() {
      await this._getImToken();
    },
    // 获取授权token
    async _getImToken() {
      const [data, err] = await getImToken({ tokenType: 1 });
      if (!err) {
        this.$store.commit("user/SET_IM_TOKEN", data);
      }
    },
    // 查询未读客服消息数量
    getCustomerList(nickname = "") {
    // 左侧用户列表
      getCustomerPageList({
        tenantCode: "",
        nickname: nickname,
        pageNumber: 1,
      }).then((res) => {
        if (res[2]) {
          let totalUnReadNum = 0
          let list = [];
          res[2].data.items.map((item) => {
            let result = item.replace(/\\n/g, "\n");
            result = result.replace(/\\/g, "");
            result = result.replace(/"{/g, "{");
            result = result.replace(/}"/g, "}");
            result = result.replace(/\n/g, "");

            try {
              item = JSON.parse(result);
            } catch (err) {
              // console.log('error',err,item)
              // extras里有富文本会解析失败 extras在timestamp字段前
              let result = item.split(',"extras')[0] + "}";
              const start = item.indexOf("extras");
              const end = item.indexOf("timestamp");
              let extras = '{"' + item.substring(start, end).trim();
              extras = extras.slice(0,-2)
              extras = extras + '}'
              // console.log(extras)
              extras = JSON.parse(extras)
              extras = JSON.parse(extras.extras)
              console.log('extras',extras)
              let result2 = '{"timestamp' + item.split("timestamp")[1];
              result = JSON.parse(result);
              result2 = JSON.parse(result2);
              item = { ...result, ...result2, extras };
            }

            list.push(item);
            totalUnReadNum += item.messageSize
            store.dispatch('tagsView/addTotalUnReadNum', totalUnReadNum)
          });
        }
      });
    },
    //链接左侧房间更新socket
    _contentLeft(roomId) {
    if (this.socketLeft) this.socket.close();
      this.socketLeft = new WebSocketWithHeartbeat(
        roomId,
        (data) => {
          console.log("leftMessage", data);
          this.getCustomerList()
        },
        this.domainImData.imSocket,
        "isLeft"
      );
      store.dispatch('tagsView/addUnReadNumSocket', this.socketLeft)
      console.log(this.socketLeft,this.unReadNumSocket)
    },
    _go(path) {
      if (passRouter(path, this.permission_routes)) {
        this.$router.push({ path })
      }
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    toggleNotice() {
      this.$store.dispatch('app/toggleNotice')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login`)
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },

    fetchTopData() {
      getTopData().then(([data, err]) => {
        if (!err) {
          this.orders = data
        }
      })
    },

    fetchNoice() {
      getNewNotice().then(([data, err]) => {
        if (!err) {
          if (data.id) {
            this.createNode(data)
            this.$store.dispatch('app/toggleNoticeHot')
            this.$store.dispatch('app/setNoticeData', data)
          }
        }
      })
    },
    noticeClickHandler() {
      this.$notify.closeAll()
      this.$store.dispatch('app/toggleNotice')
    },
    createNode({ title, content, createTime }) {
      const h = this.$createElement
      this.$notify.closeAll()
      this.$notify({
        title: this.$t('平台公告'),
        message: h('div', {
          style: {
            textAlign: 'center'
          }
        }, [
          h('p', null, title),
          h('p', { style: { fontSize: '12px' }}, this.$parseTime(createTime)),
          h('div', {
            style: {
              textAlign: 'left'
            },
            domProps: {
              innerHTML: content
            }
          }),
          h('el-button', {
            style: {
              float: 'right'
            },
            props: {
              size: 'small'
            },
            on: {
              click: this.noticeClickHandler
            }
          }, '查看更多')
        ]),
        duration: 0,
        position: 'bottom-right',
        dangerouslyUseHTMLString: true
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

  .left-menu {
    display: flex;
    align-items: center;
  }

  .hamburger-container {
    line-height: 46px;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          vertical-align: middle;
        }

        .user-name {
          display: inline-block;
          vertical-align: middle;
          margin-left: 6px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -15px;
          top: 15px;
          font-size: 12px;
        }
      }
    }
  }

  .tip-container {
    svg {
      font-size: 20px;
    }

    &>span {
      font-size: 14px;
    }

    em {
      font-style: initial;
      font-size: 12px;
    }
  }
  .flex{
    display: flex;
    align-items: center;
  }
  .redPoint{
    display: inline-block;
    margin-left: 4px;
    width: 16px;
    height: 16px;
    font-size: 12px !important;
    background-color: red;
    border-radius: 50%;
    color: white;
    text-align: center;
    line-height: 16px;
  }
}</style>
