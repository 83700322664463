<template>
  <div class="detailsPage">
    <div class="titleLine">
      <span>订单详情</span>
      <el-button type="primary" @click="$emit('close')">返回</el-button>
    </div>
    <hr />
    <h4>订单状态:{{ rowData.orderStatus }}</h4>
    <div class="contentSec">
      <div>跟单订单号:{{ rowData.orderNo || "---" }}</div>
      <div>推单订单号:{{ rowData.guruOrderNo || "---" }}</div>
      <div>跟单时间:{{ $parseTime(rowData.createTime) || "---" }}</div>
      <div>会员账号:{{ rowData.memberName || "---" }}</div>
      <div>会员昵称:{{ rowData.nickname || "---" }}</div>
      <div>游戏类型:{{ gameTypeName || "---" }}</div>
      <div>跟单金额:{{ rowData.orderAmount || "---" }}</div>
      <!-- <div>
        {{ computed_wanfa }}:{{ recOrderDetail.betInformation || "---" }}
      </div> -->
      <!-- 体育赛事-玩法：单注类型，显示总场数，串关类型，显示几串几.六合彩-期号：第***期 -->
      <div>
        抽佣:{{
          rowData.followerOrderRate
            ? rowData.followerOrderRate * 100 + "%"
            : "---"
        }}
      </div>
    </div>
    <div>
      方案宣言:
      <div v-html="rowData.planSpeech"></div>
    </div>
    <div>
      <h5>方案详情</h5>
      <div class="contentSec">
        <div>自购金额:{{ recOrderDetail.selfAmount || "---" }}</div>
        <div>中奖金额:{{ recOrderDetail.hitAmount || "---" }}</div>
        <div>自购盈亏:{{ recOrderDetail.selfOrderProfit || "---" }}</div>
      </div>
    </div>
    <hr />
    <el-table
      :data="planDetails"
      border
      style="width: 80%"
      v-if="rowData.smallPlayType == 40 || rowData.smallPlayType == 41"
      class="textAlignCenter"
    >
      <el-table-column label="玩法" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.betsContent }}</div>
        </template>
      </el-table-column>
      <el-table-column label="投注" align="center">
        <template slot-scope="scope">
          <div>
            @{{ scope.row.odds }}//<span class="xiazhu"
              >下注{{ scope.row.amount }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="输赢" align="center">
        <template slot-scope="scope">
          <div>
            {{ $t("orderStatus")[scope.row.status] }}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-table
      :data="planDetails"
      border
      style="width: 80%"
      v-if="rowData.smallPlayType == 99"
      class="textAlignCenter"
    >
      <el-table-column label="场次/对阵">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.competitionName }}</span
            >&nbsp
            <span>
              周{{
                $parseTime(
                  scope.row.matchTime +
                    new Date().getTimezoneOffset() * 60 * 1000,
                  "{a}"
                )
              }}{{ scope.row.matchNumber.substring(1) }}
            </span>
          </div>
          <div>
            <span
              >{{ scope.row.homeTeamName }}VS{{ scope.row.awayTeamName }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="投注" prop="betContent">
        <template slot-scope="scope">
          <div
            v-for="(betContentItem, ii) in scope.row.betContent.split(',')"
            :key="ii"
          >
            {{ betFormat(betContentItem, scope.row.rqCount) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="赛果">
        <template slot-scope="scope">
          <div>{{ parseScore(scope.row.betResult) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="输赢">
        <template slot-scope="scope">
          <div>
            {{ $t("orderStatus")[scope.row.status] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="planDetails"
      border
      style="width: 80%"
      v-if="rowData.smallPlayType == 1502"
      class="textAlignCenter"
    >
      <el-table-column label="场次">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.sportName }}</div>
            <div>
              周{{
                $parseTime(
                  scope.row.beginTime +
                    new Date().getTimezoneOffset() * 60 * 1000,
                  "{a}"
                )
              }}
            </div>
            <div>({{ $parseTime(scope.row.beginTime, "{m}-{d}") }})</div>

            <div>{{ scope.row.matchName }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="对阵">
        <template slot-scope="scope">
          <div>{{ scope.row.matchInfo.split("v")[0] }}</div>
          <div>VS</div>
          <div>{{ scope.row.matchInfo.split("v")[1] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="投注">
        <template slot-scope="scope">
          <div class="">{{ scope.row.playName }}</div>
          &nbsp; <span class="">{{ scope.row.playOptionName }}</span
          >&nbsp;&nbsp;
          <span class="">@{{ scope.row.oddFinally }}</span>
        </template>
      </el-table-column>
    </el-table>
    <hr />
    <h5>订单结算</h5>
    <div class="contentSec">
      <div>跟单中奖:{{ rowData.winAmount || "---" }}</div>
      <div>跟单盈亏:{{ rowData.profitAmount || "---" }}</div>
      <div>
        跟单佣金:{{
          rowData.commission
            ? Number(rowData.commission) * Number(rowData.followerOrderRate)
            : "---"
        }}
      </div>
      <div>结算金额:{{ rowData.followerSettlementAmount || "---" }}</div>
      <div>截止时间:{{ $parseTime(rowData.endTime) || "---" }}</div>
      <div>结算时间:{{ $parseTime(rowData.settlementTime) || "---" }}</div>
    </div>
  </div>
</template>
<script>
import {
  getGuruOrderLotteryDetail,
  getGuruOrderFootballDetail,
  getRecList,
  getGamePmTyDetail,
} from "@/api/orderManage";
import { parseScore, betFormat } from "@/utils";
export default {
  name: "",
  components: {},
  props: {
    rowData: {},
  },
  data() {
    return {
      parseScore,
      betFormat,
      apiData: {},
      planDetails: [],
      recOrderDetail: {},
    };
  },
  computed: {
    gameTypeName() {
      switch (Number(this.rowData?.smallPlayType)) {
        case 40:
          return "香港六合彩";
        case 41:
          return "新澳门六合彩";
        case 99:
          return "竞彩足球";
        case 1502:
          return "三方游戏";
        default:
          return "";
      }
    },
    computed_wanfa() {
      if (
        this.rowData.smallPlayType == 40 ||
        this.rowData.smallPlayType == 41
      ) {
        return "期号";
      } else {
        return "玩法";
      }
    },
  },
  methods: {
    //获取推单详情
    getRecOrderDetails() {
      const { guruOrderNo, guruPlanId } = this.rowData;
      console.log("🚀🚀🚀🚀 ~ this.rowData:", this.rowData);
      getRecList({ OrderNo: guruOrderNo }).then(([data, err]) => {
        if (!err) {
          this.recOrderDetail = data?.[0];
        }
      });
    },
    //彩票详情
    getLotteryDetails() {
      const { guruOrderNo, guruPlanId } = this.rowData;
      getGuruOrderLotteryDetail({
        orderNo: guruOrderNo,
        planId: guruPlanId,
      }).then(([data, err]) => {
        if (!err) {
          this.planDetails = data;
        }
      });
    },
    //竞彩足球详情
    getJCZQdetails() {
      const { guruOrderNo, guruPlanId } = this.rowData;
      getGuruOrderFootballDetail({
        orderNo: guruOrderNo,
        planId: guruPlanId,
      }).then(([data, err]) => {
        if (!err) {
          this.planDetails = data.recordList;
        }
      });
    },
    //第三方注单详情
    getThirdDetails() {
      const { guruOrderNo, orderNoList, guruPlanIdOrderNos, createTime } =
        this.rowData;
      getGamePmTyDetail({ orderNoList: guruPlanIdOrderNos, createTime }).then(
        ([items, err]) => {
          if (!err) {
            // this.planDetails = JSON.parse(data[0].betRecordList);
            // this.planDetails = data.map((item) => {
            //   return JSON.parse(item.betRecordList)?.[0];
            // });
            let initArr = [];
            items.forEach((item) => {
              let betRecordList = JSON.parse(item.betRecordList);
              if (betRecordList && betRecordList.length > 0) {
                initArr = [...initArr, ...betRecordList];
              }
            });
            this.planDetails = initArr;
          }
        }
      );
    },
  },
  mounted() {
    const { smallPlayType } = this.rowData;
    this.getRecOrderDetails();
    if (smallPlayType == 40 || smallPlayType == 41) {
      this.getLotteryDetails();
    } else if (smallPlayType == 99) {
      this.getJCZQdetails();
    } else if (smallPlayType == 1502) {
      this.getThirdDetails();
    }
  },
};
</script>

<style lang="scss" scoped>
.detailsPage {
  position: absolute;
  width: 98%;
  height: 99vh;
  overflow: auto;
  background-color: #fff;
  top: 0;
  z-index: 999;
  border-bottom: 10px solid #eee;
  .titleLine {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 0 5px;
    span {
      font-size: 22px;
      font-weight: 600;
    }
  }
}
.contentSec {
  display: flex;
  flex-wrap: wrap;
  color: #555;
  > div {
    width: 33%;
    margin-bottom: 10px;
  }
}
</style>
