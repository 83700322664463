<template>
  <el-dialog :title="$t('Interactive_Match.ForecastTab.直播链接配置')" :visible.sync="visible" width="500px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('Interactive_Match.ForecastTab.链接')" prop="liveUrl">
        <el-input v-model.number="ruleForm.liveUrl" />
      </el-form-item>
      <el-form-item :label="$t('Interactive_Match.ForecastTab.热门直播位置')" prop="liveHot">
        <el-input v-model.number="ruleForm.liveHot" />
      </el-form-item>
      <el-form-item label="直播图" prop="livePicture">
        <SingleImage2 :value="ruleForm.livePicture" folder="ad-photo" :style="{ marginTop: '10px' }" @input="output" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { requireUrl } from '@/utils/validation.js'
import FormDialogMixin from '@/mixins/form-dialog'
import SingleImage2 from '@/components/Upload/SingleImage2.vue'

const getDefaultForm = () => ({
  liveUrl: null,
  liveHot: null,
  livePicture: ''
})

export default {
  components: { SingleImage2 },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        liveUrl: requireUrl,
        liveHot: [
          { pattern: /^(?:[1-9]?\d|100)$/, message: this.$t('请输入m_n区间数字', { text: '0-100' }), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(row) {
      this.ruleForm.sportType = row.sportType
      this.ruleForm.liveUrl = row.liveUrl
      this.ruleForm.livePicture = row.livePicture
      this.ruleForm.liveHot = row.liveHot
      this.ruleForm.matchId = row.matchId
      this.ruleForm.matchTime = row.matchTime
      if (this.$P$) this.ruleForm.tenantCode = row.tenantCode
      this.visible = true
    },
    output(v) {
      this.ruleForm.livePicture = v === '' ? '' : v
    }
  }
}
</script>
