import { numerical, integerNormal } from '@/utils/validation.js'

export const getRuleForm = () => ({
  id: null,
  checkinStatus: 1,
  activityType: '',
  betsAmount: '',
  rechargeTimes: '',
  rechargeAmount: '',
  betsTotal: '',
  rechargeTotal: '',
  oneAmount: '',
  twoAmount: '',
  threeAmount: '',
  fourAmount: '',
  fiveAmount: '',
  sixAmount: '',
  sevenAmount: '',
  eightAmount: '',
  nineAmount: '',
  tenAmount: '',
  elevenAmount: '',
  twelveAmount: '',
  thirteenAmount: '',
  fourteenAmount: '',
  fifteenAmount: '',
  sixteenAmount: '',
  seventeenAmount: '',
  eighteenAmount: '',
  nineteenAmount: '',
  twentyAmount: '',
  twentyoneAmount: '',
  twentytwoAmount: '',
  twentythreeAmount: '',
  twentyfourAmount: '',
  twentyfiveAmount: '',
  twentysixAmount: '',
  twentysevenAmount: '',
  twentyeightAmount: '',
  twentynineAmount: '',
  thirtyAmount: '',
  thirtyOneAmount: '',
  oneRolling: '',
  twoRolling: '',
  threeRolling: '',
  fourRolling: '',
  fiveRolling: '',
  sixRolling: '',
  sevenRolling: '',
  eightRolling: '',
  nineRolling: '',
  tenRolling: '',
  elevenRolling: '',
  twelveRolling: '',
  thirteenRolling: '',
  fourteenRolling: '',
  fifteenRolling: '',
  sixteenRolling: '',
  seventeenRolling: '',
  eighteenRolling: '',
  nineteenRolling: '',
  twentyRolling: '',
  twentyoneRolling: '',
  twentytwoRolling: '',
  twentythreeRolling: '',
  twentyfourRolling: '',
  twentyfiveRolling: '',
  twentysixRolling: '',
  twentysevenRolling: '',
  twentyeightRolling: '',
  twentynineRolling: '',
  thirtyRolling: '',
  thirtyOneRolling: '',
  oneWeekCoupanList: [],
  twoWeekCoupanList: [],
  threeWeekCoupanList: [],
  fullCoupanList: [],
  oneWeekAmount: '',
  oneWeekRolling: '',
  twoWeekAmount: '',
  twoWeekRolling: '',
  threeWeekAmount: '',
  threeWeekRolling: '',
  fullAmount: '',
  fullRolling: '',
  checkinContentZh: '',
  checkinContentEn: '',
  checkinContentVi: '',
  checkinContentGu: '',
  checkinContentJa: '',
  checkinContentMs: ''
})

const rules = function() {
  const amountRule = { required: true, message: this.$t('请输入彩金'), trigger: 'blur' }
  const rollingRule = { required: true, message: this.$t('请输入打码倍数'), trigger: 'blur' }
  const coupanRule = { required: true, message: this.$t('请选择抵用券'), trigger: 'change' }
  const requireNumerical = { pattern: numerical, message: '不小于0且最多三位小数的数', trigger: 'blur' }
  const requireIntegerNormal = { pattern: integerNormal, message: '请输入0~9,999,999整数', trigger: 'blur' }
  return {
    activityType: [{ required: true, message: this.$t('请选择签到任务分类'), trigger: 'change' }],
    betsAmount: [{ required: true, message: this.$t('请输入当日有效投注'), trigger: 'blur' }, requireNumerical],
    rechargeTimes: [{ required: true, message: this.$t('请输入当日充值次数'), trigger: 'blur' }, requireIntegerNormal],
    rechargeAmount: [{ required: true, message: this.$t('请输入当日充值金额'), trigger: 'blur' }, requireNumerical],
    betsTotal: [{ required: true, message: this.$t('请输入总打码'), trigger: 'blur' }, requireNumerical],
    rechargeTotal: [{ required: true, message: this.$t('请输入总充值金额'), trigger: 'blur' }, requireNumerical],
    oneAmount: [amountRule, requireNumerical],
    oneRolling: [rollingRule, requireIntegerNormal],
    twoAmount: [amountRule, requireNumerical],
    twoRolling: [rollingRule, requireIntegerNormal],
    threeAmount: [amountRule, requireNumerical],
    threeRolling: [rollingRule, requireIntegerNormal],
    fourAmount: [amountRule, requireNumerical],
    fourRolling: [rollingRule, requireIntegerNormal],
    fiveAmount: [amountRule, requireNumerical],
    fiveRolling: [rollingRule, requireIntegerNormal],
    sixAmount: [amountRule, requireNumerical],
    sixRolling: [rollingRule, requireIntegerNormal],
    sevenAmount: [amountRule, requireNumerical],
    sevenRolling: [rollingRule, requireIntegerNormal],
    eightAmount: [amountRule, requireNumerical],
    eightRolling: [rollingRule, requireIntegerNormal],
    nineAmount: [amountRule, requireNumerical],
    nineRolling: [rollingRule, requireIntegerNormal],
    tenAmount: [amountRule, requireNumerical],
    tenRolling: [rollingRule, requireIntegerNormal],
    elevenAmount: [amountRule, requireNumerical],
    elevenRolling: [rollingRule, requireIntegerNormal],
    twelveAmount: [amountRule, requireNumerical],
    twelveRolling: [rollingRule, requireIntegerNormal],
    thirteenAmount: [amountRule, requireNumerical],
    thirteenRolling: [rollingRule, requireIntegerNormal],
    fourteenAmount: [amountRule, requireNumerical],
    fourteenRolling: [rollingRule, requireIntegerNormal],
    fifteenAmount: [amountRule, requireNumerical],
    fifteenRolling: [rollingRule, requireIntegerNormal],
    sixteenAmount: [amountRule, requireNumerical],
    sixteenRolling: [rollingRule, requireIntegerNormal],
    seventeenAmount: [amountRule, requireNumerical],
    seventeenRolling: [rollingRule, requireIntegerNormal],
    eighteenAmount: [amountRule, requireNumerical],
    eighteenRolling: [rollingRule, requireIntegerNormal],
    nineteenAmount: [amountRule, requireNumerical],
    nineteenRolling: [rollingRule, requireIntegerNormal],
    twentyAmount: [amountRule, requireNumerical],
    twentyRolling: [rollingRule, requireIntegerNormal],
    twentyoneAmount: [amountRule, requireNumerical],
    twentyoneRolling: [rollingRule, requireIntegerNormal],
    twentytwoAmount: [amountRule, requireNumerical],
    twentytwoRolling: [rollingRule, requireIntegerNormal],
    twentythreeAmount: [amountRule, requireNumerical],
    twentythreeRolling: [rollingRule, requireIntegerNormal],
    twentyfourAmount: [amountRule, requireNumerical],
    twentyfourRolling: [rollingRule, requireIntegerNormal],
    twentyfiveAmount: [amountRule, requireNumerical],
    twentyfiveRolling: [rollingRule, requireIntegerNormal],
    twentysixAmount: [amountRule, requireNumerical],
    twentysixRolling: [rollingRule, requireIntegerNormal],
    twentysevenAmount: [amountRule, requireNumerical],
    twentysevenRolling: [rollingRule, requireIntegerNormal],
    twentyeightAmount: [amountRule, requireNumerical],
    twentyeightRolling: [rollingRule, requireIntegerNormal],
    twentynineAmount: [amountRule, requireNumerical],
    twentynineRolling: [rollingRule, requireIntegerNormal],
    thirtyAmount: [amountRule, requireNumerical],
    thirtyRolling: [rollingRule, requireIntegerNormal],
    thirtyOneAmount: [amountRule, requireNumerical],
    thirtyOneRolling: [rollingRule, requireIntegerNormal],
    oneWeekCoupanList: [coupanRule],
    oneWeekAmount: [amountRule, requireNumerical],
    oneWeekRolling: [rollingRule, requireIntegerNormal],
    twoWeekCoupanList: [coupanRule],
    twoWeekAmount: [amountRule, requireNumerical],
    twoWeekRolling: [rollingRule, requireIntegerNormal],
    threeWeekCoupanList: [coupanRule],
    threeWeekAmount: [amountRule, requireNumerical],
    threeWeekRolling: [rollingRule, requireIntegerNormal],
    fullCoupanList: [coupanRule],
    fullAmount: [amountRule, requireNumerical],
    fullRolling: [rollingRule, requireIntegerNormal]
  }
}

export const getRules = function() {
  return rules.call(this)
}

const DetailParams = function(field) {
  const {
    oneAmount,
    twoAmount,
    threeAmount,
    fourAmount,
    fiveAmount,
    sixAmount,
    sevenAmount,
    eightAmount,
    nineAmount,
    tenAmount,
    elevenAmount,
    twelveAmount,
    thirteenAmount,
    fourteenAmount,
    fifteenAmount,
    sixteenAmount,
    seventeenAmount,
    eighteenAmount,
    nineteenAmount,
    twentyAmount,
    twentyoneAmount,
    twentytwoAmount,
    twentythreeAmount,
    twentyfourAmount,
    twentyfiveAmount,
    twentysixAmount,
    twentysevenAmount,
    twentyeightAmount,
    twentynineAmount,
    thirtyAmount,
    thirtyOneAmount,
    oneRolling,
    twoRolling,
    threeRolling,
    fourRolling,
    fiveRolling,
    sixRolling,
    sevenRolling,
    eightRolling,
    nineRolling,
    tenRolling,
    elevenRolling,
    twelveRolling,
    thirteenRolling,
    fourteenRolling,
    fifteenRolling,
    sixteenRolling,
    seventeenRolling,
    eighteenRolling,
    nineteenRolling,
    twentyRolling,
    twentyoneRolling,
    twentytwoRolling,
    twentythreeRolling,
    twentyfourRolling,
    twentyfiveRolling,
    twentysixRolling,
    twentysevenRolling,
    twentyeightRolling,
    twentynineRolling,
    thirtyRolling,
    thirtyOneRolling,
    oneWeekCoupanList,
    twoWeekCoupanList,
    threeWeekCoupanList,
    fullCoupanList,
    oneWeekAmount,
    oneWeekRolling,
    twoWeekAmount,
    twoWeekRolling,
    threeWeekAmount,
    threeWeekRolling,
    fullAmount,
    fullRolling,
    ...other
  } = field
  let params = { ...other }
  params.oneAmountRolling = oneAmount + ',' + oneRolling
  params.twoAmountRolling = twoAmount + ',' + twoRolling
  params.threeAmountRolling = threeAmount + ',' + threeRolling
  params.fourAmountRolling = fourAmount + ',' + fourRolling
  params.fiveAmountRolling = fiveAmount + ',' + fiveRolling
  params.sixAmountRolling = sixAmount + ',' + sixRolling
  params.sevenAmountRolling = sevenAmount + ',' + sevenRolling
  params.eightAmountRolling = eightAmount + ',' + eightRolling
  params.nineAmountRolling = nineAmount + ',' + nineRolling
  params.tenAmountRolling = tenAmount + ',' + tenRolling
  params.elevenAmountRolling = elevenAmount + ',' + elevenRolling
  params.twelveAmountRolling = twelveAmount + ',' + twelveRolling
  params.thirteenAmountRolling = thirteenAmount + ',' + thirteenRolling
  params.fourteenAmountRolling = fourteenAmount + ',' + fourteenRolling
  params.fifteenAmountRolling = fifteenAmount + ',' + fifteenRolling
  params.sixteenAmountRolling = sixteenAmount + ',' + sixteenRolling
  params.seventeenAmountRolling = seventeenAmount + ',' + seventeenRolling
  params.eighteenAmountRolling = eighteenAmount + ',' + eighteenRolling
  params.nineteenAmountRolling = nineteenAmount + ',' + nineteenRolling
  params.twentyAmountRolling = twentyAmount + ',' + twentyRolling
  params.twentyoneAmountRolling = twentyoneAmount + ',' + twentyoneRolling
  params.twentytwoAmountRolling = twentytwoAmount + ',' + twentytwoRolling
  params.twentythreeAmountRolling = twentythreeAmount + ',' + twentythreeRolling
  params.twentyfourAmountRolling = twentyfourAmount + ',' + twentyfourRolling
  params.twentyfiveAmountRolling = twentyfiveAmount + ',' + twentyfiveRolling
  params.twentysixAmountRolling = twentysixAmount + ',' + twentysixRolling
  params.twentysevenAmountRolling = twentysevenAmount + ',' + twentysevenRolling
  params.twentyeightAmountRolling = twentyeightAmount + ',' + twentyeightRolling
  params.twentynineAmountRolling = twentynineAmount + ',' + twentynineRolling
  params.thirtyAmountRolling = thirtyAmount + ',' + thirtyRolling
  params.thirtyoneAmountRolling = thirtyOneAmount + ',' + thirtyOneRolling
  params.oneWeekAmountRolling = oneWeekAmount + ',' + oneWeekRolling
  params.twoWeekAmountRolling = twoWeekAmount + ',' + twoWeekRolling
  params.threeWeekAmountRolling = threeWeekAmount + ',' + threeWeekRolling
  params.fullAmountRolling = fullAmount + ',' + fullRolling
  params.oneWeekCoupan = oneWeekCoupanList.toString()
  params.twoWeekCoupan = twoWeekCoupanList.toString()
  params.threeWeekCoupan = threeWeekCoupanList.toString()
  params.fullCoupan = fullCoupanList.toString()
  return params
}

export const getSaveDetailParams = function(field) {
  return DetailParams.call(this, field)
}

const Params = function(data) {
  this.ruleForm.id = data.id
  this.ruleForm.checkinStatus = data.checkinStatus
  this.ruleForm.activityType = data.activityType
  this.ruleForm.betsAmount = data.betsAmount
  this.ruleForm.rechargeTimes = data.rechargeTimes
  this.ruleForm.rechargeAmount = data.rechargeAmount
  this.ruleForm.betsTotal = data.betsTotal
  this.ruleForm.rechargeTotal = data.rechargeTotal
  if (data.oneAmountRolling) {
    this.ruleForm.oneAmount = data.oneAmountRolling.split(',')[0]
    this.ruleForm.oneRolling = data.oneAmountRolling.split(',')[1]
  }
  if (data.twoAmountRolling) {
    this.ruleForm.twoAmount = data.twoAmountRolling.split(',')[0]
    this.ruleForm.twoRolling = data.twoAmountRolling.split(',')[1]
  }
  if (data.threeAmountRolling) {
    this.ruleForm.threeAmount = data.threeAmountRolling.split(',')[0]
    this.ruleForm.threeRolling = data.threeAmountRolling.split(',')[1]
  }
  if (data.fourAmountRolling) {
    this.ruleForm.fourAmount = data.fourAmountRolling.split(',')[0]
    this.ruleForm.fourRolling = data.fourAmountRolling.split(',')[1]
  }
  if (data.fiveAmountRolling) {
    this.ruleForm.fiveAmount = data.fiveAmountRolling.split(',')[0]
    this.ruleForm.fiveRolling = data.fiveAmountRolling.split(',')[1]
  }
  if (data.sixAmountRolling) {
    this.ruleForm.sixAmount = data.sixAmountRolling.split(',')[0]
    this.ruleForm.sixRolling = data.sixAmountRolling.split(',')[1]
  }
  if (data.sevenAmountRolling) {
    this.ruleForm.sevenAmount = data.sevenAmountRolling.split(',')[0]
    this.ruleForm.sevenRolling = data.sevenAmountRolling.split(',')[1]
  }
  if (data.eightAmountRolling) {
    this.ruleForm.eightAmount = data.eightAmountRolling.split(',')[0]
    this.ruleForm.eightRolling = data.eightAmountRolling.split(',')[1]
  }
  if (data.nineAmountRolling) {
    this.ruleForm.nineAmount = data.nineAmountRolling.split(',')[0]
    this.ruleForm.nineRolling = data.nineAmountRolling.split(',')[1]
  }
  if (data.tenAmountRolling) {
    this.ruleForm.tenAmount = data.tenAmountRolling.split(',')[0]
    this.ruleForm.tenRolling = data.tenAmountRolling.split(',')[1]
  }
  if (data.elevenAmountRolling) {
    this.ruleForm.elevenAmount = data.elevenAmountRolling.split(',')[0]
    this.ruleForm.elevenRolling = data.elevenAmountRolling.split(',')[1]
  }
  if (data.twelveAmountRolling) {
    this.ruleForm.twelveAmount = data.twelveAmountRolling.split(',')[0]
    this.ruleForm.twelveRolling = data.twelveAmountRolling.split(',')[1]
  }
  if (data.thirteenAmountRolling) {
    this.ruleForm.thirteenAmount = data.thirteenAmountRolling.split(',')[0]
    this.ruleForm.thirteenRolling = data.thirteenAmountRolling.split(',')[1]
  }
  if (data.fourteenAmountRolling) {
    this.ruleForm.fourteenAmount = data.fourteenAmountRolling.split(',')[0]
    this.ruleForm.fourteenRolling = data.fourteenAmountRolling.split(',')[1]
  }
  if (data.fifteenAmountRolling) {
    this.ruleForm.fifteenAmount = data.fifteenAmountRolling.split(',')[0]
    this.ruleForm.fifteenRolling = data.fifteenAmountRolling.split(',')[1]
  }
  if (data.sixteenAmountRolling) {
    this.ruleForm.sixteenAmount = data.sixteenAmountRolling.split(',')[0]
    this.ruleForm.sixteenRolling = data.sixteenAmountRolling.split(',')[1]
  }
  if (data.seventeenAmountRolling) {
    this.ruleForm.seventeenAmount = data.seventeenAmountRolling.split(',')[0]
    this.ruleForm.seventeenRolling = data.seventeenAmountRolling.split(',')[1]
  }
  if (data.eighteenAmountRolling) {
    this.ruleForm.eighteenAmount = data.eighteenAmountRolling.split(',')[0]
    this.ruleForm.eighteenRolling = data.eighteenAmountRolling.split(',')[1]
  }
  if (data.nineteenAmountRolling) {
    this.ruleForm.nineteenAmount = data.nineteenAmountRolling.split(',')[0]
    this.ruleForm.nineteenRolling = data.nineteenAmountRolling.split(',')[1]
  }
  if (data.twentyAmountRolling) {
    this.ruleForm.twentyAmount = data.twentyAmountRolling.split(',')[0]
    this.ruleForm.twentyRolling = data.twentyAmountRolling.split(',')[1]
  }
  if (data.twentyoneAmountRolling) {
    this.ruleForm.twentyoneAmount = data.twentyoneAmountRolling.split(',')[0]
    this.ruleForm.twentyoneRolling = data.twentyoneAmountRolling.split(',')[1]
  }
  if (data.twentytwoAmountRolling) {
    this.ruleForm.twentytwoAmount = data.twentytwoAmountRolling.split(',')[0]
    this.ruleForm.twentytwoRolling = data.twentytwoAmountRolling.split(',')[1]
  }
  if (data.twentythreeAmountRolling) {
    this.ruleForm.twentythreeAmount = data.twentythreeAmountRolling.split(',')[0]
    this.ruleForm.twentythreeRolling = data.twentythreeAmountRolling.split(',')[1]
  }
  if (data.twentyfourAmountRolling) {
    this.ruleForm.twentyfourAmount = data.twentyfourAmountRolling.split(',')[0]
    this.ruleForm.twentyfourRolling = data.twentyfourAmountRolling.split(',')[1]
  }
  if (data.twentyfiveAmountRolling) {
    this.ruleForm.twentyfiveAmount = data.twentyfiveAmountRolling.split(',')[0]
    this.ruleForm.twentyfiveRolling = data.twentyfiveAmountRolling.split(',')[1]
  }
  if (data.twentysixAmountRolling) {
    this.ruleForm.twentysixAmount = data.twentysixAmountRolling.split(',')[0]
    this.ruleForm.twentysixRolling = data.twentysixAmountRolling.split(',')[1]
  }
  if (data.twentysevenAmountRolling) {
    this.ruleForm.twentysevenAmount = data.twentysevenAmountRolling.split(',')[0]
    this.ruleForm.twentysevenRolling = data.twentysevenAmountRolling.split(',')[1]
  }
  if (data.twentyeightAmountRolling) {
    this.ruleForm.twentyeightAmount = data.twentyeightAmountRolling.split(',')[0]
    this.ruleForm.twentyeightRolling = data.twentyeightAmountRolling.split(',')[1]
  }
  if (data.twentynineAmountRolling) {
    this.ruleForm.twentynineAmount = data.twentynineAmountRolling.split(',')[0]
    this.ruleForm.twentynineRolling = data.twentynineAmountRolling.split(',')[1]
  }
  if (data.thirtyAmountRolling) {
    this.ruleForm.thirtyAmount = data.thirtyAmountRolling.split(',')[0]
    this.ruleForm.thirtyRolling = data.thirtyAmountRolling.split(',')[1]
  }
  if (data.thirtyoneAmountRolling) {
    this.ruleForm.thirtyOneAmount = data.thirtyoneAmountRolling.split(',')[0]
    this.ruleForm.thirtyOneRolling = data.thirtyoneAmountRolling.split(',')[1]
  }
  if (data.oneWeekCoupan) {
    this.ruleForm.oneWeekCoupanList = data.oneWeekCoupan.split(',')
  }
  if (data.twoWeekCoupan) {
    this.ruleForm.twoWeekCoupanList = data.twoWeekCoupan.split(',')
  }
  if (data.threeWeekCoupan) {
    this.ruleForm.threeWeekCoupanList = data.threeWeekCoupan.split(',')
  }
  if (data.fullCoupan) {
    this.ruleForm.fullCoupanList = data.fullCoupan.split(',')
  }
  if (data.oneWeekAmountRolling) {
    this.ruleForm.oneWeekAmount = data.oneWeekAmountRolling.split(',')[0]
    this.ruleForm.oneWeekRolling = data.oneWeekAmountRolling.split(',')[1]
  }
  if (data.twoWeekAmountRolling) {
    this.ruleForm.twoWeekAmount = data.twoWeekAmountRolling.split(',')[0]
    this.ruleForm.twoWeekRolling = data.twoWeekAmountRolling.split(',')[1]
  }
  if (data.threeWeekAmountRolling) {
    this.ruleForm.threeWeekAmount = data.threeWeekAmountRolling.split(',')[0]
    this.ruleForm.threeWeekRolling = data.threeWeekAmountRolling.split(',')[1]
  }
  if (data.fullAmountRolling) {
    this.ruleForm.fullAmount = data.fullAmountRolling.split(',')[0]
    this.ruleForm.fullRolling = data.fullAmountRolling.split(',')[1]
  }

  this.ruleForm.checkinContentZh = data.checkinContentZh
  this.ruleForm.checkinContentEn = data.checkinContentEn
  this.ruleForm.checkinContentVi = data.checkinContentVi
  this.ruleForm.checkinContentGu = data.checkinContentGu
  this.ruleForm.checkinContentJa = data.checkinContentJa
  this.ruleForm.checkinContentMs = data.checkinContentMs
}

export const parameterParsing = function(field) {
  return Params.call(this, field)
}
