<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.name" maxlength="20" :placeholder="$t('商品名称')" class="filter-item" style="width: 310px" clearable />
      <el-select v-model="search.categoryId" :placeholder="$t('商品类目')" class="filter-item" style="width: 220px" clearable filterable multiple collapse-tags>
        <el-option v-for="item in category" :key="item.id" :value="item.id" :label="item.categoryName" />
      </el-select>
      <el-select v-model="search.status" :placeholder="$t('商品状态')" class="filter-item" style="width: 140px" clearable>
        <el-option v-for="item in goodsStatus" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
<!--      <el-button v-if="permission.Add" style="float:right" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">{{ $t('新建商品') }}</el-button>-->
      <el-button style="float:right" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">{{ $t('新建商品') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <GoodsDialog ref="goodsDialog" @create="handleCreate" @update="handleUpdate" />
  </div>
</template>

<script>
import { goodsStatus } from '@/libs/options'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getCategoryList, getQueryList, createGoods, onOrOff, updateGoods, deleteGoods } from '@/api/points'
import GetColumns from './columns'
import GoodsDialog from './GoodsDialog'
import { mapState } from 'vuex'

export default {
  name: 'GoodsConfig',
  components: { GoodsDialog },
  mixins: [TablePageMixin(getQueryList)],
  data() {
    return {
      search: {
        categoryId: []
      },
      filterColumn: false,
      goodsStatus,
      columns: GetColumns.call(this),
      category: []
    }
  },
  computed: {
    ...mapState('app', ['allLang'])
  },
  created() {
    getCategoryList({ categoryName: '', langCode: '', currentPage: 1, pageSize: 50 }).then(([items, err]) => {
      this.category = items
    })
  },
  methods: {
    // 点击 新建商品
    onCreate() {
      this.$refs.goodsDialog.open()
    },

    getField(field) {
      const arr = []
      const introduction = field.introduction
      for (const el of introduction) {
        arr.push(el.response?.data[0] || el.url)
      }
      field.introduction = arr
    },

    // 点击 新建商品-保存
    handleCreate({ field, cancel, close }) {
      this.getField(field)
      createGoods(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新建成功!'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    // 点击 修改商品-保存
    handleUpdate({ field, cancel, close }) {
      this.getField(field)
      updateGoods(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('修改成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    // 点击 上架、下架-确定
    handleOnOrOff({ id }, status) {
      this.$confirm(`${this.$t('确定')}${status === 0 ? this.$t('上架') : this.$t('下架')}${this.$t('商品吗？')}`, this.$t('系统提示'), { type: 'warning' }).then(() => {
        onOrOff({ id, status }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    // 点击 删除-确定
    handleDelete({ id }) {
      this.$confirm(this.$t('确定删除商品吗？'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        deleteGoods({ id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    }

  }
}
</script>
