var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "指定会员",
        width: "550px",
        top: "8vh",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "会员账号", prop: "memberName" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    maxlength: "20",
                    placeholder: "输入会员账号，最长20个字符",
                  },
                  model: {
                    value: _vm.ruleForm.memberName,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "memberName",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.memberName",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        color: "#FFFFFF",
                        "background-color": "#3894FF",
                        border: "1px solid #3894FF",
                      },
                      attrs: {
                        slot: "append",
                        loading: _vm.selecting,
                        type: "primary",
                      },
                      on: { click: _vm.queryMember },
                      slot: "append",
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.memberInfo.memberName &&
          _vm.memberInfo.memberName === _vm.ruleForm.memberName
            ? _c("el-form-item", [
                _c("div", [
                  _c("span", [
                    _vm._v("会员账号：" + _vm._s(_vm.memberInfo.memberName)),
                  ]),
                  _c("span", { staticStyle: { "margin-left": "30px" } }, [
                    _vm._v("会员昵称：" + _vm._s(_vm.memberInfo.nickname)),
                  ]),
                ]),
                _c("div", [
                  _vm._v(
                    "会员类型：" +
                      _vm._s(
                        _vm.memberTypes.find(function (e) {
                          return e.key === _vm.memberInfo.memberType
                        }).label
                      )
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "限制类型", prop: "limitType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择限制类型" },
                  model: {
                    value: _vm.ruleForm.limitType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "limitType", $$v)
                    },
                    expression: "ruleForm.limitType",
                  },
                },
                _vm._l(_vm.limitTypes, function (item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item, value: +key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          [3, 4].includes(_vm.ruleForm.limitType)
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "房间类型", prop: "roomType" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择房间类型" },
                        on: { change: _vm.handleChangeRoomType },
                        model: {
                          value: _vm.ruleForm.roomType,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "roomType", $$v)
                          },
                          expression: "ruleForm.roomType",
                        },
                      },
                      _vm._l(_vm.roomTypes, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: {
                            label: item.label,
                            value: item.value.toString(),
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "聊天室房间", prop: "groupId" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          filterable: "",
                          placeholder: "请选择聊天室房间",
                        },
                        model: {
                          value: _vm.ruleForm.groupId,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "groupId", $$v)
                          },
                          expression: "ruleForm.groupId",
                        },
                      },
                      _vm._l(_vm.roomList, function (item) {
                        return _c("el-option", {
                          key: item.roomId,
                          attrs: { label: item.roomName, value: item.roomId },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "禁言时长", prop: "expireMin" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "220px" },
                  attrs: {
                    disabled: _vm.minEver,
                    placeholder: "请输入禁言时长",
                  },
                  model: {
                    value: _vm.ruleForm.expireMin,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "expireMin", _vm._n($$v))
                    },
                    expression: "ruleForm.expireMin",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("分钟")])],
                2
              ),
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-left": "30px" },
                  on: { change: _vm.handleChangeMin },
                  model: {
                    value: _vm.minEver,
                    callback: function ($$v) {
                      _vm.minEver = $$v
                    },
                    expression: "minEver",
                  },
                },
                [_vm._v("永久")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }