var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "border-form-dialog",
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "380px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "show-message": false,
            "label-width": "95px",
          },
        },
        [
          _c("div", { staticClass: "receivable-item" }, [
            _c("h2", [_vm._v("应缴费用")]),
            _vm._v(" " + _vm._s(_vm.ruleForm.receivableAccount) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "彩票抽成" } }, [
            _vm._v(_vm._s(_vm.ruleForm.betsShareIncome)),
          ]),
          _c("el-form-item", { attrs: { label: "礼物抽成" } }, [
            _vm._v(_vm._s(_vm.ruleForm.giftShareIncome)),
          ]),
          _c("el-form-item", { attrs: { label: "房费抽成" } }, [
            _vm._v(_vm._s(_vm.ruleForm.payroomShareIncome)),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "美颜费用", prop: "beautyCost" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.beautyCost,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "beautyCost", $$v)
                  },
                  expression: "ruleForm.beautyCost",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务器费用", prop: "serverCost" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.serverCost,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "serverCost", $$v)
                  },
                  expression: "ruleForm.serverCost",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "维护费用", prop: "maintenanceCost" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.maintenanceCost,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "maintenanceCost", $$v)
                  },
                  expression: "ruleForm.maintenanceCost",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "其他费用", prop: "additionalCost" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.additionalCost,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "additionalCost", $$v)
                  },
                  expression: "ruleForm.additionalCost",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 5 },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 确认 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }