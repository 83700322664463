<template>
  <el-dialog :title="`${title}心水标签`" :visible.sync="visible" width="500px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="称号" prop="rankName">
        <el-input v-model="ruleForm.rankName" placeholder="2~4个中文" />
      </el-form-item>
      <el-form-item label="点赞数">
        <el-row>
          <el-col :span="10">
            <el-form-item prop="minAdmire">
              <el-input v-model="ruleForm.minAdmire" placeholder="请输入整数" />
            </el-form-item>
          </el-col>
          <el-col :span="4" style="text-align: center">~</el-col>
          <el-col :span="10">
            <el-form-item prop="maxAdmire">
              <el-input v-model="ruleForm.maxAdmire" placeholder="请输入整数" />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        确认
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  rankName: '',
  minAdmire: '',
  maxAdmire: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      rules: {
        rankName: [
          { required: true, message: '请填写称号', trigger: 'blur' },
          { pattern: /^[\u4e00-\u9fa5]{2,4}$/, message: '请输入2~4个中文', trigger: 'blur' }
        ],
        minAdmire: [
          { required: true, message: '请填写最小点赞数', trigger: 'blur' },
          { pattern: /^\d+$/, message: '请输入整数', trigger: 'blur' }
        ],
        maxAdmire: [
          { required: true, message: '请填写最大点赞数', trigger: 'blur' },
          { pattern: /^\d+$/, message: '请输入整数', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? '添加' : '编辑'
    }
  },
  methods: {
    open(row) {
      if (row) {
        this.emitName = 'update'
        this.ruleForm.id = row.id
        this.ruleForm.rankName = row.rankName
        this.ruleForm.minAdmire = row.minAdmire
        this.ruleForm.maxAdmire = row.maxAdmire
      } else {
        this.emitName = 'create'
      }
      this.visible = true
    }
  }
}
</script>
