<template>
  <div class="app-container">
    <div class="filter-container">
      <span>发放时间：</span>
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        value-format="yyyy-MM-dd HH:mm:ss"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0, false)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1, false)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7, false)">{{ $t('本周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 14, false)">{{ $t('上周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30, false)">{{ $t('本月') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60, false)">{{ $t('上月') }}</el-button>
    </div>
    <div class="filter-container">
      <span>领取时间：</span>
      <el-date-picker
        v-model="times1"
        clearable
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        value-format="yyyy-MM-dd HH:mm:ss"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0, false, 'times1')">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1, false, 'times1')">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7, false, 'times1')">{{ $t('本周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 14, false, 'times1')">{{ $t('上周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30, false, 'times1')">{{ $t('本月') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60, false, 'times1')">{{ $t('上月') }}</el-button>
    </div>
    <div class="filter-container">
      <el-input v-model="search.id" clearable placeholder="订单编号" class="filter-item" style="width: 160px"/>
      <el-select v-model="search.awardType" clearable placeholder="红包类型" class="filter-item" style="width: 160px">
        <el-option v-for="(val, key) in awardTypes" :key="key" :label="val" :value="key" />
      </el-select>
      <el-select v-model="search.recordStatus" clearable placeholder="红包状态" class="filter-item" style="width: 160px">
        <el-option v-for="(val, key) in recordStatus" :key="key" :label="val" :value="key" />
      </el-select>
      <el-select v-model="search.freezeStatus" clearable placeholder="vip等级" class="filter-item" style="width: 160px">
        <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
      </el-select>
    </div>
    <div class="filter-container">
      <el-select v-model="search.memberType" clearable placeholder="会员类型" class="filter-item" style="width: 160px">
        <el-option v-for="item in memberTypes" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-input v-model="search.memberName" clearable placeholder="会员账号" class="filter-item" style="width: 160px"/>
      <el-select v-model="search.freezeStatus" clearable placeholder="用户状态" class="filter-item" style="width: 160px">
        <el-option :label="$t('启用')" :value="0" />
        <el-option :label="$t('停用')" :value="1" />
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :originalColumns="defaultColumns"
      @refresh="handleSearch"
      :options="options"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <div class="total-list">
          <span>周红包领取人数：{{ Number(statistic.receivedWeekMemberCount) }}</span>
          <span>周红包领取总金额：{{ Number(statistic.receivedWeekAmount) }}</span>
          <span>月红包领取人数：{{ Number(statistic.receivedMonthMemberCount) }}</span>
          <span>月红包领取总金额：{{ Number(statistic.receivedMonthAmount) }}</span>
        </div>
      </template>
    </d-table>
    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>
<script>
import { queryPageIssueAwardList, queryIssueAwardStatistic } from '@/api/vipRewardMange'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getDefaultTimes } from '@/utils'
import { awardTypes, recordStatus, memberTypes } from '@/libs/options'
import { getAllVipLevels } from '@/api/app'
export default {
  name: 'RecodeTab',
  mixins: [TablePageMixin(queryPageIssueAwardList)],
  data() {
    return {
      times: getDefaultTimes('timestamp', 0, 0, false),
      times1: null,
      columns: [],
      defaultColumns: GetColumns.call(this),
      awardTypes,
      recordStatus,
      vipLevels: [],
      memberTypes,
      statistic: {}
    }
  },
  created() {
    this.handleVipLevels()
    this.handleQueryStatistic()
  },
  methods: {
    handleSearch() {
      this.handleRefresh()
      this.handleQueryStatistic()
    },
    handleQueryStatistic() {
      queryIssueAwardStatistic(this.implementationGetParams()).then(([data, err]) => {
        if (!err) {
          this.statistic = data
        }
      })
    },
    handleVipLevels() {
      getAllVipLevels().then(([items, err]) => {
        if (!err) {
          this.vipLevels = items
        }
      })
    },
    implementationGetParams() {
      const search = { ...this.search }
      if (this.times && this.times.length === 2) {
        let [startTime, endTime] = this.times
        search.issueAwardStartTime = startTime
        search.issueAwardEndTime = endTime
      } else {
        delete search.issueAwardStartTime
        delete search.issueAwardEndTime
      }
      if (this.times1 && this.times1.length === 2) {
        let [startTime, endTime] = this.times1
        search.receiveAwardStartTime = startTime
        search.receiveAwardEndTime = endTime
      } else {
        delete search.receiveAwardStartTime
        delete search.receiveAwardEndTime
      }
      return search
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
