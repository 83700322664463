var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "singleImageUpload2 upload-container" },
    [
      _c(
        "el-upload",
        {
          staticClass: "image-uploader",
          attrs: {
            accept: _vm.accept,
            data: _vm.dataObj,
            multiple: false,
            "show-file-list": false,
            "on-success": _vm.handleImageSuccess,
            headers: _vm.$headers(),
            "before-upload": _vm.beforeAvatarUpload,
            drag: "",
            action: _vm.actionUrl,
          },
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v(" Drag " + _vm._s(_vm.$t("或")) + " "),
            _c("em", [_vm._v(_vm._s(_vm.$t("点击上传")))]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.imageUrl.length > 0,
              expression: "imageUrl.length>0",
            },
          ],
          staticClass: "image-preview",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.imageUrl.length > 1,
                  expression: "imageUrl.length>1",
                },
              ],
              staticClass: "image-preview-wrapper",
            },
            [
              _c("img", { attrs: { src: _vm.imageUrl } }),
              _c("div", { staticClass: "image-preview-action" }, [
                _c("i", {
                  staticClass: "el-icon-delete",
                  on: { click: _vm.rmImage },
                }),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }