<template>
  <div class="app-container">
    <el-tabs type="border-card">
      <el-tab-pane v-for="(item, index) in agencyMemberType" :key="index" :label="item.label">
        <ConfigItem :key="index" :agency-member-type="item.value" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ConfigItem from './components/ConfigItem.vue'
export default {
  name: 'ConfigTab',
  components: { ConfigItem },
  data() {
    return {
      agencyMemberType: this.$t('agencyMemberType')
    }
  }
}
</script>

