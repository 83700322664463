const CanChooseColumns = function () {
    return [
      {
        title: "会员账号",
        key: "memberName",
        align: "center",
      },
      {
        title: "昵称",
        key: "nickName",
        align: "center",
      },
      {
        title: "账号类型",
        key: "guruType",
        align: "center",
        component: {
          render: (h, row) => {
            switch (row.guruType) {
              case 1:
                return <span>内部账号</span>;
              case 2:
                return <span>普通账号</span>;
              case 3:
                return <span>普通账号</span>;
              default:
                return <span>{row.guruType}</span>;
            }
          },
        },
      },
      {
        title: "申请时间",
        key: "applicationTime",
        align: "center",
        component: {
          render: (h, row) => {
            return <span>{this.$parseTime(row.applicationTime)}</span>;
          },
        },
      },
      {
        title: "申请状态",
        key: "guruStatus",
        align: "center",
        width: 200,
        component: {
          render: (h, row) => {
            switch (row.guruStatus) {
              case 0:
                return <span>申请中待审核</span>;
              case 1:
                return <span>审核不通过</span>;
              case 2:
                return <span>审核通过</span>;
              case 3:
                return <span>被取消资格</span>;
              default:
                break;
            }
          },
        },
      },
      {
        title: "操作时间",
        align: "center",
        key: "operatingTime",
        width: 200,
        component: {
          render: (h, row) => {
            return <span>{this.$parseTime(row.operatingTime)}</span>;
          },
        },
      },
      {
        title: "操作用户",
        align: "center",
        key: "operatingUser",
      },
      {
        title: "备注",
        align: "center",
        key: "remark",
        width: 200,
        component: {
          render: (h, row) => {
            if (row.guruStatus == 2) return;
            return <span>{row.remark}</span>;
          },
        },
      },
    ];
  };
  
  export default function (keys = []) {
    return CanChooseColumns.call(this);
  }
  