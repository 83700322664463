<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="600px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="180px" label-suffix=":">
      <el-form-item :label="$t('排序')" prop="sortBy">
        <el-input v-model="ruleForm.sortBy" style="width: 90%" :placeholder="$t('请输入排序')" />
      </el-form-item>
      <el-form-item v-if="ruleForm.items && ruleForm.items.length > 0" :label="$t('频道分类')" prop="categoryName" label-width="180px">
        <el-popover placement="bottom" title="频道分类多语言配置" trigger="click">
          <el-input slot="reference" style="width: 90%" :value="categoryName" readonly />
          <div class="lang-pop" style="width: 342px;">
            <el-input v-for="item of ruleForm.items" :key="item.langCode" v-model="item.categoryName" :placeholder="$t(item.langName)" />
          </div>
        </el-popover>
      </el-form-item>
      <el-form-item :label="$t('分类图标')" prop="categoryIcon">
        <div class="upload">
          <el-upload
            accept="image/png,image/jpg,image/jpeg,image/webp,image/*"
            :action="action"
            :data="{ type: 'image', folder: 'icon' }"
            :headers="$headers()"
            :show-file-list="false"
            :on-success="_handleSuccess"
          >
            <img v-if="ruleForm.categoryIcon" :src="ruleForm.categoryIcon" class="image">
            <i v-else class="el-icon-upload" />
          </el-upload>
          <i class="el-icon-warning" /> 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。
        </div>
      </el-form-item>
      <el-form-item :label="$t('所属频道')" prop="channelUnionId">
        <el-select
          v-model="ruleForm.channelUnionId"
          filterable
          clearable
          collapse-tags
          placeholder="请选择所属频道"
          style="width: 90%"
        >
          <el-option
            v-for="item in $parent.channelList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('数据来源')" prop="dataSource">
        <el-radio v-for="item of $parent.dataSource" :key="item.key" v-model="ruleForm.dataSource" :label="item.key">{{ item.label }}</el-radio>
        <div v-if="ruleForm.dataSource === '10'">
          <el-cascader
            @change="handleLottertChange"
            v-model="ruleForm.lotteryIds"
            :props="{multiple: true}"
            :options="cascaderOptions"
            style="width: 90%"
            :placeholder="$t('请选择彩种')"
          />
        </div>
        <div v-else>
          <el-select v-model="ruleForm.dataSourceLive" style="width: 90%">
            <el-option v-for="item in $parent.dataSourceLive" :key="item.key" :value="item.key" :label="item.label" />
          </el-select>
        </div>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button v-if="emitName !== 'details'" type="primary" :loading="submitting" @click="handleSubmitBefor">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { mapState } from 'vuex'
import navigationMixin from '@/views/lottery/navigationMixin'
import { getLotteryNavigationList } from '@/api/lottery'
const getDefaultForm = () => ({
  unionId: '',
  sortBy: '',
  items: [],
  categoryIcon: '',
  channelUnionId: '',
  channelName: '',
  categoryName: '',
  dataSource: '10',
  dataSourceLive: '20',
  lotteryIds: []
})

export default {
  mixins: [FormDialogMixin(getDefaultForm), navigationMixin(getLotteryNavigationList)],
  data() {
    return {
      emitName: '',
      rules: {
        categoryName: [{ required: true, message: this.$t('请输入中文频道分类'), trigger: 'blur' }],
        sortBy: [{ required: true, message: this.$t('请输入排序'), trigger: 'blur' }],
        channelUnionId: [{ required: true, message: this.$t('请选择所属频道'), trigger: 'blur' }],
        categoryIcon: [{ required: true, message: this.$t('请上传频道图标'), trigger: 'change' }]
      },
      cascaderOptions: []
    }
  },
  computed: {
    ...mapState('app', ['allLang']),

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    title() {
      return this.emitName === 'create' ? this.$t('新增分类') : this.$t('修改分类')
    },
    categoryName() {
      if (this.ruleForm.items.length > 0) {
        return this.ruleForm.items.find(e => e.langCode === 'zh_CN').categoryName
      }
      return ''
    }
  },
  watch: {
    categoryName(val) {
      this.ruleForm.categoryName = val
    }
  },
  created() {
    this.onFetch()
  },
  methods: {
    open(row) {
      const _this = this
      _this.emitName = 'create'
      const items = _this.$parent.allLang.map(e => { return { langCode: e.lang, langName: e.name, categoryId: '', categoryName: '' } })
      if (row) {
        items.forEach(e => {
          const formLang = row.items.find(r => r.langCode === e.langCode)
          if (formLang) {
            e.categoryId = formLang.categoryId
            e.categoryName = formLang.categoryName
          }
        })
        _this.ruleForm.unionId = row.unionId
        _this.ruleForm.unionId = row.unionId
        _this.ruleForm.sortBy = row.sortBy
        _this.ruleForm.categoryIcon = row.categoryIcon
        _this.ruleForm.channelUnionId = row.channelUnionId
        _this.ruleForm.channelName = row.channelName
        _this.ruleForm.dataSource = row.dataSource
        if (row.dataSource === '10' && row.lotteryIds && row.lotteryIds.length > 0) {
          const lotteryIds = []
          this.cascaderOptions.forEach(e => {
            e.children.forEach(v => {
              if (row.lotteryIds.includes(v.value)) {
                lotteryIds.push([e.value, v.value])
              }
            })
          })
          _this.ruleForm.lotteryIds = lotteryIds
        }
        _this.emitName = 'update'
      }
      _this.ruleForm.items = items
      _this.visible = true
    },
    _handleSuccess(res) {
      this.ruleForm.categoryIcon = res.data[0]
      this.$refs.validateForm.validateField('categoryIcon')
    },
    handleLottertChange() {
      if (this.ruleForm.lotteryIds.length > 10) {
        this.ruleForm.lotteryIds = this.ruleForm.lotteryIds.splice(0, 10)
        this.$message.error('数据来源的彩种最多只能选10个, 已剔除多余选项')
      }
    },
    handleSubmitBefor() {
      let msg = ''
      this.ruleForm.items.forEach(e => {
        if (this.ruleForm.items.some(r => r.langCode !== e.langCode && r.categoryName === e.categoryName)) {
          msg = '每个语言的频道分类名称不能相同，请检查'
          return
        }
        if (e.categoryName === '' || e.categoryName === null) {
          msg = `${e.channelDesc}的频道分类名称不能为空`
          return
        }
      })
      if (msg !== '') {
        this.$message.error(msg)
        return
      }
      this.handleSubmit()
    }
  }
}
</script>

<style lang="scss" scoped>

.upload {
  display: flex;
  align-items: flex-start;
  color: #BCBCBC;
  > div {
    margin-top: 5px;
  }
  > i {
    margin-top: 9px;
    margin-left: 25px;
    margin-right: 5px;
  }
  ::v-deep .el-upload {
     border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .image {
    width: 50px;
    height: 50px;
  }
}
.lang-pop {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
