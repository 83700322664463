import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    // {
    //   title: '订单号',
    //   key: 'orderNo',
    //   align: 'center',
    //   width: 140
    // },
    // {
    //   title: '会员账号',
    //   key: 'memberName',
    //   align: 'center',
    //   width: 120,
    //   component: {
    //     render: (h, row) => {
    //       return (<span class='primary-type cursor-pointer hover-underline' onClick={this._onDetails.bind(this, row)}>{ row.memberName }</span>)
    //     }
    //   }
    // },
    {
      title: '支付方式',
      key: 'payPaywayName',
      align: 'center'
    },
    {
      title: '平台名称',
      key: 'payPlatformName',
      align: 'center'
    },
    {
      title: '用户数',
      key: 'numberCount',
      align: 'center'
    },
    {
      title: '成功用户数',
      key: 'successNumber',
      align: 'center'
    },
    {
      title: '成功金额',
      key: 'successAmount',
      align: 'center'
    },
    {
      title: '失败金额',
      key: 'failAmount',
      align: 'center'
    },
    {
      title: '待处理金额',
      key: 'pendAmount',
      align: 'center'
    },
    {
      title: '总金额',
      key: 'totalAmount',
      align: 'center'
    },
    {
      title: '成功订单数',
      key: 'successOrder',
      align: 'center'
    },
    {
      title: '失败订单数',
      key: 'failOrder',
      align: 'center'
    },
    {
      title: '待处理订单数',
      key: 'pendOrder',
      align: 'center'
    },
    {
      title: '总订单数',
      key: 'totalOrder',
      align: 'center'
    },
    {
      title: '订单成功率(%)',
      key: 'successRate',
      align: 'center'
    }
    // {
    //   title: '实付金额',
    //   key: 'actually',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (<span class={this.$parseColor(row.payAmount)}>{ row.payAmount }</span>)
    //     }
    //   }
    // },
    // {
    //   title: '支付状态',
    //   key: 'status',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       const tmp = this.paymentStatus.find(o => o.key === row.status)
    //       if (tmp) {
    //         return (<span class={`${tmp.type}-type`}>{ tmp.label }</span>)
    //       }
    //     }
    //   }
    // },
    // {
    //   title: '创建时间',
    //   key: 'createTime',
    //   align: 'center',
    //   width: 150,
    //   component: {
    //     render: (h, row) => {
    //       return (<span>{ this.$parseTime(row.createTime) }</span>)
    //     }
    //   }
    // },
    // {
    //   title: '补单记录',
    //   key: 'remark',
    //   align: 'center'
    // },
    // {
    //   title: '操作',
    //   key: 'actions',
    //   align: 'center',
    //   width: 150,
    //   component: {
    //     render: (h, row) => {
    //       if (row.status === '1111' && this.permission.Again) {
    //         return (<el-button type='danger' size='small' onClick={this.handleAgain.bind(this, row)}>补单</el-button>)
    //       }
    //     }
    //   }
    // }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
