<template>
  <div class="chat-page container">
    <div class="chat-btn">
      <el-button type="primary" size="medium" v-if="permissionTabs.ScheduledTasksTab"
        @click="$router.push('/Interactive_ScheduledTasks')">{{ $t("定时消息管理") }}</el-button>
      <el-button type="primary" size="medium" @click="$router.push('/Interactive_Notice')">{{ $t("群公告管理") }}</el-button>
      <el-button class="filter-btn" size="medium" @click="_handleOpenSensitive">{{
        $t("敏感词过滤")
      }}</el-button>
      <el-button type="primary" size="medium" @click="toLiveBehaviorRestriction">{{ $t("行为限制管理") }}</el-button>

      <!-- <el-button type="primary" size="medium" v-if="permissionTabs.RobotOptionsTab"
        @click="$router.push('/Interactive_RobotOptions')">{{ $t("机器人配置") }}</el-button> -->
    </div>
    <!-- 左侧 -->
    <div class="room-card">
      <div class="room-card-head">
        <el-select v-model="roomType" class="room-type" @change="_getRoomList">
          <el-option v-for="item in roomTypes" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <el-input v-model="roomName" :placeholder="$t('请输入', { text: $t('聊天室名称') })" class="room-search"
          @keyup.enter.native="_filterRoomList">
          <el-button slot="append" @click="_filterRoomList">{{
            $t("查找")
          }}</el-button>
        </el-input>
      </div>
      <!-- 左侧房间列表-->
      <div ref="scroller" v-loading="roomLoading" class="room-card-body">
        <div class="card-body__inner">
          <chat-room v-for="item in showRoomList" :key="item.id" :item="item" :active-room-id="activeRoomId"
            :can-edit="roomType === 1" @enterRoom="_enterRoom" @clickIcon="_handleUpdateRoom" />
        </div>
      </div>
      <div v-if="roomType === 1" class="room-card-footer">
        <el-button @click="_handleCreateRoom">
          {{ $t("新增") }}
        </el-button>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="card">
      <div class="card-head">
        <el-button @click="_clear">{{ $t("清屏") }}</el-button>
        <div>{{ $t("真实人数") }}: {{ roomRealCount }}</div>
        <div>
          {{ $t("追加人数") }}:
          <el-input type="number" v-model.number="VirtualCount" style="width: 80px; margin-right: 10px" />
          <el-button type="success" @click="_setCount">{{
            $t("确认")
          }}</el-button>
        </div>
        <el-input v-model="searchContent" :placeholder="$t('请输入', { text: $t('聊天室内容') })" class="search-content"
          @keyup.enter.native="_filterMsgContent">
          <el-button slot="append" @click="_filterMsgContent">{{
            $t("查找")
          }}</el-button>
        </el-input>
      </div>
      <!-- 聊天室 -->
      <div ref="chatScroller" class="card-body">
        <div class="card-body__inner">
          <chat-item v-for="item in filterChatList" :key="item.serverMsgId" :item="item"
            :content-type="item.contentType" :me="operatorId === item.from" @on-action="_handleActionItem">{{
              item.content }}</chat-item>
        </div>
      </div>
      <div class="card-footer">
        <!-- <el-upload
          :accept="accept"
          :data="{ type: 'image', folder: 'chat-image' }"
          :action="action"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleImageSuccess"
          :before-upload="handleBeforeUpload"
        >
          <i class="el-icon-picture" />
        </el-upload> -->
        <!-- 表情包弹窗气泡 -->
        <el-popover v-model="emoShow" placement="top-start" width="350" trigger="click">
          <div class="emo-pop">
            <div v-for="emoItem of emojiArr" :key="emoItem" class="emoItem" @click="addEmo(emoItem)">
              {{ emoItem }}
            </div>
          </div>
          <img slot="reference" class="emo-img" :src="require(`@/assets/public_images/${emoShow ? 'icon-chat-keyboard' : 'icon-chat-emoji'
            }.png`)
            " />
        </el-popover>
        <!-- 输入框  -->
        <el-input v-model="content" :placeholder="$t('输入信息')" type="textarea" :autosize="{ minRows: 1, maxRows: 10 }" />

        <!-- <el-popover
          placement="top-end"
          width="300"
          trigger="click"
        >
          <chat-enjoy @on-enjoy="onEnjoy" />
          <svg-icon slot="reference" icon-class="enjoy" />
        </el-popover> -->

        <i class="el-icon-s-promotion" style="color: #e91e63" @click="_sendNormalMessage" />
      </div>
      <!-- 删除消息 -->
      <chat-disable ref="ChatDisableControl" @submit="_handleMute" />
      <!-- 新增编辑左侧 -->
      <ActionChatRoomDialog ref="ActionChatRoomDialogControl" @deleteRoom="_deleteRoom" @update="_updateRoom"
        @create="_createRoom" />
      <!-- 敏感词过滤 -->
      <SensitiveDialog ref="SensitiveDialogControl" @submit="_submitSensitive" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  getImToken,
  getLiveRoomList,
  editRoom,
  addRoom,
  addSensitive,
  onlineNum,
} from "@/api/chat";
import { getPlatformDomainImList } from "@/api/permission.js";
import { getRoomMessage } from "@/api/chatNew";
import { getThirdMatchList, getPredictionMatchList } from "@/api/match";
import { getonLiveForbidLineRoom } from "@/api/live";
import { parseTime } from "@/utils";
import SocketMixin from "./socket";
import WebSocketWithHeartbeat from "./socketNew";
import ChatItem from "./components/chat-item";
import ChatRoom from "./components/chat-room";
import ActionChatRoomDialog from "./components/ActionChatRoomDialog.vue";
import SensitiveDialog from "./components/SensitiveDialog.vue";
import ChatDisable from "./components/chat-disable";
import emojiArr from "./emojiArr.json";
import { createUniqueString } from "@/utils";
import { getLotterySettingList, getLotteryNavigationHot } from "@/api/config";
import { parentMixin } from '@/mixins/tab-items'

export default {
  name: "InteractiveChat",
  components: {
    ChatItem,
    ChatDisable,
    ChatRoom,
    ActionChatRoomDialog,
    SensitiveDialog,
  },
  mixins: [SocketMixin(), parentMixin],
  props: {
    accept: {
      type: String,
      default: "image/jpeg,image/png,image/jpg",
    },
  },
  data() {
    return {
      VirtualCount: null,
      content: "",
      beforeMessage: null,
      activeRoomId: "",
      activeRoomName: "",
      roomType: 1,
      roomName: "",
      searchContent: "",
      roomTypes: [
        { label: "互动大厅", value: 1, type: "lottery" },
        { label: "赛事-滚球", value: 2, type: "sport" },
        { label: "赛事-竞彩", value: 5, type: "sport" },
        { label: "热门彩种", value: 3, type: "lottery" },
        { label: "直播", value: 4, type: "live" },
      ],
      roomList: [],
      showRoomList: [],
      lastInputContent: "",
      replayInfo: undefined,
      itemSource: [],
      chatList: [],
      filterChatList: [],
      roomLoading: false,
      sendingMsg: [],
      emoShow: false,
      emojiArr,
      socket: null,
      msgId: null,
      msgLoading: false,
      msgLoadFinish: false,
      domainImData: {},
      deleteList: {}
    };
  },
  computed: {
    ...mapState("user", ["info"]),
    ...mapGetters(["tenantCode", "operatorId"]),

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`;
    },
    roomClientType() {
      const roomInfo = this.roomTypes.find((p) => p.value === this.roomType);
      if (roomInfo) {
        return (
          // roomInfo.type +
          // "_" +
          this.tenantCode 
          // (process.env.NODE_ENV === "development" ? "_Debug" : "")
        );
      }
      return "";
    },
    // 获取右侧聊天人数
    roomRealCount() {
      if (this.activeRoomId) {
        const activeRoomInfo = this.roomList.find(
          (p) => p.roomId === this.activeRoomId
        );
        if (activeRoomInfo) {
          return activeRoomInfo.realCount || 0;
        }
      }
      return 0;
    },
  },
  watch: {
    content(newVal) {
      if (
        this.replayInfo &&
        newVal.indexOf(`@${this.replayInfo.nickname}`) < 0
      ) {
        this.content = this.lastInputContent.replace(
          `@${this.replayInfo.nickname}`,
          ""
        );
        this.replayInfo = undefined;
        this.lastInputContent = this.content;
      } else {
        this.content = newVal;
        this.lastInputContent = newVal;
      }
    },
  },
  created() {
    this.getConfig();
  },
  mounted() {
    this.$refs.chatScroller.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    if (this.socket) this.socket.close();
    this._closewebsocket();
  },
  methods: {
    // 获取接口域名配置
    getConfig() {
      getPlatformDomainImList().then(([data, err]) => {
        if (!err && data) {
          this.domainImData = data
          this.init();
        }
      });
    },
    // 行为限制管理跳转
    toLiveBehaviorRestriction() {
      this.$router.push("/Live_Behavior_Restriction");
    },
    handleScroll() {
      if (this.$refs.chatScroller.scrollTop <= 200) {
        const scrollBottom = this.$refs.chatScroller.scrollHeight - this.$refs.chatScroller.scrollTop
        this._getRoomMessage(scrollBottom)
      }
    },
    // 获取房历史间消息
    _getRoomMessage(scrollBottom = 0) {
      if (this.msgLoading || this.msgLoadFinish) return
      this.msgLoading = true
      getRoomMessage({ room: this.activeRoomId, id: this.msgId }, this.domainImData.imApi).then(([list]) => {
        if (list.length) {
          let arr = list.map((item) => {
          let obj = {
            ...item,
            ...(JSON.parse(item.extras || "{}")),
            msgSendStatus: 0,
            vipLevel: item.vipLevel,
          }
          obj.id = item.id
          return obj
        
        }).filter((item) => item.msgType != 10);
          // id 被覆盖问题
          
          this.chatList = [
            ...arr,
            ...this.chatList
          ];
          this.filterChatList = [
            ...arr,
            ...this.filterChatList
          ];
          console.log('历史消息',this.filterChatList[this.filterChatList.length-1])
          if (this.msgId === null) {
            this.$nextTick(() => {
              this.$refs.chatScroller.scrollTop = this.$refs.chatScroller.scrollHeight
            })
          } else {
            this.$nextTick(() => {
              this.$refs.chatScroller.scrollTop = this.$refs.chatScroller.scrollHeight - scrollBottom
            })
          }
          this.msgId = list[0].id;
        } else {
          this.msgLoadFinish = true
        }
      }).finally(() => {
        this.msgLoading = false
      })
    },
    //链接soket
    _connect(roomId) {
      if (this.socket) this.socket.close();
      this.socket = new WebSocketWithHeartbeat(roomId, (data) => {
        const extras = JSON.parse(data.extras || "{}")
        if (data.cmd === 21101 && extras.data.id) {
          const resut = this.deleteList[extras.data.id]
          if (resut) {
            this.$message.error(resut.msg + '成功')
            this.chatList = this.chatList.filter((p) => p.id !== resut.id)
            this.filterChatList = this.filterChatList.filter((p) => p.id !== resut.id)
            resut.close()
            delete this.deleteList[resut.id]
          }
        } else if (!data.cmd) {
          let obj = {
            ...data,
            ...extras,
            vipLevel: data.vipLevel,
          };
          this.chatList.push(obj);
          this.filterChatList.push(obj);
          this.chatList = this.chatList.filter((item) => item.msgType != 10);
          this.filterChatList = this.filterChatList.filter(
            (item) => item.msgType != 10
          );
          this.$nextTick(() => {
            this._setScrollTop();
          });
        }
      }, this.domainImData.imSocket);
      this._clear();
      this.msgId = null
      this.msgLoading = false
      this.msgLoadFinish = false
      this._getRoomMessage();
    },
    // 发送消息
    _sendNormalMessage() {
      if (!this.content) return;
      if (!this.activeRoomId)
        return this.$message.error("请先进入聊天室再发言");
      const content = this.replayInfo
        ? this.content.replace(`@${this.replayInfo.nickname}`, "")
        : this.content;
      const data = {
        to: this.activeRoomId,
        cmd: null,
        msgType: 0,
        chatType: 1,
        content: content,
      };
      if (this.replayInfo)
        data.extras = JSON.stringify({
          replayInfo: {
            nickname: this.replayInfo.nickname,
            vipLevel: this.replayInfo.vipLevel,
          },
        });
      this.socket.send(JSON.stringify(data));
      this.replayInfo = undefined;
      this.lastInputContent = "";
      this.content = "";
    },
    // 清除聊天信息
    _clear() {
      this.chatList = [];
      this.filterChatList = [];
    },
    // 选择表情到输入框
    addEmo(emo) {
      this.content += emo;
    },
    // 初始化
    async init() {
      await this._getImToken();
      await this._getRoomList();
    },
    // 获取授权token
    async _getImToken() {
      const [data, err] = await getImToken({tokenType:0});
      if (!err) {
        this.$store.commit("user/SET_IM_TOKEN", data);
      }
    },
    // 获取左侧房间
    async _getChatRoomList(params) {
      if (params.roomType === 2) {
        // 赛事
        let startTime = new Date();
        let endTime = new Date();
        startTime = startTime.setHours(startTime.getHours() - 4);
        endTime = endTime.setHours(endTime.getHours() + 4);
        const matchListData = {
          startMatchTime: parseTime(startTime, "{y}-{m}-{d} {h}:00:00"),
          endMatchTime: parseTime(endTime, "{y}-{m}-{d} {h}:00:00"),
          currentPage: 1,
          pageSize: 2000,
          tenantCode: this.$store.getters.tenantCode,
        };
        const [data, err] = await getThirdMatchList(matchListData);
        const [data1, err1] = await getLiveRoomList(params);
        data.forEach((item) => {
          data1.forEach((ele) => {
            if (item.thirdMatchId == ele.roomId) {
              item.roomNumber = ele.roomNumber;
              item.id = ele.id;
            }
          });
        });
        if (!err) {
          const roomList = data.map((p) => ({
            roomId: p.thirdMatchId,
            roomName: p.homeTeamNameZh + " vs " + p.awayTeamNameZh,
            roomIcon: "",
            roomInfo: p.competitionNameZh,
            roomType: params.roomType,
            roomNumber: p.roomNumber || 0,
            id: p.id,
          }));
          return roomList;
        }
      } else if (params.roomType === 5) {
        let startTime = new Date();
        let endTime = new Date();
        startTime = startTime.setDate(startTime.getDate() - 3);
        endTime = endTime.setDate(endTime.getDate() + 3);
        const matchListData = {
          startTime: parseTime(startTime, "{y}-{m}-{d} {h}:00:00"),
          endTime: parseTime(endTime, "{y}-{m}-{d} {h}:00:00"),
          currentPage: 1,
          pageSize: 2000,
          tenantCode: this.$store.getters.tenantCode,
        };
        const [data, err] = await getPredictionMatchList(matchListData);
        if (!err) {
          const roomList = data.map((p) => ({
            roomId: p.thirdMatchId,
            roomName: p.homeTeamNameZh + " vs " + p.awayTeamNameZh,
            roomIcon: "",
            roomInfo: p.competitionNameZh,
            roomType: params.roomType,
            roomNumber: 0,
          }));
          return roomList;
        }
      } else if (params.roomType === 3) {
        // 热门彩种
        let seriesList = (await getLotteryNavigationHot())[0]
          ?.filter((p) => +p.seriesTag < 100)
          .flatMap((p) => p.lotteryList);
        let res = await getLotterySettingList({ type: 6 });
        const [data1, err1] = await getLiveRoomList(params);
        if (!res[0]) return [];
        res[0].forEach((item) => {
          data1.forEach((ele) => {
            if (item.lotteryId == ele.roomId) {
              item.roomNumber = ele.roomNumber;
              item.id = ele.id;
            }
          });
        });
        const roomList = res[0].map((p) => ({
          roomId: p.lotteryId,
          roomName: seriesList.find((s) => s.lotteryId == p.lotteryId)?.name,
          roomIcon: p.icon || "",
          roomInfo: p.content,
          roomType: params.roomType,
          roomNumber: p.roomNumber || 0,
          id: p.id,
        }));
        return roomList;
      } else if (params.roomType === 4) {
        // 直播
        const [data1, err1] = await getLiveRoomList(params);
        const [data, err] = await getonLiveForbidLineRoom();
        data.forEach((item) => {
          data1.forEach((ele) => {
            if (item.roomId == ele.roomId) {
              item.roomNumber = ele.roomNumber;
              item.id = ele.id;
            }
          });
        });
        if (!err) {
          const roomList = data.map((p) => ({
            roomId: p.roomId,
            roomName: p.roomName,
            roomIcon: "",
            roomInfo: "",
            roomType: params.roomType,
            roomNumber: p.roomNumber || 0,
            id: p.id,
          }));
          return roomList;
        }
      } else {
        const [data, err] = await getLiveRoomList(params);
        if (!err) {
          return data;
        }
        return undefined;
      }
    },
    // 选择房间类型
    async _getRoomList() {
      this.roomLoading = true;
      const params = {
        roomName: this.roomName,
        roomType: this.roomType,
      };
      const data = await this._getChatRoomList(params);
      this.roomLoading = false;
      if (data) {
        const roomIds = data.map((p) => p.roomId);
        if (!roomIds || roomIds.length === 0) {
          this.roomList = [];
          this.showRoomList = [];
          return;
        }
        const params = {
          tenantCode: this.roomClientType,
          groupIds: roomIds,
        };
        // 获取房间对应人数
        onlineNum(params).then(([countData, err]) => {
          if (!err) {
            data.forEach((p) => {
              countData.forEach((ele) => {
                if (ele.groupId.includes(p.roomId)) {
                  p.realCount = ele.nums;
                }
              });
            });
            this.roomList = data;
            this.showRoomList = this.roomList;
          }
        });
      }
    },
    // 选择进入房间
    _enterRoom(item) {
      this.activeRoomId = item.roomId;
      this.activeRoomName = item.roomName;
      this._connect(item.roomId);
      this._getRoomList();
    },
    // 删除房间
    _deleteRoom() {
      this._getRoomList();
    },
    // 编辑房间
    _updateRoom({ field, cancel, close }) {
      editRoom({ ...field }).then(([data, error]) => {
        if (!error) {
          this.$message.success(this.$t("修改成功"));
          close();
          this._getRoomList();
        } else {
          cancel();
        }
      });
    },
    // 新增房间
    _createRoom({ field, cancel, close }) {
      addRoom({ ...field }).then(([data, error]) => {
        if (!error) {
          this.$message.success(this.$t("新增成功"));
          close();
          this._getRoomList();
        } else {
          cancel();
        }
      });
    },
    // 聊天室本地搜索
    _filterRoomList() {
      this.showRoomList = this.roomList.filter(
        (p) => p.roomName.indexOf(this.roomName) > -1
      );
    },
    // 聊天内容查找
    _filterMsgContent() {
      this.filterChatList = this.chatList.filter(
        (p) => p.content?.indexOf(this.searchContent) > -1
      );
      this.$nextTick(() => {
        this._setScrollTop();
      });
    },
    // 编辑房间信息
    _handleUpdateRoom(item) {
      this.$refs.ActionChatRoomDialogControl.open(item);
    },
    // 新增房间
    _handleCreateRoom() {
      this.$refs.ActionChatRoomDialogControl.open();
    },
    // 过滤敏感词
    _handleOpenSensitive() {
      this.$refs.SensitiveDialogControl.open();
    },
    // 新增过滤敏感词
    _submitSensitive({ field, cancel, close }) {
      let sendInfo = field.sendInfo.replaceAll("\n", ",");
      const params = {
        tenantCode: this.tenantCode,
        words: sendInfo,
      };
      addSensitive(params).then(([data, error]) => {
        if (!error) {
          this.$message.success(this.$t("新增成功"));
          close();
        } else {
          cancel();
        }
      });
    },
    // _getCount(roomIds) {
    //   const params = {
    //     type: this.roomClientType,
    //     rooms: roomIds,
    //   };
    //   getRoomPersonCount(params).then(([data, err]) => {
    //     if (!err) {
    //       return data;
    //     }
    //   });
    // },
    // 追加真实人数
    _setCount() {
      if (!this.activeRoomId) {
        return this.$message.error(this.$t("请先选择聊天室"));
      }
      const roomInfo = this.roomList.find(
        (p) => p.roomId === this.activeRoomId
      );

      if (roomInfo) {
        roomInfo.roomNumber += this.VirtualCount; // 只能输入数字
        editRoom({ ...roomInfo }).then(([data, error]) => {
          if (!error) {
            this.$message.success(this.$t("修改成功"));
            this._getRoomList();
          }
        });
      }
    },
    // 普通消息(100) 回复类消息(101) 图片类消息(102)  体育订单分享消息(103) 彩票订单分享消息(104) 禁言消息(201)
    // 敏感词新增消息(106) 直播间系统公告(107) 用户进入直播间(108)踢出直播间(109)删除消息(110) 消息操作处理
    _handleActionItem(item, actionType) {
      // return
      // @ 回复
      if (actionType === "reply") {
        this.replayInfo = item;
        this.content = `@${item.nickname} `;
        this.lastInputContent = this.content;
        // 删除消息
      } else if (this.operatorId === item.from && actionType === "del") {
        // 删除自己的消息
        this.$confirm(this.$t("是否删除消息?"), this.$t("系统提示"), {
          type: "warning",
        })
          .then(() => {
            const extras = JSON.stringify({
              groupId: this.activeRoomId,
              id: item.id,
            });
            this.socket.send(
              JSON.stringify({
                chatType: -1,
                cmd: 21001,
                extras: extras,
                msgType: item.msgType,
                to: this.activeRoomId,
              })
            );
            this.filterChatList = this.filterChatList.filter(
              (p) => p.id !== item.id
            );
            this.chatList = this.chatList.filter((p) => p.id !== item.id);
            this.$message.success(this.$t("删除消息成功"));
          })
          .catch(() => { });
      } else {
        // 删除别人信息
        this.$refs.ChatDisableControl.open({
          ...item,
          roomId: this.activeRoomId,
        });
      }
    },
    // 删除消息
    _handleMute({ field, cancel, close }) {
      // console.log(field,'fieldfieldfield')
      if (this.deleteList[field.id]) {
        this.$message.warning('消息删除中，请稍后')
        return
      }
      if (field.type === 3) {
        // 删除永久禁言
        const str = JSON.stringify({
          groupName: this.activeRoomName,
          memberName: field.nickname,
        });
        this.socket.send(
          // 禁言
          JSON.stringify({
            chatType: -1,
            cmd: 2001,
            extras: str,
            msgType: 0,
            to: field.memberId,
          })
        );
        const extras = JSON.stringify({
          // 删除消息
          groupId: field.roomId,
          id: field.id,
        });
        setTimeout(() => {
          this.socket.send(
            JSON.stringify({
              chatType: -1,
              cmd: 21001,
              extras: extras,
              msgType: 0,
              to: field.roomId,
            })
          );
        }, 1000);
        this.deleteList[field.id] = {
          id: field.id,
          msg: '删除消并永久禁言息',
          cancel,
          close
        }
        // this.chatList = this.chatList.filter((p) => p.id !== field.id);
        // this.filterChatList = this.filterChatList.filter(
        //   (p) => p.id !== field.id
        // );
        // this.$message.success(this.$t("删除消并永久禁言息成功"));
        // close();
      } else if (field.type === 2) {
        // 删除并短暂禁言
        const str = JSON.stringify({
          groupName: this.activeRoomName,
          memberName: field.nickname,
          expireMin: field.min,
        });
        this.socket.send(
          // 禁言
          JSON.stringify({
            chatType: -1,
            cmd: 2002,
            extras: str,
            msgType: 0,
            to: field.memberId,
          })
        );
        const extras = JSON.stringify({
          // 删除消息
          groupId: field.roomId,
          id: field.id,
        });
        setTimeout(() => {
          this.socket.send(
            JSON.stringify({
              chatType: -1,
              cmd: 21001,
              extras: extras,
              msgType: 0,
              to: field.roomId,
            })
          );
        }, 1000);
        this.deleteList[field.id] = {
          id: field.id,
          msg: '删除消并短暂禁言息',
          cancel,
          close
        }
        // this.chatList = this.chatList.filter((p) => p.id !== field.id);
        // this.filterChatList = this.filterChatList.filter(
        //   (p) => p.id !== field.id
        // );
        // this.$message.success(this.$t("删除消并短暂禁言息成功"));
        // close();
      } else {
        // 只删除
        const extras = JSON.stringify({
          groupId: field.roomId,
          id: field.id,
        });
        this.socket.send(
          JSON.stringify({
            chatType: -1,
            cmd: 21001,
            extras: extras,
            msgType: field.msgType,
            to: field.roomId,
          })
        );
        this.deleteList[field.id] = {
          id: field.id,
          msg: '删除消息',
          cancel,
          close
        }
        // this.chatList = this.chatList.filter((p) => p.id !== field.id);
        // this.filterChatList = this.filterChatList.filter(
        //   (p) => p.id !== field.id
        // );
        // this.$message.success(this.$t("删除消息成功"));
        // close();
      }
      setTimeout(() => {
        const resut = this.deleteList[field.id]
        if (resut) {
          this.$message.error(resut.msg + '失败，请检查网络')
          resut.cancel()
          delete this.deleteList[field.id]
        }
      }, 6000)
    },
    handleImageSuccess(data, file) {
      let reader = new FileReader();
      const that = this;
      reader.onload = function (e) {
        let txt = e.target.result;
        let img = document.createElement("img");
        img.src = txt;
        img.onload = function () {
          const msgContent = {
            uid: that.operatorId,
            nickname: that.$t("管理员"),
            vipLevel: 60,
            vipLog:
              "https://static.lt9b.com/icon/e044bd3fce1c41d4bfe8bd2897913d66.png",
            msgType: 102,
            imgW: img.width,
            imgH: img.height,
            imgUrl: data.data[0],
            time: new Date().getTime(),
            msgTempId: createUniqueString(),
          };
          that._sendRoomMessage(msgContent);
        };
      };
      reader.readAsDataURL(file.raw);
    },
    handleBeforeUpload(file) {
      const isAccept = this.accept.split(",").some((o) => file.type === o);
      const isMax = file.size / 1024 / 1024 < 2;
      if (!isAccept) {
        this.$message.error(this.$t("上传图片只能是 JPG、PNG、JEPG 格式!"));
      }
      if (!isMax) {
        this.$message.error(this.$t("上传图片大小不能超过 2MB!"));
      }
      return isAccept && isMax;
    },
    onEnjoy(enjoy) {
      this.content = this.content + enjoy;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-page {
  padding-top: 6rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;

  .room-card {
    width: 40%;
    height: 70vh;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
      0 5px 15px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;

    .room-type {
      min-width: 100px;
      max-width: 110px;

      ::v-deep {
        .el-input__inner {
          border-radius: 24px;
        }
      }
    }

    .room-search {
      width: 200px;

      ::v-deep {
        .el-input__inner {
          border-radius: 24px 0px 0px 24px;
        }

        .el-input-group__append {
          border-radius: 0px 24px 24px 0px;
        }
      }
    }

    &-head {
      padding: 1.25rem 0.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-body {
      flex: 1 1 auto;
      position: relative;
      overflow-y: hidden;

      &__inner {
        padding: 0.5rem 1.5rem;
        min-height: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
      }
    }

    &-body:hover,
    &-body:active,
    &-body:focus {
      overflow-y: auto;
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 1.25rem 0.5rem;

      // border-top: 1px solid rgba(0,0,0,.05);
      i,
      svg {
        cursor: pointer;
        font-size: 20px;
        margin: 0 10px;
      }
    }
  }

  .card {
    width: 100%;
    height: 70vh;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
      0 5px 15px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;
    margin-left: 24px;

    &-head {
      padding: 1.25rem 1.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-body {
      flex: 1 1 auto;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;

      &__inner {
        padding: 0.5rem 1.5rem;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      padding: 1.25rem 0.5rem;
      border-top: 1px solid rgba(0, 0, 0, 0.05);

      i,
      svg {
        cursor: pointer;
        font-size: 20px;
        margin: 0 10px;
      }

      .emo-img {
        cursor: pointer;
        width: 1.3rem;
        margin-right: 10px;
      }
    }
  }

  .search-content {
    width: 40%;

    ::v-deep {
      .el-input__inner {
        border-radius: 24px 0px 0px 24px;
      }

      .el-input-group__append {
        border-radius: 0px 24px 24px 0px;
      }
    }
  }

  .chat-btn {
    position: fixed;
    top: 130px;
    right: 153px;
  }
}
</style>
<style lang="scss">
.emo-pop {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;

  // align-items: center;
  // justify-content: flex-start;
  .emoItem {
    width: 2rem;
    height: 2rem;
    font-size: 1.22rem;
    text-align: center;
  }
}
</style>
