const CanChooseColumns = function() {
  return [
    {
      title: '权限组名',
      key: 'groupName',
      align: 'center'
    },
    {
      title: '启停',
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          if (this.permission.StartStop) {
            return (
              <d-switch v-model={row.status} disabled={row.type === 1} beforeChange={() => this.handleChangeStatus(row)}>
                <span slot='close'>关闭</span>
              </d-switch>
            )
          } else {
            return (<span>{ row.status === 0 ? '启用' : '关闭' }</span>)
          }
        }
      }
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center',
      component: {
        render: (h, row) => {
          const { Delete, Update } = this.permission
          return (
            <span>
              { Update &&
                <el-button
                  type='success'
                  icon='el-icon-edit'
                  size='mini'
                  // disabled={row.type === 1}
                  onClick={() => this.$refs.UpdateDialogControl.open(row)}
                >权限修改</el-button>
              }
              { Delete &&
                <el-button
                  type='danger'
                  icon='el-icon-delete'
                  size='mini'
                  disabled={row.type === 1}
                  onClick={() => this.handleDelete(row)}
                >删除</el-button>
              }
            </span>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
