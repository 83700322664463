<template>
  <el-dialog title="图片预览" width="35%" top="5vh" :visible.sync="visible" @closed="handleClosed">
    <img v-if="src" :src="src" width="100%">
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      src: ''
    }
  },
  methods: {
    open(src) {
      this.src = src
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleClosed() {
      this.src = ''
    }
  }
}
</script>

