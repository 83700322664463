import { filterColumns } from "@/utils";
const CanChooseColumns = function () {
  return [
    {
      title: this.$t("订单号"),
      key: "orderNo",
      align: "center",
      minWidth: 175,
    },
    {
      title: this.$t("会员账号"),
      key: "memberName",
      align: "center",
      width: 120
    },
    {
      title: this.$t("所属上级"),
      key: "agencyMemberName",
      align: "center",
      width: 120,
    },
    {
      title: this.$t("昵称"),
      key: "memberNickName",
      align: "center",
      width: 130,
    },
    {
      title: this.$t("彩种期号"),
      key: "issue",
      align: "center",
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{row.lotteryName}</p>
              <p>{row.issue}</p>
            </div>
          );
        },
      },
    },
    {
      title: this.$t("玩法"),
      key: "playName",
      align: "center",
    },
    {
      title: this.$t("玩法分类"),
      key: "playClassName",
      align: "center",
    },
    {
      title: this.$t("内容"),
      key: "numbers",
      align: "center",
      minWidth: 200,
    },
    {
      title: this.$t("金额"),
      key: "amount",
      align: "center",
      sortable: "custom",
      width: 140,
      sortMethod: function (a, b) {
        return parseFloat(a.amount) - parseFloat(b.amount);
      },
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>
                {this.$t("投注")}：{row.amount}
              </p>
              <p>
                {this.$t("抵用")}：{row.couponAmount}
              </p>
              <p>
                {this.$t("中奖")}：{row.winAmount}
              </p>
            </div>
          );
        },
      },
    },
    {
      title: this.$t("抵用券"),
      key: "amount2",
      align: "center",
      sortable: "custom",
      width: 140,
      sortMethod: function (a, b) {
        return parseFloat(a.amount) - parseFloat(b.amount);
      },
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{this.$t(row.memberCouponId ? "已使用" : "未使用")}</p>
              <p>{row.memberCouponId || "--"}</p>
            </div>
          );
        },
      },
    },
    {
      title: this.$t("用户盈亏"),
      key: "profit",
      align: "center",
      sortable: "custom",
      width: 110,
      sortMethod: function (a, b) {
        return parseFloat(a.profit) - parseFloat(b.profit);
      },
    },
    {
      title: this.$t("状态"),
      key: "status",
      align: "center",
      width: 70,
      component: {
        render: (h, row) => {
          const tmp = this.bettingStatus.find((o) => o.key === row.status);
          if (tmp) {
            if (row.status === 2 || row.status === 10)
              return (
                <span style={row.status === 10 ? "color:#F59A23" : ""}>
                  {tmp.label}
                </span>
              );
            return (
              <el-link
                type={tmp.type}
                onClick={() => this.$refs.VisibleDialogControl.open(row)}
              >
                {tmp.label}
              </el-link>
            );
          }
        },
      },
    },
    {
      title: this.$t("支付状态"),
      key: "version",
      align: "center",
      width: 70,
      component: {
        render: (h, row) => {
          if (row.status === 12 || row.version === 10)
            return <span>{"下注失败(扣款失败)"}</span>;
          if (row.status === 1 || row.version === 1)
            return <span>{"确认中(待扣款)"}</span>;
          return <span>{"扣款成功"}</span>;
        },
      },
    },
    {
      title: this.$t("来源"),
      key: "source",
      align: "center",
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{row.source}</p>
              <p>{row.betsPoint}</p>
            </div>
          );
        },
      },
    },
    {
      title: this.$t("推单订单"),
      key: "deductBatchNumber",
      align: "center",
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <div>
              <div>{row.isPush == 1 ? "是" : row.isPush == 0 ? "否" : ""}{ row.isBackgroundPush === 1 && '/后台推单' }</div>
              {row.isPush == 1 && <div>{row.deductBatchNumber}</div>}
            </div>
          );
        },
      },
    },
    {
      title: this.$t("时间"),
      key: "createTime",
      align: "center",
      sortable: "custom",
      width: 180,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>
                {this.$t("投注时间")}:{this.$parseTime(row.createTime)}
              </p>
              <p>
                {this.$t("更新时间")}:{this.$parseTime(row.updateTime)}
              </p>
              <p>
                {this.$t("结算时间")}:{row.lotteryTime ? this.$parseTime(row.lotteryTime) : "-"}
              </p>
            </div>
          );
        },
      },
    }
  ];
};

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}
