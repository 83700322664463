import { appPageList } from "./options"
// 不可选择当前日期及之前日期
export const expireTimeOption = {
  disabledDate(date) {
    return date.getTime() <= Date.now()
  }
}

// 判断数组中是否有重复的值
export const isRepeat = (ary) => {
  var ispass = true
  var repeatVal
  if (ary.length > 0) {
    ary = JSON.parse(JSON.stringify(ary))
  } else {
    ispass = false
    return
  }
  var nary = ary.sort()
  for (var i = 0; i < ary.length; i++) {
    if (nary[i] === nary[i + 1]) {
      ispass = false
      repeatVal = nary[i]
      break
    }
  }
  return { ispass, repeatVal }
}

export const setAppPageParams = (appPageList) => {
  const params = {}
  params.seriesTag = appPageList[1]
  if (appPageList.length === 3) {
    if (['9999014','9999002'].includes(appPageList[1])) {
      params.activityId = appPageList[2]
    } else if (['9999007'].includes(appPageList[1])) {
      params.roomId = appPageList[2]
    } else if (appPageList[0] === '1000005') {
      params.lotteryId = appPageList[2]
    } else if (['1000006','1000007','1000008','1000009','1000010','1000011'].includes(appPageList[0])) {
      params.gameId = appPageList[2]
    }
  }
  return params
}

export const getAppPageParams = (seriesTag, activityId, lotteryId, roomId) => {
  const appPageId = []
  if (seriesTag) {
    appPageList.forEach(e => {
      const item = e.children.find(v => v.value === seriesTag)
      if (item) {
        appPageId.push(e.value)
        appPageId.push(seriesTag)
        return
      }
    })
    if (appPageId.length === 2) {
      if (['9999014','9999002'].includes(seriesTag) && activityId) {
        appPageId.push(activityId)
      } else if (['9999007'].includes(seriesTag) && roomId) {
        appPageId.push(roomId)
      } else if (['1000005', '1000006','1000007','1000008','1000009','1000010','1000011'].includes(appPageId[0])) {
        appPageId.push(lotteryId)
      }
    }
  }
  return appPageId
}