function parseNavigationList(options) {
  return options.map(o => ({
    value: o.seriesTag,
    label: o.seriesName,
    children: o.lotteryList.map(t => ({
      ...t,
      value: t.lotteryId,
      label: t.name
    }))
  }))
}

export default (serviceHandle) => ({
  methods: {
    onFetch() {
      serviceHandle(this.operatorType).then(([data, err]) => {
        if (!err) {
          if (Array.isArray(data) && data.length) {
            this.cascaderOptions = parseNavigationList(data)
            this.value = [this.cascaderOptions[0].value, this.cascaderOptions[0].children[0].value]
            this.handleFetchPlayclass && this.handleFetchPlayclass()
          }
        }
      })
    }
  }
})
