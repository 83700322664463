<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="search.channelIds"
        multiple
        filterable
        clearable
        collapse-tags
        class="filter-item"
        placeholder="频道选择"
        style="width: 200px;"
      >
        <el-option
          v-for="item in channelList"
          :key="item.labelId"
          :label="item.label"
          :value="item.labelId"
        />
      </el-select>
      <el-select
        v-model="search.lottypeId"
        :placeholder="$t('彩种类型')"
        clearable
        class="filter-item"
        style="width: 200px;"
        @change="search.lotkindId = '';lotteryList = []"
      >
        <el-option v-for="item in livePreLotteryType" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-select
        v-model="search.lotkindId"
        filterable
        remote
        clearable
        collapse-tags
        :disabled="search.lottypeId === undefined || search.lottypeId === '' || search.lottypeId === 4"
        reserve-keyword
        placeholder="彩种/赛事"
        class="filter-item"
        style="width: 200px;"
        :remote-method="remoteMethod"
        :loading="lotkindIdLoading"
      >
        <el-option
          v-for="item in lotteryList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-input v-model="search.nickName" :placeholder="$t('主播昵称')" clearable class="filter-item" style="width: 200px;" />
    </div>
    <div class="filter-container">
      <el-input v-model="search.roomName" type="search" :placeholder="$t('房间主题')" class="filter-item" style="width: 200px" />
      <el-input v-model="search.familyName" :placeholder="$t('所属家族')" clearable class="filter-item" style="width: 200px;" />
      <el-select
        v-model="search.liveStatus"
        :placeholder="$t('预约状态')"
        clearable
        class="filter-item"
        style="width: 200px;"
      >
        <el-option
          v-for="item in preStatus"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
      <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleSearch">{{ $t('查 询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <EditDialog ref="EditDialogControl" @update="_handleUpdate" />
  </div>

</template>

<script>
import {
  livePlanManagementList,
  // livePlanManagementDetail,
  livePlanManagementCancel,
  livePlanManagementUpdateLivePlan,
  getLotteryAllList,
  getLiveChannelList,
  liveCategoryList,
  livePlanManagementStart
} from '@/api/live'
import { mapGetters } from 'vuex'
import { livePreLotteryType, preStatus, displayStatusList } from '@/libs/options'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import EditDialog from './EditDialog'
import { $t } from '@/lang'
import { footballThirdMatchList, basketballThirdMatchList } from '@/api/lottery'

export default {
  name: 'LivePre',
  components: { EditDialog },
  mixins: [TablePageMixin(livePlanManagementList)],
  data() {
    return {
      search: {
        lotkindId: ''
      },
      preStatus,
      displayStatusList,
      columns: [],
      defaultColumns: GetColumns.call(this),
      lotteryOptions: [],
      channelList: [],
      categoryList: [],
      livePreLotteryType,
      lotkindIdLoading: false,
      lotteryList: []
    }
  },
  computed: {
    ...mapGetters(['tenantCode'])
  },
  created() {
    this.getChannelList()
    this.getliveCategoryList()
  },
  mounted() {
    getLotteryAllList(this.tenantCode).then(([items, err]) => {
      if (!err) {
        this.lotteryOptions = items.filter(o => o.liveShowStatus === 1 && o.tenantShowStatus === 1).map(e => {
          return {
            value: e.lotteryId,
            label: e.name
          }
        })
      }
    })
  },
  methods: {
    _handleUpdate({ field, cancel, close }) {
      const { channelUnionId, ...other } = field
      const params = { ...other }
      params.channelList = []
      const channelIdList = []
      channelUnionId.forEach(e => {
        let category = null
        const channel = this.channelList.find(v => v.value === e)
        if (channel) {
          channelIdList.push(channel.labelId)
          category = this.categoryList.find(e => e.value === channel.categoryUnionId)
        }
        params.channelList.push({
          channelUnionId: e,
          channelId: channel?.labelId || '',
          categoryUnionId: channel?.categoryUnionId || '',
          categoryId: category?.labelId || ''
        })
      })
      params.channelId = channelIdList.toString()
      if (params.serverSource === 1) {
        params.clientAddress = ''
      }
      livePlanManagementUpdateLivePlan(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success($t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    getChannelList() {
      getLiveChannelList({ currentPage: 1, pageSize: 200 }).then(([items, err]) => {
        if (!err && items && items.length > 0) {
          this.channelList = items.map(e => {
            return {
              value: e.unionId,
              label: e.items.find(e => e.langCode === 'zh_CN').channelName,
              labelId: e.items.find(e => e.langCode === 'zh_CN').channelId,
              categoryUnionId: ''
            }
          })
        }
      })
    },
    getliveCategoryList() {
      liveCategoryList({ currentPage: 1, pageSize: 200 }).then(([items, err]) => {
        if (!err && items) {
          this.categoryList = items.map(e => {
            return {
              value: e.unionId,
              label: e.items.find(v => v.langCode === 'zh_CN')?.categoryName,
              labelId: e.items.find(v => v.langCode === 'zh_CN')?.categoryId,
              channelUnionId: e.channelUnionId
            }
          })
        }
      })
    },
    handleCancelLive({ planId }) {
      this.$confirm(this.$t('确认取消直播?'), this.$t('请谨慎操作'), {
        type: 'warning',
        center: true
      }).then(() => {
        livePlanManagementCancel({ planId }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    handleStartlLive({ planId }) {
      this.$confirm(this.$t('确认上播?'), this.$t('请谨慎操作'), {
        type: 'warning',
        center: true
      }).then(() => {
        livePlanManagementStart({ planId }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    remoteMethod(query) {
      if (query !== '') {
        if (this.search.lottypeId === 1) {
          this.lotteryList = this.lotteryOptions.filter(e => e.label.toLowerCase().indexOf(query.toLowerCase()) > -1)
          return
        }
        this.lotkindIdLoading = true
        let service = footballThirdMatchList
        if (this.search.lottypeId === 3) {
          service = basketballThirdMatchList
        }
        service({ competitionName: query, currentPage: 1, pageSize: 100 }).then(([items, err]) => {
          if (!err && items && items.length) {
            this.lotteryList = items.map(e => {
              return {
                value: e.thirdMatchId,
                label: e.competitionNameZh + '-' + e.homeTeamNameZh + 'VS' + e.awayTeamNameZh
              }
            })
          }
        }).finally(() => {
          this.lotkindIdLoading = false
        })
      } else {
        this.lotteryList = []
      }
    }
  }
}
</script>
