<template>
  <el-dialog :title="$t('查看会员详细信息')" :visible.sync="visible" width="950px" top="5vh" @open="onOpen" @closed="onClosed">
    <table v-loading="loading" class="message-table" cellspacing="0" cellpadding="0" border="0" style="width:100%">
      <colgroup>
        <col width="120">
        <col width="120">
        <col width="270">
        <col width="130">
        <col width="240">
      </colgroup>
      <tbody>
        <tr>
          <td :rowspan="8 + (row.bankList && row.bankList.length || 0)" class="label">{{ $t('基本信息') }}：</td>
          <td class="label">{{ $t('登录账号') }}：</td>
          <td>
            <div flex="cross:center main:justify">
              <span>{{ row.memberName }}</span>
              <span>
                <el-button
                  v-if="row.freezeStatus === 1"
                  type="success"
                  plain
                  size="mini"
                  @click.stop="handleUpdateFreezeStatus(0)"
                >{{ $t('启用') }}</el-button>
                <el-button
                  v-if="row.freezeStatus === 0"
                  type="danger"
                  plain
                  size="mini"
                  @click.stop="handleUpdateFreezeStatus(1)"
                >{{ $t('禁用') }}</el-button>
                <el-button type="primary" size="mini" @click="handleCopy(row.memberName, $event)">{{ $t('复制')
                }}</el-button>
              </span>
            </div>
          </td>
          <td class="label">{{ $t('所属上级') }}：</td>
          <td>{{ row.agencyMemberName }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('会员昵称') }}：</td>
          <td>{{ row.nickname }}</td>
          <td class="label">{{ $t('类别/等级/更新时间') }}：</td>
          <td>
            <div flex="cross:center main:justify">
              <span>{{ row.memberType | getMemberType }}</span>
              <el-tag type="success" size="small" effect="plain">VIP{{ row.vipLevel }}</el-tag>
              <span>{{ row.updateTime | parseTime }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="label">{{ $t('会员余额') }}：</td>
          <td>{{ row.balance }}</td>
          <td class="label">{{ $t('出款所需打码量') }}：</td>
          <td>{{ row.withdrawRolling }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('总打码量') }}：</td>
          <td>{{ row.sumRolling }}</td>
          <td class="label">{{ $t('真实姓名') }}：</td>
          <td>{{ row.realName }}</td>
        </tr>

        <tr v-for="item in row.bankList" :key="item.cardNumber">
          <td class="label">{{ $t('银行地址') }}：</td>
          <td>{{ item.bank }}</td>
          <td class="label">{{ $t('银行账号') }}：</td>
          <td>{{ item.cardNumber }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('注册时间') }}：</td>
          <td>{{ row.createTime | parseTime }}</td>
          <td class="label">{{ $t('注册IP') }}：</td>
          <td>{{ row.registerIp }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('注册设备') }}：</td>
          <td>{{ row.registerDevice }}</td>
          <td class="label">{{ $t('注册来源') }}：</td>
          <td>{{ row.registerResource }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('最后登录时间') }}：</td>
          <td>{{ row.latestLoginTime | parseTime }}</td>
          <td class="label">{{ $t('最后登录IP') }}：</td>
          <td>{{ row.latestLoginIp }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('最后登录设备') }}：</td>
          <td>{{ row.latestLoginDevice }}</td>
          <td class="label">{{ $t('用户ID') }}：</td>
          <td>{{ params.memberId }}<el-button type="text" size="mini" @click="handleCopy(params.memberId, $event)">{{
            $t('复制') }}</el-button></td>
        </tr>
        <tr>
          <td class="label">{{ $t('第三方余额') }}：</td>
          <td colspan="4">
            <div flex="cross:center main:justify">
              <p class="balance">
                <span v-for="game in thirdGameList" :key="game.key">
                  {{ game.label }}: <em>{{ row[game.key] }}</em>
                  <el-button type="text" size="mini" @click="handleRefreshBalance(game.value, game.key, game.label)">{{
                    $t('刷新') }}</el-button>
                </span>
              </p>
              <el-button type="success" size="mini" @click.stop="handleRecall">{{ $t('调回余额') }}</el-button>
            </div>
          </td>
        </tr>
        <tr>
          <td class="label" rowspan="4">{{ $t('提现信息') }}：</td>
          <td class="label">{{ $t('当日提款金额') }}：</td>
          <td>{{ row.dailyWithdraw }} {{ $t('元') }}</td>
          <td class="label">{{ $t('总提款金额') }}：</td>
          <td>{{ row.sumWithdraw }} {{ $t('元') }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('当日提款次数') }}：</td>
          <td>{{ row.dailyWithdrawTimes }}</td>
          <td class="label">{{ $t('总提款次数') }}：</td>
          <td>{{ row.sumWithdrawTimes }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('首次提款') }}：</td>
          <td>{{ row.firstWithdrawAmount }}{{ $t('元') }}</td>
          <td class="label">{{ $t('最高提款') }}：</td>
          <td>{{ row.maxWithdrawAmount }}{{ $t('元') }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('人工减款') }}：</td>
          <td>{{ row.sumOperatorSubtract }}{{ $t('元') }}</td>
        </tr>

        <tr>
          <td class="label" rowspan="4">{{ $t('充值信息') }}：</td>
          <td class="label">{{ $t('当日充值金额') }}：</td>
          <td>{{ row.dailyRecharge }}{{ $t('元') }}</td>
          <td class="label">{{ $t('总充值金额') }}：</td>
          <td>{{ row.sumRecharge }}{{ $t('元') }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('当日充值次数') }}：</td>
          <td>{{ row.dailyRechargeTimes }}</td>
          <td class="label">{{ $t('总充值次数') }}：</td>
          <td>{{ row.sumRechargeTimes }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('首次充值') }}：</td>
          <td>{{ row.firstRechargeAmount }}{{ $t('元') }}</td>
          <td class="label">{{ $t('最高充值') }}：</td>
          <td>{{ row.maxRechargeAmount }}{{ $t('元') }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('人工加款') }}：</td>
          <td>{{ row.sumOperatorAdd }}{{ $t('元') }}</td>
        </tr>
        <tr>
          <td class="label" rowspan="5">{{ $t('其他信息') }}：</td>
          <td class="label">{{ $t('推单分红总金额') }}：</td>
          <td>{{ row.sumRecommendBouns }}{{ $t('元') }}</td>
          <td class="label">{{ $t('活动领取总金额') }}：</td>
          <td>{{ row.sumPromotion }}{{ $t('元') }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('发送红包总金额') }}：</td>
          <td>{{ row.sumRedPackage }}{{ $t('元') }}</td>
          <td class="label">{{ $t('收取红包总金额') }}：</td>
          <td>{{ row.sumRecvRedPackage }}{{ $t('元') }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('打赏总金额') }}：</td>
          <td>{{ row.sumTips }}{{ $t('元') }}</td>
          <td class="label">{{ $t('被打赏总金额') }}：</td>
          <td>{{ row.sumRecvTips }}{{ $t('元') }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('代理总金额') }}：</td>
          <td>{{ row.totalRebate }}{{ $t('元') }}</td>
          <td class="label">{{ $t('返水总金额') }}：</td>
          <td>{{ row.sumRebate }}{{ $t('元') }}</td>
        </tr>
        <tr>
          <td class="label">{{ $t('抵用券总额/数量') }}：</td>
          <td>{{ row.couponAmountTotal }}{{ $t('元') }} / {{ row.couponNumTotal }}</td>
          <td class="label">{{ $t('抽奖券数量/次数') }}：</td>
          <!-- <td>{{ row.sumRebate }}{{ $t('元') }}</td> -->
        </tr>
        <tr>
          <td class="label">{{ $t('备注') }}：</td>
          <td colspan="4">{{ row.remark }}</td>
        </tr>
      </tbody>
    </table>

    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">
        {{ $t('关闭') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import clip from '@/utils/clipboard'
import { getUserDetails, updateFreezeStatus, queryThirdGame, updateThirdGame } from '@/api/member'
import VDialogMixin from '@/mixins/v-dialog-mixin'
import { memberTypes } from '@/libs/options'

const thirdGameList = [
  { key: 'kyAmount', value: 100, label: '开元余额' },
  { key: 'agAmount', value: 200, label: 'AG余额' },
  { key: 'mgAmount', value: 2800, label: 'MG' },
  { key: 'jdbAmount', value: 700, label: 'JDB' },
  { key: 'tyAmount', value: 800, label: '体育' },
  // { key: 'awcAmount', value: 900, label: 'AWC' },
  // { key: 'obAmount', value: 1100, label: 'OBA' },
  { key: 'obDjAmount', value: 1501, label: 'DB电竞' },
  { key: 'obZrAmount', value: 1503, label: 'DB真人' },
  { key: 'cqAmount', value: 1600, label: 'CQ9' },
  { key: 'lyAmount', value: 1700, label: '乐游' },
  { key: 'sbAmount', value: 2000, label: '沙巴' },
  { key: 'sgAmount', value: 2500, label: 'SG' },
  { key: 'imAmount', value: 2200, label: 'IM' },
  { key: 'bgAmount', value: 2600, label: 'BG' },
  { key: 'fbAmount', value: 2700, label: 'FB' },
  { key: 'bbinAmount', value: 1800, label: 'BBIN' },
  { key: 'ppAmount', value: 1300, label: 'PP真人' },
  { key: 'pgAmount', value: 1200, label: 'PG' },
  { key: 'mtAmount', value: 2400, label: '美天' },
  { key: 'fgAmount', value: 2900, label: 'FG' },
  { key: 'PS', value: 3000, label: 'PS' }
]

export default {
  filters: {
    getMemberType(type) {
      if (!type) return
      const tmp = memberTypes.find(o => o.key === type)
      return tmp && tmp.label || ''
    }
  },
  mixins: [VDialogMixin],
  data() {
    return {
      loading: false,
      params: {},
      row: {},
      thirdGameList
    }
  },
  methods: {
    open(memberId) {
      this.params.memberId = memberId
      this.visible = true
    },
    onOpen() {
      this.loading = true
      getUserDetails(this.params).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.row = data
        }
      })
    },
    onClosed() {
      this.row = {}
    },
    handleCopy(text, event) {
      clip(text, event)
    },
    handleUpdateFreezeStatus(status) {
      updateFreezeStatus({
        memberId: this.params.memberId,
        status
      }).then(([_, err]) => {
        this.loading = false
        if (!err) {
          this.row.freezeStatus = status
          this.$message.success(this.$t('操作成功'))
        }
      })
    },

    handleRefreshBalance(thirdGameTag, key, name) {
      queryThirdGame({
        thirdGameTag,
        memberId: this.params.memberId
      }).then(([data, err]) => {
        if (!err) {
          this.row[key] = data
          this.$message.success(this.$t('刷新name成功', { text: name }))
        }
      })
    },

    handleRecall() {
      updateThirdGame({
        memberId: this.params.memberId
      }).then(([data, err]) => {
        if (!err) {
          this.row.balance = data
          this.$message.success(this.$t('调回成功'))
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.message-table {
  font-size: 12px;
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;

  td {
    padding: 0 6px;
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    line-height: 32px;

    &.label {
      text-align: right;
    }
  }

  tr {
    &:nth-child(2n) {
      background-color: #fbfbfb;
    }
  }

  .balance {
    &>span {
      display: inline-block;
      margin-right: 10px;
    }

    em {
      font-style: normal;
      ;
      color: red;
    }
  }
}
</style>
