<template>
  <div>
    <comMenu title="娱乐版-更多按钮配置" menu-name="更多按钮配置" :menu-list="menuList" :num="17" home-type="entertainment" :position="3" />
    <comMenu title="体育版-更多按钮配置" menu-name="更多按钮配置" :menu-list="menuList" :num="17" home-type="sport" :position="3" />
    <comMenu title="六合版-更多按钮配置" menu-name="更多按钮配置" :menu-list="menuList" :num="17" home-type="liuhe" :position="3" />
  </div>
</template>
<script>
import comMenu from '../components/comMenu.vue'

export default {
  name: 'FooterMenuTab',
  componentName: 'FooterMenuTab',
  components: { comMenu },
  data() {
    return {
      menuList: [
        { key: 'MORE.PAY', label: '钱包' },
        { key: 'MORE.RECHARGE', label: '充值' },
        { key: 'MORE.WITHDRAW', label: '提现' },
        { key: 'MORE.VIP', label: 'VIP' },
        { key: 'MORE.ACCOUNTCHANGE', label: '交易明细' },
        { key: 'MORE.CARD', label: '卡片管理' },
        { key: 'MORE.COINPAY', label: '虚拟钱包' },
        { key: 'MORE.MESSEGE', label: '我的消息' },
        { key: 'MORE.COUPON', label: '卡券中心' },
        { key: 'MORE.TASK', label: '任务中心' },
        { key: 'MORE.ACTIVITY', label: '优惠活动' },
        { key: 'MORE.SHARE', label: '分享赚钱' },
        { key: 'MORE.AGENT', label: '我的代理' },
        { key: 'MORE.REPORT', label: '我的报表' },
        { key: 'MORE.CUSTOMER', label: '客服' },
        { key: 'MORE.RECORD', label: '记录' },
        { key: 'MORE.SHOWOFFCIRCLE', label: '晒单圈/社区广场' },
        { key: 'MORE.LIVE', label: '直播' },
        { key: 'MORE.DRAWINGINFO', label: '彩讯' },
        { key: 'MORE.SPORTINFO', label: '体讯' },
        { key: 'MORE.GURURECORD', label: '推单' },
        // { key: 'MORE.COMMUNITY', label: '社区' },
        { key: 'MORE.FOOTBALL', label: '滚球' },
        { key: 'MORE.ENTERCENTER', label: '娱乐城' },
        { key: 'MORE.RECCOMMAND', label: '推荐' }
      ]
    }
  }
}
</script>
