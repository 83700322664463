export const sportList = [
    {
        name: '胜平负',
        key: 'originSpfList',
        rowSpan: 1,
        index: 0,
        emptyIndex: 2,
        odds: [
            {
                code: '1101',
                name: '胜',
                odds: 0,
                checked: false
            },
            {
                code: '1102',
                name: '平',
                odds: 0,
                checked: false
            },
            {
                code: '1103',
                name: '负',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '让胜平负(-1)',
        key: 'originRqList',
        rowSpan: 1,
        index: 0,
        emptyIndex: 2,
        odds: [
            {
                code: '1201',
                name: '让胜',
                odds: 0,
                checked: false
            },
            {
                code: '1202',
                name: '让平',
                odds: 0,
                checked: false
            },
            {
                code: '1203',
                name: '让负',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '半全场',
        key: 'originBqcList',
        rowSpan: 3,
        index: 0,
        emptyIndex: 2,
        odds: [
            {
                code: '4001',
                name: '胜胜',
                odds: 0,
                checked: false
            },
            {
                code: '4002',
                name: '胜平',
                odds: 0,
                checked: false
            },
            {
                code: '4003',
                name: '胜负',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '半全场',
        key: 'originBqcList',
        index: 1,
        emptyIndex: 2,
        odds: [
            {
                code: '4004',
                name: '平胜',
                odds: 0,
                checked: false
            },
            {
                code: '4005',
                name: '平平',
                odds: 0,
                checked: false
            },
            {
                code: '4006',
                name: '平负',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '半全场',
        key: 'originBqcList',
        index: 2,
        emptyIndex: 2,
        odds: [
            {
                code: '4007',
                name: '负胜',
                odds: 0,
                checked: false
            },
            {
                code: '4008',
                name: '负平',
                odds: 0,
                checked: false
            },
            {
                code: '4009',
                name: '负负',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '比分',
        key: 'originBfList',
        rowSpan: 7,
        index: 0,
        odds: [
            {
                code: '2101',
                name: '1:0',
                odds: 0,
                checked: false
            },
            {
                code: '2102',
                name: '2:0',
                odds: 0,
                checked: false
            },
            {
                code: '2103',
                name: '2:1',
                odds: 0,
                checked: false
            },
            {
                code: '2104',
                name: '3:0',
                odds: 0,
                checked: false
            },
            {
                code: '2105',
                name: '3:1',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '比分',
        key: 'originBfList',
        index: 1,
        odds: [
            {
                code: '2106',
                name: '3:2',
                odds: 0,
                checked: false
            },
            {
                code: '2107',
                name: '4:0',
                odds: 0,
                checked: false
            },
            {
                code: '2108',
                name: '4:1',
                odds: 0,
                checked: false
            },
            {
                code: '2109',
                name: '4:2',
                odds: 0,
                checked: false
            },
            {
                code: '2110',
                name: '5:0',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '比分',
        key: 'originBfList',
        index: 2,
        emptyIndex: 2,
        odds: [
            {
                code: '2111',
                name: '5:1',
                odds: 0,
                checked: false
            },
            {
                code: '2112',
                name: '5:2',
                odds: 0,
                checked: false
            },
            {
                code: '2113',
                name: '胜其他',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '比分',
        key: 'originBfList',
        index: 3,
        odds: [
            {
                code: '2201',
                name: '0:0',
                odds: 0,
                checked: false
            },
            {
                code: '2202',
                name: '1:1',
                odds: 0,
                checked: false
            },
            {
                code: '2203',
                name: '2:2',
                odds: 0,
                checked: false
            },
            {
                code: '2204',
                name: '3:3',
                odds: 0,
                checked: false
            },
            {
                code: '2205',
                name: '平其他',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '比分',
        key: 'originBfList',
        index: 4,
        odds: [
            {
                code: '2301',
                name: '0:1',
                odds: 0,
                checked: false
            },
            {
                code: '2302',
                name: '0:2',
                odds: 0,
                checked: false
            },
            {
                code: '2303',
                name: '1:2',
                odds: 0,
                checked: false
            },
            {
                code: '2304',
                name: '0:3',
                odds: 0,
                checked: false
            },
            {
                code: '2305',
                name: '1:3',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '比分',
        key: 'originBfList',
        index: 5,
        odds: [
            {
                code: '2306',
                name: '2:3',
                odds: 0,
                checked: false
            },
            {
                code: '2307',
                name: '0:4',
                odds: 0,
                checked: false
            },
            {
                code: '2308',
                name: '1:4',
                odds: 0,
                checked: false
            },
            {
                code: '2309',
                name: '2:4',
                odds: 0,
                checked: false
            },
            {
                code: '2310',
                name: '0:5',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '比分',
        key: 'originBfList',
        index: 6,
        emptyIndex: 2,
        odds: [
            {
                code: '2311',
                name: '1:5',
                odds: 0,
                checked: false
            },
            {
                code: '2312',
                name: '2:5',
                odds: 0,
                checked: false
            },
            {
                code: '2313',
                name: '负其他',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '进球数',
        key: 'originJqList',
        rowSpan: 2,
        index: 0,
        odds: [
            {
                code: '3001',
                name: '0',
                odds: 0,
                checked: false
            },
            {
                code: '3002',
                name: '1',
                odds: 0,
                checked: false
            },
            {
                code: '3003',
                name: '2',
                odds: 0,
                checked: false
            },
            {
                code: '3004',
                name: '3',
                odds: 0,
                checked: false
            },
            {
                code: '3005',
                name: '4',
                odds: 0,
                checked: false
            }
        ]
    },
    {
        name: '进球数',
        key: 'originJqList',
        index: 1,
        emptyIndex: 2,
        odds: [
            {
                code: '3006',
                name: '5',
                odds: 0,
                checked: false
            },
            {
                code: '3007',
                name: '6',
                odds: 0,
                checked: false
            },
            {
                code: '3008',
                name: '7+',
                odds: 0,
                checked: false
            },
        ]
    }
]