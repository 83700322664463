<template>
  <el-dialog
    title="编辑彩票直播封面"
    :visible.sync="visible"
    width="500px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="100px" label-suffix=":">
      <el-form-item :label="$t('系列彩种')">
        {{ lottery }}
      </el-form-item>
      <h4>直播封面</h4>
      <el-form-item :label="$t('直播中')" prop="liveImg">
        <div class="upload">
          <el-upload
            accept="image/png,image/jpg,image/jpeg,image/webp"
            :action="action"
            :data="{ type: 'image', folder: 'icon' }"
            :headers="$headers()"
            :show-file-list="false"
            :on-success="_handleLiveImgSuccess"
          >
            <img v-if="ruleForm.liveImg" :src="ruleForm.liveImg" class="image">
            <i v-else class="el-icon-upload" />
          </el-upload>
          <i class="el-icon-warning" /> 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。
        </div>
      </el-form-item>
      <el-form-item :label="$t('即将开奖')" prop="playImg">
        <div class="upload">
          <el-upload
            accept="image/png,image/jpg,image/jpeg,image/webp"
            :action="action"
            :data="{ type: 'image', folder: 'icon' }"
            :headers="$headers()"
            :show-file-list="false"
            :on-success="_handlePlayImgSuccess"
          >
            <img v-if="ruleForm.playImg" :src="ruleForm.playImg" class="image">
            <i v-else class="el-icon-upload" />
          </el-upload>
          <i class="el-icon-warning" /> 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。
        </div>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button v-if="emitName !== 'details'" type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  id: '',
  playImg: '',
  liveImg: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      lottery: '',
      rules: {
        liveImg: [{ required: true, message: this.$t('请上传直播中图片'), trigger: 'change' }],
        playImg: [{ required: true, message: this.$t('请上传即将开奖图片'), trigger: 'change' }]
      }
    }
  },
  computed: {
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    }
  },
  methods: {
    open(row) {
      const _this = this
      _this.emitName = 'create'
      if (row) {
        _this.ruleForm.id = row.id
        _this.lottery = _this.$parent.cascaderOptions.find(e => e.value === row.seriesTag)?.label + '-' + row.name
        _this.ruleForm.liveImg = row.liveImg
        _this.ruleForm.playImg = row.playImg
        _this.emitName = 'update'
      }
      _this.visible = true
    },
    _handleLiveImgSuccess(res) {
      this.ruleForm.liveImg = res.data[0]
      this.$refs.validateForm.validateField('liveImg')
    },
    _handlePlayImgSuccess(res) {
      this.ruleForm.playImg = res.data[0]
      this.$refs.validateForm.validateField('playImg')
    }
  }
}
</script>

<style lang="scss" scoped>

.upload {
  display: flex;
  align-items: flex-start;
  color: #BCBCBC;
  > div {
    margin-top: 5px;
  }
  > i {
    margin-top: 9px;
    margin-left: 25px;
    margin-right: 5px;
  }
  ::v-deep .el-upload {
     border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .image {
    width: 50px;
    height: 50px;
  }
}
</style>
