<template>
  <el-dialog
    :title="title"
    width="800px"
    :visible.sync="visible"
    :close-on-click-modal="false"
    @open="onOpen"
    @closed="onClosed"
  >
    <lottery-before
      v-if="params"
      ref="lotteryBeforehand"
      :label="$t('重开号码')"
      :series-tag="params.seriesTag"
      :lottery-id="params.lotteryId"
    />
    <div class="radio_box">
      <label class="label">{{ $t('重开方式') }}</label>
      <el-radio-group v-model="openWay">
        <el-radio v-for="item of options" :key="item.val" :label="item.val">{{ item.label }}</el-radio>
      </el-radio-group>
    </div>
    <ul class="tips">
      <li>{{ $t('_1本功能') }}</li>
      <li>{{ $t('_2撤销开奖') }}</li>
      <li>{{ $t('_3重新开奖') }}</li>
    </ul>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('取消') }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">{{ $t('确认结算') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import LotteryBefore from './LotteryBefore'

export default {
  components: { LotteryBefore },
  mixins: [VDialogMixin],
  data() {
    return {
      emitName: '',
      title: '',
      submitting: false,
      params: null, // 请求参数
      options: [
        { label: this.$t('撤销开奖'), val: 0 },
        { label: this.$t('重新开奖'), val: 1 }
      ],
      openWay: null
    }
  },
  methods: {
    open({ issue, lotteryId }, lotteryName, seriesTag) {
      this.title = `${lotteryName} ${issue} ${this.$t('期重新开奖')}`
      this.params = { issue, lotteryId, seriesTag }
      this.visible = true
    },
    onOpen() {
      this.$nextTick(() => {
        this.$refs.lotteryBeforehand.init()
      })
    },
    onClosed() {
      this.submitting = false
      this.openWay = null
      this.params = null
    },
    handleSubmit() { // 确认结算
      if (this.openWay === null) {
        this.$message.error(this.$t('请选择重开方式'))
        return
      }
      this.$refs.lotteryBeforehand.validateNumber().then((lotteryNo) => {
        this.submitting = true
        this.$emit(this.openWay === 0 ? 'cancelOpen' : 'reOpen', {
          field: { ...this.params, lotteryNo },
          cancel: () => {
            this.submitting = false
          },
          close: this.close
        })
      }).catch(error => {
        this.$message.error(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.radio_box {
  padding: 20px 0;
  font-size: 12px;
  .label {
    padding-right: 20px;
  }
}

ul.tips{
  margin: 0;
  padding: 0;
  list-style: none;
  & > li{
    line-height: 20px;
    margin-bottom: 10px;
    color: red;
    font-size: 12px;
  }
}
</style>
