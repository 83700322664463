import request from '@/plugins/axios'

// 积分商品配置-查询
export function getQueryList(data) {
  return request({
    url: '/pointsMallProduct/list',
    method: 'post',
    data
  })
}

// 积分商品配置-新建商品
export function createGoods(data) {
  return request({
    url: '/pointsMallProduct/add',
    method: 'post',
    data
  })
}

// 积分商品配置-修改
export function updateGoods(data) {
  return request({
    url: '/pointsMallProduct/update',
    method: 'post',
    data
  })
}

// 积分商品配置-上架、下架
export function onOrOff(params) {
  return request({
    url: '/pointsMallProduct/upAndDown',
    method: 'get',
    params
  })
}

// 积分商品配置-删除
export function deleteGoods(params) {
  return request({
    url: '/pointsMallProduct/delete',
    method: 'get',
    params
  })
}

// 积分商品配置-商品类目、商城类目管理-查询（共用1个接口）
export function getCategoryList(data) {
  return request({
    url: '/pointsMallCategory/list',
    method: 'post',
    data
  })
}

// 商城类目管理-新建类目
export function createGategory(data) {
  return request({
    url: '/pointsMallCategory/add',
    method: 'post',
    data
  })
}

// 商城类目管理-迁移
export function moveGategory(params) {
  return request({
    url: '/pointsMallCategory/move',
    method: 'get',
    params
  })
}

// 商城类目管理-商城类目详情
export function getGategoryDetail(params) {
  return request({
    url: '/pointsMallCategory/detail',
    method: 'get',
    params
  })
}

// 商城类目管理-修改
export function updateGategory(data) {
  return request({
    url: '/pointsMallCategory/update',
    method: 'post',
    data
  })
}

// 商城类目管理-删除
export function deleteGategory(params) {
  return request({
    url: '/pointsMallCategory/delete',
    method: 'get',
    params
  })
}

// 积分订单管理-查询
export function getOrderQueryList(data) {
  return request({
    url: '/pointsOrder/list',
    method: 'post',
    data
  })
}

// 积分订单管理-发货
export function getDeliveryInfo(data) {
  return request({
    url: '/pointsOrder/ship',
    method: 'post',
    data
  })
}

// 积分基础配置-初始化数据
export function getBaseConfig(params) {
  return request({
    url: '/points/detail',
    method: 'get',
    params
  })
}

// 积分基础配置-保存
export function createPoints(data) {
  return request({
    url: '/points/create',
    method: 'post',
    data
  })
}
