var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "left-menu" },
      [
        _c("hamburger", {
          staticClass: "hamburger-container",
          attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
          on: { toggleClick: _vm.toggleSideBar },
        }),
        _c("breadcrumb", {
          staticClass: "breadcrumb-container",
          attrs: { id: "breadcrumb-container" },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        !_vm.isProx
          ? [
              _vm.$store.getters.tenantCode !== "system"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "tip-container right-menu-item hover-effect flex",
                      on: {
                        click: function ($event) {
                          return _vm._go("/Interactive_CustomerService")
                        },
                      },
                    },
                    [
                      _c("svg-icon", { attrs: { "icon-class": "message" } }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("客服对话")))]),
                      _vm.totalUnReadNum
                        ? _c("span", { staticClass: "redPoint" }, [
                            _vm._v(
                              _vm._s(
                                _vm.totalUnReadNum > 98
                                  ? 99
                                  : _vm.totalUnReadNum
                              )
                            ),
                            _vm.totalUnReadNum > 98
                              ? _c("span", [_vm._v("+")])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.orders
                ? _c(
                    "div",
                    {
                      staticClass:
                        "tip-container right-menu-item hover-effect flex",
                      on: {
                        click: function ($event) {
                          return _vm._go("/Cash_Withdrawal")
                        },
                      },
                    },
                    [
                      _c("svg-icon", { attrs: { "icon-class": "withdrawal" } }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("提现"))),
                        _c("em", [
                          _vm._v("(" + _vm._s(_vm.orders.withdraw) + "+)"),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.orders
                ? _c(
                    "div",
                    {
                      staticClass: "tip-container right-menu-item hover-effect",
                      on: {
                        click: function ($event) {
                          return _vm._go("/Cash_Deposit")
                        },
                      },
                    },
                    [
                      _c("svg-icon", { attrs: { "icon-class": "deposit" } }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("充值"))),
                        _c("em", [
                          _vm._v("(" + _vm._s(_vm.orders.recharge) + "+)"),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.device !== "mobile"
                ? [
                    _c(
                      "div",
                      { staticClass: "right-menu-item hover-effect" },
                      [
                        _c(
                          "el-badge",
                          { attrs: { "is-dot": _vm.notice.isDot } },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "message",
                                "font-size": "24px",
                              },
                              on: { click: _vm.toggleNotice },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("lang-select", {
                      staticClass: "right-menu-item hover-effect",
                    }),
                  ]
                : _vm._e(),
            ]
          : _vm._e(),
        _c(
          "el-dropdown",
          {
            staticClass: "avatar-container right-menu-item hover-effect",
            attrs: { trigger: "click" },
          },
          [
            _c(
              "div",
              { staticClass: "avatar-wrapper" },
              [
                _c("el-image", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.head, fit: "cover" },
                }),
                _c("span", { staticClass: "user-name" }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ],
              1
            ),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _vm.$store.getters.homeRoute === "/dashboard"
                  ? _c(
                      "router-link",
                      { attrs: { to: "/" } },
                      [
                        _c("el-dropdown-item", [
                          _vm._v(_vm._s(this.$t("首页"))),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.logout($event)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.$t("退出登录")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }