import { filterColumns } from '@/utils'
import { validURL } from '@/utils/validate'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('排序'),
      key: 'sortBy',
      align: 'center',
      width: 80
    },
    {
      title: this.$t('礼物图标'),
      key: 'giftIcon',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          if (validURL(row.giftIcon)) {
            return <el-image src={row.giftIcon} style='width: 40px; height: 40px' fit='cover' lazy></el-image>
          }
        }
      }
    },
    {
      title: this.$t('礼物名称'),
      key: 'giftName',
      align: 'center'
    },
    {
      title: this.$t('礼物属性'),
      key: 'giftType',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.giftType === 1 ? this.$t('连击礼物') : this.$t('动画礼物') }</span>)
        }
      }
    },
    {
      title: this.$t('礼物价格'),
      key: 'gold',
      align: 'center'
    },
    // {
    //   title: '礼物分类',
    //   key: 'gift',
    //   align: 'center'
    // },
    {
      title: this.$t('动画地址'),
      key: 'giftAction',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <el-tooltip placement='top'>
              <div slot='content'>{ row.giftAction }</div>
              <el-button type='text'>{this.$t('查看')}</el-button>
            </el-tooltip>
          )
        }
      }
    },
    {
      title: this.$t('礼物状态'),
      key: 'giftStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.giftStatus}
              active-value={1}
              inactive-value={2}
              beforeChange={this._handleChangeStatus.bind(this, row)}
              disabled={!this.permission.GiftStartStop}
            />
          )
        }
      }
    },
    {
      title: this.$t('动画状态'),
      key: 'giftActionStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.giftActionStatus}
              active-value={1}
              inactive-value={2}
              beforeChange={this._handleChangeActionStatus.bind(this, row)}
              disabled={!this.permission.ActionStartStop}
            />
          )
        }
      }
    },
    // {
    //   title: this.$t('联动状态'),
    //   key: 'giftLinkStatus',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (
    //         <d-switch
    //           v-model={row.giftLinkStatus}
    //           active-value={1}
    //           inactive-value={2}
    //           beforeChange={this._handleChangeLinkStatus.bind(this, row, 'giftLinkStatus')}
    //           disabled={!this.permission.LinkStartStop}
    //         />
    //       )
    //     }
    //   }
    // },
    // {
    //   title: '震动方案',
    //   key: 'plan',
    //   align: 'center'
    // },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 140,
      component: {
        render: (h, row) => {
          const { Update } = this.permission
          return (Update &&
              <el-button
                type='success'
                size='mini'
                icon='el-icon-edit-outline'
                onClick={() => this.$refs.ActionDialogControl.open(row)}>{this.$t('编 辑')}</el-button>)
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
