var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "ruleForm",
      staticClass: "basic_config",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "140px" },
    },
    [
      _c("h2", [_vm._v("红包开关配置")]),
      _c(
        "el-form-item",
        { attrs: { label: "聊天室红包开关", prop: "ruleSwitch" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleForm.ruleSwitch,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "ruleSwitch", $$v)
                },
                expression: "ruleForm.ruleSwitch",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("h2", [
        _vm._v("红包发放配置"),
        _c("p", [_vm._v("未领取的红包，将于24小时后发起退款")]),
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "单个红包最大金额", prop: "maxAmount" } },
        [
          _c(
            "el-input",
            {
              model: {
                value: _vm.ruleForm.maxAmount,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "maxAmount", _vm._n($$v))
                },
                expression: "ruleForm.maxAmount",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("元")])],
            2
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "单个红包最大个数", prop: "maxNumber" } },
        [
          _c(
            "el-input",
            {
              model: {
                value: _vm.ruleForm.maxNumber,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "maxNumber", _vm._n($$v))
                },
                expression: "ruleForm.maxNumber",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("个")])],
            2
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "发放红包时间", prop: "sendTime" } },
        [
          _c("el-time-picker", {
            staticStyle: { width: "281px" },
            attrs: {
              "is-range": "",
              "range-separator": "至",
              "start-placeholder": "开始时间",
              "end-placeholder": "结束时间",
              format: "HH:mm",
              placeholder: "选择发放红包时间",
            },
            model: {
              value: _vm.sendTime,
              callback: function ($$v) {
                _vm.sendTime = $$v
              },
              expression: "sendTime",
            },
          }),
        ],
        1
      ),
      _c(
        "h2",
        [
          _vm._v("发放红包配置"),
          _c("p", [
            _vm._v("满足条件会员才可以发红包，用户达到等级取最高配置值"),
          ]),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.addConfig },
            },
            [_vm._v("+ 添加")]
          ),
        ],
        1
      ),
      _vm._l(_vm.ruleForm.confVipRequests, function (item, index) {
        return _c(
          "el-form-item",
          { key: index, staticClass: "flex-item" },
          [
            index === _vm.ruleForm.confVipRequests.length - 1
              ? _c(
                  "el-form-item",
                  {
                    staticClass: "flex-item",
                    attrs: { label: "*VIP等级", prop: "vipStart" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "119px" },
                        on: {
                          change: function ($event) {
                            return _vm.changeStartVip(item, index)
                          },
                        },
                        model: {
                          value: item.vipStart,
                          callback: function ($$v) {
                            _vm.$set(item, "vipStart", $$v)
                          },
                          expression: "item.vipStart",
                        },
                      },
                      _vm._l(_vm.Levels, function (level) {
                        return _c("el-option", {
                          key: level.id,
                          attrs: {
                            value: level.vipLevel,
                            label: "VIP" + level.vipLevel,
                          },
                        })
                      }),
                      1
                    ),
                    _c("p", [_vm._v("（含）以上才能发红包")]),
                  ],
                  1
                )
              : _c(
                  "el-form-item",
                  {
                    staticClass: "flex-item",
                    attrs: { label: "*VIP等级", prop: "vipStart" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "80px" },
                        on: {
                          change: function ($event) {
                            return _vm.changeStartVip(item, index)
                          },
                        },
                        model: {
                          value: item.vipStart,
                          callback: function ($$v) {
                            _vm.$set(item, "vipStart", $$v)
                          },
                          expression: "item.vipStart",
                        },
                      },
                      _vm._l(_vm.Levels, function (level) {
                        return _c("el-option", {
                          key: level.id,
                          attrs: {
                            value: level.vipLevel,
                            label: "VIP" + level.vipLevel,
                            disabled:
                              _vm.ruleForm.confVipRequests[index + 1]
                                .vipStart <= level.vipLevel,
                          },
                        })
                      }),
                      1
                    ),
                    _c("p", [_vm._v("~")]),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "vipEnd" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "80px" },
                            attrs: { disabled: "" },
                            model: {
                              value: item.vipEnd,
                              callback: function ($$v) {
                                _vm.$set(item, "vipEnd", $$v)
                              },
                              expression: "item.vipEnd",
                            },
                          },
                          _vm._l(_vm.Levels, function (level) {
                            return _c("el-option", {
                              key: level.id,
                              attrs: {
                                value: level.vipLevel,
                                label: "VIP" + level.vipLevel,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("p", [_vm._v("才能发红包")]),
                  ],
                  1
                ),
            _c(
              "el-form-item",
              { staticClass: "fhbcs", attrs: { prop: "vipSendNumber" } },
              [
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "140px" },
                    model: {
                      value: item.vipSendNumber,
                      callback: function ($$v) {
                        _vm.$set(item, "vipSendNumber", _vm._n($$v))
                      },
                      expression: "item.vipSendNumber",
                    },
                  },
                  [_c("template", { slot: "append" }, [_vm._v("次")])],
                  2
                ),
              ],
              1
            ),
            _c("p", [_vm._v("（-1代表不限制），")]),
            _c(
              "el-form-item",
              {
                staticClass: "fhbze",
                attrs: { label: "每日发红包总额", prop: "vipSendTotalAmount" },
              },
              [
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "180px" },
                    model: {
                      value: item.vipSendTotalAmount,
                      callback: function ($$v) {
                        _vm.$set(item, "vipSendTotalAmount", $$v)
                      },
                      expression: "item.vipSendTotalAmount",
                    },
                  },
                  [_c("template", { slot: "append" }, [_vm._v("元")])],
                  2
                ),
              ],
              1
            ),
            index !== _vm.ruleForm.confVipRequests.length - 1
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { type: "danger" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteConfig(index)
                      },
                    },
                  },
                  [_vm._v("- 删除")]
                )
              : _vm._e(),
          ],
          1
        )
      }),
      _c("h2", [
        _vm._v("领红包配置"),
        _c("p", [_vm._v("满足条件会员才可以参与领红包")]),
      ]),
      _c(
        "el-form-item",
        {
          staticClass: "flex-item",
          attrs: { label: "VIP等级", prop: "vipReceiveMinLevel" },
        },
        [
          _c(
            "el-select",
            {
              model: {
                value: _vm.ruleForm.vipReceiveMinLevel,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "vipReceiveMinLevel", $$v)
                },
                expression: "ruleForm.vipReceiveMinLevel",
              },
            },
            _vm._l(_vm.Levels, function (level) {
              return _c("el-option", {
                key: level.id,
                attrs: { value: level.vipLevel, label: "VIP" + level.vipLevel },
              })
            }),
            1
          ),
          _c("p", [_vm._v("以上才能领红包")]),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "action-button" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("保存配置")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }