<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="search.position"
        clearable
        :placeholder="$t('广告图位置')"
        class="filter-item"
      >
        <el-option v-for="item in positionOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-input
        v-model="search.title"
        maxlength="16"
        clearable
        :placeholder="$t('标题')"
        class="filter-item"
        style="width: 200px"
      />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" icon="el-icon-plus" class="filter-item" type="success" @click="_onCreate">{{ $t('添加广告') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :drag-row="true"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      row-key="id"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <el-button type="success" plain size="small" @click="_batchUpdateCpad()">{{ $t('全部保存') }}</el-button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" @update="_handleUpdate" />
  </div>
</template>

<script>
import TableSortMixin from '@/mixins/table-sort-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import ActionDialog from './ActionDialog'
import GetColumns from './columns'
import { getCpadList, batchUpdateCpad, addCpad, updateCpad, deleteCpad } from '@/api/activity'
import { _getparams, positionOptions } from './utils'

export default {
  name: 'ActivityAdvertising',
  components: { ActionDialog },
  mixins: [TablePageMixin(getCpadList), TableSortMixin()],
  data() {
    return {
      positionOptions,
      defaultColumns: GetColumns.call(this), // 默认表格
      columns: [],
      search: {}
    }
  },
  methods: {
    _onCreate() {
      this.$refs.ActionDialogControl.open()
    },
    // _editStatus(v) { // 修改启用状态
    //   return new Promise((resolve) => {
    //     const { name, binding } = v
    //     this.$confirm(`确认修改 ${name} 启用状态吗？`, { type: 'warning' }).then(() => {
    //       updateBankStatus({ name, binding: binding === 0 ? 1 : 0 }).then(([data, err]) => {
    //         if (!err) {
    //           resolve()
    //           this.handleRefresh()
    //           this.$message.success(`${name} 启用状态修改成功！`)
    //         }
    //       })
    //     }).catch(() => {})
    //   })
    // },

    _handleCreate({ field, cancel, close }) {
      addCpad(_getparams(field)).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      updateCpad(_getparams(field)).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    _handleDelete({ id, title }) { // 删除广告
      this.$confirm(`${this.$t('确认删除标题')} ${title} ${this.$t('广告图')}`, { type: 'warning' }).then(() => {
        const params = { id }
        deleteCpad(params).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    _batchUpdateCpad() {
      if (this.items.length === 0) {
        this.$message.warning(this.$t('暂无数据无法保存！'))
        return
      }
      this.$confirm(this.$t('确认批量修改公告排序?'), { type: 'warning' }).then(() => {
        const dataList = this.items.map((o, i) => ({
          id: o.id,
          status: o.status,
          sort: this.total - (this.params.currentPage - 1) * this.params.pageSize - i
        }))

        batchUpdateCpad({ dataList }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('批量修改成功！'))
            this.handleRefresh()
          }
        })
      })
    },

    implementationGetParams() {
      return { ...this.search }
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
