<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="daterange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetDate($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 7)">{{ $t('_7天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 30)">{{ $t('本月') }}</el-button>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Export" type="primary" :loading="excelLoading" icon="el-icon-download" @click="_handleExport">{{ $t("导出") }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="false"
    />
  </div>
</template>
<script>
import { tenantThirdReport, tenantThirdReportDownload } from '@/api/statistical'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getDefaultTimes, exportExcel } from '@/utils'
import { seriesTypes, seriesPlayTypes } from '@/libs/options'
export default {
  name: 'SeriesTab',
  mixins: [TablePageMixin(tenantThirdReport)],
  data() {
    return {
      times: getDefaultTimes('n'),
      search: {},
      columns: GetColumns.call(this),
      seriesTypes,
      seriesPlayTypes,
      excelLoading: false
    }
  },
  methods: {
    implementationGetParams() {
      return this.formatIntervalTime()
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    _handleExport() {
      this.excelLoading = true
      tenantThirdReportDownload({ ...this.implementationGetParams() }).then(([data, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `游戏系列统计-${this.$parseTime(new Date())}.csv`)
        }
      }).finally(()=>{
        this.excelLoading = false
      })
    }
  }
}
</script>
