<template>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="210px">
    <el-row v-loading="loading" :gutter="24" class="basic_config_content">
      <el-col :span="16">
        <div class="titleLine">
          <div class="title">推单入口配置</div>
        </div>
        <el-form-item label="推单总开关" prop="followBetMainSwitch">
          <el-radio-group v-model="ruleForm.followBetMainSwitch">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
          <div class="desc">
            至少选择一个，所有的入口都会隐藏，包括发单的下注按钮
          </div>
        </el-form-item>
        <!-- <el-form-item label="活动性质" prop="activityType">
          <el-checkbox-group v-model="ruleForm.activityType">
            <el-checkbox label="体育竞彩" name="activityType"></el-checkbox>
            <el-checkbox label="六合彩" name="activityType"></el-checkbox>
          </el-checkbox-group>
          <div class="desc">
            至少选择一个，若关闭的游戏，则连同对应游戏的排行榜数据也不展示
          </div>
        </el-form-item> -->
        <el-form-item label="滚球热门推荐" prop="rollerBallPopularSwitch">
          <el-radio-group v-model="ruleForm.rollerBallPopularSwitch">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
          <div class="desc" style="opacity: 0">x</div>
        </el-form-item>
        <el-form-item label="竞彩足球推荐" prop="lotteryPopularSwitch">
          <el-radio-group v-model="ruleForm.lotteryPopularSwitch">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
          <div class="desc" style="opacity: 0">x</div>
        </el-form-item>
        <el-form-item label="六合彩热门推荐" prop="maxSixPopularSwitch">
          <el-radio-group v-model="ruleForm.maxSixPopularSwitch">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
          <div class="desc" style="opacity: 0">x</div>
        </el-form-item>
        <div class="titleLine">
          <div class="title">大神申请配置</div>
          <div class="desc">满足条件会员才可以发起申请成为大神</div>
        </div>
        <el-form-item label="VIP等级" prop="guruVipLevelLimit">
          <el-select v-model="ruleForm.guruVipLevelLimit" placeholder="请选择">
            <el-option
              v-for="level of 100"
              :key="level"
              :value="level-1"
              :label="`VIP${level-1}`"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="" prop="moneyRange">
          <el-form-item label="" prop="hourLimit">
            <el-input v-model="ruleForm.hourLimit" />
            <div class="inlineText">小时内可申请次</div>
          </el-form-item>
          <el-form-item label="" prop="timesLimit">
            <el-input v-model="ruleForm.timesLimit" />
            <div class="inlineText">次</div>
          </el-form-item>
        </el-form-item> -->
        <h2>平台佣金配置</h2>

        <el-form-item label="平台抽取佣金" prop="platformRate">
          <!-- <el-input v-model="ruleForm.platformRate" type="number" />% -->
          <PercentInput v-model="ruleForm.platformRate" />
        </el-form-item>

        <div class="titleLine">
          <div class="title">发单展示配置</div>
          <div class="desc">
            满足条件会员的发单才会展示热门跟单列表上，我的关注不受影响
          </div>
        </div>
        <el-form-item label="7日内至少连中" prop="sevenDaysLeastHit">
          <el-input v-model="ruleForm.sevenDaysLeastHit" type="number" />
          <div class="inlineText">单</div>
        </el-form-item>
        <el-form-item label="7日内至少盈利率" prop="sevenDaysLeastProfit">
          <PercentInput v-model="ruleForm.sevenDaysLeastProfit" />
        </el-form-item>
        <el-form-item label="发单最低投注金额" prop="orderLeastAmount">
          <el-input v-model="ruleForm.orderLeastAmount" type="number" />
          <div class="inlineText">元</div>
        </el-form-item>
        <el-form-item label="最低抽佣盈利率" prop="leastProfitRate">
          <PercentInput v-model="ruleForm.leastProfitRate" />
        </el-form-item>
        <el-form-item label="发单佣金比例" prop="allowGuruRate">
          <div class="elInputs">
            <div v-for="index of 10" :key="index">
              <!-- <el-input-number
                controls-position="right"
                :min="0"
                :max="100"
                v-model="ruleForm.allowGuruRate[index - 1]"
              >
              </el-input-number
              >% -->
              <PercentInput  v-model="ruleForm.allowGuruRate[index - 1]"/>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="六合彩每期支持大神发单" prop="lotteryGuruIssueOrderNum">
          <el-input v-model="ruleForm.lotteryGuruIssueOrderNum" type="number" />
          <div class="inlineText">次</div>
        </el-form-item>
        <el-form-item label="体育竞彩每次支持大神发单" prop="sportGuruIssueOrderNum">
          <el-input v-model="ruleForm.sportGuruIssueOrderNum" type="number" />
          <div class="inlineText">次</div>
        </el-form-item>
        <h2>跟单配置</h2>

        <el-form-item label="跟单最低投注金额" prop="leastFollowAmount">
          <el-input v-model="ruleForm.leastFollowAmount" type="number" />元
        </el-form-item>
        <div class="titleLine">
          <div class="title">评论条件</div>
          <div class="desc">满足条件会员才可以评论</div>
        </div>
        <el-form-item label="是否跟单才能评论" prop="onlyFollower">
          <el-radio-group v-model="ruleForm.onlyFollower">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
          <div class="desc">该发单的用户不受这个条件影响</div>
        </el-form-item>
        <el-form-item label="VIP等级" prop="commentVipLevel">
          <el-select v-model="ruleForm.commentVipLevel" placeholder="请选择">
            <el-option
              v-for="level of 100"
              :key="level"
              :value="level-1"
              :label="`VIP${level-1}`"
            />
          </el-select>
        </el-form-item>
        <!-- <div class="titleLine">
          <div class="title">规则配置说明</div>
        </div>
        <el-form-item>
          <Tinymce
            ref="tinymce"
            v-model="ruleForm.footballdesc"
            :height="250"
          />
        </el-form-item> -->
      </el-col>
    </el-row>
    <el-form-item class="action-button">
      <el-button type="danger" :loading="submitting" @click="handleSubmit">{{
        $t("保 存 配 置")
      }}</el-button>
      <!-- <el-button @click="$refs['ruleForm'].resetFields()">{{
        $t("重 置")
      }}</el-button> -->
    </el-form-item>
  </el-form>
</template>

<script>
import { getFollowbetConfig, PostGuruRuleAdd } from "@/api/orderManage";
import {
  requiredBlur,
  requireIntegerNormal,
  requiredChange,
  requirePercent0_100,
  integer0_9999,
} from "@/utils/validation.js";
import Tinymce from "@/components/Tinymce";
import { ruleForm } from "@/views/agent-rebate/Config/pageData";
import PercentInput from "@/components/PercentInput.vue";
export default {
  name: "RulesConfig",
  componentName: "RulesConfig",
  components: { Tinymce,PercentInput },
  data() {
    return {
      loading: false,
      submitting: false,
      Levels: [],
      ruleForm: {
        allowGuruRate: [],
      },
      rules: {
        xsVipSend: requiredChange,
        xsVipReply: requiredChange,
        xsSendNum: requireIntegerNormal,
        xsReplyNum: requireIntegerNormal,
        lhcReplyNum: requiredChange,
        lhcVipReply: requireIntegerNormal,
        circleVipSend: requiredChange,
        circleVipReply: requiredChange,
        circleSendNum: requireIntegerNormal,
        circleReplyNum: requireIntegerNormal,
        delCircleStatus: requiredBlur,
        lotteryChatVipSpeak: requiredChange,
        lotteryChatWordLimit: [
          { required: true, message: this.$t("请输入"), trigger: "blur" },
          {
            pattern: integer0_9999,
            message: this.$t("请输入0-9999"),
            trigger: "blur",
          },
        ],
        liveChatVipSpeak: requiredChange,
        liveChatWordLimit: [
          { required: true, message: this.$t("请输入"), trigger: "blur" },
          {
            pattern: integer0_9999,
            message: this.$t("请输入0-9999"),
            trigger: "blur",
          },
        ],
        vipPushOrder: requiredChange,
        pushOrderNum: requireIntegerNormal,
        vipReward: requiredChange,
        rewardNum: requireIntegerNormal,
        rewardNumTestUser: requireIntegerNormal,
        maxRewardAmount: requireIntegerNormal,
        bonusLimit: requirePercent0_100,
        oddsLimit: requirePercent0_100,
        systemBonus: requirePercent0_100,
      },
    };
  },
  mounted() {
    this.getInitData();
  },
  methods: {
    dealInputValue(target) {
      let isNumber = /^[0-9]+(\.[0-9]+)?$/.test(target);
      if (!isNaN(target) && isNumber) {
        return Number(target);
      } else {
        return target;
      }
    },
    dealArray(arr) {
      if (!Array.isArray(arr)) return [];
      return arr
        .filter((item) => item !== undefined && item !== null)
        .sort((a, b) => a - b).map((item=>Number(item)));
    },
    handleSubmit() {
      let requestData = {
        guruRuleEntrance: {
          followBetMainSwitch: null,
          rollerBallPopularSwitch: null,
          maxSixPopularSwitch: null,
          lotteryPopularSwitch: null,
        },
        guruRuleOrderDisplay: {
          sevenDaysLeastHit: 0,
          sevenDaysLeastProfit: 0,
          orderLeastAmount: 0,
          leastProfitRate: 0,
          allowGuruRate: [],
          lotteryGuruIssueOrderNum:0,
          sportGuruIssueOrderNum:0,

        },
        guruApplication: {
          guruVipLevelLimit: 1,
        },
        guruRulePlatformRate: {
          platformRate: 0,
        },
        guruRuleFollowOrder: {
          leastFollowAmount: 0,
        },
        guruRuleComment: {
          onlyFollower: null,
          commentVipLevel: 0,
        },
      };
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.ruleForm.allowGuruRate = this.dealArray(
            this.ruleForm.allowGuruRate
          );
          for (const key in this.ruleForm) {
            for (const topLevelkey in requestData) {
              if (Object.hasOwnProperty.call(requestData, topLevelkey)) {
                for (const secLevelKey in requestData[topLevelkey]) {
                  if (
                    Object.hasOwnProperty.call(
                      requestData[topLevelkey],
                      secLevelKey
                    ) &&
                    secLevelKey == key
                  ) {
                    requestData[topLevelkey][secLevelKey] = this.dealInputValue(
                      this.ruleForm[key]
                    );
                  }
                }
              }
            }
          }
          // console.log(".................", requestData);
          this._updateConfig(requestData);
        }
      });
    },
    _updateConfig(requestData) {
      this.submitting = true;
      PostGuruRuleAdd(requestData).then(([_, err]) => {
        this.submitting = false;
        if (!err) {
          this.$message.success(this.$t("保存成功"));
        }
      });
    },
    getInitData() {
      getFollowbetConfig().then(async ([data, err]) => {
        if (!err) {
          if(!data.allowGuruRate)data.allowGuruRate=[]
          this.ruleForm = data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.titleLine {
  display: flex;
  margin: 40px 0 10px;
  align-items: flex-end;
  line-height: 25px;
  .title {
    width: 210px;
    text-align: right;
    font-weight: 500;
    font-size: 20px;
  }
}
.desc {
  text-wrap: nowrap;
  margin-left: 10px;
  color: #999;
  font-size: 12px;
}
::v-deep .el-form-item__content {
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  align-items: center;
  .inlineText {
    flex-shrink: 0;
  }
}
::v-deep .el-input {
  min-width: 60px;
  max-width: 100px;
}
.elInputs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>
