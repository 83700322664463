import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('彩种'),
      key: 'lotteryName',
      align: 'center',
      width: 150
    },
    {
      title: this.$t('属性'),
      key: 'attributeName',
      align: 'center',
      width: 150
    },
    {
      title: this.$t('赔率'),
      key: 'odds',
      align: 'center',
      minWidth: 140,
      component: {
        render: (h, row) => {
          return (
            <el-input v-model={row.odds}></el-input>
          )
        }
      }
    },
    {
      title: this.$t('最小投注额'),
      key: 'minBetsMoney',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          return (
            <el-input v-model={row.minBetsMoney} readonly={!this.permission.Min} />
          )
        }
      }
    },
    {
      title: this.$t('最大投注额'),
      key: 'maxBetsMoney',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          return (
            <el-input v-model={row.maxBetsMoney} readonly={!this.permission.Max} />
          )
        }
      }
    },
    {
      title: this.$t('编码'),
      key: 'attributeTag',
      align: 'center'
    },
    {
      title: this.$t('直播间开关'),
      key: 'liveShowStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch v-model={row.liveShowStatus} activeValue={1} inactiveValue={0} active-color='#13ce66' />
          )
        }
      }
    },
    {
      title: this.$t('状态'),
      key: 'status',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <d-switch v-model={row.status} activeValue={1} inactiveValue={0} disabled={!this.permission.StartStop} />
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <el-link type='primary' onClick={this._handleUpdate.bind(this, row)}>{this.$t('保存修改')}</el-link>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
