<template>
  <div class="home-tab">
    <div>
      <div>
        <h3>{{ $t(title) }}</h3>
        <el-table
          ref="elTable"
          border
          :data="items"
          row-key="sort"
          :drag-row="true"
          v-loading="loading"
        >
          <el-table-column type="index" align="center" width="60">
            <template #header>{{ $t("排序") }}</template>
            <svg-icon icon-class="drag" class-name="drag-handler" />
          </el-table-column>
          <el-table-column :label="$t('热门系列')" align="center">
            <template slot-scope="{ row }">
              <el-select
                v-model="row.seriesTag"
                style="width: 100%"
                clearable
                filterable
                @change="(value) => onChangeSeries(row, value)"
              >
                <el-option
                  v-for="series in seriesList.filter(e => !notSeriesTag.includes(e.seriesTag))"
                  :key="series.seriesTag"
                  :value="series.seriesTag"
                  :label="series.seriesName"
                />
              </el-select>
            </template>
          </el-table-column>

          <el-table-column :label="$t('游戏')" align="center">
            <template slot-scope="{ row }">
              <el-input v-if="inputSeriesTagTag.includes(row.seriesTag)" v-model="row.gameLink" />
              <el-select 
                v-else
                v-model="row.lotteryId"
                style="width: 100%"
                clearable
                filterable
                :placeholder="$t('请选择游戏')"
                @change="(value) => onChangeGame(row, value)"
              >
                <el-option
                  v-for="lottery in gameList(row.seriesTag)"
                  :key="lottery.lotteryId"
                  :value="lottery.lotteryId"
                  :label="lottery.name"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column :label="$t('图标')" align="center" width="140">
            <template slot-scope="{ row, $index }">
              <el-upload
                class="avatar-uploader"
                accept="image/png,image/jpeg,image/gif,image/webp"
                :action="action"
                :data="{ type: 'image', folder: 'icon' }"
                :headers="$headers()"
                :show-file-list="false"
                :on-success="
                  (...args) => handleUploadSuccess(args, row, $index)
                "
                :before-upload="beforeIconUpload"
              >
                <el-image
                  v-if="row.hotIcon"
                  :src="row.hotIcon"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column :label="$t('操作')" align="center" width="140">
            <template slot-scope="{ row }">
              <el-button @click="resetRow(row)">重置</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 10px 0" align="right">
          <el-button
            type="success"
            :loading="submitting"
            @click="handleSubmit()"
          >{{ $t("保 存") }}</el-button>
        </div>
      </div>
      <iframe
        ref="ifr1"
        :src="h5Url('ent')"
        frameborder="0"
        scrolling="no"
        width="600"
        height="1000"
      />
    </div>
  </div>
</template>
<script>
import {
  getLotteryNavigationHot,
  getLotterySettingList,
  updateLotterySettingList,
  updateSettingByType
} from '@/api/config'
import { getLiveRoomList } from '@/api/chat'
import TableSortMixin from '@/mixins/table-sort-mixin'
import { inputSeriesTagTag } from '@/libs/options'

export default {
  name: 'HotSetting',
  componentName: 'HomeTab',
  mixins: [TableSortMixin(true)],
  props: {
    type: {
      type: Number,
      default: () => 7
    },
    vt: {
      type: String,
      default: () => 'ent'
    },
    msgType: {
      type: String,
      default: () => 'hot'
    },
    title: {
      type: String,
      default: () => '娱乐首页推荐排序'
    },
    notSeriesTag: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      submitting: false,
      loading: true,
      items: [],
      seriesList: [],
      itemsSettings:[],
      inputSeriesTagTag
    }
  },

  computed: {

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    }

  },

  watch: {
    items: {
      handler(n, o) {
        const hotList = this.items.filter(p => p.seriesTag || p.lotteryId)
        this.$refs.ifr1.contentWindow.postMessage(
          { source: this.msgType, hotList },
          this.h5Url()
        )
      },
      deep: true
    }
  },
  async created() {
    await this.getHotList()
    await this.getSettingList()
    this.loading = false
  },

  methods: {
    h5Url() {
      const tenant = this.$store.state.user.info.tenantCode
      return `${process.env.VUE_APP_H5}/#/?vt=${
        { '9BGJ': 'int' }[tenant] || this.vt
      }&tenant=${tenant}`
    },
    gameList(seriesTag) {
      return this.seriesList.find(p => p.seriesTag == seriesTag)?.lotteryList || []
    },
    handleUploadSuccess([res], row, index) {
      row.hotIcon = res.data[0]
    },

    beforeIconUpload(file) {
      console.log(file.type)
      const isJPG = file.type === 'image/png' || file.type === 'image/jpeg'|| file.type === 'image/jpeg'|| file.type === 'image/gif'|| file.type === 'image/webp'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传彩种图标只能是 PNG、JPG、GIF、WEBP 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传彩种图标大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    async getHotList() {
      let res = await getLotteryNavigationHot()
      // 聊天室
      let chat = res[0].find((p) => p.seriesTag == '9999007')
      if (chat) {
        chat.lotteryList = (await getLiveRoomList({ roomType: 1 }))[0].map(
          (p) => ({
            lotteryId: p.roomId,
            name: p.roomName
          })
        )
      }
      this.seriesList = res[0]
    },
    async getSettingList() {
      let res = await getLotterySettingList({ type: this.type })
      this.itemsSettings=JSON.parse(JSON.stringify(res[0]||[]))
      this.items = Array.from({ length: 12 }).map((p, index) => {
        let info = res[0][index] || ({ content: '', gameLink: '', hotIcon: '', icon: null, lotteryId: null, name: null, seriesName: '', seriesTag: '', sort: index, status: 1, type: this.type })
        if (!info.lotteryId)info.content = ''
        if ((!info.lotteryId && info.seriesName) || info.seriesTag == '9999012')info.content = info.seriesName
        if (info.name)info.content = info.name
        return info
      })
    },

    onChangeSeries(row, value) {
      let info = this.seriesList.find((o) => o.seriesTag === value)
      this.$set(row, 'seriesName', info.seriesName)
      this.$set(row, 'content', info.seriesName)
      this.$set(row, 'seriesTag', info.seriesTag)
      this.$set(row, 'lotteryId', null)
      const setting= this.itemsSettings.find(p=>p.seriesTag==row.seriesTag)
      this.$nextTick(()=> this.$set(row, 'gameLink', setting?.gameLink||''))
    },
    onChangeGame(row, value) {
      let series = this.seriesList.find((o) => o.seriesTag === row.seriesTag)
      let info = series.lotteryList.find((o) => o.lotteryId === value)
      this.$set(row, 'lotteryId', info.lotteryId)
      this.$set(row, 'content', info.name)
     

      this.$forceUpdate()
    },
    resetRow(row) {
      Object.keys(row).forEach(key => {
        if(key != 'sort' &&  key != 'type'&&key != 'status'){
          row[key] = null
        }
      })
    },

    async handleSubmit() {
      const dataList = this.items.filter(p => p.seriesTag || p.lotteryId)
      if (dataList.length == 0) {
        const res = await updateSettingByType({ type: this.type })
        if (res[2].data.success) {
          this.$message.success(this.$t('保存成功'))
        } else {
          this.$message.error(this.$t('保存失败'))
        }
        return
      }
      if (dataList.some(p => !p.hotIcon)) return this.$message.error(this.$t('请选择热门图标'))

      //cdn加速器特殊处理，加上地址
      // const cdn= dataList.find(p=>p.seriesTag=='9999013')
      // if(cdn) cdn.gameLink='https://link.speedcn.in/promotion/1/'

      const res = await updateLotterySettingList(dataList)
      if (res[2].data.success) {
        this.$message.success(this.$t('保存成功'))
      } else {
        this.$message.error(this.$t('保存失败'))
      }
      this.getSettingList()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select.is-error .el-input__inner {
  border-color: #f56c6c;
}
.home-tab {
  > div {
    display: flex;
    gap: 16px;
    > div {
      width: 90%;
    }
    iframe {
      width: 428px;
      height: 978px;
      margin-top: 60px;
      border: 1px dashed #ccc;
      margin-right: 32px;
    }
  }
}
.avatar,
.avatar-uploader-icon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
