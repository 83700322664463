<template>
  <div>
    <div class="tab-header" flex="main:justify cross:bottom">
      <span v-if="permission.Send">
        <el-button v-for="(val, key) in xsLottery" :key="key" type="primary" @click="onRelease(key)">{{ val }}发帖</el-button>
      </span>
      <el-button-group>
        <el-button
          v-for="item in orderStatus"
          :key="item.value"
          size="small"
          :type="search.order === item.value && 'primary' || ''"
          @click="handleOrderStatus(item.value)"
        >{{ item.label }}</el-button>
      </el-button-group>

      <el-button-group>
        <el-button
          v-for="item in buttonStatus"
          :key="item.value"
          size="small"
          :type="search[item.value] === 1 && 'primary' || ''"
          @click="handleViewStatus(item.value)"
        >{{ item.label }}</el-button>
      </el-button-group>

      <el-button v-if="permission.Lock" type="danger" style="width: 180px" @click="onLockALL">一键锁帖</el-button>

    </div>
    <div class="filter-container">
      <div class="filter-container-item">
        <el-date-picker
          v-model="times"
          type="datetimerange"
          style="width: 400px;"
          range-separator="至"
          class="filter-item"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
        />
        <el-input v-model="search.title" class="filter-item" placeholder="标题" clearable style="width: 160px" />
        <el-input v-model="search.memberName" class="filter-item" placeholder="账号" clearable style="width: 160px" />
        <el-input v-model="search.nickname" class="filter-item" placeholder="昵称" clearable style="width: 160px" />
      </div>
      <div class="filter-container-item">
        <el-select v-model="search.rank" placeholder="用户头衔" clearable class="filter-item" style="width: 140px">
          <el-option v-for="item in $parent.ranks" :key="item.createTime" :label="item.rankName" :value="`${item.minAdmire}-${item.maxAdmire}`" />
        </el-select>
        <el-select v-model="search.source" placeholder="数据来源" clearable class="filter-item" style="width: 140px">
          <el-option label="IOS用户" value="IOS" />
          <el-option label="ANDROID用户" value="ANDROID" />
          <el-option label="H5用户" value="H5" />
          <el-option label="Web用户" value="WEB" />
        </el-select>
        <el-select v-model="search.lockStatus" placeholder="状态" clearable class="filter-item" style="width: 140px">
          <el-option label="未锁帖" :value="0" />
          <el-option label="锁帖" :value="1" />
        </el-select>
        <el-select v-model="search.lotteryId" placeholder="类别" clearable class="filter-item" style="width: 140px">
          <el-option v-for="(val, key) in xsLottery" :key="key" :value="key" :label="val" />
        </el-select>
        <el-button class="filter-item" type="success" icon="el-icon-search" @click="handleSearch">查询</el-button>
        <el-button class="filter-item" type="success" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </div>
    </div>

    <div class="d-table-container">
      <div flex="main:justify cross:center" class="d-table-header">
        <div>
          <button v-if="permission.BatchHiden" class="el-button el-button--small el-button--orange" :disabled="!selection.length" @click="handleBatchHidden">批量隐藏</button>
          <button v-if="permission.BatchLock" class="el-button el-button--small el-button--orange" :disabled="!selection.length" @click="handleBatchLimit1({lockStatus:0})">批量锁帖</button>
          <button v-if="permission.BatchLimit" class="el-button el-button--small el-button--orange" :disabled="!selection.length" @click="handleBatchLimit">批量限制</button>
          <button v-if="permission.BatchLock" class="el-button el-button--small el-button--orange" :disabled="!selection.length" @click="handleBatchLimit1({lockStatus:1})">批量解锁</button>
        </div>
        <div />
      </div>

      <el-table
        v-loading="loading"
        :data="items"
        v-bind="options"
        selection-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column prop="nickname" label="发帖人" width="100" align="center">
          <template slot-scope="{ row }">
            <p>[{{ xsLottery[row.lotteryId] }}]</p>
            <span class="primary-type">{{ row.nickname }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" header-align="center">
          <template slot-scope="{ row }">
            <div style="word-break: break-word;">
              <p style="line-height: 24px;white-space: pre-wrap;">{{ row.title }}</p>
              <div flex="main:justify cross:center">
                <span>
                  <el-tag
                    v-if="row.godTypeId"
                    type="warning"
                    effect="plain"
                    style="border-style: dashed; background-color: transparent;"
                  >{{ parseTypeName(row.godTypeId) }}</el-tag>
                  <el-tag
                    v-if="row.rankName"
                    type="success"
                    effect="plain"
                    style="border-style: dashed; background-color: transparent;"
                  >{{ row.rankName }}</el-tag>
                </span>
                <span style="color: #ccc">ID: {{ row.id }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="160" align="center">
          <template slot-scope="{ row }">{{ row.createTime | parseTime }}</template>
        </el-table-column>
        <el-table-column prop="viewNum" label="浏览数" width="140" align="center">
          <template slot-scope="{ row }">
            <template v-if="row.lockStatus === 1 || !permission.Add">{{ row.viewNum }}</template>
            <el-input-number
              v-else
              v-model="row.viewNum"
              size="small"
              controls-position="right"
              style="width: 110px"
              :min="0"
              step-strictly
              @blur="() => handleUpdateViewAndAdmire(row)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="admireNum" label="点赞数" width="140" align="center">
          <template slot-scope="{ row }">
            <template v-if="row.lockStatus === 1 || !permission.Add">{{ row.admireNum }}</template>
            <el-input-number
              v-else
              v-model="row.admireNum"
              size="small"
              controls-position="right"
              style="width: 110px"
              :min="0"
              step-strictly
              @blur="() => handleUpdateViewAndAdmire(row)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="340" align="center">
          <template slot-scope="{ row }">
            <div class="recommend-status">
              <template>
                <template v-if="row.freezeStatus === 1"><span style="color: red">禁言</span></template>
              </template>
              <template v-if="row.topViewStatus === 1"><span style="color: red">精华</span></template>
              <template v-if="row.topSort < 4"><span style="color: red">置顶</span></template>

              <template>
                <template v-if="row.lockStatus == 1"><span style="color: red">锁定</span></template>
                <template v-else><span>未锁</span></template>
              </template>
              <span>
                <template>
                  <p v-if="row.loginViewStatus === 1" style="color: red">登录可见</p>
                  <p v-else><span>非登录可见</span></p>
                </template>
                <template>
                  <p v-if="row.replyViewStatus === 1" style="color: red">回复可见</p>
                  <p v-else><span>非回复可见</span></p>
                </template>
              </span>
              <span>
                <template>
                  <template>
                    <p v-if="row.payViewStatus === 1" style="color: red">充值可见</p>
                    <p v-else><span>非充值可见</span></p>
                  </template>
                  <template>
                    <p v-if="row.vipViewLevel > 0" style="color: red">VIP{{ row.vipViewLevel }}以上可见</p>
                    <p v-else><span>VIP等级不限可见</span></p>
                  </template>
                </template>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="actions" label="操作" width="80" align="center">
          <template slot-scope="{ row }">
            <el-popover :ref="`popover-${row.id}`" trigger="hover" placement="top-end">
              <span slot="reference" class="pointer" style="padding: 0 5px">
                <i class="el-icon-arrow-down el-icon--right" />
              </span>

              <div class="vertical-popover">
                <div v-if="permission.Update" class="vertical-popover__item" @click="_handleClosePopover(row.id)">
                  <router-link :to="{ name: 'RecommendEdit', query: { id: row.id, lotteryId: row.lotteryId, type: row.type, code: undefined }}">
                    <i class="el-icon-edit" />修改帖子
                  </router-link>
                </div>
                <div v-if="permission.History" class="vertical-popover__item vertical-popover__item--divided" @click="_handleClosePopover(row.id)">
                  <router-link :to="{ name: 'RecommendHistory', query: { id: row.memberId, name: row.nickname, tenantCode: undefined }}">
                    <i class="el-icon-discover" />查看历史
                  </router-link>
                </div>
                <div v-if="permission.Top" class="vertical-popover__item vertical-popover__item--divided" @click="() => handleUpdateTop(row)"><i class="el-icon-upload2" />{{ row.topViewStatus === 0 ? '加精帖子' : '取消加精' }}</div>
                <div v-if="permission.Top" class="vertical-popover__item vertical-popover__item--divided" @click="() => handlePostTop(row)"><i class="el-icon-upload2" />{{ (row.topSort <= 3) ? '取消置顶' : '置顶帖子' }}</div>
                <div v-if="permission.Hiden" class="vertical-popover__item vertical-popover__item--divided" @click="() => handleUpdateHide(row)"><i class="el-icon-delete-solid" />隐藏贴子</div>
                <div v-if="permission.Limit" class="vertical-popover__item vertical-popover__item--divided" @click="() => handleBatchLimit(row)">
                  <template>
                    <i class="el-icon-warning-outline" />{{ ['彩讯禁言','解除禁言'][row.freezeStatus] }}
                  </template>
                </div>
                <div v-if="permission.Limit" class="vertical-popover__item vertical-popover__item--divided" @click="() => handleBatchLimit1(row)">
                  <template>
                    <i class="el-icon-lock" />{{ ['锁帖禁评','解锁评论'][row.lockStatus || 0] }}
                  </template>
                </div>
              </div>
            </el-popover>
          </template></el-table-column>
      </el-table>
    </div>

    <LockDialog ref="LockDialogControl" @update="_handleUpdateLock" />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import { getDefaultTimes } from '@/utils'
import LockDialog from './LockDialog'
import { xsLottery } from '@/libs/options'
import {
  getGodType,
  getRecommendList,
  updateLockAllPost,
  updateRecommendTop,
  updatePostTop,
  updateRecommendHiden,
  updateRecommendLimit,
  updateRecommendViewAndAdmire,
  bacthRecommendHidden,
  bacthRecommendLimit,
  bacthRecommendLock
} from '@/api/interactive'

const orderStatus = [
  { value: 'createTime', label: '创建时间' },
  { value: 'updateTime', label: '更新时间' }
]

const buttonStatus = [
  { value: 'topViewStatus', label: '精华' },
  { value: 'loginViewStatus', label: '登录可见' },
  { value: 'replyViewStatus', label: '回复可见' },
  { value: 'payViewStatus', label: '充值可见' }
]

export default {
  name: 'ListTab',
  componentName: 'ListTab',
  components: { LockDialog },
  mixins: [TablePageMixin(getRecommendList)],
  data() {
    return {
      times: getDefaultTimes(),
      search: {},
      selection: [],
      types: [],
      orderStatus,
      buttonStatus,
      xsLottery
    }
  },
  methods: {
    onRelease(lotteryId) {
      let query = { lotteryId }

      this.$router.push({ name: 'RecommendRelease', query })
    },

    onLockALL() {
      this.$refs.LockDialogControl.open()
    },

    _handleClosePopover(id) {
      this.$refs[`popover-${id}`].doClose()
    },

    _handleUpdateLock({ field, close, cancel }) {
      let params = { tenantCode: this.search.tenantCode, lotteryId: field || undefined }

      updateLockAllPost(params).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success('一键锁帖成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    // 加精
    handleUpdateTop({ id, topViewStatus }) {
      let params = { id: id, topViewStatus: ~topViewStatus + 2 }
      this.$confirm(`确定${topViewStatus === 1 ? '取消' : ''}加精此帖子`, '系统提示', { type: 'warning' }).then(() => {
        updateRecommendTop(params).then(([data, err]) => {
          if (!err) {
            this._handleClosePopover(id)
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    // 置顶
    handlePostTop({ id, topSort }) {
      let data = { id, isTop: (topSort < 4 ? 0 : 1) }// isTop 1是置顶 0是取消置顶
      this.$confirm(`确定${topSort === 1 ? '取消' : ''}置顶此帖子`, '系统提示', { type: 'warning' }).then(() => {
        updatePostTop(data).then(([data, err]) => {
          if (!err) {
            this._handleClosePopover(id)
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    handleUpdateHide({ id }) {
      let params = { postId: id }
      this.$confirm('确定隐藏此帖子', '系统提示', { type: 'warning' }).then(() => {
        updateRecommendHiden(params).then(([data, err]) => {
          if (!err) {
            this._handleClosePopover(id)
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    handleUpdateLimit({ id, memberId }) {
      let data = { memberIds: [memberId] }
      this.$confirm('确定限制此用户发帖跟评论', '系统提示', { type: 'warning' }).then(() => {
        updateRecommendLimit(data).then(([data, err]) => {
          if (!err) {
            this._handleClosePopover(id)
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    handleUpdateViewAndAdmire({ id, viewNum, admireNum }) {
      let params = { id, viewNum, admireNum }
      updateRecommendViewAndAdmire(params).then(([_, err]) => {
        if (!err) {
          this.$message.success('更新成功')
        }
      })
    },

    /**
     * 批量隐藏
     */

    handleBatchHidden() {
      let data = {}
      data.postIds = this.selection.map(o => o.id)

      this.$confirm('确定批量限制隐藏贴子', '系统提示', { type: 'warning' }).then(() => {
        bacthRecommendHidden(data).then(([_, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    /**
     * 批量锁定 修改为新接口 两处 都用一个接口
     * muteStatus 是以用户为维度的 锁帖，lockStatus是以帖子为维度来锁定的
     */
    handleBatchLimit1({ id, lockStatus = 0 }) {
      if (lockStatus != undefined) {
        lockStatus = (lockStatus + 1) % 2
      }
      let data = { lockStatus }
      data.postIds = id ? [id] : this.selection.map(o => o.id)
      this.$confirm(`确定${lockStatus === 0 ? '解锁' : '锁定'}帖子`, '系统提示', { type: 'warning' }).then(() => {
        bacthRecommendLock(data).then(([_, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    /**
     * 批量限制/解除限制
     */

    handleBatchLimit({ memberId, freezeStatus = 0 }) {
      if (freezeStatus != undefined) {
        freezeStatus = (freezeStatus + 1) % 2
      }
      let data = { freezeStatus }
      data.memberIds = memberId ? [memberId] : this.selection.map(o => o.memberId)

      this.$confirm('确定限制用户发帖', '系统提示', { type: 'warning' }).then(() => {
        bacthRecommendLimit(data).then(([data, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    _getTypes(tenantCode) {
      let params = tenantCode ? { tenantCode } : {}
      getGodType(params).then(([data, err]) => {
        if (!err) {
          this.types = data || []
        }
      })
    },

    handleSelectionChange(selection) {
      this.selection = selection
    },

    handleOrderStatus(order) {
      this.search.order = this.search.order === order ? '' : order
      this.handleRefresh()
    },

    handleViewStatus(status) {
      this.buttonStatus.forEach(o => {
        if (status === o.value && !this.search[o.value]) {
          this.search[o.value] = 1
        } else {
          this.search[o.value] = undefined
        }
      })
      this.handleRefresh()
    },
    parseTypeName(value) {
      const tmp = this.types.find(o => o.id === value)
      return tmp && tmp.name || ''
    },

    implementationTenantChanged(tenantCode) {
      this._getTypes(tenantCode)
    },

    implementationGetParams() {
      return this.formatIntervalTime()
    }
  }
}
</script>
<style lang="scss">
.recommend-status {
  font-size: 12px;
  & > span {
    display: inline-block;
    padding: 0 10px;
    vertical-align: middle;
  }
}

.vertical-popover {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin: 0 -12px;
  &__item {
    writing-mode: vertical-rl;
    writing-mode: tb-rl;
    padding: 0 12px;
    cursor: pointer;
    letter-spacing: 8px;
    &--divided {
      border-right: 1px solid #ebeef5;
    }
    i {
      font-size: 18px;
    }
  }
}

</style>
