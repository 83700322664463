<template>
  <el-dialog :title="$t('添加白名单')" :visible.sync="visible" width="500px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('IP地址')" prop="ipAddress">
        <el-input v-model="ruleForm.ipAddress" />
      </el-form-item>
      <el-form-item :label="$t('备注')" prop="remark">
        <el-input v-model="ruleForm.remark" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  ipAddress: '',
  remark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        ipAddress: [
          { required: true, message: this.$t('请输入IP地址'), trigger: 'blur' },
          { pattern: /((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}/, message: this.$t('请输入正确IP'), trigger: 'blur' }
        ],
        remark: [
          { required: true, message: this.$t('请输入备注'), trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    open(row) {
      if (row) {
        this.ruleForm.id = row.id
        this.ruleForm.ipAddress = row.ipAddress
        this.ruleForm.remark = row.remark
        this.emitName = 'update'
      } else {
        this.emitName = 'create'
      }
      this.visible = true
    }
  }
}
</script>
