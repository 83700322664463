<template>
  <div class="app-container">
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <template slot="right">
        <el-button
          type="primary"
          icon="el-icon-refresh-left"
          @click="handleFetch"
        >{{ $t('刷新线路配置') }}</el-button>
        <el-button
          v-if="permission.Add"
          type="success"
          icon="el-icon-plus"
          @click="onCreate"
        >{{ $t('新建线路') }}</el-button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" @update="_handleUpdate" />
    <ActionPlayDialog ref="ActionPlayDialogControl" @create="_handleCreate" @update="_handleUpdate" />
    <ViewDialog ref="ViewDialogControl" />
  </div>
</template>

<script>
import { getAllPushServers, doAddServer, doEditServer, doDisableServer, doDelServer } from '@/api/live'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { ActionDialog, ActionPlayDialog, ViewDialog } from './components'
export default {
  name: 'LiveServer',
  components: { ActionDialog, ActionPlayDialog, ViewDialog },
  mixins: [TablePageMixin(getAllPushServers)],
  data() {
    return {
      columns: GetColumns.call(this),
      filterColumn: false
    }
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogControl.open()
    },

    _handleCreate({ field, cancel, close }) {
      const { fn, pull, ...other } = field
      const params = pull ? { ...other, serverurl: other.serverurl + '/live' } : other
      doAddServer({ ...params }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          fn && fn() || this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleUpdate({ field, cancel, close }) {
      const { fn, pull, ...other } = field
      const params = pull ? { ...other, serverurl: other.serverurl + '/live' } : other

      doEditServer({ ...params }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          fn && fn() || this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleChangeStatus({ status, tliveid }) {
      return new Promise((resolve) => {
        this.$confirm(status === false ? this.$t('确认启用此域名') : this.$t('确认停用此域名'), this.$t('系统提示'), { type: 'warning' }).then(() => {
          doDisableServer({
            tliveid,
            status: ~status + 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    _handleDelete({ servername, tliveid }) {
      this.$confirm(this.$t('确定删除name?', { text: servername }), { type: 'warning' }).then(() => {
        doDelServer({ tliveid }).then(([_, err]) => {
          if (!err) {
            this.handleFetch()
            this.$message.success(this.$t('删除成功'))
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
