<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="450px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      label-width="85px"
      label-suffix=":"
    >
      <el-form-item label="排序" prop="sort">
        <el-input v-model="ruleForm.sort" type="number" style="width: 310px" :placeholder="$t('请输入1-99的整数，由大到小')" clearable />
      </el-form-item>
      <el-form-item v-if="ruleForm.items && ruleForm.items.length > 0" :label="$t('商城类目')" prop="categoryName">
        <el-popover placement="bottom" title="商城类目多语言配置" trigger="click">
          <el-input slot="reference" style="width: 310px" :value="ruleForm.categoryName" readonly />
          <div class="lang-pop" style="width: 310px;">
            <el-input v-for="item of ruleForm.items" :key="item.langCode" v-model="item.categoryName" :placeholder="$t(item.langName)" maxlength="20" />
          </div>
        </el-popover>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getGategoryDetail } from '@/api/points'
import { mapState } from 'vuex'

const getDefaultForm = () => ({
  sort: null,
  items: [],
  nameCn: '',
  nameEn: '',
  nameVn: '',
  nameIn: '',
  nameJp: '',
  nameMy: '',
  categoryName: '',
})

export default {
  components: {  },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      rules: {
        sort: [{ required: true, message: this.$t('请输入排序序号'), trigger: 'change' }, { pattern: /^[1-9][0-9]?$|^99$/, message: this.$t('请输入1-99的整数'), trigger: 'change' }],
        categoryName: [{ required: true, message: this.$t('请输入商城类目名称'), trigger: 'change' }],
      },
    }
  },
  computed: {
    ...mapState('app', ['allLang']),
    title() {
      return this.emitName === 'create' ? this.$t('新建商城类目') : this.$t('修改商城类目')
    },
    categoryName() {
      if (this.ruleForm.items.length > 0) {
        return this.ruleForm.items.find(e => e.langCode === 'zh_CN').categoryName
      }
      return ''
    }
  },
  watch: {
    categoryName(val) {
      this.ruleForm.categoryName = val
    }
  },
  created() {

  },
  methods: {
    open(row) {
      this.visible = true
      const items = this.$parent.allLang.map(e => { return { langCode: e.lang, langName: e.name, categoryId: '', categoryName: '' } })
      if (!row) {
        this.emitName = 'create'
      } else {
        items.forEach(e => {
          const formLang = row.items?.find(r => r.langCode === e.langCode)
          if (formLang) {
            e.categoryId = formLang.categoryId
            e.categoryName = formLang.categoryName
          }
        })
        const id = row.id
        this.ruleForm.id = id
        this.ruleForm.sort = parseInt(row.sort)
        this.ruleForm.categoryName = row.categoryName
        this.emitName = 'update'
        // 商城类目详情
        getGategoryDetail({ categoryId: id }).then(res => {
          const data = res[0]
          const paramsArr = [data.nameCn, data.nameEn, data.nameVn, data.nameIn, data.nameJp, data.nameMy]
          items.forEach(function(el, i) {
            el.categoryName = paramsArr[i]
          })
        })
      }
      this.ruleForm.items = items
    },
  }
}
</script>

<style lang="scss" scoped>
.lang-pop {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
