var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: this.$t("平台公告"),
        visible: _vm.notice.visible,
        "append-to-body": "",
        top: "5vh",
        "custom-class": "custom-dialog",
        "close-on-click-modal": false,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.notice, "visible", $event)
        },
        open: _vm.onOpen,
      },
    },
    [
      _c(
        "div",
        { staticClass: "notice-dialog" },
        [
          _c(
            "el-scrollbar",
            { attrs: { "wrap-class": "scrollbar-wrapper" } },
            _vm._l(_vm.items, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "title-item",
                  class: [
                    _vm.activeItem && _vm.activeItem.id === item.id && "active",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(item)
                    },
                  },
                },
                [
                  _c("svg-icon", { attrs: { "icon-class": "message" } }),
                  _vm._v(" " + _vm._s(item.title) + " "),
                ],
                1
              )
            }),
            0
          ),
          _vm.activeItem
            ? _c("div", { staticClass: "notice-content" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.activeItem.title)),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("parseTime")(_vm.activeItem.createTime))
                    ),
                  ]),
                ]),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.activeItem.content) },
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }