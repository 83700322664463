<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetTimes($event,0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,7)">{{ $t('_7天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,30)">{{ $t('本月') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,60)">{{ $t('上月') }}</el-button>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <div class="filter-container">
      <el-input v-model="search.memberName" clearable :placeholder="$t('会员账号')" class="filter-item" maxlength="20" style="width: 310px" />
      <el-select v-model="search.changeType" multiple collapse-tags clearable placeholder="变动类型" class="filter-item" style="width: 160px">
        <el-option v-for="item in changeTypes" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-input v-model="search.orderId" clearable :placeholder="$t('订单号')" class="filter-item" maxlength="20" style="width: 310px" />
      <el-input v-model="search.remark" clearable :placeholder="$t('变动说明')" class="filter-item" maxlength="20" style="width: 310px" />
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :filter-column="filterColumn"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template #right>
        <el-button plain type="primary" :loading="excelLoading1" icon="el-icon-download" @click="_handleExport">{{ $t('导出EXCEL') }}</el-button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

  </div>
</template>

<script>
import { getPointsChangeList, exportPointsChangeList } from '@/api/cash'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getDefaultTimes, exportExcel } from '@/utils'
import { changeTypes } from '@/libs/options'

export default {
  name: 'CashPointsChange',
  mixins: [TablePageMixin(getPointsChangeList)],
  data() {
    return {
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
      excelLoading1:false,
      times: getDefaultTimes(),
      changeTypes,
    }
  },
  created() {

  },
  methods: {
    beforeFetch() {
      if (!this.times) {
        this.$message.info(this.$t('请选择查询时间'))
        return false
      } else {
        this.search.startTime = this.times[0]
        this.search.endTime = this.times[1]
        return true
      }
    },
    _handleExport() {
      const query = {
        ...this.implementationGetParams(),
      }
      this.excelLoading1 = true
      exportPointsChangeList(query).then(([data, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `积分账变记录-${this.$parseTime(new Date())}.csv`)
        }
      }).finally(()=>{
        this.excelLoading1 = false
      })
    },
    modifyObject(obj){
      for (let key in obj) {
        if (key === 'desc') {
            obj['label'] = obj[key];
        } else if (key === 'subBmResponseList') {
            obj['children'] = obj[key];
            obj['children'].forEach(child => this.modifyObject(child));
        } else if (key === 'bmResponseList') {
            obj['children'] = obj[key];
            obj['children'].forEach(child => this.modifyObject(child));
        }
      }
      return obj;
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
