<template>
  <div>
    <cascader-select
      v-model="value"
      :options="playClass"
      :parent-label="$t('一级玩法')"
      :child-label="$t('二级玩法')"
      @change="onChangeSelect"
    />

    <d-table
      ref="DTable"
      v-loading="loading"
      row-key="attributeTag"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <el-button v-if="permission.Lottery" plain @click="$refs.PresentDialogControl.open()">{{ $t('彩种介绍设置') }}</el-button>
        <el-button v-if="permission.SetOdds" type="primary" plain @click="$refs.NumberDialogControl.open('odds')">{{ $t('批量设置赔率') }}</el-button>
        <el-button v-if="permission.Min" type="primary" plain @click="$refs.NumberDialogControl.open('minBetsMoney')">{{ $t('最小金额批量设置') }}</el-button>
        <el-button v-if="permission.Max" type="primary" plain @click="$refs.NumberDialogControl.open('maxBetsMoney')">{{ $t('最大金额批量设置') }}</el-button>
        <el-button type="success" plain @click="_handleSave">{{ $t('全部保存') }}</el-button>
      </template>
    </d-table>

    <PresentDialog ref="PresentDialogControl" @update="_handleUpdatePresent" />
    <NumberDialog ref="NumberDialogControl" @update="_handleUpdateSize" />

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CascaderSelect from '@/components/CascaderSelect'
import { getOddsList, updateLotteryAttrOdds, updateLotteryExplain } from '@/api/lottery'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { PresentDialog, NumberDialog } from './components'

export default {
  name: 'OddsTab',
  componentName: 'OddsTab',
  components: { CascaderSelect, PresentDialog, NumberDialog },
  mixins: [TablePageMixin(getOddsList, false)],
  props: {
    lotteryId: {
      type: String,
      default: ''
    },
    playClass: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      params: {},
      value: [],
      columns: [],
      defaultColumns: GetColumns.call(this)
    }
  },
  computed: {
    ...mapGetters(['operatorType'])
  },
  created() {
    this.value = [this.playClass[0].value, this.playClass[0].children[0].value]
    this.params.lotteryId = this.lotteryId
    this.params.playClassTag = this.value[1]
    this.handleRefresh()
  },
  methods: {
    onChangeSelect(val) {
      this.params.playClassTag = val[1]
      this.handleRefresh()
    },
    _handleUpdate({ attributeName, lotteryName, ...other }) {
      this.$confirm(`${this.$t('确认修改属性值为text的信息', { text: attributeName })}`, { type: 'warning' }).then(() => {
        updateLotteryAttrOdds([{ ...other, lotteryId: this.lotteryId }], this.operatorType).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('修改成功'))
          }
        })
      }).catch(() => {})
    },

    _handleUpdatePresent({ field, cancel, close }) {
      updateLotteryExplain({
        lotteryId: field.lotteryId,
        explain: field.explain,
        headerLang: field.headerLang
      }, this.operatorType).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('修改成功'))
        } else {
          cancel()
        }
      })
    },

    _handleUpdateSize({ field, close }) {
      const { type, value } = field
      this.items = this.items.map(o => ({
        ...o,
        [type]: value
      }))
      close()
    },

    _handleSave() {
      this.$confirm(this.$t('确认修改所有属性值信息'), { type: 'warning' }).then(() => {
        const tmp = this.items.map(o => ({
          lotteryId: this.lotteryId,
          attributeTag: o.attributeTag,
          odds: o.odds,
          liveShowStatus: o.liveShowStatus,
          status: o.status,
          minBetsMoney: o.minBetsMoney,
          maxBetsMoney: o.maxBetsMoney
        }))
        updateLotteryAttrOdds(tmp, this.operatorType).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('保存成功'))
            this.handleRefresh()
          }
        })
      }).catch(() => {})
    },

    implementationGetParams() {
      return { ...this.params }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
