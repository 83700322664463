var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("修改会员"),
        visible: _vm.visible,
        width: "800px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateFormUserInfoEdit",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("头像") } },
            [
              _c("DUpload", {
                attrs: {
                  folder: "icon",
                  width: 50,
                  height: 50,
                  "on-success": _vm.handleSuccess,
                },
                model: {
                  value: _vm.ruleForm.avatar,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "avatar", $$v)
                  },
                  expression: "ruleForm.avatar",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("会员账号") } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.visibleForm.memberName,
                          callback: function ($$v) {
                            _vm.$set(_vm.visibleForm, "memberName", $$v)
                          },
                          expression: "visibleForm.memberName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("会员状态"),
                        prop: "freezeStatus",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(["启用", "停用"][_vm.ruleForm.freezeStatus]) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("会员昵称"), prop: "nickname" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.nickname,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "nickname", $$v)
                          },
                          expression: "ruleForm.nickname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("会员等级"), prop: "vipLevel" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("请选择会员等级"),
                            disabled: !_vm.permission.UpdateVip,
                          },
                          model: {
                            value: _vm.ruleForm.vipLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "vipLevel", $$v)
                            },
                            expression: "ruleForm.vipLevel",
                          },
                        },
                        _vm._l(_vm.$parent.vipLevels, function (item) {
                          return _c("el-option", {
                            key: item.vipLevel,
                            attrs: {
                              label: "VIP" + item.vipLevel,
                              value: item.vipLevel,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.more
            ? [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "真实姓名" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.realname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "realname", $$v)
                                },
                                expression: "ruleForm.realname",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "手机号码", prop: "phone" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "58px" },
                              model: {
                                value: _vm.ruleForm.pho1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "pho1", $$v)
                                },
                                expression: "ruleForm.pho1",
                              },
                            }),
                            _vm._v(" - "),
                            _c("el-input", {
                              staticStyle: { width: "188px" },
                              model: {
                                value: _vm.ruleForm.pho2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "pho2", $$v)
                                },
                                expression: "ruleForm.pho2",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  _vm._l(_vm.cardNumbers, function (item, i) {
                    return _c(
                      "el-col",
                      { key: item.id, attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("银行卡") } },
                          [
                            _c(
                              "div",
                              { attrs: { flex: "main:justify cross:center" } },
                              [
                                _c("span", [_vm._v(_vm._s(item.cardNumber))]),
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    size: "small",
                                    icon: "el-icon-delete",
                                    circle: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleChangeCard(i)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("测试用户设置") } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  label: 4,
                                  disabled: [1, 4].includes(
                                    _vm.ruleForm.memberType
                                  ),
                                },
                                model: {
                                  value: _vm.memberType,
                                  callback: function ($$v) {
                                    _vm.memberType = $$v
                                  },
                                  expression: "memberType",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("设置为测试账号")))]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  label: 1,
                                  disabled: [1, 4].includes(
                                    _vm.ruleForm.memberType
                                  ),
                                },
                                model: {
                                  value: _vm.memberType,
                                  callback: function ($$v) {
                                    _vm.memberType = $$v
                                  },
                                  expression: "memberType",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("设置为内部账号")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("用户生日"),
                              prop: "birthday",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                "value-format": "timestamp",
                                clearable: false,
                              },
                              model: {
                                value: _vm.ruleForm.birthday,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "birthday", $$v)
                                },
                                expression: "ruleForm.birthday",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _c(
                "div",
                { staticClass: "action-form", on: { click: _vm.handleToggle } },
                [
                  _c("p", [_vm._v(_vm._s(_vm.$t("更多内容")))]),
                  _c("i", { staticClass: "el-icon-arrow-down" }),
                ]
              ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("注册来源"),
                        prop: "registerResource",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.visibleForm.registerResource,
                          callback: function ($$v) {
                            _vm.$set(_vm.visibleForm, "registerResource", $$v)
                          },
                          expression: "visibleForm.registerResource",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("上级代理"),
                        prop: "agencyMemberName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: !_vm.permission.UpdateAgent },
                        model: {
                          value: _vm.visibleForm.agencyMemberName,
                          callback: function ($$v) {
                            _vm.$set(_vm.visibleForm, "agencyMemberName", $$v)
                          },
                          expression: "visibleForm.agencyMemberName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("聊天室"),
                        prop: "chatRoomStatus",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.chatRoomStatusLoading,
                              expression: "chatRoomStatusLoading",
                            },
                          ],
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": "1",
                              "inactive-value": "0",
                              "active-text": _vm.$t("启用"),
                              "inactive-text": _vm.$t("禁用"),
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                            },
                            on: { change: _vm._handleChangeChatStatus },
                            model: {
                              value: _vm.ruleForm.chatRoomStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "chatRoomStatus", $$v)
                              },
                              expression: "ruleForm.chatRoomStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 12 } }),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("彩讯禁言"),
                        prop: "xsFreezeStatus",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 0,
                              "inactive-value": 1,
                              "active-text": _vm.$t("启用"),
                              "inactive-text": _vm.$t("禁用"),
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleBatchLimit("xsFreezeStatus")
                              },
                            },
                            model: {
                              value: _vm.ruleForm.xsFreezeStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "xsFreezeStatus", $$v)
                              },
                              expression: "ruleForm.xsFreezeStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("发红包"),
                        prop: "canSendRedPacket",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-text": _vm.$t("启用"),
                              "inactive-text": _vm.$t("禁用"),
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                            },
                            model: {
                              value: _vm.ruleForm.canSendRedPacket,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "canSendRedPacket", $$v)
                              },
                              expression: "ruleForm.canSendRedPacket",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("专家打赏"), prop: "canReward" } },
                    [
                      _c(
                        "div",
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-text": _vm.$t("启用"),
                              "inactive-text": _vm.$t("禁用"),
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                            },
                            model: {
                              value: _vm.ruleForm.canReward,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "canReward", $$v)
                              },
                              expression: "ruleForm.canReward",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }