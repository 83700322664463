import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('下级账号'),
      key: 'memberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<p class='primary-type cursor-pointer hover-underline' onClick={() => this.$refs.UserDialogControl.open(row.memberId)}>{ row.memberName }</p>)
        }
      }
    },
    {
      title: this.$t('下级ID'),
      key: 'memberId',
      align: 'center'
    },
    // {
    //   title: this.$t('代理等级'),
    //   key: 'agencyLevel',
    //   align: 'center'
    // },
    {
      title: this.$t('贡献业绩'),
      key: 'achievementsAmount',
      align: 'center'
    },
    {
      title: this.$t('贡献返佣'),
      key: 'rebateAmount',
      align: 'center'
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
