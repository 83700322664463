import { $t } from '@/lang'
export const LOTTERY_METHODS = {
  1: {
    100: $t('接口开奖'),
    200: $t('平台开奖'),
    201: $t('杀号开奖')
  },
  2: {
    200: $t('平台开奖'),
    300: $t('独立随机开奖'),
    301: $t('独立杀号开奖')
  }
}
