<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="1330px"
    top="5vh"
    :close-on-click-modal="false"
    @open="onOpen"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      label-suffix=":"
    >
      <el-form-item label="活动形式" prop="redpacketType">
        <el-radio v-for="(val, key) in $parent.redpacketType" :disabled="emitName === 'update' || disabled" v-model="ruleForm.redpacketType" :key="key" :label="Number(key)">{{ val }}</el-radio>
      </el-form-item>
      <el-form-item label="活动标题" prop="redpacketTitle">
        <el-input v-model="ruleForm.redpacketTitle" maxlength="6" :disabled="disabled" placeholder="6个字以内" style="width: 500px" />
      </el-form-item>
      <el-form-item label="活动分类" prop="activityTypeId">
        <el-select v-model="ruleForm.activityTypeId" :disabled="disabled" placeholder="请选择活动分类" clearable style="width: 500px">
          <el-option
            v-for="(item, index) in $parent.activityCategory"
            :key="index"
            :label="item.typeName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="活动引导说明" prop="redpacketContent">
        <el-input v-model="ruleForm.redpacketContent" :disabled="disabled" maxlength="50" placeholder="50个字以内" style="width: 500px" />
      </el-form-item>
      <el-form-item label="活动时间" prop="redpacketTime">
        <div flex="main:center">
          <el-form-item prop="redpacketTime">
            <el-date-picker
              :disabled="disabled"
              v-model="ruleForm.redpacketTime"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            />
          </el-form-item>
          <el-form-item prop="receipts" label-width="30px">
            <el-select v-model="ruleForm.receipts" :disabled="disabled" style="width: 120px">
              <el-option :value="1" label="每天限领1次" />
              <el-option :value="0" label="仅领取1次" />
            </el-select>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="开启时间" v-if="ruleForm.redpacketType === 3" prop="redpacketTime">
        <div flex="main:center">
          <el-form-item prop="showTime">
            <el-time-picker
              :disabled="disabled"
              style="width: 281px;"
              is-range
              v-model="ruleForm.showTime"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="HH:mm"
              placeholder="选择开启时间">
            </el-time-picker>
          </el-form-item>
          <el-form-item prop="showSeconds" label="下雨时长" label-width="100px">
            <el-input v-model.number="ruleForm.showSeconds" :disabled="disabled" style="width: 120px">
              <template slot="append">S</template>
            </el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="红包金额" prop="robAmtMin" style="margin-top: 60px">
        <div class="robvip-range-wrapper">
          <div flex="cross:center">
            <el-form-item prop="robAmtMin">
              <el-input v-model="ruleForm.robAmtMin" :disabled="disabled" style="width: 120px" />
            </el-form-item>
            <span style="padding: 0 5px">~</span>
            <el-form-item prop="robAmtMax">
              <el-input v-model="ruleForm.robAmtMax" :disabled="disabled" style="width: 120px" />
            </el-form-item>
          </div>

          <div class="robvip-range">
            <el-form-item label-width="0">
              <div class="robvip-range-list">
                <div v-for="(item, index) in ruleForm.items" :key="item.key" class="robvip-range-item">
                  <i
                    v-if="ruleForm.items.length > 1"
                    class="el-icon-circle-close robvip-range-item__icon"
                    @click.stop="deleteItems(index)"
                  />
                  <div class="robvip-range-item__child">
                    <el-form-item
                      :prop="'items.' + index + '.vipBegin'"
                      :show-message="false"
                      :rules="[{ required: true, message: '此项必填', trigger: ['change', 'blur'] }]"
                    >
                      <el-select
                        :disabled="disabled"
                        v-model="item.vipBegin"
                        style="width: 75px"
                        placeholder=""
                        v-if="ruleForm.redpacketType === 2"
                      >
                        <el-option
                          v-for="level in Levels"
                          :key="level.id"
                          :value="level.vipLevel"
                          :label="`VIP${level.vipLevel}`"
                          :disabled="disabledBeginVip(index, level.vipLevel)"
                        />
                      </el-select>
                      <el-input style="width: 75px" :disabled="disabled" v-else-if="ruleForm.redpacketType === 3" v-model="item.vipBegin" />
                    </el-form-item>
                    <span style="padding: 0 5px">~</span>
                    <el-form-item
                      :prop="'items.' + index + '.vipEnd'"
                      :show-message="false"
                      :rules="[{ required: true, message: '此项必填', trigger: ['change', 'blur'] }]"
                    >
                      <el-select
                        v-model="item.vipEnd"
                        style="width: 75px"
                        :disabled="(typeof ruleForm.items[index].vipBegin) !== 'number' || disabled"
                        v-if="ruleForm.redpacketType === 2"
                      >
                        <el-option
                          v-for="level in Levels"
                          :key="level.id"
                          :value="level.vipLevel"
                          :label="`VIP${level.vipLevel}`"
                          :disabled="disabledEndVip(index, level.vipLevel)"
                        />
                      </el-select>
                      <el-input style="width: 75px" :disabled="disabled" v-else-if="ruleForm.redpacketType === 3" v-model="item.vipEnd" />
                    </el-form-item>
                  </div>
                  <div class="robvip-range-item__child">
                    <el-form-item
                      :prop="'items.' + index + '.rangeAMinAmount'"
                      :show-message="false"
                      :rules="rulesAmountFloatBlur"
                    >
                      <el-input v-model="item.rangeAMinAmount" :disabled="disabled" style="width: 75px" @blur="e => onAmountBlur(e, index, 'AMin')" />
                    </el-form-item>
                    <span style="margin: 0 5px">~</span>
                    <el-form-item
                      :prop="'items.' + index + '.rangeAMaxAmount'"
                      :show-message="false"
                      :rules="rulesAmountFloatBlur"
                    >
                      <el-input v-model="item.rangeAMaxAmount" style="width: 75px" :disabled="!item.rangeAMinAmount || disabled" @blur="e => onAmountBlur(e, index, 'AMax')" />
                    </el-form-item>
                    <el-form-item
                      style="margin-left: 10px"
                      :prop="'items.' + index + '.rangeARate'"
                      :show-message="false"
                      :rules="rulesPercentBlur"
                    >
                      <el-input v-model="item.rangeARate" :disabled="disabled" style="width: 75px" @blur="e => onRangeBlur(e, index, 'B')" /> %
                    </el-form-item>
                  </div>
                  <div class="robvip-range-item__child">
                    <el-form-item
                      :prop="'items.' + index + '.rangeBMinAmount'"
                      :show-message="false"
                      :rules="rulesAmountFloatBlur"
                    >
                      <el-input v-model="item.rangeBMinAmount" :disabled="disabled" style="width: 75px" @blur="e => onAmountBlur(e, index, 'BMin')" />
                    </el-form-item>
                    <span style="margin: 0 5px">~</span>
                    <el-form-item
                      :prop="'items.' + index + '.rangeBMaxAmount'"
                      :show-message="false"
                      :rules="rulesAmountFloatBlur"
                    >
                      <el-input v-model="item.rangeBMaxAmount" style="width: 75px" :disabled="!item.rangeBMinAmount || disabled" @blur="e => onAmountBlur(e, index, 'BMax')" />
                    </el-form-item>
                    <el-form-item
                      style="margin-left: 10px"
                      :prop="'items.' + index + '.rangeBRate'"
                      :show-message="false"
                      :rules="rulesPercentBlur"
                    >
                      <el-input v-model="item.rangeBRate" :disabled="disabled" style="width: 75px" @blur="e => onRangeBlur(e, index, 'A')" /> %
                    </el-form-item>
                  </div>
                </div>
              </div>
            </el-form-item>

          </div>

        </div>
      </el-form-item>

      <el-form-item label="虚拟数据金额" prop="showAmtMin" style="margin-top: 60px">
        <div flex="main:center">
          <el-form-item prop="showAmtMin">
            <el-input v-model="ruleForm.showAmtMin" :disabled="disabled" style="width: 120px" />
          </el-form-item>
          <span style="padding: 0 5px">~</span>
          <el-form-item prop="showAmtMax">
            <el-input v-model="ruleForm.showAmtMax" :disabled="disabled" style="width: 120px" />
          </el-form-item>
          <span>（应用于C端虚拟数据领取红包金额随机范围）</span>
        </div>
      </el-form-item>

      <el-form-item label="领取条件" prop="requireBet">
        <div flex="main:center">
          <el-form-item prop="requireBetAmt" label="累计投注" label-width="90px">
            <el-input v-model="ruleForm.requireBetAmt" :disabled="disabled" style="width: 140px" />
          </el-form-item>
          <el-form-item prop="requireRechargeAmt" label="累计充值" label-width="90px">
            <el-input v-model="ruleForm.requireRechargeAmt" :disabled="disabled" style="width: 140px" />
          </el-form-item>

          <el-form-item prop="requireTime" label-width="40px">
            <el-radio v-model="ruleForm.requireTime" :disabled="disabled" :label="2">活动期间每日</el-radio>
            <el-radio v-model="ruleForm.requireTime" :disabled="disabled" :label="0">活动期间内</el-radio>
            <el-radio v-model="ruleForm.requireTime" :disabled="disabled" :label="1">不限活动期间</el-radio>
          </el-form-item>
        </div>
      </el-form-item>

      <el-form-item label="是否弹窗" prop="popup">
        <el-radio-group v-model="ruleForm.popup" :disabled="disabled">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('语言选择')" prop="lang">
        <el-checkbox-group v-model="ruleForm.lang" :disabled="disabled">
          <el-checkbox v-for="item of $parent.allLang" :key="item.lang" :label="item.lang">{{ $t(item.name) }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="活动Banner" prop="redpacketBanner">
        <el-upload
          :disabled="disabled"
          class="banner-uploader"
          accept="image/jpeg,image/png,image/jpg,image/gif,image/svg,image/webp"
          :action="action"
          :data="{ type: 'image', folder: 'ad-photo' }"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleBannerSuccess"
        >
          <img v-if="ruleForm.redpacketBanner" :src="ruleForm.redpacketBanner" class="banner">
          <div v-else class="banner-uploader-txt">
            <p>提示：建议尺寸355*138，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。</p>
            <button class="el-button el-button--small el-button--orange" @click.self.prevent>点击上传</button>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="活动详情" prop="redpacketBody">
        <tinymce ref="tinymce" :disabled="disabled" v-model="ruleForm.redpacketBody" :height="200" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" v-if="disabled">
      <el-button type="primary" @click="visible = false">
        确定
      </el-button>
    </div>
    <div slot="footer" class="dialog-footer" v-else>
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import Tinymce from '@/components/Tinymce'
import { getAllVipLevels } from '@/api/app'
import { redpacketDetail } from '@/api/activity'
import FormDialogMixin from '@/mixins/form-dialog'
import { rulesAmountBlur, rulesAmountFloatBlur, rulesPercentBlur, decoderuleForm } from './utils'

const pattern = /^(0|[1-9][0-9]*|[0-9]*.[1-9][0-9]*|[1-9]*.[0-9][0-9]*|[0-9]*.[0-9][1-9]*)$/

const getDefaultForm = () => ({
  redpacketType: 2,
  redpacketTitle: '',
  activityTypeId: '',
  redpacketContent: '',
  redpacketTime: [],
  showTime: null,
  receipts: null,
  robAmtMin: '',
  robAmtMax: '',
  showAmtMin: '',
  showAmtMax: '',
  requireBetAmt: '',
  requireRechargeAmt: '',
  requireTime: 1,
  popup: 1,
  lang: [],
  redpacketBanner: '',
  redpacketBody: '',
  items: Array.of(1, 2, 3).map(key => ({ key })),
  requireBet: '1'
})

export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validateRobAmtMin = (rule, value, callback) => {
      if (Number(value) <= 0) {
        callback(new Error('需大于0'))
      } else {
        if (this.ruleForm.robAmtMax !== '' && value - this.ruleForm.robAmtMax >= 0) {
          callback(new Error('需小于最大值'))
        }
      }
      callback()
    }
    const validateRobAmtMax = (rule, value, callback) => {
      if (Number(value) <= 0) {
        callback(new Error('需大于0'))
      } else {
        if (this.ruleForm.robAmtMin !== '' && value - this.ruleForm.robAmtMin <= 0) {
          callback(new Error('需大于最小值'))
        }
      }
      callback()
    }
    const validateShowAmtMin = (rule, value, callback) => {
      if (Number(value) <= 0) {
        callback(new Error('需大于0'))
      } else {
        if (this.ruleForm.showAmtMax !== '' && value - this.ruleForm.showAmtMax >= 0) {
          callback(new Error('需小于最大值'))
        }
      }
      callback()
    }
    const validateShowAmtMax = (rule, value, callback) => {
      if (Number(value) <= 0) {
        callback(new Error('需大于0'))
      } else {
        if (this.ruleForm.showAmtMin !== '' && value - this.ruleForm.showAmtMin <= 0) {
          callback(new Error('需大于最小值'))
        }
      }
      callback()
    }

    const validateZero = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('需大于等于0'))
      }
      callback()
    }

    return {
      loading: false,
      emitName: '',
      Levels: [],
      rulesAmountBlur,
      rulesAmountFloatBlur,
      rulesPercentBlur,
      rules: {
        robAmtMin: [
          { required: true, message: '请输入红包金额最小值', trigger: 'blur' },
          { validator: validateRobAmtMin, trigger: 'blur' }
        ],
        robAmtMax: [
          { required: true, message: '请输入红包金额最大值', trigger: 'blur' },
          { validator: validateRobAmtMax, trigger: 'blur' }
        ],
        showAmtMin: [
          { required: true, message: '请输入虚拟数据金额最小值', trigger: 'blur' },
          { validator: validateShowAmtMin, trigger: 'blur' }
        ],
        showAmtMax: [
          { required: true, message: '请输入虚拟数据金额最大值', trigger: 'blur' },
          { validator: validateShowAmtMax, trigger: 'blur' }
        ],
        requireBetAmt: [
          { required: true, message: '此项必填', trigger: 'blur' },
          { validator: validateZero, trigger: 'blur' }
        ],
        requireBet: [{ required: true }],
        requireRechargeAmt: [
          { required: true, message: '此项必填', trigger: 'blur' },
          { validator: validateZero, trigger: 'blur' }
        ],
        redpacketType: [{ required: true, message: '请选择活动形式', trigger: 'change' }],
        redpacketTitle: [{ required: true, message: '请输入活动标题', trigger: 'blur' }],
        redpacketContent: [{ required: true, message: '请输入活动引导说明', trigger: 'blur' }],
        redpacketTime: [{ type: 'array', required: true, message: '请选择活动时间', trigger: 'change' }],
        receipts: [{ required: true, message: '请选择活动时间', trigger: 'change' }],
        lang: [{ type: 'array', required: true, message: this.$t('请选择语言'), trigger: 'change' }],
        redpacketBanner: [{ required: true, message: '请上传活动bannner', trigger: 'change' }],
        redpacketBody: [{ required: true, message: '请输入活动详情', trigger: 'blur' }],
        showTime: [{ type: 'array', required: true, message: '请选择开启时间', trigger: 'change' }],
        showSeconds: [{ required: true, message: '请输入下雨时长', trigger: 'blur' }]
      },
      disabled: false
    }
  },
  computed: {
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    title() {
      if (this.disabled) {
        return '查看活动详情'
      }
      return `${this.emitName === 'create' ? '创建' : '编辑'}红包活动`
    }
  },
  methods: {
    open(row, emitName) {
      this.disabled = emitName === 'detail'
      this.emitName = emitName
      this.getAgencyConfigRemark('')
      if (row) {
        this.ruleForm.id = row.id
        if (this.$P$) this.ruleForm.tenantCode = row.tenantCode
      } else {
        if (this.$P$) this.ruleForm.tenantCode = this.$parent.$attrs.tenant.tenantCode
      }
      this.visible = true
    },
    onOpen() {
      this.getData()
      getAllVipLevels(this.$P$ && [this.$parent.$attrs.tenant.tenantCode] || {}).then(([data, err]) => {
        if (!err) {
          this.Levels = data
        }
      })
    },

    getData() {
      if (this.emitName !== 'create') {
        this.loading = true
        redpacketDetail({
          id: this.ruleForm.id,
          tenantCode: this.ruleForm.tenantCode
        }).then(([data, err]) => {
          this.loading = false
          if (!err) {
            this.ruleForm = decoderuleForm(data)
            this.getAgencyConfigRemark(data.redpacketBody)
            if (!this.ruleForm.items.length) this.checked = false
          }
        })
      }
    },
    getAgencyConfigRemark(redpacketBody) {
      this.ruleForm.redpacketBody = redpacketBody
      this.$nextTick(() => {
        this.$refs.tinymce.hasInit &&
        this.$refs.tinymce.setContent(redpacketBody)
      })
    },

    deleteItems(index) {
      this.ruleForm.items.splice(index, 1)
    },
    disabledBeginVip(index, level) {
      if (index === 0) {
        return false
      } else {
        return level <= this.ruleForm.items[index - 1].vipEnd
      }
    },
    disabledEndVip(index, level) {
      return level <= this.ruleForm.items[index].vipBegin
    },

    handleBannerSuccess(res) {
      this.ruleForm.redpacketBanner = res.data[0]
      this.$refs.validateForm.validateField('redpacketBanner')
    },
    beforeUpload(file) {
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')
      const isLt1M = file.size / 1000 / 1024 < 1

      if (!isJPG) {
        this.$message.error('图片只能是 JPG、JPEG、PNG 格式!')
        return false
      }
      if (!isLt1M) {
        this.$message.error('图片大小不能超过 1MB!')
        return false
      }
      return true
    },
    handleRemarkSuccess(res) {
      this.ruleForm.redpacketBody = res.data[0]
      this.$refs.validateForm.validateField('redpacketBody')
    },

    onAmountBlur(e, index, name) {
      if (/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,3})?$/.test(e.target.value)) {
        let item = this.ruleForm.items[index]
        let value = e.target.value
        const { robAmtMin, robAmtMax } = this.ruleForm

        this.$refs.validateForm.validateField(['robAmtMin', 'robAmtMax'], (message) => {
          if (!message) {
            if (name === 'AMin') {
              if (value < robAmtMin) {
                this.$set(item, 'rangeAMinAmount', robAmtMin)
              }
            }
            if (name === 'AMax') {
              if (item.rangeAMinAmount) {
                if (value - item.rangeAMinAmount < 0) {
                  this.$set(item, 'rangeAMaxAmount', item.rangeAMinAmount)
                } else {
                  if (value - robAmtMax > 0) {
                    this.$set(item, 'rangeAMaxAmount', robAmtMax)
                  }
                }
              }
            }
            if (name === 'BMin') {
              if (value - robAmtMin < 0) {
                this.$set(item, 'rangeBMinAmount', robAmtMin)
              }
            }
            if (name === 'BMax') {
              if (item.rangeBMinAmount) {
                if (value - item.rangeBMinAmount < 0) {
                  this.$set(item, 'rangeBMaxAmount', item.rangeBMinAmount)
                } else {
                  if (value - robAmtMax > 0) {
                    this.$set(item, 'rangeBMaxAmount', robAmtMax)
                  }
                }
              }
            }
          }
        })
      }
    },

    onRangeBlur(e, index, name) {
      if (e.target.value !== '' && /^(?:[1-9]?\d|100)$/.test(e.target.value)) {
        this.$set(this.ruleForm.items[index], `range${name}Rate`, (100 - e.target.value) + '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.robvip-range {
  margin-left: 10px;
  padding: 5px 10px;
  position: absolute;
  background-color: #ddd;
  left: 264px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  z-index: 3;
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ddd;
    left: -10px;
    top: 50%;
    margin-top: -10px;
  }

  &-wrapper {
    position: relative;
  }

  &-list {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  &-item {
    position: relative;
    & +.robvip-range-item {
      margin-left: 10px;
    }
    &__child {
      display: flex;
      align-items: center;
      margin: 5px 0;
    }
    &__icon {
      position: absolute;
      font-size: 24px;
      right: 0;
      top: 0;
      color: #aaaaaa;
      cursor: pointer;
    }
    ::v-deep .el-input__inner {
      padding: 0 10px;
    }
  }

}
</style>
