var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "close-on-click-modal": false,
        width: "70%",
        top: "5vh",
        title:
          _vm.search.type === 51
            ? "手动加款明细"
            : _vm.search.type === 52
            ? "手动减款明细"
            : "其他补款明细",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.onClosed,
      },
    },
    [
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        staticStyle: { height: "70vh", "overflow-y": "auto" },
        attrs: {
          data: _vm.items,
          options: _vm.options,
          "filter-column": _vm.filterColumn,
          columns: _vm.columns,
        },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      plain: "",
                      type: "primary",
                      loading: _vm.excelLoading,
                      icon: "el-icon-download",
                    },
                    on: { click: _vm._handleExport },
                  },
                  [_vm._v(_vm._s(_vm.$t("导出Excel")))]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("pagination", {
        attrs: {
          page: _vm.search.currentPage,
          limit: _vm.search.pageSize,
          total: _vm.total,
          layout: "total, sizes, prev, next, ->",
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.search, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.search, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }