<template>
  <div>
    <div class="filter-container">
      <div class="filter-container-item">
        <el-input
          v-model="search.nickname"
          class="filter-item"
          placeholder="请输入用户昵称"
          style="width: 160px"
        />
        <el-select
          class="filter-item"
          v-model="search.roomType"
          placeholder="请选择聊天室类型"
          @change="_getChatRoomList"
        >
          <el-option
            v-for="item in roomTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          class="filter-item"
          v-model="search.roomId"
          placeholder="请选择聊天室"
        >
          <el-option
            v-for="item in roomIdList"
            :key="item.roomId"
            :label="item.roomName"
            :value="item.roomId"
          >
          </el-option>
        </el-select>
        <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          @click="handleSearchreFn"
          >查询</el-button
        >
        <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-refresh-right"
          @click="resetSearchFN"
          >{{ $t("重置") }}</el-button
        >
        <el-button
          v-if="permission.Add"
          icon="el-icon-plus"
          type="success"
          class="filter-item"
          @click="$refs.EditDialogControl.open()"
          >添加机器人</el-button
        >
        <el-button
          v-if="permission.Add"
          icon="el-icon-cancel"
          type="primary"
          class="filter-item"
          :disabled="!isSearch || selection.length == 0"
          @click="handleOut"
          >踢出聊天室</el-button
        >
      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :selectionRow="isSearch"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      @selection-change="handleSelectionChange"
    >
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />

    <!-- 机器人编辑 -->
    <EditDialog
      ref="EditDialogControl"
      @update="handleUpdate"
      @create="handleAdd"
    />
    <!-- 聊天室管理 -->
    <RoomDialog ref="RoomDialogControl" @update="handleFetch()" />
    <!-- 聊天室展示 -->
     <ShowDialog ref="ShowDialogControl"></ShowDialog>
  </div>
</template>
<script>
import TablePageMixin from "@/mixins/table-page-mixin";
import GetColumns from "./columns";
import EditDialog from "./EditDialog";
import RoomDialog from "./RoomDialog";
import ShowDialog from "./ShowDialog"
import {
  getAssistantPageList,
  updateAssistant,
  addAssistant,
  getAssistantDelList,
} from "@/api/interactive";
import { getDefaultTimes } from "@/utils";
import { parentMixin } from "@/mixins/tab-items";
import { getLiveRoomList  } from "@/api/chat";
import { getAssistantDelRoom  } from "@/api/interactive";
export default {
  name: "reportAdminTab",
  componentName: "reportAdminTab",
  components: { EditDialog, RoomDialog,ShowDialog },
  mixins: [TablePageMixin(getAssistantPageList), parentMixin],
  data() {
    return {
      times: getDefaultTimes(),
      filterColumn: false,
      search: {
        nickname: "",
        roomType: "",
        roomId: "",
      },
      columns: GetColumns.call(this),
      selection: [],
      formParams: {
        name: "",
        nickname: "",
        avatar: "",
        status: "",
        imageDescription: "",
        activePeriod: "",
      },

      roomTypeList: [
        { label: "互动大厅", value: 1, type: "lottery" },
        { label: "赛事-滚球", value: 2, type: "sport" },
        { label: "赛事-竞彩", value: 5, type: "sport" },
        { label: "热门彩种", value: 3, type: "lottery" },
        { label: "直播", value: 4, type: "live" },
      ],
      roomIdList: [],
      isSearch: false,
    };
  },
  watch: {
    search(val) {
      if (val.roomType == "") {
        this.roomIdList = [];
      }
    },
  },
  methods: {
    handleSearchreFn() {
      this.isSearch = true
      this.handleSearch()
    },
    resetSearchFN() {
      this.isSearch = false
      this.resetSearch()
    },
    handleUpdate({ field, cancel, close }) {
      if (field && field.activePeriodTwo?.length) {
        field.activePeriod =
          field.activePeriodTwo[0] + "~" + field.activePeriodTwo[1];
      } else {
        field.activePeriod = "00:00~24:00";
      }
      this.formParams.name = field.nameTwo;
      this.formParams.nickname = field.nicknameTwo;
      this.formParams.avatar = field.avatarTwo;
      this.formParams.id = field.idTwo;
      this.formParams.vipLevel = field.vipLevel;
      this.formParams.status = field.statusTwo;
      this.formParams.imageDescription = field.imageDescriptionTwo;
      this.formParams.activePeriod = field.activePeriod;
      updateAssistant({ ...this.formParams }).then(([data, err]) => {
        if (!err) {
          close();
          this.$message.success("编辑成功");
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },
    handleAdd({ field, cancel, close }) {
      if (field && field.activePeriodTwo.length) {
        field.activePeriod =
          field.activePeriodTwo[0] + "~" + field.activePeriodTwo[1];
      } else {
        field.activePeriod = "00:00~24:00";
      }
      addAssistant({
        id: field.idTwo,
        name: field.nameTwo,
        nickname: field.nicknameTwo,
        avatar: field.avatarTwo,
        vipLevel: field.vipLevel,
        activePeriod: field.activePeriod,
        status: field.statusTwo,
        imageDescription: field.imageDescriptionTwo,
      }).then(([data, err]) => {
        if (!err) {
          close();
          this.$message.success("新增成功");
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },
    handleDelete(row) {
      this.$confirm(
        this.$t("确认删除？删除后机器人将被移出聊天室"),
        this.$t("系统提示"),
        { type: "warning" }
      ).then(() => {
        getAssistantDelList({ id: row.id })
          .then(([data, err]) => {
            if (!err) {
              this.$message.success(this.$t("删除成功"));
              this.handleFetch();
            }
          })
          .catch(() => {});
      });
    },
    handleOut() {
      const idList = [];
      this.selection.forEach((x) => {
        idList.push(x.id);
      });
      this.$confirm(this.$t("确认将机器人踢出聊天室？"), this.$t("系统提示"), {
        type: "warning",
      })
        .then(() => {
          getAssistantDelRoom({
            assistantIds: idList,
            roomType:this.search.roomType,
            roomId:this.search.roomId
          }).then(([data, err]) => {
            if (!err) {
              this.$message.success(this.$t("踢出成功"));
              this.search.roomType=""
              this.search.roomId=""
              this.handleFetch();
            }
          });
        })
        .catch((err) => {
          console.log(3333,err);
        });
    },
    handleSelectionChange(selection) {
      this.selection = selection;
    },
    implementationGetParams() {
      return this.formatIntervalTime();
    },
    // 获取聊天室列表
    async _getChatRoomList(value) {
      getLiveRoomList({ roomType: value }).then((res) => {
        if (res[0].length > 0) {
          this.roomIdList = res[0];
          this.search.roomId = "";
        }
      });
    },
  },
};
</script>
