const CanChooseColumns = function () {
  return [
    {
      title: "用户昵称",
      key: "nickname",
      align: "center",
    },
    {
      title: "跟单金额",
      key: "orderAmount",
      align: "center",
    },
    {
      title: "中奖金额",
      key: "winAmount",
      align: "center",
    },
    {
      title: "跟单盈亏",
      key: "profitAmount",
      align: "center",
    },
    {
      title: "跟单佣金",
      key: "commission",
      align: "center",
    },
  ];
};

export default function (keys = []) {
  return CanChooseColumns.call(this);
}
