import { recommendAduditStatus } from '@/libs/options'
const CanChooseColumns = function() {
  return [
    {
      title: '发帖人',
      key: 'nickname',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>[{ this.xsLottery[row.lotteryId] }]</p>
              <span class='primary-type'>{ row.nickname }</span>
            </div>
          )
        }
      }
    },
    {
      title: '标题',
      key: 'title'
    },
    {
      title: '创建时间',
      key: 'createTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: '状态',
      key: 'auditStatus',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ recommendAduditStatus[row.auditStatus].label }</span>)
        }
      }
    },
    {
      title: '审核说明',
      key: 'auditRemark',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ row.auditRemark }</span>)
        }
      }
    },
    {
      title: '操作',
      align: 'center',
      key: 'actions',
      width: 150,
      component: {
        render: (h, row) => {
          return (<button class='el-button el-button--mini el-button--orange' onClick={this._goDetails.bind(this, row)}><i class='el-icon-search' /><span>查看</span></button>)
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
