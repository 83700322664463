export default {
  今天: "today",
  昨天: "yesterday",
  _7天: "7 days",
  本周: "this week",
  上周: "last week",
  本月: "this month",
  上月: "last month",
  至: "to",
  开始日期: "Start Date",
  结束日期: "End Date",
  查询: "query",
  批量修改: "Batch modification",
  批量修改会员等级: "Batch Modify Member Levels",
  批量修改用户状态: "Batch Modify User Status",
  批量修改上级代理: "Batch Modify Parent Agents",
  用户类型: "customer type",
  VIP等级: "VIP level",
  用户状态: "User Status",
  在线状态: "Online status",
  启用: "Enable",
  停用: "Deactivate",
  在线: "on-line",
  离线: "off-line",
  关键词: "key word",
  会员账号: "Member account",
  所属上级: "Superior",
  用户余额大于: "User balance is greater than",
  充值金额大于: "Recharge amount is greater than",
  充值金额小于: "Recharge amount is less than",
  最近多少天未充值: "How many days have you not recharged",
  最近多少天未登陆: "How many days have you not logged in",
  最近多少天登陆过: "How many days have you logged in recently",
  备注内容: "Remarks",
  内部账号: "Registered tourists",
  注册账户: "Registered Account",
  充值用户: "Rechargeable user",
  测试账户: "Test User",
  总余额: "Total balance",
  总人数: "Total number of people",
  确认: "Confirm",
  此用户: "this user",
  系统提示: "System prompt",
  操作成功: "Operation succeeded!",
  添加成功: "Successfully added!",
  发送成功: "Sending succeeded",
  请输入少于100条数据: "Please enter less than 100 pieces of data!",
  代理等级: "Agency level",
  所属代理: "Affiliated agent",
  会员等级: "Membership level",
  账号余额: "Account balance",
  会员昵称: "Member nickname",
  注册时间: "Registration time",
  最后登录: "Last Login",
  在线情况: "Online situation",
  状态: "state",
  查看: "see",
  操作: "operation",
  暂无: "None",
  锁定: "locking",
  未锁定: "Unlocked",
  账变: "Account change",
  注单: "Notes",
  修改: "modify",
  修改密码: "Change Password",
  添加备注: "Add Comments",
  上级代理: "Parent agent",
  关闭: "close",
  保存: "save",
  新代理: "New Agent",
  请输入会员账号组: "Please enter the member account group",
  请输入正确的数据: "Please enter correct data",
  数据格式:
    "Data format: Member 1, Member 2, Member 3 are separated by commas; The quantity of each modification shall not exceed 100",
  请选择VIP等级: "Please select VIP level",
  请选择会员等级: "Please select the member level",
  请选择是否锁定等级: "Please select whether to lock the level",
  锁定等级: "Locking level",
  不锁定: "unlocked",
  锁定后:
    "After locking, the level will not change when the user's top up amount reaches the level",
  会员状态: "Member Status",
  请选择会员状态: "Please select member status",
  请输入新代理: "Please enter a new agent",
  修改会员: "Modify Member",
  头像: "head portrait",
  银行卡: "bank card",
  测试用户设置: "Test user settings",
  设置为测试账号: "Set as test account",
  设置为内部账号: "Set as internal account",
  更多内容: "More",
  注册来源: "Source of registration",
  聊天室: "Chat room",
  追加人数: "Additional number",
  查找: "Search",
  真实人数: "real number",
  聊天室内容: "content",
  聊天室图标: "icon",
  聊天室描述: "description",
  跟单: "Follow",
  可赢: "Can win",
  已截止: "Expired",
  截止: "End",
  敏感词: "Sensitive",
  删除当前消息: "Delete current message",
  删除消息并禁言: "Delete message and mute",
  敏感词列表: "Sensitive list",
  一行代表一个敏感词: "One line represents a sensitive word",
  确认操作: "Confirm operation",
  聊天室类型: "type",
  敏感词过滤: "Sensitive word filtering",
  禁用: "Disable",
  请输入昵称: "Please enter your nickname",
  "请输入2~12位": "Please enter 2~12 digits",
  请选择用户状态: "Please select user status",
  请选择用户等级: "Please select user level",
  请输入正确手机号码: "Please enter the correct mobile phone number",
  请选择: "Please select {text}",
  用户聊天室状态已更新: "User chat status updated",
  禁言: "Taboo",
  用户聊天室: "User chat room",
  修改用户等级: "Modify user level",
  用户等级: "User level",
  锁定状态: "Lock status",
  请选择锁定状态: "Please select lock status",
  查看会员详细信息: "View member details",
  基本信息: "essential information",
  登录账号: "Login account",
  类别: "category",
  等级: "Grade",
  会员余额: "Member balance",
  出款所需打码量: "Number of codes required for payment",
  当前打码量: "Current coding amount",
  总打码量: "Total coding amount",
  真实姓名: "Real name",
  银行地址: "Bank address",
  银行账号: "bank account",
  注册IP: "Register IP",
  注册设备: "Register device",
  最后登录时间: "Last logon time",
  最后登录IP: "Last login IP",
  最后登录设备: "Last Login Device",
  第三方余额: "Third party balance",
  联系方式: "contact information",
  提现信息: "Withdrawal information",
  人工减款: "Manual money reduction",
  总提款次数: "Total withdrawal times",
  总提款金额: "Total withdrawal amount",
  首次提款: "First drawdown",
  最高提款: "Maximum withdrawal",
  当日提款金额: "Withdrawal amount of the day",
  当日提款次数: "Number of withdrawals on that day",
  充值信息: "Recharge information",
  总充值次数: "Total recharge times",
  总充值金额: "Total recharge amount",
  首次充值: "First recharge",
  最高充值: "Maximum recharge",
  当日充值金额: "Recharge amount of the day",
  当日充值次数: "Recharge times of the day",
  备注: "remarks",
  用户账号: "User account",
  密码类型: "Password Type",
  登录密码: "Login password",
  提款密码: "Withdrawal password",
  新密码: "New Password",
  确认密码: "Confirm Password",
  请输入密码: "Please input a password",
  "请输入6-12位数字字母组合密码":
    "Please enter 6-12 digit alphanumeric password",
  请输入6位数字提款密码: "Please enter the 6-digit withdrawal password",
  请再次输入密码: "Please enter the password again",
  两次输入密码不一致: "The passwords entered twice are inconsistent!",
  修改上级代理: "Modify Parent Agent",
  当前账号: "Current account",
  旧代理: "Old Agent",
  无: "nothing",
  发送个人站内信: "Send personal messages",
  接收人: "Recipient",
  站内信标题: "On site letter title",
  站内信内容: "In station message content",
  请输入站内信标题: "Please enter the title of the site letter",
  请输入站内信内容: "Please enter the content of the site letter",
  用户账户: "User account",
  银行账户: "bank account",
  正常: "normal",
  黑名单: "blacklist",
  保存成功: "Saving succeeded!",
  确认删除此银行: "Confirm to delete this bank?",
  删除成功: "Deletion succeeded!",
  银行名称: "Bank name",
  绑定时间: "Binding time",
  修改时间: "Modification time",
  编辑: "edit",
  删除: "delete",
  上次修改时间: "Last modified",
  请输入真实姓名: "Please enter your real name",
  请输入银行账户: "Please enter a bank account",
  请输入银行名称: "Please enter the bank name",
  请选择状态: "Please select a status",
  转账姓名: "Transfer Name",
  充值状态: "Recharge status",
  订单状态: "Order Status",
  充值成功: "Recharge succeeded",
  已拒绝: "Rejected",
  待处理: "Pending",
  收款银行: "Receiving bank",
  收款姓名: "Name of payee",
  收款卡号: "Collection card No",
  订单号: "order number",
  操作员: "operator",
  订单总额: "Total order amount",
  元: "element",
  导出Excel: "Export to Excel",
  会员信息: "Member information",
  充值金额: "Recharge amount",
  赠送金额: "Gift amount",
  货币汇率: "Currency exchange rate",
  货币金额: "Currency amount",
  收款信息: "Collection information",
  操作信息: "Operation information",
  操作说明: "Operating Instructions",
  账号: "account number",
  多次充值: "Multiple recharge",
  二次充值: "Secondary recharge",
  发起: "launch",
  结束: "end",
  附言: "Postscript",
  确认条件: "Confirmation conditions",
  连接中: "Connecting",
  停止接收: "Stop receiving",
  开始接收: "Start receiving",
  收款钱包地址: "Collection wallet address",
  充值方式: "Recharging method",
  数字货币: "Digital currency",
  通过所选: "Via Selected",
  拒绝所选: "Reject Selected",
  导出所选: "Export Selected",
  充值审核列表: "Recharge review list",
  收款人: "payee",
  收款银行账号: "Account No. of receiving bank",
  发起时间: "Originating time",
  充值平台金额: "Amount of recharge platform",
  操作人: "Operator",
  通过: "pass",
  拒绝: "refuse",
  审核通过: "Approved",
  拒绝通过: "Reject",
  批量审核通过: "Batch approval passed",
  批量拒绝通过: "Batch reject",
  申请时间: "Application time",
  累计充值: "Accumulated recharge",
  累计提现: "Accumulated withdrawal",
  会员备注: "Member Notes",
  申请金额: "Requested amount",
  操作时间: "Operation time",
  提现金额小于: "Withdrawal amount less than",
  提现金额大于: "Withdrawal amount is greater than",
  导出选中: "Export Selected",
  导出TXT: "Export TXT",
  提现订单列表: "Withdrawal order list",
  拾取成功: "Picking succeeded",
  确认批量拾取选中订单: "Confirm batch picking of selected orders?",
  批量拾取成功: "Batch picking succeeded",
  总提现金额: "Total withdrawal amount",
  平台币金额: "Platform currency amount",
  拾取: "pickup",
  已拾取: "Picked",
  他人拾取: "Picked by others",
  第三方代付: "Third party payment",
  批量通过: "Batch pass",
  批量拒绝: "Batch rejection",
  导出EXCEL: "Export EXCEL",
  提现审核表: "Withdrawal Audit Form",
  商户ID: "Merchant ID",
  商户订单号: "Merchant order number",
  持卡人: "Cardholder",
  卡号: "Card No",
  代付金额: "Payment amount",
  开户行: "Bank of deposit",
  开户网点: "Opening branch",
  拾取时间: "Pickup time",
  提现: "Withdrawal",
  批量提现: "Batch withdrawal",
  平台名称: "Platform name",
  支付方式: "Payment method",
  支付状态: "Payment status",
  平台流水号: "Platform serial number",
  确认进行补单: "Confirm to supplement the order",
  来源: "source",
  实付平台金额: "Paid platform amount",
  创建时间: "Creation time",
  补单记录: "Replenishment record",
  补单: "Supplement",
  变动类型: "Change Type",
  账变金额: "Account change amount",
  变动金额: "Change amount",
  变动前金额: "Amount before change",
  变动后金额: "Amount after change",
  变动前所需打码量: "Required coding amount before change",
  变动后所需打码量: "Required coding amount after change",
  变动时间: "Change time",
  操作员备注: "Operator remarks",
  批量处理: "Batch processing",
  批量加款: "Batch increase",
  批量扣款: "Batch deduction",
  人工加款: "Manual increase",
  人工扣款: "Manual deduction",
  人工线下充值: "Manual offline recharge",
  批量处理信息数据格式不正确:
    "Incorrect format of batch processing information data",
  批量用户存在重复: "Duplicate batch users",
  批量操作成功: "Batch operation succeeded",
  提现金额: "Withdrawal amount",
  封停: "Closure",
  加款: "Increase",
  扣款: "Deduction",
  扣款操作: "Deduction",
  加款操作: "Add money",
  账户余额: "Account balance",
  增加金额: "Increase amount",
  请输入需要增加的金额: "Please enter the amount to be increased",
  计入打码量: "Include in the amount of coding",
  是: "yes",
  否: "no",
  打码量倍数: "Coding volume multiple",
  请输入打码量倍数: "Please enter the multiple of the code amount",
  类型: "type",
  礼金: "cash gift",
  现金: "cash",
  扣款金额: "Deduction amount",
  请输入需要扣除的金额: "Please enter the amount to be deducted",
  操作原因: "Operation reason",
  请输入操作原因: "Please enter the operation reason",
  请输入金额: "Please enter the amount",
  请输入正确金额: "Please enter the correct amount",
  请输入正确打码量倍数: "Please enter the correct multiple of the code size",
  请选择计入打码量: "Please select to include the code amount",
  "每行的数据格式：会员账号,金额 使用逗号或者空格隔开":
    "Data format of each line: member account, amount separated by comma or space",
  如: "as",
  使用空格隔开: "Separate with space",
  使用逗号隔开: "Separate with commas",
  请输入批量处理信息: "Please enter batch processing information",
  批量增加打码量: "Batch increase of coding quantity",
  批量减少打码量: "Batch reduce the amount of coding",
  加码: "Plus",
  减码: "Minus code",
  打码量: "Coding amount",
  所需打码量: "Required coding amount",
  增加打码量: "Increase the amount of coding",
  请输入需增加的打码量: "Please enter the amount of code to be added",
  请输入需减少的打码量: "Please enter the amount of coding to be reduced",
  减少打码量: "Reduce the amount of coding",
  开奖方式: "Opening method",
  开奖倒计时: "Countdown to lottery",
  期号: "Issue No.",
  开奖号码: "Lottery number",
  待开奖: "To be awarded",
  预开奖号码: "Pre lottery number",
  开奖时间: "Opening time",
  平台投注数: "Number of platform bets",
  平台盈利亏损: "Profits and losses of the platform",
  手动开奖: "Manual lottery",
  重新开奖: "Re award",
  下载重开未扣: "Download reopening without deduction",
  预开奖: "Pre lottery",
  投注详情: "betting details",
  统计: "Statistics",
  修改成功: "Modification succeeded",
  撤销开奖成功: "Successfully canceled the lottery!",
  重新开奖成功: "The lottery is reopened successfully!",
  接口开奖: "Interface lottery",
  平台开奖: "Platform lottery",
  杀号开奖: "Number killing and prize opening",
  独立随机开奖: "Independent random lottery",
  独立杀号开奖: "Independent number killing lottery",
  开奖方式修改: "Modification of prize drawing method",
  彩种类型: "Color type",
  官方开奖: "Official lottery",
  独立开奖: "Independent lottery",
  开奖获取: "Award winning",
  开启规则: "Open Rules",
  日投注收益: "Daily betting income",
  最低的: "Lowest",
  家: "home",
  _7日盈利为7日投注额度的: "The 7-day profit is the 7-day betting limit",
  "请输入0-100的数，且最多有两位小数!":
    "Please enter a number from 0 to 100 with a maximum of two decimal places!",
  开奖要求: "Lottery request",
  投注100最低可中: "Bet 100, minimum acceptable",
  投注100最高可中: "Betting 100 can be hit at most",
  杀号时间最多: "The killing time is the most",
  取消: "Cancel",
  确定: "determine",
  "取 消": "Cancel",
  请输入: "Please enter {text}",
  "请输入0~100的数，且最多有3位小数":
    "Please enter a number from 0 to 100 with a maximum of 3 decimal places",
  "请输入1~99的数": "Please enter a number from 1 to 99",
  "请输入0~99999的数，且最多有3位小数":
    "Please enter a number from 0 to 99999, with a maximum of 3 decimal places",
  最高可中金额需大于最低可中金额:
    "The highest attainable amount must be greater than the lowest attainable amount",
  "请输入1~7的数值": "Please enter a value of 1~7",
  确认结算: "Confirm lottery",
  期开奖: "Of lottery",
  重开方式: "Reopen mode",
  重开号码: "Reopen number",
  _1本功能:
    "1. This function is to [Cancel Lottery Opening]/[Reopen Lottery Opening]/[Reopen Result] for the lottery drawing error of the selected color and issue;",
  _2撤销开奖:
    "2. Cancel lottery: for users who have won the lottery, all the distributed bonuses of the current period will be recovered (if the user balance is insufficient, the amount can be deducted to a negative amount); And set the order to cancelled status to return the bet amount",
  _3重新开奖:
    "3. Re award: for the user who has won the lottery, all the distributed bonuses of the current period will be recovered (if the user's balance is insufficient, the amount can be deducted to a negative amount); The lottery will be settled by filling in the new lottery number, and the users who have won the lottery will be awarded;",
  撤销开奖: "Cancel lottery",
  期重新开奖: "Re award",
  请选择重开方式: "Please select the reopening method",
  结算统计: "Settlement statistics",
  总订单数: "Total Orders",
  总投注金额: "Total Betting Amount",
  总中奖单数: "Total number of winning tickets",
  总中奖金额: "Total winning amount",
  总下注人数: "Total Betters",
  总盈亏: "Total profit and loss",
  暂无数据: "No data",
  系列: "Series",
  彩种: "color seed",
  玩法: "Play method",
  会员类型: "Member type",
  "投注超过额度(大于10)": "Betting exceeds the limit (more than 10)",
  "盈利超过额度(大于10)": "Profits exceed the limit (more than 10)",
  总投注额: "Total bet amount",
  有效投注额: "Effective Betting Amount",
  中奖金额: "Winning amount",
  盈亏: "Profit and loss",
  总用户: "Total users",
  结算成功: "Settlement succeeded",
  期号结算成功: "Issue settlement succeeded",
  撤单成功: "Cancellation succeeded",
  期号撤单成功: "Issue cancellation succeeded",
  昵称: "Nickname",
  彩种期号: "Color Seed Issue No.",
  玩法分类: "Play method classification",
  内容: "Content",
  金额: "Amount",
  投注: "Betting",
  中奖: "Win a prize",
  用户盈亏: "User profit and loss",
  时间: "time",
  更新: "to update",
  结算: "settlement",
  撤单: "cancel the order",
  期号结算: "Issue settlement",
  期号撤单: "Issue cancellation",
  用户: "user",
  投注额度: "Betting limit",
  赔率: "Odds",
  校验: "check",
  请输入开奖号码: "Please enter the lottery number",
  投注金额: "Betting amount",
  订单: "order",
  开奖查询: "Lottery query",
  备注信息操作: "Remarks information operation",
  备注信息: "Remarks",
  请输入内容: "Please enter the content",
  选中: "select",
  条: "items",
  合计金额: "Total amount",
  全选: "select all",
  拾取选中: "Pick and select",
  彩票统计: "Lottery statistics",
  派奖: "Award",
  总计: "Total",
  今日更新数据: "Update data today",
  更新时间: "Update time",
  在线人数: "Online number of people",
  新注册在线数: "Number of new registered online",
  更新注册并充值人数时间: "Update registration and recharge time",
  注册并充值人数: "Number of people registered and recharged",
  充值人数: "Recharge number",
  提现人数: "Number of withdrawals",
  送礼人数: "Number of gift givers",
  付费人数: "Number of payers",
  投注人数: "Number of bets",
  注册人数: "Number of registrants",
  礼物消费: "Gift consumption",
  房费消费: "Room fee consumption",
  彩票投注: "Lottery betting",
  游戏投注: "Game betting",
  第三方额度统计: "Third-party quota statistics",
  平台: "Platform",
  总额度: "Total amount",
  已使用: "Used",
  剩余: "Remaining",
  登录注册: "Login registration",
  同IP每日注册个数: "Number of daily registrations of the same IP",
  同IP每日登录个数: "Number of daily logins of the same IP",
  前端输入密码错误冻结次数: "Frozen times of front-end input password error",
  后台Google验证: "Background Google verification",
  开启: "Open",
  购彩配置: "Lottery configuration",
  是否允许用户撤单: "Allow user to cancel order",
  投注默认一注额度: "Default betting limit",
  一级返佣比例: "Level 1 rebate ratio",
  二级返佣比例: "Second-level commission rebate ratio",
  结算方式: "Settlement method",
  保存结算方式: "Save",
  人工: "Labor",
  自动: "Auto",
  代理返点: "Agent rebate",
  国家区号: "Country area code",
  区号: "Area code",
  前端维护: "Front-end maintenance",
  维护说明: "Maintenance description",
  维护: "Maintenance",
  "提款、入款开始处理时间": "Withdrawal and deposit start processing time",
  存取款配置: "Deposit and withdrawal configuration",
  开始时间: "Start time",
  "提款、入款结束处理时间": "Withdrawal and payment end processing time",
  结束时间: "End time",
  提款最低额度: "Minimum withdrawal limit",
  提款最高额度: "Maximum withdrawal limit",
  "2次充值时间的间隔": "Interval between two recharge times",
  "2次提款时间的间隔": "Interval between two withdrawals",
  提款次数只记录成功次数: "Withdrawal times only record success times",
  每日最高提现次数: "Maximum withdrawal times per day",
  存款未处理记录失效时间: "Expiration time of unprocessed deposit record",
  提款未处理记录失效时间: "Expiration time of unprocessed withdrawal record",
  新增修改银行卡不能提款时间:
    "Add and modify the bank card non-withdrawal time",
  绑定银行卡数量: "Bind bank card quantity",
  主播联系方式金额: "Amount of anchor contact information",
  广播礼物金额: "Broadcast gift amount",
  免费时长设置: "Free duration setting",
  直播: "Live",
  "保 存": "Save",
  "确 定": "determine",
  "重 置": "Reset",
  秒: "seconds",
  次: "times",
  小时: "hour",
  分钟: "Minute",
  购彩聊天室: "Lottery chat room",
  VIP等级发言: "VIP level speech",
  VIP等级发言发图: "VIP level speech map",
  文本字数限制: "Text word limit",
  直播聊天室: "Live chat room",
  跟单大厅: "Documentary hall",
  VIP等级推单: "VIP level push",
  每日限制推送次数: "Limit push times per day",
  VIP等级打赏: "VIP level reward",
  每日限制打赏次数: "Limit reward times per day",
  每日测试账号打赏次数: "The number of rewards for the test account per day",
  单次最高打赏金额: "Maximum single reward amount",
  跟单中奖分红上限: "Maximum dividend limit of documentary winning",
  推单赔率下限: "Lower limit of push odds",
  平台抽取分红金额: "Platform withdrawal dividend amount",
  "请输入0-9999": "Please enter 0-9999",
  下载配置: "Download configuration",
  下载地址: "Download address",
  例如: "For example",
  IOS下载地址: "IOS download address",
  Android下载地址: "Android download address",
  下载地址1: "Download address 1",
  下载地址2: "Download address 2",
  下载地址3: "Download address 3",
  app弹窗下载地址: "App pop-up download address",
  推送配置: "Push configuration",
  极光配置: "Aurora configuration",
  请输入极光appkey: "Please enter aurora appkey",
  请输入极光masterSecre: "Please enter aurora masterSecret",
  客服配置: "Customer service configuration",
  客服Telegram: "Customer Service Telegram",
  第三方客服: "Third-party customer service",
  第三方聊天室: "Third-party chat room",
  聊天室名称: "Chat room name",
  请输入地址: "Please enter the address",
  简述: "Brief description",
  "H5/Web版本": "H5/Web version",
  H5地址: "H5 address",
  WEB地址: "WEB address",
  体育首页跳转: "Sports homepage jump",
  赛事记录: "Event record",
  足球专家: "Football experts",
  热门预测: "Top Forecast",
  短信配置: "SMS configuration",
  短信运营商: "SMS operator",
  权重: "Weight",
  模板ID: "Template ID",
  请上传图标: "Please upload icon",
  "上传图标只能是 JPG、PNG 格式!": "Upload icon can only be JPG or PNG format!",
  "上传图标大小不能超过 2MB!": "Upload icon size cannot exceed 2MB!",
  配置: "configure",
  "输入0.001~99999999 输入汇率x平台币=当前货币金额":
    "Enter 0.001~99999999 Enter exchange rate x platform currency=current currency amount",
  美元: "USD",
  越南盾: "Vietnamese dong",
  卢比: "Rupee",
  人民币: "RMB",
  日元: "JPY",
  韩元: "KRW",
  港币: "HKD",
  马来西亚币: "Malaysian currency",
  热门推荐: "Top recommendation",
  热门游戏排序: "Top game ranking",
  排序: "Sort",
  "彩种/厂商选择": "Color/manufacturer selection",
  热门系列: "Hot series",
  游戏: "Game",
  首页气泡文字: "Bubble text",
  游戏选择: "Game selection",
  娱乐首页推荐排序: "Entertainment homepage recommendation sorting",
  体育首页推荐排序: "Sports homepage recommendation sorting",
  请选择游戏: "Please select a game",
  请至少选择一个热门游戏: "Please select at least one popular game",
  关于我们: "About us",
  足球说明: "Football description",
  彩票类: "Lottery category",
  第三方游戏: "Third-party games",
  广告图位置: "Advertising map location",
  标题: "Title",
  添加广告: "Add advertisement",
  全部保存: "Save All",
  新增成功: "New successfully",
  编辑成功: "Edit successfully",
  确认删除标题: "Confirm to delete title",
  广告图: "Confirm to delete title",
  "暂无数据无法保存！": "No data can be saved temporarily!",
  "确认批量修改公告排序?":
    "Are you sure to batch modify the announcement sorting?",
  "批量修改成功！": "Batch modification succeeded!",
  首页推荐页: "Homepage recommendation page",
  首页直播页: "Home live page",
  个人中心: "Personal center",
  游戏中心: "Game Center",
  安卓: "Android",
  展示位置: "Display location",
  广告标题: "Advertising title",
  语言: "Language",
  展示终端: "Display terminal",
  是否启用: "Enable",
  编辑时间: "Edit time",
  请输入标题: "Please enter a title",
  请输入跳转链接地址: "Please enter jump link address",
  请选择展示位置: "Please select the display location",
  跳转方式: "Jump method",
  链接: "Link",
  活动: "Activity",
  请选择跳转活动: "Please select jump activity",
  "关 闭": "Close",
  新增: "Add",
  广告: "advertising",
  跳转链接: "Jump link",
  活动ID: "Activity ID",
  活动标题: "Activity title",
  活动BANNER: "Active BANNER",
  内页BANNER: "Inside page BANNER",
  活动Banner: "Activity Banner",
  活动引导: "Activity guidance",
  跳转页面: "Jump page",
  活动时段: "Jump page",
  活动状态: "Activity status",
  持续开启: "Continuous opening",
  新建活动: "New activity",
  "创建成功!": "Created successfully!",
  "确定删除活动?": "Are you sure to delete the activity?",
  链接地址: "Link address",
  请输入链接地址信息: "Please enter the link address information",
  语言选择: "Language selection",
  活动名称: "Activity name",
  活动引导说明: "Activity guidance description",
  活动时间: "Activity time",
  定时开启: "Timed opening",
  限20字以内: "Within 20 words",
  限50字以内: "Within 50 words",
  文件大小控制在100K以内: "File size is controlled within 100K",
  点击上传: "Click Upload",
  纯图片: "Pure picture",
  图配文: "Picture caption",
  请填写活动标题: "Please fill in the activity title",
  请填写活动引导说明: "Please fill in the activity guidance description",
  请选择跳转页面: "Please select a jump page",
  请填写链接地址: "Please fill in the link address",
  请选择活动Banner: "Please select the activity banner",
  请加入活动内页: "Please join the activity inside page",
  请选择语言: "Please select a language",
  创建活动: "Create activity",
  编辑活动: "Edit activity",
  活动内页: "Inside page of activity",
  请选择公告类型: "Please select the announcement type",
  添加公告: "Add announcement",
  首页推荐: "Home page recommendation",
  直播间: "Live room",
  充值: "Recharge",
  ABPay大厅跑马灯: "ABPay",
  确认批量修改公告: "Confirm to batch modify the announcement?",
  公告类型: "Announcement type",
  公告标题: "Post title",
  是否弹出: "Popup",
  公告时间: "Announcement time",
  公告内容: "Announcement content",
  请选择标题类型: "Please select title type",
  请选择公告时间: "Please select the announcement time",
  编辑广告: "Edit advertisement",
  创建广告: "Create advertisement",
  请选择发送方式: "Please select the sending method",
  请输入单个会员账户: "Please enter a single member account",
  发送站内信: "Send intra-station message",
  单个会员发送: "Send by single member",
  指定会员列表发送: "Send specified member list",
  按VIP等级发送: "Send by VIP level",
  充值会员发送: "Recharge member sending",
  未充值会员发送: "Sent by non-recharging members",
  "的站内信？": "Inside message of",
  发送方式: "Sending method",
  已读回执: "Read receipt",
  单个会员显示回执: "Individual member display receipt",
  已读: "Read",
  未读: "Unread",
  "请输入接收会员的账号, 多个账号之间以英文逗号隔开,例如:账号1,账号2":
    "Please enter the account number of the receiving member, and separate multiple accounts with English commas, for example: account number 1, account number 2",
  "请输入接收会员的账号, 例如:账号1":
    "Please enter the account number of the receiving member, for example: account number 1",
  会员VIP等级: "Member VIP grade",
  "请输入会员VIP等级，多个等级以英文逗号隔开, 例如: 4,5,6":
    "Please enter the member VIP grade, multiple grades separated by English commas, for example: 4,5,6",
  编辑站内信: "Edit in-site message",
  新增站内信: "New intra-station message",
  请选择发送类型: "Please select the sending type",
  请输入行动电话后四位: "Please enter the last four digits of mobile phone",
  忘记密码: "Forgot password",
  注册: "Registration",
  短信登录: "SMS login",
  发送失败: "Sending failed",
  发送类型: "Sending type",
  行动电话: "Mobile phone",
  简讯码: "SMS code",
  发送时间: "Sending time",
  发送IP地址: "Send IP address",
  发送结果: "Send result",
  赔率设置: "Odds setting",
  玩法设置: "Play settings",
  玩法分类设置: "Play classification settings",
  玩法说明设置: "Gameplay description setting",
  重置成功: "Reset succeeded",
  "确认重置所有玩法基础数据吗?":
    "Are you sure to reset all the basic data of the game?",
  请选择彩种: "Please select color variety",
  一级玩法: "Level 1 play",
  二级玩法: "Level 2 play",
  彩种介绍设置: "Color description settings",
  彩种说明设置: "Lottery description Settings",
  批量设置赔率: "Batch set odds",
  最小金额批量设置: "Reset succeeded",
  保存配置: "Save configuration",
  最大金额批量设置: "Maximum amount batch setting",
  确认修改属性值为text的信息:
    "Confirm to modify the information with attribute value of {text}",
  确认修改所有属性值信息: "Confirm to modify all attribute value information",
  属性: "Attribute",
  最小投注额: "Minimum bet amount",
  最大投注额: "Maximum bet amount",
  编码: "Code",
  直播间开关: "Live broadcast room switch",
  保存修改: "Save modification",
  批量设置: "Batch setting",
  最小金额: "Minimum amount",
  最大金额: "Maximum amount",
  彩票系列: "Lottery series",
  直播配置: "Live configuration",
  开奖说明: "Drawing description",
  官方直播: "Official live broadcast",
  请输入VIP等级: "Please enter VIP grade",
  确认修改所有玩法信息: "Confirm to modify all play information",
  确认修改玩法playName的信息: "Confirm to modify the information of {text}",
  彩种名称: "Color seed name",
  玩法分组名称: "Play group name",
  序号: "Serial number",
  请输入彩种名称: "Please enter the color name",
  选择是否开启官方直播: "Select whether to enable official live streaming",
  直播视频: "Live video",
  请输入直播视频url: "Please enter the url of the live video",
  "确认修改属性值为{playClassName}的信息":
    "Confirm to modify the information with attribute value of {text}",
  最大可选号码: "Maximum optional number",
  "确认修改属性值为{groupName}的信息":
    "Confirm to modify the information with attribute value of {groupName}",
  玩法规则: "Play rules",
  举例说明: "Example",
  期: "issues",
  "确认修改{name}的状态吗?": "Are you sure to modify the status of {text}? ",
  启停: "Start and stop",
  显示隐藏: "Show and hide",
  显示: "Display",
  隐藏: "Hide",
  赛事名称: "Event name",
  球队名称: "Team name",
  球队: "Team",
  滚球联赛: "In-play league",
  滚球球队: "In-play team",
  滚球赛事: "In-play game",
  滚球盘口: "In-play handicap",
  赛前: "Pre-competition",
  赛中: "In the game",
  确认取消此赛事: "Confirm to cancel this event",
  联赛: "League",
  主队: "Home team",
  客队: "Guest team",
  开赛时间: "Opening time",
  比赛状态: "Competition status",
  "上半场/下半场": "First half/second half",
  赔率改变: "Change in odds",
  变赔: "Variable compensation",
  无效: "Invalid",
  未: "Not",
  胜: "win",
  平: "flat",
  负: "negative",
  变赔记录: "Companion change record",
  比赛类型: "Competition type",
  赛事编号: "Event No.",
  足球: "Football",
  篮球: "Basketball",
  对阵: "Against",
  修改赔率: "Modify odds",
  变赔: "Change company",
  "玩法平赔率 格式不合法": "The format of playing equal odds is illegal",
  暂未更新赔率: "Not updated odds",
  "赔率格式不合法!": "he format of odds is illegal! ",
  聊天室人数: "Number of chat rooms",
  增加人数: "Increase number of people",
  清屏: "Clear screen",
  输入信息: "Input information",
  管理员: "Administrator",
  "上传图片只能是 JPG、PNG、JEPG 格式!":
    "Uploaded pictures can only be in JPG, PNG or JEPG format!",
  "上传图片大小不能超过 2MB!": "The size of uploaded image cannot exceed 2MB! ",
  删除消息: "Delete message",
  禁言并删除消息: "Prohibit words and delete messages",
  本单总投注金额: "Total betting amount of this order",
  "第{n}期": "{text} period",
  添加限制: "Add restriction",
  请输入限制内容: "Please enter restricted content",
  限制说明: "Restriction description",
  限制内容: "Restricted content",
  请输入限制说明: "Please enter restriction description",
  请出入限制内容: "Please enter the restricted content",
  请输入正确IP: "Please enter the correct IP",
  请出入限制说明: "Please specify the access restrictions",
  "请输入2~240个字符": "Please enter 2~240 characters",
  最后操作时间: "Last operation time",
  请输入IP: "Please enter IP",
  "确定删除{ip}这条IP限制?":
    "re you sure to delete {text} this IP restriction?",
  请输入手机号或号段: "Please enter mobile phone number or number segment",
  "确定删除{phone}这条限制?":
    "Are you sure to delete the restriction of {text}?",
  封IP: "IPs",
  封手机: "Mobile phone",
  直播状态: "Live status",
  频道选择: "Channel selection",
  当前彩种: "Current color",
  主播昵称: "Host nickname",
  房间主题: "Room theme",
  所属家族: "Family",
  "查 询": "Query",
  当前房间合计人数: "Total number of people in the current room",
  房间热度设定: "Room heat setting",
  免费超清: "Free Ultra Clear",
  高清: "HD",
  普清: "Puqing",
  是否结束当前流媒体播放: "End current streaming media playback?",
  "是否将主播踢下线并禁播（可在主播管理解除禁播）":
    "Do you want to remove the anchor from the line and ban the broadcast (it can be removed from the anchor management)?",
  "确认置顶此直播间?": "Are you sure to set the top of this live room?",
  "确认取消置顶此直播间?": "Are you sure to cancel topping this live room?",
  房间彩种: "Room color",
  分辨率: "Resolution",
  频道: "Channel",
  房间价格: "Room price",
  房间热度: "Room heat",
  房间人数: "Number of rooms",
  观看人次: "Viewers",
  "房费分成(预计)": "Room fee share (estimated)",
  "礼物分成(预计)": "Gift sharing (estimated)",
  "有效投注分成(预计)": "Effective betting share (estimated)",
  置顶状态: "Top status",
  直播中: "Live broadcast",
  已下播: "Downloaded",
  置顶: "Top",
  结束播放: "End play",
  流播放: "Stream playback",
  踢下线: "Kick off line",
  会员列表: "Member list",
  房间编辑: "Room editing",
  礼物金额加成值: "Bonus value of gift amount",
  房费金额加成值: "Room fee amount plus value",
  投注金额加成值: "Betting amount plus value",
  观看人次加成值: "Watching person time bonus value",
  加成值大于等于1: "The bonus value is greater than or equal to 1",
  倍: "times",
  热度组成如下: "The heat composition is as follows",
  "(单次礼物合计*n)+(单次房费合计*n)+(单次投注合计*n)+(单次观看人次*n)+人工热度":
    "(total single gift * n)+(total single room fee * n)+(total single betting * n)+(single viewing times * n)+artificial heat",
  请输入正确: "Please enter correctly",
  请上传: "Please upload",
  "请输入0-99999": "Please enter 0-99999",
  "请输入0-60": "Please enter 0-60",
  请输入房间主题: "Please enter the room theme",
  "请输入5-3600": "Please enter 5-3600",
  编辑房间: "Edit room",
  封面: "Cover",
  最大可输入99999: "Maximum 99999",
  "单个房间机器人数量上限(0-60)":
    "Maximum number of robots in a single room (0-60)",
  "单位为秒（5-3600）": "Unit is second (5-3600)",
  机器人数量: "Number of robots",
  机器人进入间隔: "Robot entry interval",
  机器人是否投注: "Whether the robot bets",
  机器人是否送礼: "Whether the robot gives gifts",
  流播放功能: "Stream play function",
  流媒体地址: "Streaming media address",
  "下播后的主播，可通过后台填入流媒体地址进行虚拟开播，与正常开播效果相同":
    "After downloading, the anchor can fill in the streaming media address in the background for virtual start, which has the same effect as the normal start",
  频道名称: "Channel name",
  新建频道: "New channel",
  "删除频道后，原来归属于该频道下的房间只会出现在热门频道内!":
    "After deleting the channel, the room originally belonging to the changed channel will only appear in the popular channel!",
  请谨慎操作: "Please operate with caution",
  频道图标: "Channel icon",
  中文名称: "Chinese name",
  英文名称: "English name",
  越南名称: "Vietnam name",
  数字越大排序越前: "The larger the number, the higher the ranking",
  建议输入2个汉字: "It is recommended to enter 2 Chinese characters",
  请输入英文名称: "Please enter English name",
  请输入越南名称: "Please enter Vietnam name",
  "提示：频道图标建议30*30px，格式为PNG":
    "Prompt: 30 * 30px recommended for channel icon, PNG format",
  请输入排序: "Please enter sort",
  请输入正确排序: "Please enter correct sort",
  请输入中文名称: "Please enter Chinese name",
  请上传频道图标: "Please upload the channel icon",
  频道创建: "Channel creation",
  频道编辑: "Channel editing",
  礼物名称: "Gift name",
  创建礼物: "Create gift",
  "确认启用?": "Confirm to enable?",
  "确认禁用?": "Confirm to disable?",
  礼物图标: "Gift icon",
  礼物属性: "Gift attribute",
  连击礼物: "Combo gift",
  动画礼物: "Animation gift",
  礼物价格: "Gift price",
  动画地址: "Animation address",
  礼物状态: "Gift status",
  动画状态: "Animation state",
  联动状态: "Linkage status",
  "编 辑": "Edit",
  建议图标规则: "Suggested icon rules",
  "1.尺寸：160x160px": "1. Size: 160x160px",
  "2.格式：PNG": "2. Format: PNG",
  "3.大小：1M内": "3. Size: within 1M",
  礼物动画: "Gift animation",
  支持3位小数: "Support 3 decimal places",
  请选择礼物属性: "Please select gift attribute",
  "请上传动画文件(仅支持10M内的svga格式动画)":
    "Please upload animation file (only support svga format animation within 10M)",
  请上传礼物图标: "Please upload the gift icon",
  请输入礼物价格: "Please enter the gift price",
  请输入正确礼物价格: "Please enter the correct gift price",
  请输入正确序号: "Please enter the correct serial number",
  请上传礼物动画: "Please upload gift animation",
  "您上载的档案格式不对！": "The file you uploaded is in the wrong format!",
  礼物创建: "Gift creation",
  礼物编辑: "Gift editor",
  礼物分类编辑: "Gift classification editing",
  分类名称: "Classification name",
  分类状态: "Classification status",
  方案名称: "Scheme name",
  震动值: "Vibration value",
  方案状态: "Scheme status",
  "0-20之间；用英文逗号隔开；以0结束":
    " 0-20; Separate with English commas; End with 0",
  主播账号: "Anchor account",
  家族名称: "Family name",
  新增主播: "New anchor",
  "确认启用此主播账号吗?": " Are you sure to enable this anchor account?",
  "确认禁用该主播账号吗???禁用后该账号退出后将不可再登录!":
    "Are you sure to disable the anchor account??? After disabling, the account cannot be logged in again after exiting! ",
  "确认启用此主播开播权限?":
    "Are you sure you want to enable this multicast permission?",
  "确认禁用此主播开播权限?":
    "Are you sure you want to disable this multicast permission?",
  账号状态: "Account status",
  开播权限: "Start permission",
  "6-12位": "6-12 bits",
  "请输入6~12位英文数字": "Please enter 6-12 English digits",
  关联家族: "Associated family",
  下拉选择家族: "Drop down to select family",
  请选择家族: "Please select a family",
  请输入登录账号: "Please enter the login account",
  "登录账号长度为6-12位": "Login account length is 6-12 digits",
  请输入联系方式: "Please enter contact information",
  "联系方式长度为2-20位": "Contact information length is 2-20 digits",
  编辑主播: "Edit anchor",
  家族账号: "Family account",
  新增家族: "New family",
  "是否删除该家族!!!": "Delete the family!!!",
  关联主播数: "Number of associated anchors",
  礼物分成比例: "Gift sharing proportion",
  投注分成比例: "Betting share proportion",
  房费分成比例: "Share proportion of room fee",
  分成配置: "Split configuration",
  登陆密码: "Login password",
  可提现账号类型: "Withdrawable account type",
  支付宝: "Alipay",
  微信: "WeChat",
  选择类型: "Select type",
  "0-10字": "0-10 words",
  请输入开户行: "Please enter the deposit bank",
  持卡人姓名: "Cardholder name",
  提现姓名: "Withdrawal name",
  提现帐号: "Withdrawal account number",
  "6-24字": "6-24 words",
  礼物收入分成: "Gift income sharing",
  "0-99支持1位小数": "0-99 supports 1 decimal",
  "请输入家族名称!": "Please enter family name!",
  "请选择可提现帐户类型!": "Please select the type of withdrawable account!",
  "请输入姓名!": "Please enter the name!",
  "请输入提现帐号!": "Please enter the withdrawal account number!",
  "请输入正确的帐号!": "Please enter the correct account number!",
  "提现帐号,最短10位,最大25位!":
    "Cash withdrawal account, minimum 10 digits, maximum 25 digits!",
  请输入礼物收入分成: "Please enter gift income share",
  请输入正确分成: "Please enter correct division",
  请输入投注分成: "Please enter the betting share",
  请输入登录密码: "Please enter the login password",
  请输入家族账号: "Please enter a family account",
  请输入房费分成: "Please enter the room fee share",
  投注分成: "Betting share",
  "0.1-100支持1位小数": "0.1-100 supports 1 decimal",
  请正确输入房费分成: "Please enter the room rate share correctly",
  请正确输入投注分成: "Please input the betting share correctly",
  请正确输入礼物分成: "Please input the gift share correctly",
  编辑家族: "Edit family",
  打款成功: "Payment succeeded",
  "此操作会立即结算从上次提现到当前点击确认的时间范围内家族收入，如果该家族有主播还在开播，当次开播的收入会纳入下次提现":
    "This operation will immediately settle the family income within the time range from the last withdrawal to the current click to confirm. If the family has an anchor still broadcasting, the income of the current broadcast will be included in the next withdrawal",
  可提现账户类型: "Withdrawable account type",
  提现账号: "Withdrawal account number",
  未结算金额: "Unsettled amount",
  本次结算金额: "Current settlement amount",
  明细: "Details",
  确认打款: "Confirm payment",
  明细详情: "Details",
  日期: "Date",
  礼物分成汇总: "Gift sharing summary",
  投注分成汇总: "Betting share summary",
  房费分成汇总: "Summary of room fee sharing",
  总分成汇总: "Total share summary",
  开播时段: "Opening period",
  "礼物收入/分成": "Gift income/share",
  "投注收入/分成": "Betting income/share",
  "房费收入/分成": "Room fee income/share",
  总计分成: "Total share",
  结算时间: "Settlement time",
  "家族分成(礼物/投注/房费)": "Family share (gift/bet/room fee)",
  可提现金额: "Withdrawable amount",
  打款状态: "Payment status",
  未打款: "Unpaid",
  已打款: "Paid",
  当前已打款金额: "Current paid amount",
  投注合计: "Total bet",
  礼物合计: "Total gifts",
  房费合计: "Total room charge",
  时长合计: "Total time length",
  开播时间: "Start time",
  下播时间: "Download time",
  开播时长: "Start duration",
  房费分成: "Room fee sharing",
  礼物分成: "Gift sharing",
  投注流水: "Betting flow",
  礼物流水: "Gift flow",
  房费流水: "Room fee flow",
  检索: "Search",
  待开奖投注金额: "Betting amount to be drawn",
  有效投注金额: "Effective betting amount",
  主播收入: "Anchor income",
  投注时间: "Betting time",
  礼物总额: "Total gift amount",
  礼物金额: "Gift amount",
  房费总额: "Total room charge",
  "停留时间(分)": "Dwell time (minutes)",
  房费支出: "Room expenses",
  离场时间: "Departure time",
  巡查账号: "Patrol account",
  新增巡查: "New patrol",
  "确定更改可见状态???": "Are you sure to change the visible status?",
  确认启用此用户: "Confirm enabling this user",
  确认禁用此用户: "Confirm to disable this user",
  可见状态: "Visible status",
  再次输入新密码: "Re-enter the new password",
  创建巡查: "Create tour",
  区域号: "Area No.",
  密码: "Password",
  "6-11位数字": "6-11 digits",
  "2-12位": "2-12 bits",
  请输入账号: "Please enter the account number",
  操作日志: "Operation log",
  执行时间: "Execution time",
  目标类型: "Target type",
  目标账号: "Target account",
  老妹儿: "Old sister",
  房间名称: "Room name",
  指定会员: "Designated member",
  全部禁言: "All prohibitions",
  全部禁入: "All Prohibited",
  房间禁言: "Room taboo",
  房间禁入: "No room entry",
  "确认解除此会员账号?": "Are you sure to cancel this member account?",
  当前状态: "Current status",
  限制时段: "Restriction period",
  解除: "Release",
  限制类型: "Restriction type",
  请输入关键词: "Please enter keywords",
  限制时间: "Limit time",
  选择结束时间: "Select end time",
  永久: "Permanent",
  请选择结束时间: "Please select the end time",
  请选择限制类型: "Please select restriction type",
  刷新线路配置: "Refresh line configuration",
  新建线路: "New circuit",
  "确定删除name?": "Are you sure to delete {text}?",
  确认启用此域名: "Confirm to enable this domain name",
  确认停用此域名: "Confirm to deactivate this domain name",
  线路名: "Line name",
  推流域名: "Streaming domain name",
  "推流鉴权KEY(主)": "Push authentication KEY (primary)",
  "推流鉴权KEY(备)": "Push authentication KEY (standby)",
  播放域名: "Play domain name",
  添加播放: "Add play",
  点击查看: "Click to view",
  创建线路: "Create circuit",
  编辑线路: "Edit circuit",
  线路名称: "Line name",
  "鉴权KEY(主)": "Authentication KEY",
  "鉴权KEY(备)": "Authentication KEY",
  请勿输入空格: "Do not enter spaces",
  请输入线路名称: "Please enter line name",
  请输入推流域名: "Please enter the streaming domain name",
  请选择播放协议: "Please select the playback protocol",
  请输入鉴权KEY: "Please enter authentication KEY",
  请输入播放域名: "Please enter the play domain name",
  请输入简称: "Please enter short name",
  创建播放域名: "Create play domain name",
  编辑播放域名: "Edit play domain name",
  播放域名简称: "Play domain name abbreviation",
  播放协议: "Play protocol",
  对应推流域名: "Corresponding streaming domain name",
  简称: "Abbreviation",
  "播放鉴权KEY(主)": "Play authentication KEY",
  "播放鉴权KEY(备)": "Play authentication KEY (standby)",
  添加VIP等级: "Add VIP grade",
  "确认删除此VIP等级?": "Are you sure to delete this VIP grade?",
  VIP图标: "VIP icon",
  所需充值金额: "Required recharge amount",
  晋级彩金: "Promotion lottery",
  "购彩返利(%)": "Lottery rebate (%)",
  创建VIP等级: "Create VIP grade",
  编辑VIP等级: "Edit VIP grade",
  "请输入VIP等级(唯一)": "Please enter VIP grade (unique)",
  请输入升级所需充值金额:
    "Please enter the recharge amount required for upgrade",
  请输入晋级彩金: "Please enter the promotion lottery",
  请输入购彩返利: "Please enter lottery rebate",
  购彩返利: "Lottery rebate",
  等级图标: "Grade icon",
  "请上传动画文件(支持SVGA)": "Please upload animation file (support SVGA)",
  请输入有效金额: "Please enter valid amount",
  请输入有效晋级彩金: "Please enter valid promotion lottery",
  请输入有效数字: "Please enter a valid number",
  请上传等级图标: "Please upload the level icon",
  请输入有效等级: "Please enter valid grade",
  请输入代理账号: "Please enter the agent account number",
  请输入代理昵称: "Please enter the agent nickname",
  代理: "Agent",
  "确认通过?": "Confirm to pass?",
  请输入拒绝原因: "Please enter the rejection reason",
  "批量操作操作!": "Batch operation!",
  代理账号: "Agent account number",
  代理昵称: "Agent nickname",
  负盈利金额: "Negative profit amount",
  未结算订单金额: "Unsettled order amount",
  团队人数: "Number of teams",
  一级代理业绩: "First-level agent performance",
  二级代理业绩: "Second-level agent performance",
  代理返佣: "Agent rebates",
  确认批量通过审核: "Confirm batch approval",
  "通 过": "Pass",
  "拒 绝": "Reject",
  负盈利: "Negative profit ",
  请选择游戏类型: "Please select the game type",
  返水总开关: "Main return water switch",
  "确定修改返水总开关状态?":
    "Are you sure to modify the status of the main return water switch?",
  "请勾选需要修改的数据！": "Please check the data to be modified!",
  "确认修改 lotteryName 的返水信息?":
    "Confirm to modify the return water information of {text}?",
  "确认批量修改勾选的返水信息?":
    "Confirm to batch modify the checked return water information?",
  "返水比例请输入0-100的数，且最多有三位小数的数值":
    "Please enter a number of 0-100 with a maximum of three decimal places for the return water ratio",
  返水比例: "Return water ratio",
  "请输入0-100的数，且最多有三位小数！":
    "Please enter a number of 0-100, with a maximum of three decimal places!",
  请选择代理等级: "Please select the proxy level",
  级代理: "Level agent",
  添加返佣配置: "Add commission rebate configuration",
  "确认删除此配置?": "Are you sure to delete this configuration?",
  升级要求推广人数: "Number of promoters required for upgrading",
  升级要求投注金额: "Upgrade required betting amount",
  升级要求充值金额: "Recharge amount required for upgrade",
  等级对应一级返佣点: "Level corresponds to the first level commission point",
  等级对应二级返佣点: "Level corresponds to the second level commission point",
  编辑返佣配置: "Edit commission rebate configuration",
  请输入代理ID: "Please enter agent ID",
  代理ID格式不正确: "Incorrect format of agent ID",
  请输入升级要求推广人数:
    "Please enter the number of promotion people required for upgrading",
  升级要求推广人数格式不正确:
    "The format of promotion number required for upgrading is incorrect",
  请输入升级要求投注金额: "Please enter the amount of bet required for upgrade",
  升级要求投注金额格式不正确:
    "The format of bet amount required for upgrade is incorrect",
  请输入升级要求充值金额: "Please enter the upgrade-required recharge amount",
  升级要求充值金额格式不正确:
    "The upgrade request recharge amount format is incorrect",
  请输入等级对应一级返佣点:
    "Please enter the level corresponding to the first level rebate point",
  等级对应一级返佣点格式不正确:
    "The format of the first-level commission point corresponding to the grade is incorrect",
  请输入等级对应二级返佣点:
    "Please enter the level corresponding to the secondary commission point",
  等级对应二级返佣点格式不正确:
    "The format of the level-2 rebate point corresponding to the level is incorrect",
  卖出倍数: "Sell multiple",
  联赛编号: "League number",
  过关方式: "Customs clearance method",
  定位胆: "Locating gallbladder",
  胆: "Gall",
  "串关/倍数": "Cross correlation/multiple",
  订单信息: "Order information",
  快速购彩: "Quick lottery purchase",
  倍数: "multiple",
  单关: "Single customs",
  周: "week",
  已取消: "Canceled",
  异常订单: "Exception order",
  扣款失败: "Deduction failed",
  处理失败: "Processing failed",
  已撤单: "Cancelled",
  已中奖: "Winning",
  未中奖: "Not winning",
  开奖中: "In the lottery",
  打和: "Draw",
  待审核: "To be approved",
  充值失败: "Recharge failed",
  支付失败: "Payment failed",
  等待支付: "Waiting for payment",
  支付成功: "Payment succeeded",
  银行卡转银行卡: "Bank card to bank card",
  微信转银行卡: "WeChat to bank card",
  支付宝转银行卡: "Alipay to bank card",
  提现失败: "Withdrawal failed",
  提现成功: "Withdrawal succeeded",
  代付中: "In payment",
  代付成功: "Payment succeeded",
  代付失败: "Payment failed",
  未审核: "Unapproved",
  审核拒绝: "Approval rejection",
  进行中: "In progress",
  待开始: "To start",
  已结束: "Closed",
  未开赛: "No race",
  上半场: "First half",
  中场: "Midfield",
  下半场: "Second half",
  加时赛: "Overtime",
  点球决战: "The penalty shootout",
  完场: "End",
  推迟: "Postpone",
  中断: "Interrupt",
  腰斩: "Cut in half",
  待定: "Pending",
  用户名称: "User name",
  注单号: "Note No.",
  玩家输赢: "Players win or lose",
  玩家数量: "Number of players",
  游戏名称: "Game name",
  有效投注额度: "Effective betting limit",
  输赢: "Win or lose",
  公司账号: "Company account number",
  第三方账号: "Third-party account number",
  支付平台设置: "Payment platform setting",
  支付方式设置: "Payment method setting",
  收款方式: "Collection method",
  银行卡号: "Bank card No.",
  备用: "Standby",
  添加银行账户: "Add bank account",
  添加USDT地址: "Add USDT address",
  确认删除此账户: "Confirm to delete this account",
  确认停用此用户: "Confirm to deactivate this user",
  "确定重置此账户额度上限?":
    "Are you sure to reset the upper limit of this account?",
  支付名称: "Payment name",
  币种: "Currency",
  最低额度: "Minimum amount",
  最高额度: "Maximum amount",
  新增text: "new {text}",
  上限额度: "Upper limit",
  已收款: "Received",
  赠送比例: "Gift ratio",
  重置上限: "Reset upper limit",
  支付方式图标: "Payment method icon",
  "便于区分和记忆，用于前端配置收款账户使用":
    "Easy to distinguish and remember, used for front-end configuration of collection accounts",
  请选择收款方式: "Please select payment method",
  请选择类型: "Please select type",
  默认100: "Default 100",
  前端展示图标: "Front display icon",
  数字货币钱包类型: "Digital currency wallet type",
  数字货币钱包地址: "Digital currency wallet address",
  "转换平台币的汇率；例如:6 充值50 获得300平台币":
    "Exchange rate for converting platform currency; For example: 6 recharge 50 to get 300 platform coins",
  "当前收款账户支持的最低充值金额，默认为100元":
    "The minimum recharge amount supported by the current collection account is 100 yuan by default",
  当前收款账户收款最高额度:
    "Maximum collection limit of current collection account",
  当前收款账户收款上限额度: "Current collection account collection upper limit",
  按照充值金额得比例额外赠送给用户的金额:
    "Additional amount given to users according to the proportion of the recharge amount",
  "数值越小，排序越靠前": "The smaller the value, the higher the sorting",
  "数值越大，排序越靠前": "The bigger the value, the higher the sorting",
  不启用: "Not enabled",
  地址: "Address",
  汇率: "Exchange rate",
  请输入支付名称: "Please enter payment name",
  请选择支付方式图标: "Please select the payment method icon",
  请输入汇率: "Please enter exchange rate",
  请输入正确汇率: "Please enter the correct exchange rate",
  请输入正确额度: "Please enter the correct amount",
  请输入最高额度: "Please enter the maximum amount",
  请输入赠送比例: "Please enter the gift proportion",
  请输入正确比例: "Please enter the correct scale",
  请输入序号: "Please enter serial number",
  请输入上限额度: "Please enter the upper limit",
  新增支付账户: "New payment account",
  编辑支付账户: "Edit payment account",
  收款账户分配到那种方式: "Which method is the collection account allocated to",
  货币种类: "Currency type",
  银行开户名: "Bank account name",
  银行卡的卡号: "Card number of bank card",
  "开户行名称 例如：中国银行": "Deposit bank name, for example: Bank of China",
  设置0为不限制: "Set 0 to unlimited",
  请选择货币种类: "Please select currency type",
  请输入收款姓名: "Please enter the payee name",
  请输入收款卡号: "Please enter the collection card number",
  请输入收款银行: "Please enter the receiving bank",
  新增银行账户: "New bank account",
  编辑银行账户: "Edit bank",
  支付方式名称: "Payment method name",
  支付方式标识: "Payment method ID",
  添加支付方式: "Add payment method",
  "确认删除此支付方式?": "Are you sure to delete this payment method?",
  "只支持.jpg、.png格式": "Only. jpg and. png formats are supported",
  请输入支付方式名称: "Please enter the name of payment method",
  请输入支付方式标识: "Please enter the payment method ID",
  请上传支付方式图标: "Please upload the payment method icon",
  编辑支付方式: "Edit payment method",
  图标: "Icon",
  平台标识: "Platform ID",
  商户名: "Merchant name",
  商户号: "Merchant No.",
  添加支付平台: "Add payment platform",
  "确认删除此设置?": "Are you sure to delete this setting?",
  网关: "Gateway",
  回调地址: "Callback address",
  RSA公钥: "RSA public key",
  RSA私钥: "RSA private key",
  IP白名单: "IP whitelist",
  绑定支付平台: "Bind payment platform",
  请输入平台名称: "Please enter platform name",
  请输入平台标识: "Please enter platform ID",
  请输入商户名: "Please enter the merchant name",
  请输入网关: "Please enter gateway",
  请输入商户号: "Please enter the merchant number",
  请输入回调地址: "Please enter callback address",
  渠道码: "Channel code",
  最小代付金额: "min pay amount",
  最大代付金额: "max pay amount",
  最大代付金额需要大于最小代付金额:
    "max pay amount need greater then min pay amount",
  添加第三方账户: "Add a third-party account",
  "确认删除此账户?": "Are you sure to delete this account?",
  绑定第三方账户: "Bind third-party account",
  商户名称: "Merchant name",
  请选择支付平台: "Please select a payment platform",
  请选择支付方式: "Please select payment method",
  请填写最低额度: "Please fill in the minimum limit",
  请填写最高额度: "Please fill in the maximum limit",
  开始期号: "Start issue",
  结束期号: "End issue",
  中奖总额: "Total winning amount",
  待开奖金额: "Amount to be awarded",
  请选择系列: "Please select series",
  请输入开始期号: "Please enter the starting period number",
  请输入结束期号: "Please enter the ending period number",
  请完善搜寻信息: "Please improve the search information",
  入款总额: "Total contributions",
  出款总额: "Total disbursement",
  赠送总额: "Total amount of gifts",
  回收总额: "Total recovery",
  收益总额: "Total income",
  "收益总额=入款金额-出款金额-赠送总额+回收总额":
    "Total revenue=contribution amount - payment amount - total gift+total recovery",
  充值入款: "Recharge payment",
  第三方入款: "Third-party contributions",
  提现出款: "Cash withdrawal",
  系统赠送: "System gift",
  系统回收: "System recycling",
  入款合计: "Total contributions",
  出款合计: "Total payment",
  账面收益: "Book income",
  人: "person",
  活动回收: "Activity recycling",
  人数: "Number of people",
  机器人分红: "robot dividend",
  巴西币: "BRL",
  机器人被打赏: "The robot is rewarded",
  跟单分红抽成: "Documentary dividend withdrawal",
  合计: "Total",
  优惠明细: "Preference details",
  VIP礼金: "VIP gift",
  活动礼金: "Event gift",
  注册赠送: "Registration gift",
  分享赠送: "Sharing gift",
  分享返水: "Share return water",
  彩票返水: "Lottery return",
  第三方游戏返水: "Third-party game return water",
  注册地区: "Registration region",
  设备: "Equipment",
  IP地址: "IP address",
  地区: "Region",
  渠道管理: "Channel management",
  "渠道/链接": "Channel/link",
  首充详情: "First charge details",
  首次充值金额: "First recharge amount",
  首次充值时间: "First recharge time",
  渠道标识: "Channel ID",
  活跃用户: "Active user",
  新增会员: "New members",
  首充金额: "First charge amount",
  过滤IP数: "Filter IP number",
  过滤UDID数: "Number of filtered UDIDs",
  次日留存: "Retained on the next day",
  三日留存: "Three-day retention",
  周留存: "Weekly retention",
  月留存: "Monthly retention",
  苹果: "Apple",
  笔数: "Number of transactions",
  活跃用户详情: "Active user details",
  会员ID: "Member ID",
  活跃次数: "Active times",
  最后活跃时间: "Last active time",
  创建渠道: "Create channel",
  返回统计: "Return statistics",
  渠道状态: "Channel status",
  渠道名: "Channel name",
  渠道域名: "Channel domain name",
  "是否修改渠道状态?": "Modify channel status?",
  "是否重置密码?": "Reset password?",
  "是否删除该渠道?": "Delete this channel?",
  "删除后该渠道下的推广链接不在统计新增会员！":
    "After deletion, the promotion link under this channel will not count new members!",
  提示: "Prompt",
  推广链接数量: "Number of promotion links",
  重置密码: "Reset password",
  管理链接: "Manage links",
  新建渠道: "New channel",
  最长20位字母数字: "Maximum 20 alphanumeric characters",
  "例:www.123.com或123.com": "Example: www.123.com or 123. com",
  创建: "Create",
  请输入渠道名: "Please enter the channel name",
  "请输入6~20位英文数字": "Please enter 6~20 English digits",
  请输入渠道域名: "Please enter the channel domain name",
  渠道域名不合法: "Illegal channel domain name",
  "渠道【channelName】链接列表": "Channel [{text}] link list",
  添加新链接: "Add new link",
  链接名: "Link name",
  邀请码: "Invitation code",
  推广链接: "Promotion link",
  绑定概率: "Binding probability",
  过滤IP: "Filter IP",
  过滤UDID: "Filter UDID",
  是否删除该链接: "Delete the link",
  删除后该链接不在统计新增会员:
    "After deletion, the link is not in the statistics of new members",
  组别: "Group",
  修改人: "Modified by",
  创建人: "Created by",
  冻结: "Freeze",
  添加账号: "Add account",
  编辑用户: "Edit user",
  用户分组: "User grouping",
  请选择用户分组: "Please select user group",
  "请输入6~12位英文数字密码(不填即为不修改)":
    "Please enter 6~12-digit English numeric password (if you do not fill in it, you will not modify it)",
  新增账号: "New account",
  "请输入6~12位英文数字账号":
    "Please enter 6-12 digits English digital account",
  权限组名: "Permission group name",
  添加权限组: "Add permission group",
  "确认关闭此分组?": "Are you sure to close this group?",
  "确认启用此分组?": "Are you sure to enable this grouping??",
  "确认删除此分组?": "Are you sure to delete this group?",
  权限修改: "Permission modification",
  新增权限组: "New permission group",
  全部: "All",
  权限组名称: "Permission group name",
  "2-20个字符": "2-20 characters",
  列表显示: "List display",
  菜单: "Menu",
  权限: "Permission",
  敏感权限: "Sensitive permissions",
  请输入权限组名: "Please enter permission group name",
  "请输入2-20个字符": "Please enter 2-20 characters",
  编辑权限组: "Edit permission group",
  用户登录日志: "User login log",
  后台登录日志: "Background login log",
  登录系统: "Login system",
  登录地区: "Login region",
  登录域名: "Login domain name",
  登录浏览器: "Login browser",
  登录时间: "Login time",
  登录结果: "Login result",
  登录成功: "Login success",
  请选择租户: "Please select a tenant",
  登录设备: "Login device",
  添加白名单: "Add white list",
  确定删除此IP: "Confirm to delete this IP",
  请输入IP地址: "Please enter IP address",
  访问IP: "Access",
  系统首页: "System homepage",
  平台公告: "Platform announcement",
  首页: "Home page",
  退出登录: "Log out",
  "已更换筛选条件!": "The filter condition has been changed!",
  请选择代理类型: "Please select agent type",
  一级: "Level 1 ",
  二级: "Level 2 ",
  贡献返佣: "Contribution rebate",
  复制: "Copy",
  "类别/等级": "Category/Grade",
  用户ID: "User ID",
  刷新: "Refresh",
  调回余额: "Transfer-back balance",
  返水总金额: "Total amount of returned water",
  代理总金额: "Total agent amount",
  被打赏总金额: "Total reward amount",
  打赏总金额: "Total reward amount",
  收取红包总金额: "Total amount of red packets received",
  发送红包总金额: "Total amount of red packets sent",
  活动领取总金额: "Total amount of activity claim",
  推单分红总金额: "Total pushed dividend amount",
  其他信息: "Other information",
  调回成功: "Recall success",
  刷新name成功: "Refresh {text} succeeded",
  复制成功: "Copy succeeded",
  复制失败: "Copy failed",
  添加银行: "Add bank",
  成功: "success",
  "确认删除name吗？": "Are you sure to delete {text}?",
  "name 删除成功！": "{text} deleted successfully! ",
  "确认修改name启用状态吗？":
    "Are you sure to modify the enabled status of {text}?",
  "启用状态修改成功！": "Successfully modified the activation status!",
  修改name信息: "Modify {text} information",
  银行: "Bank",
  货币: "currency",
  请选择货币: "Please select currency",
  上传图标: "upload",
  "请求超时，请稍后重试": "The request timed out, please try again later",
  重新登入: "Re-login",
  "你暂无权限进行此操作，请联系系统管理员，或者选择其他账号登录":
    "You do not have permission to perform this operation. Please contact the system administrator or select another account to log in",
  "您已注销，可以取消以保留在该页面上，或者再次登录":
    "You have logged out, you can cancel to stay on this page, or log in again",
  中文: "Chinese",
  英语: "English",
  越南语: "Vietnamese",
  印度语: "Hindi",
  日语: "Japanese",
  马来西亚语: "Malaysian",
  或: "or",
  APP功能页面: "APP function page",
  查看详情: "details",
  代付配置: "payment set",
  添加代付配置: "add payment set",
  绑定代付配置: "bind payment set",
  请输入m_n区间数字: "please enter {text} interval number",
  体育项目: "Sports",
  数据来源: "Data source",
  是否关联: "Associate",
  任务: "task",
  任务ID: "Task ID",
  任务标题: "Task Title",
  任务状态: "Status",
  任务分类: "task classification",
  新增任务: "Add Task",
  编辑任务: "Edit Task",
  至少需要录入一种语言的任务名称:
    "At least one language task name needs to be entered",
  请选择VIP限制等级: "Please select a VIP restriction level",
  请选择限制充值的公司账号:
    "Please select a company account with limited recharge restrictions",
  请选择限制充值的第三方账号:
    "Please select a third-party account with restricted recharge",
  任务时间: "task time",
  任务内容: "Task content",
  编辑内容: "Edit Content",
  请输入任务名称: "Please enter a task name",
  任务开关: "Task switch",
  请选择任务分类: "Please select a task classification",
  请选择任务时间: "Please select a task time",
  新增分类: "Add classification",
  修改分类: "Modify Classification",
  至少需要录入一种语言的任务分类名称:
    "At least one language task classification name needs to be entered",
  确定删除任务分类: "Are you sure to delete the task classification?",
  分类ID: "Classification ID",
  请输入分类名称: "Please enter a classification name",
  请输入分类排序: "Please enter a classification sort",
  奖励编号: "Reward number",
  任务类型: "Task Type",
  任务方式: "Task method",
  编号: "number",
  抵用券奖励: "Credit voucher reward",
  彩金奖励: "Lottery rewards",
  周累计天数: "Weekly cumulative days",
  领取时间: "Collection time",
  发放方式: "Distribution method",
  输入0为不限制: "Enter 0 as unrestricted",
  总充值金额大于等于: "Total recharge amount ≥",
  今日充值总额大于等于: "Today's total recharge amount ≥",
  总投注金额大于等于: "Total betting amount ≥",
  今日投注金额大于等于: "Today's betting amount ≥",
  VIP限制: "VIP restrictions",
  开启限制: "Enable restrictions",
  不限制: "no limit",
  是否限制方式: "Whether to restrict the method",
  不限制方式: "Unrestricted approach",
  限制方式: "Restriction method",
  限制: "limitation",
  任务目标: "Task Objectives",
  参与资格: "Participation qualifications",
  充值成功金额: "Successfully recharged amount",
  有效充值单数: "Effective recharge odd number",
  充值金额大于等于: "Recharge amount ≥",
  才算有效充值单数:
    "Only then can it be considered as a valid recharge odd number",
  任务奖励: "Task rewards",
  任务天数: "Task Days",
  累计一天: "Accumulated for one day",
  累计两天: "Accumulated for two days",
  累计三天: "Accumulated three days",
  累计四天: "Accumulated four days",
  累计五天: "Accumulated five days",
  累计六天: "Accumulated six days",
  累计七天: "Accumulated seven days",
  全勤奖: "Full Attendance Award",
  选择抵用券: "Choose to use vouchers",
  彩金: "winnings",
  打码倍数: "Typing multiple",
  请输入总充值金额大于等于: "Please enter a total recharge amount of ≥",
  请输入今日充值总额大于等于:
    "Please input the total amount of today's recharge ≥",
  请输入总投注金额大于等于: "Total betting amount ≥",
  请输入今日投注金额大于等于: "Please input today's betting amount ≥",
  请输入充值成功金额: "Please enter the successful recharge amount",
  请输入有效充值单数: "Please enter a valid number of recharge orders",
  请输入充值金额大于等于才算有效充值单数:
    "Please enter a recharge amount of ≥ to calculate the number of valid recharge orders",
  请选择抵用券: "Please select a voucher to use",
  请输入彩金: "Please input the lottery",
  请输入打码倍数: "Please enter the coding multiple",
  累计签到: "Accumulated check-in",
  签到时间: "Sign-in time",
  签到任务配置: "Configuration of check-in tasks",
  签到活动按月更新: "Sign in activities are updated monthly",
  签到任务: "Sign-in task",
  签到任务分类: "Classification of check-in tasks",
  选择签到任务所属分类:
    "Select the category to which the check-in task belongs",
  可签到条件限制: "Restrictions on check-in conditions",
  当日有效投注: "Effective betting on the same day",
  签到奖励配置: "Sign in reward configuration",
  签到日期: "Sign-in date",
  签到奖励: "Sign in reward",
  累计签到奖励配置: "Cumulative check-in reward configuration",
  满足条件奖励自动发放:
    "Automatically distribute rewards that meet the conditions",
  _14天: "14 Days",
  _15天: "15 Days",
  _21天: "21 days",
  请选择选项: "Please select options",
  全勤: "Full attendance",
  累计签到天数: "Number of consecutive check-in days",
  n号: "{n}th",
  奖励类型: "Reward Type",
  请输入当日充值金额: "Please enter the recharge amount for the current day",
  请输入当日充值次数:
    "Please enter the number of recharge times for the current day",
  请输入当日有效投注: "Please enter a valid bet for the current day",
  请选择签到任务分类: "Please select the check-in task category",
  分类排序: "Sort by category",
  官方跳转: "Official jump",
  官彩: "Official lottery ticket",
  平台私彩: "Platform private lottery",
  "请输入官网名称,最多10个字符":
    "Please enter the official website name, maximum 10 characters",
  请输入官网URL: "Please enter the official website URL",
  观看时长大于等于: "Viewing duration ≥",
  送礼金额大于等于: "Gift amount ≥",
  房费金额大于等于: "Room fee amount ≥",
  发送消息大于等于: "Sending messages ≥",
  任务名称: " task name",
  新增图标: "Add icon",
  编辑图标: "Edit icon",
  客服对话: "Customer service"
};
