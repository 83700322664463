<template>
  <div class="app-container">
    <cascader-select
      v-model="value"
      :options="cascaderOptions"
      @change="changeValue"
      :label="false"
    />
    <div class="filter-container">
      <d-switch v-model="allStatus" class="filter-item" :active-value="1" :inactive-value="3" />
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        range-separator="~"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        clearable
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-button type="primary" class="filter-item" @click="handleSubmit">保存</el-button>
      <span class="filter-item" style="color: #F40B0B;">当前功能一般应用在三方游戏维护或作废时，一键操作三方游戏的开关</span>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CascaderSelect from '@/components/CascaderSelect'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getLotteryNavigation, getLotteryStatusList, updateLotteryStatus, updateSeriesStatus, getTenantSeriesStatus } from '@/api/lottery'
import GetColumns from './columns'

export default {
  name: 'LotterySwitch',
  components: { CascaderSelect },
  mixins: [TablePageMixin(getLotteryStatusList, false)],
  data() {
    return {
      value: ['1000006'],
      cascaderOptions: [
        { value: '1000006', label: '真人', children: [], includeTag: ['1301','1503','1800','200','2600'] },
        { value: '1000005', label: '彩票', children: [], includeTag: ['10','11','12','13','15','18','19','20','21','22'] },
        { value: '1000007', label: '棋牌', children: [], includeTag: ['100','1700','1804','2400'] },
        { value: '1000008', label: '捕鱼', children: [], includeTag: ['1302','1601','1701','1801','1803','2300','2401','2501','2601','201'] },
        { value: '1000009', label: '老虎机', children: [], includeTag: ['1200','1300','1600','2500','2800','2900','3000','600','700','2402'] },
        { value: '1000010', label: '竞技', children: [], includeTag: ['1400','1501'] },
        { value: '1000011', label: '体育', children: [], includeTag: ['1500','1502','1802','2000','2200','2700','800','99'] },
        { value: '1000012', label: '其他业务', children: [], includeTag: ['8888801','8888802','8888803','8888804','8888805','8888806'] }
      ],
      filterColumn: false,
      columns: [],
      allStatus: 0,
      times: null
    }
  },
  computed: {
    ...mapGetters(['operatorType']),
    seriesTag() {
      if (this.value.length > 1) {
        return this.value[1]
      }
      return ''
    }
  },
  created() {
    this.handleGetLotteryNavigation()
  },
  methods: {
    handleGetLotteryNavigation() {
      getLotteryNavigation(this.operatorType).then(([items, err]) => {
        if (!err && items && items.length > 0) {
          items.forEach(e => {
            this.cascaderOptions.forEach(v => {
              if (v.includeTag.includes(e.seriesTag)) {
                v.children.push({...e, value: e.seriesTag, label: e.seriesName})
              }
            })
          })
        }
      })
    },
    _handleUpdate({ name, status, lotteryId, showStatus, liveShowStatus, virtualNumber, maxBalance }) {
      this.$confirm(this.$t('确认修改{name}的状态吗?', { text: name }), { type: 'warning' }).then(() => {
        updateLotteryStatus({ lotteryId, status, showStatus, liveShowStatus, virtualNumber, maxBalance }, this.operatorType).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('修改成功'))
            this.handleFetch()
          }
        })
      })
    },
    changeValue() {
      this.handleFetch()
      this.handleTenantSeriesStatus()
      this.columns = GetColumns.call(this)
    },
    implementationGetParams() {
      return { seriesTag: this.seriesTag }
    },
    handleTenantSeriesStatus() {
      getTenantSeriesStatus({ seriesTag: this.seriesTag, tenantCode: this.$store.getters.tenantCode }).then(([data, err]) => {
        if (!err) {
          this.allStatus = data.status
          if (data.startTime && data.endTime) {
            this.times = [this.$parseTime(data.startTime), this.$parseTime(data.endTime)]
          } else {
            this.times = null
          }
        }
      })
    },
    handleSubmit() {
      if (!this.seriesTag) {
        this.$message.error('请选择二级分类')
        return
      }
      this.$confirm(`确定${this.allStatus === 1 ? '开启' : '停用'}当前分类的所有游戏吗？`, { type: 'warning' }).then(() => {
        let startTime = null
        let endTime = null
        if (this.times && this.times.length > 1) {
          startTime = this.times[0]
          endTime = this.times[1]
        }
        updateSeriesStatus({ seriesTag: this.seriesTag, status: this.allStatus, startTime, endTime }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t(`当前分类游戏${this.allStatus === 1 ? '开启' : '停用'}成功!`))
          }
        })
      })
    }
  }
}
</script>
