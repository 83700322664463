var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("积分总开关：") } },
            [
              _c(
                "el-radio-group",
                {
                  directives: [
                    { name: "removeAriaHidden", rawName: "v-removeAriaHidden" },
                  ],
                  model: {
                    value: _vm.ruleForm.pointsSwitch,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "pointsSwitch", $$v)
                    },
                    expression: "ruleForm.pointsSwitch",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("开启"))),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("关闭"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "getPoints",
              attrs: { label: _vm.$t("充值获取积分：") },
            },
            [
              _c(
                "el-radio-group",
                {
                  directives: [
                    { name: "removeAriaHidden", rawName: "v-removeAriaHidden" },
                  ],
                  model: {
                    value: _vm.ruleForm.rechargeSwitch,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "rechargeSwitch", $$v)
                    },
                    expression: "ruleForm.rechargeSwitch",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("开启"))),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("关闭"))),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "每充值1RMB获得",
                    "label-width": "180px",
                    prop: "rechargePoints",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px", "margin-right": "7px" },
                    attrs: {
                      disabled: _vm.rechargeDisabled,
                      maxlength: "8",
                      clearable: "",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.getNumber("rechargePoints")
                      },
                      blur: function ($event) {
                        return _vm.getPoints("rechargePoints")
                      },
                    },
                    model: {
                      value: _vm.ruleForm.rechargePoints,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "rechargePoints", $$v)
                      },
                      expression: "ruleForm.rechargePoints",
                    },
                  }),
                  _vm._v("积分 "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "getPoints",
              attrs: { label: _vm.$t("投注获取积分：") },
            },
            [
              _c(
                "el-radio-group",
                {
                  directives: [
                    { name: "removeAriaHidden", rawName: "v-removeAriaHidden" },
                  ],
                  model: {
                    value: _vm.ruleForm.betSwitch,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "betSwitch", $$v)
                    },
                    expression: "ruleForm.betSwitch",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("开启"))),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("关闭"))),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "每投注1RMB获得",
                    "label-width": "180px",
                    prop: "betPoints",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px", "margin-right": "7px" },
                    attrs: {
                      disabled: _vm.betDisabled,
                      maxlength: "8",
                      clearable: "",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.getNumber("betPoints")
                      },
                      blur: function ($event) {
                        return _vm.getPoints("betPoints")
                      },
                    },
                    model: {
                      value: _vm.ruleForm.betPoints,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "betPoints", $$v)
                      },
                      expression: "ruleForm.betPoints",
                    },
                  }),
                  _vm._v("积分 "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("积分商城说明：") } },
            [
              _c("Tinymce", {
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.introduction,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "introduction", $$v)
                  },
                  expression: "ruleForm.introduction",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }