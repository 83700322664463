import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('玩法分类'),
      key: 'groupName',
      align: 'center',
      width: 140
    },
    {
      title: this.$t('玩法规则'),
      key: 'playRule',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <el-input
              v-model={row.playRule}
              type='textarea'
              autosize
              maxlength='500'
              show-word-limit
              readonly={!this.permission.Rule} />
          )
        }
      }
    },
    {
      title: this.$t('举例说明'),
      key: 'example',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <el-input
              v-model={row.example}
              type='textarea'
              autosize
              maxlength='500'
              show-word-limit
              readonly={!this.permission.Example} />
          )
        }
      }
    },
    // {
    //   title: this.$t('状态'),
    //   key: 'status',
    //   align: 'center',
    //   width: 120,
    //   component: {
    //     render: (h, row) => {
    //       return (
    //         <d-switch v-model={row.status} activeValue={1} inactiveValue={0} />
    //       )
    //     }
    //   }
    // },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <el-button type='text' onClick={this._handleUpdate.bind(this, row)}>{this.$t('保存修改')}</el-button>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
