import request from '@/plugins/axios'

/* 代理返水 */

/* 游戏返水 ************************************************************************/
// 查询游戏返水列表
export function getGameAgentList(data) {
  return request({
    url: '/profit/config/list',
    method: 'post',
    data
  })
}

// 修改游戏代理返水列表
export function updateGameAgent(data) {
  return request({
    url: '/profit/config/update',
    method: 'post',
    data
  })
}

// 查询游戏代理返水总开关状态
export function getAllStatus(params = {}) {
  return request({
    url: '/tenant/info/query',
    method: 'get',
    params
  })
}

// 修改游戏代理返水总开关状态
export function updateAllStatus(data = {}) {
  return request({
    url: `/profit/status/update?status=${data.status}&&tenantCode=${data.tenantCode}`,
    method: 'post',
    data: {}
  })
}

/* 代理报表 ************************************************************************/
// 代理报表列表
export function getReportList(params = {}) {
  return request({
    url: '/agency/list',
    method: 'get',
    params
  })
}

export function statistics(params = {}) {
  return request({
    url: '/agency/stat',
    method: 'get',
    params
  })
}

// 代理报表审核
export function handleAgency(data = {}) {
  return request({
    url: '/agency/handle',
    method: 'post',
    data
  })
}

// 代理报表一键审核
export function batchAgencyCheck(data = {}) {
  return request({
    url: '/agency/batch/v2/approve',
    method: 'post',
    data
  })
}
// 汇总代理佣金报表
export function getRebateCount(params = {}) {
  return request({
    url: '/agency/rebate/count',
    method: 'get',
    params
  })
}

// 导出汇总代理佣金报表
export function handleExportAgency(data = {}) {
  return request({
    url: '/agency/rebate/list/export',
    method: 'post',
    data,
    responseType: 'blob',
  })
}


/* 代理报表 -- 代理明细 ************************/
// 代理明细列表
export function getReportDetailList(params = {}) {
  return request({
    url: '/agency/subordinate/list',
    method: 'get',
    params
  })
}

export function getAgencyRebateReport(params) {
  return request({
    url: '/agency/rebate/list',
    method: 'get',
    params
  })
}

export function getAgencyRebateReportDetailList(params = {}) {
  return request({
    url: '/agency/rebate/detail/list',
    method: 'get',
    params
  })
}

export function getAgencyReportDataList(params = {}) {
  return request({
    url: '/agency/data/list',
    method: 'get',
    params
  })
}

export function getAgencyReportDataDetailList(params = {}) {
  return request({
    url: '/agency/data/detail/list',
    method: 'get',
    params
  })
}

export function exportAgencyReportDataDetailList(params) {
  return request({
    url: '/agency/data/detail/list/export',
    method: 'get',
    params,
    responseType: 'blob',
    timeout: 600000
  })
}

// 代理数据加减款详情
export function agencyDataAddsubList(params) {
  return request({
    url: '/agency/data/addsub/list',
    method: 'get',
    params
  })
}

// 代理数据加减款详情导出
export function agencyDataAddsubListExport(params) {
  return request({
    url: '/agency/data/addsub/list/export',
    method: 'get',
    params,
    responseType: 'blob',
    timeout: 600000
  })
}

export function getAgencyTransferRecord(data) {
  return request({
    url: '/agency/move/record/list',
    method: 'post',
    data
  })
}

export function getAgencyUserList(data) {
  return request({
    url: '/agency/member/list',
    method: 'post',
    data
  })
}

export function createAgencyUser(data) {
  return request({
    url: '/agency/member/create',
    method: 'post',
    data
  })
}

export function deleteAgencyUser(data) {
  return request({
    url: '/agency/member/delete',
    method: 'post',
    data
  })
}

export function getAgencyDataShowConfig() {
  return request({
    url: '/agency/show/list',
    method: 'post'
  })
}

export function updateAgencyDataShowConfig(data) {
  return request({
    url: '/agency/show/update',
    method: 'post',
    data
  })
}

export function getAgencyConfigRemark() {
  return request({
    url: '/agency/remark',
    method: 'get'
  })
}

export function addAgencyConfigRemark(data) {
  return request({
    url: '/agency/remark/create',
    method: 'post',
    data
  })
}

/* 代理配置 *********页面被干掉***************************************************************/
// 查询租户配置
// export function getAgencyConfig(params = {}) {
//   return request({
//     url: '/agency/config',
//     method: 'get',
//     params
//   })
// }

// // 修改返佣配置
// export function updateAgencyConfig(data = {}) {
//   return request({
//     url: '/agency/config/modify',
//     method: 'post',
//     data
//   })
// }

/* over */

// 查询代理私有返佣配置列表
export function privateRebateConfigList(params) {
  return request({
    url: '/agency/private/rebate/config/list',
    method: 'get',
    params
  })
}

// 新增代理私有返佣配置
export function privateRebateConfigAdd(data) {
  return request({
    url: '/agency/private/rebate/config/add',
    method: 'post',
    data
  })
}

// 修改代理私有返佣配置
export function privateRebateConfigEdit(data) {
  return request({
    url: '/agency/private/rebate/config/edit',
    method: 'post',
    data
  })
}

// 删除代理私有返佣配置
export function privateRebateConfigDelete(params = {}) {
  return request({
    url: '/agency/private/rebate/config/delete',
    method: 'get',
    params
  })
}

// 更新代理佣金结算方式
export function updatePaymentType(data) {
  return request({
    url: '/agency/private/rebate/config/update/payment',
    method: 'post',
    data
  })
}

// 代理报表审核
export function approve(data) {
  return request({
    url: '/agency/batch/approve',
    method: 'post',
    data
  })
}

// 代理数据彩票详情
export function agencyDataBetList(params) {
  return request({
    url: '/agency/data/bet/list',
    method: 'get',
    params
  })
}

// 代理数据彩票详情导出
export function agencyDataBetListExport(params) {
  return request({
    url: '/agency/data/bet/list/export',
    method: 'get',
    params,
    responseType: 'blob',
    timeout: 600000
  })
}
