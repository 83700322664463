<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="70%"
    top="5vh"
    :title="search.type === 51 ? '手动加款明细' : search.type === 52 ? '手动减款明细' : '其他补款明细'"
    @closed="onClosed"
  >
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :options="options"
      :filter-column="filterColumn"
      :columns="columns"
      style="height: 70vh;overflow-y: auto;"
    >
      <template #left>
        <el-button plain type="primary" :loading="excelLoading" icon="el-icon-download" @click="_handleExport">{{ $t('导出Excel') }}</el-button>
      </template>
    </d-table>

    <pagination :page.sync="search.currentPage" :limit.sync="search.pageSize" :total="total" layout="total, sizes, prev, next, ->" @pagination="handleFetch" />
  </el-dialog>
</template>
<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import { agencyDataAddsubList, agencyDataAddsubListExport } from '@/api/agent-rebate'
import { parseTime, exportExcel } from '@/utils'
import GetColumns from './manualDetailCoulumns'
export default {
  mixins: [VDialogMixin, TablePageMixin(agencyDataAddsubList, false, {}, 'memberId')],
  data() {
    parseTime
    return {
      detailTypes: this.$t('detailTypes'),
      excelLoading: false,
      filterColumn: false,
      columns: GetColumns.call(this),
    }
  },
  methods: {
    open(type, search, dataTime, memberId, oneLevelAgencyId) {
      this.search.oneLevelAgencyId = oneLevelAgencyId
      this.search.type = type
      this.search.dateType = search.dateType
      this.search.dataLevel = search.dataLevel
      this.search.agencyId = memberId
      if (search.dateType === 1) {
        this.search.dataTime = parseTime(dataTime, '{y}-{m}-{d}')
      } else {
        this.search.dataTime = undefined
      }
      this.handleSearch()
      this.visible = true
    },
    onClosed() {
      this.nickname = ''
      this.items = []
      this.parameter = {}
    },
    _handleExport() {
      this.excelLoading = true
      agencyDataAddsubListExport(this.search).then(([data, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `代理数据-${this.$parseTime(new Date())}.csv`)
        }
      }).finally(()=>{
        this.excelLoading = false
      })
    },
    // 缓存分页查询传入的业务Id
    cashStartId() {
      if (this.search.currentPage > 1 && this.pageBusId[this.search.currentPage - 1]) {
        this.search.lastMemberId = this.pageBusId[this.search.currentPage - 1]
      } else {
        this.search.lastMemberId = undefined
      }
    }
  }
}
</script>
