<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        v-if="permission.Creata"
        type="primary"
        class="filter-item"
        @click="$refs.ActionDialogControl.open()"
        >加款扣款</el-button
      >
      <el-button
        v-if="permission.Details"
        type="success"
        class="filter-item"
        @click="$refs.OperatorDialogControl.open()"
        >操作记录</el-button
      >
    </div>

    <div class="table-container">
      <el-table
        :data="list"
        v-bind="options"
        show-summary
        :summary-method="_getSummaries"
      >
        <el-table-column prop="tenantCode" label="租户" align="center">
          <template slot-scope="{ row }"
            >{{ row.tenantName }}({{ row.tenantCode }})</template
          >
        </el-table-column>
        <el-table-column
          v-for="(value, tag) in customizeColumus"
          :key="tag"
          :label="value.label"
          :prop="value.prop"
          align="center"
        />
        <el-table-column prop="total" label="总入款" align="center" />
      </el-table>
    </div>

    <ActionDialog ref="ActionDialogControl" @update="handleUpdate" />
    <OperatorDialog ref="OperatorDialogControl" @update="handleUpdate" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { thirdAmountRecordList, addBalance } from "@/api/finance";
import TablePageMixin from "@/mixins/table-page-mixin";
import { ActionDialog, OperatorDialog } from "./components";

// const customizeColumus = {
//   '100': { label: 'KY', prop: 'ky' },
//   '200': { label: 'AG', prop: 'ag' },
//   '600': { label: 'MG', prop: 'mg' },
//   '700': { label: 'JDB', prop: 'jdb' },
//   '800': { label: 'TY', prop: 'ty' }
// }
const customizeColumus = {
  100: { label: "开元棋牌", prop: "100" },
  200: { label: "AG", prop: "200" },
  600: { label: "MG", prop: "600" },
  700: { label: "JDB电子", prop: "700" },
  2300: { label: "开元捕鱼", prop: "2300" },
  800: { label: "188体育", prop: "800" },
  900: { label: "SODE", prop: "900" },
  1000: { label: "KM", prop: "1000" },
  1100: { label: "POKER", prop: "1100" },
  1200: { label: "PG", prop: "1200" },
  1300: { label: "PP", prop: "1300" },
  1301: { label: "PP真人", prop: "1301" },
  1302: { label: "JDB捕鱼", prop: "1302" },
  1400: { label: "AWC", prop: "1400" },
  1500: { label: "9B体育", prop: "1500" },
  1501: { label: "OB电子", prop: "1501" },
  1503: { label: "DB真人", prop: "1503" },
  // 1503: { label: 'OB真人', prop: '1503' },
  1502: { label: "OB滚球", prop: "1502" },
  1600: { label: "CQ", prop: "1600" },
  1601: { label: "CQ捕鱼", prop: "1601" },
  1700: { label: "LY", prop: "1700" },
  1701: { label: "LY捕鱼", prop: "1701" },
  1800: { label: "BBIN视讯", prop: "1800" },
  1801: { label: "BBIN捕鱼", prop: "1801" },
  1802: { label: "BBIN体育", prop: "1802" },
  1803: { label: "BBIN捕鱼大师", prop: "1803" },
  1804: { label: "BBIN棋牌", prop: "1804" },
  2000: { label: "SB体育", prop: "2000" },
  2200: { label: "IM体育", prop: "2200" },
  2400: { label: "MT", prop: "2400" },
  2401: { label: "MT捕鱼", prop: "2401" },
  2402: { label: "MT电子", prop: "2402" },
  2500: { label: "SG电子", prop: "2500" },
  2501: { label: "SG捕鱼", prop: "2501" },
  2600: { label: "BG视讯", prop: "2600" },
  2601: { label: "BG捕鱼", prop: "2601" },
  2700: { label: "FB体育", prop: "2700" },
  2800: { label: "MGDASH", prop: "2800" },
  2900: { label: "FG", prop: "2900" },

  3000: { label: "PS", prop: "3000" },
};
export default {
  name: "FinanceThird",
  components: { ActionDialog, OperatorDialog },
  mixins: [TablePageMixin(thirdAmountRecordList)],
  data() {
    return {
      list: [],
      customizeColumus,
    };
  },
  created() {
    this.getTenantList();
  },
  methods: {
    ...mapActions("app", ["getTenantList"]),
    _getAmount(list, tag) {
      const tmp = list.find((o) => o.tag === tag);
      return (tmp && tmp.amount) || "0.000";
    },
    _getTotal(list) {
      return list
        .map((o) => parseFloat(o.amount))
        .reduce((acc, cur) => acc + cur)
        .toFixed(3);
    },
    _getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t("总计");
          return;
        }

        const values = data.map(
          (item) => parseFloat(item[column.property]) || 0
        );
        // if (!values.every((value) => isNaN(value))) {

        // }
        sums[index] = values.reduce((prev, curr) => {
          return prev + (curr || 0);
        }, 0);
        sums[index] = sums[index].toFixed(3);
      });
      return sums;
    },

    implementationFetched({ items }) {
      this.list = items.map((o) => {
        let item = {
          tenantCode: o.tenantCode,
          tenantName: o.tenantName,
        };

        o.list.forEach((s) => {
          item[customizeColumus[s.tag].prop] = s.amount||0;
        });

        item.total = this._getTotal(o.list);

        return item;
      });
    },

    handleUpdate({ field, close, cancel }) {
      addBalance({ ...field }).then(([_, err, response]) => {
        if (!err) {
          close();
          this.$message.success(response.data.msg);
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },
  },
};
</script>
