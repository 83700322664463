<template>
    <div class="app-container">
      <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
        </el-tabs>
        <keep-alive>
          <component :is="activeName" />
        </keep-alive>
      </template>
    </div>
  </template>
  <script>
  import { parentMixin } from '@/mixins/tab-items'
  import GameTab from './Game'
  import SeriesTab from './Series'
  
  export default {
    name: 'StatisticalGame',
    components: { GameTab, SeriesTab },
    mixins: [parentMixin],
    data() {
      return {
        tabs: [
          { label: this.$t('投注统计'), name: 'GameTab' },
          { label: this.$t('游戏系列统计'), name: 'SeriesTab' }
        ]
      }
    }
  }
  </script>
  