var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "basic_config_content",
      attrs: { gutter: 24 },
    },
    _vm._l(_vm.ruleForm, function (value, name) {
      return _c(
        "el-col",
        { key: name, attrs: { span: 12 } },
        [
          _c("h2", [_vm._v(_vm._s(name) + _vm._s(_vm.$t("配置")))]),
          _c(
            "el-form",
            {
              ref: name + "RuleForm",
              refInFor: true,
              attrs: { model: _vm.ruleForm, "label-width": "210px" },
            },
            [
              _vm._l(value, function (child, key) {
                return _c(
                  "el-form-item",
                  {
                    key: key,
                    attrs: {
                      label: key,
                      prop: name + "." + key,
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm[name][key],
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm[name], key, $$v)
                        },
                        expression: "ruleForm[name][key]",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "el-form-item",
                { staticClass: "action-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", loading: _vm.submitting },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit(name)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("保 存")))]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetFields(name)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("重 置")))]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }