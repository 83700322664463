<template>
  <div class="bodys">
    <el-form ref="validateForm" v-loading="loading" :model="ruleForm" :rules="rules" label-width="220px">

      <el-form-item label="客服开关" prop="serviceSwitch">
        <d-switch v-model="ruleForm.serviceSwitch" :inactive-value="0" :active-value="1" @change="(val)=>{if(!val){ruleForm.serviceName='在线客服'}}"/>
      </el-form-item>

      <el-form-item label="客服名称" required prop="serviceName">
        <el-input v-model="ruleForm.serviceName" maxlength=10 />
      </el-form-item>

      <el-form-item  label="客服图标" prop="avatar">
        <d-upload v-model="ruleForm.avatar" folder="head" :on-success="handleSuccess" />
        只支持.jpg、.png格式，大小为2MB以下，不设置时默认系统头像
      </el-form-item>

      <el-form-item label="系统公告开关" prop="announcementSwitch">
        <d-switch v-model="ruleForm.announcementSwitch" :inactive-value="0" :active-value="1" />
      </el-form-item>

      <el-form-item label="系统公告内容" prop="announcementMessage">
        <Tinymce ref="tinyMceOne"  v-model="ruleForm.announcementMessage" :height="200" :key="tinymce1" />
      </el-form-item>

      <el-form-item label="欢迎词开关" prop="welcomeSwitch">
        <d-switch v-model="ruleForm.welcomeSwitch" :inactive-value="0" :active-value="1" />
      </el-form-item>
      <el-form-item label="欢迎词内容" prop="welcomeMessage">
        <Tinymce ref="tinyMceTwo"  v-model="ruleForm.welcomeMessage" :height="200" :key="tinymce2" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" :loading="submitting" @click="handleSubmit">
          保存
        </el-button>
      </el-form-item>

    </el-form>
  </div>
</template>
<script>

import {
  setServiceDetailList,
  getServiceDetail
} from '@/api/interactive'
import DUpload from '@/components/DUpload'
import Tinymce from '@/components/Tinymce'
export default {
  name: 'CustomerManageTab',
  components: { DUpload, Tinymce },
  componentName: 'CustomerManageTab',
  data() {
    return {
      userStatus: '',
      loading: false,
      submitting: false,
      tinymce1:0,
      tinymce2:0,
      ruleForm: {
        "serviceSwitch":0,
        "serviceName": "在线客服",
        "avatar": "",
        "welcomeSwitch": 0,
        "announcementSwitch": 0,
        "welcomeMessage": "",
        "announcementMessage": ""
      },
      rules: {
        serviceSwitch:[
        { required: true, message: '请设置客服开关', trigger: 'change' }
        ],
        serviceName: [
          { required: true, message: '请输入客服名称', trigger: 'change' }
        ],
        avatar: [
          { required: true, message: '请上传图标', trigger: 'change' }
        ],
        welcomeMessage: [
          { required: true, message: '请输入欢迎词内容', trigger: 'change' }
        ],
        announcementMessage: [
          { required: true, message: '请输入系统公告内容', trigger: 'change' }
        ],
      },
    }
  },
  activated(){
    console.log(666)
    getServiceDetail().then(res => {
      if (res && res[2].status == '200') {
        this.optionData = res[2].data.data;
        this.ruleForm = { ...this.optionData }
        this.tinymce1++
        this.tinymce2++
      }
    })
  },
  methods: {
    handleSuccess(data) {
      this.ruleForm.avatar = data[0];
      this.$refs.validateForm.validateField('avatar')
    },
    handleSubmit() {
      this.$refs['validateForm'].validate(valid => {
        if (valid) {
          let params = this.ruleForm
          setServiceDetailList(params).then(res => {
            if(res[2].status == 200){
              this.$message.success(this.$t('配置成功'))
            }
          })
        } else {
          return false;
        }
      })
    },
  },
}
</script>


<style>
.bodys {
  padding: 30px;
}
</style>