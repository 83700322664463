export const guru_order_list =  [
    {
      orderNo: 1,
      memberName: "12131",
      nickname: "12131",
      memberType: 0,
      smallPlayType: 0,
      planSpeech: "",
      selfAmount: {},
      hitAmount: {},
      selfOrderProfit: {},
      followerOrderNum: 0,
      followerAmount: {},
      followerHitAmount: {},
      followerOrderProfit: {},
      bonusRateGuru: "",
      guruCommission: "",
      bonusRatePlatform: "",
      platformCommission: "",
      settlementCommission: "",
      createTime: "",
      endTime: "",
      settlementTime: "",
      orderStatus: 0,
      planId: 0,
      betInformation: "",
    },
    {
        orderNo: 1,
        memberName: "12131",
        nickname: "12131",
        memberType: 0,
        smallPlayType: 0,
        planSpeech: "",
        selfAmount: {},
        hitAmount: {},
        selfOrderProfit: {},
        followerOrderNum: 0,
        followerAmount: {},
        followerHitAmount: {},
        followerOrderProfit: {},
        bonusRateGuru: "",
        guruCommission: "",
        bonusRatePlatform: "",
        platformCommission: "",
        settlementCommission: "",
        createTime: "",
        endTime: "",
        settlementTime: "",
        orderStatus: 0,
        planId: 0,
        betInformation: "",
      },
      {
        orderNo: 1,
        memberName: "12131",
        nickname: "12131",
        memberType: 0,
        smallPlayType: 0,
        planSpeech: "",
        selfAmount: {},
        hitAmount: {},
        selfOrderProfit: {},
        followerOrderNum: 0,
        followerAmount: {},
        followerHitAmount: {},
        followerOrderProfit: {},
        bonusRateGuru: "",
        guruCommission: "",
        bonusRatePlatform: "",
        platformCommission: "",
        settlementCommission: "",
        createTime: "",
        endTime: "",
        settlementTime: "",
        orderStatus: 0,
        planId: 0,
        betInformation: "",
      },
      {
        orderNo: 1,
        memberName: "12131",
        nickname: "12131",
        memberType: 0,
        smallPlayType: 0,
        planSpeech: "",
        selfAmount: {},
        hitAmount: {},
        selfOrderProfit: {},
        followerOrderNum: 0,
        followerAmount: {},
        followerHitAmount: {},
        followerOrderProfit: {},
        bonusRateGuru: "",
        guruCommission: "",
        bonusRatePlatform: "",
        platformCommission: "",
        settlementCommission: "",
        createTime: "",
        endTime: "",
        settlementTime: "",
        orderStatus: 0,
        planId: 0,
        betInformation: "",
      },
      {
        orderNo: 1,
        memberName: "12131",
        nickname: "12131",
        memberType: 0,
        smallPlayType: 0,
        planSpeech: "",
        selfAmount: {},
        hitAmount: {},
        selfOrderProfit: {},
        followerOrderNum: 0,
        followerAmount: {},
        followerHitAmount: {},
        followerOrderProfit: {},
        bonusRateGuru: "",
        guruCommission: "",
        bonusRatePlatform: "",
        platformCommission: "",
        settlementCommission: "",
        createTime: "",
        endTime: "",
        settlementTime: "",
        orderStatus: 0,
        planId: 0,
        betInformation: "",
      },
      
  ];

export const application_list= [
  {
    "memberId": 0,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 1,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 2,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 3,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 1,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 2,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 3,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 1,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 2,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 3,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 1,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 2,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 3,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 1,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 2,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 3,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 1,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 2,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
  {
    "memberId": 3,
    "memberName": "",
    "nickName": "",
    "guruType": "",
    "applicationTime": "",
    "operatingTime": "",
    "operatingUser": "",
    "guruStatus": 0,
    "remark": ""
  },
]