const CanChooseColumns = function() {
  return [
    {
      title: this.$t('注册时间'),
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span>{this.$parseTime(row.createTime)}</span>
          )
        }
      }
    },
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('会员昵称'),
      key: 'nickname',
      align: 'center'
    },
    {
      title: this.$t('所属上级'),
      key: 'agencyName',
      align: 'center'
    },
    {
      title: this.$t('来源'),
      key: 'registerResource',
      align: 'center'
    },
    {
      title: this.$t('设备'),
      key: 'registerDevice',
      align: 'center'
    },
    {
      title: this.$t('IP地址'),
      key: 'registerIp',
      align: 'center'
    },
    {
      title: this.$t('地区'),
      key: 'registerAddress',
      align: 'center'
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
