<template>
  <div class="app-container">
    <div class="filter-container">
      <el-cascader
        v-model="cascaderLottery"
        :options="cascaderOptions"
        style="width: 380px"
        :placeholder="$t('请选择彩种')"
        class="filter-item"
      />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="cascaderLottery = []">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="handleSaveChannelType" @update="handleSaveChannelType" />
  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import navigationMixin from '@/views/lottery/navigationMixin'
import { liveLotteryPage, liveLotteryUpdate } from '@/api/live'
import { getLotteryNavigationList } from '@/api/lottery'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { mapState } from 'vuex'

export default {
  name: 'LiveChannel',
  components: { ActionDialog },
  mixins: [TablePageMixin(liveLotteryPage), navigationMixin(getLotteryNavigationList)],
  data() {
    return {
      filterColumn: false,
      columns: GetColumns.call(this),
      cascaderLottery: [],
      cascaderOptions: []
    }
  },
  computed: {
    ...mapState('app', ['allLang'])
  },
  created() {
    this.onFetch()
  },
  methods: {
    handleSaveChannelType({ field, cancel, close }) {
      liveLotteryUpdate(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    implementationGetParams() {
      if (this.cascaderLottery.length > 1) {
        this.search.lotteryId = this.cascaderLottery[1]
      } else {
        delete this.search.lotteryId
      }
      return { ...this.search }
    }
  }
}
</script>
