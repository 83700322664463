<template>
  <el-dialog
    :title="$t('房间热度设定')"
    :visible.sync="visible"
    width="480px"
    top="5vh"
    class="border-form-dialog"
    :close-on-click-modal="false"
    @open="onOpen"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      :show-message="false"
      label-suffix=":"
      label-width="175px"
    >
      <el-form-item :label="$t('礼物金额加成值')" prop="giftAmountAddition">
        <el-input v-model.number="ruleForm.giftAmountAddition" size="medium" :placeholder="$t('加成值大于等于1')">
          <template slot="append">{{ $t('倍') }}</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('房费金额加成值')" prop="roomRateAddition">
        <el-input v-model.number="ruleForm.roomRateAddition" size="medium" :placeholder="$t('加成值大于等于1')">
          <template slot="append">{{ $t('倍') }}</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('投注金额加成值')" prop="betAmountAddition">
        <el-input v-model.number="ruleForm.betAmountAddition" size="medium" :placeholder="$t('加成值大于等于1')">
          <template slot="append">{{ $t('倍') }}</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('观看人次加成值')" prop="viewersNumAddition">
        <el-input v-model.number="ruleForm.viewersNumAddition" size="medium" :placeholder="$t('加成值大于等于1')">
          <template slot="append">{{ $t('倍') }}</template>
        </el-input>
      </el-form-item>
    </el-form>
    <p style="font-size: 12px; line-height: 28px">
      {{ $t('热度组成如下') }}：<br>+
      {{ $t('(单次礼物合计*n)+(单次房费合计*n)+(单次投注合计*n)+(单次观看人次*n)+人工热度') }}
    </p>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确认') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getRoomHot } from '@/api/live'
import { $t } from '@/lang'
const getDefaultForm = () => ({
  giftAmountAddition: null,
  roomRateAddition: null,
  betAmountAddition: null,
  viewersNumAddition: null
})

const rule = [
  { required: true, message: $t('请输入'), trigger: 'blur' },
  { pattern: /^[1-9]|[1-9][0-9]|100$/, message: $t('请输入正确'), trigger: 'blur' }
]

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      loading: false,
      emitName: 'update',
      rules: {
        giftAmountAddition: rule,
        roomRateAddition: rule,
        betAmountAddition: rule,
        viewersNumAddition: rule
      }
    }
  },
  methods: {
    onOpen() {
      this.loading = true
      getRoomHot().then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.ruleForm = data
        }
      })
    }
  }
}
</script>

