<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <el-date-picker
          v-model="times"
          type="datetimerange"
          class="filter-item"
          style="width: 400px;"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
        />
        <el-input class="filter-item" v-model="search.operatorName" clearable placeholder="操作人账号" style="width: 140px" />
        <el-input class="filter-item" v-model="search.target" clearable placeholder="操作名称" style="width: 140px" />
        <el-input class="filter-item" v-model="search.content" clearable placeholder="操作内容" style="width: 140px" />
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">
          查询
        </el-button>
      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>
<script>
import { getBackManagerOpLog } from '@/api/permission'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getDefaultTimes } from '@/utils'

export default {
  name: 'OperateLog',
  mixins: [TablePageMixin(getBackManagerOpLog)],
  data() {
    return {
      times: getDefaultTimes(),
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
      operatorTypes: {
        0: '平台管理',
        1: '租户管理'
      }
    }
  },
  methods: {
    implementationGetParams() {
      return this.formatIntervalTime()
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
