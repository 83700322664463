<template>
  <el-dialog title="修改后台用户" :visible.sync="visible" width="400px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="用户昵称" required prop="nickname">
        <el-input v-model="ruleForm.nickname" />
      </el-form-item>
      <el-form-item label="头像" prop="headUrl">
        <d-upload
          v-model="ruleForm.headUrl"
          folder="head"
          :on-success="handleSuccess"
        />
      </el-form-item>
      <el-form-item label="是否删除" prop="freezeStatus">
        <d-switch v-model="ruleForm.freezeStatus" :inactive-value="0" :active-value="1" />
      </el-form-item>
      <el-form-item label="增加关注人数" prop="newFansNum">
        <el-input v-model.number="ruleForm.newFansNum" />
      </el-form-item>
      <el-form-item :label="$t('会员VIP等级')" prop="vipLevel">
        <el-select
          v-model="ruleForm.vipLevel"
          :placeholder="$t('请选择会员VIP等级')"
          style="width: 100%"
          @change="$forceUpdate()"
        >
          <el-option
            v-for="item in vipLevels"
            :key="item.vipLevel"
            :label="`VIP${item.vipLevel}`"
            :value="item.vipLevel"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { validNickName } from '@/utils/validate'
import DUpload from '@/components/DUpload'
import { getAllVipLevels } from '@/api/app'
const getDefaultForm = () => ({
  nickname: '',
  headUrl: '',
  freezeStatus: 1,
  newFansNum: null,
  vipLevel: null
})

export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validNickname = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入昵称'))
      } else if (!validNickName(value)) {
        callback(new Error('请输入2~6位中文'))
      } else {
        callback()
      }
    }

    return {
      emitName: 'update',
      rules: {
        nickname: [
          { validator: validNickname, trigger: 'blur' }
        ],
        headUrl: [
          { required: true, message: '请上传头像', trigger: 'change' }
        ],
        freezeStatus: [
          { required: true, message: '请选择是否删除', trigger: 'change' }
        ],
        newFansNum: [
          { pattern: /^([0-9]|[1-9][0-9]*)$/, message: '请输入正确数字', trigger: 'blur' }
        ]
      },
      vipLevels: []
    }
  },
  methods: {
    open(row) {
      this.ruleForm.freezeStatus = row.freezeStatus
      this.ruleForm.headUrl = row.headUrl
      this.ruleForm.memberId = row.memberId
      this.ruleForm.nickname = row.nickname
      this.ruleForm.newFansNum = row.newFansNum
      this.ruleForm.tenantCode = row.tenantCode
      this.ruleForm.vipLevel = row.vipLevel
      this.visible = true
      getAllVipLevels().then(([data, err]) => {
        if (!err) {
          this.vipLevels = data
        }
      })
    },
    handleSuccess(data) {
      this.ruleForm.headUrl = data + ''
      this.$refs.validateForm.validateField('headUrl')
    }
  }
}
</script>
