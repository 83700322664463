<template>
  <div class="app-container">
    <div class="filter-container">
      <!-- <el-date-picker
        v-model="times"
        type="daterange"
        class="filter-item"
        style="width: 360px"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="yyyy-MM-dd"
      />
      <el-input v-model="search.memberId" :placeholder="$t('会员ID，精确匹配')" clearable style="width: 160px" />
      <el-input v-model="search.memberName" :placeholder="$t('会员账号，模糊匹配')" clearable style="width: 200px" />

      <el-button class="filter-item" type="primary" @click="handleSearch">{{ $t('查询') }}</el-button> -->
      <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate()">
        {{ $t('添加代理推广') }}
      </el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <edit-dialog ref="EditDialogControl" @create="handleCreate" @update="hanldeUpdate" />
  </div>
</template>

<script>
import EditDialog from './EditDialog.vue'
import { getList, updateInfo, createInfo, deleteAInfo } from '@/api/promotion.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
// let search = {}
export default {
  name: 'PromotionTab',
  components: { EditDialog },
  mixins: [TablePageMixin(getList)],
  data() {
    return {
      times: [],
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
      defaultAgentType: [0, 1, 2, 3, 4, 5, 6]
    }
  },
  methods: {
    onCreate(row) {
      this.$refs.EditDialogControl.open(row, this.$store.getters.tenantCode)
    },
    handleCreate({ field, cancel, close }) {
      createInfo({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('保存成功'))
        } else {
          cancel()
        }
      })
    },

    hanldeUpdate({ field, cancel, close }) {
      updateInfo({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('保存成功'))
        } else {
          cancel()
        }
      })
    },

    handleDelete(id) {
      this.$confirm(this.$t('确认删除此配置?'), this.$t('系统提示'), { type: 'error' }).then(() => {
        deleteAInfo({ id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
<style type="scss" scoped>
.filter-container {
  display: flex;
  gap: 8px;
}

.filter-container .filter-item+.filter-item {
  margin-left: 0;
}
</style>
