var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "" + _vm.ruleForm.name + _vm.$t("彩种介绍设置"),
        visible: _vm.visible,
        "close-on-click-modal": false,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: { model: _vm.ruleForm, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-right": "6px" },
                  on: { change: _vm.onOpen },
                  model: {
                    value: _vm.headerLang,
                    callback: function ($$v) {
                      _vm.headerLang = $$v
                    },
                    expression: "headerLang",
                  },
                },
                _vm._l(_vm.allLang, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: index, attrs: { label: item.lang } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "desc" } },
            [
              _c("tinymce", {
                ref: "tinymce",
                attrs: { height: 300 },
                model: {
                  value: _vm.ruleForm.explain,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "explain", $$v)
                  },
                  expression: "ruleForm.explain",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确定")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }