<template>
  <div class="app-container">
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <div slot="left" class="filter-container">
        <el-input v-model="search.memberName" :placeholder="$t('巡查账号')" clearable class="filter-item" style="width: 160px;" />
        <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleRefresh">{{ $t('查 询') }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </div>
      <el-button v-if="permission.Add" slot="right" type="success" icon="el-icon-plus" @click="onCreate">{{ $t('新增巡查') }}</el-button>

    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <CreateDialog ref="CreateDialogControl" @create="_handleCreate" />
    <ChangeDialog ref="ChangeDialogControl" @change="_handleChange" />
    <!-- <OplogDialog ref="OplogDialogControl" /> -->
  </div>
</template>

<script>
import {
  getLiveInspectionList,
  addInspection,
  updateInspectionPassword,
  updateInspectionStatus,
  updateInspectionVisibleStatus
} from '@/api/live'
import md5 from 'js-md5'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { CreateDialog, ChangeDialog } from './components'
export default {
  name: 'LiveInspection',
  components: { CreateDialog, ChangeDialog },
  mixins: [TablePageMixin(getLiveInspectionList)],
  data() {
    return {
      search: {},
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  methods: {
    onCreate() {
      this.$refs.CreateDialogControl.open()
    },
    _handleCreate({ field, cancel, close }) {
      const password = md5(this.$store.state.user.VUE_APP_MD5_KEY + field.password)
      // const password = md5(process.env.VUE_APP_MD5_KEY + field.password)
      addInspection({ ...field, password, confirmPassword: password }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    _handleChange({ field, cancel, close }) {
      const md5Value = md5(this.$store.state.user.VUE_APP_MD5_KEY + field.newPwd)
      updateInspectionPassword({
        memberId: field.memberId,
        newPwd: md5Value,
        newPwdConfirm: md5Value
      }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('编辑成功'))
        } else {
          cancel()
        }
      })
    },
    _handleChangeStatus({ memberId, accountStatus }) {
      return new Promise((resolve) => {
        this.$confirm(accountStatus ? this.$t('确认启用此用户') : this.$t('确认禁用此用户'), this.$t('系统提示'), {
          type: 'warning'
        }).then(() => {
          updateInspectionStatus({
            memberId,
            accountStatus: ~accountStatus + 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    _handleChangeVisibleStatus({ memberId, visibleStatus }) {
      return new Promise((resolve) => {
        this.$confirm(this.$t('确定更改可见状态???'), this.$t('系统提示'), {
          type: 'warning'
        }).then(() => {
          updateInspectionVisibleStatus({
            memberId,
            visibleStatus: ~visibleStatus + 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
