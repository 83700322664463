<template>
  <el-dialog :title="`${$t('批量提现')}${okText}`" :visible.sync="visible" width="420px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" label-width="120px">
      <el-form-item :label="$t('操作说明')">
        <el-input v-model="ruleForm.notes" type="textarea" :rows="3" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ okText }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  notes: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm, false)],
  data() {
    return {
      status: 4,
      emitName: 'update'
    }
  },
  computed: {
    okText() {
      return this.status === 4 ? this.$t('通过') : this.$t('拒绝')
    }
  },
  methods: {
    open(rows, status) {
      this.status = status
      this.ruleForm.status = status
      this.ruleForm.resource = rows
      this.visible = true
    }
  }
}
</script>
