import { filterColumns } from '@/utils'
import dayjs from 'dayjs'
const CanChooseColumns = function() {
  return [
    {
      title: '会员ID',
      key: 'memberId',
      align: 'center',
      minWidth: 150
    },
    {
      title: '会员账号',
      key: 'memberName',
      align: 'center',
      minWidth: 160
    },
    {
      title: '卡券名称',
      key: 'couponTitles',
      align: 'center',
      minWidth: 120
    },
    {
      title: '卡券类型',
      key: 'couponType',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{this.couponTypes[row.couponType]}</span>
        }
      }
    },
    {
      title: '抵用券ID',
      key: 'couponId',
      align: 'center'

    },
    {
      title: '抵用券子ID',
      key: 'id',
      align: 'center',
      minWidth: 140
    },
    {
      title: '面额',
      key: 'couponAmount',
      align: 'center',
      component: {
        render: (h, row) => {
          const couponAmount = row.couponAmount
          switch (row.couponType) {
            case 3:
              return <span>{row.couponDiscountType === 1 ? row.couponDiscountRate + '折' : couponAmount}</span>;
              break;
            case 6:
              return <span>加奖{couponAmount}倍</span>;
              break;
            default:
              return <span>{couponAmount}</span>;
          }
        }
      }
    },
    {
      title: '使用标准',
      key: 'couponStandard',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.couponType === 6) {
            return <span>最小下注{row.couponStandardMin}，最大下注{row.couponStandardMax}</span>;
          } else {
            return <span>{row.couponStandard}</span>;
          }
        }
      }
    },
    {
      title: '有效期',
      key: 'couponExpired',
      align: 'center',
      width: 250,
      component: {
        render: (h, row) => {
          const couponValidStart = this.$parseTime(row.couponValidStart, '{y}-{m}-{d} {h}:{i}')
          const couponValidEnd = this.$parseTime(row.couponValidEnd, '{y}-{m}-{d} {h}:{i}')
          return <span>{row.couponValidType === 1 ? (couponValidStart + ' 至 ' + couponValidEnd) : ('领取后' + row.couponExpired + '小时有效')}</span>
        }
      }
    },
    {
      title: '抵用券状态',
      key: 'couponStatus',
      align: 'center',
      width: 140,
      // 0 已下架 1 未使用 2已使用 3已过期
      component: {
        render: (h, row) => {
          return <span>{['已下架', '未使用', '已使用', '已过期'][row.couponStatus]}</span>
        }
      }
    },
    {
      title: '备注',
      key: 'remark',
      align: 'center'
    },
    {
      title: '使用订单号',
      key: 'orderNo',
      align: 'center',
      minWidth: 140
    },
    {
      title: '领取方式',
      key: 'receiveType',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>
            { this.receiveTypes[row.receiveType] }
            { row.receiveType === 1 && row.operatorName && <p>{ row.operatorName }</p> }      
          </span>
        }
      }
    },
    {
      title: '领取时间',
      key: 'createTime',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          return row.createTime && <span>{dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>
        }
      }
    },
    {
      title: '过期时间',
      key: 'expiredTime',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          return row.expiredTime && <span>{dayjs(row.expiredTime).format('YYYY-MM-DD HH:mm:ss')}</span>
        }
      }
    },

    {
      title: '使用时间',
      key: 'usedTime',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          return row.usedTime && <span>{dayjs(row.usedTime).format('YYYY-MM-DD HH:mm:ss')}</span>
        }
      }
    }

  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
