var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "basic_config_content",
      attrs: { gutter: 24 },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "210px",
          },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("h2", [_vm._v(_vm._s(_vm.$t("下载配置")))]),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("下载地址"), prop: "downloadUrl" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.downloadUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "downloadUrl", $$v)
                      },
                      expression: "ruleForm.downloadUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("IOS娱乐版下载地址"), prop: "iosUrl" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.iosUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "iosUrl", $$v)
                      },
                      expression: "ruleForm.iosUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("IOS体育版下载地址"),
                    prop: "sportsIosUrl",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.sportsIosUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "sportsIosUrl", $$v)
                      },
                      expression: "ruleForm.sportsIosUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("IOS曾道人版下载地址"),
                    prop: "liuheIosUrl",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.liuheIosUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "liuheIosUrl", $$v)
                      },
                      expression: "ruleForm.liuheIosUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("Android娱乐版下载地址"),
                    prop: "androidUrl",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.androidUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "androidUrl", $$v)
                      },
                      expression: "ruleForm.androidUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("Android体育版下载地址"),
                    prop: "sportsAndroidUrl",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.sportsAndroidUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "sportsAndroidUrl", $$v)
                      },
                      expression: "ruleForm.sportsAndroidUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("Android曾道人版下载地址"),
                    prop: "liuheAndroidUrl",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.liuheAndroidUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "liuheAndroidUrl", $$v)
                      },
                      expression: "ruleForm.liuheAndroidUrl",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("app弹窗下载地址")))]),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("下载地址1"), prop: "downloadOne" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.downloadOne,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "downloadOne", $$v)
                      },
                      expression: "ruleForm.downloadOne",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("下载地址2"), prop: "downloadTwo" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.downloadTwo,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "downloadTwo", $$v)
                      },
                      expression: "ruleForm.downloadTwo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("下载地址3"), prop: "downloadThree" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.downloadThree,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "downloadThree", $$v)
                      },
                      expression: "ruleForm.downloadThree",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("推送配置")))]),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("极光配置"), prop: "pushAppKey" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: _vm.$t("请输入极光appkey") },
                      model: {
                        value: _vm.ruleForm.pushAppKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "pushAppKey", $$v)
                        },
                        expression: "ruleForm.pushAppKey",
                      },
                    },
                    [_c("template", { slot: "prepend" }, [_vm._v("appkey")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "pushMasterSecret" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: _vm.$t("请输入极光masterSecre") },
                      model: {
                        value: _vm.ruleForm.pushMasterSecret,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "pushMasterSecret", $$v)
                        },
                        expression: "ruleForm.pushMasterSecret",
                      },
                    },
                    [
                      _c("template", { slot: "prepend" }, [
                        _vm._v("masterSecre"),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("推送配置")))]),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("极光配置"), prop: "pushAppKey" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "$t('请输入极光appkey')" },
                      model: {
                        value: _vm.ruleForm.pushAppKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "pushAppKey", $$v)
                        },
                        expression: "ruleForm.pushAppKey",
                      },
                    },
                    [_c("template", { slot: "prepend" }, [_vm._v("appkey")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "pushMasterSecret" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "$t('请输入极光masterSecre')" },
                      model: {
                        value: _vm.ruleForm.pushMasterSecret,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "pushMasterSecret", $$v)
                        },
                        expression: "ruleForm.pushMasterSecret",
                      },
                    },
                    [
                      _c("template", { slot: "prepend" }, [
                        _vm._v("masterSecre"),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("客服配置")))]),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("客服Telegram"), prop: "customerQq" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": 12346" },
                    model: {
                      value: _vm.ruleForm.customerQq,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "customerQq", $$v)
                      },
                      expression: "ruleForm.customerQq",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("客服Telegram") } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "qqShow" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#3894FF",
                          "inactive-color": "#F26161",
                          "active-text": _vm.$t("开启"),
                          "inactive-text": _vm.$t("关闭"),
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.ruleForm.qqShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "qqShow", $$v)
                          },
                          expression: "ruleForm.qqShow",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("第三方客服"), prop: "threeCustomer" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.baidu.com" },
                    model: {
                      value: _vm.ruleForm.threeCustomer,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "threeCustomer", $$v)
                      },
                      expression: "ruleForm.threeCustomer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("第三方客服") } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "threeShow" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#3894FF",
                          "inactive-color": "#F26161",
                          "active-text": _vm.$t("开启"),
                          "inactive-text": _vm.$t("关闭"),
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.ruleForm.threeShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "threeShow", $$v)
                          },
                          expression: "ruleForm.threeShow",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("曾道人第三方客服"),
                    prop: "liuheCustomer",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.baidu.com" },
                    model: {
                      value: _vm.ruleForm.liuheCustomer,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "liuheCustomer", $$v)
                      },
                      expression: "ruleForm.liuheCustomer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("曾道人第三方客服") } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "liuheShow" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#3894FF",
                          "inactive-color": "#F26161",
                          "active-text": _vm.$t("开启"),
                          "inactive-text": _vm.$t("关闭"),
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.ruleForm.liuheShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "liuheShow", $$v)
                          },
                          expression: "ruleForm.liuheShow",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "1对1专属客服名称", prop: "thirdName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "1对1专属客服名称" },
                    model: {
                      value: _vm.ruleForm.thirdName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "thirdName", $$v)
                      },
                      expression: "ruleForm.thirdName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "1对1专属客服地址", prop: "thirdCustomer" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.baidu.com" },
                    model: {
                      value: _vm.ruleForm.thirdCustomer,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "thirdCustomer", $$v)
                      },
                      expression: "ruleForm.thirdCustomer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "1对1专属客服" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "thirdShow" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#3894FF",
                          "inactive-color": "#F26161",
                          "active-text": _vm.$t("开启"),
                          "inactive-text": _vm.$t("关闭"),
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.ruleForm.thirdShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "thirdShow", $$v)
                          },
                          expression: "ruleForm.thirdShow",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "在线客服2", prop: "whatappCustomer" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.baidu.com" },
                    model: {
                      value: _vm.ruleForm.whatappCustomer,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "whatappCustomer", $$v)
                      },
                      expression: "ruleForm.whatappCustomer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "whatappShow" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#3894FF",
                      "inactive-color": "#F26161",
                      "active-text": _vm.$t("开启"),
                      "inactive-text": _vm.$t("关闭"),
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.ruleForm.whatappShow,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "whatappShow", $$v)
                      },
                      expression: "ruleForm.whatappShow",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("第三方聊天室") } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "chatShow" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                          "active-text": _vm.$t("开启"),
                          "inactive-text": _vm.$t("关闭"),
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.ruleForm.chatShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "chatShow", $$v)
                          },
                          expression: "ruleForm.chatShow",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.ruleForm.chatShow === 1
                    ? [
                        _c(
                          "div",
                          { staticClass: "chat-form-item" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "chatImagePathUrl" } },
                              [
                                _c("d-upload", {
                                  attrs: {
                                    folder: "head",
                                    "on-success": _vm.handleSuccess,
                                    width: 86,
                                    height: 86,
                                  },
                                  model: {
                                    value: _vm.ruleForm.chatImagePathUrl,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "chatImagePathUrl",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.chatImagePathUrl",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "input" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-bottom": "18px" },
                                    attrs: { prop: "chatName" },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: _vm.$t("聊天室名称"),
                                        maxlength: "20",
                                      },
                                      model: {
                                        value: _vm.ruleForm.chatName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "chatName",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "ruleForm.chatName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "chatPathUrl" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: _vm.$t("请输入地址"),
                                      },
                                      model: {
                                        value: _vm.ruleForm.chatPathUrl,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "chatPathUrl",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "ruleForm.chatPathUrl",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "chatRemark" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t("简述"),
                                type: "textarea",
                                maxlength: "100",
                                "show-word-limit": "",
                                rows: 3,
                              },
                              model: {
                                value: _vm.ruleForm.chatRemark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "chatRemark", $$v)
                                },
                                expression: "ruleForm.chatRemark",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("H5/Web版本")))]),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("H5地址"), prop: "h5Url" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.h5Url,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "h5Url", $$v)
                      },
                      expression: "ruleForm.h5Url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("WEB地址"), prop: "webUrl" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.webUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "webUrl", $$v)
                      },
                      expression: "ruleForm.webUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("轻便版地址"),
                    prop: "lightweightAddress",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("例如") + ": www.123.com/" },
                    model: {
                      value: _vm.ruleForm.lightweightAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "lightweightAddress", $$v)
                      },
                      expression: "ruleForm.lightweightAddress",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v(_vm._s(_vm.$t("体育首页跳转")))]),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("赛事记录") } },
                _vm._l(_vm.locales, function (locale) {
                  return _c(
                    "el-form-item",
                    {
                      key: locale.name,
                      attrs: { prop: "competition" + locale.name + "Url" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { "margin-bottom": "12px" },
                          model: {
                            value:
                              _vm.ruleForm["competition" + locale.name + "Url"],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "competition" + locale.name + "Url",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm[`competition${locale.name}Url`]",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(_vm._s(locale.label)),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("足球专家") } },
                _vm._l(_vm.locales, function (locale) {
                  return _c(
                    "el-form-item",
                    {
                      key: locale.name,
                      attrs: { prop: "expert" + locale.name + "Url" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { "margin-bottom": "12px" },
                          model: {
                            value: _vm.ruleForm["expert" + locale.name + "Url"],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "expert" + locale.name + "Url",
                                $$v
                              )
                            },
                            expression: "ruleForm[`expert${locale.name}Url`]",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(_vm._s(locale.label)),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("热门预测") } },
                _vm._l(_vm.locales, function (locale) {
                  return _c(
                    "el-form-item",
                    {
                      key: locale.name,
                      attrs: { prop: "predict" + locale.name + "Url" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { "margin-bottom": "12px" },
                          model: {
                            value:
                              _vm.ruleForm["predict" + locale.name + "Url"],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "predict" + locale.name + "Url",
                                $$v
                              )
                            },
                            expression: "ruleForm[`predict${locale.name}Url`]",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(_vm._s(locale.label)),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "action-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", loading: _vm.submitting },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v(_vm._s(_vm.$t("确 定")))]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$refs.ruleForm.resetFields()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("重 置")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("h2", [_vm._v(_vm._s(_vm.$t("分享赚钱")))]),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("分享赚钱") } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "shareShow" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#3894FF",
                          "inactive-color": "#F26161",
                          "active-text": _vm.$t("开启"),
                          "inactive-text": _vm.$t("关闭"),
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.ruleForm.shareShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "shareShow", $$v)
                          },
                          expression: "ruleForm.shareShow",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("代理入口") } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "shareAgentShow" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#3894FF",
                          "inactive-color": "#F26161",
                          "active-text": _vm.$t("开启"),
                          "inactive-text": _vm.$t("关闭"),
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.ruleForm.shareAgentShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "shareAgentShow", $$v)
                          },
                          expression: "ruleForm.shareAgentShow",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("Facebook分享") } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "shareFacebookShow" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#3894FF",
                          "inactive-color": "#F26161",
                          "active-text": _vm.$t("开启"),
                          "inactive-text": _vm.$t("关闭"),
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.switchs.shareFacebookShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.switchs, "shareFacebookShow", $$v)
                          },
                          expression: "switchs.shareFacebookShow",
                        },
                      }),
                      _c(
                        "p",
                        [
                          _vm.switchs.shareFacebookShow
                            ? _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.shares.shareFacebookShow,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.shares,
                                        "shareFacebookShow",
                                        $$v
                                      )
                                    },
                                    expression: "shares.shareFacebookShow",
                                  },
                                },
                                _vm._l(_vm.langs, function (lang, i) {
                                  return _c(
                                    "el-checkbox",
                                    { key: lang, attrs: { label: i + 1 } },
                                    [_vm._v(_vm._s(lang))]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("Telgram分享") } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "shareTelegramShow" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#3894FF",
                          "inactive-color": "#F26161",
                          "active-text": _vm.$t("开启"),
                          "inactive-text": _vm.$t("关闭"),
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.switchs.shareTelegramShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.switchs, "shareTelegramShow", $$v)
                          },
                          expression: "switchs.shareTelegramShow",
                        },
                      }),
                      _c(
                        "p",
                        [
                          _vm.switchs.shareTelegramShow
                            ? _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.shares.shareTelegramShow,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.shares,
                                        "shareTelegramShow",
                                        $$v
                                      )
                                    },
                                    expression: "shares.shareTelegramShow",
                                  },
                                },
                                _vm._l(_vm.langs, function (lang, i) {
                                  return _c(
                                    "el-checkbox",
                                    { key: lang, attrs: { label: i + 1 } },
                                    [_vm._v(_vm._s(lang))]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("Whatsapp分享") } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "shareWhatappShow" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#3894FF",
                          "inactive-color": "#F26161",
                          "active-text": _vm.$t("开启"),
                          "inactive-text": _vm.$t("关闭"),
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.switchs.shareWhatappShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.switchs, "shareWhatappShow", $$v)
                          },
                          expression: "switchs.shareWhatappShow",
                        },
                      }),
                      _c(
                        "p",
                        [
                          _vm.switchs.shareWhatappShow
                            ? _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.shares.shareWhatappShow,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.shares,
                                        "shareWhatappShow",
                                        $$v
                                      )
                                    },
                                    expression: "shares.shareWhatappShow",
                                  },
                                },
                                _vm._l(_vm.langs, function (lang, i) {
                                  return _c(
                                    "el-checkbox",
                                    { key: lang, attrs: { label: i + 1 } },
                                    [_vm._v(_vm._s(lang))]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("分享地址"), prop: "shareAddress" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.shareAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "shareAddress", $$v)
                      },
                      expression: "ruleForm.shareAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "action-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", loading: _vm.submitting },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v(_vm._s(_vm.$t("确 定")))]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$refs.ruleForm.resetFields()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("重 置")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 12 } },
        [
          _c(
            "el-form",
            {
              ref: "validateForm",
              attrs: {
                model: _vm.smsRuleForm,
                rules: _vm.smsRules,
                "label-width": "180px",
              },
            },
            [
              _c("h2", [_vm._v(_vm._s(_vm.$t("短信配置")))]),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("短信运营商"), prop: "smsVendor" } },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm._handleSmsVendorChange },
                      model: {
                        value: _vm.smsRuleForm.smsVendor,
                        callback: function ($$v) {
                          _vm.$set(_vm.smsRuleForm, "smsVendor", $$v)
                        },
                        expression: "smsRuleForm.smsVendor",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.smsVendor,
                        attrs: { value: item.smsVendor, label: item.signname },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("权重"), prop: "sortOrder" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.smsRuleForm.sortOrder,
                      callback: function ($$v) {
                        _vm.$set(_vm.smsRuleForm, "sortOrder", $$v)
                      },
                      expression: "smsRuleForm.sortOrder",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "AppID", prop: "appid" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.smsRuleForm.appid,
                      callback: function ($$v) {
                        _vm.$set(_vm.smsRuleForm, "appid", $$v)
                      },
                      expression: "smsRuleForm.appid",
                    },
                  }),
                ],
                1
              ),
              _vm.smsRuleForm.smsVendor === "YUNZHIXUN"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Account Sid", prop: "sid" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.smsRuleForm.sid,
                            callback: function ($$v) {
                              _vm.$set(_vm.smsRuleForm, "sid", $$v)
                            },
                            expression: "smsRuleForm.sid",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Auth Token", prop: "token" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.smsRuleForm.token,
                            callback: function ($$v) {
                              _vm.$set(_vm.smsRuleForm, "token", $$v)
                            },
                            expression: "smsRuleForm.token",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "el-form-item",
                      { attrs: { label: "AppKey", prop: "appkey" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.smsRuleForm.appkey,
                            callback: function ($$v) {
                              _vm.$set(_vm.smsRuleForm, "appkey", $$v)
                            },
                            expression: "smsRuleForm.appkey",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "AppSecret", prop: "appsecret" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.smsRuleForm.appsecret,
                            callback: function ($$v) {
                              _vm.$set(_vm.smsRuleForm, "appsecret", $$v)
                            },
                            expression: "smsRuleForm.appsecret",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.smsRuleForm.smsVendor === "YIDUN"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "AppID", prop: "appid" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.smsRuleForm.appid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.smsRuleForm, "appid", $$v)
                                },
                                expression: "smsRuleForm.appid",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "el-form-item",
                          { attrs: { label: "Signname", prop: "signname" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.smsRuleForm.signname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.smsRuleForm, "signname", $$v)
                                },
                                expression: "smsRuleForm.signname",
                              },
                            }),
                          ],
                          1
                        ),
                  ],
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("模板ID"), prop: "template1" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.smsRuleForm.template1,
                      callback: function ($$v) {
                        _vm.$set(_vm.smsRuleForm, "template1", $$v)
                      },
                      expression: "smsRuleForm.template1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("模板ID"), prop: "template2" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.smsRuleForm.template2,
                      callback: function ($$v) {
                        _vm.$set(_vm.smsRuleForm, "template2", $$v)
                      },
                      expression: "smsRuleForm.template2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("模板ID"), prop: "template3" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.smsRuleForm.template3,
                      callback: function ($$v) {
                        _vm.$set(_vm.smsRuleForm, "template3", $$v)
                      },
                      expression: "smsRuleForm.template3",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "action-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", loading: _vm.smsSubmitting },
                      on: { click: _vm.handleSmsSubmit },
                    },
                    [_vm._v(_vm._s(_vm.$t("确 定")))]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$refs.validateForm.resetFields()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("重 置")))]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }