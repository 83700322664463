<template>
  <div class="home-tab">
    <hot-setting />
    <hot-setting :type="8" vt="snt" msg-type="sport" title="体育首页推荐排序" :notSeriesTag="['9999021','9999022']" />
  </div>
</template>
<script>
import hotSetting from './components/hotSetting.vue'
export default {
  name: 'HomeTab',
  componentName: 'HomeTab',
  components: { hotSetting },
  data() {
    return {

    }
  }

}
</script>

<style lang="scss">

</style>
