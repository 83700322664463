import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('体育项目'),
      key: 'sportType',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ (row.sportType === 2 ? this.$t('Expert_Plan.篮球') : (row.sportType === 1 ? this.$t('Expert_Plan.足球') : '')) }</span>)
        }
      }
    },
    {
      title: this.$t('直播地址'),
      key: 'liveUrl',
      align: 'center',
      width:200,
      component: {
        render: (h, row) => {
          return(
            <div class='liveUrlcell'>
              <a href={row.liveUrl} target='_blank'>{row.liveUrl}</a>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('直播地址编辑'),
      key: 'liveUrledit',
      align: 'center',
      width:100,
      component: {
        render: (h, row) => {
          return(
            <el-button type='primary' size='mini' onClick={() => this.eidthLiveUrl(row)}>{ this.$t('编辑') }</el-button>
          )
        }
      }
    },
    {
      title: this.$t('Interactive_Match.ForecastTab.联赛'),
      key: 'competitionName',
      align: 'center'
    },
    {
      title: this.$t('球队'),
      key: 'team',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          return (<span>{ (row.homeTeamName + ' vs ' + row.awayTeamName) }</span>)
        }
      }
    },
    {
      title: this.$t('Interactive_Match.ForecastTab.开赛时间'),
      key: 'matchTime',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.matchTime) }</span>)
        }
      }
    },
    {
      title: this.$t('状态'),
      key: 'status',
      align: 'center',
      width: 90,
      component: {
        render: (h, row) => {
          const tmp = row.sportType === 1 ? this.sportMatchStatus[row.status] : this.basketMatchStatus[row.status]
          return (tmp && <span class={`${tmp.type}-type`} >{ tmp.label }</span>)
        }
      }
    },
    {
      title: this.$t('Interactive_Match.ForecastTab.比分'),
      key: 'score',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.sportType === 1) {
            if (row.status > 1 && row.status < 9) {
              return <span>{ row.homeScore } : { row.awayScore }</span>
            } else {
              return <span class='danger-type'>-</span>
            }
          } else {
            if (row.status > 1 && row.status < 11) {
              return <span>{ row.homeScore } : { row.awayScore }</span>
            } else {
              return <span class='danger-type'>-</span>
            }
          }
        }
      }
    },
    {
      title: this.$t('上半场/下半场'),
      key: 'half',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <p>
              <span>{ row.status > 1 && row.status < 9 ? this.getScoreText(row.homeHalfScore, row.awayHalfScore) : '未' }</span>/
              <span>{ row.status > 3 && row.status < 9 ? this.getScoreText(row.homeScore, row.awayScore) : '未' }</span>
            </p>
          )
        }
      }
    },
    {
      title: this.$t('数据来源'),
      key: 'sourceType',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.sourceType) {
            return (<span>{ row.sourceType }</span>)
          } else {
            return (<span> - </span>)
          }
        }
      }
    },
    {
      title: this.$t('滚球联赛'),
      key: 'thirdCompetitionName',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.thirdCompetitionName) {
            return (<span>{ row.thirdCompetitionName }</span>)
          } else {
            return (<span> - </span>)
          }
        }
      }
    },
    {
      title: this.$t('滚球球队'),
      key: 'thirdTeam',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          if (row.thirdMatchId) {
            return (<span>{ (row.thirdHomeName + ' vs ' + row.thirdAwayName) }</span>)
          } else {
            return (<span> - </span>)
          }
        }
      }
    },
    {
      title: this.$t('是否关联'),
      key: 'isRelation',
      align: 'center',
      component: {
        render: (h, row) => {
          const disabled = row.thirdMatchId === 0 || row.thirdMatchId
          return (
            <d-switch v-model={row.isRelation} disabled={!disabled} active-value={1} inactive-value={0} onchange={() => this._handleUpdateStatus(row)}>
              <span slot='open'></span>
              <span slot='close'></span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 380,
      component: {
        render: (h, row) => {
          const { Analysis, PredictUpdate, InPlayUpdate } = this.permission

          return (
            <span>
              {/* { Status && <el-button type='danger' size='mini' disabled={row.newsStatus === 0} onClick={() => this._handleInvalid(row)}>{ this.$t('Interactive_Match.ForecastTab.无效') }</el-button> }*/}
              { Analysis && <el-button type='primary' size='mini' onClick={() => this.$refs.AnalysisDialogControl.open(row)}>{ this.$t('Interactive_Match.ForecastTab.情报分析') }</el-button> }
              { PredictUpdate && <el-button type='info' size='mini' onClick={() => this.$refs.PredictDialogControl.open(row)}>{ this.$t('Interactive_Match.ForecastTab.预测编辑') }</el-button> }
              { InPlayUpdate && <el-button type='success' size='mini' onClick={() => this.$refs.UpdateDialogControl.open(row)}>{ this.$t('Interactive_Match.ForecastTab.滚球编辑') }</el-button> }
            </span>)
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}

