import { rechargeStatus, rateTypes } from '@/libs/options'
import { filterColumns } from '@/utils'

const CanChooseColumns = function() {
  return [
    {
      title: this.$t('订单号'),
      key: 'orderNo',
      align: 'center'
    },
    {
      title: this.$t('会员信息'),
      key: 'userInfo',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div class='primary-type cursor-pointer' onClick={this._onDetails.bind(this, row)}>
              <p>{ this.$t('账号') }:{ row.memberName }</p>
              <p>{ this.$t('真实姓名') }:{ row.realName }</p>
              <p>{ row.sumRechargeTimes > 2 ? this.$t('多次充值') : row.sumRechargeTimes === 2 ? this.$t('二次充值') : this.$t('首次充值') }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('所属代理'),
      key: 'proxyCode',
      align: 'center'
    },
    {
      title: this.$t('VIP等级'),
      key: 'vipLevel',
      align: 'center',
      width: 90,
      component: {
        render: (h, row) => {
          return (<span>VIP{row.vipLevel}</span>)
        }
      }
    },
    {
      title: this.$t('充值金额'),
      key: 'amount',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span class={this.$parseColor(row.amount)}>{ row.amount }</span>)
        }
      }
    },
    {
      title: this.$t('优惠金额'),
      key: 'couponAmount',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>
            <p>{ row.couponName }</p>
            <p>{ row.couponAmount }</p>
          </span>)
        }
      }
    },
    {
      title: this.$t('赠送金额'),
      key: 'giveAmount',
      align: 'center'
    },
    {
      title: this.$t('货币汇率'),
      align: 'center',
      component: {
        render: (h, row) => {
          const rateType = rateTypes.find(e => e.key === row.rateType)
          if (rateType) {
            return (
              <div>
                <p>{ rateType.label }</p>
                <span>{ row.exchangeRate }</span>
              </div>
            )
          }
          return (
            <span>{ row.exchangeRate }</span>
          )
        }
      }
    },
    {
      title: this.$t('货币金额'),
      key: 'exchangeAmount',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span class={this.$parseColor(row.exchangeAmount)}>{ row.exchangeAmount }</span>)
        }
      }
    },
    {
      title: this.$t('转账姓名'),
      key: 'payingName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>
            <p>{ row.payingName }</p>
            { row.rechargeUrl && <el-link type='primary' onClick={() => this.$refs.ImagePreviewControl.open(row.rechargeUrl)}>查看凭证</el-link> }
          </span>)
        }
      }
    },
    {
      title: this.$t('收款信息'),
      align: 'center',
      key: 'transfer',
      component: {
        render: (h, row) => {
          return (
            <div>
              <span>{ row.receivingBank }</span>
              <p>{ row.receivingName }</p>
              <span>{ row.receivingCardNo }</span>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('订单状态'),
      key: 'status',
      align: 'center',
      width: 90,
      component: {
        render: (h, row) => {
          const tmp = rechargeStatus.find(o => o.key === row.status)
          if (tmp) {
            return (<span class={ tmp.type + '-type' }>{ tmp.label }</span>)
          }
          return ''
        }
      }
    },
    {
      title: this.$t('操作信息'),
      key: 'actionsMessage',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          if (row.status === '1111') {
            return (<p align='left'>{ this.$t('发起') }: { this.$parseTime(row.createTime) }</p>)
          }
          return (
            <div align='left'>
              <p>{ this.$t('发起') }: { this.$parseTime(row.createTime) }</p>
              <p>{ this.$t('结束') }: { this.$parseTime(row.updateTime) }</p>
              <p>{ this.$t('操作') }: { row.operatorName }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('操作说明'),
      align: 'center',
      key: 'remark'
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
