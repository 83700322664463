var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("cascader-select", {
        attrs: {
          options: _vm.playClass,
          multiple: false,
          "parent-label": _vm.$t("一级玩法"),
          "child-label": _vm.$t("二级玩法"),
        },
        on: { change: _vm.onChangeSelect },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c(
        "d-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "DTable",
          attrs: {
            "row-key": "playClassTag",
            "drag-row": _vm.permission.Sort,
            "index-row": "",
            data: _vm.items,
            columns: _vm.columns,
            options: _vm.options,
            "filter-column": _vm.filterColumn,
          },
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", plain: "" },
                  on: { click: _vm._handleSave },
                },
                [_vm._v(_vm._s(_vm.$t("全部保存")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }