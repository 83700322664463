<template>
  <div :class="wrapClasses">
    <template v-if="seriesTag === '20'">
      <p v-for="(l, i) in JSON.parse(result)" :key="i">{{ l.replace(/,/g, '-') }}</p>
    </template>
    <template v-else>
      <div v-for="(number, key) in result.split(',')" :key="key" class="number" :class="[(seriesTag === '12' || seriesTag === '13') && `number_${parseInt(number)}`, `number_${getLhcColor(number)}`]">
        <template v-if="seriesTag !== '13'">{{ number }}</template>
      </div>
    </template>
  </div>
</template>
<script>
const prefixCls = 'winning-numers'

export default {
  name: 'LotteryNumber',
  props: {
    result: {
      type: String,
      required: true
    },
    seriesTag: {
      type: String,
      required: true
    },
    lotteryId: {
      type: String,
      required: true
    }
  },
  computed: {
    wrapClasses() {
      return [
        `${prefixCls}`,
        `${prefixCls}-seriesTag-${this.seriesTag}`,
        `${prefixCls}-lotteryId-${this.lotteryId}`
      ]
    }
  },
  methods: {
    getLhcColor(num) {
      const arr = [
        {
          color: 'red',
          text: '红',
          nums: ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46']
        },
        {
          color: 'blue',
          text: '蓝',
          nums: ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48']
        },
        {
          color: 'green',
          text: '绿',
          nums: ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49']
        }]
      const result = arr.find(item => item.nums.includes(num))
      return result?.color || 'blue'
    },
    _parseArrayObject(input) {
      var result = []

      // replace leading and trailing [], if present
      input = input.replace(/^\[/, '')
      input = input.replace(/\]$/, '')

      // change the delimiter to

      // preserve newlines, etc - use valid JSON
      // https://stackoverflow.com/questions/14432165/uncaught-syntaxerror-unexpected-token-with-json-parse
      input = input.replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f')
      // remove non-printable and other non-valid JSON chars
      input = input.replace(/[\u0000-\u0019]+/g, '')

      input = input.split(',')

      input.forEach(function(element) {
        result.push(element)
      }, this)

      return result
    },
    _getNumberClass(number) {
      return `number_${parseFloat(number)}`
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: winning-numers;
$image_path: '../../assets/number_images';

.#{$prefixCls} {
  & > .number {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 14px;
    color: #fff;
    text-align: center;
  }
  & > .number {
    width: 34px;
    height: 34px;
    line-height: 34px;
    margin: 0 3px;
    border-radius: 50%;
  }
  & > .number_red {
    background-color: red;
  }
  & > .number_blue {
    background-color: blue;
  }
  & > .number_green {
    background-color: green;
  }
  &-seriesTag-11 {
    & > .number {
      background-image: url('#{$image_path}/ball_icon_common_red.png');
    }
  }
  &-seriesTag-12 {
   @for $i from 1 through 10 {
     & > .number.number_#{$i} {
        width: 34px;
        height: 34px;
        line-height: 32px;
        margin: 0 3px;
        background-image: url('#{$image_path}/pk10_#{$i}.png');
      }
    }
  }
  &-seriesTag-13 {
   @for $i from 1 through 6 {
      & > .number.number_#{$i} {
        width: 34px;
        height: 34px;
        line-height: normal;
        margin: 0 3px;
        background-image: url('#{$image_path}/k3_#{$i}.png');
      }
    }
  }
  &-seriesTag-18,
  &-seriesTag-19 {
    & > .number:not(:last-child) {
      background-image: url('#{$image_path}/ball_icon_common_blue.png');
    }
    & > .number:last-child {
      background-image: url('#{$image_path}/ball_icon_common_red.png');
    }
    &.#{$prefixCls}-lotteryId-130 {
      & > .number:nth-child(6){
        background-image: url('#{$image_path}/ball_icon_common_red.png');
      }
    }
  }

  // &-seriesTag-21 {
  //   &>.number {
  //     color: #333;
  //   }
  // }
}
</style>
