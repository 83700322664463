var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "validateForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "210px" },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("h2", [_vm._v("登录注册")]),
              _c(
                "el-form-item",
                { attrs: { label: "同IP每日注册个数", prop: "ipRegistNum" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.ipRegistNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "ipRegistNum", _vm._n($$v))
                      },
                      expression: "ruleForm.ipRegistNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "同IP每日登录个数", prop: "ipLoginNum" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.ipLoginNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "ipLoginNum", _vm._n($$v))
                      },
                      expression: "ruleForm.ipLoginNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "前端输入密码错误冻结次数",
                    prop: "passwordWrongNum",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.passwordWrongNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "passwordWrongNum", _vm._n($$v))
                      },
                      expression: "ruleForm.passwordWrongNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "后台Google验证", prop: "googleStatus" } },
                [
                  _c(
                    "d-switch",
                    {
                      model: {
                        value: _vm.ruleForm.googleStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "googleStatus", $$v)
                        },
                        expression: "ruleForm.googleStatus",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("开启"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("关闭"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("h2", [_vm._v("购彩配置")]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否允许用户撤单",
                    prop: "cancelOrderStatus",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.cancelOrderStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "cancelOrderStatus", $$v)
                        },
                        expression: "ruleForm.cancelOrderStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("启用")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "投注默认一注额度", prop: "betAmount" } },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.betAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "betAmount", _vm._n($$v))
                        },
                        expression: "ruleForm.betAmount",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("￥")])],
                    2
                  ),
                ],
                1
              ),
              _c("h2", [_vm._v("代理返点")]),
              _c(
                "el-form-item",
                { attrs: { label: "一级返佣比例", prop: "oneLevelRakeBack" } },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.oneLevelRakeBack,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "oneLevelRakeBack", $$v)
                        },
                        expression: "ruleForm.oneLevelRakeBack",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "二级返佣比例", prop: "twoLevelRakeBack" } },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.twoLevelRakeBack,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "twoLevelRakeBack", $$v)
                        },
                        expression: "ruleForm.twoLevelRakeBack",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "结算方式", prop: "settlementType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.settlementType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "settlementType", $$v)
                        },
                        expression: "ruleForm.settlementType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("人工")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("自动")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h2", [_vm._v("国家区号")]),
              _c(
                "el-form-item",
                { attrs: { label: "区号", prop: "regins" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder:
                        "区号前需加上+号，支持填入多个国家区号，用英文逗号分开，例：+86,+84",
                    },
                    model: {
                      value: _vm.ruleForm.regins,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "regins", $$v)
                      },
                      expression: "ruleForm.regins",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v("维护")]),
              _c(
                "el-form-item",
                { attrs: { label: "前端维护", prop: "maintainStatus" } },
                [
                  _c(
                    "d-switch",
                    {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.maintainStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "maintainStatus", $$v)
                        },
                        expression: "ruleForm.maintainStatus",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("开启"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("关闭"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "维护说明", prop: "maintainInfo" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      maxlength: "100",
                      "show-word-limit": "",
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.ruleForm.maintainInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "maintainInfo", $$v)
                      },
                      expression: "ruleForm.maintainInfo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("h2", [_vm._v("存取款配置")]),
              _c(
                "el-form-item",
                {
                  attrs: { label: "提款、入款开始处理时间", prop: "startTime" },
                },
                [
                  _c("el-time-select", {
                    attrs: {
                      placeholder: "开始时间",
                      "picker-options": {
                        start: "00:00",
                        step: "01:00",
                        end: "24:00",
                        maxTime: _vm.ruleForm.endTime,
                      },
                    },
                    model: {
                      value: _vm.ruleForm.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "startTime", $$v)
                      },
                      expression: "ruleForm.startTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "提款、入款结束处理时间", prop: "endTime" } },
                [
                  _c("el-time-select", {
                    attrs: {
                      placeholder: "结束时间",
                      "picker-options": {
                        start: "00:00",
                        step: "01:00",
                        end: "24:00",
                        minTime: _vm.ruleForm.startTime,
                      },
                    },
                    model: {
                      value: _vm.ruleForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "endTime", $$v)
                      },
                      expression: "ruleForm.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "提款最低额度", prop: "withdrawMinAmount" } },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.withdrawMinAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "withdrawMinAmount", $$v)
                        },
                        expression: "ruleForm.withdrawMinAmount",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("￥")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "提款最高额度", prop: "withdrawMaxAmount" } },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.withdrawMaxAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "withdrawMaxAmount", $$v)
                        },
                        expression: "ruleForm.withdrawMaxAmount",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("￥")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "2次充值时间的间隔",
                    prop: "rechargeInterval",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.rechargeInterval,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "rechargeInterval",
                            _vm._n($$v)
                          )
                        },
                        expression: "ruleForm.rechargeInterval",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("秒")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "2次提款时间的间隔",
                    prop: "withdrawInterval",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.withdrawInterval,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "withdrawInterval",
                            _vm._n($$v)
                          )
                        },
                        expression: "ruleForm.withdrawInterval",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("秒")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "提款次数只记录成功次数",
                    prop: "recordWithdrawStatus",
                  },
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 0,
                      "active-text": "开启",
                      "inactive-value": 1,
                      "inactive-text": "关闭",
                    },
                    model: {
                      value: _vm.ruleForm.recordWithdrawStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "recordWithdrawStatus", $$v)
                      },
                      expression: "ruleForm.recordWithdrawStatus",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "存款未处理记录失效时间",
                    prop: "rechargeInvalidTime",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.rechargeInvalidTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "rechargeInvalidTime", $$v)
                        },
                        expression: "ruleForm.rechargeInvalidTime",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("小时")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "提款未处理记录失效时间",
                    prop: "withdrawInvalidTime",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.withdrawInvalidTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "withdrawInvalidTime", $$v)
                        },
                        expression: "ruleForm.withdrawInvalidTime",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("小时")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "新增修改银行卡不能提款时间",
                    prop: "bankWithdrawForbid",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.ruleForm.bankWithdrawForbid,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "bankWithdrawForbid",
                            _vm._n($$v)
                          )
                        },
                        expression: "ruleForm.bankWithdrawForbid",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("分钟")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "绑定银行卡数量", prop: "bankBindNum" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.bankBindNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "bankBindNum", _vm._n($$v))
                      },
                      expression: "ruleForm.bankBindNum",
                    },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v("直播")]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "主播联系方式金额",
                    prop: "anchorContactAmount",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.anchorContactAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "anchorContactAmount", $$v)
                      },
                      expression: "ruleForm.anchorContactAmount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "广播礼物金额", prop: "broadcastGiftAmount" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.broadcastGiftAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "broadcastGiftAmount", $$v)
                      },
                      expression: "ruleForm.broadcastGiftAmount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "免费时长设置", prop: "freeTime" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "5-300" },
                      model: {
                        value: _vm.ruleForm.freeTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "freeTime", _vm._n($$v))
                        },
                        expression: "ruleForm.freeTime",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("秒")])],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }