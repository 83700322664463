<template>
  <el-dialog
    :title="$t('渠道【channelName】链接列表', { text: search.channelName })"
    :visible.sync="visible"
    width="70%"
    :close-on-click-modal="false"
    @open="handleRefresh"
    @closed="handleClosed"
  >
    <el-button type="primary" icon="el-icon-plus" style="margin-bottom: 20px" @click="onCreate">{{ $t('添加新链接') }}</el-button>
    <el-table v-loading="loading" :data="items" v-bind="options">
      <el-table-column :label="$t('链接名')" prop="lineName" align="center" width="180" />
      <el-table-column :label="$t('邀请码')" prop="lineAccno" align="center" width="100" />
      <el-table-column :label="$t('推广链接')" prop="link" align="center">
        <template slot-scope="{ row }">{{ channelHost + '?channel=' + row.lineAccno }}</template>
      </el-table-column>
      <el-table-column :label="$t('绑定概率')" prop="bindRate" align="center" width="150">
        <template slot-scope="{ row }">
          <template v-if="row.edit">
            <el-input-number v-model="row.bindRate" controls-position="right" :min="1" :max="100" size="mini" style="width: 85px;" />
          </template>
          <template v-else>
            <el-input v-model="row.bindRate" size="mini" readonly style="width: 60px; disable: inline-block" />
          </template>
          <el-button type="text" size="mini" style="margin-left: 6px" @click="onChangeRate(row)">{{ row.edit ? $t('确定') : $t('修改') }}</el-button>
        </template>
      </el-table-column>
      <el-table-column :label="$t('过滤IP')" prop="filterIp" align="center" width="90">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.filterIp"
            :active-value="1"
            :inactive-value="0"
            inactive-color="#ff4949"
            @change="value => onUpdate(row.id, { filterIp: value })"
          />
        </template>
      </el-table-column>
      <el-table-column :label="$t('过滤UDID')" prop="filterUdid" align="center" width="90">
        <template slot-scope="{ row}">
          <el-switch
            v-model="row.filterUdid"
            :active-value="1"
            :inactive-value="0"
            inactive-color="#ff4949"
            @change="value => onUpdate(row.id, { filterUdid: value })"
          />
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')" prop="actions" align="center" width="150">
        <template slot-scope="{ row }">
          <el-button type="danger" @click="onDel(row.id)">{{ $t('删除') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

  </el-dialog>
</template>
<script>

import TablePageMixin from '@/mixins/table-page-mixin'
import { getChannelLinePageList, addChannelLine, delChannelLine, editChannelLine } from '@/api/statistical'
export default {
  mixins: [TablePageMixin(getChannelLinePageList, false)],
  data() {
    return {
      filterColumn: false,
      visible: false,
      search: {},
      channelHost: ''
    }
  },
  methods: {
    open({ id: channelId, channelName, channelHost }) {
      this.search = { channelId, channelName, channelHost }
      this.channelHost = channelHost
      this.visible = true
    },
    handleClosed() {
      this.search = {}
      this.channelHost = ''
    },
    onCreate() {
      addChannelLine({
        channelId: this.search.channelId
      }).then(([_, err]) => {
        if (!err) {
          this.$message.success(this.$t('创建成功'))
          this.handleRefresh()
        }
      })
    },
    onUpdate(id, params) {
      editChannelLine({ id, ...params }).then(([_, err]) => {
        if (!err) {
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        }
      })
    },
    onChangeRate(row) {
      if (!row.edit) {
        row.edit = true
      } else {
        row.edit = false
        this.onUpdate(row.id, { bindRate: row.bindRate })
      }
    },
    onDel(id) {
      this.$confirm(`<div><p style="font-size: 18px; margin-bottom:: 20px">${this.$t('是否删除该链接')}</p><p style="color: red;">${this.$t('删除后该链接不在统计新增会员')}</p></div>`, this.$t('提示'), {
        dangerouslyUseHTMLString: true,
        center: true,
        showClose: false
      }).then(() => {
        delChannelLine(id).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationFetched({ items }) {
      this.items = items.map(o => ({ ...o, edit: false }))
    }
  }
}
</script>
