var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title + "心水标签",
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "称号", prop: "rankName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "2~4个中文" },
                model: {
                  value: _vm.ruleForm.rankName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "rankName", $$v)
                  },
                  expression: "ruleForm.rankName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "点赞数" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "minAdmire" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入整数" },
                            model: {
                              value: _vm.ruleForm.minAdmire,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "minAdmire", $$v)
                              },
                              expression: "ruleForm.minAdmire",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { span: 4 },
                    },
                    [_vm._v("~")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "maxAdmire" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入整数" },
                            model: {
                              value: _vm.ruleForm.maxAdmire,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "maxAdmire", $$v)
                              },
                              expression: "ruleForm.maxAdmire",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 取消 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 确认 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }