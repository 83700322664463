<template>
  <div class="app-container">
    <div class="filter-container">

      <el-input
        v-model.trim="search.couponId"
        maxlength="20"
        clearable
        :placeholder="$t('请输入抵用券ID')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model.trim="search.couponTitle"
        maxlength="20"
        clearable
        :placeholder="$t('请输入抵用券名称')"
        class="filter-item"
        style="width: 140px"
      />

      <el-select v-model="search.couponType" clearable :placeholder="$t('优惠类型')" class="filter-item">
        <el-option v-for="(val, key) in couponTypes" :key="key" :value="key" :label="val" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>

<script>
import { getListStatistic } from '@/api/card.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { couponTypes } from '@/libs/options'
// let search = {}
export default {
  name: 'CardStats',
  mixins: [TablePageMixin(getListStatistic)],
  data() {
    return {
      times: [],
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
      couponTypes
    }
  },
  methods: {
    onCreate(row) {
      this.$refs.EditDialogControl.open(row, this.$store.getters.tenantCode)
    },

    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
<style type="scss" scoped>
.filter-container {
  display: flex;
  gap: 8px;
}

.filter-container .filter-item+.filter-item {
  margin-left: 0;
}
</style>
