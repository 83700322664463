<template>
  <div class="app-container">
    <cascader-select
      v-model="value"
      :options="cascaderOptions"
      @change="onChange"
    />
    <div v-if="lotteryInfo" class="draw-messsge">
      <div class="filter-container">
        <span class="filter-item">{{ $t("开奖方式") }}：</span>
        <el-button
          v-if="permission.Update"
          type="danger"
          plain
          icon="el-icon-setting"
          @click="onUpdate"
          >{{ $t("修改") }}</el-button
        >
        <el-button
          v-if="permission.Revoke"
          type="primary"
          plain
          icon="el-icon-refresh-left"
          @click="onRevokeRecord"
          >{{ $t("撤销开奖记录") }}</el-button
        >
      </div>
      <div>
        {{ lotteryInfo.issue }}{{ $t("期") }} {{ $t("开奖倒计时") }}
        <d-count-down
          v-if="lotteryInfo.lotteryTime"
          :time="+lotteryInfo.lotteryTime"
          :systemTime="+lotteryInfo.systemTime"
          @finish="finish"
        >
          <template slot-scope="props"
            >{{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}</template
          >
        </d-count-down>
      </div>
    </div>

    <el-table v-loading="loading" :data="items" v-bind="options">
      <el-table-column
        prop="issue"
        :label="$t('期号')"
        width="120"
        align="center"
      />
      <el-table-column prop="result" :label="$t('开奖号码')" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.lotteryStatus === 1">{{ $t("待开奖") }}</span>
          <lottery-number
            v-if="row.lotteryStatus !== 1 && row.result"
            :series-tag="seriesTag"
            :lottery-id="row.lotteryId"
            :result="row.result"
          />
        </template>
      </el-table-column>
      <el-table-column prop="result" :label="$t('预开奖号码')" align="center">
        <template slot-scope="{ row }">
          <lottery-number
            v-if="row.manualLotteryNo"
            :series-tag="seriesTag"
            :lottery-id="row.lotteryId"
            :result="row.manualLotteryNo"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="lotteryTime"
        :label="$t('开奖时间')"
        width="160"
        align="center"
      >
        <template slot-scope="scope">{{
          scope.row.lotteryTime | parseTime
        }}</template>
      </el-table-column>
      <el-table-column
        prop="orderNum"
        :label="$t('平台投注数')"
        width="120"
        align="center"
      />
      <el-table-column
        prop="profit"
        :label="$t('平台盈利亏损')"
        width="160"
        align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="row.profit - 0 < 0" class="danger-type">{{
            row.profit
          }}</span>
          <span v-else class="info-type">{{ row.profit }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')" width="340" align="center">
        <template slot-scope="{ row }">
          <el-button
            v-if="
              operatorType === 1 &&
              row.lotteryStatus === 1 &&
              row.lotteryId == 40
            "
            type="primary"
            size="mini"
            :disabled="row.disabled"
            @click="manualOpenPrizeShowTip(row)"
            >即将开奖</el-button
          >
          <el-button
            type="primary"
            size="mini"
            v-if="seriesTag == '10'"
            @click="
              isManualMode = true;
              $refs.ManualOpenDialogControl.open(
                row,
                lotteryName,
                seriesTag,
                true
              );
            "
            >{{ $t("手动开奖") }}</el-button
          >
          <!-- <el-button
            v-if="row.lotteryStatus === 1 && ((lotteryInfo && lotteryInfo.lotteryType == 10) || row.lotteryId == 38|| row.lotteryId == 39 || row.lotteryId == 40 || row.lotteryId == 41)"
            type="primary"
            size="mini"
            @click="$refs.ManualOpenDialogControl.open(row, lotteryName, seriesTag)"
          >{{ $t('手动开奖') }}</el-button> -->
          <el-button
            v-if="
              operatorType === 1 &&
              row.lotteryStatus === 2 &&
              seriesTag !== '20'
            "
            type="warning"
            size="mini"
            @click="
              $refs.ReopenLotteryDialogControl.open(row, lotteryName, seriesTag)
            "
            >{{ $t("重新开奖") }}</el-button
          >
          <!-- <el-button
            v-if="operatorType === 1 && row.lotteryStatus === 2 && seriesTag !== '20'"
            type="warning"
            size="mini"
            @click="$refs.ReopenLotteryDialogControl.open(row, lotteryName, seriesTag)"
          >{{ $t('重新开奖') }}</el-button> -->
          <el-button
            type="warning"
            size="mini"
            v-if="row.result"
            @click="revokeLottery(row)"
            >{{ $t("撤销开奖") }}</el-button
          >
          <!-- <el-button
            v-if="isRevoke(row)"
            type="warning"
            size="mini"
            @click="revokeLottery(row)"
          >{{ $t('撤销开奖') }}</el-button> -->
          <el-button
            v-if="operatorType === 1 && row.redrawStatus === 1"
            type="danger"
            size="mini"
            >{{ $t("下载重开未扣") }}</el-button
          >
          <el-button
            v-if="
              permission.Pretreatment &&
              row.lotteryStatus === 1 &&
              lotteryInfo &&
              lotteryInfo.lotteryType != 10 &&
              seriesTag !== '20'
            "
            type="primary"
            size="mini"
            @click="
              isManualMode = false;
              $refs.ManualOpenDialogControl.open(row, lotteryName, seriesTag);
            "
            >{{ $t("预开奖") }}</el-button
          >
          <el-button
            v-if="permission.Details && operatorType === 2"
            type="primary"
            size="mini"
            @click="$refs.BetviewDialogControl.open(row)"
            >{{ $t("投注详情") }}</el-button
          >
          <el-button
            v-if="permission.Statistics"
            type="success"
            size="mini"
            @click="$refs.StatisticsDialogControl.open(row)"
            >{{ $t("统计") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />

    <LotteryMethodDialog
      ref="LotteryMethodDialogControl"
      @update="_handleUpdateLottery"
    />
    <ManualOpenDialog ref="ManualOpenDialogControl" @update="_handleManual" />
    <ReopenLotteryDialog
      v-if="operatorType === 1"
      ref="ReopenLotteryDialogControl"
      @cancelOpen="_handleCancelOpen"
      @reOpen="_handleReOpen"
    />
    <StatisticsDialog ref="StatisticsDialogControl" />
    <BetviewDialog ref="BetviewDialogControl" />
    <RevokeRecordDialog ref="RevokeRecordDialogControl" />

    <el-dialog width="400px" :visible="revokeConfirm" style="font-size: 16px">
      <span
        ><i
          class="el-icon-error"
          style="color: #f04134; margin-right: 5px"
        />确定进行{{ revokeLotteryName }}
        {{ confirmRow.issue }} 期重新开奖吗？</span
      >
      <p style="color: rgba(217, 0, 27, 0.647058823529412); margin-top: 20px">
        tips：点击确认，将会清除已开奖信息及扣回派奖金额。
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="revokeConfirm = false">取 消</el-button>
        <el-button type="primary" @click="revokeLotterySubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { param } from "@/utils";
import CascaderSelect from "@/components/CascaderSelect";
import LotteryNumber from "@/components/LotteryNumber";
import DCountDown from "@/components/DCountDown";
import TablePageMixin from "@/mixins/table-page-mixin";
import navigationMixin from "../navigationMixin";
import {
  LotteryMethodDialog,
  ManualOpenDialog,
  ReopenLotteryDialog,
  StatisticsDialog,
  BetviewDialog,
  RevokeRecordDialog,
} from "./components";
import { LOTTERY_METHODS } from "./utils";
import store from "@/store";
import {
  getLotteryNavigationList,
  getDrawLotteryList,
  getLotteryTime,
  updateLotteryInfo,
  manualOpenPrize,
  manualAddLotteryNo,
  cancelWithdraw,
  cancelIssueLottery,
  settlementIssueLottery,
  manualOpenPrizeShowTip,
  settlementedBetsCancel,
} from "@/api/lottery";

export default {
  name: "LotteryDraw",
  components: {
    CascaderSelect,
    LotteryNumber,
    LotteryMethodDialog,
    ManualOpenDialog,
    ReopenLotteryDialog,
    StatisticsDialog,
    BetviewDialog,
    [DCountDown.name]: DCountDown,
    RevokeRecordDialog,
  },
  mixins: [
    TablePageMixin(getDrawLotteryList, false),
    navigationMixin(getLotteryNavigationList),
  ],
  data() {
    return {
      isManualMode: false, //
      params: {},
      cascaderOptions: [],
      value: [],
      lotteryInfo: null,
      manualLotteryIds: [
        "583",
        "584",
        "585",
        "586",
        "592",
        "593",
        "594",
        "595",
        "597",
        "588",
        "589",
        "590",
        "591",
        "596",
      ],
      revokeConfirm: false,
      confirmRow: {},
      revokeLotteryName: "",
    };
  },
  computed: {
    ...mapGetters(["operatorType"]),
    seriesTag() {
      return this.value[0];
    },
    lotteryId() {
      return this.value[1];
    },
    lotteryName() {
      return this.cascaderOptions
        .find((e) => e.value === this.value[0])
        .children.find((e) => e.value === this.value[1]).name;
    },
    methods() {
      return LOTTERY_METHODS[this.operatorType];
    },
  },
  created() {
    this.onFetch();
  },
  methods: {
    onChange() {
      this.handleRefresh();
      this._handleFetchLotteryInfo();
    },

    onUpdate() {
      this.$refs.LotteryMethodDialogControl.open(this.value[1]);
    },

    onRevokeRecord() {
      console.log(this.cascaderOptions)
      const lotteryList = this.cascaderOptions.find(e => e.value === this.seriesTag)?.children || []
      this.$refs.RevokeRecordDialogControl.open(this.lotteryId, lotteryList);
    },

    _handleFetchLotteryInfo() {
      getLotteryTime({ lotteryId: this.value[1] }, this.operatorType).then(
        ([data, err]) => {
          if (!err) {
            this.lotteryInfo = data;
          }
        }
      );
    },

    // 修改开奖方式
    _handleUpdateLottery({ field, cancel, close }) {
      updateLotteryInfo({ ...field }, this.operatorType).then(([, err]) => {
        if (!err) {
          close();
          this.$message.success(this.$t("修改成功"));
          this._handleFetchLotteryInfo();
        } else {
          cancel();
        }
      });
    },

    manualOpenPrizeShowTip(row) {
      const params = {
        lotteryId: row.lotteryId,
        issue: row.issue,
      };
      if (this.$P$) {
        params.tenantCode = this.tenant.tenantCode;
      }
      manualOpenPrizeShowTip(params, this.operatorType).then(([data, err]) => {
        if (!err) {
          row.disabled = true;
          this.$message.success("操作成功");
        }
      });
    },

    _handleManual({ field, cancel, close }) {
      // let serviceHandle = manualOpenPrize
      // if (this.manualLotteryIds.includes(field.lotteryId)) {
      //   serviceHandle = manualAddLotteryNo
      // }
      //预开奖接口统一更改
      let serviceHandle = this.isManualMode ? manualOpenPrize : manualAddLotteryNo;
      serviceHandle({ ...field }, this.operatorType).then(([data, err]) => {
        if (!err) {
          close();
          this.$message({ type: "info", message: "成功!" });
          this.handleRefresh();
          // if (this.manualLotteryIds.includes(field.lotteryId)) {
          //   this.$message({ type: data === 1 ? 'success' : 'error', message: data === 1 ? 'success' : data.message })
          //   data === 1 && this.handleFetch()
          // } else {
          //   const { status, message } = data
          //   this.$message({ type: status === 0 ? 'success' : 'error', message })
          //   status === 0 && this.handleFetch()
          // }
        } else {
          this.$message({ type: "error", message: err.message });
          cancel();
        }
      });
    },
    _handleCancelOpen({
      field: { issue, lotteryId, lotteryNo },
      cancel,
      close,
    }) {
      cancelWithdraw({
        lotteryId,
        issue,
      }).then(([_, err]) => {
        if (!err) {
          cancelIssueLottery({
            issue,
            lotteryId,
            lotteryNo,
          }).then(([_, err]) => {
            if (!err) {
              close();
              this.$message.success(this.$t("撤销开奖成功"));
              this.getlistFunc();
            } else {
              cancel();
            }
          });
        } else {
          cancel();
        }
      });
    },
    _handleReOpen({ field: { issue, lotteryId, lotteryNo }, cancel, close }) {
      cancelWithdraw({
        lotteryId,
        issue,
      }).then(([_, err]) => {
        if (!err) {
          settlementIssueLottery({
            issue,
            lotteryId,
            lotteryNo,
          }).then(([_, err]) => {
            if (!err) {
              close();
              this.$message.success(this.$t("重新开奖成功"));
              this.getlistFunc();
            } else {
              cancel();
            }
          });
        } else {
          cancel();
        }
      });
    },
    _handleExport({ issue, lotteryId }) {
      const query = { issue, lotteryId };
      window.open(
        `${process.env.VUE_APP_BASE_API}/bets/withdraw/export?${param(query)}`
      );
    },
    implementationGetParams() {
      return { lotteryId: this.value[1] };
    },
    finish() {
      setTimeout(() => {
        this.handleRefresh();
        this._handleFetchLotteryInfo();
      }, 2000);
      // this.onChange()
    },
    isRevoke(row) {
      if (
        this.operatorType === 2 &&
        row.lotteryStatus === 2 &&
        ["37", "38", "39", "40", "41"].includes(row.lotteryId)
      ) {
        // const lotteryTime = new Date(row.lotteryTime)
        // let currentTime = new Date()
        // currentTime = currentTime.setHours(currentTime.getHours() - 2)
        // if (lotteryTime > currentTime) {
        //   return true
        // }
        return true;
      }
      return false;
    },
    revokeLottery(row) {
      this.revokeLotteryName = this.lotteryName;
      this.confirmRow = row;
      this.revokeConfirm = true;
    },
    revokeLotterySubmit() {
      settlementedBetsCancel({
        issue: this.confirmRow.issue,
        lotteryId: this.confirmRow.lotteryId,
        tenantCode: store.getters.tenantCode,
      }).then(([_, err]) => {
        if (!err) {
          this.$message.success(this.$t("撤销开奖成功"));
          this.handleRefresh();
        }
        this.revokeConfirm = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.draw-messsge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  .d-countdown {
    color: red;
    font-weight: bolder;
  }
}
</style>
