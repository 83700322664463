<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.id" maxlength="20" :placeholder="$t('订单号')" class="filter-item" style="width: 310px" clearable />
      <el-input v-model="search.memberName" maxlength="20" :placeholder="$t('会员账号')" class="filter-item" style="width: 310px" clearable />
      <el-input v-model="search.productName" maxlength="20" :placeholder="$t('商品名称')" class="filter-item" style="width: 310px" clearable />
      <el-select v-model="search.productType" :placeholder="$t('商品类型')" class="filter-item" style="width: 140px" clearable>
        <el-option v-for="item in productType" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-select v-model="search.status" :placeholder="$t('订单状态')" class="filter-item" style="width: 140px" clearable>
        <el-option v-for="item in goodsOrderStatus" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <!--发货弹窗-->
    <el-dialog title="发货" :visible.sync="dialogVisible" width="450px">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="收货地址：" :label-width="formLabelWidth" prop="deliveryAddress">
          <el-input v-model="form.deliveryAddress" :placeholder="$t('请输入收货地址')" clearable />
        </el-form-item>
        <el-form-item label="快递公司：" :label-width="formLabelWidth" prop="deliveryProvider">
          <el-input v-model="form.deliveryProvider" :placeholder="$t('请输入快递公司名称')" clearable />
        </el-form-item>
        <el-form-item label="订单编号：" :label-width="formLabelWidth" prop="deliveryOrder">
          <el-input v-model="form.deliveryOrder" :placeholder="$t('请输入订单编号')" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm('form')">确 定</el-button>
      </div>
    </el-dialog>

    <!--订单详情弹窗-->
    <DetailDialog ref="detailDialog" />
  </div>
</template>

<script>
import { goodsOrderStatus, productType } from '@/libs/options'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getDeliveryInfo, getOrderQueryList } from '@/api/points'
import GetColumns from './columns'
import DetailDialog from './DetailDialog'
import { mapState } from 'vuex'

export default {
  name: 'OrderManage',
  components: { DetailDialog },
  mixins: [TablePageMixin(getOrderQueryList)],
  data() {
    return {
      search: {},
      filterColumn: false,
      productType,
      goodsOrderStatus,
      columns: GetColumns.call(this),
      dialogVisible: false,
      form: {
        id: null,
        deliveryAddress: '',
        deliveryProvider: '',
        deliveryOrder: '',
      },
      formLabelWidth: '95px',
      rules: {
        deliveryAddress: [{ required: true, message: this.$t('请输入收货地址'), trigger: 'change' }],
        deliveryProvider: [{ required: true, message: this.$t('请输入快递公司名称'), trigger: 'change' }],
        deliveryOrder: [{ required: true, message: this.$t('请输入订单编号'), trigger: 'change' }],
      }
    }
  },
  computed: {
    ...mapState('app', [''])
  },
  created() {

  },
  methods: {
    // 点击 发货
    handleSendGoods(row) {
      this.form.id = row.id
      this.form.deliveryAddress = row.deliveryAddress
      this.form.deliveryProvider = row.deliveryProvider
      this.form.deliveryOrder = row.deliveryOrder
      this.dialogVisible = true
    },

    // 点击 发货-确定
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          getDeliveryInfo(this.form).then(() => {
            this.$message.success(this.$t('保存成功!'))
            this.handleSearch()
            this.dialogVisible = false
          })
        } else {
          return false
        }
      })
    }

  }
}
</script>
