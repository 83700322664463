<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <div class="text">会员账号:</div>
        <el-input
          v-model="search.memberName"
          class="filter-item"
          placeholder="请输入会员账号查询"
          style="width: 160px"
        />
        <div>账号类型:</div>
        <el-select
          v-model="search.accountType"
          class="filter-item"
          placeholder="选择类型"
          style="width: 140px"
          default
          clearable
        >
          <el-option label="全部" :value="null" />
          <el-option label="内部账号" :value="[1]" />
          <el-option label="普通账号" :value="[2, 3]" />
        </el-select>
        <div>游戏类型(大):</div>
        <el-select
          v-model="search.bigPlayType"
          class="filter-item"
          placeholder="选择类型"
          style="width: 140px"
          clearable
        >
          <el-option label="全部" :value="null" />
          <el-option
            v-for="(item, index) in bigPlayType"
            :label="item.label"
            :value="item.value"
            :key="index"
          />
        </el-select>
        <div>推荐类型:</div>
        <el-select
          v-model="search.recommendType"
          class="filter-item"
          placeholder="选择类型"
          style="width: 140px"
          clearable
        >
          <el-option label="智能推荐" :value="1" />
          <el-option label="运营配置" :value="2" />
        </el-select>
      </div>
    </div>
    <div class="filter-container">
      <MyDatePicker v-model="times" :handleSetTimes="handleSetTimes" :isShortcutShow="false"/>
    </div>
    <div class="filter-container buttonLine">
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleSearch"
      >
        查询
      </el-button>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-refresh-right"
        @click="resetSearch"
        >{{ $t("重置") }}</el-button
      >
      <el-button
        v-if="permission.Add"
        class="filter-item"
        type="success"
        icon="el-icon-plus"
        @click="createData"
        >{{ $t("新建") }}</el-button
      >
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />

    <!-- 弹窗 -->
    <el-dialog :title="dialogText" :visible.sync="dialogFormVisible">
      <el-form :model="dialogForm" ref="ruleForm" :rules="rules">
        <el-form-item label="会员账号" label-width="120px" prop="memberName">
          <el-input
            placeholder="请输入会员账号查询"
            v-model="dialogForm.memberName"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearchMemeber"
            ></el-button>
          </el-input>
          <div v-if="isSearchUserResultShow">
            <el-table :data="searchUserResult" style="width: 100%">
              <el-table-column prop="memberName" label="会员账号">
              </el-table-column>
              <el-table-column prop="nickName" label="会员昵称">
              </el-table-column>
              <!-- <el-table-column
                prop="guruType"
                label="账号类型"
                :formatter="
                  (row, column) => {
                    switch (row.guruType) {
                      case 1:
                        return '内部账号';
                      case 2:
                        return '普通账号';
                      case 3:
                        return '普通账号';
                      default:
                        return '';
                    }
                  }
                "
              >
              </el-table-column> -->
            </el-table>
          </div>
        </el-form-item>
        <el-form-item label="游戏类型" label-width="120px" prop="bigPlayType">
          <el-select
            v-model="dialogForm.bigPlayType"
            class="filter-item"
            placeholder="选择类型"
            style="width: 140px"
            clearable
          >
            <el-option
              v-for="(item, index) in bigPlayType"
              :label="item.label"
              :value="item.value"
              :key="index"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="前端排序" label-width="120px" prop="sortNum">
          <span>请输入排序序号，由大到小优先排列</span>
          <el-input-number
            v-model="dialogForm.sortNum"
            class="input-with-select"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label-width="120px">
          <el-button type="primary" @click="handleSubmitDialog"
            >确 定
          </el-button>
          <el-button @click="handleCloseDialog"> 取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import TablePageMixin from "@/mixins/table-page-mixin";
import GetColumns from "./columns";
import {
  guruManageList,
  guruRecommendADD,
  guruRecommendDelete,
  getGuruList,
} from "@/api/orderManage";
import { getAllUsersByCondition } from "@/api/member";
import { parseTime } from "@/utils";
import MyDatePicker from "@/components/MyDatePicker";
import { getDefaultTimes } from "@/utils";
import permission from "@/store/modules/permission";

export default {
  name: "RecMaster",
  componentName: "RecMaster",
  components: {
    MyDatePicker,
  },
  mixins: [TablePageMixin(guruManageList)],
  data() {
    return {
      bigPlayType: this.$t("bigPlayType"),
      times: getDefaultTimes("timestamp", 0, 0, true),
      columns: GetColumns.call(this),
      filterColumn: false,
      selection: [],
      tableData: {
        pageSize: 0,
      },
      search: {
        recommendType:2
      },
      dialogFormVisible: false,
      dialogType: null,
      dialogForm: {},
      isSearchUserResultShow: false,
      searchUserResult: {},
      rules: {
        memberName: [
          { required: true, message: "请输入会员账号", trigger: "blur" },
        ],
        bigPlayType: [
          { required: true, message: "请选择游戏类型", trigger: "blur" },
        ],
        sortNum: [
          { required: true, message: "请输入前端排序", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    dialogText() {
      switch (this.dialogType) {
        case "CREATE":
          return "新建";
        case "UPDATE":
          return "修改";
        default:
          break;
      }
    },
    dialogText2() {
      switch (this.searchUserResult.guruType) {
        case 1:
          return "内部账号";
        case 2:
          return "普通账号";
        case 3:
          return "普通账号";
        default:
          return "";
      }
    },
  },
  methods: {
    implementationGetParams() {
      return this.formatIntervalTime(false, "beginTime", "endTime");
    },
    handleCloseDialog() {
      this.dialogType = null;
      this.isSearchUserResultShow = false;
      this.dialogFormVisible = false;
      this.searchUserResult = {};
      this.dialogForm = {};
    },
    handleSearchMemeber() {
      if (!this.dialogForm.memberName) {
        this.$message.warning("请输入查询会员账号");
        return;
      }
      let requestData = {
        memberName: this.dialogForm.memberName,
      };
      getGuruList(requestData).then(([data, err]) => {
        if (!err) {
          if (Array.isArray(data) && data.length) {
            this.searchUserResult = data;
            this.isSearchUserResultShow = true;
          } else {
            this.isSearchUserResultShow = false;
            this.searchUserResult = data;
            this.$message.error("不存在该会员");
          }
        }
      });
    },
    createData() {
      this.dialogType = "CREATE";
      this.isSearchUserResultShow = false;
      this.dialogFormVisible = true;
    },
    handleUpdate(row) {
      this.dialogType = "UPDATE";
      this.isSearchUserResultShow = false;
      let { id, guruId, bigPlayType, memberName } = row;
      this.dialogForm = { id, guruId, bigPlayType, memberName };
      this.dialogFormVisible = true;
    },
    handleDelete(row) {
      this.$confirm("确定删除吗？", this.$t("系统提示"), {
        type: "warning",
      })
        .then(() => {
          guruRecommendDelete({ id: row.id }).then(([data, err]) => {
            if (!err) {
              this.$message.success(this.$t("删除成功"));
              this.handleFetch();
            }
          });
        })
        .catch(() => {});
    },
    handleSubmitDialog() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (!valid) {
          this.$message.warning(this.$t("请输入必填项"));
          return false;
        } else {
          let requestData = {
            ...this.dialogForm,
          };
          if (this.dialogType == "CREATE") {
            let guruId = this.searchUserResult?.[0]?.memberId;
            if (guruId) {
              requestData.guruId = guruId;
            } else {
              const [items, err, res] = await getAllUsersByCondition({
                memberName: this.dialogForm.memberName,
              });
              if (!err) {
                if (Array.isArray(items) && items.length) {
                  requestData.guruId = items?.[0]?.memberId;
                } else {
                  this.$message.error("不存在该会员");
                  return;
                }
              }
            }
            guruRecommendADD({ recommendType: 2, ...requestData }).then(
              ([data, err]) => {
                if (!err) {
                  this.$message.success("新增成功");
                  this.handleCloseDialog();
                  this.handleFetch();
                }
              }
            );
          } else if (this.dialogType == "UPDATE") {
            guruRecommendADD({ recommendType: 2, ...requestData }).then(
              ([data, err]) => {
                if (!err) {
                  this.$message.success("修改成功");
                  this.handleCloseDialog();
                  this.handleFetch();
                }
              }
            );
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-container-item {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  font-size:12px;
  div {
    flex-shrink: 0;
  }
}
.buttonLine {
  display: flex;
  /* justify-content: end; */
}
</style>
