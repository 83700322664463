var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { clearable: "", placeholder: _vm.$t("请选择游戏类型") },
              on: { change: _vm.handleSearch },
              model: {
                value: _vm.search.gameType,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "gameType", $$v)
                },
                expression: "search.gameType",
              },
            },
            _vm._l(_vm.defaultGameType, function (item, key) {
              return _c("el-option", {
                key: key,
                attrs: { label: item, value: key },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                type: "primary",
                plain: "",
                disabled: _vm.selection.length === 0,
              },
              on: { click: _vm.batchUpdate },
            },
            [_vm._v(_vm._s(_vm.$t("批量修改")))]
          ),
        ],
        1
      ),
      _c(
        "d-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "DTable",
          attrs: {
            data: _vm.items,
            options: _vm.options,
            "filter-column": _vm.filterColumn,
            "selection-row": "",
            columns: _vm.columns,
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("template", { slot: "left" }, [
            _vm.permission.Switch
              ? _c(
                  "span",
                  { staticClass: "total-switch" },
                  [
                    _vm._v(_vm._s(_vm.$t("返水总开关")) + "： "),
                    _c(
                      "d-switch",
                      {
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          "active-color": "#3894FF",
                          "inactive-color": "#F26161",
                          "before-change": _vm.totalSwitchChange,
                        },
                        model: {
                          value: _vm.totalSwitch,
                          callback: function ($$v) {
                            _vm.totalSwitch = $$v
                          },
                          expression: "totalSwitch",
                        },
                      },
                      [
                        _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                          _vm._v(_vm._s(_vm.$t("启用"))),
                        ]),
                        _c(
                          "span",
                          { attrs: { slot: "close" }, slot: "close" },
                          [_vm._v(_vm._s(_vm.$t("停用")))]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }