<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <div class="text">会员账号:</div>
        <el-input
          v-model="search.memberName"
          class="filter-item"
          placeholder="请输入会员账号查询"
          style="width: 160px"
        />
        <div class="text">评论内容:</div>
        <el-input
          v-model="search.content"
          class="filter-item"
          placeholder="请输入评论内容查询"
          style="width: 160px"
        />
        <div class="text">推单订单号:</div>
        <el-input
          v-model="search.planOrderNo"
          class="filter-item"
          placeholder="请输入推单订单号查询"
          style="width: 160px"
        />
        <div class="text">推单会员账号:</div>
        <el-input
          v-model="search.planMemberName"
          class="filter-item"
          placeholder="请输入推单会员账号查询"
          style="width: 160px"
        />
        <div>游戏类型:</div>
        <el-select
          v-model="search.smallPlayType"
          class="filter-item"
          placeholder="选择类型"
          style="width: 140px"
          clearable
        >
          <el-option label="全部" :value="null" />
          <el-option
            v-for="(item, index) in smallPlayType"
            :label="item.label"
            :value="item.value"
            :key="index"
          />
        </el-select>
        <div class="text">方案宣言:</div>
        <el-input
          v-model="search.planSpeech"
          class="filter-item"
          placeholder="请输入方案宣言查询"
          style="width: 160px"
        />
      </div>
    </div>
    <div class="filter-container">
       <MyDatePicker v-model="times" :handleSetTimes="handleSetTimes" :isShortcutShow="false" :isRequired="true"/>
    </div>
    <div class="filter-container buttonLine">
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleSearch"
      >
        查询
      </el-button>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-refresh-right"
        @click="resetSearch"
        >{{ $t("重置") }}</el-button
      >
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />
    <!-- 弹窗 -->
  </div>
</template>
<script>
import TablePageMixin from "@/mixins/table-page-mixin";
import GetColumns from "./columns";
import {
  guruOrderCommentList,
  guruOrderCommentDelete,
} from "@/api/orderManage";
import { parseTime } from "@/utils";
import MyDatePicker from "@/components/MyDatePicker";
import { getDefaultTimes } from "@/utils";
import { guru_order_list } from "@/mockData.js";
export default {
  name: "OrderReviews",
  componentName: "OrderReviews",
  components: {
    MyDatePicker,
  },
  mixins: [TablePageMixin(guruOrderCommentList)],
  data() {
    return {
      smallPlayType:this.$t('smallPlayType'),
      times: getDefaultTimes("timestamp", 0, 0, true),
      columns: GetColumns.call(this),
      filterColumn: false,
      selection: [],
      tableData: {
        pageSize: 0,
      },
      search: {
      },
      dialogFormVisible: false,
      dialogType: null,
      dialogForm: {
        applyDesc: "",
      },
      guru_order_list: guru_order_list,
      isCheckDetailsShow: false,
      rowData: {},
    };
  },
  computed: {
    isFollowList() {
      return this.$route.path == "/OrderManage_FollowList";
    },
    dialogText() {
      switch (this.dialogType) {
        case 1:
          return "确认审核通过吗？";
        case 2:
          return "确认审核拒绝吗？";
        case 3:
          return "确认取消大神推单资格吗？";
        default:
          break;
      }
    },
    dialogText2() {
      switch (this.dialogType) {
        case 2:
          return "拒绝原因";
        case 3:
          return "取消原因";
        default:
          break;
      }
    },
  },
  methods: {
    implementationGetParams() {
      return this.formatIntervalTime(false, "beginTime", "endTime");
    },
    handleDelete(row) {
      this.$confirm("确定删除评论内容吗？", this.$t("系统提示"), {
        type: "warning",
      })
        .then(() => {
          guruOrderCommentDelete({ id: row.id }).then(([data, err]) => {
            if (!err) {
              this.$message.success(this.$t("删除成功"));
              this.handleFetch();
            } else {
              // this.$message.error("操作失败");//createService统一处理错误显示服务器msg
            }
          });
        })
        .catch(() => {});
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.filter-container-item {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  font-size:12px;
  div {
    flex-shrink: 0;
  }
}
.buttonLine {
  display: flex;
  /* justify-content: end; */
}
</style>
