<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="daterange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetDate($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 7)">{{ $t('_7天') }}</el-button>
      <el-input v-model="search.agencyName" clearable :placeholder="$t('所属上级')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.registerAddress" clearable :placeholder="$t('注册地区')" class="filter-item" style="width: 140px" />
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      @refresh="handleFetch"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getRegisterReportList } from '@/api/statistical'
import { getDefaultTimes } from '@/utils'
export default {
  name: 'RegisterStatistical',
  mixins: [TablePageMixin(getRegisterReportList)],
  data() {
    return {
      times: getDefaultTimes('n'),
      search: {},
      filterColumn: false,
      columns: GetColumns.call(this),
      statistical: null
    }
  },
  methods: {
    implementationGetParams() {
      return this.formatIntervalTime()
    }
  }
}
</script>
