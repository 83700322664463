<template>
  <el-dialog :title="$t('修改密码')" width="30%" :visible.sync="visible" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('用户账号')">
        <p>{{ account }}</p>
      </el-form-item>
      <el-form-item :label="$t('密码类型')" prop="pwdType">
        <el-select v-model="ruleForm.pwdType" style="width: 100%;">
          <el-option :label="$t('登录密码')" :value="0" />
          <el-option :label="$t('提款密码')" :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('新密码')" prop="newPwd" required>
        <el-input v-model="ruleForm.newPwd" type="password" />
      </el-form-item>
      <el-form-item :label="$t('确认密码')" prop="confirmNewPwd" required>
        <el-input v-model="ruleForm.confirmNewPwd" type="password" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { validAccountAndPassword } from '@/utils/validate'
const getDefaultForm = () => ({
  pwdType: 0,
  newPwd: '',
  confirmNewPwd: ''
})
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入密码')))
      } else {
        if (this.ruleForm.pwdType === 0) {
          if (!validAccountAndPassword(value)) {
            callback(new Error(this.$t('请输入6-12位数字字母组合密码')))
          }
        } else {
          if (!/^\d{6}$/.test(value)) {
            callback(new Error(this.$t('请输入6位数字提款密码')))
          }
        }

        if (this.ruleForm.confirmNewPwd !== '') {
          this.$refs.validateForm.validateField('confirmNewPwd')
        }
        callback()
      }
    }
    const validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请再次输入密码')))
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error(this.$t('两次输入密码不一致')))
      } else {
        callback()
      }
    }

    return {
      account: '',
      emitName: 'update',
      rules: {
        newPwd: [
          { validator: validatePass, trigger: 'blur' }
        ],
        confirmNewPwd: [
          { validator: validateConfirmPass, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open({ memberName, memberId }) {
      this.account = memberName
      this.ruleForm.memberId = memberId
      this.visible = true
    }
  }
}
</script>
