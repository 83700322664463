const CanChooseColumns = function() {
  return [
    {
      title: this.$t('活动ID'),
      key: 'id',
      align: 'center'
    },
    {
      title: '活动排序',
      key: 'sort',
      align: 'center'
    },
    {
      title: this.$t('活动标题'),
      key: 'activityTitle',
      align: 'center'
    },
    {
      title: this.$t('活动BANNER'),
      key: 'url',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image src={ row.url } lazy></el-image>)
        }
      }
    },
    {
      title: this.$t('活动分类'),
      key: 'type',
      align: 'center',
      component: {
        render: (h, row) => {
          const activityType = this.activityCategory.find(e => e.id === row.type)
          if (activityType) {
            const vnodes = []
            activityType.typeNameLang.split(',').forEach(e => {
              const typeLang = e.split(':')[0]
              const typeName = e.split(':')[1]
              const lang = this.allLang.find(v => v.lang === typeLang)
              if (lang) {
                vnodes.push(<div>{ this.$t(lang.name) + ':' + typeName }</div>)
              }
            })
            return (<span>{ vnodes }</span>)
          }
          return ''
        }
      }
    },
    {
      title: this.$t('内页BANNER'),
      key: 'remark',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.remarkType === 0) {
            return (<el-image src={ row.remark } lazy></el-image>)
          } else {
            return ''
          }
        }
      }
    },
    {
      title: this.$t('活动引导'),
      key: 'activityContent',
      align: 'center'
    },
    {
      title: this.$t('语言'),
      key: 'lang',
      align: 'center',
      component: {
        render: (h, row) => {
          let langArray = []
          if (row.lang) {
            const allLang = this.$store.getters.allLang
            row.lang.split(',').forEach(e => {
              const lang = allLang.find(v => v.lang === e)
              if (lang) {
                langArray.push(lang.name)
              }
            })
          }
          return (<span>{ langArray.toString() }</span>)
        }
      }
    },
    {
      title: this.$t('跳转页面'),
      key: 'menuName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.menuName || row.jumpUrl }</span>)
        }
      }
    },
    {
      title: this.$t('活动时段'),
      key: 'activityDate',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.activityTimeType === 0) {
            return (<span>{ this.$parseTime(row.activityBeginDate, '{y}-{m}-{d}') }~{ this.$parseTime(row.activityEndDate, '{y}-{m}-{d}') }</span>)
          } else {
            return (<span>{ this.$t('持续开启') }</span>)
          }
        }
      }
    },
    {
      title: this.$t('活动状态'),
      key: 'activityStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          const status = this.activityStatus.find(o => o.key === row.activityStatus)
          return (<span style={{ color: row.activityStatus === 0 ? '#34C758' : '' }}>{ status ? status.label : '' }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 270,
      component: {
        render: (h, row) => {
          const { Update, Delete, Close } = this.permission

          return (

            <div>
              { Close && row.activityStatus !== 1 &&
                <el-button type='danger' size='small' icon='el-icon-close' onClick={this.handleCloseActivity.bind(this, row, 0)}>{ this.$t('关闭') }</el-button> ||
                <el-button type='success' size='small' icon='el-icon-check' onClick={this.handleCloseActivity.bind(this, row, 1)}>{ this.$t('开启') }</el-button>
              }
              { Update && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.ActionDialogControl.open(row) } }>{ this.$t('修改') }</el-button> }
              { Delete && <el-button type='danger' size='small' icon='el-icon-delete' onClick={() => this.handleDeleteActivity(row)}>{ this.$t('删除') }</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
