<template>
  <el-dialog
    :title="awardName || '编辑'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="400px"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" label-suffix=":" :model="ruleForm">
      <el-form-item
        :label-width="item.labelWidth || '140px'"
        :label="item.label"
        :prop="item.prop"
        v-for="(item, key) in formItems"
        :rules="getFormItemRules(item.prop)"
        :key="item.prop + key"
      >
        <div v-if="item.html === 'span'" :class="item.class">
          <span>{{ item.prefix }}</span
          >{{ setSpanCont(item) }} <span>{{ getUnit(item.unit) }}</span>
        </div>
        <el-input
          v-else-if="item.html === 'input'"
          v-model="ruleForm[item.prop]"
          :placeholder="item.placeholder"
        />
        <el-switch
          v-else-if="item.html === 'switch'"
          v-model="ruleForm[item.prop]"
        ></el-switch>
        <el-radio-group
          v-model="ruleForm[item.prop]"
          v-else-if="item.html === 'radio'"
        >
          <el-radio label="1">增加</el-radio>
          <el-radio label="2">减少</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t("关闭") }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t("保存") }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from "@/mixins/form-dialog";
import formItemsConfig from "./formItemsConfig";
import { queryAbpayConfigInfo } from "@/api/abpay";
import { queryUserBorrowInfo } from "@/api/vipRewardMange";
import { getAppMemberInfo } from "@/api/member";
import store from "@/store";
const getDefaultForm = () => ({
  status: "",
  content: "",
  memberId: "",
  awardCode: "",
  abWithdrawStatus: "",
  addorminus: "",
});

const validateOptionalPositiveInteger = (rule, value, callback) => {
  if (!value) {
    callback();
    return;
  }

  const num = Number(value);
  if (isNaN(num) || num <= 0 || !Number.isInteger(num)) {
    callback(new Error("请输入一个正整数"));
    return;
  }

  callback();
};
export default {
  props: {
    rowAward: { type: String },
  },
  mixins: [FormDialogMixin(getDefaultForm)],

  data() {
    return {
      emitName: "update",
      row: {},
      details: {},
      awardCode: "",
      awardName: "",
      rules: {
        status: [{ required: true, trigger: "blur" }],
        content: [
          { validator: validateOptionalPositiveInteger, trigger: "blur" },
        ],
      },
      formItems: [],
    };
  },
  methods: {
    getFormItemRules(prop) {
      return this.rules[prop] || [];
    },
    getFormItems(key) {
      this.formItems = formItemsConfig(this)[key];
    },
    getUnit(unit) {
      const uenum = {
        1: `(VIP${this.details.vipLevel}）`,
        2: `(VIP${this.details.vipLevel + 1}）`,
        3: `倍(VIP${this.details.vipLevel}）`,
      };
      return uenum[unit] ? `${uenum[unit]}` : "";
    },
    setSpanCont(item) {
      if (
        this.awardCode !== "WITHDRAWAL_LIMIT" ||
        !String(this.details[item.prop] || "").includes("-1")
      ) {
        return this.details[item.prop];
      }
      return "不限制";
    },
    async getAbpayConfigInfo() {
      const params = {
        vipLevel: this.details.vipLevel,
        tenantCode: store.getters.tenantCode,
      };
      const [data] = await queryAbpayConfigInfo(params);
      this.$set(this.details, "filed1", data?.vipWithdrawRequest?.vipAmount); //每日最高可提
      this.$set(this.details, "filed2", data?.withdrawRequest?.vipAmount); //ABpay每日最高可提
    },
    async getborrowRepayRecordCount() {
      const params = {
        vipLevel: this.details.vipLevel,
        tenantCode: store.getters.tenantCode,
        memberId: this.details.memberId,
      };
      const [data] = await queryUserBorrowInfo(params);
      const fileds = [
        "remainAmount",
        "remainRepayAmount",
        "finalAmount"
      ];
      fileds.forEach((k) => {
        this.$set(this.details, k, data[k]);
      });
    },
    async getAppMemberInfo() {
      const params = {
        memberId: this.details.memberId,
        tenantCode: store.getters.tenantCode,
      };
      const [data] = await getAppMemberInfo(params);
      this.$set(
        this.details,
        "text",
        `升级到下一还需${data.nextLevelDifferenceRechargeAmount}存款，还需${data.nextLevelDifferencePlayAmount}有效投注。`
      );
    },
    open(row, key, details) {
      console.log("row", row, key, details);
      this.getFormItems(key);
      this.row = row;
      this.awardCode = key;
      this.awardName = row?.[key]?.name;
      const transformDetails = {
        ...details,
        status: details.status === 0 ? true : false,
        abWithdrawStatus: details.abWithdrawStatus === 0 ? true : false,
        addorminus: "1",
        changeQuota: details.content,
        content: "",
      };
      this.details = transformDetails;
      console.log("transformDetails", transformDetails);
      Object.keys(this.ruleForm).forEach((k) => {
        this.$set(this.ruleForm, k, transformDetails[k]);
      });
      if (key === "UPGRADE_BOUNS") {
        this.$set(this.details, "vipLevel", row.vipLevel);
        this.getAppMemberInfo();
      }
      if (key === "WITHDRAWAL_LIMIT") {
        this.getAbpayConfigInfo();
      }
      if (key === "BORROW_MONEY") {
        this.getborrowRepayRecordCount();
      }
      if (key === "POINTS_COLLECTION") {
        this.$set(
          this.details,
          "text",
          `当前只控制充值投注的积分领取，不包含活动中心的积分奖励`
        );
      }
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-uploader {
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }

  .uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 105px;
    height: 45px;
    line-height: 45px;
    text-align: center;
  }

  .icon {
    width: 105px;
    height: 45px;
    display: block;
  }
}
.red-text {
  color: red;
}
</style>
