<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <span>会员账号：</span>
        <el-input
          v-model="search.memberName"
          clearable
          :placeholder="$t('会员账号')"
          class="filter-item"
          style="width: 160px; margin-right: 20px"
        />
        <span>VIP等级：</span>
        <el-select
          v-model="search.vipLevel"
          clearable
          :placeholder="$t('VIP等级')"
          class="filter-item"
          style="width: 160px"
        >
          <el-option
            v-for="item in vipLevels"
            :key="item.vipLevel"
            :label="`VIP${item.vipLevel}`"
            :value="item.vipLevel"
          />
        </el-select>
        <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          >{{ $t("查询") }}</el-button
        >
        <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-refresh-right"
          @click="resetSearch"
          >{{ $t("重置") }}</el-button
        >
      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="computedItems"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      row-key="memberId"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
      @selection-change="handleSelectionChange"
    >
      <!-- <template #right>
        <el-button v-if="permission.Register" class="filter-item" type="primary"
          @click="$refs.RegisterDialogControl.open()">注册内部账号</el-button>
        <el-button plain type="primary" class="filter-item" icon="el-icon-download" @click="_handleExport">{{
          $t('导出Excel') }}</el-button>
      </template> -->
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />
    <UserDialog ref="UserDialogControl" />
    <ActionDialog
      ref="ActionDialogControl"
      @update="handleUpdateLevel"
      :curRowAward="rowAward"
    />
  </div>
</template>
<script>
import md5 from "js-md5";
import UserDialog from "@/views/components/UserDialog";
import TablePageMixin from "@/mixins/table-page-mixin";
import GetColumns from "./columns";
import { getAllVipLevels } from "@/api/app";
import { getDefaultTimes, exportExcel } from "@/utils";
import {
  getAppAward,
  getVIPAwards,
  getMemberAwardsDetails,
  getMemberAwards,
  updateMemberAwards,
  
} from "@/api/member";
import ActionDialog from "./ActionDialog.vue";
import { file } from "jszip/lib/object";
import { rewardTypes } from "@/views/vipRewardMange/constant";

export default {
  name: "MemberUsers",
  components: {
    UserDialog,
    ActionDialog,
  },
  mixins: [TablePageMixin(getMemberAwards)],
  data() {
    return {
      allRewardList: [],
      selection: [],
      columns: [],
      rowAward: "",
      defaultColumns: [
        {
          title: this.$t("会员账号"),
          key: "memberName",
          align: "center",
          component: {
            render: (h, row) => {
              return (
                <div>
                  <p
                    class="primary-type cursor-pointer hover-underline"
                    onClick={this._onDetails.bind(this, row)}
                  >
                    {row.memberName}
                  </p>
                </div>
              );
            },
          },
        },

        {
          title: this.$t("VIP等级"),
          key: "level",
          align: "center",
          width: 100,
          component: {
            render: (h, row) => {
              return (
                <div class="">
                  <span>VIP{row.vipLevel}</span>
                </div>
              );
            },
          },
        },
      ],
      memberTypes: this.$t("memberTypes"),
      keywordsMaps: this.$t("keywordsMaps"),
      statistics: {},
      times: getDefaultTimes("timestamp", 0, 0, false),
      search: {},
      vipLevels: [],
      areaCode: "",
      keyWord: "",
      vipLevelConfigs:{},
      currentRow: {},
    };
  },
  computed: {
    computedItems() {
      let arr = this.items.map((item) => {
        let obj = { ...item };
        if (Array.isArray(item?.awardList)) {
          item.awardList.forEach((element) => {
            if (element?.awardCode) {
              obj[element.awardCode] = {
                name: this.allRewardList.find(
                  (item) => item.awardCode == element.awardCode
                )?.name,
                ...element,
              };
            }
          });
        }
        return obj;
      });
      console.log("arr", arr);
      return arr;
    },
  },
  mounted() {
    this.implementationBeforeFetch();
    this.getVipLevelConfig()
  },

  methods: {
    async getVipLevelConfig(){
    const [data] =await getVIPAwards({currentPage: 1, pageSize: 50})
    this.vipLevelConfigs = data
    
    },
    handleUpdateLevel({ field, cancel, close }) {
      console.log("handleUpdate filed", field);
      let content = field.content?`${field.addorminus==2?(file.content<=0?'':'-'):(field.content>=0? '':'+')}${field.content}`:''
      // content = content===''?this.currentRow[this.rowAward]?.content:content
      const params = {
        memberId: field.memberId,
        awardCode: field.awardCode,
        status: field.status ? "0" : "1",
        abWithdrawStatus: field.abWithdrawStatus ? "0" : "1",
        abWithdrawStatus: field.abWithdrawStatus ? "0" : "1",
      };
      if(content) params.content = content
      updateMemberAwards(params).then(([_, err]) => {
        if (!err) {
          close();
          this.$message.success("修改成功");
          this.handleRefresh();
        } else {
          cancel();
        }
      });
    },
    handleSearch() {
      console.log("handleSearch", this.search);
      this.handleRefresh();
    },

    handleSelectionChange(selection) {
      this.selection = selection;
    },

    _onDetails({ memberId }) {
      // if (!this.permission.Details) return
      this.$refs.UserDialogControl.open(memberId);
    },

    _onSend({ memberName }) {
      if (!this.permission.Send) return;
      this.$refs.SendDialogDialogControl.open(memberName);
    },
    setTableContent(row, item) {
      
      const enableStr = `${
        row?.[item.awardCode]?.status == 0 ? "启用" : "禁用"
      } `;
      const defaultStr = `奖励${row?.[item.awardCode]?.totalAmount}`;
      const mapObj = {
        [rewardTypes[3]]: `${enableStr} 可用额度${
          row?.[item.awardCode]?.totalAmount
        }`,
        [rewardTypes[4]]: `${enableStr} 返水${
          row?.[item.awardCode]?.totalAmount
        }倍`,
        [rewardTypes[5]]: `普通提现：${enableStr} AB提现：${
          row?.[item.awardCode]?.abWithdrawStatus == 0 ? "启用" : "禁用"
        }`,
        [rewardTypes[6]]: `${enableStr}${row?.[item.awardCode]?.totalAmount}`,
        [rewardTypes[7]]: `${enableStr} 领取积分${
          row?.[item.awardCode]?.totalAmount
        }倍`,
        [rewardTypes[8]]:enableStr
      };
      return mapObj[item.awardCode] || `${enableStr}${defaultStr}`;
    },
    implementationBeforeFetch() {
      getAllVipLevels().then(([data, err]) => {
        if (!err) {
          this.vipLevels = data;
        }
      });
      getAppAward({ currentPage: 1, pageSize: 50 }).then(([data, err]) => {
        if (!err) {
          console.log("getAppAward", data);
          this.allRewardList = data.filter((item) =>
            rewardTypes.includes(item.awardCode)
          );
          let arr = this.allRewardList.map((item) => {
            return {
              title: item.name,
              key: item.awardCode,
              align: "center",
              component: {
                render: (h, row) => {
                  return (
                    <div>
                      {<p>{this.setTableContent(row, item)}</p>}
                      {
                        <i
                          class="el-icon-edit primary-type cursor-pointer"
                          onClick={() => this.handleEdit(row, item.awardCode)}
                        />
                      }
                    </div>
                  );
                },
              },
            };
          });
          this.columns = [...this.defaultColumns, ...arr];
          console.log("columns", this.columns);
        }
      });
    },

    implementationGetParams() {
      return this.search
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys);
    },
    isConfig(row,key){
      if(key==="ACTIVITY_CENTER") return true // 活动中心配置项，默认返回 
      let vipLevel = key==="UPGRADE_BOUNS"?row.vipLevel+1:row.vipLevel
       const awardList = this.vipLevelConfigs.find((item) => item.vipLevel == vipLevel)?.awardList
       if(!awardList) return false
       const awardItem = awardList.find((item) => item.awardCode == key)
       return !!awardItem
    },
    handleEdit(row, key) {
      if(!this.isConfig(row,key)) return this.$message.warning(`VIP${row.vipLevel}未配置该项，请先配置！`)
      this.currentRow = row
      this.rowAward = key;
      getMemberAwardsDetails({
        memberId: row.memberId,
        awardCode: key,
      }).then(([data, err]) => {
        if (!err) {
          console.log("handleEdit row", row, key, data);
          this.$refs.ActionDialogControl.open(row, key, data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  display: flex;
  gap: 24px;
}

.key-word {
  ::v-deep .el-input--prefix .el-input__inner {
    padding-left: 15px;
  }

  ::v-deep .el-input--small .el-input__inner {
    padding-left: 15px;
  }

  :nth-child(2) {
    margin-left: 80px;
    padding-left: 15px;
  }

  :nth-child(3) {
    left: 0;
  }

  :nth-child(4) {
    right: -75px;
  }
}
</style>
