var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-page container" }, [
    _c("div", { staticClass: "room-card" }, [
      _c(
        "div",
        { staticClass: "room-card-head" },
        [
          _c(
            "el-input",
            {
              staticClass: "room-search",
              attrs: {
                placeholder: _vm.$t("请输入", { text: _vm.$t("用户昵称") }),
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm._filterRoomList($event)
                },
              },
              model: {
                value: _vm.searchWord,
                callback: function ($$v) {
                  _vm.searchWord = $$v
                },
                expression: "searchWord",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "append" },
                  on: { click: _vm._filterRoomList },
                  slot: "append",
                },
                [_vm._v(_vm._s(_vm.$t("查找")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.roomLoading,
              expression: "roomLoading",
            },
          ],
          ref: "scroller",
          staticClass: "room-card-body",
        },
        [
          _c(
            "div",
            { staticClass: "card-body__inner", staticStyle: { padding: "0" } },
            _vm._l(_vm.showRoomList, function (item) {
              return _c("chat-room", {
                key: item.id,
                attrs: {
                  item: item,
                  "active-room-id": _vm.activeNickname,
                  "can-edit": false,
                },
                on: { enterRoom: _vm._enterRoom },
              })
            }),
            1
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("div", { staticClass: "usernames" }, [
          _vm._v(_vm._s(_vm.activeNickname)),
        ]),
        _c("el-divider"),
        _c("div", { ref: "chatScroller", staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "card-body__inner" },
            _vm._l(_vm.filterChatList, function (item) {
              return _c("chat-item", {
                key: item.serverMsgId,
                attrs: {
                  item: item,
                  "content-type": item.contentType,
                  permission: _vm.permission,
                  me: item.memberType == 5,
                  operatorInfo: _vm.operatorInfo,
                },
                on: { "on-action": _vm._handleActionItem },
              })
            }),
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "card-footer" },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "top-start",
                  width: "350",
                  trigger: "click",
                },
                model: {
                  value: _vm.emoShow,
                  callback: function ($$v) {
                    _vm.emoShow = $$v
                  },
                  expression: "emoShow",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "emo-pop" },
                  _vm._l(_vm.emojiArr, function (emoItem) {
                    return _c(
                      "div",
                      {
                        key: emoItem,
                        staticClass: "emoItem",
                        on: {
                          click: function ($event) {
                            return _vm.addEmo(emoItem)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(emoItem) + " ")]
                    )
                  }),
                  0
                ),
                _c("img", {
                  staticClass: "emo-img",
                  attrs: {
                    slot: "reference",
                    src: require("@/assets/public_images/" +
                      (_vm.emoShow ? "icon-chat-keyboard" : "icon-chat-emoji") +
                      ".png"),
                  },
                  slot: "reference",
                }),
              ]
            ),
            _c("el-input", {
              attrs: {
                placeholder: _vm.$t("输入信息"),
                type: "textarea",
                autosize: { minRows: 1, maxRows: 10 },
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm._sendNormalMessage($event)
                },
              },
              model: {
                value: _vm.content,
                callback: function ($$v) {
                  _vm.content = $$v
                },
                expression: "content",
              },
            }),
            _c("i", {
              staticClass: "el-icon-s-promotion",
              staticStyle: { color: "#e91e63" },
              on: { click: _vm._sendNormalMessage },
            }),
          ],
          1
        ),
        _c("SetUserDialog", {
          ref: "SetUserDialogControl",
          attrs: { operatorInfo: _vm.operatorInfo },
          on: { change: _vm._getOeratorInfo, submit: _vm.submitCustomer },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { width: "150px", "margin-left": "20px" } },
      [
        _c(
          "el-link",
          {
            attrs: { type: "primary" },
            on: {
              click: function () {
                this$1.$refs.SetUserDialogControl.open()
              },
            },
          },
          [_vm._v("个人信息设置")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }