var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "卡券详情",
        visible: _vm.visible,
        width: "600px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: { "label-width": "190px", "label-suffix": ":" },
        },
        [
          _c("el-form-item", { attrs: { label: _vm.$t("优惠类型") } }, [
            _vm._v(" " + _vm._s(_vm.couponTypes[_vm.row.couponType]) + " "),
          ]),
          _c("el-form-item", { attrs: { label: _vm.$t("卡券名称") } }, [
            _vm._v(" " + _vm._s(_vm.row.couponTitle) + " "),
          ]),
          _vm.row.couponType === 3
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("折扣方式") } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.row.couponDiscountType === 0
                            ? "指定金额"
                            : "指定折扣"
                        ) +
                        " "
                    ),
                    _vm.row.couponDiscountType === 0
                      ? _c("el-form-item", [
                          _vm._v(" " + _vm._s(_vm.row.couponAmount) + " "),
                        ])
                      : _c("el-form-item", [
                          _vm._v(
                            " " + _vm._s(_vm.row.couponDiscountRate) + "折 "
                          ),
                        ]),
                  ],
                  1
                ),
                _c("el-form-item", { attrs: { label: _vm.$t("使用标准") } }, [
                  _vm._v(" " + _vm._s(_vm.row.couponStandard) + " "),
                ]),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("生效时间") } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.row.couponValidType === 0 ? "领取后生效" : "固定有效期"
                  ) +
                  " "
              ),
              _vm.row.couponValidType === 1
                ? _c("el-form-item", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$parseTime(
                            _vm.row.couponValidStart,
                            "{y}-{m}-{d} {h}:{i}"
                          )
                        ) +
                        "-" +
                        _vm._s(
                          _vm.$parseTime(
                            _vm.row.couponValidEnd,
                            "{y}-{m}-{d} {h}:{i}"
                          )
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.row.couponValidType === 0
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("卡券有效期(时)") } },
                [_vm._v(" " + _vm._s(_vm.row.couponExpired) + " ")]
              )
            : _vm._e(),
          _c("el-form-item", { attrs: { label: _vm.$t("卡券库存") } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.row.couponStock === -1 ? "无限" : _vm.row.couponStock
                ) +
                " "
            ),
          ]),
          _vm.row.couponType === 4
            ? [
                _c("el-form-item", { attrs: { label: _vm.$t("卡券面值") } }, [
                  _vm._v(" " + _vm._s(_vm.row.couponAmount) + " "),
                ]),
                _c("el-form-item", { attrs: { label: _vm.$t("提现打码量") } }, [
                  _vm._v(
                    " " + _vm._s(_vm.row.couponStandardWithdrawRolling) + "倍 "
                  ),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("打码量稽查（使用标准）") } },
                  [_vm._v(" " + _vm._s(_vm.row.couponStandardRolling) + " ")]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("指定打码平台") } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.row.codingPlatform === "-1"
                            ? "无限制"
                            : "仅限以下勾选平台"
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            : _vm.row.couponType === 5
            ? [
                _c("el-form-item", { attrs: { label: _vm.$t("卡券面值") } }, [
                  _vm._v(" " + _vm._s(_vm.row.couponAmount) + " "),
                ]),
                _c("el-form-item", { attrs: { label: _vm.$t("提现打码量") } }, [
                  _vm._v(
                    " " + _vm._s(_vm.row.couponStandardWithdrawRolling) + "倍 "
                  ),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("使用标准：充值金额") } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.row.couponStandardDepositAmount) +
                        "以上 "
                    ),
                  ]
                ),
                _c("el-form-item", { attrs: { label: _vm.$t("打码量稽查") } }, [
                  _vm._v(" " + _vm._s(_vm.row.couponStandardRolling) + " "),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("指定打码平台") } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.row.codingPlatform === "-1"
                            ? "无限制"
                            : _vm.row.codingPlatformName
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            : _vm.row.couponType === 6
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("优惠面值：订单中奖加奖") } },
                  [_vm._v(" " + _vm._s(_vm.row.discountAmount) + "倍 ")]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("使用标准") } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "betAmount",
                        attrs: { label: _vm.$t("最小下注") },
                      },
                      [_vm._v(" " + _vm._s(_vm.row.couponStandardMin) + " ")]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "betAmount",
                        attrs: { label: _vm.$t("最大下注") },
                      },
                      [_vm._v(" " + _vm._s(_vm.row.couponStandardMax) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.row.couponType === 6
            ? _c("el-form-item", { attrs: { label: _vm.$t("使用方式") } }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.couponScopeModes[_vm.row.couponScopeMode])),
                ]),
              ])
            : _vm._e(),
          _vm.row.couponType === 3
            ? _c("el-form-item", { attrs: { label: _vm.$t("使用方式") } }, [
                _c("span", [_vm._v("游戏下注优惠")]),
                _c("span", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v(_vm._s(_vm.couponScopeModes[_vm.row.couponScopeMode])),
                ]),
              ])
            : _vm._e(),
          _vm.row.couponType === 3 || _vm.row.couponType === 6
            ? _c("el-form-item", { attrs: { label: _vm.$t("适用范围") } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.row.couponScope === "-1"
                        ? "无限制"
                        : _vm.row.couponScopeName
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.row.couponType === 5
            ? _c("el-form-item", { attrs: { label: _vm.$t("适用范围") } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.row.couponScope === "-1"
                        ? "无限制"
                        : _vm.row.couponScopeName
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("el-form-item", { attrs: { label: _vm.$t("备注") } }, [
            _vm._v(" " + _vm._s(_vm.row.couponRemark) + " "),
          ]),
          _c("el-form-item", { attrs: { label: _vm.$t("上架状态") } }, [
            _vm._v(
              " " + _vm._s(_vm.row.couponStatus === 1 ? "上架" : "下架") + " "
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("确定")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }