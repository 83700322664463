import request from '@/plugins/axios'

export const getAccountReportList = data => {
  return request({
    url: '/accountReport/backList',
    method: 'post',
    data
  })
}

export const getPromotionReportDetail = data => {
  return request({
    url: '/promotionReport/backList',
    method: 'post',
    data
  })
}

export const getRobotReturnReportDetail = data => {
  return request({
    url: '/robotReturnReport/backList',
    method: 'post',
    data
  })
}

export const getUserReportList = data => {
  return request({
    url: '/userReport/backList',
    method: 'post',
    data
  })
}

export const getRegisterReportList = data => {
  return request({
    url: '/registerReport/backList',
    method: 'post',
    data
  })
}

export const getSingleBetList = data => {
  return request({
    url: '/single/bet',
    method: 'post',
    data
  })
}

// 渠道统计
export const getChannelSummaryDayPageList = data => {
  return request({
    url: '/channel/channelSummaryDayPageList',
    method: 'post',
    data
  })
}

export const getChannelPageList = data => {
  return request({
    url: '/channel/channelPageList',
    method: 'post',
    data
  })
}

export const addChannel = data => {
  return request({
    url: '/channel/addChannel',
    method: 'post',
    data
  })
}

export const resetPassword = params => {
  return request({
    url: '/channel/modPsd',
    method: 'get',
    params
  })
}

export const editChannelStatus = params => {
  return request({
    url: '/channel/editChannelStatus',
    method: 'get',
    params
  })
}

export const delChannel = id => {
  return request({
    url: '/channel/delChannel',
    method: 'get',
    params: { id }
  })
}

export const getChannelLinePageList = params => {
  return request({
    url: '/channel/channelLinePageList',
    method: 'get',
    params
  })
}
// 首冲会员列表
export const firstRechargeList = data => {
  return request({
    url: '/channel/firstRechargeList',
    method: 'post',
    data
  })
}
// 活跃会员列表
export const activeMemberPageList = data => {
  return request({
    url: '/channel/activeMemberPageList',
    method: 'post',
    data
  })
}
// 新增会员列表
export const newMemberPageList = data => {
  return request({
    url: '/channel/newMemberPageList',
    method: 'post',
    data
  })
}

export const addChannelLine = data => {
  return request({
    url: '/channel/addChannelLine',
    method: 'post',
    data
  })
}

export const delChannelLine = id => {
  return request({
    url: '/channel/delChannelLine',
    method: 'get',
    params: { id }
  })
}

export const editChannelLine = data => {
  return request({
    url: '/channel/editChannelLine',
    method: 'post',
    data
  })
}

// 三方统计列表
export const getCashThirdTotal = params => {
  return request({
    url: '/paymentOrder/total/list',
    method: 'get',
    params
  })
}
// 三方统计
export const getThirdTotal = params => {
  return request({
    url: '/paymentOrder/total/count',
    method: 'get',
    params
  })
}

export const paymentOrderTotalDownload = (data) => {
  return request({
    url: '/paymentOrderTotal/download',
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 600000
  })
}

export const channelNewMemberPageListDownload = (data) => {
  return request({
    url: '/channel/newMemberPageList/download',
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 600000
  })
}

// 查询租户投注统计列表
export const tenantBetReport = data => {
  return request({
    url: '/tenantBetReport',
    method: 'post',
    data
  })
}

// 查询游戏系列统计列表
export const tenantThirdReport = data => {
  return request({
    url: '/tenantThirdReport',
    method: 'post',
    data
  })
}

// 查询租户投注统计
export const tenantBetCount = data => {
  return request({
    url: '/tenantBetCount',
    method: 'post',
    data
  })
}

export const tenantBetReportDownload = (data) => {
  return request({
    url: '/tenantBetReport/download',
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 600000
  })
}

export const tenantThirdReportDownload = (data) => {
  return request({
    url: '/tenantThirdReport/download',
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 600000
  })
}
