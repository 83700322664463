import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('公告类型'),
      key: 'type',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.typeOptions[row.type] }</span>)
        }
      }
    },
    {
      title: this.$t('公告标题'),
      key: 'title',
      align: 'center'
    },
    {
      title: this.$t('语言'),
      key: 'lang',
      align: 'center',
      component: {
        render: (h, row) => {
          let langArray = []
          if (row.lang) {
            const allLang = this.$store.getters.allLang
            row.lang.split(',').forEach(e => {
              const lang = allLang.find(v => v.lang === e)
              if (lang) {
                langArray.push(lang.name)
              }
            })
          }
          return (<span>{ langArray.toString() }</span>)
        }
      }
    },
    {
      title: this.$t('开始时间'),
      key: 'startTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.startTime) }</span>)
        }
      }
    },
    {
      title: this.$t('结束时间'),
      key: 'endTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.endTime) }</span>)
        }
      }
    },
    {
      title: this.$t('是否启用'),
      key: 'enable',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          const disabled = !this.permission.StartStop || (row.endTime <= Date.now() && row.type === 2)
          return (
            <d-switch v-model={row.enable} disabled={disabled} active-value={1} inactive-value={0} beforeChange={this.handleUpdateEnable.bind(this, row)}>
              <span slot='close'>{ this.$t('停用') }</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('是否弹出'),
      key: 'popup',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          const disabled = !this.permission.Eject || (row.endTime <= Date.now() && row.type === 2) || [11, 12].includes(row.type)
          return (
            <d-switch v-model={row.popup} disabled={disabled} active-value={1} inactive-value={0} beforeChange={this.handleUpdatePopup.bind(this, row)}>
              <span slot='close'>{ this.$t('停用') }</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('排序'),
      key: 'sort',
      align: 'center',
      width: 100
    },
    {
      title: this.$t('编辑时间'),
      key: 'updateTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作员'),
      key: 'operatorName',
      align: 'center'
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 180,
      component: {
        render: (h, row) => {
          const { Delete, Update } = this.permission
          return (
            <span>
              { Update && <el-button type='success' size='mini' icon='el-icon-edit' onClick={ () => this.onAction(row) }>{this.$t('编辑')}</el-button> }
              { Delete && <el-button type='danger' size='mini' icon='el-icon-delete' onClick={ () => this._handleDelete(row) }>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
