import { filterColumns } from '@/utils'

const CanChooseColumns = function() {
  return [
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p class='primary-type cursor-pointer hover-underline' onClick={this._onDetails.bind(this, row)}>{row.memberName}</p>
              <i
                class='el-icon-chat-dot-square cursor-pointer'
                style='color: #FF8C00'
                onClick={this._onSend.bind(this, row)}
              />
            </div>
          )
        }
      }
    },
    {
      title: this.$t('邀请码'),
      key: 'memberAccno',
      align: 'center'
    },
    {
      title: this.$t('代理等级'),
      key: 'agencyLevel',
      align: 'center'
    },
    {
      title: this.$t('所属代理'),
      key: 'agencyMemberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{row.agencyMemberName || this.$t('暂无')}</p>
              {/* {<i class='el-icon-edit primary-type cursor-pointer' onClick={() => this.$refs.ProxyDialogControl.open(row)} />} */}
              {this.permission.UpdateAgent && <i class='el-icon-edit primary-type cursor-pointer' onClick={() => this.$refs.ProxyDialogControl.open(row)} />}
            </div>
          )
        }
      }
    },
    {
      title: this.$t('会员等级'),
      key: 'level',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <div class='primary-type cursor-pointer hover-underline' onClick={this._onUpdateVip.bind(this, row)}>
              <span>VIP{row.vipLevel}</span>
              <p>{row.vipStatus ? this.$t('锁定') : this.$t('未锁定')}</p>
              {this.permission.UpdateVip && <i class='el-icon-edit' />}
            </div>
          )
        }
      }
    },
    {
      title: this.$t('账号余额'),
      key: 'balance',
      align: 'center',
      sortable: true,
      sortMethod: function(a, b) {
        return parseFloat(a.balance) - parseFloat(b.balance)
      },
      component: {
        render: (h, row) => {
          return (
            <span class='danger-type'>{row.balance}</span>
          )
        }
      }
    },
    {
      title: this.$t('会员昵称'),
      key: 'nickname',
      align: 'center',
      width: 100
    },
    // {
    //   title: this.$t('会员生日'),
    //   key: 'birthday',
    //   align: 'center',
    //   width: 100,
    //   component: {
    //     render: (h, row) => {
    //       return (<span>{ this.$parseTime(row.birthday) }</span>)
    //     }
    //   }
    // },
    {
      title: this.$t('注册时间'),
      key: 'createTime',
      align: 'center',
      sortable: true,
      width: 180,
      component: {
        render: (h, row) => {
          return (
            <div>
              <span>{row.registerIp}</span>
              <p>{row.registerAddress}</p>
              <span>{this.$parseTime(row.createTime)}</span>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('最后登录'),
      key: 'latestLoginTime',
      align: 'center',
      sortable: true,
      width: 180,
      component: {
        render: (h, row) => {
          return (
            <div>
              <span>{row.latestLoginIp}</span>
              <p>{row.latestLoginAddress}</p>
              <span>{this.$parseTime(row.latestLoginTime)}</span>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('在线情况'),
      key: 'online',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <el-tag type={row.onlineStatus === 1 ? 'success' : 'info'}>
              {row.onlineStatus === 0 ? this.$t('离线') : this.$t('在线')}
            </el-tag>
          )
        }
      }
    },
    {
      title: this.$t('状态'),
      key: 'freezeStatus',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.freezeStatus}
              disabled={!this.permission.StartStop}
              beforeChange={this.handleChangeStatus.bind(this, row)}>
              <span slot='close'>{this.$t('停用')}</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('查看'),
      align: 'center',
      key: 'preview',
      component: {
        render: (h, row) => {
          return (
            <span>
              <el-link type='primary' onClick={() => this.go(row, '/Cash_Member_Change')} size='small'>{this.$t('账变')}</el-link>
              <el-link type='primary' onClick={() => this.go(row, '/Orders_Lottery')} size='small'>{this.$t('注单')}</el-link>
            </span>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 180,
      component: {
        render: (h, row) => {
          const { UpdateInfo, updatePassword, ResetIP } = this.permission
          return (
            <span>
              {ResetIP && <el-link type='warning' onClick={() => this.resetIP(row)} size='small'>{this.$t('重置IP')}</el-link>}
              {UpdateInfo && <el-link type='primary' onClick={() => this.$refs.EditDialogControl.open(row)} size='small'>{this.$t('修改')}</el-link>}
              {updatePassword && <el-link type='success' onClick={() => this.$refs.PasswordDialogControl.open(row)} size='small'>{this.$t('修改密码')}</el-link>}
            </span>
          )
        }
      }
    },
    {
      title: this.$t('备注内容'),
      align: 'center',
      key: 'remark',
      component: {
        render: (h, row) => {
          const remark = row.remark && row.remark.replace(/\s+/g, '')
          return (
            this.permission.Remark &&
            <el-link
              type={remark ? 'info' : 'danger'}
              onClick={this._onRemark.bind(this, { memberId: row.memberId, remark: row.remark, tenantCode: row.tenantCode })}
            >{remark ? row.remark : this.$t('添加备注')}</el-link>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
