var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.params.issue + "期 " + _vm.$t("结算统计"),
        width: "600px",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.onClosed,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _vm.resource
            ? _c("ul", { staticClass: "statistics-table" }, [
                _c("li", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("总订单数")))]),
                  _c("span", [_vm._v(_vm._s(_vm.resource.orderNum))]),
                ]),
                _c("li", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("总投注金额")))]),
                  _c("span", [_vm._v(_vm._s(_vm.resource.betsAmount))]),
                ]),
                _c("li", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("总中奖单数")))]),
                  _c("span", [_vm._v(_vm._s(_vm.resource.winOrderNum))]),
                ]),
                _c("li", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("总中奖金额")))]),
                  _c("span", [_vm._v(_vm._s(_vm.resource.winAmount))]),
                ]),
                _c("li", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("总下注人数")))]),
                  _c("span", [_vm._v(_vm._s(_vm.resource.betsPeopleNum))]),
                ]),
                _c("li", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("总盈亏")))]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.resource.betsAmount - _vm.resource.winAmount)
                    ),
                  ]),
                ]),
              ])
            : _c("div", { staticClass: "statistics-table-empty" }, [
                _vm._v(_vm._s(_vm.$t("暂无数据"))),
              ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("关闭"))),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }