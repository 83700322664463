<template>
  <div class="top-tab" :style="{ width }">
    <h3>{{ title }}</h3>
    <el-table
      ref="elTable"
      border
      :data="items"
      row-key="rowKey"
      :drag-row="true"
      v-loading="loading"
    >
      <el-table-column type="index" align="center" width="60">
        <template #header>{{ $t("排序") }}</template>
        <svg-icon icon-class="drag" class-name="drag-handler" />
      </el-table-column>
      <el-table-column :label="menuName" align="center">
        <template slot-scope="{ row }">
          <el-select
            v-model="row.code"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in menuList"
              :key="item.key"
              :value="item.key"
              :label="item.label"
              :disabled="items.some(e => e.code === item.key && e.code !== row.code)"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column v-if="isName" label="名称" align="center">
        <template slot-scope="{ row }">
          <el-input v-model="row.name" />
        </template>
      </el-table-column>
      <el-table-column v-if="isIcon" :label="$t('图标')" align="center" width="140">
        <template slot-scope="{ row }">
          <d-upload
            v-model="row.icon"
            folder="icon"
            :on-success="(...args) => handleUploadSuccess(args, row)"
            :width="86"
            :height="86"
          />
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')" align="center" width="140">
        <template slot-scope="{ row }">
          <el-button @click="row.code = null;row.name = null;row.icon = null">重置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0" align="right">
      <el-button
        type="success"
        :loading="submitting"
        @click="handleSubmit()"
      >{{ $t("保 存") }}</el-button>
    </div>
  </div>
</template>
<script>
import DUpload from '@/components/DUpload'
import { menuconfigList, menuconfigUpdate  } from '@/api/config'
import TableSortMixin from '@/mixins/table-sort-mixin'

export default {
  mixins: [TableSortMixin(true)],
  components: { DUpload },
  props: {
    width: {
      type: String,
      default: '800px'
    },
    title: {
      type: String,
      default: ''
    },
    menuName: {
      type: String,
      default: ''
    },
    isName: {
      type: Boolean,
      default: true
    },
    isIcon: {
      type: Boolean,
      default: true
    },
    menuList: {
      type: Array,
      required: true
    },
    num: {
      type: Number,
      required: true
    },
    homeType: {
      type: String,
      required: true
    },
    position: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      items: [],
      submitting: false
    }
  },
  computed: {
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`;
    }
  },
  created() {
    this.getMenuList()
  },
  methods: {
    getMenuList() {
      this.loading = true
      menuconfigList({ homeType: this.homeType, position: this.position }).then(([items, err]) => {
        if (!err) {
          for(let index = 0; index < this.num; index++) {
            this.items.push(items.length > index ? { rowKey: index, ...items[index] } : { rowKey: index, code: '', name: '', icon: '' })
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleSubmit() {
      const configList = []
      this.items.forEach((e, index) => {
        if (e.code) {
          let name = e.name
          if (!this.isName) {
            name = this.menuList.find(v => v.key === e.code).label
          }
          e.sort = index
          configList.push({ code: e.code, name, sort: e.sort, icon: e.icon })
        }
      })
      this.submitting = true
      menuconfigUpdate({ homeType: this.homeType, position: this.position, configList}).then(([_, err]) => {
        if (!err) {
          this.$message.success(this.$t('保存成功'))
        }
      }).finally(() => {
        this.submitting = false
      })
    },
    handleUploadSuccess([[data]], row) {
      row.icon = data
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select.is-error .el-input__inner {
  border-color: #f56c6c;
}
.top-tab {
  width: 800px;
}
</style>
  