<template>
  <el-dialog :title="title" :visible.sync="visible" width="46%" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="后台用户" prop="nickname">
        <el-input v-model="ruleForm.nickname" maxlength="50" placeholder="后台用户昵称" />
      </el-form-item>
      <el-form-item label="评论文字" prop="commentContent">
        <el-input v-model="ruleForm.commentContent" type="textarea" rows="6" placeholder="请输入评论" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        发送
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  nickname: '',
  commentContent: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      title: '评论',
      emitName: 'create',
      rules: {
        nickname: [
          { required: true, message: '请输入后台用户昵称', trigger: 'blur' }
        ],
        commentContent: [
          { required: true, message: '请输入评论', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(row) {
      this.ruleForm.issue = row.issue
      this.ruleForm.pictureTag = row.pictureTag
      this.visible = true
    }
  }
}
</script>
