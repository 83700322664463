var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.emitName === "create"
            ? _vm.$t("新增text", {
                text: _vm.$t("Member_Expert.LevelTab.专家等级"),
              })
            : _vm.$t("编辑text", {
                text: _vm.$t("Member_Expert.LevelTab.专家等级"),
              }),
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.LevelTab.专家等级"),
                prop: "positionLevel",
                placeholder: _vm.$t("请输入m_n区间数字", { text: "1-100" }),
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.positionLevel,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "positionLevel", _vm._n($$v))
                  },
                  expression: "ruleForm.positionLevel",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.LevelTab.等级名称"),
                prop: "positionName",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.positionName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "positionName", $$v)
                  },
                  expression: "ruleForm.positionName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.LevelTab.等级色值"),
                prop: "flagColor",
              },
            },
            [
              _c("el-color-picker", {
                attrs: {
                  predefine: [
                    "#BD9AFF",
                    "#FF7E15",
                    "#4FD072",
                    "#2E8CE4",
                    "#FF47D7",
                    "#F2B600",
                    "#FC4A8D",
                    "#796FFE",
                    "#9FCC17",
                    "#05C6CD",
                  ],
                },
                model: {
                  value: _vm.ruleForm.flagColor,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "flagColor", $$v)
                  },
                  expression: "ruleForm.flagColor",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.LevelTab.发布次数"),
                prop: "publishNum",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.publishNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "publishNum", _vm._n($$v))
                  },
                  expression: "ruleForm.publishNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.LevelTab.胜率"),
                prop: "winrate",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t(
                    "Member_Expert.LevelTab.请输入1_100区间最多三位小数"
                  ),
                },
                model: {
                  value: _vm.ruleForm.winrate,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "winrate", $$v)
                  },
                  expression: "ruleForm.winrate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.LevelTab.返还率"),
                prop: "returnrate",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t(
                    "Member_Expert.LevelTab.请输入1_100区间最多三位小数"
                  ),
                },
                model: {
                  value: _vm.ruleForm.returnrate,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "returnrate", $$v)
                  },
                  expression: "ruleForm.returnrate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Member_Expert.LevelTab.抽佣"),
                prop: "commission",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t(
                    "Member_Expert.LevelTab.请输入1_100区间最多三位小数"
                  ),
                },
                model: {
                  value: _vm.ruleForm.commission,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "commission", $$v)
                  },
                  expression: "ruleForm.commission",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("状态"), prop: "positionStatus" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": _vm.$t("Member_Expert.LevelTab.需要审核"),
                  "inactive-text": _vm.$t("Member_Expert.LevelTab.无需审核"),
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.ruleForm.positionStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "positionStatus", $$v)
                  },
                  expression: "ruleForm.positionStatus",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确定")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }