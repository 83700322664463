<template>
  <div
    class="chat-room"
    :class="{
      active_bg: activeNickname == item.nickname ? true : false,
      check: item.check,
    }"
  >
    <div>
      <el-avatar :size="48" :src="item.avatar" />
    </div>
    <div class="chat-room-info" @click="_enterRoom">
      <div class="chat-room-title">
        {{ item.nickname }}
      </div>

      <div class="chat-room-descs single-line-overflow">
        {{ cardPreviw }}
      </div>
    </div>
    <div class="char-room-bottoms" v-if="item.messageSize != 0">
      <div class="chat-room-enters">
        {{ item.messageSize > 99 ? "99+" : item.messageSize }}
      </div>
    </div>
  </div>
</template>
<script>
import football from "@/assets/public_images/ball-football.png";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    activeNickname: {
      type: String,
      default: "",
    },
  },
  computed: {
    cardPreviw() {
      if (this.item.content) {
        if(this.isImageFormat(this.item.content)){
          return '图片'
        }else{
          return this.item.content;
        }
      }
      if (!this.item.extras) {
        return "";
      }
      return this.msgTypeMap[this.item.extras.msgType];
    },
  },
  data() {
    return {
      // 彩票注单 207
      // 足彩注单 208
      // 第三方注单 209
      // 充值订单 210
      // 提现订单 211
      // 任务分享 212
      // 富文本-定时消息 213
      // 任务分享-1v1客服弹框任务分享 214
      msgTypeMap: {
        207: "彩票注单",
        208: "足彩注单",
        209: "第三方注单",
        210: "充值订单",
        211: "提现订单",
        212: "任务分享",
        213: "定时消息",
        214: "任务",
      },
    };
  },
  methods: {
    // 判断是不是图片格式
    isImageFormat(fileName) {
      // 定义常见图片格式的正则表达式
      const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg|ico)$/i;
      return imageExtensions.test(fileName);
    },
    // 进入房间
    _enterRoom() {
      this.$emit("enterRoom", this.item);
    },

    getMessageNumber(item) {
      if (item.roomNumber + (item.realCount || 0) > 99) {
        return "99+";
      } else if (item.roomNumber + (item.realCount || 0) <= 0) {
        return "0";
      } else {
        return item.roomNumber + (item.realCount || 0);
      }
    },
    // 图标处理
    getRoomIcon() {
      if (this.item.roomType === 2 || this.item.roomType === 4) {
        return football;
      } else {
        return this.item.roomIcon;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-room {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  padding: 0 10px;
  &-icon {
    cursor: pointer;
  }

  &-info {
    margin-top: 8px;
    margin-left: 18px;
    padding-bottom: 10px;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
  }

  &-title {
    margin-bottom: 10px;
    color: rgba(16, 16, 16, 100);
    font-size: 18px;
    font-family: SourceHanSansSC-bold;
  }

  &-desc {
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    font-family: SourceHanSansSC-bold;
  }

  &-enter {
    font-size: 20px;
    position: relative;
    float: right;
    top: 20px;
  }
}

.chat-room-info {
  margin-left: 8px;
}

.active_bg {
  background-color: #f2f6fc;
}

.chat-room-descs {
  width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat-room-enters {
  border-radius: 50%;
  background-color: rgb(179, 5, 5);
  color: white;
  font-size: 10px;
  width: 26px;
  text-align: center;
  line-height: 26px;
  height: 26px;
}
.pic {
  width: 50px;
}
.check {
  background: #f2f6fc;
}
</style>
