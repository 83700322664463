<template>
  <el-dialog :title="$t('添加银行卡')" :visible.sync="visible" width="400px" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('用户账号')" prop="username">
        <el-input v-model="ruleForm.username" placeholder="请输入用户账号" />
      </el-form-item>
      <el-form-item :label="$t('币种类型')" prop="rateType">
        <el-select v-model="ruleForm.rateType" placeholder="请选择币种" filterable style="width: 100%;" @change="getBankList()">
          <el-option v-for="item in rateTypes" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('银行名称')" prop="cardName">
        <el-select v-model="ruleForm.cardName" filterable placeholder="请选择银行卡" style="width: 100%;" :loading="bankLoading">
          <el-option :label="item.name" :value="item.name" v-for="item in bankList" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('开户地址')" prop="bankAddress">
        <el-input v-model="ruleForm.bankAddress" placeholder="请输入开户地址" />
      </el-form-item>
      <el-form-item :label="$t('银行卡号')" prop="cardNumber">
        <el-input v-model="ruleForm.cardNumber" placeholder="请输入银行卡号" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { rateTypes } from '@/libs/options'
import { getSupportBankList } from '@/api/finance'

const getDefaultForm = () => ({
  username: '',
  cardNumber: '',
  rateType: '',
  cardName: '',
  bankAddress: ''
})
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      bankList: [],
      bankLoading: false,
      rateTypes: rateTypes,
      emitName: 'add',
      rules: {
        cardName: [{ required: true, message: this.$t('请选择银行卡'), trigger: 'change' }],
        cardNumber: [{ required: true, message: this.$t('请输入银行卡号'), trigger: 'blur' }],
        username: [{ required: true, message: this.$t('请输入用户账号'), trigger: 'blur' }],
        rateType: [{ required: true, message: this.$t('请选择币种'), trigger: 'change' }]
      }
    }
  },
  methods: {
    getBankList() {
      this.bankLoading = true
      this.bankList = []
      getSupportBankList({ rateType: this.ruleForm.rateType, currentPage: 1, pageSize: 200 }).then(([data, err]) => {
        if (!err) {
          this.bankList = data
        }
      }).finally(() => {
        this.bankLoading = false
      })
    },
    // open({ id, username, realName, bank, cardNumber, status, bindTime, updateTime, memberId }) {
    open() {
      // this.ruleForm = { id, username, realName, bank, cardNumber, status, bindTime, updateTime, memberId }
      this.visible = true
    }
  }
}
</script>
