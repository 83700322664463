<template>
  <el-dialog :title="title" :visible.sync="visible" width="60%" top="5vh" :close-on-click-modal="false"
    @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" :disabled="emitName === 'details'" label-width="170px"
      label-suffix=":">
      <el-form-item :label="$t('语言')" prop="languageSet">
        <el-checkbox-group v-model="ruleForm.languageSet">
          <el-checkbox v-for="item of allLang" :key="item.lang" :label="item.lang">{{ $t(item.name) }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('活动标题')" prop="title">
        <el-input v-model="ruleForm.title" style="width: 300px" maxlength="20" :placeholder="$t('限20字以内')" clearable />
      </el-form-item>
      <el-form-item :label="$t('活动分类')" prop="category">
        <el-select v-model="ruleForm.category" :placeholder="$t('活动分类')" :style="{ width: '300px' }" clearable>
          <el-option v-for="(item, index) in $parent.categoryList" :key="index" :label="item.typeName"
            :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('排序')" prop="sortOrder">
        <el-input v-model.number="ruleForm.sortOrder" style="width: 300px" placeholder="请输入1-999的整数，由大到小" clearable />
      </el-form-item>
      <el-form-item :label="$t('活动时间')" prop="isContinuous">
        <el-radio-group v-model="ruleForm.isContinuous">
          <el-radio :label="0">{{ $t('定时开启') }}</el-radio>
          <el-radio :label="1">{{ $t('持续开启') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="ruleForm.isContinuous === 0" prop="activityDate">
        <el-date-picker v-model="ruleForm.activityDate" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
          :range-separator="$t('至')" :start-placeholder="$t('开始时间')" :end-placeholder="$t('结束时间')"
          :picker-options="pickerOptions" />
      </el-form-item>
      <el-form-item :label="$t('活动Banner')" prop="banner">
        <el-upload class="banner-uploader" accept="image/jpeg,image/png,image/jpg,image/webp" :action="action"
          :data="{ type: 'image', folder: 'ad-photo' }" :headers="$headers()" :show-file-list="false"
          :on-success="(res) => handleUploadSuccess(res, 'banner')">
          <img v-if="ruleForm.banner" :src="ruleForm.banner" class="banner">
          <div v-else class="banner-uploader-txt">
            <!-- <p>{{ $t('文件大小控制在100K以内') }}</p> -->
            <button class="el-button el-button--small el-button--orange" @click.self.prevent>{{ $t('点击上传') }}</button>
          </div>
          <div slot="tip" class="el-upload__tip"><i
              class="el-icon-warning-outline"></i>提示：建议尺寸355*138，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。</div>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('活动类型')" prop="activityType">
        <el-radio-group v-model="ruleForm.activityType" @change="changeActivityType">
          <el-radio :label="1">{{ $t('任务活动') }}</el-radio>
          <el-radio :label="2">{{ $t('其他活动') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="ruleForm.activityType == 2" :label="$t('跳转方式')" key="jumpType" prop="jumpType">
        <el-radio-group v-model="ruleForm.jumpType" @change="changeJumpType">
          <el-radio :label="1">{{ $t('活动详情') }}</el-radio>
          <el-radio :label="2">{{ $t('链接') }}</el-radio>
          <el-radio :label="3">{{ $t('APP功能页') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="ruleForm.activityType == 2">
        <div :key="'jumpFields' + ruleForm.jumpType">
          <el-form-item v-if="ruleForm.jumpType === 3" prop="appPageId">
            <el-cascader v-model="ruleForm.appPageId" :options="appPageList" @change="handleChangeAppPage"
              style="width: 300px" clearable />
          </el-form-item>
          <el-form-item v-if="ruleForm.jumpType === 2" prop="jumpLink">
            <el-input v-model="ruleForm.jumpLink" @input="() => $refs.validateForm.validateField('jumpLink')"
              style="width: 300px" :placeholder="$t('请输入链接地址信息')" />
          </el-form-item>
        </div>
      </template>
      <el-form-item v-show="showBanner" :label="$t('活动详情Banner')" prop="detailBanner" :key="'banner' + bannerKey">
        <el-upload class="banner-uploader" accept="image/jpeg,image/png,image/jpg,image/webp" :action="action"
          :data="{ type: 'image', folder: 'ad-photo' }" :headers="$headers()" :show-file-list="false"
          :on-success="(res) => handleUploadSuccess(res, 'detailBanner')"
          @change="() => $refs.validateForm.validateField('detailBanner')" :key="'upload' + bannerKey">
          <img v-if="ruleForm.detailBanner" :src="ruleForm.detailBanner" class="banner">
          <div v-else class="banner-uploader-txt">
            <!-- <p>{{ $t('文件大小控制在100K以内') }}</p> -->
            <button class="el-button el-button--small el-button--orange" @click.self.prevent>{{ $t('点击上传') }}</button>
          </div>
          <div slot="tip" class="el-upload__tip"><i
              class="el-icon-warning-outline"></i>提示：建议尺寸355*138，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。</div>
        </el-upload>
      </el-form-item>
      <template v-if="ruleForm.activityType === 2">
        <template v-if="ruleForm.jumpType === 1">
          <el-form-item :label="$t('VIP等级')" prop="vipLimit">
            <el-radio-group v-model="ruleForm.vipLimit" @change="changeVipLimit">
              <el-radio :label="0">{{ $t('不限制') }}</el-radio>
              <el-radio :label="1">{{ $t('限制') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.vipLimit === 1" prop="vipLevels">
            <el-checkbox-group v-model="ruleForm.vipLevels">
              <el-checkbox v-for="vipLevel of formData.vipLevelList" :key="vipLevel" :label="vipLevel">{{
                `VIP${vipLevel}` }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('活动场馆')" prop="venue" key="venue">
            <el-input v-model="ruleForm.venue" style="width: 500px;max-width: 100%;" maxlength="50"
              :placeholder="$t('限50字以内')" clearable />
          </el-form-item>
          <el-form-item :label="$t('活动引导说明')" prop="guide" key="guide">
            <el-input v-model="ruleForm.guide" style="width: 500px;max-width: 100%;" maxlength="50"
              :placeholder="$t('限50字以内')" clearable />
          </el-form-item>
          <el-form-item :label="$t('任务与奖励')" prop="rewardContent">
            <tinymce :disabled="emitName === 'details'" ref="tinymce" v-model="ruleForm.rewardContent" :height="200" />
          </el-form-item>
          <el-form-item :label="$t('活动规则')" prop="ruleDisplayType">
            <el-radio-group v-model="ruleForm.ruleDisplayType" @change="changeRuleDisplayType">
              <el-radio :label="1">{{ $t('富文本') }}</el-radio>
              <el-radio :label="2">{{ $t('图片') }}</el-radio>
            </el-radio-group>
            <el-form-item prop="ruleContent">
              <tinymce :disabled="emitName === 'details'" v-if="ruleForm.ruleDisplayType === 1" ref="tinymceRule"
                v-model="ruleForm.ruleContent" :height="200" />
              <el-upload v-else class="banner-uploader" accept="image/jpeg,image/png,image/jpg,image/webp"
                :action="action" :data="{ type: 'image', folder: 'ad-photo' }" :headers="$headers()"
                :show-file-list="false" :on-success="(res) => handleUploadSuccess(res, 'ruleContent')">
                <img v-if="ruleForm.ruleContent" :src="ruleForm.ruleContent" class="banner">
                <div v-else class="banner-uploader-txt">
                  <!-- <p>{{ $t('文件大小控制在100K以内') }}</p> -->
                  <button class="el-button el-button--small el-button--orange" @click.self.prevent>{{ $t('点击上传')
                  }}</button>
                </div>
                <div slot="tip" class="el-upload__tip"><i
                    class="el-icon-warning-outline"></i>提示：建议尺寸355*138，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。</div>
              </el-upload>
            </el-form-item>
          </el-form-item>
        </template>
      </template>
      <template v-if="ruleForm.activityType === 1">
        <el-form-item class="tiaojian">
          <strong>参与资格：</strong>
          <el-form-item class="form-item-error" label="总充值金额大于等于" prop="minTotalRecharge">
            <el-input type="text" v-model="ruleForm.minTotalRecharge" @input="handleInput('minTotalRecharge')"
              style="width: 120px; margin-right: 100px" clearable />
          </el-form-item>
          <el-form-item class="form-item-error" label="总投注金额大于等于" prop="minTotalBet">
            <el-input type="text" v-model="ruleForm.minTotalBet" @input="handleInput('minTotalBet')"
              style="width: 120px" clearable />
          </el-form-item>
        </el-form-item>
        <el-form-item class="tiaojian">
          <el-form-item class="form-item-error" label="今日充值金额大于等于" prop="minTodayRecharge">
            <el-input type="text" v-model="ruleForm.minTodayRecharge" @input="handleInput('minTodayRecharge')"
              style="width: 120px; margin-right: 100px" clearable />
          </el-form-item>
          <el-form-item class="form-item-error" label="今日投注金额大于等于" prop="minTodayBet">
            <el-input type="text" v-model="ruleForm.minTodayBet" @input="handleInput('minTodayBet')"
              style="width: 120px" clearable />
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('VIP等级')" prop="vipLimit">
          <el-radio-group v-model="ruleForm.vipLimit">
            <el-radio :label="0">{{ $t('不限制') }}</el-radio>
            <el-radio :label="1">{{ $t('限制') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.vipLimit === 1" prop="vipLevels">
          <el-checkbox-group v-model="ruleForm.vipLevels">
            <el-checkbox v-for="vipLevel of formData.vipLevelList" :key="vipLevel" :label="vipLevel">{{
              `VIP${vipLevel}` }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item :label="$t('活动场馆')" prop="venue" key="venue">
          <el-input v-model="ruleForm.venue" style="width: 500px;max-width: 100%;" maxlength="50"
            :placeholder="$t('限50字以内')" clearable />
        </el-form-item>
        <el-form-item :label="$t('活动引导说明')" prop="guide" key="guide">
          <el-input v-model="ruleForm.guide" style="width: 500px;max-width: 100%;" maxlength="50"
            :placeholder="$t('限50字以内')" clearable />
        </el-form-item>
        <el-form-item :label="$t('任务类型')" prop="taskType">
          <el-radio-group v-model="ruleForm.taskType" @change="changeTaskType">
            <el-radio :label="1">{{ $t('日任务') }}</el-radio>
            <el-radio :label="2">{{ $t('周任务') }}</el-radio>
            <el-radio :label="3">{{ $t('长期任务') }}</el-radio>
            <el-radio :label="4">{{ $t('新手任务') }}</el-radio>
          </el-radio-group>
          <el-select v-if="ruleForm.taskType === 2" v-model="ruleForm.rewardMode"
            style="width: 130px; margin-left: 50px;" @change="changeRewardMode">
            <el-option v-for="item in formData.awardsOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('任务行为')" prop="taskAction">
          <el-radio-group v-model="ruleForm.taskAction" @change="changeTaskAction">
            <el-radio v-if="ruleForm.taskType === 1" :label="1">{{ $t('签到') }}</el-radio>
            <el-radio :label="2">{{ $t('充值') }}</el-radio>
            <el-radio :label="3">{{ $t('提现') }}</el-radio>
            <el-radio :label="4">{{ $t('投注') }}</el-radio>
            <el-radio :label="5">{{ $t('关注') }}</el-radio>
            <el-radio :label="6">{{ $t('发帖') }}</el-radio>
            <el-radio :label="7">{{ $t('评论') }}</el-radio>
            <el-radio :label="8">{{ $t('打赏') }}</el-radio>
            <el-radio :label="9">{{ $t('浏览') }}</el-radio>
            <el-radio :label="10">{{ $t('邀请') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm.taskAction === 1" :label="$t('任务目标与奖励')">
          <span>每日奖励</span>
          <table class="signTable">
            <thead>
              <tr>
                <th>签到日期</th>
                <th>奖励类型</th>
                <th>奖励内容</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(n, index) in day31" :key="n">
                <td>
                  {{ n }}号
                </td>
                <td>
                  <el-form-item class="form-item-error" :prop="`rewardRules4.${index}.awardsCheckedType_4`"
                    :rules="rules.awardsCheckedType_4">
                    <el-checkbox-group v-model="ruleForm.rewardRules4[index].awardsCheckedType_4"
                      @change="(value) => handleChange(value, 4, index)">
                      <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                      }}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item class="form-item-error" :prop="`rewardRules4.${index}.bonusCheck`"
                    :rules="getBonusRules(index)">
                    <span v-if="awardsCheckedType_4[index].includes('彩金')">
                      彩金 <el-input type="text" v-model="ruleForm.rewardRules4[index].bonusAmount"
                        @input="handleInput2('bonusAmount', index, 'rewardRules4')" clearable style="width: 100px" />
                      打码<el-input type="text" v-model="ruleForm.rewardRules4[index].bonusWagerMultiplier"
                        @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules4')" clearable
                        style="width: 100px" /> 倍
                    </span>
                  </el-form-item>
                  <el-form-item class="form-item-error" :prop="`rewardRules4.${index}.couponCheck`"
                    :rules="getCouponRules(index)">
                    <span v-if="awardsCheckedType_4[index].includes('优惠券')" class="ml">
                      优惠券
                      <el-select v-model="ruleForm.rewardRules4[index].couponId" :placeholder="$t('商品类目')"
                        class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                        collapse-tags>
                        <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                          :label="item.couponTitle" />
                      </el-select>
                    </span>
                  </el-form-item>
                  <el-form-item class="form-item-error" :prop="`rewardRules4.${index}.pointsCheck`"
                    :rules="getPointsRules(index)">
                    <span v-if="awardsCheckedType_4[index].includes('积分')" class="ml">
                      积分<el-input type="text" v-model="ruleForm.rewardRules4[index].points"
                        @input="handleInput2('points', index, 'rewardRules4')" clearable style="width: 100px" />
                    </span>
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
          <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd('num0')">添加</el-button>
          <table>
            <thead>
              <tr>
                <th>任务目标</th>
                <th>奖励类型</th>
                <th>奖励内容</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(n, index) in num0" :key="n">
                <td>
                  累计签到
                  <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue'"
                    :rules="rules.conditionValue">
                    <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue"
                      @input="handleInput2('conditionValue', index, 'rewardRules3', 'num')" clearable
                      style="width: 100px" /> 天
                  </el-form-item>
                </td>
                <td>
                  <el-form-item class="form-item-error" :prop="`rewardRules3.${index}.awardsCheckedType_3`"
                    :rules="rules.awardsCheckedType_3">
                    <el-checkbox-group v-model="ruleForm.rewardRules3[index].awardsCheckedType_3"
                      @change="(value) => handleChange(value, 3, index)">
                      <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                      }}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </td>
                <td>
                  <template v-if="awardsCheckedType_3[index].includes('彩金')">
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusAmount'"
                      :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusAmount : []">
                      彩金 <el-input type="text" v-model="ruleForm.rewardRules3[index].bonusAmount"
                        @input="handleInput2('bonusAmount', index, 'rewardRules3')" clearable style="width: 100px" />
                    </el-form-item>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusWagerMultiplier'"
                      :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusMultiplier : []">
                      打码<el-input type="text" v-model="ruleForm.rewardRules3[index].bonusWagerMultiplier"
                        @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules3')" clearable
                        style="width: 100px" />
                    </el-form-item>倍
                  </template>
                  <template v-if="awardsCheckedType_3[index].includes('优惠券')" class="ml">
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.couponId'"
                      :rules="awardsCheckedType_3[index].includes('优惠券') ? rules.couponId : []">
                      优惠券 <el-select v-model="ruleForm.rewardRules3[index].couponId" :placeholder="$t('商品类目')"
                        class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                        collapse-tags>
                        <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                          :label="item.couponTitle" />
                      </el-select>
                    </el-form-item>
                  </template>
                  <template v-if="awardsCheckedType_3[index].includes('积分')" class="ml">
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.points'"
                      :rules="awardsCheckedType_3[index].includes('积分') ? rules.points : []">
                      积分<el-input type="text" v-model="ruleForm.rewardRules3[index].points"
                        @input="handleInput2('points', index, 'rewardRules3')" clearable style="width: 100px" />
                    </el-form-item>
                  </template>
                </td>
                <td>
                  <el-button type="danger"
                    @click="handeldelete(index, ruleForm.rewardRules3[index], 'num0')">删除</el-button>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
        <template v-else-if="ruleForm.taskAction === 2">
          <el-form-item :label="$t('任务范围')" prop="taskScope">
            <el-radio-group v-model="ruleForm.taskScope">
              <el-radio :label="0">{{ $t('不限制') }}</el-radio>
              <el-radio :label="1">{{ $t('限制方式') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.taskScope === 1">
            <el-form-item class="taskRange" prop="paywayList">
              <el-radio v-model="ruleForm.accountType" :label="1"
                @change="handleRadioChange(1, 'paywayList', 'thirdPaywayList')">{{ $t('公司账号')
                }}</el-radio>
              <el-checkbox-group v-model="ruleForm.paywayList" :disabled="ruleForm.accountType !== 1">
                <el-checkbox v-for="item of companyAccountList" :key="item.id" :label="item.id">{{ item.accountName
                }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item class="taskRange" prop="thirdPaywayList">
              <el-radio v-model="ruleForm.accountType" :label="2"
                @change="handleRadioChange(2, 'thirdPaywayList', 'paywayList')">{{ $t('第三方充值')
                }}</el-radio>
              <el-checkbox-group v-model="ruleForm.thirdPaywayList" :disabled="ruleForm.accountType !== 2">
                <el-checkbox v-for="item of thirdAccountList" :key="item.payPlatformCode"
                  :label="item.payPlatformCode">{{ item.payPlatformName }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form-item>
          <el-form-item :label="$t('任务目标与奖励')">
            <template>
              <el-form-item class="form-item-error" prop="participationCondition1">
                充值金额 >= <el-input type="text" v-model="ruleForm.participationCondition1"
                  @input="handleInput('participationCondition1')" clearable style="width: 100px" />
                才算有效充值单数
              </el-form-item>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  充值金额 >= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput('participationCondition2')" clearable style="width: 100px" /></el-form-item>
                <el-form-item class="form-item-error" prop="participationCondition3">
                  且 有效单数 >= <el-input type="text" v-model="ruleForm.participationCondition3"
                    @input="handleInput1('participationCondition3')" clearable style="width: 100px" /> 才算有效天数
                </el-form-item>
              </template>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  有效单数 >= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" /> 才算有效天数
                </el-form-item>
              </template>
            </template>
            <p v-if="ruleForm.taskType !== 2">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
            </p>
            <p v-if="ruleForm.taskType == 2 && ruleForm.rewardMode === 1">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
              每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期
            </p>
            <p v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
              <span>任务奖励</span>
            </p>
            <table class="rechargeTable" :class="{ weekTable: ruleForm.taskType === 2 }">
              <thead>
                <tr>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">任务目标</th>
                  <th v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">累计天数</th>
                  <th>奖励类型</th>
                  <th>奖励内容</th>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)"">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)"
                    v-for="(n, index) in num" :key="n">
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue'"
                      :rules="rules.conditionValue">
                      充值金额<el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue"
                        @input="handleInput2('conditionValue', index, 'rewardRules3')" clearable style="width: 100px" />
                    </el-form-item>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue2'"
                      :rules="rules.conditionValue">
                      <span v-if="ruleForm.taskType !== 2">有效单数</span>
                      <span v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1">有效天数</span>
                      <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue2"
                        @input="handleInput2('conditionValue2', index, 'rewardRules3')" clearable
                        style="width: 100px" />
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules3.${index}.awardsCheckedType_3`"
                      :rules="rules.awardsCheckedType_3">
                      <el-checkbox-group v-model="ruleForm.rewardRules3[index].awardsCheckedType_3"
                        @change="(value) => handleChange(value, 3, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_3[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules3[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules3[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules3')" clearable
                          style="width: 100px" />
                      </el-form-item>倍
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.couponId'"
                        :rules="awardsCheckedType_3[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules3[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.points'"
                        :rules="awardsCheckedType_3[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules3[index].points"
                          @input="handleInput2('points', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                  <td>
                    <el-button type="danger" @click="handeldelete(index, ruleForm.rewardRules3[index])">删除</el-button>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" v-for="(n, index) in days" :key="n">
                  <td>{{ n }}天</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules1.${index}.awardsCheckedType_1`"
                      :rules="rules.awardsCheckedType_1">
                      <el-checkbox-group v-model="ruleForm.rewardRules1[index].awardsCheckedType_1"
                        @change="(value) => handleChange(value, 1, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_1[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules1[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules1[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules1')" clearable
                          style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.couponId'"
                        :rules="awardsCheckedType_1[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules1[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.points'"
                        :rules="awardsCheckedType_1[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules1[index].points"
                          @input="handleInput2('points', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
                  <td>全勤奖</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules2.awardsCheckedType_2'"
                      :rules="rules.awardsCheckedType_2">
                      <el-checkbox-group v-model="ruleForm.rewardRules2.awardsCheckedType_2"
                        @change="(value) => handleChange(value, 2)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_2.includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusAmount'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules2.bonusAmount"
                          @input="handleInput3('bonusAmount')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules2.bonusWagerMultiplier"
                          @input="handleInput3('bonusWagerMultiplier')" clearable style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.couponId'"
                        :rules="awardsCheckedType_2.includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules2.couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.points'"
                        :rules="awardsCheckedType_2.includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules2.points" @input="handleInput3('points')"
                          clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
                </tbody>
            </table>
          </el-form-item>
        </template>
        <template v-else-if="ruleForm.taskAction === 3">
          <el-form-item :label="$t('任务范围')" prop="taskScope">
            <el-radio-group v-model="ruleForm.taskScope">
              <el-radio :label="0">{{ $t('不限制') }}</el-radio>
              <el-radio :label="1">{{ $t('限制方式') }}（提现方式限制不包含数字钱包类型）</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.taskScope === 1">
            <el-form-item class="taskRange" prop="withdrawList1">
              <el-radio v-model="ruleForm.withdrawAccountType" :label="1"
                @change="handleRadioChange(1, 'withdrawList1', 'withdrawList2')">{{ $t('提现方式')
                }}</el-radio>
              <el-checkbox-group v-model="ruleForm.withdrawList1" :disabled="ruleForm.withdrawAccountType !== 1">
                <el-checkbox v-for="item of withdrawTypeList" :key="item.id" :label="item.id">{{ item.name
                }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item class="taskRange" prop="withdrawList2">
              <el-radio v-model="ruleForm.withdrawAccountType" :label="2"
                @change="handleRadioChange(2, 'withdrawList2', 'withdrawList1')">{{ $t('提现钱包')
                }}</el-radio>
              <el-checkbox-group v-model="ruleForm.withdrawList2" :disabled="ruleForm.withdrawAccountType !== 2">
                <el-checkbox v-for="item of paymentTypeList" :key="item.id" :label="item.id">{{ item.name
                }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form-item>
          <el-form-item :label="$t('任务目标与奖励')">
            <template>
              <el-form-item class="form-item-error" prop="participationCondition1">
                提现金额 >= <el-input type="text" v-model="ruleForm.participationCondition1"
                  @input="handleInput('participationCondition1')" clearable style="width: 100px" /> 才算有效提现单数
              </el-form-item>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  提现金额 >= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput('participationCondition2')" clearable style="width: 100px" />
                </el-form-item>
                <el-form-item class="form-item-error" prop="participationCondition3">
                  且 有效单数>= <el-input type="text" v-model="ruleForm.participationCondition3"
                    @input="handleInput1('participationCondition3')" clearable style="width: 100px" /> 才算有效天数
                </el-form-item>
              </template>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  有效单数 >= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" />
                  才算有效天数
                </el-form-item>
              </template>
            </template>
            <p v-if="ruleForm.taskType !== 2">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
            </p>
            <p v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
              <span>任务奖励</span>
            </p>
            <p v-if="ruleForm.taskType == 2 && ruleForm.rewardMode === 1">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
              每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期
            </p>
            <table class="rechargeTable" :class="{ weekTable: ruleForm.taskType === 2 }">
              <thead>
                <tr>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">任务目标</th>
                  <th v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">累计天数</th>
                  <th>奖励类型</th>
                  <th>奖励内容</th>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)"
                  v-for="(n, index) in num" :key="n">
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue'"
                      :rules="rules.conditionValue">
                      提现金额 <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue"
                        @input="handleInput2('conditionValue', index, 'rewardRules3')" clearable style="width: 100px" />
                    </el-form-item>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue2'"
                      :rules="rules.conditionValue2">
                      <span v-if="ruleForm.taskType !== 2">有效单数</span>
                      <span v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1">有效天数</span>
                      <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue2"
                        @input="handleInput2('conditionValue2', index, 'rewardRules3')" clearable
                        style="width: 100px" />
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules3.${index}.awardsCheckedType_3`"
                      :rules="rules.awardsCheckedType_3">
                      <el-checkbox-group v-model="ruleForm.rewardRules3[index].awardsCheckedType_3"
                        @change="(value) => handleChange(value, 3, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_3[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules3[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules3[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules3')" clearable
                          style="width: 100px" />
                      </el-form-item>倍
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.couponId'"
                        :rules="awardsCheckedType_3[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules3[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.points'"
                        :rules="awardsCheckedType_3[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules3[index].points"
                          @input="handleInput2('points', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                  <td>
                    <el-button type="danger" @click="handeldelete(index, ruleForm.rewardRules3[index])">删除</el-button>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" v-for="(n, index) in days" :key="n">
                  <td>{{ n }}天</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules1.${index}.awardsCheckedType_1`"
                      :rules="rules.awardsCheckedType_1">
                      <el-checkbox-group v-model="ruleForm.rewardRules1[index].awardsCheckedType_1"
                        @change="(value) => handleChange(value, 1, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_1[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules1[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules1[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules1')" clearable
                          style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.couponId'"
                        :rules="awardsCheckedType_1[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules1[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.points'"
                        :rules="awardsCheckedType_1[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules1[index].points"
                          @input="handleInput2('points', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
                  <td>全勤奖</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules2.awardsCheckedType_2'"
                      :rules="rules.awardsCheckedType_2">
                      <el-checkbox-group v-model="ruleForm.rewardRules2.awardsCheckedType_2"
                        @change="(value) => handleChange(value, 2)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_2.includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusAmount'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules2.bonusAmount"
                          @input="handleInput3('bonusAmount')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules2.bonusWagerMultiplier"
                          @input="handleInput3('bonusWagerMultiplier')" clearable style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.couponId'"
                        :rules="awardsCheckedType_2.includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules2.couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.points'"
                        :rules="awardsCheckedType_2.includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules2.points" @input="handleInput3('points')"
                          clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-form-item>
        </template>
        <template v-else-if="ruleForm.taskAction === 4">
          <el-form-item :label="$t('任务范围')" prop="taskScope">
            <el-radio-group v-model="ruleForm.taskScope">
              <el-radio :label="0">{{ $t('不限制') }}</el-radio>
              <el-radio :label="1">{{ $t('限制方式') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 投注111111111111 -->
          <el-form-item v-if="ruleForm.taskScope === 1" prop="gameSelection">
            <el-form-item>
              <el-radio v-for="item of ruleForm.gameList" :key="item.value" v-model="taskMethod" :label="item.value">{{
                item.label }}</el-radio>
            </el-form-item>
            <el-tabs type="border-card">
              <el-tab-pane v-for="(gameType, index) in filterGameList" :key="gameType.value" :label="gameType.label">
                <el-collapse v-for="(category, cIndex) in gameType.children" :key="`category_${category.value}`"
                  v-model="category.collapse">
                  <el-collapse-item :name="category.value">
                    <template #title>
                      <el-checkbox v-model="category.checked" :indeterminate="category.indeterminate"
                        @change="handleCheckedChange(category)">
                        {{ category.label }}
                      </el-checkbox>
                    </template>
                    <template v-if="category.children">
                      <template v-if="gameType.value === '1000005'">
                        <el-collapse v-for="(subCategory, sIndex) in category.children"
                          :key="`sub_${subCategory.value}`" style="margin-left: 20px;" v-model="subCategory.collapse">
                          <el-collapse-item :name="subCategory.value">
                            <template #title>
                              <el-checkbox v-model="subCategory.checked" :indeterminate="subCategory.indeterminate"
                                @change="handleCheckedChange(subCategory)">
                                {{ subCategory.label }}
                              </el-checkbox>
                            </template>
                            <div v-if="subCategory.children" style="margin-left: 20px;">
                              <el-checkbox v-for="item in subCategory.children" :key="item.value" v-model="item.checked"
                                :indeterminate="item.indeterminate" @change="handleCheckedChange(item)">
                                {{ item.label }}
                              </el-checkbox>
                            </div>
                          </el-collapse-item>
                        </el-collapse>
                      </template>
                      <div v-else style="margin-left: 20px;">
                        <el-checkbox v-for="item in category.children" :key="item.value" v-model="item.checked"
                          :indeterminate="item.indeterminate" @change="handleCheckedChange(item)">
                          {{ item.label }}
                        </el-checkbox>
                      </div>
                    </template>
                  </el-collapse-item>
                </el-collapse>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
          <el-form-item :label="$t('任务目标与奖励')">
            <span>计算方式</span><br />
            <el-radio-group v-model="ruleForm.calculationMethod" @change="initFormData">
              <el-radio :label="1">{{ $t('投注金额') }}</el-radio>
              <el-radio :label="2">{{ $t('盈利金额') }}</el-radio>
              <el-radio :label="3">{{ $t('亏损金额') }}</el-radio>
              <el-radio :label="4">{{ $t('连赢加奖') }}</el-radio>
              <el-radio :label="5">{{ $t('投注单量') }}</el-radio>
              <el-radio :label="6">{{ $t('单注派奖') }}</el-radio>
            </el-radio-group>
            <p>
              <el-form-item class="form-item-error" prop="participationCondition1">
                投注金额 >= <el-input type="text" v-model="ruleForm.participationCondition1"
                  @input="handleInput('participationCondition1')" clearable style="width: 100px" /> 才算有效投注单数
              </el-form-item>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  <span v-if="ruleForm.calculationMethod === 1">投注金额 >= </span>
                  <span v-else-if="ruleForm.calculationMethod === 2">盈利金额 >= </span>
                  <span v-else-if="ruleForm.calculationMethod === 3">亏损金额 >= </span>
                  <span v-else-if="ruleForm.calculationMethod === 4">连赢期数 >= </span>
                  <span v-else-if="ruleForm.calculationMethod === 5">投注单量 >= </span>
                  <span v-else>中奖金额 >= </span>
                  &nbsp;
                  <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput('participationCondition2')" clearable style="width: 100px" />
                </el-form-item>
                <template v-if="ruleForm.calculationMethod !== 5 && ruleForm.calculationMethod !== 6">
                  <el-form-item class="form-item-error" prop="participationCondition3">
                    且 有效单数 >= <el-input type="text" v-model="ruleForm.participationCondition3"
                      @input="handleInput1('participationCondition3')" clearable style="width: 100px" />
                    才算有效天数
                  </el-form-item>
                </template>
              </template>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  有效单数 >= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" /> 才算有效天数
                </el-form-item>
              </template>
            </p>
            <p v-if="ruleForm.taskType !== 2">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
            </p>
            <p v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
              <span>任务奖励</span>
            </p>
            <p v-if="ruleForm.taskType == 2 && ruleForm.rewardMode === 1">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
              每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期
            </p>
            <table class="rechargeTable" :class="{ weekTable: ruleForm.taskType === 2 }">
              <thead>
                <tr>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">任务目标</th>
                  <th v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">累计天数</th>
                  <th>奖励类型</th>
                  <th>奖励内容</th>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)"
                  v-for="(n, index) in num" :key="n">
                  <td>
                    <span v-if="ruleForm.calculationMethod === 1">投注金额</span>
                    <span v-else-if="ruleForm.calculationMethod === 2">盈利金额</span>
                    <span v-else-if="ruleForm.calculationMethod === 3">亏损金额</span>
                    <span v-else-if="ruleForm.calculationMethod === 4">连赢期数</span>
                    <span v-else-if="ruleForm.calculationMethod === 5">投注单量</span>
                    <span v-else>中奖金额</span>
                    &nbsp;
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue'"
                      :rules="rules.conditionValue">
                      <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue"
                        @input="handleInput2('conditionValue', index, 'rewardRules3')" clearable style="width: 100px" />
                    </el-form-item>
                    <template v-if="ruleForm.rewardMode === 2">
                      <p v-if="ruleForm.calculationMethod !== 5 && ruleForm.calculationMethod !== 6">
                        <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue2'"
                          :rules="rules.conditionValue2">
                          <span v-if="ruleForm.taskType !== 2">有效单数</span>
                          <span v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1">有效天数</span>
                          <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue2"
                            @input="handleInput2('conditionValue2', index, 'rewardRules3')" clearable
                            style="width: 100px" />
                        </el-form-item>
                      </p>
                    </template>
                    <template v-if="ruleForm.rewardMode != 2">
                      <p>
                        <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue2'"
                          :rules="rules.conditionValue2">
                          <span v-if="ruleForm.taskType !== 2">有效单数</span>
                          <span v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1">有效天数</span>
                          <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue2"
                            @input="handleInput2('conditionValue2', index, 'rewardRules3')" clearable
                            style="width: 100px" />
                        </el-form-item>
                      </p>
                    </template>
                  </td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules3.${index}.awardsCheckedType_3`"
                      :rules="rules.awardsCheckedType_3">
                      <el-checkbox-group v-model="ruleForm.rewardRules3[index].awardsCheckedType_3"
                        @change="(value) => handleChange(value, 3, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_3[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules3[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules3[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules3')" clearable
                          style="width: 100px" />
                      </el-form-item>倍
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.couponId'"
                        :rules="awardsCheckedType_3[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules3[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.points'"
                        :rules="awardsCheckedType_3[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules3[index].points"
                          @input="handleInput2('points', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                  <td>
                    <el-button type="danger" @click="handeldelete(index, ruleForm.rewardRules3[index])">删除</el-button>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" v-for="(n, index) in days" :key="n">
                  <td>{{ n }}天</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules1.${index}.awardsCheckedType_1`"
                      :rules="rules.awardsCheckedType_1">
                      <el-checkbox-group v-model="ruleForm.rewardRules1[index].awardsCheckedType_1"
                        @change="(value) => handleChange(value, 1, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_1[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules1[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules1[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules1')" clearable
                          style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.couponId'"
                        :rules="awardsCheckedType_1[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules1[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.points'"
                        :rules="awardsCheckedType_1[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules1[index].points"
                          @input="handleInput2('points', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
                  <td>全勤奖</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules2.awardsCheckedType_2'"
                      :rules="rules.awardsCheckedType_2">
                      <el-checkbox-group v-model="ruleForm.rewardRules2.awardsCheckedType_2"
                        @change="(value) => handleChange(value, 2)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_2.includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusAmount'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules2.bonusAmount"
                          @input="handleInput3('bonusAmount')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules2.bonusWagerMultiplier"
                          @input="handleInput3('bonusWagerMultiplier')" clearable style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.couponId'"
                        :rules="awardsCheckedType_2.includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules2.couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.points'"
                        :rules="awardsCheckedType_2.includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules2.points" @input="handleInput3('points')"
                          clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-form-item>
        </template>
        <template v-else-if="ruleForm.taskAction === 5">
          <el-form-item :label="$t('任务范围')" prop="taskScope">
            <el-radio-group v-model="ruleForm.taskScope">
              <el-radio :label="0">{{ $t('不限制') }}</el-radio>
              <el-radio :label="1">{{ $t('限制方式') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.taskScope === 1" prop="taskRangeFocusChecked">
            <el-checkbox-group v-model="ruleForm.taskRangeFocusChecked">
              <el-checkbox v-for="taskRange of formData.taskRangeFocusList" :key="taskRange" :label="taskRange">{{
                taskRange }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('任务目标与奖励')">
            <template>
              <el-form-item class="form-item-error" prop="participationCondition1">
                投注金额 >= <el-input type="text" v-model="ruleForm.participationCondition1"
                  @input="handleInput('participationCondition1')" clearable style="width: 100px" /> 才算有效投注单数
              </el-form-item>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  关注人数 >= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" />
                </el-form-item>
                <el-form-item class="form-item-error" prop="participationCondition3">
                  且 有效单数 <el-input type="text" v-model="ruleForm.participationCondition3"
                    @input="handleInput1('participationCondition3')" clearable style="width: 100px" />
                  才算有效天数
                </el-form-item>
              </template>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  有效单数 >= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" /> 才算有效天数
                </el-form-item>
              </template>
            </template>
            <p v-if="ruleForm.taskType !== 2">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
            </p>
            <p v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
              <span>任务奖励</span>
            </p>
            <p v-if="ruleForm.taskType == 2 && ruleForm.rewardMode === 1">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
              每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期
            </p>
            <table class="rechargeTable" :class="{ weekTable: ruleForm.taskType === 2 }">
              <thead>
                <tr>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">任务目标</th>
                  <th v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">累计天数</th>
                  <th>奖励类型</th>
                  <th>奖励内容</th>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)"
                  v-for="(n, index) in num" :key="n">
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue'"
                      :rules="rules.conditionValue">
                      关注人数 <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue"
                        @input="handleInput2('conditionValue', index, 'rewardRules3', 'num')" clearable
                        style="width: 100px" />
                    </el-form-item>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue2'"
                      :rules="rules.conditionValue2">
                      <span v-if="ruleForm.taskType !== 2">有效单数</span>
                      <span v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1">有效天数</span>
                      <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue2"
                        @input="handleInput2('conditionValue2', index, 'rewardRules3', 'num')" clearable
                        style="width: 100px" />
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules3.${index}.awardsCheckedType_3`"
                      :rules="rules.awardsCheckedType_3">
                      <el-checkbox-group v-model="ruleForm.rewardRules3[index].awardsCheckedType_3"
                        @change="(value) => handleChange(value, 3, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_3[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules3[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules3[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules3')" clearable
                          style="width: 100px" />
                      </el-form-item>倍
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.couponId'"
                        :rules="awardsCheckedType_3[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules3[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.points'"
                        :rules="awardsCheckedType_3[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules3[index].points"
                          @input="handleInput2('points', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                  <td>
                    <el-button type="danger" @click="handeldelete(index, ruleForm.rewardRules3[index])">删除</el-button>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" v-for="(n, index) in days" :key="n">
                  <td>{{ n }}天</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules1.${index}.awardsCheckedType_1`"
                      :rules="rules.awardsCheckedType_1">
                      <el-checkbox-group v-model="ruleForm.rewardRules1[index].awardsCheckedType_1"
                        @change="(value) => handleChange(value, 1, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_1[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules1[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules1[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules1')" clearable
                          style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.couponId'"
                        :rules="awardsCheckedType_1[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules1[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.points'"
                        :rules="awardsCheckedType_1[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules1[index].points"
                          @input="handleInput2('points', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
                  <td>全勤奖</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules2.awardsCheckedType_2'"
                      :rules="rules.awardsCheckedType_2">
                      <el-checkbox-group v-model="ruleForm.rewardRules2.awardsCheckedType_2"
                        @change="(value) => handleChange(value, 2)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_2.includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusAmount'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules2.bonusAmount"
                          @input="handleInput3('bonusAmount')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules2.bonusWagerMultiplier"
                          @input="handleInput3('bonusWagerMultiplier')" clearable style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.couponId'"
                        :rules="awardsCheckedType_2.includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules2.couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.points'"
                        :rules="awardsCheckedType_2.includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules2.points" @input="handleInput3('points')"
                          clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-form-item>
        </template>
        <template v-else-if="ruleForm.taskAction === 6">
          <el-form-item :label="$t('任务范围')" prop="taskScope">
            <el-radio-group v-model="ruleForm.taskScope">
              <el-radio :label="0">{{ $t('不限制') }}</el-radio>
              <el-radio :label="1">{{ $t('限制方式') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.taskScope === 1" prop="taskRangeFatieChecked">
            <el-checkbox-group v-model="ruleForm.taskRangeFatieChecked">
              <el-checkbox v-for="taskRange of formData.taskRangeFatieList" :key="taskRange" :label="taskRange">{{
                taskRange }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('任务目标与奖励')">
            <template>
              <el-form-item class="form-item-error" prop="participationCondition1">
                帖子字数 >= <el-input type="text" v-model="ruleForm.participationCondition1"
                  @input="handleInput1('participationCondition1')" clearable style="width: 100px" /> 才算有效帖子
              </el-form-item>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  有效帖子数量 >= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" />
                  才算有效天数
                </el-form-item>
              </template>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  有效帖子数量>= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" />才算有效天数
                </el-form-item>
              </template>
            </template>
            <p v-if="ruleForm.taskType !== 2">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
            </p>
            <p v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
              <span>任务奖励</span>
            </p>
            <p v-if="ruleForm.taskType == 2 && ruleForm.rewardMode === 1">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
              每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期
            </p>
            <table class="rechargeTable" :class="{ weekTable: ruleForm.taskType === 2 }">
              <thead>
                <tr>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">任务目标</th>
                  <th v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">累计天数</th>
                  <th>奖励类型</th>
                  <th>奖励内容</th>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)"
                  v-for="(n, index) in num" :key="n">
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue'"
                      :rules="rules.conditionValue">
                      有效帖子数量 <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue"
                        @input="handleInput2('conditionValue', index, 'rewardRules3', 'num')" clearable
                        style="width: 100px" />
                    </el-form-item>
                    <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue2'"
                        :rules="rules.conditionValue2">
                        有效天数<el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue2"
                          @input="handleInput2('conditionValue2', index, 'rewardRules3', 'num')" clearable
                          style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules3.${index}.awardsCheckedType_3`"
                      :rules="rules.awardsCheckedType_3">
                      <el-checkbox-group v-model="ruleForm.rewardRules3[index].awardsCheckedType_3"
                        @change="(value) => handleChange(value, 3, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_3[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules3[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules3[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules3')" clearable
                          style="width: 100px" />
                      </el-form-item>倍
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.couponId'"
                        :rules="awardsCheckedType_3[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules3[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.points'"
                        :rules="awardsCheckedType_3[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules3[index].points"
                          @input="handleInput2('points', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                  <td>
                    <el-button type="danger" @click="handeldelete(index, ruleForm.rewardRules3[index])">删除</el-button>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" v-for="(n, index) in days" :key="n">
                  <td>{{ n }}天</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules1.${index}.awardsCheckedType_1`"
                      :rules="rules.awardsCheckedType_1">
                      <el-checkbox-group v-model="ruleForm.rewardRules1[index].awardsCheckedType_1"
                        @change="(value) => handleChange(value, 1, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_1[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules1[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules1[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules1')" clearable
                          style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.couponId'"
                        :rules="awardsCheckedType_1[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules1[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.points'"
                        :rules="awardsCheckedType_1[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules1[index].points"
                          @input="handleInput2('points', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
                  <td>全勤奖</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules2.awardsCheckedType_2'"
                      :rules="rules.awardsCheckedType_2">
                      <el-checkbox-group v-model="ruleForm.rewardRules2.awardsCheckedType_2"
                        @change="(value) => handleChange(value, 2)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_2.includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusAmount'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules2.bonusAmount"
                          @input="handleInput3('bonusAmount')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules2.bonusWagerMultiplier"
                          @input="handleInput3('bonusWagerMultiplier')" clearable style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.couponId'"
                        :rules="awardsCheckedType_2.includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules2.couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.points'"
                        :rules="awardsCheckedType_2.includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules2.points" @input="handleInput3('points')"
                          clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-form-item>
        </template>
        <template v-else-if="ruleForm.taskAction === 7">
          <el-form-item :label="$t('任务范围')" prop="taskScope">
            <el-radio-group v-model="ruleForm.taskScope">
              <el-radio :label="0">{{ $t('不限制') }}</el-radio>
              <el-radio :label="1">{{ $t('限制方式') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.taskScope === 1" prop="taskRangeRemarkChecked">
            <el-checkbox-group v-model="ruleForm.taskRangeRemarkChecked">
              <el-checkbox v-for="taskRange of formData.taskRangeRemarkList" :key="taskRange" :label="taskRange">{{
                taskRange }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('任务目标与奖励')">
            <template>
              <el-form-item class="form-item-error" prop="participationCondition1">
                评论字数 >= <el-input type="text" v-model="ruleForm.participationCondition1"
                  @input="handleInput1('participationCondition1')" clearable style="width: 100px" /> 才算有效评论
              </el-form-item>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  有效评论数量 >= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" />
                  才算有效天数
                </el-form-item>
              </template>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  有效评论数量>= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" />才算有效天数
                </el-form-item>
              </template>
            </template>
            <p v-if="ruleForm.taskType !== 2">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
            </p>
            <p v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
              <span>任务奖励</span>
            </p>
            <p v-if="ruleForm.taskType == 2 && ruleForm.rewardMode === 1">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
              每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期
            </p>
            <table class="rechargeTable" :class="{ weekTable: ruleForm.taskType === 2 }">
              <thead>
                <tr>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">任务目标</th>
                  <th v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">累计天数</th>
                  <th>奖励类型</th>
                  <th>奖励内容</th>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)"
                  v-for="(n, index) in num" :key="n">
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue'"
                      :rules="rules.conditionValue">
                      有效评论数量 <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue"
                        @input="handleInput2('conditionValue', index, 'rewardRules3', 'num')" clearable
                        style="width: 100px" />
                    </el-form-item>
                    <p v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue2'"
                        :rules="rules.conditionValue2">
                        有效天数<el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue2"
                          @input="handleInput2('conditionValue2', index, 'rewardRules3', 'num')" clearable
                          style="width: 100px" />
                      </el-form-item>
                    </p>
                  </td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules3.${index}.awardsCheckedType_3`"
                      :rules="rules.awardsCheckedType_3">
                      <el-checkbox-group v-model="ruleForm.rewardRules3[index].awardsCheckedType_3"
                        @change="(value) => handleChange(value, 3, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_3[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules3[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules3[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules3')" clearable
                          style="width: 100px" />
                      </el-form-item>倍
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.couponId'"
                        :rules="awardsCheckedType_3[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules3[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.points'"
                        :rules="awardsCheckedType_3[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules3[index].points"
                          @input="handleInput2('points', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                  <td>
                    <el-button type="danger" @click="handeldelete(index, ruleForm.rewardRules3[index])">删除</el-button>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" v-for="(n, index) in days" :key="n">
                  <td>{{ n }}天</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules1.${index}.awardsCheckedType_1`"
                      :rules="rules.awardsCheckedType_1">
                      <el-checkbox-group v-model="ruleForm.rewardRules1[index].awardsCheckedType_1"
                        @change="(value) => handleChange(value, 1, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_1[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules1[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules1[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules1')" clearable
                          style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.couponId'"
                        :rules="awardsCheckedType_1[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules1[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.points'"
                        :rules="awardsCheckedType_1[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules1[index].points"
                          @input="handleInput2('points', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
                  <td>全勤奖</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules2.awardsCheckedType_2'"
                      :rules="rules.awardsCheckedType_2">
                      <el-checkbox-group v-model="ruleForm.rewardRules2.awardsCheckedType_2"
                        @change="(value) => handleChange(value, 2)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_2.includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusAmount'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules2.bonusAmount"
                          @input="handleInput3('bonusAmount')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules2.bonusWagerMultiplier"
                          @input="handleInput3('bonusWagerMultiplier')" clearable style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.couponId'"
                        :rules="awardsCheckedType_2.includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules2.couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.points'"
                        :rules="awardsCheckedType_2.includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules2.points" @input="handleInput3('points')"
                          clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-form-item>
        </template>
        <template v-else-if="ruleForm.taskAction === 8">
          <el-form-item :label="$t('任务范围')" prop="taskScope">
            <el-radio-group v-model="ruleForm.taskScope">
              <el-radio :label="0">{{ $t('不限制') }}</el-radio>
              <el-radio :label="1">{{ $t('限制方式') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.taskScope === 1" prop="taskRangeRewardChecked">
            <el-checkbox-group v-model="ruleForm.taskRangeRewardChecked">
              <el-checkbox v-for="taskRange of formData.taskRangeRewardList" :key="taskRange" :label="taskRange">{{
                taskRange }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('任务目标与奖励')">
            <template>
              <el-form-item class="form-item-error" prop="participationCondition1">
                打赏金额 >= <el-input type="text" v-model="ruleForm.participationCondition1"
                  @input="handleInput('participationCondition1')" clearable style="width: 100px" /> 才算有效打赏单数
              </el-form-item>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  打赏金额 >= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput('participationCondition2')" clearable style="width: 100px" />
                </el-form-item>
                <el-form-item class="form-item-error" prop="participationCondition3">
                  且 有效单数 <el-input type="text" v-model="ruleForm.participationCondition3"
                    @input="handleInput1('participationCondition3')" clearable style="width: 100px" />
                  才算有效天数
                </el-form-item>
              </template>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  有效单数>= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" />才算有效天数
                </el-form-item>
              </template>
            </template>
            <p v-if="ruleForm.taskType !== 2">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
            </p>
            <p v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
              <span>任务奖励</span>
            </p>
            <p v-if="ruleForm.taskType == 2 && ruleForm.rewardMode === 1">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
              每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期
            </p>
            <table class="rechargeTable" :class="{ weekTable: ruleForm.taskType === 2 }">
              <thead>
                <tr>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">任务目标</th>
                  <th v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">累计天数</th>
                  <th>奖励类型</th>
                  <th>奖励内容</th>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)"
                  v-for="(n, index) in num" :key="n">
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue'"
                      :rules="rules.conditionValue">
                      打赏金额 <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue"
                        @input="handleInput2('conditionValue', index, 'rewardRules3')" clearable style="width: 100px" />
                    </el-form-item>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue2'"
                      :rules="rules.conditionValue2">
                      <span v-if="ruleForm.taskType !== 2">有效单数</span>
                      <span v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1">有效天数</span>
                      <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue2"
                        @input="handleInput2('conditionValue2', index, 'rewardRules3')" clearable
                        style="width: 100px" />
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules3.${index}.awardsCheckedType_3`"
                      :rules="rules.awardsCheckedType_3">
                      <el-checkbox-group v-model="ruleForm.rewardRules3[index].awardsCheckedType_3"
                        @change="(value) => handleChange(value, 3, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_3[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules3[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules3[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules3')" clearable
                          style="width: 100px" />
                      </el-form-item>倍
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.couponId'"
                        :rules="awardsCheckedType_3[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules3[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.points'"
                        :rules="awardsCheckedType_3[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules3[index].points"
                          @input="handleInput2('points', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                  <td>
                    <el-button type="danger" @click="handeldelete(index, ruleForm.rewardRules3[index])">删除</el-button>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" v-for="(n, index) in days" :key="n">
                  <td>{{ n }}天</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules1.${index}.awardsCheckedType_1`"
                      :rules="rules.awardsCheckedType_1">
                      <el-checkbox-group v-model="ruleForm.rewardRules1[index].awardsCheckedType_1"
                        @change="(value) => handleChange(value, 1, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_1[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules1[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules1[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules1')" clearable
                          style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.couponId'"
                        :rules="awardsCheckedType_1[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules1[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.points'"
                        :rules="awardsCheckedType_1[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules1[index].points"
                          @input="handleInput2('points', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
                  <td>全勤奖</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules2.awardsCheckedType_2'"
                      :rules="rules.awardsCheckedType_2">
                      <el-checkbox-group v-model="ruleForm.rewardRules2.awardsCheckedType_2"
                        @change="(value) => handleChange(value, 2)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_2.includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusAmount'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules2.bonusAmount"
                          @input="handleInput3('bonusAmount')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules2.bonusWagerMultiplier"
                          @input="handleInput3('bonusWagerMultiplier')" clearable style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.couponId'"
                        :rules="awardsCheckedType_2.includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules2.couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.points'"
                        :rules="awardsCheckedType_2.includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules2.points" @input="handleInput3('points')"
                          clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-form-item>
        </template>
        <template v-else-if="ruleForm.taskAction === 9">
          <el-form-item :label="$t('任务范围')" prop="taskScope">
            <el-radio-group v-model="ruleForm.taskScope">
              <el-radio :label="0">{{ $t('不限制') }}</el-radio>
              <el-radio :label="1">{{ $t('限制方式') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.taskScope === 1" prop="taskRangeBrowseChecked">
            <el-checkbox-group v-model="ruleForm.taskRangeBrowseChecked">
              <el-checkbox v-for="taskRange of formData.taskRangeBrowseList" :key="taskRange" :label="taskRange">{{
                taskRange }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('任务目标与奖励')">
            <template>
              <el-form-item class="form-item-error" prop="participationCondition1">
                观看时间 >= <el-input type="text" v-model="ruleForm.participationCondition1"
                  @input="handleInput1('participationCondition1')" clearable style="width: 100px" /> S，才算有效观看
              </el-form-item>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  观看时长（min） >= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" />
                </el-form-item>
                <el-form-item class="form-item-error" prop="participationCondition3">
                  且 有效观看 <el-input type="text" v-model="ruleForm.participationCondition3"
                    @input="handleInput1('participationCondition3')" clearable style="width: 100px" />
                  才算有效天数
                </el-form-item>
              </template>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition2">
                  有效观看>= <el-input type="text" v-model="ruleForm.participationCondition2"
                    @input="handleInput1('participationCondition2')" clearable style="width: 100px" />才算有效天数
                </el-form-item>
              </template>
            </template>
            <p v-if="ruleForm.taskType !== 2">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
            </p>
            <p v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
              <span>任务奖励</span>
            </p>
            <p v-if="ruleForm.taskType == 2 && ruleForm.rewardMode === 1">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
              每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期
            </p>
            <table class="browseTable" :class="{ weekTable: ruleForm.taskType === 2 }">
              <thead>
                <tr>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">任务目标</th>
                  <th v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">累计天数</th>
                  <th>奖励类型</th>
                  <th>奖励内容</th>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)"
                  v-for="(n, index) in num" :key="n">
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue'"
                      :rules="rules.conditionValue">
                      观看时长（min）<el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue"
                        @input="handleInput2('conditionValue', index, 'rewardRules3', 'num')" clearable
                        style="width: 100px" />
                    </el-form-item>
                    <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue2'"
                      :rules="rules.conditionValue2">
                      <span v-if="ruleForm.taskType !== 2">有效观看</span>
                      <span v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1">有效天数</span>
                      <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue2"
                        @input="handleInput2('conditionValue2', index, 'rewardRules3', 'num')" clearable
                        style="width: 100px" />
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules3.${index}.awardsCheckedType_3`"
                      :rules="rules.awardsCheckedType_3">
                      <el-checkbox-group v-model="ruleForm.rewardRules3[index].awardsCheckedType_3"
                        @change="(value) => handleChange(value, 3, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_3[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules3[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules3[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules3')" clearable
                          style="width: 100px" />
                      </el-form-item>倍
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.couponId'"
                        :rules="awardsCheckedType_3[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules3[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.points'"
                        :rules="awardsCheckedType_3[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules3[index].points"
                          @input="handleInput2('points', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                  <td>
                    <el-button type="danger" @click="handeldelete(index, ruleForm.rewardRules3[index])">删除</el-button>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" v-for="(n, index) in days" :key="n">
                  <td>{{ n }}天</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules1.${index}.awardsCheckedType_1`"
                      :rules="rules.awardsCheckedType_1">
                      <el-checkbox-group v-model="ruleForm.rewardRules1[index].awardsCheckedType_1"
                        @change="(value) => handleChange(value, 1, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_1[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules1[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules1[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules1')" clearable
                          style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.couponId'"
                        :rules="awardsCheckedType_1[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules1[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.points'"
                        :rules="awardsCheckedType_1[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules1[index].points"
                          @input="handleInput2('points', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
                  <td>全勤奖</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules2.awardsCheckedType_2'"
                      :rules="rules.awardsCheckedType_2">
                      <el-checkbox-group v-model="ruleForm.rewardRules2.awardsCheckedType_2"
                        @change="(value) => handleChange(value, 2)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_2.includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusAmount'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules2.bonusAmount"
                          @input="handleInput3('bonusAmount')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules2.bonusWagerMultiplier"
                          @input="handleInput3('bonusWagerMultiplier')" clearable style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.couponId'"
                        :rules="awardsCheckedType_2.includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules2.couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.points'"
                        :rules="awardsCheckedType_2.includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules2.points" @input="handleInput3('points')"
                          clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item :label="$t('任务目标与奖励')">
            <template>
              <el-form-item class="form-item-error" prop="participationCondition1">
                充值金额 >= <el-input type="text" v-model="ruleForm.participationCondition1"
                  @input="handleInput('participationCondition1')" clearable style="width: 100px; margin-right: 10px;" />
              </el-form-item>
              <el-form-item class="form-item-error" prop="participationCondition2">
                且 投注金额 >= <el-input type="number" v-model="ruleForm.participationCondition2"
                  @input="handleInput('participationCondition2')" clearable style="width: 100px" /> 才算有效好友
              </el-form-item>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition3">
                  有效好友 >= <el-input type="text" v-model="ruleForm.participationCondition3"
                    @input="handleInput1('participationCondition3')" clearable style="width: 100px" />
                  才算有天数
                </el-form-item>
              </template>
              <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1" style="margin-left: 40px;">
                <el-form-item class="form-item-error" prop="participationCondition3">
                  有效好友>= <el-input type="text" v-model="ruleForm.participationCondition3"
                    @input="handleInput1('participationCondition3')" clearable style="width: 100px" />才算有效天数
                </el-form-item>
              </template>
            </template>
            <p v-if="ruleForm.taskType !== 2">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
            </p>
            <p v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
              <span>任务奖励</span>
            </p>
            <p v-if="ruleForm.taskType == 2 && ruleForm.rewardMode === 1">
              <span>阶梯奖励</span> <el-button type="success" class="add" @click="handleAdd">添加</el-button>
              每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期
            </p>
            <table :class="{ weekTable: ruleForm.taskType === 2 }">
              <thead>
                <tr>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">任务目标</th>
                  <th v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">累计天数</th>
                  <th>奖励类型</th>
                  <th>奖励内容</th>
                  <th v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="ruleForm.taskType !== 2 || (ruleForm.taskType === 2 && ruleForm.rewardMode === 1)"
                  v-for="(n, index) in num" :key="n">
                  <td>
                    <template v-if="ruleForm.taskType !== 2">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue'"
                        :rules="rules.conditionValue">
                        有效好友 <el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue"
                          @input="handleInput2('conditionValue', index, 'rewardRules3', 'num')" clearable
                          style="width: 100px" />
                      </el-form-item>
                    </template>
                    <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue'"
                        :rules="rules.conditionValue">
                        邀请人数<el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue"
                          @input="handleInput2('conditionValue', index, 'rewardRules3', 'num')" clearable
                          style="width: 100px" />
                      </el-form-item>
                    </template>
                    <template v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 1">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.conditionValue2'"
                        :rules="rules.conditionValue2">
                        有效天数<el-input type="text" v-model="ruleForm.rewardRules3[index].conditionValue2"
                          @input="handleInput2('conditionValue2', index, 'rewardRules3', 'num')" clearable
                          style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules3.${index}.awardsCheckedType_3`"
                      :rules="rules.awardsCheckedType_3">
                      <el-checkbox-group v-model="ruleForm.rewardRules3[index].awardsCheckedType_3"
                        @change="(value) => handleChange(value, 3, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_3[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules3[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_3[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules3[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules3')" clearable
                          style="width: 100px" />
                      </el-form-item>倍
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.couponId'"
                        :rules="awardsCheckedType_3[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules3[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_3[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules3.' + index + '.points'"
                        :rules="awardsCheckedType_3[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules3[index].points"
                          @input="handleInput2('points', index, 'rewardRules3')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                  <td>
                    <el-button type="danger" @click="handeldelete(index, ruleForm.rewardRules3[index])">删除</el-button>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2" v-for="(n, index) in days" :key="n">
                  <td>{{ n }}天</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="`rewardRules1.${index}.awardsCheckedType_1`"
                      :rules="rules.awardsCheckedType_1">
                      <el-checkbox-group v-model="ruleForm.rewardRules1[index].awardsCheckedType_1"
                        @change="(value) => handleChange(value, 1, index)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_1[index].includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusAmount'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules1[index].bonusAmount"
                          @input="handleInput2('bonusAmount', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_1[index].includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules1[index].bonusWagerMultiplier"
                          @input="handleInput2('bonusWagerMultiplier', index, 'rewardRules1')" clearable
                          style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.couponId'"
                        :rules="awardsCheckedType_1[index].includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules1[index].couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_1[index].includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules1.' + index + '.points'"
                        :rules="awardsCheckedType_1[index].includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules1[index].points"
                          @input="handleInput2('points', index, 'rewardRules1')" clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
                <tr v-if="ruleForm.taskType === 2 && ruleForm.rewardMode === 2">
                  <td>全勤奖</td>
                  <td>
                    <el-form-item class="form-item-error" :prop="'rewardRules2.awardsCheckedType_2'"
                      :rules="rules.awardsCheckedType_2">
                      <el-checkbox-group v-model="ruleForm.rewardRules2.awardsCheckedType_2"
                        @change="(value) => handleChange(value, 2)">
                        <el-checkbox v-for="(item, i) in awardsType" :key="item.type" :label="item.type">{{ item.type
                        }}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </td>
                  <td>
                    <template v-if="awardsCheckedType_2.includes('彩金')">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusAmount'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusAmount : []">
                        彩金 <el-input type="text" v-model="ruleForm.rewardRules2.bonusAmount"
                          @input="handleInput3('bonusAmount')" clearable style="width: 100px" />
                      </el-form-item>
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.bonusWagerMultiplier'"
                        :rules="awardsCheckedType_2.includes('彩金') ? rules.bonusMultiplier : []">
                        打码<el-input type="text" v-model="ruleForm.rewardRules2.bonusWagerMultiplier"
                          @input="handleInput3('bonusWagerMultiplier')" clearable style="width: 100px" />倍
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('优惠券')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.couponId'"
                        :rules="awardsCheckedType_2.includes('优惠券') ? rules.couponId : []">
                        优惠券 <el-select v-model="ruleForm.rewardRules2.couponId" :placeholder="$t('商品类目')"
                          class="filter-item" style="width: 220px" clearable filterable multiple :multiple-limit="5"
                          collapse-tags>
                          <el-option v-for="item in couponList" :key="item.couponId" :value="item.couponId"
                            :label="item.couponTitle" />
                        </el-select>
                      </el-form-item>
                    </template>
                    <template v-if="awardsCheckedType_2.includes('积分')" class="ml">
                      <el-form-item class="form-item-error" :prop="'rewardRules2' + '.points'"
                        :rules="awardsCheckedType_2.includes('积分') ? rules.points : []">
                        积分<el-input type="text" v-model="ruleForm.rewardRules2.points" @input="handleInput3('points')"
                          clearable style="width: 100px" />
                      </el-form-item>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-form-item>
        </template>
        <el-form-item :label="$t('温馨提示')" prop="warmTips">
          <tinymce :disabled="emitName === 'details'" ref="tinymce" v-model="ruleForm.warmTips" :height="200" />
        </el-form-item>
        <el-form-item :label="$t('活动规则')" prop="ruleDisplayType">
          <el-radio-group v-model="ruleForm.ruleDisplayType" @change="changeRuleDisplayType">
            <el-radio :label="1">{{ $t('富文本') }}</el-radio>
            <el-radio :label="2">{{ $t('图片') }}</el-radio>
          </el-radio-group>
          <el-form-item prop="ruleContent">
            <tinymce :disabled="emitName === 'details'" v-if="ruleForm.ruleDisplayType === 1" ref="tinymceRule"
              v-model="ruleForm.ruleContent" :height="200" />
            <el-upload v-else class="banner-uploader" accept="image/jpeg,image/png,image/jpg,image/webp"
              :action="action" :data="{ type: 'image', folder: 'ad-photo' }" :headers="$headers()"
              :show-file-list="false" :on-success="(res) => handleUploadSuccess(res, 'ruleContent')">
              <img v-if="ruleForm.ruleContent" :src="ruleForm.ruleContent" class="banner">
              <div v-else class="banner-uploader-txt">
                <!-- <p>{{ $t('文件大小控制在100K以内') }}</p> -->
                <button class="el-button el-button--small el-button--orange" @click.self.prevent>{{ $t('点击上传')
                }}</button>
              </div>
              <div slot="tip" class="el-upload__tip"><i
                  class="el-icon-warning-outline"></i>提示：建议尺寸355*138，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。</div>
            </el-upload>
          </el-form-item>
        </el-form-item>
        <template v-if="(ruleForm.taskType === 1 && ruleForm.taskAction !== 1)
          || (ruleForm.taskType === 2 && ruleForm.rewardMode !== 1)
          || ruleForm.taskType === 3
          || ruleForm.taskType === 4">
          <el-form-item :label="$t('选择显示模板')" prop="rewardDisplayType">
            <el-radio-group v-model="ruleForm.rewardDisplayType">
              <el-radio :label="4">{{ $t('红包领取') }}</el-radio>
              <el-radio :label="2">{{ $t('宝箱领取') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.rewardDisplayType === 4">
            <img class="template-img" src="./images/template_4.png" alt="红包领取模板" />
          </el-form-item>
          <el-form-item v-else-if="ruleForm.rewardDisplayType === 2">
            <img class="template-img" src="./images/template_2.png" alt="宝箱领取模板" />
          </el-form-item>
        </template>
      </template>
      <el-form-item v-if="ruleForm.activityType === 2 && ruleForm.jumpType === 1" :label="$t('联系客服入口')"
        prop="showContactService">
        <el-radio-group v-model="ruleForm.showContactService">
          <el-radio :label="1">{{ $t('显示') }}</el-radio>
          <el-radio :label="0">{{ $t('不显示') }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button v-if="emitName !== 'details'" type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
import Tinymce from '@/components/Tinymce'
import FormDialogMixin from '@/mixins/form-dialog'
import { getCpadActivityList, getActivityTypeList, activityNewDetail } from '@/api/activity'
import { getLotteryNavigationHot } from '@/api/config'
import { getLiveRoomList } from "@/api/chat"
import { getPayThirdAccountList, getPayReceivingAccountList } from '@/api/cash'
import { navigationLotteryList, footballThirdMatchList } from '@/api/lottery'
import { getMatchList } from '@/api/match'
import { appPageList, withdrawStatus, couponGameList } from '@/libs/options'
import { getAppPageParams } from '@/libs/universal'
import { numerical_2 } from '@/utils/validation'
import { mapping, newArr, filterMap, DATA_SOURCES } from './utils'
import moment from 'moment';
import dayjs from 'dayjs'

const initialRule = {
  ruleId: null,
  ruleType: null,
  conditionTime: null,
  conditionValue: null,
  conditionValue2: null,
  hasBonus: 0,
  bonusAmount: null,
  bonusWagerMultiplier: null,
  hasCoupon: 0,
  couponId: null,
  hasPoints: 0,
  points: null,
  // 校验辅助字段
  bonusCheck: null,
  bonusWagerCheck: null,
  couponCheck: null,
  pointsCheck: null,
  awardsCheckedType_4: [],
  awardsCheckedType_1: [],
  awardsCheckedType_2: [],
  awardsCheckedType_3: [],
};
const getDefaultForm = () => ({
  gameSelection: null, //投注-用于触发校验
  rewardRules4: Array.from({ length: 31 }, () => ({ ...initialRule })),
  rewardRules1: Array.from({ length: 7 }, () => ({ ...initialRule })),
  rewardRules3: Array.from({ length: 7 }, () => ({ ...initialRule })),
  rewardRules2: { ...initialRule },
  // createBy: 0, //创建人ID
  // updateBy: 0, //更新人ID
  tenantCode: store.getters.tenantCode, // 租户编码
  templateId: null, // 活动模板ID:编辑的时候传递，创建为空
  languageSet: ['zh_CN'], // 语言
  title: '', // 标题
  category: null, // 活动分类
  sortOrder: null, // 排序
  isContinuous: 0, // 活动时间-0定时/1持续
  activityDate: [], // 活动时间
  banner: '', // 活动Banner
  detailBanner: '',// 活动详情Banner
  minTotalRecharge: 0, // 总充值金额大于等于
  minTotalBet: 0, // 总投注金额大于等于
  minTodayRecharge: 0, // 今日充值金额大于等于
  minTodayBet: 0, // 今日投注金额大于等于
  vipLimit: 0, // VIP等级-0-无限制，1-有限制
  vipLevels: ['0'],
  venue: '', // 活动场馆
  guide: '', // 活动引导说明
  rewardContent: '', // 任务与奖励
  taskType: 1, // 任务类型
  taskAction: 1, // 任务行为
  showContactService: 1, // 是否显示客服
  warmTips: '', //温馨提示
  ruleDisplayType: 1, // 活动规则-1富文本/2图片
  ruleContent: '', // 活动规则-具体内容
  rewardDisplayType: 1, // 模板显示（1）日历领取（2）宝箱领取（3）任务列表（4）红包领取）
  taskScope: 0, // 任务范围（0=不限，1=限制方式）
  participationCondition1: 0, // 活动参与条件1例如：充值金额≥100
  participationCondition2: 0, // 活动参与条件2例如：投注金额≥500，盈利金额≥100，连赢≥3次等
  participationCondition3: 0, // 活动参与条件3例如：有效单数量
  rewardMode: 2, // 奖励方式（1=单次奖励，2=累计奖励）
  calculationMethod: 1, // 计算方式（1=投注金额，2=盈利金额，3=亏损金额，4=连赢加奖，5=投注单量，6=单注派奖)
  accountType: 1, // 充值-账号类型
  paywayList: [], // 充值-公司内部
  thirdPaywayList: [], // 充值-第三方
  withdrawAccountType: 1, // 提现-账号类型
  withdrawList1: [], // 提现-方式列表
  withdrawList2: [], // 提现-钱包列表
  taskRangeFocusChecked: [], // 任务范围限制-关注
  taskRangeFatieChecked: [], // 任务范围限制-发帖
  taskRangeRemarkChecked: [], // 任务范围限制-评论
  taskRangeRewardChecked: [], // 任务范围限制-打赏
  taskRangeBrowseChecked: [], // 任务范围限制-浏览
  activityType: 1, // 活动类型
  jumpType: 1, // 跳转类型
  jumpLink: '', // 跳转链接地址
  appPageId: [], // APP功能页
  seriesTag: null,
  jumpActivityId: null,
  lotteryId: null,
  gameId: null,
  roomId: null,
  roomName: null,
  taskMethod_: '1000005',
  gameList: couponGameList, //任务范围-投注
})
export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      saveDeleteRewardRules: [],
      bannerKey: 0,
      disabled: false,
      emitName: '',
      withdrawStatus,
      appPageList, // APP功能页
      isLoadAppPage: false,
      companyAccountList: [], // 公司账号
      thirdAccountList: [], // 第三方账号
      withdrawTypeList: [], // 提现方式
      paymentTypeList: [], // 提现钱包
      couponList: [], // 优惠券
      awardsType: [{ type: '彩金' }, { type: '优惠券' }, { type: '积分' }],
      num0: 0, //每日签到阶梯奖励默认0条
      num: 1, //阶梯奖励默认1条
      days: 7, // 周任务-累计奖励7天
      day31: 31, //日任务签到31天
      prevCheckedTypes4: Array.from({ length: 31 }, () => []), // 每日签到的校验
      prevCheckedTypes3: Array.from({ length: 7 }, () => []), // 阶梯奖励的校验
      awardsCheckedType_4: Array.from({ length: 31 }, () => []), // 初始化一个包含 31 个空数组的数组，每个空数组对应一行的选中状态
      awardsCheckedType_1: Array.from({ length: 7 }, () => []),
      awardsCheckedType_3: Array.from({ length: 7 }, () => []),
      awardsCheckedType_2: [],
      deleteRewardRules3: [], //删除的规则
      pickerOptions: {
        disabledDate(date) {
          return date.getTime() < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0)
        }
      },
      taskMethod: '1000005', //任务范围-投注选中项
      loadGameList: false,
      loadGameListLoading: true,
      formData: {
        vipLevelList: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        awardsOptions: [{ value: 2, label: '累计奖励' }, { value: 1, label: '单次奖励' }],
        taskRangeFocusList: ['心水关注专家', '关注主播', '推单关注专家'],
        taskRangeFatieList: ['心水发帖', '晒单圈发帖'],
        taskRangeRemarkList: ['心水评论', '推单评论'],
        taskRangeRewardList: ['心水打赏', '直播打赏'],
        taskRangeBrowseList: ['查看心水', '观看直播'],
      },
      rules: {
        languageSet: [{
          required: true, validator: (rule, value, callback) => {
            if (!value || value.length === 0) {
              callback(new Error(this.$t('请选择语言')));
            } else {
              callback();
            }
          }, trigger: 'change'
        }],
        title: [{ required: true, message: this.$t('请填写活动标题'), trigger: 'blur' }],
        sortOrder: [{ required: true, pattern: /^(?:[1-9]\d{0,2}|999)$/, message: '请输入1-999的整数', trigger: 'blur' }],
        activityDate: [{ required: true, message: this.$t('请选择时间'), trigger: 'change' }],
        banner: [{ required: true, message: this.$t('请上传活动Banner'), trigger: 'change' }],
        isContinuous: [{ required: true, message: '请选择任务行为', trigger: 'change' }],
        activityType: [{ required: true, message: this.$t('请选择活动类型'), trigger: 'change' }],
        jumpType: [{ required: true, message: this.$t('请选择跳转方式'), trigger: 'change' }],
        jumpLink: [
          {
            required: () => this.ruleForm.activityType === 2 && this.ruleForm.jumpType === 2,
            message: this.$t('请填写链接地址'),
            trigger: ['blur', 'change']
          }
        ],
        appPageId: [
          {
            type: 'array',
            required: () => this.ruleForm.activityType === 2 && this.ruleForm.jumpType === 3,
            message: this.$t('请选择跳转页面'),
            trigger: ['change', 'blur']
          }
        ],
        detailBanner: [
          {
            validator: (rule, value, callback) => {
              const needValidate = this.ruleForm.activityType === 1 ||
                (this.ruleForm.activityType === 2 && ![2, 3].includes(this.ruleForm.jumpType));
              if (this.ruleForm.activityType === 1 && !value) {
                return callback(new Error('请上传活动详情Banner'));
              }
              if (needValidate && !value) {
                return callback(new Error('请上传活动详情Banner'));
              }
              callback();
            },
            trigger: ['change', 'blur', 'input']
          }
        ],
        minTotalRecharge: [{ required: true, message: this.$t('请输入总充值金额'), trigger: 'blur' }, { pattern: numerical_2, message: this.$t('金额不能小于0，且最多2位小数'), trigger: 'blur' }],
        minTotalBet: [{ required: true, message: this.$t('请输入总投注金额'), trigger: 'blur' }, { pattern: numerical_2, message: this.$t('金额不能小于0，且最多2位小数'), trigger: 'blur' }],
        minTodayRecharge: [{ required: true, message: this.$t('请输入今日充值金额'), trigger: 'blur' }, { pattern: numerical_2, message: this.$t('金额不能小于0，且最多2位小数'), trigger: 'blur' }],
        minTodayBet: [{ required: true, message: this.$t('请输入今日投注金额'), trigger: 'blur' }, { pattern: numerical_2, message: this.$t('金额不能小于0，且最多2位小数'), trigger: 'blur' }],
        vipLimit: [{ required: true, message: '请选择VIP等级', trigger: 'change' }],
        vipLevels: [{ required: true, message: '请选择VIP等级', trigger: 'change' }],
        venue: [{ required: true, message: this.$t('请填写活动场馆'), trigger: 'blur' }],
        guide: [{ required: true, message: this.$t('请填写活动引导说明'), trigger: 'blur' }],
        taskScope: [{ required: true, message: this.$t('选择任务范围'), trigger: 'change' }],
        taskType: [{ required: true, message: '请选择任务类型', trigger: 'change' }],
        rewardMode: [{ required: true, message: this.$t('请选择'), trigger: 'change' }],
        taskAction: [{ required: true, message: '请选择任务行为', trigger: 'change' }],
        rewardContent: [{ required: true, message: this.$t('请选择任务与奖励'), trigger: 'blur' }],
        calculationMethod: [{ required: true, message: this.$t('请选择计算方式'), trigger: 'change' }],
        ruleDisplayType: [{ required: true, message: this.$t('请选择活动规则'), trigger: 'change' }],
        ruleContent: [{ required: true, message: this.$t('请填写活动规则'), trigger: 'blur' }],
        rewardDisplayType: [{ required: true, message: this.$t('请选择显示模板'), trigger: 'change' }],
        showContactService: [{ required: true, message: this.$t('请选择'), trigger: 'change' }],
        paywayList: [{
          validator: (rule, value, callback) => {
            if (this.ruleForm.accountType === 1 && !value?.length) {
              callback(new Error(this.$t('请选择公司账号')));
            } else {
              callback();
            }
          },
          trigger: 'change'
        }],
        thirdPaywayList: [{
          validator: (rule, value, callback) => {
            if (this.ruleForm.accountType === 2 && !value?.length) {
              callback(new Error(this.$t('请选择第三方充值')));
            } else {
              callback();
            }
          },
          trigger: 'change'
        }],
        withdrawList1: [{
          validator: (rule, value, callback) => {
            if (this.ruleForm.withdrawAccountType === 1 && !value?.length) {
              callback(new Error(this.$t('请选择提现方式')));
            } else {
              callback();
            }
          },
          trigger: 'change'
        }],
        withdrawList2: [{
          validator: (rule, value, callback) => {
            if (this.ruleForm.withdrawAccountType === 2 && !value?.length) {
              callback(new Error(this.$t('请选择提现钱包')));
            } else {
              callback();
            }
          },
          trigger: 'change'
        }],
        taskRangeFocusChecked: [{ validator: this.validateArrayRequired(this.$t('请选择任务范围')), trigger: 'change' }],
        taskRangeFatieChecked: [{ validator: this.validateArrayRequired(this.$t('请选择任务范围')), trigger: 'change' }],
        taskRangeRemarkChecked: [{ validator: this.validateArrayRequired(this.$t('请选择任务范围')), trigger: 'change' }],
        taskRangeRewardChecked: [{ validator: this.validateArrayRequired(this.$t('请选择任务范围')), trigger: 'change' }],
        taskRangeBrowseChecked: [{ validator: this.validateArrayRequired(this.$t('请选择任务范围')), trigger: 'change' }],
        participationCondition1: [{ required: true, message: '请输入', trigger: 'blur' }],
        participationCondition2: [{ required: true, message: '请输入', trigger: 'blur' }],
        participationCondition3: [{ required: true, message: '请输入', trigger: 'blur' }],
        conditionValue: [{ required: true, message: '请输入', trigger: 'blur' }],
        conditionValue2: [{ required: true, message: '请输入', trigger: 'blur' }],
        bonusAmount: [{ required: true, message: '请输入彩金', trigger: 'blur' }],
        bonusMultiplier: [{ required: true, message: '请输入打码', trigger: 'blur' }],
        couponId: [{ required: true, message: '请选择优惠券', trigger: 'blur' }],
        points: [{ required: true, message: '请输入积分', trigger: 'blur' }],
        awardsCheckedType_4: [{ required: true, message: '请至少选择一项', trigger: 'change' }],
        awardsCheckedType_1: [{ required: true, message: '请至少选择一项', trigger: 'change' }],
        awardsCheckedType_2: [{ required: true, message: '请至少选择一项', trigger: 'change' }],
        awardsCheckedType_3: [{ required: true, message: '请至少选择一项', trigger: 'change' }],
        gameSelection: [
          {
            validator: (rule, value, callback) => {
              const hasChecked = this.checkGameListSelection(this.ruleForm.gameList);
              callback(hasChecked ? undefined : new Error('请至少选择一项'));
            },
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('app', ['allLang']),
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    title() {
      if (this.emitName === 'create') {
        return this.$t('创建活动')
      } else if (this.emitName === 'update') {
        return this.$t('编辑活动')
      } else {
        return this.$t('查看详情')
      }
    },
    filterGameList() {
      const filterValue = filterMap[this.taskMethod];
      if (filterValue) {
        return this.ruleForm.gameList.filter(e => e.value === filterValue);
      }
      return this.ruleForm.gameList;
    },
    showBanner() {
      return this.ruleForm.activityType === 1 ||
        (this.ruleForm.activityType === 2 && ![2, 3].includes(this.ruleForm.jumpType));
    }
  },
  watch: {
    taskMethod(newVal, oldVal) {
      // 当游戏类型切换时清除当前类型的选中状态
      this.clearCurrentGameSelection();
      this.ruleForm.taskMethod_ = newVal
    }
  },
  async created() {
    this.initAllData();
    await this.handleOpen()
    this.initData();
  },
  methods: {
    checkGameListSelection(list) {
      return list.some(item => {
        const selfChecked = item.checked;
        const childrenChecked = item.children ? this.checkGameListSelection(item.children) : false;
        return selfChecked || childrenChecked;
      });
    },
    validateArrayRequired(message) {
      return (rule, value, callback) => {
        if (!value || value.length === 0) {
          callback(new Error(message));
        } else {
          callback();
        }
      };
    },
    // 动态获取校验规则
    getBonusRules(index) {
      return {
        validator: (rule, value, callback) => {
          if (this.awardsCheckedType_4[index].includes('彩金')) {
            const bonusAmount = this.ruleForm.rewardRules4[index].bonusAmount;
            const bonusWager = this.ruleForm.rewardRules4[index].bonusWagerMultiplier;
            const isEmpty = val => val === null || val === undefined || val === '';
            if (isEmpty(bonusAmount) || isEmpty(bonusWager)) {
              return callback(new Error('请输入彩金和打码'));
            }
          }
          callback();
        },
        trigger: 'blur'
      };
    },
    getCouponRules(index) {
      return {
        validator: (rule, value, callback) => {
          if (this.awardsCheckedType_4[index].includes('优惠券')) {
            if (this.ruleForm.rewardRules4[index].couponId.length === 0) {
              return callback(new Error('请选择优惠券'));
            }
          }
          callback();
        },
        trigger: 'blur'
      };
    },
    getPointsRules(index) {
      return {
        validator: (rule, value, callback) => {
          if (this.awardsCheckedType_4[index].includes('积分')) {
            const points = this.ruleForm.rewardRules4[index].points;
            if (points === null || points === undefined || points === '') {
              return callback(new Error('请输入积分'));
            }
          }
          callback();
        },
        trigger: 'blur'
      };
    },
    // 初始化方法
    initData() {
      this.filterGameList.forEach(game => {
        this.initNodeState(game);
      });
    },
    // 递归初始化节点状态
    initNodeState(node) {
      this.$set(node, 'indeterminate', false);
      if (node.children) {
        node.children.forEach(child => {
          this.initNodeState(child);
          this.calculateNodeStatus(node); // 初始化父级状态
        });
      }
    },
    // 处理勾选变化
    handleCheckedChange(node) {
      // 向下同步
      if (node.children) {
        const syncChildren = (n, status) => {
          n.checked = status;
          n.indeterminate = false;
          if (n.children) n.children.forEach(c => syncChildren(c, status));
        };
        syncChildren(node, node.checked);
      }
      // 向上更新
      this.updateParentStatus(node);
      this.$forceUpdate();
    },
    updateParentStatus(node) {
      let parent = this.findParent(node);
      while (parent) {
        this.calculateNodeStatus(parent);
        parent = this.findParent(parent);
      }
    },
    findParent(child) {
      const stack = [...this.filterGameList];
      while (stack.length) {
        const node = stack.pop();
        if (node.children?.includes(child)) return node;
        if (node.children) stack.push(...node.children);
      }
      return null;
    },
    // 精确计算节点状态
    calculateNodeStatus(node) {
      if (!node.children) return;
      const total = node.children.length;
      const checkedCount = node.children.filter(c => c.checked).length;
      const indeterminateCount = node.children.filter(c => c.indeterminate).length;
      node.checked = checkedCount === total;
      node.indeterminate = !node.checked && (checkedCount > 0 || indeterminateCount > 0);
    },
    // 新增方法：清除当前游戏类型的选中状态
    clearCurrentGameSelection() {
      const currentGame = this.filterGameList[0]; // 当前显示的游戏类型
      if (currentGame) {
        this.resetNodeState(currentGame);
        this.$forceUpdate();
      }
    },
    // 递归重置节点状态
    resetNodeState(node) {
      node.checked = false;
      node.indeterminate = false;
      if (node.children) {
        node.children.forEach(child => this.resetNodeState(child));
      }
    },
    handleRadioChange(type, currentField, resetField) {
      this.ruleForm[resetField] = [];
      this.$nextTick(() => {
        if (this.$refs.ruleForm) {
          this.$refs.ruleForm.validateField(resetField);
          this.$refs.ruleForm.validateField(currentField);
        }
      });
    },
    fetchDataSource(config) {
      return config.api(config.params).then(([items, err]) => {
        if (!err) {
          const processed = config.processor ? config.processor(items) : items;
          this[config.key] = processed;
        }
        return !err;
      });
    },
    initAllData() {
      const requests = DATA_SOURCES.map(config => this.fetchDataSource(config));
      return Promise.all(requests);
    },
    open(row, bool) {
      this.getGameList()
      this.initFormData()
      this.emitName = 'create'
      if (row) {
        this.handleDetailActivity(row, bool)
        this.emitName = 'update'
        if (bool) {
          this.emitName = 'details'
        }
      }
      this.visible = true
    },
    async getGameList() {
      if (this.loadGameList) {
        this.ruleForm.gameList.forEach(e => {
          e.checked = false
          e.collapse = []
          if (e.children) {
            e.children.forEach(v => {
              v.checked = false
              v.collapse = []
              if (v.children) {
                v.children.forEach(o => {
                  o.checked = false
                  o.collapse = []
                })
              }
            })
          }
        })
        return
      }
      let list1 = []
      let list2 = []
      let list3 = []
      let list4 = []
      let list5 = []
      let list6 = []
      this.loadGameListLoading = true
      const [[items1, err1], [items2, err2], [items3, err3], [items4, err4], [items5, err5], [items6, err6]] = await Promise.all([navigationLotteryList(),
      getMatchList({
        sportType: 1,
        currentPage: 1,
        pageSize: 200,
        startTime: dayjs().format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD'),
        type: 1,
        status: 1,
        tenantCode: this.$store.getters.tenantCode
      }),
      footballThirdMatchList({
        currentPage: 1,
        pageSize: 200,
        startMatchTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        endMatchTime: dayjs().format('YYYY-MM-DD 23:59:59'),
        sourceType: 1,
        sportType: 1,
        tenantCode: this.$store.getters.tenantCode
      }),
      getLotteryNavigationHot(), getPayReceivingAccountList({ currentPage: 1, pageSize: 200, status: 0 }), getPayThirdAccountList({ currentPage: 1, pageSize: 200, status: 0 })])
      this.loadGameList = true
      this.loadGameListLoading = false
      if (!err1) {
        list1 = items1
      }
      if (!err2) {
        const matchList = []
        items2.forEach(item => {
          let match = matchList.find(e => e.value === item.competitionName)
          if (!match) {
            match = {
              value: item.competitionName,
              label: item.competitionName,
              checked: false,
              collapse: [],
              children: []
            }
            matchList.push(match)
          }
          match.children.push({
            value: item.matchId,
            label: item.homeTeamName + ' VS ' + item.awayTeamName,
            checked: false,
            collapse: []
          })
        })
        list2 = matchList
      }
      if (!err3) {
        const matchList = []
        items3.forEach(item => {
          let match = matchList.find(e => e.value === item.competitionId)
          if (!match) {
            match = {
              value: item.competitionId,
              label: item.competitionNameZh,
              checked: false,
              collapse: [],
              children: []
            }
            matchList.push(match)
          }
          match.children.push({
            value: item.thirdMatchId,
            label: item.homeTeamNameZh + ' VS ' + item.awayTeamNameZh,
            checked: false,
            collapse: []
          })
        })
        list3 = matchList
      }
      if (!err4) {
        list4 = items4
      }
      if (!err5) {
        list5 = items5
      }
      if (!err6) {
        list6 = items6
      }
      this.ruleForm.gameList.forEach(e => {
        if (e.value === '1000005') {
          e.children = list1.map(v => {
            return {
              value: v.seriesTag,
              label: v.seriesName,
              checked: false,
              collapse: [],
              children: v.lotteryList.map(o => {
                return {
                  value: o.lotteryId,
                  label: o.name,
                  checked: false,
                  collapse: [],
                  children: o.playClassList.map(p => {
                    return {
                      value: p.playClassTag,
                      label: p.playClassName,
                      checked: false
                    }
                  })
                }
              })
            }
          })
        } else if (e.value === '2000002') {
          e.children = list2
        } else if (e.value === '1000002') {
          e.children = list3
        } else {
          e.children.forEach(v => {
            const item = list4.find(r => r.seriesTag === v.value)
            if (item) {
              v.label = item.seriesName
              v.checked = false
              v.children = item.lotteryList.map(o => {
                return {
                  value: o.lotteryId,
                  label: o.name,
                  checked: false
                }
              })
            }
          })
        }
      })
      const existingValues = this.ruleForm.gameList.map(item => item.value);
      newArr.forEach(item => {
        if (!existingValues.includes(item.value)) {
          this.ruleForm.gameList.push(item);
        }
      });
      console.log(this.ruleForm.gameList, '所有的投注数据')
    },
    async handleOpen() {
      if (this.isLoadAppPage) return
      this.isLoadAppPage = true
      let list1 = []
      let list2 = []
      let list3 = []
      let list4 = []
      const [[items1, err1], [items2, err2], [items3, err3], [items4, err4]] = await Promise.all([getLotteryNavigationHot(), getCpadActivityList({ jumpType: 0, tenantCode: this.ruleForm.tenantCode }), getActivityTypeList({ currentPage: 1, pageSize: 100, isShow: 1 }), getLiveRoomList({ roomType: 1 })])
      if (!err1) {
        list1 = items1
      }
      if (!err2) {
        list2 = items2
      }
      if (!err3) {
        if (items3.length > 0) {
          const list = []
          items3.forEach(e => {
            const typeNameList = e.typeName.split(',')
            list.push({
              id: e.id,
              typeName: typeNameList[0].split(':')[1]
            })
          })
          list3 = list
        }
      }
      if (!err4) {
        list4 = items4
      }
      this.appPageList.forEach(e => {
        e.children.forEach(v => {
          const item = list1.find(r => r.seriesTag === v.value)
          if (item) {
            v.children = item.lotteryList.map(o => {
              return {
                value: o.lotteryId,
                label: o.name,
                gameId: o.gameId,
                lotteryId: o.lotteryId
              }
            })
          }
          if (v.value === '9999002' && list2.length > 0) {
            v.children = list2.map(o => {
              return {
                value: o.id,
                label: o.activityTitle,
                activityId: o.id,
                activityType: o.jumpType
              }
            })
          }
          if (v.value === '9999014' && list3.length > 0) {
            v.children = list3.map(o => {
              return {
                value: o.id,
                label: o.typeName,
                activityId: o.id
              }
            })
          }
          if (v.value === '9999007' && list4.length > 0) {
            v.children = list4.map(o => {
              return {
                value: o.roomId,
                label: o.roomName,
                roomId: o.roomId,
                roomName: o.roomName
              }
            })
          }
          if (v.children && v.children.length === 0) {
            delete v.children
          }
        })
      })
    },
    handleInput(key) {
      // 允许 0 或正整数，最多 2 位小数，禁止前面连续输入多个无效 0
      let value = this.ruleForm[key];
      if (value === '') {
        return;
      }
      value = value.replace(/[^0-9.]/g, '');
      if (/^0+([^0.]|$)/.test(value)) {
        if (!value.includes('.')) {
          value = '0';
        } else {
          value = '0' + value.replace(/^0+/, '');
        }
      }
      value = value.replace(/^\./g, '');
      const parts = value.split('.');
      if (parts.length > 2) {
        value = parts[0] + '.' + parts.slice(1).join('').slice(0, 2);
      } else if (parts.length === 2) {
        value = parts[0] + '.' + parts[1].slice(0, 2);
      }
      const numValue = parseFloat(value);
      if (isNaN(numValue) || numValue < 0) {
        value = '0';
      }
      this.ruleForm[key] = value;
    },
    handleInput1(key) {
      // 允许 0 或正整数，禁止前面连续输入多个无效 0
      let value = this.ruleForm[key];
      if (value === '') {
        return;
      }
      value = value.replace(/\D/g, '');
      if (/^0+([^0]|$)/.test(value)) {
        if (/^0+$/.test(value)) {
          value = '0';
        } else {
          value = value.replace(/^0+/, '');
        }
      }
      this.ruleForm[key] = value;
    },
    handleInput2(field, index, ruleKey, num) {
      let value = this.ruleForm[ruleKey][index][field];
      if (value === '') {
        return;
      }
      if (num === 'num') {
        // 允许 0 或正整数，禁止前面连续输入多个无效 0
        value = value.replace(/[^0-9]/g, '');
        if (/^0+([^0]|$)/.test(value)) {
          value = value.replace(/^0+/, '');
          if (value === '') {
            value = '0';
          }
        }
      } else {
        if (field === 'bonusAmount' || field === 'conditionValue') {
          // 允许 0 或正整数，最多 2 位小数，禁止前面连续输入多个无效 0
          value = value.replace(/[^0-9.]/g, '');
          value = value.replace(/^\./g, '');
          const parts = value.split('.');
          if (parts.length > 1) {
            parts[1] = parts[1].slice(0, 2);
            value = parts.join('.');
          }
          if (/^0+([^0.]|$)/.test(value)) {
            if (!value.includes('.')) {
              value = '0';
            } else {
              value = '0' + value.replace(/^0+/, '');
            }
          }
        } else if (field === 'bonusWagerMultiplier' || field === 'points' || field === 'conditionValue2') {
          // 允许 0 或正整数，禁止前面连续输入多个无效 0
          value = value.replace(/[^0-9]/g, '');
          if (/^0+([^0]|$)/.test(value)) {
            value = value.replace(/^0+/, '');
            if (value === '') {
              value = '0';
            }
          }
        }
      }

      this.ruleForm[ruleKey][index][field] = value;
    },
    handleInput3(field) {
      let value = this.ruleForm.rewardRules2[field];
      if (value === '') {
        return;
      }
      if (field === 'bonusAmount') {
        // 允许 0 或正整数，最多 2 位小数，禁止前面连续输入多个无效 0
        value = value.replace(/[^0-9.]/g, '');
        value = value.replace(/^\./g, '');
        const parts = value.split('.');
        if (parts.length > 1) {
          parts[1] = parts[1].slice(0, 2);
          value = parts.join('.');
        }
        if (/^0+([^0.]|$)/.test(value)) {
          if (!value.includes('.')) {
            value = '0';
          } else {
            value = '0' + value.replace(/^0+/, '');
          }
        }
      } else if (field === 'bonusWagerMultiplier' || field === 'points') {
        // 允许 0 或正整数，禁止前面连续输入多个无效 0
        value = value.replace(/[^0-9]/g, '');
        if (/^0+([^0]|$)/.test(value)) {
          value = value.replace(/^0+/, '');
          if (value === '') {
            value = '0';
          }
        }
      }
      this.ruleForm.rewardRules2[field] = value;
    },
    handleAdd(value) {
      if (value && value == 'num0') {
        this.num0++
        this.ruleForm.rewardRules3.push({ ...initialRule });
        this.awardsCheckedType_3.push([]);
        this.$nextTick(() => {
          this.$refs.validateForm.clearValidate('rewardRules3');
        });
      } else {
        this.num++
        this.ruleForm.rewardRules3.push({ ...initialRule });
        this.awardsCheckedType_3.push([]);
        this.$nextTick(() => {
          this.$refs.validateForm.clearValidate('rewardRules3');
        });
      }
    },
    handeldelete(index, item, value) {
      if (value && value == 'num0') {
        this.num0--
        this.ruleForm.rewardRules3.splice(index, 1);
        this.awardsCheckedType_3.splice(index, 1);
        if (item.ruleId) {
          this.deleteRewardRules3.push(item.ruleId)
        }
        this.$nextTick(() => {
          this.$refs.validateForm.clearValidate('rewardRules3');
        });
      } else {
        if (this.num > 1) {
          this.num--
          this.ruleForm.rewardRules3.splice(index, 1);
          this.awardsCheckedType_3.splice(index, 1);
          if (item.ruleId) {
            this.deleteRewardRules3.push(item.ruleId)
          }
          this.$nextTick(() => {
            this.$refs.validateForm.clearValidate('rewardRules3');
          });
        }
      }
    },
    changeJumpType() {
      this.ruleForm.appPageId = null
      this.ruleForm.jumpLink = null
      this.ruleForm.detailBanner = null
      this.$nextTick(() => {
        // 清除相关字段的校验
        this.$refs.validateForm.clearValidate(['jumpLink', 'appPageId', 'detailBanner']);
        // 立即触发新字段的校验
        if (this.ruleForm.jumpType === 2) {
          this.$refs.validateForm.validateField('jumpLink');
        } else if (this.ruleForm.jumpType === 3) {
          this.$refs.validateForm.validateField('appPageId');
        }
        this.$refs.validateForm.validateField('detailBanner');
      });
    },
    changeVipLimit() {
      this.ruleForm.vipLevels = []
    },

    handleRewardRules3Edit(items) {
      this.num0 = items.length
      this.num = items.length
      // 初始化 this.awardsCheckedType_3
      this.awardsCheckedType_3 = Array.from({ length: items.length }, () => []);
      // 遍历 items，填充 this.awardsCheckedType_3
      items.forEach((item, index) => {
        if (item.hasBonus === 1) this.awardsCheckedType_3[index].push('彩金');
        if (item.hasPoints === 1) this.awardsCheckedType_3[index].push('积分');
        if (item.hasCoupon === 1) this.awardsCheckedType_3[index].push('优惠券');
        item.awardsCheckedType_3 = this.awardsCheckedType_3[index];
      });
      items.forEach((item, index) => {
        const newItem = { ...item };
        // 如果 couponId 存在且是字符串，将其转换为数组
        if (newItem.couponId && typeof newItem.couponId === 'string') {
          newItem.couponId = newItem.couponId.split(',');
        }
        if (newItem.conditionValue) {
          newItem.conditionValue = parseInt(newItem.conditionValue, 10);
        }
        if (newItem.bonusWagerMultiplier) {
          newItem.bonusWagerMultiplier = parseInt(newItem.bonusWagerMultiplier, 10);
        }
        // 将处理后的项赋值到 this.ruleForm.rewardRules3
        this.$set(this.ruleForm.rewardRules3, index, newItem);
      });
    },
    handleRewardRules4Edit(items, days) {
      this.awardsCheckedType_4 = Array.from({ length: days }, () => []);
      items.forEach((item, index) => {
        if (item.couponId && typeof item.couponId === 'string') {
          item.couponId = item.couponId.split(',').filter(Boolean); // 过滤掉空字符串
        }
        if (item.bonusWagerMultiplier) {
          item.bonusWagerMultiplier = item.bonusWagerMultiplier ? parseInt(item.bonusWagerMultiplier, 10) : null;
        }
        if (item.hasBonus === 1) this.awardsCheckedType_4[index].push('彩金');
        if (item.hasPoints === 1) this.awardsCheckedType_4[index].push('积分');
        if (item.hasCoupon === 1) this.awardsCheckedType_4[index].push('优惠券');
        // 将计算好的数组赋值到 item 的 awardsCheckedType_4 字段上
        item.awardsCheckedType_4 = this.awardsCheckedType_4[index];
        this.$set(this.ruleForm.rewardRules4, index, item);
        console.log(this.ruleForm.rewardRules4, '有纸吗')
      });
    },
    handleRewardRules1Edit(items, days) {
      // 初始化 this.awardsCheckedType_1
      this.awardsCheckedType_1 = Array.from({ length: days }, () => []);
      items.filter(item => item.ruleType !== 2).forEach(item => {

        // 将 couponId 转换为数组（如果存在且是字符串）
        if (item.couponId && typeof item.couponId === 'string') {
          item.couponId = item.couponId.split(',').filter(Boolean); // 过滤掉空字符串
        }
        if (item.bonusWagerMultiplier) {
          item.bonusWagerMultiplier = item.bonusWagerMultiplier ? parseInt(item.bonusWagerMultiplier, 10) : null;
        }
        // 将 conditionTime 转换为 moment 对象
        const date = moment(item.conditionTime);
        // 计算本周的第几天（周一为第 1 天，周日为第 7 天）
        const dayOfWeek = date.isoWeekday(); // 使用 isoWeekday() 获取本周的第几天
        if (dayOfWeek >= 1 && dayOfWeek <= 7) {
          // 计算 index，确保不超过 days 的范围
          const index = Math.min(dayOfWeek - 1, days - 1);
          // 根据 hasBonus、hasPoints、hasCoupon 填充奖励类型
          if (item.hasBonus === 1) this.awardsCheckedType_1[index].push('彩金');
          if (item.hasPoints === 1) this.awardsCheckedType_1[index].push('积分');
          if (item.hasCoupon === 1) this.awardsCheckedType_1[index].push('优惠券');
          item.awardsCheckedType_1 = this.awardsCheckedType_1[index];
          // 将当前项赋值给 this.ruleForm.rewardRules1
          this.$set(this.ruleForm.rewardRules1, index, item);
        }
      });
    },
    handleRewardRules2Edit(items) {
      if (items.hasBonus && items.hasBonus === 1) {
        this.awardsCheckedType_2.push('彩金');
        items.awardsCheckedType_2 = this.awardsCheckedType_2;
      }
      if (items.hasPoints && items.hasPoints === 1) {
        this.awardsCheckedType_2.push('积分');
        items.awardsCheckedType_2 = this.awardsCheckedType_2;
      }
      if (items.hasCoupon && items.hasCoupon === 1) {
        this.awardsCheckedType_2.push('优惠券');
        items.awardsCheckedType_2 = this.awardsCheckedType_2;
      }
      if (items.couponId && typeof items.couponId === 'string') {
        items.couponId = items.couponId.split(',');
      }
      if (items.bonusWagerMultiplier && items.bonusWagerMultiplier) {
        items.bonusWagerMultiplier = parseInt(items.bonusWagerMultiplier, 10);
      }
      this.ruleForm.rewardRules2 = items;
    },
    // 编辑数据
    applyEditData(savedData) {
      if (savedData.length === 0) return;
      // 设置当前游戏类型
      const mainGameType = savedData[0];
      this.taskMethod = mainGameType.value;

      // 等待DOM更新后应用状态
      this.$nextTick(() => {
        // 在过滤后的数据中查找对应节点
        const targetGame = this.filterGameList.find(
          g => g.value === mainGameType.value
        );

        if (targetGame) {

          this.deepApplyState(targetGame, mainGameType);
        }
      });
    },
    deepApplyState(targetNode, savedNode) {
      this.$set(targetNode, 'checked', savedNode.checked);
      this.$set(targetNode, 'indeterminate', savedNode.indeterminate);
      targetNode.collapse = savedNode.collapse;
      // 处理子节点
      if (savedNode.children) {
        savedNode.children.forEach(savedChild => {
          const targetChild = targetNode.children?.find(
            c => c.value === savedChild.value
          );

          if (targetChild) {
            this.deepApplyState(targetChild, savedChild);
          }
        });
      }
      // 重新计算父级状态
      this.updateParentStatus(targetNode);
    },
    // 查询详情数据
    handleDetailActivity(row, bool) {
      activityNewDetail({ templateId: row.templateId }).then(([items, err]) => {
        if (!err) {
          console.log(items, '详情数据')
          if (items.languageSet) {
            this.ruleForm.languageSet = items.languageSet.split(',')
          }
          if (this.emitName = 'update') {
            this.ruleForm.templateId = row.templateId
          }
          this.ruleForm.title = items.title
          this.ruleForm.category = items.category
          this.ruleForm.sortOrder = parseInt(items.sortOrder)
          this.ruleForm.banner = items.banner
          this.ruleForm.activityType = items.activityType
          this.ruleForm.detailBanner = items.detailBanner
          this.ruleForm.minTotalRecharge = items.minTotalRecharge
          this.ruleForm.minTotalBet = items.minTotalBet
          this.ruleForm.minTodayRecharge = items.minTodayRecharge
          this.ruleForm.minTodayBet = items.minTodayBet
          this.ruleForm.venue = items.venue
          this.ruleForm.guide = items.guide
          this.ruleForm.rewardContent = items.rewardContent
          this.ruleForm.taskType = items.taskType
          this.ruleForm.taskAction = items.taskAction
          this.ruleForm.rewardMode = items.rewardMode
          this.ruleForm.participationCondition1 = items.participationCondition1
          this.ruleForm.participationCondition2 = items.participationCondition2
          this.ruleForm.participationCondition3 = items.participationCondition3
          this.ruleForm.warmTips = items.warmTips
          this.ruleForm.calculationMethod = items.calculationMethod
          this.ruleForm.ruleDisplayType = items.ruleDisplayType
          this.ruleForm.ruleContent = items.ruleContent
          this.ruleForm.showContactService = items.showContactService
          this.ruleForm.rewardDisplayType = items.rewardDisplayType
          this.ruleForm.vipLimit = items.vipLimit
          if (items.vipLevels) {
            this.ruleForm.vipLevels = items.vipLevels.split(',')
          }

          this.ruleForm.jumpLink = items.jumpLink
          // APP功能页
          this.ruleForm.jumpType = items.jumpType
          this.ruleForm.seriesTag = items.seriesTag
          this.ruleForm.jumpActivityId = items.jumpActivityId
          this.ruleForm.lotteryId = items.lotteryId
          this.ruleForm.gameId = items.gameId
          this.ruleForm.roomId = items.roomId
          this.ruleForm.roomName = items.roomName
          this.ruleForm.appPageId = []
          if (items.jumpType === 3) {
            this.ruleForm.appPageId = getAppPageParams(items.seriesTag, items.jumpActivityId, items.lotteryId, items.roomId)
          }

          // this.ruleForm.appPageId = []
          // if (items.jumpType === 3) {
          //   const jumpLinkParts = items.jumpLink.split('&');
          //   this.ruleForm.appPageId = getAppPageParams(jumpLinkParts[0] || '', jumpLinkParts[1] || '', jumpLinkParts[2] || '', jumpLinkParts[3] || '')
          // }
          // this.$nextTick(() => {
          // this.ruleForm.ruleContent = items.ruleContent
          // this.$refs.tinymceRule.hasInit && this.$refs.tinymceRule.setContent(items.ruleContent)
          // })
          this.ruleForm.isContinuous = items.isContinuous
          if (items.isContinuous === 0) {
            this.ruleForm.activityDate = [moment(items.startTime).format('YYYY-MM-DD HH:mm:ss'), moment(items.endTime).format('YYYY-MM-DD HH:mm:ss')]
          }

          this.ruleForm.taskScope = items.taskScope
          if (items.taskScope == 1 && items.taskLimitChannels) {
            const taskLimitChannels = JSON.parse(items.taskLimitChannels)
            if (this.ruleForm.taskAction == 4) {
              this.applyEditData(taskLimitChannels);
            } else {
              const [key] = Object.keys(taskLimitChannels);
              if (key === 'paywayList') {
                this.ruleForm.accountType = 1;
              } else if (key === 'thirdPaywayList') {
                this.ruleForm.accountType = 2;
              }
              if (key === 'withdrawList1') {
                this.ruleForm.withdrawAccountType = 1;
              } else if (key === 'withdrawList2') {
                this.ruleForm.withdrawAccountType = 2;
              }
              for (const [targetKey, sourceKey] of Object.entries(mapping)) {
                if (taskLimitChannels[sourceKey]) {
                  this.ruleForm[targetKey] = taskLimitChannels[sourceKey];
                }
              }
            }
          }
          this.saveDeleteRewardRules = items.rewardRules;
          if (items.taskType === 1) {
            if (items.taskAction === 1) {
              // 每日奖励：
              const rewardRulesRuleType1 = items.rewardRules.filter(item => item.ruleType === 1);
              this.handleRewardRules4Edit(rewardRulesRuleType1, 31)
              // 阶梯奖励：过滤出 ruleType 为 2 的项
              const rewardRulesRuleType2 = items.rewardRules.filter(item => item.ruleType === 2);
              this.handleRewardRules3Edit(rewardRulesRuleType2)
            } else {
              this.handleRewardRules3Edit(items.rewardRules)
            }
          } else if (items.taskType === 2) {
            if (items.rewardMode === 1) {
              //单次奖励
              this.handleRewardRules3Edit(items.rewardRules)
            } else {
              // 累计奖励
              this.handleRewardRules1Edit(items.rewardRules, 7)
              // 全勤奖：取出 ruleType 为 2 的项
              const ruleType2Item = items.rewardRules.find(item => item.ruleType === 2);
              if (ruleType2Item) {
                this.handleRewardRules2Edit(ruleType2Item)
              }
            }
          } else if (items.taskType === 3 || items.taskType === 4) {
            this.handleRewardRules3Edit(items.rewardRules)
          }
          if (bool) {
            this.emitName = 'details'
          }
        }
      })
    },

    changeRuleDisplayType() {
      this.ruleForm.ruleContent = ''
    },
    handleUploadSuccess(res, fieldName, propName = fieldName) {
      this.ruleForm[propName] = res.data[0];
      this.$refs.validateForm.validateField(fieldName);
    },
    initFormData1() {
      this.ruleForm.taskScope = 0
      this.ruleForm.paywayList = []
      this.ruleForm.thirdPaywayList = []
      this.ruleForm.withdrawList1 = []
      this.ruleForm.withdrawList2 = []
      this.ruleForm.taskRangeFocusChecked = []
      this.ruleForm.taskRangeFatieChecked = []
      this.ruleForm.taskRangeRemarkChecked = []
      this.ruleForm.taskRangeRewardChecked = []
      this.ruleForm.taskRangeBrowseChecked = []
      this.ruleForm.participationCondition1 = null
      this.ruleForm.participationCondition2 = null
      this.ruleForm.participationCondition3 = null
      this.ruleForm.rewardRules4 = Array.from({ length: 31 }, () => ({ ...initialRule }))
      this.ruleForm.rewardRules1 = Array.from({ length: 7 }, () => ({ ...initialRule }))
      this.ruleForm.rewardRules3 = Array.from({ length: 7 }, () => ({ ...initialRule }))
      this.ruleForm.rewardRules2 = { ...initialRule }
      this.awardsCheckedType_4 = Array.from({ length: 31 }, () => [])
      this.awardsCheckedType_1 = Array.from({ length: 7 }, () => [])
      this.awardsCheckedType_3 = Array.from({ length: 7 }, () => [])
      this.awardsCheckedType_2 = []
      this.num = 1
      this.num0 = 0
      this.deleteRewardRules3 = []
      this.taskMethod = '1000005'
    },
    initFormData() {
      this.initFormData1()
      this.ruleForm.activityDate = []
      this.ruleForm.warmTips = ''
      this.ruleForm.ruleContent = ''
    },
    changeActivityType(value) {
      if (value == 1) {
        this.ruleForm.taskType = 1
        this.ruleForm.taskAction = 1
      } else {
        this.ruleForm.taskType = 0
        this.ruleForm.taskAction = null
      }
      this.initFormData()
      this.changeJumpType()
      this.ruleForm.detailBanner = value === 1 ? null : this.ruleForm.detailBanner
      this.$nextTick(() => {
        this.$refs.validateForm.clearValidate();
        this.bannerKey = Date.now();
        if (value === 1) {
          this.$refs.validateForm.validateField('detailBanner');
        }
      });
    },
    changeRewardDisplayType() {
      if (this.ruleForm.taskType == 1) {
        if (this.ruleForm.taskAction == 1) {
          this.ruleForm.rewardDisplayType = 1
        } else {
          this.ruleForm.rewardDisplayType = 4
        }
      } else if (this.ruleForm.taskType == 2) {
        if (this.ruleForm.rewardMode == 1) {
          this.ruleForm.rewardDisplayType = 3
        } else {
          this.ruleForm.rewardDisplayType = 4
        }
      } else if (this.ruleForm.taskType == 3) {
        this.ruleForm.rewardDisplayType = 4
      } else if (this.ruleForm.taskType == 4) {
        this.ruleForm.rewardDisplayType = 4
      }
    },
    changeTaskType() {
      // 任务类型为1时，任务行为是签到，否则是充值
      this.ruleForm.taskAction = this.ruleForm.taskType === 1 ? 1 : 2
      this.ruleForm.rewardMode = 2
      this.initFormData1()
      this.changeRewardDisplayType()
      if (this.emitName === 'update') {
        this.deleteRewardRules3 = this.saveDeleteRewardRules
          .filter(item => item.ruleId != null && item.ruleId !== '')
          .map(item => item.ruleId);
      }
    },
    changeRewardMode() {
      this.initFormData1()
      this.changeRewardDisplayType()
      if (this.emitName === 'update') {
        this.deleteRewardRules3 = this.saveDeleteRewardRules
          .filter(item => item.ruleId != null && item.ruleId !== '')
          .map(item => item.ruleId);
      }
    },
    changeTaskAction(value) {
      this.initFormData1()
      this.changeRewardDisplayType()
    },
    handleChangeAppPage(pageId) {
      this.ruleForm.seriesTag = null
      this.ruleForm.lotteryId = null
      this.ruleForm.gameId = null
      this.ruleForm.jumpActivityId = null
      // this.ruleForm.activityType = null
      this.ruleForm.roomId = null
      this.ruleForm.roomName = null
      if (pageId.length >= 2) {
        this.ruleForm.seriesTag = pageId[1]
      }
      if (pageId.length >= 3) {
        const node1 = this.appPageList.find(e => e.value === pageId[0])
        if (node1 && node1.children) {
          const node2 = node1.children.find(e => e.value === pageId[1])
          if (node2 && node2.children) {
            const node3 = node2.children.find(e => e.value === pageId[2])
            if (node3) {
              this.ruleForm.lotteryId = node3.lotteryId || null
              this.ruleForm.gameId = node3.gameId || null
              this.ruleForm.jumpActivityId = node3.activityId || null
              // this.ruleForm.activityType = node3.activityType || null
              this.ruleForm.roomId = node3.roomId || null
              this.ruleForm.roomName = node3.roomName || null
              // this.ruleForm.jumpLink = pageId[1] + '&' + node3.activityId + '&' + node3.lotteryId + '&' + node3.roomId
            }
          }
        }
      }
    },
    handleChange(value, type, index = null) {
      const typeFieldMap = {
        '彩金': 'bonusCheck',
        '打码': 'bonusWagerCheck',
        '优惠券': 'couponCheck',
        '积分': 'pointsCheck'
      };
      // rewardRules3校验
      if (type === 3 && index !== null) {
        const oldVal = this.prevCheckedTypes3[index] || [];
        const removedTypes = oldVal.filter(t => !value.includes(t));
        this.$nextTick(() => {
          if (this.$refs.validateForm?.clearValidate) {
            removedTypes.forEach(t => {
              const field = typeFieldMap[t];
              if (field) {
                this.$refs.validateForm.clearValidate(
                  `rewardRules3.${index}.${field}`
                );
              }
            });
          }
        });
        this.$set(this.prevCheckedTypes3, index, [...value]);
      }
      // rewardRules4校验
      if (type === 4 && index !== null) {
        const oldVal = this.prevCheckedTypes4[index] || [];
        const removedTypes = oldVal.filter(t => !value.includes(t));
        this.$nextTick(() => {
          if (this.$refs.validateForm?.clearValidate) {
            removedTypes.forEach(t => {
              const field = typeFieldMap[t];
              if (field) {
                this.$refs.validateForm.clearValidate(
                  `rewardRules4.${index}.${field}`
                );
              }
            });
          }
        });
        this.$set(this.prevCheckedTypes4, index, [...value]);
      }

      // 确定当前操作的数据对象
      const isArrayType = [1, 3, 4].includes(type); // 1,3,4 是数组类型，2 是对象
      const checkedKey = `awardsCheckedType_${type}`;
      const ruleKey = `rewardRules${type}`;

      if (isArrayType) {
        this.$set(this[checkedKey], index, value);
      } else {
        this[checkedKey] = value;
      }
      // 获取操作的目标对象（数组元素或直接对象）
      const targetRule = isArrayType
        ? this.ruleForm[ruleKey][index]
        : this.ruleForm[ruleKey];

      targetRule.hasBonus = value.includes('彩金') ? 1 : 0;
      targetRule.hasCoupon = value.includes('优惠券') ? 1 : 0;
      targetRule.hasPoints = value.includes('积分') ? 1 : 0;
      targetRule.ruleType = value.length > 0 ? (type === 2 || type === 3 ? 2 : 1) : null;

      // 处理日期逻辑（仅 type=1 和 type=4 需要）
      if ([1, 4].includes(type)) {
        let targetDate;
        if (type === 4) {
          // type=4: 当月第 index+1 天
          targetDate = moment().date(index + 1);
        } else if (type === 1) {
          const monday = moment().startOf('isoWeek'); // 使用isoWeek以周一为起始
          targetDate = monday.add(index, 'days');

          // 移除月份匹配限制，只保留有效性检查
          if (targetDate.isValid() && value.length > 0) {
            targetRule.conditionTime = targetDate.valueOf();
          } else {
            targetRule.conditionTime = null;
          }
          return;
        }

        // 原type=4的逻辑保持不变
        if (targetDate.isValid() && value.length > 0) {
          targetRule.conditionValue = index +1
          targetRule.conditionTime = targetDate.valueOf();
        } else {
          targetRule.conditionTime = null;
        }
      }

      // 清空未选中的字段
      const clearFields = {
        '彩金': () => {
          targetRule.bonusAmount = 0;
          targetRule.bonusWagerMultiplier = 0;
        },
        '优惠券': () => {
          targetRule.couponId = '';
        },
        '积分': () => {
          targetRule.points = 0;
        }
      };

      Object.entries(clearFields).forEach(([key, clearFn]) => {
        if (!value.includes(key)) clearFn();
      });

    },

  }
}
</script>

<style lang="scss" scoped>
.template-img {
  width: 375px;
  height: 302px;
}

::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.banner-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.border_ {
  padding: 5px 10px;
  box-sizing: border-box;
}

.banner-uploader .el-upload:hover {
  border-color: #409EFF;
}

.banner-uploader-txt {
  font-size: 14px;
  color: #8c939d;
  width: 375px;
  height: 128px;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.banner {
  width: 375px;
  height: 128px;
  display: block;
}

.tiaojian {
  strong {
    margin: 0 -17px 0 -74px;
  }

  ::v-deep {
    .el-form-item__label {
      font-weight: normal;
    }

    .el-form-item--small {
      display: inline-block;

      &:nth-child(1) {
        margin-left: -21px;
      }
    }

    .el-form-item__error {
      width: 200px;
    }
  }
}

.form-item-error {
  ::v-deep {
    .el-form-item__error {
      position: static;
    }
  }
}

table {
  width: 100%;
  border-collapse: collapse;

  &.signTable {
    td {
      span {
        &.ml {
          margin-left: 40px;
        }
      }
    }
  }

  &.rechargeTable {
    th {
      &:nth-child(1) {
        width: 13.5%;
      }

      &:nth-child(2) {
        width: 22.5%;
      }
    }
  }

  &.browseTable {
    th {
      &:nth-child(1) {
        width: 16%;
      }

      &:nth-child(2) {
        width: 22%;
      }
    }
  }

  &.weekTable {
    th {
      &:nth-child(1) {
        width: 12%;
      }

      &:nth-child(2) {
        width: 24%;
      }
    }

    td {
      span {
        &.ml {
          margin-left: 40px;
        }
      }
    }
  }

  th,
  td {
    border: 1px solid #DCDFE6;
  }

  th {
    font-weight: normal;

    &:nth-child(1) {
      width: 13%;
    }

    &:nth-child(2) {
      width: 23%;
    }

    &:nth-child(4) {
      width: 6%;
    }
  }

  td {
    text-align: center;

    span {
      &.ml {
        margin-left: 20px;
      }
    }
  }

  ::v-deep {
    .el-input--small {
      .el-input__inner {
        height: 28px;
        line-height: 28px;
      }
    }
  }
}

.add {
  margin: 5px;
}

.taskRange {
  margin-bottom: 10px !important;

  >.el-checkbox {
    font-weight: bold;
  }

  .el-checkbox-group {
    display: inline-block;
    margin-left: 20px;
  }
}

::v-deep {
  .el-input.is-disabled {
    .el-input__inner {
      color: #595959;
    }
  }
}
</style>
