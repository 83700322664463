
import { delInfo } from '@/api/qrmgr';
<template>
  <div class="app-container">
    <div class="filter-container">

      <el-input
        v-model.trim="search.domainName"
        maxlength="20"
        clearable
        :placeholder="$t('请输入名称')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model.trim="search.domainUrl"
        maxlength="20"
        clearable
        :placeholder="$t('请输入链接')"
        class="filter-item"
        style="width: 140px"
      />
      <!-- <el-select v-model="search.domainType" clearable :placeholder="$t('域名分组')" class="filter-item">
        <el-option label="所有" :value="0" />
        <el-option label="9b下载页" :value="1" />
      </el-select> -->
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button type="success" icon="el-icon-plus" class="filter-item" @click="onCreate()">
        添加链接
      </el-button>
      <el-button type="success" icon="el-icon-plus" class="filter-item" @click="visibleEditText=true">
        编辑文本
      </el-button>

    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <edit-dialog ref="EditDialogControl" @create="handleCreate" />
    <el-dialog title="编辑落地页文本" :visible.sync="visibleEditText" width="460px" :close-on-click-modal="false">
      <tinymce ref="tinymce" v-model="domainDesc" :maxlength="2000" />
      <div class="dialog-footer">
        <el-button @click="visibleEditText=false">
          {{ $t('关闭') }}
        </el-button>
        <el-button type="primary" @click="saveText">
          {{ $t('保存') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EditDialog from './EditDialog.vue'
import Tinymce from '@/components/Tinymce'
import { getList, addInfo, updateInfo, getDomainTypeDesc, createDomainTypeDesc } from '@/api/qrmgr.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
// let search = {}
export default {
  name: 'QrMgr',
  components: { EditDialog, Tinymce },
  mixins: [TablePageMixin(getList)],
  data() {
    return {
      times: [],
      search: {
        isDelete: 0,
        domainType: 1
      },
      columns: [],
      domainDesc: '',
      defaultColumns: GetColumns.call(this),
      visibleEditText: false
    }
  },
  async created() {
    this.$store.commit('permission/SET_ROUTES', [])
    this.readText()
  },
  async activated() {
  },

  methods: {
    onCreate(row) {
      this.$refs.EditDialogControl.open(row, this.$store.getters.tenantCode)
    },
    handleCreate({ field, cancel, close }) {
      addInfo({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('保存成功'))
        } else {
          cancel()
        }
      })
    },
    async saveText() {
      let res = await createDomainTypeDesc({ domainType: 1, domainDesc: this.domainDesc })
      this.$message.success(this.$t(res[0] ? '保存成功' : '保存失败'))
      this.visibleEditText = false
    },
    async readText() {
      let res = await getDomainTypeDesc({ domainType: 1 })
      this.domainDesc = res[0]?.domainDesc
    },
    async switchStatus(row) {
      this.$confirm(this.$t(`确认${row.status ? '停用' : '启用'}链接吗？`), {
        type: 'warning'
      }).then(() => {
        row.status = row.status === 1 ? 0 : 1
        updateInfo(row).then(([data, err]) => {
          if (!err) {
            this.handleRefresh()
            this.$message.success(this.$t('操作成功！'))
          }
        })
      })
    },
    _delete(row) {
      this.$confirm(this.$t('确认删除吗？'), {
        type: 'warning'
      }).then(() => {
        row.isDelete = 1
        updateInfo(row).then(([data, err]) => {
          if (!err) {
            this.handleRefresh()
            this.$message.success(this.$t(' 删除成功！'))
          }
        })
      })
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
  <style type="scss" scoped>
  .filter-container {
    display: flex;
    gap: 8px;
  }

  .filter-container .filter-item+.filter-item {
    margin-left: 0;
  }

  .scope-pop {
    /* display: flex;
    flex-direction: column; */
    /* gap: 8px; */
    height: 400px;
    max-width: 400px;
    overflow-y: scroll;
  }
  .dialog-footer{
    text-align: right;
    margin-top: 8px;
  }
  </style>

