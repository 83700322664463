<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="540px"
    top="5vh"
    :close-on-click-modal="false"
    class="border-form-dialog"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" :show-message="false" label-width="100px">
      <el-form-item :label="$t('中文名称')" prop="giftName">
        <el-input v-model="ruleForm.giftName" :placeholder="$t('请输入中文名称')" />
      </el-form-item>
      <el-form-item :label="$t('英文名称')" prop="giftNameEN">
        <el-input v-model="ruleForm.giftNameEN" :placeholder="$t('请输入英文名称')" />
      </el-form-item>
      <el-form-item :label="$t('越南名称')" prop="giftNameVN">
        <el-input v-model="ruleForm.giftNameVN" :placeholder="$t('请输入越南名称')" />
      </el-form-item>
      <el-form-item :label="$t('礼物图标')" prop="giftIcon">
        <div flex="cross:center">
          <el-upload
            class="gifticon-uploader"
            accept="image/jpeg,image/png,image/jpg,image/webp"
            :action="action"
            :data="{ type: 'image', folder: 'icon' }"
            :headers="$headers()"
            :show-file-list="false"
            :on-success="uploadIconSuccess"
          >
            <img v-if="ruleForm.giftIcon" :src="ruleForm.giftIcon" class="image">
            <i v-else class="el-icon-upload uploader-icon" />
          </el-upload>
          <div style="line-height: 22px; margin-left: 20px; font-size: 12px;">
            <p>{{ $t('建议图标规则') }}</p>
            <p>{{ $t('1.尺寸：160x160px') }}</p>
            <p>{{ $t('2.格式：PNG') }}</p>
            <p>{{ $t('3.大小：1M内') }}</p>
          </div>
        </div>
      </el-form-item>
      <el-form-item :label="$t('礼物价格')" prop="gold">
        <el-input v-model="ruleForm.gold" :placeholder="$t('支持3位小数')" show-word-limit maxlength="12" />
      </el-form-item>
      <el-form-item :label="$t('礼物属性')" prop="giftType">
        <el-select v-model="ruleForm.giftType" :placeholder="$t('请选择礼物属性')" style="width: 100%">
          <el-option :label="$t('连击礼物')" :value="1" />
          <el-option :label="$t('动画礼物')" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('排序')" prop="sortBy">
        <el-input v-model.number="ruleForm.sortBy" :placeholder="$t('请输入排序')" show-word-limit maxlength="4" />
      </el-form-item>
      <el-form-item :label="$t('礼物动画')" prop="giftAction">
        <el-input
          v-model="ruleForm.giftAction"
          readonly
          :placeholder="$t('请上传动画文件(仅支持10M内的svga格式动画)')"
        >
          <el-upload
            slot="append"
            :action="action"
            :headers="$headers()"
            :data="{ type: 'svg', folder: 'icon' }"
            :before-upload="beforeSvgaUpload"
            :on-success="uploadSvgaSuccess"
            :show-file-list="false"
          >
            <i class="el-icon-upload" />
          </el-upload>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('礼物状态')" prop="giftStatus">
        <el-switch v-model="ruleForm.giftStatus" :active-text="$t('启用')" :inactive-text="$t('禁用')" :active-value="1" :inactive-value="2" />
      </el-form-item>
      <el-form-item :label="$t('动画状态')" prop="giftActionStatus">
        <el-switch v-model="ruleForm.giftActionStatus" :active-text="$t('启用')" :inactive-text="$t('禁用')" :active-value="1" :inactive-value="2" />
      </el-form-item>
      <!-- <el-form-item :label="$t('联动状态')" prop="giftLinkStatus">
        <el-switch v-model="ruleForm.giftLinkStatus" :active-text="$t('启用')" :inactive-text="$t('禁用')" :active-value="1" :inactive-value="2" />
      </el-form-item> -->
      <!-- <el-form-item label="震动方案" prop="i">
        <el-select v-model="ruleForm.i" placeholder="请选择震动方案" style="width: 100%">
          <el-option label="方案1" value="0" />
          <el-option label="方案2" value="1" />
        </el-select>
      </el-form-item> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确认') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  giftName: '',
  giftNameEN: '',
  giftNameVN: '',
  giftIcon: '',
  gold: '',
  giftType: '',
  sortBy: null,
  giftAction: '',
  giftStatus: 1,
  giftActionStatus: 1
  // giftLinkStatus: 2
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        giftName: [{ required: true, message: this.$t('请输入中文名称'), trigger: 'blur' }],
        giftNameEN: [{ required: true, message: this.$t('请输入英文名称'), trigger: 'blur' }],
        giftNameVN: [{ required: true, message: this.$t('请输入越南名称'), trigger: 'blur' }],
        giftIcon: [{ required: true, message: this.$t('请上传礼物图标'), trigger: 'change' }],
        gold: [
          { required: true, message: this.$t('请输入礼物价格'), trigger: 'blur' },
          { pattern: /^(0|[1-9][0-9]{0,7})+(.[0-9]{1,3})?$/, message: this.$t('请输入正确礼物价格'), trigger: 'blur' }
        ],
        giftType: [{ required: true, message: this.$t('请选择礼物属性'), trigger: 'change' }],
        sortBy: [
          { required: true, message: this.$t('请输入排序'), trigger: 'blur' },
          { pattern: /^(0|[1-9][0-9]{0,3})$/, message: this.$t('请输入正确序号'), trigger: 'blur' }
        ],
        giftAction: [{ required: true, message: this.$t('请上传礼物动画'), trigger: 'change' }]
      }
    }
  },
  computed: {

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    title() {
      return this.emitName === 'create' ? this.$t('礼物创建') : this.$t('礼物编辑')
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm.unionId = row.unionId
        this.ruleForm.giftName = row.giftName
        this.ruleForm.giftNameEN = row.giftNameEN
        this.ruleForm.giftNameVN = row.giftNameVN
        this.ruleForm.giftIcon = row.giftIcon
        this.ruleForm.gold = row.gold
        this.ruleForm.giftType = row.giftType
        this.ruleForm.sortBy = row.sortBy
        this.ruleForm.giftAction = row.giftAction
        this.ruleForm.giftStatus = row.giftStatus
        this.ruleForm.giftActionStatus = row.giftActionStatus
        // this.ruleForm.giftLinkStatus = row.giftLinkStatus
        this.emitName = 'update'
      }
      this.visible = true
    },
    beforeSvgaUpload(file) {
      const name = file.name
      const point = name.lastIndexOf('.')
      const isImage = name.substr(point) === '.svga'
      if (!isImage) {
        this.$message({
          type: 'warning',
          message: this.$t('您上载的档案格式不对！')
        })
        return false
      }
      return true
    },
    uploadIconSuccess(res) {
      this.ruleForm.giftIcon = res.data[0]
      this.$refs.validateForm.validateField('giftIcon')
    },
    uploadSvgaSuccess(res) {
      this.ruleForm.giftAction = res.data[0]
      this.$refs.validateForm.validateField('giftAction')
    }
  }
}
</script>

<style lang="scss" scoped>
.gifticon-uploader {
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    display: block;
    &:hover {
      border-color: #409EFF;
    }
  }

  .uploader-icon {
    font-size: 36px;
    color: #8c939d;
    width: 90px;
    height: 90px;
    line-height: 90px;
    text-align: center;
  }

  .image {
    width: 90px;
    height: 90px;
    display: block;
  }
}
</style>
