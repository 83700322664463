var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        attrs: { type: "number" },
        model: {
          value: _vm.formattedValue,
          callback: function ($$v) {
            _vm.formattedValue = $$v
          },
          expression: "formattedValue",
        },
      }),
      _vm._v(" % "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }