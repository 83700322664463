<template>
  <el-dialog title="添加定时消息" width="1200px" top="8vh" :visible.sync="visible" @closed="handleClosed">
    <el-form ref="validateForm" v-loading="loading" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item label="消息内容" prop="messageContent">
        <Tinymce ref="tinymce" v-model="ruleForm.messageContent" :maxlength="500" :height="200" />
      </el-form-item>
      <el-form-item label="发送用户" prop="senderUserId">
        <el-radio-group v-model="ruleForm.senderType" @input="sendUserChange">
          <el-radio :label="1">会员用户</el-radio>
          <el-radio :label="2">后台用户</el-radio>
        </el-radio-group>
        <template v-if="ruleForm.senderType == 1">
          <el-select :key="1" @change="setAdminList" v-lazy-load-dropdown="handleScrollDropdown"
            :remote-method="searchByName" filterable @focus="searchByName('')" remote
            style="margin-left: 20px; width: 300px;" v-model="ruleForm.senderUserId" placeholder="请选择会员用户">
            <el-option v-for="item in memberInfo" :key="item.memberId" :label="item.memberName"
              :value="item.memberId" />
          </el-select>
        </template>
        <template v-else>
          <el-select :key="0" @change="setUserList" v-lazy-load-dropdown="handleScrollDropdown"
            style="margin-left: 20px; width: 300px;" @focus="searchByName('')" v-model="ruleForm.senderUserId"
            placeholder="请选择会员用户" :remote-method="searchByName" filterable remote>
            <el-option v-for="item in adminInfo" :key="item.memberId" :label="item.nickname" :value="item.memberId" />
          </el-select>
        </template>
      </el-form-item>

      <el-form-item label="聊天室" prop="roomId">
        <el-select v-lazy-load-dropdown="handleScrollDropdown" @change="setChatList" remote filterable
          style="width: 300px;" :remote-method="searchChatByName" @focus="searchChatByName('')"
          v-model="ruleForm.roomId" placeholder="请选择聊天室类型">
          <el-option v-for="item in chatList" :key="item.roomId" :label="item.roomName" :value="item.roomId" />
        </el-select>
      </el-form-item>

      <el-form-item label="发送方式" prop="sendMethod">
        <el-radio-group v-model="ruleForm.sendMethod" @input="sendMethodChange">
          <el-radio :value-key="'send0'" :label="0">立即发送</el-radio>
          <el-radio :value-key="'send1'" :label="1">定时发送</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item  v-if="ruleForm.sendMethod == 1" label="预计发送时间" required
        prop="scheduledSendTime">
        <el-date-picker v-model="ruleForm.scheduledSendTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
          placeholder="请选择预计发送时间" :picker-options="pickerOptions"  :disabled-date="disabledDate" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import Tinymce from '@/components/Tinymce'
import { getLiveRoomList } from "@/api/chat";
import { getAllUsersByCondition } from "@/api/member";
import {
  getBackUserList,
} from '@/api/interactive'

const getDefaultForm = () => ({
  "senderType": 1,
  "senderUserId": '',
  senderUserName: "",
  "roomId": "",
  "roomName": "",
  "scheduledSendTime": "",
  messageContent: "",
  sendMethod: 0
})

let chatItems = [];
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  components: { Tinymce },
  directives: {
    'lazy-load-dropdown': {
      bind(el, binding) {
        const SELECT_DOM = el.querySelector('.el-select-dropdown .el-scrollbar__wrap')
        SELECT_DOM.addEventListener('scroll', () => {
          if (SELECT_DOM.scrollTop + SELECT_DOM.clientHeight >= SELECT_DOM.scrollHeight) {
            binding.value()
          }
        })
      }
    }
  },
  data() {
    return {
      emitName: 'create',
      loading: false,
      visible: false,
      memberInfo: [],
      adminInfo: [],
      componentKey: false,
      params: {},
      chatPage: {
        roomType: 1,
        currentPage: 1,
        pageSize: 20,
        roomName: ''
      },
      queryMemberPage: {
        currentPage: 1,
        pageSize: 20,
        memberName: ''
      },
      pickerOptions: {
        disabledDate(date) {
          return date.getTime() < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0)
        }
      },
      chatList: [],
      rules: {
        sendMethod: [{ required: true, message: '请选择发送方式', trigger: 'blur' }],
        senderUserId: [{ required: true, message: '请选择发送用户', trigger: 'change' }],
        scheduledSendTime: [{ required: true, message: '请选择预计发送时间', trigger: 'change' }],
        messageContent: [{ required: true, message: '请输入消息内容', trigger: 'change' }],
        roomId: [{ required: true, message: '请选择聊天室', trigger: 'change' }],
      }
    }
  },
  methods: {
    setAdminList(status) {
      this.memberInfo.forEach((item) => {
        if (item.memberId == status) {
          this.ruleForm.senderUserName = item.memberName;
        }
      })
    },
    setUserList(status) {
      this.adminInfo.forEach((item) => {
        if (item.memberId == status) {
          this.ruleForm.senderUserName = item.nickname;
        }
      })
    },
    setChatList(status) {
      this.chatList.forEach((item) => {
        if (item.roomId == status) {
          this.ruleForm.roomName = item.roomName;
        }
      })
    },
    open(row) {
      this.visible = true;
      this.queryMemberPage.currentPage = 1;
      this.queryMemberPage.memberName = '';
      if (row) {
        let rows = JSON.parse(JSON.stringify(row))
        this.ruleForm.id = rows.id;
        rows.scheduledSendTime =  this.$parseTime(rows.scheduledSendTime)
        this.ruleForm = { ...rows }
        this.searchByName()
        this.searchChatByName();
        this.componentKey = true;
        this.$nextTick(()=>{
          this.$set(this.ruleForm, 'sendMethod', rows.sendMethod)
        })
      }
    },
    getAdminList(loading) {

      getAllUsersByCondition(this.queryMemberPage).then(([items, err]) => {
        if (!err) {
          if (items.length === 0) {
            if (!loading) {
              this.memberInfo = [];
            }
          } else {
            if (!loading) {
              this.memberInfo = items;
            } else {
              this.memberInfo = this.memberInfo.concat(items)
            }
          }
        }
      }).finally(() => {
      })
      
    },
    queryMember(loading) {
      getBackUserList(this.queryMemberPage).then(([items, err]) => {
        if (!err) {
          if (items.length === 0) {
            if (!loading) {
              this.adminInfo = [];
            }
          } else {
            if (!loading) {
              this.adminInfo = items;
            } else {
              this.adminInfo = this.adminInfo.concat(items)
            }
          }
        }
      })
    },
    searchByName(name) {
      this.queryMemberPage.currentPage = 1;
      this.queryMemberPage.memberName = name;
      if (this.ruleForm.senderType == 2) {
        this.queryMember()
      } else {
        this.getAdminList()
      }
    },
    searchChatByName(name) {
      this.chatPage.currentPage = 1;
      this.chatPage.roomName = name;
      this.getChatList()
    },
    getChatList(loading) {
      getLiveRoomList(this.chatPage).then((res) => {
        chatItems = res[2].data
        if (chatItems.code == 200) {
          if (chatItems.items && chatItems.items.length) {
            if (!loading) {
              this.chatList = chatItems.items
            } else {
              this.chatList = this.chatList.concat(chatItems.items)
            }
          } else {
            if (!loading) {
              this.chatList = []
            }
          }
        }
      })
    },
    sendUserChange(status) {
      this.queryMemberPage.currentPage = 1;
      this.memberInfo = [];
      this.adminInfo = [];
      this.ruleForm.senderUserId = '';
      if (this.ruleForm.senderType == 2) {
        this.queryMember()
      } else {
        this.getAdminList()
      }
    },
    sendMethodChange(status) {
      this.ruleForm.scheduledSendTime = '';
    },
    handleScrollDropdown() {
      this.queryMemberPage.currentPage += 1;
      if (this.ruleForm.senderType == 2) {
        this.queryMember(true)
      } else {
        this.getAdminList(true)
      }
    },
    handleScrollDropdownByChat() {

    },
    handleSubmitBefor() {
      if (this.ruleForm.sendMethod == 1) {
        const ntm = new Date().getTime()
        let tm = this.ruleForm.scheduledSendTime
        tm = new Date(tm.replace(/'-'/g, '/')).getTime()
        if (tm < ntm) {
          this.$message.error('预计时间不能早于当前时间');
          return
        }
      }
      this.handleSubmit()
    },
    disabledDate(date) {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      return date < now;
    },
  }
}
</script>
