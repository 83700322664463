<template>
  <el-dialog
    :title="$t('绑定代付配置')"
    :visible.sync="visible"
    width="450px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('平台名称')" prop="payPlatformName">
        <el-input v-model="ruleForm.payPlatformName" />
      </el-form-item>
      <el-form-item :label="$t('平台标识')" prop="payPlatformCode">
        <el-input v-model="ruleForm.payPlatformCode" />
      </el-form-item>
      <el-form-item :label="$t('商户名')" prop="mchName">
        <el-input v-model="ruleForm.mchName" />
      </el-form-item>
      <el-form-item :label="$t('商户号')" prop="mchId">
        <el-input v-model="ruleForm.mchId" />
      </el-form-item>
      <el-form-item :label="$t('渠道码')" prop="payChannel">
        <el-input v-model="ruleForm.payChannel" />
      </el-form-item>
      <el-form-item :label="$t('最小代付金额')" prop="minAmount">
        <el-input v-model="ruleForm.minAmount" />
      </el-form-item>
      <el-form-item :label="$t('最大代付金额')" prop="maxAmount">
        <el-input v-model="ruleForm.maxAmount" />
      </el-form-item>
      <el-form-item :label="$t('网关')" prop="thirdPayGateway">
        <el-input v-model="ruleForm.thirdPayGateway" type="textarea" autosize />
      </el-form-item>
      <el-form-item :label="$t('货币种类')" prop="rateType">
        <el-select v-model="ruleForm.rateType" placeholder="" style="width:100%;">
          <el-option v-for="item in rateTypes" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('回调地址')" prop="notifyUrl">
        <el-input v-model="ruleForm.notifyUrl" type="textarea" autosize />
      </el-form-item>
      <el-form-item :label="$t('查询地址')" prop="thirdSearchGateway">
        <el-input v-model="ruleForm.thirdSearchGateway" type="textarea" autosize />
      </el-form-item>
      <el-form-item label="MD5key">
        <el-input v-model="ruleForm.md5Key" type="textarea" autosize />
      </el-form-item>
      <el-form-item :label="$t('RSA公钥')">
        <el-input v-model="ruleForm.publicKey" type="textarea" autosize />
      </el-form-item>
      <el-form-item :label="$t('RSA私钥')">
        <el-input v-model="ruleForm.privateKey" type="textarea" autosize />
      </el-form-item>
      <el-form-item :label="$t('IP白名单')">
        <el-input v-model="ruleForm.whiteIp" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { rateTypes } from '@/libs/options'
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  payPlatformName: '',
  payPlatformCode: '',
  mchName: '',
  mchId: '',
  payChannel: '',
  minAmount: '',
  maxAmount: '',
  thirdPayGateway: '',
  notifyUrl: '',
  md5Key: '',
  publicKey: '',
  privateKey: '',
  whiteIp: '',
  thirdSearchGateway: '',
  rateType: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validateMaxAmount = (rule, value, callback) => {
      if (!value || value === '') {
        callback(new Error(this.$t('请输入', { text: this.$('最大代付金额') })))
      } else {
        if (!/^\+?[1-9][0-9]*$/.test(value)) {
          callback(new Error(this.$t('请输入正整数')))
        } else {
          if (this.ruleForm.minAmount !== '' && value - this.ruleForm.minAmount <= 0) {
            callback(new Error(this.$t('最大代付金额需要大于最小代付金额')))
          }
        }
        callback()
      }
    }
    return {
      emitName: 'create',
      rules: {
        payPlatformName: [{ required: true, message: this.$t('请输入平台名称'), trigger: 'blur' }],
        payPlatformCode: [{ required: true, message: this.$t('请输入平台标识'), trigger: 'blur' }],
        mchName: [{ required: true, message: this.$t('请输入商户名'), trigger: 'blur' }],
        mchId: [{ required: true, message: this.$t('请输入商户号'), trigger: 'blur' }],
        payChannel: [{ required: true, message: this.$t('请输入', { text: this.$t('渠道码') }), trigger: 'blur' }],
        minAmount: [
          { required: true, message: this.$t('请输入', { text: this.$t('最小代付金额') }), trigger: 'blur' },
          { pattern: /^\+?[1-9][0-9]*$/, message: '请输入正整数', trigger: 'blur' }
        ],
        maxAmount: [
          { required: true, message: this.$t('请输入', { text: this.$t('最大代付金额') }), trigger: 'blur' },
          { validator: validateMaxAmount, trigger: 'blur' }
        ],
        thirdPayGateway: [{ required: true, message: this.$t('请输入网关'), trigger: 'blur' }],
        rateType: [{ required: true, message: this.$t('请选择货币种类'), trigger: 'change' }],
        notifyUrl: [{ required: true, message: this.$t('请输入回调地址'), trigger: 'blur' }],
        thirdSearchGateway: [{ required: true, message: this.$t('请输入查询地址'), trigger: 'blur' }]
      },
      rateTypes: rateTypes
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        // debugger
        // this.ruleForm.id = row.id
        // this.ruleForm.payPlatformName = row.payPlatformName
        // this.ruleForm.payPlatformCode = row.payPlatformCode
        // this.ruleForm.mchName = row.mchName
        // this.ruleForm.mchId = row.mchId
        // this.ruleForm.thirdPayGateway = row.thirdPayGateway
        // this.ruleForm.notifyUrl = row.notifyUrl
        // this.ruleForm.md5Key = row.md5Key
        // this.ruleForm.publicKey = row.publicKey
        // this.ruleForm.privateKey = row.privateKey
        // this.ruleForm.whiteIp = row.whiteIp
        // this.ruleForm.rateType = row.rateType
        row.minAmount = parseInt(row.minAmount)
        row.maxAmount = parseInt(row.maxAmount)
        Object.assign(this.ruleForm, row)

        this.emitName = 'update'
      }
      this.visible = true
    }
  }
}
</script>
