import currencies from '../cash'
const CanChooseColumns = function() {
  return [
    {
      title: '排序',
      key: 'sort',
      align: 'center'

    },
    {
      title: '名称',
      key: 'name',
      align: 'center'
    },
    {
      title: '图标',
      key: 'icon',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image style='width:48px;height:48px' src={row.icon} lazy></el-image>)
        }
      }
    },
    // {
    //   title: '货币',
    //   key: 'rateType',
    //   align: 'center'
    // },

    {
      title: '货币',
      key: 'rateType',
      align: 'center',
      component: {
        render: (h, row) => {
          let name = currencies.find(p => p.id === row.rateType).name
          return (<span>{name}</span>)
        }
      }
    },

    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 270,
      component: {
        render: (h, row) => {
          // const { Update, Delete, Close } = this.permission

          return (

            <div>

              {<el-button type='primary' size='small' icon='el-icon-edit' onClick={() => { this.$refs.ActionDialogControl.open(row) }}>{this.$t('修改')}</el-button>}
              {<el-button type='danger' size='small' icon='el-icon-delete' onClick={() => this.handleDeleteActivity(row)}>{this.$t('删除')}</el-button>}
              {/* { Update && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.ActionDialogControl.open(row) } }>{ this.$t('修改') }</el-button> }
              { Delete && <el-button type='danger' size='small' icon='el-icon-delete' onClick={() => this.handleDeleteActivity(row)}>{ this.$t('删除') }</el-button> } */}
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
