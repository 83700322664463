var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.emitName === "create"
            ? _vm.$t("创建VIP等级")
            : _vm.$t("编辑VIP等级"),
        visible: _vm.visible,
        "close-on-click-modal": false,
        width: "430px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("VIP等级"), prop: "vipLevel" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.ruleForm.vipLevel === 0,
                  placeholder: _vm.$t("请输入VIP等级(唯一)"),
                },
                model: {
                  value: _vm.ruleForm.vipLevel,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "vipLevel", _vm._n($$v))
                  },
                  expression: "ruleForm.vipLevel",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("充值金额"),
                prop: "rechargeUpgradeAmount",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.ruleForm.vipLevel === 0,
                  placeholder: _vm.$t("请输入升级所需充值金额"),
                },
                model: {
                  value: _vm.ruleForm.rechargeUpgradeAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "rechargeUpgradeAmount", $$v)
                  },
                  expression: "ruleForm.rechargeUpgradeAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("升级有效投注"),
                prop: "upgradeLevelMinPlayAmount",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.ruleForm.vipLevel === 0,
                  placeholder: _vm.$t("升级所需有效投注金额(输入0代表不需要)"),
                },
                model: {
                  value: _vm.ruleForm.upgradeLevelMinPlayAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "upgradeLevelMinPlayAmount", $$v)
                  },
                  expression: "ruleForm.upgradeLevelMinPlayAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("保级有效投注"),
                prop: "keepLevelMinPlayAmount",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.ruleForm.vipLevel === 0,
                  placeholder: _vm.$t("保级所需有效投注金额(输入0代表不需要)"),
                },
                model: {
                  value: _vm.ruleForm.keepLevelMinPlayAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "keepLevelMinPlayAmount", $$v)
                  },
                  expression: "ruleForm.keepLevelMinPlayAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("保级计算周期"), prop: "keepLevelDays" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    disabled: _vm.ruleForm.vipLevel === 0,
                    placeholder: _vm.$t("保级计算天数(输入0代表不需要)"),
                  },
                  model: {
                    value: _vm.ruleForm.keepLevelDays,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "keepLevelDays", $$v)
                    },
                    expression: "ruleForm.keepLevelDays",
                  },
                },
                [
                  _c("span", { attrs: { slot: "suffix" }, slot: "suffix" }, [
                    _vm._v("天"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("VIP等级图标"), prop: "vipLog" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "icon-uploader",
                  attrs: {
                    accept: "image/png,image/webp",
                    action: _vm.action,
                    data: { type: "image", folder: "icon" },
                    headers: _vm.$headers(),
                    "show-file-list": false,
                    "on-success": _vm.uploadSuccess,
                  },
                },
                [
                  _vm.ruleForm.vipLog
                    ? _c("img", {
                        staticClass: "icon",
                        attrs: { src: _vm.ruleForm.vipLog },
                      })
                    : _c("i", { staticClass: "el-icon-upload uploader-icon" }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }