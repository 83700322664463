import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: '账号',
      key: 'operatorName',
      align: 'center',
      width: 120
    },
    {
      title: '昵稱',
      key: 'nickName',
      align: 'center'
    },
    {
      title: '组别',
      key: 'groupName',
      align: 'center',
      width: 120
    },
    {
      title: '最后登录时间',
      key: 'lastLoginTime',
      align: 'center',
      width: 150,
      component: {
        render: (h, row) => {
          return <span>{ this.$parseTime(row.lastLoginTime) }</span>
        }
      }
    },
    {
      title: '创建时间',
      key: 'createTime',
      align: 'center',
      width: 150,
      component: {
        render: (h, row) => {
          return <span>{ this.$parseTime(row.createTime) }</span>
        }
      }
    },
    {
      title: '创建人',
      key: 'createUser',
      align: 'center',
      width: 100
    },
    {
      title: '修改时间',
      key: 'updateTime',
      align: 'center',
      width: 150,
      component: {
        render: (h, row) => {
          return <span>{ this.$parseTime(row.updateTime) }</span>
        }
      }
    },
    {
      title: '修改人',
      key: 'updateUser',
      align: 'center',
      width: 100
    },
    {
      title: '账号状态',
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          if (this.permission.UpdateStatus) {
            return (
              <d-switch v-model={row.status} beforeChange={() => this.handleChangeStatus(row)}>
                <span slot='open'>正常</span>
                <span slot='close'>冻结</span>
              </d-switch>
            )
          } else {
            return (<span>{ row.status === 0 ? '正常' : '冻结' }</span>)
          }
        }
      }
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center',
      component: {
        render: (h, row) => {
          if (this.permission.Update) {
            return (<el-link type='primary' onClick={() => this.$refs.UpdateDialogControl.open(row)}>编辑</el-link>)
          }
        }
      }
    },
    {
      title: '备注',
      key: 'remark',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          if (row.remark) {
            return (<span>{ row.remark }</span>)
          }
          if (this.permission.Remark) {
            return (
              <el-link
                type='danger'
                onClick={() => this.$refs.RemarkDialogControl.open({ operatorId: row.operatorId, tenantCode: row.tenantCode })}>添加备注</el-link>)
          }
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
