<template>
  <div class="app-container">
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <div slot="left" class="filter-container">
        <el-input v-model="search.channelName" :placeholder="$t('频道名称')" clearable class="filter-item" style="width: 200px;" />
        <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleRefresh">{{ $t('查询') }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </div>
      <el-button v-if="permission.Add" slot="right" icon="el-icon-plus" type="success" @click="onCreate">{{ $t('新建频道') }}</el-button>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" @update="_handleUpdate" />
  </div>
</template>

<script>
import { getLiveChannelList, doSaveLiveChannel, doUpdateLiveChannel, doDelLiveChannel } from '@/api/live'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { dataSource, dataSourceLive } from '@/libs/options'
import { mapState } from 'vuex'

function getParams(field) {
  let params = { ...field }
  if (params.dataSource === '20') {
    params.dataSource = params.dataSourceLive
  }
  delete params.channelName
  delete params.displayName
  delete params.dataSourceLive
  return params
}

export default {
  name: 'LiveChannel',
  components: { ActionDialog },
  mixins: [TablePageMixin(getLiveChannelList)],
  data() {
    return {
      search: {},
      filterColumn: false,
      dataSource,
      dataSourceLive,
      columns: GetColumns.call(this)
    }
  },
  computed: {
    ...mapState('app', ['allLang'])
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogControl.open()
    },
    _handleCreate({ field, cancel, close }) {
      const params = getParams(field)
      doSaveLiveChannel(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      const params = getParams(field)
      doUpdateLiveChannel(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleDelete({ unionId }) {
      this.$confirm(this.$t('删除频道后，原来归属于该频道下的房间只会出现在热门频道内!'), this.$t('请谨慎操作'), {
        type: 'warning',
        center: true
      }).then(() => {
        doDelLiveChannel({ unionId }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
