<template>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="210px">
    <el-row v-loading="loading" :gutter="96" class="basic_config_content">
      <el-col :span="12">
        <h2>{{ $t('登录注册') }}</h2>
        <el-form-item :label="$t('同IP每日注册个数')" prop="ipRegistNum">
          <el-input v-model.number="ruleForm.ipRegistNum" />
        </el-form-item>
        <el-form-item :label="$t('同IP每日登录个数')" prop="ipLoginNum">
          <el-input v-model.number="ruleForm.ipLoginNum" />
        </el-form-item>
        <el-form-item :label="$t('前端输入密码错误冻结次数')" prop="passwordWrongNum">
          <el-input v-model.number="ruleForm.passwordWrongNum" />
        </el-form-item>
        <el-form-item :label="$t('后台Google验证')" prop="googleStatus">
          <d-switch v-model="ruleForm.googleStatus">
            <span slot="open">{{ $t('开启') }}</span>
            <span slot="close">{{ $t('关闭') }}</span>
          </d-switch>
        </el-form-item>
        <el-form-item label="C端图形验证码" prop="isVerifi">
          <d-switch v-model="ruleForm.isVerifi">
            <span slot="open">{{ $t('开启') }}</span>
            <span slot="close">{{ $t('关闭') }}</span>
          </d-switch>
        </el-form-item>
        <el-form-item label="真实姓名开关" prop="realNameSwitch">
          <d-switch v-model="ruleForm.realNameSwitch" :active-value="1" :inactive-value="0">
            <span slot="open">{{ $t('开启') }}</span>
            <span slot="close">{{ $t('关闭') }}</span>
          </d-switch>
        </el-form-item>
        <el-form-item label="新设备安全验证" prop="newCheckDeviceSwitch">
          <d-switch v-model="ruleForm.newCheckDeviceSwitch" :active-value="1" :inactive-value="0">
            <span slot="open">{{ $t('开启') }}</span>
            <span slot="close">{{ $t('关闭') }}</span>
          </d-switch>&nbsp;&nbsp;提示：开启时，已绑定手机号码的账号更换设备账号密码登录时需要进行手机验证码验证
        </el-form-item>

        <h2>{{ $t('购彩配置') }}</h2>
        <el-form-item :label="$t('是否允许用户撤单')" prop="cancelOrderStatus">
          <el-radio-group v-model="ruleForm.cancelOrderStatus">
            <el-radio :label="0">{{ $t('启用') }}</el-radio>
            <el-radio :label="1">{{ $t('关闭') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('投注默认一注额度')" prop="betAmount">
          <el-input v-model.number="ruleForm.betAmount"><template slot="append">￥</template></el-input>
        </el-form-item>

        <h2>{{ $t('代理返点') }}</h2>
        <el-form-item :label="$t('一级返佣比例')" prop="oneLevelRakeBack">
          <el-input v-model="ruleForm.oneLevelRakeBack"><template slot="append">%</template></el-input>
        </el-form-item>
        <el-form-item :label="$t('二级返佣比例')" prop="twoLevelRakeBack">
          <el-input v-model="ruleForm.twoLevelRakeBack"><template slot="append">%</template></el-input>
        </el-form-item>
        <el-form-item :label="$t('结算方式')" prop="settlementType">
          <el-radio-group v-model="ruleForm.settlementType">
            <el-radio :label="1">{{ $t('人工') }}</el-radio>
            <el-radio :label="2">{{ $t('自动') }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <h2>游戏返水</h2>
        <el-form-item label="返水周期设置" prop="lotteryBackCalculateDay">
          <el-select v-model="ruleForm.lotteryBackCalculateDay" style="width: 120px" placeholder="请选择">
            <el-option :value="0" label="每天" />
            <el-option :value="1" label="周一" />
            <el-option :value="2" label="周二" />
            <el-option :value="3" label="周三" />
            <el-option :value="4" label="周四" />
            <el-option :value="5" label="周五" />
            <el-option :value="6" label="周六" />
            <el-option :value="7" label="周日" />
          </el-select>&nbsp;&nbsp;#返水周期，筛选每天、周一..到周日
        </el-form-item>
        <el-form-item label="返水时间" prop="lotteryBackCalculateTime">
          <el-time-picker
            v-model="ruleForm.lotteryBackCalculateTime"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59'
            }"
            style="width: 120px"
            placeholder="返水时间"
            value-format="HH:mm:ss"
          />&nbsp;&nbsp;#时钟筛选，设置具体返水时间
        </el-form-item>

        <h2>{{ $t('国家区号') }}</h2>
        <el-form-item :label="$t('区号')" prop="regins">
          <el-input
            v-model="ruleForm.regins"
            type="textarea"
            :rows="4"
            placeholder="$t('登录注册')区号前需加上+号，支持填入多个国家区号，用英文逗号分开，例：+86,+84"
          />
        </el-form-item>

        <h2>{{ $t('维护') }}</h2>
        <el-form-item :label="$t('前端维护')" prop="maintainStatus">
          <d-switch v-model="ruleForm.maintainStatus" :active-value="1" :inactive-value="0">
            <span slot="open">{{ $t('开启') }}</span>
            <span slot="close">{{ $t('关闭') }}</span>
          </d-switch>
        </el-form-item>
        <el-form-item :label="$t('维护说明')" prop="maintainInfo">
          <el-input
            v-model="ruleForm.maintainInfo"
            type="textarea"
            :rows="4"
            maxlength="100"
            show-word-limit
            :placeholder="$t('请输入内容')"
          />
        </el-form-item>

        <h2>域名配置</h2>
        <el-form-item label="心水域名" prop="favoriteDomain">
          <el-input v-model="ruleForm.favoriteDomain" :placeholder="`${$t('例如')}: www.123.com/`" />
        </el-form-item>
        <el-form-item label="聊天API域名" prop="chatApiDomain">
          <el-input v-model="ruleForm.chatApiDomain" :placeholder="`${$t('例如')}: www.123.com/`" />
        </el-form-item>
        <el-form-item label="聊天Socket域名" prop="chatSocketDomain">
          <el-input v-model="ruleForm.chatSocketDomain" :placeholder="`${$t('例如')}: www.123.com/`" />
        </el-form-item>
        <el-form-item label="直播开奖域名" prop="liveDrawingDomain">
          <el-input v-model="ruleForm.liveDrawingDomain" :placeholder="`${$t('例如')}: www.123.com/`" />
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <h2>{{ $t('存取款配置') }}</h2>
        <el-form-item :label="$t('提款、入款开始处理时间')" prop="startTime">
          <el-time-select
            v-model="ruleForm.startTime"
            :placeholder="$t('开始时间')"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
              maxTime: ruleForm.endTime
            }"
          />
        </el-form-item>
        <el-form-item :label="$t('提款、入款结束处理时间')" prop="endTime">
          <el-time-select
            v-model="ruleForm.endTime"
            :placeholder="$t('结束时间')"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
              minTime: ruleForm.startTime
            }"
          />
        </el-form-item>
        <el-form-item :label="$t('提款最低额度')" prop="withdrawMinAmount">
          <el-input v-model="ruleForm.withdrawMinAmount"><template slot="append">￥</template></el-input>
        </el-form-item>
        <el-form-item :label="$t('提款最高额度')" prop="withdrawMaxAmount">
          <el-input v-model="ruleForm.withdrawMaxAmount"><template slot="append">￥</template></el-input>
        </el-form-item>
        <el-form-item :label="$t('2次充值时间的间隔')" prop="rechargeInterval">
          <el-input v-model.number="ruleForm.rechargeInterval"><template slot="append">{{ $t('秒') }}</template></el-input>
        </el-form-item>
        <el-form-item :label="$t('2次提款时间的间隔')" prop="withdrawInterval">
          <el-input v-model.number="ruleForm.withdrawInterval"><template slot="append">{{ $t('秒') }}</template></el-input>
        </el-form-item>
        <el-form-item :label="$t('提款次数只记录成功次数')" prop="recordWithdrawStatus">
          <d-switch v-model="ruleForm.recordWithdrawStatus">
            <span slot="open">{{ $t('开启') }}</span>
            <span slot="close">{{ $t('关闭') }}</span>
          </d-switch>
        </el-form-item>
        <!-- <el-form-item :label="$t('每日最高提现次数')" prop="withdrawaDailyNum">
          <el-input v-model.number="ruleForm.withdrawaDailyNum"><template #append>{{ $t('次') }}</template></el-input>
        </el-form-item> -->
        <el-form-item :label="$t('存款未处理记录失效时间')" prop="rechargeInvalidTime">
          <el-input v-model="ruleForm.rechargeInvalidTime"><template slot="append">{{ $t('小时') }}</template></el-input>
        </el-form-item>
        <el-form-item :label="$t('提款未处理记录失效时间')" prop="withdrawInvalidTime">
          <el-input v-model="ruleForm.withdrawInvalidTime"><template slot="append">{{ $t('小时') }}</template></el-input>
        </el-form-item>
        <el-form-item :label="$t('新增修改银行卡不能提款时间')" prop="bankWithdrawForbid">
          <el-input v-model.number="ruleForm.bankWithdrawForbid"><template slot="append">{{ $t('分钟') }}</template></el-input>
        </el-form-item>
        <el-form-item :label="$t('绑定银行卡数量')" prop="bankBindNum">
          <el-input v-model.number="ruleForm.bankBindNum" />
        </el-form-item>

        <h2>{{ $t('直播') }}</h2>
        <el-form-item label="直播开关" prop="liveShow">
          <d-switch v-model="ruleForm.liveShow" :active-value="1" :inactive-value="0">
            <span slot="open">{{ $t('开启') }}</span>
            <span slot="close">{{ $t('关闭') }}</span>
          </d-switch>&nbsp;&nbsp;关闭后用户进入首页:不显示直播入口
        </el-form-item>
        <el-form-item :label="$t('主播联系方式金额')" prop="anchorContactAmount">
          <el-input v-model="ruleForm.anchorContactAmount" />
        </el-form-item>

        <el-form-item :label="$t('广播礼物金额')" prop="broadcastGiftAmount">
          <el-input v-model="ruleForm.broadcastGiftAmount" />
        </el-form-item>
        <el-form-item :label="$t('免费时长设置')" prop="freeTime">
          <el-input v-model.number="ruleForm.freeTime" placeholder="5-300"><template slot="append">{{ $t('秒') }}</template></el-input>
        </el-form-item>
        <h2>{{ $t('图形验证码错误次数') }}</h2>
        <el-form-item :label="$t('错误次数')" prop="imgCaptchaNum">
          <el-input v-model.number="ruleForm.imgCaptchaNum" placeholder="5-300" />
        </el-form-item>
        <h2>欧洲杯精选</h2>
        <el-form-item label="欧洲杯精选开关" prop="eurocupStatus">
          <d-switch v-model="ruleForm.eurocupStatus" :active-value="1" :inactive-value="0">
            <span slot="open">{{ $t('开启') }}</span>
            <span slot="close">{{ $t('关闭') }}</span>
          </d-switch>&nbsp;&nbsp;关闭后不显示欧洲杯精选入口
        </el-form-item>
        <h2>新版首页在线人数设置</h2>
        <el-form-item label="虚拟人数" prop="virtualUserCount">
          <el-input v-model.number="ruleForm.virtualUserCount" placeholder="虚拟人数" />&nbsp;&nbsp;提示：首页在线人数=虚拟人数+真实在线人数
        </el-form-item>
        <h2>新版资源配置</h2>
        <el-form-item label="YouTube" prop="youtubeAddress">
          <el-input v-model="ruleForm.youtubeAddress" placeholder="YouTube" />
        </el-form-item>
        <el-form-item label="Facebook" prop="facebookAddress">
          <el-input v-model="ruleForm.facebookAddress" placeholder="Facebook" />
        </el-form-item>
        <el-form-item label="Telegram" prop="telegramAddress">
          <el-input v-model="ruleForm.telegramAddress" placeholder="Telegram" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item class="action-button">
      <el-button type="danger" :loading="submitting" @click="handleSubmit">{{ $t('保 存') }}</el-button>
      <el-button @click="$refs['ruleForm'].resetFields()">{{ $t('重 置') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getBaseConfig, editBaseConfig } from '@/api/config.js'
import { getRuleForm, getRules } from '@/views/common/config'
export default {
  name: 'BaseTab',
  componentName: 'BaseTab',
  data() {
    return {
      loading: false,
      submitting: false,
      ruleForm: getRuleForm(),
      rules: getRules.call(this)
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.loading = true
      getBaseConfig().then(([data, err]) => {
        this.loading = false
        if (!err && data) {
          this.ruleForm = {
            ...data,
            anchorContactAmount: String(+data.anchorContactAmount),
            broadcastGiftAmount: String(+data.broadcastGiftAmount),
            favoriteDomain: data.domainConfig.favoriteDomain,
            chatApiDomain: data.domainConfig.chatApiDomain,
            chatSocketDomain: data.domainConfig.chatSocketDomain,
            liveDrawingDomain: data.domainConfig.liveDrawingDomain,
          }
        }
      })
    },
    handleSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this._updateConfig()
        }
      })
    },
    _updateConfig() {
      this.submitting = true
      const params = { ...this.ruleForm }
      params.domainConfig = {
        favoriteDomain: params.favoriteDomain,
        chatApiDomain: params.chatApiDomain,
        chatSocketDomain: params.chatSocketDomain,
        liveDrawingDomain: params.liveDrawingDomain
      }
      delete params.favoriteDomain
      delete params.chatApiDomain
      delete params.chatSocketDomain
      delete params.liveDrawingDomain
      editBaseConfig(params).then(([_, err]) => {
        this.submitting = false
        if (!err) {
          this.$message.success(this.$t('保存成功'))
        }
      })
    }
  }
}
</script>
