<template>
  <el-dialog
    title="编辑"
    :visible.sync="visible"
    width="500px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('活动分类')" prop="activityCategoryId">
        <el-select v-model="ruleForm.activityCategoryId" :loading="categoryLoading" @change="handleChangeCategory">
          <el-option v-for="(item, index) in activityCategoryList" :key="index" :value="item.id" :label="item.typeName" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('活动标题')" prop="activityId">
        <el-select v-model="ruleForm.activityId" @change="handleChangeActivity">
          <el-option v-for="(item, index) in activityList" :key="index" :value="item.id" :label="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <d-switch v-model="ruleForm.status" :active-value="1" :inactive-value="0">
          <span slot="open">启用</span>
          <span slot="close">禁用</span>
        </d-switch>
      </el-form-item>
      <el-form-item label="活动图片" prop="activityImage">
        <d-upload v-model="ruleForm.activityImage" folder="icon" text="上传活动图片" tip-text="提示：建议尺寸358*70，支持上传PNG、JPG、GIF、SVG图片，大小不超过2MB。" :on-success="handleIconSuccess" />
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import DUpload from '@/components/DUpload'
import { activityNewList, getActivityConfTypeList } from '@/api/activity'
const getDefaultForm = () => ({
  id: undefined,
  activityId: '',
  activityName: '',
  activityCategoryId: '',
  activityCategoryName: '',
  status: 1,
  activityImage: '',
  sort: '',
  position: 0,
  gameKey: 0
})

export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      categoryLoading: false,
      activityCategoryList: [],
      activityLoading: false,
      activityList: [],
      rules: {
        activityCategoryId: [{ required: true, message: this.$t('请选择活动分类'), trigger: 'change' }],
        activityId: [{ required: true, message: this.$t('请选择活动标题'), trigger: 'change' }],
        status: [{ required: true, message: this.$t('请选择状态'), trigger: 'change' }],
        activityImage: [{ required: true, message: this.$t('请上传活动图片'), trigger: 'change' }]
      }
    }
  },
  methods: {
    open(row) {
      for(let key in this.ruleForm) {
        if(key in row) {
          this.ruleForm[key] = row[key]
        }
      }
      this.handleActivityConfTypeList()
      if (this.ruleForm.activityCategoryId !== '') {
        this.handleActivityList()
      }
      this.visible = true
    },
    handleChangeCategory() {
      if (this.ruleForm.activityCategoryId !== '') {
        this.ruleForm.activityCategoryName = this.activityCategoryList.find(e => e.id === this.ruleForm.activityCategoryId)?.typeName
        this.ruleForm.activityId = ''
        this.handleActivityList()
      }
    },
    handleActivityConfTypeList() {
      this.categoryLoading = true
      getActivityConfTypeList({ currentPage: 1, pageSize: 200 }).then(([items, err]) => {
        if (!err) {
          if (items.length > 0) {
            const list = []
            items.forEach(e => {
              const typeNameList = e.typeName.split(',')
              list.push({
                id: e.id,
                typeName: typeNameList[0].split(':')[1],
                typeNameLang: e.typeName
              })
            })
            this.activityCategoryList = list
          }
        }
      }).finally(() => {
        this.categoryLoading = false
      })
    },
    handleActivityList() {
      this.activityLoading = true
      activityNewList({ currentPage: 1, pageSize: 200, category: this.ruleForm.activityCategoryId }).then(([items, err]) => {
        if (!err) {
          this.activityList = items.filter(e => e.status !== 2).map(e => {
            return {
              id: e.templateId,
              name: e.title
            }
          })
          if (this.ruleForm.activityId && !this.activityList.some(e => e.id === this.ruleForm.activityId)) {
            this.ruleForm.activityId = ''
          }
        }
      }).finally(() => {
        this.activityLoading = false
      })
    },
    handleIconSuccess(res) {
      this.ruleForm.activityImage = res[0]
    },
    handleChangeActivity() {
      this.ruleForm.activityName = this.activityList.find(e => e.id === this.ruleForm.activityId)?.name
    }
  }
}
</script>