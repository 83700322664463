export default {
  memberTypes: [
    { key: 1, label: 'Registered tourists' },
    { key: 2, label: 'Registered Account' },
    { key: 3, label: 'Recharge account' },
    { key: 4, label: 'Test account' }
  ],
  memberType: [
    { key: [1], label: 'Inside Account' },
    { key: [2,3], label: 'Common Account' },
  ],
  depositTabs: [
    { label: 'Recharge order approval', name: 'CheckTab' },
    { label: 'Recharge Order List', name: 'ListTab' }
  ],
  rechargeStatus: [
    { key: 0, label: 'All' },
    { key: 1, label: 'First recharge' },
    { key: 2, label: 'Secondary recharge' },
    { key: 3, label: 'Multiple recharge' }
  ],
  withdrawalTabs: [
    { label: 'Cash withdrawal order picking', name: 'ReceiveTab' },
    { label: 'Withdrawal order audit', name: 'CheckTab' },
    { label: 'Withdrawal order list', name: 'ListTab' }
  ],
  dateFilterButtons: [
    { label: 'today', key: 'today' },
    { label: 'yesterday', key: 'yesterday' },
    { label: 'this Month', key: 'thisMonth' },
    { label: 'last Month', key: 'lastMonth' }
  ],
  configBasicTabs: [
    { label: 'Basic config', name: 'BaseTab' },
    { label: 'Interactive config', name: 'InteractionTab' },
    { label: 'Address config', name: 'AddressTab' },
    { label: 'Third-party game config', name: 'ThirdTab' },
    // { label: 'Carsh config', name: 'CarshTab' },
    { label: 'Exchange rate config', name: 'RateTab' }
  ],
  smsVendorOptions: [
    { signname: 'YUNZHIXUN', smsVendor: 'YUNZHIXUN', sortOrder: 0 },
    { signname: 'ALI', smsVendor: 'ALI', sortOrder: 1 },
    { signname: 'JIGUANG', smsVendor: 'JIGUANG', sortOrder: 2 },
    { signname: 'DUANXINBAO', smsVendor: 'DUANXINBAO', sortOrder: 3 },
    { signname: 'WANGYIDUN', smsVendor: 'YIDUN', sortOrder: 4 },
    { signname: '闪速码', smsVendor: 'SHANSUMA', sortOrder: 5 },
    { signname: '腾讯云', smsVendor: 'TENCENT', sortOrder: 6 }
  ],
  localesOptions: [
    { name: 'Zn', label: 'Chinese' },
    { name: 'Vi', label: 'Vietnam' },
    { name: 'En', label: 'English' }
  ],
  displayTabs: [
    { label: 'Home recommend', name: 'HomeTab' },
    { label: 'Casino hot', name: 'CasinoTab' },
    { label: 'Casino', name: 'CityTab' },
    { label: 'Color icon configuration', name: 'IconTab' },
    { label: '账变图标配置', name: 'IconAmountTab' },
    { label: 'Rich text configuration', name: 'ContentTab' },
    { label: 'FooterMenuTab', name: 'FooterMenuTab' },
    { label: 'LiuheTab', name: 'LiuheTab' },
    { label: 'XinshuiTab', name: 'XinshuiTab' },
    { label: '新版首页设置', name: 'NewHomeTab' },
    { label: '新版滚球顶部导航设置', name: 'NewBallTopTab' },
    { label: '新版活动配置', name: 'NewActivityTab' }
  ],
  activityTabs: [
    { label: 'Ordinary Activity', name: 'ConfigTab' },
    { label: 'Red Activity', name: 'RedTab' },
    { label: 'Activity classification', name: 'ClassificationTab' },
    { label: 'New Activity', name: 'NewConfigTab' }
  ],
  taskTabs: [
    { label: 'Task management', name: 'ManageTab' },
    { label: 'Task classification', name: 'ClassificationTab' },
    { label: 'Reward Record', name: 'RewardTab' },
    { label: 'Sign-in configuration', name: 'SigninConfigTab' },
    { label: 'Attendance Record', name: 'SigninRecordTab' }
  ],
  agencyRebateDataTabs: [
    { label: 'Agency data', name: 'IndexTab' },
    { label: 'Agency config', name: 'ConfigTab' },
    { label: 'Agency User', name: 'AgencyUserTab' },
    { label: 'Agency transfer record', name: 'TransferTab' }
  ],
  agencyRebateConfigTabs: [
    { label: 'Agency rebate config', name: 'IndexTab' },
    { label: 'Agency remark config', name: 'RemarkTab' },
    { label: 'Agency promotion config', name: 'PromotionTab' }
  ],
  paymentTypes: [
    { value: 1, label: 'Manual' },
    { value: 2, label: 'Auto' }
  ],
  dateTypes: [
    { value: 1, label: 'Date' },
    { value: 2, label: 'This week' },
    { value: 3, label: 'Lasted week' },
    { value: 4, label: 'This month' },
    { value: 5, label: 'Lasted month' },
    { value: 6, label: 'Lasted Lasted month' }

  ],
  dataGroups: [
    { value: 3, label: 'Direct and second subordinate' },
    { value: 1, label: 'Direct subordinate' },
    { value: 2, label: 'second level subordinate' }// carlos 说隐藏团队数据选项
    // { value: 0, label: 'Team' }
  ],
  showGroups: [
    { value: 'isShowTeam', label: 'Team data' },
    { value: 'isShowOneLevel', label: 'Direct subordinate data' },
    { value: 'isShowTwoLevel', label: 'Second level data' }
  ],
  agencyMemberType: [
    { value: 0, label: 'Normal User' },
    { value: 1, label: 'Agency User' }
  ],
  showDataTypes: [
    { value: 'isShowMember', label: 'Member count' },
    { value: 'isShowRegister', label: 'Register' },
    { value: 'isShowFirstRecharge', label: 'First recharge' },
    { value: 'isShowRecharge', label: 'Recharge' },
    { value: 'isShowWithdraw', label: 'Withdrawal' },
    { value: 'isShowProfit', label: 'Deposit and withdrawal profit' },
    { value: 'isShowLotteryMember', label: 'Lottery member' },
    { value: 'isShowLotteryBet', label: 'Lottery bet amount' },
    { value: 'isShowLotteryProfit', label: 'Lottery profit' },
    { value: 'isShowSportMember', label: 'Sport member' },
    { value: 'isShowSportBet', label: 'Sport bet amount' },
    { value: 'isShowSportProfit', label: 'Sport profit' },
    { value: 'isShowNativeSportMember', label: 'Native sport member' },
    { value: 'isShowNativeSportBet', label: 'Native sport bet amount' },
    { value: 'isShowNativeSportProfit', label: 'Native sport profit' },
    { value: 'isShowThirdMember', label: 'Third sport member' },
    { value: 'isShowThirdBet', label: 'Third sport bet amount' },
    { value: 'isShowThirdProfit', label: 'Third sport profit' },
    { value: 'isShowActivity', label: 'Activity amount' },
    { value: 'isShowCashback', label: 'Cashback' },
    { value: 'isShowRebate', label: 'Rebate' },
    { value: 'isShowAchievements', label: 'Achievements' }
  ],
  detailTypes: [
    { value: 1, label: 'Register' },
    { value: 2, label: 'First recharge' },
    { value: 3, label: 'Recharge' },
    { value: 4, label: 'Withdrawal' },
    { value: 5, label: 'Manual add and reduce amount' },
    { value: 6, label: 'Activity amount' },
    { value: 7, label: 'Cashback amount' },
    { value: 8, label: 'Agency rebate' },
    { value: 9, label: 'Lottery' },
    { value: 10, label: 'Sport' },
    { value: 11, label: 'Native sport' },
    { value: 12, label: 'Third sport' }
  ],
  displayTypeList: [
    { key: 1, lable: 'Top on Casino' },
    { key: 2, lable: 'Chess and card' },
    { key: 3, lable: 'one-arm bandit' },
    { key: 4, lable: 'Real person' },
    { key: 5, lable: 'fishing' },
    { key: 6, lable: 'lottery' }
  ],
  keywordsMaps: [
    { key: 1, label: 'Member nickname' },
    { key: 2, label: 'Member name' },
    { key: 3, label: 'Mobile number' },
    { key: 4, label: 'Last login IP' },
    { key: 5, label: 'Register IP' },
    { key: 6, label: 'registration place' },
    { key: 7, label: 'bank account number' }
  ],
  defaultGameType: {
    1: 'Lottery',
    2: 'Chess and card',
    3: 'Slot machine',
    4: 'Fishing',
    5: 'real person',
    6: 'Horse racing',
    7: 'Sports'
  },
  carshStatus: [
    { key: 1, label: 'To be deducted', type: 'primary' },
    { key: 2, label: 'To be awarded', type: 'primary' },
    { key: 3, label: 'Failure to win the prize', type: 'info' },
    { key: 4, label: 'Has won the prize', type: 'danger' }
  ],
  responseStatus: {
    请求错误: 'Request error',
    '未授权，请登录': 'Unauthorized, please login',
    拒绝访问: 'Access denied',
    请求地址出错: 'Error requesting address',
    服务器内部错误: 'Internal server error',
    请求超时: 'Request timeout',
    服务未实现: 'Service not implemented',
    网关错误: 'Gateway error',
    服务不可用: 'Service unavailable',
    网关超时: 'Gateway timeout',
    HTTP版本不受支持: 'HTTP version is not supported',
    '暂停服务，请稍后再试！': 'The service is suspended. Please try again later!',
    '该接口暂停服务，请稍后再试': 'The interface is out of service, please try again later'
  },
  Member_Expert: {
    专家列表: 'List of experts',
    专家等级管理: 'expert level management',
    ExpertTab: {
      专家: 'expert',
      专家头像: 'avatar',
      专家账号: 'account',
      专家昵称: 'nickname',
      擅长联赛: 'strong',
      总胜率: 'win rate',
      总返奖率: 'return rate',
      近10_近7_近3: 'near10 near7 near3',
      最高连胜: 'highest win',
      推荐次数: 'recommend',
      编辑专家: 'editorial expert',
      专家简介: 'expert profile',
      专家等级: 'expert level',
      粉丝数: 'number of fans',
      历史推荐查询: 'historical recommended queries',
      确认启用此专家: 'confirm activation of this expert?',
      确认禁用此专家: 'confirm to disable this expert?',
      确定删除此方案: 'confirm to delete this article?'
    },
    LevelTab: {
      专家等级: 'expert level',
      别名: 'alias',
      发布次数: 'number of releases',
      胜率: 'win rate',
      返还率: 'return rate',
      抽佣: 'rake',
      需要审核: 'audits are required',
      无需审核: 'no audit required',
      等级名称: 'level name',
      等级色值: 'level color value',
      删除最大等级: 'delete the maximum level',
      删除等级后不可恢复: 'unrecoverable after deletion',
      请输入1_100区间最多三位小数: 'please enter 1-100 range up to three decimal places',
      格式不规范: 'irregular format'
    }
  },
  Expert_Plan: {
    原文标题: 'Original title',
    终端标题: 'Terminal title',
    标题: 'Title',
    内容: 'Content',
    原文内容: 'Original content',
    终端内容: 'Terminal content',
    方案状态: 'Post Status',
    拾取状态: 'Pickup state',
    译者名: 'Translator name',
    选项: 'Options',
    确认拾取: 'Confirm Pickup',
    专家账号: 'account',
    专家昵称: 'nickname',
    排序方式: 'sort by',
    比赛类型: 'competition methods',
    过关方式: 'pass method',
    是否收费: 'whether to charge',
    用户头像: 'profile',
    免费: 'free',
    收费: 'toll',
    不中退: 'no setback',
    销量: 'sales volume',
    阅读量: 'reading volume',
    方案详情: 'article details',
    方案单号: 'number',
    销售量: 'sales volume',
    赛事选择: 'match selection',
    推荐理由: 'recommended reasons',
    返还率: 'return rate',
    足球: 'football',
    篮球: 'basketball',
    单场: 'single field',
    串联: 'series',
    操作信息: 'time',
    确认通过此方案: 'confirm pass this article?',
    确认禁止此方案: 'confirm the prohibit article?',
    未审: 'unapproved',
    红: 'red',
    黑: 'black',
    结果: 'result'
  },
  Interactive_Match: {
    赛事列表: 'match list',
    联赛设置: 'league settings',
    '体育-热门': '体育-热门',
    '体育-首页': '体育-首页',
    ForecastTab: {
      赛事名称: 'match name',
      球队名称: 'team name',
      联赛: 'league',
      简称: 'contraction',
      开赛时间: 'start time',
      热点排序: 'sorting',
      设置排序: 'set sort',
      主队: 'home team',
      比分: 'score',
      客队: 'guest team',
      半场: 'half time',
      热门位置: 'hot competition location',
      稳场位置: 'top hot location',
      无效: 'invalid',
      情报分析: 'intelligence analysis',
      预测编辑: 'forecast edit',
      滚球编辑: 'in-play edit',
      预测内容编辑: 'forecast content edit',
      重要提示: 'top tips',
      防冷: 'close bet',
      主状态: 'main state',
      客状态: 'customer status',
      攻防: 'attack and defense',
      伤停: 'injury',
      规律: 'regularity',
      上半场: 'first half',
      亚盘规律: 'asian disk regularity',
      赛果规律: 'results Regular',
      盘口情况: 'handicap situation',
      基本面: 'basic page',
      分析场次: 'match analysis',
      欧盘让球规律: 'european handicap regular',
      本次基本面分析: 'this basic page analysis',
      热门赛事位置: 'popular match location',
      赛前: 'Before the match',
      赛中: 'in match time',
      结束: 'finish',
      确认取消此赛事: 'confirm cancellation of this match?'
    },
    LeagueTab: {
      是否推荐: 'recommended or not',
      热门赛事: 'popular match ',
      展示赛事: 'show match',
      赛事名称: 'match name',
      联赛: 'league',
      简称: 'contraction',
      支持推荐: 'support recommended',
      推荐玩法: 'recommended gameplay',
      赛事展示: 'match show',
      联赛等级: 'league level',
      编辑赛事: 'edit match',
      热门推荐: 'popular recommended',
      联赛类型: 'league type'
    }
  },
  articleStatusList: [
    { value: 1, label: 'unapproved', type: 'success' },
    { value: 2, label: 'put on shelves', type: 'info' },
    { value: 3, label: 'pull off', type: 'danger' },
    { value: 4, label: 'cancel', type: 'disable' }
  ],
  sportMatchStatus: {
    '0': { label: 'abnormal', type: 'danger' },
    '1': { label: 'not started', type: 'disable' },
    '2': { label: 'first half', type: 'info' },
    '3': { label: 'midfield', type: 'info' },
    '4': { label: 'second half', type: 'info' },
    '5': { label: 'extra time', type: 'info' },
    '7': { label: 'penalty shootout', type: 'info' },
    '8': { label: 'end', type: 'success' },
    '9': { label: 'delay', type: 'danger' },
    '10': { label: 'disrupted', type: 'danger' },
    '11': { label: 'cut-out', type: 'danger' },
    '12': { label: 'cancel', type: 'danger' },
    '13': { label: 'pending', type: 'danger' }
  },
  basketMatchStatus: {
    // 1-未开赛，2-第一节，3-第一节完，
    // 4-第二节，5-第二节完，6-第三节，
    // 7-第三节完，8-第四节，9-加时，10-完场，11-中断，
    // 12-取消，13-延期，14-腰斩，15-待定
    '0': { label: 'abnormal', type: 'danger' },
    '1': { label: 'not started', type: 'disable' },
    '2': { label: 'first quarter', type: 'info' },
    '3': { label: 'first quarter end', type: 'info' },
    '4': { label: 'second quarter', type: 'info' },
    '5': { label: 'second quarter end', type: 'info' },
    '6': { label: 'third quarter', type: 'info' },
    '7': { label: 'third quarter end', type: 'info' },
    '8': { label: 'fourth quarter', type: 'info' },
    '9': { label: 'overtime', type: 'info' },
    '10': { label: 'end', type: 'success' },
    '11': { label: 'disrupted', type: 'danger' },
    '12': { label: 'cancel', type: 'danger' },
    '13': { label: 'delay', type: 'danger' },
    '14': { label: 'cut-out', type: 'danger' },
    '15': { label: 'pending', type: 'danger' }
  },
  positionList: [
    { label: 'good information for the home team', value: 'homeFavorableInfo' },
    { label: 'good information for the visiting team', value: 'awayFavorableInfo' },
    { label: 'unfavorable information for the home team', value: 'homeAdverseInfo' },
    { label: 'unfavorable information for the visiting team', value: 'awayAdverseInfo' },
    { label: 'neutral intelligence', value: 'neutralInfo' }
  ],
  recommendPlayMaps: {
    '1': 'win、draw、lose',
    '2': 'concede points:win、draw、lose',
    '3': 'score',
    '4': 'total goals',
    '5': 'halftime,fulltime'
  },
  footballRecommendPlayMaps: {
    '1': 'Win/Tie/Loss',
    '2': 'Handicap Win/Loss',
    '11': 'Asian Handicap',
    '12': 'Handicap',
    '14': 'Over/Under'
  },
  basketRecommendPlayMaps: {
    '6': 'Win/Loss',
    '7': 'Handicap Win/Loss',
    '8': 'Over/Under',
    '11': 'Asian Handicap',
    '13': 'Handicap',
    '14': 'Over/Under'
  },
  sourceType: [
    { label: 'OB', value: 1 }
  ],
  roomTypes: [
    { label: 'Lottery', value: 1, type: 'lottery' },
    { label: 'Match', value: 2, type: 'sport' },
    { label: 'Customer', value: 3, type: 'service' },
    { label: 'Live', value: 4, type: 'live' },
    { label: 'WOS', value: 5, type: 'service' }
  ],
  activityCategory: [
    {
      type: '0',
      title: 'Lottery'
    },
    {
      type: '1',
      title: 'Sport'
    },
    {
      type: '2',
      title: 'Live'
    },
    {
      type: '3',
      title: 'ChessCard'
    },
    {
      type: '4',
      title: 'Fish'
    },
    {
      type: '5',
      title: 'ESport'
    },
    {
      type: '6',
      title: 'Slot'
    },
    {
      type: '7',
      title: 'Blockchain'
    }
  ],
  marketType: [
    { name: 'EU', value: 'EU' },
    { name: 'HK', value: 'HK' }
  ],
  taskStatus: [
    { key: 3, label: 'To begin' },
    { key: 1, label: 'In progress' },
    { key: 0, label: 'Ended' }
  ],
  taskType: [
    { key: 1, label: 'Daily Tasks' },
    { key: 0, label: 'Weekly Tasks' },
    { key: 2, label: 'Newbie Tasks' },
    { key: 3, label: 'Long Tasks' }
  ],
  taskMethod: [
    { key: 0, label: 'Recharge task' },
    { key: 1, label: 'Betting task' },
    { key: 2, label: 'Invite Tasks' },
    { key: 3, label: 'Live tasks' },
    { key: 4, label: 'Tracking task' },
    { key: 5, label: 'Post Task' }
  ],
  rewardType: [
    { key: 0, label: 'Ordinary rewards' },
    { key: 1, label: 'Accumulated rewards' }
  ],
  betsType: [
    { key: 1, label: 'Lottery' },
    { key: 2, label: 'Lottery Football' },
    { key: 3, label: 'Native sports' },
    { key: 4, label: 'Sports' },
    { key: 5, label: 'Person' },
    { key: 6, label: 'Catch fish' },
    { key: 7, label: 'Slots' },
    { key: 8, label: 'E-sports' },
    { key: 9, label: 'Blockchain' }
  ]
}
