<template>
  <comMenu title="六合版-首页金刚区" menu-name="金刚区" :menu-list="menuList" :num="8" home-type="liuhe" :position="4" />
</template>
<script>
import comMenu from '../components/comMenu.vue'

export default {
  name: 'LiuheTab',
  componentName: 'LiuheTab',
  components: { comMenu },
  data() {
    return {
      menuList: [
        { key: 'king.kaijiangxianchang', label: '开奖现场' },
        { key: 'king.ziliaotongji', label: '资讯统计' },
        { key: 'king.chaxunzhushou', label: '查询助手' },
        { key: 'king.ziliaojiaoliu', label: '资料交流' },
        { key: 'king.saidanquan', label: '晒单圈' },
        { key: 'king.jihuadashen', label: '计划大神' },
        { key: 'king.gaoshuoluntan', label: '高手论坛' },
        { key: 'king.gongjubaoxiang', label: '工具宝箱' },
        { key: 'king.gongshishahao', label: '公式杀号' },
        { key: 'king.tiaomazhushou', label: '挑码助手' },
        { key: 'king.shuxingcankao', label: '属性参考' },
        { key: 'king.lisikaijiang', label: '历史开奖' },
        { key: 'king.fenxiangzhuanqian', label: '分享赚钱' },
        { key: 'king.tuijiandanbao', label: '推荐担保' }
      ]
    }
  }
}
</script>
