<template>
  <el-dialog :title="title" :visible.sync="visible" width="900px" top="5vh" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-position="right" label-width="160px" label-suffix=":">
      <el-form-item :label="$t('会员账号')" prop="memberName">
        <el-input v-model="ruleForm.memberName" type="textarea" :rows="6" />
        <div style="line-height: 1.5">
          {{ $t('每行的数据格式：会员账号,金额 使用逗号或者空格隔开') }}
          <br>
          {{ $t('如') }}：aaaaaa 300
          <span style="font-size: 10px; color: red;">{{ $t('使用空格隔开') }}</span>
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bbbbbb,200
          <span style="font-size: 10px; color: red;">{{ $t('使用逗号隔开') }}</span>
        </div>
      </el-form-item>
      <el-form-item :label="$t('操作原因')">
        <el-input v-model="ruleForm.operatorRemark" type="textarea" :rows="3" :placeholder="$t('请输入操作原因')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  memberName: '',
  operatorRemark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        memberName: [
          { required: true, message: this.$t('请输入批量处理信息'), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    title() {
      return this.ruleForm.transType === 6 ? this.$t('批量增加打码量') : this.$t('批量减少打码量')
    }
  },
  methods: {
    open(transType) {
      this.ruleForm.transType = transType
      this.visible = true
    }
  }
}
</script>
