var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titleObj.title,
            width: "800px",
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm._close,
          },
        },
        [
          _c("d-table", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "DTable",
            attrs: {
              data: _vm.items,
              height: 500,
              "filter-column": false,
              columns: _vm.columns,
              "original-columns": _vm.defaultColumns,
              options: _vm.options,
            },
            on: {
              refresh: _vm.handleFetch,
              "column-change": _vm.handleColumnChange,
            },
          }),
          _c("pagination", {
            attrs: {
              page: _vm.params.currentPage,
              limit: _vm.params.pageSize,
              total: _vm.total,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.params, "currentPage", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.params, "pageSize", $event)
              },
              pagination: _vm.handleFetch,
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("reply", { ref: "replyEle", on: { submit: _vm.submitReply } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }