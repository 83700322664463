const CanChooseColumns = function() {
  return [
    {
      title: this.$t('Interactive_Match.LeagueTab.联赛'),
      key: 'competitionName',
      align: 'center'
    },
    {
      title: this.$t('Interactive_Match.LeagueTab.支持推荐'),
      key: 'recommend',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.recommend === 1 ? this.$t('是') : this.$t('否') }</span>)
        }
      }
    },
    {
      title: this.$t('Interactive_Match.LeagueTab.推荐玩法'),
      key: 'recommendPlay',
      align: 'center',
      minWidth: 200
    },
    {
      title: this.$t('Interactive_Match.LeagueTab.热门赛事'),
      key: 'hot',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.hot === 1 ? this.$t('是') : this.$t('否') }</span>)
        }
      }
    },
    {
      title: this.$t('Interactive_Match.LeagueTab.赛事展示'),
      key: 'showStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.showStatus === 1 ? this.$t('是') : this.$t('否') }</span>)
        }
      }
    },
    {
      title: this.$t('Interactive_Match.LeagueTab.联赛等级'),
      key: 'level',
      align: 'center'
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 150,
      component: {
        render: (h, row) => {
          const { Update } = this.permission
          return (Update && <el-button type='success' size='mini' icon='el-icon-edit-outline' onClick={() => this.$refs.ActionDialogControl.open({ ...row, sportType: this.search.sportType })}>{ this.$t('编辑') }</el-button>)
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
