<template>
  <el-form
    ref="validateForm"
    v-loading="loading"
    :model="ruleForm"
    :rules="rules"
    label-width="210px"
  >
    <el-row :gutter="24">
      <el-col :span="12">
        <h2>登录注册</h2>
        <el-form-item label="同IP每日注册个数" prop="ipRegistNum">
          <el-input v-model.number="ruleForm.ipRegistNum" />
        </el-form-item>
        <el-form-item label="同IP每日登录个数" prop="ipLoginNum">
          <el-input v-model.number="ruleForm.ipLoginNum" />
        </el-form-item>
        <el-form-item label="前端输入密码错误冻结次数" prop="passwordWrongNum">
          <el-input v-model.number="ruleForm.passwordWrongNum" />
        </el-form-item>
        <el-form-item label="后台Google验证" prop="googleStatus">
          <d-switch v-model="ruleForm.googleStatus">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </d-switch>
        </el-form-item>

        <h2>购彩配置</h2>
        <el-form-item label="是否允许用户撤单" prop="cancelOrderStatus">
          <el-radio-group v-model="ruleForm.cancelOrderStatus">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="投注默认一注额度" prop="betAmount">
          <el-input v-model.number="ruleForm.betAmount"><template slot="append">￥</template></el-input>
        </el-form-item>

        <h2>代理返点</h2>
        <el-form-item label="一级返佣比例" prop="oneLevelRakeBack">
          <el-input v-model="ruleForm.oneLevelRakeBack"><template slot="append">%</template></el-input>
        </el-form-item>
        <el-form-item label="二级返佣比例" prop="twoLevelRakeBack">
          <el-input v-model="ruleForm.twoLevelRakeBack"><template slot="append">%</template></el-input>
        </el-form-item>
        <el-form-item label="结算方式" prop="settlementType">
          <el-radio-group v-model="ruleForm.settlementType">
            <el-radio :label="1">人工</el-radio>
            <el-radio :label="2">自动</el-radio>
          </el-radio-group>
        </el-form-item>

        <h2>国家区号</h2>
        <el-form-item label="区号" prop="regins">
          <el-input
            v-model="ruleForm.regins"
            type="textarea"
            :rows="4"
            placeholder="区号前需加上+号，支持填入多个国家区号，用英文逗号分开，例：+86,+84"
          />
        </el-form-item>

        <h2>维护</h2>
        <el-form-item label="前端维护" prop="maintainStatus">
          <d-switch v-model="ruleForm.maintainStatus" :active-value="1" :inactive-value="0">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </d-switch>
        </el-form-item>
        <el-form-item label="维护说明" prop="maintainInfo">
          <el-input
            v-model="ruleForm.maintainInfo"
            type="textarea"
            :rows="4"
            maxlength="100"
            show-word-limit
            placeholder="请输入内容"
          />
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <h2>存取款配置</h2>
        <el-form-item label="提款、入款开始处理时间" prop="startTime">
          <el-time-select
            v-model="ruleForm.startTime"
            placeholder="开始时间"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
              maxTime: ruleForm.endTime
            }"
          />
        </el-form-item>
        <el-form-item label="提款、入款结束处理时间" prop="endTime">
          <el-time-select
            v-model="ruleForm.endTime"
            placeholder="结束时间"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
              minTime: ruleForm.startTime
            }"
          />
        </el-form-item>
        <el-form-item label="提款最低额度" prop="withdrawMinAmount">
          <el-input v-model="ruleForm.withdrawMinAmount"><template slot="append">￥</template></el-input>
        </el-form-item>
        <el-form-item label="提款最高额度" prop="withdrawMaxAmount">
          <el-input v-model="ruleForm.withdrawMaxAmount"><template slot="append">￥</template></el-input>
        </el-form-item>
        <el-form-item label="2次充值时间的间隔" prop="rechargeInterval">
          <el-input v-model.number="ruleForm.rechargeInterval"><template slot="append">秒</template></el-input>
        </el-form-item>
        <el-form-item label="2次提款时间的间隔" prop="withdrawInterval">
          <el-input v-model.number="ruleForm.withdrawInterval"><template slot="append">秒</template></el-input>
        </el-form-item>
        <el-form-item label="提款次数只记录成功次数" prop="recordWithdrawStatus">
          <el-switch v-model="ruleForm.recordWithdrawStatus" :active-value="0" active-text="开启" :inactive-value="1" inactive-text="关闭" />
        </el-form-item>
        <!-- <el-form-item label="每日最高提现次数" prop="withdrawaDailyNum">
          <el-input v-model.number="ruleForm.withdrawaDailyNum"><template #append>次</template></el-input>
        </el-form-item> -->
        <el-form-item label="存款未处理记录失效时间" prop="rechargeInvalidTime">
          <el-input v-model="ruleForm.rechargeInvalidTime"><template slot="append">小时</template></el-input>
        </el-form-item>
        <el-form-item label="提款未处理记录失效时间" prop="withdrawInvalidTime">
          <el-input v-model="ruleForm.withdrawInvalidTime"><template slot="append">小时</template></el-input>
        </el-form-item>
        <el-form-item label="新增修改银行卡不能提款时间" prop="bankWithdrawForbid">
          <el-input v-model.number="ruleForm.bankWithdrawForbid"><template slot="append">分钟</template></el-input>
        </el-form-item>
        <el-form-item label="绑定银行卡数量" prop="bankBindNum">
          <el-input v-model.number="ruleForm.bankBindNum" />
        </el-form-item>

        <h2>直播</h2>
        <el-form-item label="主播联系方式金额" prop="anchorContactAmount">
          <el-input v-model="ruleForm.anchorContactAmount" />
        </el-form-item>

        <el-form-item label="广播礼物金额" prop="broadcastGiftAmount">
          <el-input v-model="ruleForm.broadcastGiftAmount" />
        </el-form-item>
        <el-form-item label="免费时长设置" prop="freeTime">
          <el-input v-model.number="ruleForm.freeTime" placeholder="5-300"><template slot="append">秒</template></el-input>
        </el-form-item>

      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { getBaseConfig } from '@/api/tenant'
import { getRuleForm, getRules } from '@/views/common/config'

export default {
  name: 'Function',
  data() {
    return {
      loading: false,
      ruleForm: getRuleForm(),
      rules: getRules.call(this)
    }
  },
  methods: {
    fetch(tenantCode) {
      this.loading = true
      getBaseConfig({ tenantCode }).then(([data, err]) => {
        this.loading = false
        if (!err && data) {
          this.ruleForm = {
            ...data,
            anchorContactAmount: String(+data.anchorContactAmount),
            broadcastGiftAmount: String(+data.broadcastGiftAmount)
          }
        }
      })
    },
    onValidate(cb) {
      return new Promise((resolve, reject) => {
        this.$refs.validateForm.validate((valid) => {
          if (valid) {
            cb && cb({ baseConfigRequest: this.ruleForm })
            resolve({ baseConfigRequest: this.ruleForm })
          } else {
            reject()
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  width: 200px;
  text-align: right;
}
</style>
