<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" v-loading="loading" :model="ruleForm" :rules="rules" label-width="100px" label-suffix=":">
      <el-form-item :label="$t('彩种')" prop="lotteryId">
        <el-select v-model="ruleForm.lotteryId" placeholder="请选择彩种" style="width: 100%" @change="handleChangeLottery">
          <el-option v-for="(val, key) in xsLottery" :key="key" :value="key" :label="val" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('数据来源')" prop="source">
        <el-select v-model="ruleForm.source" :placeholder="$t('请选择数据来源')" style="width: 100%" @change="handlePictureList">
          <el-option v-for="item of sources.filter(e => e.lotteryId === Number(ruleForm.lotteryId))" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('排序')" prop="sort">
        <el-input v-model="ruleForm.sort" :placeholder="$t('请输入排序')" />
      </el-form-item>
      <el-form-item :label="$t('类别名称')" prop="categoryName">
        <el-input v-model="ruleForm.categoryName" maxlength="20" :placeholder="$t('请输入类别名称，最长20个字符')" />
      </el-form-item>
      <el-form-item :label="$t('关联图库')" prop="pictureRequestList">
        <el-select 
          v-model="pictureRequestList" 
          multiple
          collapse-tags 
          filterable 
          placeholder="请选择关联图库"
          style="width: 100%;"
          :loading="loading"
        >
          <el-option v-for="item in picList" :key="item.pictureTag" :label="item.name" :value="item.pictureTag" />
        </el-select>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button v-if="emitName !== 'details'" type="primary" :loading="submitting" @click="handleSubmitBefor">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { xsLottery, sources } from '@/libs/options'
import { pictureList } from '@/api/interactive'

const getDefaultForm = () => ({
  pictureCategoryId: undefined,
  categoryName: '',
  sort: '',
  lotteryId: '',
  source: '',
  pictureRequestList: []
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      loading: false,
      title: '',
      xsLottery,
      sources,
      picList: [],
      pictureRequestList: [],
      pictureNameResponseList: [],
      rules: {
        lotteryId: [{ required: true, message: this.$t('请选择彩种'), trigger: 'change' }],
        source: [{ required: true, message: this.$t('请选择数据来源'), trigger: 'change' }],
        sort: [{ required: true, message: this.$t('请输入排序'), trigger: 'blur' }],
        categoryName: [{ required: true, message: this.$t('请输入分类名称'), trigger: 'blur' }]
      }
    }
  },
  methods: {
    open(row, source) {
      this.emitName = 'create'
      this.title = '新建图库类别'
      this.ruleForm.source = source
      this.pictureRequestList = []
      this.picList = []
      this.pictureNameResponseList = []
      if (row) {
        this.emitName = 'update'
        this.title = '修改图库类别'
        this.ruleForm.pictureCategoryId = row.id
        this.ruleForm.categoryName = row.categoryName
        this.ruleForm.sort = row.sort
        this.ruleForm.lotteryId = row.lotteryId
        this.ruleForm.source = row.source
        this.handlePictureList()
        if (row.pictureNameResponseList && row.pictureNameResponseList.length > 0) {
          this.pictureNameResponseList = row.pictureNameResponseList
          this.pictureRequestList = row.pictureNameResponseList.map(e => e.pictureTag)
        }
      }
      this.visible = true
    },
    handlePictureList() {
      this.loading = true
      this.pictureRequestList = []
      let pictureTag = this.ruleForm.source
      if (this.ruleForm.lotteryId === '1255') {
        pictureTag = 'KLLH01'
      }
      pictureList({ pictureTag }).then(([items, err]) => {
        if (!err && items && items.length) {
          this.picList = items
          this.pictureNameResponseList.forEach(e => {
            if(!this.picList.some(v => v.pictureTag === e.pictureTag)) {
              this.picList.push({id: '', name: e.pictureName, pictureTag: e.pictureTag})
            }
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleChangeLottery() {
      this.ruleForm.source = null
      this.pictureRequestList = []
      this.picList = []
    },
    handleSubmitBefor() {
      this.ruleForm.pictureRequestList = []
      this.pictureRequestList.forEach(e => {
        const pic = this.picList.find(v => v.pictureTag === e)
        if (pic) {
          this.ruleForm.pictureRequestList.push({ pictureName: pic.name, pictureTag: pic.pictureTag })
        }
      })
      this.handleSubmit()
    }
  }
}
</script>
