<template>
  <el-dialog title="评论" :visible.sync="visible" width="700px" :close-on-click-modal="false" @open="handleRefresh" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules">
      <el-table v-loading="loading" :data="items" border size="small" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="70" align="center" />
        <el-table-column prop="title" label="标题" align="center" />
      </el-table>
      <pagination layout="prev, pager, next" :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" style="padding: 0; margin: 20px -5px" @pagination="handleFetch" />

      <el-form-item required prop="commentNickname" style="margin-top: 20px">
        <el-input v-model="ruleForm.commentNickname" style="width: 200px" placeholder="输入评论昵称" />
      </el-form-item>
      <el-form-item prop="content">
        <el-input v-model="ruleForm.content" placeholder="输入评论内容" type="textarea" :rows="4" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        发送
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { validNickName } from '@/utils/validate'
import { getNoLockPost } from '@/api/interactive'
import FormDialogMixin from '@/mixins/form-dialog'
import TablePageMixin from '@/mixins/table-page-mixin'

const getDefaultForm = () => ({
  nickname: '',
  content: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm), TablePageMixin(getNoLockPost, false)],
  data() {
    const validNickname = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入昵称'))
      } else if (!validNickName(value)) {
        callback(new Error('请输入2~6位中文'))
      } else {
        callback()
      }
    }

    return {
      emitName: 'update',
      parameter: {},
      params: { currentPage: 1, pageSize: 5 },
      selections: [],
      rules: {
        commentNickname: [
          { validator: validNickname, trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入评论内容', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    open(params) {
      this.parameter = params
      this.visible = true
    },
    implementationClosed() {
      this.parameter = {}
      this.params = { currentPage: 1, pageSize: 5 }
      this.selections = []
      this.items = []
    },
    handleSelectionChange(val) {
      this.selections = val
    },
    implementationGetParams() {
      return { ...this.parameter, ...this.params }
    },
    handleSubmit() {
      this.$refs.validateForm.validate(valid => {
        if (valid) {
          if (!this.selections.length) {
            this.$message.error('请选择帖子')
            return false
          }
          this.ruleForm.selections = this.selections
          this._handleDoEmit()
        } else {
          return false
        }
      })
    }
  }
}
</script>
