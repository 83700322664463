var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "ruleForm", attrs: { model: _vm.ruleForm, "label-width": "110px" } },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "basic_config_content",
          attrs: { gutter: 24 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("div", { staticClass: "titleLine" }, [
                _c("div", { staticClass: "title" }, [_vm._v("推单说明")]),
              ]),
              _c(
                "el-form-item",
                [
                  _c("Tinymce", {
                    ref: "tinymce",
                    attrs: { height: 350 },
                    model: {
                      value: _vm.ruleForm.explainContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "explainContent", $$v)
                      },
                      expression: "ruleForm.explainContent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "action-button" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("保 存")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }