var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.emitName == "create" ? "添加问题" : "编辑问题",
        visible: _vm.visible,
        width: "800px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题", required: "", prop: "title" } },
            [
              _c("el-input", {
                attrs: { maxlength: "50" },
                model: {
                  value: _vm.ruleForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", required: "", prop: "sort" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sort", $$v)
                  },
                  expression: "ruleForm.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回复内容", prop: "replyContent" } },
            [
              _c("Tinymce", {
                ref: "tinymce",
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.replyContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "replyContent", $$v)
                  },
                  expression: "ruleForm.replyContent",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 取消 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }