<template>
  <div>
    <div class="filter-container">
      <el-input v-model="search.memberName" class="filter-item" :placeholder="$t('Member_Expert.ExpertTab.专家账号')" style="width: 160px" />
      <el-input v-model="search.nickName" class="filter-item" :placeholder="$t('Member_Expert.ExpertTab.专家昵称')" style="width: 160px" />
      <el-select v-model="search.positionId" class="filter-item" :placeholder="$t('Member_Expert.ExpertTab.专家等级')" clearable style="width: 140px">
        <el-option v-for="item in positionList" :key="item.id" :label="item.positionName" :value="item.id" />
      </el-select>
      <el-select v-model="search.expertStatus" class="filter-item" :placeholder="$t('状态')" clearable style="width: 140px">
        <el-option :label="$t('正常')" :value="1" />
        <el-option :label="$t('禁用')" :value="0" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>

      <!-- <el-button type="success" icon="el-icon-plus" class="filter-item" style="float: right" @click="onCreate">
          新增内部账号
        </el-button> -->
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      :default-sort="{ order: 'descending', prop: 'artcileNum' }"
      @sort-change="handleSortChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <CreateDialog ref="CreateDialogControl" @create="_handleCreate" />
    <UpdateDialog ref="UpdateDialogControl" @update="_handleUpdate" />
    <HistoryDialog ref="HistoryDialogControl" />

  </div>
</template>
<script>
import { getExpertList, createExpert, updateExpert, updateExpertStatus, getExpertPositionList } from '@/api/expert'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { CreateDialog, UpdateDialog, HistoryDialog } from './components'
import { mapGetters } from 'vuex'

const SORT_CONFIG = {
  linkwin: {
    ascending: 0,
    descending: 1
  },
  artcileNum: {
    ascending: 2,
    descending: 3
  },
  tenofWinrate: {
    ascending: 4,
    descending: 5
  },
  tenofReturnrate: {
    ascending: 6,
    descending: 7
  }
}

export default {
  name: 'ExpertTab',
  components: { UpdateDialog, HistoryDialog, CreateDialog },
  mixins: [TablePageMixin(getExpertList)],
  data() {
    return {
      filterColumn: false,
      columns: GetColumns.call(this),
      search: { },
      defaultSearch: { },
      positionList: []
    }
  },
  computed: {
    ...mapGetters(['operatorType', 'tenantCode', 'operatorId'])
  },
  created() {
    this._getExpertPositionList(this.tenantCode)
  },
  methods: {
    onCreate() {
      this.$refs.CreateDialogControl.open()
    },

    handleSortChange({ prop, order }) {
      this.search.isSortBy = SORT_CONFIG[prop][order]
      this.handleRefresh()
    },

    _handleCreate({ field, cancel, close }) {
      createExpert({
        ...field,
        tenantCode: this.tenantCode,
        operatorId: this.operatorId
      }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      updateExpert({ ...field, greatGameIds: field.greatGameIds.join(',') }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    _handleUpdateStatus({ expertId, expertStatus, tenantCode }) {
      return new Promise((resolve) => {
        const confirm = expertStatus === 0 ? this.$t('Member_Expert.ExpertTab.确认启用此专家') : this.$t('Member_Expert.ExpertTab.确认禁用此专家')
        this.$confirm(confirm, this.$t('系统提示'), { type: 'warning' }).then(() => {
          updateExpertStatus({
            id: expertId,
            tenantCode,
            expertStatus: ~expertStatus + 2
          }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },

    async _getExpertPositionList(tenantCode) {
      await getExpertPositionList({
        pageSize: 100,
        currentPage: 1,
        tenantCode
      }).then(([data, err]) => {
        if (!err) {
          this.positionList = data || []
        }
      })
    },

    implementationGetParams() {
      return { ...this.search, tenantCode: this.tenantCode, operatorId: this.operatorId }
    }
  }
}
</script>

