<template>
  <el-dialog
    :title="$t('新增权限组')"
    :visible.sync="visible"
    width="60%"
    :close-on-click-modal="false"
    top="5vh"
    custom-class="custom-dialog"
    @open="handleOpen"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" size="small">
      <el-form-item
        :label="$t('权限组名称')"
        prop="groupName"
        label-width="100px"
      >
        <el-input
          v-model="ruleForm.groupName"
          :placeholder="$t('2-20个字符')"
          style="width: 150px"
        />
      </el-form-item>
      <el-form-item label="谷歌验证码" label-width="100px" prop="googleCode">
        <el-input v-model.trim="ruleForm.googleCode" style="width: 150px" />
      </el-form-item>
      <el-form-item prop="menuIdList">
        <el-table
          v-loading="loading"
          border
          :data="items"
          :header-cell-style="() => ({ 'text-align': 'center' })"
        >
          <el-table-column type="expand" width="50" align="center">
            <template slot-scope="{ row }">
              <el-table
                border
                :data="row.children"
                :show-header="false"
                class="expand-table"
              >
                <el-table-column width="200" prop="text">
                  <template slot-scope="props">
                    <div class="el-table-cell" style="padding-left: 60px">
                      {{ props.row.text }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="150">
                  <template slot-scope="props">
                    <template v-if="props.row.hasChildren">
                      <div
                        v-for="item in props.row.children"
                        :key="item.id"
                        class="el-table-cell"
                      >
                        <el-checkbox
                          v-model="item.checked"
                          @change="
                            (value) =>
                              handleChildChange(
                                value,
                                [row.id, props.row.id, item.id],
                                true
                              )
                          "
                          >{{ item.text }}
                        </el-checkbox>
                      </div>
                    </template>
                    <div v-else class="el-table-cell">
                      <el-checkbox
                        v-model="props.row.checked"
                        @change="
                          (value) =>
                            handleChildChange(
                              value,
                              [row.id, props.row.id],
                              true
                            )
                        "
                        >{{ $t("列表显示") }}</el-checkbox
                      >
                    </div>
                  </template>
                </el-table-column>
                <el-table-column>
                  <template slot-scope="props">
                    <template v-if="props.row.hasChildren">
                      <div
                        v-for="item in props.row.children"
                        :key="item.id"
                        class="el-table-cell"
                      >
                        <el-checkbox
                          v-for="child in item.children"
                          :key="child.id"
                          v-model="child.checked"
                          @change="
                            (value) =>
                              handleChildChange(
                                value,
                                [
                                  row.id,
                                  props.row.id,
                                  child.parentId,
                                  child.id,
                                ],
                                false
                              )
                          "
                          >{{ child.text }}</el-checkbox
                        >
                      </div>
                    </template>
                    <div v-else class="el-table-cell">
                      <el-checkbox
                        v-for="item in props.row.children"
                        :key="item.id"
                        v-model="item.checked"
                        @change="
                          (value) =>
                            handleChildChange(
                              value,
                              [row.id, props.row.id, item.id],
                              false
                            )
                        "
                        >{{ item.text }}</el-checkbox
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('菜单')"
            prop="text"
            align="center"
            width="150"
          />
          <el-table-column :label="$t('权限')" width="150">
            <template slot-scope="{ row }">
              <el-checkbox
                :value="_checkedChildren(row.id)"
                @change="(value) => _onCheckedAllChildren(value, row.id)"
                >{{ $t("全部") }}</el-checkbox
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('敏感权限')">
            <template slot-scope="{ row }">
              <el-checkbox
                v-if="_checkedGrandChilren(row.id).visible"
                :value="_checkedGrandChilren(row.id).checked"
                @change="(value) => _onCheckedAllGrandChildren(value, row.id)"
                >{{ $t("全部") }}</el-checkbox
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t("关闭") }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t("保存") }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import VDialogMixin from "@/mixins/v-dialog-mixin";
import { changeChildrenChecked, getChildren, getCheckedIds } from "./functions";
import { getMenuList } from "@/api/permission";

const getDefaultForm = () => ({
  groupName: "",
  googleCode: "",
});

export default {
  mixins: [VDialogMixin],
  data() {
    return {
      loading: false,
      submitting: false,
      items: [],
      ruleForm: getDefaultForm(),
      rules: {
        groupName: [
          {
            required: true,
            message: this.$t("请输入权限组名"),
            trigger: "blur",
          },
          {
            pattern: /^.{2,20}$/,
            message: this.$t("请输入2-20个字符"),
            trigger: "blur",
          },
        ],
        googleCode: [
          { required: true, message: "请输入谷歌验证码", trigger: "blur" },
          {
            pattern: /^[0-9]{6}$/,
            message: "请输入6位谷歌验证码",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.submitting = false;
      this.visible = false;
    },
    handleChildChange(value, ids, parent) {
      changeChildrenChecked(this.items, ids, value, parent);
    },

    handleSubmit() {
      this.$refs.validateForm.validate((valid) => {
        if (valid) {
          this.submitting = true;
          const field = {
            ...this.ruleForm,
            menuIdList: getCheckedIds(this.items),
          };
          this.$emit("create", {
            field,
            cancel: () => {
              this.submitting = false;
            },
            close: this.close,
          });
        }
      });
    },

    handleClosed() {
      this.ruleForm = getDefaultForm();
      this.$refs.validateForm.resetFields();
    },

    handleOpen() {
      this.fetchTenantPermission();
    },

    fetchTenantPermission() {
      this.loading = true;
      getMenuList({}).then(([data, err]) => {
        this.loading = false;
        if (!err) {
          this.items = data;
        }
      });
    },

    _checkedChildren(id) {
      const item = this.items.find((o) => o.id === id);
      if (!item.hasChildren) return item.checked;
      return item.children.every((o) => {
        if (o.hasChildren) {
          return o.children.every((oo) => oo.checked);
        } else {
          return o.checked;
        }
      });
    },

    _checkedGrandChilren(id) {
      const item = this.items.find((o) => o.id === id);
      const menus = getChildren(item.children);
      if (!menus.length) return false;
      return {
        visible: menus.length > 0,
        checked: menus.every((o) => o.checked),
      };
    },

    _onCheckedAllChildren(value, id) {
      this.items.forEach((item) => {
        if (item.id === id) {
          item.checked = value;
          item.children.forEach((o) => {
            if (o.hasChildren) {
              o.checked = value;
              o.children = o.children.map((oo) => {
                return { ...oo, checked: value };
              });
            } else {
              o.checked = value;
            }
          });
        }
      });
    },

    _onCheckedAllGrandChildren(value, id) {
      this.items.forEach((item) => {
        if (item.id === id) {
          item.children.forEach((o) => {
            if (o.hasChildren) {
              o.children.forEach((oo) => {
                oo.children = oo.children.map((ooo) => {
                  return { ...ooo, checked: value };
                });
              });
            } else {
              o.children.forEach((oo) => {
                oo.checked = value;
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.el-table__expanded-cell {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}
.el-table.expand-table {
  margin: -1px;
  & td {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 0;
    & .cell {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    & .el-table-cell {
      padding: 12px 10px;
      border-top: 1px solid #ebeef5;
      width: 100%;
      height: inherit;
      display: table;
      min-height: 48px;
      overflow: hidden;
      .el-checkbox {
        line-height: 2;
      }
    }
    & .el-table-cell:first-child {
      border-top: none;
    }
  }
}
</style>
