<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <el-input v-model="search.ipAddress" clearable placeholder="IP" class="filter-item" style="width: 200px" />
        <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
        <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">
          {{ $t('添加白名单') }}
        </el-button>
      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      index-row
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <EditDialog ref="EditDialogControl" @create="handleCreate" @update="handleUpdate" />

  </div>
</template>
<script>
import { getWhiteList, addWhiteIp, updateWhiteIp, deleteWhiteIp } from '@/api/permission'
import EditDialog from './EditDialog'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'

export default {
  name: 'PermissionWhiteList',
  components: { EditDialog },
  mixins: [TablePageMixin(getWhiteList)],
  data() {
    return {
      filterColumn: false,
      search: {},
      columns: GetColumns.call(this)
    }
  },
  methods: {
    onCreate() {
      this.$refs.EditDialogControl.open()
    },
    handleCreate({ field, cancel, close }) {
      addWhiteIp({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('添加成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    handleUpdate({ field, cancel, close }) {
      updateWhiteIp({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('修改成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    handleDelete({ id }) {
      this.$confirm(this.$t('确定删除此IP'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        deleteWhiteIp(id).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleRefresh()
          }
        }).catch(() => {})
      })
    },
    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
