<template>
  <el-dialog
    :title="`${ params.issue }${$t('期')} ${$t('投注详情')}`"
    top="5vh"
    width="65%"
    :visible.sync="visible"
    @open="onOpen"
    @closed="onClosed"
  >
    <div v-loading="loading">
      <cascader-select
        v-model="value"
        :options="playClass"
        :label="false"
        size="mini"
        @change="onChangeSelect"
      />

      <div class="betview-warp">
        <div v-for="item in resource" :key="item.attrsTag" class="betview-warp-item">
          <div class="name">{{ item.attrsName }}</div>
          <div class="amount">{{ item.betAmount }}</div>
        </div>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('关闭') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import CascaderSelect from '@/components/CascaderSelect'
import { getPlayclass, getBetsDetail } from '@/api/lottery'
export default {
  components: { CascaderSelect },
  data() {
    return {
      visible: false,
      loading: false,
      params: {},
      value: [],
      playClass: [],
      resource: []
    }
  },
  methods: {
    open({ lotteryId, issue }) {
      this.params = { lotteryId, issue }
      this.visible = true
    },
    onOpen() {
      this.loading = true
      getPlayclass({
        lotteryId: this.params.lotteryId
      }).then(([items, err]) => {
        if (!err) {
          this.playClass = items.map(o => ({
            value: o.playTag,
            label: o.playName,
            children: o.playClassList.map(t => ({
              value: t.playClassTag,
              label: t.playClassName
            }))
          }))
          this.value = [this.playClass[0].value, this.playClass[0].children[0].value]
          this.onFetch()
        }
      })
    },
    onFetch() {
      getBetsDetail({
        ...this.params,
        playClassTag: this.value[1]
      }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.resource = data || []
        }
      })
    },
    onChangeSelect() {
      //
      this.onFetch()
    },
    close() {
      this.visible = false
    },
    onClosed() {
      this.params = {}
      this.resource = []
    }
  }
}
</script>

<style lang="scss" scoped>
.betview-warp {
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #dfe6ec;
  border-bottom: 1px solid #dfe6ec;
  &-item {
    text-align: center;
    line-height: 30px;
    flex: 0 20%;
    display: flex;
    align-items: center;
    &:nth-child(10n+1),
    &:nth-child(10n+2),
    &:nth-child(10n+3),
    &:nth-child(10n+4),
    &:nth-child(10n+5) {
      background-color: #f5f7fa;
    }
    border-top: 1px solid #dfe6ec;
    border-right: 1px solid #dfe6ec;
    .name {
      flex: 0 40%;
      border-right: 1px solid #dfe6ec;
    }
    .amount {
      flex: 0 60%;
    }
  }
}
</style>
