<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model.trim="search.name" maxlength="16" clearable :placeholder="$t('请输入银行名称')" class="filter-item" style="width: 200px" />
      <el-select v-model="search.rateType" :placeholder="$t('币种')" class="filter-item">
        <el-option v-for="item in rateTypes" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleRefresh">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" class="filter-item" icon="el-icon-plus" type="success" @click="openBankDialog()">{{ $t('添加银行') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <edit-dialog ref="bankDialogEle" @save="_save" />
  </div>
</template>
<script>
import { rateTypes } from '@/libs/options'
import EditDialog from './EditDialog.vue'
import { getSupportBankList, saveSupportBank, updateSupportBank, deleteSupportBank, updateBankStatus } from '@/api/finance'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
export default {
  name: 'FinanceBank',
  components: {
    EditDialog
  },
  mixins: [TablePageMixin(getSupportBankList)],
  data() {
    return {
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
      rateTypes: rateTypes
    }
  },
  created() {},
  methods: {
    _save({ field, cancel, close }) {
      let submits = field.id > 0 ? updateSupportBank : saveSupportBank
      submits(field).then(([data, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          const msg = field.id > 0 ? this.$t('修改') : this.$t('新增')
          this.$message.success(`${field.name} ${msg}${this.$t('成功')}！`)
        } else {
          cancel()
        }
      })
    },
    // 删除银行
    _delete(id, name) {
      this.$confirm(this.$t('确认删除name吗？', { text: name }), {
        type: 'warning'
      }).then(() => {
        deleteSupportBank({ id }).then(([data, err]) => {
          if (!err) {
            this.handleRefresh()
            this.$message.success(this.$t('name 删除成功！', { text: name }))
          }
        })
      })
    },
    _editStatus(v) { // 修改启用状态
      return new Promise((resolve) => {
        const { id, name } = v
        const binding = v.binding === 0 ? 1 : 0
        this.$confirm(this.$t('确认修改name启用状态吗？', { text: name }), { type: 'warning' }).then(() => {
          updateBankStatus({ id, binding }).then(([data, err]) => {
            if (!err) {
              resolve()
              v.binding = binding
              this.$message.success(`${name} ${this.$t('启用状态修改成功！')}`)
            }
          })
        }).catch(() => {})
      })
    },
    openBankDialog(obj) {
      if (obj && obj.binding === 0) {
        this.$message.error(this.$t('name 为停用状态不可修改！', { text: obj.name }))
        return
      }
      this.$refs.bankDialogEle.titleObj = {
        title: obj === undefined ? this.$t('添加银行') : this.$t('修改name信息', { text: obj.name }),
        type: obj === undefined ? 'add' : 'edit'
      }
      this.$refs.bankDialogEle.open(obj)
    },

    implementationGetParams() {
      return { ...this.search }
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>

<style lang="scss">
  .card_code_warp {
    margin: -5px;

    .card_code {
      width: 20%;
      height: 30px;
      line-height: 30px;
      font-weight: normal;
      display: inline-block;
      border-right: 1px solid #dfe6ec;
      border-top: 1px solid #dfe6ec;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        border-top: 0;
      }

      &:nth-child(5n+5) {
        border-right: 0;
      }
    }
  }
</style>
