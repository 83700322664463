<template>
  <div class="top-tab">
    <el-table
      ref="elTable"
      border
      :data="items"
      row-key="type"
      :drag-row="true"
      v-loading="loading"
    >
      <el-table-column type="index" align="center" width="60">
        <template #header>{{ $t("排序") }}</template>
        <svg-icon icon-class="drag" class-name="drag-handler" />
      </el-table-column>
      <el-table-column label="玩法" align="center">
        <template slot-scope="{ row }">
          {{ types.find(e => e.value === row.type).label }}
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="{ row }">
          <d-switch v-model="row.status" :activeValue="1" :inactiveValue="0">
            <span slot="open">{{ $t('开启') }}</span>
            <span slot="close">{{ $t('关闭') }}</span>
          </d-switch>
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')" align="center" width="140">
        <template slot-scope="{ row }">
          <el-button @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0" align="right">
      <el-button
        type="success"
        :loading="submitting"
        @click="handleSubmit()"
      >{{ $t("保 存") }}</el-button>
    </div>
    <ActionDialog ref="actionDialogContorl" @update="handleSave" />
  </div>
</template>
<script>
import { homepagePlaySetting, editPlaySetting, editPlaySettingBatch  } from '@/api/config'
import TableSortMixin from '@/mixins/table-sort-mixin'
import ActionDialog from './ActionDialog.vue'

export default {
  components: { ActionDialog },
  mixins: [TableSortMixin(true)],
  data() {
    return {
      loading: false,
      items: [],
      submitting: false,
      types: [
        { value: 1, label: '彩票', includeTag: ['10','11','12','13','15','18','19','20'] },
        { value: 2, label: '电子', includeTag: ['1200','1300','1600','2500','2800','2900','3000','600','700','2402'] },
        { value: 3, label: '真人', includeTag: ['1301','1503','1800','200','2600'] },
        { value: 4, label: '体育', includeTag: ['1500','1502','1802','2000','2200','2700','800','99'] },
        { value: 5, label: '竞技', includeTag: ['1400','1501'] },
        { value: 6, label: '捕鱼', includeTag: ['1302','1601','1701','1801','1803','2300','2401','2501','2601','201'] },
        { value: 7, label: '棋牌', includeTag: ['100','1700','1804','2400'] }
      ],
    }
  },
  created() {
    this.getMenuList()
  },
  methods: {
    handleEdit(row) {
      const includeTag = this.types.find(e => e.value === row.type).includeTag
      this.$refs.actionDialogContorl.open(row, includeTag)
    },
    getMenuList() {
      this.loading = true
      homepagePlaySetting().then(([items, err]) => {
        if (!err) {
          this.items = []
          this.types.forEach(v => {
            const item = items.find(e => e.type === v.value)
            if(item) {
              this.items.push(item)
            } else {
              this.items.push({ type: v.value, sort: 9999, status: 0 })
            }
          })
          this.items = this.items.sort((a, b) => {
            return a.sort - b.sort
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    //保存多项
    handleSubmit() {
      const list = []
      this.items.forEach((e, i) => {
        if (e.id) {
          list.push({ id: e.id, status: e.status, sort: i + 1, type: e.type })
        }
      });
      if (list.length === 0) {
        return this.$message.warning('请先至少编辑保存一条数据')
      }
      this.submitting = true
      editPlaySettingBatch(list).then(([_, err]) => {
        if (!err) {
          this.$message.success(this.$t('保存成功'))
        }
      }).finally(() => {
        this.submitting = false
      })
    },
    //保存单项
    handleSave({ field, cancel, close }) {
      const arr = []
      if(field.myGamePlayList.length > 0){
        field.myGamePlayList.forEach(e => {
          if(e.seriesTag && e.icon){
            arr.push(e)
          }
        });
        if(arr.length < 1){
          this.$message.error(this.$t('请至少填写一条完整的信息（玩法-图标）'))
          this.submitting = false
          cancel()
          return
        }
      }
      field.sort = this.items.findIndex(e => e.type === field.type) + 1
      arr.forEach((e, i) => {e.sort = i + 1; delete e.rowKey});
      field.gamePlayListJson = JSON.stringify(arr)
      delete field.gamePlayList
      delete field.myGamePlayList
      editPlaySetting(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.getMenuList()
        } else {
          cancel()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select.is-error .el-input__inner {
  border-color: #f56c6c;
}
.top-tab {
  padding: 20px;
  width: 800px;
}
</style>
