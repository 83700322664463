var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("活动回收"),
        visible: _vm.visible,
        top: "5vh",
        "custom-class": "custom-dialog",
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.items, border: "", size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { property: "type", align: "center", label: _vm.$t("类型") },
          }),
          _c("el-table-column", {
            attrs: {
              property: "acount",
              align: "center",
              label: _vm.$t("金额"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              property: "member",
              align: "center",
              label: _vm.$t("人数"),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("关闭")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }