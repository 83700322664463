import { filterColumns, parseTime } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      width: 120
    },
    {
      title: this.$t('会员ID'),
      key: 'memberId',
      align: 'center'
    },
    {
      title: this.$t('上级'),
      key: 'oneLevelAgencyName',
      align: 'center'
    },
    {
      title: this.$t('添加时间'),
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span> { parseTime(row.createTime) } </span>
          )
        }
      }
    },
    {
      title: this.$t('操作人'),
      key: 'operatorName',
      align: 'center'
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (<el-button type='text' onClick={() => this.handleDelete(row.memberId)}>{this.$t('删除')}</el-button>)
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
