<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="50%"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      :disabled="emitName === 'details'"
      label-width="120px"
      label-suffix=":"
    >
      <el-form-item :label="$t('跳转页面')">
        <el-radio-group v-model="ruleForm.jumpType">
          <el-radio :label="-1">{{ $t('活动详情') }}</el-radio>
          <el-radio :label="1">{{ $t('链接地址') }}</el-radio>
          <el-radio :label="8">{{ $t('APP功能页面') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="ruleForm.jumpType === 8">
        <el-form-item prop="appPageId">
          <el-cascader v-model="ruleForm.appPageId" :options="appPageList" @change="handleChangeAppPage" style="width: 300px" clearable />
        </el-form-item>
      </template>
      <template v-if="ruleForm.jumpType === 1">
        <el-form-item prop="jumpUrl">
          <el-input v-model="ruleForm.jumpUrl" style="width: 300px" :placeholder="$t('请输入链接地址信息')" />
        </el-form-item>
      </template>
      <el-form-item :label="$t('语言选择')" prop="lang">
        <el-checkbox-group v-model="ruleForm.lang">
          <el-checkbox v-for="item of allLang" :key="item.lang" :label="item.lang">{{ $t(item.name) }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('活动名称')" prop="activityTitle">
        <el-input v-model="ruleForm.activityTitle" style="width: 300px" maxlength="20" :placeholder="$t('限20字以内')" />
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model.number="ruleForm.sort" style="width: 300px" placeholder="请输入1-99" />
      </el-form-item>
      <el-form-item :label="$t('活动分类')" prop="type">
        <el-select v-model="ruleForm.type" :placeholder="$t('活动分类')" :style="{ width: '300px' }" clearable>
          <el-option
            v-for="(item, index) in $parent.activityCategory"
            :key="index"
            :label="item.typeName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('活动引导说明')" prop="activityContent">
        <el-input v-model="ruleForm.activityContent" style="width: 500px;max-width: 100%;" maxlength="50" :placeholder="$t('限50字以内')" />
      </el-form-item>
      <el-form-item :label="$t('活动时间')">
        <el-radio-group v-model="ruleForm.activityTimeType">
          <el-radio :label="0">{{ $t('定时开启') }}</el-radio>
          <el-radio :label="1">{{ $t('持续开启') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="ruleForm.activityTimeType === 0" prop="activityDate">
        <el-date-picker
          v-model="ruleForm.activityDate"
          type="daterange"
          value-format="timestamp"
          :range-separator="$t('至')"
          :start-placeholder="$t('开始时间')"
          :end-placeholder="$t('开始时间')"
          :picker-options="pickerOptions"
        />
      </el-form-item>
      <el-form-item :label="$t('活动Banner')" prop="url">
        <el-upload
          class="banner-uploader"
          accept="image/jpeg,image/png,image/jpg,image/webp"
          :action="action"
          :data="{ type: 'image', folder: 'ad-photo' }"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleBannerSuccess"
        >
          <img v-if="ruleForm.url" :src="ruleForm.url" class="banner">
          <div v-else class="banner-uploader-txt">
            <p>{{ $t('文件大小控制在100K以内') }}</p>
            <button class="el-button el-button--small el-button--orange" @click.self.prevent>{{ $t('点击上传') }}</button>
          </div>
        </el-upload>
      </el-form-item>

      <el-form-item :label="$t('活动内页')">
        <el-radio-group v-model="ruleForm.remarkType" @change="_remarkTypeChange">
          <el-radio :label="0">{{ $t('纯图片') }}</el-radio>
          <el-radio :label="1">{{ $t('图配文') }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item prop="remark">
        <tinymce v-if="ruleForm.remarkType === 1" ref="tinymce" v-model="ruleForm.remark" :height="200" />
        <el-upload
          v-else
          class="banner-uploader"
          accept="image/jpeg,image/png,image/jpg,image/webp"
          :action="action"
          :data="{ type: 'image', folder: 'ad-photo' }"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleRemarkSuccess"
        >
          <img v-if="ruleForm.remark" :src="ruleForm.remark" class="banner">
          <div v-else class="banner-uploader-txt">
            <p>{{ $t('文件大小控制在100K以内') }}</p>
            <button class="el-button el-button--small el-button--orange" @click.self.prevent>{{ $t('点击上传') }}</button>
          </div>
        </el-upload>
      </el-form-item>

    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button v-if="emitName !== 'details'" type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import Tinymce from '@/components/Tinymce'
import FormDialogMixin from '@/mixins/form-dialog'
import { getCpadActivityList, getActivityTypeList } from '@/api/activity'
import { mapState } from 'vuex'
import { appPageList } from '@/libs/options'
import { getAppPageParams } from '@/libs/universal'
import { getLotteryNavigationHot } from '@/api/config'
import { getLiveRoomList } from "@/api/chat";

const getDefaultForm = () => ({
  activityTitle: '',
  activityContent: '',
  activityDate: '',
  jumpUrl: '',
  jumpType: undefined,
  appPageId: [],
  url: '',
  sort: 0,
  remark: '',
  type: '',
  popup: 0,
  remarkType: 0,
  activityTimeType: 0,
  lang: [],
  seriesTag: null,
  activityId: null,
  activityType: null,
  lotteryId: null,
  gameId: null,
  roomId: null,
  roomName: null
})

export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      redirectStatus: -1,
      firstLinks: [],
      jump: {
        first: '',
        second: '',
        third: ''
      },
      activityCategory: this.$t('activityCategory'),
      pickerOptions: {
        disabledDate(date) {
          return date.getTime() < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0)
        }
      },
      rules: {
        activityTitle: [{ required: true, message: this.$t('请填写活动标题'), trigger: 'blur' }],
        activityContent: [{ required: true, message: this.$t('请填写活动引导说明'), trigger: 'blur' }],
        // type: [{ required: true, message: this.$t('请选择活动分类'), trigger: 'blur' }],
        activityDate: [{ required: true, message: this.$t('请选择'), trigger: 'change' }],
        appPageId: [{ type: 'array', required: true, message: this.$t('请选择跳转页面'), trigger: 'change' }],
        jumpUrl: [{ required: true, message: this.$t('请填写链接地址'), trigger: 'blur' }],
        url: [{ required: true, message: this.$t('请选择活动Banner'), trigger: 'change' }],
        remark: [{ required: true, message: this.$t('请加入活动内页'), trigger: 'blur' }],
        sort: [{ required: true, pattern: /^[1-9][0-9]?$|^99$/, message: '请输入1-99', trigger: 'change' }],
        lang: [{ required: true, message: this.$t('请选择语言'), trigger: 'change' }]
      },
      isLoadAppPage: false,
      appPageList
    }
  },
  computed: {
    ...mapState('app', ['allLang']),

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    title() {
      return this.emitName === 'create' ? this.$t('创建活动') : this.$t('编辑活动')
    }
  },
  async created() {
    await this.handleOpen()
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm.id = row.id
        this.ruleForm.activityTitle = row.activityTitle
        this.ruleForm.activityContent = row.activityContent
        this.ruleForm.activityTimeType = row.activityTimeType
        this.ruleForm.url = row.url
        this.ruleForm.remarkType = row.remarkType
        this.ruleForm.remark = row.remark
        this.ruleForm.popup = row.popup
        this.ruleForm.jumpUrl = row.jumpUrl
        if (row.type > 0) {
          this.ruleForm.type = row.type
        }
        this.ruleForm.sort = parseInt(row.sort)

        if (row.remarkType === 1) {
          this.$nextTick(() => {
            this.$refs.tinymce.hasInit && this.$refs.tinymce.setContent(row.remark)
          })
        }

        if (row.activityTimeType === 0) {
          this.ruleForm.activityDate = [row.activityBeginDate, row.activityEndDate]
        }
        
        this.ruleForm.jumpType = row.jumpType
        this.ruleForm.seriesTag = row.seriesTag
        this.ruleForm.activityId = row.activityId
        this.ruleForm.activityType = row.activityType
        this.ruleForm.lotteryId = row.lotteryId
        this.ruleForm.gameId = row.gameId
        this.ruleForm.roomId = row.roomId
        this.ruleForm.roomName = row.roomName
        this.ruleForm.appPageId = []
        if (row.jumpType === 8) {
          this.ruleForm.appPageId = getAppPageParams(row.seriesTag, row.activityId, row.lotteryId, row.roomId)
        }

        if (row.lang) {
          this.ruleForm.lang = row.lang.split(',')
        }
        this.emitName = 'update'
      }
      this.visible = true
    },
    async handleOpen() {
      if (this.isLoadAppPage) return
      this.isLoadAppPage = true
      let list1 = []
      let list2 = []
      let list3 = []
      let list4 = []
      const [[items1, err1], [items2, err2], [items3, err3], [items4, err4]] = await Promise.all([getLotteryNavigationHot(), getCpadActivityList({ jumpType: 0, tenantCode: this.ruleForm.tenantCode }), getActivityTypeList({ currentPage: 1, pageSize: 100, isShow: 1 }), getLiveRoomList({ roomType: 1 })])
      if (!err1) {
        list1 = items1
      }
      if (!err2) {
        list2 = items2
      }
      if (!err3) {
        if (items3.length > 0) {
          const list = []
          items3.forEach(e => {
            const typeNameList = e.typeName.split(',')
            list.push({
              id: e.id,
              typeName: typeNameList[0].split(':')[1]
            })
          })
          list3 = list
        }
      }
      if (!err4) {
        list4 = items4
      }
      this.appPageList.forEach(e => {
        e.children.forEach(v => {
          const item = list1.find(r => r.seriesTag === v.value)
          if (item) {
            v.children = item.lotteryList.map(o => { 
              return {
                value: o.lotteryId,
                label: o.name,
                gameId: o.gameId,
                lotteryId: o.lotteryId
              }
            })
          }
          if (v.value === '9999002' && list2.length > 0) {
            v.children = list2.map(o => {
              return {
                value: o.id,
                label: o.activityTitle,
                activityId: o.id,
                activityType: o.jumpType
              }
            })
          }
          if (v.value === '9999014' && list3.length > 0) {
            v.children = list3.map(o => {
              return {
                value: o.id,
                label: o.typeName,
                activityId: o.id
              }
            })
          }
          if (v.value === '9999007' && list4.length > 0) {
            v.children = list4.map(o => {
              return {
                value: o.roomId,
                label: o.roomName,
                roomId: o.roomId,
                roomName: o.roomName
              }
            })
          }
          if (v.children && v.children.length === 0) {
            delete v.children
          }
        })
      })
    },
    handleBannerSuccess(res) {
      this.ruleForm.url = res.data[0]
      this.$refs.validateForm.validateField('url')
    },
    handleRemarkSuccess(res) {
      this.ruleForm.remark = res.data[0]
      this.$refs.validateForm.validateField('remark')
    },
    _remarkTypeChange() {
      this.ruleForm.remark = ''
    },
    handleChangeAppPage(pageId) {
      this.ruleForm.seriesTag = null
      this.ruleForm.lotteryId = null
      this.ruleForm.gameId = null
      this.ruleForm.activityId = null
      this.ruleForm.activityType = null
      this.ruleForm.roomId = null
      this.ruleForm.roomName = null
      if (pageId.length >= 2) {
        this.ruleForm.seriesTag = pageId[1]
      }
      if (pageId.length >= 3) {
        const node1 = this.appPageList.find(e => e.value === pageId[0])
        if (node1 && node1.children) {
          const node2 = node1.children.find(e => e.value === pageId[1])
          if (node2 && node2.children) {
            const node3 = node2.children.find(e => e.value === pageId[2])
            if (node3) {
              this.ruleForm.lotteryId = node3.lotteryId || null
              this.ruleForm.gameId = node3.gameId || null
              this.ruleForm.activityId = node3.activityId || null
              this.ruleForm.activityType = node3.activityType || null
              this.ruleForm.roomId = node3.roomId || null
              this.ruleForm.roomName = node3.roomName || null
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.banner-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.border_ {
  padding: 5px 10px;
  box-sizing: border-box;
}

.banner-uploader .el-upload:hover {
  border-color: #409EFF;
}

.banner-uploader-txt {
  font-size: 14px;
  color: #8c939d;
  width: 375px;
  height: 128px;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.banner {
  width: 375px;
  height: 128px;
  display: block;
}
</style>
