<template>
  <el-dialog
    :title="$t('Member_Expert.ExpertTab.编辑专家')"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    @open="onOpen"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      label-suffix=":"
    >
      <el-form-item :label="$t('Member_Expert.ExpertTab.专家简介')" prop="introduction">
        <el-input
          v-model="ruleForm.introduction"
          type="textarea"
          maxlength="500"
          clearable
          show-word-limit
        />
      </el-form-item>
      <el-form-item :label="$t('Member_Expert.ExpertTab.专家等级')" prop="positionId">
        <el-select v-model="ruleForm.positionId" :placeholder="$t('请选择',{text:$t('Member_Expert.ExpertTab.专家等级')})">
          <el-option v-for="item in positionList" :key="item.id" :label="item.positionName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Member_Expert.ExpertTab.总胜率')" prop="initialWinrate">
        <el-input v-model.number="ruleForm.initialWinrate" :placeholder="$t('请输入m_n区间数字', { text: '1-100' })">
          <template #suffix>%</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('Member_Expert.ExpertTab.总返奖率')" prop="initialReturnrate">
        <el-input v-model.number="ruleForm.initialReturnrate" :placeholder="$t('请输入m_n区间数字', { text: '1-100' })">
          <template #suffix>%</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('Member_Expert.ExpertTab.最高连胜')" prop="initialLinkwin">
        <el-input v-model.number="ruleForm.initialLinkwin" :placeholder="$t('请输入m_n区间数字', { text: '1-10' })" />
      </el-form-item>
      <el-form-item :label="$t('Member_Expert.ExpertTab.推荐次数')" prop="initialArtcileNum">
        <el-input v-model.number="ruleForm.initialArtcileNum" :placeholder="$t('请输入m_n区间数字', { text: '1-10' })" />
      </el-form-item>
      <el-form-item :label="$t('Member_Expert.ExpertTab.粉丝数')" prop="initialFansNum">
        <el-input v-model.number="ruleForm.initialFansNum" :placeholder="$t('请输入m_n区间数字', { text: '1-10' })" />
      </el-form-item>
      <el-form-item :label="$t('Member_Expert.ExpertTab.擅长联赛')" prop="greatGameIds">
        <el-select
          v-model="ruleForm.greatGameIds"
          multiple
          filterable
          :multiple-limit="3"
          :placeholder="$t('请选择', { text: $t('Member_Expert.ExpertTab.擅长联赛') })"
          style="width: 100%"
          @change="onChangeGames"
        >
          <el-option v-for="item in competitionList" :key="item.competitionId" :label="item.shortCompetitionName" :value="item.competitionId" />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getCompetitionList } from '@/api/lottery'
import { getExpertDetailById } from '@/api/expert'

const getDefaultForm = () => ({
  introduction: '',
  positionId: null,
  initialWinrate: null,
  initialReturnrate: null,
  initialLinkwin: null,
  initialArtcileNum: null,
  initialFansNum: null,
  greatGameIds: []
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      loading: false,
      emitName: 'update',
      competitionList: [],
      positionList: [],
      rules: {
        introduction: [{ required: true, message: this.$t('请输入', { text: this.$t('Member_Expert.ExpertTab.专家简介') }), trigger: 'blur' }],
        positionId: [{ required: true, message: this.$t('请选择', { text: this.$t('Member_Expert.ExpertTab.专家等级') }), trigger: 'change' }],
        initialWinrate: [
          { pattern: /^(?:[1-9]?\d|100)$/, message: this.$t('请输入m_n区间数字', { text: '1-100' }), trigger: 'blur' }
        ],
        initialReturnrate: [
          { pattern: /^(?:[1-9]?\d|100)$/, message: this.$t('请输入m_n区间数字', { text: '1-100' }), trigger: 'blur' }
        ],
        initialLinkwin: [
          { pattern: /^(0|[1-9]?\d{1,9})$/, message: this.$t('请输入m_n区间数字', { text: '1-10' }), trigger: 'blur' }
        ],
        initialArtcileNum: [
          { pattern: /^(0|[1-9]?\d{1,9})$/, message: this.$t('请输入m_n区间数字', { text: '1-10' }), trigger: 'blur' }
        ],
        initialFansNum: [
          { pattern: /^(0|[1-9]?\d{1,9})$/, message: this.$t('请输入m_n区间数字', { text: '1-10' }), trigger: 'blur' }
        ],
        greatGameIds: [
          { required: true, message: this.$t('请选择', { text: this.$t('Member_Expert.ExpertTab.擅长联赛') }), trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    open(row, positionList) {
      this.ruleForm.id = row.expertId
      this.ruleForm.tenantCode = row.tenantCode
      this.visible = true
      this.positionList = positionList
    },

    onOpen() {
      this.init().then(() => {
        this.fetch()
      })
    },

    init() {
      return new Promise((resolve) => {
        this.loading = true
        getExpertDetailById({
          id: this.ruleForm.id,
          tenantCode: this.ruleForm.tenantCode
        }).then(([data, err]) => {
          this.loading = false
          if (!err) {
            this.ruleForm.introduction = data.introduction || ''
            this.ruleForm.positionId = data.positionId
            this.ruleForm.initialWinrate = parseInt(data.initialWinrate)
            this.ruleForm.initialReturnrate = parseInt(data.initialReturnrate)
            this.ruleForm.initialLinkwin = parseInt(data.initialLinkwin)
            this.ruleForm.initialArtcileNum = parseInt(data.initialArtcileNum)
            this.ruleForm.initialFansNum = parseInt(data.initialFansNum)
            this.ruleForm.greatGames = data.greatGames
            this.ruleForm.greatGameIds = data.greatGameIds && data.greatGameIds.split(',').map(o => parseInt(o)) || []
            resolve()
          }
        })
      })
    },
    fetch() {
      getCompetitionList({
        sportType: 1,
        showStatus: 1,
        currentPage: 1,
        pageSize: 100,
        tenantCode: this.ruleForm.tenantCode
      }).then(([data, err]) => {
        if (!err) {
          this.competitionList = data || []
        }
      })
    },
    onChangeGames(ids) {
      const greatGames = ids.reduce((c, v) => {
        const tmp = this.competitionList.find(o => o.competitionId === v)
        if (tmp) {
          c.push(tmp.shortCompetitionName)
        }
        return c
      }, [])

      this.ruleForm.greatGames = greatGames + ''
    }
  }
}
</script>
