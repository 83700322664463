<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0)">今天</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1)">昨天</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7)">7天</el-button>
      <el-select v-model="search.type" clearable placeholder="请选择查询类型" class="filter-item">
        <el-option v-for="(item, key) in typeOptions" :key="key" :label="item" :value="key" />
      </el-select>
      <el-input
        v-if="search.type !== '' && search.type !== undefined"
        v-model="search.name"
        class="filter-item"
        maxlength="30"
        clearable
        :placeholder="search.type === '0' ? '请输入用户账户' : '请输入用户昵称'"
        style="width: 200px"
      />
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch()">查询</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button class="filter-item" type="primary" @click="onRelease()">圈子发帖</el-button>
      <el-button class="filter-item" type="primary" @click="$refs.communitySetDialogControl.open()">社区配置</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <silent-dialog ref="silentDialogEle" @backSuccess="handleSearch()" />
    <reply-dialog ref="replyDialogEle" />
    <look-img ref="lookImgEle" />
    <CommunitySetDialog ref="communitySetDialogControl" @set="handleCommunitySet" />
  </div>
</template>

<script>
import silentDialog from './components/silent-dialog.vue'
import replyDialog from './components/reply-dialog/index.vue'
import lookImg from './components/look-img.vue'
import { getTopicList, deleteTopic } from '@/api/sun-lap.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { typeOptions } from './pageData.js'
import { getDefaultTimes } from '@/utils'
import { bacthRecommendLimit, communityEditConfig } from '@/api/interactive'
import CommunitySetDialog from './components/CommunitySetDialog.vue'
export default {
  name: 'InteractiveShareLap',
  components: { silentDialog, replyDialog, lookImg, CommunitySetDialog },
  mixins: [TablePageMixin(getTopicList)],
  data() {
    return {
      typeOptions, // 查询类型
      defaultColumns: GetColumns.call(this), // 默认表格
      columns: [], // 已选展示项
      times: getDefaultTimes(),
      search: {},
      tenant: null // 租户信息
    }
  },
  computed: {
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    }
  },
  methods: {

    // 锁帖、解除锁帖 三处入口 心水推荐 晒单全 用户管理
    handleBatchLimit1({ memberId, muteStatus = 0 }) {
      if (muteStatus != undefined) {
        muteStatus = (muteStatus + 1) % 2
      }
      let data = { muteStatus }
      data.memberIds = [memberId]
      let msg = `确定对用户所有帖子${muteStatus ? '' : '解除'}锁贴`
      this.$confirm(msg, '系统提示', { type: 'warning' }).then(() => {
        bacthRecommendLimit(data).then(([data, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    // 禁言、解除禁言 三处入口 心水推荐 晒单全 用户管理
    handleBatchLimit({ memberId, freezeStatus = 0 }) {
      if (freezeStatus != undefined) {
        freezeStatus = (freezeStatus + 1) % 2
      }
      let data = { freezeStatus }
      data.memberIds = [memberId]
      let msg = `确定对用户${freezeStatus ? '' : '解除'}禁言`
      this.$confirm(msg, '系统提示', { type: 'warning' }).then(() => {
        bacthRecommendLimit(data).then(([data, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    _delete({ id, nickname }) { // 删帖
      this.$confirm(`确认删除昵称 ${nickname} 的帖子吗？`, { type: 'warning' }).then(() => {
        const params = this.$P$ ? { tenantCode: this.tenant.tenantCode, id } : { id }
        deleteTopic(params).then(([data, err]) => {
          if (!err) {
            this.handleSearch()
            this.$message.success(`${nickname} 的帖子删除成功！`)
          }
        })
      })
    },

    _banned(obj) { // 禁言
      this.$refs.silentDialogEle.init(obj)
    },

    _reply(obj) { // 回复
      this.$refs.replyDialogEle.init(obj)
    },

    _lookImg(list, name) { // 查看图片
      this.$refs.lookImgEle.init(list, name)
    },

    handleChangeTenant([tenant]) { // 选择租户
      this.times = null
      this.search = {}
      this.tenant = tenant
      this.search.tenantCode = tenant.tenantCode
      this.handleSearch()
    },

    onRelease() {
      if (this.$P$ && !this.search.tenantCode) {
        this.$message.error('请选择租户')
        return
      }
      let query = { code: '' }
      if (this.$P$) query.code = this.search.tenantCode

      this.$router.push({ name: 'ShareLapRelease', query })
    },

    handleCommunitySet({ field, cancel, close }) {
      field.communityDesc = JSON.stringify(field.communityDesc)
      communityEditConfig(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('设置成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    implementationGetParams() {
      if (this.search.type === undefined || this.search.type === '') delete this.search.name
      return this.formatIntervalTime()
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
