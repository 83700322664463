const CanChooseColumns = function () {
  return [
    {
      title: "大神账号",
      key: "memberName",
      align: "center",
    },
    {
      title: "昵称",
      key: "nickname",
      align: "center",
    },
    {
      title: "账号类型",
      key: "memberType",
      align: "center",
      component: {
        render: (h, row) => {
          switch (row.memberType) {
            case 1:
              return <span>内部账号</span>;
            case 2:
              return <span>普通账号</span>;
            case 3:
              return <span>普通账号</span>;
            default:
              return <span>{row.memberType}</span>;
          }
        },
      },
    },
    {
      title: "游戏类型",
      key: "bigPlayType",
      align: "center",
      component: {
        render: (h, row) => {
          switch (row.bigPlayType) {
            case 1:
              return <span>竞彩体育</span>;
            case 2:
              return <span>六合彩</span>;
            default:
              return <span>{row.bigPlayType}</span>;
          }
        },
      },
    },
    {
      title: "发单数",
      key: "numberOfOrder",
      align: "center",
    },
    {
      title: "自购金额",
      key: "selfBuyAmount",
      align: "center",
    },
    {
      title: "自购中奖",
      key: "selfHitAmount",
      align: "center",
      sortable: true,
      sortMethod: function (a, b) {
        return parseFloat(a.selfHitAmount) - parseFloat(b.selfHitAmount);
      },
      width:100,
    },
    {
      title: "自购盈亏",
      key: "selfProfit",
      align: "center",
    },
    {
      title: "盈利率",
      key: "profitability",
      align: "center",
      component: {
        render: (h, row) => {
         return <span>{this.dealNumber(row.profitability*100,1)}%</span>
        },
      },
      sortable: true,
      sortMethod: function (a, b) {
        return parseFloat(a.profitability) - parseFloat(b.profitability);
      },
      width:100,

    },
    {
      title: "跟单单数",
      key: "followCount",
      align: "center",
    },
    {
      title: "跟单金额",
      key: "followAmount",
      align: "center",
    },
    {
      title: "跟单中奖",
      key: "followHitAmount",
      align: "center",
    },
    {
      title: "跟单盈亏",
      key: "followProfit",
      align: "center",
    },

    {
      title: "总佣金",
      key: "totalCommission",
      align: "center",
    },
    {
      title: "平台抽佣金",
      key: "platformCommission",
      align: "center",
      width:100,
    },
    {
      title: "命中统计",
      key: "hitNum",
      align: "center",
      sortable: true,
      sortMethod: function (a, b) {
        return parseFloat(a.hitNum) - parseFloat(b.hitNum);
      },
      width:100,
    },
    {
      title: "连中统计",
      key: "continuousHit",
      align: "center",
      sortable: true,
      sortMethod: function (a, b) {
        return parseFloat(a.continuousHit) - parseFloat(b.continuousHit);
      },
      width:100,
    },
  ];
};

export default function (keys = []) {
  return CanChooseColumns.call(this);
}
