import { filterColumns } from '@/utils'
import dayjs from 'dayjs'

const CanChooseColumns = function() {
  return [
    {
      title: '卡券ID',
      key: 'couponId',
      align: 'center'
    },
    {
      title: '优惠类型',
      key: 'couponType',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{ this.couponTypes[row.couponType] }</span>
        }
      }
    },
    {
      title: '卡券名称',
      key: 'couponTitle',
      align: 'center'
    },
    {
      title: '面额',
      key: 'couponAmount',
      align: 'center',
      component: {
        render: (h, row) => {
          const couponAmount = row.couponAmount
          switch (row.couponType) {
            case 3:
              return <span>{row.couponDiscountType === 1 ? row.couponDiscountRate + '折' : couponAmount}</span>;
              break;
            case 6:
              return <span>加奖{couponAmount}倍</span>;
              break;
            default:
              return <span>{couponAmount}</span>;
          }
        }
      }
    },
    {
      title: '使用标准',
      key: 'couponStandard',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.couponType === 6) {
            return <span>最小下注{row.couponStandardMin}，最大下注{row.couponStandardMax}</span>;
          } else {
            return <span>{row.couponStandard}</span>;
          }
        }
      }
    },
    {
      title: '有效期',
      key: 'couponExpired',
      align: 'center',
      width: 250,
      component: {
        render: (h, row) => {
          const couponValidStart = this.$parseTime(row.couponValidStart, '{y}-{m}-{d} {h}:{i}')
          const couponValidEnd = this.$parseTime(row.couponValidEnd, '{y}-{m}-{d} {h}:{i}')
          return <span>{row.couponValidType === 1 ? (couponValidStart + ' 至 ' + couponValidEnd) : ('领取后' + row.couponExpired + '小时有效')}</span>
        }
      }
    },
    {
      title: '剩余库存',
      key: 'couponStock',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{row.couponStock === -1 ? '无限' : row.couponStock}</span>
        }
      }
    },
    {
      title: '适用范围',
      key: 'couponScopeName',
      align: 'center',
      component: {
        render: (h, row) => {
          if ((row.couponType !== 4 && row.couponScope === '-1') || (row.couponType === 4 && row.codingPlatform === '-1')) {
            return <span>无限制</span>
          } else {
            let couponScopeName = '适用范围：' + row.couponScopeName
            if (row.couponType === 4) {
              couponScopeName = '打码平台：' + row.codingPlatformName
            }
            return (
              <el-tooltip
                placement='right'
                content={couponScopeName}
              >
                <span class='ellipsis-text'>{ couponScopeName }</span>
              </el-tooltip>
            )
          }
        }
      }
    },
    {
      title: '上架状态',
      key: 'couponStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          // const { Update, Delete } = this.permission
          return (
            <d-switch v-model={row.couponStatus}
              active-value={1}
              inactive-value={0}
              beforeChange={this._handleChangeStatus.bind(this, row)}
            >
              <span slot='open'>{this.$t('上架')}</span>
              <span slot='close'>{this.$t('下架')}</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: '备注',
      key: 'couponRemark',
      align: 'center'
    },
    {
      title: '创建人',
      key: 'createUser',
      align: 'center'
    },
    {
      title: '创建时间',
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          const { Delete, Update } = this.permission
          return (
            <span>
              { <el-link type='primary' onClick={() => this.$refs.DetailDialogControl.open(row)}>{ this.$t('查看详情') }</el-link> }
              { <el-link type='primary' onClick={() => this.$refs.SendDialogControl.open(row)}>{ this.$t('手动发放') }</el-link> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
