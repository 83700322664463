<template>
  <el-dialog
    :visible.sync="visible"
    width="60%"
    title="功能配置"
    top="5vh"
    :close-on-click-modal="false"
    @open="onOpen"
    @closed="onClosed"
  >
    <el-tabs v-model="active" type="card">
      <el-tab-pane label="彩种配置" name="Lottery">
        <Lottery ref="TenantFunctionLottery" />
      </el-tab-pane>
      <el-tab-pane label="功能配置" name="Function">
        <Function ref="TenantFunctionFunction" />
      </el-tab-pane>
    </el-tabs>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        确认
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getSettingLotteryStatusList } from '@/api/tenant'
import { Lottery, Function } from './common/functions'
export default {
  components: { Lottery, Function },
  data: () => ({
    active: 'Lottery',
    visible: false,
    submitting: false,
    ruleForm: {}
  }),
  methods: {
    open({ tenantId, tenantCode }) {
      this.ruleForm.tenantId = tenantId
      this.ruleForm.tenantCode = tenantCode
      this.visible = true
    },
    close() {
      this.visible = false
    },
    onOpen() {
      this.$nextTick(() => {
        this.$refs.TenantFunctionLottery.fetch(getSettingLotteryStatusList, this.ruleForm.tenantCode)
        this.$refs.TenantFunctionFunction.fetch(this.ruleForm.tenantCode)
      })
    },
    onClosed() {
      this.active = 'Lottery'
      this.ruleForm = {}
      this.submitting = false
    },
    handleSubmit() {
      this.$refs.TenantFunctionLottery.onValidate().then((lottery) => {
        this.$refs.TenantFunctionFunction.onValidate().then((config) => {
          this.submitting = true
          this.$emit('update', {
            field: {
              ...this.ruleForm,
              ...lottery,
              ...config
            },
            close: this.close,
            cancel: () => {
              this.submitting = false
            }
          })
        }).catch(() => {
          this.active = 'Function'
        })
      }).catch(() => {
        console.warn('参数不合法')
      })
    }
  }
}
</script>
