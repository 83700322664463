import DSwitch from '@/components/DSwitch'
import DTable from '@/components/DTable'
import ClipButton from '@/components/ClipButton'
import Onlynumber from '@/directive/onlynumber'
import { parseTime } from '@/utils'
import store from '@/store'
import { getToken } from '@/utils/auth'

export default (Vue) => {
  Vue.directive('Onlynumber', Onlynumber)
  Vue.directive('removeAriaHidden', {
    bind(el, binding) {
      const ariaEls = el.querySelectorAll('.el-radio__original')
      ariaEls.forEach((item) => {
        item.removeAttribute('aria-hidden')
      })
    }
  })

  Vue.component(DSwitch.name, DSwitch)
  Vue.component(DTable.name, DTable)
  Vue.component(ClipButton.name, ClipButton)

  Vue.prototype.$parseTime = parseTime
  Vue.prototype.$parseColor = (amount) => {
    const tmp = Math.abs(amount)
    if (tmp >= 0 && tmp <= 1000) {
      return 'success-type'
    } else if (tmp > 1000 && tmp <= 10000) {
      return 'process-type'
    } else {
      return 'error-type'
    }
  }
  Vue.prototype.$parseRedOrGreen = (amount) => {
    if(amount>0){
      return 'isred'
    }else if (amount <0){
      return 'isgreen'
    }
  }
  Vue.prototype.$headers = () => { return { 'X-AUTH-TOKEN': getToken(), 'X-TIMESTAMP': (new Date()).getTime(), 'Request-Encrypt': false, 'X-TENANT-CODE': store.getters.tenantCode } }
}

