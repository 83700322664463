<template>
  <el-dialog
    :title="title"
    width="700px"
    top="5vh"
    :visible.sync="visible"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item :label="$t('标题')" prop="title">
        <el-input v-model="ruleForm.title" :placeholder="$t('请输入站内信标题')" />
      </el-form-item>
      <el-form-item
        :label="$t('发送方式')"
        prop="sendType"
      >
        <el-select
          v-model="ruleForm.sendType"
          :disabled="emitName === 'update'"
          :placeholder="$t('请选择发送方式')"
        >
          <el-option v-for="(item, key) in $parent.sendTypeOptions" :key="key" :label="item" :value="+key" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.sendType === 1 || ruleForm.sendType === 2"
        :label="$t('接收人')"
        prop="memberList"
      >
        <el-input
          v-model="ruleForm.memberList"
          :disabled="emitName === 'update'"
          :placeholder="ruleForm.sendType === 2 && $t('请输入接收会员的账号, 多个账号之间以英文逗号隔开,例如:账号1,账号2') || $t('请输入接收会员的账号, 例如:账号1')"
        />
      </el-form-item>
      <el-form-item v-if="ruleForm.sendType === 3" :label="$t('会员VIP等级')" prop="vipLevel">
        <el-input
          v-model="ruleForm.vipLevel"
          :disabled="emitName === 'update'"
          :placeholder="$t('请输入会员VIP等级，多个等级以英文逗号隔开, 例如: 4,5,6')"
        />
      </el-form-item>
      <el-form-item label="" label-width="0" prop="content">
        <tinymce ref="tinymce" v-model="ruleForm.content" :height="200" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('取消') }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">{{ $t('确 定') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import Tinymce from '@/components/Tinymce'
// import { getStationletterDetail } from '@/api/announcement'

const getDefaultForm = () => ({
  title: '',
  sendType: null,
  memberList: '',
  vipLevel: '',
  content: ''
})

export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      loading: false,
      rules: {
        title: [{ required: true, message: this.$t('请输入标题'), trigger: 'blur' }],
        sendType: [{ required: true, message: this.$t('请选择发送方式'), trigger: 'change' }],
        vipLevel: [{ required: true, message: this.$t('请输入'), trigger: 'blur' }],
        memberList: [{ required: true, message: this.$t('请输入'), trigger: 'blur' }],
        content: [{ required: true, message: this.$t('请输入'), trigger: 'blur' }]
      }
    }
  },
  computed: {
    title() {
      return this.emitName === 'update' ? this.$t('编辑站内信') : this.$t('新增站内信')
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm.stationLetterId = row.id
        this.ruleForm.title = row.title
        this.ruleForm.sendType = row.sendType
        this.ruleForm.vipLevel = row.vipLevel
        this.ruleForm.memberList = row.memberList
        this.ruleForm.content = row.content

        this.$nextTick(() => {
          this.$refs.tinymce.hasInit && this.$refs.tinymce.setContent(row.content)
        })

        this.emitName = 'update'
      } else {
        this.$nextTick(() => {
          this.$refs.tinymce.hasInit && this.$refs.tinymce.setContent('')
        })
      }
      this.visible = true
    }
  }
}
</script>
