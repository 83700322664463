import { rateTypes } from '@/libs/options'
import { filterColumns } from '@/utils'

const CanChooseColumns = function() {
  return [
    {
      title: this.$t('订单号'),
      key: 'orderNo',
      align: 'center'
    },
    {
      title: this.$t('会员信息'),
      key: 'member',
      renderHeader: (h) => {
        return (
          <div>
            <p>{ this.$t('会员账号') }</p>
            <p>{ this.$t('真实姓名') }</p>
          </div>
        )
      },
      component: {
        render: (h, row) => {
          return (
            <div class='cursor-pointer' onClick={this._onDetails.bind(this, row)}>
              <clip-button text={ row.memberName }></clip-button>
              {/* <p>账号:{ row.memberName }<el-button type='text' size='mini' onClick={(e) => this.clip(row.memberName, e)}>复制</el-button></p> */}
              <span>{ row.realName }</span>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('VIP等级'),
      key: 'vipLevel',
      align: 'center',
      width: 80,
      component: {
        render: (h, row) => {
          return (<span>VIP{ row.vipLevel }</span>)
        }
      }
    },
    {
      title: this.$t('充值平台金额'),
      key: 'amount',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              { row.type === 0 && <p style='font-weight: bolder'>{ row.exchangeAmount } / { row.exchangeRate }</p> }
              <clip-button text={ row.amount } classes={this.$parseColor(row.amount)}></clip-button>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('货币汇率'),
      align: 'center',
      component: {
        render: (h, row) => {
          const rateType = rateTypes.find(e => e.key === row.rateType)
          if (rateType) {
            return (
              <div>
                <p>{ rateType.label }</p>
                <span>{ row.exchangeRate }</span>
              </div>
            )
          }
          return (
            <span>{ row.exchangeRate }</span>
          )
        }
      }
    },
    {
      title: this.$t('货币金额'),
      key: 'exchangeAmount',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span class={this.$parseColor(row.exchangeAmount)}>{ row.exchangeAmount }</span>)
        }
      }
    },
    {
      title: this.$t('赠送金额'),
      key: 'giveAmount',
      align: 'center'
    },
    {
      title: this.$t('转账姓名'),
      key: 'payingName',
      align: 'center'
    },
    {
      title: this.$t('收款信息'),
      key: 'paying',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{ row.receivingName }</p>
              <p>{ row.receivingBank }</p>
              <p>{ row.receivingCardNo }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('附言'),
      key: 'postscript',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>
            <p>{ row.postscript }</p>
            { row.rechargeUrl && <el-link type='primary' onClick={() => this.$refs.ImagePreviewControl.open(row.rechargeUrl)}>查看凭证</el-link> }
          </span>)
        }
      }
    },
    {
      title: this.$t('发起时间'),
      key: 'createTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: this.$t('订单状态'),
      key: 'status',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          const tmp = this.rechargeStatus.find(o => o.key === row.status)
          if (tmp) {
            return (<span class={`${tmp.type}-type`}>{ tmp.label }</span>)
          }
          return '--'
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          if (row.status === '1111') {
            const { Yes, NOT } = this.permission
            return (
              <span>
                { Yes && <el-button type='success' size='small' onClick={() => this.$refs.AloneDialogControl.open(row, true)}>{ this.$t('通过') }</el-button> }
                { NOT && <el-button type='danger' size='small' onClick={() => this.$refs.AloneDialogControl.open(row, false)}>{ this.$t('拒绝') }</el-button> }
              </span>
            )
          }
          return (
            <span>{this.$t('操作人')}:{ row.operatorName }</span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
