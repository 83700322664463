import { $t } from '@/lang'
export function filterResponseStatus(error) {
  switch (error.response.status) {
    case 400:
      error.message = $t('responseStatus.请求错误')
      break
    case 401:
      error.message = $t('responseStatus.未授权，请登录')
      break
    case 403:
      error.message = $t('responseStatus.拒绝访问')
      break
    case 404:
      error.message = `${$t('responseStatus.请求地址出错')}: ${error.response.config.url}`
      break
    case 408:
      error.message = $t('responseStatus.请求超时')
      break
    case 500:
      error.message = $t('responseStatus.服务器内部错误')
      break
    case 501:
      error.message = $t('responseStatus.服务未实现')
      break
    case 502:
      error.message = $t('responseStatus.网关错误')
      break
    case 503:
      error.message = $t('responseStatus.服务不可用')
      break
    case 504:
      error.message = $t('responseStatus.网关超时')
      break
    case 505:
      error.message = $t('responseStatus.HTTP版本不受支持')
      break
    case 1048:
      error.message = $t('responseStatus.暂停服务，请稍后再试！')
      break
    case 1049:
      error.message = $t('responseStatus.该接口暂停服务，请稍后再试')
      break
    default:
      break
  }

  return error
}

