<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.roomName" type="search" :placeholder="$t('房间主题')" class="filter-item" style="width: 200px" />
      <el-select
        v-model="search.lottypeId"
        :placeholder="$t('彩种类型')"
        clearable
        class="filter-item"
        style="width: 200px;"
        @change="search.lotkindId = '';lotteryList = []"
      >
        <el-option v-for="item in livePreLotteryType" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-select
        v-model="search.lotkindId"
        filterable
        remote
        collapse-tags
        :disabled="search.lottypeId === undefined || search.lottypeId === '' || search.lottypeId === 4"
        reserve-keyword
        placeholder="彩种/赛事"
        class="filter-item"
        style="width: 200px;"
        :remote-method="remoteMethod"
        :loading="lotkindIdLoading"
      >
        <el-option
          v-for="item in lotteryList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="filter-container">
      <el-input v-model="search.nickName" :placeholder="$t('主播昵称')" clearable class="filter-item" style="width: 200px;" />
      <el-input v-model="search.familyName" :placeholder="$t('所属家族')" clearable class="filter-item" style="width: 200px;" />
      <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleSearch">{{ $t('查 询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      row-key="roomId"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @select="handleSelect"
      @select-all="handleSelectAll"
    >
      <template slot="left"><el-checkbox v-model="showChecked" @change="handleChecked">展示选中</el-checkbox></template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>

</template>

<script>
import {
  getLiveManagement,
  getLotteryAllList,
  doUpdateTopStatus,
  updateRoomHot
} from '@/api/live'
import { mapGetters } from 'vuex'
import { displayStatusList, livePreLotteryType } from '@/libs/options'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { $t } from '@/lang'
import { footballThirdMatchList, basketballThirdMatchList } from '@/api/lottery'
import { string } from 'clipboard';

export default {
  name: 'LiveManagement',
  mixins: [TablePageMixin(getLiveManagement)],
  data() {
    return {
      displayStatusList,
      livePreLotteryType,
      columns: [],
      defaultColumns: GetColumns.call(this),
      lotteryOptions: [],
      lotkindIdLoading: false,
      lotteryList: [],
      showChecked: false,
      checkedItems: []
    }
  },
  props: {
    channelIds: {
      type: Array,
      default() {
        return []
      }
    },
    categoryUnionId: {
      type: String,
      default: ''
    },
    categoryIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    ...mapGetters(['tenantCode'])
  },
  mounted() {
    getLotteryAllList(this.tenantCode).then(([items, err]) => {
      if (!err) {
        this.lotteryOptions = items.filter(o => o.liveShowStatus === 1 && o.tenantShowStatus === 1).map(e => {
          return {
            value: e.lotteryId,
            label: e.name
          }
        })
      }
    })
  },
  methods: {
    _handleUpdateDever({ field, cancel, close }) {
      updateRoomHot({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success($t('操作成功'))
        } else {
          cancel()
        }
      })
    },
    _handleChangeTopStatus({ roomId, topStatus }) {
      return new Promise((resolve) => {
        this.$confirm(topStatus === 2 ? $t('确认置顶此直播间?') : $t('确认取消置顶此直播间?'), $t('系统提示'), { type: 'warning' }).then(() => {
          doUpdateTopStatus({
            roomId,
            topStatus: topStatus === 2 ? 1 : 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success($t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    implementationFetched({ items }) {
      if (items.length > 0) {
        this.$nextTick(() => {
          items.forEach(e => {
            if (e.categoryUnionIds && e.categoryUnionIds.includes(this.categoryUnionId)) {
              this.$refs.DTable.$refs.elTable.toggleRowSelection(e)
            }
          })
        })
      }
    },
    handleSelect(selection, row) {
      const status = selection.some(e => e.roomId === row.roomId) ? 1 : 0
      const item = this.checkedItems.find(e => e.roomId === row.roomId)
      if (item) {
        item.status = status
      } else {
        this.checkedItems.push({
          roomId: row.roomId,
          status
        })
      }
    },
    handleSelectAll(selection) {
      const list = []
      if (selection.length > 0) {
        selection.forEach(e => {
          const item = this.checkedItems.find(v => v.roomId === e.roomId)
          if (item) {
            item.status = 1
          } else {
            list.push({
              roomId: e.roomId,
              status: 1
            })
          }
        })
        if (list.length > 0) {
          this.checkedItems = this.checkedItems.concat(list)
        }
      } else {
        this.originalItems.forEach(e => {
          const item = this.checkedItems.find(v => v.roomId === e.roomId)
          if (item) {
            item.status = 0
          } else {
            list.push({
              roomId: e.roomId,
              status: 0
            })
          }
        })
        if (list.length > 0) {
          this.checkedItems = this.checkedItems.concat(list)
        }
      }
    },
    handleChecked(checked) {
      if (checked) {
        this.search.categoryIds = this.categoryIds
        this.handleSearch()
      } else {
        delete this.search.categoryIds
        this.handleSearch()
      }
    },
    implementationGetParams() {
      this.search.channelIds = this.channelIds
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    remoteMethod(query) {
      if (query !== '') {
        if (this.search.lottypeId === 1) {
          this.lotteryList = this.lotteryOptions.filter(e => e.label.toLowerCase().indexOf(query.toLowerCase()) > -1)
          return
        }
        this.lotkindIdLoading = true
        let service = footballThirdMatchList
        if (this.search.lottypeId === 3) {
          service = basketballThirdMatchList
        }
        service({ competitionName: query, currentPage: 1, pageSize: 100 }).then(([items, err]) => {
          if (!err && items && items.length) {
            this.lotteryList = items.map(e => {
              return {
                value: e.thirdMatchId,
                label: e.competitionNameZh + '-' + e.homeTeamNameZh + 'VS' + e.awayTeamNameZh
              }
            })
          }
        }).finally(() => {
          this.lotkindIdLoading = false
        })
      } else {
        this.lotteryList = []
      }
    }
  }
}
</script>
