// 引入示例 import ElDialogMx from '@/views/mixins/el-dialog-mx.js'
export default {
  data() {
    return {
      dialogFormVisible: false,
      btnloading: false,
      dialogloading: false,
      formLabelWidth: '120px',
      titleObj: { title: '', type: '' }
    }
  },
  methods: {
    handleSubmit(formName) { // 表单提交
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this._submit()
        } else {
          return false
        }
      })
    },

    _submit() {},

    _close() { // 关闭弹框
      if (this.rules) this.$refs['ruleForm'].resetFields()
      if (this.$refs.tinymceEle) this.$refs.tinymceEle.setContent('') // 更新富文本框的值
      this.btnloading = false
      this.dialogloading = false
      this.dialogFormVisible = false
    }
  }
}
