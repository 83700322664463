<template>
  <el-dialog title="添加卡券" :visible.sync="visible" width="1100px" top="5vh" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :key="ruleForm.couponType" :model="ruleForm" v-loading="loading" :rules="rules" label-width="190px" label-suffix=":">
      <el-form-item :label="$t('优惠类型')" prop="couponType">
        <el-select v-model="ruleForm.couponType" :placeholder="$t('优惠类型')" class="filter-item" style="width: 350px">
          <el-option v-for="(val, key) in couponTypes" :key="key" :label="val" :value="+key" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('卡券名称')" prop="couponTitle">
        <el-popover v-if="ruleForm.couponTitleList.length > 0" placement="bottom" title="卡券名称多语言配置" trigger="click">
          <el-input slot="reference" :value="ruleForm.couponTitleList[0].couponTitle" style="width: 350px" readonly />
          <div class="lang-pop">
            <el-input v-for="item in ruleForm.couponTitleList" :key="item.lang" v-model="item.couponTitle" style="width: 350px" :placeholder="item.name" />
          </div>
        </el-popover>
      </el-form-item>
      <template v-if="ruleForm.couponType === 3">
        <el-form-item :label="$t('折扣方式')">
          <el-radio-group v-model="ruleForm.couponDiscountType" v-removeAriaHidden>
            <el-radio :label="0">{{ $t('指定金额') }}</el-radio>
            <el-radio :label="1">{{ $t('指定折扣') }}</el-radio>
          </el-radio-group>
          <el-form-item v-if="ruleForm.couponDiscountType === 0" prop="couponAmount">
            <el-input v-model="ruleForm.couponAmount" class="amount" :placeholder="$t('设置优惠金额')" maxlength="11" />
          </el-form-item>
          <el-form-item v-else prop="couponDiscountRate">
            <el-input v-model="ruleForm.couponDiscountRate" class="amount" :placeholder="$t('设置折扣')">
              <template slot="append">折</template>
            </el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('使用标准')" prop="couponStandard">
          <el-input v-model="ruleForm.couponStandard" :placeholder="$t('最低投注金额')" style="width: 350px" maxlength="8" />
        </el-form-item>
      </template>
      <el-form-item :label="$t('生效时间')">
        <el-radio-group v-model="ruleForm.couponValidType">
          <el-radio :label="0">领取后生效</el-radio>
          <el-radio :label="1">固定有效期</el-radio>
        </el-radio-group>
        <el-form-item v-if="ruleForm.couponValidType === 1" prop="couponStartTime">
          <el-date-picker
            v-model="ruleForm.couponTime"
            type="datetimerange"
            style="width: 400px;"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          />
        </el-form-item>
      </el-form-item>
      <el-form-item v-if="ruleForm.couponValidType === 0" :label="$t('卡券有效期(时)')" prop="couponExpired">
        <el-input v-model="ruleForm.couponExpired" style="width: 230px" maxlength="8" />
      </el-form-item>
      <el-form-item :label="$t('卡券库存')" prop="couponStockType">
        <el-radio-group v-model="ruleForm.couponStockType" v-removeAriaHidden>
          <el-radio :label="-1">无限</el-radio>
          <el-radio :label="0">设置库存</el-radio>
        </el-radio-group>
        <el-input v-if="ruleForm.couponStockType === 0" v-model="ruleForm.couponStock" style="width: 350px;margin-left: 20px;" maxlength="8" placeholder="库存数量"/>
      </el-form-item>
      <template v-if="ruleForm.couponType === 4">
        <el-form-item :label="$t('卡券面值')" prop="couponAmount">
          <el-input v-model="ruleForm.couponAmount" style="width: 350px" maxlength="11" />
        </el-form-item>
        <el-form-item :label="$t('提现打码量')" prop="couponStandardWithdrawRolling">
          <el-input type="text" v-model="ruleForm.couponStandardWithdrawRolling" maxlength="3" style="width: 350px">
            <template slot="append">倍</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('打码量稽查（使用标准）')" prop="couponStandardRolling">
          <el-input v-model="ruleForm.couponStandardRolling" :placeholder="$t('打码量稽查')" style="width: 350px" maxlength="8" />
        </el-form-item>
        <el-form-item :label="$t('指定打码平台')">
          <el-radio-group v-model="codingPlatform" v-removeAriaHidden>
            <el-radio :label="-1">无限制</el-radio>
            <el-radio :label="0">仅限以下勾选平台</el-radio>
          </el-radio-group>
        </el-form-item>
      </template>
      <template v-else-if="ruleForm.couponType === 5">
        <el-form-item :label="$t('卡券面值')" prop="couponAmount">
          <el-input v-model="ruleForm.couponAmount" style="width: 350px" maxlength="11" />
        </el-form-item>
        <el-form-item :label="$t('提现打码量')" prop="couponStandardWithdrawRolling">
          <el-input type="text" v-model="ruleForm.couponStandardWithdrawRolling" maxlength="3" style="width: 350px">
            <template slot="append">倍</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('使用标准：充值金额')" prop="couponStandardDepositAmount">
          <el-input v-model="ruleForm.couponStandardDepositAmount" :placeholder="$t('充值金额')" style="width: 350px" maxlength="11">
            <template slot="append">以上</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('打码量稽查')" prop="couponStandardRolling">
          <el-input v-model="ruleForm.couponStandardRolling" :placeholder="$t('打码量稽查')" style="width: 350px" maxlength="8" />
        </el-form-item>
        <el-form-item :label="$t('指定打码平台')">
          <el-radio-group v-model="codingPlatform" v-removeAriaHidden>
            <el-radio :label="-1">无限制</el-radio>
            <el-radio :label="0">仅限以下勾选平台</el-radio>
          </el-radio-group>
        </el-form-item>
      </template>
      <template v-else-if="ruleForm.couponType === 6">
        <el-form-item :label="$t('优惠面值：订单中奖加奖')" prop="discountAmount">
          <el-input v-model="ruleForm.discountAmount" style="width: 350px" maxlength="11">
            <template slot="append">倍</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('使用标准')">
          <el-form-item :label="$t('最小下注')" class="betAmount" prop="couponStandardMin">
            <el-input v-model="ruleForm.couponStandardMin" style="width: 130px" maxlength="8" />
          </el-form-item>
          <el-form-item :label="$t('最大下注')" class="betAmount" prop="couponStandardMax">
            <el-input v-model="ruleForm.couponStandardMax" style="width: 130px" maxlength="8" />
          </el-form-item>
        </el-form-item>
      </template>
      <el-form-item v-if="ruleForm.couponType === 6" :label="$t('使用方式')" prop="couponScopeMode">
        <el-select v-model="ruleForm.couponScopeMode">
          <el-option v-for="(val, key) in couponScopeModes" :key="key" :label="val" :value="+key" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.couponType === 3" :label="$t('使用方式')">
        <el-radio-group v-model="ruleForm.couponUsageMode" v-removeAriaHidden>
          <el-radio :label="0">游戏下注优惠</el-radio>
        </el-radio-group>
        <el-select v-model="ruleForm.couponScopeMode" style="margin-left: 20px;">
          <el-option v-for="(val, key) in couponScopeModes" v-show="+key !== 2" :key="key" :label="val" :value="+key" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.couponType === 3 || ruleForm.couponType === 6" :label="$t('适用范围')" prop="couponScope">
        <el-radio-group v-model="couponScope" v-removeAriaHidden>
          <el-radio :label="-1">无限制</el-radio>
          <el-radio :label="0">适用范围</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="([4,5].includes(ruleForm.couponType) && codingPlatform === 0) || ([3,6].includes(ruleForm.couponType) && couponScope === 0)" v-loading="loadGameListLoading">
        <el-tabs type="border-card">
          <el-tab-pane v-for="(item, index) in filterGameList" :key="index" :label="item.label">
            <el-collapse v-model="item.collapse" v-for="(item1, index1) in item.children" :key="index1">
              <el-collapse-item :name="item1.value">
                <template #title>
                  <el-checkbox :indeterminate="item1.indeterminate" v-model="item1.checked" @change="handleCheckedChange(item1)" autofocus>{{ item1.label }}</el-checkbox>
                </template>
                <template v-if="item1.children && item1.children.length > 0 && item.value === '1000005'">
                  <el-collapse v-model="item1.collapse" v-for="(item2, index2) in item1.children" :key="index2" style="margin-left: 20px;">
                    <el-collapse-item :name="item2.value">
                      <template #title>
                        <el-checkbox :indeterminate="item2.indeterminate" v-model="item2.checked" @change="handleCheckedChange(item2, item1)" autofocus>{{ item2.label }}</el-checkbox>
                      </template>
                      <div v-if="item2.children && item2.children.length > 0" style="margin-left: 20px;">
                        <el-checkbox v-model="item3.checked" v-for="(item3, index3) in item2.children" :key="index3" @change="handleCheckedChange(item3, item2, item1)" autofocus>{{ item3.label }}</el-checkbox>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </template>
                <div v-else style="margin-left: 20px;">
                  <el-checkbox v-model="item2.checked" @change="handleCheckedChange(item2, item1)" v-for="(item2, index2) in item1.children" :key="index2" autofocus>{{ item2.label }}</el-checkbox>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
      <el-form-item v-if="ruleForm.couponType === 5" :label="$t('适用范围')" prop="couponScope">
        <el-radio-group v-model="couponScope" v-removeAriaHidden>
          <el-radio :label="-1">无限制</el-radio>
          <el-radio :label="0">适用范围</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="couponScope === 0 && ruleForm.couponType === 5">
        <el-cascader
          v-model="couponScopeList"
          :options="payList"
          style="width: 350px"
          :props="{ multiple: true }"
          placeholder="输入搜索"
          collapse-tags
          filterable
          clearable
        />
      </el-form-item>
      <el-form-item :label="$t('备注')" prop="couponRemark">
        <el-input v-model="ruleForm.couponRemark" style="width: 350px" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="500" />
      </el-form-item>
      <el-form-item :label="$t('上架状态')" prop="couponStatus">
        <d-switch v-model="ruleForm.couponStatus" :activeValue="1" :inactiveValue="0" >
          <span slot='open'>上架</span>
          <span slot='close'>下架</span>
        </d-switch>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">{{ $t('关闭') }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">{{ $t('保存') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { mapState } from 'vuex'
import { integer0, integer2, numerical_0 } from '@/utils/validation.js'
import { couponTypes, couponScopeModes, couponGameList } from '@/libs/options'
import { navigationLotteryList, footballThirdMatchList } from '@/api/lottery'
import { getLotteryNavigationHot } from '@/api/config'
import { getMatchList } from '@/api/match'
import { getPayReceivingAccountList, getPayThirdAccountList } from '@/api/cash'
import dayjs from 'dayjs'

const getDefaultForm = () => ({
  couponType: 3,
  couponTitle: undefined,
  couponTitleList: [],
  couponDiscountType: 0,
  couponDiscountRate: undefined,
  couponAmount: undefined,
  discountAmount: undefined,
  couponStandard: undefined,
  couponValidType: 0,
  couponTime: [],
  couponExpired: undefined,
  couponStockType: -1,
  couponStock: undefined,
  couponUsageMode: 0,
  codingPlatform: -1,
  couponScopeMode: 0,
  couponScope: -1,
  couponScopeName: undefined,
  couponRemark: undefined,
  couponStatus: 0,
  couponStandardWithdrawRolling: undefined,
  couponStandardRolling: undefined,
  couponStandardDepositAmount: undefined,
  couponStandardMin: undefined,
  couponStandardMax: undefined
})
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      couponTypes,
      couponScopeModes,
      gameList: couponGameList,
      loading: false,
      loadGameList: false,
      loadGameListLoading: false,
      couponScope: -1,
      codingPlatform: -1,
      couponScopeList: [],
      payList: [{ value: '01', label: '公司账号', checked: false, children: []},{ value: '02', label: '第三方账号', checked: false, children: []}],
      rules: {
        couponType: [{ required: true, message: '请选择优惠类型', trigger: 'change' }],
        // couponTitle: [{ required: true, message: this.$t('请输入卡券名称'), trigger: 'change' }],
        couponAmount: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { pattern: integer2, message: '请输入大于0的数，最多2位小数', trigger: 'blur' }
        ],
        couponDiscountRate: [
          { required: true, message: this.$t('请设置折扣'), trigger: 'blur' },
          { pattern: numerical_0, message: this.$t('请输入大于0的数，最多1位小数'), trigger: 'blur' }
        ],
        couponStandard: [
          { required: true, message: '请输入最低投注金额', trigger: 'blur' },
          { pattern: integer0, message: '请输入大于0的整数', trigger: 'blur' }
        ],
        couponExpired: [
          { required: true, message: '请输入卡券有效期', trigger: 'blur' },
          { pattern: integer0, message: '请输入大于0的整数', trigger: 'blur' }
        ],
        couponStandardRolling: [
          { required: true, message: '请输入打码量稽查', trigger: 'blur' },
          { pattern: integer0, message: '请输入大于0的整数', trigger: 'blur' }
        ],
        couponStock: [
          { required: true, message: '请输入库存数量', trigger: 'blur' },
          { pattern: integer0, message: '请输入大于0的整数', trigger: 'blur' }
        ],
        couponStandardDepositAmount: [
          { required: true, message: '请输入充值金额', trigger: 'blur' },
          { pattern: integer0, message: '请输入大于0的整数', trigger: 'blur' }
        ],
        couponStandardWithdrawRolling: [
          { required: true, message: '请输入提现打码量', trigger: 'blur' },
          { pattern: integer0, message: '请输入大于0的整数', trigger: 'blur' }
        ],
        discountAmount: [
          { required: true, message: '请输入优惠面值', trigger: 'blur' },
          { pattern: numerical_0, message: '请输入大于0的数，最多1位小数', trigger: 'blur' }
        ],
        couponStandardMin: [
          { required: true, message: '请输入最小下注', trigger: 'blur' },
          { pattern: integer0, message: '请输入大于0的整数', trigger: 'blur' }
        ],
        couponStandardMax: [
          { required: true, message: '请输入最大下注', trigger: 'blur' },
          { pattern: integer0, message: '请输入大于0的整数', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState('app', ['allLang']),
    filterGameList() {
      if ([3,6].includes(this.ruleForm.couponType)) {
        if (this.ruleForm.couponScopeMode === 0) {
          return this.gameList.filter(e => e.value === '1000005')
        } else if (this.ruleForm.couponScopeMode === 1) {
          return this.gameList.filter(e => e.value === '2000002')
        } else if (this.ruleForm.couponScopeMode === 2) {
          return this.gameList.filter(e => e.value === '1000002')
        }
      }
      return this.gameList
    }
  },
  methods: {
    async open() {
      this.loading = true
      this.couponScope = -1
      this.codingPlatform = -1
      this.couponScopeList = []
      this.ruleForm.couponTitleList = this.allLang.map(e => { return { ...e, couponTitle: '' } })
      this.visible = true
      await this.getGameList()
      this.loading = false
    },
    async getGameList() {
      if (this.loadGameList) {
        this.gameList.forEach(e => {
          e.checked = false
          e.indeterminate = false
          e.collapse = []
          if (e.children) {
            e.children.forEach(v => {
              v.checked = false
              v.indeterminate = false
              v.collapse = []
              if (v.children) {
                v.children.forEach(o => {
                  o.checked = false
                  o.indeterminate = false
                  o.collapse = []
                })
              }
            })
          }
        })
        return
      }
      let list1 = []
      let list2 = []
      let list3 = []
      let list4 = []
      let list5 = []
      let list6 = []
      this.loadGameListLoading = true
      const [[items1, err1], [items2, err2], [items3, err3], [items4, err4], [items5, err5], [items6, err6]] = await Promise.all([navigationLotteryList(), 
      getMatchList({
        sportType: 1,
        currentPage: 1,
        pageSize: 200,
        startTime: dayjs().format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD'),
        type: 1,
        status: 1,
        tenantCode: this.$store.getters.tenantCode
      }),
      footballThirdMatchList({
        currentPage: 1,
        pageSize: 200,
        startMatchTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        endMatchTime: dayjs().format('YYYY-MM-DD 23:59:59'),
        sourceType: 1,
        sportType: 1,
        tenantCode: this.$store.getters.tenantCode
      }),
      getLotteryNavigationHot(), getPayReceivingAccountList({currentPage: 1, pageSize: 200, status: 0}), getPayThirdAccountList({currentPage: 1, pageSize: 200, status: 0})])
      this.loadGameList = true
      this.loadGameListLoading = false
      if (!err1) {
        list1 = items1
      }
      if (!err2) {
        const matchList = []
        items2.forEach(item => {
          let match = matchList.find(e => e.value === item.competitionName)
          if (!match) {
            match = {
              value: item.competitionName,
              label: item.competitionName,
              checked: false,
              indeterminate: false,
              collapse: [],
              children: []
            }
            matchList.push(match)
          }
          match.children.push({
            value: item.matchId,
            label: item.homeTeamName + ' VS ' + item.awayTeamName,
            checked: false,
            indeterminate: false,
            collapse: []
          })
        })
        list2 = matchList
      }
      if (!err3) {
        const matchList = []
        items3.forEach(item => {
          let match = matchList.find(e => e.value === item.competitionId)
          if (!match) {
            match = {
              value: item.competitionId,
              label: item.competitionNameZh,
              checked: false,
              indeterminate: false,
              collapse: [],
              children: []
            }
            matchList.push(match)
          }
          match.children.push({
            value: item.thirdMatchId,
            label: item.homeTeamNameZh + ' VS ' + item.awayTeamNameZh,
            checked: false,
            indeterminate: false,
            collapse: []
          })
        })
        list3 = matchList
      }
      if (!err4) {
        list4 = items4
      }
      if (!err5) {
        list5 = items5
      }
      if (!err6) {
        list6 = items6
      }
      this.gameList.forEach(e => {
        if (e.value === '1000005') {
          e.children = list1.map(v => {
            return {
              value: v.seriesTag,
              label: v.seriesName,
              checked: false,
              indeterminate: false,
              collapse: [],
              children: v.lotteryList.map(o => {
                return {
                  value: o.lotteryId,
                  label: o.name,
                  checked: false,
                  indeterminate: false,
                  collapse: [],
                  children: o.playClassList.map(p => {
                    return {
                      value: p.playClassTag,
                      label: p.playClassName,
                      checked: false,
                      indeterminate: false
                    }
                  })
                }
              })
            }
          })
        } else if (e.value === '2000002') {
          e.children = list2
        } else if (e.value === '1000002') {
          e.children = list3
        } else {
          e.children.forEach(v => {
            const item = list4.find(r => r.seriesTag === v.value)
            if (item) {
              v.label = item.seriesName
              v.checked = false
              v.indeterminate = false
              v.children = item.lotteryList.map(o => { 
                return {
                  value: o.lotteryId,
                  label: o.name,
                  checked: false,
                  indeterminate: false
                }
              })
            }
          })
        }
      })
      this.payList.forEach(e => {
        if (e.value === '01') {
          e.children = list5.map(e => {
            return {
              value: e.id,
              label: e.accountName
            }
          })
        } else if (e.value === '02') {
          e.children = list6.map(e => {
            return {
              value: e.id,
              label: e.paywayName
            }
          })
        }
      })
    },
    handleSubmitBefor() {
      if (this.ruleForm.couponValidType === 1) {
        if (!this.ruleForm.couponTime || this.ruleForm.couponTime.length === 0) {
          return this.$message.error('请选择卡券有效时间')
        }
        this.ruleForm.couponStartTime = this.ruleForm.couponTime[0]
        this.ruleForm.couponEndTime = this.ruleForm.couponTime[1]
      }

      if (this.ruleForm.couponType === 3 && this.ruleForm.couponDiscountType === 0) {
        const couponAmount = Number(this.ruleForm.couponAmount) || 0
        const couponStandard = Number(this.ruleForm.couponStandard) || 0
        if (couponStandard <= couponAmount) {
          return this.$message.error('使用标准必须大于指定金额')
        }
      }

      if (this.ruleForm.couponType === 5) {
        const couponAmount = Number(this.ruleForm.couponAmount) || 0
        const couponStandardDepositAmount = Number(this.ruleForm.couponStandardDepositAmount) || 0
        if (couponStandardDepositAmount <= couponAmount) {
          return this.$message.error('使用标准的充值金额必须大于卡券面值')
        }
      }

      this.ruleForm.couponScope = -1
      this.ruleForm.couponScopeName = undefined
      this.ruleForm.codingPlatform = -1
      this.ruleForm.codingPlatformName = undefined
      if (this.ruleForm.couponType === 5 && this.couponScope === 0) {
        if (this.couponScopeList.length === 0) {
          return this.$message.error('请选择适用范围')
        }
        const couponScopeArr = []
        const couponScopeNameArr = []
        this.couponScopeList.forEach(e => {
          const payParent = this.payList.find(v => v.value === e[0])
          const pay = payParent.children.find(v => v.value === e[1])
          const item = couponScopeArr.find(v => v.key === e[0])
          const itemName = couponScopeArr.find(v => v.key === e[0])
          if (item) {
            item.value.push(e[1])
            itemName.value.push(pay.label)
          } else {
            couponScopeArr.push({ key: e[0], value: [e[1]] })
            couponScopeNameArr.push({ key: e[0], label: payParent.label, value: [pay.label] })
          }
        })
        this.ruleForm.couponScope = couponScopeArr.map(v => { return v.key + ':' + v.value.toString() }).join(';')
        this.ruleForm.couponScopeName = couponScopeNameArr.map(v => { return v.label + ':' + v.value.toString() }).join(';')
      }

      if (([3,6].includes(this.ruleForm.couponType) && this.couponScope === 0) || ([4,5].includes(this.ruleForm.couponType) && this.codingPlatform === 0)) {
        const couponScopeArr = []
        const couponScopeNameArr = []
        this.gameList.forEach(e => {
          if ([3,6].includes(this.ruleForm.couponType)) {
            if (this.ruleForm.couponScopeMode === 0 && e.value === '1000005') {
              e.children.forEach(v => {
                v.children.forEach(o => {
                  const valueArr = []
                  if (o.checked) {
                    valueArr.push('-1')
                    couponScopeNameArr.push(`${o.label}-无限制`)
                  } else if (o.indeterminate) {
                    o.children.forEach(p => {
                      if (p.checked) {
                        valueArr.push(p.value)
                        couponScopeNameArr.push(`${e.label},${v.label},${o.label},${p.label}`)
                      }
                    })
                  }
                  if (valueArr.length > 0) {
                    couponScopeArr.push(o.value + ':' + valueArr.toString())
                  }
                })
              })
              if (couponScopeArr.length > 0) {
                this.ruleForm.couponScope = couponScopeArr.join(';')
              }
            } else if (this.ruleForm.couponScopeMode === 1 && e.value === '2000002') {
              e.children.forEach(v => {
                v.children.forEach(o => {
                  if (o.checked > 0) {
                    couponScopeArr.push(o.value)
                    couponScopeNameArr.push(`${e.label},${v.label},${o.label}`)
                  }
                })
              })
              if (couponScopeArr.length > 0) {
                this.ruleForm.couponScope = couponScopeArr.toString()
              }
            } else if (this.ruleForm.couponScopeMode === 2 && e.value === '1000002') {
              e.children.forEach(v => {
                v.children.forEach(o => {
                  if (o.checked > 0) {
                    couponScopeArr.push(o.value)
                    couponScopeNameArr.push(`${e.label},${v.label},${o.label}`)
                  }
                })
              })
              if (couponScopeArr.length > 0) {
                this.ruleForm.couponScope = couponScopeArr.toString()
              }
            }
            if (couponScopeNameArr.length > 0) {
              this.ruleForm.couponScopeName = couponScopeNameArr.join(';')
            }
          } else {
            if (e.value === '1000005') {
              const lotteryScopeArr = []
              e.children.forEach(v => {
                v.children.forEach(o => {
                  const valueArr = []
                  o.children.forEach(p => {
                    if (p.checked) {
                      valueArr.push(p.value)
                      couponScopeNameArr.push(`${e.label},${v.label},${o.label},${p.label}`)
                    }
                  })
                  if (valueArr.length > 0) {
                    lotteryScopeArr.push(o.value + ':' + valueArr.toString())
                  }
                })
              })
              couponScopeArr.push(e.value + '*' + lotteryScopeArr.join(';'))
            } else if (['2000002','1000002'].includes(e.value)) {
              const sportScopeArr = []
              e.children.forEach(v => {
                v.children.forEach(o => {
                  if (o.checked > 0) {
                    sportScopeArr.push(e.value + '*' + o.value)
                    couponScopeNameArr.push(`${e.label},${v.label},${o.label}`)
                  }
                })
              })
              couponScopeArr.push(e.value + '*' + sportScopeArr.toString())
            } else {
              const gameScopeArr = []
              e.children.forEach(v => {
                const valueArr = []
                v.children.forEach(o => {
                  if (o.checked > 0) {
                    valueArr.push(o.value)
                    couponScopeNameArr.push(`${e.label},${v.label},${o.label}`)
                  }
                })
                gameScopeArr.push(v.value + ':' + valueArr.toString())
              })
              couponScopeArr.push(e.value + '*' + gameScopeArr.join(';'))
            }
            if (couponScopeArr.length > 0) {
              this.ruleForm.codingPlatform = couponScopeArr.join('@')
            }
            if (couponScopeNameArr.length > 0) {
              this.ruleForm.codingPlatformName = couponScopeNameArr.join(';')
            }
          }
        })
        if (!this.ruleForm.couponScope) {
          return this.$message.error('请选择适用范围')
        }
      }
      this.handleSubmit()
    },
    handleCheckedChange(item, itemParent, itemParentParent) {
      if (item.children) {
        item.indeterminate = false
        item.children.forEach(v => {
          v.checked = item.checked
          v.indeterminate = false
          if (v.children) {
            v.children.forEach(o => {
              o.checked = item.checked
              o.indeterminate = false
            })
          }
        })
      }
      if (itemParent) {
        itemParent.checked = itemParent.children.every(e => e.checked)
        itemParent.indeterminate = itemParent.children.some(e => e.checked) && !itemParent.children.every(e => e.checked)
      }
      if (itemParentParent) {
        itemParentParent.checked = itemParentParent.children.every(e => e.checked)
        itemParentParent.indeterminate = itemParentParent.children.some(e => e.checked || e.indeterminate) && !itemParentParent.children.every(e => e.checked)
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss">
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.star {
  position: absolute;
  left: -83px;
  color: #ff4949;
}
.amount {
  position: absolute;
  width: 145px;
  top: -33px;
  margin-left: 205px;
  &.discount {
    width: 125px;
  }
  + span {
    position: absolute;
    right: 55px;
    top: -33px;
  }
}

.rechargeAmount {
  label {
    width: 176px !important;
  }
}

.couponAwards {
  label {
    width: 217px !important;
  }
}

.betAmount {
  label {
    width: auto !important;
    margin-bottom: 18px !important;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
  .el-form-item__error {
    top: 67%;
  }
}

.lang-pop {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
