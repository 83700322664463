import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'

import { passRouter } from '@/utils/permission'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/401', '/404'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  NProgress.start()

  document.title = getPageTitle(to.meta.title)

  const hasToken = getToken()
  if (whiteList.indexOf(to.path) !== -1) { // 如果菜单获取不到 避免死循环
    next()
  } else if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      const hasRoles = store.getters.roles && store.getters.roles.length > 0

      if (hasRoles) {
        next()
      } else {
        try {
          const rules = await store.dispatch('user/getInfo')
          if (store.getters.tenantCode === 'DOMAINQR') {
            // 替换路由
            to.path == '/qr-mgr' ? next() : next('/qr-mgr')
            return
          }
          if (rules) {
            const accessRoutes = await store.dispatch('permission/generateRoutes', rules)
            router.addRoutes(accessRoutes)
            if (to.path === '/dashboard') {
              next({ path: store.getters.homeRoute, replace: true })
            } else {
              if (!passRouter(to.path, accessRoutes)) {
                next('/401?noGoBack=Yes')
              } else {
                next({ ...to, replace: true })
              }
            }
          } else {
            // await store.dispatch('user/logout')
            next('/404')
          }
        } catch (error) {
          // remove token and go to login page to re-login
          console.log(error)
          await store.dispatch('user/logout')
          next(`/login`)
          // next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login`)
      // next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
