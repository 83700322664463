var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "tab-header",
          attrs: { flex: "main:justify cross:bottom" },
        },
        [
          _vm.permission.Send
            ? _c(
                "span",
                _vm._l(_vm.xsLottery, function (val, key) {
                  return _c(
                    "el-button",
                    {
                      key: key,
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onRelease(key)
                        },
                      },
                    },
                    [_vm._v(_vm._s(val) + "发帖")]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "el-button-group",
            _vm._l(_vm.orderStatus, function (item) {
              return _c(
                "el-button",
                {
                  key: item.value,
                  attrs: {
                    size: "small",
                    type: (_vm.search.order === item.value && "primary") || "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleOrderStatus(item.value)
                    },
                  },
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            1
          ),
          _c(
            "el-button-group",
            _vm._l(_vm.buttonStatus, function (item) {
              return _c(
                "el-button",
                {
                  key: item.value,
                  attrs: {
                    size: "small",
                    type: (_vm.search[item.value] === 1 && "primary") || "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleViewStatus(item.value)
                    },
                  },
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            1
          ),
          _vm.permission.Lock
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "180px" },
                  attrs: { type: "danger" },
                  on: { click: _vm.onLockALL },
                },
                [_vm._v("一键锁帖")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-container-item" },
          [
            _c("el-date-picker", {
              staticClass: "filter-item",
              staticStyle: { width: "400px" },
              attrs: {
                type: "datetimerange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                "value-format": "timestamp",
                "default-time": ["00:00:00", "23:59:59"],
                "picker-options": _vm.pickerOptions,
              },
              model: {
                value: _vm.times,
                callback: function ($$v) {
                  _vm.times = $$v
                },
                expression: "times",
              },
            }),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { placeholder: "标题", clearable: "" },
              model: {
                value: _vm.search.title,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "title", $$v)
                },
                expression: "search.title",
              },
            }),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { placeholder: "账号", clearable: "" },
              model: {
                value: _vm.search.memberName,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "memberName", $$v)
                },
                expression: "search.memberName",
              },
            }),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { placeholder: "昵称", clearable: "" },
              model: {
                value: _vm.search.nickname,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "nickname", $$v)
                },
                expression: "search.nickname",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-container-item" },
          [
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "140px" },
                attrs: { placeholder: "用户头衔", clearable: "" },
                model: {
                  value: _vm.search.rank,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "rank", $$v)
                  },
                  expression: "search.rank",
                },
              },
              _vm._l(_vm.$parent.ranks, function (item) {
                return _c("el-option", {
                  key: item.createTime,
                  attrs: {
                    label: item.rankName,
                    value: item.minAdmire + "-" + item.maxAdmire,
                  },
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "140px" },
                attrs: { placeholder: "数据来源", clearable: "" },
                model: {
                  value: _vm.search.source,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "source", $$v)
                  },
                  expression: "search.source",
                },
              },
              [
                _c("el-option", { attrs: { label: "IOS用户", value: "IOS" } }),
                _c("el-option", {
                  attrs: { label: "ANDROID用户", value: "ANDROID" },
                }),
                _c("el-option", { attrs: { label: "H5用户", value: "H5" } }),
                _c("el-option", { attrs: { label: "Web用户", value: "WEB" } }),
              ],
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "140px" },
                attrs: { placeholder: "状态", clearable: "" },
                model: {
                  value: _vm.search.lockStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "lockStatus", $$v)
                  },
                  expression: "search.lockStatus",
                },
              },
              [
                _c("el-option", { attrs: { label: "未锁帖", value: 0 } }),
                _c("el-option", { attrs: { label: "锁帖", value: 1 } }),
              ],
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "140px" },
                attrs: { placeholder: "类别", clearable: "" },
                model: {
                  value: _vm.search.lotteryId,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "lotteryId", $$v)
                  },
                  expression: "search.lotteryId",
                },
              },
              _vm._l(_vm.xsLottery, function (val, key) {
                return _c("el-option", {
                  key: key,
                  attrs: { value: key, label: val },
                })
              }),
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "success", icon: "el-icon-search" },
                on: { click: _vm.handleSearch },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "success", icon: "el-icon-refresh-right" },
                on: { click: _vm.resetSearch },
              },
              [_vm._v(_vm._s(_vm.$t("重置")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "d-table-container" },
        [
          _c(
            "div",
            {
              staticClass: "d-table-header",
              attrs: { flex: "main:justify cross:center" },
            },
            [
              _c("div", [
                _vm.permission.BatchHiden
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "el-button el-button--small el-button--orange",
                        attrs: { disabled: !_vm.selection.length },
                        on: { click: _vm.handleBatchHidden },
                      },
                      [_vm._v("批量隐藏")]
                    )
                  : _vm._e(),
                _vm.permission.BatchLock
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "el-button el-button--small el-button--orange",
                        attrs: { disabled: !_vm.selection.length },
                        on: {
                          click: function ($event) {
                            return _vm.handleBatchLimit1({ lockStatus: 0 })
                          },
                        },
                      },
                      [_vm._v("批量锁帖")]
                    )
                  : _vm._e(),
                _vm.permission.BatchLimit
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "el-button el-button--small el-button--orange",
                        attrs: { disabled: !_vm.selection.length },
                        on: { click: _vm.handleBatchLimit },
                      },
                      [_vm._v("批量限制")]
                    )
                  : _vm._e(),
                _vm.permission.BatchLock
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "el-button el-button--small el-button--orange",
                        attrs: { disabled: !_vm.selection.length },
                        on: {
                          click: function ($event) {
                            return _vm.handleBatchLimit1({ lockStatus: 1 })
                          },
                        },
                      },
                      [_vm._v("批量解锁")]
                    )
                  : _vm._e(),
              ]),
              _c("div"),
            ]
          ),
          _c(
            "el-table",
            _vm._b(
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { data: _vm.items, "selection-row": "" },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              "el-table",
              _vm.options,
              false
            ),
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "50", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "nickname",
                  label: "发帖人",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("p", [
                          _vm._v(
                            "[" + _vm._s(_vm.xsLottery[row.lotteryId]) + "]"
                          ),
                        ]),
                        _c("span", { staticClass: "primary-type" }, [
                          _vm._v(_vm._s(row.nickname)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  label: "标题",
                  "header-align": "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          { staticStyle: { "word-break": "break-word" } },
                          [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "line-height": "24px",
                                  "white-space": "pre-wrap",
                                },
                              },
                              [_vm._v(_vm._s(row.title))]
                            ),
                            _c(
                              "div",
                              { attrs: { flex: "main:justify cross:center" } },
                              [
                                _c(
                                  "span",
                                  [
                                    row.godTypeId
                                      ? _c(
                                          "el-tag",
                                          {
                                            staticStyle: {
                                              "border-style": "dashed",
                                              "background-color": "transparent",
                                            },
                                            attrs: {
                                              type: "warning",
                                              effect: "plain",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.parseTypeName(row.godTypeId)
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    row.rankName
                                      ? _c(
                                          "el-tag",
                                          {
                                            staticStyle: {
                                              "border-style": "dashed",
                                              "background-color": "transparent",
                                            },
                                            attrs: {
                                              type: "success",
                                              effect: "plain",
                                            },
                                          },
                                          [_vm._v(_vm._s(row.rankName))]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("span", { staticStyle: { color: "#ccc" } }, [
                                  _vm._v("ID: " + _vm._s(row.id)),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  width: "160",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("parseTime")(row.createTime))),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "viewNum",
                  label: "浏览数",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.lockStatus === 1 || !_vm.permission.Add
                          ? [_vm._v(_vm._s(row.viewNum))]
                          : _c("el-input-number", {
                              staticStyle: { width: "110px" },
                              attrs: {
                                size: "small",
                                "controls-position": "right",
                                min: 0,
                                "step-strictly": "",
                              },
                              on: {
                                blur: function () {
                                  return _vm.handleUpdateViewAndAdmire(row)
                                },
                              },
                              model: {
                                value: row.viewNum,
                                callback: function ($$v) {
                                  _vm.$set(row, "viewNum", $$v)
                                },
                                expression: "row.viewNum",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "admireNum",
                  label: "点赞数",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.lockStatus === 1 || !_vm.permission.Add
                          ? [_vm._v(_vm._s(row.admireNum))]
                          : _c("el-input-number", {
                              staticStyle: { width: "110px" },
                              attrs: {
                                size: "small",
                                "controls-position": "right",
                                min: 0,
                                "step-strictly": "",
                              },
                              on: {
                                blur: function () {
                                  return _vm.handleUpdateViewAndAdmire(row)
                                },
                              },
                              model: {
                                value: row.admireNum,
                                callback: function ($$v) {
                                  _vm.$set(row, "admireNum", $$v)
                                },
                                expression: "row.admireNum",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  width: "340",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          { staticClass: "recommend-status" },
                          [
                            [
                              row.freezeStatus === 1
                                ? [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("禁言")]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            row.topViewStatus === 1
                              ? [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("精华")]
                                  ),
                                ]
                              : _vm._e(),
                            row.topSort < 4
                              ? [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("置顶")]
                                  ),
                                ]
                              : _vm._e(),
                            [
                              row.lockStatus == 1
                                ? [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("锁定")]
                                    ),
                                  ]
                                : [_c("span", [_vm._v("未锁")])],
                            ],
                            _c(
                              "span",
                              [
                                [
                                  row.loginViewStatus === 1
                                    ? _c(
                                        "p",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("登录可见")]
                                      )
                                    : _c("p", [
                                        _c("span", [_vm._v("非登录可见")]),
                                      ]),
                                ],
                                [
                                  row.replyViewStatus === 1
                                    ? _c(
                                        "p",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("回复可见")]
                                      )
                                    : _c("p", [
                                        _c("span", [_vm._v("非回复可见")]),
                                      ]),
                                ],
                              ],
                              2
                            ),
                            _c(
                              "span",
                              [
                                [
                                  [
                                    row.payViewStatus === 1
                                      ? _c(
                                          "p",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("充值可见")]
                                        )
                                      : _c("p", [
                                          _c("span", [_vm._v("非充值可见")]),
                                        ]),
                                  ],
                                  [
                                    row.vipViewLevel > 0
                                      ? _c(
                                          "p",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _vm._v(
                                              "VIP" +
                                                _vm._s(row.vipViewLevel) +
                                                "以上可见"
                                            ),
                                          ]
                                        )
                                      : _c("p", [
                                          _c("span", [
                                            _vm._v("VIP等级不限可见"),
                                          ]),
                                        ]),
                                  ],
                                ],
                              ],
                              2
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "actions",
                  label: "操作",
                  width: "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-popover",
                          {
                            ref: "popover-" + row.id,
                            attrs: { trigger: "hover", placement: "top-end" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "pointer",
                                staticStyle: { padding: "0 5px" },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]
                            ),
                            _c("div", { staticClass: "vertical-popover" }, [
                              _vm.permission.Update
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "vertical-popover__item",
                                      on: {
                                        click: function ($event) {
                                          return _vm._handleClosePopover(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "RecommendEdit",
                                              query: {
                                                id: row.id,
                                                lotteryId: row.lotteryId,
                                                type: row.type,
                                                code: undefined,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-edit",
                                          }),
                                          _vm._v("修改帖子 "),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.permission.History
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vertical-popover__item vertical-popover__item--divided",
                                      on: {
                                        click: function ($event) {
                                          return _vm._handleClosePopover(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "RecommendHistory",
                                              query: {
                                                id: row.memberId,
                                                name: row.nickname,
                                                tenantCode: undefined,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-discover",
                                          }),
                                          _vm._v("查看历史 "),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.permission.Top
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vertical-popover__item vertical-popover__item--divided",
                                      on: {
                                        click: function () {
                                          return _vm.handleUpdateTop(row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-upload2",
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          row.topViewStatus === 0
                                            ? "加精帖子"
                                            : "取消加精"
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.permission.Top
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vertical-popover__item vertical-popover__item--divided",
                                      on: {
                                        click: function () {
                                          return _vm.handlePostTop(row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-upload2",
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          row.topSort <= 3
                                            ? "取消置顶"
                                            : "置顶帖子"
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.permission.Hiden
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vertical-popover__item vertical-popover__item--divided",
                                      on: {
                                        click: function () {
                                          return _vm.handleUpdateHide(row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-delete-solid",
                                      }),
                                      _vm._v("隐藏贴子"),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.permission.Limit
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vertical-popover__item vertical-popover__item--divided",
                                      on: {
                                        click: function () {
                                          return _vm.handleBatchLimit(row)
                                        },
                                      },
                                    },
                                    [
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-warning-outline",
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            ["彩讯禁言", "解除禁言"][
                                              row.freezeStatus
                                            ]
                                          ) + " "
                                        ),
                                      ],
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.permission.Limit
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vertical-popover__item vertical-popover__item--divided",
                                      on: {
                                        click: function () {
                                          return _vm.handleBatchLimit1(row)
                                        },
                                      },
                                    },
                                    [
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-lock",
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            ["锁帖禁评", "解锁评论"][
                                              row.lockStatus || 0
                                            ]
                                          ) + " "
                                        ),
                                      ],
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("LockDialog", {
        ref: "LockDialogControl",
        on: { update: _vm._handleUpdateLock },
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }