export default {
  data: () => ({
    times: [],
    search: {},
    summary: null,
    visible: false
  }),
  // computed: {
  //   pickerOptions() {
  //     if (this.summary) {
  //       const { offlineDate } = this.summary
  //       return {
  //         disabledDate(date) {
  //           return date.getTime() > offlineDate
  //         }
  //       }
  //     } else {
  //       return null
  //     }
  //   }
  // },
  methods: {
    open({ mergeRoomId, onlineDate, offlineDate, onlineRoomId }) {
      this.times = [this.$parseTime(onlineDate), this.$parseTime(offlineDate)]
      this.search.mergeRoomId = mergeRoomId
      this.search.onlineRoomId = onlineRoomId
      this.visible = true
    },
    close() {
      this.visible = false
    },
    implementationGetParams() {
      const [startTime, endTime] = this.times || []
      return {
        ...this.search,
        startTime,
        endTime
      }
    }
  }
}
