<template>
  <el-dialog
    :title="diaTitle"
    :visible.sync="visible"
    width="800px"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      label-suffix=":"
    >
      <el-form-item label="聊天室分类" required prop="roomType">
        <el-select
          class="filter-item"
          v-model="ruleForm.roomType"
          placeholder="请选择聊天室类型"
          @change="_getChatRoomList"
        >
          <el-option
            v-for="item in roomTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="聊天室名称" prop="roomId">
        <el-select
          class="filter-item"
          v-model="ruleForm.roomId"
          placeholder="请选择聊天室名称"
          @change="_changeRoom"
          :key="componentKey"
        >
          <el-option
            v-for="item in roomIdList"
            :key="item.roomId"
            :label="item.roomName"
            :value="item.roomId"
          ></el-option>
        </el-select>
        <div class="title">
          提示：只选择聊天室分类不选择聊天室时默认添加分类下所有聊天室
        </div>
        <el-button type="primary" @click="add">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="roomList" style="width: 100%" max-height="250">
      <el-table-column prop="roomTypeName" label="聊天室类型" width="250">
      </el-table-column>
      <el-table-column prop="roomName" label="聊天室名称" width="250">
      </el-table-column>
      <el-table-column prop="opretor" label="操作">
        <template slot-scope="scope">
          <el-button @click="goOut(scope.row)" type="text" size="small"
            >踢出</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close"> 取消 </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from "@/mixins/form-dialog";
import { getLiveRoomList, getDetailRoom, AIsaveChatRoom } from "@/api/chat";
import DUpload from "@/components/DUpload";
const getDefaultForm = () => ({
  roomType: "",
  roomeId: "",
});

export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      diaTitle: "聊天室管理",
      componentKey:0,
      ruleForm: {
        roomType: "",
        roomId: "",
        roomTypeName: "",
        roomIdName: "",
      },
      roomIdList: [],
      roomList: [],
      assistantId: "",
      roomTypeList: [
        { label: "互动大厅", value: 1, type: "lottery" },
        { label: "赛事-滚球", value: 2, type: "sport" },
        { label: "赛事-竞彩", value: 5, type: "sport" },
        { label: "热门彩种", value: 3, type: "lottery" },
        { label: "直播", value: 4, type: "live" },
      ],
      prefixMap: {
        1: "interactive_",
        2: "sportrolling_",
        5: "sportlottery_",
        3: "hotlottery_",
        4: "live_",
      },
      rules: {
        roomType: [
          { required: true, message: "请选择聊天室类型", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    ruleForm(val) {
      if (val.roomType == "") {
        this.roomIdList = [];
      }
    },
  },
  methods: {
    open(row) {
      console.log(row);
      this.assistantId = row.id;
      const params = { assistantId: row.id };
      getDetailRoom(params).then((res) => {
        if (res[2].status == 200) {
          this.roomList = res[2].data.items;
        }
      });
      this.ruleForm.roomType = "";
      this.ruleForm.roomId = "";
      this.roomList = [];
      this.roomIdList = [];
      this.visible = true;
    },

    // 获取聊天室列表
    async _getChatRoomList(value) {
      getLiveRoomList({ roomType: value }).then((res) => {
        if (res[0]) {
          this.roomIdList = res[0] || [];
          this.ruleForm.roomId = "";
        }
      });
    },
    _changeRoom(val){
      // 不清楚为什么组件不自动更新
      this.ruleForm.roomeId = val
      this.componentKey++
    },
    // 添加
    add() {
      if (!this.ruleForm.roomType) {
        this.$message.error("请选择聊天室类型");
        return;
      }
      const index = this.roomList.findIndex(item => this.ruleForm.roomId == item.roomId)
      if (index > -1) {
        this.$message.error("已添加聊天室类型");
        return;
      }
      const item = {
        roomType: "",
        roomTypeName: "",
        roomId: "",
        roomName: "",
        roomTypePrefix: "",
      };
      item.roomType = this.ruleForm.roomType;
      item.roomTypeName = this.roomTypeList.filter((y) => {
        return y.value == this.ruleForm.roomType;
      })[0].label;
      item.roomTypePrefix = this.prefixMap[item.roomType];
      // 只选聊天室类型不选聊天室,为全选
      if (this.ruleForm.roomType && !this.ruleForm.roomId) {
        item.roomId = 0;
        item.roomName = "-";
        this.roomList.push(item);
       } else if (this.ruleForm.roomType && this.ruleForm.roomId) {
        item.roomId = this.ruleForm.roomId;
        item.roomName = this.roomIdList.filter((y) => {
          return y.roomId == this.ruleForm.roomId;
        })[0].roomName;
        this.roomList.push(item);
      }
      console.log(this.roomList)
    },
    // 踢出
    goOut(row) {
      console.log(row);
      this.roomList.map((x, index) => {
        if (x.roomId == row.roomId) {
          this.roomList.splice(index, 1);
        }
      });
    },
    // 保存
    handleSubmit() {
      AIsaveChatRoom({
        assistantId: this.assistantId,
        chatRoomRequests: this.roomList,
      }).then((res) => {
        console.log(res);
        if (res[2].status == 200) {
          this.$emit("update");
          this.visible = false;
        }
      });
    },
  },
};
</script>
