const CanChooseColumns = function() {
  return [
    {
      title: this.$t('线路名'),
      key: 'servername',
      align: 'center',
      width: 100
    },
    {
      title: this.$t('推流域名'),
      key: 'serverurl',
      align: 'center'
    },
    {
      title: this.$t('推流鉴权KEY(主)'),
      key: 'primarykey',
      align: 'center'
    },
    {
      title: this.$t('推流鉴权KEY(备)'),
      key: 'backupkey',
      align: 'center'
    },
    {
      title: this.$t('播放域名'),
      key: 'familyname',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <el-link type='primary' onClick={() => this.$refs.ViewDialogControl.open(row)}>{this.$t('点击查看')}</el-link>
          )
        }
      }
    },
    {
      title: this.$t('创建时间'),
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作信息'),
      key: 'message',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{this.$t('操作人')}：{ row.operatorName }</p>
              <p>{ this.$parseTime(row.updateTime) }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('启用'),
      key: 'status',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.status}
              active-value={true}
              inactive-value={false}
              size='default'
              disabled={!this.permission.StartStop}
              beforeChange={this._handleChangeStatus.bind(this, row)}>
              <span slot='open' />
              <span slot='close' />
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 280,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission
          return (
            <span>
              <el-button
                type='warning'
                size='mini'
                onClick={this.$refs.ActionPlayDialogControl.open.bind(this, row, 'create', null)}>{this.$t('添加播放')}</el-button>
              { Update &&
                <el-button
                  type='primary'
                  size='mini'
                  icon='el-icon-edit-outline'
                  onClick={() => this.$refs.ActionDialogControl.open(row)}>{this.$t('编辑')}</el-button> }
              { Delete &&
                <el-button
                  type='danger'
                  size='mini'
                  icon='el-icon-delete'
                  onClick={this._handleDelete.bind(this, row)}>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
