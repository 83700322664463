import request from '@/plugins/axios'

export function getTenantList(params) {
  return request({
    url: '/platform/tenant/List',
    method: 'get',
    params
  })
}

export function addTenant(data) {
  return request({
    url: '/platform/tenant/add',
    method: 'post',
    data
  })
}

export function updateBlackStatus(data) {
  return request({
    url: '/platform/tenant/blackStatus',
    method: 'post',
    data
  })
}

export function updateDivideIntoConf(data) {
  return request({
    url: '/platform/tenant/divideIntoConf',
    method: 'post',
    data
  })
}

export function updateStatus(data) {
  return request({
    url: '/platform/tenant/status',
    method: 'post',
    data
  })
}

export function updateConfig(data) {
  return request({
    url: '/platform/tenant/updateConfig',
    method: 'post',
    data
  })
}

// 新增租户 查询平台所有的彩种 和游戏系列
export function getLotteryStatusList(params = {}) {
  return request({
    url: '/platform/lottery/game/status/list',
    method: 'get',
    params
  })
}

export function getBaseConfig(params = {}) {
  return request({
    url: '/platform/tenant/getBaseConfig',
    method: 'get',
    params
  })
}

// 获取彩种开关状态
export function getSettingLotteryStatusList(params = {}) {
  return request({
    url: '/platform/lottery/game/list',
    method: 'get',
    params
  })
}
export function getTenantInfo(params = {}) {
  return request({
    url: '/platform/tenant/info/query',
    method: 'get',
    params
  })
}

// 租户报表
export function getTenantReportList(data) {
  return request({
    url: '/report/reportTenant/list',
    method: 'post',
    data
  })
}

export function confirmReceipt(data) {
  return request({
    url: '/report/reportTenant/confirmReceipt',
    method: 'post',
    data
  })
}

export function updateReport(data) {
  return request({
    url: '/report/reportTenant/update',
    method: 'post',
    data
  })
}

/* 平台短消息 ***************************************************************************************/
// 查询平台公告列表
export function getNoticeList(data = {}) {
  return request({
    url: '/platform/notice/list',
    method: 'post',
    data
  })
}

// 修改平台公告信息
export function updateNotice(data = {}) {
  return request({
    url: '/platform/notice/update',
    method: 'post',
    data
  })
}

// 删除平台公告
export function deleteNotice(data = {}) {
  return request({
    url: '/platform/notice/delete',
    method: 'post',
    data
  })
}

// 发送平台公告
export function createNotice(data = {}) {
  return request({
    url: '/platform/notice/create',
    method: 'post',
    data
  })
}

// 查看平台公告详情
export function getNoticeDetail(params = {}) {
  return request({
    url: '/platform/notice/detail',
    method: 'get',
    params
  })
}

// 平台：获取租户组列表
export function tenantGroupList(data) {
  return request({
    url: '/platform/tenant/group/list',
    method: 'post',
    data
  })
}

// 平台：创建租户分组
export function tenantGroupCreate(data) {
  return request({
    url: '/platform/tenant/group/create',
    method: 'post',
    data
  })
}

// 平台：修改租户分组
export function tenantUpdateGroupInfo(data) {
  return request({
    url: '/platform/tenant/updateGroupInfo',
    method: 'post',
    data
  })
}

// 平台：删除租户分组
export function tenantGroupDelete(params) {
  return request({
    url: '/platform/tenant/group/delete',
    method: 'get',
    params
  })
}

// 租户、获取同组租户列表、响应数据包括当前租户
export function groupTenantList(params) {
  return request({
    url: '/tenant/group/tenant/list',
    method: 'get',
    params
  })
}

// 修改租户组别
export function tenantUpdateGroup(data) {
  return request({
    url: '/platform/tenant/updateGroup',
    method: 'post',
    data
  })
}
