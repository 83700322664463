var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chat-room",
      class: {
        active_bg: _vm.activeNickname == _vm.item.nickname ? true : false,
        check: _vm.item.check,
      },
    },
    [
      _c(
        "div",
        [_c("el-avatar", { attrs: { size: 48, src: _vm.item.avatar } })],
        1
      ),
      _c(
        "div",
        { staticClass: "chat-room-info", on: { click: _vm._enterRoom } },
        [
          _c("div", { staticClass: "chat-room-title" }, [
            _vm._v(" " + _vm._s(_vm.item.nickname) + " "),
          ]),
          _c("div", { staticClass: "chat-room-descs single-line-overflow" }, [
            _vm._v(" " + _vm._s(_vm.cardPreviw) + " "),
          ]),
        ]
      ),
      _vm.item.messageSize != 0
        ? _c("div", { staticClass: "char-room-bottoms" }, [
            _c("div", { staticClass: "chat-room-enters" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.item.messageSize > 99 ? "99+" : _vm.item.messageSize
                  ) +
                  " "
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }