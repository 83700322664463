<template>
  <el-dialog
    title="发布新版本"
    width="700px"
    :visible.sync="visible"
    @closed="handleClosed"
    top="5vh"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      label-width="130px"
    >
      <el-form-item label="发布平台" prop="appType">
        <el-radio-group v-model="ruleForm.appType">
          <el-radio :label="1">Android版</el-radio>
          <el-radio :label="2">IOS版</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="版本名称" prop="versionName">
        <el-input v-model="ruleForm.versionName" maxlength="10" show-word-limit placeholder="请输入版本名称" />
      </el-form-item>
      <el-form-item label="版本号" prop="version">
        <el-input v-model="ruleForm.version" placeholder="请输入版本号" />
      </el-form-item>
      <el-form-item label="文件大小" prop="appSize">
        <el-input v-model="ruleForm.appSize" placeholder="请输入文件大小">
          <template slot="append">MB</template>
        </el-input>
      </el-form-item>
      <el-form-item label="娱乐版下载地址" prop="appDownurl">
        <el-input v-model="ruleForm.appDownurl" placeholder="请输入下载地址">
          <template slot="append">
            <el-upload
              class="app-uploader"
              :action="action"
              :data="{ type: 'apk', folder: 'apk' }"
              :headers="$headers()"
              :show-file-list="false"
              :on-progress="onProgressUpload"
              :on-success="onSuccessUpload"
            >
              <el-button icon="el-icon-upload" :loading="uploading" />
            </el-upload>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item label="体育版下载地址" prop="sportsAppDownurl">
        <el-input v-model="ruleForm.sportsAppDownurl" placeholder="请输入下载地址">
          <template slot="append">
            <el-upload
              class="app-uploader"
              :action="action"
              :data="{ type: 'apk', folder: 'apk' }"
              :headers="$headers()"
              :show-file-list="false"
              :on-progress="onProgressUpload"
              :on-success="onSportsSuccessUpload"
            >
              <el-button icon="el-icon-upload" :loading="uploading" />
            </el-upload>
          </template>
        </el-input>
      </el-form-item>
      
      <el-form-item label="曾道人版下载地址" prop="zdrAppDownurl">
        <el-input v-model="ruleForm.zdrAppDownurl" placeholder="请输入下载地址">
          <template slot="append">
            <el-upload
              class="app-uploader"
              :action="action"
              :data="{ type: 'apk', folder: 'apk' }"
              :headers="$headers()"
              :show-file-list="false"
              :on-progress="onProgressUpload"
              :on-success="onZdrSuccessUpload"
            >
              <el-button icon="el-icon-upload" :loading="uploading" />
            </el-upload>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item label="强制更新" prop="force">
        <el-radio-group v-model="ruleForm.force">
          <el-radio :label="0">是</el-radio>
          <el-radio :label="9">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发布时间">
        <div flex="cross:center">
          <el-form-item prop="publishStatus">
            <el-radio-group v-model="ruleForm.publishStatus">
              <el-radio :label="1">立即发布</el-radio>
              <el-radio :label="2">定时发布</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.publishStatus === 2" prop="publishTime" style="margin-left: 20px">
            <el-date-picker
              v-model="ruleForm.publishTime"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="版本说明" prop="versionInfo">
        <el-input
          v-model="ruleForm.versionInfo"
          type="textarea"
          :rows="5"
          maxlength="200"
          show-word-limit
          placeholder="请输入版本说明，多条信息请使用#分割"
        /></el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  appType: 1,
  versionName: '',
  version: '',
  appSize: '',
  appDownurl: '',
  sportsAppDownurl: '',
  zdrAppDownurl: '',
  force: 0,
  publishStatus: 1,
  publishTime: null,
  versionInfo: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      uploading: false,
      pickerOptions: {
        disabledDate(date) {
          return date.valueOf() < Date.now()
        }
      },
      rules: {
        versionName: [{ required: true, message: '请输入版本名称', trigger: 'blur' }],
        version: [
          { required: true, message: '请输入版本号', trigger: 'blur' },
          { pattern: /^\d+\.\d+\.\d+$/, message: '请输入正确版本号', trigger: 'blur' }
        ],
        appSize: [
          { required: true, message: '请输入文件大小', trigger: 'blur' },
          { pattern: /^(0|[1-9][0-9]{0,2})$/, message: '请输入正确大小', trigger: 'blur' }
        ],
        appDownurl: [{ required: true, message: '请输入下载地址', trigger: 'blur' }],
        sportsAppDownurl: [{ required: true,message: '请输入下载地址', trigger: 'blur' }],
        publishTime: [{ required: true, message: '请选择发布时间', trigger: 'change' }]
      }
    }
  },
  computed: {

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}/large`
    }
  },
  methods: {
    open(appType, tenantCode) {
      this.ruleForm.appType = +appType
      this.ruleForm.tenantCode = tenantCode
      this.visible = true
    },
    onProgressUpload() {
      this.uploading = true
    },
    onSuccessUpload(res) {
      this.uploading = false
      this.ruleForm.appDownurl = res.data[0]
      this.$refs.validateForm.validateField('appDownurl')
    },
    onSportsSuccessUpload(res) {
      this.uploading = false
      this.ruleForm.sportsAppDownurl = res.data[0]
      this.$refs.validateForm.validateField('sportsAppDownurl')
    },
    onZdrSuccessUpload(res) {
      this.uploading = false
      this.ruleForm.zdrAppDownurl = res.data[0]
      this.$refs.validateForm.validateField('zdrAppDownurl')
    }
  }
}
</script>
