var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("确认打款"),
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px" } },
        [
          _c("el-form-item", { attrs: { label: _vm.$t("家族名称") } }, [
            _vm._v(_vm._s(_vm.ruleForm.familyName)),
          ]),
          _c("el-form-item", { attrs: { label: _vm.$t("提现账号") } }, [
            _c("div", [_vm._v(_vm._s(_vm.ruleForm.accountNo))]),
            _c("div", [_vm._v(_vm._s(_vm.ruleForm.bankName))]),
            _c("div", [_vm._v(_vm._s(_vm.ruleForm.accountName))]),
          ]),
          _c("el-form-item", { attrs: { label: _vm.$t("提现金额") } }, [
            _c(
              "span",
              { staticStyle: { color: "#ff0000", "font-size": "14px" } },
              [_vm._v(_vm._s(_vm.ruleForm.apycGold))]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确认打款")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }