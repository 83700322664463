var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "添加图库", visible: _vm.visible, width: "46%" },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "六合彩种", prop: "lotteryId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "100%" },
                  attrs: { disabled: "", placeholder: "选择六合彩种" },
                  model: {
                    value: _vm.ruleForm.lotteryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "lotteryId", $$v)
                    },
                    expression: "ruleForm.lotteryId",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "快乐六合彩", value: 1255 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "期数", prop: "issue" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "", placeholder: "期数" },
                  model: {
                    value: _vm.ruleForm.issue,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "issue", $$v)
                    },
                    expression: "ruleForm.issue",
                  },
                },
                _vm._l(_vm.openPrizeList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.issue + "期", value: item.issue },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图库名称", prop: "pictureId" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    maxlength: "20",
                    placeholder: "请输入图库名称查询，最多20个字符",
                  },
                  model: {
                    value: _vm.ruleForm.pictureId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "pictureId", $$v)
                    },
                    expression: "ruleForm.pictureId",
                  },
                },
                _vm._l(_vm.pictureListArr, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上传图片", prop: "url" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-drag",
                  attrs: {
                    drag: "",
                    accept: _vm.accept,
                    data: _vm.uploadFileParams,
                    headers: _vm.$headers(),
                    action: _vm.action,
                    "on-success": _vm.onSuccess,
                    "before-upload": _vm.onBeforeUpload,
                    "before-remove": _vm.onBeforeRemove,
                    "file-list": _vm.fileList,
                    "list-type": "picture",
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("将文件拖到此处，或"),
                    _c("em", [_vm._v("点击上传")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("只能上传jpg/png文件，且不超过500kb")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }