var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-tab" },
    [
      _c("hot-setting"),
      _c("hot-setting", {
        attrs: {
          type: 8,
          vt: "snt",
          "msg-type": "sport",
          title: "体育首页推荐排序",
          notSeriesTag: ["9999021", "9999022"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }