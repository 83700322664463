/**
 * Created by PanJiaChen on 16/11/18.
 */

import isEmpty from 'lodash/isEmpty'
import { sha256 } from 'js-sha256'

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (!time) return null
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        // indexOf('T')
        // support "2021-03-18T03:45:45.849+0000"
        if (time.indexOf('T') === -1) {
          time = time.replace(new RegExp(/-/gm), '/')
        }
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  date.setUTCHours(date.getUTCHours() + 8)
  const formatObj = {
    y: date.getUTCFullYear(),
    m: date.getUTCMonth() + 1,
    d: date.getUTCDate(),
    h: date.getUTCHours(),
    i: date.getUTCMinutes(),
    s: date.getUTCSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

export const filterColumns = function(cols, keys) {
  if (isEmpty(keys)) return cols
  cols = cols.filter(item => keys.indexOf(item.key) >= 0)
  return cols
}

export function filterParam(param) {
  let newparam = {}
  for (let i in param) {
    if (param[i] !== undefined && param[i] !== '') {
      if (typeof param[i] == 'string') {
        newparam[i] = param[i].trim()
      } else {
        newparam[i] = param[i]
      }
    }
  }
  return newparam
}

export function getDefaultTimes(type = 'timestamp', before = 0, after = 0, isTimestamp = true) {
  let start = parseTime(new Date().valueOf() + before * 24 * 60 * 60 * 1000, `{y}-{m}-{d}${type === 'timestamp' ? ' 00:00:00' : ''}`)
  let end = parseTime(new Date().valueOf() + after * 24 * 60 * 60 * 1000, `{y}-{m}-{d}${type === 'timestamp' ? ' 23:59:59' : ''}`)
  if (type === 'timestamp' && isTimestamp) start = new Date(start).valueOf()
  if (type === 'timestamp' && isTimestamp) end = new Date(end).valueOf()
  return [start, end]
}

export function GenerateSign(tenantCode, time) {
  var hash = sha256.create()
  hash.update(tenantCode + ',' + time)
  return hash.hex()
}
export function replaceExtension(fileName, newExtension) {
  // 获取文件名中最后一个点的位置
  const lastDotIndex = fileName.lastIndexOf('.');
  // 如果找到了点，则替换后缀名
  if (lastDotIndex !== -1) {
    return fileName.substring(0, lastDotIndex) + newExtension;
  }
  // 如果没有找到点，则直接添加新的后缀名
  return fileName + newExtension;
}

export function exportExcel(blobData, fileName) {
  console.log("🚀🚀🚀🚀 ~ fileName:", fileName)
  fileName = replaceExtension(fileName,'.zip')//阿布说的 默认导出zip 由于后端可能返回的类型是appliation/json 不是zip 但是却是zip
  // const zipType = 'application/zip';
  // if (blobData.type == zipType) {//统一修改下 现在后端导出excel是默认zip  但是也有可能是csv（excel表格） 所以在这里判断blob类型 统一判断替换下
  // }
  if (!blobData) {
    return
  }
  var blob = new Blob([blobData], { type: blobData.type })

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName)
  } else {
    var downloadElement = document.createElement('a')
    var href = window.URL.createObjectURL(blob) // 创建下载的链接
    downloadElement.href = href
    downloadElement.download = fileName // 下载后文件名
    document.body.appendChild(downloadElement)
    downloadElement.click() // 点击下载
    document.body.removeChild(downloadElement) // 下载完成移除元素
    window.URL.revokeObjectURL(href) // 释放掉blob对象
  }
}
const getBetContentItem = (index, rqCount) => {
  switch (true) {
    case 1101 == index:
      return '胜'
    case 1102 == index:
      return '平'
    case 1103 == index:
      return '负'
    case 1201 == index:
      return `让胜${rqCount}`
    case 1202 == index:
      return `让平${rqCount}`
    case 1203 == index:
      return `让负${rqCount}`
    default: {
      let betsArr = collectReduceByArr(scoreTmp, ['arr', 'tmps', 'bets'])
      let result = betsArr.find((item) => item.index == index).text
      return result.replace('/', '')
    }
  }
}
export const betFormat = (betContentItem, rqCount) => {
  let [index, odds] = betContentItem.split('@')
  return `${getBetContentItem(index, rqCount)}@${odds}`
}

export const NumberFormat = (dataStr, num = 2) => {
  if ((dataStr || dataStr == 0) && isNumber(dataStr)) {
    return dataStr.toFixed(num)
  } else {
    return dataStr
  }
}

export const parseScore = (data) => {
  return data && JSON.parse(data).bfString || ''
}

/**
 * 数据处理
 * @param {null|undefined|number|string} target
 * @param {Number} decimal - 保留的小数位数
 * @returns {Number|''}
 */
export const dealNumber = (target, decimal = 3) => {
  if (target === null || target === undefined || isNaN(target) || target === '') {
    return ''
  }
  // 确保target是数字
  let number = parseFloat(target)
  // 检查是否是有效的数字
  if (isNaN(number)) {
    return ''
  }
  // 处理精度问题
  const factor = Math.pow(10, decimal)
  return (Math.round(number * factor) / factor).toFixed(decimal)
}

// 输入时间戳返回该日0点到24点时间戳
export const getTodayTimestamps = (timestamp) => {
  const date = new Date(timestamp*1);

  // 设置到当天的 0 点
  const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();

  // 设置到第二天的 0 点（即当天的 24 点）
  const endOfDay = startOfDay + 24 * 60 * 60 * 1000 - 1; // 当天结束时间（23:59:59.999）
  return {
      startOfDay,
      endOfDay
  };
}

export function generate16DigitId() {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 16; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}
