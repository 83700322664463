var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "600px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "180px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("排序"), prop: "sortBy" } },
            [
              _c("el-input", {
                staticStyle: { width: "90%" },
                attrs: { placeholder: _vm.$t("请输入排序") },
                model: {
                  value: _vm.ruleForm.sortBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sortBy", $$v)
                  },
                  expression: "ruleForm.sortBy",
                },
              }),
            ],
            1
          ),
          _vm.ruleForm.items && _vm.ruleForm.items.length > 0
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("频道分类"),
                    prop: "categoryName",
                    "label-width": "180px",
                  },
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        title: "频道分类多语言配置",
                        trigger: "click",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "90%" },
                        attrs: {
                          slot: "reference",
                          value: _vm.categoryName,
                          readonly: "",
                        },
                        slot: "reference",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "lang-pop",
                          staticStyle: { width: "342px" },
                        },
                        _vm._l(_vm.ruleForm.items, function (item) {
                          return _c("el-input", {
                            key: item.langCode,
                            attrs: { placeholder: _vm.$t(item.langName) },
                            model: {
                              value: item.categoryName,
                              callback: function ($$v) {
                                _vm.$set(item, "categoryName", $$v)
                              },
                              expression: "item.categoryName",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("分类图标"), prop: "categoryIcon" } },
            [
              _c(
                "div",
                { staticClass: "upload" },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        accept:
                          "image/png,image/jpg,image/jpeg,image/webp,image/*",
                        action: _vm.action,
                        data: { type: "image", folder: "icon" },
                        headers: _vm.$headers(),
                        "show-file-list": false,
                        "on-success": _vm._handleSuccess,
                      },
                    },
                    [
                      _vm.ruleForm.categoryIcon
                        ? _c("img", {
                            staticClass: "image",
                            attrs: { src: _vm.ruleForm.categoryIcon },
                          })
                        : _c("i", { staticClass: "el-icon-upload" }),
                    ]
                  ),
                  _c("i", { staticClass: "el-icon-warning" }),
                  _vm._v(
                    " 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。 "
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("所属频道"), prop: "channelUnionId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "90%" },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    "collapse-tags": "",
                    placeholder: "请选择所属频道",
                  },
                  model: {
                    value: _vm.ruleForm.channelUnionId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "channelUnionId", $$v)
                    },
                    expression: "ruleForm.channelUnionId",
                  },
                },
                _vm._l(_vm.$parent.channelList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("数据来源"), prop: "dataSource" } },
            [
              _vm._l(_vm.$parent.dataSource, function (item) {
                return _c(
                  "el-radio",
                  {
                    key: item.key,
                    attrs: { label: item.key },
                    model: {
                      value: _vm.ruleForm.dataSource,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "dataSource", $$v)
                      },
                      expression: "ruleForm.dataSource",
                    },
                  },
                  [_vm._v(_vm._s(item.label))]
                )
              }),
              _vm.ruleForm.dataSource === "10"
                ? _c(
                    "div",
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "90%" },
                        attrs: {
                          props: { multiple: true },
                          options: _vm.cascaderOptions,
                          placeholder: _vm.$t("请选择彩种"),
                        },
                        on: { change: _vm.handleLottertChange },
                        model: {
                          value: _vm.ruleForm.lotteryIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "lotteryIds", $$v)
                          },
                          expression: "ruleForm.lotteryIds",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "90%" },
                          model: {
                            value: _vm.ruleForm.dataSourceLive,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "dataSourceLive", $$v)
                            },
                            expression: "ruleForm.dataSourceLive",
                          },
                        },
                        _vm._l(_vm.$parent.dataSourceLive, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { value: item.key, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _vm.emitName !== "details"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmitBefor },
                },
                [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }