var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "border-form-dialog",
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "540px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "show-message": false,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("中文名称"), prop: "giftName" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("请输入中文名称") },
                model: {
                  value: _vm.ruleForm.giftName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "giftName", $$v)
                  },
                  expression: "ruleForm.giftName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("英文名称"), prop: "giftNameEN" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("请输入英文名称") },
                model: {
                  value: _vm.ruleForm.giftNameEN,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "giftNameEN", $$v)
                  },
                  expression: "ruleForm.giftNameEN",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("越南名称"), prop: "giftNameVN" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("请输入越南名称") },
                model: {
                  value: _vm.ruleForm.giftNameVN,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "giftNameVN", $$v)
                  },
                  expression: "ruleForm.giftNameVN",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("礼物图标"), prop: "giftIcon" } },
            [
              _c(
                "div",
                { attrs: { flex: "cross:center" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "gifticon-uploader",
                      attrs: {
                        accept: "image/jpeg,image/png,image/jpg,image/webp",
                        action: _vm.action,
                        data: { type: "image", folder: "icon" },
                        headers: _vm.$headers(),
                        "show-file-list": false,
                        "on-success": _vm.uploadIconSuccess,
                      },
                    },
                    [
                      _vm.ruleForm.giftIcon
                        ? _c("img", {
                            staticClass: "image",
                            attrs: { src: _vm.ruleForm.giftIcon },
                          })
                        : _c("i", {
                            staticClass: "el-icon-upload uploader-icon",
                          }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "line-height": "22px",
                        "margin-left": "20px",
                        "font-size": "12px",
                      },
                    },
                    [
                      _c("p", [_vm._v(_vm._s(_vm.$t("建议图标规则")))]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("1.尺寸：160x160px")))]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("2.格式：PNG")))]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("3.大小：1M内")))]),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("礼物价格"), prop: "gold" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("支持3位小数"),
                  "show-word-limit": "",
                  maxlength: "12",
                },
                model: {
                  value: _vm.ruleForm.gold,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "gold", $$v)
                  },
                  expression: "ruleForm.gold",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("礼物属性"), prop: "giftType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: _vm.$t("请选择礼物属性") },
                  model: {
                    value: _vm.ruleForm.giftType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "giftType", $$v)
                    },
                    expression: "ruleForm.giftType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: _vm.$t("连击礼物"), value: 1 },
                  }),
                  _c("el-option", {
                    attrs: { label: _vm.$t("动画礼物"), value: 2 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("排序"), prop: "sortBy" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("请输入排序"),
                  "show-word-limit": "",
                  maxlength: "4",
                },
                model: {
                  value: _vm.ruleForm.sortBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sortBy", _vm._n($$v))
                  },
                  expression: "ruleForm.sortBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("礼物动画"), prop: "giftAction" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    readonly: "",
                    placeholder: _vm.$t(
                      "请上传动画文件(仅支持10M内的svga格式动画)"
                    ),
                  },
                  model: {
                    value: _vm.ruleForm.giftAction,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "giftAction", $$v)
                    },
                    expression: "ruleForm.giftAction",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        slot: "append",
                        action: _vm.action,
                        headers: _vm.$headers(),
                        data: { type: "svg", folder: "icon" },
                        "before-upload": _vm.beforeSvgaUpload,
                        "on-success": _vm.uploadSvgaSuccess,
                        "show-file-list": false,
                      },
                      slot: "append",
                    },
                    [_c("i", { staticClass: "el-icon-upload" })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("礼物状态"), prop: "giftStatus" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": _vm.$t("启用"),
                  "inactive-text": _vm.$t("禁用"),
                  "active-value": 1,
                  "inactive-value": 2,
                },
                model: {
                  value: _vm.ruleForm.giftStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "giftStatus", $$v)
                  },
                  expression: "ruleForm.giftStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("动画状态"), prop: "giftActionStatus" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": _vm.$t("启用"),
                  "inactive-text": _vm.$t("禁用"),
                  "active-value": 1,
                  "inactive-value": 2,
                },
                model: {
                  value: _vm.ruleForm.giftActionStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "giftActionStatus", $$v)
                  },
                  expression: "ruleForm.giftActionStatus",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确认")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }