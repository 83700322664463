<template>
  <el-row>
    <el-col :span="12" style="padding-right: 30px">
      <button v-if="permission.Add" class="el-button el-button--orange el-button--small" style="margin-bottom: 20px" @click="createLabel">添加心水标签</button>

      <el-table border stripe :data="rankList">
        <el-table-column label="称号" prop="rankName" align="center" />
        <el-table-column label="点赞数" prop="admire" align="center">
          <template slot-scope="{ row }">{{ row.minAdmire }}~{{ row.maxAdmire }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button v-if="permission.Update" type="primary" icon="el-icon-edit" size="mini" @click="$refs.EditRankDialogControl.open(row)">编辑</el-button>
            <el-button v-if="permission.Del" type="danger" icon="el-icon-delete" size="mini" @click="handleDeleteRank(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :span="12" style="padding-right: 30px">
      <button v-if="permission.Add" class="el-button el-button--orange el-button--small" style="margin-bottom: 20px" @click="createType">添加大神分类</button>

      <el-table border stripe :data="typeItems">
        <el-table-column label="分类" prop="name" align="center" />
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button v-if="permission.Update" type="primary" icon="el-icon-edit" size="mini" @click="$refs.EditTypeDialogControl.open(row)">编辑</el-button>
            <el-button v-if="permission.Del" type="danger" icon="el-icon-delete" size="mini" @click="handleDeleteType(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>

    <EditTypeDialog ref="EditTypeDialogControl" @create="_handleCreateType" @update="_handleUpdateType" />
    <EditRankDialog ref="EditRankDialogControl" @create="_handleCreateRank" @update="_handleUpdateRank" />

  </el-row>
</template>
<script>
import {
  getGodType,
  saveGodType,
  updateGodType,
  deleteGodType,
  getRankList,
  addRank,
  updateRank,
  deleteRank
} from '@/api/interactive'
import EditTypeDialog from './EditTypeDialog'
import EditRankDialog from './EditRankDialog'
import { mapGetters } from 'vuex'
export default {
  name: 'LabelTab',
  components: { EditTypeDialog, EditRankDialog },
  data() {
    return {
      typeItems: [],
      rankList: []
    }
  },
  computed: {
    ...mapGetters(['permissionSensitive']),
    permission() {
      return this.permissionSensitive(this.$route.path, this.$options.name)
    },
    tenant() {
      return {}
    }
  },
  watch: {
    tenant: {
      handler(v) {
        if (JSON.stringify(v) !== '{}') this.fetchData()
      },
      deep: true
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {},
  methods: {
    fetchData() {
      this.fetchGodType()
      this.fetchRankList()
    },

    createLabel() {
      this.$refs.EditRankDialogControl.open()
    },

    createType() {
      this.$refs.EditTypeDialogControl.open()
    },

    fetchGodType() {
      getGodType(this.tenant).then(([data, err]) => {
        if (!err) {
          this.typeItems = data
        }
      })
    },

    _handleCreateType({ field, cancel, close }) {
      saveGodType({ ...field, ...this.tenant }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success('创建成功')
          this.fetchGodType()
        } else {
          cancel()
        }
      })
    },

    _handleUpdateType({ field, cancel, close }) {
      updateGodType({ ...field, ...this.tenant }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success('修改成功')
          this.fetchGodType()
        } else {
          cancel()
        }
      })
    },

    handleDeleteType(id) {
      return new Promise((resolve) => {
        this.$confirm(`确认删除此大神分类`, '系统提示', { type: 'warning' }).then(() => {
          deleteGodType({ id }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
              this.fetchGodType()
            }
          }).catch(() => {})
        })
      })
    },

    // 头衔

    fetchRankList() {
      getRankList(this.tenant).then(([data, err]) => {
        if (!err) {
          this.rankList = data
        }
      })
    },
    _handleCreateRank({ field, cancel, close }) {
      addRank({ ...field, ...this.tenant }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success('创建成功')
          this.fetchRankList()
        } else {
          cancel()
        }
      })
    },
    _handleUpdateRank({ field, cancel, close }) {
      updateRank({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success('修改成功')
          this.fetchRankList()
        } else {
          cancel()
        }
      })
    },
    handleDeleteRank(id) {
      return new Promise((resolve) => {
        this.$confirm(`确认删除此心水标签`, '系统提示', { type: 'warning' }).then(() => {
          deleteRank({ id }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
              this.fetchRankList()
            }
          }).catch(() => {})
        })
      })
    }
  }
}
</script>
