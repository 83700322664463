var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("新增用户详情"),
        visible: _vm.visible,
        width: "70%",
        "close-on-click-modal": false,
        top: "8vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.handleRefresh,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { float: "right", "margin-bottom": "10px" },
              attrs: {
                plain: "",
                type: "primary",
                loading: _vm.excelLoading,
                icon: "el-icon-download",
              },
              on: { click: _vm._handleExport },
            },
            [_vm._v(_vm._s(_vm.$t("导出Excel")))]
          ),
          _c(
            "el-table",
            _vm._b(
              {
                staticStyle: { "max-height": "60vh", "overflow-y": "auto" },
                attrs: { data: _vm.items },
              },
              "el-table",
              _vm.options,
              false
            ),
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("会员账号"),
                  prop: "memberName",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("会员ID"),
                  prop: "memberId",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("注册来源"),
                  prop: "registerResource",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("注册域名"),
                  prop: "h5ReferDomain",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("最后活跃时间"),
                  prop: "createTime",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("parseTime")(row.createTime))),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            attrs: {
              page: _vm.params.currentPage,
              limit: _vm.params.pageSize,
              total: _vm.total,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.params, "currentPage", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.params, "pageSize", $event)
              },
              pagination: _vm.handleFetch,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }