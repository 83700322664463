var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.ruleForm.transType === 5
            ? _vm.$t("扣款操作")
            : _vm.$t("加款操作"),
        visible: _vm.visible,
        width: "400px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c("el-form-item", { attrs: { label: _vm.$t("会员账号") } }, [
            _c("p", [_vm._v(_vm._s(_vm.ruleForm.memberName))]),
          ]),
          _c("el-form-item", { attrs: { label: _vm.$t("账户余额") } }, [
            _c("p", [_vm._v(_vm._s(_vm.ruleForm.balance))]),
          ]),
          _vm.ruleForm.transType !== 5
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("增加金额"), prop: "amount" } },
                  [
                    _c("el-input", {
                      attrs: { min: 1, label: _vm.$t("请输入需要增加的金额") },
                      model: {
                        value: _vm.ruleForm.amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "amount", $$v)
                        },
                        expression: "ruleForm.amount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("计入打码量"),
                      prop: "effecRolling",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.effecRolling,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "effecRolling", $$v)
                          },
                          expression: "ruleForm.effecRolling",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("是"))),
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v(_vm._s(_vm.$t("否"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: _vm.$t("打码量倍数"), prop: "rollingRate" },
                  },
                  [
                    _c("el-input", {
                      attrs: { label: _vm.$t("请输入打码量倍数") },
                      model: {
                        value: _vm.ruleForm.rollingRate,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "rollingRate", $$v)
                        },
                        expression: "ruleForm.rollingRate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("类型"), prop: "transType" } },
                  [
                    _c(
                      "el-select",
                      {
                        model: {
                          value: _vm.ruleForm.transType,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "transType", $$v)
                          },
                          expression: "ruleForm.transType",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: _vm.$t("礼金"), value: 174 },
                        }),
                        _c("el-option", {
                          attrs: { label: _vm.$t("现金"), value: 4 },
                        }),
                        _c("el-option", {
                          attrs: { label: _vm.$t("微信扫码"), value: 254 },
                        }),
                        _c("el-option", {
                          attrs: { label: _vm.$t("其他补款"), value: 255 },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "tip" }, [
                      _vm.ruleForm.transType == 4
                        ? _c("p", [
                            _vm._v(" 现金应用于线下充值，平台入款统计 "),
                          ])
                        : _vm._e(),
                      _vm.ruleForm.transType == 174
                        ? _c("p", [
                            _vm._v(" 礼金应用于会员赠送，优惠活动统计 "),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("扣款金额"), prop: "amount" } },
                  [
                    _c("el-input", {
                      attrs: { label: _vm.$t("请输入需要扣除的金额") },
                      model: {
                        value: _vm.ruleForm.amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "amount", $$v)
                        },
                        expression: "ruleForm.amount",
                      },
                    }),
                  ],
                  1
                ),
              ],
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("操作原因"), prop: "operatorRemark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: _vm.$t("请输入操作原因"),
                },
                model: {
                  value: _vm.ruleForm.operatorRemark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "operatorRemark", $$v)
                  },
                  expression: "ruleForm.operatorRemark",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }