<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive>
        <component :is="activeName" ref="AliveComponent" />
      </keep-alive>
    </template>

    <UserDialog ref="UserDialogControl" />

  </div>
</template>
<script>
import { getAllVipLevels } from '@/api/app'
import { parentMixin } from '@/mixins/tab-items'
import UserDialog from '@/views/components/UserDialog'
import CheckTab from './deposit-check'
import ListTab from './deposit-list'

export default {
  name: 'CashDeposit',
  components: {
    UserDialog,
    CheckTab,
    ListTab
  },
  mixins: [parentMixin],
  data() {
    return {
      vipLevels: [],
      tabs: this.$t('depositTabs')
    }
  },
  created() {
    this.implementationParentTenantChanged()
  },
  methods: {
    implementationParentTenantChanged() {
      getAllVipLevels().then(([data, err]) => {
        if (!err) {
          this.vipLevels = data
        }
      })
    }
  }
}
</script>
