<template>
  <el-dialog :title="$t('Interactive_Match.ForecastTab.预测内容编辑')" :visible.sync="visible" width="600px" :close-on-click-modal="false" @closed="handleClosed">
    <div ref="scrollForm" v-loading="loading" class="my-form">
      <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="80px" label-suffix=":">
        <h3>{{ $t('Interactive_Match.ForecastTab.重要提示') }}</h3>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.防冷')">
          <el-input v-model.trim="ruleForm.upsetContent" />
        </el-form-item>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.主状态')">
          <el-input v-model.trim="ruleForm.homeStatus" />
        </el-form-item>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.客状态')">
          <el-input v-model.trim="ruleForm.awayStatus" />
        </el-form-item>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.攻防')">
          <el-input v-model.trim="ruleForm.defenceRule" />
        </el-form-item>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.伤停')">
          <el-input v-model.trim="ruleForm.stoppage" />
        </el-form-item>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.规律')">
          <el-input v-model.trim="ruleForm.law" />
        </el-form-item>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.上半场')">
          <el-input v-model.trim="ruleForm.firstHalf" />
        </el-form-item>
        <h4>{{ $t('Interactive_Match.ForecastTab.亚盘规律') }}</h4>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.赛果规律')">
          <el-input v-model.trim="ruleForm.asiaLaw" />
        </el-form-item>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.盘口情况')">
          <el-input v-model.trim="ruleForm.asiaHandicap" />
        </el-form-item>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.基本面')">
          <el-input v-model.trim="ruleForm.asiaBase" />
        </el-form-item>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.分析场次')" prop="asiaGames">
          <el-input v-model.number="ruleForm.asiaGames" />
        </el-form-item>
        <h4>{{ $t('Interactive_Match.ForecastTab.欧盘让球规律') }}</h4>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.赛果规律')">
          <el-input v-model.trim="ruleForm.euLaw" />
        </el-form-item>
        <el-form-item :label="$t('Interactive_Match.ForecastTab.分析场次')" prop="euGames">
          <el-input v-model.number="ruleForm.euGames" />
        </el-form-item>
        <h4>{{ $t('Interactive_Match.ForecastTab.本次基本面分析') }}</h4>
        <el-form-item>
          <el-input v-model="ruleForm.baseInfo" type="textarea" rows="6" />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { predictionMatchDetail } from '@/api/match'
const getDefaultForm = () => ({})
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      loading: false,
      rules: {
        asiaGames: [
          { pattern: /^(?:[1-9]?\d{0,4}|100000)$/, message: this.$t('请输入m_n区间数字', { text: '0_100000' }), trigger: 'blur' }
        ],
        euGames: [
          { pattern: /^(?:[1-9]?\d{0,4}|100000)$/, message: this.$t('请输入m_n区间数字', { text: '0_100000' }), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open({ tenantCode, matchId, sportType }) {
      this.ruleForm.sportType = sportType
      this.visible = true
      this.loading = true
      this.ruleForm.tenantCode = tenantCode
      this.ruleForm.matchId = matchId
      predictionMatchDetail({ matchId: matchId, type: sportType, tenantCode: tenantCode }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          data.predictionMatchDetailResp.matchId = matchId
          data.predictionMatchDetailResp.tenantCode = tenantCode
          data.predictionMatchDetailResp.baseInfo = data.baseInfo.replaceAll('<br>', '\n')
          this.ruleForm = data.predictionMatchDetailResp
        }
      })
      this.$nextTick(() => {
        this.$refs.scrollForm.scrollTo(0, 0)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0;
  .my-form {
    max-height: 400px;
    padding: 0 20px;
    overflow: auto;
  }
}
</style>
