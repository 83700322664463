<template>
  <div class="app-container">
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <div slot="left" class="filter-container">
        <el-input v-model="search.familyName" :placeholder="$t('家族名称')" clearable class="filter-item" style="width: 160px;" />
        <el-input v-model="search.familyOwerLogin" :placeholder="$t('家族账号')" clearable class="filter-item" style="width: 180px;" />
        <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleRefresh">{{ $t('查 询') }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </div>
      <el-button
        v-if="permission.Add"
        slot="right"
        type="success"
        icon="el-icon-plus"
        @click="onCreate"
      >{{ $t('新增家族') }}</el-button>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <CreateDialog ref="CreateDialogControl" @create="_handleCreate" />
    <UpdateDialog ref="UpdateDialogControl" @update="_handleUpdate" />
    <ChangeDialog ref="ChangeDialogControl" @change="_handleChange" />
    <ShareDialog ref="ShareDialogControl" @update="_handleShareUpdate" />
  </div>
</template>

<script>
import {
  getLiveFamilyList,
  doCreateFamily,
  doUpdateFamily,
  doDelFamily,
  doUpdateFamilyPassword,
  doUpdateFamilyPercent
} from '@/api/live'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { CreateDialog, UpdateDialog, ShareDialog, ChangeDialog } from './components'
export default {
  name: 'LiveFamily',
  components: { CreateDialog, UpdateDialog, ShareDialog, ChangeDialog },
  mixins: [TablePageMixin(getLiveFamilyList)],
  data() {
    return {
      search: {},
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  methods: {
    onCreate() {
      this.$refs.CreateDialogControl.open()
    },
    _handleCreate({ field, cancel, close }) {
      doCreateFamily({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      doUpdateFamily({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleShareUpdate({ field, cancel, close }) {
      doUpdateFamilyPercent({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleChange({ field, cancel, close }) {
      doUpdateFamilyPassword({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleChangeStatus() {
      //
    },
    _handleDelete({ familyId }) {
      this.$confirm(this.$t('是否删除该家族!!!'), this.$t('请谨慎操作'), {
        type: 'warning',
        center: true
      }).then(() => {
        doDelFamily({ familyId }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
