var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-tab" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "elTable",
          attrs: {
            border: "",
            data: _vm.items,
            "row-key": "type",
            "drag-row": true,
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { type: "index", align: "center", width: "60" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.$t("排序")))]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("svg-icon", {
                attrs: { "icon-class": "drag", "class-name": "drag-handler" },
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { label: "玩法", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.types.find(function (e) {
                            return e.value === row.type
                          }).label
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "d-switch",
                      {
                        attrs: { activeValue: 1, inactiveValue: 0 },
                        model: {
                          value: row.status,
                          callback: function ($$v) {
                            _vm.$set(row, "status", $$v)
                          },
                          expression: "row.status",
                        },
                      },
                      [
                        _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                          _vm._v(_vm._s(_vm.$t("开启"))),
                        ]),
                        _c(
                          "span",
                          { attrs: { slot: "close" }, slot: "close" },
                          [_vm._v(_vm._s(_vm.$t("关闭")))]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("操作"), align: "center", width: "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { padding: "10px 0" }, attrs: { align: "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", loading: _vm.submitting },
              on: {
                click: function ($event) {
                  return _vm.handleSubmit()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("保 存")))]
          ),
        ],
        1
      ),
      _c("ActionDialog", {
        ref: "actionDialogContorl",
        on: { update: _vm.handleSave },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }