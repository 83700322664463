<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane
          v-for="tab of permissionTabs"
          :key="tab.name"
          :label="tab.label"
          :name="tab.name"
        />
        <el-tab-pane label="ABPay" name="Abpay" @click="activeName = 'Abpay'" />
        <el-tab-pane
          label="提现方式"
          name="Withdraw"
          @click="activeName = 'Withdraw'"
        />
      </el-tabs>
      <component :is="`Payment${activeName}`" ref="AliveComponent" />
    </template>
  </div>
</template>
<script>
import { parentMixin } from "@/mixins/tab-items";
import PaymentCompany from "./payment-company";
import PaymentThree from "./payment-third";
import PaymentPay from "./payment-platform";
import PaymentPayType from "./payment-method";
import PaymentAgent from "./payment-agent";
// import PaymentAgent from './payment-agent'
import PaymentConf from "./payment-conf";
import PaymentFast from "./payment-fast";
import PaymentAbpay from "./payment-abpay";
import PaymentWithdraw from "./payment-withdraw";

export default {
  name: "CashPaymentConfig",
  components: {
    PaymentCompany,
    PaymentThree,
    PaymentPay,
    PaymentPayType,
    PaymentAgent,
    PaymentConf,
    PaymentFast,
    PaymentAbpay,
    PaymentWithdraw,
  },
  mixins: [parentMixin],
  data() {
    return {
      tabs: [
        { label: this.$t("公司账号"), name: "Company" },
        { label: this.$t("第三方账号"), name: "Three" },
        { label: this.$t("支付平台设置"), name: "Pay" },
        { label: this.$t("支付方式设置"), name: "PayType" },
        { label: this.$t("代付配置"), name: "Agent" },
        { label: "快捷金额配置", name: "Fast" },
        { label: "提现钱包", name: "Conf" },
        { label: "ABPay配置", name: "Abpay" },
      ],
    };
  },
};
</script>
