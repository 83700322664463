const CanChooseColumns = function() {
  return [
    {
      title: this.$t('玩法分类'),
      key: 'playClassName',
      align: 'center'
    },
    {
      title: this.$t('最大可选号码'),
      key: 'maxSelectNum',
      align: 'center',
      minWidth: 360,
      component: {
        render: (h, row) => {
          return (
            <el-input v-model={row.maxSelectNum} readonly={!this.permission.Max} />
          )
        }
      }
    },
    {
      title: this.$t('直播间开关'),
      key: 'liveShowStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch v-model={row.liveShowStatus} activeValue={1} inactiveValue={0} disabled={!this.permission.StartStop} />
          )
        }
      }
    },
    {
      title: this.$t('状态'),
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch v-model={row.status} activeValue={1} inactiveValue={0} disabled={!this.permission.StartStop} />
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      component: {
        render: (h, row) => {
          return (
            <el-button type='text' onClick={this._handleUpdate.bind(this, row)}>{this.$t('保存修改')}</el-button>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
