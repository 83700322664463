var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "400px" },
            attrs: {
              clearable: "",
              type: "datetimerange",
              "value-format": "yyyy-MM-dd HH:mm:ss",
              "range-separator": _vm.$t("至"),
              "start-placeholder": _vm.$t("开始日期"),
              "end-placeholder": _vm.$t("结束日期"),
              "default-time": ["00:00:00", "23:59:59"],
            },
            model: {
              value: _vm.times,
              callback: function ($$v) {
                _vm.times = $$v
              },
              expression: "times",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetTimes($event, 0, false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("今天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetTimes($event, 1, false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("昨天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetTimes($event, 7, false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("_7天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleSearchWrap },
            },
            [_vm._v(_vm._s(_vm.$t("查询")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-refresh-right" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("重置")))]
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "filter-item",
              attrs: { trigger: "click", placement: "bottom-start" },
              on: { command: _vm.handleCommand },
            },
            [
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v(" " + _vm._s(_vm.$t("批量修改"))),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _vm.permission.UpdateVip
                    ? _c("el-dropdown-item", { attrs: { command: 0 } }, [
                        _vm._v(_vm._s(_vm.$t("批量修改会员等级"))),
                      ])
                    : _vm._e(),
                  _vm.permission.StartStop
                    ? _c("el-dropdown-item", { attrs: { command: 1 } }, [
                        _vm._v(_vm._s(_vm.$t("批量修改用户状态"))),
                      ])
                    : _vm._e(),
                  _vm.permission.UpdateAgent
                    ? _c("el-dropdown-item", { attrs: { command: 2 } }, [
                        _vm._v(_vm._s(_vm.$t("批量修改上级代理"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: _vm.$t("用户类型") },
              model: {
                value: _vm.search.memberType,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "memberType", $$v)
                },
                expression: "search.memberType",
              },
            },
            _vm._l(_vm.memberTypes, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: _vm.$t("VIP等级") },
              model: {
                value: _vm.search.vipLevel,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "vipLevel", $$v)
                },
                expression: "search.vipLevel",
              },
            },
            _vm._l(_vm.vipLevels, function (item) {
              return _c("el-option", {
                key: item.vipLevel,
                attrs: { label: "VIP" + item.vipLevel, value: item.vipLevel },
              })
            }),
            1
          ),
          _vm.permission.StartStop
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: _vm.$t("用户状态") },
                  model: {
                    value: _vm.search.freezeStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.search, "freezeStatus", $$v)
                    },
                    expression: "search.freezeStatus",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: _vm.$t("启用"), value: 0 },
                  }),
                  _c("el-option", {
                    attrs: { label: _vm.$t("停用"), value: 1 },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: _vm.$t("是否提现") },
              model: {
                value: _vm.search.withdrawStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "withdrawStatus", $$v)
                },
                expression: "search.withdrawStatus",
              },
            },
            [
              _c("el-option", { attrs: { label: _vm.$t("否"), value: 0 } }),
              _c("el-option", { attrs: { label: _vm.$t("是"), value: 1 } }),
            ],
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: _vm.$t("在线状态") },
              model: {
                value: _vm.search.onlineStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "onlineStatus", $$v)
                },
                expression: "search.onlineStatus",
              },
            },
            [
              _c("el-option", { attrs: { label: _vm.$t("在线"), value: 1 } }),
              _c("el-option", { attrs: { label: _vm.$t("离线"), value: 0 } }),
            ],
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: "充值状态" },
              model: {
                value: _vm.search.rechargeStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "rechargeStatus", $$v)
                },
                expression: "search.rechargeStatus",
              },
            },
            [
              _c("el-option", { attrs: { label: "一次充值", value: 1 } }),
              _c("el-option", { attrs: { label: "二次充值", value: 2 } }),
              _c("el-option", { attrs: { label: "多次充值", value: 3 } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: _vm.$t("会员账号") },
            model: {
              value: _vm.search.memberName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "memberName", $$v)
              },
              expression: "search.memberName",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: _vm.$t("所属上级") },
            model: {
              value: _vm.search.agencyUsername,
              callback: function ($$v) {
                _vm.$set(_vm.search, "agencyUsername", $$v)
              },
              expression: "search.agencyUsername",
            },
          }),
          _c("el-input", {
            directives: [{ name: "onlynumber", rawName: "v-onlynumber" }],
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: _vm.$t("用户余额大于") },
            nativeOn: {
              "!paste": function ($event) {
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.search.balance,
              callback: function ($$v) {
                _vm.$set(_vm.search, "balance", _vm._n($$v))
              },
              expression: "search.balance",
            },
          }),
          _c("el-input", {
            directives: [{ name: "onlynumber", rawName: "v-onlynumber" }],
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: _vm.$t("充值金额大于") },
            nativeOn: {
              "!paste": function ($event) {
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.search.thanRecharge,
              callback: function ($$v) {
                _vm.$set(_vm.search, "thanRecharge", _vm._n($$v))
              },
              expression: "search.thanRecharge",
            },
          }),
          _c("el-input", {
            directives: [{ name: "onlynumber", rawName: "v-onlynumber" }],
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: _vm.$t("充值金额小于") },
            nativeOn: {
              "!paste": function ($event) {
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.search.lessRecharge,
              callback: function ($$v) {
                _vm.$set(_vm.search, "lessRecharge", _vm._n($$v))
              },
              expression: "search.lessRecharge",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            directives: [{ name: "onlynumber", rawName: "v-onlynumber" }],
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: _vm.$t("最近多少天未充值") },
            nativeOn: {
              "!paste": function ($event) {
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.search.notRechargeDay,
              callback: function ($$v) {
                _vm.$set(_vm.search, "notRechargeDay", _vm._n($$v))
              },
              expression: "search.notRechargeDay",
            },
          }),
          _c("el-input", {
            directives: [{ name: "onlynumber", rawName: "v-onlynumber" }],
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: _vm.$t("最近多少天未登陆") },
            nativeOn: {
              "!paste": function ($event) {
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.search.notLoginDay,
              callback: function ($$v) {
                _vm.$set(_vm.search, "notLoginDay", _vm._n($$v))
              },
              expression: "search.notLoginDay",
            },
          }),
          _c("el-input", {
            directives: [{ name: "onlynumber", rawName: "v-onlynumber" }],
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: _vm.$t("最近多少天登陆过") },
            nativeOn: {
              "!paste": function ($event) {
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.search.loginDay,
              callback: function ($$v) {
                _vm.$set(_vm.search, "loginDay", _vm._n($$v))
              },
              expression: "search.loginDay",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: _vm.$t("备注内容") },
            model: {
              value: _vm.search.remark,
              callback: function ($$v) {
                _vm.$set(_vm.search, "remark", $$v)
              },
              expression: "search.remark",
            },
          }),
          _c(
            "el-input",
            {
              staticClass: "filter-item input-with-select",
              class: { "key-word": _vm.search.key === 3 },
              staticStyle: { width: "290px" },
              attrs: {
                clearable: "",
                placeholder:
                  _vm.search.key === 3 ? _vm.$t("手机号") : _vm.$t("关键词"),
              },
              model: {
                value: _vm.keyWord,
                callback: function ($$v) {
                  _vm.keyWord = $$v
                },
                expression: "keyWord",
              },
            },
            [
              _vm.search.key === 3
                ? _c("el-input", {
                    staticStyle: { width: "80px" },
                    attrs: {
                      slot: "prefix",
                      clearable: "",
                      placeholder: _vm.$t("区号"),
                    },
                    slot: "prefix",
                    model: {
                      value: _vm.areaCode,
                      callback: function ($$v) {
                        _vm.areaCode = $$v
                      },
                      expression: "areaCode",
                    },
                  })
                : _vm._e(),
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { slot: "prepend", clearable: "" },
                  slot: "prepend",
                  model: {
                    value: _vm.search.key,
                    callback: function ($$v) {
                      _vm.$set(_vm.search, "key", $$v)
                    },
                    expression: "search.key",
                  },
                },
                _vm._l(_vm.keywordsMaps, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "d-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "DTable",
          attrs: {
            "selection-row": "",
            data: _vm.items,
            columns: _vm.columns,
            "original-columns": _vm.defaultColumns,
            options: _vm.options,
            "row-key": "memberId",
          },
          on: {
            refresh: _vm.handleFetch,
            "column-change": _vm.handleColumnChange,
            "selection-change": _vm.handleSelectionChange,
          },
          scopedSlots: _vm._u([
            {
              key: "right",
              fn: function () {
                return [
                  _vm.permission.Register
                    ? _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.RegisterDialogControl.open()
                            },
                          },
                        },
                        [_vm._v("注册内部账号")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        plain: "",
                        type: "primary",
                        icon: "el-icon-download",
                      },
                      on: { click: _vm._handleExport },
                    },
                    [_vm._v(_vm._s(_vm.$t("导出Excel")))]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "statistics" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("注册人数")) +
                    "：" +
                    _vm._s(_vm.statistics.totalPerson) +
                    " "
                ),
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("首充人数")) +
                    "：" +
                    _vm._s(_vm.statistics.totalRecharge) +
                    " "
                ),
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("测试账户")) +
                    "：" +
                    _vm._s(_vm.statistics.totalTest) +
                    " "
                ),
              ]),
              _c("span", [_vm._v("（需带入时间才可统计）")]),
            ]),
          ]),
        ],
        2
      ),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("user-dialog", { ref: "UserDialogControl" }),
      _c("edit-dialog", {
        ref: "EditDialogControl",
        on: { update: _vm.handleUpdateUserInfo },
      }),
      _c("batch-edit-dialog", {
        ref: "BatchEditDialogControl",
        on: {
          "update-level": _vm.handleBatchUpdateLevel,
          "update-status": _vm.handleBatchUpdateStatus,
          "update-agency": _vm.handleBatchUpdateAgency,
        },
      }),
      _c("send-dialog", {
        ref: "SendDialogDialogControl",
        on: { create: _vm.hanldeCreateStationletter },
      }),
      _c("proxy-dialog", {
        ref: "ProxyDialogControl",
        on: { update: _vm.handleUpdateAgency },
      }),
      _c("level-dialog", {
        ref: "LevelDialogControl",
        on: { update: _vm.handleUpdateLevel },
      }),
      _c("password-dialog", {
        ref: "PasswordDialogControl",
        on: { update: _vm.handleChangePwd },
      }),
      _c("remark-dialog", {
        ref: "RemarkDialogControl",
        on: { update: _vm.handleUpdateRemark },
      }),
      _c("GoogleDialog", {
        ref: "GoogleDialog",
        on: { validate: _vm.handleGoogleDialog },
      }),
      _c("RegisterDialog", {
        ref: "RegisterDialogControl",
        on: { register: _vm.handleUserCenterRegister },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }