<template>
  <el-dialog
    :title="title"
    width="800px"
    :visible.sync="visible"
    @closed="handleClosed"
    @open="onOpen"
    top="2vh"
  >
    <el-form
      ref="validateForm"
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item :label="$t('标题')" prop="title">
        <el-input v-model="ruleForm.title" :placeholder="$t('请输入标题')" />
      </el-form-item>
      <el-form-item :label="$t('公告类型')" prop="type">
        <el-select
          v-model="ruleForm.type"
          :placeholder="$t('请选择公告类型')"
          @change="handleChangeType"
        >
          <el-option
            v-for="(value, key) in $parent.typeOptions"
            :key="key"
            :label="value"
            :value="+key"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('公告时间')" prop="dateTime">
        <el-date-picker
          v-model="ruleForm.dateTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          type="datetimerange"
          :range-separator="$t('至')"
          :start-placeholder="$t('开始时间')"
          :end-placeholder="$t('结束时间')"
        />
      </el-form-item>
      <el-form-item :label="$t('是否启用')" prop="enable">
        <el-radio-group
          v-model="ruleForm.enable"
          :disabled="!$parent.permission.StartStop"
        >
          <el-radio :label="1">{{ $t('启用') }}</el-radio>
          <el-radio :label="0">{{ $t('关闭') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('是否弹出')" prop="popup">
        <el-radio-group
          v-model="ruleForm.popup"
          :disabled="!$parent.permission.Eject || [11, 12].includes(ruleForm.type)"
        >
          <el-radio :label="1">{{ $t('启用') }}</el-radio>
          <el-radio :label="0">{{ $t('关闭') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('排序')" prop="sort">
        <el-input v-model.number="ruleForm.sort" style="width: 150px" :placeholder="$t('请输入排序')" />
      </el-form-item>
      <el-form-item :label="$t('语言')" prop="lang">
        <el-checkbox-group v-model="ruleForm.lang">
          <el-checkbox
            v-for="item of allLang"
            :key="item.lang"
            :label="item.lang"
          >{{ $t(item.name) }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="公告详情" prop="detailType">
        <el-radio v-model="ruleForm.detailType" @change="handleChangeContent" :label="1">富文本</el-radio>
        <el-radio v-model="ruleForm.detailType" @change="handleChangeContent" :label="2">图片</el-radio>
      </el-form-item>
      <el-form-item label="展示方式" prop="imgShowType" v-if="ruleForm.detailType === 2">
        <el-radio v-model="ruleForm.imgShowType" :label="1">合并显示</el-radio>
        <el-radio v-model="ruleForm.imgShowType" :label="2">独立展开</el-radio>
      </el-form-item>
      <template v-if="ruleForm.detailType === 1">
        <el-form-item v-if="ruleForm.type !== 5" label="" label-width="0" prop="content" id="content">
          <tinymce ref="tinymce" v-model="ruleForm.content" :height="200" />
        </el-form-item>
        <el-form-item v-else :label="$t('公告内容')" prop="content" id="content">
          <el-input
            v-model="ruleForm.content"
            type="textarea"
            :rows="5"
            :placeholder="$t('请输入内容')"
          />
        </el-form-item>
      </template>
      <template v-else-if="ruleForm.detailType === 2">
        <el-form-item id="content">
          <div v-for="(item, index) in ruleForm.imgList" :key="index" class="picList">
            <div class="left">
              <el-form-item>
                <div class="upload">
                  <el-upload
                    accept="image/png,image/jpg,image/jpeg,image/webp,image/*"
                    :action="action"
                    :data="{ type: 'image', folder: 'icon' }"
                    :headers="$headers()"
                    :show-file-list="false"
                    :before-upload="() => { imgIndex = index }"
                    :on-success="_handleSuccess"
                  >
                    <img v-if="item.imgUrl" :src="item.imgUrl" class="image">
                    <i v-else class="el-icon-upload" />
                  </el-upload>
                  <i class="el-icon-warning" /> 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。
                </div>
              </el-form-item>
              <el-form-item label="跳转方式" label-width="90">
                <el-radio-group v-model="item.jumpType" @change="item.jumpUrl = ''">
                  <el-radio :label="1">{{ $t('链接') }}</el-radio>
                  <el-radio :label="5">{{ $t('APP功能页') }}</el-radio>
                  <!-- <el-radio :label="2">{{ $t('活动') }}</el-radio>
                  <el-radio :label="3">{{ $t('任务中心') }}</el-radio> -->
                  <el-radio :label="4">{{ $t('签到') }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="跳转链接" v-if="item.jumpType === 1" label-width="90">
                <el-input v-model="item.jumpUrl" style="width: 80%" />
              </el-form-item>
              <el-form-item v-if="item.jumpType === 5" label="APP功能页" label-width="90">
                <el-cascader v-model="item.appPageId" :options="appPageList" @change="handleChangeAppPage($event, item)" style="width: 80%" />
              </el-form-item>
              <!-- <el-form-item v-if="item.jumpType === 3" :label="$t('跳转活动')" label-width="90">
                <el-select v-model="item.jumpUrl" :placeholder="$t('请选择跳转活动')" style="width: 80%">
                  <el-option v-for="item in activityOption" :key="item.id" :label="item.activityTitle" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item v-if="item.jumpType === 4" :label="$t('跳转任务')" label-width="90">
                <el-select v-model="item.jumpUrl" :placeholder="$t('请选择跳转任务分类')" style="width: 80%">
                  <el-option v-for="item in activityTypes" :key="item.id" :label="item.typeName" :value="item.id" />
                </el-select>
              </el-form-item> -->
            </div>
            <div class="right">
              <a @click.prevent="handleAddPic(1, index)" class="jia">+</a>
              <a @click.prevent="handleAddPic(-1, index)" class="jian" v-if="index !== 0">-</a>
            </div>
          </div>
        </el-form-item>
      </template>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('取消') }}</el-button>
      <el-button
        type="primary"
        :loading="submitting"
        @click="handleSubmitBefor"
      >{{ $t('确 定') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import Tinymce from '@/components/Tinymce'
import { parseTime } from '@/utils'
import { mapState } from 'vuex'
import { requireIntegerNormal } from '@/utils/validation.js'
import { getAnnouncementDetail } from '@/api/announcement'
import { getCpadActivityList, getActivityTypeList } from '@/api/activity'
import { appPageList } from '@/libs/options'
import { getAppPageParams } from '@/libs/universal'
import { getLotteryNavigationHot } from '@/api/config'
import { getLiveRoomList } from "@/api/chat";

const getDefaultForm = () => ({
  title: '',
  type: null,
  dateTime: [],
  enable: 1,
  scroll: 1,
  popup: 0,
  sort: '',
  content: '',
  lang: [],
  detailType: 1,
  imgShowType: 1,
  imgList:[{
    jumpType: 1,
    jumpUrl: '',
    imgUrl: '',
    imgSort: 1,
    seriesTag: '',
    activityId: '',
    activityTitle: '',
    activityType: 1,
    lotteryId: '',
    gameId: '',
    appPageId: [],
    roomId: '',
    roomName: ''
  }]
})

export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      loading: false,
      rules: {
        title: [{ required: true, message: this.$t('请输入标题'), trigger: 'blur' }],
        type: [
          { required: true, message: this.$t('请选择标题类型'), trigger: 'change' }
        ],
        dateTime: [
          { required: true, message: this.$t('请选择公告时间'), trigger: 'change' }
        ],
        content: [{ required: true, message: this.$t('请输入内容'), trigger: 'blur' }],
        lang: [{ required: true, message: this.$t('请选择语言'), trigger: 'change' }],
        sort: requireIntegerNormal,
        detailType: [{ required: true, message: this.$t('请选择公告详情'), trigger: 'change' }],
        imgShowType: [{ required: true, message: this.$t('请选择展示方式'), trigger: 'change' }]
      },
      firstLinks: [],
      activityOption: [],
      activityTypes: [],
      imgIndex: 0,
      isLoadAppPage: false,
      appPageList
    }
  },
  computed: {
    ...mapState('app', ['allLang']),
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    title() {
      return this.emitName === 'update' ? this.$t('编辑公告') : this.$t('创建公告')
    }
  },
  async created() {
    await this.handleOpen()
  },
  methods: {
    handleChangeContent() {
      this.$nextTick()
      const errorElement = document.querySelector('#content').querySelector('.el-form-item__error')
      if (this.ruleForm.detailType === 2 && errorElement) {
        errorElement.style.display = 'none'
      } else if (errorElement) {
        errorElement.style.display = 'block'
      }
    },
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm.announcementId = row.id
        this.emitName = 'update'
        this.ruleForm.title = row.title
        this.ruleForm.type = row.type
        this.ruleForm.enable = row.enable
        this.ruleForm.popup = row.popup
        this.ruleForm.sort = row.sort
        this.ruleForm.content = row.content
        this.ruleForm.detailType = row.detailType || 1
        this.ruleForm.imgShowType = row.imgShowType
        this.ruleForm.dateTime = [
          parseTime(row.startTime),
          parseTime(row.endTime)
        ]
        if (this.ruleForm.type !== 5) {
          this.$nextTick(() => {
            this.$refs.tinymce.hasInit &&
              this.$refs.tinymce.setContent(row.content)
          })
        }
        if (row.lang) {
          this.ruleForm.lang = row.lang.split(',')
        }
      }
      this.visible = true
    },
    handleAddPic(num, index) {
      if (num === 1) {
        this.ruleForm.imgList.splice(index + 1, 0, {
          jumpType: 1,
          jumpUrl: '',
          imgUrl: '',
          imgSort: 1,
          seriesTag: '',
          activityId: '',
          activityTitle: '',
          activityType: 1,
          lotteryId: '',
          gameId: '',
          appPageId: [],
          roomId: '',
          roomName: ''
        })
      } else {
        this.ruleForm.imgList.splice(index, 1)
      }
    },
    async handleOpen() {
      if (this.isLoadAppPage) return
      this.isLoadAppPage = true
      let list1 = []
      let list2 = []
      let list3 = []
      let list4 = []
      const [[items1, err1], [items2, err2], [items3, err3], [items4, err4]] = await Promise.all([getLotteryNavigationHot(), getCpadActivityList({ jumpType: 0, tenantCode: this.ruleForm.tenantCode }), getActivityTypeList({ currentPage: 1, pageSize: 100, isShow: 1 }), getLiveRoomList({ roomType: 1 })])
      if (!err1) {
        list1 = items1
      }
      if (!err2) {
        list2 = items2
      }
      if (!err3) {
        if (items3.length > 0) {
          const list = []
          items3.forEach(e => {
            const typeNameList = e.typeName.split(',')
            list.push({
              id: e.id,
              typeName: typeNameList[0].split(':')[1]
            })
          })
          list3 = list
        }
      }
      if (!err4) {
        list4 = items4
      }
      this.appPageList.forEach(e => {
        e.children.forEach(v => {
          const item = list1.find(r => r.seriesTag === v.value)
          if (item) {
            v.children = item.lotteryList.map(o => { 
              return {
                value: o.lotteryId,
                label: o.name,
                gameId: o.gameId,
                lotteryId: o.lotteryId
              }
            })
          }
          if (v.value === '9999002' && list2.length > 0) {
            v.children = list2.map(o => {
              return {
                value: o.id,
                label: o.activityTitle,
                activityId: o.id,
                activityType: o.jumpType,
                activityTitle: o.activityTitle
              }
            })
          }
          if (v.value === '9999014' && list3.length > 0) {
            v.children = list3.map(o => {
              return {
                value: o.id,
                label: o.typeName,
                activityId: o.id
              }
            })
          }
          if (v.value === '9999007' && list4.length > 0) {
            v.children = list4.map(o => {
              return {
                value: o.roomId,
                label: o.roomName,
                roomId: o.roomId,
                roomName: o.roomName
              }
            })
          }
          if (v.children && v.children.length === 0) {
            delete v.children
          }
        })
      })
    },
    onOpen() {
      if (this.emitName === 'create') return
      const params = {
        announcementId: this.ruleForm.announcementId,
        tenantCode: this.ruleForm.tenantCode
      }
      this.loading = true
      getAnnouncementDetail(params).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.ruleForm.title = data.title
          this.ruleForm.type = data.type
          this.ruleForm.enable = data.enable
          this.ruleForm.popup = data.popup
          this.ruleForm.sort = data.sort
          this.ruleForm.content = data.content
          this.ruleForm.detailType = data.detailType || 1
          this.ruleForm.imgShowType = data.imgShowType
          this.ruleForm.dateTime = [
            parseTime(data.startTime),
            parseTime(data.endTime)
          ]
          if (this.ruleForm.type !== 5) {
            this.$nextTick(() => {
              this.$refs.tinymce.hasInit &&
              this.$refs.tinymce.setContent(data.content)
            })
          }
          if (data.imgList && data.imgList.length > 0) {
            data.imgList.forEach(e => {
              e.appPageId = []
              if (e.jumpType === 5) {
                e.appPageId = getAppPageParams(e.seriesTag, e.activityId, e.lotteryId, e.roomId)
              }
            })
            this.ruleForm.imgList = data.imgList

          }
          if (data.lang) {
            this.ruleForm.lang = data.lang.split(',')
          }
        }
      })
    },
    _handleSuccess(res) {
      this.ruleForm.imgList[this.imgIndex].imgUrl =  res.data[0]
    },
    handleSubmitBefor() {
      if (this.ruleForm.detailType === 2) {
        let errMsg = ''
        this.ruleForm.imgList.forEach((e, index) => {
          if (e.jumpType === 1 && e.jumpUrl === '') {
            errMsg = '请输入展示图片的跳转链接'
            return
          }
          if (e.jumpType === 5 && e.appPageId.length <= 1) {
            errMsg = '请选择展示图片的跳转APP功能页'
            return
          }
          if (e.imgUrl === '') {
            errMsg = '请上传展示图片'
            return
          }
          e.imgSort = index + 1
        });
        if (errMsg !== '') {
          this.$message.error(errMsg)
          return
        }
      }
      this.handleSubmit()
    },
    handleChangeAppPage(pageId, item) {
      item.seriesTag = null
      item.lotteryId = null
      item.gameId = null
      item.activityId = null
      item.activityType = null
      item.activityTitle = null
      item.roomId = null
      item.roomName = null
      if (pageId.length >= 2) {
        item.seriesTag = pageId[1]
      }
      if (pageId.length >= 3) {
        const node1 = this.appPageList.find(e => e.value === pageId[0])
        if (node1 && node1.children) {
          const node2 = node1.children.find(e => e.value === pageId[1])
          if (node2 && node2.children) {
            const node3 = node2.children.find(e => e.value === pageId[2])
            if (node3) {
              item.lotteryId = node3.lotteryId || null
              item.gameId = node3.gameId || null
              item.activityId = node3.activityId || null
              item.activityType = node3.activityType || null
              item.activityTitle = node3.activityTitle || null
              item.roomId = node3.roomId || null
              item.roomName = node3.roomName || null
            }
          }
        }
      }
    },
    handleChangeType() {
      if([11, 12].includes(this.ruleForm.type)) {
        this.ruleForm.popup = 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.picList {
  display: flex;
  > .left {
    padding: 10px;
    border: 1px dashed;
    flex: 1;
  }
  > .right {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 25%;
    > a {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 50px;
      font-weight: bold;
      font-size: 25px;
      &.jia {
        background: #0c2162;
      }
      &.jian {
        background: #d9001b;
      }
    }
  }
  + .picList {
    margin-top: 20px;
  }
}
.upload {
  display: flex;
  align-items: flex-start;
  color: #BCBCBC;
  > div {
    margin-top: 5px;
  }
  > i {
    margin-top: 9px;
    margin-left: 25px;
    margin-right: 5px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  .image {
    width: 100px;
    height: 100px;
  }
}
</style>