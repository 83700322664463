<template>
  <div class="chat-room">
    <div :class="{'chat-room-icon': canEdit }" @click="_clickRoomIcon">
      <el-avatar :size="58" :src="getRoomIcon()" />
    </div>
    <div class="chat-room-info" @click="_enterRoom">
      <div class="chat-room-title">
        {{ item.roomName }} ({{ item.roomNumber + (item.realCount || 0) }})
      </div>
      <div class="chat-room-desc">
        {{ item.roomInfo }}
      </div>
    </div>
    <div v-show="activeRoomId == item.roomId" class="chat-room-enter">
      <i class="el-icon-right" />
    </div>
  </div>
</template>
<script>
import football from '@/assets/public_images/ball-football.png'
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    activeRoomId: {
      type: String,
      default: ''
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // 进入房间
    _enterRoom() {
      this.$emit('enterRoom', this.item)
    },
    // 图标点击编辑
    _clickRoomIcon() {
      if (!this.canEdit) {
        return
      }

      this.$emit('clickIcon', this.item)
    },
    // 图标处理
    getRoomIcon() {
      if (this.item.roomType === 2 || this.item.roomType === 4) {
        return football
      } else {
        return this.item.roomIcon
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
  .chat-room{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(0,0,0,.05);
    &-icon {
      cursor: pointer;
    }
    &-info {
      margin-top: 8px;
      margin-left: 18px;
      padding-bottom: 10px;
      flex-direction: column;
      width:100%;
      cursor: pointer;
    }
    &-title {
      margin-bottom: 10px;
      color: rgba(16, 16, 16, 100);
      font-size: 18px;
      font-family: SourceHanSansSC-bold;
    }
    &-desc {
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      font-family: SourceHanSansSC-bold;
    }
    &-enter{
      font-size: 20px;
      position: relative;
      float: right;
      top: 20px;
    }
  }
  </style>

