import request from '@/plugins/axios'

export function getLotterySettingList(params = {}) {
  return request({
    url: '/lottery/getLotterySettingList',
    method: 'get',
    params
  })
}

export function updateLotterySettingList(data) {
  return request({
    url: '/lottery/batchUpdateLotterySetting',
    method: 'post',
    data
  })
}

export function updateOperatorEdit(data) {
  return request({
    url: '/customer/service/operator/edit',
    method: 'post',
    data
  })
}

export function selectOperator(params = {}) {
  return request({
    url: '/customer/service/operator',
    method: 'get',
    params
  })
}

export function updateSettingByType(params = {}) {
  return request({
    url: '/tenant/lottery/updateSettingByType',
    method: 'get',
    params
  })
}


export function getLotteryNavigation(params = {}) {
  return request({
    url: '/tenant/lottery/navigation',
    method: 'get',
    params
  })
}
export function getLotteryNavigationHot(params = {}) {
  return request({
    url: '/tenant/lottery/navigation/hotsetting',
    method: 'get',
    params
  })
}

export function getLotteryIcon(params = {}) {
  return request({
    url: '/lottery/game/icon',
    method: 'get',
    params
  })
}

export function updateLotteryIcon(data) {
  return request({
    url: '/lottery/game/icon/update',
    method: 'post',
    data
  })
}

export function accountChangeGetIcons(params) {
  return request({
    url: '/accountChange/getIcons',
    method: 'get',
    params
  })
}

export function accountChangeUpdateIcon(data) {
  return request({
    url: '/accountChange/updateIcon',
    method: 'post',
    data
  })
}

// 租户系列图标修改
export function updateSeriesIcon(data) {
  return request({
    url: '/series/game/icon/update',
    method: 'post',
    data
  })
}

/* 产品配置--基础配置*********************************/
// 基础配置查询
export function getBaseConfig(params = {}) {
  return request({
    url: '/tenant/getBaseConfig',
    method: 'get',
    params
  })
}

// 基础配置修改
export function editBaseConfig(data = {}) {
  return request({
    url: '/tenant/editBaseConfig',
    method: 'post',
    data
  })
}

// 交互配置查询
export function getInteractiveConfig(params = {}) {
  return request({
    url: '/tenant/getInteractiveConfig',
    method: 'get',
    params
  })
}

// 交互配置修改
export function editInteractiveConfig(data = {}) {
  return request({
    url: '/tenant/editInteractiveConfig',
    method: 'post',
    data
  })
}

// 地址配置查询
export function getAddressConfig(params = {}) {
  return request({
    url: '/tenant/getAddress',
    method: 'get',
    params
  })
}

// 地址配置修改
export function editAddressConfig(data = {}) {
  return request({
    url: '/tenant/editAddress',
    method: 'post',
    data
  })
}

export function getThirdGameSet(params = {}) {
  return request({
    url: '/thirdgame/queryThirdGameSet',
    method: 'get',
    params
  })
}

export function updateThirdGameSet(data, name) {
  return request({
    url: `/thirdgame/update${name}ThirdGameSet`,
    method: 'post',
    data
  })
}

export function getSmsConfig(params = {}) {
  return request({
    url: '/tenant/getSmsConfig',
    method: 'get',
    params
  })
}

export function saveSmsConfig(data = {}) {
  return request({
    url: '/tenant/saveSmsConfig',
    method: 'post',
    data
  })
}

export function queryCrash() {
  return request({
    url: '/queryCrash',
    method: 'get'
  })
}

export function editCrash(data = {}) {
  return request({
    url: '/editCrash',
    method: 'post',
    data
  })
}

// 汇率查询
export function getRateConfig() {
  return request({
    url: '/tenant/getRateConfig',
    method: 'get'
  })
}

// 汇率修改
export function editRateConfig(data = {}) {
  return request({
    url: '/tenant/editRateConfig',
    method: 'post',
    data
  })
}

// 租户后台获取说明描述
export function getTenantDesc(params = {}) {
  return request({
    url: '/tenant/getTenantDesc',
    method: 'get',
    params
  })
}

// 租户后台修改说明描述
export function updateTenantInfo(data = {}) {
  return request({
    url: '/tenant/updateTenantInfo',
    method: 'post',
    data
  })
}

// 查询展示配置
export function getTenantMenu(params) {
  return request({
    url: '/tenant/getTenantMenu',
    method: 'get',
    params
  })
}

// 添加展示配置
export function addMenu(data) {
  return request({
    url: '/tenant/addMenu',
    method: 'post',
    data
  })
}

// 菜单配置列表
export function menuconfigList(params) {
  return request({
    url: '/menuconfig/list',
    method: 'get',
    params
  })
}

// 菜单配置修改
export function menuconfigUpdate(data) {
  return request({
    url: '/menuconfig/update',
    method: 'post',
    data
  })
}

// 新版首页设置查询
export function homepagePlaySetting(data) {
  return request({
    url: '/homepage/play/setting',
    method: 'post',
    data
  })
}

// 新版首页设置保存单项
export function editPlaySetting(data) {
  return request({
    url: '/edit/play/setting',
    method: 'post',
    data
  })
}

// 新版首页设置保存批量
export function editPlaySettingBatch(data) {
  return request({
    url: '/batch/update/play/sort/status',
    method: 'post',
    data
  })
}

// 查看活动配置列表
export function homepagePromotionSetting(params) {
  return request({
    url: '/homepage/promotion/setting',
    method: 'get',
    params
  })
}

// 编辑活动配置
export function editpromotionSetting(data) {
  return request({
    url: '/edit/promotion/setting',
    method: 'post',
    data
  })
}

// 批量编辑活动配置状态值和排序
export function batchUpdatePromotionSortStatus(data) {
  return request({
    url: '/batch/update/promotion/sort/status',
    method: 'post',
    data
  })
}

// 修改新版娱乐城推荐活动开关
export function casinoPromotionSwitch(data) {
  return request({
    url: '/casino/promotion/switch?promotionSwitch=' + data.promotionSwitch,
    method: 'post',
    data
  })
}

// 修改娱乐城应用开关
export function casinoSwitch(data) {
  return request({
    url: '/casino/switch?casinoSwitch=' + data.casinoSwitch,
    method: 'post',
    data
  })
}

// 修改新版娱乐城推荐活动开关
export function casinoThirdSwitch(data) {
  return request({
    url: '/casino/third/switch?autoThirdSwitch=' + data.autoThirdSwitch,
    method: 'post',
    data
  })
}
