<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.payPlatformName" clearable :placeholder="$t('平台名称')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.payPlatformCode" clearable :placeholder="$t('平台标识')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.mchName" clearable :placeholder="$t('商户名')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.operatorName" clearable :placeholder="$t('操作员')" class="filter-item" style="width: 140px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">
        {{ $t('添加支付平台') }}
      </el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <edit-dialog ref="EditDialogControl" @create="handleCreateAccount" @update="hanldeUpdateAccount" />
  </div>
</template>
<script>
import EditDialog from './edit-dialog'
import { getPayWayThirdConfList, addPayWayThirdConfConf, updatePayWayThirdConfConf, delPayWayThirdConfConf } from '@/api/cash'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
export default {
  name: 'Pay',
  componentName: 'Pay',
  components: { EditDialog },
  mixins: [TablePageMixin(getPayWayThirdConfList)],
  data() {
    return {
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this)
    }
  },
  methods: {
    onCreate() {
      this.$refs.EditDialogControl.open()
    },
    handleCreateAccount({ field, close }) {
      addPayWayThirdConfConf({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('添加成功'))
        }
      })
    },

    hanldeUpdateAccount({ field, close }) {
      updatePayWayThirdConfConf({ ...field }).then(([_, err]) => {
        if (!err) {
          if (!err) {
            close()
            this.handleFetch()
            this.$message.success(this.$t('保存成功'))
          }
        }
      })
    },

    handleDeleteAccount(id) {
      this.$confirm(this.$t('确认删除此设置?'), this.$t('系统提示'), { type: 'error' }).then(() => {
        delPayWayThirdConfConf({ id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
