import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('房间主题'),
      key: 'roomName',
      align: 'center'
    },
    {
      title: this.$t('彩种类型'),
      key: 'lottypeId',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.livePreLotteryType.find(e => e.key === row.lottypeId)?.label }</span>)
        }
      }
    },
    {
      title: this.$t('彩种/赛事'),
      key: 'lotteryName',
      align: 'center'
    },
    {
      title: this.$t('分辨率'),
      key: 'displayStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.displayStatusList[row.displayStatus] }</span>)
        }
      }
    },
    {
      title: this.$t('主播昵称'),
      key: 'nickName',
      align: 'center'
    },
    {
      title: this.$t('所属家族'),
      key: 'familyName',
      align: 'center'
    },
    {
      title: this.$t('频道'),
      key: 'channelName',
      align: 'center'
    },
    // {
    //   title: this.$t('Q')'房间标签',
    //   key: 'labelName',
    //   align: 'center'
    // },
    {
      title: this.$t('房间价格'),
      key: 'payAmount',
      align: 'center'
    },
    {
      title: this.$t('房间热度'),
      key: 'hotNum',
      align: 'center'
    },
    {
      title: this.$t('房间人数'),
      key: 'onlineNum',
      align: 'center'
    },
    {
      title: this.$t('观看人次'),
      key: 'visitorsNum',
      align: 'center'
    },
    {
      title: this.$t('房费分成(预计)'),
      key: 'payroomIncome',
      align: 'center',
      width: 100
    },
    {
      title: this.$t('礼物分成(预计)'),
      key: 'giftIncome',
      align: 'center',
      width: 100
    },
    {
      title: this.$t('有效投注分成(预计)'),
      key: 'betsIncome',
      align: 'center',
      width: 100
    },
    {
      title: this.$t('直播状态'),
      key: 'roomStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.roomStatus === 1) {
            return (<el-tag type='success' effect='plain'>{this.$t('直播中')}</el-tag>)
          } else {
            return (<el-tag type='danger' effect='plain'>{this.$t('已下播')}</el-tag>)
          }
        }
      }
    },
    {
      title: this.$t('置顶状态'),
      key: 'topStatus',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.topStatus}
              disabled={!this.permission.Top}
              activeValue={1}
              inactiveValue={2}
              beforeChange={this._handleChangeTopStatus.bind(this, row)}>
              <span slot='open'>{this.$t('置顶')}</span>
              <span slot='close'>{this.$t('关闭')}</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 320,
      component: {
        render: (h, row) => {
          return (
            <span>
              <el-button
                disabled={!this.permission.Update}
                type='primary'
                size='mini'
                onClick={() => this.$refs.EditDialogControl.open(row)}>{this.$t('房间编辑')}</el-button>
              <el-button
                type='success'
                size='mini'
                onClick={this.goUser.bind(this, row)}>{this.$t('会员列表')}</el-button>
              {
                row.roomStatus === 1 && row.streamStatus !== 3 &&
                <el-button
                  type='danger'
                  size='mini'
                  onClick={this._handleKick.bind(this, row)}>{this.$t('踢下线')}</el-button>
              }
              {
                row.roomStatus === 9 && row.streamStatus === 2 &&
                <el-button
                  type='warning'
                  size='mini'
                  onClick={this.$refs.StreamDialogControl.open.bind(this, row)}
                >{this.$t('流播放')}</el-button> }
              {
                row.roomStatus === 1 && row.streamStatus === 3 &&
                <el-button
                  type='danger'
                  size='mini'
                  onClick={this._handleEndPlayStream.bind(this, row)}
                >{this.$t('结束播放')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
