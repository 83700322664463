<template>
  <el-dialog :title="title" :visible.sync="visible" width="400px" top="5vh" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-position="right" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('会员账号')">
        <p>{{ ruleForm.memberName }}</p>
      </el-form-item>
      <el-form-item :label="$t('当前打码量')">
        <p>{{ ruleForm.sumRolling }}</p>
      </el-form-item>
      <el-form-item :label="$t('出款所需打码量')">
        <p>{{ ruleForm.withdrawRolling }}</p>
      </el-form-item>
      <el-form-item label="变动类型" v-if="transType === 6">
        <el-radio-group v-model="ruleForm.transType">
          <el-radio :label="6">出款打码量</el-radio>
          <el-radio :label="2012">总打码量</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="变动类型" v-if="transType === 7">
        <el-radio-group v-model="ruleForm.transType">
          <el-radio :label="7">出款打码量</el-radio>
          <el-radio :label="2013">总打码量</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="transType === 6">
        <el-form-item :label="$t('增加打码量')" prop="rolling">
          <el-input v-model="ruleForm.rolling" label="$t('请输入需增加的打码量')" />
          <div v-if="ruleForm.transType === 2012">默认增加到手动打码量</div>
        </el-form-item>
      </template>
      <template v-if="transType === 7">
        <el-form-item :label="$t('减少打码量')" prop="rolling">
          <el-input v-model="ruleForm.rolling" :label="$t('请输入需减少的打码量')" />
          <div v-if="ruleForm.transType === 2013">只能减少手动打码量余额 {{ sumManualRolling }}</div>
        </el-form-item>
      </template>
      <el-form-item :label="$t('操作原因')">
        <el-input v-model="ruleForm.operatorRemark" type="textarea" :rows="3" maxlength="30" show-word-limit :placeholder="$t('请输入操作原因')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  transType: 6,
  rolling: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      transType: 6,
      emitName: 'update',
      sumManualRolling: 0,
      rules: {
        rolling: [
          { required: true, message: this.$t('请输入打码量'), trigger: 'blur' },
          { pattern: /^(\d|[1-9][0-9]*)+(.?\d{1,3})?$/, message: this.$t('请输入正确打码量'), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    title() {
      return this.transType === 6 ? this.$t('增加打码量') : this.$t('减少打码量')
    }
  },
  methods: {
    open({ memberName, sumRolling, withdrawRolling, sumManualRolling }, transType) {
      this.transType = transType
      this.ruleForm.transType = transType
      this.ruleForm.memberName = memberName
      this.ruleForm.sumRolling = sumRolling
      this.ruleForm.withdrawRolling = withdrawRolling
      this.sumManualRolling = Number(sumManualRolling)
      this.visible = true
    },
    handleSubmitBefor() {
      if (this.ruleForm.transType === 2013 && Number(this.ruleForm.rolling) > Number(this.sumManualRolling)) {
        return this.$message.error('只能减少手动打码量余额 ' + this.sumManualRolling)
      }
      this.handleSubmit()
    }
  }
}
</script>
