<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="search.cmd"
        placeholder="操作命令"
        class="filter-item"
      >
        <el-option v-for="(item, key) in cmd" :key="key" :label="item" :value="+key" />
      </el-select>
      <el-input v-model="search.nickname" clearable placeholder="会员昵称" class="filter-item" style="width: 200px" />
      <el-input v-model="search.memberId" clearable placeholder="会员ID" class="filter-item" style="width: 200px" />
      <el-input v-model="search.groupName" clearable placeholder="群名称" class="filter-item" style="width: 200px" />
      <el-button icon="el-icon-search" type="primary" class="filter-item" :loading="loading" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button icon="el-icon-plus" type="success" class="filter-item" @click="$refs.ActionDialogControl.open()">指定会员</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" />
  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import ActionDialog from './ActionDialog'
import GetColumns from './columns'
import { imCmdMemberList, imOperatorCmd } from '@/api/chat'

export default {
  name: 'AnnouncementStationLetter',
  components: { ActionDialog },
  mixins: [TablePageMixin(imCmdMemberList)],
  data() {
    return {
      defaultColumns: GetColumns.call(this),
      columns: [],
      search: {
        cmd: 2001
      },
      cmd: {
        1001: '群管理员永久踢人',
        1002: '群管理员短时踢人',
        1003: '群管理员取消踢人',
        2001: '群管理员永久禁言',
        2002: '群管理员短时禁言',
        2003: '群管理员取消禁言'
      }
    }
  },
  methods: {
    _handleRelieve(row) {
      this.$confirm('确认解除当前限制?', { type: 'warning' }).then(() => {
        let cmd = ''
        if ([1001, 1002].includes(row.cmd)) {
          cmd = 1003
        }
        if ([2001, 2002].includes(row.cmd)) {
          cmd = 2003
        }
        const params = {
          cmd: cmd,
          groupId: row.groupId,
          groupName: row.groupName,
          to: row.memberId,
          toNickname: row.nickname
        }
        imOperatorCmd(params).then(([_, err]) => {
          if (!err) {
            this.$message.success('解除成功')
            this.handleRefresh()
          }
        })
      })
    },

    _handleCreate({ field, cancel, close }) {
      const params = { ...field }
      delete params.memberName
      delete params.limitType
      delete params.roomType
      imOperatorCmd(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('添加成功')
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    implementationGetParams() {
      return { ...this.search }
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
