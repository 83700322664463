var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("开奖方式修改"),
        width: "600px",
        visible: _vm.visible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c("el-form-item", { attrs: { label: _vm.$t("彩种类型") } }, [
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.ruleForm.lotteryType == 10
                      ? _vm.$t("官方开奖")
                      : _vm.$t("独立开奖")
                  ) +
                  " "
              ),
            ]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("开奖获取"), prop: "lotteryMethod" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.lotteryMethod,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "lotteryMethod", $$v)
                    },
                    expression: "ruleForm.lotteryMethod",
                  },
                },
                _vm._l(_vm.$parent.methods, function (label, key) {
                  return _c(
                    "el-radio",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.ruleForm.lotteryType == 20 &&
                              label != _vm.$t("平台开奖")) ||
                            label == _vm.$t("平台开奖"),
                          expression:
                            "\n            (ruleForm.lotteryType == 20 && label != $t('平台开奖')) ||\n              label == $t('平台开奖')\n          ",
                        },
                      ],
                      key: key,
                      attrs: { label: key * 1 },
                    },
                    [_vm._v(_vm._s(label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.isKillMethod
            ? [
                _vm.$parent.operatorType === 1
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("开启规则"),
                            prop: "killNumberParamer1",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "1~7" },
                            model: {
                              value: _vm.ruleForm.killNumberParamer1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "killNumberParamer1",
                                  $$v
                                )
                              },
                              expression: "ruleForm.killNumberParamer1",
                            },
                          }),
                          _vm._v(_vm._s(_vm.$t("日投注收益")) + " "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "killNumberParamer2" } },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("最低的"))),
                          _c("el-input", {
                            attrs: { placeholder: "1~99" },
                            model: {
                              value: _vm.ruleForm.killNumberParamer2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "killNumberParamer2",
                                  _vm._n($$v)
                                )
                              },
                              expression: "ruleForm.killNumberParamer2",
                            },
                          }),
                          _vm._v(_vm._s(_vm.$t("家")) + " "),
                        ],
                        1
                      ),
                    ]
                  : _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("开启规则"),
                          prop: "killNumberParamer2",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("_7日盈利为7日投注额度的")) + " "
                        ),
                        _c("el-input", {
                          staticStyle: { width: "140px", margin: "0 6px" },
                          attrs: {
                            placeholder: _vm.$t(
                              "请输入0-100的数，且最多有两位小数!"
                            ),
                          },
                          model: {
                            value: _vm.ruleForm.killNumberParamer2,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "killNumberParamer2", $$v)
                            },
                            expression: "ruleForm.killNumberParamer2",
                          },
                        }),
                        _vm._v(" % "),
                      ],
                      1
                    ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("开奖要求"),
                      prop: "killNumberParamer3",
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("投注100最低可中")) + " "),
                    _c("el-input", {
                      staticStyle: { width: "140px", margin: "0 6px" },
                      attrs: { placeholder: "0-99999" },
                      model: {
                        value: _vm.ruleForm.killNumberParamer3,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "killNumberParamer3", $$v)
                        },
                        expression: "ruleForm.killNumberParamer3",
                      },
                    }),
                    _vm._v(" % "),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "killNumberParamer4" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("投注100最高可中")) + " "),
                    _c("el-input", {
                      staticStyle: { width: "140px", margin: "0 6px" },
                      attrs: { placeholder: "0-99999" },
                      model: {
                        value: _vm.ruleForm.killNumberParamer4,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "killNumberParamer4", $$v)
                        },
                        expression: "ruleForm.killNumberParamer4",
                      },
                    }),
                    _vm._v(" % "),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "killNumberParamer5" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("杀号时间最多"))),
                    _c("el-input-number", {
                      staticStyle: { width: "140px", margin: "0 6px" },
                      attrs: {
                        min: 5,
                        max: 3600,
                        step: 5,
                        "step-strictly": "",
                      },
                      model: {
                        value: _vm.ruleForm.killNumberParamer5,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "killNumberParamer5", $$v)
                        },
                        expression: "ruleForm.killNumberParamer5",
                      },
                    }),
                    _vm._v("秒 "),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "killSwitch",
                      label: _vm.$t("指定用户杀号"),
                    },
                  },
                  [
                    _c(
                      "d-switch",
                      {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.ruleForm.killSwitch,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "killSwitch", $$v)
                          },
                          expression: "ruleForm.killSwitch",
                        },
                      },
                      [
                        _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                          _vm._v(_vm._s(_vm.$t("开启"))),
                        ]),
                        _c(
                          "span",
                          { attrs: { slot: "close" }, slot: "close" },
                          [_vm._v(_vm._s(_vm.$t("关闭")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.ruleForm.killSwitch === 1
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "memberName",
                          label: _vm.$t("杀号用户"),
                        },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "200px" },
                            attrs: {
                              maxlength: "20",
                              placeholder: "请输入用户账号",
                            },
                            on: {
                              input: function ($event) {
                                _vm.ruleForm.memberId = ""
                              },
                            },
                            model: {
                              value: _vm.ruleForm.memberName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "memberName", $$v)
                              },
                              expression: "ruleForm.memberName",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  loading: _vm.queryLoading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getMember()
                                  },
                                },
                                slot: "append",
                              },
                              [_vm._v("查询")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" " + _vm._s(this.ruleForm.memberId) + " "),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.isPlatformMethod
            ? [
                _vm.ruleForm.platformLotteryMethod == 100
                  ? _c("div", { staticClass: "platformTipText" }, [
                      _vm._v(" 当前平台开奖方式是接口开奖 "),
                    ])
                  : _vm._e(),
                _vm.ruleForm.platformLotteryMethod == 200
                  ? _c("div", { staticClass: "platformTipText" }, [
                      _vm._v(" 当前平台开奖方式是平台开奖，平台将随机开奖 "),
                    ])
                  : _vm._e(),
                _vm.ruleForm.platformLotteryMethod == 201
                  ? _c("div", { staticClass: "platformTipText" }, [
                      _vm._v(
                        " 当前平台开奖方式是杀号开奖，平台会根据所有租户订单进行统一杀号开奖 "
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("取消"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v(_vm._s(_vm.$t("确定")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }