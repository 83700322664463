var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("编辑"),
        visible: _vm.visible,
        "close-on-click-modal": false,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "130px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { "label-width": "130px", label: _vm.$t("VIP等级") } },
            [_c("span", [_vm._v(_vm._s(_vm.ruleForm.vipLevel))])]
          ),
          _c(
            "el-form-item",
            { attrs: { "label-width": "130px", label: _vm.$t("VIP福利") } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.checkList,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "checkList", $$v)
                    },
                    expression: "ruleForm.checkList",
                  },
                },
                _vm._l(_vm.allRewardList, function (item, index) {
                  return _c(
                    "el-checkbox",
                    {
                      key: index,
                      attrs: { label: item.awardCode },
                      on: { change: _vm.onCheckBoxChanged },
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.ruleForm.awardList, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                item.isShow
                  ? [
                      !_vm.isShowZhbYhb(item)
                        ? _c(
                            "div",
                            { staticClass: "default-item" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: item.name,
                                    prop: "awardList." + index + ".value",
                                    rules: _vm.getvalueRules(item),
                                  },
                                },
                                [
                                  item.firstStr
                                    ? _c("span", {
                                        staticClass: "str1",
                                        domProps: {
                                          innerHTML: _vm._s(item.firstStr),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.isShowSrlj(item)
                                    ? [
                                        _c("el-switch", {
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                          attrs: {
                                            "active-text": "开启",
                                            "inactive-text": "关闭",
                                          },
                                          model: {
                                            value: item.open,
                                            callback: function ($$v) {
                                              _vm.$set(item, "open", $$v)
                                            },
                                            expression: "item.open",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm.hideValueItem(item)
                                    ? _c(
                                        "el-input",
                                        {
                                          staticClass: "filter-item",
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            placeholder: "请输入",
                                            clearable: "",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.handleBlur(item)
                                            },
                                          },
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.unit))]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                              item.secondStr
                                ? _c("span", {
                                    staticClass: "str2",
                                    domProps: {
                                      innerHTML: _vm._s(item.secondStr),
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "",
                                    "label-width": "50px",
                                    prop: "awardList." + index + ".bgColor",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      placeholder: "色号",
                                      clearable: "",
                                    },
                                    model: {
                                      value: item.bgColor,
                                      callback: function ($$v) {
                                        _vm.$set(item, "bgColor", $$v)
                                      },
                                      expression: "item.bgColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "图片上传",
                                    prop: "awardList." + index + ".bgImage",
                                  },
                                },
                                [
                                  _c("d-upload", {
                                    staticClass: "com-upload",
                                    attrs: {
                                      folder: "icon",
                                      "on-success": function () {
                                        var args = [],
                                          len = arguments.length
                                        while (len--) args[len] = arguments[len]

                                        return _vm.handleUploadSuccess(
                                          args,
                                          item,
                                          index
                                        )
                                      },
                                      text: "上传",
                                    },
                                    model: {
                                      value: item.bgImage,
                                      callback: function ($$v) {
                                        _vm.$set(item, "bgImage", $$v)
                                      },
                                      expression: "item.bgImage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "week-month-red-envelope" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: item.name,
                                    prop: "awardList." + index + ".value",
                                    rules: _vm.getvalueRules(item),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                    },
                                    model: {
                                      value: item.value,
                                      callback: function ($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "有效投注",
                                    prop: "awardList." + index + ".betsLimit",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                    },
                                    model: {
                                      value: item.betsLimit,
                                      callback: function ($$v) {
                                        _vm.$set(item, "betsLimit", $$v)
                                      },
                                      expression: "item.betsLimit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "有效充值",
                                    prop:
                                      "awardList." + index + ".rechargeLimit",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                    },
                                    model: {
                                      value: item.rechargeLimit,
                                      callback: function ($$v) {
                                        _vm.$set(item, "rechargeLimit", $$v)
                                      },
                                      expression: "item.rechargeLimit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "文案色值",
                                    prop: "awardList." + index + ".bgColor",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                    },
                                    model: {
                                      value: item.bgColor,
                                      callback: function ($$v) {
                                        _vm.$set(item, "bgColor", $$v)
                                      },
                                      expression: "item.bgColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "图片上传",
                                    prop: "awardList." + index + ".bgImage",
                                  },
                                },
                                [
                                  _c("d-upload", {
                                    staticClass: "com-upload",
                                    attrs: {
                                      folder: "icon",
                                      "on-success": function () {
                                        var args = [],
                                          len = arguments.length
                                        while (len--) args[len] = arguments[len]

                                        return _vm.handleUploadSuccess(
                                          args,
                                          item,
                                          index
                                        )
                                      },
                                      text: "上传",
                                    },
                                    model: {
                                      value: item.bgImage,
                                      callback: function ($$v) {
                                        _vm.$set(item, "bgImage", $$v)
                                      },
                                      expression: "item.bgImage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }