import { filterColumns } from '@/utils'
import { ftBettingStatus } from '@/libs/options'

const getStatus = (status) => {
  const tmp = ftBettingStatus.find(o => o.key === status)
  return tmp && tmp.label || ''
}

const parseResult = (value) => {
  if (!value) return {}
  return value.split(',').reduce((a, o) => {
    const [key, value] = o.split('@')
    a[key] = value
    return a
  }, {})
}

const CanChooseColumns = function() {
  return [

    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <div>
              { this.$P$ && <p class='info-type'>[{ row.tenantCode }]</p> }
              <p class='primary-type cursor-pointer hover-underline' onClick={this._onDetails.bind(this, row)}>{ row.memberAccount }</p>
              <p>{ row.memberNickname }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('赛事编号'),
      key: 'matchNumber',
      align: 'center',
      width: 80,
      component: {
        render: (h, row) => {
          return (<span>{this.$t('周')}{ this.$parseTime((new Date(row.matchTime).getTime() + new Date().getTimezoneOffset() * 60 * 1000), '{a}') }{ row.matchNumber.substring(1) }</span>)
        }
      }
    },
    {
      title: this.$t('联赛'),
      key: 'competitionName',
      align: 'center',
      width: 80
    },
    {
      title: this.$t('对阵'),
      key: 'teamName',
      align: 'center',
      minWidth: 120,
      component: {
        render: (h, row) => {
          return (<span>{ row.homeTeamName } VS { row.awayTeamName }</span>)
        }
      }
    },
    {
      title: this.$t('状态'),
      key: 'destatus',
      align: 'center',
      width: 75,
      component: {
        render: (h, row) => {
          return (<span>{ getStatus(row.destatus) }</span>)
        }
      }
    },

    {
      title: this.$t('属性'),
      key: 'betContent',
      align: 'center',
      minWidth: 200,
      component: {
        render: (h, row) => {
          let prefix;
          try {
            prefix=(row.betContent.startsWith('500'))?`${row.betContentExt}  `:'';
          } catch (error) {
            console.log(error);
          }
          if (!this.playsOptions) {
            return (<span>{prefix}{ row.betContent }{ this.permission.Update && row.isInternal === 1 && row.isPush === 1 && <el-link type='primary' style='margin-left: 10px' onClick={() => this.$refs.EditOrderDialogControl.open(row)}>编辑</el-link> }</span>)
          } else {
            const _content = parseResult(row.betContent)
            const _win = parseResult(row.winContent)
            const keys = Object.keys(_content)
            return (
              <div>
                {prefix}
                { keys.map((key, i) => (
                  <span>
                    <span class={_win[key] && 'danger-type'}>{ this.playsOptions[key] }@{ _content[key]}</span>
                    { i !== keys.length - 1 && <span>,</span> }
                  </span>
                ))
                }
                { this.permission.Update && row.isInternal === 1 && row.isPush === 1 && <el-link type='primary' style='margin-left: 10px' onClick={() => this.$refs.EditOrderDialogControl.open(row)}>编辑</el-link> }
              </div>
            )
          }
        }
      }
    },
    {
      title: this.$t('定位胆'),
      key: 'posGall',
      align: 'center',
      width: 66,
      component: {
        render: (h, row) => {
          if (row.posGall === 1) {
            return (
              <span class='posGall-column'>{this.$t('胆')}</span>
            )
          } else {
            return (<span>-</span>)
          }
        }
      }
    },
    {
      title: this.$t('串关/倍数'),
      key: 'passType',
      align: 'center',
      component: {
        render: (h, row) => {
          const angle = [2, 3, 4, 5, 6, 7, 8].every(o => row[`bunch${o}`] === 0)
          return (
            <div>
              {
                row.passType.split(',').map(o => (
                  <p>{ this.passTypeOptions[o] }</p>
                ))
              }
              {
                angle
                  ? <div>{this.$t('单关')}:{ row.betCount }</div>
                  : <div>
                    {
                      [2, 3, 4, 5, 6, 7, 8].map(o => {
                        return (row[`bunch${o}`] > 0 && <p>{o}*1:{ row[`bunch${o}`] }</p>)
                      })
                    }
                  </div>
              }
            </div>
          )
        }
      }
    },
    {
      title: this.$t('金额'),
      key: 'amount2',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{this.$t('倍数')}：{ row.times }</p>
              <p>{this.$t('抵用')}：{ row.couponAmount }</p>
              <p>{this.$t('投注')}：{ row.betAmount }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('抵用券'),
      key: 'amount',
      align: 'center',
      sortable: 'custom',
      width: 140,
      sortMethod: function(a, b) {
        return parseFloat(a.amount) - parseFloat(b.amount)
      },
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{this.$t(row.memberCouponId ? '已使用' : '未使用')}</p>
              <p>{row.memberCouponId || '--'}</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('用户盈亏'),
      key: 'profit',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p style={ row.status === 10 ? 'color:#F59A23' : '' }>{ getStatus(row.status) }</p>
              <p>{this.$t('中奖')}：{ row.winAmount }</p>
              <p>{this.$t('盈亏')}：{ row.profitAmount }</p>
            </div>
          )
        }
      }
    },
    
    {
      title: this.$t("推单订单"),
      key: "orderNo",
      align: "center",
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <div>
              <div>{row.isPush == 1 ? "是" : row.isPush == 0 ? "否" : ""}</div>
              {row.isPush==1 && <div>{row.orderNo}</div>}
            </div> 
          );
        },
      },
    },
    {
      title: this.$t('订单信息'),
      key: 'createTime',
      align: 'center',
      minWidth: 150,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{this.$t('订单号')}: { row.orderNo }</p>
              <p>{ row.clientType } { row.betsPoint === 1 && this.$t('快速购彩') }</p>
              <p>{this.$t('投注')}:{ this.$parseTime(row.createTime) }</p>
              <p>{this.$t('更新')}:{ this.$parseTime(row.updateTime) }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          const { Cancel } = this.permission
          return (Cancel && <el-button type='danger' size='mini' disabled={row.status !== 2} onClick={() => this._onCancel(row)}>{this.$t('撤单')}</el-button>)
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
