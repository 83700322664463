<template>
  <div class="app-container">
    <el-form ref="validateForm" label-position="top" label-width="80px" :model="ruleForm" :rules="rules">
      <el-form-item :label="title" prop="isShow">
        <el-radio-group v-model="ruleForm.isShow">
          <el-radio :label="1"> {{ $t('显示') }}</el-radio>
          <el-radio :label="0">{{ $t('隐藏') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('展示层级')" prop="showGroups">
        <el-checkbox-group v-model="ruleForm.showGroups">
          <el-checkbox v-for="(item, index) in showGroups" :key="index" :label="item.value"> {{ item.label }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('展示数据')" prop="showDataTypes">
        <el-checkbox-group v-model="ruleForm.showDataTypes">
          <el-checkbox v-for="(item, index) in showDataTypes" :key="index" :label="item.value"> {{ item.label }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('相关权限')">
        <div>
          <span style="margin-right: 6px;">{{ $t('点击数据查看详情') }}</span>
          <d-switch
            v-model="ruleForm.isShowDataDetail"
            size="large"
            :active-value="1"
            :inactive-value="0"
          >
            <span slot="open">开启</span>
            <span slot="close">禁止</span>
          </d-switch>
        </div>
        <div>
          <span style="margin-right: 32px;">{{ $t('查看直属下级') }}</span>
          <d-switch
            v-model="ruleForm.isShowNextLevel"
            size="large"
            :active-value="1"
            :inactive-value="0"
          >
            <span slot="open">开启</span>
            <span slot="close">禁止</span>
          </d-switch>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ $t('保存配置') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updateAgencyDataShowConfig, getAgencyDataShowConfig } from '@/api/agent-rebate.js'
export default {
  name: 'AgentRebateDataConfigItem',
  props: {
    agencyMemberType: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showGroups: this.$t('showGroups'),
      showDataTypes: this.$t('showDataTypes'),
      rules: {
        isShow: [{ required: true, message: this.$t('请选择显示隐藏'), trigger: 'blur' }],
        showGroups: [{ required: true, message: this.$t('请至少选择一项展示层级'), trigger: 'blur' }],
        showDataTypes: [{ required: true, message: this.$t('请至少选择一项展示数据'), trigger: 'blur' }]
      },
      ruleForm: {
        isShow: undefined,
        showGroups: [],
        showDataTypes: [],
        isShowDataDetail: 0,
        isShowNextLevel: 0
      }
    }
  },
  computed: {
    title() {
      if (this.agencyMemberType === 0) {
        return this.$t('普通用户')
      } else {
        return this.$t('代理用户')
      }
    }
  },
  created() {
    this.getAgencyDataShowConfig()
  },
  methods: {
    onSubmit() {
      this.ruleForm.agencyMemberType = this.agencyMemberType
      this.$refs.validateForm.validate(valid => {
        if (valid) {
          this.showGroups.forEach(group => {
            if (this.ruleForm.showGroups.includes(group.value)) {
              this.ruleForm[group.value] = 1
            } else {
              this.ruleForm[group.value] = 0
            }
          })

          this.showDataTypes.forEach(dataType => {
            if (this.ruleForm.showDataTypes.includes(dataType.value)) {
              this.ruleForm[dataType.value] = 1
            } else {
              this.ruleForm[dataType.value] = 0
            }
          })

          updateAgencyDataShowConfig(this.ruleForm).then(([data, _err]) => {
            if (!_err) {
              this.$message.success(this.$t('保存成功'))
            }
          })
        } else {
          return false
        }
      })
    },
    getAgencyDataShowConfig() {
      getAgencyDataShowConfig().then(([data, _err]) => {
        if (!_err) {
          if (data) {
            const configItem = data.find(p => p.agencyMemberType === this.agencyMemberType)
            if (configItem) {
              configItem.showGroups = []
              configItem.showDataTypes = []
              this.showGroups.forEach(group => {
                if (configItem[group.value] === 1) {
                  configItem.showGroups.push(group.value)
                }
              })
              this.showDataTypes.forEach(dataType => {
                if (configItem[dataType.value] === 1) {
                  configItem.showDataTypes.push(dataType.value)
                }
              })
              this.ruleForm = configItem
            }
          }
        }
      })
    }
  }
}
</script>

