var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("分成配置"),
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("礼物收入分成"), prop: "giftPercent" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: _vm.$t("0.1-100支持1位小数") },
                  model: {
                    value: _vm.ruleForm.giftPercent,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "giftPercent", $$v)
                    },
                    expression: "ruleForm.giftPercent",
                  },
                },
                [
                  _c("span", { attrs: { slot: "append" }, slot: "append" }, [
                    _vm._v("%"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("投注分成"), prop: "betsPercent" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: _vm.$t("0.1-100支持1位小数") },
                  model: {
                    value: _vm.ruleForm.betsPercent,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "betsPercent", $$v)
                    },
                    expression: "ruleForm.betsPercent",
                  },
                },
                [
                  _c("span", { attrs: { slot: "append" }, slot: "append" }, [
                    _vm._v("%"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("房费分成比例"), prop: "payroomPercent" },
            },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: _vm.$t("0.1-100支持1位小数") },
                  model: {
                    value: _vm.ruleForm.payroomPercent,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "payroomPercent", $$v)
                    },
                    expression: "ruleForm.payroomPercent",
                  },
                },
                [
                  _c("span", { attrs: { slot: "append" }, slot: "append" }, [
                    _vm._v("%"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }