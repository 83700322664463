import Layout from '@/layout'

export default {
  path: '/Vip',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Vip',
  alwaysShow: true,
  meta: { title: 'VIP福利管理', icon: 'product-config' },
  children: [
    {
      path: '/LevelTab',
      component: () => import('@/views/vipRewardMange/vipReward/index'),
      name: 'vipLevel',
      meta: { title: 'VIP等级管理', noCache: true }
    },
    {
      path: '/Member_Reward',
      component: () => import('@/views/vipRewardMange/MemberReward/index'),
      name: 'MemberLevel',
      meta: { title: '会员福利管理', noCache: true }
    },
    {
      path: '/Vip_RedRnvelope',
      component: () => import('@/views/vipRewardMange/redEnvelope/index'),
      name: 'Vip_RedRnvelope',
      meta: { title: 'vip红包', noCache: true }
    },
    {
      path: '/Vip_Jiebei',
      component: () => import('@/views/vipRewardMange/jiebei/index'),
      name: 'Vip_Jiebei',
      meta: { title: '借呗', noCache: true }
    },
    {
      path: '/Vip_Points',
      component: () => import('@/views/vipRewardMange/pointsManage/index'),
      name: 'Vip_Points',
      meta: { title: '积分管理', noCache: true }
    },
    // {
    //   path: '/Vip_Level',
    //   component: () => import('@/views/member/Level'),
    //   name: 'MemberLevel',
    //   meta: { title: 'VIP等级管理', noCache: true }
    // },
    {
      path: '/Vip_Game',
      component: () => import('@/views/agent-rebate/game'),
      name: 'AgentRebateGame',
      meta: { title: '游戏返水' }
    }
  ]
}

