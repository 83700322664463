var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.emitName === "create" ? "新建群公告" : "编辑群公告",
        width: "700px",
        top: "8vh",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "20",
                  placeholder: "请输入标题，最长20个字符",
                },
                model: {
                  value: _vm.ruleForm.title,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "聊天室", prop: "roomType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.roomType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "roomType", $$v)
                    },
                    expression: "ruleForm.roomType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("全部")]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("聊天室类型"),
                  ]),
                ],
                1
              ),
              _vm.ruleForm.roomType === 1
                ? _c(
                    "el-select",
                    {
                      staticStyle: { "margin-left": "20px", width: "300px" },
                      attrs: { placeholder: "请选择聊天室类型" },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.ruleForm.isRoom,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isRoom", $$v)
                        },
                        expression: "ruleForm.isRoom",
                      },
                    },
                    _vm._l(_vm.roomTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.ruleForm.roomType === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "适用范围", prop: "roomSeries" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.roomSeries,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "roomSeries", $$v)
                        },
                        expression: "ruleForm.roomSeries",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("无限")]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("适用范围"),
                      ]),
                    ],
                    1
                  ),
                  _vm.ruleForm.roomSeries === 1
                    ? _c(
                        "el-select",
                        {
                          staticStyle: {
                            "margin-left": "32px",
                            width: "300px",
                          },
                          attrs: {
                            loading: _vm.loading,
                            filterable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择适用范围聊天室",
                          },
                          model: {
                            value: _vm.ruleForm.roomList,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "roomList", $$v)
                            },
                            expression: "ruleForm.roomList",
                          },
                        },
                        _vm._l(_vm.roomList, function (item) {
                          return _c("el-option", {
                            key: item.roomId,
                            attrs: { label: item.roomName, value: item.roomId },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("是否启用"), prop: "enable" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.enable,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "enable", $$v)
                    },
                    expression: "ruleForm.enable",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("启用"))),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("关闭"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公告详情", prop: "content" } },
            [
              _c("tinymce", {
                ref: "tinymce",
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }