<template>
  <div class="singleImageUpload2 upload-container">
    <el-upload
      :accept="accept"
      :data="dataObj"
      :multiple="false"
      :show-file-list="false"
      :on-success="handleImageSuccess"
      :headers="$headers()"
      :before-upload="beforeAvatarUpload"
      class="image-uploader"
      drag
      :action="actionUrl"
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text">
        Drag {{ $t('或') }} <em>{{ $t('点击上传') }}</em>
      </div>
    </el-upload>
    <div v-show="imageUrl.length>0" class="image-preview">
      <div v-show="imageUrl.length>1" class="image-preview-wrapper">
        <img :src="imageUrl">
        <div class="image-preview-action">
          <i class="el-icon-delete" @click="rmImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleImageUpload2',
  props: {
    value: {
      type: String,
      default: ''
    },
    imageSize: {
      type: Number,
      default: 2
    },
    accept: {
      type: String,
      default: 'image/png,image/jpg,image/jpeg,image/webp,image/*'
    },
    imageType: {
      type: String,
      default: 'image'
    },
    folder: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tempUrl: ''
    }
  },
  computed: {

    dataObj() {
      return { type: this.imageType, folder: this.folder }
    },
    actionUrl() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    imageUrl() {
      return this.value
    }
  },
  methods: {
    rmImage() {
      this.emitInput('')
    },
    emitInput(val) {
      this.$emit('input', val)
    },
    handleImageSuccess(res, file) {
      this.tempUrl = res.data[0]
      this.emitInput(this.tempUrl)
    },
    beforeAvatarUpload(file) {
      const isAccept = this.accept.split(',').some(s => file.type === s)
      const isLt2M = file.size / 1024 / 1024 < this.imageSize
      if (!isAccept) {
        const imageType = []
        this.accept.split(',').forEach((item) => {
          imageType.push(item.split('/')[1].toUpperCase())
        })
        this.$message.error(`上传图片只能是 ${imageType.join('/')} 格式!`)
      }
      if (!isLt2M) {
        this.$message.error(`上传图片大小不能超过 ${this.imageSize}MB!`)
      }
      return isAccept && isLt2M
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container {
  width: 100%;
  height: 100%;
  position: relative;
  .image-uploader {
    height: 100%;
  }
  .image-preview {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    border: 1px dashed #d9d9d9;
    .image-preview-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .image-preview-action {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0, 0, 0, .5);
      transition: opacity .3s;
      cursor: pointer;
      text-align: center;
      line-height: 200px;
      .el-icon-delete {
        font-size: 36px;
      }
    }
    &:hover {
      .image-preview-action {
        opacity: 1;
      }
    }
  }
}
</style>
