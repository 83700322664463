var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "460px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("名称"), prop: "domainName" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { placeholder: _vm.$t("请输入名称以便后续跟踪") },
                model: {
                  value: _vm.ruleForm.domainName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "domainName", $$v)
                  },
                  expression: "ruleForm.domainName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("链接"), prop: "domainUrl" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { placeholder: _vm.$t("请输入链接以便生成二维码") },
                model: {
                  value: _vm.ruleForm.domainUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "domainUrl", $$v)
                  },
                  expression: "ruleForm.domainUrl",
                },
              }),
              _c(
                "el-upload",
                {
                  staticStyle: { "margin-top": "8px" },
                  attrs: {
                    "before-upload": _vm.decodeQRCode,
                    action: "/api/upload",
                    limit: 1,
                    "file-list": _vm.fileList,
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("点击上传")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("可以上传二维码图片以提取链接地址")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }