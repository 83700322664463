<template>
  <el-dropdown trigger="click" placement="bottom" @command="handleSetLanguage">
    <div>
      <svg-icon icon-class="language" />
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="(item,index) of language" :key="index" :disabled="item.lang === lang" :command="item.lang">
        <img :src="require('@/assets/locale/'+item.lang+'.png')">
        {{ item.name }}
      </el-dropdown-item>
      <!-- <el-dropdown-item :disabled="language==='en-US'" command="en-US">
        <img src="@/assets/locale/en-US.png" alt="" srcset="">
        English
      </el-dropdown-item> -->
      <!-- <el-dropdown-item :disabled="language==='vi-VN'" command="vi-VN">
        <img src="@/assets/locale/vi-VN.png" alt="" srcset="">
        Việt Nam
      </el-dropdown-item> -->
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { language } from '@/lang'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      language
    }
  },
  computed: {
    ...mapGetters(['lang'])
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.dispatch('app/setLang', lang)
      this.$message({
        message: 'Switch Language Success',
        type: 'success'
      })
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">
.el-dropdown-menu--small .el-dropdown-menu__item {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
}
</style>
