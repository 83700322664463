<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.groupName" placeholder="组别名称" class="filter-item" clearable style="width: 160px;" />
      <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleFetch">查询</el-button>
      <el-button v-if="permission.Add" icon="el-icon-plus" type="success" style="float: right" @click="onCreate">新建组别</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <ActionDialog ref="ActionDialogControl" @create="_handleCreateGroup" @update="_handleUpdateGroup" />
  </div>
</template>

<script>
import { tenantGroupList, tenantGroupCreate, tenantUpdateGroupInfo, tenantGroupDelete } from '@/api/tenant'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import ActionDialog from './components/ActionDialog.vue'
export default {
  name: 'TenantGroup',
  components: { ActionDialog },
  mixins: [TablePageMixin(tenantGroupList)],
  data() {
    return {
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogControl.open()
    },
    _handleCreateGroup({ field, cancel, close }) {
      tenantGroupCreate({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('新增成功')
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    _handleUpdateGroup({ field, cancel, close }) {
      tenantUpdateGroupInfo({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('保存成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleDelete({ id }) {
      this.$confirm('请确认当前组别没有租户才能进行删除。', '确定删除租户组别吗？', { type: 'warning' }).then(() => {
        tenantGroupDelete({ groupId: id }).then(([_, err]) => {
          if (!err) {
            this.handleFetch()
            this.$message.success(this.$t('删除成功'))
          }
        })
      })
    },
    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>

<style lang="scss">
.division {
  line-height: 32px;
  text-align: center;
  font-size: 12px;

  & > .el-col:not(:last-child) {
    border-right: 1px solid #ddd;
  }

  &-popper {
    padding: 0;
  }
  &-title {
    border-bottom: 1px solid #ddd;
  }
  &-line {
    border-right: 1px solid #ddd;
  }
}
</style>
