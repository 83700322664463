<template>
  <div class="app-container">

    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card" >
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive>
        <component :is="activeName" :optionData="optionData" />
      </keep-alive>
    </template>
  </div>
</template>

<script>

import reportAdminTab from './reportAdminTab'
import aiReportOptionTab from './aiReportOptionTab'
import { parentMixin } from '@/mixins/tab-items'

export default {
  name: 'InteractiveAIChat',
  components: { reportAdminTab, aiReportOptionTab },
  mixins: [parentMixin],
  data() {
    return {
      activeName: 'reportAdminTab',
      tabs: [
        { label: '机器人管理', name: 'reportAdminTab' },
        { label: 'AI聊天配置', name: 'aiReportOptionTab' },
      ],
      optionData: {}
    }
  },
  created() {
  },
  methods: {
  }
}
</script>
