var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "audit-form",
      staticStyle: { "padding-top": "20px" },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
          },
        },
        [
          _vm.$route.query.auditStatus === 2 && _vm.ruleForm.auditRemark
            ? _c("el-form-item", { attrs: { label: "审核拒绝原因" } }, [
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.auditRemark))]),
              ])
            : _vm._e(),
          _c("el-form-item", { attrs: { label: "昵称" } }, [
            _c("span", [_vm._v(_vm._s(_vm.ruleForm.memberName))]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "标题" } },
            [
              _c("el-input", {
                attrs: { readonly: "", placeholder: "输入标题" },
                model: {
                  value: _vm.ruleForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "正文内容" } },
            [
              _c("tinymce", {
                ref: "tinymce",
                model: {
                  value: _vm.ruleForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "隐藏内容" } },
            [
              _c("tinymce", {
                ref: "tinymce",
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.hideContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "hideContent", $$v)
                  },
                  expression: "ruleForm.hideContent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回复可见隐藏" } },
            [
              _c("d-switch", {
                attrs: { disabled: "", "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.ruleForm.replyViewStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "replyViewStatus", $$v)
                  },
                  expression: "ruleForm.replyViewStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "登录可见隐藏" } },
            [
              _c("d-switch", {
                attrs: { disabled: "", "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.ruleForm.loginViewStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "loginViewStatus", $$v)
                  },
                  expression: "ruleForm.loginViewStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "充值可见隐藏" } },
            [
              _c("d-switch", {
                attrs: { disabled: "", "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.ruleForm.payViewStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "payViewStatus", $$v)
                  },
                  expression: "ruleForm.payViewStatus",
                },
              }),
              _vm.ruleForm.payViewStatus === 1
                ? [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.instant,
                          callback: function ($$v) {
                            _vm.instant = $$v
                          },
                          expression: "instant",
                        },
                      },
                      [_vm._v("即时充值")]
                    ),
                    _c("el-input", {
                      staticStyle: { width: "120px" },
                      attrs: { readonly: "", placeholder: "金额" },
                      model: {
                        value: _vm.ruleForm.rechargeViewMoney,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "rechargeViewMoney", $$v)
                        },
                        expression: "ruleForm.rechargeViewMoney",
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm.$route.query.auditStatus === 0
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { type: "danger", size: "large" },
                      on: { click: _vm.handleOpenAuditRemark },
                    },
                    [_vm._v("拒绝")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { type: "success", size: "large" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit(1)
                        },
                      },
                    },
                    [_vm._v("通过")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("ActionDialog", {
        ref: "ActionDialogControl",
        on: { update: _vm._handleUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }