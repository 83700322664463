const CanChooseColumns = function() {
  return [
    {
      title: '头像',
      key: 'head',
      align: 'center',
      width: 60,
      component: {
        render: (h, row) => {
          return (<el-image fix='cover' src={row.headUrl} style='width: 40px; height: 40px; border-radius: 50%' />)
        }
      }
    },
    {
      title: '昵称',
      key: 'nickname',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (<span class='primary-type'>{ row.nickname }</span>)
        }
      }
    },
    {
      title: '头像地址',
      key: 'headUrl',
      align: 'center'
    },
    {
      title: '实际关注人数',
      key: 'fansNum',
      align: 'center',
      width: 100
    },
    {
      title: '增加关注人数',
      key: 'newFansNum',
      align: 'center',
      width: 100
    },
    {
      title: 'VIP等级',
      key: 'vipLevel',
      align: 'center',
      width: 100
    },
    {
      title: '状态',
      key: 'freezeStatus',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          if (row.freezeStatus === 1) {
            return (<span style='color: red'>删除</span>)
          }
          return (<span>正常</span>)
        }
      }
    },
    {
      title: '操作',
      align: 'center',
      key: 'actions',
      width: 150,
      component: {
        render: (h, row) => {
          return (
            this.permission.Update && <button class='el-button el-button--mini el-button--orange' onClick={this.$refs.EditDialogControl.open.bind(this, row)}>
              <i class='el-icon-edit' />
              <span>编辑</span>
            </button>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
