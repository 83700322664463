var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "validateForm",
      staticClass: "system-form",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "120px" },
    },
    [
      _c("h2", [_vm._v("超级管理员")]),
      _c(
        "el-form-item",
        { attrs: { label: "账号", prop: "operatorName" } },
        [
          _c("el-input", {
            model: {
              value: _vm.ruleForm.operatorName,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "operatorName", $$v)
              },
              expression: "ruleForm.operatorName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "昵称", prop: "nickName" } },
        [
          _c("el-input", {
            model: {
              value: _vm.ruleForm.nickName,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "nickName", $$v)
              },
              expression: "ruleForm.nickName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "密码", prop: "password", required: "" } },
        [
          _c("el-input", {
            model: {
              value: _vm.ruleForm.password,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "password", $$v)
              },
              expression: "ruleForm.password",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "确认密码", prop: "confirmPassword", required: "" } },
        [
          _c("el-input", {
            model: {
              value: _vm.ruleForm.confirmPassword,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "confirmPassword", $$v)
              },
              expression: "ruleForm.confirmPassword",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }