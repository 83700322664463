<template>
  <div>
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 360px"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始时间')"
        :end-placeholder="$t('结束时间')"
        :clearable="false"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, -1)">{{ $t('明天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, -7)">{{ $t('_7天') }}</el-button>

      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Read" class="filter-item" type="primary" @click="$refs.ActionDialogContorl.open()">数据读取配置</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <template #left>
        <el-select
          v-model="search.sourceType"
          :placeholder="$t('数据来源')"
          clearable
          style="width: 120px"
          class="filter-item"
        >
          <el-option v-for="(item, index) in sourceType" :key="index" :label="item.label" :value="item.value" />
        </el-select>
        <el-select
          v-model="search.sportType"
          :placeholder="$t('体育项目')"
          clearable
          style="width: 120px"
          class="filter-item"
        >
          <el-option v-for="(val, key) in sportTypes" :key="key" :label="val" :value="+key" />
        </el-select>
        <el-select
          v-model="search.position"
          placeholder="首页位置"
          clearable
          style="width: 120px"
          class="filter-item"
        >
          <el-option v-for="(val, key) in queryPositions" :key="key" :label="val" :value="+key" />
        </el-select>
        <el-input
          v-model="search.competitionName"
          :placeholder="$t('Interactive_Match.ForecastTab.赛事名称')"
          clearable
          style="width: 160px"
          class="filter-item"
        />
        <el-input
          v-model="search.teamName"
          :placeholder="$t('Interactive_Match.ForecastTab.球队名称')"
          clearable
          style="width: 160px"
          class="filter-item"
        />
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <!-- <el-dialog :title="this.$t(' Interactive_Match.ForecastTab.设置排序')" :visible.sync="visibleSetSort" width="800px"
      @open="onOpen">

    </el-dialog> -->

    <el-dialog :visible.sync="visibleSetSort" title="首页排序设置" width="400px" center>
      <p class="sort-dialog">
        <span>*首页位置</span>
        <el-select v-model="position" style="width: 200px" class="filter-item">
          <el-option v-for="(val, key) in queryPositions" :key="key" :label="val" :value="+key" />
        </el-select>
      </p>
      <p class="sort-dialog">
        <span>*排序</span>
        <el-input v-model="sortVal" placeholder="最小1,最大999. 999为不展示" onkeyup="value=value.replace(/[^\d]/g,'')" style="width: 200px;" />
      </p>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="visibleSetSort = false">{{ $t('取消') }}</el-button>
          <el-button type="primary" @click="setSort">
            {{ $t('确定') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <ActionDialog ref="ActionDialogContorl" @update="handleSportconfigUpdate" />
  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getThirdMatchList, setSort, sportconfigUpdate } from '@/api/match'
import { mapGetters } from 'vuex'
import { getDefaultTimes, parseTime } from '@/utils'
import ActionDialog from './ActionDialog.vue'
import { sportTypes } from '@/libs/options'

export default {
  name: 'ThirdMatchHomeTab',
  componentName: 'ThirdMatchHomeTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(getThirdMatchList, true)],
  data() {
    return {
      times: getDefaultTimes(),
      filterColumn: false,
      columns: GetColumns.call(this),
      search: { sourceType: 1, sportType: 1 },
      sourceType: this.$t('sourceType'),
      sortVal: null, // 热门排序
      position: null,
      currentRow: null, // 点编辑时的当前行
      visibleSetSort: false, // 设置排序
      sportTypes,
      positions: { 1: '热门', 2: '精选', 3: '滚球', 4: '即将' },
      queryPositions: { 2: '精选', 3: '滚球', 4: '即将' }
    }
  },
  computed: {
    ...mapGetters(['tenantCode'])
  },
  watch: {
    'search.sportType': {
      handler(n, o) {
        insetparams.sportType = n
      }
    }
  },
  methods: {
    implementationGetParams() {
      const startMatchTime = parseTime(this.times[0])
      const endMatchTime = parseTime(this.times[1])
      return {
        startMatchTime,
        endMatchTime,
        ...this.search,
        tenantCode: this.tenantCode
      }
    },
    handleOpenSort(row) {
      this.currentRow = row
      if (this.queryPositions[row.position]) {
        this.position = row.position
      }
      this.sortVal = row.firstOrder
      this.visibleSetSort = true
    },
    async setSort() {
      if (!this.currentRow) return
      const { thirdMatchId, tenantCode, sourceType } = this.currentRow
      // if (!(/^[1-9][1-9][1-9]$/).test(this.sortVal)) {
      //   this.$message.error('排序值范围1-999')
      //   return
      // }
      if (this.sortVal < 1 || this.sortVal > 999 || isNaN(this.sortVal)) {
        this.$message.error('排序值范围1-999');
        return;
      }
      if (this.position === null) {
        this.$message.error('请选择首页位置');
        return;
      }
      let result = await setSort({ thirdMatchId, tenantCode, sourceType, position: this.position, sort: this.sortVal, sportType: this.search.sportType, firstOrder: this.sortVal })
      let isok = result[2] && result[2].data && result[2].data.success
      this.handleFetch()
      this.visibleSetSort = false
      this.$message.success(this.$t('操作' + (isok ? '成功' : '失败')))
    },
    handleSportconfigUpdate({ field, cancel, close }) {
      const list = []
      for(let key in field) {
        list.push({ configKey: key, configValue: field[key] })
      }
      sportconfigUpdate({ list }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    }
  }
}
</script>
<style lang="scss">
.sort-dialog {
  display: flex;
  align-items: center;
  margin-bottom:8px;
  // margin-bottom: 10px;

  span {
    margin-right: 10px;
    display: inline-block;
    width: 80px;
    text-align: right;
    // font-weight: bold;
  }

  .el-input {
    width: 200px;
    margin-right: 10px;

    input {
      height: 32px;
    }
  }
}
</style>
