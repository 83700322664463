<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive>
        <component :is="activeName" ref="AliveComponent" />
      </keep-alive>
    </template>
  </div>
</template>
<script>
import TopTab from './TopTab'
import HomeTab from './HomeTab'
import IconTab from './IconTab'
import IconAmountTab from './IconAmountTab'
import ContentTab from './ContentTab'
import CasinoTab from './CasinoTab'
import CityTab from './CityTab'
import FooterMenuTab from './FooterMenuTab'
import LiuheTab from './LiuheTab'
import XinshuiTab from './XinshuiTab'
import NewHomeTab from './NewHomeTab'
import NewBallTopTab from './NewBallTopTab'
import NewActivityTab from './NewActivityTab'
import { parentMixin } from '@/mixins/tab-items'

export default {
  components: {
    TopTab,
    HomeTab,
    IconTab,
    IconAmountTab,
    ContentTab,
    CasinoTab,
    CityTab,
    FooterMenuTab,
    LiuheTab,
    XinshuiTab,
    NewHomeTab,
    NewBallTopTab,
    NewActivityTab
  },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      tabs: this.$t('displayTabs')
    }
  }
}
</script>
