<template>
  <el-dialog
    :title="$t('审核提示')"
    width="500px"
    :visible.sync="visible"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <h3 style="text-align: center;">{{ $t('确认审核拒绝吗？') }}</h3>
      <el-form-item :label="$t('拒绝原因')" prop="auditRemark">
        <el-input
          v-model="ruleForm.auditRemark"
          type="textarea"
          :rows="5"
          :placeholder="$t('请输入拒绝原因')"
          maxlength="50"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('取消') }}</el-button>
      <el-button
        type="primary"
        :loading="submitting"
        @click="handleSubmit"
      >{{ $t('确 定') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  auditStatus: 2,
  auditRemark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        auditRemark: [{ required: true, message: this.$t('请输入拒绝原因'), trigger: 'blur' }]
      }
    }
  },
  methods: {
    open() {
      this.visible = true
    }
  }
}
</script>
