<template>
  <div>
    <div class="filter-container">
      <div class="filter-container-item">
        <el-input v-model="nickname" clearable placeholder="发帖昵称" class="filter-item" style="width: 160px" />
        <button v-if="permission.Choose" class="filter-item el-button el-button--small el-button--orange" @click="handleSelectRecommend">选择帖子</button>
      </div>
    </div>
    <div class="filter-container">
      <div class="filter-container-item">
        <el-date-picker
          v-model="times"
          type="datetimerange"
          style="width: 400px;"
          range-separator="至"
          start-placeholder="开始日期"
          class="filter-item"
          end-placeholder="结束日期"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
        />
        <el-input v-model="search.title" class="filter-item" placeholder="标题" style="width: 160px" />
        <el-input v-model="search.commentNickname" class="filter-item" placeholder="昵称" style="width: 160px" />
        <el-select v-model="search.source" placeholder="数据来源" clearable class="filter-item" style="width: 140px">
          <el-option label="IOS用户" value="IOS" />
          <el-option label="ANDROID用户" value="ANDROID" />
          <el-option label="H5用户" value="H5" />
          <el-option label="Web用户" value="WEB" />
        </el-select>
        <el-select v-model="search.lotteryId" placeholder="类别" clearable class="filter-item" style="width: 140px">
          <el-option v-for="(val, key) in xsLottery" :key="key" :value="key" :label="val" />
        </el-select>
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      @selection-change="handleSelectionChange"
    >
      <template slot="left">
        <button v-if="permission.BatchDel" class="el-button el-button--small el-button--orange" :disabled="!selection.length" @click="handleBatchDeleteComment">批量删除</button>
        <button v-if="permission.BatchLimit" class="el-button el-button--small el-button--orange" :disabled="!selection.length" @click="handleBatchLimit">批量限制</button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <CommentDialog ref="CommentDialogControl" @update="handleComment" />
    <BatchCommentDialog ref="BatchCommentDialogControl" @update="handleBatchComment" />
  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { CommentDialog, BatchCommentDialog } from './components'
import { getCommentList, deleteComment, updateCommentCreate, updateRecommendLimit, batchCommentCreate, bacthRecommendLimit } from '@/api/interactive'
import { getDefaultTimes } from '@/utils'
import { xsLottery } from '@/libs/options'
export default {
  name: 'CommentTab',
  componentName: 'CommentTab',
  components: { CommentDialog, BatchCommentDialog },
  mixins: [TablePageMixin(getCommentList)],
  data() {
    return {
      times: getDefaultTimes(),
      filterColumn: false,
      nickname: '',
      search: {},
      columns: GetColumns.call(this),
      selection: [],
      xsLottery
    }
  },
  methods: {
    _onCommand(command, row) {
      switch (command) {
        case 0:
          this.$refs.CommentDialogControl.open(row)
          break
        case 1:
          this.handleDeleteComment(row)
          break
        case 2:
          this.handleUpdateLimit(row)
          break
      }
    },
    handleSelectRecommend() {
      if (!this.nickname) {
        this.$message.warning('请输入昵称')
        return
      }

      let params = { nickname: this.nickname }

      this.$refs.BatchCommentDialogControl.open(params)
    },

    handleComment({ field, cancel, close }) {
      updateCommentCreate({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success('添加评论成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleDeleteComment({ id }) {
      let params = { commentIds: [id] }

      return new Promise((resolve) => {
        this.$confirm('确定删除此评论', '系统提示', { type: 'warning' }).then(() => {
          deleteComment(params).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success('删除成功')
              this.handleFetch()
            }
          }).catch(() => {})
        })
      })
    },

    handleUpdateLimit({ memberId }) {
      let data = { memberIds: [memberId] }
      return new Promise((resolve) => {
        this.$confirm('确定限制此用户发帖跟评论', '系统提示', { type: 'warning' }).then(() => {
          updateRecommendLimit(data).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
              this.handleFetch()
            }
          }).catch(() => {})
        })
      })
    },

    handleBatchComment({ field, cancel, close }) {
      let data = {
        commentNickname: field.commentNickname,
        content: field.content,
        postIds: field.selections.map(o => o.id)
      }
      batchCommentCreate(data).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success('添加评论成功')
        } else {
          cancel()
        }
      })
    },

    handleBatchDeleteComment() {
      let params = { commentIds: this.selection.map(o => o.id) }
      deleteComment(params).then(([data, err]) => {
        if (!err) {
          this.$message.success('批量删除成功')
          this.handleFetch()
        }
      })
    },

    handleBatchLimit() {
      let data = {}
      data.memberIds = this.selection.map(o => o.memberId)
      bacthRecommendLimit(data).then(([data, err]) => {
        if (!err) {
          this.$message.success('批量限制操作成功')
          this.handleFetch()
        }
      })
    },

    handleSelectionChange(selection) {
      this.selection = selection
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    }
  }
}
</script>
