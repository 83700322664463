import { filterColumns } from '@/utils'
import { sportMatchStatus } from '@/libs/options'

function getScoreText(h, a) {
  return h > a ? '胜' : h === a ? '平' : '负'
}

const CanChooseColumns = function() {
  return [
    {
      title: this.$t('联赛'),
      key: 'competitionName',
      align: 'center'
    },
    {
      title: this.$t('主队'),
      key: 'homeTeamName',
      align: 'center'
    },
    {
      title: this.$t('客队'),
      key: 'awayTeamName',
      align: 'center'
    },
    {
      title: this.$t('开赛时间'),
      key: 'matchTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.matchTime) }</span>)
        }
      }
    },
    {
      title: this.$t('比赛状态'),
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          const tmp = sportMatchStatus[row.status]
          return (
            <div>
              <span style='font-size: 16px'>{ row.homeScore }:{ row.awayScore }</span>
              { tmp && <span class={`${tmp.type}-type`} style='font-size: 14px'>&nbsp;&nbsp;{ tmp.label }</span> }
            </div>
          )
        }
      }
    },
    {
      title: this.$t('上半场/下半场'),
      key: 'half',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <p>
              <span>{ row.status > 1 && row.status < 9 ? getScoreText(row.homeHalfScore, row.awayHalfScore) : this.$t('未') }</span>/
              <span>{ row.status > 3 && row.status < 9 ? getScoreText(row.homeScore, row.awayScore) : this.$t('未') }</span>
            </p>
          )
        }
      }
    },
    {
      title: this.$t('赔率改变'),
      key: 'changeContent',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.changeContent && row.changeContent || '--' }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 180,
      component: {
        render: (h, row) => {
          const { Invalid, Update } = this.permission
          return (
            <span>
              { Invalid && <el-button type='danger' size='mini' disabled={row.lotteryStatus === 0} onClick={() => this._handleInvalid(row)}>{this.$t('无效')}</el-button> }
              { Update && <el-button type='warning' size='mini' onClick={() => this.$refs.ChangeOddsDialogControl.open(row)}>{this.$t('变赔')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}

