const showSeriesTag = ['10', '11', '12', '13', '15', '20', '21']
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('彩种'),
      key: 'name',
      align: 'center'
    },
    {
      title: this.$t('启停'),
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch v-model={row.status} active-value={1} inactive-value={0} disabled={!this.permission.StartStop}>
              <span slot='close'>{this.$t('停用')}</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('显示隐藏'),
      key: 'showStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch v-model={row.showStatus} active-value={1} inactive-value={0} disabled={!this.permission.OpenClose}>
              <span slot='open'>{this.$t('显示')}</span>
              <span slot='close'>{this.$t('隐藏')}</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: this.$t('直播间开关'),
      key: 'liveShowStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch v-model={row.liveShowStatus} activeValue={1} inactiveValue={0} active-color='#13CE66' />
          )
        }
      }
    },
    {
      title: this.$t('玩家人数（虚拟）'),
      key: 'virtualNumber',
      align: 'center',
      hide: !showSeriesTag.includes(this.seriesTag),
      tips: '娱乐城玩家人数=玩家人数（虚拟）+ 玩家人数（实际）',
      component: {
        render: (h, row) => {
          return (
            <el-input v-model={row.virtualNumber} type='number' />
          )
        }
      }
    },
    {
      title: this.$t('最高奖金'),
      key: 'maxBalance',
      align: 'center',
      hide: !showSeriesTag.includes(this.seriesTag),
      tips: '娱乐城最高奖金=当前最高奖金',
      component: {
        render: (h, row) => {
          return (
            <el-input v-model={row.maxBalance} type='number' />
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          return (<el-link type='primary' onClick={this._handleUpdate.bind(this, row)}>{this.$t('保存修改')}</el-link>)
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
