<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="search.gameType" clearable :placeholder="$t('请选择游戏类型')" class="filter-item" @change="handleSearch">
        <el-option v-for="(item, key) in defaultGameType" :key="key" :label="item" :value="key" />
      </el-select>
      <el-button class="filter-item" type="primary" plain :disabled="selection.length === 0" @click="batchUpdate">{{ $t('批量修改') }}</el-button>

    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :options="options"
      :filter-column="filterColumn"
      selection-row
      :columns="columns"
      @selection-change="handleSelectionChange"
    >
      <template slot="left">
        <span v-if="permission.Switch" class="total-switch">{{ $t('返水总开关') }}：
          <d-switch
            v-model="totalSwitch"
            :active-value="1"
            :inactive-value="0"
            active-color="#3894FF"
            inactive-color="#F26161"
            :before-change="totalSwitchChange"
          >
            <span slot="open">{{ $t('启用') }}</span>
            <span slot="close">{{ $t('停用') }}</span>
          </d-switch>
        </span>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>

<script>
import { getGameAgentList, updateGameAgent, getAllStatus, updateAllStatus } from '@/api/agent-rebate.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
export default {
  name: 'AgentRebateGame',
  mixins: [TablePageMixin(getGameAgentList)],
  data() {
    return {
      search: {}, // 查询参数
      filterColumn: false,
      columns: GetColumns.call(this),
      selection: [], // 勾选的列表
      totalSwitch: 1, // 总开关
      defaultGameType: this.$t('defaultGameType') // 发送类型
    }
  },
  created() {
    this._getTotalSwitch()
  },
  methods: {
    totalSwitchChange() { // 修改返水总开关状态
      return new Promise((resolve) => {
        this.$confirm(this.$t('确定修改返水总开关状态?'), { type: 'warning' }).then(() => {
          let params = { status: this.totalSwitch === 0 ? 1 : 0 }
          updateAllStatus(params).then(([_, err]) => {
            if (!err) {
              this.$message.success(this.$t('操作成功'))
              resolve()
            }
          })
        }).catch(() => {})
      })
    },

    batchUpdate() { // 批量修改
      this.selection.length > 0 ? this._handleUpdate() : this.$message.warning(this.$t('请勾选需要修改的数据！'))
    },

    _handleUpdate(row) { // 修改
      const msg = row ? this.$t('确认修改 lotteryName 的返水信息?', { text: row.lotteryName }) : this.$t('确认批量修改勾选的返水信息?')
      this.$confirm(msg, { type: 'warning' }).then(() => {
        const arr = row ? [row] : [...this.selection]
        let params = []
        let pass = true
        arr.forEach((item) => {
          const { lotteryId, backRate, status } = item
          params.push({ lotteryId, backRate, status })
          if (!/(^(\d|[1-9]\d)(\.\d{1,3})?$)|(^100(\.0{1,3})?$)/.test(backRate)) pass = false
        })
        if (pass) {
          updateGameAgent(params).then(([_, err]) => {
            if (!err) {
              this.handleFetch()
              this.selection = []
              this.$message.success(this.$t('操作成功'))
            }
          })
        } else {
          this.$message.error(this.$t('返水比例请输入0-100的数，且最多有三位小数的数值'))
        }
      }).catch(() => {})
    },

    handleSelectionChange(selection) { // 批量选择
      this.selection = [...selection]
    },

    implementationGetParams() {
      return { ...this.search }
    },

    _getTotalSwitch() {
      getAllStatus().then(([data, err]) => {
        if (!err) this.totalSwitch = data.backProfitStatus || 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .total-switch{ line-height: 40px; display: inline-block; height: 40px;}
</style>
