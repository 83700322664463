<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        value-format="yyyy-MM-dd HH:mm:ss"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0, false)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1, false)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7, false)">{{ $t('_7天') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearchWrap">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-dropdown class="filter-item" trigger="click" placement="bottom-start" @command="handleCommand">
        <el-button type="primary">
          {{ $t('批量修改') }}<i class="el-icon-arrow-down el-icon--right" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-if="permission.UpdateVip" :command="0">{{ $t('批量修改会员等级') }}</el-dropdown-item>
          <el-dropdown-item v-if="permission.StartStop" :command="1">{{ $t('批量修改用户状态') }}</el-dropdown-item>
          <el-dropdown-item v-if="permission.UpdateAgent" :command="2">{{ $t('批量修改上级代理') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="filter-container">
      <el-select v-model="search.memberType" clearable :placeholder="$t('用户类型')" class="filter-item" style="width:160px">
        <el-option v-for="item in memberTypes" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-select v-model="search.vipLevel" clearable :placeholder="$t('VIP等级')" class="filter-item" style="width: 160px">
        <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
      </el-select>
      <el-select
        v-if="permission.StartStop"
        v-model="search.freezeStatus"
        clearable
        :placeholder="$t('用户状态')"
        class="filter-item"
        style="width: 160px"
      >
        <el-option :label="$t('启用')" :value="0" />
        <el-option :label="$t('停用')" :value="1" />
      </el-select>
      <el-select 
        v-model="search.withdrawStatus"
        clearable
        :placeholder="$t('是否提现')"
        class="filter-item"
        style="width: 160px"
      >
        <el-option :label="$t('否')" :value="0" />
        <el-option :label="$t('是')" :value="1" />
      </el-select>
      <el-select
        v-model="search.onlineStatus"
        clearable
        :placeholder="$t('在线状态')"
        class="filter-item"
        style="width: 160px"
      >
        <el-option :label="$t('在线')" :value="1" />
        <el-option :label="$t('离线')" :value="0" />
      </el-select>
      <el-select v-model="search.rechargeStatus" clearable placeholder="充值状态" class="filter-item" style="width: 160px">
        <el-option label="一次充值" :value="1" />
        <el-option label="二次充值" :value="2" />
        <el-option label="多次充值" :value="3" />
      </el-select>
    </div>
    <div class="filter-container">
      <el-input
        v-model="search.memberName"
        clearable
        :placeholder="$t('会员账号')"
        class="filter-item"
        style="width: 160px"
      />
      <el-input
        v-model="search.agencyUsername"
        clearable
        :placeholder="$t('所属上级')"
        class="filter-item"
        style="width: 160px"
      />
      <el-input
        v-model.number="search.balance"
        v-onlynumber
        clearable
        :placeholder="$t('用户余额大于')"
        class="filter-item"
        style="width: 160px"
        @paste.native.capture.prevent
      />
      <el-input
        v-model.number="search.thanRecharge"
        v-onlynumber
        clearable
        :placeholder="$t('充值金额大于')"
        class="filter-item"
        style="width: 160px"
        @paste.native.capture.prevent
      />
      <el-input
        v-model.number="search.lessRecharge"
        v-onlynumber
        clearable
        :placeholder="$t('充值金额小于')"
        class="filter-item"
        style="width: 160px"
        @paste.native.capture.prevent
      />
    </div>
    <div class="filter-container">
      <el-input
        v-model.number="search.notRechargeDay"
        v-onlynumber
        clearable
        :placeholder="$t('最近多少天未充值')"
        class="filter-item"
        style="width: 160px"
        @paste.native.capture.prevent
      />
      <el-input
        v-model.number="search.notLoginDay"
        v-onlynumber
        clearable
        :placeholder="$t('最近多少天未登陆')"
        class="filter-item"
        style="width: 160px"
        @paste.native.capture.prevent
      />
      <el-input
        v-model.number="search.loginDay"
        v-onlynumber
        clearable
        :placeholder="$t('最近多少天登陆过')"
        class="filter-item"
        style="width: 160px"
        @paste.native.capture.prevent
      />
      <el-input v-model="search.remark" clearable :placeholder="$t('备注内容')" class="filter-item" style="width: 160px" />
      <el-input
        v-model="keyWord"
        clearable
        :placeholder="search.key === 3 ? $t('手机号') : $t('关键词')"
        class="filter-item input-with-select"
        :class="{ 'key-word': search.key === 3 }"
        style="width: 290px;"
      >
        <el-input
          v-if="search.key === 3"
          slot="prefix"
          v-model="areaCode"
          clearable
          :placeholder="$t('区号')"
          style="width: 80px;"
        />
        <el-select slot="prepend" v-model="search.key" clearable style="width: 130px">
          <el-option v-for="item in keywordsMaps" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-input>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      row-key="memberId"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
      @selection-change="handleSelectionChange"
    >
      <template slot="left">
        <div class="statistics">
          <span>{{ $t('注册人数') }}：{{ statistics.totalPerson }}&nbsp;&nbsp;</span>
          <span>{{ $t('首充人数') }}：{{ statistics.totalRecharge
          }}&nbsp;&nbsp;</span>
          <span>{{ $t('测试账户') }}：{{ statistics.totalTest }}&nbsp;&nbsp;</span>
          <span>（需带入时间才可统计）</span>
        </div>
      </template>
      <template #right>
        <el-button v-if="permission.Register" class="filter-item" type="primary" @click="$refs.RegisterDialogControl.open()">注册内部账号</el-button>
        <el-button plain type="primary" class="filter-item" icon="el-icon-download" @click="_handleExport">{{ $t('导出Excel') }}</el-button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <user-dialog ref="UserDialogControl" />
    <edit-dialog ref="EditDialogControl" @update="handleUpdateUserInfo" />
    <batch-edit-dialog
      ref="BatchEditDialogControl"
      @update-level="handleBatchUpdateLevel"
      @update-status="handleBatchUpdateStatus"
      @update-agency="handleBatchUpdateAgency"
    />
    <send-dialog ref="SendDialogDialogControl" @create="hanldeCreateStationletter" />
    <proxy-dialog ref="ProxyDialogControl" @update="handleUpdateAgency" />
    <level-dialog ref="LevelDialogControl" @update="handleUpdateLevel" />
    <password-dialog ref="PasswordDialogControl" @update="handleChangePwd" />
    <remark-dialog ref="RemarkDialogControl" @update="handleUpdateRemark" />
    <GoogleDialog ref="GoogleDialog" @validate="handleGoogleDialog"></GoogleDialog>
    <RegisterDialog ref="RegisterDialogControl" @register="handleUserCenterRegister"></RegisterDialog>
  </div>
</template>
<script>
import md5 from 'js-md5'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getAllVipLevels } from '@/api/app'
import { getDefaultTimes, exportExcel } from '@/utils'
import {
  getAllUsersByCondition,
  getAllUsersByConditionCount,
  updateUserInfo,
  updateVipLevel,
  updatePassword,
  updateFreezeStatus,
  updateAgent,
  addRemark,
  batchUpdateFreezeStatus,
  batchUpdateVipLevel,
  batchUpdateAgent,
  updateMemberLastLoginIp,
  downloadUserInfo,
  userCenterRegister
} from '@/api/member'
import { addStationletter } from '@/api/announcement'
import UserDialog from '@/views/components/UserDialog'
import RemarkDialog from '@/views/components/RemarkDialog'
import {
  EditDialog,
  BatchEditDialog,
  SendDialog,
  LevelDialog,
  PasswordDialog,
  ProxyDialog,
  RegisterDialog
} from './components'
import GoogleDialog from '@/views/components/GoogleDialog'

export default {
  name: 'MemberUsers',
  components: {
    UserDialog,
    BatchEditDialog,
    SendDialog,
    ProxyDialog,
    LevelDialog,
    EditDialog,
    PasswordDialog,
    RemarkDialog,
    GoogleDialog,
    RegisterDialog
  },
  mixins: [TablePageMixin(getAllUsersByCondition)],
  data() {
    return {
      timer: null,
      selection: [],
      columns: [],
      defaultColumns: GetColumns.call(this),
      memberTypes: this.$t('memberTypes'),
      keywordsMaps: this.$t('keywordsMaps'),
      statistics: {},
      times: getDefaultTimes('timestamp', 0, 0, false),
      search: {
        freezeStatus: 0,
        withdrawStatus:null
      },
      vipLevels: [],
      areaCode: '',
      keyWord: ''
    }
  },

  mounted() {
    this.implementationBeforeFetch()
  },

  methods: {
    handleSearchWrap() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.handleSearch()
      }, 500)      
    },
    handleSearch() {
      this.search.value = this.keyWord
      if (this.search.key === 3) {
        if (this.areaCode !== '' && this.keyWord !== '') {
          this.search.value = `+${this.areaCode}_${this.keyWord}`
        }
      }
      this.handleRefresh()
    },

    handleSelectionChange(selection) {
      this.selection = selection
    },
    handleChangeStatus({ memberId, freezeStatus }) { // 修改用户冻结状态
      return new Promise((resolve) => {
        this.$confirm(`${this.$t('确认')} ${freezeStatus ? this.$t('启用') : this.$t('停用')} ${this.$t('此用户')}`, this.$t('系统提示'), { type: 'warning' }).then(() => {
          updateFreezeStatus({
            memberId,
            status: (freezeStatus + 1) % 2
            // status: ~freezeStatus + 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => { })
      })
    },

    handleUpdateUserInfo({ field, cancel, close }) { // 修改用户基本信息
      updateUserInfo({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleUpdateAgency({ field, cancel, close }) {
      updateAgent({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleUpdateLevel({ field, cancel, close }) { // 修改用户等级
      updateVipLevel({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleChangePwd({ field, cancel, close }) { // 修改用户登录或提款密码
      delete field.confirmNewPwd

      // if (field.pwdType === 0) {
      // }
      field.newPwd = md5(this.$store.state.user.VUE_APP_MD5_KEY + field.newPwd)

      updatePassword({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
        } else {
          cancel()
        }
      })
    },

    handleUpdateRemark({ field, cancel, close }) { // 修改用户备注
      addRemark({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('添加成功'))
        } else { cancel() }
      })
    },

    _handleExport() {
      this.$refs.GoogleDialog.open({ googleCode: '' })
    },
    handleGoogleDialog({ field, cancel, close }) {
      const query = { ...this.implementationGetParams() }
      query.googleCode = field.googleCode
      query.downloadKeys = '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19.20,21,22,23,24,25'
      downloadUserInfo(query).then(([data, error, blobData]) => {
        if (!error) {
          close()
          this.$refs.GoogleDialog.handleColse()
          exportExcel(blobData, `用户中心-${this.$parseTime(new Date())}.csv`)
        } else {
          cancel()
        }
      })
    },

    hanldeCreateStationletter({ field, cancel, close }) { // 发送站内信
      addStationletter({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('发送成功'))
        } else { cancel() }
      })
    },

    handleBatchUpdateLevel({ field, cancel, close }) { // 批量修改用户等级
      field.memberList = field.memberList.split(',').filter(Boolean)

      if (field.memberList.length > 100) {
        this.$message.success(this.$t('请输入少于100条数据'))
        return
      }

      batchUpdateVipLevel({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleBatchUpdateStatus({ field, cancel, close }) { // 批量修改用户状态
      field.memberList = field.memberList.split(',').filter(Boolean)
      if (field.memberList.length > 100) {
        this.$message.success(this.$t('请输入少于100条数据'))
        return
      }
      batchUpdateFreezeStatus({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleBatchUpdateAgency({ field, cancel, close }) { // 批量上级代理
      const { memberList, ...other } = field
      const params = {
        ...other,
        memberNameList: memberList.split(',').filter(Boolean)
      }

      if (params.memberNameList.length > 100) {
        this.$message.success(this.$t('请输入少于100条数据'))
        return
      }
      batchUpdateAgent({ ...params }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleUserCenterRegister({ field, cancel, close }) { // 注册内部账号
      field.password = md5(this.$store.state.user.VUE_APP_MD5_KEY + field.password)
      userCenterRegister({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleCommand(type) {
      this.$refs.BatchEditDialogControl.open(this.selection, type)
    },

    go(row, path) {
      this.$router.replace({ path: '/redirect' + path, query: { memberName: row.memberName }})
    },

    _onDetails({ memberId }) {
      if (!this.permission.Details) return
      this.$refs.UserDialogControl.open(memberId)
    },

    _onSend({ memberName }) {
      if (!this.permission.Send) return
      this.$refs.SendDialogDialogControl.open(memberName)
    },

    _onUpdateAgent(row) {
      this.$refs.ProxyDialogControl.open(row)
    },

    _onUpdateVip(row) {
      if (!this.permission.UpdateVip) return
      this.$refs.LevelDialogControl.open(row)
    },

    _onRemark(row) {
      if (!this.permission.Remark) return
      this.$refs.RemarkDialogControl.open(row)
    },

    implementationBeforeFetch() {
      getAllVipLevels().then(([data, err]) => {
        if (!err) {
          this.vipLevels = data
        }
      })
    },

    implementationFetched() {
      // 统计信息另外获取
      getAllUsersByConditionCount(this.implementationGetParams()).then(([data]) => {
        Object.entries(data).forEach(([k, v]) => (data[k] = (v || 0)))
        this.statistics = Object.assign({}, this.statistics, data)
      })
    },

    implementationGetParams() {
      return this.formatIntervalTime()
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    async resetIP({ memberId }) {
      let res = await this.$confirm('是否确认重置IP').catch(() => { })
      if (res !== 'confirm') return
      res = await updateMemberLastLoginIp({ memberId })
      let isok = res[2] && res[2].data && res[2].data.success
      await this.handleFetch()
      if (isok) this.$message.success('重置成功')
      else this.$message.error('重置失败')
    }
  }
}
</script>

<style lang="scss" scoped>
.statistics{
  display: flex;
  gap:24px;
}
.key-word {
  ::v-deep .el-input--prefix .el-input__inner {
    padding-left: 15px;
  }

  ::v-deep .el-input--small .el-input__inner {
    padding-left: 15px;
  }

  :nth-child(2) {
    margin-left: 80px;
    padding-left: 15px;
  }

  :nth-child(3) {
    left: 0;
  }

  :nth-child(4) {
    right: -75px;
  }
}
</style>
