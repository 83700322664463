<template>
  <el-dialog :title="$t('绑定第三方账户')" :visible.sync="visible" width="500px" @open="handleOpen" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('商户名称')" prop="paychannelId">
        <el-select v-model="ruleForm.paychannelId" :placeholder="$t('请选择支付平台')">
          <el-option v-for="item in platList" :key="item.id" :label="item.payPlatformName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('支付方式')" prop="paywayId">
        <el-select v-model="ruleForm.paywayId" :placeholder="$t('请选择会员等级')">
          <el-option v-for="item in payMetodList" :key="item.paywayId" :label="item.paywayName" :value="item.paywayId" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('货币种类')" prop="rateType">
        <el-select v-model="ruleForm.rateType" :placeholder="$t('请选择货币种类')">
          <el-option v-for="item in rateTypes" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('充值金额类型')" prop="isType">
        <el-select
          v-model="ruleForm.isType"
          :placeholder="$t('请选择充值金额类型')"
          style="width: 240px"
        >
          <el-option :label="$t('充值范围金额')" :value="0" />
          <el-option :label="$t('充值固定金额')" :value="1" />
        </el-select>
        <span class="validate-tip">{{ $t("收款账户分配到那种方式") }}</span>
      </el-form-item>
      <template v-if="ruleForm.isType == 0">
        <el-form-item :label="$t('最低额度')" prop="minAmount">
          <el-input
            v-model="ruleForm.minAmount"
            style="width: 240px"
            :placeholder="$t('默认100')"
          />
          <span class="validate-tip">{{
            $t("当前收款账户支持的最低充值金额，默认为100元")
          }}</span>
        </el-form-item>
        <el-form-item :label="$t('最高额度')" prop="maxAmount">
          <el-input v-model="ruleForm.maxAmount" style="width: 240px" />
          <span class="validate-tip">{{ $t("当前收款账户收款最高额度") }}</span>
        </el-form-item>
      </template>
      <template v-if="ruleForm.isType == 1">
        <el-form-item :label="$t('固定额度')" prop="periodAmount">
          <el-input v-model="ruleForm.periodAmount" style="width: 240px" />
          <span class="validate-tip">{{
            $t(`当前收款账户支持的固定金额，多个金额以","隔开`)
          }}</span>
        </el-form-item>
      </template>
      <!-- <el-form-item :label="$t('最低额度')" prop="minAmount">
        <el-input v-model="ruleForm.minAmount" :placeholder="$t('默认100')" />
      </el-form-item>
      <el-form-item :label="$t('最高额度')" prop="maxAmount">
        <el-input v-model="ruleForm.maxAmount" :placeholder="$t('设置0为不限制')" />
      </el-form-item> -->
      <el-form-item :label="$t('渠道码')" prop="payChannel">
        <el-input v-model="ruleForm.payChannel" />
      </el-form-item>
      <el-form-item :label="$t('赠送比例')" prop="givePercent">
        <el-input v-model="ruleForm.givePercent" />
      </el-form-item>
      <el-form-item :label="$t('序号')" prop="sort">
        <el-input v-model.number="ruleForm.sort" :placeholder="$t('数值越大，排序越靠前')" />
      </el-form-item>
      <el-form-item :label="$t('VIP等级')" prop="vipLevel">
        <el-select v-model="ruleForm.vipLevel" multiple collapse-tags style="width: 100%">
          <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('是否启用')" required>
        <el-radio-group v-model="ruleForm.status">
          <el-radio :label="0">{{ $t('启用') }}</el-radio>
          <el-radio :label="1">{{ $t('不启用') }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { rateTypes } from '@/libs/options'
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  paychannelId: '',
  paywayId: '',
  minAmount: '',
  maxAmount: '',
  payChannel: '',
  givePercent: '',
  sort: null,
  vipLevel: [],
  isType: null,
  periodAmount: null,
  status: 0,
  rateType: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      platList: [],
      payMetodList: [],
      rules: {
        paychannelId: [{ required: true, message: this.$t('请选择支付平台'), trigger: 'change' }],
        paywayId: [{ required: true, message: this.$t('请选择支付方式'), trigger: 'change' }],
        rateType: [{ required: true, message: this.$t('请选择货币种类'), trigger: 'change' }],
       
        payChannel: [{ required: true, message: this.$t('渠道码'), trigger: 'blur' }],
        givePercent: [{ required: true, message: this.$t('赠送比例'), trigger: 'blur' }],
        vipLevel: [{ required: true, message: this.$t('请选择VIP等级'), trigger: 'change' }],
        status: [{ required: true, message: this.$t('请选择状态'), trigger: 'change' }],
        sort: [
          { required: true, message: this.$t('请输入序号'), trigger: 'blur' },
          { pattern: /^(0|[1-9][0-9]*)$/, message: this.$t('请输入正确序号'), trigger: 'blur' }
        ]
      },
      rateTypes: rateTypes
    }
  },
  computed: {
    vipLevels() {
      return this.$parent.vipLevels
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm.id = row.id
        this.ruleForm.paychannelId = row.paychannelId
        this.ruleForm.paywayId = row.paywayId
        this.ruleForm.minAmount = row.minAmount
        this.ruleForm.maxAmount = row.maxAmount
        this.ruleForm.payChannel = row.payChannel
        this.ruleForm.givePercent = row.givePercent
        this.ruleForm.isType = row.isType;
        this.ruleForm.periodAmount = row.periodAmount;
        this.ruleForm.status = row.status
        this.ruleForm.sort = row.sort
        this.ruleForm.vipLevel = row.vipLevel.split(',').map(o => parseInt(o))
        this.ruleForm.rateType = row.rateType
        this.emitName = 'update'
      }
      this.visible = true
    },
    handleOpen() {
      this.platList = this.$parent.platList
      this.payMetodList = this.$parent.payMetodList
    }
  }
}
</script>
