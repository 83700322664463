import { filterColumns } from "@/utils";
const CanChooseColumns = function () {
  return [
    {
      title: "账户/昵称/发布时间",
      key: "memberName",
      align: "center",
      width: 300,
      component: {
        render: (h, row) => {
          return (
            <div style="text-align: left; padding-left: 40px">
              <p style="margin-bottom: 10px">账户: {row.memberName}</p>
              <p style="margin-bottom: 10px">昵称: {row.nickname}</p>
              <p>发布时间: {this.$parseTime(row.createTime)}</p>
            </div>
          );
        },
      },
    },
    {
      title: "内容",
      key: "content",
      align: "center",
      component: {
        render: (h, row) => {
          if (row.imgList) {
            const imgList = row.imgList.split(",");
            return (
              <div style="text-align: left">
                <div style="padding: 5px 0">{row.content}</div>
                {imgList.length > 0 && (
                  <el-button
                    type="text"
                    onClick={() => this._lookImg(imgList, row.nickname)}
                  >
                    查看图片
                  </el-button>
                )}
              </div>
            );
          } else {
            return (
              <div style="text-align: left">
                <div style="padding: 5px 0">{row.content}</div>
              </div>
            );
          }
        },
      },
    },
    {
      title: "状态",
      key: "status",
      width: 80,
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <div style="color: red">
              {row.freezeStatus ? <p>锁定</p> : "未锁定"}
              {/* {row.lockStatus && <p>锁定</p>} */}
            </div>
          );
        },
      },
    },
    {
      title: "操作",
      key: "actions",
      align: "center",
      width: 240,
      component: {
        render: (h, row) => {
          const { Reply, Del } = this.permission;
          return (
            <div style="display:flex;gap:8px;justify-content:center;flex-wrap:wrap;">
              {Reply && (
                <el-button
                  type="primary"
                  style="width: 80px; margin-bottom: 10px"
                  size="mini"
                  onClick={() => this._reply(row)}
                >
                  回复({row.replyNum})
                </el-button>
              )}
              <br />
              {Del && (
                <el-button
                  type="primary"
                  style="width: 80px; margin-bottom: 10px"
                  size="mini"
                  onClick={() => this._delete(row)}
                >
                  删帖
                </el-button>
              )}
              <br />
              {
                <el-button
                  type="primary"
                  style="width: 80px; margin-bottom: 10px"
                  size="mini"
                  onClick={() => this.handleBatchLimit(row)}
                >
                  {["彩讯禁言", "解除禁言"][row.freezeStatus || 0]}
                </el-button>
              }
              <br />
              {/* {<el-button type='primary' style='width: 80px; margin-bottom: 10px' size='mini' onClick={() => this.handleBatchLock((row.lockStatus + 1) % 2, row.id)}>{['锁帖禁评', '解锁评论'][row.lockStatus || 0]}</el-button>}<br /> */}
              {
                <el-button
                  type="primary"
                  style="width: 80px; margin-bottom: 10px"
                  size="mini"
                  onClick={() => this.handleBatchLimit1(row)}
                >
                  {["锁帖禁评", "解锁评论"][row.muteStatus || 0]}
                </el-button>
              }
              <br />
            </div>
          );
        },
      },
    },
  ];
};

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}
