var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "730px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("支付名称"), prop: "accountName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入支付名称，最多50个字",
                  maxlength: "50",
                },
                model: {
                  value: _vm.ruleForm.accountName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "accountName", $$v)
                  },
                  expression: "ruleForm.accountName",
                },
              }),
              _c("span", { staticClass: "validate-tip" }, [
                _vm._v(
                  _vm._s(_vm.$t("便于区分和记忆，用于前端配置收款账户使用"))
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("支付方式图标"), prop: "paywayId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: _vm.$t("请选择支付方式图标") },
                  model: {
                    value: _vm.ruleForm.paywayId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "paywayId", $$v)
                    },
                    expression: "ruleForm.paywayId",
                  },
                },
                _vm._l(_vm.paymentWayConfList, function (item) {
                  return _c("el-option", {
                    key: item.paywayId,
                    attrs: { label: item.paywayName, value: item.paywayId },
                  })
                }),
                1
              ),
              _c("span", { staticClass: "validate-tip" }, [
                _vm._v(_vm._s(_vm.$t("前端展示图标"))),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("客服方式"), prop: "paymentCustomerType" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: _vm.$t("请选择客服方式") },
                  on: { change: _vm.handleChangeCustomerType },
                  model: {
                    value: _vm.ruleForm.paymentCustomerType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "paymentCustomerType", $$v)
                    },
                    expression: "ruleForm.paymentCustomerType",
                  },
                },
                _vm._l(_vm.paymentCustomerTypes, function (val, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: val, value: +key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.paymentCustomerType === 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("三方客服地址"),
                    prop: "paymentCustomerAddress",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入三方客服地址" },
                    model: {
                      value: _vm.ruleForm.paymentCustomerAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "paymentCustomerAddress", $$v)
                      },
                      expression: "ruleForm.paymentCustomerAddress",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("充值金额类型"), prop: "isType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: _vm.$t("请选择充值金额类型") },
                  model: {
                    value: _vm.ruleForm.isType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "isType", $$v)
                    },
                    expression: "ruleForm.isType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: _vm.$t("固定金额"), value: 1 },
                  }),
                  _c("el-option", {
                    attrs: { label: _vm.$t("范围金额"), value: 0 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.isType === 0
            ? _c(
                "el-form-item",
                { staticClass: "amount", attrs: { label: _vm.$t("范围金额") } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { prop: "minAmount", "label-width": "0" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("最低金额") },
                        model: {
                          value: _vm.ruleForm.minAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "minAmount", $$v)
                          },
                          expression: "ruleForm.minAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "120px", "margin-left": "10px" },
                      attrs: { prop: "maxAmount", "label-width": "0" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("最高金额") },
                        model: {
                          value: _vm.ruleForm.maxAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "maxAmount", $$v)
                          },
                          expression: "ruleForm.maxAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.ruleForm.isType === 1
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("固定金额"), prop: "periodAmount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "支持的固定金额，多个金额以“,”隔开" },
                    model: {
                      value: _vm.ruleForm.periodAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "periodAmount", $$v)
                      },
                      expression: "ruleForm.periodAmount",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("上限额度"), prop: "limitAccount" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("请输入上限额度") },
                model: {
                  value: _vm.ruleForm.limitAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "limitAccount", $$v)
                  },
                  expression: "ruleForm.limitAccount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("排序"), prop: "sort" } },
            [
              _c("el-input", {
                attrs: { placeholder: "排序由大到小" },
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sort", _vm._n($$v))
                  },
                  expression: "ruleForm.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("VIP等级"), prop: "vipLevel" } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", "collapse-tags": "" },
                  model: {
                    value: _vm.ruleForm.vipLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "vipLevel", $$v)
                    },
                    expression: "ruleForm.vipLevel",
                  },
                },
                _vm._l(_vm.vipLevels, function (item) {
                  return _c("el-option", {
                    key: item.vipLevel,
                    attrs: {
                      label: "VIP" + item.vipLevel,
                      value: item.vipLevel,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("货币种类"), prop: "rateType" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    "collapse-tags": "",
                    placeholder: _vm.$t("请选择货币种类"),
                  },
                  model: {
                    value: _vm.ruleForm.rateType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "rateType", $$v)
                    },
                    expression: "ruleForm.rateType",
                  },
                },
                _vm._l(_vm.rateTypes, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("是否启用"), prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("启用"))),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("不启用"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("关闭"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v(_vm._s(_vm.$t("保存")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }