import { rateTypes } from '@/libs/options'
import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('平台名称'),
      key: 'payPlatformName',
      align: 'center'
    },
    {
      title: this.$t('平台标识'),
      key: 'payPlatformCode',
      align: 'center'
    },
    {
      title: this.$t('商户名'),
      key: 'mchName',
      align: 'center'
    },
    {
      title: this.$t('币种'),
      key: 'rateType',
      align: 'center',
      component: {
        render: (h, row) => {
          const rateType = rateTypes.find(v => v.key === row.rateType)
          let rateTypeStr = ''
          if (rateType) {
            rateTypeStr = rateType.label
          }
          return (
            <span>{ rateTypeStr }</span>
          )
        }
      }
    },
    {
      title: this.$t('网关'),
      key: 'thirdPayGateway',
      align: 'center',
      showOverflowTooltip: true
    },
    {
      title: this.$t('回调地址'),
      key: 'notifyUrl',
      align: 'center',
      showOverflowTooltip: true
    },
    {
      title: 'MD5key',
      key: 'md5Key',
      align: 'center',
      showOverflowTooltip: true
    },
    {
      title: this.$t('RSA公钥'),
      key: 'publicKey',
      align: 'center',
      showOverflowTooltip: true
    },
    {
      title: this.$t('RSA私钥'),
      key: 'privateKey',
      align: 'center',
      showOverflowTooltip: true
    },
    {
      title: this.$t('IP白名单'),
      key: 'whiteIp',
      align: 'center'
    },
    {
      title: this.$t('修改时间'),
      key: 'updateTime',
      align: 'center',
      width: 125,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('创建时间'),
      key: 'createTime',
      align: 'center',
      width: 125,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作员'),
      align: 'center',
      key: 'operatorName',
      width: 100
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 180,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission
          return (
            <span>
              { Update && <el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={() => this.$refs.EditDialogControl.open(row)}>{this.$t('编辑')}</el-button> }
              { Delete && <el-button type='danger' size='mini' icon='el-icon-delete' onClick={this.handleDeleteAccount.bind(this, row.id)}>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
