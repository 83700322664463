var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticClass: "tab-header",
          attrs: { flex: "main:justify cross:bottom" },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.nickname))]),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                icon: "el-icon-arrow-left",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c(
        "d-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "DTable",
          attrs: {
            "selection-row": "",
            data: _vm.items,
            columns: _vm.columns,
            options: _vm.options,
            "filter-column": _vm.filterColumn,
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("template", { slot: "left" }, [
            _c(
              "button",
              {
                staticClass: "el-button el-button--small el-button--orange",
                attrs: { disabled: !_vm.selection.length },
                on: { click: _vm.handleBatchHidden },
              },
              [_vm._v("批量隐藏")]
            ),
            _c(
              "button",
              {
                staticClass: "el-button el-button--small el-button--orange",
                attrs: { disabled: !_vm.selection.length },
                on: { click: _vm.handleBatchLimit1 },
              },
              [_vm._v("批量锁帖")]
            ),
            _c(
              "button",
              {
                staticClass: "el-button el-button--small el-button--orange",
                attrs: { disabled: !_vm.selection.length },
                on: { click: _vm.handleBatchLimit },
              },
              [_vm._v("批量限制")]
            ),
          ]),
        ],
        2
      ),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }