const CanChooseColumns = function() {
  return [
    {
      title: '租户简称',
      key: 'tenantCode',
      align: 'center',
      width: 100
    },
    {
      title: '租户名称',
      key: 'tenantName',
      align: 'center',
      width: 120
    },
    {
      title: '账单日期',
      key: 'statementDate',
      align: 'center',
      width: 120
    },
    {
      title: '彩票抽成',
      key: 'betsShareIncome',
      align: 'center'
    },
    {
      title: '礼物抽成',
      key: 'giftShareIncome',
      align: 'center'
    },
    {
      title: '房费抽成',
      key: 'payroomShareIncome',
      align: 'center'
    },
    {
      title: '美颜服务',
      key: 'beautyCost',
      align: 'center'
    },
    {
      title: '服务器费用',
      key: 'serverCost',
      align: 'center'
    },
    {
      title: '维护费用',
      key: 'maintenanceCost',
      align: 'center'
    },
    {
      title: '应收账款',
      key: 'receivableAccount',
      align: 'center'
    },
    {
      title: '实收账款',
      key: 'receiptsAccount',
      align: 'center'
    },
    {
      title: '收款状态',
      key: 'status',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (<span>{ row.status === 2 ? '已缴' : '代收' }</span>)
        }
      }
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          const { GenerateBill, ConfirmReceipt } = this.permission
          if (row.status === 2) {
            return (
              <el-button type='success' icon='el-icon-search' size='mini' onClick={this.onAction.bind(this, row, 'review')}>查看</el-button>
            )
          } else {
            return (
              <span>
                { GenerateBill && <el-button type='primary' size='mini' onClick={this.$refs.UpdateDialogControl.open.bind(this, row, 'review')}>编辑账单</el-button> }
                { ConfirmReceipt && <el-button type='danger' size='mini' onClick={this.onAction.bind(this, row, 'confirm')}>确认收款</el-button> }
              </span>
            )
          }
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
