<template>
  <div class="app-container">
    <div class="filter-container">
      <el-cascader
        v-model="search.type2"
        :options="thirdGames"
        :props="{ expandTrigger: 'hover' }"
        @change="onChangeCascaderType"
      ></el-cascader>
    </div>

    <div class="filter-container">
      <el-select
        v-model="search.timeType"
        placeholder="时间类型"
        class="filter-item"
        style="width: 160px"
      >
        <el-option label="投注时间" :value="0" />
        <el-option label="结算时间" :value="1" />
      </el-select>
      <el-date-picker
        v-model="times"
        clearable
        type="datetimerange"
        class="filter-item"
        style="width: 400px"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-input
        v-model="search.memberName"
        clearable
        :placeholder="$t('平台用户账号')"
        class="filter-item"
        style="width: 160px"
      />
      <el-input
        v-model="search.competitionName"
        clearable
        :placeholder="$t('联赛选择')"
        class="filter-item"
        style="width: 160px"
        v-if="isSports"
      />
      <el-input
        v-model="search.teamName"
        clearable
        :placeholder="$t('球队选择')"
        class="filter-item"
        style="width: 160px"
        v-if="isSports"
      />
      <!-- <el-autocomplete
        v-if="isSports"
        value-key="label"
        v-model="search.competitionName"
        :fetch-suggestions="querySearchAsync1"
        placeholder="联赛选择"
        @select="(item) => handleSelect(item, 'competitionName')"
      ></el-autocomplete>
      <el-autocomplete
        v-if="isSports"
        value-key="label"
        v-model="search.teamName"
        :fetch-suggestions="querySearchAsync2"
        placeholder="球队选择"
        @select="(item) => handleSelect(item, 'teamName')"
      ></el-autocomplete> -->
    </div>
    <div class="filter-container">
      <el-input
        v-model="search.uname"
        clearable
        :placeholder="$t('三方用户账号')"
        class="filter-item"
        style="width: 160px"
      />
      <el-input
        v-model="search.orderNo"
        clearable
        :placeholder="$t('注单号')"
        class="filter-item"
        style="width: 160px"
      />
      <el-input
        v-model="search.gameName"
        class="filter-item"
        clearable
        :placeholder="$t('游戏名称')"
        style="width: 160px"
      />
      <el-select
        v-model="search.orderStatus"
        clearable
        placeholder="订单状态"
        class="filter-item"
        style="width: 160px"
      >
        <el-option
          v-for="(val, key) in orderStatus"
          :key="key"
          :label="val"
          :value="key"
        />
      </el-select>
      <el-button
        class="filter-item"
        :disabled="!search.type"
        type="primary"
        icon="el-icon-search"
        @click="handleSearchWrap"
        >{{ $t("查询") }}</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-refresh-right"
        @click="resetSearch"
        >{{ $t("重置") }}</el-button
      >
    </div>
    <div v-show="!isSports">
      <d-table
        ref="DTable"
        v-loading="loading"
        :data="resource"
        :columns="columns"
        :options="options"
        :filter-column="filterColumn"
        :span-method="objectSpanMethod"
      >
        <template slot="left">
          {{ $t("总订单数") }}：{{ statistics ? (statistics.numberCount || 0) : '***' }}&nbsp;&nbsp;
          {{ $t("总投注额") }}：{{ statistics ? (statistics.allbetCount || 0) : '***' }}&nbsp;&nbsp;
          {{ $t("有效投注额") }}：{{ statistics ? (statistics.betCount || 0) : '***' }}&nbsp;&nbsp;
          {{ $t("中奖金额") }}：{{
            statistics ? (Number(statistics.sumWinCount || 0).toFixed(3)) : '***'
          }}&nbsp;&nbsp;
          <template v-if="![24, 36, 27, 28].includes(search.type)">
            {{ $t("玩家输赢") }}：{{
              statistics ? (
                (Number(statistics.sumWinCount || 0) -
                Number(statistics.betCount || 0)
              ).toFixed(3)) : '***'
            }}&nbsp;&nbsp;
          </template>
          <template v-else>
            {{ $t("玩家输赢") }}：{{
              statistics ? (statistics.profitCount || 0) : '***'
            }}&nbsp;&nbsp;
          </template>
          <!-- {{ $t('中奖金额') }}：{{ Number(statistics.profitCount).toFixed(3) || 0 }}&nbsp;&nbsp; 
        {{ $t('玩家输赢') }}：{{ statistics.profitCount || 0 }}&nbsp;&nbsp;  -->
          {{ $t("玩家数量") }}：{{ statistics ? (statistics.accountNumber || 0) : '***' }}&nbsp;&nbsp; 
          <el-link type="primary" :disabled="!search.type" @click="getStatisticsData">查看结果</el-link>
        </template>
        <template #right>
          <el-button
            plain
            type="primary"
            :disabled="!search.type"
            :loading="excelLoading1"
            icon="el-icon-download"
            @click="_handleExport"
            >{{ $t("导出Excel") }}</el-button
          >
          <el-button
            plain
            type="primary"
            :disabled="!search.type"
            :loading="excelLoading2"
            icon="el-icon-download"
            @click="_handleExportTotal"
            >{{ $t("汇总导出") }}</el-button
          >
        </template>
      </d-table>

      <pagination
        :page.sync="params.currentPage"
        :limit.sync="params.pageSize"
        :total="total"
        @pagination="handleFetch"
      />
    </div>
    <div v-show="isSports">
      <d-table
        ref="DTable"
        v-loading="loading"
        :data="resource"
        :columns="columns2"
        :options="options"
        :filter-column="filterColumn"
        :span-method="objectSpanMethod"
      >
        <template slot="left">
          {{ $t("总订单数") }}：{{ statistics ? (statistics.numberCount || 0) : '***' }}&nbsp;&nbsp;
          {{ $t("总投注额") }}：{{ statistics ? (statistics.allbetCount || 0) : '***' }}&nbsp;&nbsp;
          {{ $t("有效投注额") }}：{{ statistics ? (statistics.betCount || 0) : '***' }}&nbsp;&nbsp;
          {{ $t("中奖金额") }}：{{
            statistics ? (Number(statistics.sumWinCount || 0).toFixed(3)) : '***'
          }}&nbsp;&nbsp;
          <!-- <template v-if="search.type != 24">
            {{ $t('玩家输赢') }}：{{ statistics ? ((Number(statistics.sumWinCount|| 0)- Number(statistics.allbetCount|| 0)).toFixed(3)) : '***' }}&nbsp;&nbsp;
          </template>
          <template v-else>
            {{ $t('玩家输赢') }}：{{ statistics ? (statistics.profitCount || 0) : '***' }}&nbsp;&nbsp;
          </template> -->
          <!-- {{ $t('中奖金额') }}：{{ statistics ? (Number(statistics.profitCount).toFixed(3) || 0) : '***' }}&nbsp;&nbsp;-->
          {{ $t("玩家输赢") }}：{{ statistics ? (statistics.profitCount || 0) : '***' }}&nbsp;&nbsp;
          {{ $t("玩家数量") }}：{{ statistics ? (statistics.accountNumber || 0) : '***' }}&nbsp;&nbsp; 
          <el-link type="primary" :disabled="!search.type" @click="getStatisticsData">查看结果</el-link>
        </template>
        <template #right>
          <el-button
            plain
            type="primary"
            :disabled="!search.type"
            :loading="excelLoading1"
            icon="el-icon-download"
            @click="_handleExport"
            >{{ $t("导出Excel") }}</el-button
          >
          <el-button
            plain
            type="primary"
            :disabled="!search.type"
            :loading="excelLoading2"
            icon="el-icon-download"
            @click="_handleExportTotal"
            >{{ $t("汇总导出") }}</el-button
          >
        </template>
      </d-table>
      <pagination
        :page.sync="params.currentPage"
        :limit.sync="params.pageSize"
        :total="total"
        @pagination="handleFetch"
      />
    </div>
  </div>
</template>
<script>
import {
  getThirdOrderList,
  getThirdOrderCount,
  exportThirdOrderList,
  queryOrderDetail,
  queryOrderCompetition,
  queryOrderTeam,
  downThirdgameQueryOrderMemberCount,
} from "@/api/orders";
import TablePageMixin from "@/mixins/table-page-mixin";
import GetColumns from "./columns";
import GetColumns2 from "./columns2";
import { getDefaultTimes, exportExcel, getTodayTimestamps } from "@/utils";
import { orderStatus } from "@/libs/options";

export default {
  name: "ThirdOrders",
  mixins: [TablePageMixin(getThirdOrderList, false)],
  data() {
    return {
      timer: null,
      isSports: false,
      times: getDefaultTimes(),
      excelLoading1: false,
      excelLoading2: false,
      search: { timeType: 0, type2: "" },
      statistics: null,
      filterColumn: false,
      resource: [],
      columns: GetColumns.call(this),
      columns2: GetColumns2.call(this),
      orderStatus,
      gameTypeMap: {
        AG厅: 2,
        PP厅: 12,
        BBIN真人: 19,
        BG视讯: 34,
        PM真人: 38,
        开元棋牌: 3,
        乐游系列: 17,
        BBIN棋牌: 23,
        美天棋牌: 29,
        AG捕鱼: 7,
        BBIN大师: 22,
        CQ捕鱼: 25,
        乐游捕鱼: 26,
        MT捕鱼: 30,
        SG捕鱼: 32,
        BG捕鱼: 35,
        开元捕鱼: 40,
        JDB捕鱼: 42,
        JDB: 6,
        CQ9: 16,
        PG: 18,
        美天: 31,
        SG: 33,
        MG: 37,
        PP: 39,
        FG: 41,
        PS: 43,
        AWC赛马: 13,
        PM电竞: 15,
        "188体育": 8,
        "9B体育": 24,
        沙巴体育: 27,
        IM体育: 28,
        FB体育: 36,
      },
      thirdGames: [
        {
          value: "",
          label: "真人荷官",
          children: [
            {
              value: "2",
              label: "AG厅",
            },
            {
              value: "12",
              label: "PP真人",
            },
            {
              value: "19",
              label: "BBIN真人",
            },
            {
              value: "34",
              label: "BG视讯",
            },

            {
              value: "38",
              label: "DB真人",
            },
          ],
        },
        {
          value: "",
          label: "棋牌",
          children: [
            {
              value: "3",
              label: "开元棋牌",
            },
            {
              value: "17",
              label: "乐游棋牌",
            },
            {
              value: "23",
              label: "BBIN棋牌",
            },
            {
              value: "29",
              label: "MT棋牌",
            },
          ],
        },
        {
          value: "",
          label: "捕鱼",
          children: [
            {
              value: "7",
              label: "AG捕鱼",
            },
            {
              value: "20",
              label: "BBIN达人",
            },
            {
              value: "22",
              label: "BBIN大师",
            },
            {
              value: "25",
              label: "CQ捕鱼",
            },
            {
              value: "26",
              label: "乐游捕鱼",
            },
            {
              value: "30",
              label: "MT捕鱼",
            },
            {
              value: "32",
              label: "SG捕鱼",
            },
            {
              value: "35",
              label: "BG捕鱼",
            },
            {
              value: "40",
              label: "开元捕鱼",
            },

            {
              value: "42",
              label: "JDB捕鱼",
            },
          ],
        },
        {
          value: "",
          label: "老虎机",
          children: [
            {
              value: "6",
              label: "JDB",
            },
            {
              value: "16",
              label: "CQ9电子",
            },
            {
              value: "18",
              label: "PG电子",
            },
            {
              value: "31",
              label: "MT电子",
            },
            {
              value: "33",
              label: "SG电子",
            },
            {
              value: "37",
              label: "MG电子",
            },
            {
              value: "39",
              label: "PP电子",
            },
            {
              value: "41",
              label: "FG电子",
            },
            {
              value: "43",
              label: "PS电子",
            },
          ],
        },
        {
          value: "",
          label: "竞技游戏",
          children: [
            {
              value: "13",
              label: "AWC赛马",
            },
            {
              value: "15",
              label: "DB电竞",
            },
          ],
        },
        {
          value: "sports",
          label: "体育",
          children: [
            {
              value: "8",
              label: "188体育",
            },
            {
              value: "24",
              label: "9B体育",
            },
            {
              value: "27",
              label: "沙巴体育",
            },
            {
              value: "28",
              label: "IM体育",
            },
            {
              value: "36",
              label: "FB体育",
            },
          ],
        },
      ],
      competitionNames: [],
      teamNames: [],
    };
  },
  mounted() {},
  activated() {
    const { orderNo, startTime, lotteryName } = this.$route.query;
    if (orderNo) this.search.orderNo = orderNo;
    if (startTime) {
      this.search.startTime = getTodayTimestamps(startTime).startOfDay;
      this.times.splice(0, 1, getTodayTimestamps(startTime).startOfDay);
      this.search.endTime = getTodayTimestamps(startTime).endOfDay;
      this.times.splice(1, 1, getTodayTimestamps(startTime).endOfDay);
      this.search.type = this.gameTypeMap[lotteryName] * 1;
      let arr = [8, 24, 27, 28, 36];
      this.search.type2 = [
        arr.includes(this.gameTypeMap[lotteryName]) ? "sports" : "",
        String(this.gameTypeMap[lotteryName]),
      ];
    }
    if (this.search.orderNo) {
      this.handleSearch();
    }
  },
  methods: {
    handleSearchWrap() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.handleSearch();
      }, 500);
    },
    querySearchAsync1(queryString, cb) {
      if (!queryString) return;
      queryOrderCompetition({
        competitionName: this.search.competitionName,
      }).then(([data, err]) => {
        let list = [];
        if (!err && data) {
          list = data.slice(0, 50);
          // if (data.length > 50) {
          //   list.push({
          //     value: null,
          //     label: "......",
          //   });
          // }
          cb(list);
        } else {
          this.$message.warning("查无此联赛");
        }
      });
    },
    querySearchAsync2(queryString, cb) {
      if (!queryString) return;
      queryOrderTeam({
        teamName: this.search.teamName,
      }).then(([data, err]) => {
        let list = [];
        if (!err && data) {
          list = data.slice(0, 50);
          // if (data.length > 50) {
          //   list.push({
          //     value: null,
          //     label: "......",
          //   });
          // }
          cb(list);
        } else {
          this.$message.warning("查无此队");
        }
      });
    },
    handleSelect(item, key) {
      console.log(item);
    },
    onChangeCascaderType(value) {
      this.search.type = +value?.[1];
      console.log(value);
      if (value?.[0] == "sports") {
        this.isSports = true;
      } else {
        this.isSports = false;
      }
      this.handleRefresh();
    },
    onChangeType() {
      this.handleRefresh();
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (
        columnIndex === 0 ||
        columnIndex === 1 ||
        columnIndex === 2 ||
        columnIndex === 5 ||
        columnIndex > 6
      ) {
        return {
          rowspan: row.rowspan,
          colspan: 1,
        };
      }
    },
    implementationFetched({ items }) {
      this.resourece = [];
      this.resource = items.reduce((c, v) => {
        const { subRespList, ...others } = v;
        if (subRespList) {
          subRespList.forEach((r, i) => {
            c.push({
              ...others,
              ...r,
              rowspan: i === 0 ? subRespList.length : 0,
            });
          });
        } else {
          c.push({ ...others, rowspan: 1 });
        }
        return c;
      }, []);
      this.statistics = null
      this.$forceUpdate();
    },

    getStatisticsData() {
      getThirdOrderCount({ ...this.implementationGetParams() }).then(
        ([data, err]) => {
          if (!err) {
            this.statistics = data;
          }
        }
      );
    },

    implementationGetParams() {
      return {
        ...this.formatIntervalTime(),
      };
    },
    _handleExport() {
      this.excelLoading1 = true;
      exportThirdOrderList({ ...this.implementationGetParams() })
        .then(([data, error, blobData]) => {
          if (!error) {
            exportExcel(
              blobData,
              `第三方注单-${this.$parseTime(new Date())}.csv`
            );
          }
        })
        .finally(() => {
          this.excelLoading1 = false;
        });
    },
    _handleExportTotal() {
      this.excelLoading2 = true;
      downThirdgameQueryOrderMemberCount({ ...this.implementationGetParams() })
        .then(([data, error, blobData]) => {
          if (!error) {
            exportExcel(
              blobData,
              `第三方注单汇总-${this.$parseTime(new Date())}.csv`
            );
          }
        })
        .finally(() => {
          this.excelLoading2 = false;
        });
    },
    checkDetails(row) {
      const { orderNo, betTime } = row;
      queryOrderDetail({ orderNo, betTime, type: this.search.type }).then(
        ([data, error]) => {
          if (!data) {
            this.$message.warning(
              this.$t("无投注详情或该三方不支持查看投注详情")
            );
          } else if (this.search.type == 3 || this.search.type == 40) {
            this.$alert(
              `<pre style="height: 300px; overflow-y: auto;">${data}</pre>`,
              "详情",
              {
                dangerouslyUseHTMLString: true,
              }
            );
          } else {
            window.open(data);
          }
        }
      );
    },
  },
};
</script>
