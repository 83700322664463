import { rateTypes } from "@/libs/options";
import { filterColumns } from "@/utils";
const CanChooseColumns = function () {
  return [
    {
      title: this.$t("订单号"),
      key: "orderNo",
      align: "center",
      width: 140,
    },
    {
      title: this.$t("会员信息"),
      key: "memberName",
      align: "center",
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <div
              class="primary-type cursor-pointer"
              onClick={this._onDetails.bind(this, row)}
            >
              <p>{row.memberName}</p>
              <p>
                {row.sumRechargeTimes > 2
                  ? this.$t("多次充值")
                  : row.sumRechargeTimes === 2
                  ? this.$t("二次充值")
                  : this.$t("首次充值")}
              </p>
            </div>
          );
        },
      },
    },
    {
      title: this.$t("赠送金额"),
      key: "giveAmount",
      align: "center",
    },
    {
      title: this.$t("所属代理"),
      key: "proxyCode",
      align: "center",
    },
    {
      title: this.$t("支付方式"),
      key: "paywayName",
      align: "center",
    },
    {
      title: this.$t("平台名称"),
      key: "payPlatformName",
      align: "center",
    },
    {
      title: this.$t("来源"),
      key: "source",
      align: "center",
    },
    {
      title: this.$t("附言"),
      key: "postscript",
      align: "center",
    },
    {
      title: this.$t("付款凭证"),
      key: "abpayUrl",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            row.abpayUrl&<el-popover placement="top-start" trigger="hover">
              <el-image
                src={row.abpayUrl}
                fix="cover"
                slot="reference"
                style="width: 40px;height: 40px;border-radius: 4px"
              />
              <el-image
                src={row.abpayUrl}
                fix="cover"
                style="width: 240px;height: 240px;border-radius: 4px"
              />
            </el-popover>
          );
        },
      },
    },
    {
      title: this.$t("货币汇率"),
      align: "center",
      component: {
        render: (h, row) => {
          const rateType = rateTypes.find((e) => e.key === row.rateType);
          if (rateType) {
            return (
              <div>
                <p>{rateType.label}</p>
                <span>{row.exchangeRate}</span>
              </div>
            );
          }
          return <span>{row.exchangeRate}</span>;
        },
      },
    },
    {
      title: this.$t("货币金额"),
      key: "exchangeAmount",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span class={this.$parseColor(row.exchangeAmount)}>
              {row.exchangeAmount}
            </span>
          );
        },
      },
    },
    {
      title: this.$t("平台流水号"),
      key: "thirdNo",
      align: "center",
      width: 85,
    },
    {
      title: this.$t("充值平台金额"),
      key: "amount",
      align: "center",
      width: 90,
    },
    {
      title: this.$t("优惠金额"),
      key: "discountAmount",
      align: "center",
      width: 90,
    },
    {
      title: this.$t("实付平台金额"),
      key: "payAmount",
      align: "center",
      width: 90,
      component: {
        render: (h, row) => {
          return (
            <span class={this.$parseColor(row.payAmount)}>{row.payAmount}</span>
          );
        },
      },
    },
    {
      title: this.$t("支付状态"),
      key: "status",
      align: "center",
      component: {
        render: (h, row) => {
          const tmp = this.paymentStatus.find((o) => o.key === row.status);
          if (tmp) {
            return <span class={`${tmp.type}-type`}>{tmp.label}</span>;
          }
        },
      },
    },
    {
      title: this.$t("创建时间"),
      key: "createTime",
      align: "center",
      width: 150,
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.createTime)}</span>;
        },
      },
    },
    {
      title: this.$t("补单记录"),
      key: "remark",
      align: "center",
    },
    {
      title: this.$t("操作"),
      key: "actions",
      align: "center",
      width: 150,
      component: {
        render: (h, row) => {
          if (row.status === "1111" && this.permission.Again) {
            return (
              <el-button
                type="danger"
                size="small"
                onClick={this.handleAgain.bind(this, row)}
              >
                {this.$t("补单")}
              </el-button>
            );
          }
        },
      },
    },
  ];
};

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}
