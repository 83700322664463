var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "460px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm._handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("代理等级"), prop: "level" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: { placeholder: _vm.$t("请选择代理等级") },
                  model: {
                    value: _vm.ruleForm.level,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "level", $$v)
                    },
                    expression: "ruleForm.level",
                  },
                },
                _vm._l(_vm.$parent.defaultAgentType, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item + _vm.$t("级代理"), value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("升级要求推广人数"),
                prop: "requireSpreadNum",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { disabled: _vm.disabled },
                model: {
                  value: _vm.ruleForm.requireSpreadNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "requireSpreadNum", $$v)
                  },
                  expression: "ruleForm.requireSpreadNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("升级要求投注金额"),
                prop: "requireBetAmount",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { disabled: _vm.disabled },
                model: {
                  value: _vm.ruleForm.requireBetAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "requireBetAmount", $$v)
                  },
                  expression: "ruleForm.requireBetAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("升级要求充值金额"),
                prop: "requireRechargeAmount",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { disabled: _vm.disabled },
                model: {
                  value: _vm.ruleForm.requireRechargeAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "requireRechargeAmount", $$v)
                  },
                  expression: "ruleForm.requireRechargeAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("等级对应一级返佣点"),
                prop: "oneLevelAgencyRebate",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                model: {
                  value: _vm.ruleForm.oneLevelAgencyRebate,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "oneLevelAgencyRebate", $$v)
                  },
                  expression: "ruleForm.oneLevelAgencyRebate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("等级对应二级返佣点"),
                prop: "twoLevelAgencyRebate",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                model: {
                  value: _vm.ruleForm.twoLevelAgencyRebate,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "twoLevelAgencyRebate", $$v)
                  },
                  expression: "ruleForm.twoLevelAgencyRebate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }