<template>
  <div>
    <div class="tab-header" flex="main:justify cross:bottom">
      <div class="el-form-item" style="margin-bottom: 0">
        <template v-if="permission.AuditSwitch">
          <label class="el-form-item__label" style="width: 80px">审核开关</label>
          <div class="el-form-item__content" style="margin-left: 80px;">
            <d-switch v-model="auditStatus" :before-change="handleBeforeChange">
              <span slot="open">开</span>
              <span slot="close">关</span>
            </d-switch>
          </div>
        </template>
      </div>

      <el-button v-if="permission.BatchRefuse" type="danger" style="width: 180px" @click="handleDelete">删除未通过</el-button>
    </div>
    <div class="filter-container">
      <div class="filter-container-item">
        <el-date-picker
          v-model="times"
          type="datetimerange"
          style="width: 400px;"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="filter-item"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
        />
        <el-select v-model="search.auditStatus" placeholder="审核状态" class="filter-item" clearable style="width: 140px">
          <el-option v-for="item in recommendAduditStatus" :key="item.key" class="filter-item" :label="item.label" :value="item.key" />
        </el-select>
        <el-input v-model="search.title" placeholder="标题" class="filter-item" style="width: 160px" />
        <div class="filter-container-item">
          <el-input v-model="search.memberName" placeholder="账号" class="filter-item" style="width: 160px" />
          <el-input v-model="search.nickname" placeholder="昵称" class="filter-item" style="width: 160px" />
          <el-select v-model="search.lotteryId" placeholder="类别" clearable class="filter-item" style="width: 140px">
            <el-option v-for="(val, key) in xsLottery" :key="key" :value="key" :label="val" />
          </el-select>
          <el-button class="filter-item" type="success" icon="el-icon-search" @click="handleSearch">查询</el-button>
          <el-button class="filter-item" type="success" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
        </div>
      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      @selection-change="handleSelectionChange"
    >
      <template slot="left">
        <button v-if="permission.Audit" class="el-button el-button--small el-button--orange" :disabled="!selection.length && 'disabled'" @click="handleBacthAudit(1)">批量通过</button>
        <button v-if="permission.Audit" class="el-button el-button--small el-button--orange" :disabled="!selection.length && 'disabled'" @click="handleOpenAuditRemark">批量拒绝</button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <ActionDialog ref="ActionDialogControl" @update="_handleUpdate" />
  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { recommendAduditStatus } from '@/libs/options'
import ActionDialog from './ActionDialog.vue'
import {
  getRecommendPostList,
  deleteHideRecommend,
  batchUpdateAuditStatus,
  updatePostAuditOpen
} from '@/api/interactive'
import { getDefaultTimes } from '@/utils'
import { xsLottery } from '@/libs/options'
export default {
  name: 'AuditTab',
  components: { ActionDialog },
  componentName: 'AuditTab',
  mixins: [TablePageMixin(getRecommendPostList)],
  data() {
    return {
      times: getDefaultTimes(),
      filterColumn: false,
      search: {},
      columns: GetColumns.call(this),
      selection: [],
      recommendAduditStatus,
      auditStatus: null,
      xsLottery
    }
  },
  activated() {
    this.handleFetch && this.handleFetch()
  },
  methods: {
    _goDetails({ id, tenantCode, auditStatus }) {
      this.$router.push({ name: 'RecommendAudit', query: { id, tenantCode, auditStatus }})
    },
    handleBeforeChange() {
      return new Promise((resolve) => {
        this.$confirm(`确认${this.auditStatus === 1 ? '开启' : '关闭'}审核`, '系统提示', { type: 'warning' }).then(() => {
          updatePostAuditOpen({
            status: ~this.auditStatus + 2
          }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
            }
          }).catch(() => {})
        })
      })
    },
    handleOpenAuditRemark() {
      this.$refs.ActionDialogControl.open()
    },
    _handleUpdate({ field, cancel, close }) {
      this.handleBacthAudit(field.auditStatus, field.auditRemark, cancel, close)
    },
    handleBacthAudit(auditStatus, auditRemark = '', cancel, close) {
      let data = { auditStatus, idList: this.selection.map(o => o.id), auditRemark }
      batchUpdateAuditStatus(data).then(([data, err]) => {
        if (!err) {
          if (close) {
            close()
          }
          this.$message.success('批量操作成功')
          this.handleFetch()
        } else if (cancel) {
          cancel()
        }
      })
    },
    handleDelete() {
      let params = {}
      deleteHideRecommend(params).then(([data, err]) => {
        if (!err) {
          this.$message.success('删除成功')
          this.handleFetch()
        }
      })
    },
    handleSelectionChange(selection) {
      this.selection = selection
    },
    implementationFetched({ status }) {
      this.auditStatus = status
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    }
  }
}
</script>
