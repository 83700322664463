import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('展示位置'),
      key: 'position',
      align: 'center',
      component: {
        render: (h, row) => {
          const tmp = this.positionOptions.find(o => o.value === row.position)
          return (<span>{ tmp && tmp.label || '' }</span>)
        }
      }
    },
    {
      title: this.$t('广告标题'),
      key: 'title',
      align: 'center'
    },
    {
      title: this.$t('语言'),
      key: 'lang',
      align: 'center',
      component: {
        render: (h, row) => {
          let langArray = []
          if (row.lang) {
            const allLang = this.$store.getters.allLang
            row.lang.split(',').forEach(e => {
              const lang = allLang.find(v => v.lang === e)
              if (lang) {
                langArray.push(lang.name)
              }
            })
          }
          return (<span>{ langArray.toString() }</span>)
        }
      }
    },
    {
      title: this.$t('开始时间'),
      key: 'startDate',
      align: 'center',
      width: 125,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.startDate) }</span>)
        }
      }
    },
    {
      title: this.$t('结束时间'),
      key: 'endDate',
      align: 'center',
      width: 125,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.endDate) }</span>)
        }
      }
    },
    {
      title: this.$t('是否启用'),
      key: 'status',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          // const disabled = !this.permission.StartStop || row.endDate <= Date.now()
          return (
            // <d-switch v-model={row.status} disabled={disabled} active-value={1} inactive-value={2} before-change={ () => this._editStatus(row) }>
            //   <span slot='close'>{ this.$t('停用') }</span>
            // </d-switch>
            <span>{row.status == 1 ? '启用' : '停用'}</span>
          )
        }
      }
    },
    {
      title: this.$t('编辑时间'),
      key: 'updateTime',
      align: 'center',
      width: 125,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作员'),
      key: 'operatorName',
      align: 'center'
    },
    {
      title: this.$t('展示位置'),
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          const { Delete, Update } = this.permission
          return (
            <span>
              { Update && <el-button type='primary' icon='el-icon-edit' size='mini' onClick={() => this.$refs.ActionDialogControl.open(row)}>{ this.$t('编辑') }</el-button> }
              { Delete && <el-button type='danger' icon='el-icon-delete' size='mini' onClick={() => this._handleDelete(row)}>{ this.$t('删除') }</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
