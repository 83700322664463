<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="80%"
    top="5vh"
    title="注单明细"
    @closed="onClosed"
  >
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :options="options"
      :filter-column="filterColumn"
      :columns="columns"
      style="height: 70vh;overflow-y: auto;"
    >
      <template #left>
        <el-input v-model="search.memberName" clearable :placeholder="$t('会员账号')" class="filter-item" style="width: 140px"/>
        <el-select v-model="search.seriesTag" clearable :placeholder="$t('系列')" :loading="seriesLoading" class="filter-item" style="width: 140px" @change="_getLotterys">
          <el-option v-for="item in seriesList" :key="item.seriesTag" :label="item.seriesName" :value="item.seriesTag" />
        </el-select>
        <el-select v-model="search.lotteryId" clearable :placeholder="$t('彩种')" :loading="lotterysLoading" class="filter-item" style="width: 140px" :disabled="!search.seriesTag">
          <el-option v-for="item in lotterysList" :key="item.lotteryId" :label="item.name" :value="item.lotteryId" />
        </el-select>
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t("查询") }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t("重置") }}</el-button>
      </template>
      <template #right>
        <el-button type="primary" :loading="excelLoading" icon="el-icon-download" @click="_handleExport">导出Excel</el-button>
      </template>
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />
  </el-dialog>
</template>

<script>
import { agencyDataBetList, agencyDataBetListExport } from '@/api/agent-rebate.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './orderDetailColumns'
import { bettingStatus } from "@/libs/options";
import { exportExcel } from "@/utils";
import { getSeries, getLotterys } from "@/api/orders";
export default {
  name: 'IndexTab',
  mixins: [TablePageMixin(agencyDataBetList, false)],
  data() {
    return {
      visible: false,
      excelLoading: false,
      filterColumn: false,
      columns: GetColumns.call(this),
      bettingStatus,
      seriesList: [],
      seriesLoading: false,
      lotterysList: [],
      lotterysLoading: false
    }
  },
  created() {
    this.implementationTenantChanged()
  },
  methods: {
    open(row, parentSearch) {
      const { currentPage, pageSize, ...others } = parentSearch
      this.search = { ...others, oneLevelAgencyId: row.oneLevelAgencyId, agencyId: row.memberId }
      delete this.search.memberName
      if (this.search.dateType !== 1) {
        delete this.search.dataTime
      }
      this.handleSearch()
      this.visible = true
    },
    close() {
      this.visible = false
    },
    onClosed() {
      this.items = []
    },
    _handleExport() {
      this.excelLoading = true;
      agencyDataBetListExport({ ...this.implementationGetParams() }).then(([data, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `注单明细-${this.$parseTime(new Date())}.csv`);
        }
      }).finally(() => {
        this.excelLoading = false;
      });
    },
    implementationTenantChanged() {
      this.seriesLoading = true
      getSeries().then(([data, err]) => {
        if (!err) {
          this.seriesList = data.filter((o) => +o.seriesTag < 100);
        }
      }).finally(() => {
        this.seriesLoading = false
      });
    },
    _getLotterys(seriesTag) {
      if (this.search.lotteryId !== undefined) this.search.lotteryId = "";
      let params = { seriesTag };
      if (!seriesTag) return;
      this.lotterysLoading = true
      getLotterys(params).then(([data, err]) => {
        if (!err) {
          this.lotterysList = data;
        }
      }).finally(() => {
        this.lotterysLoading = false
      });
    },
    resetSearch() {
      this.search.memberName = undefined
      this.search.seriesTag = undefined
      this.search.lotteryId = undefined
    }
  }
}
</script>
  