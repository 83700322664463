<template>
  <el-dialog :title="diaTitle" :visible.sync="visible" width="800px" :close-on-click-modal="false"
    @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="助手名称" required prop="nameTwo">
        <el-input v-model="ruleForm.nameTwo" placeholder="输入助手名称，最多不超过10个字符" :maxlength="10"/>
      </el-form-item>
      <el-form-item label="用户昵称" required prop="nicknameTwo">
        <el-input v-model="ruleForm.nicknameTwo" placeholder="输入用户昵称，最多不超过10个字符" :maxlength="10" />
      </el-form-item>
      <el-form-item label="用户头像" prop="avatarTwo">
        <d-upload v-model="ruleForm.avatarTwo" folder="head" :on-success="handleSuccess" />
        只支持.jpg、.png格式，图片大小不超过2MB
      </el-form-item>

      <el-form-item :label="$t('会员等级')" prop="vipLevel">
        
        <el-select
          class="filter-item"
          v-model="ruleForm.vipLevel"
          placeholder="请选择会员等级"
        >
          <el-option
            v-for="item in vipList"
            :key="item.id"
            :label="`VIP${item.vipLevel}`"
            :value="item.vipLevel"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('活跃时段')" prop="activePeriodTwo">
        <el-time-picker value-format="HH:mm" is-range v-model="ruleForm.activePeriodTwo" range-separator="至" start-placeholder="开始时间"
          end-placeholder="结束时间" placeholder="选择时间范围">
        </el-time-picker>
      </el-form-item>


      <el-form-item :label="$t('形象描述')" prop="imageDescriptionTwo">
        <el-input v-model="ruleForm.imageDescriptionTwo" type="textarea" :rows="5"
          :placeholder="$t('请输入形象描述，英文逗号隔开，最多输入2000字符')" maxlength="2000" />
      </el-form-item>

      <el-form-item label=" 状态" prop="statusTwo">
        <d-switch v-model="ruleForm.statusTwo" :inactive-value="0" :active-value="1" />
        <div>
          默认启用状态，关闭后前端不展示
        </div>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getVipLevelManagerList } from "@/api/member";
import FormDialogMixin from '@/mixins/form-dialog'
import { validNickNameTen } from '@/utils/validate'
import DUpload from '@/components/DUpload'
const getDefaultForm = () => ({
  nameTwo: '',
  nicknameTwo: '',
  avatarTwo: '',
  vipLevel: '',
  statusTwo: 1,
  imageDescriptionTwo: '',
  activePeriodTwo: '',
})

export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validNickname = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入名称'))
      } else if (!validNickNameTen(value)) {
        callback(new Error('请输入2~10个字符'))
      } else {
        callback()
      }
    }

    return {
      diaTitle: '添加机器人',
      emitName: 'create',
      rules: {
        nicknameTwo: [
          
          { validator: validNickname, trigger: 'blur' }
        ],
        nameTwo: [
          { validator: validNickname, trigger: 'blur' }
        ],
        avatarTwo: [
          { required: true, message: '请上传头像', trigger: 'change' }
        ],
        vipLevel: [
        { required: true, message: '请选择会员等级', trigger: 'change' }
        ],
        statusTwo: [
          { required: true, message: '请选择是否启用', trigger: 'change' }
        ],
        imageDescriptionTwo: [
          { required: true, message: '请输入形象描述', trigger: 'change' }
        ],
      },
      vipList: []
    }
  },
  created() {
    getVipLevelManagerList().then(res => {
      this.vipList = res[0] || []
    })
  },
  methods: {
    open(row) {
      if (row) {
        console.log(row)
        this.emitName = 'update';
        this.diaTitle = '编辑机器人';
        this.ruleForm.nameTwo = row.name;
        this.ruleForm.idTwo = row.id;
        this.ruleForm.vipLevel = row.vipLevel || 0;
        this.ruleForm.nicknameTwo = row.nickname;
        this.ruleForm.avatarTwo = row.avatar;
        this.ruleForm.statusTwo = row.status;
        this.ruleForm.imageDescriptionTwo = row.imageDescription;
        this.ruleForm.activePeriodTwo = row.activePeriod;
        if (row.activePeriod) {
          this.ruleForm.activePeriodTwo = row.activePeriod.split('~');
          // this.ruleForm.activePeriodTwo[0]=new Date(this.ruleForm.activePeriodTwo[0])
          // this.ruleForm.activePeriodTwo[1]=new Date(this.ruleForm.activePeriodTwo[1])
          console.log(this.ruleForm.activePeriodTwo)
        }
        
      } else {
        this.emitName = 'create';
        this.diaTitle = '添加机器人'
      }
      this.visible = true
    },
    handleSuccess(data) {
      this.ruleForm.avatarTwo = data[0];
      this.$refs.validateForm.validateField('avatarTwo')
    }
  }
}
</script>
