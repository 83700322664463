<template>
  <div v-loading="loading" class="audit-form" style="padding-top: 20px">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="160px">
      <el-form-item v-if="$route.query.auditStatus === 2 && ruleForm.auditRemark" label="审核拒绝原因">
        <span>{{ ruleForm.auditRemark }}</span>
      </el-form-item>
      <el-form-item label="昵称">
        <span>{{ ruleForm.memberName }}</span>
      </el-form-item>
      <el-form-item label="标题">
        <el-input v-model="ruleForm.title" readonly placeholder="输入标题" />
      </el-form-item>
      <el-form-item label="正文内容">
        <tinymce ref="tinymce" v-model="ruleForm.content" />
      </el-form-item>
      <el-form-item label="隐藏内容">
        <tinymce ref="tinymce" v-model="ruleForm.hideContent" :height="200" />
      </el-form-item>
      <el-form-item label="回复可见隐藏">
        <d-switch v-model="ruleForm.replyViewStatus" disabled :active-value="1" :inactive-value="0" />
      </el-form-item>
      <el-form-item label="登录可见隐藏">
        <d-switch v-model="ruleForm.loginViewStatus" disabled :active-value="1" :inactive-value="0" />
      </el-form-item>
      <el-form-item label="充值可见隐藏">
        <d-switch v-model="ruleForm.payViewStatus" disabled :active-value="1" :inactive-value="0" />
        <template v-if="ruleForm.payViewStatus === 1">
          <el-checkbox v-model="instant" disabled>即时充值</el-checkbox>
          <el-input v-model="ruleForm.rechargeViewMoney" readonly placeholder="金额" style="width: 120px" />
        </template>
      </el-form-item>
      <el-form-item v-if="$route.query.auditStatus === 0">
        <el-button type="danger" size="large" style="width: 140px" @click="handleOpenAuditRemark">拒绝</el-button>
        <el-button type="success" size="large" style="width: 140px" @click="handleSubmit(1)">通过</el-button>
      </el-form-item>
    </el-form>
    <ActionDialog ref="ActionDialogControl" @update="_handleUpdate" />
  </div>
</template>
<script>
import Tinymce from '@/components/Tinymce'
import { getRecommendPostDetails, auditRecommendPostDetails } from '@/api/interactive'
import ActionDialog from './../../AuditTab/ActionDialog.vue'
export default {
  components: { Tinymce, ActionDialog },
  data() {
    return {
      instant: false,
      vipLevels: [],
      ruleForm: {
        replyViewStatus: 0,
        loginViewStatus: 0,
        payViewStatus: 0
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入正文', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getDetails(this.$route.query)
  },
  methods: {
    getDetails({ id, tenantCode }) {
      this.loading = true
      getRecommendPostDetails({ id, tenantCode }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.ruleForm = data
          if (data.payViewStatus === 1 && data.rechargeViewMoney) this.instant = true
        }
      })
    },
    handleOpenAuditRemark() {
      this.$refs.ActionDialogControl.open()
    },
    _handleUpdate({ field, cancel, close }) {
      this.handleSubmit(field.auditStatus, field.auditRemark, cancel, close)
    },
    handleSubmit(auditStatus, auditRemark = '', cancel, close) {
      this.$refs.validateForm.validate(valid => {
        if (valid) {
          auditRecommendPostDetails({
            id: this.ruleForm.id,
            title: this.ruleForm.title,
            auditStatus,
            auditRemark,
            postLevelId: this.ruleForm.postLevelId,
            tenantCode: this.ruleForm.tenantCode,
            loginViewStatus: this.ruleForm.loginViewStatus,
            payViewStatus: this.ruleForm.payViewStatus,
            rechargeViewMoney: this.ruleForm.rechargeViewMoney,
            replyViewStatus: this.ruleForm.replyViewStatus
          }).then(([data, err]) => {
            if (!err) {
              if (close) {
                close()
              }
              this.$store.dispatch('tagsView/delView', this.$route)
              this.$message.success('操作成功')
              this.$router.go(-1)
            } else if (cancel) {
              cancel()
            }
          })
        }
      })
    }
  }
}
</script>

