<template>
  <el-dialog :show-close="false" :visible.sync="visible" width="300px" center>
    <h3>{{ $t('确认批量通过审核') }}</h3>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" plain @click="close">{{ $t('取 消') }}</el-button>
      <el-button v-loading="submitting" type="danger" @click="handleSubmit(2)">{{ $t('拒 绝') }}</el-button>
      <el-button v-loading="submitting" type="success" @click="handleSubmit(1)">{{ $t('通 过') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      submitting: false
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleSubmit(flag) { // 取消 通过 拒绝
      this.$emit('update', {
        field: { flag },
        close: this.close,
        cancel: () => {
          this.submitting = false
        }
      })
    }
  }
}
</script>
