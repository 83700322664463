const CanChooseColumns = function () {
  return [
    {
      title: "会员账号",
      key: "memberName",
      align: "center",
    },
    {
      title: "昵称",
      key: "nickname",
      align: "center",
    },
    {
      title: "评论ID",
      key: "id",
      align: "center",
    },
    {
      title: "评论内容",
      key: "content",
      align: "center",
    },
    {
      title: "推单订单号",
      key: "orderNo",
      align: "center",
      width: 100,
    },
    {
      title: "推单会员账号",
      key: "guruAccountName",
      align: "center",
      width: 100,
    },
    {
      title: "推单会员昵称",
      key: "guruNickname",
      align: "center",
      width: 100,
    },
    {
      title: "游戏类型",
      key: "smallPlayType",
      align: "center",
      component: {
        render: (h, row) => {
          switch (row.smallPlayType) {
            case 40:
              return <span>香港六合彩</span>;
            case 41:
              return <span>新澳门六合彩</span>;
            case 99:
              return <span>竞彩足球</span>;
            case 1502:
              return <span>滚球</span>;
            default:
              return <span>{row.smallPlayType}</span>;
          }
        },
      },
    },
    {
      title: "方案宣言",
      key: "planSpeech",
      align: "center",
      width: 300,
      isRichText:true
    },
    {
      title: "创建时间",
      key: "createTime",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.createTime)}</span>;
        },
      },
    },
    {
      title: "操作",
      align: "center",
      key: "remark",
      width: 200,
      component: {
        render: (h, row) => {
          const { Delete } = this.permission;
          return (
            (Delete || true) && (
              <span>
                <el-button
                  type="warning"
                  size="mini"
                  onClick={()=>this.handleDelete(row)}
                >
                  删除
                </el-button>
              </span>
            )
          );
        },
      },
    },
  ];
};

export default function (keys = []) {
  return CanChooseColumns.call(this);
}
