<template>
  <el-dialog
    :title="$t('新增主播')"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    @open="onOpen"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" v-loading="loading" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item :label="$t('关联家族')" prop="familyId">
        <el-select v-model="ruleForm.familyId" :placeholder="$t('下拉选择家族')">
          <el-option v-for="item in familyList" :key="item.familyId" :label="item.familyName" :value="item.familyId" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('主播昵称')" prop="nickName">
        <el-input v-model.trim="ruleForm.nickName" maxlength="12" show-word-limit />
      </el-form-item>
      <el-form-item :label="$t('登录账号')" prop="accLogin">
        <el-input v-model.trim="ruleForm.accLogin" maxlength="12" show-word-limit />
      </el-form-item>
      <el-form-item :label="$t('联系方式')" prop="contactInfo">
        <el-input v-model.trim="ruleForm.contactInfo" maxlength="20" show-word-limit />
      </el-form-item>
      <el-form-item :label="$t('登录密码')" prop="password" required>
        <el-input v-model="ruleForm.password" :placeholder="$t('6-12位')" />
      </el-form-item>
      <el-form-item :label="$t('确认密码')" prop="confirmPassword" required>
        <el-input v-model="ruleForm.confirmPassword" :placeholder="$t('6-12位')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getLiveFamilyList } from '@/api/live'
import { validAccountAndPassword } from '@/utils/validate'
const getDefaultForm = () => ({
  familyId: null,
  nickName: '',
  accLogin: '',
  contactInfo: '',
  password: '',
  confirmPassword: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入密码')))
      } else if (!validAccountAndPassword(value)) {
        callback(new Error(this.$t('请输入6~12位英文数字')))
      } else {
        if (this.ruleForm.confirmPassword !== '') {
          this.$refs.validateForm.validateField('confirmPassword')
        }
        callback()
      }
    }

    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请再次输入密码')))
      } else if (value !== this.ruleForm.password) {
        callback(new Error(this.$t('两次输入密码不一致')))
      } else {
        callback()
      }
    }
    return {
      emitName: 'create',
      loading: false,
      familyList: [],
      rules: {
        familyId: [{ required: true, message: this.$t('请选择家族'), trigger: 'change' }],
        nickName: [{ required: true, message: this.$t('请输入昵称'), trigger: 'blur' }],
        accLogin: [
          { required: true, message: this.$t('请输入登录账号'), trigger: 'blur' },
          { pattern: /^.{6,12}$/, message: this.$t('登录账号长度为6-12位'), trigger: 'blur' }
        ],
        contactInfo: [
          { required: true, message: this.$t('请输入联系方式'), trigger: 'blur' },
          { pattern: /^.{2,20}$/, message: this.$t('联系方式长度为2-20位'), trigger: 'blur' }
        ],
        password: [{ validator: validatePass, trigger: 'blur' }],
        confirmPassword: [{ validator: validateCheckPass, trigger: 'blur' }]
      }
    }
  },
  methods: {
    onOpen() {
      this.loading = true
      getLiveFamilyList({
        currentPage: 1,
        pageSize: 100
      }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.familyList = data || []
        }
      })
    }
  }
}
</script>

