<template>
  <el-dialog
    :visible.sync="visible"
    width="1250px"
    title="创建新租户"
    top="5vh"
    :close-on-click-modal="false"
    @closed="onClosed"
  >
    <el-steps :active="active" align-center finish-status="success">
      <el-step v-for="step in steps" :key="step.name" :title="step.title" :description="step.description" />
    </el-steps>

    <div class="step-container">
      <keep-alive>
        <component :is="steps[active].name" :groupList="$parent.groupList" :ref="steps[active].name" />
      </keep-alive>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button v-if="active < 2" type="danger" @click="close">关闭</el-button>
      <el-button v-if="active > 0" type="warning" @click="prev">上一步</el-button>
      <el-button v-if="active < 2" type="primary" @click="next">下一步</el-button>
      <el-button v-if="active === 2" type="success" @click="handleSubmit">完成</el-button>
    </div>

  </el-dialog>
</template>

<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import { TenantForm, TenantFunction, TenantSystem } from './common'
export default {
  components: { TenantForm, TenantFunction, TenantSystem },
  mixins: [VDialogMixin],
  data: () => ({
    active: 0,
    submitting: false,
    ruleForm: {},
    steps: [
      { title: '步骤 1', description: '基本信息', name: 'TenantForm' },
      { title: '步骤 2', description: '功能配置', name: 'TenantFunction' },
      { title: '步骤 3', description: '账号配置', name: 'TenantSystem' }
    ]
  }),
  methods: {
    prev() {
      this.active -= 1
    },
    next() {
      const fn = () => { this.active += 1 }

      if (this.active === 0) {
        this.$refs.TenantForm.onValidate(this.setTenant).then(() => {
          fn()
        })
      } else if (this.active === 1) {
        this.$refs.TenantFunction.onValidate(this.setTenant).then(() => {
          fn()
        })
      }
    },
    onClosed() {
      this.active = 0
      this.ruleForm = {}
    },
    handleSubmit() {
      this.$refs.TenantSystem.onValidate(this.setTenant).then(() => {
        this.$emit('create', {
          field: this.ruleForm,
          cancel: () => {
            this.submitting = false
          },
          close: this.close
        })
      })
    },
    setTenant(params) {
      this.ruleForm = { ...this.ruleForm, ...params }
    }
  }
}
</script>

<style lang="scss">
.step-container {
  .tenant-form,
  .system-form {
    padding: 50px 25%;
  }
  .system-form {
    h2 {
      text-align: center;
      line-height: 2;
    }
  }
}
</style>
