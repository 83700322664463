<template>
  <div class="app-container">
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template #left>
        <el-input v-model="search.memberName" :placeholder="$t('会员账号')" class="filter-item" style="width: 200px" />
        <el-button type="primary" icon="el-icon-search" class="filter-item" :loading="loading" @click="handleSearch">{{ $t('查询') }}</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
        <el-dropdown
          trigger="click"
          placement="bottom-start"
          class="filter-item"
          @command="handleCommand"
        >
          <el-button type="success">
            {{ $t('批量处理') }}<i class="el-icon-arrow-down el-icon--right" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="permission.Sum" :command="6">{{ $t('批量增加打码量') }}</el-dropdown-item>
            <el-dropdown-item v-if="permission.Subtract" :command="7">{{ $t('批量减少打码量') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <AloneDialog ref="AloneDialogControl" @update="_handleUpdateAlone" />
    <BatchDialog ref="BatchDialogControl" @update="_handleUpdateBacth" />

  </div>
</template>
<script>
import { getMemberAccountList, updateAccountRolling } from '@/api/cash'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { AloneDialog, BatchDialog } from './components'
export default {
  name: 'CashMemberSubtract',
  components: { AloneDialog, BatchDialog },
  mixins: [TablePageMixin(getMemberAccountList)],
  data() {
    return {
      columns: [],
      defaultColumns: GetColumns.call(this),
      search: {
        memberName: ''
      }
    }
  },
  methods: {
    _handleUpdateAlone({ field, close, cancel }) {
      let data = {
        totalNum: 1,
        transType: field.transType,
        remark: '1',
        operatorRemark: field.operatorRemark,
        list: [{ memberName: field.memberName, rolling: [7, 2013].includes(field.transType)  ? '-' + field.rolling : field.rolling }]
      }
      updateAccountRolling(data).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    _handleUpdateBacth({ field, cancel, close }) {
      const tmp = field.memberName.split(/[\n]/).filter(Boolean)

      const isValide = tmp.every(o => /^((\w{1,65})[\s|,]((\d|[1-9][0-9]*)+(.\d{1,20})?))$/.test(o))

      if (!isValide) {
        this.$message.error(this.$t('批量处理信息数据格式不正确'))
        cancel()
        return
      }

      const objTmp = tmp.map(o => {
        return o.split(/[\s|,]/g).filter(Boolean)
      })

      const memberNameList = objTmp.map(o => o[0])
      const DeduplicationList = Array.from(new Set(memberNameList))

      if (memberNameList.length !== DeduplicationList.length) {
        this.$message.error(this.$t('批量用户存在重复'))
        cancel()
        return
      }
      updateAccountRolling({
        totalNum: objTmp.length,
        transType: field.transType,
        remark: field.transType === 6 ? this.$t('加码') : this.$t('减码'),
        operatorRemark: field.operatorRemark,
        list: objTmp.map(o => {
          const [memberName, amount] = o
          return {
            memberName,
            rolling: field.transType === 7 ? parseFloat('-' + amount) : parseFloat(amount)
          }
        })
      }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('批量操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleCommand(command) {
      this.$refs.BatchDialogControl.open(command)
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    handleExtra({ account, balance }, type) {
      this.$refs.ExtraDialogControl.open({ account, balance, type })
    }
  }
}
</script>
