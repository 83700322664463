<template>
  <el-dialog :title="title" :visible.sync="visible" width="700px" top="5vh" :close-on-click-modal="false"
    @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" :disabled="emitName === 'details'" label-width="180px"
      label-suffix=":" style="height: 70vh;overflow-y: auto;">
      <el-form-item :label="$t('任务分类')" prop="activityType">
        <el-select v-model="ruleForm.activityType" :placeholder="$t('任务分类')" style="width: 90%">
          <el-option v-for="item in $parent.activityTypeList" :key="item.id" :label="item.typeName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item v-for="item of ruleForm.activityTitleList" :key="item.lang" :label="$t(item.name) + $t('任务名称')">
        <el-input v-model="item.activityTitle" style="width: 90%" :placeholder="$t('请输入任务名称')" />
      </el-form-item>
      <el-form-item :label="$t('排序')" prop="sort">
        <el-input v-model="ruleForm.sort" style="width: 90%" maxlength="20" :placeholder="$t('请输入排序')" />
      </el-form-item>


      <el-form-item :label="$t('自动分享')" prop="isAutoShare">
        <div style="position: relative;">
          <el-switch :active-value="1"
          :inactive-value="0" :width="45" v-model="ruleForm.isAutoShare" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
          <span :class="ruleForm.isAutoShare ? 'switchactive' : 'switchtext'"> {{ ruleForm.isAutoShare ? '启用' : '关闭' }} </span>
        </div>
      </el-form-item>

      <el-form-item :label="$t('聊天室')" prop="roomId" v-if="ruleForm.isAutoShare==1">
        <el-select v-model="ruleForm.roomId" :placeholder="$t('请选择聊天室')" clearable style="width: 90%">
          <el-option v-for="item in chatList" :key="item.roomId" :label="item.roomName" :value="item.roomId" />
        </el-select>
      </el-form-item>


      <el-form-item :label="$t('任务时间')">
        <el-radio-group v-model="ruleForm.activityTimeType">
          <el-radio :label="0">{{ $t('定时开启') }}</el-radio>
          <el-radio :label="1">{{ $t('持续开启') }}</el-radio>
        </el-radio-group>
      </el-form-item>



      <el-form-item v-if="ruleForm.activityTimeType === 0" prop="activityDate">
        <el-date-picker v-model="ruleForm.activityDate" type="daterange" value-format="timestamp"
          :range-separator="$t('至')" :start-placeholder="$t('开始时间')" :end-placeholder="$t('开始时间')"
          :picker-options="pickerOptions" />
      </el-form-item>
      <el-form-item :label="$t('任务开关')">
        <el-radio-group v-model="ruleForm.activityStatus">
          <el-radio :label="1">{{ $t('开启') }}</el-radio>
          <el-radio :label="0">{{ $t('结束') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('任务内容')">
        <el-radio-group v-model="lang" style="margin-right: 6px;" @change="handleLang">
          <el-radio-button v-for="(item, index) in $parent.allLang" :key="index" :label="item.lang">{{ $t(item.name)
            }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <!-- <tinymce v-for="(item ,key, index) in content" v-show="lang === key" :ref="key" :key="index" v-model="ruleForm[item]" :height="200" /> -->
        <tinymce v-show="lang === 'zh_CN'" ref="zh_CN" v-model="ruleForm.activityContentZh" style="width: 92%;" :height="300" />
        <tinymce v-show="lang === 'en_US'" ref="en_US" v-model="ruleForm.activityContentEn" style="width: 92%;" :height="300" />
        <tinymce v-show="lang === 'vi_VN'" ref="vi_VN" v-model="ruleForm.activityContentVi" style="width: 92%;" :height="300" />
        <tinymce v-show="lang === 'gu_IN'" ref="gu_IN" v-model="ruleForm.activityContentGu" style="width: 92%;" :height="300" />
        <tinymce v-show="lang === 'ja_JP'" ref="ja_JP" v-model="ruleForm.activityContentJa" style="width: 92%;" :height="300" />
        <tinymce v-show="lang === 'ms_MY'" ref="ms_MY" v-model="ruleForm.activityContentMs" style="width: 92%;" :height="300" />
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button v-if="emitName !== 'details'" type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import Tinymce from '@/components/Tinymce'
import FormDialogMixin from '@/mixins/form-dialog'
import { getActivityDetail } from '@/api/activity'
import { getLiveRoomList } from "@/api/chat";

const getDefaultForm = () => ({
  id: null,
  activityType: '',
  activityTitleList: [],
  activityTimeType: 0,
  activityDate: '',
  activityStatus: 1,
  sort: '',
  activityContentZh: '',
  activityContentEn: '',
  activityContentVi: '',
  activityContentGu: '',
  activityContentJa: '',
  activityContentMs: '',
  isAutoShare: 0,
  roomId: ''
})

export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      lang: 'zh_CN',
      targetList: [],
      content: {
        'zh_CN': 'activityContentZh',
        'en_US': 'activityContentEn',
        'vi_VN': 'activityContentVi',
        'gu_IN': 'activityContentGu',
        'ja_JP': 'activityContentJa',
        'ms_MY': 'activityContentMs'
      },
      chatList: [],
      pickerOptions: {
        disabledDate(date) {
          return date.getTime() < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0)
        }
      },
      rules: {
        activityType: [{ required: true, message: this.$t('请选择任务分类'), trigger: 'blur' }],
        sort: [{ required: true, message: this.$t('请输入排序'), trigger: 'blur' }],
        activityDate: [{ required: true, message: this.$t('请选择任务时间'), trigger: 'change' }],
        roomId: [{ required: true, message: this.$t('请选择聊天室'), trigger: 'change' }],
      }
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? this.$t('新增任务') : this.$t('编辑任务')
    }
  },
  methods: {
    beforeSubmit() {
      // 运营需求，不让输入‘新手任务’这四个字
      const unValid = this.ruleForm.activityTitleList.some(p => p.activityTitle == '新手任务')
      if (unValid) {
        this.$message({
          message: '标题不符合要求',
          type: 'error'
        })
      }
      return !unValid
    },
    open(row) {
      const _this = this
      _this.emitName = 'create'
      _this.ruleForm.activityTitleList = _this.$parent.allLang.map(e => { return { ...e, activityTitle: '' } })
      _this.getChatList()
      if (row) {
        _this.ruleForm.id = row.id;
        if (row.roomId && row.roomId.indexOf('interactive_')== -1 ){
          _this.ruleForm.roomId = `interactive_${row.roomId}`;
        }else{
          _this.ruleForm.roomId = row.roomId;
        }
        _this.ruleForm.activityType = row.activityType;
        _this.ruleForm.isAutoShare = row?.isAutoShare;
        const activityTitleList = row.activityTitle.split(',')
        activityTitleList.forEach(e => {
          const lang = e.split(':')[0]
          const activityTitle = e.split(':')[1]
          const formLang = _this.ruleForm.activityTitleList.find(r => r.lang === lang)
          if (formLang) {
            formLang.activityTitle = activityTitle
          }
        })
        if (row.activityBeginDate + 4 * 365 * 24 * 60 * 60 * 1000 === row.activityEndDate) {
          _this.ruleForm.activityTimeType = 1
        } else {
          _this.ruleForm.activityTimeType = 0
          _this.ruleForm.activityDate = [row.activityBeginDate, row.activityEndDate]
        }
        _this.ruleForm.sort = row.sort
        _this.getContent()
        _this.emitName = 'update'
      } else {
        _this.ruleForm.activityContentZh = ''
        _this.ruleForm.activityContentEn = ''
        _this.ruleForm.activityContentVi = ''
        _this.ruleForm.activityContentGu = ''
        _this.ruleForm.activityContentJa = ''
        _this.ruleForm.activityContentMs = ''
        this.handleLang()
      }

      _this.visible = true
    },
    getChatList() {
      getLiveRoomList({ roomType: 1 }).then((res) => {
        this.chatList = res[2].data;
        if (this.chatList.code == 200) {
          if (this.chatList.items && this.chatList.items.length) {
            this.chatList = this.chatList.items
          } else {
            this.chatList = []
          }
        } else {
          this.chatList = [];
        }
      })
    },
    getContent() {
      getActivityDetail({ id: this.ruleForm.id }).then(([data, err]) => {
        if (!err) {
          this.ruleForm.activityContentZh = data.activityContentZh
          this.ruleForm.activityContentEn = data.activityContentEn
          this.ruleForm.activityContentVi = data.activityContentVi
          this.ruleForm.activityContentGu = data.activityContentGu
          this.ruleForm.activityContentJa = data.activityContentJa
          this.ruleForm.activityContentMs = data.activityContentMs
          this.handleLang()
        }
      })
    },
    handleLang() {
      this.$nextTick(() => {
        for (let key in this.content) {
          this.$refs[key].hasInit && this.$refs[key].setContent(this.ruleForm[this.content[key]])
        }
      })
    }
  }
}
</script>


<style scoped>
.switchtext {
  position: absolute;
  color: white;
  left: 20px;
  z-index: 99999 !important;
  font-size: 10px !important;
}

.switchactive {
  position: absolute;
  color: white;
  left: 5px;
  z-index: 99999 !important;
  font-size: 10px !important;
}
</style>