const CanChooseColumns = function() {
  return [
    {
      title: this.$t('活动ID'),
      key: 'id',
      align: 'center'
    },
    {
      title: this.$t('活动标题'),
      key: 'redpacketTitle',
      align: 'center'
    },
    {
      title: this.$t('活动形式'),
      key: 'redpacketType',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.redpacketType[row.redpacketType] }</span>)
        }
      }
    },
    {
      title: this.$t('活动分类'),
      key: 'activityTypeId',
      align: 'center',
      component: {
        render: (h, row) => {
          const activityType = this.activityCategory.find(e => e.id === row.activityTypeId)
          if (activityType) {
            const vnodes = []
            activityType.typeNameLang.split(',').forEach(e => {
              const typeLang = e.split(':')[0]
              const typeName = e.split(':')[1]
              const lang = this.allLang.find(v => v.lang === typeLang)
              if (lang) {
                vnodes.push(<div>{ this.$t(lang.name) + ':' + typeName }</div>)
              }
            })
            return (<span>{ vnodes }</span>)
          }
          return ''
        }
      }
    },
    {
      title: this.$t('活动Banner'),
      key: 'redpacketBanner',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image src={ row.redpacketBanner } lazy></el-image>)
        }
      }
    },
    {
      title: this.$t('活动引导'),
      key: 'redpacketContent',
      align: 'center'
    },
    {
      title: this.$t('有效时间'),
      key: 'activityDate',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.startTime, '{y}-{m}-{d}') }~{ this.$parseTime(row.endTime, '{y}-{m}-{d}') }</span>)
        }
      }
    },
    {
      title: this.$t('充值条件'),
      key: 'requireRechargeAmt',
      align: 'center'
    },
    {
      title: this.$t('投注条件'),
      key: 'requireBetAmt',
      align: 'center'
    },
    {
      title: '领取红包金额',
      key: 'amount',
      align: 'center',
      minWidth: 200,
      component: {
        render: (h, row) => {
          return (
            <div>
              {
                Array.of(1, 2, 3).map(key => {
                  if (row[`robVip${key}Rang`]) {
                    const [vipBegin, vipEnd] = row[`robVip${key}Rang`].split(',')
                    const [, rangeAMinAmount] = row[`robVip${key}Amt1`].split(',')
                    const [, rangeBMaxAmount] = row[`robVip${key}Amt2`].split(',')
                    return (
                      <p>VIP{vipBegin}-VIP{vipEnd},{parseFloat(rangeAMinAmount)}-{parseFloat(rangeBMaxAmount)}元</p>
                    )
                  }
                })
              }
            </div>
          )
        }
      }
    },
    {
      title: this.$t('已派送彩金'),
      key: 'memberAmt',
      align: 'center'
    },
    {
      title: this.$t('虚拟数据金额'),
      key: 'activityContent',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.showAmtMin }~{ row.showAmtMax }</span>)
        }
      }
    },
    {
      title: this.$t('活动状态'),
      key: 'redpacketStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          const status = this.activityStatus.find(o => o.key === row.redpacketStatus)
          return (<span style={{ color: row.activityStatus === 0 ? '#34C758' : '' }}>{ status ? status.label : '' }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 270,
      component: {
        render: (h, row) => {
          const { Update, Close } = this.permission
          return (
            <div>
              { Update && row.redpacketStatus !== 1 && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.ActionDialogControl.open(row, 'update') } }>{ this.$t('编辑') }</el-button> }
              { Close && row.redpacketStatus !== 1 && <el-button type='danger' size='small' icon='el-icon-close' onClick={this.handleCloseActivity.bind(this, row)}>{ this.$t('关闭') }</el-button> }
              { row.redpacketStatus === 1 && <el-button type='primary' size='small' onClick={ () => { this.$refs.ActionDialogControl.open(row, 'detail') } }>查看详情</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
