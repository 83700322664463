<template>
  <el-dialog :title="title" :visible.sync="visible" status-icon width="420px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" label-width="128px" label-suffix=":">
      <el-form-item :label="$t('操作说明')" prop="remark">
        <el-input v-model="ruleForm.remark" type="textarea" :rows="5" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ okText }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  remark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm, false)],
  data() {
    return {
      emitName: 'update',
      type: true
    }
  },

  computed: {
    okText() {
      return this.type ? this.$t('通过') : this.$t('拒绝')
    },
    title() {
      return this.type ? this.$t('审核通过') : this.$t('拒绝通过')
    }
  },

  methods: {
    open(row, type) {
      this.type = type
      this.ruleForm.orderNo = row.orderNo
      this.ruleForm.status = type ? '3333' : '4444'
      this.ruleForm.tenantCode = row.tenantCode
      this.visible = true
    }
  }
}
</script>
