import Sortable from 'sortablejs'
export default (isLlTable) => ({
  mounted() {
    this.$nextTick(() => {
      // 解决火狐浏览器默认拖拽打开新页面
      document.body.ondrop = function(event) {
        event.preventDefault()
        event.stopPropagation()
      }
      this.setSort()
    })
  },
  methods: {
    setSort() {
      let el
      if (isLlTable) {
        el = this.$refs.elTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      } else {
        el = this.$refs.DTable.$refs.elTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      }
      this.sortable = Sortable.create(el, {
        animation: 180,
        delay: 0,
        handle: '.drag-handler',
        ghostClass: 'sortable-ghost',
        setData: function(dataTransfer) {
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
          dataTransfer.setData('Text', '')
        },
        onEnd: ({ newIndex, oldIndex }) => {
          const targetRow = this.items.splice(oldIndex, 1)[0]
          this.items.splice(newIndex, 0, targetRow)
        }
      })
    }
  }
})
