import request from '@/plugins/axios'

/* 封ip/手机 ***************************************************************************************/
export function getLimitIpList(data = {}) {
  return request({
    url: '/block/blockIpList',
    method: 'post',
    data
  })
}

export function updateBlocked(params = {}) {
  return request({
    url: '/block/updateBlocked',
    method: 'get',
    params
  })
}

export function deleteBlocked(params = {}) {
  return request({
    url: '/block/deleteBlocked',
    method: 'get',
    params
  })
}

export function createBlocked(params = {}) {
  return request({
    url: '/block/addBlocked',
    method: 'get',
    params
  })
}

/* 封ip/手机 ***************************************************************************************/
// 查询封ip
export function getLimitIPList(data = {}) {
  return request({
    url: '/block/blockIpList',
    method: 'post',
    data
  })
}

// 修改封ip
export function updateLimitIP(params = {}) {
  return request({
    url: '/block/updateBlockedIp',
    method: 'get',
    params
  })
}

// 删除封ip
export function deleteLimitIP(params = {}) {
  return request({
    url: '/block/deleteBlockedIp',
    method: 'get',
    params
  })
}

// 发送封ip
export function createLimitIP(params = {}) {
  return request({
    url: '/block/addBlockedIp',
    method: 'get',
    params
  })
}
/* 手机**/
// 查询手机列表
export function getLimitPhoneList(data = {}) {
  return request({
    url: '/block/blockPhoneList',
    method: 'post',
    data
  })
}

export function getRegins(params = {}) {
  return request({
    url: '/block/getRegins',
    method: 'get',
    params
  })
}

// 修改手机
export function updateLimitPhone(params = {}) {
  return request({
    url: '/block/updateBlockedPhone',
    method: 'get',
    params
  })
}

// 删除手机
export function deleteLimitPhone(params = {}) {
  return request({
    url: '/block/deleteBlockedPhone',
    method: 'get',
    params
  })
}

// 发送手机
export function createLimitPhone(params = {}) {
  return request({
    url: '/block/addBlockedPhone',
    method: 'get',
    params
  })
}

export function getGodType(params = {}) {
  return request({
    url: '/godType/get',
    method: 'get',
    params
  })
}

export function saveGodType(data) {
  return request({
    url: '/godType/save',
    method: 'post',
    data
  })
}

export function updateGodType(data) {
  return request({
    url: '/godType/update',
    method: 'post',
    data
  })
}

export function deleteGodType(params) {
  return request({
    url: '/godType/delete',
    method: 'post',
    params
  })
}

// 头衔
export function getRankList(data = {}) {
  return request({
    url: '/rank/list',
    method: 'post',
    data
  })
}

export function addRank(data) {
  return request({
    url: '/rank/addRank',
    method: 'post',
    data
  })
}

export function updateRank(data) {
  return request({
    url: '/rank/updateRank',
    method: 'post',
    data
  })
}

export function deleteRank(params) {
  return request({
    url: '/rank/delRank',
    method: 'get',
    params
  })
}

// 隐藏帖子
export function getRecommendHideList(params) {
  return request({
    url: '/post/getHidePost',
    method: 'get',
    params
  })
}

export function updateRecoverRecommend(data) {
  return request({
    url: '/post/recover',
    method: 'post',
    data
  })
}

// 心水评论
export function getCommentList(data) {
  return request({
    url: '/comment/list',
    method: 'post',
    data
  })
}

export function deleteComment(data) {
  return request({
    url: '/comment/delete',
    method: 'post',
    data
  })
}

export function getNoLockPost(params) {
  return request({
    url: '/post/getNoLockPost',
    method: 'get',
    params
  })
}

export function updateCommentCreate(data) {
  return request({
    url: '/comment/create',
    method: 'post',
    data
  })
}

export function batchCommentCreate(data) {
  return request({
    url: '/comment/batch/create',
    method: 'post',
    data
  })
}

export function getRecommendPostDetails(params) {
  return request({
    url: '/post/details',
    method: 'get',
    params
  })
}

export function auditRecommendPostDetails(data) {
  return request({
    url: '/post/audit',
    method: 'post',
    data
  })
}

export function updatePostAuditOpen(params) {
  return request({
    url: '/post/auditOpen',
    method: 'get',
    params
  })
}

// 置顶帖子
export function updatePostTop(data) {
  return request({
    url: '/post/top',
    method: 'post',
    data
  })
}

// 心水
export function getRecommendList(data) {
  return request({
    url: '/recommend/list',
    method: 'post',
    data
  })
}

export function getRecommendHistoryList(params) {
  return request({
    url: '/recommend/historyList',
    method: 'get',
    params
  })
}

export function updateLockAllPost(params = {}) {
  return request({
    url: '/recommend/lockAllPost',
    method: 'get',
    params
  })
}

// 加精
export function updateRecommendTop(data) {
  return request({
    url: '/recommend/top',
    method: 'post',
    data
  })
}

export function updateRecommendHiden(params) {
  return request({
    url: '/recommend/hiden',
    method: 'get',
    params
  })
}

export function updateRecommendLimit(data) {
  return request({
    url: '/recommend/limit',
    method: 'post',
    data
  })
}

export function updateRecommendViewAndAdmire(params) {
  return request({
    url: '/recommend/add',
    method: 'get',
    params
  })
}

export function bacthRecommendLock(data) {
  return request({
    url: '/recommend/lock',
    method: 'post',
    data
  })
}

export function bacthRecommendLimit(data) {
  return request({
    url: '/recommend/limit',
    method: 'post',
    data
  })
}

export function bacthRecommendHidden(data) {
  return request({
    url: '/recommend/batchHiden',
    method: 'post',
    data
  })
}

export function getLastTermForApp(params) {
  return request({
    url: '/post/getAppLastPost',
    method: 'get',
    params
  })
}

export function getLastTermForBack(params) {
  return request({
    url: '/post/getBackLastPost',
    method: 'get',
    params
  })
}

export function updateRecommendPost(data) {
  return request({
    url: '/post/updatePost',
    method: 'post',
    data
  })
}

export function saveRecommendForBack(data) {
  return request({
    url: '/post/save',
    method: 'post',
    data
  })
}

export function saveRecommendForUser(data) {
  return request({
    url: '/post/agentSend',
    method: 'post',
    data
  })
}

// 后台用户
export function getBackUserList(data) {
  return request({
    url: '/user/list',
    method: 'post',
    data
  })
}

// 客服基础配置 
export function getServiceDetail(params) {
  return request({
    url: '/customer/service/settings/detail',
    method: 'get',
    params
  })
}

// 聊天问题
export function getQuestionPageList(data) {
  return request({
    url: '/chat/quick/question/pageList',
    method: 'post',
    data
  })
}

// 修改快捷问题
export function updateQuestionPageList(data) {
  return request({
    url: '/chat/quick/question/update',
    method: 'post',
    data
  })
}

// 新增快捷问题 
export function addQuestionPageList(data) {
  return request({
    url: '/chat/quick/question/add',
    method: 'post',
    data
  })
}

// 删除快捷问题 
export function delQuestionPageList(data) {
  return request({
    url: '/chat/quick/question/del',
    method: 'post',
    data
  })
}


export function getAssistantDelList(data) {
  return request({
    url: '/ai/assistant/del',
    method: 'post',
    data
  })
}

export function getAssistantDelRoom(data) {
  return request({
    url: '/ai/assistant/delRoom',
    method: 'post',
    data
  })
}


export function getAssistantPageList(data) {
  return request({
    url: '/ai/assistant/pageList',
    method: 'post',
    data
  })
}

export function getAiChatDetailList(params = {}) {
  return request({
    url: '/ai/chat/settings/detail',
    method: 'get',
    params
  })
}

export function setAiChatDetailList(data) {
  return request({
    url: '/ai/chat/settings/edit',
    method: 'post',
    data
  })
}
// 编辑客服基础配置 
export function setServiceDetailList(data) {
  return request({
    url: '/customer/service/settings/edit',
    method: 'post',
    data
  })
}


export function updateBackUser(data) {
  return request({
    url: '/user/update',
    method: 'post',
    data
  })
}



export function updateAssistant(data) {
  return request({
    url: '/ai/assistant/update',
    method: 'post',
    data
  })
}

export function addAssistant(data) {
  return request({
    url: '/ai/assistant/add',
    method: 'post',
    data
  })
}

export function batchUpdateBackUserStatus(data) {
  return request({
    url: '/user/delUser',
    method: 'post',
    data
  })
}

// 审核
export function getRecommendPostList(data) {
  return request({
    url: '/post/list',
    method: 'post',
    data
  })
}

export function deleteHideRecommend(params) {
  return request({
    url: '/post/delete',
    method: 'get',
    params
  })
}

export function batchUpdateAuditStatus(data) {
  return request({
    url: '/post/batchAudit',
    method: 'post',
    data
  })
}

// 六合图库
export function getPictureIssues(params) {
  return request({
    url: '/tenant/lhc/issue',
    method: 'get',
    params
  })
}

export function getPictureList(params = {}) {
  return request({
    url: '/tenant/picture/list',
    method: 'get',
    params
  })
}

export function getPictureStatistical(params) {
  return request({
    url: '/tenant/picture/update/stat',
    method: 'get',
    params
  })
}

export function createPicture(data) {
  return request({
    url: '/tenant/picture/add',
    method: 'post',
    data
  })
}

export function updatePicture(data) {
  return request({
    url: '/tenant/picture/upload',
    method: 'post',
    data
  })
}

export function deletePicture(data) {
  return request({
    url: '/tenant/picture/del',
    method: 'post',
    data
  })
}

export function updatePictureStatus(data) {
  return request({
    url: '/tenant/picture/status',
    method: 'post',
    data
  })
}

// 图库评论

export function getPictureCommentList(params) {
  return request({
    url: '/picture/comment/list',
    method: 'get',
    params
  })
}

export function delComment(data) {
  return request({
    url: '/comment/del',
    method: 'post',
    data
  })
}

// 平台图库新增评论
export function pictureCommentAdd(data) {
  return request({
    url: '/picture/comment/add',
    method: 'post',
    data
  })
}
// 后台管理回复
export function sendReply(data) {
  return request({
    url: '/article/sendReply',
    method: 'post',
    data
  })
}

// 代用户回复
export function sendAgentReply(data) {
  return request({
    url: '/article/agentReply',
    method: 'post',
    data
  })
}

// 代用户发嗮单圈
export function sendAgentArticle(data) {
  return request({
    url: '/article/agentArticle',
    method: 'post',
    data
  })
}

// 后台管理发嗮单圈
export function sendArticle(data) {
  return request({
    url: '/article/sendArticle',
    method: 'post',
    data
  })
}
// 获取图库名称
export function pictureList(data) {
  return request({
    url: '/tenant/picture/queryPictureList',
    method: 'post',
    data
  })
}
// 六合图库新增--获取快乐六合期数
export function openPrize(data) {
  return request({
    url: '/tenant/lottery/open/prize/list',
    method: 'post',
    data
  })
}
// 新增图库
export function addPictureRecord(data) {
  return request({
    url: '/tenant/picture/addPictureRecord',
    method: 'post',
    data
  })
}

// 查询社区配置
export function communityGetConfigDetail(params) {
  return request({
    url: '/community/get/config/detail',
    method: 'get',
    params
  })
}

// 编辑社区配置
export function communityEditConfig(data) {
  return request({
    url: '/community/edit/config',
    method: 'post',
    data
  })
}

// 分页查询六合图库类别
export function pictureCategoryPageList(data) {
  return request({
    url: '/tenant/picture/category/pageList',
    method: 'post',
    data
  })
}


// 新增六合图库类别
export function pictureCategoryAdd(data) {
  return request({
    url: '/tenant/picture/category/add',
    method: 'post',
    data
  })
}


// 修改六合图库类别
export function pictureCategoryUpdate(data) {
  return request({
    url: '/tenant/picture/category/update',
    method: 'post',
    data
  })
}


// 删除六合图库类别
export function pictureCategoryDel(data) {
  return request({
    url: '/tenant/picture/category/del',
    method: 'post',
    data
  })
}

