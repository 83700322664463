<template>
  <el-dialog
    title="指定会员"
    width="550px"
    top="8vh"
    :visible.sync="visible"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item label="会员账号" prop="memberName">
        <el-input v-model.trim="ruleForm.memberName" maxlength="20" placeholder="输入会员账号，最长20个字符">
          <el-button slot="append" :loading="selecting" @click="queryMember" type="primary" style="color: #FFFFFF; background-color: #3894FF; border: 1px solid #3894FF;">查询</el-button>
        </el-input>
      </el-form-item>
      <el-form-item v-if="memberInfo.memberName && memberInfo.memberName === ruleForm.memberName">
        <div><span>会员账号：{{ memberInfo.memberName }}</span><span style="margin-left: 30px;">会员昵称：{{ memberInfo.nickname }}</span></div>
        <div>会员类型：{{ memberTypes.find(e => e.key === memberInfo.memberType).label }}</div>
      </el-form-item>
      <el-form-item label="限制类型" prop="limitType">
        <el-select v-model="ruleForm.limitType" style="width: 100%;" placeholder="请选择限制类型">
          <el-option v-for="(item, key) in limitTypes" :key="key" :label="item" :value="+key" />
        </el-select>
      </el-form-item>
      <template v-if="[3,4].includes(ruleForm.limitType)">
        <el-form-item label="房间类型" prop="roomType">
          <el-select v-model="ruleForm.roomType" style="width: 100%;" placeholder="请选择房间类型" @change="handleChangeRoomType">
            <el-option v-for="item in roomTypes" :key="item.value" :label="item.label" :value="item.value.toString()" />
          </el-select>
        </el-form-item>
        <el-form-item label="聊天室房间" prop="groupId">
          <el-select v-model="ruleForm.groupId" style="width: 100%;" filterable placeholder="请选择聊天室房间">
            <el-option
              v-for="item in roomList"
              :key="item.roomId"
              :label="item.roomName"
              :value="item.roomId">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="禁言时长" prop="expireMin">
        <el-input v-model.number="ruleForm.expireMin" :disabled="minEver" style="width: 220px;" placeholder="请输入禁言时长">
          <template slot="append">分钟</template>
        </el-input>
        <el-checkbox style="margin-left: 30px;" @change="handleChangeMin" v-model="minEver">永久</el-checkbox>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { roomTypes, memberTypes, limitTypes } from '@/libs/options'
import chatRoomListMixin from './../mixins/chatRoomList'
import { getAllUsersByCondition } from "@/api/member";

const getDefaultForm = () => ({
  cmd: '',
  memberName: '',
  to: '',
  toNickname: '',
  limitType: '',
  roomType: ' ',
  groupId: '*',
  groupName: '*',
  expireMin: ' '
})

export default {
  mixins: [FormDialogMixin(getDefaultForm), chatRoomListMixin],
  data() {
    return {
      emitName: 'create',
      loading: false,
      roomTypes,
      memberTypes,
      limitTypes,
      roomList: [],
      memberInfo: {},
      selecting: false,
      minEver: false,
      rules: {
        memberName: [{ required: true, message: '请输入会员账号', trigger: 'blur' }],
        limitType: [{ required: true, message: '请选择限制类型', trigger: 'change' }],
        roomType: [{ required: true, message: '请选择房间类型', trigger: 'change' }],
        groupId: [{ required: true, message: '请选择聊天室房间', trigger: 'change' }],
        expireMin: [{ required: true, message: '请输入禁言时长', trigger: 'blur' }]
      }
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    async handleChangeRoomType () {
      if (this.ruleForm.roomType) {
        this.loading = true
        this.roomList = await this._getChatRoomList({ roomType: this.ruleForm.roomType }) || []
        this.loading = false
      } else {
        this.roomList = []
      }
    },
    queryMember () {
      this.selecting = true
      getAllUsersByCondition({ currentPage: 1, pageSize: 1, memberName: this.ruleForm.memberName }).then(([items, err]) => {
        if (!err) {
          if (items.length === 0) {
            this.$message.warning('未查询到该会员信息')
          } else {
            this.memberInfo = items[0]
          }
        }
      }).finally(() => {
        this.selecting = false
      })
    },
    handleChangeMin () {
      if (this.minEver) {
        this.ruleForm.expireMin = ' '
      } else {
        this.ruleForm.expireMin = ''
      }
    },
    handleSubmitBefor () {
      if (!this.ruleForm.memberName || this.memberInfo.memberName !== this.ruleForm.memberName) {
        this.$message.error('请先查询会员，确认会员信息')
        return
      }
      if ([3,4].includes(this.ruleForm.limitType)) {
        this.ruleForm.roomType = this.ruleForm.roomType.trim()
        this.ruleForm.groupId = this.ruleForm.groupId.trim()
        if (this.ruleForm.groupId) {
          this.ruleForm.groupName = this.roomList.find(e => e.roomId === this.ruleForm.groupId).roomName
        }
      } else {
        this.ruleForm.roomType = ' '
        this.ruleForm.groupId = '*'
        this.ruleForm.groupName = '*'
      }
      this.ruleForm.to = this.memberInfo.memberId
      this.ruleForm.toNickname = this.memberInfo.nickname
      if (this.minEver) {
        switch (this.ruleForm.limitType) {
          case 1:
          case 3:
            this.ruleForm.cmd = 2001
            break
          case 2:
          case 4:
            this.ruleForm.cmd = 1001
            break
        }
      } else {
        switch (this.ruleForm.limitType) {
          case 1:
          case 3:
            this.ruleForm.cmd = 2002
            break
          case 2:
          case 4:
            this.ruleForm.cmd = 1002
            break
        }
      }
      this.handleSubmit()
    }
  }
}
</script>
