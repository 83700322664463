<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <component :is="activeName" ref="AliveComponent" />
    </template>
  </div>
</template>
<script>
import ConfigTab from './ConfigTab'
import EmitTab from './EmitTab'
import ReceiveTab from './ReceiveTab'
import { parentMixin } from '@/mixins/tab-items'

export default {
  components: {
    ConfigTab,
    EmitTab,
    ReceiveTab
  },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      tabs: [
        { label: '红包发出记录', name: 'EmitTab' },
        { label: '红包领取记录', name: 'ReceiveTab' },
        { label: '红包规则配置', name: 'ConfigTab' }
      ],
      orderId: ''
    }
  },
  methods: {
    handleReceiveTab(id) {
      this.orderId = id
      this.activeName = 'ReceiveTab'
    }
  }
}
</script>

