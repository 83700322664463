var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("敏感词列表"), prop: "sendInfo" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: _vm.$t("敏感词用逗号分割！！"),
                  autosize: { minRows: 15 },
                },
                model: {
                  value: _vm.ruleForm.sendInfo,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sendInfo", $$v)
                  },
                  expression: "ruleForm.sendInfo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.close } },
            [_vm._v(" " + _vm._s(_vm.$t("关闭")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "medium",
                loading: _vm.submitting,
              },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确认")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }