var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bodys" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "220px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客服开关", prop: "serviceSwitch" } },
            [
              _c("d-switch", {
                attrs: { "inactive-value": 0, "active-value": 1 },
                on: {
                  change: function (val) {
                    if (!val) {
                      _vm.ruleForm.serviceName = "在线客服"
                    }
                  },
                },
                model: {
                  value: _vm.ruleForm.serviceSwitch,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "serviceSwitch", $$v)
                  },
                  expression: "ruleForm.serviceSwitch",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客服名称", required: "", prop: "serviceName" } },
            [
              _c("el-input", {
                attrs: { maxlength: "10" },
                model: {
                  value: _vm.ruleForm.serviceName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "serviceName", $$v)
                  },
                  expression: "ruleForm.serviceName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客服图标", prop: "avatar" } },
            [
              _c("d-upload", {
                attrs: { folder: "head", "on-success": _vm.handleSuccess },
                model: {
                  value: _vm.ruleForm.avatar,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "avatar", $$v)
                  },
                  expression: "ruleForm.avatar",
                },
              }),
              _vm._v(
                " 只支持.jpg、.png格式，大小为2MB以下，不设置时默认系统头像 "
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "系统公告开关", prop: "announcementSwitch" } },
            [
              _c("d-switch", {
                attrs: { "inactive-value": 0, "active-value": 1 },
                model: {
                  value: _vm.ruleForm.announcementSwitch,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "announcementSwitch", $$v)
                  },
                  expression: "ruleForm.announcementSwitch",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "系统公告内容", prop: "announcementMessage" } },
            [
              _c("Tinymce", {
                key: _vm.tinymce1,
                ref: "tinyMceOne",
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.announcementMessage,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "announcementMessage", $$v)
                  },
                  expression: "ruleForm.announcementMessage",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "欢迎词开关", prop: "welcomeSwitch" } },
            [
              _c("d-switch", {
                attrs: { "inactive-value": 0, "active-value": 1 },
                model: {
                  value: _vm.ruleForm.welcomeSwitch,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "welcomeSwitch", $$v)
                  },
                  expression: "ruleForm.welcomeSwitch",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "欢迎词内容", prop: "welcomeMessage" } },
            [
              _c("Tinymce", {
                key: _vm.tinymce2,
                ref: "tinyMceTwo",
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.welcomeMessage,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "welcomeMessage", $$v)
                  },
                  expression: "ruleForm.welcomeMessage",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }