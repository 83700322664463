const CanChooseColumns = function() {
  return [
    {
      title: '发送方式',
      key: 'sendType',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (<span>{ this.sendTypeOptions[row.sendType] }</span>)
        }
      }
    },
    {
      title: '发送租户数量',
      key: 'number',
      align: 'center',
      width: 100
    },
    {
      title: '站内信标题',
      key: 'title',
      align: 'center'
    },
    {
      title: '创建时间',
      key: 'createTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: '创建人',
      key: 'createOperatorName',
      align: 'center'
    },
    {
      title: '最后编辑时间',
      key: 'updateTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: '最后操作人',
      key: 'updateOperatorName',
      align: 'center'
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          const { Delete, Update } = this.permission
          return (
            <span>
              { Update && <el-button type='text' onClick={ () => this.onAction(row) }>编辑</el-button> }
              { Delete && <el-button type='text' onClick={ () => this._handleDelete(row) }>删除</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
