const CanChooseColumns = function() {
  return [
    {
      title: this.$t('序号'),
      key: 'id',
      align: 'center'
    },
    {
      title: this.$t('系列'),
      key: 'seriesTag',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.cascaderOptions.find(e => e.value === row.seriesTag)?.label }</span>)
        }
      }
    },
    {
      title: this.$t('彩种封面编辑'),
      key: 'name',
      align: 'center'
    },
    {
      title: this.$t('直播中'),
      key: 'liveImg',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image style='width:48px;height:48px' src={row.liveImg} lazy></el-image>)
        }
      }
    },
    {
      title: this.$t('即将开奖'),
      key: 'playImg',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image style='width:48px;height:48px' src={row.playImg} lazy></el-image>)
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      component: {
        render: (h, row) => {
          const { Edeti } = this.permission
          return (
            <div>
              { Edeti && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.ActionDialogControl.open(row) } }>{ this.$t('编辑') }</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
