var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("Interactive_Match.LeagueTab.编辑赛事"),
        visible: _vm.visible,
        width: "560px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Interactive_Match.LeagueTab.支持推荐"),
                prop: "recommend",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.recommend,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "recommend", $$v)
                    },
                    expression: "ruleForm.recommend",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("是"))),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("否"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Interactive_Match.LeagueTab.热门推荐"),
                prop: "hot",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.hot,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "hot", $$v)
                    },
                    expression: "ruleForm.hot",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("是"))),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("否"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Interactive_Match.LeagueTab.赛事展示"),
                prop: "showStatus",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.showStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "showStatus", $$v)
                    },
                    expression: "ruleForm.showStatus",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("是"))),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("否"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Interactive_Match.LeagueTab.推荐玩法"),
                prop: "recommendPlay",
              },
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.recommendPlay,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "recommendPlay", $$v)
                    },
                    expression: "ruleForm.recommendPlay",
                  },
                },
                _vm._l(_vm.recommendPlayMaps, function (label, value) {
                  return _c(
                    "el-checkbox",
                    {
                      key: value,
                      attrs: { name: "recommendPlay", label: value },
                    },
                    [_vm._v(_vm._s(label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("Interactive_Match.LeagueTab.联赛等级"),
                prop: "level",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("请输入m_n区间数字", { text: "0-100" }),
                },
                model: {
                  value: _vm.ruleForm.level,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "level", _vm._n($$v))
                  },
                  expression: "ruleForm.level",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确定")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }