<template>
  <div class="cascader-select">
    <div v-if="options.length" class="cascader-select-item">
      <label v-if="label" class="cascader-select-item__label">{{ parentLabel }}:</label>

      <el-radio-group v-model="parentValue" :size="size">
        <el-radio-button v-for="parent in options" :key="parent.value" :label="parent.value">{{ parent.label }}</el-radio-button>
      </el-radio-group>
    </div>

    <div v-if="multiple && childOptions.length" class="cascader-select-item">
      <label v-if="label" class="cascader-select-item__label">{{ childLabel }}:</label>

      <el-radio-group v-model="childValue" :size="size">
        <el-radio-button v-for="child in childOptions" :key="child.value" :label="child.value">{{ child.label }}</el-radio-button>
      </el-radio-group>
    </div>

  </div>
</template>

<script>
import { oneOf } from '@/utils/assist'
import { isEqual, isEmpty } from '@/utils/assist'
import { $t } from '@/lang'

export default {
  name: 'CascaderSelect',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    size: {
      validator(value) {
        return oneOf(value, ['medium', 'small', 'mini', 'default'])
      },
      default: 'small'
    },
    options: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: true
    },
    label: {
      type: Boolean,
      default: true
    },
    parentLabel: {
      type: String,
      default: $t('系列')
    },
    childLabel: {
      type: String,
      default: $t('彩种')
    }
  },
  data() {
    return {
      checkedValue: this.value
    }
  },
  computed: {
    childOptions() {
      if (!isEmpty(this.checkedValue)) {
        return this._getChildren(this.checkedValue[0])
      }
      return []
    },
    parentValue: {
      get() {
        return this.checkedValue[0]
      },
      set(val) {
        const tmp = this._getChildren(val)
        this.checkedValue = [val, tmp[0]?.value]
      }
    },
    childValue: {
      get() {
        return this.checkedValue[1]
      },
      set(val) {
        this.$set(this.checkedValue, 1, val)
      }
    }
  },
  watch: {
    value(val) {
      if (!isEqual(val, this.checkedValue)) {
        this.checkedValue = val
      }
    },
    checkedValue(val) {
      this.$emit('input', val)
      this.$emit('change', val)
    }
  },
  methods: {
    _getChildren(value) {
      return this.options.find(o => o.value === value).children
    }
  }
}
</script>

<style lang="scss" scoped>
.cascader-select {
  &-item {
    margin-bottom: 10px;
    &__label {
      display: inline-block;
      width: 80px;
      font-size: 14px;
      font-weight: inherit;
    }
  }
}
</style>
