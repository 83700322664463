<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive>
        <component :is="activeName" />
      </keep-alive>
    </template>
  </div>
</template>
<script>
import System from './SystemLog'
import User from './UserLog'
import { parentMixin } from '@/mixins/tab-items'

export default {
  components: {
    User,
    System
  },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      activeName: '',
      tabs: [
        { label: this.$t('用户登录日志'), name: 'User' },
        { label: this.$t('后台登录日志'), name: 'System' }
      ]
    }
  }
}
</script>
