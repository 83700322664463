<template>
  <div class="app-container">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
    </el-tabs>
    <keep-alive><component :is="activeName" /></keep-alive>
  </div>
</template>
<script>
import { parentMixin } from '@/mixins/tab-items'
import ListTab from './ListTab'
import HideTab from './HideTab'
import CommentTab from './CommentTab'
import BackTab from './BackTab'
import AuditTab from './AuditTab'
import LabelTab from './LabelTab'
import { getRankList } from '@/api/interactive'

export default {
  name: 'InteractiveRecommend',
  components: {
    ListTab,
    HideTab,
    CommentTab,
    BackTab,
    AuditTab,
    LabelTab
  },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      ranks: [],
      tabs: [
        { label: '心水推荐', name: 'ListTab' },
        { label: '隐藏帖子', name: 'HideTab' },
        { label: '心水评论', name: 'CommentTab' },
        { label: '后台用户', name: 'BackTab' },
        { label: '审   核', name: 'AuditTab' },
        { label: '标签设置', name: 'LabelTab' }
      ]
    }
  },
  created() {
    this.handleRankList()
  },
  methods: {
    handleRankList() {
      getRankList({ tenantCode: this.$store.getters.tenantCode }).then(([data, err]) => {
        if (!err) {
          data.forEach(item=>{
            item.rank = `${item.minAdmire}-${item.maxAdmire}`
          })
          this.ranks = data
        }
      })
    }
  }
}
</script>
<style lang="scss">
.tab-header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #bbb;
}
</style>
