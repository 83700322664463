import Layout from '@/layout'

export default {
  path: '/AgentRebate',
  component: Layout,
  redirect: 'noRedirect',
  name: 'AgentRebate',
  meta: { title: '代理返水', icon: 'proxy' },
  children: [
    {
      path: '/AgentRebate_ProxLine',
      component: () => import('@/views/agent-rebate/ProxLine'),
      name: 'AgentRebateProxLine',
      meta: { title: '代理线路' }
    },
    {
      path: '/AgentRebate_Report',
      component: () => import('@/views/agent-rebate/Report/main'),
      name: 'AgentRebateReport',
      meta: { title: '代理报表' }
    },
    {
      path: '/AgentRebate_Report_Detail',
      component: () => import('@/views/agent-rebate/Report/detail'),
      name: 'AgentRebateReportDetail',
      meta: { title: '代理明细', noCache: true, activeMenu: '/AgentRebate_Report' },
      hidden: true
    },
    {
      path: '/AgentRebate_Data',
      component: () => import('@/views/agent-rebate/Data'),
      name: 'AgentRebateData',
      meta: { title: '代理数据' }
    },
    {
      path: '/AgentRebate_Setting',
      component: () => import('@/views/agent-rebate/Setting'),
      name: 'AgentRebateSetting',
      meta: { title: '代理返佣配置' }
    }
  ]
}

