var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "border-form-dialog",
      attrs: {
        title: _vm.$t("流播放功能"),
        visible: _vm.visible,
        width: "480px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "show-message": false,
            "label-suffix": ":",
            "label-width": "160px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("流媒体地址"), prop: "streamMediaUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.streamMediaUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "streamMediaUrl", _vm._n($$v))
                  },
                  expression: "ruleForm.streamMediaUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("彩种"), prop: "lotkindId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  model: {
                    value: _vm.ruleForm.lotkindId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "lotkindId", $$v)
                    },
                    expression: "ruleForm.lotkindId",
                  },
                },
                _vm._l(_vm.$parent.lotteryOptions, function (item) {
                  return _c("el-option", {
                    key: item.lotteryId,
                    attrs: { label: item.name, value: item.lotteryId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("频道"), prop: "unionId" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.unionId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "unionId", $$v)
                    },
                    expression: "ruleForm.unionId",
                  },
                },
                _vm._l(_vm.$parent.channelOptions, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.unionId, attrs: { label: item.unionId } },
                    [_vm._v(_vm._s(item["channelName" + _vm.getLanguageKey()]))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("封面"), prop: "cover" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "thumbnail-uploader",
                  attrs: {
                    accept: "image/jpeg,image/png,image/jpg,image/webp",
                    action: _vm.action,
                    data: { type: "image", folder: "ad-photo" },
                    headers: _vm.$headers(),
                    "show-file-list": false,
                    "on-success": _vm.handleThumbnailSuccess,
                  },
                },
                [
                  _vm.ruleForm.cover
                    ? _c("img", {
                        staticClass: "thumbnail",
                        attrs: { src: _vm.ruleForm.cover },
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus thumbnail-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", { staticStyle: { "font-size": "12px", "line-height": "30px" } }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "下播后的主播，可通过后台填入流媒体地址进行虚拟开播，与正常开播效果相同"
            )
          )
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确认")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }