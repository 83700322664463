<template>
  <div>
    <!-- <div style="width: 100px;">{{listItem  }}</div> -->

    <!-- 任务分享 -->
    <template v-if="this.listItem.msgType == 212">
      <el-card class="box-div" :body-style="{ padding: '0px' }">
        <div class="box-left">
          {{ listItem.msgType == 212 ? '任务分享' : taskTypeMap[listItem.isDaily].name }}
        </div>
        <div class="setDisplay setPd" style="min-height: 30px">
          <span>
            <span v-if="listItem.isReceiveAward == 1" class="mainText mainSize"
              >{{ listItem.memberName }}完成</span
            >
            <span class="mainText mainSize setBold">{{
              listItem.activityTitle
            }}</span>
          </span>
          <el-button
            v-if="listItem.isReceiveAward != 1"
            type="danger"
            @click="getActivityReward(listItem)"
            style="background-color: #c62430"
            round
          >
            {{
              listItem.isAchieveGoal == 1 && listItem.isAchieveBase != 0
                ? "领取奖励"
                : "去完成"
            }}
          </el-button>
        </div>
        <el-divider></el-divider>
        <div class="setDisplayCol setPd">
          <div
            v-if="listItem.isReceiveAward != 1"
            v-for="(item, index) in typeMapList[listItem.modeType]"
            :key="index"
          >
            <div class="smallText">{{ item.name }}</div>
            <div class="setPdTop setDisplay">
              <div style="width: 60%">
                <el-progress
                  :stroke-width="8"
                  stroke-linecap="butt"
                  :percentage="
                    listItem[item.currentKey] / listItem[item.totalKey] > 1
                      ? 100
                      : (listItem[item.currentKey] / listItem[item.totalKey]) * 100 || 0
                  "
                  :show-text="false"
                  color="#c62430"
                ></el-progress>
              </div>
              <div>
                <span style="color: #c62430"
                  >{{ listItem[item.currentKey] * 1 }} /
                  {{ listItem[item.totalKey] * 1 }}</span
                >
              </div>
            </div>
          </div>
          <div
            style="margin: 10px 0px; justify-content: start"
            class="setDisplay"
          >
            <div class="smallText">任务奖励:</div>
            <div class="coulist">
              <div v-if="listItem.couponTitles">
                <span class="title">抵扣券</span>
                <span class="val">{{ listItem.couponTitles }}</span>
              </div>
              <div
                v-if="
                  listItem.amountRolling &&
                  !!Number(listItem.amountRolling.split(',')[0])
                "
              >
                <span class="title">彩金</span>
                <span class="val"
                  >+{{ listItem.amountRolling.split(",")[0] }}</span
                >
              </div>
              <div v-if="listItem.fullCouponTitles">
                <span class="title">全勤抵扣券</span>
                <span class="val">{{ listItem.fullCouponTitles }}</span>
              </div>
              <div
                v-if="
                  listItem.fullAmountRolling &&
                  !!Number(listItem.fullAmountRolling.split(',')[0])
                "
              >
                <span class="title">全勤彩金</span>
                <span class="val"
                  >+{{ listItem.fullAmountRolling.split(",")[0] }}</span
                >
              </div>
            </div>
          </div>
          <div class="setfont">
            {{ parseTime(item.timestamp, "{y}-{m}-{d} {h}:{i}") }}
          </div>
        </div>
      </el-card>
    </template>
    <!-- 富文本 -->
    <template v-else-if="listItem.msgType == 213">
      <div v-html="listItem.extras.content"></div>
    </template>
    <!-- 注单 -->
    <el-card v-else class="box-card">
      <div slot="header" class="clearfix">
        <span class="mainText mainSize fontBold fl">{{
          listData.statusTitle
        }}</span>
        <span class="smallText fr">{{ listData.statusText }}</span>
      </div>
      <div v-for="(items, index) in listData.list" :key="index">
        <template v-if="items.type == 1">
          <div class="setDisplay">
            <span class="smallText">{{ items.label }}</span>
            <div>
              <span class="mainText smallSize"> {{ items.value }}</span>
              <i class="el-icon-document-copy smallSize"></i>
            </div>
          </div>
        </template>
        <template v-else-if="items.type == 2">
          <div class="setDisplay">
            <span class="smallText">{{ items.label }}</span>
            <div>
              <span class="mainText smallSize">{{ items.value }}</span>
              <div class="iconWidth"></div>
            </div>
          </div>
        </template>

        <template v-else-if="items.type == 3">
          <div class="setDisplay">
            <span class="smallSize mainText">{{ items.label }}</span>
            <div>
              <span class="mainText smallSize">{{ items.value }}</span>
              <div class="iconWidth"></div>
            </div>
          </div>
        </template>

        <template v-else-if="items.type == 4">
          <div class="setDisplay">
            <span class="mainText smallSize">{{ items.label }}</span>
            <div class="iconWidth"></div>
          </div>
        </template>

        <template v-else-if="items.type == 5">
          <div class="setDisplay">
            <span class="mainText smallSize">{{ items.label }}</span>
            <div>
              <span class="smallText smallSize"> 场次: </span>
              <span class="mainText smallSize">{{ items.value }}</span>
            </div>
          </div>
        </template>
      </div>
    </el-card>
  </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";
import { parseTime } from "@/utils";

export default {
  name: "CardItems",
  components: {},
  props: {
    permission: {
      type: Object,
      default: () => ({}),
    },
    showMessage: {},
    item: {
      type: Object,
      default: () => ({}),
    },
    me: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parseTime,
      listData: [],
      listItem: {},
      taskTypeMap: {
        0: {
          name: "周任务",
          className: "day",
        },
        1: {
          name: "日任务",
          className: "day",
        },
        2: {
          name: "新手任务",
          className: "day",
        },
        3: {
          name: "长期任务",
          className: "day",
        },
      },
      typeMapList: {
        0: [
          {
            name: "充值金额",
            currentKey: "dailyRecharge",
            totalKey: "rechargeAmount",
          },
          {
            name: "充值单数",
            currentKey: "dailyRechargeTimes",
            totalKey: "rechargeTimes",
            unit: "单",
          },
        ],
        1: [
          {
            name: "投注金额",
            currentKey: "memberBetsAmount",
            totalKey: "betsAmount",
          },
          {
            name: "投注单数",
            currentKey: "memberBetsTimes",
            totalKey: "betsTimes",
            unit: "单",
          },
        ],
        2: [
          {
            name: "邀请人数",
            currentKey: "memberInviteNums",
            totalKey: "inviteNums",
            unit: "人",
          },
        ],
        3: [
          {
            name: "观看时长",
            currentKey: "memberLiveSeeMinites",
            totalKey: "liveSeeMinites",
            unit: "分钟",
          },
          {
            name: "送礼金额",
            currentKey: "memberLiveGiftAmount",
            totalKey: "liveGiftAmount",
          },
          {
            name: "房费金额",
            currentKey: "memberLiveRoomAmount",
            totalKey: "liveRoomAmount",
          },
          {
            name: "发送消息",
            currentKey: "memberLiveMsgNums",
            totalKey: "liveMsgNums",
            unit: "条",
          },
        ],

        4: [
          {
            name: "跟单金额",
            currentKey: "memberFollowBetsAmount",
            totalKey: "followBetsAmount",
          },
          {
            name: "跟单单数",
            currentKey: "memberFollowBetsTimes",
            totalKey: "followBetsTimes",
            unit: "单",
          },
        ],
        5: [
          {
            name: "发帖数量",
            currentKey: "memberPostsNums",
            totalKey: "postsNums",
            unit: "个",
          },
          {
            name: "评论数",
            currentKey: "memberPostsCommentNums",
            totalKey: "postsCommentNums",
            unit: "条",
          },
          {
            name: "点赞数",
            currentKey: "memberPostsLikeNums",
            totalKey: "postsLikeNums",
            unit: "个",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    item: {
      immediate: true,
      handler(val) {
        this.listItem = val;
        if (this.listItem?.extras && this.listItem.msgType == 213) {
          this.listItem.extras = JSON.parse(this.listItem.extras);
        }
      },
    },
  },
  created() {},
  mounted() {},
  // 方法集合
  methods: {
    _doAction(type) {
      this.$emit("_doAction", type);
    },
    getActivityReward(data) {
      if (!this.$props.permission?.JumpTask) {
        MessageBox.confirm(
          "你暂无权限进行此操作，请联系系统管理员，或者选择其他账号登录",
          "系统提示",
          {
            confirmButtonText: "重新登入",
            type: "warning",
          }
        )
          .then(() => {})
          .catch(() => {});
      } else {
        this.$router.push({ name: "ActivityTask", query: { id: data.id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.coulist {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;

  .title {
    color: #333;
  }

  .val {
    font-size: 16px;
    margin-left: 10px;
    color: #c62430;
  }
}
.box-div {
  min-height: 150px;
}
</style>
