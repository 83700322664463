<template>
  <el-dialog
    :visible.sync="visible"
    width="800px"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-table :data="roomList" style="width: 100%" max-height="350">
      <el-table-column prop="roomTypeName" label="聊天室类型" width="250">
      </el-table-column>
      <el-table-column prop="roomName" label="聊天室" width="250">
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <!-- <el-button @click="close"> 取消 </el-button> -->
      <el-button type="primary" :loading="submitting" @click="close">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from "@/mixins/form-dialog";
import { getLiveRoomList, getDetailRoom, AIsaveChatRoom } from "@/api/chat";
import DUpload from "@/components/DUpload";

const getDefaultForm = () => ({
  roomType: "",
  roomeId: "",
});
export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      visible:false,
      roomIdList: [],
      roomList: [],
      _roomList: [],
      assistantId: "",
      roomTypeList: [
        { label: "互动大厅", value: 1, type: "lottery" },
        { label: "赛事-滚球", value: 2, type: "sport" },
        { label: "赛事-竞彩", value: 5, type: "sport" },
        { label: "热门彩种", value: 3, type: "lottery" },
        { label: "直播", value: 4, type: "live" },
      ],
      prefixMap: {
        1: "interactive_",
        2: "sportrolling_",
        5: "sportlottery_",
        3: "hotlottery_",
        4: "live_",
      },
    };
  },
  methods: {
    open(row, title, type) {
      this.roomList = []
      this._getChatRoomList(type, title)
      this.visible = true;
    },

    handleClosed() {},

    // 获取聊天室列表
    async _getChatRoomList(value, title) {
      getLiveRoomList({ roomType: value }).then((res) => {
        if (res[0].length > 0) {
          let arr = []
          res[0].forEach(v => {
            arr.push({
              ...v,
              roomTypeName: title
            })
          })
          this.roomList = arr;
        }
      });
    },

  },
};
</script>
