var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("Expert_Plan.方案详情"),
        visible: _vm.visible,
        width: "55%",
        top: "5vh",
        "append-to-body": _vm.appendToBody,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "170px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Expert_Plan.方案单号") } },
            [_vm._v(_vm._s(_vm.ruleForm.id))]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Expert_Plan.过关方式") } },
            [
              _vm._v(
                _vm._s(
                  _vm.ruleForm.articleSchedules === 1
                    ? _vm.$t("Expert_Plan.单场")
                    : _vm.$t("Expert_Plan.串联")
                )
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Expert_Plan.是否收费") } },
            [
              _vm._v(
                _vm._s(
                  parseInt(_vm.ruleForm.goldnum) === 0
                    ? _vm.$t("Expert_Plan.免费")
                    : _vm.ruleForm.goldnum
                )
              ),
              _vm.ruleForm.goldnum > 0 && _vm.ruleForm.isRefund === 1
                ? _c("span", { staticStyle: { "margin-left": "30px" } }, [
                    _vm._v("不中退"),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Expert_Plan.阅读量"), prop: "newView" } },
            [
              _vm.edit
                ? _c(
                    "el-input",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        placeholder: _vm.$t("请输入", { text: _vm.$t("整数") }),
                      },
                      model: {
                        value: _vm.ruleForm.newView,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "newView", _vm._n($$v))
                        },
                        expression: "ruleForm.newView",
                      },
                    },
                    [
                      _c("template", { slot: "prepend" }, [
                        _vm._v(
                          _vm._s(
                            _vm.ruleForm.viewNum ? _vm.ruleForm.viewNum : 0
                          ) + "+"
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.newView + _vm.ruleForm.viewNum)),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("Expert_Plan.销售量"), prop: "newBuyNum" },
            },
            [
              _vm.edit
                ? _c(
                    "el-input",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        placeholder: _vm.$t("请输入", { text: _vm.$t("整数") }),
                      },
                      model: {
                        value: _vm.ruleForm.newBuyNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "newBuyNum", _vm._n($$v))
                        },
                        expression: "ruleForm.newBuyNum",
                      },
                    },
                    [
                      _c("template", { slot: "prepend" }, [
                        _vm._v(_vm._s(_vm.ruleForm.buynum) + "+"),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.newBuyNum + _vm.ruleForm.buynum)),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Expert_Plan.赛事选择") } },
            [
              _vm.ruleForm.matchList && _vm.ruleForm.matchList.length > 0
                ? _c(
                    "ul",
                    { staticClass: "matchList" },
                    _vm._l(_vm.ruleForm.matchList, function (item) {
                      return _c("li", { key: item.matchId }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("parseTime")(
                                item.matchTime,
                                "{m}-{d} {h}:{i}"
                              )
                            )
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(item.homeTeamName) +
                              " VS " +
                              _vm._s(item.awayTeamName)
                          ),
                        ]),
                        _c("span", [_vm._v("选项：" + _vm._s(item.betting))]),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Expert_Plan.标题"), prop: "title" } },
            [
              _vm.edit
                ? _c("el-input", {
                    attrs: { maxlength: "200" },
                    model: {
                      value: _vm.ruleForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "title", $$v)
                      },
                      expression: "ruleForm.title",
                    },
                  })
                : _c("span", [_vm._v(_vm._s(_vm.ruleForm.title))]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Expert_Plan.内容"), prop: "content" } },
            [
              _c("el-input", {
                attrs: {
                  autosize: { minRows: 4, maxRows: 20 },
                  rows: 4,
                  type: "textarea",
                },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _vm.edit
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("确定")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }