const CanChooseColumns = function () {
  return [
    {
      title: "推单订单号",
      key: "orderNo",
      align: "center",
    },
    {
      title: "会员账号",
      key: "memberName",
      align: "center",
    },
    {
      title: "昵称",
      key: "nickname",
      align: "center",
    },
    {
      title: "账号类型",
      key: "memberType",
      align: "center",
      // component: {
      //   render: (h, row) => {
      //     switch (row.memberType) {
      //       case 1:
      //         return <span>内部账号</span>;
      //       case 2:
      //         return <span>普通账号</span>;
      //       case 3:
      //         return <span>普通账号</span>;
      //       default:
      //         return <span>{row.memberType}</span>;
      //     }
      //   },
      // },
    },
    {
      title: "游戏类型",
      key: "computed_smallPlayType",
      align: "center",
      // component: {
      //   render: (h, row) => {
      //     switch (row.smallPlayType) {
      //       case 40:
      //         return <span>香港六合彩</span>;
      //       case 41:
      //         return <span>新澳门六合彩</span>;
      //       case 99:
      //         return <span>竞彩足球</span>;
      //       case 1502:
      //         return <span>滚球</span>;
      //       default:
      //         return <span>{row.smallPlayType}</span>;
      //     }
      //   },
      // },
    },
    {
      title: "方案宣言",
      key: "planSpeech",
      align: "center",
      width: 300,
      isRichText:true
    },
    {
      title: "自购金额",
      key: "selfAmount",
      align: "center",
    },
    {
      title: "自购中奖",
      key: "hitAmount",
      align: "center",
    },
    {
      title: "自购盈亏",
      key: "selfOrderProfit",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span class={this.$parseRedOrGreen(row.selfOrderProfit)}>
              {row.selfOrderProfit}
            </span>
          );
        },
      },
    },
    {
      title: "跟单单数/跟单金额",
      key: "followOrderNum",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span>
              {row.followOrderNum}/{row.followAmount}
            </span>
          );
        },
      },
    },
    {
      title: "跟单中奖",
      key: "followHitAmount",
      align: "center",
    },
    {
      title: "跟单盈亏",
      key: "followOrderProfit",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span class={this.$parseRedOrGreen(row.followOrderProfit)}>
              {row.followOrderProfit}
            </span>
          );
        },
      },
    },
    {
      title: "佣金比例/总佣金",
      key: "bonusRateGuru",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span>
              {row.bonusRateGuru*100}% /{row.guruCommission}
            </span>
          );
        },
      },
    },
    {
      title: "抽佣比例/平台抽佣",
      key: "bonusRatePlatform",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span>
              {this.dealNumber(row.bonusRatePlatform*100,0)}% /{row.platformCommission}
            </span>
          );
        },
      },
    },
    {
      title: "结算佣金",
      key: "settlementCommission",
      align: "center",
    },
    {
      title: "时间",
      key: "applyTime",
      align: "center",
      width: "300",
      component: {
        render: (h, row) => {
          return (
            <div>
              <div>发单时间：{this.$parseTime(row.createTime)}</div>
              <div>截止时间：{this.$parseTime(row.endTime)}</div>
              <div>结算时间：{this.$parseTime(row.settlementTime)}</div>
            </div>
          );
        },
      },
    },
    {
      title: "状态",
      key: "orderStatus",
      align: "center",
      width: 200,
    },
    {
      title: this.$t("操作"),
      key: "actions",
      align: "center",
      width: 100,
      fixed: "right",
      component: {
        render: (h, row) => {
          const { Check } = this.permission;
          return (
            <div>
              {Check && (
                <el-button
                  type="primary"
                  size="mini"
                  onClick={() => this.checkDetails(row)}
                >
                  查看详情
                </el-button>
              )}
            </div>
          );
        },
      },
    },
  ];
};

export default function (keys = []) {
  return CanChooseColumns.call(this);
}
