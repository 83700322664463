import { filterColumns, parseTime } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      width: 120
    },
    {
      title: this.$t('会员ID'),
      key: 'memberId',
      align: 'center'
    },
    {
      title: this.$t('移动前上级'),
      key: 'oldOneLevelAgencyName',
      align: 'center'
    },
    {
      title: this.$t('移动后上级'),
      key: 'oneLevelAgencyName',
      align: 'center'
    },
    {
      title: this.$t('移动时间'),
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span> { parseTime(row.createTime) } </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
