import request from '@/plugins/axios'

// 租户首页

export function getHomeStatistic() {
  return request({
    url: '/homeStatistic',
    method: 'get'
  })
}

export function getHomeLottery(timeType) {
  return request({
    url: '/homeLottery',
    method: 'post',
    data: { timeType }
  })
}

export function thirdAmountList() {
  return request({
    url: '/thirdAmount/thirdAmountList',
    method: 'get'
  })
}

// 系统首页游戏系列统计列表
export function tenantThirdReportList(data) {
  return request({
    url: '/tenantThirdReportList',
    method: 'post',
    data
  })
}

// // 平台首页

// export function getHomeStatisticWithPlatform(timeType) {
//   return request({
//     url: '/homePlatformData',
//     method: 'post',
//     data: { timeType }
//   })
// }

// export function getHomePlatformList(timeType) {
//   return request({
//     url: '/homePlatformList',
//     method: 'post',
//     data: { timeType }
//   })
// }
