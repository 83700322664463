import request from '@/plugins/axios'

// 彩票注单
export const getBetList = data => {
  return request({
    url: '/bets/list',
    method: 'post',
    data,
    timeout: 20000
  })
}

// 彩票详情
export const betsDetail = data => {
  return request({
    url: '/bets/detail',
    method: 'post',
    data,
    timeout: 20000
  })
}

export const getThirdOrderList = data => {
  return request({
    url: '/thirdgame/queryOrder',
    method: 'post',
    data
  })
}

export const getThirdOrderCount = data => {
  return request({
    url: '/thirdgame/queryOrderCount',
    method: 'post',
    data
  })
}

// 注单管理统计数据
export const getBetsTotal = data => {
  return request({
    url: '/bets/count',
    method: 'post',
    data,
    timeout: 20000
  })
}

export const getSeries = params => {
  return request({
    url: '/tenant/lottery/getSeries',
    method: 'get',
    params
  })
}

export const getLotterys = params => {
  return request({
    url: '/tenant/lottery/getLotterys',
    method: 'get',
    params
  })
}

export const getPlayList = params => {
  return request({
    url: '/tenant/lottery/play/class',
    method: 'get',
    params
  })
}

export const cancelBet = data => {
  return request({
    url: '/bets/cancel',
    method: 'post',
    data
  })
}

export const cancelIssueBets = data => {
  return request({
    url: '/bets/issue/cancel',
    method: 'post',
    data
  })
}

export const checkBets = data => {
  return request({
    url: '/bets/check',
    method: 'post',
    data
  })
}

export const settlementBets = data => {
  return request({
    url: '/bets/settlement',
    method: 'post',
    data
  })
}

export const settlementIssueBets = data => {
  return request({
    url: '/bets/issue/settlement',
    method: 'post',
    data
  })
}

// 足彩

export const getFootballOrderList = data => {
  return request({
    url: '/football/queryOrder',
    method: 'post',
    data
  })
}

export const getFootballOrderCount = data => {
  return request({
    url: '/football/queryOrderCount',
    method: 'post',
    data
  })
}

export const cancelFootballOrder = data => {
  return request({
    url: '/football/adminCancleOrder',
    method: 'post',
    data
  })
}

export const getPassTypeList = () => {
  return request({
    url: '/queryPassTypeList',
    method: 'get'
  })
}

export const getCompetition = () => {
  return request({
    url: '/sport/lottery/competition',
    method: 'get'
  })
}

export const queryCrashOrder = (data) => {
  return request({
    url: '/queryCrashOrder',
    method: 'post',
    data
  })
}

// 导出彩票注单
export const exportBetList = (params) => {
  return request({
    url: '/down/bets/list',
    method: 'get',
    params,
    responseType: 'blob',
    timeout: 600000
  })
}

// 导出彩票注单汇总
export const exportCountBetList = (data) => {
  return request({
    url: '/down/count/bets/list',
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 600000
  })
}

// 导出足彩注单
export const exportFootballOrderList = (data) => {
  return request({
    url: '/down/football/queryOrder',
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 600000
  })
}

// 导出第三方注单
export const exportThirdOrderList = (data) => {
  return request({
    url: '/down/thirdgame/queryOrder',
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 600000
  })
}

// 导出租户游戏注单用户统计
export const downThirdgameQueryOrderMemberCount = (data) => {
  return request({
    url: '/down/thirdgame/queryOrderMemberCount',
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 600000
  })
}


// 查询租户游戏注单详情
export const queryOrderDetail = (data) => {
  return request({
    url: '/thirdgame/queryOrderDetail',
    method: 'post',
    data,
  })
}


export const queryOrderCompetition = (data) => {
  return request({
    url: '/thirdgame/queryOrder/competition',
    method: 'post',
    data,
  })
}
export const queryOrderTeam = (data) => {
  return request({
    url: 'thirdgame/queryOrder/team',
    method: 'post',
    data,
  })
}


// (租户)管理后台查询比赛的基础赔率
export const sportNowBaseOdds = (params) => {
  return request({
    url: '/sport/now/baseOdds',
    method: 'get',
    params,
  })
}


// 足彩租户后台管理修改子订单玩法投注
export const footballUpdateOrder = (data) => {
  return request({
    url: '/football/updateOrder',
    method: 'post',
    data,
  })
}

// 足彩租户后台管理修改子订单结算
export const footballOrderSettlemet = (data) => {
  return request({
    url: '/football/order/settlemet',
    method: 'post',
    data
  })
}

// 推单彩票订单生成
export const guruOrderLotteryOrderCreate = (data) => {
  return request({
    url: '/guru/order/lottery/order/create',
    method: 'post',
    data
  })
}

