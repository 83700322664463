import request from '@/plugins/axios'

/* 晒单圈 */

/* 帖子 ************************************************************************/
// 查询帖子列表
export function getTopicList(data = {}) {
  return request({
    url: '/article/list',
    method: 'post',
    data
  })
}
export function articleLock(data = {}) {
  return request({
    url: '/article/lock',
    method: 'post',
    data
  })
}

// 删除帖子
export function deleteTopic(data = {}) {
  return request({
    url: '/article/delete',
    method: 'post',
    data
  })
}

// 禁言用户
export function forbiddenUser(data = {}) {
  return request({
    url: '/article/forbidden',
    method: 'post',
    data
  })
}

// 获取帖子回复列表
export function getTopicReplyList(data = {}) {
  return request({
    url: '/reply/list',
    method: 'post',
    data
  })
}

// 删除动态评论
export function deleteReply(data = {}) {
  return request({
    url: '/reply/delete',
    method: 'post',
    data
  })
}

// 对评论的用户当前帖子评论禁言
// export function forbiddenReplyUser(data = {}) {
//   return request({
//     url: '/article/reply/forbidden',
//     method: 'post',
//     data
//   })
// }

/* 禁言 ************************************************************************/
// 查询禁言列表
export function getSilentList(data = {}) {
  return request({
    url: '/silent/list',
    method: 'post',
    data
  })
}

// 解除禁言
export function relieveSilent(data = {}) {
  return request({
    url: '/silent/relieve',
    method: 'post',
    data
  })
}

/** over */
