var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      Array.isArray(_vm.permissionTabs) && _vm.permissionTabs.length
        ? [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              _vm._l(_vm.permissionTabs, function (tab) {
                return _c("el-tab-pane", {
                  key: tab.name,
                  attrs: { label: tab.label, name: tab.name },
                })
              }),
              1
            ),
            _c("keep-alive", [_c(_vm.activeName, { tag: "component" })], 1),
          ]
        : _vm._e(),
      _c("UserDialog", { ref: "UserDialogControl" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }