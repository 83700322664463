<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.groupName" clearable :placeholder="$t('权限组名')" class="filter-item" style="width: 160px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">
        {{ $t('添加权限组') }}
      </el-button>

      <!-- <el-button v-if="$P$" type="success" @click="onSyncPermission">
          同步超管权限
        </el-button> -->
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="false"
    />

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch()"
    />
    <CreateDialog ref="CreateDialogControl" @create="handleCreateGroupMenu" />

    <UpdateDialog ref="UpdateDialogControl" @update="handleUpdateGroupMenu" />

    <GoogleDialog ref="GoogleDialog" @validate="handleGoogleDialog"></GoogleDialog>

  </div>
</template>
<script>
import { getGroupList, updateGroupStatus, addGroupMenu, updateGroupMenu, deleteGroup } from '@/api/permission'
import { CreateDialog, UpdateDialog } from './components'
import TablePageMixin from '@/mixins/table-page-mixin'
import GoogleDialog from '@/views/components/GoogleDialog'
import GetColumns from './columns'

export default {
  name: 'PermissionAssign',
  components: { CreateDialog, UpdateDialog, GoogleDialog },
  mixins: [TablePageMixin(getGroupList)],
  data() {
    return {
      tenant: null,
      search: {},
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  methods: {
    onCreate() {
      this.$refs.CreateDialogControl.open()
    },

    // onSyncPermission() {
    //   syncPermissions().then(([_, err]) => {
    //     if (!err) {
    //       this.$message.success('同步权限成功')
    //     }
    //   })
    // },

    handleChangeStatus({ id, status }) {
      return new Promise((resolve) => {
        this.$confirm(status === 1 ? this.$t('确认启用此分组?') : this.$t('确认关闭此分组?'), this.$t('系统提示?'), { type: 'warning' }).then(() => {
          updateGroupStatus({
            id,
            status: ~status + 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },

    handleCreateGroupMenu({ field, cancel, close }) {
      addGroupMenu({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('创建成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleUpdateGroupMenu({ field, cancel, close }) {
      updateGroupMenu({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleDelete(id) {
      this.$refs.GoogleDialog.open({ id }, '确认删除此分组?')
    },
    handleGoogleDialog({ field, cancel, close }) {  
      deleteGroup({...field}).then(([_, err]) => {
        this.$refs.GoogleDialog.submitting = false
        if (!err) {
          this.$refs.GoogleDialog.handleColse()
          this.$message.success(this.$t('删除成功'))
          this.handleFetch()
        }
      })
    },

    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
