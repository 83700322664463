const CanChooseColumns = function() {
  return [
    {
      title: this.$t('家族名称'),
      key: 'familyName',
      align: 'center'
    },
    {
      title: this.$t('家族账号'),
      key: 'familyOwerLogin',
      align: 'center'
    },
    {
      title: this.$t('关联主播数'),
      key: 'memNums',
      align: 'center'
    },
    {
      title: this.$t('礼物分成比例'),
      key: 'giftPercent',
      align: 'center'
    },
    {
      title: this.$t('投注分成比例'),
      key: 'betsPercent',
      align: 'center'
    },
    {
      title: this.$t('房费分成比例'),
      key: 'payroomPercent',
      align: 'center'
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 350,
      component: {
        render: (h, row) => {
          const { UpdatePassword, ShareSet, Update, Delete } = this.permission
          return (
            <span>
              { UpdatePassword &&
                <el-button
                  type='danger'
                  size='mini'
                  onClick={() => this.$refs.ChangeDialogControl.open(row)}>{this.$t('修改密码')}</el-button>
              }
              {
                ShareSet &&
                <el-button
                  type='primary'
                  size='mini'
                  onClick={() => this.$refs.ShareDialogControl.open(row)}>{this.$t('分成配置')}</el-button>
              }
              {
                Update &&
                <el-button
                  type='success'
                  size='mini'
                  icon='el-icon-edit-outline'
                  onClick={() => this.$refs.UpdateDialogControl.open(row)}>{this.$t('编辑')}</el-button>
              }
              {
                Delete &&
                <el-button
                  type='danger'
                  size='mini'
                  icon='el-icon-delete'
                  disabled={row.memNums > 0} onClick={this._handleDelete.bind(this, row)}>{this.$t('删除')}</el-button>
              }
            </span>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
