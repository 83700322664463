<template>
  <div class="app-container">
    <el-form ref="validateForm" v-loading="loading" :model="ruleForm" :rules="rules" label-width="160px" label-suffix=":">
      <el-form-item label="帖子类型">
        <el-radio-group v-model="type" :disabled="!!($route.query && $route.query.id)">
          <el-radio :label="1">后台发帖</el-radio>
          <el-radio :label="0">代用户发帖</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="type == 0">
        <el-form-item label="用户账号" prop="memberName">
          <el-input v-model="ruleForm.memberName" placeholder="用户账号" style="width: 500px" />
        </el-form-item>
        <el-form-item :label="`${xsLottery[ruleForm.lotteryId]}心水`">
          <el-button
            type="danger"
            size="small"
            style="width: 500px; display: block; margin-top: 10px"
            :disabled="!ruleForm.memberName"
            @click="handleLastTermForUser"
          >加载上期</el-button>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="后台用户" prop="nickname">
          <el-input v-model="ruleForm.nickname" placeholder="后台用户昵称" style="width: 500px" />
        </el-form-item>
        <el-form-item :label="`${xsLottery[ruleForm.lotteryId]}心水`">
          <el-button
            type="danger"
            size="small"
            style="width: 500px; display: block; margin-top: 10px"
            :disabled="!ruleForm.nickname"
            @click="handleLastTermForBack"
          >加载上期</el-button>
        </el-form-item>
      </template>

      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title" placeholder="输入标题" maxlength="50" />
      </el-form-item>
      <el-form-item label="大神榜" prop="godTypeId">
        <el-select v-model="ruleForm.godTypeId" clearable placeholder="无" style="width: 100%">
          <el-option v-for="item in typeItems" :key="item.id" :value="item.id" :label="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item label="是否加精">
        <d-switch v-model="ruleForm.topViewStatus" :active-value="1" :inactive-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </d-switch>
      </el-form-item>
      <el-form-item label="正文内容" prop="content">
        <tinymce ref="tinymce" v-model="ruleForm.content" :maxlength="2000" />
      </el-form-item>
      <el-form-item label="隐藏内容">
        <tinymce ref="tinymce" v-model="ruleForm.hideContent" :height="200" />
      </el-form-item>
      <el-form-item label="回复可见隐藏">
        <d-switch v-model="ruleForm.replyViewStatus" :active-value="1" :inactive-value="0" />
      </el-form-item>
      <el-form-item label="登录可见隐藏">
        <d-switch v-model="ruleForm.loginViewStatus" :active-value="1" :inactive-value="0" />
      </el-form-item>
      <el-form-item label="充值可见隐藏">
        <d-switch v-model="ruleForm.payViewStatus" :active-value="1" :inactive-value="0" />
        <template v-if="ruleForm.payViewStatus === 1">
          <el-checkbox v-model="instant" style="margin: 0 10px">即时充值</el-checkbox>
          <el-input v-if="instant" v-model="ruleForm.rechargeViewMoney" placeholder="金额" style="width: 120px" />
        </template>
      </el-form-item>
      <el-form-item label="VIP可见隐藏" prop="vipViewLevel">
        <el-select v-model="ruleForm.vipViewLevel">
          <el-option
            v-for="item in vipLevels"
            :key="item.vipLevel"
            :value="item.vipLevel"
            :label="`VIP${item.vipLevel}`"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="同步租户" prop="tenantCodeList" v-if="type === 1 && !ruleForm.id && tenants.length > 0">
        <el-checkbox-group v-model="ruleForm.tenantCodeList">
          <el-checkbox v-for="tenant in tenants" :key="tenant.tenantCode" :label="tenant.tenantCode">{{ tenant.tenantName }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="danger" size="large" style="width: 140px" @click="goback">取消</el-button>
        <el-button type="success" size="large" style="width: 140px" @click="handleSubmit">确认</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>
<script>
import Tinymce from '@/components/Tinymce'
import { getAllVipLevels } from '@/api/app'
import { groupTenantList } from '@/api/tenant'
import { xsLottery } from '@/libs/options'
import {
  getLastTermForApp,
  getLastTermForBack,
  saveRecommendForUser,
  saveRecommendForBack,
  getRecommendPostDetails,
  updateRecommendPost,
  getGodType
} from '@/api/interactive'
export default {
  name: 'Release',
  components: { Tinymce },
  data() {
    return {
      tenantCode: null,
      tenants: [],
      vipLevels: [],
      typeItems: [],
      instant: false,
      type: 1,
      xsLottery,
      loading: false,
      ruleForm: {
        memberName: '',
        nickname: '',
        title: '',
        godTypeId: null,
        topViewStatus: 0,
        replyViewStatus: 0,
        loginViewStatus: 0,
        payViewStatus: 0,
        vipViewLevel: 0,
        rechargeViewMoney: 0,
        tenantCodeList: []
      },
      rules: {
        nickname: [
          { required: true, message: '请输入后台用户昵称', trigger: 'blur' }
        ],
        memberName: [
          { required: true, message: '请输入用户账号', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入正文', trigger: 'blur' }
        ],
        rechargeViewMoney: [
          { message: '请输入即时金额', trigger: 'blur' },
          { pattern: /^[1-9]\d*$/, message: '请输入正确的金额', trigger: 'blur' }
        ],
        vipViewLevel: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    const { code, type, id, lotteryId } = this.$route.query
    this.ruleForm.lotteryId = lotteryId
    if (code) this.tenantCode = code
    if (type == 0) {
      this.type = 0
    }
    if (type == 1) {
      this.type = 1
    }
    if (id) {
      this.getDetails(id, code)
    }
    this.init()
  },
  methods: {
    init() {
      groupTenantList().then(([items, err]) => {
        if (!err) {
          this.tenants = items.filter(e => e.tenantCode !== this.$store.getters.tenantCode)
        }
      })
      getAllVipLevels(this.tenantCode && [this.tenantCode] || {}).then(([data, err]) => {
        if (!err) {
          this.vipLevels = data
        }
      })
      getGodType(this.tenantCode && { tenantCode: this.tenantCode } || {}).then(([data, err]) => {
        if (!err) {
          this.typeItems = data
        }
      })
    },
    goback() {
      this.$router.go(-1)
    },
    getDetails(id, tenantCode) {
      this.loading = true
      getRecommendPostDetails({ id, tenantCode }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          this.ruleForm = { ...this.ruleForm, ...data }
          if (data.payViewStatus === 1 && data.rechargeViewMoney) this.instant = true
        }
      })
    },
    handleLastTermForUser() {
      getLastTermForApp({
        memberName: this.ruleForm.memberName,
        lotteryId: this.ruleForm.lotteryId,
        // tenantCode: this.$router.query.code
      }).then(([data, err]) => {
        if (!err) {
          this.ruleForm = { ...this.ruleForm, ...data }
        }
      })
    },
    handleLastTermForBack() {
      getLastTermForBack({
        nickname: this.ruleForm.nickname,
        lotteryId: this.ruleForm.lotteryId,
        // tenantCode: this.$route.query.code
      }).then(([data, err]) => {
        if (!err) {
          this.ruleForm = { ...this.ruleForm, ...data }
        }
      })
    },
    handleSubmit() {
      this.$refs.validateForm.validate(valid => {
        if (valid) {
          let handle = null
          if (this.$route.query.id) {
            handle = updateRecommendPost
          } else {
            handle = this.type == 0 ? saveRecommendForUser : saveRecommendForBack
          }
          handle(this.ruleForm).then(([data, err]) => {
            if (!err) {
              this.$message.success('操作成功')
              this.goback()
            }
          })
        }
      })
    }
  }
}
</script>
