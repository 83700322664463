<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="search.type" clearable :placeholder="$t('请选择公告类型')" class="filter-item">
        <el-option v-for="(item, key) in typeOptions" :key="key" :label="item" :value="key" />
      </el-select>
      <el-input v-model="search.title" clearable :placeholder="$t('标题')" maxlength="16" class="filter-item" style="width: 200px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" icon="el-icon-plus" type="success" class="filter-item" @click="onAction()">{{ $t('添加公告') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <div class="filter-container-item">
          <el-button type="success" plain size="small" @click="_batchUpdate()">{{ $t('全部保存') }}</el-button>
        </div>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" @update="_handleUpdate" />
  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getAnnouncementList, batchUpdateAnnouncement, addAnnouncement, updateAnnouncement, deleteAnnouncement } from '@/api/announcement'
import ActionDialog from './ActionDialog.vue'
import { $t } from '@/lang'

const typeOptions = {
  1: $t('首页推荐'),
  2: $t('游戏中心'),
  3: $t('充值'),
  4: $t('提现'),
  5: $t('直播间'),
  6: $t('主页记录'),
  9: $t('ABPay大厅跑马灯'),
  10: $t('首页推荐页-六合版'),
  11: $t('心水详情热门评论'),
  12: $t('图库详情热门评论')
}

function _getparams(field) {
  const { dateTime, lang, ...other } = field
  let params = { ...other }
  params.startTime = new Date(dateTime[0]) // .getTime()
  params.endTime = new Date(dateTime[1]) // .getTime()
  params.lang = lang.toString()

  params.imgList.forEach(e => {
    delete e.appPageId
  })
  return params
}

export default {
  name: 'AnnouncementHome',
  components: { ActionDialog },
  mixins: [TablePageMixin(getAnnouncementList)],
  data() {
    return {
      typeOptions,
      defaultColumns: GetColumns.call(this),
      columns: [],
      search: {}
    }
  },
  methods: {
    onAction(row) {
      this.$refs.ActionDialogControl.open(row)
    },

    _handleCreate({ field, cancel, close }) {
      addAnnouncement(_getparams(field)).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('添加成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      updateAnnouncement(_getparams(field)).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    _handleDelete({ id }) {
      this.$confirm('确认删除此条公告', { type: 'warning' }).then(() => {
        deleteAnnouncement({ announcementId: id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },

    _batchUpdate() {
      if (this.items.length === 0) {
        this.$message.warning(this.$t('暂无数据无法保存！'))
        return
      }
      this.$confirm(this.$t('确认批量修改公告'), { type: 'warning' }).then(() => {
        let dataList = []
        this.items.forEach((item) => {
          const { id, enable, scroll, popup } = item
          dataList.push({ announcementId: id, enable, scroll, popup })
        })
        batchUpdateAnnouncement({ dataList }).then(([data, err]) => {
          if (!err) this.handleFetch()
        })
      })
    },

    handleUpdateEnable({ id, enable, scroll, popup }) {
      return new Promise((resolve) => {
        this.$confirm(`确认${enable === 0 ? '启用' : '停用'}公告?`, { type: 'warning' }).then(() => {
          enable = enable === 0 ? 1 : 0
          let dataList = [{ announcementId: id, enable, scroll, popup }]
          batchUpdateAnnouncement({ dataList }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
            }
          })
        })
      })
    },

    handleUpdatePopup({ id, enable, scroll, popup }) {
      return new Promise((resolve) => {
        this.$confirm(`确认${popup === 0 ? '启用' : '停用'}是否弹出?`, { type: 'warning' }).then(() => {
          popup = popup === 0 ? 1 : 0
          let dataList = [{ announcementId: id, enable, scroll, popup }]
          batchUpdateAnnouncement({ dataList }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
            }
          })
        })
      })
    },

    implementationGetParams() {
      return { ...this.search }
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
