import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('借款订单编号'),
      key: 'orderNo',
      align: 'center'
    },
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('当前借款次数'),
      key: 'borrowTimes',
      align: 'center',
      width: 100
    },
    {
      title: this.$t('总借呗额度'),
      key: 'totalAmount',
      align: 'center'
    },
    {
      title: this.$t('已使用额度'),
      key: 'usedAmount',
      align: 'center'
    },
    {
      title: this.$t('当前额度'),
      key: 'nowAmount',
      align: 'center'
    },
    {
      title: this.$t('借款金额'),
      key: 'borrowAmount',
      align: 'center'
    },
    {
      title: this.$t('剩余额度'),
      key: 'remainAmount',
      align: 'center'
    },
    {
      title: this.$t('打码倍数'),
      key: 'rollingTimes',
      align: 'center'
    },
    {
      title: this.$t('状态'),
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.borrowStatus[row.status] }</span>)
        }
      }
    },
    {
      title: this.$t('申请时间'),
      key: 'applyTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.applyTime) }</span>)
        }
      }
    },
    {
      title: this.$t('审核时间'),
      key: 'approvalTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.approvalTime) }</span>)
        }
      }
    },
    {
      title: this.$t('审核人'),
      key: 'approvalUser',
      align: 'center'
    },
    {
      title: this.$t('查看'),
      key: 'seeDetail',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>
            <el-link type="primary" onClick={ () => this.$router.push('/Cash_Member_Change?memberName=' + row.memberName) }>账变</el-link>
            <el-link type="primary" onClick={ () => this.$router.push('/Orders_Lottery?memberName=' + row.memberName) }>注单</el-link>
          </span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 180,
      component: {
        render: (h, row) => {
          return (
            <span>
              { this.permission.Audit && row.status === 0 && <el-button type='primary' size='mini' onClick={ () => this.handleBorrowLoanApproval(row) }>{this.$t('通过')}</el-button> }
              { this.permission.Audit && row.status === 0 && <el-button type='danger' size='mini' onClick={ () => this.$refs.StatusDialogControl.open(row) }>{this.$t('不通过')}</el-button> }
            </span>
          )
        }
      }
    },
    {
      title: this.$t('备注'),
      key: 'remark',
      align: 'center'
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
