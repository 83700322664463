const CanChooseColumns = function() {
  return [
    {
      title: this.$t('排序'),
      key: 'sort',
      align: 'center'
    },
    {
      title: this.$t('图库类别'),
      key: 'categoryName',
      align: 'center',
      component: {
        render: (h, row) => {
          return <el-link type='primary' onClick={ () => this.goPic(row.categoryName, row.lotteryId, row.source)}>{ row.categoryName }</el-link>
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission
          return (
            <div>
              { Update && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.ActionDialogControl.open(row) } }>{ this.$t('修改') }</el-button> }
              { Delete && <el-button type='danger' size='small' icon='el-icon-delete' onClick={ () => this.handleDeletePictureCategory(row)}>{ this.$t('删除') }</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
