<template>
  <el-dialog
    title="社区配置"
    :visible.sync="visible"
    width="550px"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      label-width="100px"
      label-suffix=":"
      v-loading="loading"
    >
      <el-form-item :label="$t('社区背景')" prop="bgImage">
        <d-upload
          v-model="ruleForm.bgImage"
          folder="icon"
          :on-success="handleSuccess"
          text="上传"
          tips
        />
      </el-form-item>
      <el-form-item :label="$t('社区说明')" prop="communityDesc">
        <el-radio-group v-model="lang" style="margin-right: 6px;">
          <el-radio-button v-for="(item, index) in allLang" :key="index" :label="item.lang">{{ $t(item.name) }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-input type="textarea" maxlength="100" show-word-limit placeholder="请输入社区说明，最长100个字符" rows="8" v-model="ruleForm.communityDesc[lang]" />
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { communityGetConfigDetail } from '@/api/interactive'
import { mapState } from 'vuex'
import DUpload from '@/components/DUpload'
const getDefaultForm = () => ({
  communityDesc: {},
  bgImage: ''
})

export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'set',
      loading: false,
      lang: 'zh_CN',
      rules: {
        bgImage: [{ required: true, message: this.$t('请上传社区背景'), trigger: 'change' }],
        communityDesc: [{ required: true, message: this.$t('社区说明至少需要录入其中一个语言'), trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState('app', ['allLang'])
  },
  methods: {
    open() {
      this.allLang.forEach(e => {
        this.ruleForm.communityDesc[e.lang] = ''
      })
      this.handleCommunityGetConfigDetail()
      this.visible = true
    },
    handleCommunityGetConfigDetail() {
      this.loading = true
      communityGetConfigDetail().then(([data, err]) => {
        if (!err) {
          this.ruleForm.bgImage = data.bgImage
          this.ruleForm.communityDesc = { ...this.ruleForm.communityDesc, ...JSON.parse(data.communityDesc) }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleSuccess(data) {
      this.ruleForm.bgImage = data[0]
    },
    handleSubmitBefor() {
      let msg = '社区说明至少需要录入其中一个语言'
      this.allLang.forEach(e => {
        if (this.ruleForm.communityDesc[e.lang]) {
          msg = ''
          return
        }
      })
      if (msg !== '') {
        this.$message.error(msg)
        return
      }
      this.handleSubmit()
    }
  }
}
</script>
