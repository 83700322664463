<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.accLogin" :placeholder="$t('主播账号')" clearable class="filter-item" style="width: 160px;" />
      <el-input v-model="search.nickName" :placeholder="$t('主播昵称')" clearable class="filter-item" style="width: 160px;" />
      <el-input v-model="search.familyName" :placeholder="$t('家族名称')" clearable class="filter-item" style="width: 160px;" />
      <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleRefresh">{{ $t('查 询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template v-if="summary" slot="left">
        {{ $t('投注合计') }}：{{ summary.sumBetsSumamt }}，
        {{ $t('礼物合计') }}：{{ summary.sumGiftSumamt }}，
        {{ $t('房费合计') }}：{{ summary.sumPaySumamt }}，
        {{ $t('时长合计') }}：{{ summary.sumOnlineTime }}
      </template>

    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <RoomStreamDialog ref="RoomStreamDialogControl" />
    <GiftStreamDialog ref="GiftStreamDialogControl" />
    <BetStreamDialog ref="BetStreamDialogControl" />
  </div>
</template>

<script>
import { getLiveBroadcastList, getLiveBroadcastSummary } from '@/api/live'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { RoomStreamDialog, GiftStreamDialog, BetStreamDialog } from './components'
export default {
  name: 'LiveBroadcast',
  components: { RoomStreamDialog, GiftStreamDialog, BetStreamDialog },
  mixins: [TablePageMixin(getLiveBroadcastList)],
  data() {
    return {
      search: {},
      columns: [],
      summary: null,
      defaultColumns: GetColumns.call(this)
    }
  },
  methods: {
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    implementationFetched() {
      getLiveBroadcastSummary({
        ...this.implementationGetParams()
      }).then(([data, err]) => {
        if (!err) {
          this.summary = data
        }
      })
    }
  }
}
</script>
