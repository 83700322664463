<template>
  <el-dialog :title="ruleForm.type === 2 ? '上传': '替换'" :visible.sync="visible" width="40%" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":" size="small">
      <el-form-item label="名称">{{ pictureName }}</el-form-item>
      <el-form-item label="期号">{{ ruleForm.issue }}期</el-form-item>
      <el-form-item label="上传图片" prop="url">
        <el-upload
          class="upload-drag"
          drag
          :accept="accept"
          :data="uploadFileParams"
          :headers="$headers()"
          :action="action"
          :on-success="onSuccess"
          :before-upload="onBeforeUpload"
          :before-remove="onBeforeRemove"
          :file-list="fileList"
          list-type="picture"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('图库类别')" prop="pictureCategoryRelRequestList">
        <el-select 
          v-model="pictureCategoryRelRequestList" 
          multiple
          collapse-tags 
          filterable 
          placeholder="请输入图库类别查询，最多20个字"
          maxlength="20"
          style="width: 100%;"
        >
          <el-option v-for="item in $parent.picList" :key="item.id" :label="item.categoryName" :value="item.id" />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  url: '',
  type: 1,
  pictureCategoryRelRequestList: []
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  props: {
    accept: {
      type: String,
      default: 'image/jpeg,image/png,image/jpg,image/webp'
    },
    maxSize: {
      default: 0.5,
      type: Number
    }
  },
  data() {
    return {
      emitName: 'update',
      pictureName: '',
      fileList: [],
      pictureCategoryRelRequestList: [],
      rules: {
        url: [{ required: true, message: '请上传图片' }]
      }
    }
  },

  computed: {
    uploadFileParams() {
      return { type: 'image', folder: 'lh-image' }
    },

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    }
  },
  methods: {
    open(row, type = 2) {
      this.pictureName = row.pictureName
      this.ruleForm.url = row.url || ''
      this.ruleForm.tag = row.pictureTag
      this.ruleForm.issue = row.issue
      this.ruleForm.type = type
      this.pictureCategoryRelRequestList = []
      if(row.pictureCategoryRelRequestList && row.pictureCategoryRelRequestList.length > 0) {
        this.pictureCategoryRelRequestList = row.pictureCategoryRelRequestList.map(e => e.pictureCategoryId)
      }

      this.visible = true
    },

    onSuccess(res, file) {
      this.ruleForm.url = res.data[0]
      this.fileList = [{ name: file.name, url: file.url }]
      this.$refs.validateForm.validateField('url')
    },
    onBeforeUpload(file) {
      const isAccept = this.accept.split(',').some(o => file.type === o)
      const isMax = file.size / 1024 / 1024 < this.maxSize
      if (!isAccept) {
        this.$message.error('上传图片只能是 JPG,PNG 格式!')
      }
      if (!isMax) {
        this.$message.error('上传图片大小不能超过 500KB!')
      }

      return isAccept && isMax
    },

    onBeforeRemove(file, fileList) {
      this.ruleForm.url = ''
      this.fileList = []
      this.$refs.validateForm.validateField('url')
      return true
    },

    implementationClosed() {
      this.fileList = []
    },
    handleSubmitBefor() {
      this.ruleForm.pictureCategoryRelRequestList = []
      this.pictureCategoryRelRequestList.forEach(e => {
        const category = this.$parent.picList.find(v => v.id === e)
        if (category) {
          this.ruleForm.pictureCategoryRelRequestList.push({ pictureName: category.categoryName, pictureCategoryId: category.id })
        }
      })
      this.handleSubmit()
    }
  }
}
</script>

<style lang="scss">
.upload-drag {
  .el-upload {
    display: block;
  }
  .el-upload-dragger {
    width: 100%;
  }
}
</style>
