var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("首充详情"),
        visible: _vm.visible,
        width: "50%",
        "close-on-click-modal": false,
        top: "8vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.handleRefresh,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-table",
            _vm._b(
              {
                staticStyle: { "max-height": "60vh", "overflow-y": "auto" },
                attrs: { data: _vm.items },
              },
              "el-table",
              _vm.options,
              false
            ),
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("会员账号"),
                  prop: "memberName",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("昵称"),
                  prop: "nickname",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("首次充值金额"),
                  prop: "firstRechargeAmount",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("首次充值时间"),
                  prop: "firstRechargeDate",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(_vm._f("parseTime")(row.firstRechargeDate))
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }