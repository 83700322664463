<template>
  <div>
    <el-input type="number" v-model="formattedValue" /> %
  </div>
</template>

<script>
export default {
  name: "PercentInput",
  props: {
    value: 0,
  },
  computed: {
    formattedValue: {
      get() {
        return Number((this.value * 100).toFixed(2)) || 0;
      },
      set(newValue) {
        this.$emit("input", newValue / 100);
      },
    },
  },
  methods: {},
};
</script>

<style scoped>
input {
  width: 100px;
  text-align: right;
}
</style>
