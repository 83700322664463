var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("对应推流域名") + "  " + _vm.serverurl,
        visible: _vm.visible,
        "close-on-click-modal": false,
        width: "75%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.handleRefresh,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-table",
        _vm._b(
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { data: _vm.items },
          },
          "el-table",
          _vm.options,
          false
        ),
        [
          _c("el-table-column", {
            attrs: {
              prop: "servername",
              label: _vm.$t("简称"),
              align: "center",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "serverurl",
              label: _vm.$t("播放域名"),
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "primarykey",
              label: _vm.$t("播放鉴权KEY(主)"),
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "backupkey",
              label: _vm.$t("播放鉴权KEY(备)"),
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "protocol",
              label: _vm.$t("播放协议"),
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.protocol === 1
                      ? [_vm._v("RTMP")]
                      : row.protocol === 2
                      ? [_vm._v("FLV")]
                      : row.protocol === 3
                      ? [_vm._v("HLS")]
                      : row.protocol === 4
                      ? [_vm._v("UDP")]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$t("创建时间"),
              align: "center",
              width: "130",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm._f("parseTime")(row.createTime)))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("操作信息"), align: "center", width: "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("操作人")) +
                          ":" +
                          _vm._s(row.operatorName)
                      ),
                    ]),
                    _c("div", [
                      _vm._v(_vm._s(_vm._f("parseTime")(row.updateTime))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("启用"), width: "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "d-switch",
                      {
                        attrs: {
                          "active-value": true,
                          "inactive-value": false,
                          size: "default",
                          disabled: !_vm.$parent.permission.StartStop,
                          "before-change": function () {
                            return _vm._onStatus(row)
                          },
                        },
                        model: {
                          value: row.status,
                          callback: function ($$v) {
                            _vm.$set(row, "status", $$v)
                          },
                          expression: "row.status",
                        },
                      },
                      [
                        _c("span", { attrs: { slot: "open" }, slot: "open" }),
                        _c("span", { attrs: { slot: "close" }, slot: "close" }),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("操作"), width: "200", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-edit",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm._onUpdate(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("编辑")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          size: "mini",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm._onDel(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("删除")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }