<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive>
        <component :is="activeName" />
      </keep-alive>
    </template>
  </div>
</template>
<script>
import { parentMixin } from '@/mixins/tab-items'
import Center from './card-center'
import Mgr from './card-mgr'
import Stats from './card-stats'

export default {
  name: 'CardTask',
  components: {
    Mgr,
    Stats,
    Center
  },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      tabs: [
        { label: '卡券管理', name: 'Mgr' },
        { label: '会员卡券中心', name: 'Center' },
        { label: '卡券统计', name: 'Stats' }
      ]
    }
  }
}
</script>
