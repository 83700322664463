import request from '@/plugins/axios'

/* 代理推广 */

// 查询
export function getList(data) {
  return request({
    url: '/agency/promotion/list',
    method: 'get',
    data
  })
}

// 修改
export function updateInfo(data) {
  return request({
    url: '/agency/promotion/update',
    method: 'post',
    data
  })
}

// 新增
export function createInfo(data) {
  return request({
    url: '/agency/promotion/create',
    method: 'post',
    data
  })
}

// 删除
export function deleteAInfo(data) {
  return request({
    url: '/agency/promotion/delete',
    method: 'post',
    data
  })
}

