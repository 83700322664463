var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.diaTitle,
        visible: _vm.visible,
        width: "800px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "助手名称", required: "", prop: "nameTwo" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "输入助手名称，最多不超过10个字符",
                  maxlength: 10,
                },
                model: {
                  value: _vm.ruleForm.nameTwo,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "nameTwo", $$v)
                  },
                  expression: "ruleForm.nameTwo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户昵称", required: "", prop: "nicknameTwo" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "输入用户昵称，最多不超过10个字符",
                  maxlength: 10,
                },
                model: {
                  value: _vm.ruleForm.nicknameTwo,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "nicknameTwo", $$v)
                  },
                  expression: "ruleForm.nicknameTwo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户头像", prop: "avatarTwo" } },
            [
              _c("d-upload", {
                attrs: { folder: "head", "on-success": _vm.handleSuccess },
                model: {
                  value: _vm.ruleForm.avatarTwo,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "avatarTwo", $$v)
                  },
                  expression: "ruleForm.avatarTwo",
                },
              }),
              _vm._v(" 只支持.jpg、.png格式，图片大小不超过2MB "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("会员等级"), prop: "vipLevel" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: { placeholder: "请选择会员等级" },
                  model: {
                    value: _vm.ruleForm.vipLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "vipLevel", $$v)
                    },
                    expression: "ruleForm.vipLevel",
                  },
                },
                _vm._l(_vm.vipList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: "VIP" + item.vipLevel,
                      value: item.vipLevel,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("活跃时段"), prop: "activePeriodTwo" } },
            [
              _c("el-time-picker", {
                attrs: {
                  "value-format": "HH:mm",
                  "is-range": "",
                  "range-separator": "至",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  placeholder: "选择时间范围",
                },
                model: {
                  value: _vm.ruleForm.activePeriodTwo,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "activePeriodTwo", $$v)
                  },
                  expression: "ruleForm.activePeriodTwo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("形象描述"), prop: "imageDescriptionTwo" },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 5,
                  placeholder: _vm.$t(
                    "请输入形象描述，英文逗号隔开，最多输入2000字符"
                  ),
                  maxlength: "2000",
                },
                model: {
                  value: _vm.ruleForm.imageDescriptionTwo,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "imageDescriptionTwo", $$v)
                  },
                  expression: "ruleForm.imageDescriptionTwo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " 状态", prop: "statusTwo" } },
            [
              _c("d-switch", {
                attrs: { "inactive-value": 0, "active-value": 1 },
                model: {
                  value: _vm.ruleForm.statusTwo,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "statusTwo", $$v)
                  },
                  expression: "ruleForm.statusTwo",
                },
              }),
              _c("div", [_vm._v(" 默认启用状态，关闭后前端不展示 ")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 取消 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }