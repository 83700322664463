var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        width: "800px",
        visible: _vm.visible,
        top: "2vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
        open: _vm.onOpen,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("标题"), prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("请输入标题") },
                model: {
                  value: _vm.ruleForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("公告类型"), prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: _vm.$t("请选择公告类型") },
                  on: { change: _vm.handleChangeType },
                  model: {
                    value: _vm.ruleForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "type", $$v)
                    },
                    expression: "ruleForm.type",
                  },
                },
                _vm._l(_vm.$parent.typeOptions, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: +key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("公告时间"), prop: "dateTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"],
                  type: "datetimerange",
                  "range-separator": _vm.$t("至"),
                  "start-placeholder": _vm.$t("开始时间"),
                  "end-placeholder": _vm.$t("结束时间"),
                },
                model: {
                  value: _vm.ruleForm.dateTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "dateTime", $$v)
                  },
                  expression: "ruleForm.dateTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("是否启用"), prop: "enable" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !_vm.$parent.permission.StartStop },
                  model: {
                    value: _vm.ruleForm.enable,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "enable", $$v)
                    },
                    expression: "ruleForm.enable",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("启用"))),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("关闭"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("是否弹出"), prop: "popup" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: {
                    disabled:
                      !_vm.$parent.permission.Eject ||
                      [11, 12].includes(_vm.ruleForm.type),
                  },
                  model: {
                    value: _vm.ruleForm.popup,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "popup", $$v)
                    },
                    expression: "ruleForm.popup",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("启用"))),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("关闭"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("排序"), prop: "sort" } },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: _vm.$t("请输入排序") },
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sort", _vm._n($$v))
                  },
                  expression: "ruleForm.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("语言"), prop: "lang" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.lang,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "lang", $$v)
                    },
                    expression: "ruleForm.lang",
                  },
                },
                _vm._l(_vm.allLang, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.lang, attrs: { label: item.lang } },
                    [_vm._v(_vm._s(_vm.$t(item.name)))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公告详情", prop: "detailType" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  on: { change: _vm.handleChangeContent },
                  model: {
                    value: _vm.ruleForm.detailType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "detailType", $$v)
                    },
                    expression: "ruleForm.detailType",
                  },
                },
                [_vm._v("富文本")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  on: { change: _vm.handleChangeContent },
                  model: {
                    value: _vm.ruleForm.detailType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "detailType", $$v)
                    },
                    expression: "ruleForm.detailType",
                  },
                },
                [_vm._v("图片")]
              ),
            ],
            1
          ),
          _vm.ruleForm.detailType === 2
            ? _c(
                "el-form-item",
                { attrs: { label: "展示方式", prop: "imgShowType" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.ruleForm.imgShowType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "imgShowType", $$v)
                        },
                        expression: "ruleForm.imgShowType",
                      },
                    },
                    [_vm._v("合并显示")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.ruleForm.imgShowType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "imgShowType", $$v)
                        },
                        expression: "ruleForm.imgShowType",
                      },
                    },
                    [_vm._v("独立展开")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.ruleForm.detailType === 1
            ? [
                _vm.ruleForm.type !== 5
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "",
                          "label-width": "0",
                          prop: "content",
                          id: "content",
                        },
                      },
                      [
                        _c("tinymce", {
                          ref: "tinymce",
                          attrs: { height: 200 },
                          model: {
                            value: _vm.ruleForm.content,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "content", $$v)
                            },
                            expression: "ruleForm.content",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("公告内容"),
                          prop: "content",
                          id: "content",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 5,
                            placeholder: _vm.$t("请输入内容"),
                          },
                          model: {
                            value: _vm.ruleForm.content,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "content", $$v)
                            },
                            expression: "ruleForm.content",
                          },
                        }),
                      ],
                      1
                    ),
              ]
            : _vm.ruleForm.detailType === 2
            ? [
                _c(
                  "el-form-item",
                  { attrs: { id: "content" } },
                  _vm._l(_vm.ruleForm.imgList, function (item, index) {
                    return _c("div", { key: index, staticClass: "picList" }, [
                      _c(
                        "div",
                        { staticClass: "left" },
                        [
                          _c("el-form-item", [
                            _c(
                              "div",
                              { staticClass: "upload" },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    attrs: {
                                      accept:
                                        "image/png,image/jpg,image/jpeg,image/webp,image/*",
                                      action: _vm.action,
                                      data: { type: "image", folder: "icon" },
                                      headers: _vm.$headers(),
                                      "show-file-list": false,
                                      "before-upload": function () {
                                        _vm.imgIndex = index
                                      },
                                      "on-success": _vm._handleSuccess,
                                    },
                                  },
                                  [
                                    item.imgUrl
                                      ? _c("img", {
                                          staticClass: "image",
                                          attrs: { src: item.imgUrl },
                                        })
                                      : _c("i", {
                                          staticClass: "el-icon-upload",
                                        }),
                                  ]
                                ),
                                _c("i", { staticClass: "el-icon-warning" }),
                                _vm._v(
                                  " 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。 "
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "跳转方式", "label-width": "90" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      item.jumpUrl = ""
                                    },
                                  },
                                  model: {
                                    value: item.jumpType,
                                    callback: function ($$v) {
                                      _vm.$set(item, "jumpType", $$v)
                                    },
                                    expression: "item.jumpType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v(_vm._s(_vm.$t("链接"))),
                                  ]),
                                  _c("el-radio", { attrs: { label: 5 } }, [
                                    _vm._v(_vm._s(_vm.$t("APP功能页"))),
                                  ]),
                                  _c("el-radio", { attrs: { label: 4 } }, [
                                    _vm._v(_vm._s(_vm.$t("签到"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          item.jumpType === 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "跳转链接",
                                    "label-width": "90",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "80%" },
                                    model: {
                                      value: item.jumpUrl,
                                      callback: function ($$v) {
                                        _vm.$set(item, "jumpUrl", $$v)
                                      },
                                      expression: "item.jumpUrl",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.jumpType === 5
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "APP功能页",
                                    "label-width": "90",
                                  },
                                },
                                [
                                  _c("el-cascader", {
                                    staticStyle: { width: "80%" },
                                    attrs: { options: _vm.appPageList },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleChangeAppPage(
                                          $event,
                                          item
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.appPageId,
                                      callback: function ($$v) {
                                        _vm.$set(item, "appPageId", $$v)
                                      },
                                      expression: "item.appPageId",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "right" }, [
                        _c(
                          "a",
                          {
                            staticClass: "jia",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleAddPic(1, index)
                              },
                            },
                          },
                          [_vm._v("+")]
                        ),
                        index !== 0
                          ? _c(
                              "a",
                              {
                                staticClass: "jian",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleAddPic(-1, index)
                                  },
                                },
                              },
                              [_vm._v("-")]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("取消"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v(_vm._s(_vm.$t("确 定")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }