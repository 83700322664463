import { filterColumns } from '@/utils'

const CanChooseColumns = function() {
  return [
    // {
    //   title: this.$t('租户'),
    //   key: 'tenantCode',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (<span>{ row.level + this.$t('级代理') }</span>)
    //     }
    //   }
    // },
    {
      title: this.$t('代理账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('代理下会员人数'),
      key: 'agencyMembers',
      align: 'center'
    },
    {
      title: this.$t('代理总返佣金额'),
      key: 'rebateAmount',
      align: 'center'
    },
    {
      title: this.$t('排序'),
      key: 'sort',
      align: 'center'
    },
    {
      title: this.$t('操作者账号'),
      key: 'operatorName',
      align: 'center'
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission
          return (
            <span>
              { Update && <el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={ () => this.onCreate(row) }>{this.$t('编辑')}</el-button> }
              { Delete && <el-button type='danger' size='mini' icon='el-icon-delete' onClick={ this.handleDelete.bind(this, row.id) }>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
