<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        type="daterange"
        class="filter-item"
        style="width: 260px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        clearable
        :picker-options="pickerOptions"
      />
      <el-input v-model.trim="search.memberName" maxlength="20" clearable :placeholder="$t('请输入代理账号')" class="filter-item" style="width: 140px" />
      <!-- <el-input v-model.trim="search.nickName" maxlength="20" clearable :placeholder="$t('请输入代理昵称')" class="filter-item" style="width: 140px" /> -->
      <el-select v-model="search.type" :placeholder="$t('请选择代理类型')" class="filter-item" @change="handleSearch">
        <el-option v-for="(item, key) in defaultAgentType" :key="key" :label="item + $t('下级')" :value="+key" />
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      show-summary
      :summary-method="getSummaries"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <user-dialog ref="UserDialogControl" />
  </div>
</template>

<script>
import tableStaticConfigMx from '@/views/mixins/table-static-config.js'
import { getAgencyRebateReportDetailList } from '@/api/agent-rebate.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getDefaultTimes, parseTime } from '@/utils'
import UserDialog from '@/views/components/UserDialog'
export default {
  name: 'AgentRebateReportDetail',
  components: { UserDialog },
  mixins: [TablePageMixin(getAgencyRebateReportDetailList, false), tableStaticConfigMx],
  data() {
    return {
      times: getDefaultTimes(),
      defaultAgentType: { 1: this.$t('直属'), 2: this.$t('二级') },
      search: { type: 1 },
      defaultColumns: GetColumns.call(this), // 默认表格
      columns: [], // 已选展示项
      pickerOptions: {
        disabledDate(date) {
          return new Date(parseTime(date, '{y}-{m}-{d} 00:00:00')).valueOf() > Date.now() || date.valueOf() < Date.now() - 3600 * 1000 * 24 * 10
        }
      }
    }
  },
  created() {
    this.search = Object.assign(this.search, this.$route.query)
    this.handleRefresh()
  },
  methods: {
    implementationFetched({ sumRecharge, sumWithdraw, validBet, reward, negativeProfit, sumBetBalance, sumBonus }) { // 初始化统计信息
      this.staticInfo = { // key还未对应接口
        sumRecharge: { columnName: 'sumRecharge', val: sumRecharge }, // 充值金额
        sumWithdraw: { columnName: 'sumWithdraw', val: sumWithdraw }, // 提现金额
        validBet: { columnName: 'validBet', val: validBet }, // 有效投注金额
        reward: { columnName: 'reward', val: reward }, // 中奖金额
        negativeProfit: { columnName: 'negativeProfit', val: negativeProfit }, // 负盈利金额
        sumBetBalance: { columnName: 'sumBetBalance', val: sumBetBalance }, // 未结算订单金额
        sumBonus: { txt: this.$t('代理返佣'), sumBonus: 'rebateAmount', val: sumBonus } // 贡献返佣
      }
    },

    implementationGetParams() {
      return this.formatIntervalTime()
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
