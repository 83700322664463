<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <div class="text">会员账号:</div>
        <el-input
          v-model="search.memberName"
          class="filter-item"
          placeholder="请输入会员账号查询,最多20个字符"
          style="width: 260px"
          maxlength="20"
          show-word-limit
        />
        <div>账号类型:</div>

        <el-select
          v-model="search.accountType"
          class="filter-item"
          placeholder="选择类型"
          style="width: 140px"
          default
          clearable
        >
          <el-option label="全部" :value="null" />
          <el-option label="内部账号" :value="[1]" />
          <el-option label="普通账号" :value="[2, 3]" />
        </el-select>
        <div>申请状态:</div>
        <el-select
          v-model="search.guruStatus"
          class="filter-item"
          placeholder="选择类型"
          style="width: 140px"
          clearable
        >
          <el-option label="全部" :value="null" />
          <el-option label="申请中待审核" :value="0" />
          <el-option label="审核不通过" :value="1" />
          <el-option label="审核通过" :value="2" />
          <el-option label="被取消资格" :value="3" />
        </el-select>
      </div>
      <MyDatePicker v-model="times" :handleSetTimes="handleSetTimes" :isShortcutShow="false"/>
      <div class="buttonLine">
        <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
        >
          查询
        </el-button>
        <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-refresh-right"
          @click="resetSearch"
          >{{ $t("重置") }}</el-button
        >
      </div>
    </div>

    <div>
      <el-button
        class="filter-item"
        type="primary"
        @click="handleApply(2)"
        v-if="permission.Approved"
        >{{ $t("审核通过") }}
      </el-button>
      <el-button
        class="filter-item"
        type="primary"
        @click="handleApply(1)"
        v-if="permission.Reject"
        >{{ $t("审核拒绝") }}
      </el-button>
      <el-button
        class="filter-item"
        type="primary"
        @click="handleApply(3)"
        v-if="permission.Unqualified"
        >{{ $t("取消资格") }}
      </el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      @selection-change="handleSelectionChange"
    >
      <template slot="left">
        <button
          v-if="permission.Delete"
          class="el-button el-button--small el-button--orange"
          :disabled="selection.length === 0"
          @click="handleBatchDelete(2)"
        >
          批量限制
        </button>
        <button
          v-if="permission.Delete"
          class="el-button el-button--small el-button--orange"
          :disabled="selection.length === 0"
          @click="handleBatchDelete(1)"
        >
          批量删除
        </button>
      </template>
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />
    <!-- 弹窗 -->
    <el-dialog title="审核提示" :visible.sync="dialogFormVisible">
      <div>{{ dialogText }}</div>
      <el-form
        :model="dialogForm"
        v-if="dialogType == 1 || dialogType == 3"
        ref="ruleForm"
      >
        <el-form-item :label="dialogText2" label-width="120px" required>
          <el-input
            type="textarea"
            v-model="dialogForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitDialog">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 查看详情弹窗 -->
    <el-dialog title="审核详情" :visible.sync="reviewHistoryTableVisible">
      <d-table
        ref="DTable"
        v-loading="loading"
        :data="historyData"
        :columns="columns2"
        :options="options"
        :filter-column="filterColumn"
      >
      </d-table>
    </el-dialog>
    <UserDialog ref="UserDialogControl" />
    <FansDialog ref="FansDialogControl" @update="handleFetch()"/>

  </div>
</template>
<script>
import TablePageMixin from "@/mixins/table-page-mixin";
import GetColumns from "./columns";
import GetColumns2 from "./columns2";
import FansDialog from "./FansDialog.vue";
import {
  getGuruApplyList,
  reviewGuruApply,
  getApplicationCheckHistory,
} from "@/api/orderManage";
import { getDefaultTimes } from "@/utils";
import MyDatePicker from "@/components/MyDatePicker";
import UserDialog from "@/views/components/UserDialog";

export default {
  name: "OrderManageApplyList",
  componentName: "OrderManageApplyList",
  components: {
    MyDatePicker,
    UserDialog,
    FansDialog
  },
  mixins: [TablePageMixin(getGuruApplyList)],
  data() {
    return {
      reviewHistoryTableVisible: false,
      times: getDefaultTimes("timestamp", 0, 0, true),
      columns: GetColumns.call(this),
      columns2: GetColumns2.call(this),
      filterColumn: false,
      selection: [],
      tableData: {
        pageSize: 0,
      },
      search: {},
      dialogFormVisible: false,
      dialogType: null,
      dialogForm: {
        remark: "",
      },
      historyData: [],
    };
  },
  computed: {
    dialogText() {
      switch (this.dialogType) {
        case 2:
          return "确认审核通过吗？";
        case 1:
          return "确认审核拒绝吗？";
        case 3:
          return "确认取消大神推单资格吗？";
        default:
          break;
      }
    },
    dialogText2() {
      switch (this.dialogType) {
        case 1:
          return "拒绝原因";
        case 3:
          return "取消原因";
        default:
          break;
      }
    },
  },
  methods: {
    implementationGetParams() {
      return this.formatIntervalTime(false, "beginTime", "endTime");
    },
    handleSelectionChange(selection) {
      // 批量选择
      this.selection = [...selection];
    },

    handleApply(status) {
      if (this.selection.length == 0) {
        this.$message.warning(this.$t("请选择要操作的用户"));
        return;
      }
      this.dialogType = status;
      this.dialogFormVisible = true;
    },
    handleSubmitDialog() {
      if (this.dialogType != 2 && !this.dialogForm.remark) {
        this.$message.warning(this.$t("请输入原因"));
        return;
      }
      const memberList = (this.selection || []).map((item) => item.memberId);
      let requestData = {
        memberList,
        remark: this.dialogForm.remark,
        checkType: this.dialogType,
      };
      reviewGuruApply(requestData).then(([data, err]) => {
        if (!err) {
          this.$message.success("操作成功");
          this.dialogFormVisible = false;
          this.handleFetch();
        }
      });
    },
    checkDetails(row) {
      this.reviewHistoryTableVisible = true;
      getApplicationCheckHistory({ memberId: row.memberId }).then(
        ([data, err]) => {
          if (!err) {
            this.historyData = data;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-container-item {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  font-size:12px;
}
.buttonLine {
  display: flex;
  /* justify-content: end; */
  margin-bottom: 20px;
}
</style>
