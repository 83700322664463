import request from '@/plugins/axios'

// 用户
export const getOperatorList = ({ tenantCode, ...data }) => {
  return request({
    url: '/operator/getOperatorList',
    method: 'post',
    data: { ...data, tenantCode },
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const checkOperatorName = ({ tenantCode, ...params }) => {
  return request({
    url: '/operator/checkOperatorName',
    method: 'get',
    params,
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const checkNickName = ({ tenantCode, ...params }) => {
  return request({
    url: '/operator/checkNickName',
    method: 'get',
    params,
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const addOperator = ({ tenantCode, ...data }) => {
  return request({
    url: '/operator/addOperator',
    method: 'post',
    data,
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const updateOperator = ({ tenantCode, ...data }) => {
  return request({
    url: '/operator/updateOperator',
    method: 'post',
    data,
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const updateOperatorStatus = ({ tenantCode, ...data }) => {
  return request({
    url: '/operator/updateOperatorStatus',
    method: 'post',
    data,
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const addOperatorRemark = ({ tenantCode, ...params }) => {
  return request({
    url: '/operator/addRemark',
    method: 'get',
    params,
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const checkGoogleKey = ({ tenantCode, ...params }) => {
  return request({
    url: '/operator/checkGoogleKey',
    method: 'get',
    params,
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

// 权限分配
export const getGroupList = ({ tenantCode, ...data }) => {
  return request({
    url: '/group/getGroupList',
    method: 'post',
    data: { ...data, tenantCode },
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

// export const syncPermissions = () => {
//   return request({
//     url: '/group/syncPermissions',
//     method: 'get'
//   })
// }

export const updateGroupStatus = ({ tenantCode, ...params }) => {
  return request({
    url: '/group/updateGroupStatus',
    method: 'get',
    params,
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const deleteGroup = ({ tenantCode, ...params }) => {
  return request({
    url: '/group/deleteGroup',
    method: 'get',
    params,
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const getMenuList = ({ tenantCode, ...params }) => {
  return request({
    url: '/menu/getMenuList',
    method: 'get',
    params,
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const getMenuListByGroupId = ({ tenantCode, ...params }) => {
  return request({
    url: '/menu/getMenuListByGroupId',
    method: 'get',
    params: { ...params, tenantCode },
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const addGroupMenu = ({ tenantCode, platformTenantCode, ...data }) => {
  return request({
    url: '/menu/addGroupMenu',
    method: 'post',
    data: { ...data, tenantCode: platformTenantCode },
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const updateGroupMenu = ({ tenantCode, platformTenantCode, ...data }) => {
  return request({
    url: '/menu/updateGroupMenu',
    method: 'post',
    data: { ...data, tenantCode: platformTenantCode },
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

// 登錄日志
export const getAppLog = ({ tenantCode, ...data }) => {
  return request({
    url: '/authorization/getAppLog',
    method: 'post',
    data,
    headers: tenantCode && { 'X-TENANT-CODE': tenantCode }
  })
}

export const getBackManagerLog = data => {
  return request({
    url: '/authorization/getBackManagerLog',
    method: 'post',
    data
  })
}

// 白名单
export const getWhiteList = data => {
  return request({
    url: '/white/whiteList',
    method: 'post',
    data
  })
}

export const addWhiteIp = data => {
  return request({
    url: '/white/addWhiteIp',
    method: 'post',
    data
  })
}

export const updateWhiteIp = data => {
  return request({
    url: '/white/editWhiteIp',
    method: 'post',
    data
  })
}

export const deleteWhiteIp = id => {
  return request({
    url: '/white/delete',
    method: 'get',
    params: { id }
  })
}
// 国际化配置列表查询
export const getI18nList = data => {
  return request({
    url: '/i18n/page',
    method: 'post',
    data
  })
}

export const addI18n = data => {
  return request({
    url: '/i18n/add',
    method: 'post',
    data
  })
}

export const updateI18n = data => {
  return request({
    url: '/i18n/update',
    method: 'post',
    data
  })
}
export const deleteI18n = data => {
  return request({
    url: '/i18n/delete',
    method: 'post',
    data
  })
}

// 查询线路域名配置
export const getPlatformDomainList = params => {
  return request({
    url: '/platform/domain/list',
    method: 'get',
    params
  })
}

// 新增线路域名配置
export const platformDomainCreate = data => {
  return request({
    url: '/platform/domain/create',
    method: 'post',
    data
  })
}

// 更新线路域名配置
export const platformDomainUpdate = data => {
  return request({
    url: '/platform/domain/update',
    method: 'post',
    data
  })
}

// 启用停用线路域名配置
export const platformDomainDelete = params => {
  return request({
    url: '/platform/domain/delete',
    method: 'get',
    params
  })
}

export const getBackManagerOpLog = data => {
  return request({
    url: '/authorization/getBackManagerOpLog',
    method: 'post',
    data
  })
}

// 查询IM线路域名配置
export const getPlatformDomainImList = params => {
  return request({
    url: '/platform/domain/im/list',
    method: 'get',
    params
  })
}
