var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑",
        visible: _vm.visible,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "150px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("排版样式"), prop: "layoutStyle" } },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { width: "680px" },
                  on: {
                    change: function ($event) {
                      return _vm.getMyGamePlayList(_vm.ruleForm.layoutStyle)
                    },
                  },
                  model: {
                    value: _vm.ruleForm.layoutStyle,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "layoutStyle", $$v)
                    },
                    expression: "ruleForm.layoutStyle",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "style-list" },
                    [
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _c("div", { staticClass: "style-title" }, [
                          _vm._v("样式1"),
                        ]),
                        _c("div", { staticClass: "style-inner" }, [
                          _c("div", { staticClass: "style-item item1" }, [
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _c("div", { staticClass: "style-title" }, [
                          _vm._v("样式2"),
                        ]),
                        _c("div", { staticClass: "style-inner" }, [
                          _c("div", { staticClass: "style-item item2" }, [
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _c("div", { staticClass: "style-title" }, [
                          _vm._v("样式3"),
                        ]),
                        _c("div", { staticClass: "style-inner" }, [
                          _c("div", { staticClass: "style-item item3" }, [
                            _c("i", {
                              staticClass: "el-icon-arrow-left arr lt",
                            }),
                            _c("i", {
                              staticClass: "el-icon-arrow-right arr rt",
                            }),
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _c("el-radio-button", { attrs: { label: "4" } }, [
                        _c("div", { staticClass: "style-title" }, [
                          _vm._v("样式4"),
                        ]),
                        _c("div", { staticClass: "style-inner" }, [
                          _c("div", { staticClass: "style-item item4" }, [
                            _c("i", {
                              staticClass: "el-icon-arrow-left arr lt",
                            }),
                            _c("i", {
                              staticClass: "el-icon-arrow-right arr rt",
                            }),
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _c("el-radio-button", { attrs: { label: "5" } }, [
                        _c("div", { staticClass: "style-title" }, [
                          _vm._v("样式5"),
                        ]),
                        _c("div", { staticClass: "style-inner" }, [
                          _c("div", { staticClass: "style-item item5" }, [
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("div", [
                              _c("i", {
                                staticClass: "el-icon-arrow-left arr lt",
                              }),
                              _c("i", {
                                staticClass: "el-icon-arrow-right arr rt",
                              }),
                              _c(
                                "div",
                                [
                                  _c("el-image", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-picture-outline",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("el-image", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-picture-outline",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("el-image", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-picture-outline",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("el-radio-button", { attrs: { label: "6" } }, [
                        _c("div", { staticClass: "style-title" }, [
                          _vm._v("样式6"),
                        ]),
                        _c("div", { staticClass: "style-inner" }, [
                          _c("div", { staticClass: "style-item item5" }, [
                            _c(
                              "div",
                              [
                                _c("el-image", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("div", [
                              _c("i", {
                                staticClass: "el-icon-arrow-left arr lt",
                              }),
                              _c("i", {
                                staticClass: "el-icon-arrow-right arr rt",
                              }),
                              _c(
                                "div",
                                [
                                  _c("el-image", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-picture-outline",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("el-image", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-picture-outline",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("el-image", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-picture-outline",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              ["5", "6"].includes(_vm.ruleForm.layoutStyle)
                ? _c("p", [
                    _vm._v(
                      "提示：选择样式" +
                        _vm._s(_vm.ruleForm.layoutStyle) +
                        "需要配置banner。"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          ["5", "6"].includes(_vm.ruleForm.layoutStyle)
            ? _c(
                "el-form-item",
                { attrs: { label: "banner图", prop: "bannerImage" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "banner-uploader",
                      attrs: {
                        accept:
                          "image/jpeg,image/png,image/jpg,image/gif,image/webp",
                        action: _vm.action,
                        data: { type: "image", folder: "activity" },
                        headers: _vm.$headers(),
                        "show-file-list": false,
                        "on-success": _vm.handleSuccess,
                      },
                    },
                    [
                      !_vm.ruleForm.bannerImage
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "el-upload el-upload--text",
                                attrs: { tabindex: "0" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "avatar-uploader-icon",
                                    staticStyle: {
                                      width: "120px",
                                      height: "120px",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-plus" }),
                                    _c("p", [_vm._v("上传图片")]),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _c("img", {
                            staticClass: "banner",
                            attrs: { src: _vm.ruleForm.bannerImage },
                          }),
                    ]
                  ),
                  _c("p", [
                    _vm._v(
                      "提示：图片支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。"
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          ["5", "6"].includes(_vm.ruleForm.layoutStyle)
            ? _c(
                "el-form-item",
                { attrs: { label: "banner跳转地址", prop: "bannerJumpUrl" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "90%" },
                    attrs: { placeholder: _vm.$t("请输入banner跳转地址") },
                    model: {
                      value: _vm.ruleForm.bannerJumpUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "bannerJumpUrl", $$v)
                      },
                      expression: "ruleForm.bannerJumpUrl",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          ["5", "6"].includes(_vm.ruleForm.layoutStyle)
            ? _c(
                "el-form-item",
                {
                  attrs: { label: "是否显示奖金", prop: "displayPrizeStatus" },
                },
                [
                  _c("d-switch", {
                    attrs: {
                      "active-text": "是",
                      "inactive-text": "否",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.ruleForm.displayPrizeStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "displayPrizeStatus", $$v)
                      },
                      expression: "ruleForm.displayPrizeStatus",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          ["5", "6"].includes(_vm.ruleForm.layoutStyle) &&
          _vm.ruleForm.displayPrizeStatus
            ? _c(
                "el-form-item",
                {
                  attrs: { label: "虚拟金额设置", prop: "virtualAmountConfig" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "90%" },
                    attrs: {
                      type: "number",
                      placeholder: _vm.$t("请输入金额"),
                    },
                    model: {
                      value: _vm.ruleForm.virtualAmountConfig,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "virtualAmountConfig",
                          _vm._n($$v)
                        )
                      },
                      expression: "ruleForm.virtualAmountConfig",
                    },
                  }),
                  _c("p", [_vm._v("提示：中奖金额=虚拟金额+实际中奖金额")]),
                ],
                1
              )
            : _vm._e(),
          _vm.ruleForm.layoutStyle === "6" && _vm.ruleForm.displayPrizeStatus
            ? _c(
                "el-form-item",
                { attrs: { label: "奖金颜色", prop: "virtualAmountConfig" } },
                [
                  _c("el-color-picker", {
                    model: {
                      value: _vm.ruleForm.styleColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "styleColor", $$v)
                      },
                      expression: "ruleForm.styleColor",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "游戏图片", prop: "myGamePlayList" } },
            [
              _c(
                "el-table",
                {
                  ref: "elTable",
                  attrs: {
                    border: "",
                    data: _vm.ruleForm.myGamePlayList,
                    "row-key": "rowKey",
                    "drag-row": true,
                  },
                },
                [
                  _c(
                    "el-table-column",
                    {
                      attrs: { type: "index", align: "center", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [_vm._v(_vm._s(_vm.$t("排序")))]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("svg-icon", {
                        attrs: {
                          "icon-class": "drag",
                          "class-name": "drag-handler",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-table-column", {
                    attrs: { label: "玩法系列", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleChangeSeries(row)
                                  },
                                },
                                model: {
                                  value: row.seriesTag,
                                  callback: function ($$v) {
                                    _vm.$set(row, "seriesTag", $$v)
                                  },
                                  expression: "row.seriesTag",
                                },
                              },
                              _vm._l(
                                _vm.seriesList.filter(function (e) {
                                  return _vm.includeTag.includes(e.seriesTag)
                                }),
                                function (item) {
                                  return _c("el-option", {
                                    key: item.seriesTag,
                                    attrs: {
                                      value: item.seriesTag,
                                      label: item.seriesName,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "游戏", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleChangeLottery(row)
                                  },
                                },
                                model: {
                                  value: row.lotteryId,
                                  callback: function ($$v) {
                                    _vm.$set(row, "lotteryId", $$v)
                                  },
                                  expression: "row.lotteryId",
                                },
                              },
                              _vm._l(
                                _vm.seriesList.some(function (e) {
                                  return e.seriesTag === row.seriesTag
                                })
                                  ? _vm.seriesList.find(function (e) {
                                      return e.seriesTag === row.seriesTag
                                    }).list
                                  : [],
                                function (item) {
                                  return _c("el-option", {
                                    key: item.lotteryId,
                                    attrs: {
                                      value: item.lotteryId,
                                      label: item.name,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("图标"),
                      align: "center",
                      width: "140",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("d-upload", {
                              attrs: {
                                folder: "icon",
                                "on-success": function () {
                                  var args = [],
                                    len = arguments.length
                                  while (len--) args[len] = arguments[len]

                                  return _vm.handleUploadSuccess(args, row)
                                },
                                width: 86,
                                height: 86,
                              },
                              model: {
                                value: row.icon,
                                callback: function ($$v) {
                                  _vm.$set(row, "icon", $$v)
                                },
                                expression: "row.icon",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("操作"),
                      align: "center",
                      width: "140",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    row.lotteryId = null
                                    row.lotteryName = null
                                    row.seriesTag = null
                                    row.seriesName = null
                                    row.icon = null
                                  },
                                },
                              },
                              [_vm._v("重置")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-form-item", [
            _c("p", [
              _vm._v(
                "提示：不选择游戏时默认显示玩法，图片支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。"
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.myClose } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }