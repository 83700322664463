var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("comMenu", {
    attrs: {
      title: "六合版-首页金刚区",
      "menu-name": "金刚区",
      "menu-list": _vm.menuList,
      num: 8,
      "home-type": "liuhe",
      position: 4,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }