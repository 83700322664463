<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="50%"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      :disabled="emitName === 'details'"
      label-width="120px"
      label-suffix=":"
    >
      <el-form-item :label="$t('商品名称')" prop="name">
        <el-input v-model="ruleForm.name" style="width: 310px" maxlength="20" :placeholder="$t('限20字以内')" clearable />
      </el-form-item>
      <el-form-item :label="$t('商品标题')" prop="title">
        <el-input v-model="ruleForm.title" style="width: 500px" maxlength="50" :placeholder="$t('限50字以内')" clearable />
      </el-form-item>
      <el-form-item :label="$t('商品类目')">
        <el-select v-model="ruleForm.categoryId" :placeholder="$t('请输入类目名称查询')" @change="$forceUpdate()" style="width: 310px" clearable filterable multiple collapse-tags>
          <el-option v-for="item in category" :key="item.id" :value="item.id" :label="item.categoryName" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('商品单价')" prop="price">
        <el-input v-model="ruleForm.price" type="text" style="width: 277px; margin-right: 7px;" :placeholder="$t('请填写商品单价')" maxlength="8" clearable />积分
      </el-form-item>
      <el-form-item :label="$t('可用库存')">
        <el-radio-group v-model="stockRadio" v-removeAriaHidden>
          <el-radio :label="-1">{{ $t('不限制') }}</el-radio>
          <el-radio :label="0">{{ $t('限制') }}</el-radio>
        </el-radio-group>
        <el-form-item v-if="stockRadio !== -1" prop="stock">
          <el-input v-model="ruleForm.stock" type="number" class="stock" :placeholder="$t('可用库存数量')" clearable />
        </el-form-item>
      </el-form-item>
      <el-form-item :label="$t('商品类型')">
        <el-radio-group v-model="ruleForm.type" v-removeAriaHidden>
          <el-radio :label="2">{{ $t('虚拟商品') }}</el-radio>
          <el-radio :label="1">{{ $t('实物商品') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('排序')" prop="sort">
        <el-input v-model="ruleForm.sort" type="number" style="width: 310px" :placeholder="$t('请输入1-999999的整数，由大到小')" clearable />
      </el-form-item>
      <el-form-item :label="$t('商品封面')" prop="cover">
        <el-upload
          class="cover-uploader"
          accept="image/jpeg,image/png,image/jpg,image/gif,image/svg,image/webp"
          :action="action"
          :data="{ type: 'image', folder: 'ad-photo' }"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleCoverSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="ruleForm.cover" :src="ruleForm.cover" class="cover">
          <div v-else class="cover-uploader-txt">
            <p>提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。</p>
            <button class="el-button el-button--small el-button--orange" @click.self.prevent>点击上传</button>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('商品介绍')" prop="introduction">
        <el-upload
          :action="action"
          :file-list="ruleForm.introduction"
          :headers="$headers()"
          :data="{ type: 'image', folder: 'ad-photo' }"
          :on-success="handleUploadSuccess"
          :before-upload="beforeImgUpload"
          :on-remove="onImgRemove"
          :on-change="imgOnChange"
          :on-exceed="onExceed"
          :limit="8"
          list-type="picture-card"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            <i class="el-icon-warning" />
            提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。支持多张，最多8张
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('商品详情')" prop="detail">
        <Tinymce ref="detail" v-model="ruleForm.detail" :height="200" />
      </el-form-item>
      <el-form-item :label="$t('商品状态')">
        <el-radio-group v-model="ruleForm.status" v-removeAriaHidden>
          <el-radio :label="0">{{ $t('上架') }}</el-radio>
          <el-radio :label="1">{{ $t('下架') }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button v-if="emitName !== 'details'" type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import FormDialogMixin from '@/mixins/form-dialog'
import { mapState } from 'vuex'
import { integer1, integerNormal } from '@/utils/validation'
import { getCategoryList } from '@/api/points'

const getDefaultForm = () => ({
  name: '',
  title: '',
  price: '',
  stock: -1,
  type: 2,
  sort: null,
  cover: '',
  introduction: [],
  detail: '',
  status: 0,
})

export default {
  components: { Tinymce },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      category: [],
      stockRadio: -1,
      rules: {
        name: [{ required: true, message: this.$t('请填写商品名称'), trigger: 'blur' }],
        title: [{ required: true, message: this.$t('请填写商品标题'), trigger: 'blur' }],
        price: [{ required: true, message: this.$t('请填写商品单价'), trigger: 'blur' }, { pattern: integer1, message: this.$t('请输入大于0的整数'), trigger: 'blur' }],
        stock: [{ required: true, message: this.$t('请填写可用库存数量'), trigger: 'blur' }, { pattern: integerNormal, message: this.$t('请输入大于或等于0的整数'), trigger: 'blur' }],
        sort: [{ required: false, pattern: /^[1-9][0-9]{0,5}$/, message: this.$t('请输入1-999999的整数'), trigger: 'change' }],
        cover: [{ required: true, message: this.$t('请上传商品封面'), trigger: 'change' }],
        introduction: [{ required: true, message: this.$t('请上传商品介绍'), trigger: 'change' }],
        detail: [{ required: true, message: this.$t('请填写商品详情'), trigger: 'change' }],
      },
    }
  },
  computed: {
    ...mapState('app', ['']),
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    title() {
      return this.emitName === 'create' ? this.$t('新建商品') : this.$t('修改商品')
    },
  },
  watch: {
    stockRadio(val) {
      if (val === -1) {
        this.ruleForm.stock = -1
      } else {
        this.ruleForm.stock = null
      }
    }
  },
  created() {

  },
  methods: {
    imgOnChange(file, fileList) {
      this.ruleForm.introduction = fileList;
    },
    onImgRemove(file, fileList) {
      this.ruleForm.introduction = fileList;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error(`上传图片大小不能超过 2MB!`)
      }
      return isLt2M
    },
    beforeImgUpload(file) {
      const isJPG =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg" ||
        file.type === "image/gif" ||
        file.type === "image/webp";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error(this.$t('上传图片只能是 PNG、JPG、GIF、SVG 格式!'));
      }
      if (!isLt2M) {
        this.$message.error(this.$t('上传图片大小不能超过 2MB!'));
      }
      return isJPG && isLt2M;
    },
    onExceed(file, fileList) {
      this.$message.error(this.$t('商品介绍最多8张图片'));
    },
    async open(row) {
      this.visible = true
      // 商品类目
      await getCategoryList({ categoryName: '', langCode: '', currentPage: 1, pageSize: 50 }).then(([items, err]) => {
        this.category = items
      })
      if (!row) {
        this.emitName = 'create'
        this.stockRadio = -1
      } else {
        this.ruleForm.id = row.id
        this.ruleForm.name = row.name
        this.ruleForm.title = row.title
        this.ruleForm.categoryId = row.categoryId
        this.ruleForm.price = row.price
        const stock = row.stock
        if (stock === -1) {
          this.stockRadio = -1
        } else {
          this.stockRadio = 0
          this.$nextTick(function() {
            this.ruleForm.stock = stock
          })
        }
        this.ruleForm.type = row.type
        this.ruleForm.sort = parseInt(row.sort)
        this.ruleForm.cover = row.cover
        const introduction = row.introduction
        if (introduction.length) {
          for (const el of introduction) {
            this.ruleForm.introduction.push({
              url: el
            })
          }
        }
        this.ruleForm.detail = row.detail
        this.ruleForm.status = row.status
        this.emitName = 'update'
      }
    },
    handleCoverSuccess(res) {
      this.ruleForm.cover = res.data[0]
      this.$refs.validateForm.validateField('cover')
    },
    handleUploadSuccess(res) {
      this.ruleForm.introduction.push(res.data[0])
    }
  }
}
</script>

<style lang="scss">
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.stock {
  position: absolute;
  width: 125px;
  top: -33px;
  margin-left: 185px;
}

.cover-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.cover-uploader .el-upload:hover {
  border-color: #409EFF;
}

.cover-uploader-txt {
  font-size: 14px;
  color: #8c939d;
  width: 310px;
  height: 128px;
  padding: 0 5px;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.cover {
  width: 310px;
  height: 128px;
  display: block;
}

.tinymce-container {
  margin-bottom: 5px;
}
</style>
