<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="70%"
    :title="title"
    @open="handleRefresh"
    @closed="onClosed"
  >
    <template v-if="search.type === 3">
      <el-input v-model="search.memberName" clearable :placeholder="$t('会员账号')" class="filter-item" style="width: 140px"/>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t("查询") }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t("重置") }}</el-button>
    </template>
    <el-button plain type="primary" :loading="excelLoading1" icon="el-icon-download" style="margin-bottom: 6px;" @click="_handleExport">{{ $t('导出Excel') }}</el-button>
    <el-table
      v-loading="loading"
      :data="items"
      v-bind="options"
      style="height: 400px; overflow-y: auto;"
    >
      <el-table-column prop="ruleType" :label="$t('会员账号')" align="center">
        <template slot-scope="{ row }">
          <div>
            <p>{{ row.memberName }}</p>
            <template v-if="search.type !== 13">
              <p>{{ $t('代理等级') }}: {{ row.agencyLevel }}</p>
              <p>{{ $t('VIP等级') }}: {{ row.vipLevel }}</p>
              <p>{{ $t('直属上级') }}: {{ row.oneLevelAgencyName }}</p>
            </template>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="search.type !== 13" prop="register" :label="$t('注册')" align="center">
        <template slot-scope="{ row }">
          <div>
            <p>{{ row.registerIp }}</p>
            <p>{{ row.registerAddress }}</p>
            <p>{{ $parseTime(row.registerTime) }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="search.type === 1" prop="lastLogin" :label="$t('最后登录')" align="center">
        <template slot-scope="{ row }">
          <div>
            <p>{{ row.latestLoginIp }}</p>
            <p>{{ row.latestLoginAddress }}</p>
            <p>{{ $parseTime(row.latestLoginTime) }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="search.type === 2" prop="firstRechargeTime" :label="$t('首充时间')" align="center">
        <template slot-scope="{ row }">
          {{ $parseTime(row.firstRechargeTime) }}
        </template>
      </el-table-column>
      <el-table-column v-if="search.type !== 1" prop="amount" :label="amountLabel" align="center">
        <template slot-scope="{ row }">
          {{ row.amount }}
        </template>
      </el-table-column>
      <el-table-column v-if="[9,10,11,12].includes(search.type)" prop="winAmount" :label="$t('中奖金额')" align="center">
        <template slot-scope="{ row }">
          {{ row.winAmount }}
        </template>
      </el-table-column>
      <el-table-column v-if="[9,10,11,12].includes(search.type)" prop="profitAmount" :label="$t('盈亏')" align="center">
        <template slot-scope="{ row }">
          {{ row.profitAmount }}
        </template>
      </el-table-column>
    </el-table>

    <pagination :page.sync="search.currentPage" :limit.sync="search.pageSize" :total="total" layout="total, sizes, prev, next, ->" @pagination="handleFetch" />

    <ExportDialog ref="ExportDialogControl" />
  </el-dialog>
</template>
<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getAgencyReportDataDetailList, exportAgencyReportDataDetailList } from '@/api/agent-rebate'
import ExportDialog from './ExportDialog.vue'
import { parseTime, exportExcel } from '@/utils'
export default {
  components: { ExportDialog },
  mixins: [VDialogMixin, TablePageMixin(getAgencyReportDataDetailList, false, {}, 'memberId')],
  data() {
    parseTime
    return {
      detailTypes: this.$t('detailTypes'),
      excelLoading1: false,
      search: { type: 1 }
    }
  },
  computed: {
    title() {
      const typeInfo = this.detailTypes.find(p => p.value === this.search.type)
      if (typeInfo) {
        return typeInfo.label
      }
      return ''
    },
    amountLabel() {
      // 详情类型 1-注册 2-首充 3-充值 4-提现 5-手动加减款 6-活动礼金 7-返水 8-代理返佣 9-彩票 10-竞彩体育 11-原生体育 12-三方游戏
      switch (this.search.type) {
        case 2:
          return this.$t('首充金额')
        case 3:
          return this.$t('累积充值金额')
        case 4:
          return this.$t('累积提现金额')
        case 5:
          return this.$t('累积手动加减款金额')
        case 6:
          return this.$t('活动礼金')
        case 7:
          return this.$t('返水')
        case 8:
          return this.$t('获得返佣')
        case 9:
        case 10:
        case 11:
        case 12:
          return this.$t('有效投注')
        case 13:
          return '累计复充金额'
        default:
          return ''
      }
    }
  },
  methods: {
    open(type, params, dataTime, memberId, oneLevelAgencyId) {
      this.search.oneLevelAgencyId = oneLevelAgencyId
      this.search.type = type
      this.search.dateType = params.dateType
      this.search.dataLevel = params.dataLevel
      this.search.agencyId = memberId
      this.search.currentPage = 1
      if (params.dateType === 1) {
        this.search.dataTime = parseTime(dataTime, '{y}-{m}-{d}')
      } else {
        this.search.dataTime = undefined
      }
      this.visible = true
    },
    onClosed() {
      this.nickname = ''
      this.items = []
      this.parameter = {}
    },
    _handleExport() {
      this.excelLoading1 = true
      exportAgencyReportDataDetailList(this.search).then(([_, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `代理数据-${this.$parseTime(new Date())}.csv`)
        }
      }).finally(()=>{
        this.excelLoading1 = false
      })
    },
    // 缓存分页查询传入的业务Id
    cashStartId() {
      if (this.search.currentPage > 1 && this.pageBusId[this.search.currentPage - 1]) {
        this.search.lastMemberId = this.pageBusId[this.search.currentPage - 1]
      } else {
        this.search.lastMemberId = undefined
      }
    },
    resetSearch() {
      this.search.memberName = ''
    }
  }
}
</script>
