import { mapGetters } from 'vuex'
import Pagination from '@/components/Pagination'
import { filterParam } from '@/utils/'

export default (serviceHandle, createdFetech = true, insetparams = {}, startIdKey) => ({
  data: () => ({
    items: [],
    originalItems: [],
    total: 0,
    loading: false,
    loaderr: false,
    params: {
      currentPage: 1,
      pageSize: 50
    },
    filterColumn: true,
    options: {
      // stripe: true,
      border: true,
      fit: true,
      highlightCurrentRow: true,
      class: 'customize-table'
    },
    pageBusId: {},
    search: {},
    pickerOptions: {
      // 限制只能在开始和结束周期内选择
      disabledDate: (time) => {
        // 如果没有后面的 -8.64e7 就是不可以选择今天的
        const dateTime = new Date(new Date().getTime() - 180 * 24 * 60 * 60 * 1000).getTime() - 8.64e7
        return dateTime > time.getTime()
      }
    },
    mixinTimer: null
  }),
  components: {
    Pagination
  },
  computed: {
    ...mapGetters(['permissionSensitive']),
    permission() {
      return this.permissionSensitive(this.$route.path, this.$options.name)
    }
  },
  created() {
    if (this.filterColumn) this.handleColumnChange()
    if (createdFetech) {
      this.handleFetch()
    }
  },
  destroyed() {
    if (this.mixinTimer) {
      clearTimeout(this.mixinTimer)
    }
  },
  methods: {
    handleSearch() {
      if(this.mixinTimer) {
        clearTimeout(this.mixinTimer)
      }
      this.mixinTimer = setTimeout(() => {
        this.handleRefresh()
      }, 500)
    },
    // 重置page = 1 再请求
    handleRefresh() {
      this.params.currentPage = 1
      this.handleFetch()
    },
    // 发送请求
    async handleFetch() {
      if (this.beforeFetch && !this.beforeFetch()) return false
      this.loaderr = false
      this.loading = true
      if (startIdKey) {
        this.cashStartId()
      }
      
      // 调用者实现这个方法
      const params = filterParam({ ...insetparams, ...this.params, ...this.implementationGetParams() })
      const [, err, response] = await serviceHandle({ ...params }, this.operatorType)
      this.loading = false
      if (err) {
        this.loaderr = true
        return
      }
      const { totalNum, items } = response.data
      this.total = +totalNum
      const data = items || []
      this.items = data
      this.originalItems = data
      if (startIdKey && data.length > 0) {
        this.pageBusId[this.params.currentPage] = data[data.length - 1][startIdKey]
      }
      this.implementationFetched(response.data)
    },
    handleSetTimes(e, day = 0, isTimestamp = true, times = '') {
      let start = new Date()
      let end = new Date()
      const oneDayMilli = 3600 * 1000 * 24
      const todayDayNum = new Date().getDay()
      switch (day) {
        case 0: // 今日
          start = this.$parseTime(start, '{y}-{m}-{d} 00:00:00')
          end = this.$parseTime(end, '{y}-{m}-{d} 23:59:59')
          break
        case 1: // 昨日
          start = this.$parseTime(start.valueOf() - oneDayMilli, '{y}-{m}-{d} 00:00:00')
          end = this.$parseTime(end.valueOf() - oneDayMilli, '{y}-{m}-{d} 23:59:59')
          break
        case -1: // 明日
          start = this.$parseTime(start.valueOf() + oneDayMilli, '{y}-{m}-{d} 00:00:00')
          end = this.$parseTime(end.valueOf() + oneDayMilli, '{y}-{m}-{d} 23:59:59')
          break
        case 7: // 7天
          start = this.$parseTime(start.valueOf() - oneDayMilli * 6, '{y}-{m}-{d} 00:00:00')
          end = this.$parseTime(end, '{y}-{m}-{d} 23:59:59')
          break
        case -7: // 未来7天
          start = this.$parseTime(start, '{y}-{m}-{d} 00:00:00')
          end = this.$parseTime(end.valueOf() + oneDayMilli * 6, '{y}-{m}-{d} 23:59:59')
          break
        case 3: // 本周
          start = this.$parseTime(start.valueOf() - oneDayMilli * (todayDayNum - 1), '{y}-{m}-{d} 00:00:00')
          end = this.$parseTime(end, '{y}-{m}-{d} 23:59:59')
          break
        case 14: // 上周
          start = this.$parseTime(start.valueOf() - oneDayMilli * (todayDayNum + 6), '{y}-{m}-{d} 00:00:00')
          end = this.$parseTime(end.valueOf() - oneDayMilli * todayDayNum, '{y}-{m}-{d} 23:59:59')
          break
        case 30: // 本月
          start = this.$parseTime(start, '{y}-{m}-01 00:00:00')
          end = this.$parseTime(end, '{y}-{m}-{d} 23:59:59')
          break
        case 60: // 上月
          start.setDate(1)
          end.setDate(1)
          start = this.$parseTime(start - oneDayMilli, '{y}-{m}-01 00:00:00')
          end = this.$parseTime(end - oneDayMilli, '{y}-{m}-{d} 23:59:59')
          break
      }
      if (isTimestamp) {
        start = new Date(start).getTime()
        end = new Date(end).getTime()
      }
      if (times) {
        this[times] = [start, end]
      } else {
        this.times = [start, end]
      }
    },

    handleSetDate(e, day = 0) {
      let end = new Date()
      let start = new Date()
      switch (day) {
        case 0:
          start = this.$parseTime(start, '{y}-{m}-{d}')
          end = this.$parseTime(end, '{y}-{m}-{d}')
          break
        case 1:
          start = this.$parseTime(start.valueOf() - 3600 * 1000 * 24, '{y}-{m}-{d}')
          end = this.$parseTime(end.valueOf() - 3600 * 1000 * 24, '{y}-{m}-{d}')
          break
        case 7:
          start = this.$parseTime(start.valueOf() - 3600 * 1000 * 24 * 6, '{y}-{m}-{d}')
          end = this.$parseTime(end, '{y}-{m}-{d}')
          break
        case 30:
          start = this.$parseTime(start, '{y}-{m}-01')
          end = this.$parseTime(end, '{y}-{m}-{d}')
          break
      }
      this.times = [start, end]
    },

    formatIntervalTime(isDate = false, key1='startTime', key2='endTime') {
      if (this.times && Array.isArray(this.times)) {
        let [startTime, endTime] = this.times
        if (isDate) {
          startTime = startTime.substr(0, 10)
          endTime = endTime.substr(0, 10)
        }
        return {
          [key1]: startTime,
          [key2]: endTime,
          ...this.search
        }
      }
      return { ...this.search }
    },

    handleColumnChange(keys) {
      this.columns = this.implementationGetColumns(keys)
    },
    // 子组件实现获取外部参数
    implementationGetParams() {
      return this.search || {}
    },
    // 子组件实现获取列配置
    implementationGetColumns(keys) {
      return []
    },
    // 子组件实现获取数据之后
    implementationFetched(data) {
      //
    },
    // 缓存分页查询传入的业务Id
    cashStartId() {
      if (this.search.currentPage > 1 && this.pageBusId[this.search.currentPage - 1]) {
        this.search[startIdKey] = this.pageBusId[this.search.currentPage - 1]
      } else {
        this.search[startIdKey] = undefined
      }
    },
    // 重置条件
    resetSearch() {
      if (this.times !== undefined) {
        this.times = this.$options.data.call(this).times
      }
      this.search = this.$options.data.call(this).search
      this.handleSearch()
    }
  }
})
