var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "添加图库集合", visible: _vm.visible, width: "46%" },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "六合彩种", prop: "pictureTag" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "100%" },
                  attrs: { disabled: "", placeholder: "选择六合彩种" },
                  model: {
                    value: _vm.ruleForm.pictureTag,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "pictureTag", $$v)
                    },
                    expression: "ruleForm.pictureTag",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "快乐六合彩", value: "KLLH01" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图库名称", prop: "pictureName" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "20",
                  placeholder: "请输入图库名称，最长20个字符",
                },
                model: {
                  value: _vm.ruleForm.pictureName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "pictureName", $$v)
                  },
                  expression: "ruleForm.pictureName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("图库类别"),
                prop: "pictureCategoryRelRequestList",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    filterable: "",
                    placeholder: "请输入图库类别查询，最多20个字",
                    maxlength: "20",
                  },
                  model: {
                    value: _vm.pictureCategoryRelRequestList,
                    callback: function ($$v) {
                      _vm.pictureCategoryRelRequestList = $$v
                    },
                    expression: "pictureCategoryRelRequestList",
                  },
                },
                _vm._l(_vm.$parent.picList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.categoryName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }