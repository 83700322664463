<template>
  <el-dialog :title="`${title}大神分类`" :visible.sync="visible" width="400px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="分类名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="4~6个中文" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        确认
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  name: ''
  // deleted: 0
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        name: [
          { required: true, message: '请填写分类名称', trigger: 'blur' },
          { pattern: /^[\u4e00-\u9fa5]{4,6}$/, message: '请输入4~6个中文', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? '添加' : '编辑'
    }
  },
  methods: {
    open(row) {
      if (row) {
        this.emitName = 'update'
        this.ruleForm.id = row.id
        this.ruleForm.name = row.name
        // this.ruleForm.deleted = row.deleted
      } else {
        this.emitName = 'create'
      }
      this.visible = true
    }
  }
}
</script>
