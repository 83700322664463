<template>
  <el-dialog
    :title="$t('明细详情')"
    :visible.sync="visible"
    width="75%"
    :close-on-click-modal="false"
    @open="handleRefresh"
    @closed="handleClosed"
  >
    <div class="filter-container">
      <div class="filter-container-item">
        <el-input v-model="search.nickName" :placeholder="$t('主播昵称')" clearable style="width: 160px;" />
        <el-button icon="el-icon-search" type="primary" @click="handleRefresh">{{ $t('查询') }}</el-button>
      </div>
    </div>
    <div v-loading="loading">
      <div v-if="summary" flex="cross:center main:justify" style="line-height: 50px">
        <div>{{ $t('日期') }}：{{ summary.onlineDate | parseTime('{y}-{m}-{d}') }}</div>
        <div>
          {{ $t('礼物分成汇总') }}：<span class="danger-type">{{ summary.sumGiftIncome }}</span>
          {{ $t('投注分成汇总') }}：<span class="danger-type">{{ summary.sumBetsIncome }}</span>
          {{ $t('房费分成汇总') }}：<span class="danger-type">{{ summary.sumPayroomIncome }}</span>
          {{ $t('总分成汇总') }}：<span class="danger-type">{{ summary.sumTotalAmount }}</span>
        </div>
      </div>
      <el-table :data="items" v-bind="options" max-height="250">
        <el-table-column :label="$t('主播昵称')" prop="nickName" align="center" width="140" />
        <el-table-column :label="$t('开播时段')" prop="time" align="center">
          <template slot-scope="{ row }">{{ row.onlineDate | parseTime }}&nbsp;~&nbsp;{{ row.offlineDate | parseTime }}</template>
        </el-table-column>
        <el-table-column :label="$t('礼物收入/分成')" prop="gift" align="center">
          <template slot-scope="{ row }">{{ row.giftAmount }}/{{ row.giftIncome }}</template>
        </el-table-column>
        <el-table-column :label="$t('投注收入/分成')" prop="bets" align="center">
          <template slot-scope="{ row }">{{ row.betsAmount }}/{{ row.betsIncome }}</template>
        </el-table-column>
        <el-table-column :label="$t('房费收入/分成')" prop="room" align="center">
          <template slot-scope="{ row }">{{ row.payroomAmount }}/{{ row.payroomIncome }}</template>
        </el-table-column>
        <el-table-column :label="$t('总计分成')" prop="total" align="center">
          <template slot-scope="{ row }">{{ row.totalAmount }}</template>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="medium" @click="close">
        {{ $t('关闭') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getLiveFamilyWithdrawDetails, getLiveFamilyWithdrawDetailsSum } from '@/api/live'
export default {
  mixins: [VDialogMixin, TablePageMixin(getLiveFamilyWithdrawDetails, false)],
  data() {
    return {
      search: {},
      summary: null
    }
  },
  methods: {
    open(row) {
      this.search.apycId = row.apycId
      this.visible = true
    },
    handleClosed() {
      this.items = []
      this.search = {}
      this.summary = null
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationFetched({ data }) {
      this.items = data || []
      getLiveFamilyWithdrawDetailsSum({ ...this.implementationGetParams() }).then(([data, err]) => {
        if (!err) {
          this.summary = data
        }
      })
    }
  }
}
</script>

