const CanChooseColumns = function() {
  return [
    {
      title: this.$t('排序'),
      key: 'sortBy',
      align: 'center'
    },
    {
      title: this.$t('分类名称'),
      key: 'items',
      align: 'center',
      component: {
        render: (h, row) => {
          const vnodes = []
          row.items.forEach(e => {
            if (e.categoryName !== null && e.categoryName !== '') {
              const lang = this.allLang.find(v => v.lang === e.langCode)
              if (lang) {
                vnodes.push(<div>{ this.$t(lang.name) + ':' + e.categoryName }</div>)
              }
            }
          })
          return (<span>{ vnodes }</span>)
        }
      }
    },
    {
      title: '分类图标',
      key: 'categoryIcon',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image style='width:48px;height:48px' src={row.categoryIcon} lazy></el-image>)
        }
      }
    },
    {
      title: this.$t('所属频道'),
      key: 'channelName',
      align: 'center',
      component: {
        render: (h, row) => {
          const vnodes = []
          const channel = this.channelList.find(e => e.value === row.channelUnionId)
          if (channel) {
            channel.items.forEach(e => {
              if (e.channelName !== null && e.channelName !== '') {
                const lang = this.allLang.find(v => v.lang === e.langCode)
                if (lang) {
                  vnodes.push(<div>{ this.$t(lang.name) + ':' + e.channelName }</div>)
                }
              }
            })
          }
          return (<span>{ vnodes }</span>)
        }
      }
    },
    {
      title: this.$t('数据来源'),
      key: 'dataSource',
      align: 'center',
      component: {
        render: (h, row) => {
          let dataSourceName = this.dataSource.find(e => e.key === '10').label
          if (row.dataSource !== '10') {
            dataSourceName = this.dataSource.find(e => e.key === '20').label
            const dataLive = this.dataSourceLive.find(e => e.key === row.dataSource)
            if (dataLive) {
              dataSourceName += '-' + dataLive.label
            }
          }
          return (<span>{ dataSourceName }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: '320',
      component: {
        render: (h, row) => {
          const { DataManage, Edeti, Delete } = this.permission
          return (
            <div>
              { DataManage && row.dataSource !== '10' && <el-button type='info' size='small' icon='el-icon-s-data' onClick={ () => { this.$refs.DataManageDialogControl.open(row) } }>{ this.$t('数据管理') }</el-button> }
              { Edeti && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.ActionDialogControl.open(row) } }>{ this.$t('编辑') }</el-button> }
              { Delete && <el-button type='danger' size='small' icon='el-icon-delete' onClick={ () => this.handleDeleteChannelType(row.unionId)}>{ this.$t('删除') }</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
