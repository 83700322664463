export default {
  props: {
    originalColumns: {
      type: Array,
      default() {
        return []
      }
    },
    columns: {
      type: Array,
      default() {
        return []
      }
    },
    // 数据源与 el-table 相同
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    _columns() {
      return this.columns.map(o => o.key).filter(Boolean)
    }
  }
}
