var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "700px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          staticStyle: { height: "70vh", "overflow-y": "auto" },
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            disabled: _vm.emitName === "details",
            "label-width": "180px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("任务分类"), prop: "activityType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "90%" },
                  attrs: { placeholder: _vm.$t("任务分类") },
                  model: {
                    value: _vm.ruleForm.activityType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "activityType", $$v)
                    },
                    expression: "ruleForm.activityType",
                  },
                },
                _vm._l(_vm.$parent.activityTypeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.typeName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.ruleForm.activityTitleList, function (item) {
            return _c(
              "el-form-item",
              {
                key: item.lang,
                attrs: { label: _vm.$t(item.name) + _vm.$t("任务名称") },
              },
              [
                _c("el-input", {
                  staticStyle: { width: "90%" },
                  attrs: { placeholder: _vm.$t("请输入任务名称") },
                  model: {
                    value: item.activityTitle,
                    callback: function ($$v) {
                      _vm.$set(item, "activityTitle", $$v)
                    },
                    expression: "item.activityTitle",
                  },
                }),
              ],
              1
            )
          }),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("排序"), prop: "sort" } },
            [
              _c("el-input", {
                staticStyle: { width: "90%" },
                attrs: { maxlength: "20", placeholder: _vm.$t("请输入排序") },
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sort", $$v)
                  },
                  expression: "ruleForm.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("自动分享"), prop: "isAutoShare" } },
            [
              _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      width: 45,
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                    },
                    model: {
                      value: _vm.ruleForm.isAutoShare,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "isAutoShare", $$v)
                      },
                      expression: "ruleForm.isAutoShare",
                    },
                  }),
                  _c(
                    "span",
                    {
                      class: _vm.ruleForm.isAutoShare
                        ? "switchactive"
                        : "switchtext",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.ruleForm.isAutoShare ? "启用" : "关闭") +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.ruleForm.isAutoShare == 1
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("聊天室"), prop: "roomId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "90%" },
                      attrs: {
                        placeholder: _vm.$t("请选择聊天室"),
                        clearable: "",
                      },
                      model: {
                        value: _vm.ruleForm.roomId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "roomId", $$v)
                        },
                        expression: "ruleForm.roomId",
                      },
                    },
                    _vm._l(_vm.chatList, function (item) {
                      return _c("el-option", {
                        key: item.roomId,
                        attrs: { label: item.roomName, value: item.roomId },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("任务时间") } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.activityTimeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "activityTimeType", $$v)
                    },
                    expression: "ruleForm.activityTimeType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("定时开启"))),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("持续开启"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.activityTimeType === 0
            ? _c(
                "el-form-item",
                { attrs: { prop: "activityDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "value-format": "timestamp",
                      "range-separator": _vm.$t("至"),
                      "start-placeholder": _vm.$t("开始时间"),
                      "end-placeholder": _vm.$t("开始时间"),
                      "picker-options": _vm.pickerOptions,
                    },
                    model: {
                      value: _vm.ruleForm.activityDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "activityDate", $$v)
                      },
                      expression: "ruleForm.activityDate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("任务开关") } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.activityStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "activityStatus", $$v)
                    },
                    expression: "ruleForm.activityStatus",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("开启"))),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("结束"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("任务内容") } },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-right": "6px" },
                  on: { change: _vm.handleLang },
                  model: {
                    value: _vm.lang,
                    callback: function ($$v) {
                      _vm.lang = $$v
                    },
                    expression: "lang",
                  },
                },
                _vm._l(_vm.$parent.allLang, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: index, attrs: { label: item.lang } },
                    [_vm._v(_vm._s(_vm.$t(item.name)))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("tinymce", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.lang === "zh_CN",
                    expression: "lang === 'zh_CN'",
                  },
                ],
                ref: "zh_CN",
                staticStyle: { width: "92%" },
                attrs: { height: 300 },
                model: {
                  value: _vm.ruleForm.activityContentZh,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "activityContentZh", $$v)
                  },
                  expression: "ruleForm.activityContentZh",
                },
              }),
              _c("tinymce", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.lang === "en_US",
                    expression: "lang === 'en_US'",
                  },
                ],
                ref: "en_US",
                staticStyle: { width: "92%" },
                attrs: { height: 300 },
                model: {
                  value: _vm.ruleForm.activityContentEn,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "activityContentEn", $$v)
                  },
                  expression: "ruleForm.activityContentEn",
                },
              }),
              _c("tinymce", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.lang === "vi_VN",
                    expression: "lang === 'vi_VN'",
                  },
                ],
                ref: "vi_VN",
                staticStyle: { width: "92%" },
                attrs: { height: 300 },
                model: {
                  value: _vm.ruleForm.activityContentVi,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "activityContentVi", $$v)
                  },
                  expression: "ruleForm.activityContentVi",
                },
              }),
              _c("tinymce", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.lang === "gu_IN",
                    expression: "lang === 'gu_IN'",
                  },
                ],
                ref: "gu_IN",
                staticStyle: { width: "92%" },
                attrs: { height: 300 },
                model: {
                  value: _vm.ruleForm.activityContentGu,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "activityContentGu", $$v)
                  },
                  expression: "ruleForm.activityContentGu",
                },
              }),
              _c("tinymce", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.lang === "ja_JP",
                    expression: "lang === 'ja_JP'",
                  },
                ],
                ref: "ja_JP",
                staticStyle: { width: "92%" },
                attrs: { height: 300 },
                model: {
                  value: _vm.ruleForm.activityContentJa,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "activityContentJa", $$v)
                  },
                  expression: "ruleForm.activityContentJa",
                },
              }),
              _c("tinymce", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.lang === "ms_MY",
                    expression: "lang === 'ms_MY'",
                  },
                ],
                ref: "ms_MY",
                staticStyle: { width: "92%" },
                attrs: { height: 300 },
                model: {
                  value: _vm.ruleForm.activityContentMs,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "activityContentMs", $$v)
                  },
                  expression: "ruleForm.activityContentMs",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _vm.emitName !== "details"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }