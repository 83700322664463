import { render, staticRenderFns } from "./EditDialog.vue?vue&type=template&id=5c6c1fca"
import script from "./EditDialog.vue?vue&type=script&lang=js"
export * from "./EditDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.jenkins/workspace/live3-test-h5-admin-js/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c6c1fca')) {
      api.createRecord('5c6c1fca', component.options)
    } else {
      api.reload('5c6c1fca', component.options)
    }
    module.hot.accept("./EditDialog.vue?vue&type=template&id=5c6c1fca", function () {
      api.rerender('5c6c1fca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/interactive/Customer/CustomerQuestionTab/EditDialog.vue"
export default component.exports