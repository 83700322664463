import { $t } from '@/lang'

export default {
  data() {
    return {
      key: 1,
      currentRowIndex: -1
    }
  },
  props: {
    rowKey: [String, Function],
    /**
     * @description 表格标题
     */
    title: {
      default: ''
    },
    emptyText: {
      type: String,
      default: $t('暂无数据')
    },
    options: {
      type: Object,
      default: null
    },
    filterColumn: {
      type: Boolean,
      default: true
    },
    /**
     * @description 索引
     */
    indexRow: {
      type: Boolean,
      default: false
    },
    /**
     * @description 多选
     */
    selectionRow: {
      type: Boolean,
      default: false
    },
    /**
     * @description 当前列表是否可以勾选
     */
    selectable: {
      type: Function
    },

    /**
     * 默认排序
     */
    defaultSort: {
      type: Object,
      default() {
        return {}
      }
    },

    /**
     * @description 行类名
     */
    rowClassName: [String, Function],
    /**
     * @description 合计
     */
    showSummary: {
      type: Boolean,
      default: false
    },
    summaryMethod: {
      type: Function
    },
    /**
     * @description 拖拽
     */
    dragRow: {
      type: Boolean,
      default: false
    },
    spanMethod: Function,
    lazy: {
      type: Boolean,
      default: false
    },
    treeProps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    load: Function
  },
  methods: {
    /**
     * @description 勾选数据时触发的事件
     */
    handleSelect(selection, row) {
      this.$emit('select', selection, row)
    },
    /**
     * @description 勾选全选时触发的事件
     */
    handleSelectAll(selection) {
      this.$emit('select-all', selection)
    },
    /**
     * @description 复选框选择项发生变化时触发的事件
     */
    handleSelectionChange(selection) {
      this.$emit('selection-change', selection)
    },
    /**
     * @description 单元格 hover 进入时触发的事件
     */
    handleCellMouseEnter(row, column, cell, event) {
      this.$emit('cell-mouse-enter', row, column, cell, event)
    },
    /**
     * @description 单元格 hover 退出时触发的事件
     */
    handleCellMouseLeave(row, column, cell, event) {
      this.$emit('cell-mouse-leave', row, column, cell, event)
    },
    /**
     * @description 行点击时触发的事件
     */
    handleRowClick(row, event, column) {
      this.$emit('row-click', row, event, column)
    },
    /**
     * @description 表头点击时触发的事件
     */
    handleHeaderClick(column, event) {
      this.$emit('header-click', column, event)
    },
    /**
     * @description 表头右键点击时触发的事件
     */
    handleHeaderContextmenu(column, event) {
      this.$emit('header-contextmenu', column, event)
    },
    /**
     * @description 右侧刷新按钮点击事件
     */
    handleSettingRefresh() {
      this.$emit('refresh')
    },

    handleSortChange({ column, prop, order }) {
      this.$emit('sort-change', { column, prop, order })
    },

    handleColumnChange(keys) {
      this.key = this.key + 1
      this.$emit('column-change', keys)
    }
  }
}
