var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "卡券发放",
        width: "500px",
        visible: _vm.visible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("会员账号"), prop: "memberIds" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: "10",
                  placeholder: _vm.$t(
                    "数据格式：会员1,会员2, 使用逗号隔开;每次修改的数量不得超过100条"
                  ),
                },
                model: {
                  value: _vm.ruleForm.memberIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "memberIds", $$v)
                  },
                  expression: "ruleForm.memberIds",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: _vm.$t("优惠券名称") } }, [
            _vm._v(" " + _vm._s(this.ruleForm.couponName) + " "),
          ]),
          _c("el-form-item", { attrs: { label: _vm.$t("发放数量") } }, [
            _vm._v(" 1 "),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("关 闭"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v(_vm._s(_vm.$t("保 存")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }