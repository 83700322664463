<template>
  <div>
    <div class="title-box"><h3>新版娱乐城推荐活动</h3>
    <d-switch v-model="promotionSwitch" :active-value="1" :inactive-value="0" :beforeChange="handleChangeStatus">
      <span slot="open">开启</span>
      <span slot="close">关闭</span>
    </d-switch></div>
    <div class="title-box">
      <h4>推荐游戏：</h4>
      <el-select v-model="gameKey" @change="handleChangeGameKey">
        <el-option :value="undefined" label="全部" />
        <el-option v-for="(item, index) in activityGameKeyList" :key="index" :value="item.gameKey" :label="item.name" />
    </el-select>
    <el-button type="success" @click="handleAdd(gameKey)">添加</el-button>
    </div>
    
    <el-table
      ref="elTable"
      border
      :data="items"
      row-key="index"
      :drag-row="true"
      v-loading="loading"
    >
      <el-table-column type="index" align="center" width="60">
        <template #header>{{ $t("排序") }}</template>
        <svg-icon icon-class="drag" class-name="drag-handler" />
      </el-table-column>
      <el-table-column label="活动分类" prop="activityCategoryName" align="center" />
      <el-table-column label="活动标题" prop="activityName" align="center" />
      <el-table-column :label="$t('活动图片')" align="center">
        <template slot-scope="{ row }" v-if="row.activityImage">
          <el-image :src="row.activityImage" />
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="{ row }">
          {{ { 1: '启用', 0: '禁用' }[row.status] }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')" align="center" width="140">
        <template slot-scope="{ row }">
          <el-button @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0" align="right">
      <el-button
        type="success"
        :loading="submitting"
        @click="handleSubmit()"
      >{{ $t("保 存") }}</el-button>
    </div>
    <ActionDialog ref="ActionDialogContorl" @update="handleSave" />
  </div>
</template>
<script>
import { homepagePromotionSetting, editpromotionSetting, batchUpdatePromotionSortStatus, getBaseConfig, casinoPromotionSwitch } from '@/api/config'
import TableSortMixin from '@/mixins/table-sort-mixin'
import ActionDialog from './ActionDialog.vue'

export default {
  components: { ActionDialog },
  mixins: [TableSortMixin(true)],
  data() {
    return {
      openStatus: 1,
      loading: false,
      items: [],
      submitting: false,
      gameKey: undefined,
      activityGameKeyList: [
        { gameKey: 'trueman', name: '真人' },
        { gameKey: 'lottery', name: '彩票' },
        { gameKey: 'board', name: '棋牌' },
        { gameKey: 'fish', name: '捕鱼' },
        { gameKey: 'slots', name: '电子' },
        { gameKey: 'race', name: '竞技' },
        { gameKey: 'sport', name: '体育' },
        { gameKey: 'blockchain', name: '区块链' },
      ],
      promotionSwitch: 0
    }
  },
  created() {
    this.handleGetBaseConfig()
    this.getMenuList()
  },
  methods: {
    handleAdd() {
      let lenght = this.items.length
      const remainder = length % 10
      if (remainder === 0) {
        for (let i = 0; i < 10; i++) {
          this.items.push({ sort: 9999 + i, gameKey: this.gameKey, status: 1 ,index: lenght + i})
        }
      } else {
        const needAdd = 10 - remainder
        for (let i = 0; i < needAdd; i++) {
          this.items.push({ sort: 9999 + i, gameKey: this.gameKey, status: 1 ,index: lenght + i})
        }
      }
    },
    handleChangeGameKey(gameKey) {
      this.gameKey = gameKey
      this.getMenuList()
    },
    handleEdit(row) {
      const index = this.items.findIndex(item => item === row)
      row.sort = index + 1
      row.gameKey = row.gameKey || this.gameKey
      if (!row.gameKey) {
        return this.$message.error('请选择推荐游戏后，编辑添加')
      }
      this.$refs.ActionDialogContorl.open(row)
    },
    getMenuList() {
      this.loading = true
      homepagePromotionSetting({ position: 1, gameKey: this.gameKey }).then(([items, err]) => {
        if (!err) {
          this.items = []
          for(let index = 0;index < 10;index++) {
            if(items.length > index) {
              this.items.push({ ...items[index], index })
            } else {
              this.items.push({ sort: 9999, index })
            }
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    //保存多项
    handleSubmit() {
      const list = []
      this.items.forEach((e, i) => {
        if (e.id) {
          list.push({ id: e.id, sort: i + 1 })
        }
      });
      if (list.length === 0) {
        return this.$message.warning('请先至少编辑保存一条数据')
      }
      this.submitting = true
      batchUpdatePromotionSortStatus(list).then(([_, err]) => {
        if (!err) {
          this.$message.success(this.$t('保存成功'))
        }
      }).finally(() => {
        this.submitting = false
      })
    },
    //保存单项
    handleSave({ field, cancel, close }) {
      editpromotionSetting(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.getMenuList()
        } else {
          cancel()
        }
      }).finally(() => {
        this.submitting = false
      })
    },
    handleGetBaseConfig() {
      getBaseConfig().then(([data, err]) => {
        if (!err && data) {
          this.promotionSwitch = data.newPromotionActiveSwitch || 0
        }
      });
    },
    handleChangeStatus() {
      return new Promise((resolve) => {
        this.$confirm(`确认${this.promotionSwitch === 0 ? '开起' : '关闭'}新版娱乐城推荐活动？`, '系统提示', { type: 'warning' }).then(() => {
          casinoPromotionSwitch({
            promotionSwitch: ~this.promotionSwitch + 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
            }
          })
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.title-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > h3 {
    margin-right: 20px;
  }
  > button {
    margin-left: 20px;
  }
}
</style>
