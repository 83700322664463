import { login, logout, getOperatorMenuList } from '@/api/user';
import { getToken, setToken, removeToken, clearSession, getImToken, setImToken } from '@/utils/auth';
import router, { resetRouter } from '@/router';

const state = {
  token: getToken(),
  imToken: getImToken(),
  info: {},
  roles: [],
  VUE_APP_MD5_KEY: '',
  remark:''
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
    setToken(token);
  },
  SET_IM_TOKEN: (state, token) => {
    state.imToken = token;
    setImToken(token);
  },
  SET_INFO: (state, info) => {
    state.info = info;
  },
  SET_VUE_APP_MD5_KEY: (state, key) => {
    state.VUE_APP_MD5_KEY = key;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_REMARK: (state, payload) => {
    state.remark = payload;
  }
};

const actions = {
  // user login
  login ({ commit }, userInfo) {
    const { operatorName, password, googleCode } = userInfo;
    return new Promise((resolve, reject) => {
      login({ operatorName: operatorName.trim(), password, googleCode, ipAddress: '' }).then(([data, err,res]) => {
        if (err) {
          reject('Verification failed, please Login again.');
        } else {
          commit('SET_TOKEN', data);
          if(res?.data?.code==200&&res?.data?.msg){
            commit('SET_REMARK', res?.data?.msg)
          }else{
            commit('SET_REMARK', null)
          }
          resolve(data);
        }
      }).catch(error => {
        reject(error);
      });
    });
  },

  getInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      getOperatorMenuList(encodeURIComponent(state.token)).then(([data, err]) => {
        if (!err) {
          const { list, ...info } = data;
          commit('SET_INFO', info);
          commit('SET_VUE_APP_MD5_KEY', ['9BYL','495CP','58CP','AMXPJ','8160XPJ'].includes(info.tenantCode)? process.env.VUE_APP_MD5_KEY_9BYL : process.env.VUE_APP_MD5_KEY);
          commit('SET_ROLES', list);
          resolve(list);
        } else {
          reject();
        }
      }).catch(error => {
        reject(error);
      });
    });
  },

  // user logout
  logout({ commit, dispatch }) {
    return new Promise(resolve => {
      logout().then(() => {//调登出接口
      }).finally(() => {
        commit('SET_INFO', {});
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        commit('SET_ROUTES', []);
        clearSession();
        removeToken();
        resetRouter();
        window.localStorage.removeItem('CHAT_LAST_MESSAGE_ID');
        dispatch('tagsView/delAllViews', null, { root: true });
        resolve();
      })
    });
  },

  // dynamically modify permissions
  changeRoles ({ commit, dispatch }) {
    return new Promise(resolve => {
      const roles = dispatch('getInfo');

      resetRouter();

      // generate accessible routes map based on roles
      const accessRoutes = dispatch('permission/generateRoutes', roles, { root: true });

      // dynamically add accessible routes
      router.addRoutes(accessRoutes);

      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, { root: true });

      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
