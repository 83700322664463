var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "d-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "DTable",
          attrs: {
            data: _vm.items,
            "drag-row": true,
            columns: _vm.columns,
            "original-columns": _vm.defaultColumns,
            options: _vm.options,
            "row-key": "id",
          },
          on: {
            refresh: _vm.handleFetch,
            "column-change": _vm.handleColumnChange,
          },
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", plain: "", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm._batchUpdateCpad()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("全部保存")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }