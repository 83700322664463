<template>
  <el-dialog
    :title="$t('变赔')"
    width="1050px"
    top="5vh"
    :visible.sync="visible"
    :close-on-click-modal="false"
    @open="onOpen"
    @closed="onClosed"
  >
    <el-form ref="validateForm" v-loading="loading" :model="ruleFrom">
      <div class="change-odds">
        <div class="change-odds-item change-odds-header">
          <span>{{ $t('类型') }}</span>
          <span>{{ $t('属性') }}</span>
          <span>{{ $t('变赔') }}</span>
          <span>{{ $t('属性') }}</span>
          <span>{{ $t('变赔') }}</span>
          <span>{{ $t('属性') }}</span>
          <span>{{ $t('变赔') }}</span>
          <span>{{ $t('属性') }}</span>
          <span>{{ $t('变赔') }}</span>
          <span>{{ $t('属性') }}</span>
          <span>{{ $t('变赔') }}</span>
        </div>

        <div v-for="(item, name) in ruleFrom" :key="name" class="change-odds-item">
          <label class="change-odds-item__label">{{ item.label }} <template v-if="name === 'rqChange' && rq">({{ rq }})</template> 
            <template v-if="name === 'bsChange' && daxiaoqiu.name">({{ daxiaoqiu.name }})</template>
          </label>
          <div class="change-odds-item__content">
            <div v-for="(child, index) in item.children" :key="index" class="item">
              <template v-if="child">
                <div class="label">
                  <p class="player">{{ child.label }}</p>
                  <span class="odd">{{ child.origin }}</span>
                </div>
                <div class="input">
                  <el-form-item
                    :prop="name + '.children.' + index + '.value'"
                    :show-message="false"
                    :rules="{ pattern: /^([\-]?(\d*))([.]\d{0,2})?$/, message: `${child.label} ${$t('玩法平赔率 格式不合法')}`, trigger: 'blur' }"
                  >
                    <el-input v-model="child.value" :disabled="child.disabled" />
                  </el-form-item>
                </div>
              </template>
              <template v-else>
                <div class="label" />
                <div class="input" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import optionsObject from './options-object'
import { getMatchBaseOdds } from '@/api/lottery'

function _getChildren(children, odds ,childValues) {
  return children.map((o,i) => {
    return o && {
      value: childValues?.[i]||0,
      label: o.label,
      disabled: !odds[o.index],
      origin: odds[o.index] || ''
    } || null
  })
}

export default {
  mixins: [VDialogMixin],
  data: () => ({
    loading: false,
    submitting: false,
    rq: '',
    ruleFrom: { ...optionsObject },
    dateForm: {},
    daxiaoqiu:{
      name:'',
    }
  }),
  methods: {
    open({ matchId, tenantCode }) {
      this.dateForm.matchId = matchId
      if (this.$P$) this.dateForm.tenantCode = tenantCode
      this.visible = true
    },
    onOpen() {
      this.loading = true
      getMatchBaseOdds({ ...this.dateForm }).then(([data, err]) => {
//         data={
//   "matchId": "4071979",
//   "originSpf": "4.70,4.00,1.48",
//   "originRq": "+1,2.19,3.10,2.78",
//   "originJq": "19.00,7.05,4.40,3.45,4.50,7.25,12.50,19.00",
//   "originBf": "17.00,28.00,13.50,80.00,33.00,31.00,250.0,120.0,120.0,650.0,450.0,450.0,100.0,19.00,8.25,14.00,50.00,250.0,8.75,8.75,7.00,13.00,11.00,20.00,24.00,21.00,30.00,55.00,42.00,60.00,27.00",
//   "originBqc": "8.00,16.00,22.00,11.00,7.50,4.25,36.00,16.00,2.38",
//   "originBs": "2.5,3.78,2.21",
//   "spfChange": "1.22,1.33",
//   "rqChange": "1.22,1.33",
//   "jqChange": "1.22,1.33",
//   "bfChange": "1.22,1.33",
//   "bqcChange": "1.22,1.33",
//   "bsChange": null
// }
        if (!err) {
          for (let key in optionsObject) {
            const item = optionsObject[key]
            const odds = data[item.name] && data[item.name].split(',') || []
            let childValues;
            if (item.name === 'originRq') {
              this.rq = odds[0]
            }
            if (item.name === 'originBs') {
              this.daxiaoqiu.name = odds[0]
              childValues =  data[key] && data?.[key].split(',') || []
            }
            this.ruleFrom[key] = {
              label: item.label,
              name: item.name,
              children: _getChildren(item.children, odds ,childValues)
            }
          }
          console.log('%c@@@@optionsObject','background: gray; color: white; font-size: 16px;',optionsObject);
        }

        this.loading = false
      })
    },
    onClosed() {
      this.submitting = false
      this.dateForm = {}
      this.ruleFrom = { ...optionsObject }
    },
    handleSubmit() {
      this.$refs.validateForm.validate(valid => {
        if (valid) {
          this._doSubmit()
        } else {
          this.$message.error(this.$t('赔率格式不合法!'))
          return false
        }
      })
    },

    _doSubmit() {
      let field = { ...this.dateForm }
      let changeContent = []

      for (let key in this.ruleFrom) {
        const tmp = this.ruleFrom[key].children.filter(Boolean)
        const tmpValues = tmp.map(o => o.value)

        tmp.forEach(o => {
          if (parseFloat(o.value)) {
            changeContent.push(o)
          }
        })

        field[key] = tmpValues.every(o => parseFloat(o) === 0) ? null : tmpValues.join(',')
      }

      if (changeContent.length === 0) {
        this.$message.error(this.$t('暂未更新赔率'))
        return
      }

      field.changeContent = changeContent.map(o => `${o.label}@${o.value}`).join(',')

      this.submitting = true
      this.$emit('update', {
        field,
        cancel: () => {
          this.submitting = false
        },
        close: this.close
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.change-odds {
  border: 1px solid #ddd;
  border-right: 0;
  border-bottom: 0;
  &-item {
    display: flex;
    align-items: stretch;
    &__content {
      flex: 1;
      & > .item {
        width: 20%;
        float: left;
        border-bottom: 1px solid #ddd;
        & > div {
          padding: 5px 8px;
          width: 50%;
          height: 42px;
          float: left;
          text-align: center;
          border-right: 1px solid #ddd;
          &.label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .player {
              color: #333;
            }
            .odd {
              color: #aaa;
              font-size: 12px;
              margin-top: 5px;
            }
          }
          & >>> .el-form-item {
            margin-bottom: 0;
            .el-input__inner {
              padding: 0 8px;
              text-align: center;
            }
          }
        }
      }
    }
    &__label {
      flex: 0 1 125px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.change-odds-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-align: center;
      line-height: 50px;
      border-bottom: 1px solid #ddd;
      & > span {
        flex: 1;
        border-right: 1px solid #ddd;
        &:first-child {
          flex: 0 1 125px;
        }
      }
    }
  }
}
</style>
