<template>
  <div class="app-container">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane v-for="tab of tabs" :key="tab.name" :label="tab.label" :name="tab.name" />
    </el-tabs>
    <component :is="activeName" />
  </div>
</template>
<script>
import { parentMixin } from '@/mixins/tab-items'
import IconTab from './IconTab'
import LevelTab from './level'
import RewardTab from './RewardTab'

export default {
  name: 'vipRewardMange_index',
  components: { IconTab, LevelTab, RewardTab },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      activeName: 'LevelTab',
      permissionTabs: [],
      tabs: [
        { label: this.$t('VIP等级管理'), name: 'LevelTab' },
        { label: this.$t('VIP福利管理'), name: 'RewardTab' },
        { label: this.$t('VIp福利图标管理'), name: 'IconTab' }
      ]
    }
  },
  created() {
    this.activeName = this.$route.query.tab || 'LevelTab'
  }
}
</script>
