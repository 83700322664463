<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">{{ $t('新增提现方式') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="handleCreateActivity" @update="handleUpdateActivity" />
  </div>
</template>
<script>
import { activityStatus } from '@/libs/options'
import TablePageMixin from '@/mixins/table-page-mixin'
import { queryWithdrawTypeConf, createWithdrawTypeConf, upWithdrawTypeConf } from '@/api/withdraw'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { payTypesAbpay } from '@/libs/options'
export default {
  name: 'PayTypeConf',
  components: { ActionDialog },
  mixins: [TablePageMixin(queryWithdrawTypeConf)],
  data() {
    return {
      search: {},
      filterColumn: false,
      payTypesAbpay,
      activityStatus,
      columns: GetColumns.call(this),
      activityCategory: this.$t('activityCategory')
    }
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogControl.open()
    },
    handleCreateActivity({ field, cancel, close }) {
      createWithdrawTypeConf({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('创建成功!'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    handleUpdateActivity({ field, cancel, close }) {
      upWithdrawTypeConf({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('修改成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
 
    handleDeleteActivity(row) {
      this.$confirm(this.$t('确定删除?'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        row.status = 1
        upWithdrawTypeConf({ ...row }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },

    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
