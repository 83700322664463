<template>
  <el-dialog :title="title" :visible.sync="visible" width="460px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="160px" label-suffix=":">
      <!-- <el-form-item v-if="emitName === 'create'" label="代理ID" prop="agencyId">
        <el-input v-model="ruleForm.agencyId" style="width: 240px" />
      </el-form-item> -->

      <el-form-item :label="$t('国际化key')" prop="i18nKey">
        <el-input v-if="emitName=='create'" v-model="ruleForm.i18nKey" style="width: 240px" />
        <span v-if="emitName=='update'" style="width: 240px">{{ ruleForm.i18nKey }}</span>
      </el-form-item>
      <el-form-item :label="$t('国际化value')" prop="i18nValue">
        <el-input v-model="ruleForm.i18nValue" style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('国际化对应语言')" prop="lang">
        <el-input v-model="ruleForm.lang" style="width: 240px" />
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
// import { stringCE3_12 } from '@/utils/validation.js'
const getDefaultForm = () => ({
  i18nKey: '',
  i18nValue: '',
  lang: ''
})
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        i18nKey: [
          { required: true, message: '国际化key', trigger: 'blur' }

        ],
        i18nValue: [
          { required: true, message: '国际化value', trigger: 'blur' }
        ],
        lang: [
          { required: true, message: '国际化对应语言', trigger: 'blur' }
        ]

      }
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? '添加国际化语言' : '编辑国际化语言'
    }
  },
  methods: {
    open(row, tenantCode) {
      this.ruleForm.tenantCode = tenantCode
      if (row) {
        Object.assign(this.ruleForm, row)
        this.emitName = 'update'
      } else {
        this.emitName = 'create'
      }
      this.visible = true
    }
  }
}
</script>
