var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-page container" }, [
    _c(
      "div",
      { staticClass: "chat-btn" },
      [
        _vm.permissionTabs.ScheduledTasksTab
          ? _c(
              "el-button",
              {
                attrs: { type: "primary", size: "medium" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/Interactive_ScheduledTasks")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("定时消息管理")))]
            )
          : _vm._e(),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "medium" },
            on: {
              click: function ($event) {
                return _vm.$router.push("/Interactive_Notice")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("群公告管理")))]
        ),
        _c(
          "el-button",
          {
            staticClass: "filter-btn",
            attrs: { size: "medium" },
            on: { click: _vm._handleOpenSensitive },
          },
          [_vm._v(_vm._s(_vm.$t("敏感词过滤")))]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "medium" },
            on: { click: _vm.toLiveBehaviorRestriction },
          },
          [_vm._v(_vm._s(_vm.$t("行为限制管理")))]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "room-card" }, [
      _c(
        "div",
        { staticClass: "room-card-head" },
        [
          _c(
            "el-select",
            {
              staticClass: "room-type",
              on: { change: _vm._getRoomList },
              model: {
                value: _vm.roomType,
                callback: function ($$v) {
                  _vm.roomType = $$v
                },
                expression: "roomType",
              },
            },
            _vm._l(_vm.roomTypes, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-input",
            {
              staticClass: "room-search",
              attrs: {
                placeholder: _vm.$t("请输入", { text: _vm.$t("聊天室名称") }),
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm._filterRoomList($event)
                },
              },
              model: {
                value: _vm.roomName,
                callback: function ($$v) {
                  _vm.roomName = $$v
                },
                expression: "roomName",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "append" },
                  on: { click: _vm._filterRoomList },
                  slot: "append",
                },
                [_vm._v(_vm._s(_vm.$t("查找")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.roomLoading,
              expression: "roomLoading",
            },
          ],
          ref: "scroller",
          staticClass: "room-card-body",
        },
        [
          _c(
            "div",
            { staticClass: "card-body__inner" },
            _vm._l(_vm.showRoomList, function (item) {
              return _c("chat-room", {
                key: item.id,
                attrs: {
                  item: item,
                  "active-room-id": _vm.activeRoomId,
                  "can-edit": _vm.roomType === 1,
                },
                on: {
                  enterRoom: _vm._enterRoom,
                  clickIcon: _vm._handleUpdateRoom,
                },
              })
            }),
            1
          ),
        ]
      ),
      _vm.roomType === 1
        ? _c(
            "div",
            { staticClass: "room-card-footer" },
            [
              _c("el-button", { on: { click: _vm._handleCreateRoom } }, [
                _vm._v(" " + _vm._s(_vm.$t("新增")) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "card" },
      [
        _c(
          "div",
          { staticClass: "card-head" },
          [
            _c("el-button", { on: { click: _vm._clear } }, [
              _vm._v(_vm._s(_vm.$t("清屏"))),
            ]),
            _c("div", [
              _vm._v(
                _vm._s(_vm.$t("真实人数")) + ": " + _vm._s(_vm.roomRealCount)
              ),
            ]),
            _c(
              "div",
              [
                _vm._v(" " + _vm._s(_vm.$t("追加人数")) + ": "),
                _c("el-input", {
                  staticStyle: { width: "80px", "margin-right": "10px" },
                  attrs: { type: "number" },
                  model: {
                    value: _vm.VirtualCount,
                    callback: function ($$v) {
                      _vm.VirtualCount = _vm._n($$v)
                    },
                    expression: "VirtualCount",
                  },
                }),
                _c(
                  "el-button",
                  { attrs: { type: "success" }, on: { click: _vm._setCount } },
                  [_vm._v(_vm._s(_vm.$t("确认")))]
                ),
              ],
              1
            ),
            _c(
              "el-input",
              {
                staticClass: "search-content",
                attrs: {
                  placeholder: _vm.$t("请输入", { text: _vm.$t("聊天室内容") }),
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm._filterMsgContent($event)
                  },
                },
                model: {
                  value: _vm.searchContent,
                  callback: function ($$v) {
                    _vm.searchContent = $$v
                  },
                  expression: "searchContent",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { slot: "append" },
                    on: { click: _vm._filterMsgContent },
                    slot: "append",
                  },
                  [_vm._v(_vm._s(_vm.$t("查找")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { ref: "chatScroller", staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "card-body__inner" },
            _vm._l(_vm.filterChatList, function (item) {
              return _c(
                "chat-item",
                {
                  key: item.serverMsgId,
                  attrs: {
                    item: item,
                    "content-type": item.contentType,
                    me: _vm.operatorId === item.from,
                  },
                  on: { "on-action": _vm._handleActionItem },
                },
                [_vm._v(_vm._s(item.content))]
              )
            }),
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "card-footer" },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "top-start",
                  width: "350",
                  trigger: "click",
                },
                model: {
                  value: _vm.emoShow,
                  callback: function ($$v) {
                    _vm.emoShow = $$v
                  },
                  expression: "emoShow",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "emo-pop" },
                  _vm._l(_vm.emojiArr, function (emoItem) {
                    return _c(
                      "div",
                      {
                        key: emoItem,
                        staticClass: "emoItem",
                        on: {
                          click: function ($event) {
                            return _vm.addEmo(emoItem)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(emoItem) + " ")]
                    )
                  }),
                  0
                ),
                _c("img", {
                  staticClass: "emo-img",
                  attrs: {
                    slot: "reference",
                    src: require("@/assets/public_images/" +
                      (_vm.emoShow ? "icon-chat-keyboard" : "icon-chat-emoji") +
                      ".png"),
                  },
                  slot: "reference",
                }),
              ]
            ),
            _c("el-input", {
              attrs: {
                placeholder: _vm.$t("输入信息"),
                type: "textarea",
                autosize: { minRows: 1, maxRows: 10 },
              },
              model: {
                value: _vm.content,
                callback: function ($$v) {
                  _vm.content = $$v
                },
                expression: "content",
              },
            }),
            _c("i", {
              staticClass: "el-icon-s-promotion",
              staticStyle: { color: "#e91e63" },
              on: { click: _vm._sendNormalMessage },
            }),
          ],
          1
        ),
        _c("chat-disable", {
          ref: "ChatDisableControl",
          on: { submit: _vm._handleMute },
        }),
        _c("ActionChatRoomDialog", {
          ref: "ActionChatRoomDialogControl",
          on: {
            deleteRoom: _vm._deleteRoom,
            update: _vm._updateRoom,
            create: _vm._createRoom,
          },
        }),
        _c("SensitiveDialog", {
          ref: "SensitiveDialogControl",
          on: { submit: _vm._submitSensitive },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }