<template>
  <div class="top-tab" style="width: 800px;">
    <h3>{{ $t("热门推荐") }}</h3>
    <div style="display: flex; justify-content: space-between; margin: 10px 0; align-items: center;">
      <h5 style="margin: 0;">
        {{ $t("热门游戏排序") }}：<el-select v-model="selType" @change="getSetting">
          <el-option v-for="item in typeList" :key="item.key" :value="+item.key" :label="item.lable" />
        </el-select>
      </h5>
      <div>
        <el-button type="success" :loading="submitting" @click="addItems">{{ $t("添 加") }}</el-button>
        <el-button type="success" :loading="submitting" @click="handleSubmit">{{ $t("保 存") }}</el-button>
      </div>
    </div>
    <el-table ref="elTable" border :data="items" row-key="rowKey" :drag-row="true" v-loading="loading">
      <el-table-column type="index" align="center" width="60">
        <template #header>{{ $t("排序") }}</template>
        <svg-icon icon-class="drag" class-name="drag-handler" />
      </el-table-column>
      <el-table-column :label="$t('彩种/厂商选择')" align="center">
        <template slot-scope="{ row, $index }">
          <el-select
            v-model="row.seriesTag"
            style="width: 100%"
            clearable
            filterable
            @change="(value) => onChangeSeries(value, $index)"
          >
            <el-option
              v-for="series in cpSeriesList"
              :key="series.seriesTag"
              :value="series.seriesTag"
              :label="series.seriesName"
            />
          </el-select>
        </template>
      </el-table-column>

      <el-table-column :label="$t('游戏选择')" align="center">
        <template slot-scope="{ row, $index }">
          <el-select
            v-model="row.lotteryId"
            style="width: 100%"
            clearable
            filterable
            :placeholder="$t('请选择游戏')"
            @change="(value) => onChangeLottery(value, $index)"
          >
            <el-option
              v-for="lottery in row.lotteryList"
              :key="lottery.lotteryId"
              :value="lottery.lotteryId"
              :label="lottery.name"
              :disabled="checkPass(row.seriesTag, lottery.lotteryId)"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column :label="$t('图标')" align="center" width="140" v-if="selType === 1">
        <template slot-scope="{ row }">
          <d-upload
            v-model="row.hotIcon"
            folder="icon"
            :on-success="(...args) => handleUploadSuccess(args, row)"
            :width="86"
            :height="86"
          />
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')" align="center" width="140" v-if="selType === 1">
        <template slot-scope="{ row }">
          <el-button @click="row.seriesTag = null;row.lotteryId = null;row.hotIcon = null">重置</el-button>
        </template>
      </el-table-column>

      <!-- <el-table-column align="center">
        <template slot-scope="{ row }">
          <div flex="align:center">

            <el-input v-model="row.content"  :placeholder="$t('首页气泡文字')" />
              </div>
        </template>
      </el-table-column> -->
    </el-table>
    <div v-if="items.length>20" style="padding: 10px 0" align="right">
      <el-button type="success" :loading="submitting" @click="addItems">{{ $t("添 加") }}</el-button>
      <el-button type="success" :loading="submitting" @click="handleSubmit">{{ $t("保 存") }}</el-button>
    </div>
  </div>
</template>
<script>
import {
  getLotteryNavigationHot,
  getLotterySettingList,
  updateLotterySettingList,
  updateSettingByType
} from '@/api/config'
import DUpload from '@/components/DUpload'
import TableSortMixin from '@/mixins/table-sort-mixin'

function getLotteryList(seriesList, seriesTag) {
  const tmp = seriesList.find((o) => o.seriesTag === seriesTag)
  return (tmp && tmp.lotteryList) || []
}

export default {
  name: 'CasinoTab',
  mixins: [TableSortMixin(true)],
  components: { DUpload },
  data() {
    return {
      submitting: false,
      loading: false,
      items: [],
      seriesList: [],
      selType: 1,
      typeList: this.$t('displayTypeList')
    }
  },
  computed: {
    cpSeriesList(n, o) {
      return this.selType == 6
        ? this.seriesList.filter((p) => +p.seriesTag < 100)
        : this.seriesList
    }
  },
  created() {
    this.onFetch()
  },

  methods: {
    onFetch() {
      getLotteryNavigationHot().then(([data, err]) => {
        if (!err) {
          this.seriesList = data || []
          this.getSetting()
        }
      })
    },

    checkPass(seriesTag, lotteryId) {
      return (
        this.items.findIndex(
          (o) => o.seriesTag === seriesTag && o.lotteryId === lotteryId
        ) > -1
      )
    },

    onChangeSeries(seriesTag, $index) {
      const item = this.items[$index]
      const lotteryList = getLotteryList(this.seriesList, seriesTag)
      this.$nextTick(() => {
        this.$set(this.items, $index, {
          ...item,
          seriesTag,
          lotteryId: null,
          lotteryList
        })
      })
    },

    onChangeLottery(lotteryId, $index) {
      const item = this.items[$index]
      this.$set(this.items, $index, { ...item, lotteryId })
    },

    // onChangeStatus(status, $index) {
    //   if (status === 1) {
    //     this.items.forEach((item, i) => {
    //       if ($index !== i) {
    //         item.status = 0
    //       }
    //     })
    //   }
    // },
    addItems() {
      this.items.push(
        ...Array.from({ length: 10 }).map((o, i) => ({
          sort: this.items.length + i + 1,
          seriesTag: '',
          lotteryId: null,
          status: 0,
          content: null,
          lotteryList: []
        }))
      )
    },

    getSetting() {
      this.loading = true
      getLotterySettingList({ type: this.selType }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          data = data || []
          this.items = data.map((o, i) => ({
            ...o,
            rowKey: i,
            lotteryList: getLotteryList(this.seriesList, o.seriesTag)
          }))
        }
      })
    },

    isDisabled(lotteryId) {
      return this.items.includes((o) => o.lotteryId === lotteryId)
    },

    async handleSubmit() {
      const items = this.items.filter((o) => o.seriesTag && o.lotteryId)
      if (items.length == 0) {
        const res = await updateSettingByType({ type: this.selType })
        if (res[2].data.success) {
          this.$message.success(this.$t('保存成功'))
        } else {
          this.$message.error(this.$t('保存失败'))
        }
        return
      }
      if (this.selType === 1 && items.some(e => !e.hotIcon)) {
        this.$message.error(this.$t('请上传图标'))
        return
      }
      // if (items.length === 0) {
      //   this.$message.error(this.$t('请至少选择一个热门游戏'))
      //   return
      // }

      const dataList = items.map((o, i) => ({
        id: o.id,
        sort: i + 1,
        seriesTag: o.seriesTag,
        lotteryId: o.lotteryId,
        type: this.selType,
        content: o.content,
        hotIcon: o.hotIcon,
        status: 1
      }))
      updateLotterySettingList(dataList).then(([_, err]) => {
        this.submitting = false
        if (!err) {
          this.$message.success(this.$t('保存成功'))
        }
      })
    },
    handleUploadSuccess([[data]], row) {
      row.hotIcon = data
    }
  }
}
</script>

<style lang="scss">
.el-select.is-error .el-input__inner {
  border-color: #f56c6c;
}
.upload-wrapper {
  justify-content: center;
}
</style>
