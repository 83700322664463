<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="600px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="180px" label-suffix=":">
      <el-form-item v-for="item of ruleForm.typeNameList" :key="item.lang" :label="$t(item.name) + $t('分类名称')">
        <el-input v-model="item.typeName" style="width: 90%" :placeholder="$t('请输入分类名称')" />
      </el-form-item>
      <el-form-item :label="$t('分类排序')" prop="sort">
        <el-input v-model="ruleForm.sort" style="width: 90%" :placeholder="$t('请输入分类排序')" />
      </el-form-item>
      <el-form-item label="logo" prop="icon">
        <el-upload
          class="banner-uploader"
          accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
          :action="action"
          :data="{ type: 'image', folder: 'activity' }"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleIconSuccess"
        >
          <img v-if="ruleForm.icon" :src="ruleForm.icon" class="banner">
          <div v-else class="banner-uploader-txt">
            <p>{{ $t('文件大小控制在100K以内') }}</p>
            <button class="el-button el-button--small el-button--orange" @click.self.prevent>{{ $t('点击上传') }}</button>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="动画json" prop="animation">
        <el-upload
          class="banner-uploader"
          accept="text/javascript,application/javascript,application/json"
          :action="action"
          :data="{ type: 'image', folder: 'activity' }"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleAnimationSuccess"
        >
          <div class="banner-uploader-txt">
            <p v-if="ruleForm.animation">{{ ruleForm.animation.split('/')[ruleForm.animation.split('/').length - 1] }}</p>
            <p>{{ $t('请上传.js、.json文件') }}</p>
            <button class="el-button el-button--small el-button--orange" @click.self.prevent>{{ $t('点击上传') }}</button>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button v-if="emitName !== 'details'" type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { mapState } from 'vuex'
const getDefaultForm = () => ({
  id: null,
  sort: '',
  typeNameList: [],
  icon: '',
  animation: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: '',
      rules: {
        typeName: [{ required: true, message: this.$t('请输入分类名称'), trigger: 'blur' }],
        sort: [{ required: true, message: this.$t('请输入分类排序'), trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState('app', ['allLang']),

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    title() {
      return this.emitName === 'create' ? this.$t('新增分类') : this.$t('修改分类')
    }
  },
  methods: {
    open(row) {
      const _this = this
      _this.emitName = 'create'
      _this.ruleForm.typeNameList = _this.allLang.map(e => { return { ...e, typeName: '' } })
      if (row) {
        _this.ruleForm.id = row.id
        const typeNameList = row.typeName.split(',')
        typeNameList.forEach(e => {
          const lang = e.split(':')[0]
          const typeName = e.split(':')[1]
          const formLang = _this.ruleForm.typeNameList.find(r => r.lang === lang)
          if (formLang) {
            formLang.typeName = typeName
          }
        })

        _this.ruleForm.sort = row.sort
        _this.ruleForm.icon = row.icon
        _this.ruleForm.animation = row.animation
        _this.emitName = 'update'
      }
      _this.visible = true
    },
    handleIconSuccess(res) {
      this.ruleForm.icon = res.data[0]
    },
    handleAnimationSuccess(res) {
      this.ruleForm.animation = res.data[0]
    }
  }
}
</script>

<style lang="scss">
.banner-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.border_ {
  padding: 5px 10px;
  box-sizing: border-box;
}

.banner-uploader .el-upload:hover {
  border-color: #409EFF;
}

.banner-uploader-txt {
  font-size: 14px;
  color: #8c939d;
  width: 300px;
  height: 128px;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.banner {
  width: 300px;
  height: 128px;
  display: block;
}
</style>
