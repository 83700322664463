<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.memberName" clearable :placeholder="$t('平台会员账号')" class="filter-item" style="width: 200px" />
      <el-input v-model="search.thirdMemberName" clearable :placeholder="$t('三方会员账号')" class="filter-item" style="width: 200px" />
      <el-select v-model="search.status" clearable :placeholder="$t('状态')" class="filter-item" style="width: 150px">
        <el-option :label="value" :value="key" v-for="(value, key) in tryPreStatus" :key="key" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" :loading="loading" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <span style="color: red;">***待重试和重试中订单正在执行请勿手动处理，失败状态订单请确认会员信息后进行手动补偿。</span>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>
<script>
import { getXffailrecordList } from '@/api/cash'
import TablePageMixin from '@/mixins/table-page-mixin'
import { tryPreStatus } from '@/libs/options'
import GetColumns from './columns'
export default {
  name: 'CashMemberSubtract',
  mixins: [TablePageMixin(getXffailrecordList)],
  data() {
    return {
      columns: [],
      defaultColumns: GetColumns.call(this), 
      tryPreStatus
    }
  },
  methods: {
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
