var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "评论",
        visible: _vm.visible,
        width: "700px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.handleRefresh,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: { model: _vm.ruleForm, rules: _vm.rules },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { data: _vm.items, border: "", size: "small" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "70", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "标题", align: "center" },
              }),
            ],
            1
          ),
          _c("pagination", {
            staticStyle: { padding: "0", margin: "20px -5px" },
            attrs: {
              layout: "prev, pager, next",
              page: _vm.params.currentPage,
              limit: _vm.params.pageSize,
              total: _vm.total,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.params, "currentPage", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.params, "pageSize", $event)
              },
              pagination: _vm.handleFetch,
            },
          }),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { required: "", prop: "commentNickname" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "输入评论昵称" },
                model: {
                  value: _vm.ruleForm.commentNickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "commentNickname", $$v)
                  },
                  expression: "ruleForm.commentNickname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "content" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "输入评论内容",
                  type: "textarea",
                  rows: 4,
                },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 取消 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 发送 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }