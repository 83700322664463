var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "50%",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            disabled: _vm.emitName === "details",
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("商品名称"), prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "310px" },
                attrs: {
                  maxlength: "20",
                  placeholder: _vm.$t("限20字以内"),
                  clearable: "",
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("商品标题"), prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "500px" },
                attrs: {
                  maxlength: "50",
                  placeholder: _vm.$t("限50字以内"),
                  clearable: "",
                },
                model: {
                  value: _vm.ruleForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("商品类目") } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "310px" },
                  attrs: {
                    placeholder: _vm.$t("请输入类目名称查询"),
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    "collapse-tags": "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                  model: {
                    value: _vm.ruleForm.categoryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "categoryId", $$v)
                    },
                    expression: "ruleForm.categoryId",
                  },
                },
                _vm._l(_vm.category, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.categoryName },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("商品单价"), prop: "price" } },
            [
              _c("el-input", {
                staticStyle: { width: "277px", "margin-right": "7px" },
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("请填写商品单价"),
                  maxlength: "8",
                  clearable: "",
                },
                model: {
                  value: _vm.ruleForm.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "price", $$v)
                  },
                  expression: "ruleForm.price",
                },
              }),
              _vm._v("积分 "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("可用库存") } },
            [
              _c(
                "el-radio-group",
                {
                  directives: [
                    { name: "removeAriaHidden", rawName: "v-removeAriaHidden" },
                  ],
                  model: {
                    value: _vm.stockRadio,
                    callback: function ($$v) {
                      _vm.stockRadio = $$v
                    },
                    expression: "stockRadio",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: -1 } }, [
                    _vm._v(_vm._s(_vm.$t("不限制"))),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("限制"))),
                  ]),
                ],
                1
              ),
              _vm.stockRadio !== -1
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "stock" } },
                    [
                      _c("el-input", {
                        staticClass: "stock",
                        attrs: {
                          type: "number",
                          placeholder: _vm.$t("可用库存数量"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.ruleForm.stock,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "stock", $$v)
                          },
                          expression: "ruleForm.stock",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("商品类型") } },
            [
              _c(
                "el-radio-group",
                {
                  directives: [
                    { name: "removeAriaHidden", rawName: "v-removeAriaHidden" },
                  ],
                  model: {
                    value: _vm.ruleForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "type", $$v)
                    },
                    expression: "ruleForm.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v(_vm._s(_vm.$t("虚拟商品"))),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("实物商品"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("排序"), prop: "sort" } },
            [
              _c("el-input", {
                staticStyle: { width: "310px" },
                attrs: {
                  type: "number",
                  placeholder: _vm.$t("请输入1-999999的整数，由大到小"),
                  clearable: "",
                },
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sort", $$v)
                  },
                  expression: "ruleForm.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("商品封面"), prop: "cover" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "cover-uploader",
                  attrs: {
                    accept:
                      "image/jpeg,image/png,image/jpg,image/gif,image/svg,image/webp",
                    action: _vm.action,
                    data: { type: "image", folder: "ad-photo" },
                    headers: _vm.$headers(),
                    "show-file-list": false,
                    "on-success": _vm.handleCoverSuccess,
                    "before-upload": _vm.beforeAvatarUpload,
                  },
                },
                [
                  _vm.ruleForm.cover
                    ? _c("img", {
                        staticClass: "cover",
                        attrs: { src: _vm.ruleForm.cover },
                      })
                    : _c("div", { staticClass: "cover-uploader-txt" }, [
                        _c("p", [
                          _vm._v(
                            "提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。"
                          ),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass:
                              "el-button el-button--small el-button--orange",
                            on: {
                              click: function ($event) {
                                if ($event.target !== $event.currentTarget) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                          },
                          [_vm._v("点击上传")]
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("商品介绍"), prop: "introduction" } },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    action: _vm.action,
                    "file-list": _vm.ruleForm.introduction,
                    headers: _vm.$headers(),
                    data: { type: "image", folder: "ad-photo" },
                    "on-success": _vm.handleUploadSuccess,
                    "before-upload": _vm.beforeImgUpload,
                    "on-remove": _vm.onImgRemove,
                    "on-change": _vm.imgOnChange,
                    "on-exceed": _vm.onExceed,
                    limit: 8,
                    "list-type": "picture-card",
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("点击上传")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _vm._v(
                        " 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。支持多张，最多8张 "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("商品详情"), prop: "detail" } },
            [
              _c("Tinymce", {
                ref: "detail",
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.detail,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "detail", $$v)
                  },
                  expression: "ruleForm.detail",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("商品状态") } },
            [
              _c(
                "el-radio-group",
                {
                  directives: [
                    { name: "removeAriaHidden", rawName: "v-removeAriaHidden" },
                  ],
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("上架"))),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("下架"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _vm.emitName !== "details"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }