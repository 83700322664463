<template>
  <div class="app-container">
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <div slot="left" class="filter-container">
        <el-date-picker
          v-model="times"
          clearable
          type="daterange"
          class="filter-item"
          style="width: 400px;"
          :range-separator="$t('至')"
          :start-placeholder="$t('开始日期')"
          :end-placeholder="$t('结束日期')"
          value-format="yyyy-MM-dd"
        />
        <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleRefresh">{{ $t('查询') }}</el-button>
      </div>
      <div slot="right">{{ $t('当前已打款金额') }}：<span class="danger-type">{{ paymentAmount }}</span></div>
    </d-table>

    <ActionDialog ref="ActionDialogControl" />

  </div>

</template>

<script>
import { getLiveFamilyWithdrawInfo, getPaidTotalAmount } from '@/api/live'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
export default {
  name: 'LiveFamilyOrderHistory',
  components: { ActionDialog },
  mixins: [TablePageMixin(getLiveFamilyWithdrawInfo, false)],
  data() {
    return {
      times: [],
      search: {},
      filterColumn: false,
      columns: GetColumns.call(this),
      paymentAmount: null
    }
  },
  created() {
    this.search.familyOwerAccno = this.$route.query.id
    this.handleFetch()
  },
  methods: {
    implementationGetParams() {
      const [settlementStartTime, settlementEndTime] = this.times || []
      return { ...this.search, settlementStartTime, settlementEndTime }
    },
    implementationFetched() {
      getPaidTotalAmount({ ...this.implementationGetParams() }).then(([data, err]) => {
        if (!err) {
          this.paymentAmount = data
        }
      })
    }
  }
}
</script>
