const CanChooseColumns = function () {
  return [
    {
      title: "助手名称",
      key: "name",
      align: "center",
      width: 100,
      component: {
        render: (h, row) => {
          return <span>{row.name}</span>;
        },
      },
    },
    {
      title: "用户昵称",
      key: "nickname",
      align: "center",
      width: 100,
      component: {
        render: (h, row) => {
          return <span>{row.nickname}</span>;
        },
      },
    },
    {
      title: "用户头像",
      key: "avatar",
      align: "center",
      width: 160,
      component: {
        render: (h, row) => {
          return (
            <el-image
              fix="cover"
              src={row.avatar}
              style="width: 40px; height: 40px; border-radius: 50%"
            />
          );
        },
      },
    },
    {
      title: "会员等级",
      key: "vipLevel",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>vip{row.vipLevel}</span>;
        },
      },
    },
    {
      title: "聊天室类型",
      key: "roomTypeNames",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span>{row.roomTypeNames ? row.roomTypeNames.join(",") : ""}</span>
          );
        },
      },
    },
    {
      title: "聊天室",
      key: "roomNames",
      align: "center",
      component: {
        render: (h, row) => {
          let dom = []
          if (Array.isArray(row.roomNames)) {
            row.roomNames.forEach((v, i) => {
              let d = {}
              let s = v
              if (v.indexOf('*') > -1) {
                let _s = v.split('_')
                s = _s[0]
                d.style = 'color:#3894FF;cursor:pointer;'
                d.on = {
                  click: () => {
                    this.$refs.ShowDialogControl.open(row, _s[0], _s[1]);
                  }
                }
              }
              if (i < row.roomNames.length - 1) {
                s = `${s}，`
              }
              dom.push(h('span', d, s))
            })
          }
          return h('div', dom)
        },
      },
    },
    {
      title: "活跃时段",
      key: "activePeriod",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>{row.activePeriod}</span>;
        },
      },
    },

    {
      title: "形象描述",
      key: "imageDescription",
      align: "center",
      width: 100,
    },
    {
      title: this.$t("状态"),
      key: "status",
      align: "center",
      width: 100,
      component: {
        render: (h, row) => {
          return <div>{row.status ? "启用" : "禁用"}</div>;
        },
      },
    },
    {
      title: "创建时间",
      key: "createTime",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.createTime)}</span>;
        },
      },
    },
    {
      title: "操作人",
      key: "updateUser",
      align: "center",
      width: 100,
    },
    {
      title: "操作",
      align: "center",
      key: "actions",
      width: 310,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission;

          return (
            <div>
              {Update && (
                <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-edit"
                  onClick={() => {
                    this.$refs.EditDialogControl.open(row);
                  }}
                >
                  {this.$t("编辑")}
                </el-button>
              )}
              {Delete && (
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  onClick={() => this.handleDelete(row)}
                >
                  {this.$t("删除")}
                </el-button>
              )}

              {
                <el-button
                  type="primary"
                  size="small"
                  onClick={() => {
                    this.$refs.RoomDialogControl.open(row);
                  }}
                >
                  {this.$t("聊天室管理")}
                </el-button>
              }
            </div>
          );
        },
      },
    },
  ];
};

export default function () {
  return CanChooseColumns.call(this);
}
