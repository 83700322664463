var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("comMenu", {
        attrs: {
          title: "娱乐版-更多按钮配置",
          "menu-name": "更多按钮配置",
          "menu-list": _vm.menuList,
          num: 17,
          "home-type": "entertainment",
          position: 3,
        },
      }),
      _c("comMenu", {
        attrs: {
          title: "体育版-更多按钮配置",
          "menu-name": "更多按钮配置",
          "menu-list": _vm.menuList,
          num: 17,
          "home-type": "sport",
          position: 3,
        },
      }),
      _c("comMenu", {
        attrs: {
          title: "六合版-更多按钮配置",
          "menu-name": "更多按钮配置",
          "menu-list": _vm.menuList,
          num: 17,
          "home-type": "liuhe",
          position: 3,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }