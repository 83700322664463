import { $t } from '@/lang'

// 广告图展示位置
export const positionOptions = [
  { label: $t('首页推荐页'), value: 1 },
  { label: $t('首页直播页'), value: 2 },
  { label: $t('个人中心'), value: 3 },
  { label: $t('游戏中心'), value: 4 },
  { label: $t('心水推荐'), value: 5 },
  { label: $t('六合图库顶部'), value: 6 },
  { label: $t('六合图库中部'), value: 7 },
  { label: $t('心水推荐广告链接'), value: 8 },
  { label: $t('六合图库广告链接'), value: 9 },
  { label: '首页悬浮', value: 10 },
  { label: '心水悬浮', value: 11 },
  { label: '心水详情悬浮', value: 12 },
  { label: '聊天室悬浮', value: 13 },
  { label: '推单专家榜', value: 14 },
  { label: '六合版-首页推荐页', value: 15 },
  { label: '六合版-首页广告流', value: 16 },
  { label: '六合版-心水主页Banner', value: 17 },
  { label: '六合版-推荐广告流-网址大全-新澳门', value: 18 },
  { label: '六合版-推荐广告流-网址大全-香港', value: 19 },
  { label: '六合版-推荐广告流-担保平台', value: 20 },
  { label: '新版-首页推荐', value: 21 },
  { label: '新版-滚球Banner', value: 22 },
  { label: '积分商城-首页Banner', value: 23 },
  { label: '体讯Banner', value: 24 }
]

// 终端 1 展示 2 不展示
export const terminalList = [
  { label: $t('安卓'), attributeName: 'androidStatus' },
  { label: 'H5', attributeName: 'h5Status' },
  { label: 'IOS', attributeName: 'iosStatus' }
]

export function getTerminalVal(application, type) { // type 1文字 2属性
  let data1 = {}
  let data2 = []
  for (const i in terminalList) {
    let a = terminalList[i]
    if (type === 1) { // 根据终端名称提取参数及对应值
      if (application.includes(a['label'])) {
        data1[a['attributeName']] = 2// 展示
      } else {
        data1[a['attributeName']] = 1// 不展示
      }
    } else if (type === 2) { // 根据属性及属性值展示终端
      if (application[a['attributeName']] === 2) {
        data2.push(a['label'])
      }
    }
  }
  return type === 1 ? data1 : data2
}

export function _getparams(field) {
  const { dateTime: [startDate, endDate], displayTerminal, appPageId, lang, ...other } = field
  let params = { ...other, startDate, endDate }

  for (const item of terminalList) {
    if (displayTerminal.includes(item.attributeName)) {
      params[item.attributeName] = 2
    } else {
      params[item.attributeName] = 1
    }
    params.lang = lang.toString()
  }

  return params
}
