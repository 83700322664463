import { filterColumns } from '@/utils'
import dayjs from 'dayjs'
const CanChooseColumns = function() {
  return [
    {
      title: '名称',
      key: 'domainName',
      align: 'center'
    },
    // {
    //   title: '域名分组',
    //   key: 'domainType',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return <span>{row.domainType === 1 ? '9B落地页' : '所有'}</span>
    //     }
    //   }
    // },
    {
      title: '二维码生产所需链接',
      key: 'domainUrl',
      align: 'center'
    },

    {
      title: '创建人',
      key: 'createUser',
      align: 'center'
    },
    {
      title: '创建时间',
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{dayjs(row.createTime).format('YYYY-MM-DD')}</span>
        }
      }
    },
    {
      title: '状态',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <el-switch value={row.status == 1} active-text='启用' inactive-text='停用' onChange={ () => this.switchStatus(row) }></el-switch>
            </div>
          )
        }
      }
    },
    {
      title: '操作',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <el-button type='danger' size='mini' icon='el-icon-delete' onClick={ () => this._delete(row) }>{this.$t('删除')}</el-button>
            </div>
          )
        }
      }
    }

  ]
}
export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
