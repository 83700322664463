import { filterColumns } from '@/utils'

// 代理业绩 和 返佣金额 是前缀one + 前缀two字段 其他是前缀one字段
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('代理账号'),
      key: 'memberName',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (<p class='primary-type cursor-pointer hover-underline' onClick={() => this.$refs.UserDialogControl.open(row.memberId)}>{row.memberName}</p>)
        }
      }
    },
    {
      title: this.$t('代理ID'),
      key: 'memberId',
      align: 'center'
    },
    {
      title: this.$t('代理等级'),
      key: 'agencyLevel',
      align: 'center'
    },
    {
      title: this.$t('直属返佣比例'),
      key: 'oneLevelAgencyRate',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.oneLevelAgencyRate) {
            return (<span>{row.oneLevelAgencyRate}%</span>)
          }
          return ''
        }
      }
    },
    {
      title: this.$t('二层返佣比例'),
      key: 'twoLevelAgencyRate',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.twoLevelAgencyRate) {
            return (<span>{row.twoLevelAgencyRate}%</span>)
          }
          return ''
        }
      }
    },
    {
      title: this.$t('直属业绩'),
      key: 'oneLevelAchievements',
      align: 'center'
    },
    {
      title: this.$t('二层业绩'),
      key: 'twoLevelAchievements',
      align: 'center'
    },
    {
      title: this.$t('代理返佣'),
      key: 'rebateAmount',
      align: 'center',
      sortable: true,
      sortMethod: function(a, b) {
        return parseFloat(a.rebateAmount) - parseFloat(b.rebateAmount)
      },
      component: {
        render: (h, row) => {
          return (<span class='danger-type'>{row.rebateAmount}</span>)
        }
      }
    },
    {
      title: this.$t('结算时间'),
      key: 'settlementTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.settlementTime)}</span>
        }
      }
    },
    {
      title: this.$t('审核状态'),
      key: 'settlementStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{['未结算支付', '已结算支付', '拒绝结算'][row.settlementStatus]}</span>
        }
      }
    },
    // {
    //   title: '状态',
    //   key: 'auditStatus',
    //   align: 'center',
    //   width: 180,
    //   component: {
    //     render: (h, row) => {
    //       if (row.auditStatus === 1) {
    //         return (
    //           this.permission.Audit &&
    //           <span>
    //             <el-button type='primary' size='mini' onClick={() => this._handlePass(row, true)}>通过</el-button>
    //             <el-button type='danger' size='mini' onClick={() => this._handleReject(row, false)}>拒绝</el-button>
    //           </span>
    //         )
    //       } else if (row.auditStatus === 2) {
    //         return <span>已审核</span>
    //       } else {
    //         return <span style='color: red'>拒绝原因: { row.remark }</span>
    //       }
    //     }
    //   }
    // },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 170,
      component: {
        render: (h, row) => {
          return (
            <span>
              <el-button type='text' onClick={() => this.goDetail(row)}>{this.$t('查看详情')}</el-button>
              {row.settlementStatus === 0 && <el-button type='text' onClick={() => { this.currentRow = row }}>{this.$t('审核')}</el-button>}
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
