<template>
  <div>
    <div class="filter-container">
      <div class="filter-container-item">
        <el-input v-model="search.title" class="filter-item" placeholder="请输入问题标题" style="width: 160px" />
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置')
          }}</el-button>
        <el-button v-if="permission.Add" icon="el-icon-plus" type="success" class="filter-item"
          @click="$refs.EditDialogControl.open()">添加问题</el-button>
      </div>
    </div>

    <d-table ref="DTable" v-loading="loading" :data="items" :columns="columns" :options="options"
      :filter-column="filterColumn">
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total"
      @pagination="handleFetch" />

    <EditDialog ref="EditDialogControl" @update="handleUpdate" @create="handleCreate" />

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import EditDialog from './EditDialog'
import {
  getQuestionPageList,
  updateQuestionPageList,
  addQuestionPageList,
  delQuestionPageList
} from '@/api/interactive'

export default {
  name: 'CustomerQuestionTab',
  componentName: 'CustomerQuestionTab',
  components: { EditDialog },
  mixins: [TablePageMixin(getQuestionPageList)],
  data() {
    return {
      filterColumn: false,
      search: {
        title: ''
      },
      columns: GetColumns.call(this),
      selection: []
    }
  },
  methods: {
    handleDelete({ id }) { 
      this.$confirm(`${this.$t('确认删除?')}`, { type: 'warning' }).then(() => {
        const params = { id }
        delQuestionPageList(params).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    handleCreate({ field, cancel, close }) {
      field.sort = Number(field.sort);
      addQuestionPageList({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success('添加成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    handleUpdate({ field, cancel, close }) {
      field.sort = Number(field.sort);
      updateQuestionPageList({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success('编辑成功')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    }
  }
}
</script>
