<template>
  <el-dialog :title="$t('修改上级代理')" width="500px" :visible.sync="visible" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('当前账号')">
        <el-input v-model="diabsledFields.memberName" disabled />
      </el-form-item>
      <el-form-item :label="$t('旧代理')">
        <el-input v-model="diabsledFields.agencyMemberName" :placeholder="$t('无')" disabled />
      </el-form-item>
      <el-form-item :label="$t('新代理')" prop="newAgent">
        <el-input v-model="ruleForm.newAgent" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  newAgent: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      diabsledFields: {},
      rules: {
        newAgent: [
          { required: true, message: this.$t('请输入新代理'), trriger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open({ memberId, memberName, agencyMemberName }) {
      this.ruleForm.memberId = memberId
      this.diabsledFields = { memberName, agencyMemberName }
      this.visible = true
    }
  }
}
</script>
