<template>
  <el-dialog
    title="数据管理"
    :visible.sync="visible"
    width="90%"
    top="2vh"
    :close-on-click-modal="false"
  >
    <div
      class="app-container"
    >
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of tabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive v-if="refresh">
        <component
          :is="activeName"
          :channel-ids="channelIds"
          :category-ids="categoryIds"
          :category-union-id="categoryUnionId"
          ref="dataRef"
          style="height: 70vh;overflow-y: auto;"
        />
      </keep-alive>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('提交') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import LiveManagementTab from './management'
import LivePreTab from './pre'
import { liveManagementBatchEditRoom, livePlanManagementBatchEditRoom } from '@/api/live'

export default {
  components: { LiveManagementTab, LivePreTab },
  data() {
    return {
      visible: false,
      activeName: 'LiveManagementTab',
      tabs: [
        { label: '直播间管理', name: 'LiveManagementTab' },
        { label: '预开播管理', name: 'LivePreTab' }
      ],
      submitting: false,
      channelIds: [],
      channelUnionId: '',
      categoryUnionId: '',
      categoryIds: [],
      refresh: true
    }
  },
  methods: {
    open(row) {
      this.activeName = 'LiveManagementTab'
      this.refresh = false
      setTimeout(() => {
        this.refresh = true
      }, 100);
      this.channelIds = [row.channelId]
      this.channelUnionId = row.channelUnionId
      this.categoryUnionId = row.unionId
      this.categoryIds = row.items.map(e => e.categoryId)
      this.visible = true
    },
    handleSubmit() {
      const items = this.$refs.dataRef.checkedItems
      if (items && items.length > 0) {
        this.submitting = true
        let service = liveManagementBatchEditRoom
        if (this.activeName === 'LivePreTab') {
          service = livePlanManagementBatchEditRoom
        }
        service({ channelUnionId: this.channelUnionId, categoryUnionId: this.categoryUnionId, items }).then(([_, err]) => {
          if (!err) {
            this.$message.success('提交成功！')
            this.visible = false
          }
        }).finally(() => {
          this.submitting = false
        })
      } else {
        this.$message.success('未检测到有数据勾选状态发生变更，请检查！')
      }
    }
  }
}
</script>

