var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("频道分类"), clearable: "" },
            model: {
              value: _vm.search.categoryName,
              callback: function ($$v) {
                _vm.$set(
                  _vm.search,
                  "categoryName",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "search.categoryName",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                filterable: "",
                clearable: "",
                "collapse-tags": "",
                placeholder: "所属频道",
              },
              model: {
                value: _vm.search.channelUnionId,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "channelUnionId", $$v)
                },
                expression: "search.channelUnionId",
              },
            },
            _vm._l(_vm.channelList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleSearch },
            },
            [_vm._v(_vm._s(_vm.$t("查询")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-refresh-right" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("重置")))]
          ),
          _vm.permission.Add
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { float: "right" },
                  attrs: { type: "success", icon: "el-icon-plus" },
                  on: { click: _vm.onCreate },
                },
                [_vm._v(_vm._s(_vm.$t("新增分类")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          options: _vm.options,
          "filter-column": _vm.filterColumn,
        },
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("ActionDialog", {
        ref: "ActionDialogControl",
        on: {
          create: _vm.handleSaveChannelType,
          update: _vm.handleSaveChannelType,
        },
      }),
      _c("DataManageDialog", { ref: "DataManageDialogControl" }),
      _c(
        "el-dialog",
        {
          staticStyle: { "font-size": "16px" },
          attrs: { width: "400px", visible: _vm.deleteConfirm },
        },
        [
          _c("span", [
            _c("i", {
              staticClass: "el-icon-error",
              staticStyle: { color: "#F04134", "margin-right": "5px" },
            }),
            _vm._v("确定删除频道分类吗？"),
          ]),
          _c(
            "p",
            {
              staticStyle: {
                color: "rgba(217, 0, 27, 0.647058823529412)",
                "margin-top": "20px",
              },
            },
            [_vm._v("tips：删除分类后，当前分类视频只会在频道列表展示。")]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteConfirm = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }