import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('时间'),
      key: 'theDay',
      align: 'center'
    },
    {
      title: this.$t('游戏类型'),
      key: 'seriesType',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.seriesTypes[row.seriesType] }</span>)
        }
      }
    },
    {
      title: this.$t('游戏系列'),
      key: 'seriesPlayType',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.seriesPlayTypes[row.seriesPlayType] }</span>)
        }
      }
    },
    {
      title: this.$t('投注人数'),
      key: 'totalNumber',
      align: 'center',
      type: 'amount'
    },
    {
      title: '有效投注',
      key: 'betAmount',
      align: 'center',
      type: 'amount'
    },
    {
      title: '派奖',
      key: 'winAmount',
      align: 'center',
      type: 'amount'
    },
    {
      title: '盈亏',
      key: 'profitAmount',
      align: 'center',
      type: 'amount'
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
