<script>
import { mapGetters } from 'vuex'

export default {
  name: "MenuItem",
  functional: true,
  computed: {
    ...mapGetters([      
      'sidebar'
    ]),
  },  
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    unReadNum: {
      type: Number,
      default: 0,
    },
  },
  render(h, context) {
    const { icon, title, unReadNum } = context.props;
    const isOpen = context.parent.$store.state.app.sidebar.opened;
    const vnodes = [];
    if (icon) {
      if (title == "系统首页" && !isOpen) {
        // 侧边栏收起时显示图标
        vnodes.push(
          <svg-icon icon-class={icon} style="position: relative;left: 16px;" />
        );
      } else {
        vnodes.push(<svg-icon icon-class={icon} />);
      }
    } 
    
    if (title) {
      if (title !== "系统首页" || (isOpen && title == "系统首页")) {
        vnodes.push(<span>{title}</span>);
      }
      if (unReadNum && (title == "交互管理" || title == "客服管理")) {
        vnodes.push(
          <span style="margin-left:5px;background:red;border-radius:50%;width:22px;height:22px;display:inline-block;text-align:center;line-height:22px;font-size:12px">
            {unReadNum > 99 ? "99+" : unReadNum}
          </span>
        );
      }
    }
    return vnodes;
  },
};
</script>
