var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "400px" },
            attrs: {
              clearable: "",
              type: "daterange",
              "range-separator": _vm.$t("至"),
              "start-placeholder": _vm.$t("开始日期"),
              "end-placeholder": _vm.$t("结束日期"),
              "value-format": "yyyy-MM-dd",
              "picker-options": _vm.pickerOptions,
            },
            model: {
              value: _vm.times,
              callback: function ($$v) {
                _vm.times = $$v
              },
              expression: "times",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetDate($event, 0)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("今天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetDate($event, 1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("昨天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetDate($event, 7)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("_7天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetDate($event, 30)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("本月")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleSearch },
            },
            [_vm._v(_vm._s(_vm.$t("查询")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-refresh-right" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("重置")))]
          ),
          _vm.permission.Export
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.excelLoading,
                    icon: "el-icon-download",
                  },
                  on: { click: _vm._handleExport },
                },
                [_vm._v(_vm._s(_vm.$t("导出")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.resources.length,
              expression: "resources.length",
            },
          ],
          attrs: { data: _vm.resources, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "time", align: "center", label: "时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(_vm._s(row.startTime) + "~" + _vm._s(row.endTime)),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "registerNumber",
              align: "center",
              label: "注册人数",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "firstNumber", align: "center", label: "首存人数" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "totalDepositNumber",
              align: "center",
              label: "总存款人数",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "totalDepositAmount",
              align: "center",
              label: "总存款金额",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "totalNumber", align: "center", label: "投注人数" },
          }),
          _c("el-table-column", {
            attrs: { prop: "betAmount", align: "center", label: "有效投注" },
          }),
          _c("el-table-column", {
            attrs: { prop: "winAmount", align: "center", label: "派奖" },
          }),
          _c("el-table-column", {
            attrs: { prop: "profitAmount", align: "center", label: "盈亏" },
          }),
        ],
        1
      ),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          options: _vm.options,
          "filter-column": false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }