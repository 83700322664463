<template>
  <el-dialog title="第三方加减款" :visible.sync="visible" width="500px" top="5vh" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="租户">
        <el-select v-model="ruleForm.tenantCode">
          <el-option v-for="item in tenants" :key="item.tenantCode" :value="item.tenantCode" :label="item.tenantName" />
        </el-select>
      </el-form-item>
      <el-form-item label="第三方游戏" prop="thirdGameTag">
        <el-select v-model="ruleForm.thirdGameTag" @change="_getAmount">
          <el-option v-for="(value, tag) in $parent.customizeColumus" :key="tag" :value="tag" :label="value.label" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.tenantCode && ruleForm.thirdGameTag" label="余额">{{ amount }}</el-form-item>
      <el-form-item label="操作类型" prop="type">
        <el-radio-group v-model="ruleForm.type">
          <el-radio label="0">加款</el-radio>
          <el-radio label="1">扣款</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="操作金额" prop="amount">
        <el-input v-model="ruleForm.amount" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  tenantCode: '',
  thirdGameTag: '',
  type: '0',
  amount: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      amount: null,
      rules: {
        tenantCode: [{ required: true, message: '请选择租户', trigger: 'change' }],
        thirdGameTag: [{ required: true, message: '请选择第三方游戏', trigger: 'change' }],
        amount: [
          { required: true, message: '请输入操作金额', trigger: 'blur' },
          { pattern: /^\+?[1-9][0-9]*$/, message: '请输入正整数', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState('app', ['tenants'])
  },
  methods: {
    _getAmount(tag) {
      if (this.ruleForm.tenantCode) {
        const item = this.$parent.items.find(o => o.tenantCode === this.ruleForm.tenantCode)
        const list = item && item.list || []
        const tmp = list.find(o => o.tag === tag)
        this.amount = tmp && tmp.amount || 0
      }
    },
    implementationClosed() {
      this.amount = null
    }
  }
}
</script>
