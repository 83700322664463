<template>
  <div>
    <el-card shadow="never" :body-style="{ padding: 0, margin: '-1px' }">
      <template slot="header">
        <span>{{ $t('彩票统计') }}</span>
        <date-filter :value="params.timeType" :loading="loading" @input="_input" @refresh="_get" />
      </template>

      <el-table v-loading="loading" :data="items" size="medium" border show-summary :summary-method="getSummaries">
        <el-table-column prop="name" :label="$t('彩种')" align="center" />
        <el-table-column prop="betsAmount" :label="$t('投注')" align="center" />
        <el-table-column prop="winAmount" :label="$t('派奖')" align="center" />
        <el-table-column prop="earnAmount" :label="$t('盈亏')" align="center" />
      </el-table>
    </el-card>
  </div>
</template>
<script>
import DateFilter from '../../components/DateFilter'
import { getHomeLottery } from '@/api/dashboard'
export default {
  components: { DateFilter },
  data() {
    return {
      loading: false,
      items: [],
      params: {
        timeType: 'today'
      },
      statistics: {
        betSum: { key: 'betsAmount', value: null },
        winSum: { key: 'winAmount', value: null },
        earnSum: { key: 'earnAmount', value: null }
      }
    }
  },
  created() {
    this._get()
  },
  methods: {
    _get() {
      this.loading = true
      getHomeLottery(this.params.timeType).then(([data, err, response]) => {
        this.loading = false
        if (!err) {
          this.items = data || []
          this.implementationFetched(response.data)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    implementationFetched(data) {
      for (const key in data) {
        if (key in this.statistics) {
          this.statistics[key].value = data[key]
        }
      }
    },
    _input(value) {
      this.params.timeType = value
      this._get()
    },
    getSummaries(param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('总计')
          return
        }

        for (const key in this.statistics) {
          if (this.statistics[key].key === column.property) {
            sums[index] = this.statistics[key].value
            break
          } else {
            sums[index] = ''
          }
        }
      })

      return sums
    }
  }
}
</script>
