import { getLiveRoomList } from '@/api/chat'
import { getThirdMatchList, getPredictionMatchList } from '@/api/match'
import { getLotterySettingList, getLotteryNavigationHot } from '@/api/config'
import { parseTime } from '@/utils'
export default {
  methods: {
    async _getChatRoomList(params) {
      if (params.roomType === 2) {
        let startTime = new Date()
        let endTime = new Date()
        // startTime = startTime.setDate(startTime.getDate() + 1)
        // endTime = endTime.setDate(endTime.getDate() + 1)
        startTime = startTime.setHours(startTime.getHours() - 4)
        endTime = endTime.setHours(endTime.getHours() + 4)
        const matchListData = {
          // startMatchTime: parseTime(startTime, '{y}-{m}-{d} 00:00:00'),
          // endMatchTime: parseTime(endTime, '{y}-{m}-{d} 23:00:00'),
          startMatchTime: parseTime(startTime, "{y}-{m}-{d} {h}:00:00"),
          endMatchTime: parseTime(endTime, "{y}-{m}-{d} {h}:00:00"),
          currentPage: 1,
          pageSize: 2000,
          tenantCode: this.$store.getters.tenantCode
        }
        const [data, err] = await getThirdMatchList(matchListData)
        if (!err) {
          const roomList = data.map((p) => ({
            roomId: p.thirdMatchId,
            roomName: p.homeTeamNameZh + " vs " + p.awayTeamNameZh,
            roomIcon: "",
            roomInfo: p.competitionNameZh,
            roomType: params.roomType,
            roomNumber: 0
          }))
          return roomList
        }
      } else if (params.roomType === 5) {
        let startTime = new Date()
        let endTime = new Date()
        startTime = startTime.setDate(startTime.getDate() - 3)
        endTime = endTime.setDate(endTime.getDate() + 3)
        const matchListData = {
          startTime: parseTime(startTime, "{y}-{m}-{d} {h}:00:00"),
          endTime: parseTime(endTime, "{y}-{m}-{d} {h}:00:00"),
          currentPage: 1,
          pageSize: 2000,
          tenantCode: this.$store.getters.tenantCode
        }
        const [data, err] = await getPredictionMatchList(matchListData)
        if (!err) {
          const roomList = data.map((p) => ({
            roomId: p.matchId,
            roomName: p.homeTeamNameZh + " vs " + p.awayTeamNameZh,
            roomIcon: "",
            roomInfo: p.nameZh,
            roomType: params.roomType,
            roomNumber: 0
          }))
          return roomList
        }
      } else if (params.roomType === 3) {
        let seriesList = (await getLotteryNavigationHot())[0]
          ?.filter((p) => +p.seriesTag < 100)
          .flatMap((p) => p.lotteryList)
        let res = await getLotterySettingList({ type: 6 })
        if (!res[0]) return []
        const roomList = res[0].map((p) => ({
          roomId: p.lotteryId,
          roomName: seriesList.find((s) => s.lotteryId == p.lotteryId)?.name,
          roomIcon: p.icon,
          roomInfo: p.content,
          roomType: params.roomType,
          roomNumber: 0
        }))
        return roomList
      } else {
        const [data, err] = await getLiveRoomList(params)
        if (!err) {
          return data
        }
      }

      return undefined
    }
  }
}
