const CanChooseColumns = function () {
  return [
    {
      title: this.$t("平台用户账号"),
      key: "memberName",
      align: "center",
    },
    {
      title: this.$t("三方用户账号"),
      key: "uname",
      align: "center",
    },
    {
      title: this.$t("注单号"),
      key: "orderNo",
      align: "center",
    },
    // {
    //   title: "赛事",
    //   key: "gameName",
    //   align: "center",
    //   hide:!this.isSports
    // },
    // {
    //   title: "玩法",
    //   key: "playName",
    //   align: "center",
    //   hide:!this.isSports
    // },
    {
      title: '游戏名称',
      key: "gameName",
      align: "center",
    },
    {
      title: '场次',
      key: "playName",
      align: "center",
    },
    {
      title: this.$t("投注"),
      key: "betContent",
      align: "center",
    },
    {
      title: this.$t("结果"),
      key: "betResult",
      align: "center",
      component: {
        render: (h, row) => {
          switch (row.betResult) {
            case "000":
              return (
                <span
                  onClick={() => this.checkDetails(row)}
                  class="betResultLink"
                >
                  输
                </span>
              );
            case "001":
              return (
                <span
                  onClick={() => this.checkDetails(row)}
                  class="betResultLink"
                >
                  赢
                </span>
              );
            case "002":
              return (
                <span
                  onClick={() => this.checkDetails(row)}
                  class="betResultLink"
                >
                  打和
                </span>
              );
            default:
              return <span>{row.betResult}</span>;
          }
        },
      },
    },
    {
      title: this.$t("投注额度"),
      key: "allbet",
      align: "center",
    },
    {
      title: this.$t("有效投注额度"),
      key: "bet",
      align: "center",
    },
    {
      title: this.$t("中奖金额"),
      key: "winAmount",
      align: "center",
    },
    {
      title: this.$t("输赢"),
      key: "profit",
      align: "center",
    },
    {
      title: this.$t("状态"),
      key: "orderStatus",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>{this.orderStatus[row.orderStatus]}</span>;
        },
      },
    },
    
    {
      title: this.$t("推单订单"),
      key: "orderNo1",
      align: "center",
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <div>
              <div>{row.isPush == 1 ? "是" : row.isPush == 0 ? "否" : ""}</div>
              {row.isPush==1 && <div>{row.orderNo}</div>}
            </div>
          );
        },
      },
    },
    {
      title: this.$t("时间"),
      key: "betTime",
      align: "center",
      width: 240,
      component: {
        render: (h, row) => {
          return (
            <span>
              <p>
                投注时间：{row.betTime ? this.$parseTime(row.betTime) : "-"}
              </p>
              <p>
                更新时间：
                {row.updateTime ? this.$parseTime(row.updateTime) : "-"}
              </p>
              <p>
                结算时间：
                {row.settleTime ? this.$parseTime(row.settleTime) : "-"}
              </p>
            </span>
          );
        },
      },
    },
    // {
    //   title: '操作',
    //   key: 'actions',
    //   fixed: 'right',
    //   align: 'center',
    //   width: 150,
    //   component: {
    //     render: (h, row) => {
    //       const { Details } = this.permission
    //       return (
    //         <div>
    //           { <el-button type='text' size='mini' onClick={() => this.checkDetails(row)}>查看详情</el-button> }
    //         </div>
    //       )
    //     }
    //   }
    // }
  ];
};

export default function () {
  return CanChooseColumns.call(this);
}
