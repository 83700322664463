<template>
  <div class="detailsPage">
    <div class="titleLine">
      <span>订单详情</span>
      <el-button type="primary" @click="$emit('close')">返回</el-button>
    </div>
    <hr />
    <h4>订单状态:{{ rowData.orderStatus }}</h4>
    <div class="contentSec">
      <div>推单订单号:{{ rowData.orderNo || "---" }}</div>
      <div>游戏类型:{{ rowData.computed_smallPlayType || "---" }}</div>
      <div>发单时间:{{ $parseTime(rowData.createTime) || "---" }}</div>
      <div>会员账号:{{ rowData.memberName || "---" }}</div>
      <div>会员昵称:{{ rowData.nickname || "---" }}</div>
      <div>账号类型:{{ rowData.memberType || "---" }}</div>
      <div>自购金额:{{ rowData.selfAmount || "---" }}</div>
      <div>{{ computed_wanfa }}:{{ rowData.betInformation || "---" }}</div>
      <div>
        抽佣:{{
          rowData.bonusRateGuru ? rowData.bonusRateGuru * 100 + "%" : "---"
        }}
      </div>

      <!-- <div>跟单金额:{{ rowData.followerAmount || "---" }}</div>
      <div>中奖金额:{{ rowData.hitAmount || "---" }}</div>
      <div>自购盈亏:{{ rowData.selfOrderProfit || "---" }}</div> -->
      <!-- <div>自购盈利率:{{ rowData.planSpeech || "---" }}</div> -->
    </div>
    <div>
      方案宣言:
      <div v-html="rowData.planSpeech"></div>
    </div>
    <h5>方案详情</h5>
    <hr />
    <el-table
      :data="planDetails"
      border
      style="width: 80%"
      v-if="rowData.smallPlayType == 40 || rowData.smallPlayType == 41"
      class="textAlignCenter"
    >
      <el-table-column label="玩法" align="center">
        <template slot-scope="scope">
          <!-- <div>{{ scope.row.playName }}－{{ scope.row.playClassName }}</div> -->
          <!-- <div>第{{ scope.row.issue }}期</div> -->
          <div>{{ scope.row.betsContent }}</div>
        </template>
      </el-table-column>
      <el-table-column label="投注" align="center">
        <template slot-scope="scope">
          <div>
            @{{ scope.row.odds }}//<span class="xiazhu"
              >下注{{ scope.row.amount }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="输赢" align="center">
        <template slot-scope="scope">
          <div>
            {{ $t("orderStatus")[scope.row.status] }}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-table
      :data="planDetails"
      border
      style="width: 80%"
      v-if="rowData.smallPlayType == 99"
      class="textAlignCenter"
    >
      <el-table-column label="场次/对阵">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.competitionName }}</span
            >&nbsp
            <span>
              周{{
                $parseTime(
                  scope.row.matchTime +
                    new Date().getTimezoneOffset() * 60 * 1000,
                  "{a}"
                )
              }}{{ scope.row.matchNumber.substring(1) }}
            </span>
          </div>
          <div>
            <span
              >{{ scope.row.homeTeamName }}VS{{ scope.row.awayTeamName }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="投注" prop="betContent">
        <template slot-scope="scope">
          <div
            v-for="(betContentItem, ii) in scope.row.betContent.split(',')"
            :key="ii"
          >
            {{ betFormat(betContentItem, scope.row.rqCount) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="赛果">
        <template slot-scope="scope">
          <div>{{ parseScore(scope.row.betResult) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="输赢">
        <template slot-scope="scope">
          <div>
            {{ $t("orderStatus")[scope.row.status] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="planDetails"
      border
      style="width: 80%"
      v-if="rowData.smallPlayType == 1502"
      class="textAlignCenter"
    >
      <el-table-column label="场次">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.sportName }}</div>
            <div>
              周{{
                $parseTime(
                  scope.row.beginTime +
                    new Date().getTimezoneOffset() * 60 * 1000,
                  "{a}"
                )
              }}
            </div>
            <div>({{ $parseTime(scope.row.beginTime, "{m}-{d}") }})</div>

            <div>{{ scope.row.matchName }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="对阵">
        <template slot-scope="scope">
          <div>{{ scope.row.matchInfo.split("v")[0] }}</div>
          <div>VS</div>
          <div>{{ scope.row.matchInfo.split("v")[1] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="投注">
        <template slot-scope="scope">
          <div class="">{{ scope.row.playName }}</div>
          &nbsp; <span class="">{{ scope.row.playOptionName }}</span
          >&nbsp;&nbsp;
          <span class="">@{{ scope.row.oddFinally }}</span>
        </template>
      </el-table-column>
    </el-table>
    <hr />
    <h4>订单结算</h4>
    <div class="contentSec">
      <div>中奖金额:¥{{ rowData.hitAmount || "---" }}</div>
      <div>自购盈亏:¥{{ rowData.selfOrderProfit || "---" }}</div>
      <div>跟单数量:{{ rowData.followOrderNum || "---" }}</div>
      <div>跟单金额:¥{{ rowData.followAmount || "---" }}</div>
      <div>跟单中奖:¥{{ rowData.followHitAmount || "---" }}</div>
      <div>跟单盈亏:¥{{ rowData.followOrderProfit || "---" }}</div>
      <div>跟单佣金:¥{{ rowData.guruCommission || "---" }}</div>
      <div>平台抽佣:¥{{ rowData.platformCommission || "---" }}</div>
      <div class="isred">
        结算金额:¥{{
          Number(rowData.selfOrderProfit) +
            Number(rowData.guruCommission) -
            Number(rowData.platformCommission) || "---"
        }}
      </div>
      <div>截止时间:{{ $parseTime(rowData.endTime) || "---" }}</div>
      <div>结算时间:{{ $parseTime(rowData.settlementTime) || "---" }}</div>
    </div>
    <hr />
    <h4>跟单详情</h4>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns2"
      :options="null"
      :filter-column="null"
    >
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />
  </div>
</template>
<script>
import {
  getGuruOrderLotteryDetail,
  getGuruOrderFootballDetail,
  getGamePmTyDetail,
  getOrderSimpleList,
} from "@/api/orderManage";
import TablePageMixin from "@/mixins/table-page-mixin";
import GetColumns2 from "./columns2";
import { parseScore, betFormat } from "@/utils";
export default {
  name: "",
  components: {},
  props: {
    rowData: {},
  },
  mixins: [TablePageMixin(getOrderSimpleList)],
  data() {
    return {
      parseScore,
      betFormat,
      apiData: {},
      planDetails: [],
      columns2: GetColumns2.call(this),
      isDataFetched: false,
    };
  },
  computed: {
    computed_wanfa() {
      if (
        this.rowData.smallPlayType == 40 ||
        this.rowData.smallPlayType == 41
      ) {
        return "期号";
      } else {
        return "玩法";
      }
    },
  },
  methods: {
    implementationGetParams() {
      return { planId: this.rowData.planId };
    },
    //彩票详情
    getLotteryDetails() {
      const { orderNo, planId } = this.rowData;
      getGuruOrderLotteryDetail({
        orderNo,
        planId,
      }).then(([data, err]) => {
        if (!err) {
          this.planDetails = data;
        }
      });
    },
    //竞彩足球详情
    getJCZQdetails() {
      const { orderNo, planId } = this.rowData;
      getGuruOrderFootballDetail({ orderNo, planId }).then(([data, err]) => {
        if (!err) {
          this.planDetails = data.recordList;
        }
      });
    },
    //第三方注单详情
    getThirdDetails() {
      const { orderNo, orderNoList, createTime } = this.rowData;
      getGamePmTyDetail({ orderNoList, createTime }).then(([items, err]) => {
        if (!err) {
          let initArr = [];
          items.forEach((item) => {
            let betRecordList = JSON.parse(item.betRecordList);
            if (betRecordList && betRecordList.length > 0) {
              initArr = [...initArr, ...betRecordList];
            }
          });
          this.planDetails = initArr;
          // this.planDetails = items;
          // this.planDetails = JSON.parse(items[0].betRecordList);
        }
      });
    },
    async getFollowOrderDetails() {
      // const { planId } = this.rowData;
      // getOrderSimpleList({ planId }).then(([data, err]) => {
      //   if (!err) {
      //   }
      // });
      await this.handleFetch();
      this.isDataFetched = true;
    },
  },
  mounted() {
    const { smallPlayType } = this.rowData;
    if (smallPlayType == 40 || smallPlayType == 41) {
      this.getLotteryDetails();
    } else if (smallPlayType == 99) {
      this.getJCZQdetails();
    } else if (smallPlayType == 1502) {
      this.getThirdDetails();
    }
    // this.getFollowOrderDetails();
  },
};
</script>

<style lang="scss" scoped>
.detailsPage {
  position: absolute;
  width: 98%;
  height: 99vh;
  overflow: auto;
  background-color: #fff;
  top: 0;
  z-index: 999;
  border-bottom: 10px solid #eee;
  .titleLine {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 0 5px;
    span {
      font-size: 22px;
      font-weight: 600;
    }
  }
}
.contentSec {
  display: flex;
  flex-wrap: wrap;
  color: #555;
  > div {
    width: 33%;
    margin-bottom: 10px;
  }
}
</style>
