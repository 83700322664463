var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "240px" },
    },
    [
      _vm._l(_vm.ruleForm.rateTypes, function (item, index) {
        return _c(
          "el-row",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            key: index,
            staticClass: "basic_config_content",
          },
          [
            _c(
              "el-col",
              { attrs: { span: 14 } },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: item.label,
                      rules: _vm.rules.rate,
                      prop: "rateTypes." + index + ".rate",
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          placeholder: _vm.$t(
                            "输入0.001~99999999 输入汇率x平台币=当前货币金额"
                          ),
                        },
                        model: {
                          value: item.rate,
                          callback: function ($$v) {
                            _vm.$set(item, "rate", $$v)
                          },
                          expression: "item.rate",
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "append" },
                          [
                            _c(
                              "d-switch",
                              {
                                attrs: {
                                  "active-value": 0,
                                  "inactive-value": 1,
                                },
                                model: {
                                  value: item.isOpen,
                                  callback: function ($$v) {
                                    _vm.$set(item, "isOpen", $$v)
                                  },
                                  expression: "item.isOpen",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "open" }, slot: "open" },
                                  [_vm._v(_vm._s(_vm.$t("开启")))]
                                ),
                                _c(
                                  "span",
                                  { attrs: { slot: "close" }, slot: "close" },
                                  [_vm._v(_vm._s(_vm.$t("关闭")))]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "el-form-item",
        { staticClass: "action-button" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("保 存")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }