import { filterColumns } from "@/utils";
import { rateTypes } from "@/libs/options";
const CanChooseColumns = function () {
  return [
    {
      title: this.$t("订单号"),
      key: "orderNo",
      align: "center",
    },
    {
      title: this.$t("申请时间"),
      key: "createTime",
      align: "center",
      width: 145,
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.createTime)}</span>;
        },
      },
    },
    {
      title: this.$t("会员账号"),
      key: "memberName",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span
              class="primary-type cursor-pointer hover-underline"
              onClick={this._onDetails.bind(this, row)}
            >
              {row.memberName}
            </span>
          );
        },
      },
    },
    {
      title: this.$t("所属代理"),
      key: "proxyCode",
      align: "center",
    },
    {
      title: this.$t("VIP等级"),
      key: "vipLevel",
      align: "center",
      width: 70,
      component: {
        render: (h, row) => {
          return <span>VIP{row.vipLevel}</span>;
        },
      },
    },
    {
      title: this.$t("累计充值"),
      key: "sumRecharge",
      align: "center",
    },
    {
      title: this.$t("累计提现"),
      key: "sumWithdraw",
      align: "center",
    },
    {
      title: this.$t("会员备注"),
      key: "memberRemark",
      align: "center",
      showOverflowTooltip: true,
    },
    {
      title: this.$t("申请金额"),
      key: "amount",
      align: "center",
      component: {
        render: (h, row) => {
          return <span class={this.$parseColor(row.amount)}>{row.amount}</span>;
        },
      },
    },
    {
      title: this.$t("收款信息"),
      key: "receiveing",
      align: "center",
      width: 160,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{row.receivingName}</p>
              <p>{row.abpayType==null?row.receivingBank:['abpay-划转提现','abpay-微信提现','abpay-支付宝提现','abpay-银行卡提现'][row.abpayType]}</p>
              <p>
                {row.receivingCardNo.startsWith("http")
                  ? ""
                  : row.receivingCardNo}
              </p>
              {row.walletUrl&&
               <el-image 
               style="width: 100px; height: 100px"
               src={row.walletUrl}
               preview-src-list={[row.walletUrl]}>
             </el-image>
              // <el-popover
              //   placement="center" 
              //   trigger="click" 
              // >
              //   <el-image
              //     src={row.walletUrl}
              //     fix="cover"
              //     slot="reference"
              //     style="width: 40px;height: 40px;border-radius: 4px;"
              //   />
              //   <el-image
              //     src={row.walletUrl}
              //     fix="cover" 
              //     style="width: 340px;height: 340px;border-radius: 4px;position:absolute;z-index:999"
              //   />
              // </el-popover>
              }
            </div>
          );
        },
      },
    },
    {
      title: this.$t("货币汇率"),
      align: "center",
      component: {
        render: (h, row) => {
          const rateType = rateTypes.find((e) => e.key === row.rateType);
          if (rateType) {
            return (
              <div>
                <p>{rateType.label}</p>
                <span>{row.paymentRate}</span>
              </div>
            );
          }
          return <span>{row.paymentRate}</span>;
        },
      },
    },
    {
      title: this.$t("货币金额"),
      key: "paymentAmount",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span class={this.$parseColor(row.paymentAmount)}>
              {row.paymentAmount}
            </span>
          );
        },
      },
    },
    // {
    //   title: 'IFSC',
    //   key: 'ifsc',
    //   align: 'center'
    // },
    // {
    //   title: this.$t('订单状态'),
    //   key: 'status',
    //   align: 'center',
    //   width: 90,
    //   component: {
    //     render: (h, row) => {
    //       const tmp = this.withdrawStatus.find(o => o.key === row.status)
    //       if (tmp) {
    //         return (<span class={`${tmp.type}-type`}>{ tmp.label }</span>)
    //       }
    //     }
    //   }
    // },
    {
      title: "订单状态",
      key: "status",
      align: "center",
      width: 180,
      component: {
        render: (h, row) => {
          const tmp = this.withdrawStatus.find((o) => o.key === row.status);
          if (tmp) {
            // const { Update } = this.permission
            return (
              <div>
                <p>
                  <span class={`${tmp.type}-type`}>{tmp.label}</span>
                </p>
                {row.status === 7 && (
                  <span style="margin-right:6px;">
                    <el-button
                      size="small"
                      type="success"
                      onClick={() => this._handleSuccess(row)}
                    >
                      确认
                    </el-button>
                  </span>
                )}
                {row.status === 7 && (
                  <el-button
                    size="small"
                    type="danger"
                    onClick={() => this._handleCancel(row)}
                  >
                    取消
                  </el-button>
                )}
                {/* {Update && row.status === 7 && (<span style='margin-right:6px;'><el-button size='small' type='success' onClick={() => this._handleSuccess(row)}>确认</el-button></span>)}
                {Update && row.status === 7 && <el-button size='small' type='danger' onClick={() => this._handleCancel(row)}>取消</el-button>} */}
              </div>
            );
          }
        },
      },
    },
    {
      title: this.$t("操作时间"),
      key: "updateTime",
      align: "center",
      width: 145,
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.updateTime)}</span>;
        },
      },
    },
    {
      title: this.$t("操作员"),
      key: "operatorName",
      align: "center",
    },
    {
      title: this.$t("操作说明"),
      key: "remark",
      align: "center",
      component: {
        render: (h, row) => {
          return <span class={ row.status === 2 && row.remark === '前端用户确定未收款' && 'danger-type' }>{ row.remark }</span>;
        }
      }
    },
    {
      title: this.$t("备注"),
      key: "notes",
      align: "center",
    },
  ];
};

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}
