const CanChooseColumns = function() {
  return [
    {
      title: this.$t('编号'),
      key: 'id',
      align: 'center'
    },
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('会员ID'),
      key: 'memberId',
      align: 'center'
    },
    {
      title: this.$t('奖励类型'),
      key: 'rewardType',
      align: 'center',
      component: {
        render: (h, row) => {
          const rewardType = this.rewardType.find(p => p.key === row.rewardType)
          if (rewardType) {
            return (<span>{ rewardType.label }</span>)
          }
          return ''
        }
      }
    },
    {
      title: this.$t('抵用券奖励'),
      key: 'couponAmount',
      align: 'center'
    },
    {
      title: this.$t('彩金奖励'),
      key: 'rewardAmount',
      align: 'center'
    },
    {
      title: this.$t('累计签到'),
      key: 'checkinDays',
      align: 'center'
    },
    {
      title: this.$t('签到时间'),
      key: 'checkDate',
      align: 'center'
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
