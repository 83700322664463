<template>
  <div>
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 360px"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始时间')"
        :end-placeholder="$t('结束时间')"
        :clearable="false"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, -1)">{{ $t('明天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, -7)">{{ $t('_7天') }}</el-button>

      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <template #left>
        <el-select
          v-model="search.sourceType"
          :placeholder="$t('数据来源')"
          clearable
          style="width: 120px"
          class="filter-item"
        >
          <el-option v-for="(item, index) in sourceType" :key="index" :label="item.label" :value="item.value" />
        </el-select>
        <el-select
          v-model="search.sportType"
          :placeholder="$t('体育项目')"
          clearable
          style="width: 120px"
          class="filter-item"
        >
          <el-option v-for="(val, key) in sportTypes" :key="key" :label="val" :value="+key" />
        </el-select>
        <el-input
          v-model="search.competitionName"
          :placeholder="$t('Interactive_Match.ForecastTab.赛事名称')"
          clearable
          style="width: 160px"
          class="filter-item"
        />
        <el-input
          v-model="search.teamName"
          :placeholder="$t('Interactive_Match.ForecastTab.球队名称')"
          clearable
          style="width: 160px"
          class="filter-item"
        />
      </template>
      <template #right>
        <span class="filter-item">是否应用娱乐城</span>
        <d-switch v-model="casinoSwitch" :active-value="1" :inactive-value="0" :beforeChange="handleChangeCasinoSwitch" class="filter-item">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </d-switch>
        <span class="filter-item" style="margin-left: 20px">无数据自动获取第三方</span>
        <d-switch v-model="autoThirdSwitch" :active-value="1" :inactive-value="0" :beforeChange="handleChangeAutoThirdSwitch" class="filter-item">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </d-switch>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <!-- <el-dialog :title="this.$t(' Interactive_Match.ForecastTab.设置排序')" :visible.sync="visibleSetSort" width="800px"
      @open="onOpen">

    </el-dialog> -->

    <el-dialog :visible.sync="visibleSetSort" title="热门排序设置" width="400px" center>
      <!-- <p class="sort-dialog">
        <span>*位置</span>
        <el-select v-model="positionVal" clearable style="width: 120px" class="filter-item">
          <el-option label="早盘" :value="1" />
          <el-option label="今日" :value="2" />
        </el-select>
      </p> -->
      <p class="sort-dialog">
        <span>*排序</span>
        <el-input v-model="sortVal" placeholder="最小1,最大999. 999为不展示" onkeyup="value=value.replace(/[^\d]/g,'')" style="width: 280px;" />
      </p>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="visibleSetSort = false">{{ $t('取消') }}</el-button>
          <el-button type="primary" @click="setSort">
            {{ $t('确定') }}
          </el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getThirdMatchList, setSort } from '@/api/match'
import { mapGetters } from 'vuex'
import { getDefaultTimes, parseTime } from '@/utils'
import { sportTypes } from '@/libs/options'
import { getBaseConfig, casinoSwitch, casinoThirdSwitch } from '@/api/config'

const insetparams = { sportType: 1 ,position:1}

export default {
  name: 'ThirdMatchTab',
  componentName: 'ThirdMatchTab',
  mixins: [TablePageMixin(getThirdMatchList, true, insetparams)],
  data() {
    return {
      times: getDefaultTimes(),
      filterColumn: false,
      columns: GetColumns.call(this),
      search: { sportType: 1 },
      sourceType: this.$t('sourceType'),
      sortVal: null, // 热门排序
      currentRow: null, // 点编辑时的当前行
      visibleSetSort: false, // 设置排序
      sportTypes,
      casinoSwitch: 0,
      autoThirdSwitch: 0
    }
  },
  computed: {
    ...mapGetters(['tenantCode'])
  },
  watch: {
    'search.sportType': {
      handler(n, o) {
        insetparams.sportType = n
      }
    }
  },
  created() {
    this.handleGetBaseConfig()
  },
  methods: {
    implementationGetParams() {
      const startMatchTime = parseTime(this.times[0])
      const endMatchTime = parseTime(this.times[1])
      return {
        startMatchTime,
        endMatchTime,
        ...this.search,
        tenantCode: this.tenantCode
      }
    },
    async setSort() {
      if (!this.currentRow) return
      const { thirdMatchId, tenantCode, sourceType } = this.currentRow
      // if (!(/^[1-9][1-9][1-9]$/).test(this.sortVal)) {
      //   this.$message.error('排序值范围1-999')
      //   return
      // }
      if (this.sortVal < 1 || this.sortVal > 999 || isNaN(this.sortVal)) {
        this.$message.error('排序值范围1-999');
        return;
      }
      let result = await setSort({ thirdMatchId, tenantCode, sourceType, sort: this.sortVal, sportType: this.search.sportType, hotOrder: this.sortVal })
      let isok = result[2] && result[2].data && result[2].data.success
      await this.handleFetch()
      this.visibleSetSort = false
      this.$message.success(this.$t('操作' + (isok ? '成功' : '失败')))
    },
    handleGetBaseConfig() {
      getBaseConfig().then(([data, err]) => {
        if (!err && data) {
          this.casinoSwitch = data.casinoApplicationSwitch || 0
          this.autoThirdSwitch = data.autoFetchThirdSwitch || 0
        }
      });
    },
    handleChangeCasinoSwitch() {
      return new Promise((resolve) => {
        this.$confirm(`确认${this.casinoSwitch === 0 ? '开起' : '关闭'}应用娱乐城`, '系统提示', { type: 'warning' }).then(() => {
          casinoSwitch({
            casinoSwitch: ~this.casinoSwitch + 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
            }
          })
        })
      })
    },
    handleChangeAutoThirdSwitch() {
      return new Promise((resolve) => {
        this.$confirm(`确认${this.autoThirdSwitch === 0 ? '开起' : '关闭'}无数据自动获取第三方`, '系统提示', { type: 'warning' }).then(() => {
          casinoThirdSwitch({
            autoThirdSwitch: ~this.autoThirdSwitch + 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
            }
          })
        })
      })
    }
  }
}
</script>
<style lang="scss">
.sort-dialog {
  display: flex;
  align-items: center;
  margin-bottom:8px;
  // margin-bottom: 10px;

  span {
    margin-right: 10px;
    // font-weight: bold;
  }

  .el-input {
    width: 200px;
    margin-right: 10px;

    input {
      height: 32px;
    }
  }
}
</style>
