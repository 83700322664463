<template>
  <el-dialog :title="$t('备注信息操作')" width="30%" :visible.sync="visible" @closed="handleClosed">
    <el-form label-width="120px" label-suffix=":">
      <el-form-item :label="$t('备注信息')">
        <el-input v-model.trim="ruleForm.remark" :autosize="{ minRows: 8, maxRows: 10}" type="textarea" :placeholder="$t('请输入内容')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  remark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm, null)],
  data() {
    return {
      emitName: 'update'
    }
  },
  methods: {
    open(row) {
      this.ruleForm = { ...this.ruleForm, ...row }
      this.visible = true
    }
  }
}
</script>
