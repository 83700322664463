<template>
  <div>
    <div class="filter-container">
      <el-select v-model="search.timeType" class="filter-item" style="width:160px">
        <el-option label="创建时间" :value="0" />
        <el-option label="操作时间" :value="1" />
      </el-select>
      <el-date-picker
        v-model="times"
        clearable
        type="datetimerange"
        class="filter-item"
        style="width: 400px"
        :range-separator="$t('至')"
        :start-:placeholder="$t('开始日期')"
        :end-:placeholder="$t('结束日期')"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0)">{{
        $t("今天")
      }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1)">{{
        $t("昨天")
      }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7)">{{
        $t("_7天")
      }}</el-button>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleSearchWrap"
        >{{ $t("查询") }}</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-refresh-right"
        @click="resetSearch"
        >{{ $t("重置") }}</el-button
      >
    </div>
    <div class="filter-container">
      <el-select
        v-model="search.memberType"
        clearable
        :placeholder="$t('用户类型')"
        class="filter-item"
        style="width: 160px"
      >
        <el-option
          v-for="item in memberTypes"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="search.vipLevel"
        ::placeholder="$t('VIP等级')"
        clearable
        class="filter-item"
        style="width: 120px"
      >
        <el-option
          v-for="item in vipLevels"
          :key="item.vipLevel"
          :label="`VIP${item.vipLevel}`"
          :value="item.vipLevel"
        />
      </el-select>
      <el-input
        v-model="search.memberName"
        :placeholder="$t('会员账号')"
        clearable
        class="filter-item"
        style="width: 120px"
      />
      <el-input
        v-model="search.realName"
        :placeholder="$t('真实姓名')"
        clearable
        class="filter-item"
        style="width: 120px"
      />
      <el-input
        v-model="search.payingName"
        :placeholder="$t('转账姓名')"
        clearable
        class="filter-item"
        style="width: 120px"
      />
      <el-input
        v-model="search.proxyCode"
        :placeholder="$t('所属代理')"
        clearable
        class="filter-item"
        style="width: 120px"
      />
      <el-select
        v-model="search.rechargeTimes"
        :placeholder="$t('充值状态')"
        clearable
        class="filter-item"
        style="width: 120px"
      >
        <el-option
          v-for="item in rechargeStatus"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
    </div>
    <div class="filter-container">
      <el-select
        v-model="search.status"
        :placeholder="$t('订单状态')"
        clearable
        class="filter-item"
        style="width: 120px"
      >
        <el-option :label="$t('充值成功')" value="0000" />
        <el-option :label="$t('已拒绝')" value="2222" />
        <el-option :label="$t('待处理')" value="1111" />
      </el-select>
      <el-input
        v-model="search.receivingBank"
        clearable
        :placeholder="$t('收款银行')"
        class="filter-item"
        style="width: 120px"
      />
      <el-input
        v-model="search.receivingName"
        clearable
        :placeholder="$t('收款姓名')"
        class="filter-item"
        style="width: 120px"
      />
      <el-input
        v-model="search.receivingCardNo"
        clearable
        :placeholder="$t('收款卡号')"
        class="filter-item"
        style="width: 160px"
      />
      <el-input
        v-model="search.orderNo"
        clearable
        :placeholder="$t('订单号')"
        class="filter-item"
        style="width: 160px"
      />
      <el-input
        v-model="search.operatorName"
        clearable
        :placeholder="$t('操作员')"
        class="filter-item"
        style="width: 120px"
      />
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      row-key="orderNo"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        {{ $t("订单总额") }}：{{ sumAmount }}{{ $t("元")
        }}<template v-if="sumAmountGroupByStatus.length > 0">，</template>
        <span v-for="(item, index) in sumAmountGroupByStatus" :key="item.status"
          >{{ item.status | labelFilter }}：{{ item.sum
          }}<template v-if="sumAmountGroupByStatus.length - 1 > index"
            >，</template
          ></span
        >
      </template>
      <template #right>
        <el-button
          plain
          type="primary"
          :loading="excelLoading1"
          icon="el-icon-download"
          @click="_handleExport"
          >{{ $t("导出Excel") }}</el-button
        >
      </template>
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />
    <ImagePreview ref="ImagePreviewControl" />
  </div>
</template>
<script>
import { getPaymentOrderList, paymentOrderDownload } from "@/api/cash";
import { rechargeStatus } from "@/libs/options";
import TablePageMixin from "@/mixins/table-page-mixin";
import GetColumns from "./columns";
import { exportExcel, getDefaultTimes,getTodayTimestamps } from "@/utils";
import ImagePreview from '@/components/ImagePreview'

const insetparams = { orderType: 1 };
export default {
  name: "ListTab",
  componentName: "ListTab",
  components: { ImagePreview },
  filters: {
    labelFilter(status) {
      const tmp = rechargeStatus.find((o) => o.key === status);
      return tmp && tmp.label;
    },
  },
  mixins: [TablePageMixin(getPaymentOrderList, false, insetparams)],
  data() {
    return {
      timer: null,
      times: getDefaultTimes(),
      excelLoading1: false,
      search: {
        orderNo: '',
        timeType: 0
      },
      sumAmount: 0,
      sumAmountGroupByStatus: [],
      columns: [],
      defaultColumns: GetColumns.call(this),
      rechargeStatus: this.$t("rechargeStatus"),
      memberTypes: this.$t("memberTypes"),
    };
  },
  computed: {
    vipLevels() {
      return this.$parent.vipLevels;
    },
  },
  watch: {},
  activated() {
    const { orderNo, startTime } = this.$route.query;
    if (orderNo) this.search.orderNo = orderNo;
    if (startTime) {
      this.search.startTime = getTodayTimestamps(startTime).startOfDay;
      this.times.splice(0, 1, getTodayTimestamps(startTime).startOfDay);
      this.search.endTime = getTodayTimestamps(startTime).endOfDay;
      this.times.splice(1, 1,  getTodayTimestamps(startTime).endOfDay);
    }

    if (this.search.orderNo) {
      this.search.orderType = 1;
    }
    this.handleSearch()
  },
  methods: {
    handleSearchWrap() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.handleSearch()
      }, 500)      
    },
    _onDetails({ memberId }) {
      if (!this.permission.Details) return;
      this.$parent.$refs.UserDialogControl.open(
        memberId,
        this.permission.Phone
      );
    },
    _handleExport() {
      const query = {
        ...this.implementationGetParams(),
        ...insetparams,
      };
      this.excelLoading1 = true;
      paymentOrderDownload(query)
        .then(([data, error, blobData]) => {
          if (!error) {
            exportExcel(
              blobData,
              `充值订单-${this.$parseTime(new Date())}.csv`
            );
          }
        })
        .finally(() => {
          this.excelLoading1 = false;
        });
    },
    implementationFetched({ sumAmount, sumAmountGroupByStatus }) {
      this.sumAmount = sumAmount || 0;
      this.sumAmountGroupByStatus = sumAmountGroupByStatus.filter(
        (e) => e.sum !== undefined
      );
    },
    implementationGetParams() {
      if (this.times && Array.isArray(this.times)) {
        const [startTime, endTime] = this.times;
        return {
          startTime,
          endTime,
          ...this.search,
        };
      }
      return { ...this.search };
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys);
    },
  },
};
</script>
