<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="daterange"
        class="filter-item"
        style="width: 260px;"
        value-format="yyyy-MM-dd"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0, false)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1, false)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 3, false)">{{ $t('本周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 14, false)">{{ $t('上周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30, false)">{{ $t('本月') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60, false)">{{ $t('上月') }}</el-button>
    </div>
    <div class="filter-container">
      <el-input v-model="search.memberId" clearable maxlength="30" :placeholder="$t('会员ID')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.memberName" clearable maxlength="30" :placeholder="$t('会员账号')" class="filter-item" style="width: 140px" />
      <el-select v-model="search.rewardType" clearable :placeholder="$t('奖励类型')" class="filter-item" style="width: 140px">
        <el-option v-for="item in rewardType" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleRefresh">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <p class="amount-total"><span v-if="dataInfo.couponAmountTotal">抵用券金额：{{ dataInfo.couponAmountTotal }}</span><span v-if="dataInfo.rewardAmountTotal">彩金金额：{{ dataInfo.rewardAmountTotal }}</span></p>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>
<script>
import { getDefaultTimes } from '@/utils'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getCheckinMemberList } from '@/api/activity'
import GetColumns from './columns'
export default {
  name: 'SigninRecordTab',
  mixins: [TablePageMixin(getCheckinMemberList)],
  data() {
    return {
      times: getDefaultTimes('timestamp', 0, 0, false),
      search: {},
      filterColumn: false,
      dateTime: new Date().getTime(),
      columns: GetColumns.call(this),
      rewardType: this.$t('rewardType'),
      dataInfo: {}
    }
  },
  methods: {
    implementationGetParams() {
      return this.formatIntervalTime(true)
    },
    implementationFetched(data) {
      this.dataInfo = data
    }
  }
}
</script>
