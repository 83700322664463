<template>
  <div>
    <div class="tab-header">
      <el-button-group>
        <el-button v-for="item in orderStatus" :key="item.value" size="small" :type="search.order === item.value && 'primary' || ''" @click="handleOrderStatus(item.value)">{{ item.label }}</el-button>
      </el-button-group>
    </div>
    <div class="filter-container">
      <div class="filter-container-item">
        <el-date-picker
          v-model="times"
          type="datetimerange"
          style="width: 400px;"
          range-separator="至"
          class="filter-item"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
          clearable
          :picker-options="pickerOptions"
        />
        <el-input v-model="search.title" class="filter-item" clearable placeholder="标题" style="width: 160px" />
        <el-input v-model="search.memberName" class="filter-item" clearable placeholder="账号" style="width: 160px" />
        <el-input v-model="search.nickname" class="filter-item" clearable placeholder="昵称" style="width: 160px" />
        <div class="filter-container-item">
          <el-select v-model="search.rank" placeholder="用户头衔" clearable class="filter-item" style="width: 140px">
            <el-option v-for="item in $parent.ranks" :key="item.createTime" :label="item.rankName" :value="item.rank" />
          </el-select>
          <el-select v-model="search.source" placeholder="数据来源" clearable class="filter-item" style="width: 140px">
            <el-option label="ANDROID用户" value="ANDROID" />
            <el-option label="IOS用户" value="IOS" />
            <el-option label="Web用户" value="WEB" />
          </el-select>
          <el-select v-model="search.lockStatus" placeholder="状态" clearable class="filter-item" style="width: 140px">
            <el-option label="未锁帖" :value="0" />
            <el-option label="锁帖" :value="1" />
          </el-select>
          <el-select v-model="search.lotteryId" placeholder="类别" clearable class="filter-item" style="width: 140px">
            <el-option v-for="(val, key) in xsLottery" :key="key" :value="key" :label="val" />
          </el-select>
          <el-button class="filter-item" type="success" icon="el-icon-search" @click="handleSearch">查询</el-button>
          <el-button class="filter-item" type="success" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
        </div>
      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      selection-row
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      @selection-change="handleSelectionChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getRecommendHideList, updateRecoverRecommend } from '@/api/interactive'
import { getDefaultTimes } from '@/utils'
import { xsLottery } from '@/libs/options'
export default {
  name: 'HideTab',
  componentName: 'HideTab',
  mixins: [TablePageMixin(getRecommendHideList)],
  data() {
    return {
      times: getDefaultTimes(),
      filterColumn: false,
      search: {},
      columns: GetColumns.call(this),
      selection: [],
      orderStatus: [
        { value: 'createTime', label: '创建时间' },
        { value: 'updateTime', label: '更新时间' }
      ],
      xsLottery
    }
  },
  methods: {
    handleRecover({ id }) {
      let data = { postIds: [id] }
      return new Promise((resolve) => {
        this.$confirm('确定恢复此帖子', '系统提示', { type: 'warning' }).then(() => {
          updateRecoverRecommend(data).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success('恢复成功')
              this.handleFetch()
            }
          }).catch(() => {})
        })
      })
    },
    handleOrderStatus(order) {
      this.search.order = this.search.order === order ? '' : order
      this.handleRefresh()
    },
    handleSelectionChange(selection) {
      this.selection = selection
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    }
  }
}
</script>
