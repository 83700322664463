var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "ruleForm", attrs: { "label-width": "100px" } },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "basic_config_content",
              attrs: { gutter: 24 },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-right": "6px" },
                          on: { change: _vm.handleSearch },
                          model: {
                            value: _vm.lang,
                            callback: function ($$v) {
                              _vm.lang = $$v
                            },
                            expression: "lang",
                          },
                        },
                        _vm._l(_vm.allLang, function (item, index) {
                          return _c(
                            "el-radio-button",
                            { key: index, attrs: { label: item.lang } },
                            [_vm._v(_vm._s(_vm.$t(item.name)))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("h2", [_vm._v(_vm._s(_vm.$t("关于我们")))]),
                      _c("tinymce", {
                        ref: "tinymce1",
                        attrs: { height: 250 },
                        model: {
                          value: _vm.ruleForm.aboutus,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "aboutus", $$v)
                          },
                          expression: "ruleForm.aboutus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("h2", [_vm._v(_vm._s(_vm.$t("足球说明")))]),
                      _c("tinymce", {
                        ref: "tinymce2",
                        attrs: { height: 250 },
                        model: {
                          value: _vm.ruleForm.footballdesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "footballdesc", $$v)
                          },
                          expression: "ruleForm.footballdesc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { align: "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", loading: _vm.submitting },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v(_vm._s(_vm.$t("保 存")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form",
        { ref: "ruleForm1", attrs: { "label-width": "100px" } },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading1,
                  expression: "loading1",
                },
              ],
              staticClass: "basic_config_content",
              attrs: { gutter: 24 },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("h2", [_vm._v(_vm._s(_vm.$t("首页启动图配置")))]),
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: _vm.action,
                            "file-list": _vm.startImgList,
                            data: { type: "image", folder: "ad-photo" },
                            "on-success": _vm.handleUploadSuccess,
                            "before-upload": _vm.beforeImgUpload,
                            "on-remove": _vm.onImgRemove,
                            "on-change": _vm.imgOnChange,
                            headers: _vm.$headers(),
                            "on-exceed": _vm.onExceed,
                            limit: 3,
                            "list-type": "picture-card",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _c("i", { staticClass: "el-icon-warning" }),
                              _vm._v(
                                " 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。支持多张，最多3张 "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { align: "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", loading: _vm.submitting1 },
                          on: { click: _vm.handleSubmit1 },
                        },
                        [_vm._v(_vm._s(_vm.$t("保 存")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }