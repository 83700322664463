<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        :clearable="false"
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
        popper-class="date-style"
      />
      <el-button class="filter-item" @click="handleSetTimes($event,0, false)">今天</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,1, false)">昨天</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,7, false)">7天</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,30, false)">本月</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,60, false)">上月</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :options="options"
      :filter-column="filterColumn"
      selection-row
      :columns="columns"
      @selection-change="handleSelectionChange"
    >
      <template #left>
        <div class="filter-container">
          <el-input v-model.trim="search.memberId" maxlength="20" clearable :placeholder="$t('会员Id')" class="filter-item" style="width: 140px" />
          <el-input v-model.trim="search.memberName" maxlength="20" clearable :placeholder="$t('会员账号')" class="filter-item" style="width: 140px" />
          <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
          <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
        </div>
      </template>

    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>

<script>
import { getAgencyTransferRecord } from '@/api/agent-rebate.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getDefaultTimes } from '@/utils'
import GetColumns from './columns'
export default {
  name: 'TransferTab',
  mixins: [TablePageMixin(getAgencyTransferRecord)],
  data() {
    return {
      search: {},
      times: getDefaultTimes('timestamp', 0, 0, false),
      filterColumn: true,
      columns: GetColumns.call(this)
    }
  },
  methods: {
    handleSelectionChange(selection) { // 批量选择
      this.selection = [...selection]
    },

    implementationGetParams() {
      return this.formatIntervalTime()
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
