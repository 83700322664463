const CanChooseColumns = function() {
  return [
    {
      title: '排序',
      key: 'sort',
      align: 'center'
    },
    {
      title: this.$t('类目名称'),
      key: 'categoryName',
      align: 'center',
    },
    {
      title: this.$t('操作'),
      key: 'operate',
      align: 'center',
      component: {
        render: (h, row) => {
          const { Update, Delete, Close } = this.permission
          return (
            /*<div>
              { Close && row.activityStatus !== 1 &&
                <el-button type='danger' size='small' icon='el-icon-close' onClick={this.handleClose.bind(this, row, 0)}>{ this.$t('下架') }</el-button> ||
                <el-button type='success' size='small' icon='el-icon-check' onClick={this.handleClose.bind(this, row, 1)}>{ this.$t('上架') }</el-button>
              }
              { Update && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.categoryDialog.open(row) } }>{ this.$t('修改') }</el-button> }
              { Delete && <el-button type='danger' size='small' icon='el-icon-delete' onClick={() => this.handleDelete(row)}>{ this.$t('删除') }</el-button> }
            </div>*/
            <div>
              { <el-button type='success' size='small' icon='el-icon-document-remove' onClick={() => this.handleMove(row)}>{ this.$t('迁移') }</el-button> }
              { <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.categoryDialog.open(row) } }>{ this.$t('修改') }</el-button> }
              { <el-button type='danger' size='small' icon='el-icon-delete' onClick={() => this.handleDelete(row)}>{ this.$t('删除') }</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
