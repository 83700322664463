import request from '@/plugins/axios'

export function login(data) {
  return request({
    url: '/backmanager/login',
    method: 'post',
    data
  })
}

export function logout(params = {}) {
  return request({
    url: '/backmanager/logout',
    method: 'get',
    params
  })
}


export function getOperatorMenuList(token) {
  return request({
    url: `/menu/getOperatorMenuList?token=${token}`,
    method: 'get'
  })
}
