import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('账号'),
      key: 'operatorName',
      align: 'center',
      width: 160
    },
    {
      title: this.$t('组别'),
      key: 'groupName',
      align: 'center',
      width: 120
    },
    {
      title: this.$t('IP地址'),
      key: 'loginIp',
      align: 'center'
    },
    {
      title: this.$t('登录地区'),
      key: 'address',
      align: 'center'
    },
    {
      title: this.$t('登录域名'),
      key: 'domain',
      align: 'center'
    },
    {
      title: this.$t('登录系统'),
      key: 'system',
      align: 'center'
    },
    {
      title: this.$t('登录浏览器'),
      key: 'browser',
      align: 'center'
    },
    {
      title: this.$t('登录时间'),
      key: 'updateTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{ this.$parseTime(row.updateTime) }</span>
        }
      }
    },
    {
      title: this.$t('登录结果'),
      key: 'description',
      align: 'center',
      component: {
        render: (h, row) => {
          if (!row.description.includes('登录成功')) {
            return <span class='danger-type'>{ row.description }</span>
          }
          return <span>{ row.description }</span>
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
