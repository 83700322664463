<template>
  <el-dialog :title="title" :visible.sync="visible" width="730px" top="5vh" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item :label="$t('支付名称')" prop="accountName">
        <el-input v-model="ruleForm.accountName" placeholder="请输入支付名称，最多50个字" maxlength="50" />
        <span class="validate-tip">{{ $t("便于区分和记忆，用于前端配置收款账户使用") }}</span>
      </el-form-item>
      <el-form-item :label="$t('支付方式图标')" prop="paywayId">
        <el-select v-model="ruleForm.paywayId" :placeholder="$t('请选择支付方式图标')">
          <el-option v-for="item in paymentWayConfList" :key="item.paywayId" :label="item.paywayName" :value="item.paywayId" />
        </el-select>
        <span class="validate-tip">{{ $t("前端展示图标") }}</span>
      </el-form-item>
      <el-form-item :label="$t('客服方式')" prop="paymentCustomerType">
        <el-select v-model="ruleForm.paymentCustomerType" :placeholder="$t('请选择客服方式')" @change="handleChangeCustomerType">
          <el-option v-for="(val, key) in paymentCustomerTypes" :key="key" :label="val" :value="+key" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.paymentCustomerType === 2" :label="$t('三方客服地址')" prop="paymentCustomerAddress">
        <el-input v-model="ruleForm.paymentCustomerAddress" placeholder="请输入三方客服地址" />
      </el-form-item>
      <el-form-item :label="$t('充值金额类型')" prop="isType">
        <el-select v-model="ruleForm.isType" :placeholder="$t('请选择充值金额类型')">
          <el-option :label="$t('固定金额')" :value="1" />
          <el-option :label="$t('范围金额')" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.isType === 0" :label="$t('范围金额')" class="amount">
        <el-form-item prop="minAmount" label-width="0" style="width: 120px;">
          <el-input v-model="ruleForm.minAmount" :placeholder="$t('最低金额')" />
        </el-form-item>
        <el-form-item prop="maxAmount" label-width="0" style="width: 120px;margin-left: 10px;">
          <el-input v-model="ruleForm.maxAmount" :placeholder="$t('最高金额')" />
        </el-form-item>
      </el-form-item>
      <el-form-item v-if="ruleForm.isType === 1" :label="$t('固定金额')" prop="periodAmount">
        <el-input v-model="ruleForm.periodAmount" placeholder="支持的固定金额，多个金额以“,”隔开" />
      </el-form-item>
      <el-form-item :label="$t('上限额度')" prop="limitAccount">
        <el-input v-model="ruleForm.limitAccount" :placeholder="$t('请输入上限额度')" />
      </el-form-item>
      <el-form-item :label="$t('排序')" prop="sort">
        <el-input v-model.number="ruleForm.sort" placeholder="排序由大到小" />
      </el-form-item>
      <el-form-item :label="$t('VIP等级')" prop="vipLevel">
        <el-select v-model="ruleForm.vipLevel" multiple collapse-tags>
          <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('货币种类')" prop="rateType">
        <el-select v-model="ruleForm.rateType" collapse-tags :placeholder="$t('请选择货币种类')">
          <el-option v-for="item in rateTypes" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('是否启用')" prop="status">
        <el-radio-group v-model="ruleForm.status">
          <el-radio :label="0">{{ $t("启用") }}</el-radio>
          <el-radio :label="1">{{ $t("不启用") }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t("关闭") }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">{{ $t("保存") }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from "@/mixins/form-dialog"
import { rateTypes, paymentCustomerTypes } from "@/libs/options"
import { numerical_f4 } from '@/utils/validation.js'

const getDefaultForm = () => ({
  accountName: '',
  paywayId: '',
  paymentCustomerType: '',
  paymentCustomerAddress: '',
  isType: '',
  minAmount: '',
  maxAmount: '',
  periodAmount: '',
  limitAccount: '',
  sort: null,
  vipLevel: [],
  status: 0,
  rateType: '',
  receivingTypeId: 9,
  receivingBank: '代客充值',
  receivingCardNo: '平台客服'
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: "create",
      rateTypes,
      paymentCustomerTypes,
      rules: {
        accountName: [{ required: true, message: this.$t("请输入支付名称"), trigger: "blur" }],
        paywayId: [{ required: true, message: this.$t("请选择支付方式图标"), trigger: "change" }],
        paymentCustomerType: [{ required: true, message: this.$t("请选择客服方式"), trigger: "change" }],
        paymentCustomerAddress: [{ required: true, message: this.$t("请输入三方客服地址"), trigger: "blur" }],
        accountName: [{ required: true, message: this.$t("请输入支付名称"), trigger: "blur" }],
        isType: [{ required: true, message: this.$t("请选择充值金额类型"), trigger: "change" }],
        minAmount: [
          { required: true, message: this.$t("请输入最低金额"), trigger: "blur" },
          { pattern: /^\d+(\.\d+)?$/, message: this.$t("请输入正确最低金额"), trigger: "blur" }
        ],
        maxAmount: [
          { required: true, message: this.$t("请输入最高金额"), trigger: "blur" },
          { pattern: /^\d+(\.\d+)?$/, message: this.$t("请输入正确最高金额"), trigger: "blur" }
        ],
        periodAmount: [{ required: true, message: this.$t("请输入固定金额"), trigger: "blur" }],
        limitAccount: [
          { required: true, message: this.$t("请输入上限额度"), trigger: "blur" },
          { pattern: numerical_f4, message: this.$t("请输入正确上限额度"), trigger: "blur" }
        ],
        vipLevel: [{ required: true, type: "array", message: this.$t("请选择VIP等级"), trigger: "change" }],
        sort: [
          { required: true, message: this.$t("请输入序号"), trigger: "blur" },
          { pattern: /^(0|[1-9][0-9]*)$/, message: this.$t("请输入正确序号"), trigger: "blur" }
        ],
        rateType: [{ required: true, message: this.$t("请选择货币种类"), trigger: "change" }],
      }
    }
  },
  computed: {
    title() {
      return this.emitName === "create" ? this.$t("新增代客充值") : this.$t("编辑代客充值")
    },
    vipLevels() {
      return this.$parent.vipLevels
    },
    paymentWayConfList() {
      return this.$parent.paymentWayConfList.filter(e => e.type === 6)
    }
  },
  methods: {
    open(row) {
      this.emitName = "create"
      if (row) {
        this.ruleForm.id = row.id
        this.ruleForm.accountName = row.accountName
        this.ruleForm.paywayId = row.paywayId
        this.ruleForm.paymentCustomerType = row.paymentCustomerType
        this.ruleForm.paymentCustomerAddress = row.paymentCustomerAddress
        this.ruleForm.isType = row.isType
        this.ruleForm.maxAmount = row.maxAmount
        this.ruleForm.minAmount = row.minAmount
        this.ruleForm.periodAmount = row.periodAmount
        this.ruleForm.limitAccount = row.limitAccount
        this.ruleForm.vipLevel = row.vipLevel ? row.vipLevel.split(",").map((o) => Number(o)) : []
        this.ruleForm.status = row.status
        this.ruleForm.sort = row.sort
        this.ruleForm.rateType = row.rateType
        this.ruleForm.receivingCardNo = row.receivingCardNo
        this.emitName = "update"
      }
      this.visible = true
    },
    handleChangeCustomerType() {
      if (this.ruleForm.paymentCustomerType === 1) {
        this.ruleForm.receivingCardNo = '平台客服'
      } else {
        this.ruleForm.receivingCardNo = '三方客服'
      }
    },
    handleSubmitBefor() {
      if (this.ruleForm.isType === 1 && this.ruleForm.periodAmount !== '') {
        let minAmount = 100000000
        let maxAmount = 0
        let msg = ''
        this.ruleForm.periodAmount.split(',').forEach(e => {
          const amount = Number(e)
          if (isNaN(amount) || amount <= 0) {
            msg = '固定金额的每项值必须为大于0的数字'
            return
          }
          if (amount < minAmount) {
            minAmount = amount
          }
          if (amount > maxAmount) {
            maxAmount = amount
          }
        })
        if (msg !== '') {
          return this.$message.error(msg)
        }
        this.ruleForm.minAmount = minAmount
        this.ruleForm.maxAmount = maxAmount
      }
      this.handleSubmit()
    }
  }
}
</script>

<style lang="scss" scoped>
.validate-tip {
  padding-left: 6px
}
.el-select,
.el-input {
  width: 250px;
}
.amount ::v-deep.el-form-item__content {
  display: flex;
}
</style>
