import { rateTypes } from "@/libs/options";
import { filterColumns } from "@/utils"; 
const CanChooseColumns = function () {
  return [
    {
      title: this.$t("支付名称"),
      key: "accountName",
      align: "center",
    },
    {
      title: this.$t("收款方式"),
      key: "receivingType",
      align: "center",
      width: 100,
    },
    {
      title: this.$t("收款信息"),
      key: "message",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{row.reveivingName}</p>
              <p>{row.receivingBank}</p>
              <p>{row.receivingCardNo}</p>
            </div>
          );
        },
      },
    },
    {
      title: this.$t("币种"),
      key: "rateType",
      align: "center",
      component: {
        render: (h, row) => {
          let rateTypeArray = [];
          if (row.rateType) {
            row.rateType.split(",").forEach((e) => {
              const rateType = rateTypes.find((v) => v.key === Number(e));
              if (rateType) {
                rateTypeArray.push(rateType.label);
              }
            });
          }
          return <span>{rateTypeArray.toString()}</span>;
        },
      },
    },
    {
      title: this.$t("付款类型"),
      key: "type",
      align: "center",
      component: {
        render: (h, row) => {
          const payType = this.payTypes.find((e) => e.key === row.type);
          return <span>{payType?.label}</span>;
        },
      },
    },
    {
      title: this.$t("充值金额类型"),
      key: "isType",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>{row.isType ? "充值固定金额" : "充值范围金额"}</span>;
        },
      },
    },
    {
      title: this.$t("最低额度"),
      key: "minAmount",
      align: "center",
    },
    {
      title: this.$t("最高额度"),
      key: "maxAmount",
      align: "center",
    },
    {
      title: this.$t("充值固定金额"),
      key: "periodAmount",
      align: "center",
    },
    {
      title: this.$t("上限额度"),
      key: "limitAccount",
      align: "center",
    },
    {
      title: this.$t("已收款"),
      key: "receivedAmount",
      align: "center",
      component: {
        render: (h, row) => {
          return (
            <span class={this.$parseColor(row.receivedAmount)}>
              {row.receivedAmount}
            </span>
          );
        },
      },
    },
    {
      title: this.$t("VIP等级"),
      key: "vipLevel",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>VIP{row.vipLevel}</span>;
        },
      },
    },
    {
      title: this.$t("赠送比例"),
      key: "givePercent",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>{parseFloat(row.givePercent)}%</span>;
        },
      },
    },
    {
      title: this.$t("状态"),
      key: "status",
      align: "center",
      width: 100,
      component: {
        render: (h, row) => {
          return (
            <span>
              {this.permission.StartStop ? (
                <d-switch
                  v-model={row.status}
                  beforeChange={() => this.handleChangeStatus(row)}
                >
                  <span slot="close">{this.$t("备用")}</span>
                </d-switch>
              ) : (
                (row.status === 0 && this.$t("启用")) || this.$t("备用")
              )}
            </span>
          );
        },
      },
    },
    {
      title: this.$t("修改时间"),
      key: "updateTime",
      align: "center",
      width: 150,
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.updateTime)}</span>;
        },
      },
    },
    {
      title: this.$t("创建时间"),
      key: "createTime",
      align: "center",
      width: 150,
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.createTime)}</span>;
        },
      },
    },
    {
      title: this.$t("操作员"),
      align: "center",
      key: "operatorName",
      width: 90,
    },
    {
      title: this.$t("操作"),
      key: "actions",
      align: "center",
      width: 280,
      fixed: "right",
      component: {
        render: (h, row) => {
          const { Update, Max, Delete } = this.permission;
          const updateCommon = () => this.$refs.EditDialogControl.open(row);
          const updateDigital = () => this.$refs.ActionDigitalDialogControl.open(row);
          const updateProxy = () => this.$refs.ProxyDialogControl.open(row);
          return (
            <span>
              {Update && (
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit-outline"
                  onClick={row.type === 0 ? updateDigital : row.type === 6 ? updateProxy : updateCommon}
                >
                  {this.$t("编辑")}
                </el-button>
              )}
              {Max && (
                <el-button
                  type="warning"
                  size="mini"
                  onClick={() =>
                    this.handleResetCap({ id: row.id, rateType: row.rateType })
                  }
                >
                  {this.$t("重置上限")}
                </el-button>
              )}
              {Delete && (
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  onClick={() => this.handleDelete(row)}
                >
                  {this.$t("删除")}
                </el-button>
              )}
            </span>
          );
        },
      },
    },
  ];
};

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}
