var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("编辑权限组"),
        visible: _vm.visible,
        width: "60%",
        "close-on-click-modal": false,
        top: "5vh",
        "custom-class": "custom-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: { model: _vm.ruleForm, rules: _vm.rules, size: "small" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("权限组名称"),
                prop: "groupName",
                "label-width": "100px",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: _vm.$t("2-20个字符") },
                model: {
                  value: _vm.ruleForm.groupName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "groupName", $$v)
                  },
                  expression: "ruleForm.groupName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "谷歌验证码",
                "label-width": "100px",
                prop: "googleCode",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                model: {
                  value: _vm.ruleForm.googleCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "googleCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.googleCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "menuIdList" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    border: "",
                    data: _vm.items,
                    "header-cell-style": function () {
                      return { "text-align": "center" }
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand", width: "50", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-table",
                              {
                                staticClass: "expand-table",
                                attrs: {
                                  border: "",
                                  data: row.children,
                                  "show-header": false,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { width: "200", prop: "text" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (props) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "el-table-cell",
                                                staticStyle: {
                                                  "padding-left": "60px",
                                                },
                                              },
                                              [_vm._v(_vm._s(props.row.text))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { width: "150" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (props) {
                                          return [
                                            props.row.hasChildren
                                              ? _vm._l(
                                                  props.row.children,
                                                  function (item) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: item.id,
                                                        staticClass:
                                                          "el-table-cell",
                                                      },
                                                      [
                                                        _c(
                                                          "el-checkbox",
                                                          {
                                                            on: {
                                                              change: function (
                                                                value
                                                              ) {
                                                                return _vm.handleChildChange(
                                                                  value,
                                                                  [
                                                                    row.id,
                                                                    props.row
                                                                      .id,
                                                                    item.id,
                                                                  ],
                                                                  true
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item.checked,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "checked",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.checked",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.text)
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "el-table-cell",
                                                  },
                                                  [
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        on: {
                                                          change: function (
                                                            value
                                                          ) {
                                                            return _vm.handleChildChange(
                                                              value,
                                                              [
                                                                row.id,
                                                                props.row.id,
                                                              ],
                                                              true
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            props.row.checked,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              props.row,
                                                              "checked",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "props.row.checked",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("列表显示")
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (props) {
                                          return [
                                            props.row.hasChildren
                                              ? _vm._l(
                                                  props.row.children,
                                                  function (item) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: item.id,
                                                        staticClass:
                                                          "el-table-cell",
                                                      },
                                                      _vm._l(
                                                        item.children,
                                                        function (child) {
                                                          return _c(
                                                            "el-checkbox",
                                                            {
                                                              key: child.id,
                                                              on: {
                                                                change:
                                                                  function (
                                                                    value
                                                                  ) {
                                                                    return _vm.handleChildChange(
                                                                      value,
                                                                      [
                                                                        row.id,
                                                                        props
                                                                          .row
                                                                          .id,
                                                                        child.parentId,
                                                                        child.id,
                                                                      ],
                                                                      false
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  child.checked,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      child,
                                                                      "checked",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "child.checked",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  child.text
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  }
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "el-table-cell",
                                                  },
                                                  _vm._l(
                                                    props.row.children,
                                                    function (item) {
                                                      return _c(
                                                        "el-checkbox",
                                                        {
                                                          key: item.id,
                                                          on: {
                                                            change: function (
                                                              value
                                                            ) {
                                                              return _vm.handleChildChange(
                                                                value,
                                                                [
                                                                  row.id,
                                                                  props.row.id,
                                                                  item.id,
                                                                ],
                                                                false
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value: item.checked,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "checked",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.checked",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.text)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("菜单"),
                      prop: "text",
                      align: "center",
                      width: "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("权限"), width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { value: _vm._checkedChildren(row.id) },
                                on: {
                                  change: function (value) {
                                    return _vm._onCheckedAllChildren(
                                      value,
                                      row.id
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("全部")))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("敏感权限") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._checkedGrandChilren(row.id).visible
                              ? _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      value: _vm._checkedGrandChilren(row.id)
                                        .checked,
                                    },
                                    on: {
                                      change: function (value) {
                                        return _vm._onCheckedAllGrandChildren(
                                          value,
                                          row.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("全部")))]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }