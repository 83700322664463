import { filterColumns } from "@/utils";
import { roomTypes } from "@/libs/options";
const CanChooseColumns = function () {
  return [
    {
      title: "聊天室分类",
      key: "roomType",
      align: "center",
      component: {
        render: (h, row) => {
          const str = roomTypes.filter((item) => {
            if (item.value == row.roomType) {
              return item;
            }
          });
          return <span>{str[0].label}</span>;
        },
      },
    },
    {
      title: "聊天室名称",
      key: "roomList",
      align: "center",
      component: {
        render: (h, row) => {
          const arr = JSON.parse(row.roomList);
          let str = "";
          if (arr && arr.length) {
            arr.forEach((item) => {
              str += item.name + " ";
            });
          }
          return <span>{str}</span>;
        },
      },
    },
    {
      title: "助手名称",
      key: "assistantList",
      align: "center",
      component: {
        render: (h, row) => {
          const arr = JSON.parse(row.assistantList);
          let str = "";
          if (arr && arr.length) {
            arr.forEach((item, index) => {
              if (index < arr.length - 1) {
                str += item.name + ",";
              } else {
                str += item.name;
              }
            });
          }
          return (
            <div>
              <div>{str}</div>
              <el-button
                type="small"
                style="margin-top:5px"
                onClick={() => {
                  this.$refs.robot.open();
                }}
              >
                查看
              </el-button>
            </div>
          );
        },
      },
    },
    {
      title: "创建时间",
      key: "createTime",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.createTime)}</span>;
        },
      },
    },
    {
      title: "操作人",
      key: "updateUser",
      align: "center",
    },
    {
      title: "操作",
      key: "actions",
      align: "center",
      width: 200,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission;
          return (
            <div>
              {Update && (
                <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-edit"
                  onClick={() => {
                    this.$refs.ActionDialogControl.open(row);
                  }}
                >
                  {this.$t("编辑")}
                </el-button>
              )}
              {Delete && (
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  onClick={() => this.handleDelete(row)}
                >
                  {this.$t("删除")}
                </el-button>
              )}
            </div>
          );
        },
      },
    },
  ];
};

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}
