<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card" @tab-click="handleTabChange">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive><component ref="comRef" :is="activeName" @child="goStockTab" @refresh="handleRefresh" /></keep-alive>
    </template>
  </div>
</template>
<script>
import { parentMixin } from '@/mixins/tab-items'
import StockTab from './StockTab'
import CategoryTab from './CategoryTab'
import CommentTab from './CommentTab'

export default {
  name: 'InteractiveStock',
  components: {
    StockTab,
    CategoryTab,
    CommentTab
  },
  mixins: [parentMixin],
  data() {
    return {
      tabs: [
        { label: '六合图库', name: 'StockTab' },
        { label: '图库类别', name: 'CategoryTab' },
        { label: '评论管理', name: 'CommentTab' }
      ],
      refresh: false
    }
  },
  methods: {
    goStockTab(categoryName, lotteryId, source) {
      this.activeName = 'StockTab'
      this.$nextTick(() => {
        if (this.$refs.comRef.setPictureCategoryName) {
          this.$refs.comRef.setPictureCategoryName(categoryName, lotteryId, source)
        }
      })
    },
    handleRefresh() {
      this.refresh = true
    },
    handleTabChange() {
      this.$nextTick(() => {
        if (this.refresh && this.$refs.comRef.handlePictureCategoryPageList) {
          this.$refs.comRef.handlePictureCategoryPageList()
        }
      })
    }
  }
}
</script>
