<template>
  <el-dialog :title="title" :visible.sync="visible" width="400px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('支付方式名称')" prop="paywayName">
        <el-input v-model="ruleForm.paywayName" style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('支付方式标识')" prop="paywayCode">
        <el-input v-model="ruleForm.paywayCode" style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('付款类型')" prop="type">
        <el-select v-model="ruleForm.type" clearable :placeholder="$t('请选择付款类型')" style="width: 100%">
          <el-option v-for="item of $parent.payTypes" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('支付方式图标')" prop="icon">
        <el-input v-show="false" v-model="ruleForm.icon" />
        <DUpload
          v-model="ruleForm.icon"
          folder="bank-icon"
          :on-success="handleSuccess"
          accept="image/png,image/webp"
        />
        <span>{{ $t('只支持.png,.webp格式') }}</span>
      </el-form-item>
      <el-form-item :label="$t('全屏支付图标')" prop="icon">
        <el-input v-show="false" v-model="ruleForm.bigIcon" />
        <DUpload
          v-model="ruleForm.bigIcon"
          folder="bank-icon"
          :on-success="handleBigIconSuccess"
          accept="image/png,image/webp"
        />
        
        <span style="width: 184px; display: inline-block;">{{ $t('提示：支持上传PNG,WEBP图片，单张大小不超过2MB') }}</span>
      </el-form-item>
      <el-form-item :label="'排序'" prop="name">
        <el-input v-model="ruleForm.sort" style="width: 240px" type="number"   :placeholder="$t('1-999')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import DUpload from '@/components/DUpload'
import { getToken } from '@/utils/auth'
const getDefaultForm = () => ({
  paywayName: '',
  paywayCode: '',
  type: '',
  icon: '',
  sort: null,
  bigIcon: '',
  status: 0
})
export default {
  components: { DUpload },
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      action: `${process.env.VUE_APP_FILE_UPLOAD}`,
      headers: { 'X-AUTH-TOKEN': getToken() },
      rules: {
        paywayName: [
          { required: true, message: this.$t('请输入支付方式名称'), trigger: 'blur' }
        ],
        paywayCode: [
          { required: true, message: this.$t('请输入支付方式标识'), trigger: 'change' }
        ],
        type: [{ required: true, message: this.$t('请选择付款类型'), trigger: 'change' }],
        icon: [
          { required: true, message: this.$t('请上传支付方式图标'), trigger: 'blur' }
        ],
        bigIcon: [
          { required: true, message: this.$t('请上传全屏支付图标'), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? this.$t('添加支付方式') : this.$t('编辑支付方式')
    }
  },
  methods: {
    open(row) {
      if (row) {
        this.ruleForm.paywayId = row.paywayId
        this.ruleForm.paywayName = row.paywayName
        this.ruleForm.paywayCode = row.paywayCode
        this.ruleForm.type = row.type
        this.ruleForm.icon = row.icon
        this.ruleForm.bigIcon = row.bigIcon
        this.emitName = 'update'
      } else {
        this.emitName = 'create'
      }
      this.visible = true
    },
    handleSuccess(data) {
      this.ruleForm.icon = data + ''
    },
    handleBigIconSuccess(data) {
      this.ruleForm.bigIcon = data + ''
    }
  }
}
</script>
<style lang="scss">
.icon-uploader {
  line-height: 1;
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409EFF;
    }
  }

  img {
    width: 120px;
    height: 120px;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
}

</style>
