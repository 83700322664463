<template>
  <div class="app-container">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane v-for="tab of tabs" :key="tab.name" :label="tab.label" :name="tab.name" />
    </el-tabs>
    <component :is="activeName" />
  </div>
</template>
<script>
import { parentMixin } from '@/mixins/tab-items'
import HbTab from './hb'
// import LevelTab from './level'
import RecodeTab from './recode'

export default {
  name: 'vipRewardMange_index',
  components: { HbTab, RecodeTab },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      activeName: 'HbTab',
      permissionTabs: [],
      tabs: [
        { label: this.$t('红包配置'), name: 'HbTab' },
        // { label: this.$t('VIP红包等级管理'), name: 'LevelTab' },
        { label: this.$t('VIP红包领取记录'), name: 'RecodeTab' }
      ]
    }
  },
  created() {
    this.activeName = this.$route.query.tab || 'HbTab'
  }
}
</script>
