var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Google验证",
        width: "560px",
        visible: _vm.visible,
        "append-to-body": "",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        opened: _vm.handleOpened,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px", "label-suffix": ":" } },
        [
          _c("el-form-item", { attrs: { label: "二维码" } }, [
            _vm.ruleForm.googleQrcode
              ? _c("div", { attrs: { id: "qrcode" } })
              : _vm._e(),
          ]),
          _c("el-form-item", { attrs: { label: "密钥" } }, [
            _c("p", [_vm._v(_vm._s(_vm.ruleForm.googleKey))]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.close } },
            [_vm._v(" 确认 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }