import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('时间'),
      key: 'dateTime',
      align: 'center',
      width: 100
    },
    {
      title: this.$t('充值入款'),
      key: 'countRechargeOffline',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.countRechargeOffline }&nbsp;({ row.countRechargeOfflineMember }{this.$t('人')})</span>)
        }
      }
    },
    {
      title: this.$t('第三方入款'),
      key: 'countRechargeOnline',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.countRechargeOnline }&nbsp;({ row.countRechargeOnlineMember }{this.$t('人')})</span>)
        }
      }
    },
    {
      title: '人工加款',
      key: 'countOperatorAdd',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.countOperatorAdd }&nbsp;({ row.countOperatorAddMember }人)</span>)
        }
      }
    },
    {
      title: '微信扫码\n（人工加款）',
      key: 'countOperatorWxAdd',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.countOperatorWxAdd }&nbsp;({ row.countOperatorWxAddMember }人)</span>)
        }
      }
    },
    {
      title: '其他补款',
      key: 'countOperatorQtAdd',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.countOperatorQtAdd }&nbsp;({ row.countOperatorQtAddMember }人)</span>)
        }
      }
    },
    {
      title: '优惠',
      key: 'countPromotion',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span
              class='primary-type cursor-pointer hover-underline'
              onClick={() => this.$refs.SaleDialogControl.open(row.dateTime)}
            >
              { row.countPromotion }&nbsp;({ row.countPromotionMember }人)
            </span>)
        }
      }
    },
    {
      title: '活动回收',
      key: 'Activityrecycling',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span
              class='primary-type cursor-pointer hover-underline'
              onClick={() => this.$refs.RecycleDialogControl.open(row.dateTime)}
            >
              { row.activityMoney }&nbsp;({ row.activityMember }人)
            </span>)
        }
      }
    },
    {
      title: '提现出款',
      key: 'countWithdraw',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.countWithdraw }&nbsp;({ row.countWithdrawMember }人)</span>)
        }
      }
    },
    {
      title: '人工扣款',
      key: 'countOperatorSubtraction',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.countOperatorSubtraction }&nbsp;({ row.countOperatorSubtractionMember }人)</span>)
        }
      }
    },
    {
      title: '入款合计',
      key: 'countIn',
      align: 'center'
    },
    {
      title: '出款合计',
      key: 'countOut',
      align: 'center'
    },
    {
      title: '账面收益',
      key: 'countIncome',
      align: 'center'
    },
    {
      title: '实际收益',
      key: 'countRealIncome',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.countRealIncome < 0) {
            return (<span class='danger-type'>{ row.countRealIncome }</span>)
          }
          return (<span>{ row.countRealIncome }</span>)
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
