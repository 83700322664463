import Layout from '@/layout'

export default {
  path: '/Config',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Config',
  alwaysShow: true,
  meta: { title: '产品配置', icon: 'product-config' },
  children: [
    {
      path: '/Config_Basic',
      component: () => import('@/views/Config/Basic'),
      name: 'ConfigBasic',
      meta: { title: '基本配置', noCache: true }
    },
    {
      path: '/Config_Display',
      component: () => import('@/views/Config/Display'),
      name: 'ConfigDisplay',
      meta: { title: '展示配置', noCache: true }
    }
  ]
}

