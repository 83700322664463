var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "600px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "彩种类型", prop: "lotteryName" } },
            [
              _c("el-input", {
                attrs: {
                  value:
                    _vm.ruleForm.seriesName + "-" + _vm.ruleForm.lotteryName,
                  disabled: "",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "官方直播", prop: "officialStatus" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.officialStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "officialStatus", $$v)
                    },
                    expression: "ruleForm.officialStatus",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.officialStatus == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "直播视频", prop: "officialVideoPath" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("请输入直播视频url") },
                    model: {
                      value: _vm.ruleForm.officialVideoPath,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "officialVideoPath", $$v)
                      },
                      expression: "ruleForm.officialVideoPath",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "开奖说明", prop: "explanationStatus" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.explanationStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "explanationStatus", $$v)
                    },
                    expression: "ruleForm.explanationStatus",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.explanationStatus == 1
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "说明类型", prop: "explanationType" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.explanationType,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "explanationType", $$v)
                          },
                          expression: "ruleForm.explanationType",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("彩票规则"),
                        ]),
                        _c(
                          "el-radio",
                          {
                            attrs: {
                              disabled: !_vm.explanationTypeArr.includes(
                                _vm.ruleForm.seriesTag
                              ),
                              label: 2,
                            },
                          },
                          [
                            _vm._v("号码详情 "),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    "号码详情仅支持：时时彩系列，PK10系列，快三系列",
                                  placement: "top",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-question" })]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.ruleForm.explanationType == 2
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("官方跳转"),
                            prop: "lotteryType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.lotteryType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "lotteryType", $$v)
                                },
                                expression: "ruleForm.lotteryType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 10 } }, [
                                _vm._v(_vm._s(_vm.$t("官彩"))),
                              ]),
                              _c("el-radio", { attrs: { label: 20 } }, [
                                _vm._v(_vm._s(_vm.$t("平台私彩"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.lotteryType == 10
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("官网名称"),
                                prop: "officialName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    _vm.$t("请输入官网名称,最多10个字符"),
                                },
                                model: {
                                  value: _vm.ruleForm.officialName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "officialName", $$v)
                                  },
                                  expression: "ruleForm.officialName",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.ruleForm.lotteryType == 10
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("官网地址"),
                                prop: "officialPath",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: _vm.$t("请输入官网URL") },
                                model: {
                                  value: _vm.ruleForm.officialPath,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "officialPath", $$v)
                                  },
                                  expression: "ruleForm.officialPath",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 确认 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }