import Layout from '@/layout'

const router = {
  path: '/Version',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Version',
  alwaysShow: true,
  meta: { title: '版本管理', icon: 'version' },
  children: [
    {
      path: '/Version_Client',
      component: () => import('@/views/version/client'),
      name: 'VersionClient',
      meta: { title: '客户端', noCache: true }
    },
    {
      path: '/Version_Anchor',
      component: () => import('@/views/version/anchor'),
      name: 'VersionAnchor',
      meta: { title: '主播端', noCache: true }
    }
  ]
}

export default router
