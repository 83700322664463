<template>
  <el-dialog :title="$t('Interactive_Match.LeagueTab.编辑赛事')" :visible.sync="visible" width="560px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('Interactive_Match.LeagueTab.支持推荐')" prop="recommend">
        <el-radio-group v-model="ruleForm.recommend">
          <el-radio :label="1">{{ $t('是') }}</el-radio>
          <el-radio :label="0">{{ $t('否') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('Interactive_Match.LeagueTab.热门推荐')" prop="hot">
        <el-radio-group v-model="ruleForm.hot">
          <el-radio :label="1">{{ $t('是') }}</el-radio>
          <el-radio :label="0">{{ $t('否') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('Interactive_Match.LeagueTab.赛事展示')" prop="showStatus">
        <el-radio-group v-model="ruleForm.showStatus">
          <el-radio :label="1">{{ $t('是') }}</el-radio>
          <el-radio :label="0">{{ $t('否') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('Interactive_Match.LeagueTab.推荐玩法')" prop="recommendPlay">
        <el-checkbox-group v-model="ruleForm.recommendPlay">
          <el-checkbox v-for="(label, value) in recommendPlayMaps" :key="value" name="recommendPlay" :label="value">{{ label }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('Interactive_Match.LeagueTab.联赛等级')" prop="level">
        <el-input v-model.number="ruleForm.level" :placeholder="$t('请输入m_n区间数字', { text: '0-100' })" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

function getRecommendPlay(recommendPlay, arr, isArr = false) {
  let tmp = []
  if (typeof recommendPlay === 'string') {
    if (isArr) {
      arr.forEach(item => {
        if (recommendPlay.indexOf(item.label) > -1) {
          tmp.push(+item.key)
        }
      })
    } else {
      const recommendArr = recommendPlay.split(',')
      for (const key in arr) {
        // const label = arr[key]
        recommendArr.forEach(item => {
          if (arr[key] === item) {
            tmp.push(key)
          }
        })
      }
    }
  }
  return tmp
}

const getDefaultForm = () => ({
  recommend: null,
  hot: null,
  showStatus: null,
  // recommendType: [1, 2],
  recommendPlay: [],
  level: null
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        recommend: [{ required: true, message: this.$t('请选择', { text: this.$t('Interactive_Match.LeagueTab.支持推荐') }), trigger: 'change' }],
        hot: [{ required: true, message: this.$t('请选择', { text: this.$t('Interactive_Match.LeagueTab.热门推荐') }), trigger: 'change' }],
        showStatus: [{ required: true, message: this.$t('请选择', { text: this.$t('Interactive_Match.LeagueTab.赛事展示') }), trigger: 'change' }],
        recommendPlay: [
          { type: 'array', required: true, message: this.$t('请选择', { text: this.$t('Interactive_Match.LeagueTab.推荐玩法') }), trigger: 'change' }
        ],
        // recommendType: [
        //   { type: 'array', required: true, message: this.$t('请选择', { text: this.$t('Interactive_Match.LeagueTab.玩法类型') }), trigger: 'change' }
        // ],
        level: [
          { required: true, message: this.$t('请输入', { text: this.$t('Interactive_Match.LeagueTab.联赛等级') }), trigger: 'blur' },
          { pattern: /^([1-9]|[1-9]\d|100)$/, message: this.$t('请输入m_n区间数字', { text: '0-100' }), trigger: 'blur' }
        ]
      },
      playTypes: [
        { key: 1, label: this.$t('竞彩') },
        { key: 2, label: this.$t('国际') }
      ],
      competitionType: 1,
      footballRecommendPlayMaps: this.$t('footballRecommendPlayMaps'),
      basketRecommendPlayMaps: this.$t('basketRecommendPlayMaps'),
      recommendPlayMaps: this.$t('recommendPlayMaps')
    }
  },
  computed: {
    // recommendPlayMaps() {
    //   const competitionType = this.competitionType
    //   const selectTypes = this.ruleForm.recommendType
    //   const selectLen = selectTypes.length
    //   let newMaps = {}
    //   if (competitionType === 1) { // 足球
    //     switch (selectLen) {
    //       case 0: newMaps = {}; break
    //       case 1:
    //         newMaps = selectTypes[0] === 1 ? pick(this.footballRecommendPlayMaps, ['1', '2']) : pick(this.footballRecommendPlayMaps, ['11', '12', '14'])
    //         break
    //       case 2:newMaps = this.footballRecommendPlayMaps; break
    //     }
    //   } else { // 篮球
    //     switch (selectLen) {
    //       case 0: newMaps = {}; break
    //       case 1:
    //         newMaps = selectTypes[0] === 1 ? pick(this.basketRecommendPlayMaps, ['6', '7', '8']) : pick(this.basketRecommendPlayMaps, ['11', '13', '14'])
    //         break
    //       case 2:newMaps = this.basketRecommendPlayMaps; break
    //     }
    //   }
    //   return newMaps
    // }
  },
  methods: {
    open(row) {
      if (row) {
        this.competitionType = row.sportType
        this.ruleForm.competitionId = row.competitionId
        this.ruleForm.sportType = row.sportType
        this.ruleForm.recommend = row.recommend
        this.ruleForm.tenantCode = row.tenantCode
        // this.ruleForm.recommendType = getRecommendPlay(row.recommendType, this.playTypes, true)
        this.ruleForm.hot = row.hot
        this.ruleForm.showStatus = row.showStatus
        this.ruleForm.recommendPlay = getRecommendPlay(row.recommendPlay, row.sportType === 1 ? this.footballRecommendPlayMaps : this.basketRecommendPlayMaps)
        this.ruleForm.level = row.level
      }
      this.visible = true
    }
  }
}
</script>
