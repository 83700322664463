<template>
  <span style="margin-left: 6px">
    <el-button icon="el-icon-refresh-right" style="margin-right: 6px" @click="$emit('refresh')" />
    <el-popover
      placement="bottom-end"
      trigger="click"
      popper-class="d-table-fixed-popper"
    >
      <el-checkbox-group v-model="_checkList">
        <el-checkbox
          v-for="column in columns"
          :key="column.key"
          :label="column.key"
          style="display: block"
        >{{ column.title }}</el-checkbox>
      </el-checkbox-group>
      <el-button slot="reference" icon="el-icon-s-unfold" />
    </el-popover>
  </span>
</template>
<script>
export default {
  props: {
    checkList: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    _checkList: {
      get() {
        return this.checkList
      },
      set(list) {
        console.log('listlist',list);
        
        this.$emit('change-column', list)
      }
    }
  }
}
</script>
