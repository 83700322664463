<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="search.tenantCode"
        placeholder="请选择租户"
        @change="_handleChangeTenant"
      >
        <el-option
          v-for="item in tenants"
          :key="item.tenantCode"
          :value="item.tenantCode"
          :label="item.tenantName"
        />
      </el-select>
    </div>
    <div class="filter-container">
      <el-input v-model="search.groupName" clearable placeholder="权限组名" class="filter-item" style="width: 160px" />
      <el-button type="primary" :disabled="!search.tenantCode" icon="el-icon-search" class="filter-item" @click="handleSearch">
        查询
      </el-button>
      <el-button v-if="permission.Add" :disabled="!search.tenantCode" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">
        添加权限组
      </el-button>

      <!-- <el-button v-if="$P$" type="success" @click="onSyncPermission">
          同步超管权限
        </el-button> -->
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="false"
    />

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch()"
    />
    <CreateDialog ref="CreateDialogControl" @create="handleCreateGroupMenu" />

    <UpdateDialog ref="UpdateDialogControl" @update="handleUpdateGroupMenu" />
    <GoogleDialog ref="GoogleDialog" @validate="handleGoogleDialog"></GoogleDialog>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getGroupList, updateGroupStatus, addGroupMenu, updateGroupMenu, deleteGroup } from '@/api/permission'
import { CreateDialog, UpdateDialog } from './components'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import GoogleDialog from '@/views/components/GoogleDialog'
export default {
  name: 'TenantAssign',
  components: { CreateDialog, UpdateDialog, GoogleDialog },
  mixins: [TablePageMixin(getGroupList, false)],
  data() {
    return {
      tenant: null,
      search: {},
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  computed: {
    ...mapState('app', ['tenants'])
  },
  created() {
    this.getTenantList()
  },
  methods: {
    ...mapActions('app', ['getTenantList']),
    _handleChangeTenant() {
      this.handleRefresh()
    },
    onCreate() {
      this.$refs.CreateDialogControl.open(this.search.tenantCode)
    },

    // onSyncPermission() {
    //   syncPermissions().then(([_, err]) => {
    //     if (!err) {
    //       this.$message.success('同步权限成功')
    //     }
    //   })
    // },

    handleChangeStatus({ id, status, tenantCode }) {
      return new Promise((resolve) => {
        this.$confirm(`确认${status === 1 ? '启用' : '关闭'}此分组???`, '系统提示', { type: 'warning' }).then(() => {
          updateGroupStatus({
            id,
            tenantCode,
            status: ~status + 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功!')
            }
          })
        }).catch(() => {})
      })
    },

    handleCreateGroupMenu({ field, cancel, close }) { //
      addGroupMenu({ ...field, platformTenantCode: this.search.tenantCode }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('创建成功!')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleUpdateGroupMenu({ field, cancel, close }) {
      updateGroupMenu({ ...field, platformTenantCode: this.search.tenantCode }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('编辑成功!')
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    handleDelete({ id, tenantCode }) {
      this.$refs.GoogleDialog.open({ id, tenantCode }, '确认删除此分组?')
    },
    handleGoogleDialog({ field, cancel, close }) {  
      deleteGroup({...field}).then(([_, err]) => {
        this.$refs.GoogleDialog.submitting = false
        if (!err) {
          this.$refs.GoogleDialog.handleColse()
          this.$message.success(this.$t('删除成功'))
          this.handleFetch()
        }
      })
    },

    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
