var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("修改密码"),
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("新密码"), prop: "newPwd", required: "" },
            },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("6-12位") },
                model: {
                  value: _vm.ruleForm.newPwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "newPwd", $$v)
                  },
                  expression: "ruleForm.newPwd",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("确认密码"),
                prop: "newPwdConfirm",
                required: "",
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("6-12位") },
                model: {
                  value: _vm.ruleForm.newPwdConfirm,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "newPwdConfirm", $$v)
                  },
                  expression: "ruleForm.newPwdConfirm",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }