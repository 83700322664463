<template>
  <el-dialog
    :title="$t('投注流水')"
    :visible.sync="visible"
    width="75%"
    top="5vh"
    @open="handleRefresh"
  >
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="datetimerange"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="yyyy-MM-dd HH:mm:ss"
        :disabled="!summary"
        :default-time="['00:00:00', '23:59:59']"
      />
      <div v-if="summary" style="float:right">{{ $t('开播时段') }}: {{ summary.onlineDate | parseTime }} {{ $t('至') }} {{ summary.offlineDate | parseTime }}</div>
    </div>
    <div class="filter-container">
      <el-input v-model="search.nickName" :placeholder="$t('会员昵称')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.memberName" :placeholder="$t('会员账号')" class="filter-item" style="width: 140px" />
      <el-button type="primary" class="filter-item" @click="handleRefresh">{{ $t('检索') }}</el-button>
      <div v-if="summary" style="float: right">
        {{ $t('待开奖投注金额') }}：{{ summary.countAmount }}
        {{ $t('有效投注金额') }}：{{ summary.realAmount }}
        {{ $t('中奖金额') }}：{{ summary.countWin }}
        {{ $t('主播收入') }}：{{ summary.anchorIncome }}
      </div>
    </div>

    <el-table v-loading="loading" :data="items" v-bind="options">
      <el-table-column :label="$t('会员昵称')" prop="nickName" align="center" />
      <el-table-column :label="$t('会员账号')" prop="memberName" align="center" />
      <el-table-column :label="$t('彩种')" prop="lotteryName" align="center" />
      <el-table-column :label="$t('玩法分类')" prop="playClassName" align="center" />
      <el-table-column :label="$t('内容')" prop="numbers" align="center" />
      <el-table-column :label="$t('投注金额')" prop="amount" align="center" />
      <el-table-column :label="$t('中奖金额')" prop="winAmount" align="center" />
      <el-table-column :label="$t('投注分成')" align="center">
        <template slot-scope="{ row }">
          {{ row.betsIncome }}
          <br>
          ({{ row.betsPercent }}%)
        </template>
      </el-table-column>
      <el-table-column :label="$t('状态')" prop="status" align="center">
        <template slot-scope="{ row }">{{ filterStatuLabel(row.status) }}</template>
      </el-table-column>
      <el-table-column :label="$t('投注时间')" prop="createTime" align="center" width="120">
        <template slot-scope="{ row }">{{ row.createTime | parseTime }}</template>
      </el-table-column>
      <el-table-column :label="$t('开奖时间')" prop="lotteryTime" align="center" width="120">
        <template slot-scope="{ row }">{{ row.lotteryTime | parseTime }}</template>
      </el-table-column>
    </el-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

  </el-dialog>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import StreamMixin from './stream'
import { getLiveBroadcastBetsRecordList, getLiveBroadcastBetsRecordSummary } from '@/api/live'
import { bettingStatus } from '@/libs/options'

export default {
  mixins: [TablePageMixin(getLiveBroadcastBetsRecordList, false), StreamMixin],
  methods: {
    filterStatuLabel(status) {
      const tmp = bettingStatus.find(o => o.key === status)
      return tmp && tmp.label || ''
    },
    implementationFetched() {
      getLiveBroadcastBetsRecordSummary({
        ...this.implementationGetParams()
      }).then(([data, err]) => {
        if (!err) this.summary = data
      })
    }
  }
}
</script>
