import request from '@/plugins/axios'

export function getVersionList(data) {
  return request({
    url: '/version/versionList',
    method: 'post',
    data
  })
}

export function addVersion(data) {
  return request({
    url: '/version/addVersion',
    method: 'post',
    data
  })
}

export function cancelPublishVersion(data) {
  return request({
    url: '/version/cancelPublishVersion',
    method: 'post',
    data
  })
}
