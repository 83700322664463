import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('代理账号'),
      key: 'memberName',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p class='primary-type cursor-pointer hover-underline' onClick={() => this.$refs.UserDialogControl.open(row.memberId)}>{ row.memberName }</p>
              <p>ID: {row.memberId}</p>
              <p>{this.$t('代理等级')}: {row.agencyLevel}</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('上级代理'),
      key: 'memberId',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.oneLevelAgencyId === '0') {
            return ''
          }
          return (
            <div>
              <p >{ row.oneLevelAgencyName }</p>
              <p>ID: {row.oneLevelAgencyId}</p>
              <p>{this.$t('代理等级')}: {row.oneLevelAgencyLevel}</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('人数'),
      key: 'members',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{row.members}人,{Number(row.balance)}</span>
        }
      }
    },
    {
      title: this.$t('注册'),
      key: 'registerMembers',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(1, row)}>{ row.registerMembers }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('首充'),
      key: 'firstRechargeMembers',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(2, row)}>{ row.firstRechargeMembers }</p>
            </div>
          )
        }
      }
    },
    {
      title: '复充',
      key: 'multipleRechargeMembers',
      align: 'center',
      tips: '统计时间范围内，充值次数大于等于2的用户',
      component: {
        render: (h, row) => {
          return (
            <span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(13, row)}>
              <p>人数：{ row.multipleRechargeMembers }</p>
              <p>金额：{ Number(row.multipleRechargeAmount) }</p>
            </span>
          )
        }
      }
    },
    {
      title: this.$t('充值'),
      key: 'rechargeMembers',
      tips: '充值=充值管理+第三方充值管理+手动加款（微信扫码）',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(3, row)}>
              <p>人数：{ row.rechargeMembers }</p>
              <p>金额：{ Number(row.rechargeAmount) }</p>
            </span>
          )
        }
      }
    },
    {
      title: this.$t('提现'),
      key: 'withdrawMembers',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(4, row)}>
              <p>人数：{ row.withdrawMembers }</p>
              <p>金额：{ Number(row.withdrawAmount) }</p>
            </span>
          )
        }
      }
    },
    {
      title: this.$t('其他补款'),
      key: 'manualQtDeposit',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(53, row)}>
              <p>人数：{ row.manualQtMembers }</p>
              <p>金额：{ Number(row.manualQtDeposit) }</p>
            </span>
          )
        }
      }
    },
    {
      title: this.$t('出入款盈亏'),
      tips: '出入款盈亏=充值-提现+其他补款',
      key: 'weekProfit',
      align: 'center'
    },
    {
      title: this.$t('手动加减款'),
      key: 'manualMembers',
      align: 'center',
      minWidth: 120,
      component: {
        render: (h, row) => {
          return (<span>
            <span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(51, row)}>
              <p>加款人数：{ row.manualMembersAdd }</p>
              <p>加款金额：{ Number(row.manualDeposit) }</p>
            </span>
            <span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(52, row)}>
              <p>减款人数：{ row.manualMembersDeduction }</p>
              <p>减款金额：{ Number(row.manualDeduction) }</p>
            </span>
          </span>)
        }
      }
    },
    {
      title: this.$t('活动礼金'),
      key: 'activityMembers',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(6, row)}>
              <p>人数：{ row.activityMembers }</p>
              <p>金额：{ Number(row.activityAmount) }</p>
            </span>
          )
        }
      }
    },
    {
      title: this.$t('返水'),
      key: 'cashbackMembers',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(7, row)}>
              <p>人数：{ row.cashbackMembers }</p>
              <p>金额：{ Number(row.cashbackAmount) }</p>
            </span>
          )
        }
      }
    },
    {
      title: this.$t('代理返佣'),
      key: 'rebateMembers',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(8, row)}>
              <p>人数：{ row.rebateMembers }</p>
              <p>金额：{ Number(row.rebateAmount) }</p>
            </span>
          )
        }
      }
    },
    {
      title: this.$t('彩票'),
      key: 'lotteryProfit',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p><span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(9, row)}>{this.$t('投注人数')}: { row.lotteryBetMembers } {this.$t('人')} </span></p>
              <p>{this.$t('有效投注')}: <span class='primary-type cursor-pointer hover-underline' onClick={() => this.$refs.OrderDetailDialogControl.open(row, this.implementationGetParams())}>{ row.lotteryBetAmount }</span></p>
              <p>{this.$t('中奖金额')}: { row.lotteryWinAmount }</p>
              <p>{this.$t('盈亏')}: { row.lotteryProfit }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('竞彩体育'),
      key: 'sportProfit',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p><span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(10, row)}>{this.$t('投注人数')}: { row.sportBetMembers } {this.$t('人')}</span></p>
              <p>{this.$t('有效投注')}: { row.sportBetAmount }</p>
              <p>{this.$t('中奖金额')}: { row.sportWinAmount }</p>
              <p>{this.$t('盈亏')}: { row.sportProfit }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('原生体育'),
      key: 'nativeSportProfit',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p><span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(11, row)}>{this.$t('投注人数')}: { row.nativeSportBetMembers } {this.$t('人')}</span></p>
              <p>{this.$t('有效投注')}: { row.nativeSportBetAmount }</p>
              <p>{this.$t('中奖金额')}: { row.nativeSportWinAmount }</p>
              <p>{this.$t('盈亏')}: { row.nativeSportProfit }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('第三方'),
      key: 'thirdProfit',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p><span class='primary-type cursor-pointer hover-underline' onClick={() => this.openDetailsDialog(12, row)}>{this.$t('投注人数')}: { row.thirdBetMembers } {this.$t('人')}</span></p>
              <p>{this.$t('有效投注')}: { row.thirdBetAmount }</p>
              <p>{this.$t('中奖金额')}: { row.thirdWinAmount }</p>
              <p>{this.$t('盈亏')}: { row.thirdProfit }</p>
            </div>
          )
        }
      }
    },
    {
      key: 'deficitAmount',
      align: 'center',
      renderHeader: (h) => {
        return (
          <div>
            <span>{this.$t('负盈利')}</span>
            <el-tooltip effect='dark' content='负盈利=彩票盈亏+体育盈亏+第三方盈亏-活动礼金-返水' placement='top'>
              <svg-icon icon-class='question-outline'></svg-icon>
            </el-tooltip>
          </div>
        )
      },
      component: {
        render: (h, row) => {
          return (
            <span>
              {this.$t('负盈利')}: {row.deficitAmount}
            </span>
          )
        }
      }
    },
    {
      key: 'rollingAmount',
      align: 'center',
      renderHeader: (h) => {
        return (
          <div>
            <span>{this.$t('打码量')}</span>
            {/* <el-tooltip effect='dark' content='打码量负盈利=彩票盈亏-活动礼金-返水' placement='top'>
              <svg-icon icon-class='question-outline'></svg-icon>
            </el-tooltip> */}
          </div>
        )
      },
      component: {
        render: (h, row) => {
          return (
            <span>
              {this.$t('打码量')}: {row.rollingAmount}
            </span>
          )
        }
      }
    }
    // {
    //   title: '状态',
    //   key: 'auditStatus',
    //   align: 'center',
    //   width: 180,
    //   component: {
    //     render: (h, row) => {
    //       if (row.auditStatus === 1) {
    //         return (
    //           this.permission.Audit &&
    //           <span>
    //             <el-button type='primary' size='mini' onClick={() => this._handlePass(row, true)}>通过</el-button>
    //             <el-button type='danger' size='mini' onClick={() => this._handleReject(row, false)}>拒绝</el-button>
    //           </span>
    //         )
    //       } else if (row.auditStatus === 2) {
    //         return <span>已审核</span>
    //       } else {
    //         return <span style='color: red'>拒绝原因: { row.remark }</span>
    //       }
    //     }
    //   }
    // },
    // {
    //   title: this.$t('操作'),
    //   key: 'actions',
    //   align: 'center',
    //   width: 100,
    //   component: {
    //     render: (h, row) => {
    //       return (<el-button type='text' onClick={() => this.goDetail(row)}>{this.$t('查看详情')}</el-button>)
    //     }
    //   }
    // }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
