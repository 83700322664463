const CanChooseColumns = function() {
  return [
    {
      title: this.$t('彩种名称'),
      key: 'lotteryName',
      align: 'center'
    },
    {
      title: this.$t('玩法分组名称'),
      key: 'playName',
      align: 'center'
    },
    {
      title: this.$t('状态'),
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch v-model={row.status} activeValue={1} inactiveValue={0} disabled={!this.permission.StartStop} />
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      component: {
        render: (h, row) => {
          return (
            this.permission.Update && <el-button type='text' onClick={this._handleUpdate.bind(this, row)}>{this.$t('保存修改')}</el-button>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
