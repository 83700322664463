import request from '@/plugins/axios'

// 获取彩种数据---系列及其下彩种 不带游戏  用于 开奖管理/彩种设置用
export function getLotteryNavigationList(operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/navigation/lottery/list`,
    method: 'get'
  })
}

// 获取彩种数据---系列及其下彩种 带游戏  用于 彩种启停/彩种开关
export function getLotteryNavigation(operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/navigation`,
    method: 'get'
  })
}

// 11、获取玩法分类数据
export function getPlayclass(params = {}, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/play/class`,
    method: 'get',
    params
  })
}

export function getPlayClassList(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/playClass/list`,
    method: 'post',
    data
  })
}

// 12、获取赔率设置数据
export function getOddsList(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/attr/odds/list`,
    method: 'post',
    data
  })
}

// 批量修改赔率信息
export function updateLotteryAttrOdds(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/attr/odds/update`,
    method: 'post',
    data
  })
}

// 5、获取玩法设置数据 一级玩法
export function getPlayList(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/play/list`,
    method: 'post',
    data
  })
}

// 6、批量修改玩法信息
export function batchUpdatePlay(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/play/update`,
    method: 'post',
    data
  })
}

// 7、获取玩法分类设置数据 二级玩法

// 8、批量修改玩法分类信息
export function batchUpdatePlayClass(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/playClass/update`,
    method: 'post',
    data
  })
}

// 9、获取玩法分类分组设置数据
export function getPlayGroupList(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/play/group/list`,
    method: 'post',
    data
  })
}

// 10、批量修改玩法分类分组信息
export function updatePlayClassGroup(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/play/group/update`,
    method: 'post',
    data
  })
}

// 获取彩种信息接口
export function getLotteryInfo(params, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/info`,
    method: 'get',
    params
  })
}

// 获取彩种信息接口
export function getProfitStatic(params, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/profit/stat`,
    method: 'get',
    params
  })
}

// 获取彩种开奖时间
export function getLotteryTime(params, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/time`,
    method: 'get',
    params
  })
}

// 13、修改彩种信息-- 修改说明的时候用 -- 彩种设置里面使用
export function updateLotteryExplain(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/explain/update`,
    method: 'post',
    data
  })
}

// 修改彩种信息-- 开奖管理的修改使用
export function updateLotteryInfo(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/updateLotteryInfo`,
    method: 'post',
    data
  })
}

// 修改彩种信息-- 根据名称查询杀号用户信息
export function killNumberUserByName(params) {
  return request({
    url: '/userCenter/killNumberUser/byName',
    method: 'get',
    params
  })
}

// 2、获取彩种下的租户  (只有平台-彩种启停使用)
export function getLotteryTenantList(data = {}) {
  return request({
    url: '/platform/lottery/getLotteryTenantList',
    method: 'post',
    data
  })
}

// 平台管理修改租户彩种的开关
export function updateTenantStatus(data = {}) {
  return request({
    url: '/platform/lottery/update/tenant/status',
    method: 'post',
    data
  })
}

// 获取平台总开关状态(平台管理---彩种启停)
export function lotteryTotalStatus(params = {}) {
  return request({
    url: '/platform/lottery/total/status',
    method: 'get',
    params
  })
}

// 平台彩种总开关启停/显隐(平台管理---彩种启停)
export function updateLotteryTotalStatus(data = {}) {
  return request({
    url: '/platform/lottery/total/status/update',
    method: 'post',
    data
  })
}

// 平台修改彩种启停/显隐  (彩种设置--彩种开关)
export function updateLotteryStatus(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/status/update`,
    method: 'post',
    data
  })
}

// 查询彩种封盘时间
export function getStopBetsTime(params = {}) {
  return request({
    url: '/platform/lottery/StopBetsTimeSecond',
    method: 'get',
    params
  })
}

// 3、平台修改彩种提前封盘时间
export function updateStopBetTimeSecond(params = {}) {
  return request({
    url: '/platform/lottery/updateStopBetTimeSecond',
    method: 'get',
    params
  })
}

// 2、获取租户彩种开关数据
export function getLotteryStatusList(params, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/status/list`,
    method: 'get',
    params
  })
}

// 租户开奖管理列表
export function getDrawLotteryList(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/open/prize/list`,
    method: 'post',
    data
  })
}

// 开奖管理统计
export function getDrawLotteryStatistics(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/tenantStatistics`,
    method: 'post',
    data
  })
}

// 开奖管理，投注详情
export function getBetsDetail(params, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/betsDetail`,
    method: 'get',
    params
  })
}

// 重新开奖 --撤销开奖
export function cancelWithdraw(data) {
  return request({
    url: '/bets/issue/withdraw',
    method: 'post',
    data
  })
}

// 重新开奖 --彩种期号撤单
export function cancelIssueLottery(data) {
  return request({
    url: '/bets/lottery/issue/cancel',
    method: 'post',
    data,
    timeout: 20000
  })
}

// 重新开奖 --彩种期号开奖结算
export function settlementIssueLottery(data) {
  return request({
    url: '/bets/lottery/issue/settlement',
    method: 'post',
    data,
    timeout: 20000
  })
}

// 人工开奖
export function manualOpenPrize(data, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform/' : ''}manual/open/prize`,
    method: 'post',
    data
  })
}

// 预开奖
export function manualAddLotteryNo(data) {
  return request({
    url: '/tenant/lottery/manual/updateLotteryNo',
    method: 'post',
    data: { ...data, status: 9 }
  })
}

/* 彩种设置重置功能接口start *******************************************************/
// 重置所有玩法基础数据
export function resetTenant(params = {}) {
  return request({
    url: '/tenant/reset',
    method: 'get',
    params
  })
}

// 重置玩法
export function resetPlay(params = {}, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/resetPlay`,
    method: 'get',
    params
  })
}

// 重置玩法分类
export function resetPlayClass(params = {}, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/resetPlayClass`,
    method: 'get',
    params
  })
}

// 重置玩法分类组
export function resetPlayClassGroup(params = {}, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/resetPlayClassGroup`,
    method: 'get',
    params
  })
}

// 重置玩法分类组属性
export function resetPlayClassGroupAttribute(params = {}, operatorType) {
  return request({
    url: `/${operatorType === 1 ? 'platform' : 'tenant'}/lottery/resetPlayClassGroupAttribute`,
    method: 'get',
    params
  })
}

// 获取所有彩种系列及彩种及彩种玩法
export function navigationLotteryList(params) {
  return request({
    url: '/tenant/navigation/lottery/list',
    method: 'get',
    params
  })
}

/* 彩种设置重置功能接口end **************************************************************/

// 赛事管理

export function getMatchList(data) {
  return request({
    url: '/sport/filter/match',
    method: 'post',
    data
  })
}

export function footballThirdMatchList(data) {
  return request({
    url: '/football/third/match/list',
    method: 'post',
    data
  })
}

export function basketballThirdMatchList(data) {
  return request({
    url: '/basketball/third/match/list',
    method: 'post',
    data
  })
}

export function getMatchBaseOdds(params) {
  return request({
    url: '/sport/now/baseOdds',
    method: 'get',
    params
  })
}

export function updateMatchBaseOdds(data) {
  return request({
    url: '/sport/update/baseOdds',
    method: 'post',
    data
  })
}

export function updateMatch(data) {
  return request({
    url: '/sport/update/hot',
    method: 'post',
    data
  })
}

export function getOddsLog(data) {
  return request({
    url: '/sport/odds/log',
    method: 'post',
    data
  })
}

export function getCompetitionList(data) {
  return request({
    url: '/sport/filter/competition',
    method: 'post',
    data
  })
}

export function updateCompetition(data) {
  return request({
    url: '/sport/competition/update',
    method: 'post',
    data
  })
}

// 系列彩种数据[所有语言]
// export function getLangLottery(data) {
//   return request({
//     url: '/tenant/lottery/list/task',
//     method: 'post',
//     data
//   })
// }
export function getLangLottery(params = {}) {
  return request({
    url: '/tenant/lottery/list/task',
    method: 'get',
    params
  })
}

// 手动弹窗开奖消息推送（新增开奖号码、编辑开奖号码）
export function manualOpenPrizeShow(data, operatorType) {
  return request({
    url: `${operatorType === 1 ? '/platform' : ''}/manual/open/prizeShow`,
    method: 'post',
    data
  })
}

// 手动开奖前通知C端
export function manualOpenPrizeShowTip(data, operatorType) {
  return request({
    url: `${operatorType === 1 ? '/platform' : ''}/manual/open/prizeShow/tip`,
    method: 'post',
    data
  })
}

// 平台开奖结果对比
export function showResultCompared(data, operatorType) {
  return request({
    url: `${operatorType === 1 ? '/platform' : ''}/manual/open/prizeShow/showResultCompared`,
    method: 'post',
    data
  })
}

// 撤销记账查询
export function getCancelIssuePage(data) {
  return request({
    url: '/tenant/lottery/cancel/getCancelIssuePage',
    method: 'post',
    data
  })
}

// 撤销记账下载
export function cancelDownloadRecord(data) {
  return request({
    url: '/tenant/lottery/cancel/downloadRecord',
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 600000
  })
}

// 一键开启游戏
export function updateSeriesStatus(data) {
  return request({
    url: '/lottery/tenant/series/updateSeriesStatus',
    method: 'post',
    data
  })
}

// 获取一键开启游戏状态
export function getTenantSeriesStatus(params) {
  return request({
    url: '/lottery/tenant/series/getTenantSeriesStatus',
    method: 'get',
    params
  })
}

// 撤销开奖
export function settlementedBetsCancel(data) {
  return request({
    url: '/platform/settlemented/bets/cancel',
    // url: '/tenant/settlemented/bets/cancel',
    method: 'post',
    data
  })
}
