const CanChooseColumns = function() {
  return [
    {
      title: this.$t('结算时间'),
      key: 'settlementTime',
      align: 'center'
    },
    {
      title: this.$t('家族分成(礼物/投注/房费)'),
      key: 'income',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>{ row.giftIncome }/{ row.betsIncome }/{ row.payroomIncome }</div>
          )
        }
      }
    },
    {
      title: this.$t('可提现金额'),
      key: 'withdrawalAmount',
      align: 'center'
    },
    {
      title: this.$t('打款状态'),
      key: 'settlementStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>{ (row.settlementStatus === 1 || row.settlementStatus === 2) ? this.$t('未打款') : this.$t('已打款') }</div>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      component: {
        render: (h, row) => {
          return (
            <el-button type='success' size='mini' onClick={() => this.$refs.ActionDialogControl.open(row)}>{this.$t('明细详情')}</el-button>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
