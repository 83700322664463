<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button type="success" icon="el-icon-plus" class="filter-item" @click="$refs.CreateDialogControl.open()">
        {{ $t('创建渠道') }}
      </el-button>
      <el-select v-model="search.channelStatus" clearable :placeholder="$t('渠道状态')" class="filter-item" style="width: 160px">
        <el-option :label="$t('启用')" :value="1" />
        <el-option :label="$t('停用')" :value="0" />
      </el-select>
      <el-input v-model="search.channelName" clearable :placeholder="$t('渠道名')" class="filter-item" style="width: 160px" />
      <el-input v-model="search.channelHost" clearable :placeholder="$t('渠道域名')" class="filter-item" style="width: 200px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleRefresh">
        {{ $t('查询') }}
      </el-button>
      <el-button type="success" style="float: right" @click="$router.replace({ name: 'Channel' })">
        {{ $t('返回统计') }}
      </el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      @refresh="handleFetch"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <CreateDialog ref="CreateDialogControl" @create="handleCreate" />
    <LinkDialog ref="LinkDialogControl" />
  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getChannelPageList, addChannel, delChannel, editChannelStatus, resetPassword } from '@/api/statistical'
import { CreateDialog, LinkDialog } from './components'

export default {
  name: 'ChannelStatisticalMannge',
  components: { CreateDialog, LinkDialog },
  mixins: [TablePageMixin(getChannelPageList)],
  data() {
    return {
      search: {},
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  methods: {
    handleCreate({ field, cancel, close }) {
      addChannel({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.$alert(`<div style="font-size: 16px; line-height: 2.5"><div>${this.$t('渠道名')}：${data.channelName}</div><div>${this.$t('密码')}：${data.password}</div></div>`, this.$t('系统提示'), {
            dangerouslyUseHTMLString: true,
            center: true,
            showClose: false
          }).then(() => {
            this.handleRefresh()
          })
        } else {
          cancel()
        }
      })
    },
    handleChangeStatus(channelStatus, { id }) {
      this.$confirm(this.$t('是否修改渠道状态?'), this.$t('系统提示'), {
        center: true,
        showClose: false
      }).then(() => {
        editChannelStatus({ id, channelStatus }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('修改成功?'))
          }
        })
      }).catch(() => {})
    },
    handleReset({ channelName }) {
      this.$confirm(this.$t('是否重置密码?'), this.$t('系统提示'), {
        center: true,
        showClose: false
      }).then(() => {
        resetPassword({ channelName }).then(([data, err]) => {
          if (!err) {
            this.$message.success(this.$t('重置成功'))
            this.$alert(`<div style="font-size: 16px; line-height: 2.5"><div>${this.$t('渠道名')}：${data.channelName}</div><div>${this.$t('密码')}：${data.password}</div></div>`, this.$t('系统提示'), {
              dangerouslyUseHTMLString: true,
              center: true,
              showClose: false
            }).then(() => {})
          }
        })
      }).catch(() => {})
    },
    handleDelete({ id }) {
      this.$confirm(`<div><p style="font-size: 18px; margin-bottom: 20px">${this.$t('是否删除该渠道?')}</p><p style="color: red;">${this.$t('删除后该渠道下的推广链接不在统计新增会员！')}</p></div>`, this.$t('提示'), {
        dangerouslyUseHTMLString: true,
        center: true,
        showClose: false
      }).then(() => {
        delChannel(id).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
