import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: '前端排序',
      key: 'sort',
      align: 'center'
    },
    {
      title: '线路名称',
      key: 'domainName',
      align: 'center'
    },
    {
      title: '域名',
      key: 'domainUrl',
      align: 'center'
    },
    {
      title: '域名类型',
      key: 'domainType',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.domainTypes[row.domainType] }</span>)
        }
      }
    },
    {
      title: '所属租户',
      key: 'tenantCode',
      align: 'center'
    },
    {
      title: '切出次数',
      key: 'outNums',
      align: 'center'
    },
    {
      title: '切入次数',
      key: 'inNums',
      align: 'center'
    },
    {
      title: '线路状态',
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          if (this.permission.StartStop) {
            return (
              <d-switch v-model={row.status} beforeChange={() => this.handleChangeStatus(row)} active-value={1} inactive-value={0}>
                <span slot='open'>启用</span>
                <span slot='close'>禁用</span>
              </d-switch>
            )
          } else {
            return (<span>{ row.status === 1 ? '启用' : '禁用' }</span>)
          }
        }
      }
    },
    {
      title: '备注',
      key: 'remark',
      align: 'center'
    },
    {
      title: '更新用户',
      key: 'updateUser',
      align: 'center'
    },
    {
      title: '更新时间',
      key: 'updateTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{ this.$parseTime(row.updateTime) }</span>
        }
      }
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center',
      component: {
        render: (h, row) => {
          if (this.permission.Update) {
            return (<el-link type='primary' onClick={() => this.$refs.ActionDialogControl.open(row)}>修改</el-link>)
          }
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
