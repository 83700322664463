import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('彩票系列'),
      key: 'seriesName',
      align: 'center'
    },
    {
      title: this.$t('彩种'),
      key: 'lotteryName',
      align: 'center'
    },
    {
      title: this.$t('官方直播'),
      key: 'nickName',
      align: 'center', // 官方直播：officialStatus     0表示关闭， 1表示开启
      component: {
        render: (h, row) => {
          return (
            <span>
              {row.officialStatus == 0 ? '关闭' : '开启'}
            </span>
          )
        }
      }
    },
    {
      title: this.$t('开奖说明'),
      key: 'familyName',
      align: 'center', // 开奖说明explanationStatus  0表示关闭，1表示开启
      component: {
        render: (h, row) => {
          return (
            <span>
              {row.explanationStatus == 0 ? '关闭' : '开启'}
            </span>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 240,
      component: {
        render: (h, row) => {
          return (
            <span>
              <el-button type='text' onClick={() => this.$refs.LiveEditDialogControl.open(row)}>{this.$t('直播配置')}</el-button>
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
