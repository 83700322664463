var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "310px" },
            attrs: {
              maxlength: "20",
              placeholder: _vm.$t("类目名称"),
              clearable: "",
            },
            model: {
              value: _vm.search.categoryName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "categoryName", $$v)
              },
              expression: "search.categoryName",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleSearch },
            },
            [_vm._v(_vm._s(_vm.$t("查询")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-refresh-right" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("重置")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { float: "right" },
              attrs: { type: "success", icon: "el-icon-plus" },
              on: { click: _vm.onCreate },
            },
            [_vm._v(_vm._s(_vm.$t("新建类目")))]
          ),
        ],
        1
      ),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          options: _vm.options,
          "filter-column": _vm.filterColumn,
        },
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("CategoryDialog", {
        ref: "categoryDialog",
        on: { create: _vm.handleCreate, update: _vm.handleUpdate },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品迁移",
            visible: _vm.dialogFormVisible,
            width: "420px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.cancel,
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "当前商品类目：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.form.categoryName))])]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品数量：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.form.quantity))])]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "迁移商品类目：",
                    "label-width": _vm.formLabelWidth,
                    prop: "categoryNewId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "220px" },
                      attrs: {
                        placeholder: _vm.$t("请输入类目名称查询"),
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.form.categoryNewId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "categoryNewId", $$v)
                        },
                        expression: "form.categoryNewId",
                      },
                    },
                    _vm._l(_vm.category, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          disabled: item.categoryName === _vm.form.categoryName,
                          value: item.id,
                          label: item.categoryName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm("form")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }