<template>
  <el-dialog
    title="任务内容"
    :visible.sync="visible"
    width="50%"
    top="5vh"
    :close-on-click-modal="false"
    @open="handleOpen"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" v-loading="loading" :model="ruleForm" :rules="rules" label-width="120px" style="height: 70vh;overflow-y: auto;">
      <h3>{{ $t('参与资格') }}<span class="sign-tips">{{ $t('输入0为不限制') }}</span></h3>
      <el-form-item class="tiaojian">
        <el-form-item label="总充值金额大于等于" prop="rechargeTotal">
          <el-input v-model="ruleForm.rechargeTotal" />
        </el-form-item>
        <el-form-item label="今日充值总额大于等于" prop="rechargeDaily">
          <el-input v-model="ruleForm.rechargeDaily" />
        </el-form-item>
      </el-form-item>
      <el-form-item class="tiaojian">
        <el-form-item label="总投注金额大于等于" prop="betsTotal">
          <el-input v-model="ruleForm.betsTotal" />
        </el-form-item>
        <el-form-item label="今日投注金额大于等于" prop="betsDaily">
          <el-input v-model="ruleForm.betsDaily" />
        </el-form-item>
      </el-form-item>
      <el-form-item :label="$t('VIP限制')" prop="vipLimitType">
        <el-radio v-model="ruleForm.vipLimitType" :label="1">{{ $t('开启限制') }}</el-radio>
        <el-radio v-model="ruleForm.vipLimitType" :label="0">{{ $t('不限制') }}</el-radio>
      </el-form-item>
      <el-form-item v-show="ruleForm.vipLimitType === 1">
        <el-checkbox-group v-model="ruleForm.vipLimitList">
          <el-checkbox v-for="item of vipLevelList" :key="item.vipLevel" :label="item.vipLevel">{{ `VIP${item.vipLevel}` }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('任务类型')" prop="isDaily">
        <el-radio v-for="item of $t('taskType')" :key="item.key" v-model="ruleForm.isDaily" :label="item.key">{{ item.label }}</el-radio>
      </el-form-item>
      <el-form-item :label="$t('任务方式')" prop="modeType">
        <el-radio v-for="item of $t('taskMethod')" :key="item.key" v-model="ruleForm.modeType" :label="item.key">{{ item.label }}</el-radio>
      </el-form-item>
      <h3>{{ $t('任务目标') }}</h3>
      <div v-if="ruleForm.modeType === 0">
        <el-form-item :label="$t('是否限制方式')">
          <el-radio v-model="ruleForm.target" :label="0">{{ $t('不限制方式') }}</el-radio>
          <el-radio v-model="ruleForm.target" :label="1">{{ $t('限制方式') }}</el-radio>
        </el-form-item>
        <div v-show="ruleForm.target === 1">
          <el-form-item class="rwmb rwmb1">
            <el-radio v-model="ruleForm.accountType" class="zhradio" :label="1">{{ $t('公司账号') }}</el-radio>
            <el-radio v-model="ruleForm.paywayType" :disabled="ruleForm.accountType === 2" :label="0">{{ $t('不限制') }}</el-radio>
            <el-radio v-model="ruleForm.paywayType" :disabled="ruleForm.accountType === 2" :label="1">{{ $t('限制') }}</el-radio>
            <div v-show="ruleForm.paywayType === 1" class="czfs">
              <el-checkbox-group v-model="ruleForm.paywayIdList">
                <el-checkbox v-for="item of companyAccountList" :key="item.id" :disabled="ruleForm.accountType === 2" :label="item.id">{{ item.accountName }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item class="rwmb rwmb2">
            <el-radio v-model="ruleForm.accountType" class="zhradio" :label="2">{{ $t('第三方账号') }}</el-radio>
            <el-radio v-model="ruleForm.thirdPaywayType" :disabled="ruleForm.accountType === 1" :label="0">{{ $t('不限制') }}</el-radio>
            <el-radio v-model="ruleForm.thirdPaywayType" :disabled="ruleForm.accountType === 1" :label="1">{{ $t('限制') }}</el-radio>
            <div v-show="ruleForm.thirdPaywayType === 1" class="czfs">
              <el-checkbox-group v-model="ruleForm.thirdPaywayIdList">
                <el-checkbox v-for="item of thirdAccountList" :key="item.payPlatformCode" :disabled="ruleForm.accountType === 1" :label="item.payPlatformCode">{{ item.payPlatformName }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
        </div>
        <el-form-item :label="$t('充值成功金额')" prop="rechargeAmount">
          <el-input v-model="ruleForm.rechargeAmount" style="width: 100px" />
        </el-form-item>
        <el-form-item class="tiaojian">
          <el-form-item :label="$t('有效充值单数')" prop="rechargeTimes">
            <el-input v-model="ruleForm.rechargeTimes" />
          </el-form-item>
          <el-form-item :label="$t('充值金额大于等于')" prop="rechargeTimesAmount">
            <el-input v-model="ruleForm.rechargeTimesAmount" />
          </el-form-item>
          <span class="my-label-title">{{ $t('才算有效充值单数') }}</span>
        </el-form-item>
      </div>
      <div v-else-if="ruleForm.modeType === 1">
        <el-form-item :label="$t('投注类型')">
          <el-radio v-for="item of this.$t('betsType')" :key="item.key" v-model="ruleForm.betsType" :label="item.key" @change="handleBetsType">{{ item.label }}</el-radio>
        </el-form-item>
        <el-form-item v-show="ruleForm.betsType === 3">
          <el-radio v-model="ruleForm.betsType3" :label="0">{{ $t('不限制赛事') }}</el-radio>
          <el-radio v-model="ruleForm.betsType3" :label="1">{{ $t('限制赛事') }}</el-radio>
        </el-form-item>
        <div v-show="ruleForm.betsType !== 3 || ruleForm.betsType3 === 1">
          <el-form-item class="rwmb rwmb1">
            <el-radio v-show="ruleForm.betsType === 3" v-model="ruleForm.betsType3Ball" class="zhradio" :label="1">{{ $t('足球') }}</el-radio>
            <el-radio v-model="ruleForm.betsCompetitionIdsType1" :disabled="ruleForm.betsType3Ball === 2" :label="0">{{ $t('不限制') }}</el-radio>
            <el-radio v-model="ruleForm.betsCompetitionIdsType1" :disabled="ruleForm.betsType3Ball === 2" :label="1">{{ $t('限制') }}</el-radio>
            <div v-show="ruleForm.betsCompetitionIdsType1 === 1" class="sfxz">
              <el-checkbox-group v-model="ruleForm.betsCompetitionIdsList1">
                <el-checkbox v-for="(item, index) of targetList.filter(e => e.typeId !== 3 || e.sportType === 1)" :key="index" :disabled="ruleForm.betsType3Ball === 2" :label="item.targetId">{{ item.targetName }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item v-show="ruleForm.betsType === 3" class="rwmb rwmb1">
            <el-radio v-model="ruleForm.betsType3Ball" class="zhradio" :label="2">{{ $t('篮球') }}</el-radio>
            <el-radio v-model="ruleForm.betsCompetitionIdsType2" :disabled="ruleForm.betsType3Ball === 1" :label="0">{{ $t('不限制') }}</el-radio>
            <el-radio v-model="ruleForm.betsCompetitionIdsType2" :disabled="ruleForm.betsType3Ball === 1" :label="1">{{ $t('限制') }}</el-radio>
            <div v-show="ruleForm.betsCompetitionIdsType2 === 1" class="sfxz">
              <el-checkbox-group v-model="ruleForm.betsCompetitionIdsList2">
                <el-checkbox v-for="(item, index) of targetList.filter(e => e.sportType === 2)" :key="index" :disabled="ruleForm.betsType3Ball === 1" :label="item.targetId">{{ item.targetName }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
        </div>
        <el-form-item :label="$t('有效投注金额')" prop="betsAmount">
          <el-input v-model="ruleForm.betsAmount" style="width: 100px" />
        </el-form-item>
        <el-form-item class="tiaojian">
          <el-form-item :label="$t('有效注单数')" prop="betsTimes">
            <el-input v-model="ruleForm.betsTimes" />
          </el-form-item>
          <el-form-item :label="$t('注单投注金额大于等于')" prop="betsTimesAmout">
            <el-input v-model="ruleForm.betsTimesAmout" />
          </el-form-item>
          <span class="my-label-title">{{ $t('才算有效注单数') }}</span>
        </el-form-item>
      </div>
      <div v-else-if="ruleForm.modeType === 2">
        <el-form-item :label="$t('邀请有效好友')" prop="inviteNums">
          <el-input v-model="ruleForm.inviteNums" style="width: 100px" />
        </el-form-item>
        <el-form-item :label="$t('有效好友条件')" />
        <el-form-item :label="$t('充值金额≥')" prop="inviteRechargeAmount">
          <el-input v-model="ruleForm.inviteRechargeAmount" />
        </el-form-item>
        <el-form-item :label="$t('投注金额≥')" prop="inviteBetsAmount">
          <el-input v-model="ruleForm.inviteBetsAmount" />
        </el-form-item>
      </div>
      <div v-else-if="ruleForm.modeType === 3">
        <el-form-item class="tiaojian">
          <span class="my-label-title">{{ $t('满足所有条件即可发放奖励，配置为0则不作为任务目标') }}</span>
        </el-form-item>
        <el-form-item :label="$t('观看时长大于等于')" prop="liveSeeMinites">
          <el-input v-model="ruleForm.liveSeeMinites" style="width: auto;">
            <template slot="append">{{ $t('分钟') }}</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('送礼金额大于等于')" prop="liveGiftAmount">
          <el-input v-model="ruleForm.liveGiftAmount" />
        </el-form-item>
        <el-form-item :label="$t('房费金额大于等于')" prop="liveRoomAmount">
          <el-input v-model="ruleForm.liveRoomAmount" />
        </el-form-item>
        <el-form-item :label="$t('发送消息大于等于')" prop="liveMsgNums">
          <el-input v-model="ruleForm.liveMsgNums" style="width: auto;">
            <template slot="append">{{ $t('条') }}</template>
          </el-input>
        </el-form-item>
      </div>
      <div v-else-if="ruleForm.modeType === 4">
        <el-form-item class="tiaojian">
          <span class="my-label-title">{{ $t('满足所有条件即可发放奖励，配置为0则不作为任务目标') }}</span>
        </el-form-item>
        <el-form-item :label="$t('跟单注单数≥')" prop="followBetsTimes">
          <el-input v-model="ruleForm.followBetsTimes" />
        </el-form-item>
        <el-form-item :label="$t('跟单金额≥')" prop="followBetsAmount">
          <el-input v-model="ruleForm.followBetsAmount" />
        </el-form-item>
      </div>
      <div v-else-if="ruleForm.modeType === 5">
        <el-form-item class="tiaojian">
          <span class="my-label-title">{{ $t('满足所有条件即可发放奖励，配置为0则不作为任务目标') }}</span>
        </el-form-item>
        <el-form-item :label="$t('发帖数量≥')" prop="postsNums">
          <el-input v-model="ruleForm.postsNums" />
        </el-form-item>
        <el-form-item :label="$t('评论数≥')" prop="postsCommentNums">
          <el-input v-model="ruleForm.postsCommentNums" />
        </el-form-item>
        <el-form-item :label="$t('点赞数≥')" prop="postsLikeNums">
          <el-input v-model="ruleForm.postsLikeNums" />
        </el-form-item>
        <el-form-item :label="$t('观看数≥')" prop="postsSeeNums">
          <el-input v-model="ruleForm.postsSeeNums" />
        </el-form-item>
      </div>
      <h3>{{ $t('任务奖励') }}</h3>
      <el-form-item v-if="ruleForm.isDaily === 0" class="tiaojian">
        <table class="table">
          <tr>
            <td>{{ $t('任务天数') }}</td>
            <td>{{ $t('任务奖励') }}</td>
          </tr>
          <tr>
            <td>
              {{ $t('累计一天') }}
            </td>
            <td>
              <el-form-item :label="$t('选择抵用券')" prop="oneCoupanList">
                <el-select v-model="ruleForm.oneCoupanList" multiple collapse-tags>
                  <el-option v-for="item of couponList" :key="item.couponId" :value="item.couponId" :label="item.couponTitle" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('彩金')" prop="oneAmount">
                <el-input v-model="ruleForm.oneAmount" />
              </el-form-item>
              <el-form-item :label="$t('打码倍数')" prop="oneRolling">
                <el-input v-model="ruleForm.oneRolling" />
              </el-form-item>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('累计两天') }}
            </td>
            <td>
              <el-form-item :label="$t('选择抵用券')" prop="twoCoupanList">
                <el-select v-model="ruleForm.twoCoupanList" multiple collapse-tags>
                  <el-option v-for="item of couponList" :key="item.couponId" :value="item.couponId" :label="item.couponTitle" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('彩金')" prop="twoAmount">
                <el-input v-model="ruleForm.twoAmount" />
              </el-form-item>
              <el-form-item :label="$t('打码倍数')" prop="twoRolling">
                <el-input v-model="ruleForm.twoRolling" />
              </el-form-item>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('累计三天') }}
            </td>
            <td>
              <el-form-item :label="$t('选择抵用券')" prop="threeCoupanList">
                <el-select v-model="ruleForm.threeCoupanList" multiple collapse-tags>
                  <el-option v-for="item of couponList" :key="item.couponId" :value="item.couponId" :label="item.couponTitle" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('彩金')" prop="threeAmount">
                <el-input v-model="ruleForm.threeAmount" />
              </el-form-item>
              <el-form-item :label="$t('打码倍数')" prop="threeRolling">
                <el-input v-model="ruleForm.threeRolling" />
              </el-form-item>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('累计四天') }}
            </td>
            <td>
              <el-form-item :label="$t('选择抵用券')" prop="fourCoupanList">
                <el-select v-model="ruleForm.fourCoupanList" multiple collapse-tags>
                  <el-option v-for="item of couponList" :key="item.couponId" :value="item.couponId" :label="item.couponTitle" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('彩金')" prop="fourAmount">
                <el-input v-model="ruleForm.fourAmount" />
              </el-form-item>
              <el-form-item :label="$t('打码倍数')" prop="fourRolling">
                <el-input v-model="ruleForm.fourRolling" />
              </el-form-item>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('累计五天') }}
            </td>
            <td>
              <el-form-item :label="$t('选择抵用券')" prop="fiveCoupanList">
                <el-select v-model="ruleForm.fiveCoupanList" multiple collapse-tags>
                  <el-option v-for="item of couponList" :key="item.couponId" :value="item.couponId" :label="item.couponTitle" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('彩金')" prop="fiveAmount">
                <el-input v-model="ruleForm.fiveAmount" />
              </el-form-item>
              <el-form-item :label="$t('打码倍数')" prop="fiveRolling">
                <el-input v-model="ruleForm.fiveRolling" />
              </el-form-item>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('累计六天') }}
            </td>
            <td>
              <el-form-item :label="$t('选择抵用券')" prop="sixCoupanList">
                <el-select v-model="ruleForm.sixCoupanList" multiple collapse-tags>
                  <el-option v-for="item of couponList" :key="item.couponId" :value="item.couponId" :label="item.couponTitle" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('彩金')" prop="sixAmount">
                <el-input v-model="ruleForm.sixAmount" />
              </el-form-item>
              <el-form-item :label="$t('打码倍数')" prop="sixRolling">
                <el-input v-model="ruleForm.sixRolling" />
              </el-form-item>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('累计七天') }}
            </td>
            <td>
              <el-form-item :label="$t('选择抵用券')" prop="sevenCoupanList">
                <el-select v-model="ruleForm.sevenCoupanList" multiple collapse-tags>
                  <el-option v-for="item of couponList" :key="item.couponId" :value="item.couponId" :label="item.couponTitle" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('彩金')" prop="sevenAmount">
                <el-input v-model="ruleForm.sevenAmount" />
              </el-form-item>
              <el-form-item :label="$t('打码倍数')" prop="sevenRolling">
                <el-input v-model="ruleForm.sevenRolling" />
              </el-form-item>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('全勤奖') }}
            </td>
            <td>
              <el-form-item :label="$t('选择抵用券')" prop="fullCoupanList">
                <el-select v-model="ruleForm.fullCoupanList" multiple collapse-tags>
                  <el-option v-for="item of couponList" :key="item.couponId" :value="item.couponId" :label="item.couponTitle" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('彩金')" prop="fullAmount">
                <el-input v-model="ruleForm.fullAmount" />
              </el-form-item>
              <el-form-item :label="$t('打码倍数')" prop="fullRolling">
                <el-input v-model="ruleForm.fullRolling" />
              </el-form-item>
            </td>
          </tr>
        </table>
      </el-form-item>
      <template v-else>
        <el-form-item :label="$t('选择抵用券')" prop="oneCoupanList">
          <el-select v-model="ruleForm.oneCoupanList" multiple collapse-tags>
            <el-option v-for="item of couponList" :key="item.couponId" :value="item.couponId" :label="item.couponTitle" />
          </el-select>
        </el-form-item>
        <el-form-item class="tiaojian">
          <el-form-item :label="$t('彩金')" prop="oneAmount">
            <el-input v-model="ruleForm.oneAmount" />
          </el-form-item>
          <el-form-item :label="$t('打码倍数')" prop="oneRolling">
            <el-input v-model="ruleForm.oneRolling" />
          </el-form-item>
        </el-form-item>
      </template>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button v-if="emitName !== 'details'" type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getPayReceivingAccountList, getPayWayThirdConfList } from '@/api/cash'
import { getActivityDetail, getActivityTargetList } from '@/api/activity'
import { getList } from '@/api/card.js'
import { getAllVipLevels } from '@/api/app'
import { getRuleForm, getRules } from './params'

export default {
  mixins: [FormDialogMixin(getRuleForm)],
  data() {
    return {
      emitName: 'create',
      redirectStatus: 0,
      firstLinks: [],
      accountRadio: 1,
      companyRadio: 1,
      thirdRadio: 1,
      companyCheckList: [],
      thirdCheckList: [],
      companyAccountList: [],
      thirdAccountList: [],
      couponList: [],
      vipLevelList: [],
      targetList: [],
      rules: getRules.call(this),
      chinese: 'zh_CN',
      english: 'en_US',
      loading: true
    }
  },
  created() {
    this.getCompanyAccountList()
    this.getThirdAccountList()
    this.getCouponList()
    this.getVipLevelList()
  },
  methods: {
    open(id) {
      this.loading = true
      this.ruleForm.activityId = id
      this.visible = true
    },
    getCompanyAccountList() {
      getPayReceivingAccountList({ currentPage: 1, pageSize: 100, status: 0 }).then(([items, err]) => {
        if (!err) {
          this.companyAccountList = items
        }
      })
    },
    getThirdAccountList() {
      getPayWayThirdConfList({ currentPage: 1, pageSize: 100, status: 0 }).then(([items, err]) => {
        if (!err) {
          this.thirdAccountList = items
        }
      })
    },
    getCouponList() {
      getList({ currentPage: 1, pageSize: 100, couponStatus: 1 }).then(([items, err]) => {
        if (!err) {
          this.couponList = items.filter(e => e.couponStatus === 1)
        }
      })
    },
    getVipLevelList() {
      getAllVipLevels().then(([data, err]) => {
        if (!err) {
          this.vipLevelList = data
        }
      })
    },
    handleBetsType(isFirst) {
      if (isFirst !== true) {
        this.ruleForm.betsCompetitionIdsList1 = []
      }
      getActivityTargetList({ typeId: this.ruleForm.betsType }).then(([items, err]) => {
        if (!err) {
          this.targetList = items || []
          this.targetList.forEach(e => {
            const i18nArray = e.i18n.split(',')
            i18nArray.forEach(r => {
              if (r !== '' && r.split(':').length === 2) {
                const langKey = r.split(':')[0]
                const langValue = r.split(':')[1]
                e[langKey] = langValue
              }
            })
          })
        }
      })
    },
    handleOpen() {
      getActivityDetail({ id: this.ruleForm.activityId }).then(([data, err]) => {
        if (!err) {
          this.ruleForm.rechargeTotal = data.rechargeTotal
          this.ruleForm.rechargeDaily = data.rechargeDaily
          this.ruleForm.betsTotal = data.betsTotal
          this.ruleForm.betsDaily = data.betsDaily
          if (data.vipLimit !== '' && data.vipLimit !== null) {
            this.ruleForm.vipLimitType = 1
            this.ruleForm.vipLimitList = data.vipLimit.split(',').map(e => { return Number(e) })
          }
          this.ruleForm.isDaily = data.isDaily || 0
          this.ruleForm.modeType = data.modeType || 0
          if (data.paywayId === '-1' && data.thirdPaywayId === '-1') {
            this.ruleForm.target = 0
          }
          if (data.paywayId !== '-1') {
            this.ruleForm.target = 1
            this.ruleForm.accountType = 1
            if (data.paywayId === '0') {
              this.ruleForm.paywayType = 0
            } else if (data.paywayId) {
              this.ruleForm.paywayIdList = data.paywayId.split(',')
            }
          }
          if (data.thirdPaywayId !== '-1') {
            this.ruleForm.target = 1
            this.ruleForm.accountType = 2
            if (data.thirdPaywayId === '0') {
              this.ruleForm.thirdPaywayType = 0
            } else if (data.thirdPaywayId) {
              this.ruleForm.thirdPaywayIdList = data.thirdPaywayId.split(',')
            }
          }
          this.ruleForm.rechargeAmount = data.rechargeAmount
          this.ruleForm.rechargeTimes = data.rechargeTimes
          this.ruleForm.rechargeTimesAmount = data.rechargeTimesAmount
          this.ruleForm.betsAmount = data.betsAmount
          this.ruleForm.betsTimes = data.betsTimes
          this.ruleForm.betsTimesAmout = data.betsTimesAmout
          this.ruleForm.inviteNums = data.inviteNums
          this.ruleForm.inviteRechargeAmount = data.inviteRechargeAmount
          this.ruleForm.inviteBetsAmount = data.inviteBetsAmount
          this.ruleForm.liveSeeMinites = data.liveSeeMinites
          this.ruleForm.liveGiftAmount = data.liveGiftAmount
          this.ruleForm.liveRoomAmount = data.liveRoomAmount
          this.ruleForm.liveMsgNums = data.liveMsgNums
          this.ruleForm.followBetsTimes = data.followBetsTimes
          this.ruleForm.followBetsAmount = data.followBetsAmount
          this.ruleForm.postsNums = data.postsNums
          this.ruleForm.postsCommentNums = data.postsCommentNums
          this.ruleForm.postsLikeNums = data.postsLikeNums
          this.ruleForm.postsSeeNums = data.postsSeeNums
          if (data.betsCompetitionIds) {
            this.ruleForm.betsType = Number(data.betsCompetitionIds.split(':')[0])
            if (this.ruleForm.betsType === 3) {
              if (data.betsCompetitionIds === '3:-1;-1') {
                this.ruleForm.betsType3 = 0
              } else {
                this.ruleForm.betsType3 = 1
                if (data.betsCompetitionIds.split(':')[1].split(';')[0] !== '0' && data.betsCompetitionIds.split(':')[1].split(';')[0] !== '-1') {
                  this.ruleForm.betsCompetitionIdsType1 = 1
                  this.ruleForm.betsCompetitionIdsList1 = data.betsCompetitionIds.split(':')[1].split(';')[0].split(',')
                }
                if (data.betsCompetitionIds.split(':')[1].split(';').length === 2) {
                  this.ruleForm.betsCompetitionIdsType2 = 1
                  if (data.betsCompetitionIds.split(':')[1].split(';')[1] !== '0' && data.betsCompetitionIds.split(':')[1].split(';')[1] !== '-1') {
                    this.ruleForm.betsCompetitionIdsList2 = data.betsCompetitionIds.split(':')[1].split(';')[1].split(',')
                  }
                }
              }
            } else {
              if (data.betsCompetitionIds.split(':')[1] !== '0') {
                this.ruleForm.betsCompetitionIdsType1 = 1
                this.ruleForm.betsCompetitionIdsList1 = data.betsCompetitionIds.split(':')[1].split(',')
              }
            }
          }
          this.handleBetsType(true)
          if (data.oneCoupan) {
            this.ruleForm.oneCoupanList = data.oneCoupan.split(',')
          }
          if (data.twoCoupan) {
            this.ruleForm.twoCoupanList = data.twoCoupan.split(',')
          }
          if (data.threeCoupan) {
            this.ruleForm.threeCoupanList = data.threeCoupan.split(',')
          }
          if (data.fourCoupan) {
            this.ruleForm.fourCoupanList = data.fourCoupan.split(',')
          }
          if (data.fiveCoupan) {
            this.ruleForm.fiveCoupanList = data.fiveCoupan.split(',')
          }
          if (data.sixCoupan) {
            this.ruleForm.sixCoupanList = data.sixCoupan.split(',')
          }
          if (data.sevenCoupan) {
            this.ruleForm.sevenCoupanList = data.sevenCoupan.split(',')
          }
          if (data.fullCoupan) {
            this.ruleForm.fullCoupanList = data.fullCoupan.split(',')
          }
          if (data.oneAmountRolling && data.oneAmountRolling !== '0.000,1') {
            this.ruleForm.oneAmount = data.oneAmountRolling.split(',')[0]
            this.ruleForm.oneRolling = data.oneAmountRolling.split(',')[1]
          }
          if (data.twoAmountRolling && data.twoAmountRolling !== '0.000,1') {
            this.ruleForm.twoAmount = data.twoAmountRolling.split(',')[0]
            this.ruleForm.twoRolling = data.twoAmountRolling.split(',')[1]
          }
          if (data.threeAmountRolling && data.threeAmountRolling !== '0.000,1') {
            this.ruleForm.threeAmount = data.threeAmountRolling.split(',')[0]
            this.ruleForm.threeRolling = data.threeAmountRolling.split(',')[1]
          }
          if (data.fourAmountRolling && data.fourAmountRolling !== '0.000,1') {
            this.ruleForm.fourAmount = data.fourAmountRolling.split(',')[0]
            this.ruleForm.fourRolling = data.fourAmountRolling.split(',')[1]
          }
          if (data.fiveAmountRolling && data.fiveAmountRolling !== '0.000,1') {
            this.ruleForm.fiveAmount = data.fiveAmountRolling.split(',')[0]
            this.ruleForm.fiveRolling = data.fiveAmountRolling.split(',')[1]
          }
          if (data.sixAmountRolling && data.sixAmountRolling !== '0.000,1') {
            this.ruleForm.sixAmount = data.sixAmountRolling.split(',')[0]
            this.ruleForm.sixRolling = data.sixAmountRolling.split(',')[1]
          }
          if (data.sevenAmountRolling && data.sevenAmountRolling !== '0.000,1') {
            this.ruleForm.sevenAmount = data.sevenAmountRolling.split(',')[0]
            this.ruleForm.sevenRolling = data.sevenAmountRolling.split(',')[1]
          }
          if (data.fullAmountRolling && data.fullAmountRolling !== '0.000,1') {
            this.ruleForm.fullAmount = data.fullAmountRolling.split(',')[0]
            this.ruleForm.fullRolling = data.fullAmountRolling.split(',')[1]
          }
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-tips {
  margin-left: 20px;
  color: #AAAAAA;
  font-weight: 400;
  font-size: 14px;
}

.my-label-title {
  margin-left: 10px;
  font-size: 14px;
  color: #606266;
  font-weight: 700;
}

::v-deep .el-input__inner {
    width: 120px;
}

::v-deep .el-select {
    .el-input__inner {
        width: 180px;
    }
}
::v-deep .el-form-item__label {
  width: auto !important;
  min-width: 120px;
}
.tiaojian ::v-deep .el-form-item__content {
  margin-left: 0 !important;
  display: flex;
}

.rwmb {
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 2.5%;
  margin-right: 2.5%;
  .zhradio {
    padding: 6px 10px;
    background-color: rgba(242, 242, 242, 1);
  }
  .el-radio {
    margin-right: 10px;
  }
}

.rwmb1 ::v-deep .el-form-item__content {
  margin-left: 20px !important;
  .czfs {
    margin-left: 102px;
  }
  .sfxz {
    margin-left: auto;
  }
}

.rwmb2 ::v-deep .el-form-item__content {
  margin-left: 8px !important;
  .czfs {
    margin-left: 114px;
  }
  .sfxz {
    margin-left: auto;
  }
}

.table {
  margin-left: 2.5%;
  width: 95%;
  text-align: center;
  border-collapse:collapse;
  border: solid #d7d7d7;
  border-width: 1px 0 0 1px;
  tr {
    border: solid #d7d7d7;
    border-width: 0 0 1px 0;
    padding: 2px;
  }
  td {
    border: solid #d7d7d7;
    border-width: 0 1px 0 0;
    padding: 2px;
    height: 50px;
    ::v-deep .el-input__inner {
      width: 80px;
    }
    ::v-deep .el-select {
        .el-input__inner {
            width: 180px;
        }
    }
    &:first-child {
      min-width: 90px;
    }
    &:last-child {
      display: flex;
      align-items: center;
      justify-content: space-around;
      ::v-deep .el-form-item {
        margin-bottom: 0;
        display: flex;
        .el-form-item__label {
          width: auto !important;
        }
        .el-form-item__content {
          margin-left: auto !important;
        }
      }
    }
  }
}
</style>

