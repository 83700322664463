<template>
  <div class="top-tab">
    <el-table
      ref="elTable"
      border
      :data="items"
      row-key="sort"
      :drag-row="true"
      v-loading="loading"
    >
      <el-table-column type="index" align="center" width="60">
        <template #header>{{ $t("排序") }}</template>
        <svg-icon icon-class="drag" class-name="drag-handler" />
      </el-table-column>
      <el-table-column :label="$t('系列')" align="center">
        <template slot-scope="{ row }">
          <el-select
            v-model="row.seriesTag"
            style="width: 100%"
            clearable
            filterable
            @change="(value) => onChangeSeries(row, value)"
            :disabled="row.seriesTag === '1502'"
          >
            <el-option
              v-for="series in seriesList"
              :key="series.seriesTag"
              :value="series.seriesTag"
              :label="series.seriesName"
              :disabled="series.seriesTag === '1502' && items.some(e => e.seriesTag === '1502')"
            />
          </el-select>
        </template>
      </el-table-column>

      <el-table-column :label="$t('游戏')" align="center">
        <template slot-scope="{ row }">
          <el-input v-if="inputSeriesTagTag.includes(row.seriesTag)" v-model="row.gameLink" />
          <el-select
            v-else
            v-model="row.lotteryId"
            style="width: 100%"
            clearable
            filterable
            :placeholder="$t('请选择游戏')"
            @change="(value) => onChangeGame(row, value)"
            :disabled="row.seriesTag === '1502' && !!row.lotteryId"
          >
            <el-option
              v-for="lottery in gameList(row.seriesTag)"
              :key="lottery.lotteryId"
              :value="lottery.lotteryId"
              :label="lottery.name"
            />
          </el-select>
        </template>
      </el-table-column>

      <el-table-column :label="$t('游戏名称')" align="center">
        <template slot-scope="{ row }">
          <el-input v-model="row.content" />
        </template>
      </el-table-column>
      <el-table-column :label="$t('图标')" align="center" width="140">
        <template slot-scope="{ row }">
          <d-upload
            v-model="row.hotIcon"
            folder="icon"
            :on-success="(...args) => handleUploadSuccess(args, row)"
            :width="86"
            :height="86"
          />
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')" align="center" width="140">
        <template slot-scope="{ row }">
          <el-button @click="resetRow(row)">重置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0" align="right">
      <el-button
        type="success"
        :loading="submitting"
        @click="handleSubmit()"
      >{{ $t("保 存") }}</el-button>
    </div>
  </div>
</template>
<script>
import DUpload from '@/components/DUpload'
import {
  getLotteryNavigationHot,
  getLotterySettingList,
  updateLotterySettingList,
  updateSettingByType
} from '@/api/config'
import { getLiveRoomList } from '@/api/chat'
import TableSortMixin from '@/mixins/table-sort-mixin'
import { getLiveChannelList } from '@/api/live'
import { inputSeriesTagTag } from '@/libs/options'

export default {
  name: 'NewBallTopTab',
  componentName: 'NewBallTopTab',
  mixins: [TableSortMixin(true)],
  components: { DUpload },
  data() {
    return {
      submitting: false,
      loading: false,
      items: [],
      seriesList: [],
      itemsSettings:[],
      type: 9,
      inputSeriesTagTag
    }
  },
  async created() {
    this.loading = true
    await this.getHotList()
    this.getSettingList()
  },

  methods: {
    gameList(seriesTag) {
      return this.seriesList.find(p => p.seriesTag == seriesTag)?.lotteryList || []
    },
    handleUploadSuccess([[data]], row) {
      row.hotIcon = data
    },
    async getHotList() {
      let res = await getLotteryNavigationHot()
      // 聊天室
      let chat = res[0].find((p) => p.seriesTag == '9999007')
      if (chat) {
        chat.lotteryList = (await getLiveRoomList({ roomType: 1 }))[0].map(
          (p) => ({
            lotteryId: p.roomId,
            name: p.roomName
          })
        )
      }
      // 直播
      let channel = res[0].find((p) => p.seriesTag == '8888801')
      if (channel) {
        const [items, err] = await getLiveChannelList({ currentPage: 1, pageSize: 100 })
        if (!err) {
          channel.lotteryList = items.filter(e => e.isDeletable === 1).map(e => {
            return {
              lotteryId: e.unionId,
              name: e.items[0].channelName || e.items[0].displayName
            }
          })
        }
      }
      this.seriesList = res[0]
    },
    async getSettingList() {
      let res = await getLotterySettingList({ type: this.type }).finally(() => { this.loading = false })
      this.itemsSettings=JSON.parse(JSON.stringify(res[0]||[]))
      this.items = Array.from({ length: 10 }).map((p, index) => {
        const info = res[0][index] || ({ content: '', gameLink: '', hotIcon: '', icon: null, lotteryId: null, name: null, seriesName: '', seriesTag: '', sort: index, status: 1, type: this.type })
        const content = info.content
        if (!info.lotteryId) {
          info.content = ''
        }
        if ((!info.lotteryId && info.seriesName) || info.seriesTag == '9999012') {
          info.content = info.seriesName
        }
        if (info.name) {
          info.content = info.name
        }
        if (content) {
          info.content = content
        }
        return info
      })
    },

    onChangeSeries(row, value) {
      let info = this.seriesList.find((o) => o.seriesTag === value)
      this.$set(row, 'seriesName', info.seriesName)
      this.$set(row, 'content', info.seriesName)
      this.$set(row, 'seriesTag', info.seriesTag)
      this.$set(row, 'lotteryId', null)
      const setting= this.itemsSettings.find(p => p.seriesTag === row.seriesTag)
      this.$nextTick(() => this.$set(row, 'gameLink', setting?.gameLink||''))
    },
    onChangeGame(row, value) {
      let series = this.seriesList.find((o) => o.seriesTag === row.seriesTag)
      let info = series.lotteryList.find((o) => o.lotteryId === value)
      this.$set(row, 'lotteryId', info.lotteryId)
      this.$set(row, 'content', info.name)
      this.$set(row, 'name', info.name)
      this.$forceUpdate()
    },
    resetRow(row) {
      Object.keys(row).forEach(key => {
        if (row.seriesTag === '1502') {
          if (!['sort', 'type', 'status', 'seriesTag', 'seriesName', 'lotteryId', 'name'].includes(key)) {
            row[key] = null
          }
        } else if (!['sort', 'type', 'status'].includes(key)) {
          row[key] = null
        }
      })
    },

    async handleSubmit() {
      const dataList = this.items.filter(p => p.seriesTag || p.lotteryId)
      if (dataList.length == 0) {
        const res = await updateSettingByType({ type: this.type })
        if (res[2].data.success) {
          this.$message.success(this.$t('保存成功'))
        } else {
          this.$message.error(this.$t('保存失败'))
        }
        return
      }
      if (dataList.some(p => !p.hotIcon)) return this.$message.error(this.$t('请选择热门图标'))

      const res = await updateLotterySettingList(dataList)
      if (res[2].data.success) {
        this.$message.success(this.$t('保存成功'))
      } else {
        this.$message.error(this.$t('保存失败'))
      }
      this.getSettingList()
    }
  }
}
</script>

<style lang="scss" scoped>
.top-tab {
  width: 900px;
}
</style>