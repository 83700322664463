<template>
  <span
    tabindex="0"
    :class="wrapClasses"
    :style="wrapStyles"
    @click="toggle"
    @keydown.space="toggle"
  >
    <input type="hidden" :name="name" :value="currentValue">
    <span :class="innerClasses">
      <slot v-if="currentValue === activeValue" name="open">{{ activeText }}</slot>
      <slot v-if="currentValue === inactiveValue" name="close">{{ inactiveText }}</slot>
    </span>
  </span>
</template>
<script>
import { oneOf } from '@/utils/assist'
import Emitter from 'element-ui/src/mixins/emitter'
const prefixCls = 'd-switch'
export default {
  name: 'DSwitch',
  mixins: [Emitter],
  props: {
    value: {
      type: [String, Number, Boolean],
      default: false
    },
    activeValue: {
      type: [String, Number, Boolean],
      default: 0
    },
    inactiveValue: {
      type: [String, Number, Boolean],
      default: 1
    },
    activeText: {
      type: String,
      default: '启用'
    },
    inactiveText: {
      type: String,
      default: '禁用'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      validator(value) {
        return oneOf(value, ['large', 'small', 'default'])
      },
      default: 'large'
    },
    name: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    activeColor: {
      type: String,
      default: '#3894FF'
    },
    inactiveColor: {
      type: String,
      default: '#F26161'
    },
    beforeChange: {
      type: Function,
      default: undefined
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  computed: {
    wrapClasses() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}-checked`]: this.currentValue === this.activeValue,
          [`${prefixCls}-disabled`]: this.disabled,
          [`${prefixCls}-${this.size}`]: !!this.size,
          [`${prefixCls}-loading`]: this.loading
        }
      ]
    },
    wrapStyles() {
      let style = {}
      if (this.activeColor && this.currentValue === this.activeValue) {
        style['border-color'] = this.activeColor
        style['background-color'] = this.activeColor
      } else if (this.inactiveColor && this.currentValue === this.inactiveValue) {
        style['border-color'] = this.inactiveColor
        style['background-color'] = this.inactiveColor
      }
      return style
    },
    innerClasses() {
      return `${prefixCls}-inner`
    }
  },
  watch: {
    value(val) {
      // if (val !== this.activeValue && val !== this.inactiveValue) {
      //   // throw 'Value should be activeValue or inactiveValue.';
      // }
      this.currentValue = val
    }
  },
  methods: {
    handleToggle() {
      const checked = this.currentValue === this.activeValue ? this.inactiveValue : this.activeValue
      this.currentValue = checked
      this.$emit('input', checked)
      this.$emit('change', checked)
      this.dispatch('ElFormItem', 'el.form.change', [checked])
    },
    toggle(event) {
      event.preventDefault()
      if (this.disabled || this.loading) {
        return false
      }
      if (!this.beforeChange) {
        return this.handleToggle()
      }
      const before = this.beforeChange()
      if (before && before.then) {
        before.then(() => {
          this.handleToggle()
        })
      } else {
        this.handleToggle()
      }
    }
  }
}
</script>

<style lang="scss">
$prefix-cls: d-switch;

@keyframes switch-loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.#{$prefix-cls} {
  width: 44px;
  height: 22px;
  line-height: 20px;
  border-radius: 22px;
  vertical-align: middle;
  border: 1px solid #ccc;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  display: inline-block;
  user-select: none;
  transition: all .2s ease-in-out;
  &:focus {
    box-shadow: 0 0 0 2px rgba(45,140,240,.2);
    outline: 0;
  }
  &::before {
    content: "";
    display: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: transparent;
    position: absolute;
    left: 3px;
    top: 3px;
    z-index: 1;
    border: 1px solid #2d8cf0;
    border-color: transparent transparent transparent #2d8cf0;
    animation: switch-loading 1s linear;
    animation-iteration-count: infinite;
  }
  &::after {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background-color: #fff;
    position: absolute;
    left: 1px;
    top: 1px;
    cursor: pointer;
    transition: all .3s;
  }

  &-loading {
    opacity: .4;
    &::before {
      display: block;
    }
  }

  &-checked {
    &::before {
      left: 25px;
    }
    &::after {
      left: 23px;
    }
    .#{$prefix-cls}-inner {
      left: 7px;
    }
  }

  &-small {
    width: 28px;
    height: 16px;
    line-height: 14px;
  }
  &-large {
    width: 56px;
    &.#{$prefix-cls}-checked {
      &::before {
        left: 37px;
      }
      &::after {
        left: 35px;
      }
    }
  }

  &-inner {
    color: #fff;
    font-size: 12px;
    position: absolute;
    left: 23px;
  }

  &.#{$prefix-cls}-disabled {
    cursor: not-allowed;
    opacity: .4;

    &:after {
      background: #fff;
      cursor: not-allowed;
    }

    .#{$prefix-cls}-inner {
      color: #fff;
    }
  }
}
</style>
