<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive>
        <component :is="activeName" class="basic_config" />
      </keep-alive>
    </template>
  </div>
</template>

<script>
import { parentMixin } from '@/mixins/tab-items'
import BaseTab from './components/BaseTab'
import InteractionTab from './components/InteractionTab'
import AddressTab from './components/AddressTab'
import ThirdTab from './components/ThirdTab'
// import CarshTab from './components/CarshTab'
import RateTab from './components/RateTab'
export default {
  components: {
    BaseTab,
    InteractionTab,
    AddressTab,
    ThirdTab,
    // CarshTab,
    RateTab
  },
  mixins: [parentMixin],
  data() {
    return {
      tabs: this.$t('configBasicTabs')
    }
  }
}
</script>

<style lang="scss">
  .basic_config{
    padding-right: 48px;
    h2{
      width: 210px;
      text-align: right;
      font-weight: 500;
      font-size: 20px;
      margin-top: 40px;
    }
    // .el-form-item__label{
    //   font-weight: 400;
    // }
    .action-button{
      border-top: 1px dashed #E4E7ED;
      padding-top: 10px;
    }
  }
</style>
