import { liveAccountTypes } from '@/libs/options'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('家族名称'),
      key: 'familyName',
      align: 'center'
    },
    {
      title: this.$t('可提现账户类型'),
      key: 'accountType',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ liveAccountTypes[row.accountType] }</span>)
        }
      }
    },
    {
      title: this.$t('提现姓名'),
      key: 'accountName',
      align: 'center'
    },
    {
      title: this.$t('提现账号'),
      key: 'accountNo',
      align: 'center'
    },
    {
      title: this.$t('未结算金额'),
      key: 'unsettledAmount',
      align: 'center'
    },
    {
      title: this.$t('本次结算金额'),
      key: 'apycGold',
      align: 'center'
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 350,
      component: {
        render: (h, row) => {
          const { Settlement, Payment, Details } = this.permission
          return (
            <span>
              { Payment && row.state === 3 && <el-button type='danger' onClick={() => this.$refs.ActionDialogControl.open(row)}>{this.$t('打款')}</el-button> }
              { Settlement && [1,2].includes(row.state) && <el-button type='success' onClick={this._handleSettlement.bind(this, row)}>{this.$t('结算')}</el-button> }
              { Details && <el-button type='primary' onClick={this._goHistory.bind(this, row)}>{this.$t('明细')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
