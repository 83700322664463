<template>
  <div class="abpay">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px">
      <el-row v-loading="loading" :gutter="96" class="basic_config_content">
        <el-col :span="12">
          <h2>{{ $t("充值入口开关") }}</h2>
          <el-form-item :label="$t('ABPay充值入口')">
            <div class="config-item">
              <d-switch v-model="ruleForm.rechargeEntrance">
                <span slot="open">{{ $t("开启") }}</span>
                <span slot="close">{{ $t("关闭") }}</span>
              </d-switch>
              * VIP等级:
              <el-select v-model="ruleForm.rechargeVip" :placeholder="$t('VIP等级')" clearable class="filter-item"
                style="width: 120px">
                <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`"
                  :value="item.vipLevel" />
              </el-select>
              以上显示入口
            </div>
          </el-form-item>
          <el-form-item :label="$t('微信直充入口')">
            <div class="config-item">
              <d-switch v-model="ruleForm.rechargeWechatEntrance">
                <span slot="open">{{ $t("开启") }}</span>
                <span slot="close">{{ $t("关闭") }}</span>
              </d-switch>
              * VIP等级:
              <el-select v-model="ruleForm.rechargeWechatVip" :placeholder="$t('VIP等级')" clearable class="filter-item"
                style="width: 120px">
                <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`"
                  :value="item.vipLevel" />
              </el-select>
              以上显示入口
            </div>
          </el-form-item>
          <el-form-item :label="$t('支付宝直充入口')">
            <div class="config-item">
              <d-switch v-model="ruleForm.rechargeAlipayEntrance">
                <span slot="open">{{ $t("开启") }}</span>
                <span slot="close">{{ $t("关闭") }}</span>
              </d-switch>
              * VIP等级:
              <el-select v-model="ruleForm.rechargeAlipayVip" :placeholder="$t('VIP等级')" clearable class="filter-item"
                style="width: 120px">
                <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`"
                  :value="item.vipLevel" />
              </el-select>
              以上显示入口
            </div>
          </el-form-item>
          <el-form-item :label="$t('银行卡直充入口')">
            <div class="config-item">
              <d-switch v-model="ruleForm.rechargeBankEntrance">
                <span slot="open">{{ $t("开启") }}</span>
                <span slot="close">{{ $t("关闭") }}</span>
              </d-switch>
              * VIP等级:
              <el-select v-model="ruleForm.rechargeBankVip" :placeholder="$t('VIP等级')" clearable class="filter-item"
                style="width: 120px">
                <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`"
                  :value="item.vipLevel" />
              </el-select>
              以上显示入口
            </div>
          </el-form-item>
          <el-form-item>
            <div class="config-item paixu">
              <div>
                <span>微信排序:&nbsp;&nbsp;</span>
                <el-select v-model="rechargeOrder[0]" class="filter-item" style="width: 60px" placeholder="">
                  <el-option :label="1" :value="1" />
                  <el-option :label="2" :value="2" />
                  <el-option :label="3" :value="3" />
                </el-select>
              </div>
              <div>
                <span>支付宝排序:&nbsp;&nbsp;</span>
                <el-select v-model="rechargeOrder[1]" class="filter-item" style="width: 60px" placeholder="">
                  <el-option :label="1" :value="1" />
                  <el-option :label="2" :value="2" />
                  <el-option :label="3" :value="3" />
                </el-select>
              </div>
              <div>
                <span>银行卡排序:&nbsp;&nbsp;</span>
                <el-select v-model="rechargeOrder[2]" class="filter-item" style="width: 60px" placeholder="">
                  <el-option :label="1" :value="1" />
                  <el-option :label="2" :value="2" />
                  <el-option :label="3" :value="3" />
                </el-select>
              </div>
            </div>

          </el-form-item>

          <h2>{{ $t("ABPay充值限制") }}</h2>

          <el-form-item :label="$t('单笔充值金额限制')">
            <div class="config-item">
              <el-input v-model="ruleForm.minAmount" class="filter-item" style="width: 120px" clearable />
              ~
              <el-input v-model="ruleForm.maxAmount" class="filter-item" style="width: 120px" clearable />
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <h2>{{ $t("提现入口开关") }}</h2>
          <el-form-item :label="$t('ABPay提现入口')">
            <div class="config-item">
              <d-switch v-model="ruleForm.withdrawEntrance">
                <span slot="open">{{ $t("开启") }}</span>
                <span slot="close">{{ $t("关闭") }}</span>
              </d-switch>
              * VIP等级:
              <el-select v-model="ruleForm.withdrawVip" :placeholder="$t('VIP等级')" clearable class="filter-item"
                style="width: 120px">
                <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`"
                  :value="item.vipLevel" />
              </el-select>
              以上显示入口
            </div>
          </el-form-item>
          <el-form-item :label="$t('微信提现入口')">
            <div class="config-item">
              <d-switch v-model="ruleForm.withdrawWechatEntrance">
                <span slot="open">{{ $t("开启") }}</span>
                <span slot="close">{{ $t("关闭") }}</span>
              </d-switch>
              * VIP等级:
              <el-select v-model="ruleForm.withdrawWechatVip" :placeholder="$t('VIP等级')" clearable class="filter-item"
                style="width: 120px">
                <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`"
                  :value="item.vipLevel" />
              </el-select>
              以上显示入口
            </div>
          </el-form-item>
          <el-form-item :label="$t('支付宝提现入口')">
            <div class="config-item">
              <d-switch v-model="ruleForm.withdrawAlipayEntrance">
                <span slot="open">{{ $t("开启") }}</span>
                <span slot="close">{{ $t("关闭") }}</span>
              </d-switch>
              * VIP等级:
              <el-select v-model="ruleForm.withdrawAlipayVip" :placeholder="$t('VIP等级')" clearable class="filter-item"
                style="width: 120px">
                <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`"
                  :value="item.vipLevel" />
              </el-select>
              以上显示入口
            </div>
          </el-form-item>
          <el-form-item :label="$t('银行卡提现入口')">
            <div class="config-item">
              <d-switch v-model="ruleForm.withdrawBankEntrance">
                <span slot="open">{{ $t("开启") }}</span>
                <span slot="close">{{ $t("关闭") }}</span>
              </d-switch>
              * VIP等级:
              <el-select v-model="ruleForm.withdrawBankVip" :placeholder="$t('VIP等级')" clearable class="filter-item"
                style="width: 120px">
                <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`"
                  :value="item.vipLevel" />
              </el-select>
              以上显示入口
            </div>
          </el-form-item>
          <el-form-item>
            <div class="config-item paixu">
              <div>
                <span>微信排序:&nbsp;&nbsp;</span>
                <el-select v-model="withdrawalOrder[0]" class="filter-item" style="width: 60px" placeholder="">
                  <el-option :label="1" :value="1" />
                  <el-option :label="2" :value="2" />
                  <el-option :label="3" :value="3" />
                </el-select>
              </div>
              <div>
                <span>支付宝排序:&nbsp;&nbsp;</span>
                <el-select v-model="withdrawalOrder[1]" class="filter-item" style="width: 60px" placeholder="">
                  <el-option :label="1" :value="1" />
                  <el-option :label="2" :value="2" />
                  <el-option :label="3" :value="3" />
                </el-select>
              </div>
              <div>
                <span>银行卡排序:&nbsp;&nbsp;</span>
                <el-select v-model="withdrawalOrder[2]" class="filter-item" style="width: 60px" placeholder="">
                  <el-option :label="1" :value="1" />
                  <el-option :label="2" :value="2" />
                  <el-option :label="3" :value="3" />
                </el-select>
              </div>
            </div>
          </el-form-item>

          <h2>{{ $t("ABPay提现加赠（仅在提现显示）") }}</h2>
          <el-form-item :label="$t('用户使用ABpay提现显示加赠')" label-width="150" prop="withdrawGive">
            <el-input v-model="ruleForm.withdrawGive" class="filter-item" style="width: 120px" /> %
          </el-form-item>
        </el-col>
      </el-row>

      <h2>{{ $t("VIP用户充值加赠") }}</h2>

      <el-form-item :label="$t('VIP用户ABPay充值加赠')" label-width="220px">
        <div class="config-item">
          <p>用户达到等级取最高配置值</p>
          <el-button type="primary" :loading="submitting" @click="ruleForm.giveRequestList.unshift({})">{{ $t("添加")
            }}</el-button>
        </div>
      </el-form-item>
      <div class="config-item ml16 mb16" v-for="(item, index) of ruleForm.giveRequestList" :key="'give' + index">
        * VIP等级:
        <el-select v-model="item.vipLevel" :placeholder="$t('VIP等级')" clearable class="filter-item"
          style="width: 120px">
          <el-option v-for="vip in vipLevels" :key="vip.vipLevel" :label="`VIP${vip.vipLevel}`" :value="vip.vipLevel" />
        </el-select>
        以上充值加赠
        <el-input v-model="item.vipGive" 　class="filter-item" style="width: 120px" clearable />
        %
        <el-button type="warning" :loading="submitting" v-if="ruleForm.giveRequestList.length > 1"
          @click="ruleForm.giveRequestList.splice(index, 1)">{{ $t("删除") }}</el-button>
      </div>

      <h2>{{ $t("提现用户等级") }}</h2>

      <el-form-item :label="$t('其他渠道提现用户等级配置')" label-width="220px">
        <div class="config-item">
          <p>用户达到等级取最高配置值</p>
          <el-button type="primary" :loading="submitting" @click="ruleForm.vipWithdrawRequestList.unshift({})">{{ $t("添加")
            }}</el-button>
        </div>
      </el-form-item>
      <div class="config-item ml16 mb16" v-for="(item, index) of ruleForm.vipWithdrawRequestList" :key="'with' + index">
        * VIP等级:
        <el-select v-model="item.vipLevel" :placeholder="$t('VIP等级')" clearable class="filter-item"
          style="width: 120px">
          <el-option v-for="vip in vipLevels" :key="vip.vipLevel" :label="`VIP${vip.vipLevel}`" :value="vip.vipLevel" />
        </el-select>
        以上每日可提现
        <el-input v-model="item.vipNumber" 　class="filter-item" style="width: 120px" clearable />
        次（-1代表不限制） 每日可提现
        <el-input v-model="item.vipAmount" 　class="filter-item" style="width: 120px" clearable />

        ￥（-1代表不限制）
        <el-button type="warning" :loading="submitting" v-if="ruleForm.vipWithdrawRequestList.length > 1"
          @click="ruleForm.vipWithdrawRequestList.splice(index, 1)">{{ $t("删除") }}</el-button>
      </div>

      <el-form-item :label="$t('ABPay渠道提现用户等级配置')" label-width="220px">
        <div class="config-item">
          <p>用户达到等级取最高配置值</p>
          <el-button type="primary" :loading="submitting" @click="ruleForm.withdrawRequestList.unshift({})">{{ $t("添加")
            }}</el-button>
        </div>
      </el-form-item>
      <div class="config-item ml16 mb16" v-for="(item, index) of ruleForm.withdrawRequestList" :key="'vip' + index">
        * VIP等级:
        <el-select v-model="item.vipLevel" :placeholder="$t('VIP等级')" clearable class="filter-item"
          style="width: 120px">
          <el-option v-for="vip in vipLevels" :key="vip.vipLevel" :label="`VIP${vip.vipLevel}`" :value="vip.vipLevel" />
        </el-select>
        以上每日可提现
        <el-input v-model="item.vipNumber" 　class="filter-item" style="width: 120px" clearable />
        次（-1代表不限制） 每日可提现
        <el-input v-model="item.vipAmount" 　class="filter-item" style="width: 120px" clearable />

        ￥（-1代表不限制）
        <el-button type="warning" :loading="submitting" v-if="ruleForm.withdrawRequestList.length > 1"
          @click="ruleForm.withdrawRequestList.splice(index, 1)">{{ $t("删除") }}</el-button>
      </div>

      <div class="action-button">
        <el-button type="danger" :loading="submitting" @click="handleSubmit">{{ $t("保 存") }}</el-button>
        <el-button @click="getAbpayConfig">{{ $t("重 置") }}</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getAllVipLevels } from "@/api/app";
import { queryAbpayConfig, upAbpayConfig } from "@/api/abpay";
import { percent0_100 } from '@/utils/validation.js'
export default {
  name: "PaymentAbpayTab",
  componentName: "PaymentAbpayTab",
  data() {
    return {
      loading: false,
      submitting: false,
      ruleForm: {
        rechargeEntrance: false,
        rechargeWechatEntrance: false,
        rechargeAlipayEntrance: false,
        rechargeBankEntrance: false,
        rechargeVip: null,
        rechargeWechatVip: null,
        rechargeAlipayVip: null,
        rechargeBankVip: null,
        withdrawEntrance: false,
        withdrawWechatEntrance: false,
        withdrawAlipayEntrance: false,
        withdrawBankEntrance: false,
        withdrawVip: null,
        withdrawWechatVip: null,
        withdrawAlipayVip: null,
        withdrawBankVip: null,
        minAmount: null,
        maxAmount: null,
        giveRequestList: [],
        withdrawRequestList: [],
        vipWithdrawRequestList: [],
        withdrawGive: null
      },
      vipLevels: [],
      rules: {
        withdrawGive: [
          { pattern: percent0_100, message: '请输入0-100的数，最多三位小数', trigger: 'blur' }
        ]
      },
      rechargeOrder:['','',''],
      withdrawalOrder:['','',''],
    };
  },
  created() {
    getAllVipLevels().then(([data, err]) => {
      if (!err) {
        this.vipLevels = data;
      }
    });
    this.getAbpayConfig();
  },
  methods: {
    handleSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.saveAbpayConfig();
        }
      });
    },
    async getAbpayConfig() {
      let res = await queryAbpayConfig();
      if (!res[0].giveRequestList) res[0].giveRequestList = [{}];
      if (!res[0].withdrawRequestList) res[0].withdrawRequestList = [{}];
      if (!res[0].vipWithdrawRequestList) res[0].vipWithdrawRequestList = [{}];
      this.rechargeOrder=res[0].rechargeSort?.split(',')||[]
      this.withdrawalOrder=res[0].withdrawSort?.split(',')||[]
      Object.assign(this.ruleForm, res[0]);
    },
    async saveAbpayConfig() {
      //先处理排序的数据
      // rechargeSort withdrawSort
      this.ruleForm.rechargeSort = this.rechargeOrder.join(',')
      this.ruleForm.withdrawSort = this.withdrawalOrder.join(',')
      this.submitting = true;
      let res = await upAbpayConfig(this.ruleForm);
      this.submitting = false;
      if (res[2].data.success) this.$message.success(this.$t("保存成功"));
      else this.$message.error(this.$t("保存失败"));
    },
  },
};
</script>
<style scoped>
.abpay {
  margin: 16px;
}

.config-item {
  display: flex;
  gap: 16px;
  align-items: center;
}

.el-form-item__label {
  line-height: 48px !important;
}

.ml16 {
  margin-left: 16px;
}

.mb16 {
  margin-bottom: 16px;
}

.action-button {
  margin-left: 16px;
}

.paixu {
  margin-left: -120px;
}
</style>
