<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <div class="text">会员账号:</div>
        <el-input
          v-model="search.memberName"
          class="filter-item"
          placeholder="请输入会员账号查询"
          style="width: 160px"
        />
        <div>账号类型:</div>
        <el-select
          v-model="search.memberType"
          class="filter-item"
          placeholder="选择类型"
          style="width: 140px"
          clearable
        >
          <el-option label="全部" :value="null" />
          <el-option
            v-for="(item, index) in memberType"
            :label="item.label"
            :value="item.value"
            :key="index"
          />
        </el-select>
        <div>游戏类型:</div>
        <el-select
          v-model="search.bigPlayType"
          class="filter-item"
          placeholder="选择类型"
          style="width: 140px"
          clearable
        >
          <el-option label="全部" :value="null" />
          <el-option label="竞彩体育" :value="1" />
          <el-option label="六合彩" :value="2" />
        </el-select>
      </div>
    </div>
    <div class="filter-container">
      <el-radio-group v-model="search.timeDimension">
        <el-radio-button
          v-for="(item, key) in timeDimension"
          :key="key"
          :label="item.value"
        >
          {{ item.label }}
        </el-radio-button>
      </el-radio-group>
      <el-date-picker
        v-show="search.timeDimension === 1"
        v-model="search.dateinfo"
        type="date"
        class="filter-item"
        style="width: 260px"
        :placeholder="$t('选择日期')"
        value-format="timestamp"
        :picker-options="pickerOptions"
      />
    </div>
    <div class="filter-container buttonLine">
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleSearch"
      >
        查询
      </el-button>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-refresh-right"
        @click="resetSearch"
        >{{ $t("重置") }}</el-button
      >
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <template slot="left">
        <span class="danger-type">
          tips：仅统计已开奖订单数据，以订单结算时间为准，并在次日0点进行重新计算统计
        </span>
      </template>
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />
    <!-- 弹窗 -->
  </div>
</template>
<script>
import TablePageMixin from "@/mixins/table-page-mixin";
import GetColumns from "./columns";
import { guruOrderCountList } from "@/api/orderManage";
import MyDatePicker from "@/components/MyDatePicker";
import { getDefaultTimes,dealNumber } from "@/utils";
import { guru_order_list } from "@/mockData.js";
export default {
  name: "OrderStastics",
  componentName: "OrderStastics",
  components: {
    MyDatePicker,
  },
  mixins: [TablePageMixin(guruOrderCountList)],
  data() {
    return {
      dealNumber,
      timeDimension: this.$t("timeDimension"),
      smallPlayType: this.$t("smallPlayType"),
      memberType: this.$t("memberType"),
      columns: GetColumns.call(this),
      filterColumn: false,
      selection: [],
      tableData: {
        pageSize: 0,
      },
      search: {
        dateinfo: new Date().getTime(),
      },
      dialogFormVisible: false,
      dialogType: null,
      dialogForm: {
        applyDesc: "",
      },
      guru_order_list: guru_order_list,
      isCheckDetailsShow: false,
      rowData: {},
    };
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.filter-container-item {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  font-size:12px;
  div {
    flex-shrink: 0;
  }
}
.buttonLine {
  display: flex;
  /* justify-content: end; */
}
</style>
