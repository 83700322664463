var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "310px" },
            attrs: {
              maxlength: "20",
              placeholder: _vm.$t("商品名称"),
              clearable: "",
            },
            model: {
              value: _vm.search.name,
              callback: function ($$v) {
                _vm.$set(_vm.search, "name", $$v)
              },
              expression: "search.name",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "220px" },
              attrs: {
                placeholder: _vm.$t("商品类目"),
                clearable: "",
                filterable: "",
                multiple: "",
                "collapse-tags": "",
              },
              model: {
                value: _vm.search.categoryId,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "categoryId", $$v)
                },
                expression: "search.categoryId",
              },
            },
            _vm._l(_vm.category, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { value: item.id, label: item.categoryName },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: { placeholder: _vm.$t("商品状态"), clearable: "" },
              model: {
                value: _vm.search.status,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "status", $$v)
                },
                expression: "search.status",
              },
            },
            _vm._l(_vm.goodsStatus, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { value: item.key, label: item.label },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleSearch },
            },
            [_vm._v(_vm._s(_vm.$t("查询")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-refresh-right" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("重置")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { float: "right" },
              attrs: { type: "success", icon: "el-icon-plus" },
              on: { click: _vm.onCreate },
            },
            [_vm._v(_vm._s(_vm.$t("新建商品")))]
          ),
        ],
        1
      ),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          options: _vm.options,
          "filter-column": _vm.filterColumn,
        },
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("GoodsDialog", {
        ref: "goodsDialog",
        on: { create: _vm.handleCreate, update: _vm.handleUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }