<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        clearable
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7)">{{ $t('_7天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30)">{{ $t('本月') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60)">{{ $t('上月') }}</el-button>
    </div>
    <div class="filter-container">
      <el-input v-model="search.memberName" clearable placeholder="会员账号" class="filter-item" style="width: 150px" />
      <el-select v-model="search.memberType" clearable placeholder="账号类型" class="filter-item" style="width: 150px">
        <el-option v-for="(val, key) in memberType" :key="key" :value="key" :label="val" />
      </el-select>
      <el-input v-model="search.redpacketId" clearable placeholder="活动ID" class="filter-item" style="width: 150px" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <template v-if="statistical" slot="left">
        合计人数：<span style="margin-right: 15px;">{{ statistical.memberNum || 0 }}</span>
        领取金额：<span style="margin-right: 15px;">{{ statistical.memberAmt || 0 }}</span>
        领取次数：<span>{{ statistical.robNum || 0 }}</span>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <UserDialog ref="UserDialogControl" />
  </div>
</template>
<script>
import { memberType } from '@/libs/options'
import TablePageMixin from '@/mixins/table-page-mixin'
import { redpacketMemberList } from '@/api/activity'
import GetColumns from './columns'
import { getDefaultTimes } from '@/utils'
import UserDialog from '@/views/components/UserDialog'

export default {
  name: 'RedTabRecord',
  components: { UserDialog },
  mixins: [TablePageMixin(redpacketMemberList)],
  data() {
    return {
      times: getDefaultTimes(),
      filterColumn: false,
      memberType,
      columns: GetColumns.call(this),
      statistical: {
        memberNum: 0,
        memberAmt: 0,
        robNum: 0
      }
    }
  },
  methods: {
    implementationGetParams() {
      return this.formatIntervalTime()
    },
    implementationFetched(data) {
      this.statistical.memberNum = data.memberNum
      this.statistical.memberAmt = data.memberAmt
      this.statistical.robNum = data.robNum
    }
  }
}
</script>
