var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              model: {
                value: _vm.search.timeType,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "timeType", $$v)
                },
                expression: "search.timeType",
              },
            },
            [
              _c("el-option", { attrs: { label: "创建时间", value: 0 } }),
              _c("el-option", { attrs: { label: "操作时间", value: 1 } }),
            ],
            1
          ),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "400px" },
            attrs: {
              clearable: "",
              type: "datetimerange",
              "range-separator": _vm.$t("至"),
              "start-:placeholder": _vm.$t("开始日期"),
              "end-:placeholder": _vm.$t("结束日期"),
              "value-format": "timestamp",
              "default-time": ["00:00:00", "23:59:59"],
              "picker-options": _vm.pickerOptions,
            },
            model: {
              value: _vm.times,
              callback: function ($$v) {
                _vm.times = $$v
              },
              expression: "times",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetTimes($event, 0)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("今天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetTimes($event, 1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("昨天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              on: {
                click: function ($event) {
                  return _vm.handleSetTimes($event, 7)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("_7天")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleSearchWrap },
            },
            [_vm._v(_vm._s(_vm.$t("查询")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-refresh-right" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("重置")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: _vm.$t("用户类型") },
              model: {
                value: _vm.search.memberType,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "memberType", $$v)
                },
                expression: "search.memberType",
              },
            },
            _vm._l(_vm.memberTypes, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { ":placeholder": _vm.$t("VIP等级"), clearable: "" },
              model: {
                value: _vm.search.vipLevel,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "vipLevel", $$v)
                },
                expression: "search.vipLevel",
              },
            },
            _vm._l(_vm.vipLevels, function (item) {
              return _c("el-option", {
                key: item.vipLevel,
                attrs: { label: "VIP" + item.vipLevel, value: item.vipLevel },
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "120px" },
            attrs: { placeholder: _vm.$t("会员账号"), clearable: "" },
            model: {
              value: _vm.search.memberName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "memberName", $$v)
              },
              expression: "search.memberName",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "120px" },
            attrs: { placeholder: _vm.$t("真实姓名"), clearable: "" },
            model: {
              value: _vm.search.realName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "realName", $$v)
              },
              expression: "search.realName",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "120px" },
            attrs: { placeholder: _vm.$t("转账姓名"), clearable: "" },
            model: {
              value: _vm.search.payingName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "payingName", $$v)
              },
              expression: "search.payingName",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "120px" },
            attrs: { placeholder: _vm.$t("所属代理"), clearable: "" },
            model: {
              value: _vm.search.proxyCode,
              callback: function ($$v) {
                _vm.$set(_vm.search, "proxyCode", $$v)
              },
              expression: "search.proxyCode",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: _vm.$t("充值状态"), clearable: "" },
              model: {
                value: _vm.search.rechargeTimes,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "rechargeTimes", $$v)
                },
                expression: "search.rechargeTimes",
              },
            },
            _vm._l(_vm.rechargeStatus, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: _vm.$t("订单状态"), clearable: "" },
              model: {
                value: _vm.search.status,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "status", $$v)
                },
                expression: "search.status",
              },
            },
            [
              _c("el-option", {
                attrs: { label: _vm.$t("充值成功"), value: "0000" },
              }),
              _c("el-option", {
                attrs: { label: _vm.$t("已拒绝"), value: "2222" },
              }),
              _c("el-option", {
                attrs: { label: _vm.$t("待处理"), value: "1111" },
              }),
            ],
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "120px" },
            attrs: { clearable: "", placeholder: _vm.$t("收款银行") },
            model: {
              value: _vm.search.receivingBank,
              callback: function ($$v) {
                _vm.$set(_vm.search, "receivingBank", $$v)
              },
              expression: "search.receivingBank",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "120px" },
            attrs: { clearable: "", placeholder: _vm.$t("收款姓名") },
            model: {
              value: _vm.search.receivingName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "receivingName", $$v)
              },
              expression: "search.receivingName",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: _vm.$t("收款卡号") },
            model: {
              value: _vm.search.receivingCardNo,
              callback: function ($$v) {
                _vm.$set(_vm.search, "receivingCardNo", $$v)
              },
              expression: "search.receivingCardNo",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: _vm.$t("订单号") },
            model: {
              value: _vm.search.orderNo,
              callback: function ($$v) {
                _vm.$set(_vm.search, "orderNo", $$v)
              },
              expression: "search.orderNo",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "120px" },
            attrs: { clearable: "", placeholder: _vm.$t("操作员") },
            model: {
              value: _vm.search.operatorName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "operatorName", $$v)
              },
              expression: "search.operatorName",
            },
          }),
        ],
        1
      ),
      _c(
        "d-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "DTable",
          attrs: {
            data: _vm.items,
            columns: _vm.columns,
            "original-columns": _vm.defaultColumns,
            options: _vm.options,
            "row-key": "orderNo",
          },
          on: {
            refresh: _vm.handleFetch,
            "column-change": _vm.handleColumnChange,
          },
          scopedSlots: _vm._u([
            {
              key: "right",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        plain: "",
                        type: "primary",
                        loading: _vm.excelLoading1,
                        icon: "el-icon-download",
                      },
                      on: { click: _vm._handleExport },
                    },
                    [_vm._v(_vm._s(_vm.$t("导出Excel")))]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("订单总额")) +
                  "：" +
                  _vm._s(_vm.sumAmount) +
                  _vm._s(_vm.$t("元"))
              ),
              _vm.sumAmountGroupByStatus.length > 0 ? [_vm._v("，")] : _vm._e(),
              _vm._l(_vm.sumAmountGroupByStatus, function (item, index) {
                return _c(
                  "span",
                  { key: item.status },
                  [
                    _vm._v(
                      _vm._s(_vm._f("labelFilter")(item.status)) +
                        "：" +
                        _vm._s(item.sum)
                    ),
                    _vm.sumAmountGroupByStatus.length - 1 > index
                      ? [_vm._v("，")]
                      : _vm._e(),
                  ],
                  2
                )
              }),
            ],
            2
          ),
        ],
        2
      ),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("ImagePreview", { ref: "ImagePreviewControl" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }