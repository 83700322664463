import request from '@/plugins/axios';

// 充值管理

// export function getPaymentOrderAuditList(params) {
//   return request({
//     url: '/paymentOrder/audit/list',
//     method: 'get',
//     params
//   })
// }

export function getPaymentOrderList (params) {
  return request({
    url: '/paymentOrder/list',
    method: 'get',
    params
  });
}

export const thirdOrderDownload = (data) => {
  return request({
    url: '/thirdOrder/download',
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 600000
  })
}

export function paymentOrderDownload(data) {
  return request({
    url: '/paymentOrder/download',
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 600000
  });
}

// 充值订单审核
export function auditPayOrder (data) {
  return request({
    url: '/paymentOrder/auditPayOrder',
    method: 'post',
    data
  });
}

// 充值订单批量审核
export function batchAuditPayOrder (data) {
  return request({
    url: '/paymentOrder/batchAuditPayOrder',
    method: 'post',
    data
  });
}

// 查询提现订单列表

// export function getWithdrawPickList(params) {
//   return request({
//     url: '/withdraw/toPick/list',
//     method: 'get',
//     params
//   })
// }

export function withdrawList (params) {
  return request({
    url: '/withdraw/list',
    method: 'get',
    params
  });
}

// 提现

export function postWithdrawPick (data) {
  return request({
    url: '/withdraw/pick',
    method: 'post',
    data
  });
}

export function postWithdrawBatchPick (data) {
  return request({
    url: 'withdraw/batchPick',
    method: 'post',
    data
  });
}

export function getWithdrawList (params) {
  return request({
    url: '/withdraw/list',
    method: 'get',
    params
  });
}

export function postWithdrawAudit (data) {
  return request({
    url: '/withdraw/audit',
    method: 'post',
    data
  });
}

export function postWithdrawBatchAudit (data) {
  return request({
    url: '/withdraw/batchAudit',
    method: 'post',
    data
  });
}

export function getAgentListByTenantCode (params = {}) {
  return request({
    url: '/paymentAgent/getListByTenantCode',
    method: 'get',
    params
  });
}

export function postWithdrawBatchPaymentAgeny (data) {
  return request({
    url: '/withdraw/batchPaymentAgeny',
    method: 'post',
    data
  });
}

// 支付配置-公司账户
export function getPayReceivingAccountList (params) {
  return request({
    url: '/payReceivingAccount/list',
    method: 'get',
    params
  });
}

export function addPayReceivingAccount (data) {
  return request({
    url: '/payReceivingAccount/add',
    method: 'post',
    data
  });
}

export function updatePayReceivingAccount (data) {
  return request({
    url: '/payReceivingAccount/update',
    method: 'post',
    data
  });
}

export function updatePayMaxAmountVal (data) {
  return request({
    url: '/payReceivingAccount/updatePayMaxAmountVal',
    method: 'post',
    data: data
  });
}
export function deletePayReceivingAccount (data) {
  return request({
    url: '/payReceivingAccount/deleteReceiveAccount',
    method: 'post',
    data
  });
}
// 支付配置-第三方账户
export function getPayThirdAccountList (params) {
  return request({
    url: '/payThirdAccount/list',
    method: 'get',
    params
  });
}

export function addPayThirdAccount (data) {
  return request({
    url: '/payThirdAccount/add',
    method: 'post',
    data
  });
}

export function updatePayThirdAccount (data) {
  return request({
    url: '/payThirdAccount/update',
    method: 'post',
    data
  });
}

export function delPayThirdAccount (id) {
  return request({
    url: '/payThirdAccount/delAccount',
    method: 'post',
    data: { id }
  });
}

// 支付配置-支付平台设置
export function getPayWayThirdConfList (params) {
  return request({
    url: '/payWayThirdConf/list',
    method: 'get',
    params
  });
}

export function addPayWayThirdConfConf (data) {
  return request({
    url: '/payWayThirdConf/add',
    method: 'post',
    data
  });
}

export function updatePayWayThirdConfConf (data) {
  return request({
    url: '/payWayThirdConf/update',
    method: 'post',
    data
  });
}

export function delPayWayThirdConfConf (data) {
  return request({
    url: '/payWayThirdConf/del',
    method: 'post',
    data
  });
}

// 支付配置-支付方式配置
export function getPaymentWayConfList (params) {
  return request({
    url: '/paymentWayConf/list',
    method: 'get',
    params
  });
}

export function addPaymentWayConf (data) {
  return request({
    url: '/paymentWayConf/add',
    method: 'post',
    data
  });
}

export function updatePaymentWayConf (data) {
  return request({
    url: '/paymentWayConf/update',
    method: 'post',
    data
  });
}

export function delPaymentWayConf (data) {
  return request({
    url: '/paymentWayConf/del',
    method: 'post',
    data
  });
}

// 第三方充值

export function compensateOnlineOrder (data) {
  return request({
    url: '/paymentOrder/compensateOnlineOrder',
    method: 'post',
    data
  });
}

// 会员账变

export function getTranTypes () {
  return request({
    url: '/accountChange/getTranTypes',
    method: 'get'
  });
}


export function getTranTypesChipRecords () {
  return request({
    url: '/accountRolling/getTranTypes',
    method: 'get'
  });
}

export function getAccountChangeList (data) {
  return request({
    url: '/accountChange/List',
    method: 'post',
    data
  });
}

// 积分账变记录-查询
export function getPointsChangeList (data) {
  return request({
    url: '/points/changeList',
    method: 'post',
    data
  });
}

// 账变统计
export function getAccountChangeSum (data) {
  return request({
    url: '/accountChange/sum/amount',
    method: 'post',
    data
  });
}
// 打码量统计
export function getAccountRollingSum (data) {
  return request({
    url: '/accountRolling/sum/amount',
    method: 'post',
    data
  });
}
export function exportAccountChangeList (params) {
  return request({
    url: '/accountChange/download',
    method: 'get',
    params,
    responseType: 'blob',
    timeout: 600000
  });
}

// 积分账变记录-导出
export function exportPointsChangeList (params) {
  return request({
    url: '/points/changeDownload',
    method: 'get',
    params,
    responseType: 'blob',
    timeout: 600000
  });
}

export function getMemberAccountList (params) {
  return request({
    url: '/memberAccount/list',
    method: 'get',
    params
  });
}

// 会员加款减款
export function updateAccountBalance (data) {
  return request({
    url: '/memberAccount/updateBalance',
    method: 'post',
    data
  });
}

export function updateAccountRolling (data) {
  return request({
    url: '/memberAccount/updateRolling',
    method: 'post',
    data
  });
}

// 代付配置
export function getPaymentAgentList (params) {
  return request({
    url: '/paymentAgent/list',
    method: 'get',
    params
  });
}

export function addPaymentAgent (data) {
  return request({
    url: '/paymentAgent/add',
    method: 'post',
    data
  });
}

export function updatePaymentAgent (data) {
  return request({
    url: '/paymentAgent/update',
    method: 'post',
    data
  });
}

export function delPaymentAgent (data) {
  return request({
    url: '/paymentAgent/del',
    method: 'post',
    data
  });
}
export function editStatus (data) {
  return request({
    url: '/withdraw/editStatus',
    method: 'post',
    data
  });
}
// 提现方式列表
export function getPaymentTypeConfList (params) {
  return request({
    url: '/paymentTypeConf/list',
    method: 'get',
    params
  });
}

export function addPaymentTypeConf (data) {
  return request({
    url: '/paymentTypeConf/add',
    method: 'post',
    data
  });
}
export function updatePaymentTypeConf (data) {
  return request({
    url: '/paymentTypeConf/update',
    method: 'post',
    data
  });
}
export function delPaymentTypeConf (data) {
  return request({
    url: '/paymentTypeConf/delete',
    method: 'post',
    data
  });
}

export function getXffailrecordList (data) {
  return request({
    url: '/thirdgame/xffailrecord/list',
    method: 'post',
    data
  });
}

export function getAccountRollingList (data) {
  return request({
    url: '/accountRolling/List',
    method: 'post',
    data
  });
}
