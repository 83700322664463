<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="search.sendType"
        clearable
        :placeholder="$t('请选择发送方式')"
        class="filter-item"
      >
        <el-option v-for="(item, key) in sendTypeOptions" :key="key" :label="item" :value="+key" />
      </el-select>
      <el-input v-model="search.title" clearable :placeholder="$t('请输入站内信标题')" class="filter-item" style="width: 200px" />
      <el-input v-if="search.sendType < 3" v-model="search.memberName" maxlength="20" clearable :placeholder="$t('请输入单个会员账户')" class="filter-item" style="width: 200px" />
      <el-button icon="el-icon-search" type="primary" class="filter-item" :loading="loading" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Send" icon="el-icon-plus" type="success" class="filter-item" @click="$refs.ActionDialogControl.open()">{{ $t('发送站内信') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" @update="_handleUpdate" />
  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import ActionDialog from './ActionDialog'
import GetColumns from './columns'
import { getStationletterList, addStationletter, updateStationletter, deleteStationletter } from '@/api/announcement'
import { $t } from '@/lang'

const sendTypeOptions = {
  1: $t('单个会员发送'),
  2: $t('指定会员列表发送'),
  3: $t('按VIP等级发送'),
  4: $t('充值会员发送'),
  5: $t('未充值会员发送')
}

export default {
  name: 'AnnouncementStationLetter',
  components: { ActionDialog },
  mixins: [TablePageMixin(getStationletterList)],
  data() {
    return {
      sendTypeOptions,
      defaultColumns: GetColumns.call(this),
      columns: [],
      search: {}
    }
  },
  methods: {
    _handleDelete({ id, title }) {
      this.$confirm(`${$t('确认删除标题')} ${title} ${$t('的站内信？')}`, { type: 'warning' }).then(() => {
        deleteStationletter({ stationLetterId: id }).then(([_, err]) => {
          if (!err) {
            this.$message.success($t('删除成功'))
            this.handleFetch()
          }
        })
      })
    },

    _handleCreate({ field, cancel, close }) {
      addStationletter({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success($t('添加成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      updateStationletter({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success($t('操作成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    implementationGetParams() {
      return { ...this.search }
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
