<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="daterange"
        class="filter-item"
        style="width: 320px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
      />

      <el-cascader :props="props" v-model="search.channelList" class="filter-item" clearable style="width: 300px" :placeholder="$t('渠道/链接')" filterable @change="hanleChange" />
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button type="primary" style="float: right" @click="$router.push({ name: 'ChannelStatisticalMannge' })">
        {{ $t('渠道管理') }}
      </el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      @refresh="handleFetch"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <RechargeDialog ref="RechargeDialogControl" />
    <ActiveMemberDialog ref="ActiveMemberDialogControl" />
    <NewMemberDialog ref="NewMemberDialogControl" />

  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getChannelSummaryDayPageList, getChannelPageList, getChannelLinePageList } from '@/api/statistical'
import RechargeDialog from './RechargeDialog'
import ActiveMemberDialog from './ActiveMemberDialog'
import NewMemberDialog from './NewMemberDialog'

export default {
  name: 'Channel',
  components: { RechargeDialog, ActiveMemberDialog, NewMemberDialog },
  mixins: [TablePageMixin(getChannelSummaryDayPageList)],
  data() {
    return {
      times: '',
      search: {
        channelList: []
      },
      filterColumn: false,
      columns: GetColumns.call(this),
      props: {
        checkStrictly: true,
        lazy: true,
        lazyLoad(node, resolve) {
          const { level, value } = node
          if (level === 0) {
            getChannelPageList({ pageSize: 1000, currentPage: 1 }).then(([channels, err]) => {
              if (!err) {
                const nodes = channels.map(o => ({
                  value: o.id,
                  label: o.channelName,
                  leaf: false
                }))
                resolve(nodes)
              }
            })
          } else if (level === 1) {
            getChannelLinePageList({ pageSize: 1000, currentPage: 1, channelId: value }).then(([lines, err]) => {
              if (!err) {
                const nodes = lines.map(o => ({
                  value: o.id,
                  label: o.lineName,
                  leaf: true
                }))
                resolve(nodes)
              }
            })
          } else {
            resolve(null)
          }
        }
      }
    }
  },
  methods: {
    hanleChange([channelId, lineId]) {
      this.search.channelId = channelId
      this.search.lineId = lineId
    },
    implementationGetParams() {
      if (this.times && Array.isArray(this.times)) {
        const [startDate, endDate] = this.times
        return {
          startDate,
          endDate,
          ...this.search
        }
      }
      return { ...this.search }
    }
  }
}
</script>
