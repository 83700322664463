var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tenant-function" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "彩种配置", name: "Lottery" } },
            [_c("Lottery", { ref: "TenantFunctionLottery" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "功能配置", name: "Function" } },
            [_c("Function", { ref: "TenantFunctionFunction" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }