var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bodys" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "220px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "AI聊天开关", prop: "aiChatEnabled" } },
            [
              _c("d-switch", {
                attrs: { "inactive-value": 0, "active-value": 1 },
                model: {
                  value: _vm.ruleForm.aiChatEnabled,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "aiChatEnabled", $$v)
                  },
                  expression: "ruleForm.aiChatEnabled",
                },
              }),
            ],
            1
          ),
          _vm.ruleForm.aiChatEnabled
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "机器人自动聊天最大回合",
                      prop: "maxReplies",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "420px" },
                      attrs: { placeholder: "请输入聊天最大回合" },
                      on: {
                        input: function ($event) {
                          return _vm.validateInput("maxReplies")
                        },
                      },
                      model: {
                        value: _vm.ruleForm.maxReplies,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "maxReplies", $$v)
                        },
                        expression: "ruleForm.maxReplies",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "机器人自动聊天最小延迟",
                      prop: "minDelaySeconds",
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "420px" },
                        attrs: { placeholder: "请输入最小延迟" },
                        on: {
                          input: function ($event) {
                            return _vm.validateInput("minDelaySeconds")
                          },
                        },
                        model: {
                          value: _vm.ruleForm.minDelaySeconds,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "minDelaySeconds", $$v)
                          },
                          expression: "ruleForm.minDelaySeconds",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("秒")])],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "机器人自动聊天最大延迟",
                      prop: "maxDelaySeconds",
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "420px" },
                        attrs: { placeholder: "请输入最大延迟" },
                        on: {
                          input: function ($event) {
                            return _vm.validateInput("maxDelaySeconds")
                          },
                        },
                        model: {
                          value: _vm.ruleForm.maxDelaySeconds,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "maxDelaySeconds", $$v)
                          },
                          expression: "ruleForm.maxDelaySeconds",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("秒")])],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }