<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model.trim="search.name" maxlength="16" clearable placeholder="请输入银行名称" class="filter-item" style="width: 200px" />
      <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleRefresh">查询</el-button>
      <el-button v-if="permission.Add" class="filter-item" icon="el-icon-plus" type="success" @click="openBankDialog()">添加银行</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <bank-dialog ref="bankDialogEle" @changesuccess="handleRefresh()" />
    <look-bank-card-bin ref="lookBankCardBin" />
  </div>
</template>
<script>
import bankDialog from './components/bank-dialog'
import lookBankCardBin from './components/look-bank-card-bin.vue'
import { getSupportBankList, deleteSupportBank, updateBankStatus } from '@/api/finance'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
export default {
  name: 'FinanceBank',
  components: {
    bankDialog,
    lookBankCardBin
  },
  mixins: [TablePageMixin(getSupportBankList)],
  data() {
    return {
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this)
    }
  },
  created() {},
  methods: {
    _delete(id, name) { // 删除银行
      this.$confirm(`确认删除 ${name} 吗？`, {
        type: 'warning'
      }).then(() => {
        deleteSupportBank({ id }).then(([data, err]) => {
          if (!err) {
            this.handleRefresh()
            this.$message.success(`${name} 删除成功！`)
          }
        })
      })
    },
    _editStatus(v) { // 修改启用状态
      return new Promise((resolve) => {
        const { name, binding } = v
        this.$confirm(`确认修改 ${name} 启用状态吗？`, { type: 'warning' }).then(() => {
          updateBankStatus({ name, binding: binding === 0 ? 1 : 0 }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.handleRefresh()
              this.$message.success(`${name} 启用状态修改成功！`)
            }
          })
        }).catch(() => {})
      })
    },
    openBankDialog(obj) {
      if (obj && obj.binding === 0) {
        this.$message.error(`${obj.name} 为停用状态不可修改！`)
        return
      }
      this.$refs.bankDialogEle.titleObj = {
        title: obj === undefined ? '新增银行卡' : `修改 ${obj.name} 信息`,
        type: obj === undefined ? 'add' : 'edit'
      }
      this.$refs.bankDialogEle.init(obj && obj.name)
    },

    implementationGetParams() {
      return { ...this.search }
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>

<style lang="scss">
  .card_code_warp {
    margin: -5px;

    .card_code {
      width: 20%;
      height: 30px;
      line-height: 30px;
      font-weight: normal;
      display: inline-block;
      border-right: 1px solid #dfe6ec;
      border-top: 1px solid #dfe6ec;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        border-top: 0;
      }

      &:nth-child(5n+5) {
        border-right: 0;
      }
    }
  }
</style>
