<template>
  <div class="app-container">
    <div class="filter-container">
      <span>申请时间：</span>
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        value-format="yyyy-MM-dd HH:mm:ss"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0, false)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1, false)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7, false)">{{ $t('本周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 14, false)">{{ $t('上周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30, false)">{{ $t('本月') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60, false)">{{ $t('上月') }}</el-button>
    </div>
    <div class="filter-container">
      <span>审核时间：</span>
      <el-date-picker
        v-model="times1"
        clearable
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        value-format="yyyy-MM-dd HH:mm:ss"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0, false, 'times1')">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1, false, 'times1')">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7, false, 'times1')">{{ $t('本周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 14, false, 'times1')">{{ $t('上周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30, false, 'times1')">{{ $t('本月') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60, false, 'times1')">{{ $t('上月') }}</el-button>
    </div>
    <div class="filter-container">
      <el-select v-model="search.status" clearable placeholder="状态" class="filter-item" style="width: 160px">
        <el-option v-for="(val, key) in borrowStatus" :key="key" :label="val" :value="key" />
      </el-select>
      <el-input v-model="search.orderNo" clearable placeholder="借款订单号" class="filter-item" style="width: 160px"/>
      <el-input v-model="search.memberName" clearable placeholder="会员账号" class="filter-item" style="width: 160px"/>
      <el-select v-model="search.memberType" clearable placeholder="会员类型" class="filter-item" style="width: 160px">
        <el-option v-for="item in memberTypes" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-select v-model="search.vipLevel" clearable placeholder="vip等级" class="filter-item" style="width: 160px">
        <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :originalColumns="defaultColumns"
      @refresh="handleSearch"
      :options="options"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <div class="filter-container">
          <span>
            <span class="filter-item">申请金额:{{ statistical.applyAmount }}</span>
            <span class="filter-item">申请笔数:{{ statistical.applyCount }}笔</span>
            <span class="filter-item">申请人数:{{ statistical.applyMemberCount }}人</span>
          </span>
          <span style="color: #D9001B;margin-left: 40px;">
            <span class="filter-item">待审核金额:{{ statistical.waitApprovalAmount }}</span>
            <span class="filter-item">待审核笔数:{{ statistical.waitApprovalCount }}笔</span>
            <span class="filter-item">待审核人数:{{ statistical.waitApprovalMemberCount }}人</span>
          </span>
          <span style="margin-left: 40px;">
            <span class="filter-item">不通过金额:{{ statistical.failedAmount }}</span>
            <span class="filter-item">不通过笔数:{{ statistical.failedCount }}笔</span>
            <span class="filter-item">不通过人数:{{ statistical.failedMemberCount }}人</span>
          </span>
        </div>
      </template>
    </d-table>
    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <StatusDialog ref="StatusDialogControl" @update="handleUpdateStatus" />
  </div>
</template>
<script>
import { borrowLoanApprovalList, borrowLoanApproval, borrowLoanApprovalCount } from '@/api/vipRewardMange'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getDefaultTimes } from '@/utils'
import { memberTypes, borrowStatus } from '@/libs/options'
import { getAllVipLevels } from '@/api/app'
import StatusDialog from './StatusDialog'

export default {
  name: 'AuditTab',
  mixins: [TablePageMixin(borrowLoanApprovalList)],
  components: { StatusDialog },
  data() {
    return {
      times: getDefaultTimes('timestamp', 0, 0, false),
      times1: null,
      columns: [],
      defaultColumns: GetColumns.call(this),
      vipLevels: [],
      memberTypes,
      borrowStatus,
      statistical: {}
    }
  },
  created() {
    this.handleVipLevels()
  },
  methods: {
    handleVipLevels() {
      getAllVipLevels().then(([items, err]) => {
        if (!err) {
          this.vipLevels = items
        }
      })
    },
    handleUpdateStatus({ field, cancel, close }) {
      borrowLoanApproval(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('操作成功')
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    handleBorrowLoanApproval({ orderNo }) {
      this.$confirm(`确定审核通过该借款`, this.$t('系统提示'), { type: 'warning' }).then(() => {
        borrowLoanApproval({ orderNo, status: 1 }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleFetch()
          }
        })
      })
    },
    implementationGetParams() {
      const search = { ...this.search }
      if (this.times && this.times.length === 2) {
        let [startTime, endTime] = this.times
        search.startApplyTime = startTime
        search.endApplyTime = endTime
      } else {
        delete search.startApplyTime
        delete search.endApplyTime
      }
      if (this.times1 && this.times1.length === 2) {
        let [startTime, endTime] = this.times1
        search.startApprovalTime = startTime
        search.endApprovalTime = endTime
      } else {
        delete search.startApprovalTime
        delete search.endApprovalTime
      }
      return search
    },
    implementationFetched() {
      borrowLoanApprovalCount(this.implementationGetParams()).then(([data, err]) => {
        if (!err) {
          this.statistical = data
        }
      })
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
