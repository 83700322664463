const CanChooseColumns = function() {
  return [
    {
      title: this.$t('日期'),
      key: 'day',
      align: 'center'
    },
    {
      title: this.$t('渠道标识'),
      align: 'center',
      key: 'channelName'
    },
    {
      title: this.$t('活跃用户'),
      align: 'center',
      key: 'activeUser',
      component: {
        render: (h, row) => {
          return (
            <p class='cursor-pointer hover-underline link' onClick={() => this.$refs.ActiveMemberDialogControl.open(row, this.search)}>
              { row.activeUser }
            </p>
          )
        }
      }
    },
    {
      title: this.$t('新增会员'),
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div class='cursor-pointer hover-underline link' onClick={() => this.$refs.NewMemberDialogControl.open(row, this.search)}>
              <p>{this.$t('苹果')}:{ row.registerIos }</p>
              <p>{this.$t('安卓')}:{ row.registerAndroid }</p>
              <p>{this.$t('H5')}:{ row.registerH5 }</p>
              <p>{this.$t('合计')}:{ row.registerIos + row.registerAndroid + row.registerH5 }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('充值金额'),
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{this.$t('金额')}:{ row.rechargeAmount }</p>
              <p>{this.$t('笔数')}:{ row.rechargeNum }</p>
              <p>{this.$t('人数')}:{ row.rechargeMember }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('提现金额'),
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{this.$t('金额')}:{ row.withdrawAmount }</p>
              <p>{this.$t('笔数')}:{ row.withdrawNum }</p>
              <p>{this.$t('人数')}:{ row.withdrawMember }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('首充金额'),
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div class='cursor-pointer hover-underline link' onClick={() => this.$refs.RechargeDialogControl.open(row, this.search)}>
              <p>{this.$t('金额')}:{ row.firstRechargeAmount }</p>
              <p>{this.$t('笔数')}:{ row.firstRechargeNum }</p>
              <p>{this.$t('人数')}:{ row.firstRechargeMember }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('过滤IP数'),
      align: 'center',
      key: 'filterIpNum'
    },
    {
      title: this.$t('过滤UDID数'),
      align: 'center',
      key: 'filterUdidNum'
    },
    {
      title: this.$t('次日留存'),
      align: 'center',
      key: 'keep1Rate'
    },
    {
      title: this.$t('三日留存'),
      align: 'center',
      key: 'keep3Rate'
    },
    {
      title: this.$t('周留存'),
      align: 'center',
      key: 'keep7Rate'
    },
    {
      title: this.$t('月留存'),
      align: 'center',
      key: 'keep30Rate'
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
