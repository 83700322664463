<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-item">
        <el-input v-model="search.operatorName" clearable placeholder="线路名称" class="filter-item" style="width: 140px" />
        <el-input v-model="search.createUser" clearable placeholder="域名" class="filter-item" style="width: 140px" />
        <el-select v-model="search.status" clearable placeholder="线路状态" class="filter-item" style="width: 140px">
          <el-option label="启用" :value="1" />
          <el-option label="禁用" :value="0" />
        </el-select>
        <el-select
          v-model="search.tenantCode"
          placeholder="所属租户"
          class="filter-item"
          clearable
        >
          <el-option
            v-for="item in tenants"
            :key="item.tenantCode"
            :value="item.tenantCode"
            :label="item.tenantName"
          />
        </el-select>
        <el-select v-model="search.domainType" clearable placeholder="域名类型" class="filter-item" style="width: 140px">
          <el-option v-for="(val, key) in domainTypes" :key="key" :label="val" :value="key" />
        </el-select>
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">
          查询
        </el-button>
        <el-button v-if="permission.Add" class="filter-item" type="success" icon="el-icon-plus" @click="onCreate">
          添加域名
        </el-button>
      </div>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleSearch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <ActionDialog ref="ActionDialogControl" @create="handleAdd" @update="handleUpdate" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getPlatformDomainList, platformDomainCreate, platformDomainUpdate, platformDomainDelete } from '@/api/permission'
import TablePageMixin from '@/mixins/table-page-mixin'
import ActionDialog from './ActionDialog.vue'
import GetColumns from './columns'
import { domainTypes } from '@/libs/options'

export default {
  name: 'PermissionUsers',
  components: { ActionDialog },
  mixins: [TablePageMixin(getPlatformDomainList)],
  data() {
    return {
      search: {},
      groupList: [],
      columns: [],
      defaultColumns: GetColumns.call(this),
      domainTypes
    }
  },
  computed: {
    ...mapState('app', ['tenants'])
  },
  created() {
    this.getTenantList()
  },
  methods: {
    ...mapActions('app', ['getTenantList']),
    onCreate() {
      this.$refs.ActionDialogControl.open()
    },

    handleAdd({ field, cancel, close }) {
      platformDomainCreate({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    handleUpdate({ field, cancel, close }) {
      platformDomainUpdate({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    handleChangeStatus({ id, status }) {
      return new Promise((resolve) => {
        this.$confirm(status === 0 ? '确认启用此线路' : '确认禁用此线路', this.$t('系统提示'), { type: 'warning' }).then(() => {
          platformDomainDelete({
            id,
            status: ~status + 2
          }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          }).catch(() => {})
        })
      })
    },

    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
