var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("新增家族"),
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("家族名称"), prop: "familyName" } },
            [
              _c("el-input", {
                attrs: { maxlength: "10", "show-word-limit": "" },
                model: {
                  value: _vm.ruleForm.familyName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "familyName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.familyName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("可提现账号类型"), prop: "accountType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: _vm.$t("选择类型") },
                  model: {
                    value: _vm.ruleForm.accountType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "accountType", $$v)
                    },
                    expression: "ruleForm.accountType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: _vm.$t("支付宝"), value: 1 },
                  }),
                  _c("el-option", {
                    attrs: { label: _vm.$t("微信"), value: 2 },
                  }),
                  _c("el-option", {
                    attrs: { label: _vm.$t("银行卡"), value: 3 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.accountType == 3
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("银行名称"), prop: "bankName" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: _vm.$t("0-10字"), maxlength: "10" },
                      model: {
                        value: _vm.ruleForm.bankName,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "bankName", $$v)
                        },
                        expression: "ruleForm.bankName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("开户行"), prop: "bankAddress" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: _vm.$t("请输入开户行"),
                        maxlength: "30",
                      },
                      model: {
                        value: _vm.ruleForm.bankAddress,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "bankAddress", $$v)
                        },
                        expression: "ruleForm.bankAddress",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  _vm.ruleForm.accountType == 3
                    ? _vm.$t("持卡人姓名")
                    : _vm.$t("提现姓名"),
                prop: "accountName",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("0-10字"),
                  maxlength: "10",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.ruleForm.accountName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "accountName", $$v)
                  },
                  expression: "ruleForm.accountName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("提现帐号"), prop: "accountNo" } },
            [
              _c("el-input", {
                attrs: { maxlength: "24", placeholder: _vm.$t("6-24字") },
                model: {
                  value: _vm.ruleForm.accountNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "accountNo", $$v)
                  },
                  expression: "ruleForm.accountNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("礼物收入分成"), prop: "giftPercent" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: _vm.$t("0-99支持1位小数") },
                  model: {
                    value: _vm.ruleForm.giftPercent,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "giftPercent", $$v)
                    },
                    expression: "ruleForm.giftPercent",
                  },
                },
                [
                  _c("span", { attrs: { slot: "append" }, slot: "append" }, [
                    _vm._v("%"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("投注分成"), prop: "betsPercent" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: _vm.$t("0-99支持1位小数") },
                  model: {
                    value: _vm.ruleForm.betsPercent,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "betsPercent", $$v)
                    },
                    expression: "ruleForm.betsPercent",
                  },
                },
                [
                  _c("span", { attrs: { slot: "append" }, slot: "append" }, [
                    _vm._v("%"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("房费分成比例"), prop: "payroomPercent" },
            },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: _vm.$t("0-99支持1位小数") },
                  model: {
                    value: _vm.ruleForm.payroomPercent,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "payroomPercent", $$v)
                    },
                    expression: "ruleForm.payroomPercent",
                  },
                },
                [
                  _c("span", { attrs: { slot: "append" }, slot: "append" }, [
                    _vm._v("%"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("家族账号"), prop: "familyOwerLogin" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("6-12位"),
                  "show-word-limit": "",
                  maxlength: "12",
                },
                model: {
                  value: _vm.ruleForm.familyOwerLogin,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "familyOwerLogin", $$v)
                  },
                  expression: "ruleForm.familyOwerLogin",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("登陆密码"), prop: "password" } },
            [
              _c("el-input", {
                attrs: { type: "password", placeholder: _vm.$t("6-12位") },
                model: {
                  value: _vm.ruleForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "password", $$v)
                  },
                  expression: "ruleForm.password",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }