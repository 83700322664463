<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.pictureCategoryName" clearable placeholder="类别名称，最多20个字符" maxlength="20" class="filter-item" style="width: 200px" @keyup.enter.native="handleSearch" />
      <el-select v-model="search.lotteryId" class="filter-item" clearable placeholder="彩种" style="width: 140px" @change="search.source = null">
        <el-option v-for="(val, key) in xsLottery" :key="key" :value="+key" :label="val" />
      </el-select>
      <el-select v-model="search.source" clearable :placeholder="$t('数据来源')" style="width: 140px" class="filter-item">
        <el-option v-for="item of sources.filter(e => e.lotteryId === search.lotteryId)" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
      <el-button v-if="permission.Add" type="success" icon="el-icon-plus" class="filter-item" @click="onCreate">{{ $t('新建类别') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />
    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
    <ActionDialog ref="ActionDialogControl" @create="handleAddPictureCategory" @update="handleUpdatePictureCategory" />
  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import { pictureCategoryPageList, pictureCategoryAdd, pictureCategoryUpdate, pictureCategoryDel } from '@/api/interactive'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { xsLottery, sources } from '@/libs/options'

export default {
  name: 'CategoryTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(pictureCategoryPageList)],
  data() {
    return {
      search: { source: '' },
      filterColumn: false,
      columns: GetColumns.call(this),
      xsLottery,
      sources
    }
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogControl.open(null, this.search.source)
    },
    handleAddPictureCategory({ field, cancel, close }) {
      pictureCategoryAdd(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.handleRefresh()
          this.$emit('refresh')
        } else {
          cancel()
        }
      })
    },
    handleUpdatePictureCategory({ field, cancel, close }) {
      pictureCategoryUpdate(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.handleRefresh()
          this.$emit('refresh')
        } else {
          cancel()
        }
      })
    },
    handleDeletePictureCategory({ id }) {
      this.$confirm(this.$t('删除图库类别后，关联图库只会在全部列表展示。'), this.$t('确定删除图库类别吗?'), { type: 'warning' }).then(() => {
        pictureCategoryDel({ pictureCategoryId: id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      })
    },
    goPic(categoryName, lotteryId, source) {
      this.$emit('child', categoryName, lotteryId, source)
    }
  }
}
</script>
