var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "160px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "帖子类型" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: {
                    disabled: !!(_vm.$route.query && _vm.$route.query.id),
                  },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("后台发帖")]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v("代用户发帖"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.type == 0
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "用户账号", prop: "memberName" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "500px" },
                      attrs: { placeholder: "用户账号" },
                      model: {
                        value: _vm.ruleForm.memberName,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "memberName", $$v)
                        },
                        expression: "ruleForm.memberName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.xsLottery[_vm.ruleForm.lotteryId] + "心水",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          width: "500px",
                          display: "block",
                          "margin-top": "10px",
                        },
                        attrs: {
                          type: "danger",
                          size: "small",
                          disabled: !_vm.ruleForm.memberName,
                        },
                        on: { click: _vm.handleLastTermForUser },
                      },
                      [_vm._v("加载上期")]
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "el-form-item",
                  { attrs: { label: "后台用户", prop: "nickname" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "500px" },
                      attrs: { placeholder: "后台用户昵称" },
                      model: {
                        value: _vm.ruleForm.nickname,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "nickname", $$v)
                        },
                        expression: "ruleForm.nickname",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.xsLottery[_vm.ruleForm.lotteryId] + "心水",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          width: "500px",
                          display: "block",
                          "margin-top": "10px",
                        },
                        attrs: {
                          type: "danger",
                          size: "small",
                          disabled: !_vm.ruleForm.nickname,
                        },
                        on: { click: _vm.handleLastTermForBack },
                      },
                      [_vm._v("加载上期")]
                    ),
                  ],
                  1
                ),
              ],
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入标题", maxlength: "50" },
                model: {
                  value: _vm.ruleForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "大神榜", prop: "godTypeId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { clearable: "", placeholder: "无" },
                  model: {
                    value: _vm.ruleForm.godTypeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "godTypeId", $$v)
                    },
                    expression: "ruleForm.godTypeId",
                  },
                },
                _vm._l(_vm.typeItems, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否加精" } },
            [
              _c(
                "d-switch",
                {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.ruleForm.topViewStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "topViewStatus", $$v)
                    },
                    expression: "ruleForm.topViewStatus",
                  },
                },
                [
                  _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                    _vm._v("是"),
                  ]),
                  _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                    _vm._v("否"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "正文内容", prop: "content" } },
            [
              _c("tinymce", {
                ref: "tinymce",
                attrs: { maxlength: 2000 },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "隐藏内容" } },
            [
              _c("tinymce", {
                ref: "tinymce",
                attrs: { height: 200 },
                model: {
                  value: _vm.ruleForm.hideContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "hideContent", $$v)
                  },
                  expression: "ruleForm.hideContent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回复可见隐藏" } },
            [
              _c("d-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.ruleForm.replyViewStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "replyViewStatus", $$v)
                  },
                  expression: "ruleForm.replyViewStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "登录可见隐藏" } },
            [
              _c("d-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.ruleForm.loginViewStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "loginViewStatus", $$v)
                  },
                  expression: "ruleForm.loginViewStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "充值可见隐藏" } },
            [
              _c("d-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.ruleForm.payViewStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "payViewStatus", $$v)
                  },
                  expression: "ruleForm.payViewStatus",
                },
              }),
              _vm.ruleForm.payViewStatus === 1
                ? [
                    _c(
                      "el-checkbox",
                      {
                        staticStyle: { margin: "0 10px" },
                        model: {
                          value: _vm.instant,
                          callback: function ($$v) {
                            _vm.instant = $$v
                          },
                          expression: "instant",
                        },
                      },
                      [_vm._v("即时充值")]
                    ),
                    _vm.instant
                      ? _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "金额" },
                          model: {
                            value: _vm.ruleForm.rechargeViewMoney,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "rechargeViewMoney", $$v)
                            },
                            expression: "ruleForm.rechargeViewMoney",
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: "VIP可见隐藏", prop: "vipViewLevel" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.ruleForm.vipViewLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "vipViewLevel", $$v)
                    },
                    expression: "ruleForm.vipViewLevel",
                  },
                },
                _vm._l(_vm.vipLevels, function (item) {
                  return _c("el-option", {
                    key: item.vipLevel,
                    attrs: {
                      value: item.vipLevel,
                      label: "VIP" + item.vipLevel,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.type === 1 && !_vm.ruleForm.id && _vm.tenants.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: "同步租户", prop: "tenantCodeList" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.ruleForm.tenantCodeList,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "tenantCodeList", $$v)
                        },
                        expression: "ruleForm.tenantCodeList",
                      },
                    },
                    _vm._l(_vm.tenants, function (tenant) {
                      return _c(
                        "el-checkbox",
                        {
                          key: tenant.tenantCode,
                          attrs: { label: tenant.tenantCode },
                        },
                        [_vm._v(_vm._s(tenant.tenantName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "140px" },
                  attrs: { type: "danger", size: "large" },
                  on: { click: _vm.goback },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "140px" },
                  attrs: { type: "success", size: "large" },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }