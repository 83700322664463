<template>
  <div>
    <div class="filter-container">
      <el-select v-model="search.sportType" :placeholder="$t('体育项目')" clearable style="width: 120px" class="filter-item">
        <el-option :label="$t('Expert_Plan.足球')" :value="1" />
        <el-option :label="$t('Expert_Plan.篮球')" :value="2" />
      </el-select>
      <el-select v-model="search.recommend" :placeholder="$t('Interactive_Match.LeagueTab.是否推荐')" clearable style="width: 120px" class="filter-item">
        <el-option :label="$t('是')" :value="1" />
        <el-option :label="$t('否')" :value="0" />
      </el-select>
      <el-select v-model="search.hot" :placeholder="$t('Interactive_Match.LeagueTab.热门赛事')" clearable style="width: 120px" class="filter-item">
        <el-option :label="$t('是')" :value="1" />
        <el-option :label="$t('否')" :value="0" />
      </el-select>
      <el-select v-model="search.showStatus" :placeholder="$t('Interactive_Match.LeagueTab.展示赛事')" clearable style="width: 120px" class="filter-item">
        <el-option :label="$t('是')" :value="1" />
        <el-option :label="$t('否')" :value="0" />
      </el-select>

      <el-input v-model="search.competitionName" :placeholder="$t('Interactive_Match.LeagueTab.赛事名称')" clearable style="width: 160px" class="filter-item" />

      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @update="_handleUpdate" />

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getCompetitionList, updateCompetition } from '@/api/match'
import ActionDialog from './components/ActionDialog'
import { mapGetters } from 'vuex'

const insetparams = {}

export default {
  name: 'LeagueTab',
  componentName: 'LeagueTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(getCompetitionList, true, insetparams)],
  data() {
    return {
      filterColumn: false,
      columns: GetColumns.call(this),
      search: { sportType: 1 },
      recommendPlayMaps: this.$t('recommendPlayMaps')
    }
  },
  computed: {
    ...mapGetters(['tenantCode'])
  },
  methods: {
    _handleUpdate({ field, cancel, close }) {
      updateCompetition({
        ...field,
        recommendPlay: field.recommendPlay.join(',')
      }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    implementationGetParams() {
      return { ...this.search, tenantCode: this.tenantCode }
    }
  }
}
</script>
