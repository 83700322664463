import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('用户账户'),
      key: 'username',
      align: 'center'
    },
    {
      title: this.$t('真实姓名'),
      key: 'realName',
      align: 'center'
    },
    {
      title: this.$t('银行账户'),
      key: 'cardNumber',
      align: 'center'
    },
    {
      title: this.$t('银行名称'),
      key: 'bank',
      align: 'center'
    },
    {
      title: this.$t('开户地址'),
      key: 'bankAddress', 
    },
    {
      title: this.$t('绑定时间'),
      key: 'bindTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.bindTime) }</span>)
        }
      }
    },
    {
      title: this.$t('修改时间'),
      key: 'updateTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('状态'),
      align: 'center',
      key: 'status',
      component: {
        render: (h, row) => {
          return (
            <el-tag type={this.onlineFilter(row.status)}>
              { row.status === 0 ? this.$t('正常') : this.$t('黑名单') }
            </el-tag>
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 180,
      component: {
        render: (h, row) => {
          const { Delete, Update } = this.permission
          return (
            <span>
              { Update && <el-button type='primary' size='mini' icon='el-icon-edit' onClick={() => this.$refs.EditDialogControl.open(row)}>{this.$t('编辑')}</el-button> }
              { Delete && <el-button type='danger' size='mini' icon='el-icon-delete' onClick={this.handleDeleteCard.bind(this, row)}>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
