var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("明细详情"),
        visible: _vm.visible,
        width: "75%",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.handleRefresh,
        closed: _vm.handleClosed,
      },
    },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-container-item" },
          [
            _c("el-input", {
              staticStyle: { width: "160px" },
              attrs: { placeholder: _vm.$t("主播昵称"), clearable: "" },
              model: {
                value: _vm.search.nickName,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "nickName", $$v)
                },
                expression: "search.nickName",
              },
            }),
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-search", type: "primary" },
                on: { click: _vm.handleRefresh },
              },
              [_vm._v(_vm._s(_vm.$t("查询")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _vm.summary
            ? _c(
                "div",
                {
                  staticStyle: { "line-height": "50px" },
                  attrs: { flex: "cross:center main:justify" },
                },
                [
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.$t("日期")) +
                        "：" +
                        _vm._s(
                          _vm._f("parseTime")(
                            _vm.summary.onlineDate,
                            "{y}-{m}-{d}"
                          )
                        )
                    ),
                  ]),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.$t("礼物分成汇总")) + "："),
                    _c("span", { staticClass: "danger-type" }, [
                      _vm._v(_vm._s(_vm.summary.sumGiftIncome)),
                    ]),
                    _vm._v(" " + _vm._s(_vm.$t("投注分成汇总")) + "："),
                    _c("span", { staticClass: "danger-type" }, [
                      _vm._v(_vm._s(_vm.summary.sumBetsIncome)),
                    ]),
                    _vm._v(" " + _vm._s(_vm.$t("房费分成汇总")) + "："),
                    _c("span", { staticClass: "danger-type" }, [
                      _vm._v(_vm._s(_vm.summary.sumPayroomIncome)),
                    ]),
                    _vm._v(" " + _vm._s(_vm.$t("总分成汇总")) + "："),
                    _c("span", { staticClass: "danger-type" }, [
                      _vm._v(_vm._s(_vm.summary.sumTotalAmount)),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "el-table",
            _vm._b(
              { attrs: { data: _vm.items, "max-height": "250" } },
              "el-table",
              _vm.options,
              false
            ),
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("主播昵称"),
                  prop: "nickName",
                  align: "center",
                  width: "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("开播时段"),
                  prop: "time",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(_vm._f("parseTime")(row.onlineDate)) +
                            " ~ " +
                            _vm._s(_vm._f("parseTime")(row.offlineDate))
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("礼物收入/分成"),
                  prop: "gift",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(row.giftAmount) + "/" + _vm._s(row.giftIncome)
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("投注收入/分成"),
                  prop: "bets",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(row.betsAmount) + "/" + _vm._s(row.betsIncome)
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("房费收入/分成"),
                  prop: "room",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(row.payroomAmount) +
                            "/" +
                            _vm._s(row.payroomIncome)
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("总计分成"),
                  prop: "total",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(_vm._s(row.totalAmount))]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.close } },
            [_vm._v(" " + _vm._s(_vm.$t("关闭")) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }