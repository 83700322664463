<template>
  <div class="app-container">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('入口开关')" prop="openFlag">
        <el-switch
          v-model="ruleForm.openFlag"
          :active-value="1"
          :inactive-value="0"
          active-text="开"
          inactive-text="关">
        </el-switch>
        <span style="padding-left: 20px; color: #999999;">关闭入口后,前台不再显示vip红包功能入口,正常情况下请勿关闭入口</span>
      </el-form-item>

      <el-form-item :label="$t('打码要求')" prop="rollingOpenFlag" class="dmyq">
        <el-switch
          v-model="ruleForm.rollingOpenFlag"
          :active-value="1"
          :inactive-value="0"
          active-text="开"
          inactive-text="关">
        </el-switch>
        <el-form-item prop="rollingFactor" label="打码倍数" label-width="100px">
          <el-input v-model="ruleForm.rollingFactor" @input="ruleForm.rollingFactor = ruleForm.rollingFactor.replace(/[^\d]/g, '')" style="width: 100px;" />
          <span style="padding-left: 20px; color: #999999;">开启后发的红包,需要满足打码倍数要求才能进行提现.</span>
        </el-form-item>
      </el-form-item>

      <el-form-item :label="$t('红包说明')" prop="redEnvelopeDescription">
        <tinymce v-model="ruleForm.redEnvelopeDescription" :height="200" />
      </el-form-item>

      <el-form-item :label="$t('vip奖励描述')" prop="awardDescription">
        <tinymce v-model="ruleForm.awardDescription" :height="200" />
      </el-form-item>

      <el-form-item>
        <!-- v-if="permission.Update" -->
        <el-button type="primary" @click="handleSubmit" :loading="submitting">
          {{ $t('保存') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { vipRedEnvelopeConfig, getVipRedEnvelopeConfig } from '@/api/vipRewardMange'
import Tinymce from '@/components/Tinymce'
import { mapGetters } from 'vuex'
export default {
  name: 'HbTab',
  components: { Tinymce },
  data() {
    const validateRollingFactor = (rule, value, callback) => {
      if (value !== '' && value !== null) {
        const num = Number(value)
        if (isNaN(num) || num < 1 || num > 100) {
          callback(new Error('打码倍数有效区间：1~100'))
        }
      }
      callback()
    }
    return {
      ruleForm: {
        openFlag: 0,
        rollingOpenFlag:  0,
        rollingFactor: '',
        redEnvelopeDescription: '',
        awardDescription: '',
      },
      rules: {
        rollingFactor: [
          { required: true, message: '请输入打码倍数', trigger: 'blur' },
          { validator: validateRollingFactor, trigger: 'blur' }
        ],
        awardDescription: [
          { required: true, message: '请输入vip奖励描述', trigger: 'change' }
        ],
        redEnvelopeDescription: [
          { required: true, message: '请输入红包说明', trigger: 'change' }
        ],
      },
      submitting: false
    }
  },
  created() {
    this.getInfos()
  },
  computed: {
    ...mapGetters(['permissionSensitive']),
    permission() {
      return this.permissionSensitive(this.$route.path, this.$options.name)
    }
  },
  methods: {
    handleSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.submitting = true
          vipRedEnvelopeConfig(this.ruleForm).then(([_, err]) => {
            if (!err) {
              this.$message.success(this.$t('保存成功'))
            }
          }).finally(() => {
            this.submitting = false
          })
        }
      })
    },

    getInfos() {
      getVipRedEnvelopeConfig({}).then(([data, err]) => {
        if (!err) {
          this.ruleForm = data
          this.ruleForm.openFlag === 1 ? 1 : 0
          this.ruleForm.rollingOpenFlag === 1 ? 1 : 0
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.dmyq {
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
  }
}
</style>