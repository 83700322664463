var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑彩票直播封面",
        visible: _vm.visible,
        width: "500px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
            "label-suffix": ":",
          },
        },
        [
          _c("el-form-item", { attrs: { label: _vm.$t("系列彩种") } }, [
            _vm._v(" " + _vm._s(_vm.lottery) + " "),
          ]),
          _c("h4", [_vm._v("直播封面")]),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("直播中"), prop: "liveImg" } },
            [
              _c(
                "div",
                { staticClass: "upload" },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        accept: "image/png,image/jpg,image/jpeg,image/webp",
                        action: _vm.action,
                        data: { type: "image", folder: "icon" },
                        headers: _vm.$headers(),
                        "show-file-list": false,
                        "on-success": _vm._handleLiveImgSuccess,
                      },
                    },
                    [
                      _vm.ruleForm.liveImg
                        ? _c("img", {
                            staticClass: "image",
                            attrs: { src: _vm.ruleForm.liveImg },
                          })
                        : _c("i", { staticClass: "el-icon-upload" }),
                    ]
                  ),
                  _c("i", { staticClass: "el-icon-warning" }),
                  _vm._v(
                    " 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。 "
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("即将开奖"), prop: "playImg" } },
            [
              _c(
                "div",
                { staticClass: "upload" },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        accept: "image/png,image/jpg,image/jpeg,image/webp",
                        action: _vm.action,
                        data: { type: "image", folder: "icon" },
                        headers: _vm.$headers(),
                        "show-file-list": false,
                        "on-success": _vm._handlePlayImgSuccess,
                      },
                    },
                    [
                      _vm.ruleForm.playImg
                        ? _c("img", {
                            staticClass: "image",
                            attrs: { src: _vm.ruleForm.playImg },
                          })
                        : _c("i", { staticClass: "el-icon-upload" }),
                    ]
                  ),
                  _c("i", { staticClass: "el-icon-warning" }),
                  _vm._v(
                    " 提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。 "
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _vm.emitName !== "details"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }