// 充值提现相关接口
import request from '@/plugins/axios'

// 获取快捷金额
export function getPaymentAmount(params = {}) {
  return request({
    url: '/paymentAmountConf/list',
    method: 'get',
    params
  })
}
// 获取快捷金额
export function addPaymentAmount(data) {
  return request({
    url: '/paymentAmountConf/add',
    method: 'post',
    data
  })
}
