import { filterColumns } from '@/utils'
import { validURL } from '@/utils/validate'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('VIP等级'),
      key: 'vipLevel',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>VIP{row.vipLevel}</div>
          )
        }
      }
    },
    {
      title: this.$t('VIP图标'),
      key: 'vipLog',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          if (validURL(row.vipLog)) {
            return <el-image src={row.vipLog} style='width: 80px; height: 30px' fit='cover' lazy></el-image>
          }
        }
      }
    },
    
    {
      title: this.$t('升级充值金额'),
      key: 'rechargeUpgradeAmount',
      align: 'center',
    },
    {
      title: this.$t('升级有效投注'),
      key: 'upgradeLevelMinPlayAmount',
      align: 'center',
    },
    {
      title: this.$t('保级有效投注'),
      key: 'keepLevelMinPlayAmount',
      align: 'center',
    },
    {
      title: this.$t('保级计算周期（天）'),
      key: 'keepLevelDays',
      align: 'center',
    },
    {
      title: this.$t('最后操作时间'),
      key: 'updateTime',
      align: 'center',
      minWidth: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作员'),
      key: 'operatorName',
      align: 'center',
      minWidth: 160,
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      minWidth: 200,
      component: {
        render: (h, row) => {
          // const { Update, Delete } = this.permission
          return (
            <span>
              {  <el-button type='primary' size='mini' icon='el-icon-edit-outline' onClick={() => this.$refs.ActionDialogControl.open(row)}>{this.$t('编辑')}</el-button> }
              {  <el-button type='danger' size='mini' icon='el-icon-delete' onClick={this.handleDeleteAccount.bind(this, row.id)}>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
