<template>
  <div class="app-container">

    <div class="filter-container">
      <el-select
        v-model="search.tenantCode"
        placeholder="请选择租户"
        style="margin-bottom: 20px"
        @change="_handleChangeTenant"
      >
        <el-option
          v-for="item in tenants"
          :key="item.tenantCode"
          :value="item.tenantCode"
          :label="item.tenantName"
        />
      </el-select>

      <el-button
        v-if="permission.AddVersion"
        type="success"
        icon="el-icon-plus"
        style="float: right"
        :disabled="!search.tenantCode"
        @click="onAction"
      >创建版本</el-button>
    </div>

    <el-tabs v-model="search.appType" type="card" @tab-click="_handleTabClick">
      <el-tab-pane label="Android版本" name="1" />
      <el-tab-pane label="IOS版本" name="2" />
    </el-tabs>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" />

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import TablePageMixin from '@/mixins/table-page-mixin'
import methodMixins from '../common/methodMixins'
import GetColumns from '../common/columns'
import ActionDialog from '../common/ActionDialog'
import { getVersionList } from '@/api/version'

export default {
  name: 'VersionClient',
  components: { ActionDialog },
  mixins: [TablePageMixin(getVersionList, false), methodMixins],
  data() {
    return {
      search: { servicePlatform: 1, appType: '1' },
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  computed: {
    ...mapState('app', ['tenants'])
  },
  created() {
    this.getTenantList()
  },
  methods: {
    ...mapActions('app', ['getTenantList']),
    _handleChangeTenant() {
      this.handleRefresh()
    }
  }
}
</script>
