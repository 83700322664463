<template>
  <el-dialog
    :title="$t('分成配置')"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item :label="$t('礼物收入分成')" prop="giftPercent">
        <el-input v-model="ruleForm.giftPercent" :placeholder="$t('0.1-100支持1位小数')">
          <span slot="append">%</span>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('投注分成')" prop="betsPercent">
        <el-input v-model="ruleForm.betsPercent" :placeholder="$t('0.1-100支持1位小数')">
          <span slot="append">%</span>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('房费分成比例')" prop="payroomPercent">
        <el-input v-model="ruleForm.payroomPercent" :placeholder="$t('0.1-100支持1位小数')">
          <span slot="append">%</span>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  giftPercent: '',
  betsPercent: '',
  payroomPercent: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        giftPercent: [
          { required: true, message: this.$t('请输入礼物收入分成'), trigger: 'blur' },
          { pattern: /^([1-9][0-9]?|0)(\.\d)?$/, message: this.$t('请正确输入礼物分成'), trigger: 'blur' }
        ],
        betsPercent: [
          { required: true, message: this.$t('请输入投注分成'), trigger: 'blur' },
          { pattern: /^([1-9][0-9]?|0)(\.\d)?$/, message: this.$t('请正确输入投注分成'), trigger: 'blur' }
        ],
        payroomPercent: [
          { required: true, message: this.$t('请输入房费分成'), trigger: 'blur' },
          { pattern: /^([1-9][0-9]?|0)(\.\d)?$/, message: this.$t('请正确输入房费分成'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(row) {
      this.ruleForm.familyId = row.familyId
      this.ruleForm.giftPercent = parseFloat(row.giftPercent).toFixed(1)
      this.ruleForm.betsPercent = parseFloat(row.betsPercent).toFixed(1)
      this.ruleForm.payroomPercent = parseFloat(row.payroomPercent).toFixed(1)
      this.visible = true
    }

  }
}
</script>

