<template>
  <el-dialog
    :title="$t('添加限制')"
    width="700px"
    :visible.sync="visible"
    @open="onOpen"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item :label="$t('限制内容')">
        <el-row type="flex" align="center">
          <el-col :span="4">
            <el-form-item prop="regin">
              <el-select
                v-model="ruleForm.region"
                filterable
                :placeholder="$t('请选择')"
              >
                <el-option
                  v-for="(item, i) in Regins"
                  :key="item.code + i"
                  :value="item.code"
                >
                  <span style="float: left">{{ item.city }}</span>
                  <span style="float: right; color: #909399; font-size: 12px">{{ item.code }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item prop="phone">
              <el-input v-model.trim="ruleForm.phone" :placeholder="$t('请输入限制内容')" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('限制说明')" prop="remark">
        <el-input v-model="ruleForm.remark" type="textarea" maxlength="240" show-word-limit :autosize="{ minRows: 4, maxRows: 8}" :placeholder="$t('请输入限制说明')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('取 消') }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">{{ $t('确 定') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getRegins } from '@/api/interactive'

const getDefaultForm = () => ({
  region: '',
  phone: '',
  remark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      Regins: [],
      emitName: 'create',
      rules: {
        region: [{ required: true, message: this.$t('请选择'), trigger: 'change' }],
        phone: [
          { required: true, message: this.$t('请出入限制内容'), trigger: 'blur' }
          // { pattern: /^([1][3,4,5,6,7,8,9])\d{1,9}$/, message: '请输入正确号码或号码段', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: this.$t('请出入限制说明'), trigger: 'blur' },
          { min: 2, max: 240, message: this.$t('请输入2~240个字符'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm.id = row.id
        this.ruleForm.region = row.region
        this.ruleForm.phone = row.phone
        this.ruleForm.remark = row.remark
        this.emitName = 'update'
      }
      this.visible = true
    },
    onOpen() {
      getRegins().then(([data, err]) => {
        if (!err) {
          this.Regins = data
        }
      })
    }
  }
}
</script>
