<template>
  <el-dialog title="注册内部账号" width="500px" :visible.sync="visible" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="用户名" prop="memberName" required>
        <el-input v-model="ruleForm.memberName" placeholder="请输入用户名"/>
      </el-form-item>
      <el-form-item label="登录密码" prop="password" required>
        <el-input v-model="ruleForm.password" placeholder="请输入6~12位英文数字密码" type="password" />
      </el-form-item>
      <el-form-item label="用户类型" prop="userType" required>
        <el-radio v-model="ruleForm.userType" label="0">普通会员</el-radio>
        <el-radio v-model="ruleForm.userType" label="1">推单专家用户</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { validAccountAndPassword } from '@/utils/validate'
const getDefaultForm = () => ({
  memberName: '',
  password: '',
  userType: ''
})
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入登录密码')))
      } else {
        if (!validAccountAndPassword(value)) {
          callback(new Error(this.$t('请输入6~12位英文数字密码')))
        }
        callback()
      }
    }
    return {
      emitName: 'register',
      rules: {
        memberName: [{ required: true, message: '请输入用户名', trigger: "blur" },],
        password: [{ validator: validatePass, trigger: 'blur' }],
        userType: [{ required: true, message: '请选择用户类型', trigger: 'change' }]
      }
    }
  },
  methods: {
    open() {
      this.visible = true
    }
  }
}
</script>
