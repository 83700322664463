
export default function (vm){
    return {
    "UPGRADE_BOUNS":[
        {
            label: "会员账号",
            prop:"memberName",
            html:"span"
        },
        {
            label: "VIP等级",
            prop:"vipLevel",
            html:"span"
        },
        {
            label: "领取状态",
            prop:"status",
            html:"switch"
        },
        {
            label: "下一级彩金",
            prop:"vipAmount",
            html:"span",
            unit:`2`
        },
        {
            label: "下一级可领取彩金",
            prop:"totalAmount",
            html:"span",
            unit:"2"
        },
        {
            label: "变更额度",
            prop:"changeQuota",
            html:"span"
        },
       
        {
            label: "额度调整",
            prop:"addorminus",
            html:"radio",
        },
        {
            label: "",
            prop:"content",
            html:"input",
            placeholder:"请输入"
            
        },
        {
            label: "",
            prop:"text",
            html:"span",
            class:"red-text",
            labelWidth: '50px'
        },
        // 升级到下一还需1000存款，还需1000有效投注。
    ],
    "WEEK_RED_ENVELOPE":[
        {
            label: "会员账号",
            prop:"memberName",
            html:"span"
        },
        {
            label: "VIP等级",
            prop:"vipLevel",
            html:"span"
        },
        {
            label: "领取状态",
            prop:"status",
            html:"switch"
        },
        {
            label: "周红包",
            prop:"vipAmount",
            html:"span",
            unit:"1"
        },
        {
            label: "实际周红包",
            prop:"totalAmount",
            html:"span",
            unit:"1"
        },
        {
            label: "变更额度",
            prop:"changeQuota",
            html:"span"
        },
        
        {
            label: "额度调整",
            prop:"addorminus",
            html:"radio",
        },
        {
            label: "",
            prop:"content",
            html:"input",
            placeholder:"请输入"
        },
    ],
    "MONTH_RED_ENVELOPE":[
        {
            label: "会员账号",
            prop:"memberName",
            html:"span"
        },
        {
            label: "VIP等级",
            prop:"vipLevel",
            html:"span"
        },
        {
            label: "领取状态",
            prop:"status",
            html:"switch"
        },
        {
            label: "月红包",
            prop:"vipAmount",
            html:"span",
            unit:"1"
        },
        {
            label: "实际月红包",
            prop:"totalAmount",
            html:"span",
            unit:"1"
        },
        {
            label: "变更额度",
            prop:"changeQuota",
            html:"span"
        },
        
        {
            label: "额度调整",
            prop:"addorminus",
            html:"radio",
        },
        {
            label: "",
            prop:"content",
            html:"input",
            placeholder:"请输入"
        },
    ],
    "BORROW_MONEY":[
        {
            label: "会员账号",
            prop:"memberName",
            html:"span"
        },
        {
            label: "VIP等级",
            prop:"vipLevel",
            html:"span"
        },
        {
            label: "领取状态",
            prop:"status",
            html:"switch"
        },
        {
            label: "借呗额度",
            prop:"totalAmount",
            html:"span",
            unit:"1"
        },
        {
            label: "实际借呗额度",
            prop:"remainAmount",
            html:"span",
            unit:"1"
        },
        {
            label: "借呗可用额度",
            prop:"finalAmount",
            html:"span",
             unit:"1"
        },
        {
            label: "待还款",
            prop:"remainRepayAmount",
            html:"span"
        },
        {
            label: "变更额度",
            prop:"changeQuota",
            html:"span"
        },
        {
            label: "额度调整",
            prop:"addorminus",
            html:"radio",
        },
        {
            label: "",
            prop:"content",
            html:"input",
            placeholder:"请输入"
        },
    ],
    "GAME_REBATE":[
        {
            label: "会员账号",
            prop:"memberName",
            html:"span"
        },
        {
            label: "VIP等级",
            prop:"vipLevel",
            html:"span",
            prefix:"VIP"
        },
        {
            label: "反水状态",
            prop:"status",
            html:"switch"
        },
        {
            label: "反水比例",
            prop:"vipAmount",
            html:"span",
            unit:"3"
        },
    ],
    "WITHDRAWAL_LIMIT":[
        {
            label: "会员账号",
            prop:"memberName",
            html:"span"
        },
        {
            label: "VIP等级",
            prop:"vipLevel",
            html:"span"
        },
        {
            label: "普通提现",
            prop:"status",
            html:"switch"
        },
        {
            label: "AB提现",
            prop:"abWithdrawStatus",
            html:"switch",
        
        },
        {
            label: "每日最高可提",
            prop:"filed1",
            html:"span",
             unit:"1"
        },
        {
            label: "AB每日最高可提现",
            prop:"filed2",
            html:"span",
           unit:"1"
        },
    ],
    "BIRTHDAY_RED_ENVELOPE":[
        {
            label: "会员账号",
            prop:"memberName",
            html:"span"
        },
        {
            label: "VIP等级",
            prop:"vipLevel",
            html:"span"
        },
        {
            label: "领取状态",
            prop:"status",
            html:"switch"
        },
        {
            label: "生日红包",
            prop:"vipAmount",
            html:"span",
        unit:"1"
        },
        {
            label: "实际生日红包",
            prop:"totalAmount",
            html:"span",
            unit:"1"
        },
        {
            label: "变更额度",
            prop:"changeQuota",
            html:"span"
        },
       
        {
            label: "额度调整",
            prop:"addorminus",
            html:"radio",
      
        },
        {
            label: "",
            prop:"content",
            html:"input",
            placeholder:"请输入"
        },
    ],
    "POINTS_COLLECTION":[
        {
            label: "会员账号",
            prop:"memberName",
            html:"span"
        },
        {
            label: "VIP等级",
            prop:"vipLevel",
            html:"span",
            prefix:"VIP"
        },
        {
            label: "领取状态",
            prop:"status",
            html:"switch"
        },
        {
            label: "领取积分",
            prop:"vipAmount",
            html:"span",
            unit:"3"
        },
        {
            label: "",
            prop:"text",
            html:"span",
            class:"red-text",
            labelWidth: "50px"
        },
    ],
    "ACTIVITY_CENTER":[
        {
            label: "会员账号",
            prop:"memberName",
            html:"span"
        },
        {
            label: "VIP等级",
            prop:"vipLevel",
            html:"span"
        },
        {
            label: "领取状态",
            prop:"status",
            html:"switch"
        },
    ]
}

}