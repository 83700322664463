<template>
  <div>
    <el-form ref="ruleForm" label-width="100px">
      <el-row v-loading="loading" :gutter="24" class="basic_config_content">
        <el-col :span="20">
          <el-form-item>
            <el-radio-group
              v-model="lang"
              style="margin-right: 6px"
              @change="handleSearch"
            >
              <el-radio-button
                v-for="(item, index) in allLang"
                :key="index"
                :label="item.lang"
                >{{ $t(item.name) }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <h2>{{ $t("关于我们") }}</h2>
            <tinymce ref="tinymce1" v-model="ruleForm.aboutus" :height="250" />
          </el-form-item>
          <el-form-item>
            <h2>{{ $t("足球说明") }}</h2>
            <tinymce
              ref="tinymce2"
              v-model="ruleForm.footballdesc"
              :height="250"
            />
          </el-form-item>
          <div align="right">
            <el-button
              type="success"
              :loading="submitting"
              @click="handleSubmit"
              >{{ $t("保 存") }}</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="ruleForm1" label-width="100px">
      <el-row v-loading="loading1" :gutter="24" class="basic_config_content">
        <el-col :span="20">
          <el-form-item>
            <h2>{{ $t("首页启动图配置") }}</h2>
            <el-upload
              class="upload-demo"
              :action="action"
              :file-list="startImgList"
              :data="{ type: 'image', folder: 'ad-photo' }"
              :on-success="handleUploadSuccess"
              :before-upload="beforeImgUpload"
              :on-remove="onImgRemove"
              :on-change="imgOnChange"
              :headers="$headers()"
              :on-exceed="onExceed"
              :limit="3"
              list-type="picture-card"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                <i class="el-icon-warning" />
                提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。支持多张，最多3张
              </div>
            </el-upload>
          </el-form-item>
          <div align="right">
            <el-button
              type="success"
              :loading="submitting1"
              @click="handleSubmit1"
              >{{ $t("保 存") }}</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import {
  getTenantDesc,
  updateTenantInfo,
  getBaseConfig,
  editBaseConfig,
} from "@/api/config";
import { mapGetters } from "vuex";
export default {
  name: "ContentTab",
  componentName: "ContentTab",
  components: { Tinymce },
  data() {
    return {
      loading: false,
      loading1: false,
      submitting: false,
      submitting1: false,
      startImgList: [],
      lang: "zh_CN",
      ruleForm: {},
      ruleForm1: {},
    };
  },
  computed: {
    ...mapGetters(["allLang"]),
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`;
    }
  },
  created() {
    if (this.allLang.length > 0) {
      this.lang = this.allLang[0].lang;
    }
    this.handleSearch();
    this.handleSearch1();
  },
  methods: {
    imgOnChange(file, fileList) {
      console.log("🚀🚀🚀🚀 ~imgOnChange file, fileList:", file, fileList);
      this.startImgList = fileList;
    },
    handleUploadSuccess(response, file, fileList) {
      console.log(
        "🚀🚀🚀🚀 ~ response, file, fileList:",
        response,
        file,
        fileList
      );
    },
    onImgRemove(file, fileList) {
      console.log("🚀🚀🚀🚀 ~ file, fileList:", file, fileList);
      this.startImgList = fileList;
    },
    beforeImgUpload(file) {
      console.log(file.type);
      const isJPG =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg" ||
        file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传彩种图标只能是 PNG、JPG、GIF、SVG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传彩种图标大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onExceed(file, fileList){
      this.$message.error("最多3张启动图");
    },
    handleSearch() {
      this.loading = true;
      getTenantDesc({ lang: this.lang }).then(([data, err]) => {
        this.loading = false;
        if (!err) {
          if (data) {
            this.ruleForm = data;
            this.$nextTick(() => {
              this.$refs.tinymce1.hasInit &&
                this.$refs.tinymce1.setContent(data.aboutus || "");
              this.$refs.tinymce2.hasInit &&
                this.$refs.tinymce2.setContent(data.footballdesc || "");
            });
          }
        }
      });
    },
    handleSearch1() {
      this.loading1 = true;
      getBaseConfig().then(([data, err]) => {
        this.loading1 = false;
        if (!err && data) {
          this.ruleForm1 = data
          this.startImgList = this.ruleForm1.startImg || [];
          // this.startImgList = [];

        }
      });
    },
    handleSubmit1() {
      if(this.startImgList.length == 0){
        this.$message.error("至少上传一张启动图");
        return
      }
      this._updateConfig1();
    },
    _updateConfig1() {
      this.submitting1 = true;
      this.ruleForm1.startImg = [];

      this.startImgList.forEach((item) => {
        this.ruleForm1.startImg.push({
          name: item.name,
          url: item?.response?.data[0] || item.url,
        });
      });
      console.log("🚀🚀🚀🚀 ~ this.ruleForm1.startImg:", this.ruleForm1.startImg)
      console.log("🚀🚀🚀🚀 ~ this.startImgList:", this.startImgList)
      
      editBaseConfig(this.ruleForm1).then(([_, err]) => {
        this.submitting1 = false;
        if (!err) {
          this.$message.success(this.$t("保存成功"));
        }
      });
    },

    handleSubmit() {
      this.submitting = true;
      //   if (this.ruleForm.aboutus === '') {
      //     this.submitting = false
      //     this.$message.error('请输入关于我们')
      //     return
      //   }
      //   if (this.ruleForm.footballdesc === '') {
      //     this.submitting = false
      //     this.$message.error('请输入足球说明')
      //     return
      //   }
      let params = {
        lang: this.lang,
        aboutus: this.ruleForm.aboutus,
        footballdesc: this.ruleForm.footballdesc,
      };
      updateTenantInfo(params).then(([_, err]) => {
        this.submitting = false;
        if (!err) {
          this.$message.success(this.$t("保存成功"));
        }
      });
    },
  },
};
</script>
