const CanChooseColumns = function () {
  return [
    {
      title: "前端排序",
      key: "sortNum",
      align: "center",
    },
    {
      title: "会员账号",
      key: "memberName",
      align: "center",
    },
    {
      title: "昵称",
      key: "nickName",
      align: "center",
    },
    {
      title: "账号类型",
      key: "guruType",
      align: "center",
      component: {
        render: (h, row) => {
          switch (row.guruType) {
            case 1:
              return <span>内部账号</span>;
            case 2:
              return <span>普通账号</span>;
            case 3:
              return <span>普通账号</span>;
            default:
              return <span>{row.guruType}</span>;
          }
        },
      },
    },
    {
      title: "(大)游戏类型",
      key: "bigPlayType",
      align: "center",
      component: {
        render: (h, row) => {
          switch (row.bigPlayType) {
            case 1:
              return <span>竞彩体育</span>;
            case 2:
              return <span>六合彩</span>;
            default:
              return <span>{row.bigPlayType}</span>;
          }
        },
      },
    },
    {
      title: "创建时间",
      key: "createTime",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.createTime)}</span>;
        },
      },
    },
    {
      title: "更新时间",
      key: "updateTime",
      align: "center",
      component: {
        render: (h, row) => {
          return <span>{this.$parseTime(row.updateTime)}</span>;
        },
      },
    },
    {
      title: "操作",
      align: "center",
      key: "",
      width: 200,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission;
          return (
            (row?.recommendType == 2) && (
              <div>
                {Update && (
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-edit"
                    onClick={() => {
                      this.handleUpdate(row);
                    }}
                  >
                    {this.$t("编辑")}
                  </el-button>
                )}
                {Delete && (
                  <el-button
                    type="danger"
                    size="small"
                    icon="el-icon-delete"
                    onClick={() => this.handleDelete(row)}
                  >
                    {this.$t("删除")}
                  </el-button>
                )}
              </div>
            )
          );
        },
      },
    },
  ];
};

export default function (keys = []) {
  return CanChooseColumns.call(this);
}
