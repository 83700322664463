<template>
  <div v-loading="loading" class="display-icon">
    <div v-for="resource in resources" :key="resource.key" class="display-icon-list">
      <h2 class="display-icon-title">{{ resource.title }}:</h2>
      <div v-for="item in resource.items" :key="item.seriesTag" class="display-icon-item">
        <h4>{{ item.seriesName }}</h4>
        <div v-for="child in item.list" :key="child.lotteryId" class="display-icon-child">
          <el-upload
            class="avatar-uploader"
            accept="image/png,image/jpeg,image/webp"
            :action="action"
            :data="{ type: 'image', folder: 'icon' }"
            :headers="$headers()"
            :show-file-list="false"
            :on-success="(...args) => handleUploadSuccess(args, child)"
            :before-upload="beforeIconUpload"
          >
            <el-image v-if="child.icon" :src="child.icon" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
          <p class="name" :class="{ parent: child.seriesTag }">{{ child.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLotteryIcon, updateLotteryIcon, updateSeriesIcon } from '@/api/config'
import { $t } from '@/lang'

function formatLottery(items) {
  if (!items.length) return []

  const resources = items.reduce((p, v) => {
    v.list.unshift({
      icon: v.seriesIcon,
      seriesTag: v.seriesTag,
      name: v.seriesName
    })
    if (v.seriesTag < 100) {
      p[0].items.push(v)
    } else {
      p[1].items.push(v)
    }
    return p
  }, [{ title: $t('彩票类'), items: [] }, { title: $t('第三方游戏'), items: [] }])

  return resources.map(o => {
    return {
      title: o.title,
      items: o.items.sort((a, b) => a.seriesTag - b.seriesTag)
    }
  })
}

export default {
  name: 'IconTab',
  componentName: 'IconTab',
  data() {
    return {
      resources: [],
      loading: false
    }
  },
  computed: {

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.loading = true
      getLotteryIcon().then(([data, err]) => {
        this.loading = false
        if (!err) this.resources = formatLottery(data || [])
      })
    },
    handleUploadSuccess([res], child) {
      const icon = res.data[0]
      if (child.lotteryId) {
        updateLotteryIcon({ lotteryId: child.lotteryId, icon, type: 1 }).then(([_, err]) => {
          if (!err) {
            child.icon = icon
            this.$message.success('更新图标成功')
          }
        })
      } else {
        updateSeriesIcon({ seriesTag: child.seriesTag, icon, type: 1 }).then(([_, err]) => {
          if (!err) {
            child.icon = icon
            this.$message.success('更新图标成功')
          }
        })
      }
    },

    beforeIconUpload(file) {
      const isJPG = file.type === 'image/png' || file.type === 'image/jpeg'|| file.type === 'image/webp'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传彩种图标只能是 PNG、JPG、WEBP 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传彩种图标大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>
<style lang="scss" scoped>
.display-icon {
  padding-left: 60px;
  &-title {
    font-size: 18px;
  }
  &-item {
    padding-left: 40px;
  }
  &-child {
    display: inline-block;
    margin-right: 30px;
    text-align: center;
    .name {
      text-align: center;
      margin: 10px 0 20px 0;
      &.parent {
        font-weight: bold;
      }
    }
  }
  h4 {
    font-size: 14px;
  }
  .avatar-uploader .el-upload  {
    border: 1px dashed #3894FF;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
  .avatar {
    width: 60px;
    height: 60px;
    display: block;
  }
}
</style>
