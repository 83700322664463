<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.assistantName" clearable placeholder="请输入助手名称" class="filter-item" style="width: 200px" />
      <el-select style="margin:0 10px;" v-model="search.roomType" placeholder="请选择聊天室类型">
        <el-option v-for="item in roomTypes" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-input v-model="search.roomName" clearable placeholder="请输入聊天室名称" class="filter-item" style="width: 200px" />
      <el-button icon="el-icon-search" type="primary" class="filter-item" :loading="loading" @click="handleSearch">{{
        $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置')
        }}</el-button>
      <el-button v-if="permission.Add" icon="el-icon-plus" type="success" class="filter-item"
        @click="$refs.ActionDialogControl.open()">添加</el-button>
    </div>

    <d-table ref="DTable" v-loading="loading" :data="items" :columns="columns" :original-columns="defaultColumns"
      :options="options" @refresh="handleFetch" @column-change="handleColumnChange" />

    <ActionDialog ref="ActionDialogControl" @finsh="_handleCreate" />
    <div ref="robotList">
    </div>
  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import ActionDialog from './ActionDialog'
import GetColumns from './columns'
import { getRobotPageList, delRobot } from '@/api/chat'
import { roomTypes } from '@/libs/options'
export default {
  name: 'Live_RobotOptions',
  components: { ActionDialog },
  mixins: [TablePageMixin(getRobotPageList)],
  data() {
    return {
      defaultColumns: GetColumns.call(this),
      columns: [],
      search: {
        "currentPage": 1,
        "pageSize": 20,
        "roomType": '',
        "name": "",
        "roomName": ""
      },
      roomTypes: roomTypes
    }
  },
  methods: {
    _handleCreate() {
      this.handleFetch()
    },
    implementationGetParams() {
      return { ...this.search }
    },
    handleDelete({ id }) {
      this.$confirm(`${this.$t('确认删除? 删除后机器人将被移出聊天室')}`, { type: 'warning' }).then(() => {
        const params = { id }
        delRobot(params).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
  }
}
</script>
