import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('还款订单编号'),
      key: 'orderNo',
      align: 'center'
    },
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('总借呗额度'),
      key: 'totalAmount',
      align: 'center'
    },
    {
      title: this.$t('当前额度'),
      key: 'nowAmount',
      align: 'center'
    },
    {
      title: this.$t('还款金额'),
      key: 'repayAmount',
      align: 'center'
    },
    {
      title: this.$t('剩余额度'),
      key: 'remainAmount',
      align: 'center'
    },
    {
      title: this.$t('剩余应还'),
      key: 'remainRepayAmount',
      align: 'center'
    },
    {
      title: this.$t('还款时间'),
      key: 'createTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
