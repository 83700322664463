<template>
    <el-dialog title="投注内容" width="1000px" top="5vh" :visible.sync="visible" class="cancel-dialog" @closed="handleClosed">
      <table v-loading="loading" border border-color="#ccc" border-collaspse class="my-table">
        <tr>
            <th>类型</th>
            <th>属性</th>
            <th>投注</th>
            <th>属性</th>
            <th>投注</th>
            <th>属性</th>
            <th>投注</th>
            <th>属性</th>
            <th>投注</th>
            <th>属性</th>
            <th>投注</th>
        </tr>
        <tr v-for="(item, index) in sportList" :key="index">
            <td v-if="item.index === 0" :rowspan="item.rowSpan">{{ item.name }}</td>
            <template v-for="(oddsItem, oddsIndex) in item.odds">
                <td :key="oddsIndex + 'a'">{{ oddsItem.name }}<p>{{ oddsItem.odds }}</p></td>
                <td :key="oddsIndex + 'b'"><el-checkbox v-model="oddsItem.checked"></el-checkbox></td>
                <template v-if="item.emptyIndex === oddsIndex">
                    <td :key="oddsIndex + 'c'"></td>
                    <td :key="oddsIndex + 'd'"></td>
                    <td :key="oddsIndex + 'e'"></td>
                    <td :key="oddsIndex + 'f'"></td>
                </template>
            </template>
        </tr>
      </table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="danger" :loading="submitting" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </template>
  
  <script>
  import VDialogMixin from '@/mixins/v-dialog-mixin'
  import { sportNowBaseOdds } from '@/api/orders'
  import { sportList } from './data'
  export default {
    mixins: [VDialogMixin],
    data() {
      return {
        id: '',
        emitName: 'update',
        sportList,
        loading: false,
        tenantCode: undefined
      }
    },
    methods: {
      open({ deId, matchId, betContent, tenantCode }) {
        this.loading = true
        this.id = deId
        this.sportList = sportList
        this.tenantCode = tenantCode
        const betCodes = []
        if (betContent) {
            betContent.split(',').forEach(e => {
                betCodes.push(e.split('@')[0])
            })
        }
        sportNowBaseOdds({ matchId }).then(([data, err]) => {
            if (!err) {
                data.originSpfList = data.originSpf.split(',')
                data.originRqList = data.originRq.split(',')
                data.originBqcList = data.originBqc.split(',')
                data.originBfList = data.originBf.split(',')
                data.originJqList = data.originJq.split(',')
                let key = ''
                let emptyCount = 0
                this.sportList.forEach(e => {
                    if (key === e.key) {
                        emptyCount += e.emptyIndex || 0
                    } else {
                        emptyCount = 0
                    }
                    key = e.key
                    e.odds.forEach((v, index) => {
                        if (betCodes.includes(v.code)) {
                            v.checked = true
                        } else {
                            v.checked = false
                        }
                        v.odds = data[e.key][5 * e.index - emptyCount + index]
                    })
                })
            }
        }).finally(() => {
            this.loading = false
        })
        this.visible = true
      },
      handleClosed() {
        this.submitting = false
      },
      handleSubmit() {
        this.submitting = true
        let checkedContent = []
        this.sportList.forEach(e => {
            e.odds.forEach(v => {
                if(v.checked) {
                    checkedContent.push(v.code + '@' + v.odds)
                }
            })
        })
        if (checkedContent.length === 0) {
            this.$message.error('请选择投注内容')
            return
        }
        let betContent = checkedContent.toString()
        this.$emit(this.emitName, {
          field: {
            id: this.id,
            betContent,
            tenantCode: this.$P$ && this.tenantCode || undefined
          },
          close: this.close,
          cancel: () => { this.submitting = false }
        })
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .my-table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border-color: #f5f1f1;
    > tr {
        > th {
            
            height: 40px;
            width: 8.5%;
            &:first-child {
                width: 15%;
            }
        }
        > td {
            height: 50px;
            > p {
                color: #ccc;
            }
        }
    }
  }
  </style>
  