<template>
  <el-dialog
    :title="`大神粉丝数量`"
    top="5vh"
    width="40%"
    :visible.sync="visible"
  >
    <div>
      <el-form
        ref="validateForm"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="table-ruleForm"
      >
        <el-form-item :label="$t('实际粉丝数量')" prop="followersNum">
          <el-input v-model.number="followersNum" disabled />
        </el-form-item>
        <el-form-item :label="$t('虚拟粉丝数量')" prop="createFollowersNum">
          <el-input v-model.number="ruleForm.createFollowersNum" />
        </el-form-item>
        <el-form-item :label="$t('当前合计粉丝')">
          {{ followersNum + ruleForm.createFollowersNum }}
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="save">{{ $t("确定") }}</el-button>
      <el-button @click="close">{{ $t("关闭") }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from "@/mixins/form-dialog";
import { numerical } from "@/utils/validation.js";
import { upGuru } from "@/api/orderManage";
const getDefaultForm = () => ({
  followersNum: 0,
  createFollowersNum: 0,
});
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      followersNum: 0,
      rules: {
        followersNum: [
          {
            required: true,
            message: this.$t("请输入", { text: this.$t("粉丝数") }),
            trigger: "blur",
          },
          {
            pattern: numerical,
            message: "不小于0且最多三位小数的数",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    open({ memberId, followersNum, createFollowersNum }) {
      console.log(this.$store.state.user.info);
      this.ruleForm.memberId = memberId;
      this.followersNum = followersNum;
      this.ruleForm.createFollowersNum = +createFollowersNum;
      this.visible = true;
    },

    async save() {
      const res = await upGuru(this.ruleForm);
      // console.log(res);
      if (res[2].data.success) this.$message.success(this.$t("保存成功"));
      else this.$message.error(this.$t("保存失败"));
      this.$emit('update')
      this.visible = false;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.betview-warp {
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #dfe6ec;
  border-bottom: 1px solid #dfe6ec;
  &-item {
    text-align: center;
    line-height: 30px;
    flex: 0 20%;
    display: flex;
    align-items: center;
    &:nth-child(10n + 1),
    &:nth-child(10n + 2),
    &:nth-child(10n + 3),
    &:nth-child(10n + 4),
    &:nth-child(10n + 5) {
      background-color: #f5f7fa;
    }
    border-top: 1px solid #dfe6ec;
    border-right: 1px solid #dfe6ec;
    .name {
      flex: 0 40%;
      border-right: 1px solid #dfe6ec;
    }
    .amount {
      flex: 0 60%;
    }
  }
}
</style>
