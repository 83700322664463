import Layout from '@/layout'

const manageRouter = {
  path: '/Lottery',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Lottery',
  alwaysShow: true,
  meta: { title: '彩种设置', icon: 'lottery-setting' },
  children: [
    {
      path: '/Lottery_Setting',
      component: () => import('@/views/lottery/manage'),
      name: 'LotteryManage',
      meta: { title: '彩种管理', noCache: true }
    },
    {
      path: '/Lottery_Draw',
      component: () => import('@/views/lottery/draw'),
      name: 'LotteryDraw',
      meta: { title: '开奖管理', noCache: true }
    },
    {
      path: '/Lottery_Switch',
      component: () => import('@/views/lottery/switch'),
      name: 'LotterySwitch',
      meta: { title: '彩种开关', noCache: true }
    },
    {
      path: '/Lottery_Contorl',
      component: () => import('@/views/lottery/control'),
      name: 'LotteryContorl',
      meta: { title: '彩种启停', noCache: true }
    },
    {
      path: '/Live_Lottery_Config',
      name: 'LiveLotteryConfig',
      component: () => import('@/views/live/live-lottery-config'),
      meta: { title: '彩票直播配置', noCache: true }
    }
  ]
}

export default manageRouter
