<template>
  <div class="upload-wrapper">
    <el-upload
      :accept="accept"
      :data="data"
      :action="action"
      :headers="$headers()"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="currentValue" :src="currentValue" class="avatar" :style="styles">
      <div v-else :style="styles" class="avatar-uploader-icon">
        <i class="el-icon-plus" />
        <p>{{ text }}</p>
      </div>
    </el-upload>
    <span v-if="tips">
      提示：支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。
    </span>
    <span v-if="tipText">
      {{ tipText }}
    </span>
  </div>
</template>
<script>
import { $t } from '@/lang'
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'image'
    },
    folder: {
      type: String,
      required: true
    },
    accept: {
      type: String,
      default: 'image/jpeg,image/png,image/jpg,image/webp'
    },
    width: {
      type: Number,
      default: 120
    },
    height: {
      type: Number,
      default: 120
    },
    maxSize: {
      default: 2,
      type: Number
    },
    text: {
      type: String,
      default: $t('上传图标')
    },
    onSuccess: {
      type: Function,
      default() {
        return {}
      }
    },
    onError: {
      type: Function,
      default() {
        return {}
      }
    },
    tips: {
      type: Boolean,
      default: false
    },
    tipText: {
      type: String
    },
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  computed: {
    data() {
      return { type: this.type, folder: this.folder }
    },

    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
    styles() {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    }
  },
  watch: {
    value(val) {
      this.currentValue = val
    }
  },
  methods: {
    beforeAvatarUpload(file) {
      const isAccept = this.accept.split(',').some(o => file.type === o)
      const isMax = file.size / 1024 / 1024 < this.maxSize
      if (!isAccept) {
        this.$message.error(`上传图标只能是 ${this.accept.replaceAll('image/', '')} 格式!`)
      }
      if (!isMax) {
        this.$message.error(`上传图标大小不能超过 ${this.maxSize}MB!`)
      }
      return isAccept && isMax
    },
    handleAvatarSuccess(res, file) {
      this.onSuccess(res.data)
    }
  }
}
</script>
<style lang="scss">
.upload-wrapper {
  display: flex;
  align-items: center;
  > span {
    margin-left: 30px;
    line-height: 20px;
    color: #999;
  }
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    &:hover {
      border-color: #409EFF;
    }
  }

  .avatar {
    display: block;
  }

  .avatar-uploader-icon {
    padding: 10px 0;
    text-align: center;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    i {
      font-size: 28px;
      color: #8c939d;
    }
  }
}
</style>
