<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 400px"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        clearable
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0)">{{
        $t("今天")
      }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1)">{{
        $t("昨天")
      }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7)">{{
        $t("_7天")
      }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30)">{{
        $t("本月")
      }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60)">{{
        $t("上月")
      }}</el-button>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleSearch"
        >{{ $t("查询") }}</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-refresh-right"
        @click="resetSearch"
        >{{ $t("重置") }}</el-button
      >
    </div>

    <div class="filter-container">
      <el-select
        v-model="search.competitionId"
        class="filter-item"
        clearable
        :placeholder="$t('联赛')"
        style="width: 140px"
      >
        <el-option
          v-for="item in competitionList"
          :key="item.competitionId"
          :label="item.competitionName"
          :value="item.competitionId"
        />
      </el-select>
      <el-input
        v-model="search.matchNumber"
        class="filter-item"
        :placeholder="$t('联赛编号')"
        style="width: 140px"
      />
      <el-input
        v-model="search.orderNo"
        class="filter-item"
        clearable
        :placeholder="$t('订单号')"
        style="width: 200px"
      />
      <el-select
        v-model="search.memberType"
        class="filter-item"
        clearable
        :placeholder="$t('会员类型')"
        style="width: 120px"
      >
        <el-option
          v-for="item in memberTypes"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="search.status"
        class="filter-item"
        clearable
        :placeholder="$t('状态')"
        style="width: 120px"
      >
        <el-option
          v-for="item in ftBettingStatus"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="search.passType"
        class="filter-item"
        clearable
        :placeholder="$t('过关方式')"
        style="width: 120px"
      >
        <el-option
          v-for="(value, name) in passTypeOptions"
          :key="name"
          :label="value"
          :value="name"
        />
      </el-select>
    </div>
    <div class="filter-container">
      <el-input
        v-model="search.memberNickname"
        class="filter-item"
        clearable
        :placeholder="$t('会员昵称')"
        style="width: 140px"
      />
      <el-input
        v-model="search.memberAccount"
        class="filter-item"
        clearable
        :placeholder="$t('会员账号')"
        style="width: 140px"
      />
      <el-input
        v-model.number="search.betAmount"
        class="filter-item"
        clearable
        :placeholder="$t('投注超过额度(大于10)')"
        style="width: 200px"
      />
      <el-input
        v-model.number="search.profitAmount"
        class="filter-item"
        clearable
        :placeholder="$t('盈利超过额度(大于10)')"
        style="width: 200px"
      />
      <el-select
        v-model="search.isPush"
        class="filter-item"
        clearable
        placeholder="推单订单"
        style="width: 120px"
      >
        <el-option label="是" value="1" />
        <el-option label="否" value="0" />
      </el-select>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="resource"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      :span-method="objectSpanMethod"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
      selection-row
      row-key="deId"
      @select="handleSelect"
      @select-all="handleSelect"
    >
      <template slot="left">
        {{ $t("总投注额") }}：<span>{{ statistical ? (statistical.betAmount || 0) : '***' }}</span
        >&nbsp;&nbsp; {{ $t("有效投注额") }}：<span>{{
          statistical ? (statistical.realAmount || 0) : '***'
        }}</span
        >&nbsp;&nbsp; {{ $t("中奖金额") }}：<span>{{
          statistical ? (statistical.winAmount || 0) : '***'
        }}</span
        >&nbsp;&nbsp; {{ $t("盈亏") }}：<span>{{
          statistical ? (statistical.profitAmount || 0) : '***'
        }}</span
        >&nbsp;&nbsp; {{ $t("总用户") }}：<span>{{
          statistical ? (statistical.memberNumber || 0) : '***'
        }}</span>&nbsp;&nbsp; 
        <el-link type="primary" @click="getBetsCount">查看结果</el-link>
      </template>
      <template #right>
        <el-button
          type="primary"
          v-if="permission.Push"
          @click="handleOrderPushOpen()"
          >竞彩推单</el-button
        >
        <el-button
          v-if="permission.Settlemet"
          type="primary"
          @click="handleSettlemet"
          >结算</el-button
        >
        <el-button
          plain
          :loading="excelLoading1"
          type="primary"
          icon="el-icon-download"
          @click="_handleExport"
          >{{ $t("导出Excel") }}</el-button
        >
      </template>
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />
    <UserDialog ref="UserDialogControl" />

    <CancelDialog ref="CancelDialogControl" @cancel="handleCancel" />

    <EditOrderDialog ref="EditOrderDialogControl" @update="handleUpdate" />
    <OrderPushDialog ref="OrderPushDialogControl" @push="handleOrderPush" />
  </div>
</template>

<script>
import TablePageMixin from "@/mixins/table-page-mixin";
import { ftBettingStatus } from "@/libs/options";
import CancelDialog from "./CancelDialog";
import EditOrderDialog from "./EditOrderDialog";
import OrderPushDialog from "./OrderPushDialog";
import UserDialog from "@/views/components/UserDialog";
import GetColumns from "./columns";
import { getDefaultTimes, exportExcel,getTodayTimestamps } from "@/utils";
import {
  getFootballOrderList,
  getFootballOrderCount,
  cancelFootballOrder,
  getPassTypeList,
  getCompetition,
  exportFootballOrderList,
  footballUpdateOrder,
  footballOrderSettlemet,
} from "@/api/orders";
import { addGuruOrders } from "@/api/orderManage";

export default {
  name: "FootballOrders",
  components: { UserDialog, CancelDialog, EditOrderDialog, OrderPushDialog },
  mixins: [TablePageMixin(getFootballOrderList)],
  data() {
    return {
      tenant: null,
      times: getDefaultTimes(),
      excelLoading1: false,
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
      resource: [],
      memberTypes: this.$t("memberTypes"),
      ftBettingStatus,
      competitionList: [],
      passTypeOptions: {},
      playsOptions: null,
      statistical: null,
      selection: [],
    };
  },
  mounted() {
    this.getPlayList();
    this.implementationTenantChanged();
  },
  activated() {
    const { orderNo, startTime } = this.$route.query;
    if (orderNo) this.search.orderNo = orderNo;
    if (startTime) {
      this.search.startTime = getTodayTimestamps(startTime).startOfDay;
      this.times.splice(0, 1, getTodayTimestamps(startTime).startOfDay);
      this.search.endTime = getTodayTimestamps(startTime).endOfDay;
      this.times.splice(1, 1,  getTodayTimestamps(startTime).endOfDay);
    }

    if (this.search.orderNo) {
      this.handleSearch();
    }
  },
  methods: {
    _onDetails({ memberId }) {
      this.$refs.UserDialogControl.open(memberId);
    },

    _onCancel({ orderNo }) {
      const order = this.items.find((o) => o.orderNo === orderNo);
      this.$refs.CancelDialogControl.open(order);
    },
    handleOrderPushOpen() {
      if (
        this.selection.length == 0 ||
        this.selection.some((p) => ![5, 6, 7].includes(p.destatus))
      ) {
        return this.$message.info("请选择已中奖或未中奖或打和的订单进行操作！");
      }

      this.$refs.OrderPushDialogControl.open(this.selection);
    },
    handleChangeTenant([tenant]) {
      this.tenant = tenant;
      this.times = null;
      this.search = {};
      this.search.tenantCode = tenant.tenantCode;
      this.handleRefresh();
      this.implementationTenantChanged(tenant.tenantCode);
    },

    handleRefresh() {
      this.params.currentPage = 1;
      this.handleFetch();
      this.selection = [];
    },

    handleCancel({ field, cancel, close }) {
      cancelFootballOrder({ ...field }).then(([_, err]) => {
        if (!err) {
          close();
          this.$message.success(this.$t("撤单成功"));
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },

    handleUpdate({ field, cancel, close }) {
      footballUpdateOrder({ ...field }).then(([_, err]) => {
        if (!err) {
          close();
          this.$message.success(this.$t("编辑成功"));
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },

    handleSettlemet() {
      if (this.selection.length === 0) {
        this.$message.error("请选择要结算的数据");
        return;
      }
      this.$confirm(
        "tips：结算后将进行自动派奖以及跟单结算。",
        "确定进行订单结算吗？",
        { type: "warning" }
      ).then(() => {
        const items = [];
        this.selection.forEach((e) => {
          const detailList = this.resource.filter((r) => r.id === e.id);
          detailList.forEach((o) => {
            if (!items.some((v) => v.id === o.deId)) {
              items.push({
                id: o.deId,
                tenantCode: (this.$P$ && o.tenantCode) || undefined,
                memberId: o.memberId,
                matchId: o.matchId,
              });
            }
          });
        });
        footballOrderSettlemet({ items }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t("编辑成功"));
            this.selection = [];
            this.handleFetch();
          } else {
          }
        });
      });
    },

    handleSelect(selection) {
      this.selection = selection;
    },

    objectSpanMethod({ row, columnIndex }) {
      if (columnIndex === 0 || columnIndex > 6) {
        return {
          rowspan: row.rowspan,
          colspan: 1,
        };
      }
    },

    getBetsCount() {
      // 获取统计详情
      getFootballOrderCount({ ...this.implementationGetParams() }).then(
        ([data, err]) => {
          if (!err) {
            this.statistical = data;
          }
        }
      );
    },

    getPlayList() {
      getPassTypeList().then(([data, err]) => {
        if (!err) {
          const { passType, plays } = data;
          this.playsOptions = plays;
          this.passTypeOptions = passType;
        }
      });
    },

    implementationFetched({ items }) {
      this.resource = items.reduce((c, v) => {
        const { recordList, ...others } = v;
        recordList.forEach((r, i) => {
          c.push({
            ...r,
            ...others,
            deId: r.id,
            destatus: r.status,
            rowspan: i === 0 ? recordList.length : 0,
          });
        });
        return c;
      }, []);
      this.statistical = null
    },

    implementationTenantChanged() {
      getCompetition().then(([data, err]) => {
        if (!err) {
          this.competitionList = data || [];
        }
      });
    },

    implementationGetParams() {
      return this.formatIntervalTime();
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys);
    },
    _handleExport() {
      this.excelLoading1 = true;
      exportFootballOrderList({ ...this.implementationGetParams() })
        .then(([data, error, blobData]) => {
          if (!error) {
            exportExcel(
              blobData,
              `足彩注单-${this.$parseTime(new Date())}.csv`
            );
          }
        })
        .finally(() => {
          this.excelLoading1 = false;
        });
    },
    // 竞猜推单
    handleOrderPush({ field, cancel, close }) {
      addGuruOrders(field).then(([_, err]) => {
        if (!err) {
          close();
          this.$message.success(this.$t("推单创建成功"));
          this.selection = [];
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.posGall-column {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #ffa39e;
  color: white;
  text-align: center;
  border-radius: 50%;
}
</style>
