<template>
  <el-dialog
    :title="$t('修改密码')"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item :label="$t('新密码')" prop="newPwd" required>
        <el-input v-model="ruleForm.newPwd" :placeholder="$t('6-12位')" />
      </el-form-item>
      <el-form-item :label="$t('再次输入新密码')" prop="newPwdConfirm" required>
        <el-input v-model="ruleForm.newPwdConfirm" :placeholder="$t('6-12位')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { validAccountAndPassword } from '@/utils/validate'
const getDefaultForm = () => ({
  newPwd: '',
  newPwdConfirm: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入密码')))
      } else if (!validAccountAndPassword(value)) {
        callback(new Error(this.$t('请输入6~12位英文数字')))
      } else {
        if (this.ruleForm.newPwdConfirm !== '') {
          this.$refs.validateForm.validateField('newPwdConfirm')
        }
        callback()
      }
    }

    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请再次输入密码')))
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error(this.$t('两次输入密码不一致')))
      } else {
        callback()
      }
    }

    return {
      emitName: 'change',
      rules: {
        newPwd: [{ validator: validatePass, trigger: 'blur' }],
        newPwdConfirm: [{ validator: validateCheckPass, trigger: 'blur' }]
      }
    }
  },
  methods: {
    open(row) {
      this.ruleForm.memberId = row.memberId
      this.visible = true
    }

  }
}
</script>

