<template>
  <el-dialog
    :title="$t('创建巡查')"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    @closed="handleClosed"
    @open="onOpen"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item :label="$t('区域号')" prop="region">
        <el-select
          v-model="ruleForm.region"
          filterable
          :placeholder="$t('请选择')"
        >
          <el-option
            v-for="(item, i) in Regins"
            :key="item.code + i"
            :value="item.code"
          >
            <span style="float: left">{{ item.city }}</span>
            <span style="float: right; color: #909399; font-size: 12px">{{ item.code }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('账号')" prop="memberName">
        <el-input v-model="ruleForm.memberName" :placeholder="$t('6-11位数字')" />
      </el-form-item>
      <el-form-item :label="$t('昵称')" prop="nickname">
        <el-input v-model="ruleForm.nickname" :placeholder="$t('2-12位')" />
      </el-form-item>
      <el-form-item :label="$t('密码')" prop="password" required>
        <el-input v-model="ruleForm.password" :placeholder="$t('6-12位')" />
      </el-form-item>
      <el-form-item :label="$t('再次输入新密码')" prop="confirmPassword" required>
        <el-input v-model="ruleForm.confirmPassword" :placeholder="$t('6-12位')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getRegins } from '@/api/interactive'
import { validAccountAndPassword, validNickName } from '@/utils/validate'
const getDefaultForm = () => ({
  region: '',
  memberName: '',
  password: '',
  confirmPassword: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validNickname = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('请输入昵称')))
      } else if (!validNickName(value)) {
        callback(new Error(this.$t('请输入2~12位')))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入密码')))
      } else if (!validAccountAndPassword(value)) {
        callback(new Error(this.$t('请输入6~12位英文数字')))
      } else {
        if (this.ruleForm.confirmPassword !== '') {
          this.$refs.validateForm.validateField('confirmPassword')
        }
        callback()
      }
    }

    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请再次输入密码')))
      } else if (value !== this.ruleForm.password) {
        callback(new Error(this.$t('两次输入密码不一致')))
      } else {
        callback()
      }
    }

    return {
      emitName: 'create',
      Regins: [],
      rules: {
        region: [{ required: true, message: this.$t('请选择'), trigger: 'change' }],
        memberName: [
          { required: true, message: this.$t('请输入账号'), trigger: 'blur' },
          { pattern: /^\d{6,11}$/, message: this.$t('6-11位数字'), trigger: 'blur' }
        ],
        nickname: [{ validator: validNickname, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        confirmPassword: [{ validator: validateCheckPass, trigger: 'blur' }]
      }
    }
  },
  methods: {
    open(row) {
      this.visible = true
    },
    onOpen() {
      getRegins().then(([data, err]) => {
        if (!err) {
          this.Regins = data
        }
      })
    }
  }
}
</script>

