const CanChooseColumns = function() {
  return [
    {
      title: this.$t('活动ID'),
      key: 'activityId',
      align: 'center'
    },
    {
      title: this.$t('活动标题'),
      key: 'activityTitle',
      align: 'center'
    },
    {
      title: this.$t('用户昵称'),
      key: 'memberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-link type="primary" onClick={ () => this.$refs.UserDialogControl.open(row.memberId) }>{ row.memberName }</el-link>)
        }
      }
    },
    {
      title: this.$t('账号类型'),
      key: 'memberType',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.memberType == 1 ? '普通用户' : '内部账户' }</span>)
        }
      }
    },
    {
      title: this.$t('奖励类型'),
      key: 'rewardMode',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.rewardMode == 1 ? '单次奖励' : '累计奖励' }</span>)
        }
      }
    },
    {
      title: this.$t('彩金奖励'),
      key: 'bonusAmount',
      align: 'center'
    },
    {
      title: this.$t('优惠券奖励'),
      key: 'couponList',
      align: 'center',
      component: {
        render: (h, row) => {
          const couponList = JSON.parse(row.couponList)
          if(couponList) {
            const titles = couponList.map((coupon, index) => {
              const title = coupon.couponTitle.split(',')[0].split(':')[1];
              if (index < couponList.length - 1) {
                return [h('span', title), h('br')];
              }
              return h('span', title);
            });
            return h('span', titles.flat());
          }
        }
      }
    },
    {
      title: this.$t('积分奖励'),
      key: 'points',
      align: 'center'
    },
    {
      title: this.$t('累计签到'),
      key: '',
      align: 'center'
    },
    {
      title: this.$t('领取时间'),
      key: 'receiveTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.receiveTime) }</span>)
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
