var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "currency-page" }, [
    _c(
      "div",
      { staticClass: "currency-list" },
      _vm._l(_vm.currencies, function (currency) {
        return _c(
          "div",
          {
            key: currency.id,
            staticClass: "currency-item",
            class: _vm.selectedCurrency.id == currency.id ? "active" : "",
            on: {
              click: function ($event) {
                return _vm.selectCurrency(currency)
              },
            },
          },
          [
            _c("img", {
              staticClass: "currency-icon",
              attrs: { src: currency.icon },
            }),
            _c("span", { staticClass: "currency-text" }, [
              _vm._v(_vm._s(currency.name)),
            ]),
          ]
        )
      }),
      0
    ),
    _vm.selectedCurrency
      ? _c("div", { staticClass: "currency-details" }, [
          _c("form", { staticClass: "currency-form" }, [
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("label", { staticClass: "form-label" }, [_vm._v("1.")]),
                _c("el-input", {
                  model: {
                    value: _vm.amount1,
                    callback: function ($$v) {
                      _vm.amount1 = $$v
                    },
                    expression: "amount1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("label", { staticClass: "form-label" }, [_vm._v("2.")]),
                _c("el-input", {
                  model: {
                    value: _vm.amount2,
                    callback: function ($$v) {
                      _vm.amount2 = $$v
                    },
                    expression: "amount2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("label", { staticClass: "form-label" }, [_vm._v("3.")]),
                _c("el-input", {
                  model: {
                    value: _vm.amount3,
                    callback: function ($$v) {
                      _vm.amount3 = $$v
                    },
                    expression: "amount3",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("label", { staticClass: "form-label" }, [_vm._v("4.")]),
                _c("el-input", {
                  model: {
                    value: _vm.amount4,
                    callback: function ($$v) {
                      _vm.amount4 = $$v
                    },
                    expression: "amount4",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("label", { staticClass: "form-label" }, [_vm._v("5.")]),
                _c("el-input", {
                  model: {
                    value: _vm.amount5,
                    callback: function ($$v) {
                      _vm.amount5 = $$v
                    },
                    expression: "amount5",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("label", { staticClass: "form-label" }, [_vm._v("6.")]),
                _c("el-input", {
                  model: {
                    value: _vm.amount6,
                    callback: function ($$v) {
                      _vm.amount6 = $$v
                    },
                    expression: "amount6",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-actions" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "form-button form-button-save",
                    on: {
                      click: function ($event) {
                        return _vm.addPaymentAmount()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  { staticClass: "form-button form-button-cancel" },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }