var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-container-item" },
          [
            _c("span", [_vm._v("会员账号：")]),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "160px", "margin-right": "20px" },
              attrs: { clearable: "", placeholder: _vm.$t("会员账号") },
              model: {
                value: _vm.search.memberName,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "memberName", $$v)
                },
                expression: "search.memberName",
              },
            }),
            _c("span", [_vm._v("VIP等级：")]),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "160px" },
                attrs: { clearable: "", placeholder: _vm.$t("VIP等级") },
                model: {
                  value: _vm.search.vipLevel,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "vipLevel", $$v)
                  },
                  expression: "search.vipLevel",
                },
              },
              _vm._l(_vm.vipLevels, function (item) {
                return _c("el-option", {
                  key: item.vipLevel,
                  attrs: { label: "VIP" + item.vipLevel, value: item.vipLevel },
                })
              }),
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handleSearch },
              },
              [_vm._v(_vm._s(_vm.$t("查询")))]
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary", icon: "el-icon-refresh-right" },
                on: { click: _vm.resetSearch },
              },
              [_vm._v(_vm._s(_vm.$t("重置")))]
            ),
          ],
          1
        ),
      ]),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.computedItems,
          columns: _vm.columns,
          "original-columns": _vm.defaultColumns,
          options: _vm.options,
          "row-key": "memberId",
        },
        on: {
          refresh: _vm.handleFetch,
          "column-change": _vm.handleColumnChange,
          "selection-change": _vm.handleSelectionChange,
        },
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("UserDialog", { ref: "UserDialogControl" }),
      _c("ActionDialog", {
        ref: "ActionDialogControl",
        attrs: { curRowAward: _vm.rowAward },
        on: { update: _vm.handleUpdateLevel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }