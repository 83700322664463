var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        {
          attrs: {
            shadow: "never",
            "body-style": { padding: 0, margin: "-1px" },
          },
        },
        [
          _c(
            "template",
            { slot: "header" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("彩票统计")))]),
              _c("date-filter", {
                attrs: { value: _vm.params.timeType, loading: _vm.loading },
                on: { input: _vm._input, refresh: _vm._get },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.items,
                size: "medium",
                border: "",
                "show-summary": "",
                "summary-method": _vm.getSummaries,
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: _vm.$t("彩种"), align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "betsAmount",
                  label: _vm.$t("投注"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "winAmount",
                  label: _vm.$t("派奖"),
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "earnAmount",
                  label: _vm.$t("盈亏"),
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }