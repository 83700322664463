var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-tab" }, [
    _c("div", [
      _c(
        "div",
        [
          _c("h3", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "elTable",
              attrs: {
                border: "",
                data: _vm.items,
                "row-key": "sort",
                "drag-row": true,
              },
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: { type: "index", align: "center", width: "60" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v(_vm._s(_vm.$t("排序")))]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class": "drag",
                      "class-name": "drag-handler",
                    },
                  }),
                ],
                1
              ),
              _c("el-table-column", {
                attrs: { label: _vm.$t("热门系列"), align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { clearable: "", filterable: "" },
                            on: {
                              change: function (value) {
                                return _vm.onChangeSeries(row, value)
                              },
                            },
                            model: {
                              value: row.seriesTag,
                              callback: function ($$v) {
                                _vm.$set(row, "seriesTag", $$v)
                              },
                              expression: "row.seriesTag",
                            },
                          },
                          _vm._l(
                            _vm.seriesList.filter(function (e) {
                              return !_vm.notSeriesTag.includes(e.seriesTag)
                            }),
                            function (series) {
                              return _c("el-option", {
                                key: series.seriesTag,
                                attrs: {
                                  value: series.seriesTag,
                                  label: series.seriesName,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("游戏"), align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm.inputSeriesTagTag.includes(row.seriesTag)
                          ? _c("el-input", {
                              model: {
                                value: row.gameLink,
                                callback: function ($$v) {
                                  _vm.$set(row, "gameLink", $$v)
                                },
                                expression: "row.gameLink",
                              },
                            })
                          : _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: _vm.$t("请选择游戏"),
                                },
                                on: {
                                  change: function (value) {
                                    return _vm.onChangeGame(row, value)
                                  },
                                },
                                model: {
                                  value: row.lotteryId,
                                  callback: function ($$v) {
                                    _vm.$set(row, "lotteryId", $$v)
                                  },
                                  expression: "row.lotteryId",
                                },
                              },
                              _vm._l(
                                _vm.gameList(row.seriesTag),
                                function (lottery) {
                                  return _c("el-option", {
                                    key: lottery.lotteryId,
                                    attrs: {
                                      value: lottery.lotteryId,
                                      label: lottery.name,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("图标"), align: "center", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              accept:
                                "image/png,image/jpeg,image/gif,image/webp",
                              action: _vm.action,
                              data: { type: "image", folder: "icon" },
                              headers: _vm.$headers(),
                              "show-file-list": false,
                              "on-success": function () {
                                var args = [],
                                  len = arguments.length
                                while (len--) args[len] = arguments[len]

                                return _vm.handleUploadSuccess(
                                  args,
                                  row,
                                  $index
                                )
                              },
                              "before-upload": _vm.beforeIconUpload,
                            },
                          },
                          [
                            row.hotIcon
                              ? _c("el-image", {
                                  staticClass: "avatar",
                                  attrs: { src: row.hotIcon },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("操作"), align: "center", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.resetRow(row)
                              },
                            },
                          },
                          [_vm._v("重置")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "10px 0" }, attrs: { align: "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", loading: _vm.submitting },
                  on: {
                    click: function ($event) {
                      return _vm.handleSubmit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("保 存")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("iframe", {
        ref: "ifr1",
        attrs: {
          src: _vm.h5Url("ent"),
          frameborder: "0",
          scrolling: "no",
          width: "600",
          height: "1000",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }