const CanChooseColumns = function() {
  return [
    // {
    //   title: this.$t('Expert_Plan.用户头像'),
    //   key: 'avatar',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (
    //         <el-avatar
    //           src={row.avatar}
    //           fit='cover'
    //           size={40}
    //           style='display: block; margin: 0 auto'
    //         >
    //           <img src={require('@/assets/public_images/public-avatar.png')}/>
    //         </el-avatar>)
    //     }
    //   }
    // },
    {
      title: this.$t('Expert_Plan.方案单号'),
      key: 'id',
      align: 'center'
    },
    {
      title: this.$t('Expert_Plan.专家账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('Expert_Plan.专家昵称'),
      key: 'nickname',
      align: 'center'
    },
    {
      title: this.$t('发布时间'),
      key: 'publishTime',
      align: 'center',
      width: 125
    },
    {
      title: this.$t('Expert_Plan.终端标题'),
      key: 'title',
      align: 'center',
      width: document.body.clientWidth * 0.15,
      component: {
        render: (h, row) => {
          return (<span title={ row.title }>{ row.title && row.title.length > 44 ? row.title.substr(0, 44) + '...' : row.title }</span>)
        }
      }
    },
    {
      title: this.$t('Expert_Plan.终端内容'),
      key: 'content',
      align: 'center',
      width: document.body.clientWidth * 0.2,
      component: {
        render: (h, row) => {
          return (<span title={ row.content }>{ row.content && row.content.length > 70 ? row.content.substr(0, 70) + '...' : row.content }</span>)
        }
      }
    },
    // {
    //   title: this.$t('Expert_Plan.比赛类型'),
    //   key: 'ruleType',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (<span>{ row.ruleType === 1 ? this.$t('Expert_Plan.足球') : this.$t('Expert_Plan.篮球') }</span>)
    //     }
    //   }
    // },
    // {
    //   title: this.$t('Expert_Plan.过关方式'),
    //   key: 'articleSchedules',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (<span>{ row.articleSchedules === 1 ? this.$t('Expert_Plan.单场') : this.$t('Expert_Plan.串联') }</span>)
    //     }
    //   }
    // },
    // {
    //   title: this.$t('Expert_Plan.是否收费'),
    //   key: 'goldnum',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (<span>{ parseInt(row.goldnum) === 0 ? this.$t('Expert_Plan.免费') : row.goldnum }</span>)
    //     }
    //   }
    // },
    // {
    //   title: this.$t('Expert_Plan.不中退'),
    //   key: 'isRefund',
    //   align: 'center',
    //   component: {
    //     render: (h, row) => {
    //       return (<span>{ row.isRefund === 1 ? this.$t('否') : this.$t('是') }</span>)
    //     }
    //   }
    // },
    // {
    //   title: this.$t('Expert_Plan.销量'),
    //   key: 'newBuyNum',
    //   align: 'center'
    // },
    // {
    //   title: this.$t('Expert_Plan.阅读量'),
    //   key: 'newView',
    //   align: 'center'
    // },
    {
      title: this.$t('Expert_Plan.方案状态'),
      key: 'articleStatus',
      align: 'center',
      width: 80,
      component: {
        render: (h, row) => {
          const tmp = this.articleStatusList.find(o => o.value === row.articleStatus)
          return (tmp && <span class={`${tmp.type}-type`}>{ tmp.label }</span>)
        }
      }
    },
    {
      title: this.$t('Expert_Plan.方案详情'),
      key: 'details',
      align: 'center',
      width: 80,
      component: {
        render: (h, row) => {
          return <span class='primary-type cursor-pointer' onClick={() => this.$refs.VisibleDialogControl.open(row)}>{ this.$t('编辑') }</span>
        }
      }
    },
    {
      isHide: this.tenantCode === 'TYCC',
      title: this.$t('Expert_Plan.拾取状态'),
      key: 'isTranslation',
      align: 'center',
      width: 80,
      component: {
        render: (h, row) => {
          return <span>{ row.isTranslation === 1 ? this.$t('是') : this.$t('否') }</span>
        }
      }
    },
    {
      isHide: this.tenantCode === 'TYCC',
      title: this.$t('Expert_Plan.译者名'),
      key: 'translationName',
      align: 'center'
    },
    {
      title: this.$t('Expert_Plan.操作信息'),
      key: 'operatorName',
      align: 'center',
      width: 125,
      component: {
        render: (h, row) => {
          return <span>
            <p>
              { row.operatorName }
            </p>
            { row.updateTime }
          </span>
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 150,
      component: {
        render: (h, row) => {
          const { Action } = this.permission

          return (
            <span>
              { Action && (row.articleStatus === 1) && <el-button type='danger' size='mini' onClick={() => this._handleUpdateStatus(row, 3)}>{ this.$t('拒绝') }</el-button> }
              { Action && (row.articleStatus === 1) && <el-button type='success' size='mini' onClick={() => this._handleUpdateStatus(row, 2)}>{ this.$t('通过') }</el-button> }
              { Action && (row.articleStatus === 2) && <el-button type='success' size='mini' onClick={() => this._handleUpdateStatus(row, 3)}>{ this.$t('下架') }</el-button> }
              { Action && (row.articleStatus === 3) && <el-button type='success' size='mini' onClick={() => this._handleUpdateStatus(row, 2)}>{ this.$t('上架') }</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
