<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="daterange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetDate($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetDate($event, 7)">{{ $t('_7天') }}</el-button>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <el-table v-show="resources.length" :data="resources" border>
      <el-table-column prop="time" align="center" label="时间">
        <template slot-scope="{ row }">{{ row.startTime | parseTime('{y}-{m}-{d}') }}~{{ row.endTime | parseTime('{y}-{m}-{d}') }}</template>
      </el-table-column>
      <el-table-column prop="proceeds" align="center" label="入款总额" />
      <el-table-column prop="operatorAdd" align="center" label="人工加款总额" />
      <el-table-column prop="otherInall" align="center" label="其他加款总额" />
      <el-table-column prop="payOut" align="center" label="出款总额">
        <template slot="header">
          <span>出款总额</span>
          <el-tooltip effect="dark" style="cursor: pointer; vertical-align: middle; margin-left: 2px; margin-bottom: 2px;" content="出款总额：只计算审核通过并出款成功的订单" placement="top">
            <i class="el-icon-question" style="font-size: 17px;" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="promotion" align="center" label="优惠总额" />
      <el-table-column prop="income" align="center">
        <template slot="header">
          <span>账面收益总额</span>
          <el-tooltip style="cursor: pointer; vertical-align: middle; margin-left: 2px; margin-bottom: 2px;" effect="dark" content="账面收益金额=入款金额-出款金额+其他补款" placement="bottom">
            <i class="el-icon-question" style="font-size: 17px;" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="realIncome" align="center" label="实际收益总额">
        <template slot="header">
          <span>实际收益</span>
          <el-tooltip style="cursor: pointer; vertical-align: middle; margin-left: 2px; margin-bottom: 2px;" effect="dark" content="实际收益金额=账面收益金额-优惠总额+活动回收总额" placement="bottom">
            <i class="el-icon-question" style="font-size: 17px;" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <!-- <el-table :data="resources" border>
      <el-table-column prop="time" align="center" :label="$t('时间')">
        <template slot-scope="{ row }">{{ row.startTime | parseTime('{y}-{m}-{d}') }}~{{ row.endTime | parseTime('{y}-{m}-{d}') }}</template>
      </el-table-column>
      <el-table-column prop="proceeds" align="center" :label="$t('入款总额')" />
      <el-table-column prop="payOut" align="center" :label="$t('出款总额')" />
      <el-table-column prop="promotion" align="center" :label="$t('赠送总额')" />
      <el-table-column prop="recycling" align="center" :label="$t('回收总额')" />
      <el-table-column prop="income" align="center">
        <template slot="header">
          <span>{{ $t('收益总额') }}</span>
          <el-tooltip class="item" effect="dark" :content="$t('收益总额=入款金额-出款金额-赠送总额+回收总额')" placement="bottom">
            <svg-icon icon-class="question-outline" style="font-size: 18px" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table> -->

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="false"
    />

    <pagination v-if="total > params.pageSize" :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <sale-dialog ref="SaleDialogControl" />
    <RecycleDialog ref="RecycleDialogControl" />

  </div>
</template>
<script>
import { getAccountReportList } from '@/api/statistical'
import TablePageMixin from '@/mixins/table-page-mixin'
import SaleDialog from './components/SaleDialog'
import RecycleDialog from './components/RecycleDialog'
import GetColumns from './columns'
import { getDefaultTimes } from '@/utils'
export default {
  name: 'AccountStatistical',
  components: { SaleDialog, RecycleDialog },
  mixins: [TablePageMixin(getAccountReportList)],
  data() {
    return {
      times: getDefaultTimes('n'),
      search: {},
      columns: GetColumns.call(this),
      resources: []
    }
  },
  methods: {
    implementationFetched({ startTime, endTime, proceeds, operatorAdd, payOut, promotion, income, realIncome, otherInall }) {
      this.resources = [{ startTime, endTime, proceeds, operatorAdd, payOut, promotion, income, realIncome, otherInall }]
    },

    implementationGetParams() {
      return this.formatIntervalTime()
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    onSaleView() {
      this.$refs.SaleDialogControl.open()
    }
  }
}
</script>
ssss
