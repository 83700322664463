import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: '公告标题',
      key: 'title',
      align: 'center'
    },
    {
      title: this.$t('是否启用'),
      key: 'enable',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch v-model={row.enable} active-value={1} inactive-value={0} beforeChange={this.handleUpdateEnable.bind(this, row)}>
              <span slot='close'>{ this.$t('停用') }</span>
            </d-switch>
          )
        }
      }
    },
    {
      title: '聊天室适用范围',
      key: 'isRoom',
      align: 'center',
      component: {
        render: (h, row) => {
          let isRoomText = '全部'
          if (row.isRoom !== 0) {
            isRoomText = this.roomTypes.find(e => e.value === row.isRoom)?.label
          } else {
            return (<span>全部</span>)
          }
          return (<span>{ isRoomText + '-' + (row.roomSeries === 0 ? '全部' : row.roomList.split(',').length + '+') }</span>)
        }
      }
    },
    {
      title: '最新编辑时间',
      key: 'updateTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: '操作员',
      key: 'operateName',
      align: 'center'
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          return (
            <span>
              { <el-button type='primary' size='mini' onClick={ () => this._handleOpen(row) }>修改</el-button> }
              { <el-button type='danger' size='mini' onClick={ () => this._handleDelete(row) }>删除</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
