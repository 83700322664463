import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: '发帖人',
      key: 'nickname',
      align: 'center',
      width: 100,
      component: {
        render: (h, row) => {
          return (<span class='primary-type'>{ row.nickname }</span>)
        }
      }
    },
    {
      title: '标题',
      key: 'title',
      align: 'center'
    },
    {
      title: '创建时间',
      key: 'createTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: '浏览数',
      key: 'viewNum',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          if (row.muteStatus === 1) {
            return (<span>{ row.viewNum }</span>)
          }
          return (
            <el-input-number
              v-model={row.viewNum}
              size='small'
              controls-position='right'
              style='width: 110px'
              min={0}
              step-strictly
              onBlur={this.handleUpdateViewAndAdmire.bind(this, row)}
            />
          )
        }
      }
    },
    {
      title: '点赞数',
      key: 'admireNum',
      align: 'center',
      width: 140,
      component: {
        render: (h, row) => {
          if (row.muteStatus === 1) {
            return (<span>{ row.admireNum }</span>)
          }
          return (
            <el-input-number
              v-model={row.admireNum}
              size='small'
              controls-position='right'
              style='width: 110px'
              min={0}
              step-strictly
              onBlur={this.handleUpdateViewAndAdmire.bind(this, row)}
            />
          )
        }
      }
    },
    {
      title: '打赏',
      key: 'reward',
      align: 'center',
      width: 100
    },
    {
      title: '状态',
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div class='recommend-status'>
              {
                row.topViewStatus === 1
                  ? <span style='color: red'>精华</span>
                  : ''
              }
              {
                row.topSort < 4
                  ? <span style='color: red'>置顶</span>
                  : ''
              }
              {
                row.lockStatus == 1
                  ? <span style='color: red'>锁定</span>
                  : <span>未锁</span>
              }
              <span>
                {
                  row.loginViewStatus === 1
                    ? <p style='color: red'>登录可见</p>
                    : <p>非登录可见</p>
                }
                {
                  row.replyViewStatus === 1
                    ? <p style='color: red'>回复可见</p>
                    : <p>非回复可见</p>
                }
              </span>
              {
                row.payViewStatus === 1
                  ? <span style='color: red'>充值可见</span>
                  : <span>非充值可见</span>
              }
            </div>
          )
        }
      }
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center',
      width: 80,
      component: {
        render: (h, row) => {
          return (
            <el-dropdown trigger='click' class='cursor-pointer' onCommand={command => this.onCommand(command, row)}>
              <span class='el-dropdown-link'>
                <i class='el-icon-arrow-down el-icon--right'></i>
              </span>
              <el-dropdown-menu slot='dropdown'>
                <el-dropdown-item icon='el-icon-edit' command={0}>修改帖子</el-dropdown-item>
                <el-dropdown-item icon='el-icon-upload2' command={1} divided >{ row.topViewStatus === 0 ? '加精帖子' : '取消加精'}</el-dropdown-item>
                <el-dropdown-item icon='el-icon-upload2' command={4} divided >{ row.topSort < 4 ? '取消置顶' : '置顶帖子'}</el-dropdown-item>
                <el-dropdown-item icon='el-icon-edit' command={2} divided>隐藏贴子</el-dropdown-item>
                <el-dropdown-item icon='el-icon-edit' command={3} divided>{ row.lockStatus === 0 ? '锁贴禁评' : '解锁评论'}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
