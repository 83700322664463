import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: '昵称/发布时间',
      key: 'replyNickname',
      align: 'center',
      width: 300,
      component: {
        render: (h, row) => {
          return (
            <div style='text-align: left; padding-left: 40px'>
              <p style='margin-bottom: 10px'>昵称: { row.replyNickname }</p>
              <p>发布时间: { this.$parseTime(row.createTime) }</p>
            </div>
          )
        }
      }
    },
    {
      title: '内容',
      key: 'content',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<div style='padding: 15px 0; text-align: left'>{ row.content }</div>)
        }
      }
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          // const { Update, Max } = this.permission
          // { Update && <el-button type='success' size='small' icon='el-icon-edit' onClick={() => this.updateFunc(row)}>保存</el-button> }
          return (
            <div>
              <el-button type='primary' style='width: 80px; margin-bottom: 10px' size='mini' onClick={() => this._reply(row)}>回复</el-button><br />
              <el-button type='primary' style='width: 80px; margin-bottom: 10px' size='mini' onClick={() => this._delete(row)}>删评论</el-button><br />
              { row.forbiddenStatus === 1 && <el-button type='primary' style='width: 80px' size='mini' onClick={() => this._banned(row)}>禁言</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
