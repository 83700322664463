<template>
  <div class="app-container">
    <el-form ref="validateForm" label-position="top" label-width="80px" :model="ruleForm" :rules="rules">
      <el-form-item :label="$t('代理说明')" prop="remark">
        <tinymce ref="tinymce" :key="tinymceFlags" v-model="ruleForm.remark" :height="600" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ $t('保存配置') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import { addAgencyConfigRemark, getAgencyConfigRemark } from '@/api/agent-rebate.js'
export default {
  name: 'RemarkTab',
  components: { Tinymce },
  data() {
    return {
      rules: {
      },
      ruleForm: {
        remark: ''
      },
      tinymceFlags: 0
    }
  },
  created() {
    this.getAgencyConfigRemark()
  },
  activated() {
    this.tinymceFlags++
  },
  methods: {
    onSubmit() {
      this.ruleForm.agencyMemberType = this.agencyMemberType
      this.$refs.validateForm.validate(valid => {
        if (valid) {
          addAgencyConfigRemark(this.ruleForm).then(([data, _err]) => {
            if (!_err) {
              this.$message.success(this.$t('保存成功'))
            }
          })
        } else {
          return false
        }
      })
    },
    getAgencyConfigRemark() {
      getAgencyConfigRemark().then(([data, _err]) => {
        if (!_err) {
          this.ruleForm.remark = data.remark
          this.$nextTick(() => {
            this.$refs.tinymce.hasInit &&
            this.$refs.tinymce.setContent(data.remark)
          })
        }
      })
    }
  }
}
</script>

