const CanChooseColumns = function() {
  return [
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-link type="primary" onClick={ () => this.$refs.UserDialogControl.open(row.memberId) }>{ row.memberName }</el-link>)
        }
      }
    },
    {
      title: this.$t('账号类型'),
      key: 'memberType',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.memberType[row.memberType] }</span>)
        }
      }
    },
    {
      title: this.$t('聊天室名称'),
      key: 'roomName',
      align: 'center'
    },
    {
      title: this.$t('发红包用户'),
      key: 'sendMemberName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-link type="primary" onClick={ () => this.$refs.UserDialogControl.open(row.sendMemberId) }>{ row.sendMemberName }</el-link>)
        }
      }
    },
    {
      title: this.$t('红包ID'),
      key: 'orderId',
      align: 'center'
    },
    {
      title: this.$t('领取金额'),
      key: 'redAmount',
      align: 'center'
    },
    {
      title: this.$t('领取时间'),
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
