var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "150px" },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-col",
            [
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("签到任务配置"))),
                _c("span", { staticClass: "sign-tips" }, [
                  _vm._v(_vm._s(_vm.$t("签到活动按月更新"))),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("签到任务"), prop: "checkinStatus" } },
                [
                  _c(
                    "d-switch",
                    {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.checkinStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "checkinStatus", $$v)
                        },
                        expression: "ruleForm.checkinStatus",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v(_vm._s(_vm.$t("开启"))),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v(_vm._s(_vm.$t("关闭"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("签到任务分类"),
                    prop: "activityType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { placeholder: _vm.$t("请选择任务分类") },
                      model: {
                        value: _vm.ruleForm.activityType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "activityType", $$v)
                        },
                        expression: "ruleForm.activityType",
                      },
                    },
                    _vm._l(_vm.activityTypeList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.typeName, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c("span", { staticClass: "sign-tips" }, [
                    _vm._v(_vm._s(_vm.$t("选择签到任务所属分类"))),
                  ]),
                ],
                1
              ),
              _c("h3", [_vm._v(_vm._s(_vm.$t("可签到条件限制")))]),
              _c(
                "el-form-item",
                { staticClass: "tiaojian" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("当日有效投注"),
                        prop: "betsAmount",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.betsAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "betsAmount", $$v)
                          },
                          expression: "ruleForm.betsAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("当日充值次数"),
                        prop: "rechargeTimes",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.rechargeTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "rechargeTimes", $$v)
                          },
                          expression: "ruleForm.rechargeTimes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("当日充值金额"),
                        prop: "rechargeAmount",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.rechargeAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "rechargeAmount", $$v)
                          },
                          expression: "ruleForm.rechargeAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "tiaojian" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("总打码"), prop: "betsTotal" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.betsTotal,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "betsTotal", $$v)
                          },
                          expression: "ruleForm.betsTotal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("总充值金额"),
                        prop: "rechargeTotal",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.rechargeTotal,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "rechargeTotal", $$v)
                          },
                          expression: "ruleForm.rechargeTotal",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h3", [_vm._v(_vm._s(_vm.$t("签到说明配置")))]),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "margin-right": "6px" },
                      on: { change: _vm.handleLang },
                      model: {
                        value: _vm.lang,
                        callback: function ($$v) {
                          _vm.lang = $$v
                        },
                        expression: "lang",
                      },
                    },
                    _vm._l(_vm.allLang, function (item, index) {
                      return _c(
                        "el-radio-button",
                        { key: index, attrs: { label: item.lang } },
                        [_vm._v(_vm._s(_vm.$t(item.name)))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                _vm._l(_vm.content, function (item, key) {
                  return _c("tinymce", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.lang === key,
                        expression: "lang === key",
                      },
                    ],
                    key: key,
                    ref: key,
                    refInFor: true,
                    attrs: { height: 200 },
                    model: {
                      value: _vm.ruleForm[item],
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, item, $$v)
                      },
                      expression: "ruleForm[item]",
                    },
                  })
                }),
                1
              ),
              _c("h3", [_vm._v(_vm._s(_vm.$t("签到奖励配置")))]),
              _c(
                "el-form-item",
                { staticClass: "tiaojian" },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        border: "",
                        data: _vm.signInSet1,
                        "header-cell-class-name": _vm.getCellClass,
                        "cell-class-name": _vm.getCellClass,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", align: "center", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function () {
                              return [_vm._v(_vm._s(_vm.$t("签到日期")))]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(_vm._s(row.signInDate))]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: _vm.$t("签到奖励"), align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("彩金"),
                                      prop: row.winningField,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.ruleForm[row.winningField],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            row.winningField,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm[row.winningField]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("打码倍数"),
                                      prop: row.typingField,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.ruleForm[row.typingField],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            row.typingField,
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm[row.typingField]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        border: "",
                        data: _vm.signInSet2,
                        "header-cell-class-name": _vm.getCellClass,
                        "cell-class-name": _vm.getCellClass,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", align: "center", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function () {
                              return [_vm._v(_vm._s(_vm.$t("签到日期")))]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(_vm._s(row.signInDate))]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: _vm.$t("签到奖励"), align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("彩金"),
                                      prop: row.winningField,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.ruleForm[row.winningField],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            row.winningField,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm[row.winningField]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("打码倍数"),
                                      prop: row.typingField,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.ruleForm[row.typingField],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            row.typingField,
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm[row.typingField]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        border: "",
                        data: _vm.signInSet3,
                        "header-cell-class-name": _vm.getCellClass,
                        "cell-class-name": _vm.getCellClass,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", align: "center", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function () {
                              return [_vm._v(_vm._s(_vm.$t("签到日期")))]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(_vm._s(row.signInDate))]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: _vm.$t("签到奖励"), align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("彩金"),
                                      prop: row.winningField,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.ruleForm[row.winningField],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            row.winningField,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm[row.winningField]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("打码倍数"),
                                      prop: row.typingField,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.ruleForm[row.typingField],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            row.typingField,
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm[row.typingField]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("累计签到奖励配置"))),
                _c("span", { staticClass: "sign-tips" }, [
                  _vm._v(_vm._s(_vm.$t("满足条件奖励自动发放"))),
                ]),
              ]),
              _c(
                "el-form-item",
                { staticClass: "tiaojian" },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "leiji",
                      attrs: { border: "", data: _vm.accumulatedSignInSet },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", align: "center", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function () {
                              return [_vm._v(_vm._s(_vm.$t("累计签到天数")))]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(_vm._s(row.signInDate))]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: _vm.$t("签到奖励"), align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("选择抵用券"),
                                      prop: row.couponField,
                                      placeholder: _vm.$t("请选择抵用券"),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          "collapse-tags": "",
                                        },
                                        model: {
                                          value: _vm.ruleForm[row.couponField],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              row.couponField,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm[row.couponField]",
                                        },
                                      },
                                      _vm._l(_vm.couponList, function (item) {
                                        return _c("el-option", {
                                          key: item.couponId,
                                          attrs: {
                                            value: item.couponId,
                                            label: item.couponTitle,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("彩金"),
                                      prop: row.winningField,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.ruleForm[row.winningField],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            row.winningField,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm[row.winningField]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("打码倍数"),
                                      prop: row.typingField,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.ruleForm[row.typingField],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            row.typingField,
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm[row.typingField]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "action-button" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("保 存")))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$refs["ruleForm"].resetFields()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("重 置")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }