import { $t } from '@/lang';
export const userStatus = [
  { key: 0, label: $t('启用') },
  { key: 1, label: $t('停用') }
];

export const limitTypeOptions = {
  0: $t('充值'),
  1: $t('打码量')
};

export const memberTypes = [
  { key: 1, label: $t('内部账号') },
  { key: 2, label: $t('注册账户') },
  { key: 3, label: $t('充值账户') },
  { key: 4, label: $t('测试账户') }
];

export const changeTypes = [
  { key: 1, label: $t('投注') },
  { key: 2, label: $t('充值') },
  { key: 3, label: $t('任务领取') },
  { key: 4, label: $t('商城兑换') }
];

export const bettingStatus = [
  { key: 2, label: $t('待开奖'), type: 'primary' },
  { key: 5, label: $t('未中奖'), type: 'info' },
  { key: 6, label: $t('已中奖'), type: 'danger' },
  { key: 7, label: $t('打和'), type: 'danger' },
  { key: 10, label: $t('已撤单'), type: 'warning' }
];

export const ftBettingStatus = [
  { key: 2, label: $t('待开奖') },
  { key: 3, label: $t('开奖中') },
  { key: 5, label: $t('未中奖') },
  { key: 6, label: $t('已中奖') },
  { key: 10, label: $t('已撤单') },
  { key: 11, label: $t('处理失败') },
  { key: 12, label: $t('扣款失败') },
  { key: 20, label: $t('异常订单') },
  { key: 21, label: $t('已取消') }
];

export const rechargeStatus = [
  { key: '0000', label: $t('充值成功'), type: 'success' },
  { key: '1111', label: $t('待审核'), type: 'process' },
  { key: '2222', label: $t('充值失败'), type: 'error' }
];

export const paymentStatus = [
  { key: '0000', label: $t('支付成功'), type: 'success' },
  { key: '1111', label: $t('等待支付'), type: 'process' },
  { key: '2222', label: $t('支付失败'), type: 'danger' }
];

export const paymentTypes = [
  { key: 0, label: $t('银行卡转银行卡') },
  { key: 1, label: $t('微信转银行卡') },
  { key: 2, label: $t('支付宝转银行卡') }
];

export const withdrawStatus = [
  { key: 0, label: $t('待扣款'), type: 'process' },
  { key: 1, label: $t('待处理'), type: 'process' },
  { key: 2, label: $t('处理中'), type: 'process' },
  { key: 3, label: $t('拒绝'), type: 'danger' },
  { key: 4, label: $t('成功'), type: 'success' },
  { key: 5, label: $t('核对中'), type: 'process' },
  { key: 6, label: $t('批量处理中'), type: 'process' },
  { key: 7, label: $t('代付中'), type: 'process' },
  { key: 8, label: $t('代付成功'), type: 'success' },
  { key: 9, label: $t('代付失败'), type: 'danger' }
];

export const recommendAduditStatus = [
  { key: 0, label: $t('未审核') },
  { key: 1, label: $t('审核通过') },
  { key: 2, label: $t('审核拒绝') }
];

export const activityStatus = [
  { key: 0, label: $t('待开始'), type: 'danger' },
  { key: 1, label: $t('进行中'), type: 'process' },
  { key: 2, label: $t('已结束'), type: 'success' }
];

export const activityCategorySelect = [
  { key: 0, label: $t('最受欢迎') },
  { key: 1, label: $t('积分活动') }
];

export const taskCategory = [
  { key: 0, label: $t('其他活动') },
  { key: 1, label: $t('任务活动-日任务') },
  { key: 2, label: $t('任务活动-周任务') },
  { key: 3, label: $t('任务活动-长期任务') },
  { key: 4, label: $t('任务活动-新手任务') },
];

export const taskAction = [
  { key: -1, label: $t('全部') },
  { key: 1, label: $t('签到') },
  { key: 2, label: $t('充值') },
  { key: 3, label: $t('提现') },
  { key: 4, label: $t('投注') },
  { key: 5, label: $t('关注') },
  { key: 6, label: $t('发帖') },
  { key: 7, label: $t('评论') },
  { key: 8, label: $t('打赏') },
  { key: 9, label: $t('浏览') },
  { key: 10, label: $t('邀请') },
];

export const goodsStatus = [
  { key: 0, label: $t('上架') },
  { key: 1, label: $t('下架') }
];

export const productType = [
  { key: 1, label: $t('实物商品') },
  { key: 2, label: $t('虚拟商品') }
];

export const goodsOrderStatus = [
  { key: 1, label: $t('待发货') },
  { key: 2, label: $t('已完成') }
];

export const awardsType = [
  { key: '', label: $t('全部') },
  { key: 1, label: $t('普通奖励') },
  { key: 2, label: $t('累计奖励') }
];

export const liveRoomStatus = {
  1: $t('直播中'),
  9: $t('已下播')
};

export const liveAccountTypes = {
  1: $t('支付宝'),
  2: $t('微信'),
  3: $t('银行卡')
};

export const sportMatchStatus = {
  '1': { label: $t('未开赛'), type: 'danger' },
  '2': { label: $t('上半场'), type: 'info' },
  '3': { label: $t('中场'), type: 'info' },
  '4': { label: $t('下半场'), type: 'info' },
  '5': { label: $t('加时赛'), type: 'info' },
  '7': { label: $t('点球决战'), type: 'info' },
  '8': { label: $t('完场'), type: 'disable' },
  '9': { label: $t('推迟'), type: 'success' },
  '10': { label: $t('中断'), type: 'success' },
  '11': { label: $t('腰斩'), type: 'success' },
  '12': { label: $t('取消'), type: 'success' },
  '13': { label: $t('待定'), type: 'success' }
};

export const rateTypes = [
  { key: 3, label: 'USDT', rate: '', isOpen: 0 },
  { key: 1, label: $t('美元'), rate: '', isOpen: 0 },
  { key: 4, label: $t('越南盾'), rate: '', isOpen: 0 },
  { key: 5, label: $t('卢比'), rate: '', isOpen: 0 },
  { key: 2, label: $t('人民币'), rate: '', isOpen: 1 },
  { key: 6, label: $t('日元'), rate: '', isOpen: 0 },
  { key: 7, label: $t('韩元'), rate: '', isOpen: 0 },
  { key: 8, label: $t('港币'), rate: '', isOpen: 0 },
  { key: 9, label: $t('马来西亚币'), rate: '', isOpen: 0 },
  { key: 10, label: $t('巴西币'), rate: '', isOpen: 0 }
];

export const agencyTypes = [
  { key: 1, label: $t('负盈利') },
  { key: 2, label: $t('打码量') }
];

export const payTypes = [
  { key: 0, label: 'USDT' },
  { key: 1, label: $t('银行卡') },
  { key: 6, label: $t('代客充值') },
  { key: 2, label: $t('支付宝') },
  { key: 3, label: $t('微信') },
  { key: 4, label: $t('三方钱包') },
  { key: 5, label: $t('其他三方支付') }
];
export const payTypesAbpay = [
  { label: "微信", key: 1 },
  { label: "支付宝", key: 2 },
  { label: "银行卡", key: 3 },
  { label: "USDT", key: 4 },
  { label: "数字钱包", key: 5 },
  { label: "abpay", key: 6 },
];

export const dataSource = [
  { key: '10', label: $t('彩票开奖') },
  { key: '20', label: $t('直播间') }
];

export const dataSourceLive = [
  { key: '20', label: $t('全部') },
  { key: '21', label: $t('彩票') },
  { key: '22', label: $t('足球') },
  { key: '23', label: $t('篮球') }
];

export const livePreLotteryType = [
  { key: 1, label: $t('彩票') },
  { key: 2, label: $t('足球') },
  { key: 3, label: $t('篮球') },
  { key: 4, label: $t('无') }
];

export const preStatus = [
  { key: 1, label: $t('预约中') },
  { key: 2, label: $t('已取消') },
  { key: 3, label: $t('直播中') },
  { key: 4, label: $t('已结束') }
];

export const displayStatusList = {
  1: $t('免费超清'),
  2: $t('高清'),
  3: $t('普清')
};

export const serverSource = {
  1: '设备开播',
  2: '流媒体播放',
  3: 'OBS开播',
  4: '点播视频'
};

export const tryPreStatus = {
  0: $t('待重试'),
  1: $t('重试中'),
  2: $t('失败'),
  3: $t('成功')
};

export const orderStatus = {
  0: $t('未结算'),
  1: $t('已结算'),
  2: $t('已撤单')
};

export const paymentType = {
  0: $t('未扣款'),
  1: $t('已扣款')
};

export const memberType = { 1: '注册游客', 2: '注册用户', 3: '充值用户', 4: '测试用户' }

export const appPageList = [
  { value: '1000001', label: '首页', children: [
    { value: '1000001', label: '推荐' },
    { value: '2000001', label: '彩票大厅' },
    { value: '2000002', label: '竞彩足球' },
    { value: '2000003', label: '直播' },
    { value: '2000004', label: '彩讯' },
    { value: '9999008', label: '体讯' },
    { value: '2000005', label: '推单大厅' },
    { value: '9999010', label: '新澳门开奖直播' },
    { value: '9999011', label: '香港开奖直播' },
    { value: '9999012', label: '爱博钱包' },
    { value: '9999021', label: 'ABpay充值' }
  ]},
  { value: '1000002', label: '滚球', children: [
    { value: '1000002', label: '滚球' },
    { value: '9999017', label: '欧洲杯' }
  ]},
  { value: '1000003', label: '心水', children: [
    { value: '9999005', label: '心水推荐（香港）' },
    { value: '9999006', label: '心水推荐（新澳门）' },
    { value: '9999003', label: '六合图库（香港）' },
    { value: '9999004', label: '六合图库（新澳门）' },
    { value: '9999022', label: '快乐六合心水' },
    { value: '9999023', label: '快乐六合图库' },
    { value: '2000006', label: '晒单圈' },
    { value: '9999007', label: '资料交流（聊天室）' }
  ]},
  { value: '1000004', label: '我的', children: [
    { value: '2000007', label: '充值页面' },
    { value: '2000008', label: '卡片管理' },
    { value: '2000009', label: '我的消息' },
    { value: '9999014', label: '任务中心' },
    { value: '9999002', label: '优惠活动' },
    { value: '2000011', label: '分享赚钱' },
    { value: '2000012', label: '我的代理' },
    { value: '9999031', label: '积分商城' }
  ]},
  { value: '1000005', label: '彩票大厅', children: [
    { value: '10', label: '六合彩系列' },
    { value: '11', label: '时时彩系列' },
    { value: '13', label: '快三系列' },
    { value: '12', label: 'PK10系列' },
    { value: '20', label: '越南系列' },
    { value: '21', label: '数字货币系列', children: [
      { value: "135", label: "BTC3分" },
      { value: "136", label: "BTC5分" },
      { value: "137", label: "BTC15分" },
      { value: "138", label: "BTC30分" }
    ]},
    { value: '15', label: '乐透彩' },
    { value: '22', label: '动物会' }
  ]},
  { value: '1000006', label: '真人荷官', children: [
    { value: '1301', label: 'PP真人' },
    { value: '1503', label: 'DB真人' },
    { value: '1800', label: 'BBIN真人' },
    { value: '200', label: 'AG旗舰厅' },
    { value: '200', label: 'AG国际厅' },
    { value: '2600', label: 'BG视讯' }
  ]},
  { value: '1000007', label: '棋牌', children: [
    { value: '100', label: '开元棋牌' },
    { value: '1700', label: '乐游棋牌' },
    { value: '1804', label: 'BBIN棋牌' },
    { value: '2400', label: 'MT棋牌' }
  ]},
  { value: '1000008', label: '捕鱼', children: [
    { value: '1302', label: 'JDB捕鱼' },
    { value: '1601', label: 'CQ捕鱼' },
    { value: '1701', label: '乐游捕鱼' },
    { value: '1803', label: 'BBIN大师' },
    { value: '2300', label: '开元捕鱼' },
    { value: '2401', label: 'MT捕鱼' },
    { value: '2501', label: 'SG捕鱼' },
    { value: '2601', label: 'BG捕鱼' }
  ]},
  { value: '1000009', label: '老虎机', children: [
    { value: '1200', label: 'PG电子' },
    { value: '1300', label: 'PP电子' },
    { value: '1600', label: 'CQ9电子' },
    { value: '2402', label: 'MT电子' },
    { value: '2500', label: 'SG电子' },
    { value: '2800', label: 'MG电子' },
    { value: '700', label: 'JDB电子' },
    { value: '2900', label: 'FG电子' },
    { value: '3000', label: 'PS电子' }
  ]},
  { value: '1000010', label: '竞技游戏', children: [
    { value: '1400', label: 'AWC赛马' },
    { value: '1501', label: 'DB电竞' }
  ]},
  { value: '1000011', label: '体育', children: [
    { value: '2200', label: 'IM体育' },
    { value: '1802', label: 'BBIN体育' },
    { value: '2000', label: '沙巴体育' },
    { value: '2700', label: 'FB体育' },
    { value: '800', label: '188体育' }
  ]},
]

export const xsLottery = { 40: '香港', 41: '澳门', 1255: '快乐六合彩' }

export const roomTypes = [
  { label: '互动大厅', value: 1, type: 'lottery' },
  { label: '赛事-滚球', value: 2, type: 'sport' },
  { label: '赛事-竞彩', value: 5, type: 'sport' },
  { label: '直播', value: 4, type: 'live' },
  { label: '热门彩种', value: 3, type: 'lottery' }
]

export const limitTypes = { 1: '全部禁言', 2: '全部禁入', 3: '房间禁言', 4: '房间禁入' }

export const sportTypes = { 1: '足球', 2: '篮球', 3: '网球' }

export const domainTypes = { 0: '网关域名', 1: '图片域名', 2: '开奖域名socket', 3: 'IM域名socket', 4: 'IM域名API' }

export const awardTypes = { 1: '周红包', 2: '月红包' }

export const recordStatus = { 1: '待领取', 2: '已领取', 3: '已过期' }

export const borrowStatus = { 0: '待审核', 1: '审核通过', 2: '审核不通过' }

export const sources = [
  { label: '画廊图库', value: 'SJTK', lotteryId: 40 },
  { label: '49图库', value: 'HK49', lotteryId: 40 },
  { label: '54图库', value: 'HK54', lotteryId: 40 },
  { label: '画廊图库', value: 'AMTK', lotteryId: 41 },
  { label: '49图库', value: 'AM49', lotteryId: 41 },
  { label: '54图库', value: 'AM54', lotteryId: 41 },
  { label: '手动添加', value: 'CUSTOMIZED', lotteryId: 1255 }
]

export const commonStatus = { '1': '启用', '0': '停用' }

export const paymentCustomerTypes = { 1: '平台', 2: '三方' }

// 文本框输入的SeriesTag
export const inputSeriesTagTag = ['9999012','9999013','9999015','9999021','9999022','9999030']

// export const couponTypes = { 0: '彩票', 1: '竞彩', 2: '直播间', 3: '抵扣券', 4: '满赠券', 5: '存款券', 6: '投注加奖券' }
export const couponTypes = { 3: '抵扣券', 4: '满赠券', 5: '存款券', 6: '投注加奖券' }

export const receiveTypes = { 1: '手动发放', 2: '任务领取' }

export const couponScopeModes = { 0: '彩票', 1: '竞彩', 2: '原生体育' }

export const seriesTypes = { 1: '彩票', 2: '竞彩足球', 3: '体育', 4: '真人', 5: '捕鱼', 6: '老虎机', 7: '棋牌', 8: '竞技' }

export const seriesPlayTypes = { 1: '六合彩', 2: '时时彩', 3: 'PK10系列', 4: '快三系列', 5: 'PP真人', 6: 'DB真人', 7: 'BBIN真人', 8: 'AG真人', 9: 'BG视讯', 10: '开元棋牌', 11: '乐游棋牌', 12: 'BBIN棋牌', 13: 'MT棋牌:', 14: 'JDB捕鱼', 15: 'CQ捕鱼', 16: '乐游捕鱼', 17: 'BBIN大师', 18: 'AG捕鱼', 19: '开元捕鱼', 20: 'MT捕鱼', 21: 'SG捕鱼', 22: 'BG捕鱼', 23: 'MT电子', 24: 'PG电子', 25: 'PP电子', 26: 'CQ电子', 27: 'SG电子', 28: 'FG电子', 29: 'PS电子', 30: 'JDB电子', 31: 'AWC赛马', 32: 'DB电竞', 33: '原生体育', 34: 'BBIN体育', 35: '沙巴体育', 36: 'IM体育', 37: 'FB体育', 38: '竞彩足球' }



export const couponGameList = [
  { value: '1000005', label: '彩票', collapse: [], children: []},
  { value: '2000002', label: '竞彩', collapse: [], children: []},
  { value: '1000002', label: '原生体育', collapse: [], children: []},
  { value: '1000006', label: '真人', collapse: [], children: [
    { value: '1301', label: 'PP真人', checked: false, indeterminate: false, collapse: [] },
    { value: '1503', label: 'DB真人', checked: false, indeterminate: false, collapse: [], children: [{ value: '1503', label: 'DB真人', checked: false, indeterminate: false }] },
    { value: '1800', label: 'BBIN真人', checked: false, indeterminate: false, collapse: [] },
    { value: '200', label: 'AG厅', checked: false, indeterminate: false, collapse: [] },
    { value: '2600', label: 'BG视讯', checked: false, indeterminate: false, collapse: [] }
  ]},
  { value: '1000007', label: '棋牌', collapse: [], children: [
    { value: '100', label: '开元棋牌', checked: false, indeterminate: false, collapse: [] },
    { value: '1700', label: '乐游棋牌', checked: false, indeterminate: false, collapse: [] },
    { value: '1804', label: 'BBIN棋牌', checked: false, indeterminate: false, collapse: [] },
    { value: '2400', label: 'MT棋牌', checked: false, indeterminate: false, collapse: [] }
  ]},
  { value: '1000008', label: '捕鱼', collapse: [], children: [
    { value: '1302', label: 'JDB捕鱼', checked: false, indeterminate: false, collapse: [] },
    { value: '1601', label: 'CQ捕鱼', checked: false, indeterminate: false, collapse: [] },
    { value: '1701', label: '乐游捕鱼', checked: false, indeterminate: false, collapse: [] },
    { value: '1803', label: 'BBIN大师', checked: false, indeterminate: false, collapse: [] },
    { value: '2300', label: '开元捕鱼', checked: false, indeterminate: false, collapse: [] },
    { value: '2401', label: 'MT捕鱼', checked: false, indeterminate: false, collapse: [] },
    { value: '2501', label: 'SG捕鱼', checked: false, indeterminate: false, collapse: [] },
    { value: '2601', label: 'BG捕鱼', checked: false, indeterminate: false, collapse: [] }
  ]},
  { value: '1000009', label: '电子', collapse: [], children: [
    { value: '1200', label: 'PG电子', checked: false, indeterminate: false, collapse: [] },
    { value: '1300', label: 'PP电子', checked: false, indeterminate: false, collapse: [] },
    { value: '1600', label: 'CQ9电子', checked: false, indeterminate: false, collapse: [] },
    { value: '2402', label: 'MT电子', checked: false, indeterminate: false, collapse: [] },
    { value: '2500', label: 'SG电子', checked: false, indeterminate: false, collapse: [] },
    { value: '2800', label: 'MG电子', checked: false, indeterminate: false, collapse: [] },
    { value: '700', label: 'JDB电子', checked: false, indeterminate: false, collapse: [] },
    { value: '2900', label: 'FG电子', checked: false, indeterminate: false, collapse: [] },
    { value: '3000', label: 'PS电子', checked: false, indeterminate: false, collapse: [] }
  ]},
  { value: '1000010', label: '竞技', collapse: [], children: [
    { value: '1400', label: 'AWC赛马', checked: false, indeterminate: false, collapse: [], children: [{ value: '1400', label: 'AWC赛马', checked: false, indeterminate: false }] },
    { value: '1501', label: 'DB电竞', checked: false, indeterminate: false, collapse: [] }
  ]},
  { value: '1000011', label: '体育', collapse: [], children: [
    { value: '2200', label: 'IM体育', checked: false, indeterminate: false, collapse: [] },
    { value: '1802', label: 'BBIN体育', checked: false, indeterminate: false, collapse: [] },
    { value: '2000', label: '沙巴体育', checked: false, indeterminate: false, collapse: [], children: [{ value: '2000', label: '沙巴体育', checked: false, indeterminate: false }] },
    { value: '2700', label: 'FB体育', checked: false, indeterminate: false, collapse: [] },
    { value: '800', label: '188体育', checked: false, indeterminate: false, collapse: [], children: [{ value: '800', label: '188体育', checked: false, indeterminate: false }] }
  ]}
]