/* 正则*/
// 大于0正整数
import { parseTime } from '@/utils'
export const integer0 = /^\+?[1-9]\d{0,7}|-1$/
export const integer1 = /^\+?[1-9]\d*|-1$/
export const integer2 = /^\+?[1-9]\d{0,7}\.?\d{0,2}$/

export const integerNormal = /^(?!00)(?:[0-9]{1,9})$/

// 0到100正整数
export const integer0_100 = /^100$|^(\d|[1-9]\d)$/

// 1到100正整数
export const integer1_100 = /^100$|^[1-9][0-9]{0,1}$/

// 1到99正整数
export const integer1_99 = /^[1-9][0-9]{0,1}$/

// 1到7正整数
export const integer1_7 = /^[1-7]{0,1}$/

// 0到9999整数
export const integer0_9999 = /^(?!00)(?:[0-9]{1,4})$/

// 1到9999整数
export const integer1_9999 = /^\+?[1-9]{1}[0-9]{0,3}$/

/**
 * /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/ 大于0最多两位小数
 * */

// 请输入0-100的数，且最多有三位小数！
export const percent0_100 = /^(?:0|[1-9][0-9]?|100)(\.[0-9]{0,3})?$/

// 0或者大于0的正整数
export const numerical_ = /^(0|[1-9]\d*)$/

// 大于0且最多1位小数的数
export const numerical_0 = /^[0-9]+\.?\d{0,1}$/

// 不小于0且最多1位小数的数
export const numerical_1 = /^\d+\.?\d{0,1}$/

// 不小于0且最多2位小数的数
export const numerical_2 = /^\d+\.?\d{0,2}$/

// 不小于0且最多三位小数的数
export const numerical = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,3})?$/

// 不小于0且最多四位小数的数
export const numerical_f4 = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,4})?$/

// 2~10位英文字符
export const stringE2_10 = /^[a-zA-Z]{2,10}$/

// 长度为3-12位英文数字
export const stringCE3_12 = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{3,12}$/

// 2-6个汉字昵称
export const stringC2_6 = /^[\u4e00-\u9fa5]{2,6}$/

// 2-16个汉字昵称
export const stringC2_16 = /^[\u4e00-\u9fa5]{2,16}$/

// 手机号验证
export const phone = /^([1][3,4,5,6,7,8,9])\d{9}$/

// IP验证
export const IP = /^((25[0-5]|2[0-4]\d|[01]?\d\d?)($|(?!\.$)\.)){4}$/

// 链接地址验证
export const url = /^(https?|ftp|http):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|online|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/

/* 验证*/
export const requiredBlur = [{ required: true, message: '此项必填', trigger: 'blur' }]

export const requiredChange = [{ required: true, message: '此项必选', trigger: 'change' }]

export const requiredArrayChange = [{ type: 'array', required: true, message: '此项必选', trigger: 'change' }]

export const requireIntegerNormal = [
  ...requiredBlur,
  { pattern: integerNormal, message: '请输入0~9,999,999整数', trigger: 'blur' }
]

export const requirePercent0_100 = [
  ...requiredBlur,
  { pattern: percent0_100, message: '请输入0~100的数，且最多有一位小数', trigger: 'blur' }
]

export const requireNumerical = [
  ...requiredBlur,
  { pattern: numerical, message: '不小于0且最多三位小数的数', trigger: 'blur' }
]

export const requirePhone = [
  ...requiredBlur,
  { pattern: phone, message: '请输入正确的手机号码', trigger: 'blur' }
]

export const requireIP = [
  ...requiredBlur,
  { pattern: IP, message: '请输入正确的IP', trigger: 'blur' }
]

export const requireUrl = [
  ...requiredBlur,
  { pattern: url, message: '请输入正确的链接地址', trigger: 'blur' }
]

export const stringLengcheck = ({ min, max }) => {
  if (min === undefined) min = 1
  if (max === undefined) min = 999
  return [
    { required: true, message: `此项必填`, trigger: 'blur' },
    { min, max, message: `请输入${min}~${max}个字符`, trigger: 'blur' }
  ]
}

export function getPercent(val, pe) {
  let n = 1
  if (pe) n = pe > 1 ? 1 : 3
  return (pe ? (val * pe) : (val * 1)).toFixed(n)
}

// 验证时间相对于当前时间是否过期
/** validateTimeInterval({ type: 'datetime', index: 0 })
 * type 'datetimerange' 表示日期时间区间验证开始
 * type datetime 表示日期时间
 * type daterange 表示日期区间
 * index 0表示验证开始时间 1 表示结束时间 仅type为datetimerange daterange有效
 * */
export const validateTimeInterval = (obj = {}) => {
  const { type, index } = Object.assign({}, { type: 'datetime', index: 0 }, obj)
  const validateTime = (rule, value, callback) => {
    if ((type === 'datetime' && value === '') || (type !== 'datetime' && value.length === 0)) {
      callback(new Error('此项必填'))
    } else {
      let date
      let time = type === 'datetime' ? value : value[index]

      if (typeof _value === 'object') {
        date = time
      } else {
        if ((typeof time === 'string')) {
          if ((/^[0-9]+$/.test(time))) {
            time = parseInt(time)
          } else {
            time = time.replace(new RegExp(/-/gm), '/')
          }
        }
      }

      if ((typeof time === 'number') && (time.toString().length === 10)) {
        time = time * 1000
      }

      date = new Date(time)

      const checktime = date.getTime()
      const now = type === 'daterange' ? new Date(parseTime(new Date(), '{y}-{m}-{d}').replace(/-/g, '/')).getTime() : new Date().getTime()
      if (checktime - now < 0) {
        const checkTimeDesc = ['开始时间', '结束时间']
        callback(new Error(`已选${checkTimeDesc[index]}不可小于当前时间`))
      } else {
        callback()
      }
    }
  }
  const requireTime = type !== 'datetime' ? [{ type: 'array', required: true, message: '此项必填', trigger: 'change' }] : requiredChange
  return [
    ...requireTime,
    { validator: validateTime, trigger: 'change' }
  ]
}
