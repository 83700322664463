<template>
  <el-dialog :title="$t('编辑用户')" :visible.sync="visible" width="500px" top="5vh" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('账号')">
        <el-input v-model="ruleForm.operatorName" disabled style="width: 300px;" />
      </el-form-item>
      <el-form-item :label="$t('昵称')" prop="nickName">
        <el-input v-model="ruleForm.nickName" style="width: 300px;" />
      </el-form-item>
      <el-form-item :label="$t('用户分组')">
        <el-select v-model="ruleForm.groupId" :placeholder="$t('请选择用户分组')" style="width: 300px;">
          <el-option v-for="item in groupList" :key="item.id" :label="item.groupName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('修改密码')" prop="password">
        <el-input v-model="ruleForm.password" type="password" :placeholder="$t('请输入密码(不填即为不修改)')" style="width: 300px;" />
      </el-form-item>
      <el-form-item label="谷歌验证码" prop="googleCode">
        <el-input v-model.trim="ruleForm.googleCode" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { validAccountAndPassword } from '@/utils/validate'
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  operatorName: '',
  nickName: '',
  groupId: null,
  password: undefined,
  googleCode: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        nickName: [{ required: true, message: this.$t('请输入昵称'), trigger: 'blur' }],
        googleCode: [
          { required: true, message: '请输入谷歌验证码', trigger: 'blur' },
          { pattern: /^[0-9]{6}$/, message: '请输入6位谷歌验证码', trigger: ['change', 'blur'] }
        ]
      }
    }
  },
  computed: {
    groupList() {
      return this.$parent.groupList
    }
  },
  methods: {
    open({ operatorId, operatorName, nickName, groupId } = {}) {
      this.ruleForm = {
        operatorId,
        operatorName,
        nickName,
        groupId,
        password: undefined
      }
      this.visible = true
    }
  }
}
</script>
