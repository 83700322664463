<template>
  <el-dialog :title="title" :visible.sync="visible" width="500px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item :label="$t('敏感词列表')" prop="sendInfo">
        <el-input v-model="ruleForm.sendInfo" type="textarea" :placeholder="$t('敏感词用逗号分割！！')" :autosize="{ minRows: 15 }" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="medium" @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" size="medium" :loading="submitting" @click="handleSubmit">
        {{ $t('确认') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getSensitiveInfo } from '@/api/chat'
const getDefaultForm = () => ({
  sendInfo: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'submit',
      rules: {
        sendInfo: [
          { required: true, message: this.$t('请输入', { text: this.$t('敏感词') }), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    title() {
      return this.$t('敏感词过滤')
    }
  },
  methods: {
    open() {
      getSensitiveInfo({}).then(([data, err]) => {
        if (!err) {
          this.ruleForm.sendInfo = data || ''
        }
      })
      this.visible = true
    }
  }
}
</script>

