var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c("el-cascader", {
          attrs: { options: _vm.thirdGames, props: { expandTrigger: "hover" } },
          on: { change: _vm.onChangeCascaderType },
          model: {
            value: _vm.search.type2,
            callback: function ($$v) {
              _vm.$set(_vm.search, "type2", $$v)
            },
            expression: "search.type2",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c(
          "el-select",
          {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { placeholder: "时间类型" },
            model: {
              value: _vm.search.timeType,
              callback: function ($$v) {
                _vm.$set(_vm.search, "timeType", $$v)
              },
              expression: "search.timeType",
            },
          },
          [
            _c("el-option", { attrs: { label: "投注时间", value: 0 } }),
            _c("el-option", { attrs: { label: "结算时间", value: 1 } }),
          ],
          1
        ),
        _c("el-date-picker", {
          staticClass: "filter-item",
          staticStyle: { width: "400px" },
          attrs: {
            clearable: "",
            type: "datetimerange",
            "range-separator": _vm.$t("至"),
            "start-placeholder": _vm.$t("开始日期"),
            "end-placeholder": _vm.$t("结束日期"),
            "value-format": "timestamp",
            "default-time": ["00:00:00", "23:59:59"],
            "picker-options": _vm.pickerOptions,
          },
          model: {
            value: _vm.times,
            callback: function ($$v) {
              _vm.times = $$v
            },
            expression: "times",
          },
        }),
        _c("el-input", {
          staticClass: "filter-item",
          staticStyle: { width: "160px" },
          attrs: { clearable: "", placeholder: _vm.$t("平台用户账号") },
          model: {
            value: _vm.search.memberName,
            callback: function ($$v) {
              _vm.$set(_vm.search, "memberName", $$v)
            },
            expression: "search.memberName",
          },
        }),
        _vm.isSports
          ? _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: _vm.$t("联赛选择") },
              model: {
                value: _vm.search.competitionName,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "competitionName", $$v)
                },
                expression: "search.competitionName",
              },
            })
          : _vm._e(),
        _vm.isSports
          ? _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { clearable: "", placeholder: _vm.$t("球队选择") },
              model: {
                value: _vm.search.teamName,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "teamName", $$v)
                },
                expression: "search.teamName",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c("el-input", {
          staticClass: "filter-item",
          staticStyle: { width: "160px" },
          attrs: { clearable: "", placeholder: _vm.$t("三方用户账号") },
          model: {
            value: _vm.search.uname,
            callback: function ($$v) {
              _vm.$set(_vm.search, "uname", $$v)
            },
            expression: "search.uname",
          },
        }),
        _c("el-input", {
          staticClass: "filter-item",
          staticStyle: { width: "160px" },
          attrs: { clearable: "", placeholder: _vm.$t("注单号") },
          model: {
            value: _vm.search.orderNo,
            callback: function ($$v) {
              _vm.$set(_vm.search, "orderNo", $$v)
            },
            expression: "search.orderNo",
          },
        }),
        _c("el-input", {
          staticClass: "filter-item",
          staticStyle: { width: "160px" },
          attrs: { clearable: "", placeholder: _vm.$t("游戏名称") },
          model: {
            value: _vm.search.gameName,
            callback: function ($$v) {
              _vm.$set(_vm.search, "gameName", $$v)
            },
            expression: "search.gameName",
          },
        }),
        _c(
          "el-select",
          {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "订单状态" },
            model: {
              value: _vm.search.orderStatus,
              callback: function ($$v) {
                _vm.$set(_vm.search, "orderStatus", $$v)
              },
              expression: "search.orderStatus",
            },
          },
          _vm._l(_vm.orderStatus, function (val, key) {
            return _c("el-option", {
              key: key,
              attrs: { label: val, value: key },
            })
          }),
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "filter-item",
            attrs: {
              disabled: !_vm.search.type,
              type: "primary",
              icon: "el-icon-search",
            },
            on: { click: _vm.handleSearchWrap },
          },
          [_vm._v(_vm._s(_vm.$t("查询")))]
        ),
        _c(
          "el-button",
          {
            staticClass: "filter-item",
            attrs: { type: "primary", icon: "el-icon-refresh-right" },
            on: { click: _vm.resetSearch },
          },
          [_vm._v(_vm._s(_vm.$t("重置")))]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isSports,
            expression: "!isSports",
          },
        ],
      },
      [
        _c(
          "d-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "DTable",
            attrs: {
              data: _vm.resource,
              columns: _vm.columns,
              options: _vm.options,
              "filter-column": _vm.filterColumn,
              "span-method": _vm.objectSpanMethod,
            },
            scopedSlots: _vm._u([
              {
                key: "right",
                fn: function () {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          plain: "",
                          type: "primary",
                          disabled: !_vm.search.type,
                          loading: _vm.excelLoading1,
                          icon: "el-icon-download",
                        },
                        on: { click: _vm._handleExport },
                      },
                      [_vm._v(_vm._s(_vm.$t("导出Excel")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          plain: "",
                          type: "primary",
                          disabled: !_vm.search.type,
                          loading: _vm.excelLoading2,
                          icon: "el-icon-download",
                        },
                        on: { click: _vm._handleExportTotal },
                      },
                      [_vm._v(_vm._s(_vm.$t("汇总导出")))]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "template",
              { slot: "left" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("总订单数")) +
                    "：" +
                    _vm._s(
                      _vm.statistics ? _vm.statistics.numberCount || 0 : "***"
                    ) +
                    " " +
                    _vm._s(_vm.$t("总投注额")) +
                    "：" +
                    _vm._s(
                      _vm.statistics ? _vm.statistics.allbetCount || 0 : "***"
                    ) +
                    " " +
                    _vm._s(_vm.$t("有效投注额")) +
                    "：" +
                    _vm._s(
                      _vm.statistics ? _vm.statistics.betCount || 0 : "***"
                    ) +
                    " " +
                    _vm._s(_vm.$t("中奖金额")) +
                    "：" +
                    _vm._s(
                      _vm.statistics
                        ? Number(_vm.statistics.sumWinCount || 0).toFixed(3)
                        : "***"
                    ) +
                    " "
                ),
                ![24, 36, 27, 28].includes(_vm.search.type)
                  ? [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("玩家输赢")) +
                          "：" +
                          _vm._s(
                            _vm.statistics
                              ? (
                                  Number(_vm.statistics.sumWinCount || 0) -
                                  Number(_vm.statistics.betCount || 0)
                                ).toFixed(3)
                              : "***"
                          ) +
                          " "
                      ),
                    ]
                  : [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("玩家输赢")) +
                          "：" +
                          _vm._s(
                            _vm.statistics
                              ? _vm.statistics.profitCount || 0
                              : "***"
                          ) +
                          " "
                      ),
                    ],
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("玩家数量")) +
                    "：" +
                    _vm._s(
                      _vm.statistics ? _vm.statistics.accountNumber || 0 : "***"
                    ) +
                    " "
                ),
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary", disabled: !_vm.search.type },
                    on: { click: _vm.getStatisticsData },
                  },
                  [_vm._v("查看结果")]
                ),
              ],
              2
            ),
          ],
          2
        ),
        _c("pagination", {
          attrs: {
            page: _vm.params.currentPage,
            limit: _vm.params.pageSize,
            total: _vm.total,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.params, "currentPage", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.params, "pageSize", $event)
            },
            pagination: _vm.handleFetch,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isSports,
            expression: "isSports",
          },
        ],
      },
      [
        _c(
          "d-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "DTable",
            attrs: {
              data: _vm.resource,
              columns: _vm.columns2,
              options: _vm.options,
              "filter-column": _vm.filterColumn,
              "span-method": _vm.objectSpanMethod,
            },
            scopedSlots: _vm._u([
              {
                key: "right",
                fn: function () {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          plain: "",
                          type: "primary",
                          disabled: !_vm.search.type,
                          loading: _vm.excelLoading1,
                          icon: "el-icon-download",
                        },
                        on: { click: _vm._handleExport },
                      },
                      [_vm._v(_vm._s(_vm.$t("导出Excel")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          plain: "",
                          type: "primary",
                          disabled: !_vm.search.type,
                          loading: _vm.excelLoading2,
                          icon: "el-icon-download",
                        },
                        on: { click: _vm._handleExportTotal },
                      },
                      [_vm._v(_vm._s(_vm.$t("汇总导出")))]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "template",
              { slot: "left" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("总订单数")) +
                    "：" +
                    _vm._s(
                      _vm.statistics ? _vm.statistics.numberCount || 0 : "***"
                    ) +
                    " " +
                    _vm._s(_vm.$t("总投注额")) +
                    "：" +
                    _vm._s(
                      _vm.statistics ? _vm.statistics.allbetCount || 0 : "***"
                    ) +
                    " " +
                    _vm._s(_vm.$t("有效投注额")) +
                    "：" +
                    _vm._s(
                      _vm.statistics ? _vm.statistics.betCount || 0 : "***"
                    ) +
                    " " +
                    _vm._s(_vm.$t("中奖金额")) +
                    "：" +
                    _vm._s(
                      _vm.statistics
                        ? Number(_vm.statistics.sumWinCount || 0).toFixed(3)
                        : "***"
                    ) +
                    " "
                ),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("玩家输赢")) +
                    "：" +
                    _vm._s(
                      _vm.statistics ? _vm.statistics.profitCount || 0 : "***"
                    ) +
                    " " +
                    _vm._s(_vm.$t("玩家数量")) +
                    "：" +
                    _vm._s(
                      _vm.statistics ? _vm.statistics.accountNumber || 0 : "***"
                    ) +
                    " "
                ),
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary", disabled: !_vm.search.type },
                    on: { click: _vm.getStatisticsData },
                  },
                  [_vm._v("查看结果")]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _c("pagination", {
          attrs: {
            page: _vm.params.currentPage,
            limit: _vm.params.pageSize,
            total: _vm.total,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.params, "currentPage", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.params, "pageSize", $event)
            },
            pagination: _vm.handleFetch,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }