<template>
  <el-dialog :title="titleObj.title" width="600px" :visible.sync="dialogFormVisible" @close="_close">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
      <el-form-item label="账户" prop="memberName">
        <el-input v-model="ruleForm.memberName" disabled placeholder="请输入账户" />
      </el-form-item>
      <el-form-item label="昵称" prop="nickname">
        <el-input v-model="ruleForm.nickname" maxlength="10" show-word-limit disabled placeholder="请输入昵称" />
      </el-form-item>
      <el-form-item label="状态" prop="forbiddenStatus">
        <el-radio-group v-model="ruleForm.forbiddenStatus" disabled>
          <el-radio :label="0">禁言</el-radio>
          <el-radio :label="1">未禁言</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="时间" required prop="relieveTime">
        <el-date-picker
          v-model="ruleForm.relieveTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="请选择解禁时间"
        />
      </el-form-item>
      <el-form-item label="备注" prop="content">
        <el-input v-model="ruleForm.content" type="textarea" maxlength="50" show-word-limit :rows="4" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" :loading="btnloading" @click="handleSubmit('ruleForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import ElDialogMx from '@/views/mixins/el-dialog-mx.js'
import { forbiddenUser } from '@/api/sun-lap.js'
import { ruleForm, rules } from '../pageData.js'
export default {
  mixins: [ElDialogMx],
  data() {
    return {
      ruleForm,
      rules
    }
  },
  methods: {
    _submit() {
      const { tenantCode, nickname, memberId, relieveTime, content } = this.ruleForm
      const params = Object.assign({}, { memberId, relieveTime: new Date(relieveTime).getTime(), content }, tenantCode && { tenantCode })
      this._forbiddenTopic(params, nickname)
    },

    init({ memberId, nickname, memberName }) { // 初始化
      this.dialogFormVisible = true
      const code = this.$P$ && this.$parent.tenant ? { tenantCode: this.$parent.tenant.tenantCode } : {}
      const params = Object.assign({}, { memberId, nickname, memberName }, code)
      this.titleObj.title = `昵称为 ${nickname} 的用户禁言`
      this.ruleForm = Object.assign({}, this.ruleForm, params, { forbiddenStatus: 0 })
    },

    _forbiddenTopic(params, nickname) { // 禁言
      this.btnloading = true
      forbiddenUser(params).then(([data, err]) => {
        this.btnloading = false
        if (!err) {
          this.$emit('backSuccess', true)
          this.dialogFormVisible = false
          this.$message.success(`昵称 ${nickname} 的用户禁言成功！`)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
