<template>
  <el-dialog
    :title="$t('编辑主播')"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item :label="$t('主播昵称')" prop="nickName">
        <el-input v-model.trim="ruleForm.nickName" maxlength="12" show-word-limit />
      </el-form-item>
      <el-form-item :label="$t('登录账号')" prop="accLogin">
        <el-input v-model.trim="ruleForm.accLogin" maxlength="12" show-word-limit />
      </el-form-item>
      <el-form-item :label="$t('联系方式')" prop="contactInfo">
        <el-input v-model.trim="ruleForm.contactInfo" maxlength="20" show-word-limit />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  nickName: '',
  accLogin: '',
  contactInfo: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        nickName: [{ required: true, message: this.$t('请输入昵称'), trigger: 'blur' }],
        accLogin: [
          { required: true, message: this.$t('请输入登录账号'), trigger: 'blur' },
          { pattern: /^.{6,12}$/, message: this.$t('登录账号长度为6-12位'), trigger: 'blur' }
        ],
        contactInfo: [
          { required: true, message: this.$t('请输入联系方式'), trigger: 'blur' },
          { pattern: /^.{2,20}$/, message: this.$t('联系方式长度为2-20位'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(row) {
      this.ruleForm.anchorAccno = row.anchorAccno
      this.ruleForm.familyId = row.familyId
      this.ruleForm.nickName = row.nickName
      this.ruleForm.accLogin = row.accLogin
      this.ruleForm.contactInfo = row.contactInfo
      this.visible = true
    }
  }
}
</script>

