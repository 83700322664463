<template>
  <div>
    <h3>首页优惠和奖励设置</h3>
    <el-table
      ref="elTable"
      border
      :data="items"
      row-key="index"
      :drag-row="true"
      v-loading="loading"
    >
      <el-table-column type="index" align="center" width="60">
        <template #header>{{ $t("排序") }}</template>
        <svg-icon icon-class="drag" class-name="drag-handler" />
      </el-table-column>
      <el-table-column label="活动标题" prop="activityName" align="center" />
      <el-table-column :label="$t('活动图片')" align="center">
        <template slot-scope="{ row }" v-if="row.activityImage">
          <el-image :src="row.activityImage" />
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="{ row }">
          {{ { 1: '启用', 0: '禁用' }[row.status] }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')" align="center" width="140">
        <template slot-scope="{ row }">
          <el-button @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0" align="right">
      <el-button
        type="success"
        :loading="submitting"
        @click="handleSubmit()"
      >{{ $t("保 存") }}</el-button>
    </div>
    <ActionDialog ref="ActionDialogContorl" @update="handleSave" />
  </div>
</template>
<script>
import { homepagePromotionSetting, editpromotionSetting, batchUpdatePromotionSortStatus } from '@/api/config'
import TableSortMixin from '@/mixins/table-sort-mixin'
import ActionDialog from './ActionDialog.vue'

export default {
  components: { ActionDialog },
  mixins: [TableSortMixin(true)],
  data() {
    return {
      loading: false,
      items: [],
      submitting: false,
      types: { 1: '彩票', 2: '电子', 3: '真人', 4: '体育', 5: '竞技', 6: '捕鱼', 7: '棋牌' }
    }
  },
  created() {
    this.getMenuList()
  },
  methods: {
    handleEdit(row) {
      this.$refs.ActionDialogContorl.open(row)
    },
    getMenuList() {
      this.loading = true
      homepagePromotionSetting({ position: 0 }).then(([items, err]) => {
        if (!err) {
          this.items = []
          for(let index = 0;index < 10;index++) {
            if(items.length > index) {
              this.items.push({ ...items[index], index })
            } else {
              this.items.push({ sort: 9999 + index, index })
            }
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    //保存多项
    handleSubmit() {
      const list = []
      this.items.forEach((e, i) => {
        if (e.id) {
          list.push({ id: e.id, sort: i + 1 })
        }
      });
      if (list.length === 0) {
        return this.$message.warning('请先至少编辑保存一条数据')
      }
      this.submitting = true
      batchUpdatePromotionSortStatus(list).then(([_, err]) => {
        if (!err) {
          this.$message.success(this.$t('保存成功'))
        }
      }).finally(() => {
        this.submitting = false
      })
    },
    //保存单项
    handleSave({ field, cancel, close }) {
      editpromotionSetting(field).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.getMenuList()
        } else {
          cancel()
        }
      }).finally(() => {
        this.submitting = false
      })
    }
  }
}
</script>
