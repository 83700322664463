var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: _vm.wrapClasses,
      style: _vm.wrapStyles,
      attrs: { tabindex: "0" },
      on: {
        click: _vm.toggle,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          ) {
            return null
          }
          return _vm.toggle($event)
        },
      },
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.currentValue },
      }),
      _c(
        "span",
        { class: _vm.innerClasses },
        [
          _vm.currentValue === _vm.activeValue
            ? _vm._t("open", [_vm._v(_vm._s(_vm.activeText))])
            : _vm._e(),
          _vm.currentValue === _vm.inactiveValue
            ? _vm._t("close", [_vm._v(_vm._s(_vm.inactiveText))])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }