var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "注册内部账号",
        width: "500px",
        visible: _vm.visible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户名", prop: "memberName", required: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入用户名" },
                model: {
                  value: _vm.ruleForm.memberName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "memberName", $$v)
                  },
                  expression: "ruleForm.memberName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "登录密码", prop: "password", required: "" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入6~12位英文数字密码",
                  type: "password",
                },
                model: {
                  value: _vm.ruleForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "password", $$v)
                  },
                  expression: "ruleForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户类型", prop: "userType", required: "" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.ruleForm.userType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "userType", $$v)
                    },
                    expression: "ruleForm.userType",
                  },
                },
                [_vm._v("普通会员")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.ruleForm.userType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "userType", $$v)
                    },
                    expression: "ruleForm.userType",
                  },
                },
                [_vm._v("推单专家用户")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }