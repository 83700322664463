var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("comMenu", {
        attrs: {
          title: "娱乐版-首页顶部导航",
          width: "600px",
          "is-name": false,
          "is-icon": false,
          "menu-list": _vm.menuList,
          num: 4,
          "home-type": "entertainment",
          position: 1,
        },
      }),
      _c("comMenu", {
        attrs: {
          title: "体育版-首页顶部导航",
          width: "600px",
          "is-name": false,
          "is-icon": false,
          "menu-list": _vm.menuList,
          num: 4,
          "home-type": "sport",
          position: 1,
        },
      }),
      _c("comMenu", {
        attrs: {
          title: "六合版-首页顶部导航",
          width: "600px",
          "is-name": false,
          "is-icon": false,
          "menu-list": _vm.menuList,
          num: 4,
          "home-type": "liuhe",
          position: 1,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }