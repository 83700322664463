var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "大神粉丝数量",
        top: "5vh",
        width: "40%",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "validateForm",
              staticClass: "table-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("实际粉丝数量"),
                    prop: "followersNum",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.followersNum,
                      callback: function ($$v) {
                        _vm.followersNum = _vm._n($$v)
                      },
                      expression: "followersNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("虚拟粉丝数量"),
                    prop: "createFollowersNum",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.createFollowersNum,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "createFollowersNum",
                          _vm._n($$v)
                        )
                      },
                      expression: "ruleForm.createFollowersNum",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: _vm.$t("当前合计粉丝") } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.followersNum + _vm.ruleForm.createFollowersNum) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.save } }, [
            _vm._v(_vm._s(_vm.$t("确定"))),
          ]),
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("关闭"))),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }