<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive>
        <component :is="activeName" />
      </keep-alive>
    </template>
  </div>
</template>
<script>
import { parentMixin } from '@/mixins/tab-items'
import ForecastTab from './forecast'
import LeagueTab from './league'
import ThirdMatchHotTab from './thirdMatchHot'
import ThirdMatchHomeTab from './thirdMatchHome'

export default {
  name: 'MutualMatch',
  components: {
    ForecastTab,
    LeagueTab,
    ThirdMatchHotTab,
    ThirdMatchHomeTab
  },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      activeName: 'ForecastTab',
      tabs: [
        { label: this.$t('Interactive_Match.赛事列表'), name: 'ForecastTab' },
        { label: this.$t('Interactive_Match.联赛设置'), name: 'LeagueTab' },
        { label: this.$t('Interactive_Match.体育-热门'), name: 'ThirdMatchHotTab' },
        { label: this.$t('Interactive_Match.体育-首页'), name: 'ThirdMatchHomeTab' }
      ]
    }
  }
}
</script>

