<template>
  <div>
    <cascader-select
      v-model="value"
      :options="playClass"
      :multiple="false"
      :parent-label="$t('一级玩法')"
      :child-label="$t('二级玩法')"
      @change="onChangeSelect"
    />

    <d-table
      ref="DTable"
      v-loading="loading"
      row-key="playClassTag"
      :drag-row="permission.Sort"
      index-row
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <template slot="left">
        <el-button type="success" plain @click="_handleSave">{{ $t('全部保存') }}</el-button>
      </template>
    </d-table>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CascaderSelect from '@/components/CascaderSelect'
import { getPlayClassList, batchUpdatePlayClass } from '@/api/lottery'
import TableSortMixin from '@/mixins/table-sort-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'

export default {
  name: 'TyPeTab',
  componentName: 'TyPeTab',
  components: { CascaderSelect },
  mixins: [TableSortMixin(), TablePageMixin(getPlayClassList, false)],
  props: {
    lotteryId: {
      type: String,
      default: ''
    },
    playClass: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      params: {},
      value: [],
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  computed: {
    ...mapGetters(['operatorType'])
  },
  created() {
    this.value = [this.playClass[0].value, this.playClass[0].children[0].value]
    this.params.lotteryId = this.lotteryId
    this.params.playTag = this.value[0]
    this.handleRefresh()
  },
  methods: {
    onChangeSelect(val) {
      this.params.playTag = val[0]
      this.handleRefresh()
    },
    _handleUpdate({ playClassName, ...other }) {
      this.$confirm(this.$t('确认修改属性值为{playClassName}的信息', { text: playClassName }), { type: 'warning' }).then(() => {
        batchUpdatePlayClass([{ ...other, lotteryId: this.params.lotteryId }], this.operatorType).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('修改成功'))
          }
        })
      }).catch(() => {})
    },

    _handleSave() {
      this.$confirm(this.$t('确认修改所有属性值信息'), { type: 'warning' }).then(() => {
        const tmp = this.items.map((o, i) => ({
          sort: i,
          lotteryId: this.lotteryId,
          liveShowStatus: o.liveShowStatus,
          maxSelectNum: o.maxSelectNum,
          playClassName: o.playClassName,
          playClassTag: o.playClassTag,
          status: o.status
        }))
        batchUpdatePlayClass(tmp, this.operatorType).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            // this.handleRefresh()
          }
        })
      }).catch(() => {})
    },

    implementationGetParams() {
      return { ...this.params }
    }
  }
}
</script>
