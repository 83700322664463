<template>
  <el-dialog
    :title="okText"
    width="610px"
    top="5vh"
    :visible.sync="visible"
    class="table-dialog"
    @closed="handleClosed"
  >
    <table>
      <colgroup>
        <col width="165">
        <col>
      </colgroup>
      <tbody>
        <tr>
          <td class="is-right">{{ $t('彩种') }}：</td>
          <td>{{ ruleForm.lotteryName }}</td>
        </tr>
        <tr>
          <td class="is-right">{{ $t('期号') }}：</td>
          <td>{{ ruleForm.issue }}</td>
        </tr>
      </tbody>
    </table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('取消') }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">{{ okText }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
export default {
  mixins: [VDialogMixin],
  data() {
    return {
      submitting: false,
      ruleForm: {},
      emitName: 'cancel'
    }
  },
  computed: {
    okText() {
      return this.emitName === 'cancel' ? this.$t('撤单') : this.$t('期号撤单')
    }
  },
  methods: {
    open(row, emitName,[startTime,endTime]) {
      const { lotteryId, orderNo, lotteryName,playClassTag, issue, memberId,deductBatchNumber }=row
      this.emitName = emitName
      this.ruleForm =  { startTime,endTime,lotteryId, orderNo, lotteryName,playClassTag, issue, memberId,deductBatchNumber }
      this.visible = true
    },
    close() {
      this.submitting = false
      this.visible = false
    },
    handleClosed() {
      this.ruleForm = {}
    },
    handleSubmit() {
      this.submitting = true
      this.$emit(this.emitName, {
        field: this.ruleForm,
        close: this.close,
        cancel: () => { this.submitting = false }
      })
    }
  }
}
</script>

