import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: '会员昵称',
      key: 'nickname',
      align: 'center',
      component: {
        render: (h, row) => {
          return <el-link type="primary" onClick={ () => this.$refs.UserDialogControl.open(row.memberId) }>{ row.nickname }</el-link>
        }
      }
    },
    {
      title: '会员ID',
      key: 'memberId',
      align: 'center'
    },
    {
      title: '限制类型',
      key: 'cmd',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.cmd[row.cmd] }</span>)
        }
      }
    },
    {
      title: '限制时段',
      key: 'expiredTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) + ' - ' + this.$parseTime(row.expiredTime) }</span>)
        }
      }
    },
    {
      title: '房间名称',
      key: 'groupName',
      align: 'center'
    },
    {
      title: '操作人员',
      key: 'operatorNickname',
      align: 'center'
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          return (
            <span>
              { this.permission.Relieve && <el-button type='warning' size='mini' onClick={ () => this._handleRelieve(row) }>解除</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
