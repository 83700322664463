<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        value-format="yyyy-MM-dd HH:mm:ss"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0, false)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1, false)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7, false)">{{ $t('本周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 14, false)">{{ $t('上周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30, false)">{{ $t('本月') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60, false)">{{ $t('上月') }}</el-button>
    </div>
    <div class="filter-container">
      <el-input v-model="search.orderNo" clearable placeholder="还款订单号" class="filter-item" style="width: 160px"/>
      <el-input v-model="search.memberName" clearable placeholder="会员账号" class="filter-item" style="width: 160px"/>
      <el-select v-model="search.memberType" clearable placeholder="会员类型" class="filter-item" style="width: 160px">
        <el-option v-for="item in memberTypes" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-select v-model="search.vipLevel" clearable placeholder="会员等级" class="filter-item" style="width: 160px">
        <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :originalColumns="defaultColumns"
      @refresh="handleSearch"
      :options="options"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <div class="filter-container">
          <span class="filter-item">还款金额:{{ statistical.repayAmount }}</span>
          <span class="filter-item">还款笔数:{{ statistical.repayCount }}笔</span>
          <span class="filter-item">还款人数:{{ statistical.repayMemberCount }}人</span>
        </div>
      </template>
    </d-table>
    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>
<script>
import { borrowRepayRecordList, borrowRepayRecordCount } from '@/api/vipRewardMange'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { getDefaultTimes } from '@/utils'
import { memberTypes } from '@/libs/options'
import { getAllVipLevels } from '@/api/app'
export default {
  name: 'ReturnRecodesTab',
  mixins: [TablePageMixin(borrowRepayRecordList)],
  data() {
    return {
      times: getDefaultTimes('timestamp', 0, 0, false),
      columns: [],
      defaultColumns: GetColumns.call(this),
      vipLevels: [],
      memberTypes,
      statistical: {}
    }
  },
  created() {
    this.handleVipLevels()
  },
  methods: {
    handleVipLevels() {
      getAllVipLevels().then(([items, err]) => {
        if (!err) {
          this.vipLevels = items
        }
      })
    },
    implementationGetParams() {
      return this.formatIntervalTime(false, 'startRepayTime', 'endRepayTime')
    },
    implementationFetched() {
      borrowRepayRecordCount(this.implementationGetParams()).then(([data, err]) => {
        if (!err) {
          this.statistical = data
        }
      })
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
