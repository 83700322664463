<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.giftName" :placeholder="$t('礼物名称')" clearable class="filter-item" style="width: 160px;" />
      <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleRefresh">{{ $t('查 询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template slot="right">
        <!-- <el-button type="primary" icon="el-icon-edit-outline" @click="$refs.ClassifyDialogControl.open()">礼物分类编辑</el-button> -->
        <!-- <el-button type="success" icon="el-icon-setting" @click="$refs.ShakeDialogControl.open()">震动方案配置</el-button> -->
        <el-button v-if="permission.Add" type="success" icon="el-icon-plus" @click="onCreate">{{ $t('创建礼物') }}</el-button>
      </template>

    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" @update="_handleUpdate" />
    <!-- <ShakeDialog ref="ShakeDialogControl" /> -->
    <!-- <ClassifyDialog ref="ClassifyDialogControl" /> -->

  </div>
</template>

<script>
import { getLiveGiftList, createLiveGift, updateLiveGift, updateLiveGiftStatus, updateLiveGiftActionStatus, updateLiveGiftLinkStatus } from '@/api/live'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { ActionDialog } from './components'
export default {
  name: 'LiveGift',
  components: { ActionDialog },
  mixins: [TablePageMixin(getLiveGiftList)],
  data() {
    return {
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this)
    }
  },
  methods: {
    onCreate() {
      this.$refs.ActionDialogControl.open()
    },
    _handleCreate({ field, cancel, close }) {
      createLiveGift({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      updateLiveGift({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleChangeStatus({ unionId, giftStatus }) {
      return new Promise((resolve) => {
        this.$confirm(giftStatus === 2 ? this.$t('确认启用?') : this.$t('确认禁用?'), this.$t('系统提示'), {
          center: true,
          type: 'warning'
        }).then(() => {
          updateLiveGiftStatus({
            unionId,
            giftStatus: giftStatus === 2 ? 1 : 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    _handleChangeLinkStatus({ unionId, giftLinkStatus }) {
      return new Promise((resolve) => {
        this.$confirm(giftLinkStatus === 2 ? this.$t('确认启用?') : this.$t('确认禁用?'), this.$t('系统提示'), {
          center: true,
          type: 'warning'
        }).then(() => {
          updateLiveGiftLinkStatus({
            unionId,
            giftLinkStatus: giftLinkStatus === 2 ? 1 : 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    _handleChangeActionStatus({ unionId, giftActionStatus }) {
      return new Promise((resolve) => {
        this.$confirm(giftActionStatus === 2 ? this.$t('确认启用?') : this.$t('确认禁用?'), this.$t('系统提示'), {
          center: true,
          type: 'warning'
        }).then(() => {
          updateLiveGiftActionStatus({
            unionId,
            giftActionStatus: giftActionStatus === 2 ? 1 : 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success(this.$t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
