<template>
  <div>
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7)">{{ $t('_7天') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <div class="filter-container">
      <el-select v-model="search.vipLevel" :placeholder="$t('用户等级')" clearable class="filter-item" style="width: 120px">
        <el-option v-for="item in vipLevels" :key="item.vipLevel" :label="`VIP${item.vipLevel}`" :value="item.vipLevel" />
      </el-select>
      <el-input v-model="search.memberName" clearable :placeholder="$t('会员账号')" class="filter-item" style="width: 120px" />
      <el-input v-model="search.receivingBank" clearable :placeholder="$t('收款银行')" class="filter-item" style="width: 120px" />
      <el-input v-model="search.orderNo" clearable :placeholder="$t('订单号')" class="filter-item" style="width: 120px" />
      <el-input v-model="search.lessThanAmount" clearable :placeholder="$t('提现金额小于')" class="filter-item" style="width: 160px" />
      <el-input v-model="search.greaterThanAmount" clearable :placeholder="$t('提现金额大于')" class="filter-item" style="width: 160px" />
      <el-input v-model="search.proxyCode" :placeholder="$t('所属代理')" clearable class="filter-item" style="width: 140px" />
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      selection-row
      row-key="orderNo"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
      @selection-change="handleSelectionChange"
    >
      <div slot="left">{{ $t('选中') }}：{{ selection.length }}{{ $t('条') }}  ：{{ selectionAmount }}{{ $t('元') }}</div>
      <template slot="right">
        <el-dropdown v-if="agentList.length" placement="top" trigger="click" @command="onCommandPay">
          <el-button type="primary" plain :disabled="!selection.length">{{ $t('第三方代付') }}<i class="el-icon-arrow-down el-icon--right" /></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in agentList" :key="item.id" :command="item.id">{{ item.payPlatformName }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button type="primary" plain @click="handleSelectAll">{{ $t('全选') }}</el-button>
        <el-button v-if="permission.Yes" type="primary" plain :disabled="!selection.length" @click="_onBatch(4)">{{ $t('批量通过') }}</el-button>
        <el-button v-if="permission.NOT" type="danger" plain :disabled="!selection.length" @click="_onBatch(3)">{{ $t('批量拒绝') }}</el-button>

        <el-dropdown v-if="permission.Excel || permission.Txt" placement="top" trigger="click" @command="onCommandExport">
          <el-button
            type="primary"
            plain
            :loading="exportLoading"
            :disabled="!selection.length"
          >{{ $t('导出选中') }}<i class="el-icon-arrow-down el-icon--right" /></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="permission.Excel" :command="1">{{ $t('导出EXCEL') }}</el-dropdown-item>
            <el-dropdown-item v-if="permission.Txt" :command="2">{{ $t('导出TXT') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <AloneDialog ref="AloneDialogControl" @update="handleUpdateAlone" />
    <BatchDialog ref="BatchDialogControl" @update="handleUpdateBatch" />

  </div>
</template>
<script>
import { AloneDialog, BatchDialog } from './components'
import { getWithdrawList, postWithdrawAudit, postWithdrawBatchAudit, getAgentListByTenantCode, postWithdrawBatchPaymentAgeny } from '@/api/cash'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'

import exportExcelTemplate from './exportExcelTemplate'

const insetparams = { status: 2 }

export default {
  name: 'CheckTab',
  componentName: 'CheckTab',
  components: { AloneDialog, BatchDialog },
  mixins: [TablePageMixin(getWithdrawList, true, insetparams)],
  data() {
    return {
      times: null,
      search: {},
      exportLoading: false,
      selection: [],
      columns: [],
      defaultColumns: GetColumns.call(this),
      agentList: []
    }
  },
  computed: {
    vipLevels() {
      return this.$parent.vipLevels || []
    },
    selectionAmount() {
      if (!this.selection.length) return 0
      return this.selection.map(o => Number(o.amount)).reduce((p, v) => p + v)
    }
  },
  created() {
    this.implementationTenantChanged()
  },
  methods: {
    _onDetails({ memberId }) {
      if (!this.permission.Details) return
      this.$parent.$refs.UserDialogControl.open(memberId, this.permission.Phone)
    },

    _onBatch(status) {
      this.$refs.BatchDialogControl.open(this.selection, status)
    },
    handleUpdateAlone({ field, cancel, close }) {
      postWithdrawAudit({ ...field }).then(([_, err, response]) => {
        if (!err) {
          close()
          this.$message.success(response.data.msg)
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    handleUpdateBatch({ field, cancel, close }) {
      const data = field.resource.map(o => {
        return {
          orderNo: o.orderNo,
          status: field.status,
          notes: field.notes
        }
      })

      postWithdrawBatchAudit(data).then(([_, err, response]) => {
        if (!err) {
          close()
          this.$message.success(response.data.msg)
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },

    onCommandPay(command) {
      const data = this.selection.map(o => ({
        orderNo: o.orderNo,
        status: 2,
        paymentAgentId: command
      }))
      postWithdrawBatchPaymentAgeny(data).then(([_, err, response]) => {
        this.handleFetch()
        if (!err) {
          this.$message.success(response.data.msg)
        }
      })
    },

    onCommandExport(command) {
      if (command === 1) {
        this._exportExcel()
      } else if (command === 2) {
        this._exportTxt()
      }
    },
    _exportExcel() {
      this.exportLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = exportExcelTemplate.map(oo => oo.title)
        const tKeys = exportExcelTemplate.map(oo => oo.key)
        const data = this.formatJson(tKeys, this.selection)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename || this.$t('提现审核表')
        })
        this.$refs.DTable.$refs.elTable.clearSelection()
        this.exportLoading = false
      })
    },
    _exportTxt() {
      import('@/vendor/Export2Excel').then(excel => {
        excel.export_table_to_txt({ data: this.selection })
        this.$refs.DTable.$refs.elTable.clearSelection()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j] && String(v[j]) || ''))
    },
    handleSelectionChange(selection) {
      this.selection = selection
    },
    handleSelectAll() {
      this.$refs.DTable.$refs.elTable.toggleAllSelection()
    },
    implementationTenantChanged() {
      getAgentListByTenantCode().then(([data, err]) => {
        if (!err) {
          this.agentList = data || []
        }
      })
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
