<template>
  <el-dialog
    :title="$t('Interactive_Match.ForecastTab.情报分析')"
    :visible.sync="visible"
    width="800px"
    :close-on-click-modal="false"
    @closed="handleClosed"
    @open="handleOpen"
  >

    <div v-loading="loading">
      <el-select v-model="position" style="margin-bottom: 18px">
        <el-option
          v-for="item in positionList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-input v-model="ruleForm[position]" type="textarea" :autosize="{ minRows: 8 }" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import { getIntelligenceList } from '@/api/match'

const getDefaultForm = () => ({
  homeFavorableInfo: '',
  awayFavorableInfo: '',
  homeAdverseInfo: '',
  awayAdverseInfo: '',
  neutralInfo: ''
})

export default {
  mixins: [VDialogMixin],
  data() {
    return {
      loading: false,
      submitting: false,
      emitName: 'update',
      positionList: this.$t('positionList'),
      position: 'homeFavorableInfo',
      ruleForm: getDefaultForm()
    }
  },
  methods: {
    open({ tenantCode, matchId, sportType }) {
      this.ruleForm.sportType = sportType
      this.ruleForm.matchId = matchId
      this.ruleForm.tenantCode = tenantCode
      this.visible = true
    },
    handleOpen() {
      this.loading = true
      getIntelligenceList({
        matchId: this.ruleForm.matchId,
        tenantCode: this.ruleForm.tenantCode,
        sportType: this.ruleForm.sportType
      }).then(([data, err]) => {
        this.loading = false
        if (!err) {
          if (Array.isArray(data) && data.length) {
            const tmp = data[0]
            const obj = getDefaultForm()
            for (let key in obj) {
              if (Array.isArray(tmp[key]) && tmp[key].length) this.ruleForm[key] = tmp[key] && tmp[key].join('\n')
            }
          }
        }
      })
    },
    handleClosed() {
      this.loading = false
      this.submitting = false
      this.position = 'homeFavorableInfo'
      this.ruleForm = getDefaultForm()
    },
    handleSubmit() {
      this.submitting = true
      this.$emit(this.emitName, {
        field: { ...this.ruleForm, lang: 'zh_CN' },
        cancel: () => {
          this.submitting = false
        },
        close: this.close
      })
    }
  }
}
</script>
