<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="daterange"
        class="filter-item"
        style="width: 260px;"
        value-format="yyyy-MM-dd"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0, false)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1, false)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 3, false)">{{ $t('本周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 14, false)">{{ $t('上周') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30, false)">{{ $t('本月') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60, false)">{{ $t('上月') }}</el-button>
    </div>
    <div class="filter-container">
      <el-input v-model="search.memberId" clearable maxlength="30" :placeholder="$t('会员ID')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.memberName" clearable maxlength="30" :placeholder="$t('会员账号')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.id" clearable maxlength="30" :placeholder="$t('奖励编号')" class="filter-item" style="width: 140px" />
      <el-select v-model="search.isDaily" clearable :placeholder="$t('任务类型')" class="filter-item" style="width: 140px">
        <el-option v-for="item in taskType" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-select v-model="search.modeType" clearable :placeholder="$t('任务方式')" class="filter-item" style="width: 140px">
        <el-option v-for="item in taskMethod" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-select v-model="search.activityType" clearable :placeholder="$t('任务分类')" class="filter-item"
        style="width: 140px">
        <el-option v-for="item in activityTypeList" :key="item.id" :value="item.id" :label="item.typeName" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleRefresh">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <template #left>
        <div class="total-list">
          <span>抵用券金额：{{ dataInfo.couponAmountTotal || 0 }}</span>
          <span>彩金金额：{{ dataInfo.rewardAmountTotal || 0 }}</span>
          <span>领取人数：{{ dataInfo.recipients || 0 }}</span>
        </div>
      </template>
      <template #right>
        <el-button plain type="primary" :loading="excelLoading" icon="el-icon-download" @click="_handleExport">{{ $t('导出Excel') }}</el-button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>
<script>
import { getDefaultTimes, exportExcel } from '@/utils'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getActivityMemberList, getActivityTypeList, activityMemberDownload } from '@/api/activity'
import GetColumns from './columns'
export default {
  name: 'RewardTab',
  mixins: [TablePageMixin(getActivityMemberList)],
  data() {
    return {
      times: getDefaultTimes('timestamp', 0, 0, false),
      search: {},
      filterColumn: false,
      dateTime: new Date().getTime(),
      columns: GetColumns.call(this),
      taskType: this.$t('taskType'),
      taskMethod: this.$t('taskMethod'),
      dataInfo: {},
      activityTypeList: [],
      excelLoading: false
    }
  },
  created() {
    this.getTypeList()
  },
  methods: {
    implementationGetParams() {
      return this.formatIntervalTime(true)
    },
    implementationFetched(data) {
      this.dataInfo = data
    },
    getTypeList() {
      getActivityTypeList({ currentPage: 1, pageSize: 100, isShow: 1 }).then(([_, err, data]) => {
        if (!err) {
          const { items } = data.data
          if (items.length > 0) {
            const list = []
            items.forEach(e => {
              const typeNameList = e.typeName.split(',')
              list.push({
                id: e.id,
                typeName: typeNameList[0].split(':')[1],
                typeNameLang: e.typeName
              })
            })
            this.activityTypeList = list
          }
        }
      })
    },
    _handleExport() {
      this.excelLoading = true
      activityMemberDownload({ ...this.implementationGetParams() }).then(([data, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `任务奖励记录-${this.$parseTime(new Date())}.csv`)
        }
      }).finally(()=>{
        this.excelLoading = false
      })
    }
  }
}
</script>

