var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rankpage" },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "basic_config_content",
          attrs: { gutter: 24 },
        },
        _vm._l(_vm.arr, function (item, index) {
          return _c(
            "el-form",
            {
              key: index,
              ref: "ruleForm",
              refInFor: true,
              attrs: { model: _vm.ruleForm[item.smallType] },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "titleLine" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    [
                      _c("Tinymce", {
                        ref: "tinymce",
                        refInFor: true,
                        attrs: { height: 350 },
                        model: {
                          value: _vm.ruleForm[item.smallType].explainContent,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm[item.smallType],
                              "explainContent",
                              $$v
                            )
                          },
                          expression: "ruleForm[item.smallType].explainContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "action-button" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", loading: _vm.submitting },
                          on: {
                            click: function () {
                              return _vm.handleSubmit(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("保 存")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }