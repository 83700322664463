<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive>
        <component :is="activeName" />
      </keep-alive>
    </template>
  </div>
</template>
<script>
import { parentMixin } from '@/mixins/tab-items'
import ConfigTab from './config'
import AuditTab from './audit'
import RecodesTab from './recodes'
import ReturnRecodesTab from './returnRecodes'
// import ManageTab from './manage'

export default {
  name: 'vipRewardMange_index',
  components: { ConfigTab, AuditTab, RecodesTab, ReturnRecodesTab },
  mixins: [parentMixin],
  data() {
    return {
      tabs: [
        { label: this.$t('借呗配置'), name: 'ConfigTab' },
        { label: this.$t('借款审核'), name: 'AuditTab' },
        { label: this.$t('借款记录'), name: 'RecodesTab' },
        { label: this.$t('还款记录'), name: 'ReturnRecodesTab' },
        // { label: this.$t('会员借呗管理'), name: 'ManageTab' },
      ]
    }
  }
}
</script>
