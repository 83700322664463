var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: "800px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.roomList, "max-height": "350" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "roomTypeName", label: "聊天室类型", width: "250" },
          }),
          _c("el-table-column", {
            attrs: { prop: "roomName", label: "聊天室", width: "250" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.close },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }