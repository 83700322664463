var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "display-icon",
    },
    _vm._l(_vm.resources, function (resource) {
      return _c(
        "div",
        { key: resource.key, staticClass: "display-icon-list" },
        [
          _c("h2", { staticClass: "display-icon-title" }, [
            _vm._v(_vm._s(resource.title) + ":"),
          ]),
          _vm._l(resource.items, function (item) {
            return _c(
              "div",
              { key: item.seriesTag, staticClass: "display-icon-item" },
              [
                _c("h4", [_vm._v(_vm._s(item.seriesName))]),
                _vm._l(item.list, function (child) {
                  return _c(
                    "div",
                    { key: child.lotteryId, staticClass: "display-icon-child" },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            accept: "image/png,image/jpeg,image/webp",
                            action: _vm.action,
                            data: { type: "image", folder: "icon" },
                            headers: _vm.$headers(),
                            "show-file-list": false,
                            "on-success": function () {
                              var args = [],
                                len = arguments.length
                              while (len--) args[len] = arguments[len]

                              return _vm.handleUploadSuccess(args, child)
                            },
                            "before-upload": _vm.beforeIconUpload,
                          },
                        },
                        [
                          child.icon
                            ? _c("el-image", {
                                staticClass: "avatar",
                                attrs: { src: child.icon },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "name",
                          class: { parent: child.seriesTag },
                        },
                        [_vm._v(_vm._s(child.name))]
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            )
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }