<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"
        clearable
        type="datetimerange"
        style="width: 400px;"
        range-separator="至"
        value-format="yyyy-MM-dd HH:mm:ss"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0, false)">今天</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1, false)">昨天</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7, false)">7天</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30, false)">本月</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60, false)">上月</el-button>
    </div>
    <div class="filter-container">
      <el-input
        v-model.trim="search.couponId"
        maxlength="20"
        clearable
        :placeholder="$t('卡券ID')"
        class="filter-item"
        style="width: 180px"
      />
      <el-input
        v-model.trim="search.id"
        maxlength="20"
        clearable
        :placeholder="$t('请输入抵用券子ID')"
        class="filter-item"
        style="width: 180px"
      />
      <el-input
        v-model.trim="search.memberName"
        maxlength="20"
        clearable
        :placeholder="$t('请输入会员账号')"
        class="filter-item"
        style="width: 180px"
      />
      <el-input
        v-model.trim="search.memberId"
        maxlength="20"
        clearable
        :placeholder="$t('请输入会员ID')"
        class="filter-item"
        style="width: 180px"
      />
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
      selection-row
      row-key="id"
      @select="handleSelect"
      @select-all="handleSelect"
    >
      <template #left>
        <div class="filter-container">
          <el-select v-model="search.couponStatus" style="width: 180px" clearable :placeholder="$t('状态')" class="filter-item">
            <el-option label="全部" :value="null" />
            <el-option label="未使用" :value="1" />
            <el-option label="已使用" :value="2" />
            <el-option label="已过期" :value="3" />
            <el-option label="已下架" :value="0" />
          </el-select>
          <el-select v-model="search.couponType" style="width: 180px" clearable :placeholder="$t('卡券类型')" class="filter-item">
            <el-option v-for="(val, key) in couponTypes" :key="key" :value="key" :label="val" />
          </el-select>
          <el-input
            v-model.trim="search.orderNo"
            maxlength="20"
            clearable
            :placeholder="$t('游戏注单号')"
            class="filter-item"
            style="width: 180px"
          />
          <el-select v-model="search.receiveType" clearable :placeholder="$t('领取方式')" class="filter-item" style="width: 180px">
            <el-option v-for="(val, key) in receiveTypes" :key="key" :label="val" :value="key" />
          </el-select>
          <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
          <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
        </div>
      </template>
<!--      <template #right>
        <el-button type="primary" class="filter-item" @click="handleInvalid">{{ $t('作废') }}</el-button>
      </template>-->
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>

<script>
import { getDefaultTimes } from '@/utils'
import { getListMember, couponInvalid } from '@/api/card.js'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { couponTypes, receiveTypes } from '@/libs/options'
// let search = {}
export default {
  name: 'CardCenter',
  mixins: [TablePageMixin(getListMember)],
  data() {
    return {
      times: getDefaultTimes('timestamp', 0, 0, false),
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
      couponTypes,
      receiveTypes,
      selection: []
    }
  },
  methods: {
    onCreate(row) {
      this.$refs.EditDialogControl.open(row, this.$store.getters.tenantCode)
    },
    implementationGetParams() {
      return this.formatIntervalTime()
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    handleSelect(selection) {
      this.selection = selection;
    },
    handleInvalid() {
      if (this.selection.length === 0) {
        return this.$message.error('请选择要作废的卡券')
      }
      this.$confirm(this.$t('确认作废选中的卡券吗?'), '系统提示', { type: 'warning' }).then(() => {
        const couponIds = this.selection.map(e => e.id)
        couponInvalid({ couponIds }).then(([_, err]) => {
          if (!err) {
            this.$message.success('操作成功')
            this.handleFetch()
          }
        })
      })
    }
  }
}
</script>
<style type="scss" scoped>
.filter-container {
  display: flex;
  overflow-wrap: break-word;
  gap: 8px;
}

.filter-container .filter-item+.filter-item {
  margin-left: 0;
}
</style>
