import { getImToken } from "@/utils/auth";
class WebSocketWithHeartbeat {
  constructor(roomId, onMessageCallback, url,isLeft) {
    console.log(url)
    this.url = isLeft? `${url}/receive/${getImToken()}` : `${url}/im/${getImToken({tokenType:0})}/${roomId}`;
    this.reconnectInterval = 5000;
    this.heartbeatInterval = 30000;
    this.socket = null;
    this.isConnected = false;
    this.heartbeatTimer = null;
    this.reconnectTimer = null;
    this.isManualClose = false; // 手动关闭
    this.onMessageCallback = onMessageCallback; // 消息回调函数
    this.connect();
  }
  connect() {
    this.socket = new WebSocket(this.url);
    this.socket.onopen = () => {
      console.log("WebSocket connection");
      this.isConnected = true;
      this.startHeartbeat();
    };

    this.socket.onmessage = (e) => {
      if (e.data instanceof Blob) {
        e.data.arrayBuffer().then((buffer) => {
          this.formatMessage(buffer);
        });
      } else if (e.data instanceof ArrayBuffer) {
        this.formatMessage(e.data);
      }
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    this.socket.onclose = (event) => {
      console.log("WebSocket connection closed:", event.reason);
      this.isConnected = false;
      this.stopHeartbeat();
      this.reconnect();
    };
  }

  reconnect() {
    if (!this.isConnected && !this.reconnectTimer && !this.isManualClose) {
      this.reconnectTimer = setTimeout(() => {
        console.log("Reconnecting WebSocket...");
        this.connect();
        this.reconnectTimer = null;
      }, this.reconnectInterval);
    }
  }

  startHeartbeat() {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
    }
    this.heartbeatTimer = setInterval(() => {
      if (this.isConnected) {
        this.send("PING");
      }
    }, this.heartbeatInterval);
  }

  stopHeartbeat() {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }
  }
  formatMessage(data) {
    if (data) {
      let obj = JSON.parse(new TextDecoder().decode(new Uint8Array(data)));
      this.onMessageCallback && this.onMessageCallback(obj);
    }
  }
  send(data) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(data);
    }
  }
  close() {
    if (this.socket) {
      this.isManualClose = true;
      this.socket.close();
    }
  }
}

export default WebSocketWithHeartbeat;
