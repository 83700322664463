<template>
  <div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <div class="filter-container">
          <el-input v-model.trim="search.phone" clearable :placeholder="$t('请输入手机号或号段')" class="filter-item" style="width: 200px" />
          <el-button type="primary" class="filter-item" icon="el-icon-search" @click="handleRefresh">{{ $t('查询') }}</el-button>
          <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
          <el-button icon="el-icon-plus" type="success" class="filter-item" @click="$refs.ActionDialogControl.open()">{{ $t('添加限制') }}</el-button>
        </div>
      </template>
    </d-table>
    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="_handleCreate" @update="_handleUpdate" />

  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { getLimitPhoneList, createLimitPhone, updateLimitPhone, deleteLimitPhone } from '@/api/interactive'
export default {
  name: 'BlockPhoneTab',
  componentName: 'BlockPhoneTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(getLimitPhoneList)],
  data() {
    return {
      columns: [],
      defaultColumns: GetColumns.call(this),
      search: {}
    }
  },
  methods: {
    _handleDelete({ id, phone }) {
      this.$confirm(this.$t('确定删除{phone}这条限制?', { text: phone }), { type: 'warning' }).then(() => {
        deleteLimitPhone({ id }).then(([_, err]) => {
          if (!err) {
            this.handleFetch()
            this.$message.success(this.$t('删除成功'))
          }
        })
      }).catch(() => {})
    },

    _handleCreate({ field, cancel, close }) {
      createLimitPhone({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleRefresh()
          this.$message.success(this.$t('创建成功'))
        } else {
          cancel()
        }
      })
    },

    _handleUpdate({ field, cancel, close }) {
      updateLimitPhone({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('创建成功'))
        } else {
          cancel()
        }
      })
    },

    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },

    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
