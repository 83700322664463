import Layout from '@/layout'

export default {
  path: '/Announcement',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Announcement',
  alwaysShow: true,
  meta: { title: '通知公告', icon: 'announcement' },
  children: [
    {
      path: '/Announcement_Home',
      component: () => import('@/views/announcement/home'),
      name: 'AnnouncementHome',
      meta: { title: '首页公告', noCache: true }
    },
    {
      path: '/Announcement_Station_Letter',
      component: () => import('@/views/announcement/stationLetter'),
      name: 'AnnouncementStationLetter',
      meta: { title: '发送站内信' }
    },
    {
      path: '/Announcement_Brief',
      component: () => import('@/views/announcement/Brief'),
      name: 'AnnouncementBrief',
      meta: { title: '简讯管理' }
    }
  ]
}

