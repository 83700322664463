<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane
          v-for="tab of permissionTabs"
          :key="tab.name"
          :label="tab.label"
          :name="tab.name"
        />
      </el-tabs>
      <component :is="activeName" class="basic_config" />
    </template>
  </div>
</template>

<script>
import { parentMixin } from '@/mixins/tab-items'
import OrderManage_RulesConfig_index from './components/OrderManage_RulesConfig_index'
import OrderManage_RulesConfig_explain from './components/OrderManage_RulesConfig_explain'
import OrderManage_RulesConfig_ranking from './components/OrderManage_RulesConfig_ranking'
export default {
  components: {
    OrderManage_RulesConfig_index, OrderManage_RulesConfig_explain,OrderManage_RulesConfig_ranking
  },
  mixins: [parentMixin],
  data() {
    return {
      tabs: this.$t('orderManage_config')
    }
  }
}
</script>

<style lang="scss">
.basic_config {
  padding-right: 48px;
  h2 {
    width: 210px;
    text-align: right;
    font-weight: 500;
    font-size: 20px;
    margin-top: 40px;
  }
  // .el-form-item__label{
  //   font-weight: 400;
  // }
  .action-button {
    border-top: 1px dashed #e4e7ed;
    padding-top: 10px;
  }
}
</style>
