var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "数据读取配置",
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "滚球", prop: "gunqiu" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.ruleForm.gunqiu,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "gunqiu", $$v)
                    },
                    expression: "ruleForm.gunqiu",
                  },
                },
                [_vm._v("手动配置（不配置不显示）")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.gunqiu,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "gunqiu", $$v)
                    },
                    expression: "ruleForm.gunqiu",
                  },
                },
                [_vm._v("自动获取（优先手动，无数据读取三方数据）")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "精选", prop: "jingxuan" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.ruleForm.jingxuan,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "jingxuan", $$v)
                    },
                    expression: "ruleForm.jingxuan",
                  },
                },
                [_vm._v("手动配置（不配置不显示）")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.jingxuan,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "jingxuan", $$v)
                    },
                    expression: "ruleForm.jingxuan",
                  },
                },
                [_vm._v("自动获取（优先手动，无数据读取三方数据）")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "即将", prop: "jijiang" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.ruleForm.jijiang,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "jijiang", $$v)
                    },
                    expression: "ruleForm.jijiang",
                  },
                },
                [_vm._v("手动配置（不配置不显示）")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.ruleForm.jijiang,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "jijiang", $$v)
                    },
                    expression: "ruleForm.jijiang",
                  },
                },
                [_vm._v("自动获取（优先手动，无数据读取三方数据）")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("确定")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }