var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { placeholder: _vm.$t("礼物名称"), clearable: "" },
            model: {
              value: _vm.search.giftName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "giftName", $$v)
              },
              expression: "search.giftName",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.handleRefresh },
            },
            [_vm._v(_vm._s(_vm.$t("查 询")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-refresh-right" },
              on: { click: _vm.resetSearch },
            },
            [_vm._v(_vm._s(_vm.$t("重置")))]
          ),
        ],
        1
      ),
      _c(
        "d-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "DTable",
          attrs: {
            data: _vm.items,
            columns: _vm.columns,
            "original-columns": _vm.defaultColumns,
            options: _vm.options,
          },
          on: {
            refresh: _vm.handleFetch,
            "column-change": _vm.handleColumnChange,
          },
        },
        [
          _c(
            "template",
            { slot: "right" },
            [
              _vm.permission.Add
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success", icon: "el-icon-plus" },
                      on: { click: _vm.onCreate },
                    },
                    [_vm._v(_vm._s(_vm.$t("创建礼物")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("ActionDialog", {
        ref: "ActionDialogControl",
        on: { create: _vm._handleCreate, update: _vm._handleUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }