<template>
  <el-card
    shadow="never"
    class="statistics-member"
  >
    <template #header>
      {{ $t('今日更新数据') }}({{ $t('更新时间') }}：{{ resource.updateTime | parseTime }})
      <i style="float: right; padding: 3px 0" class="el-icon-refresh-right cursor-pointer" :class="[ loading && `el-icon-refresh-loading` ]" @click="_get" />
    </template>
    <el-row v-loading="loading" type="flex" :gutter="24" class="statistics-member-card">
      <el-col
        v-for="option in options"
        :key="option.key"
        :span="6"
        class="statistics-member-item"
      >
        <el-card :header="option.label">
          <div class="item-over">{{ resource[option.key] }}</div>
          <div class="item-label">
            <span>{{ option.subLabel }}</span>
            <span>{{ resource[option.subKey] }}人</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import { getHomeStatistic } from '@/api/dashboard'
export default {
  data() {
    return {
      loading: false,
      resource: {},
      options: [
        { label: this.$t('在线人数'), key: 'onlineNum', subLabel: this.$t('新注册在线数'), subKey: 'todayOnlineNum', decimals: false },
        { label: this.$t('注册人数'), key: 'regNum', subLabel: this.$t('注册并充值人数'), subKey: 'regNumAndRecharge', decimals: false },
        { label: this.$t('充值金额'), key: 'payAmt', subLabel: this.$t('充值人数'), subKey: 'payNum' },
        { label: this.$t('提现金额'), key: 'withdrawAmt', subLabel: this.$t('提现人数'), subKey: 'withdrawNum' },
        { label: this.$t('礼物消费'), key: 'giftAmt', subLabel: this.$t('送礼人数'), subKey: 'giftNum' },
        { label: this.$t('房费消费'), key: 'payroomAmt', subLabel: this.$t('付费人数'), subKey: 'payroomNum' },
        { label: this.$t('彩票投注'), key: 'betsAmt', subLabel: this.$t('投注人数'), subKey: 'betsNum' },
        { label: this.$t('游戏投注'), key: 'gameAmt', subLabel: this.$t('投注人数'), subKey: 'gameNum' }
      ]
    }
  },
  created() {
    this._get()
  },
  methods: {
    _get() {
      this.loading = true
      getHomeStatistic().then(([data, err]) => {
        this.loading = false
        if (!err && data) {
          this.resource = data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.statistics-member {
  margin: 20px 0;
  &-card {
    flex-wrap: wrap;
  }
  &-item {
    margin: 10px 0;
    .item-over {
      color: #fe4546;
      font-size: 30px;
      padding-bottom: 20px;
    }
    .item-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #eee;
      font-size: 13px;
      line-height: 22px;
      padding-top: 10px;
    }
  }
}
</style>
