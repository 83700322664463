<template>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="150px">
    <el-row v-loading="loading">
      <el-col>
        <h3>{{ $t('签到任务配置') }}<span class="sign-tips">{{ $t('签到活动按月更新') }}</span></h3>
        <el-form-item :label="$t('签到任务')" prop="checkinStatus">
          <d-switch v-model="ruleForm.checkinStatus" :active-value="1" :inactive-value="0">
            <span slot="open">{{ $t('开启') }}</span>
            <span slot="close">{{ $t('关闭') }}</span>
          </d-switch>
        </el-form-item>
        <el-form-item :label="$t('签到任务分类')" prop="activityType">
          <el-select v-model="ruleForm.activityType" style="width: 180px" :placeholder="$t('请选择任务分类')">
            <el-option
              v-for="item in activityTypeList"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
            />
          </el-select>
          <span class="sign-tips">{{ $t('选择签到任务所属分类') }}</span>
        </el-form-item>
        <h3>{{ $t('可签到条件限制') }}</h3>
        <el-form-item class="tiaojian">
          <el-form-item :label="$t('当日有效投注')" prop="betsAmount">
            <el-input v-model="ruleForm.betsAmount" />
          </el-form-item>
          <el-form-item :label="$t('当日充值次数')" prop="rechargeTimes">
            <el-input v-model="ruleForm.rechargeTimes" />
          </el-form-item>
          <el-form-item :label="$t('当日充值金额')" prop="rechargeAmount">
            <el-input v-model="ruleForm.rechargeAmount" />
          </el-form-item>
        </el-form-item>
        <el-form-item class="tiaojian">
          <el-form-item :label="$t('总打码')" prop="betsTotal">
            <el-input v-model="ruleForm.betsTotal" />
          </el-form-item>
          <el-form-item :label="$t('总充值金额')" prop="rechargeTotal">
            <el-input v-model="ruleForm.rechargeTotal" />
          </el-form-item>
        </el-form-item>
        <h3>{{ $t('签到说明配置') }}</h3>
        <el-form-item>
          <el-radio-group v-model="lang" style="margin-right: 6px;" @change="handleLang">
            <el-radio-button v-for="(item,index) in allLang" :key="index" :label="item.lang">{{ $t(item.name) }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <tinymce v-for="(item ,key) in content" v-show="lang === key" :ref="key" :key="key" v-model="ruleForm[item]" :height="200" />
        </el-form-item>
        <h3>{{ $t('签到奖励配置') }}</h3>
        <el-form-item class="tiaojian">
          <el-table border :data="signInSet1" :header-cell-class-name="getCellClass" :cell-class-name="getCellClass">
            <el-table-column type="index" align="center" width="100">
              <template #header>{{ $t('签到日期') }}</template>
              <template slot-scope="{ row }">{{ row.signInDate }}</template>
            </el-table-column>
            <el-table-column :label="$t('签到奖励')" align="center">
              <template slot-scope="{ row }">
                <el-form-item :label="$t('彩金')" :prop="row.winningField">
                  <el-input v-model="ruleForm[row.winningField]" />
                </el-form-item>
                <el-form-item :label="$t('打码倍数')" :prop="row.typingField">
                  <el-input v-model="ruleForm[row.typingField]" />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
          <el-table border :data="signInSet2" :header-cell-class-name="getCellClass" :cell-class-name="getCellClass">
            <el-table-column type="index" align="center" width="100">
              <template #header>{{ $t('签到日期') }}</template>
              <template slot-scope="{ row }">{{ row.signInDate }}</template>
            </el-table-column>
            <el-table-column :label="$t('签到奖励')" align="center">
              <template slot-scope="{ row }">
                <el-form-item :label="$t('彩金')" :prop="row.winningField">
                  <el-input v-model="ruleForm[row.winningField]" />
                </el-form-item>
                <el-form-item :label="$t('打码倍数')" :prop="row.typingField">
                  <el-input v-model="ruleForm[row.typingField]" />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
          <el-table border :data="signInSet3" :header-cell-class-name="getCellClass" :cell-class-name="getCellClass">
            <el-table-column type="index" align="center" width="100">
              <template #header>{{ $t('签到日期') }}</template>
              <template slot-scope="{ row }">{{ row.signInDate }}</template>
            </el-table-column>
            <el-table-column :label="$t('签到奖励')" align="center">
              <template slot-scope="{ row }">
                <el-form-item :label="$t('彩金')" :prop="row.winningField">
                  <el-input v-model="ruleForm[row.winningField]" />
                </el-form-item>
                <el-form-item :label="$t('打码倍数')" :prop="row.typingField">
                  <el-input v-model="ruleForm[row.typingField]" />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <h3>{{ $t('累计签到奖励配置') }}<span class="sign-tips">{{ $t('满足条件奖励自动发放') }}</span></h3>
        <el-form-item class="tiaojian">
          <el-table border :data="accumulatedSignInSet" class="leiji">
            <el-table-column type="index" align="center" width="100">
              <template #header>{{ $t('累计签到天数') }}</template>
              <template slot-scope="{ row }">{{ row.signInDate }}</template>
            </el-table-column>
            <el-table-column :label="$t('签到奖励')" align="center">
              <template slot-scope="{ row }">
                <el-form-item :label="$t('选择抵用券')" :prop="row.couponField" :placeholder="$t('请选择抵用券')">
                  <el-select v-model="ruleForm[row.couponField]" multiple collapse-tags>
                    <el-option v-for="item of couponList" :key="item.couponId" :value="item.couponId" :label="item.couponTitle" />
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('彩金')" :prop="row.winningField">
                  <el-input v-model="ruleForm[row.winningField]" />
                </el-form-item>
                <el-form-item :label="$t('打码倍数')" :prop="row.typingField">
                  <el-input v-model="ruleForm[row.typingField]" />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item class="action-button">
      <el-button type="danger" :loading="submitting" @click="handleSubmit">{{ $t('保 存') }}</el-button>
      <el-button @click="$refs['ruleForm'].resetFields()">{{ $t('重 置') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import { getCheckinDetail, updateCheckin, getActivityTypeList } from '@/api/activity'
import FormDialogMixin from '@/mixins/form-dialog'
import { getList } from '@/api/card.js'
import { getRuleForm, getRules, getSaveDetailParams, parameterParsing } from './params'
import { mapState } from 'vuex'
export default {
  name: 'SigninConfigTab',
  components: { Tinymce },
  mixins: [FormDialogMixin(getRuleForm)],
  data() {
    return {
      loading: false,
      submitting: false,
      couponList: [],
      activityTypeList: [],
      lang: 'zh_CN',
      content: {
        'zh_CN': 'checkinContentZh',
        'en_US': 'checkinContentEn',
        'vi_VN': 'checkinContentVi',
        'gu_IN': 'checkinContentGu',
        'ja_JP': 'checkinContentJa',
        'ms_MY': 'checkinContentMs'
      },
      signInSet1: [
        { signInDate: this.$t('n号', { n: 1 }), winningField: 'oneAmount', typingField: 'oneRolling' },
        { signInDate: this.$t('n号', { n: 2 }), winningField: 'twoAmount', typingField: 'twoRolling' },
        { signInDate: this.$t('n号', { n: 3 }), winningField: 'threeAmount', typingField: 'threeRolling' },
        { signInDate: this.$t('n号', { n: 4 }), winningField: 'fourAmount', typingField: 'fourRolling' },
        { signInDate: this.$t('n号', { n: 5 }), winningField: 'fiveAmount', typingField: 'fiveRolling' },
        { signInDate: this.$t('n号', { n: 6 }), winningField: 'sixAmount', typingField: 'sixRolling' },
        { signInDate: this.$t('n号', { n: 7 }), winningField: 'sevenAmount', typingField: 'sevenRolling' },
        { signInDate: this.$t('n号', { n: 8 }), winningField: 'eightAmount', typingField: 'eightRolling' },
        { signInDate: this.$t('n号', { n: 9 }), winningField: 'nineAmount', typingField: 'nineRolling' },
        { signInDate: this.$t('n号', { n: 10 }), winningField: 'tenAmount', typingField: 'tenRolling' },
        { signInDate: this.$t('n号', { n: 11 }), winningField: 'elevenAmount', typingField: 'elevenRolling' }
      ],
      signInSet2: [
        { signInDate: this.$t('n号', { n: 12 }), winningField: 'twelveAmount', typingField: 'twelveRolling' },
        { signInDate: this.$t('n号', { n: 13 }), winningField: 'thirteenAmount', typingField: 'thirteenRolling' },
        { signInDate: this.$t('n号', { n: 14 }), winningField: 'fourteenAmount', typingField: 'fourteenRolling' },
        { signInDate: this.$t('n号', { n: 15 }), winningField: 'fifteenAmount', typingField: 'fifteenRolling' },
        { signInDate: this.$t('n号', { n: 16 }), winningField: 'sixteenAmount', typingField: 'sixteenRolling' },
        { signInDate: this.$t('n号', { n: 17 }), winningField: 'seventeenAmount', typingField: 'seventeenRolling' },
        { signInDate: this.$t('n号', { n: 18 }), winningField: 'eighteenAmount', typingField: 'eighteenRolling' },
        { signInDate: this.$t('n号', { n: 19 }), winningField: 'nineteenAmount', typingField: 'nineteenRolling' },
        { signInDate: this.$t('n号', { n: 20 }), winningField: 'twentyAmount', typingField: 'twentyRolling' },
        { signInDate: this.$t('n号', { n: 21 }), winningField: 'twentyoneAmount', typingField: 'twentyoneRolling' },
        { signInDate: this.$t('n号', { n: 22 }), winningField: 'twentytwoAmount', typingField: 'twentytwoRolling' }
      ],
      signInSet3: [
        { signInDate: this.$t('n号', { n: 23 }), winningField: 'twentythreeAmount', typingField: 'twentythreeRolling' },
        { signInDate: this.$t('n号', { n: 24 }), winningField: 'twentyfourAmount', typingField: 'twentyfourRolling' },
        { signInDate: this.$t('n号', { n: 25 }), winningField: 'twentyfiveAmount', typingField: 'twentyfiveRolling' },
        { signInDate: this.$t('n号', { n: 26 }), winningField: 'twentysixAmount', typingField: 'twentysixRolling' },
        { signInDate: this.$t('n号', { n: 27 }), winningField: 'twentysevenAmount', typingField: 'twentysevenRolling' },
        { signInDate: this.$t('n号', { n: 28 }), winningField: 'twentyeightAmount', typingField: 'twentyeightRolling' },
        { signInDate: this.$t('n号', { n: 29 }), winningField: 'twentynineAmount', typingField: 'twentynineRolling' },
        { signInDate: this.$t('n号', { n: 30 }), winningField: 'thirtyAmount', typingField: 'thirtyRolling' },
        { signInDate: this.$t('n号', { n: 31 }), winningField: 'thirtyOneAmount', typingField: 'thirtyOneRolling' }
      ],
      accumulatedSignInSet: [
        { signInDate: this.$t('_7天'), couponField: 'oneWeekCoupanList', winningField: 'oneWeekAmount', typingField: 'oneWeekRolling' },
        { signInDate: this.$t('_14天'), couponField: 'twoWeekCoupanList', winningField: 'twoWeekAmount', typingField: 'twoWeekRolling' },
        { signInDate: this.$t('_21天'), couponField: 'threeWeekCoupanList', winningField: 'threeWeekAmount', typingField: 'threeWeekRolling' },
        { signInDate: this.$t('全勤'), couponField: 'fullCoupanList', winningField: 'fullAmount', typingField: 'fullRolling' }
      ],
      rules: getRules.call(this)
    }
  },
  computed: {
    ...mapState('app', ['allLang'])
  },
  created() {
    this.getCouponList()
    this.getTypeList()
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.loading = true
      getCheckinDetail().then(([data, err]) => {
        this.loading = false
        if (!err && data) {
          parameterParsing.call(this, data)
        }
      })
    },
    getCouponList() {
      getList({ currentPage: 1, pageSize: 100, couponStatus: 1 }).then(([items, err]) => {
        if (!err) {
          this.couponList = items
        }
      })
    },
    getTypeList() {
      getActivityTypeList({ currentPage: 1, pageSize: 100, isShow: 1 }).then(([_, err, data]) => {
        if (!err) {
          const { items } = data.data
          if (items.length > 0) {
            const list = []
            items.forEach(e => {
              const typeNameList = e.typeName.split(',')
              list.push({
                id: e.id,
                typeName: typeNameList[0].split(':')[1],
                typeNameLang: e.typeName
              })
            })
            this.activityTypeList = list
          }
        }
      })
    },
    handleSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this._updateConfig()
        }
      })
    },
    _updateConfig() {
      const params = getSaveDetailParams.call(this, this.ruleForm)
      if (params === false) {
        return
      }
      this.submitting = true
      updateCheckin(params).then(([_, err]) => {
        this.submitting = false
        if (!err) {
          this.$message.success(this.$t('保存成功'))
        } else {
          this.$message.error(err.message)
        }
      })
    },
    getCellClass({ columnIndex }) {
      if (columnIndex === 0) {
        return 'cell-bg'
      }
      return ''
    },
    handleLang() {
      this.$nextTick(() => {
        for (let key in this.content) {
          this.$refs[key].hasInit && this.$refs[key].setContent(this.ruleForm[this.content[key]] || '')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form {
  margin-left: 40px;
}
.sign-tips {
  margin-left: 20px;
  color: #AAAAAA;
  font-weight: 400;
  font-size: 14px;
}
.tiaojian ::v-deep .el-form-item__content {
  margin-left: 0 !important;
  display: flex;
  .el-input__inner {
    width: 80px;
  }
  .el-table {
    width: 33.33%;
    flex: none;
    &.leiji {
      width: 100%;
      max-width: 900px;
    }
    .cell-bg {
      background-color: #f2f2f2;
    }
    .el-select .el-input__inner {
      width: 150px;
    }
  }
  .cell {
    padding-left: 0;
    display: flex;
    justify-content: space-around;
    .el-form-item__label {
      width: auto !important;
    }
    .el-form-item__error {
      display: contents;
    }
    .el-form-item__content {
      flex-direction: column;
    }
  }
}
</style>
