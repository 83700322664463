var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm._handleTabClick },
          model: {
            value: _vm.search.appType,
            callback: function ($$v) {
              _vm.$set(_vm.search, "appType", $$v)
            },
            expression: "search.appType",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "Android版本", name: "1" } }),
          _c("el-tab-pane", { attrs: { label: "IOS版本", name: "2" } }),
        ],
        1
      ),
      _vm.permission.AddVersion
        ? _c(
            "el-button",
            {
              style: { position: "absolute", top: "12px", right: "12px" },
              attrs: { type: "success", icon: "el-icon-plus" },
              on: { click: _vm.onAction },
            },
            [_vm._v("创建版本")]
          )
        : _vm._e(),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.items,
          columns: _vm.columns,
          options: _vm.options,
          "filter-column": _vm.filterColumn,
        },
      }),
      _c("pagination", {
        attrs: {
          page: _vm.params.currentPage,
          limit: _vm.params.pageSize,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.params, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.params, "pageSize", $event)
          },
          pagination: _vm.handleFetch,
        },
      }),
      _c("ActionDialog", {
        ref: "ActionDialogControl",
        on: { create: _vm._handleCreate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }