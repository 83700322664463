const CanChooseColumns = function() {
  return [
    {
      title: '图库名称',
      key: 'pictureName',
      align: 'center'
    },
    {
      title: '评论昵称',
      key: 'nickName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span class='primary-type'>{ row.nickName }</span>)
        }
      }
    },
    {
      title: '评论内容',
      key: 'commentContent',
      headerAlign: 'center',
      minWidth: 300
    },
    {
      title: '评论时间',
      key: 'commentTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.commentTime) }</span>)
        }
      }
    },
    {
      title: '操作',
      align: 'center',
      key: 'actions',
      width: 200,
      component: {
        render: (h, row) => {
          const { Delete } = this.permission

          return (
            Delete &&
            <span>
              <el-button type='warning' size='mini' onClick={this.handleDelete.bind(this, row.id, 1, row.tenantCode)}>删除</el-button>
              <el-button type='danger' size='mini' onClick={this.handleDelete.bind(this, row.id, 2, row.tenantCode)}>删除并限制</el-button>
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return CanChooseColumns.call(this)
}
