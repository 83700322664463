<template>
  <div>
    <el-dialog
      :title="titleObj.title"
      width="800px"
      :visible.sync="dialogFormVisible"
      @close="_close"
    >
      <d-table
        ref="DTable"
        v-loading="loading"
        :data="items"
        :height="500"
        :filter-column="false"
        :columns="columns"
        :original-columns="defaultColumns"
        :options="options"
        @refresh="handleFetch"
        @column-change="handleColumnChange"
      />

      <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <reply ref="replyEle" @submit="submitReply" />
  </div>
</template>

<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import { getTopicReplyList, deleteReply } from '@/api/sun-lap.js'
import reply from './replyDialog.vue'
import GetColumns from './columns'
export default {
  components: { reply },
  mixins: [TablePageMixin(getTopicReplyList, false)],
  data() {
    return {
      defaultColumns: GetColumns.call(this), // 默认表格
      columns: [], // 已选展示项
      dialogFormVisible: false,
      tenantCode: {},
      search: {},
      articleId: '',
      titleObj: {
        title: '',
        type: ''
      }

    }
  },
  computed: {},
  created() {
    this.params.pageSize = 10
  },
  mounted() {

  },
  destroyed() {

  },
  methods: {
    init({ memberName, id }) { // 初始化
      this.dialogFormVisible = true
      this.titleObj.title = `昵称 ${memberName} 的回复`
      this.search.articleId = id
      this.articleId = id
      this.tenantCode = this.$P$ && this.$parent.tenant && { tenantCode: this.$parent.tenant.tenantCode } || {}
      this.handleRefresh()
    },

    _delete({ id, replyNickname }) { // 删评论
      this.$confirm(`确认删除昵称为 ${replyNickname} 的回复？`, { type: 'warning' }).then(() => {
        const params = Object.assign({}, { id }, this.tenantCode)
        deleteReply(params).then(([data, err]) => {
          if (!err) {
            this.handleRefresh()
            this.$message.success(`昵称为 ${replyNickname} 的回复已被删除`)
          }
        })
      })
    },

    _banned({ replyMemberId, replyNickname, replyMemberName }) { // 对评论的用户当前帖子评论禁言
      this.$confirm(`确认禁止昵称为 ${replyMemberName} 的用户对当前帖子禁言？`, { type: 'warning' }).then(() => {
        this.$parent._banned({ memberId: replyMemberId, nickname: replyNickname, memberName: replyMemberName })
        this.dialogFormVisible = false
      })
    },

    _close() {
      this.dialogFormVisible = false
      this.tenantCode = {}
      this.items = []
    },

    _reply(row) {
      this.$refs.replyEle.open(this.articleId, row, this.tenantCode.tenantCode)
    },

    implementationGetParams() {
      return Object.assign({}, { ...this.search }, this.tenantCode)
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    submitReply() {
      this.handleRefresh()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
