var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改后台用户",
        visible: _vm.visible,
        width: "400px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户昵称", required: "", prop: "nickname" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.nickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "nickname", $$v)
                  },
                  expression: "ruleForm.nickname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "头像", prop: "headUrl" } },
            [
              _c("d-upload", {
                attrs: { folder: "head", "on-success": _vm.handleSuccess },
                model: {
                  value: _vm.ruleForm.headUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "headUrl", $$v)
                  },
                  expression: "ruleForm.headUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否删除", prop: "freezeStatus" } },
            [
              _c("d-switch", {
                attrs: { "inactive-value": 0, "active-value": 1 },
                model: {
                  value: _vm.ruleForm.freezeStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "freezeStatus", $$v)
                  },
                  expression: "ruleForm.freezeStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "增加关注人数", prop: "newFansNum" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.newFansNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "newFansNum", _vm._n($$v))
                  },
                  expression: "ruleForm.newFansNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("会员VIP等级"), prop: "vipLevel" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: _vm.$t("请选择会员VIP等级") },
                  on: {
                    change: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                  model: {
                    value: _vm.ruleForm.vipLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "vipLevel", $$v)
                    },
                    expression: "ruleForm.vipLevel",
                  },
                },
                _vm._l(_vm.vipLevels, function (item) {
                  return _c("el-option", {
                    key: item.vipLevel,
                    attrs: {
                      label: "VIP" + item.vipLevel,
                      value: item.vipLevel,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 取消 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }