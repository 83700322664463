<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
    </template>
    <keep-alive><component :is="activeName" /></keep-alive>
  </div>
</template>
<script>
import { parentMixin } from '@/mixins/tab-items'
import ExpertTab from './list'
import LevelTab from './level'

export default {
  name: 'MemberExpert',
  components: { ExpertTab, LevelTab },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      tabs: [
        { label: this.$t('Member_Expert.专家列表'), name: 'ExpertTab' },
        { label: this.$t('Member_Expert.专家等级管理'), name: 'LevelTab' }
      ]
    }
  }
}
</script>
