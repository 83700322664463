var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "clip-warpper",
      class: (_vm.clip && "clip-warpper-success") || "clip-warpper-default",
    },
    [
      _c(
        "span",
        {
          staticClass: "clip-warpper-text",
          class: _vm.classes,
          style: _vm.textStyles,
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleClick($event)
            },
          },
        },
        [_vm._v(_vm._s(_vm.text))]
      ),
      _c(
        "button",
        {
          staticClass: "clip-warpper-button",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleClip($event)
            },
          },
        },
        [_vm._v(_vm._s(_vm.clip ? "已复制" : "复制"))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }