<template>
  <el-dialog
    :title="`${label}${$t('批量设置')}`"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="450px"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item :label="label" prop="value">
        <el-input v-model="ruleForm.value" label="0~99999999" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { $t } from '@/lang'

const titleOptions = {
  'odds': $t('赔率'),
  'minBetsMoney': $t('最小金额'),
  'maxBetsMoney': $t('最大金额')
}

const getDefaultForm = () => ({
  type: 'odds',
  value: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      visible: false,
      rules: {
        value: [
          { required: true, message: this.$t('请输入'), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    label() {
      return titleOptions[this.ruleForm.type]
    }
  },
  methods: {
    open(type) {
      this.ruleForm.type = type
      this.visible = true
    }
  }
}
</script>
