var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-container-item" },
          [
            _c("span", [_vm._v("VIP福利名称：")]),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { maxlength: "20", clearable: "" },
              model: {
                value: _vm.search.name,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "name", $$v)
                },
                expression: "search.name",
              },
            }),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handleSearch },
              },
              [_vm._v(_vm._s(_vm.$t("查询")))]
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { type: "primary", icon: "el-icon-refresh-right" },
                on: { click: _vm.resetSearch },
              },
              [_vm._v(_vm._s(_vm.$t("重置")))]
            ),
          ],
          1
        ),
      ]),
      _c("d-table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "DTable",
        attrs: {
          data: _vm.tableData,
          columns: _vm.defaultColumns,
          "original-columns": _vm.defaultColumns,
          options: _vm.options,
          filterColumn: false,
        },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    attrs: { type: "primary", icon: "el-icon-plus" },
                    on: { click: _vm._onCreate },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("添加")) + " ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("ActionDialog", {
        ref: "ActionDialogControl",
        on: { create: _vm.handleCreateLevel, update: _vm.handleUpdateLevel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }