var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.diaTitle,
        visible: _vm.visible,
        width: "800px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "聊天室分类", required: "", prop: "roomType" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: { placeholder: "请选择聊天室类型" },
                  on: { change: _vm._getChatRoomList },
                  model: {
                    value: _vm.ruleForm.roomType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "roomType", $$v)
                    },
                    expression: "ruleForm.roomType",
                  },
                },
                _vm._l(_vm.roomTypeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "聊天室名称", prop: "roomId" } },
            [
              _c(
                "el-select",
                {
                  key: _vm.componentKey,
                  staticClass: "filter-item",
                  attrs: { placeholder: "请选择聊天室名称" },
                  on: { change: _vm._changeRoom },
                  model: {
                    value: _vm.ruleForm.roomId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "roomId", $$v)
                    },
                    expression: "ruleForm.roomId",
                  },
                },
                _vm._l(_vm.roomIdList, function (item) {
                  return _c("el-option", {
                    key: item.roomId,
                    attrs: { label: item.roomName, value: item.roomId },
                  })
                }),
                1
              ),
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  " 提示：只选择聊天室分类不选择聊天室时默认添加分类下所有聊天室 "
                ),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.add } },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.roomList, "max-height": "250" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "roomTypeName", label: "聊天室类型", width: "250" },
          }),
          _c("el-table-column", {
            attrs: { prop: "roomName", label: "聊天室名称", width: "250" },
          }),
          _c("el-table-column", {
            attrs: { prop: "opretor", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.goOut(scope.row)
                          },
                        },
                      },
                      [_vm._v("踢出")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 取消 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }