var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "数据管理",
        visible: _vm.visible,
        width: "90%",
        top: "2vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.tabs, function (tab) {
              return _c("el-tab-pane", {
                key: tab.name,
                attrs: { label: tab.label, name: tab.name },
              })
            }),
            1
          ),
          _vm.refresh
            ? _c(
                "keep-alive",
                [
                  _c(_vm.activeName, {
                    ref: "dataRef",
                    tag: "component",
                    staticStyle: { height: "70vh", "overflow-y": "auto" },
                    attrs: {
                      "channel-ids": _vm.channelIds,
                      "category-ids": _vm.categoryIds,
                      "category-union-id": _vm.categoryUnionId,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("取消")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("提交")) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }