import Layout from '@/layout'

export default {
  path: '/Activity',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Activity',
  alwaysShow: true,
  meta: { title: '活动设置', icon: 'activity' },
  children: [
    {
      path: '/Activity_Advertising',
      component: () => import('@/views/activity/advertising'),
      name: 'ActivityAdvertising',
      meta: { title: '广告图', noCache: true }
    },
    {
      path: '/Activity_Config',
      component: () => import('@/views/activity/Config'),
      name: 'ActivityConfig',
      meta: { title: '活动配置', noCache: true }
    },
    {
      path: '/Activity_RedTab_Record',
      component: () => import('@/views/activity/Config/RedTab/Record'),
      name: 'ActivityRedTabRecord',
      meta: { title: '活动红包记录', noCache: true, activeMenu: '/Activity_Config' },
      hidden: true
    },
    {
      path: '/Activity_Awards_Record',
      component: () => import('@/views/activity/Config/NewConfigTab/Record'),
      name: 'ActivityAwardsRecord',
      meta: { title: '活动奖励记录', noCache: true, activeMenu: '/Activity_Config' },
      hidden: true
    },
    {
      path: '/Activity_ChatRed',
      component: () => import('@/views/activity/ChatRed'),
      name: 'ActivityChatRed',
      meta: { title: '聊天室红包', noCache: true }
    },
    {
      path: '/Activity_Task',
      component: () => import('@/views/activity/Task'),
      name: 'ActivityTask',
      meta: { title: '任务管理', noCache: true }
    }
  ]
}

