var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.$t("修改用户等级"), visible: _vm.visible },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("用户等级"), prop: "vipLevel" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: _vm.$t("请选择会员等级") },
                  model: {
                    value: _vm.ruleForm.vipLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "vipLevel", $$v)
                    },
                    expression: "ruleForm.vipLevel",
                  },
                },
                _vm._l(_vm.Levels, function (item) {
                  return _c("el-option", {
                    key: item.vipLevel,
                    attrs: {
                      label: "VIP" + item.vipLevel,
                      value: item.vipLevel,
                      disabled: _vm.ruleForm.vipLevel === item.vipLevel,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("锁定状态"), prop: "vipStatus" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.vipStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "vipStatus", $$v)
                    },
                    expression: "ruleForm.vipStatus",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("锁定"))),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("不锁定"))),
                  ]),
                ],
                1
              ),
              _c("p", { staticClass: "error-type" }, [
                _vm._v(_vm._s(_vm.$t("锁定后"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }