// 域名二维码

import request from '@/plugins/axios'
// 查询
export function getList(params = {}) {
  return request({
    url: '/getDomainQrCodeList',
    method: 'get',
    params
  })
}

// 新增
export const addInfo = data => {
  return request({
    url: '/createDomainQrCode',
    method: 'post',
    data
  })
}
// 修改
export const updateInfo = data => {
  return request({
    url: '/updateDomainQrCode',
    method: 'post',
    data
  })
}

// 删除
export function delInfo(params = {}) {
  return request({
    url: '/deleteDomainQrCode',
    method: 'get',
    params
  })
}

// 二维码文本读取
export const getDomainTypeDesc = data => {
  return request({
    url: '/getDomainTypeDesc',
    method: 'post',
    data
  })
}
// 二维码文本编辑
export const createDomainTypeDesc = data => {
  return request({
    url: '/createDomainTypeDesc',
    method: 'post',
    data
  })
}
