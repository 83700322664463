<template>
  <el-dialog
    :title="$t('开奖方式修改')"
    width="600px"
    :visible.sync="visible"
    :close-on-click-modal="false"
    @open="onOpen"
  >
    <el-form
      ref="validateForm"
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item :label="$t('彩种类型')">
        <div>
          {{ ruleForm.lotteryType == 10 ? $t("官方开奖") : $t("独立开奖") }}
        </div>
      </el-form-item>
      <el-form-item :label="$t('开奖获取')" prop="lotteryMethod">
        <!-- lotteryType 10是官彩 20是私彩 -->
        <el-radio-group v-model="ruleForm.lotteryMethod">
          <el-radio
            v-for="(label, key) in $parent.methods"
            v-show="
              (ruleForm.lotteryType == 20 && label != $t('平台开奖')) ||
                label == $t('平台开奖')
            "
            :key="key"
            :label="key * 1"
          >{{ label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="isKillMethod">
        <template v-if="$parent.operatorType === 1">
          <el-form-item :label="$t('开启规则')" prop="killNumberParamer1">
            <el-input
              v-model="ruleForm.killNumberParamer1"
              placeholder="1~7"
            />{{ $t("日投注收益") }}
          </el-form-item>
          <el-form-item prop="killNumberParamer2">
            {{ $t("最低的")
            }}<el-input
              v-model.number="ruleForm.killNumberParamer2"
              placeholder="1~99"
            />{{ $t("家") }}
          </el-form-item>
        </template>
        <el-form-item v-else :label="$t('开启规则')" prop="killNumberParamer2">
          {{ $t("_7日盈利为7日投注额度的") }}
          <el-input
            v-model="ruleForm.killNumberParamer2"
            :placeholder="$t('请输入0-100的数，且最多有两位小数!')"
            style="width: 140px; margin: 0 6px"
          />
          %
        </el-form-item>
        <el-form-item :label="$t('开奖要求')" prop="killNumberParamer3">
          {{ $t('投注100最低可中') }}
          <el-input v-model="ruleForm.killNumberParamer3" placeholder="0-99999" style="width: 140px; margin: 0 6px" />
          %
        </el-form-item>
        <el-form-item prop="killNumberParamer4">
          {{ $t('投注100最高可中') }}
          <el-input v-model="ruleForm.killNumberParamer4" placeholder="0-99999" style="width: 140px; margin: 0 6px" /> %
        </el-form-item>
        <el-form-item prop="killNumberParamer5">
          {{ $t("杀号时间最多")
          }}<el-input-number
            v-model="ruleForm.killNumberParamer5"
            :min="5"
            :max="3600"
            :step="5"
            step-strictly
            style="width: 140px; margin: 0 6px"
          />秒
        </el-form-item>
        <el-form-item prop="killSwitch" :label="$t('指定用户杀号')">
          <d-switch v-model="ruleForm.killSwitch" :active-value="1" :inactive-value="0">
            <span slot="open">{{ $t('开启') }}</span>
            <span slot="close">{{ $t('关闭') }}</span>
          </d-switch>
        </el-form-item>
        <el-form-item v-if="ruleForm.killSwitch === 1" prop="memberName" :label="$t('杀号用户')">
          <el-input v-model="ruleForm.memberName" @input="ruleForm.memberId = ''" maxlength="20" placeholder="请输入用户账号" style="width: 200px;">
            <el-button type="primary" :loading="queryLoading" slot="append" @click="getMember()">查询</el-button>
          </el-input>
          {{ this.ruleForm.memberId }}
        </el-form-item>
      </template>
      <template v-if="isPlatformMethod">
        <div
          v-if="ruleForm.platformLotteryMethod == 100"
          class="platformTipText"
        >
          当前平台开奖方式是接口开奖
        </div>
        <div
          v-if="ruleForm.platformLotteryMethod == 200"
          class="platformTipText"
        >
          当前平台开奖方式是平台开奖，平台将随机开奖
        </div>
        <div
          v-if="ruleForm.platformLotteryMethod == 201"
          class="platformTipText"
        >
          当前平台开奖方式是杀号开奖，平台会根据所有租户订单进行统一杀号开奖
        </div>
      </template>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t("取消") }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">{{ $t("确定") }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getProfitStatic } from '@/api/lottery'
import FormDialogMixin from '@/mixins/form-dialog'
import { killNumberUserByName } from "@/api/lottery";

const getDefaultForm = () => ({
  lotteryId: null,
  lotteryType: null,
  lotteryMethod: null,
  killNumberParamer1: '',
  killNumberParamer2: '',
  killNumberParamer3: '',
  killNumberParamer4: '',
  killNumberParamer5: '',
  killSwitch: 0,
  memberId: '',
  memberName: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validparamer2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入')))
      } else {
        if (this.ruleForm.lotteryMethod === 301) {
          if (!/^((\d|[1-9]\d)(\.\d{1,3})?|100(\.0{1,3})?)$/.test(value)) {
            callback(new Error(this.$t('请输入0~100的数，且最多有3位小数')))
          }
        } else if (this.ruleForm.lotteryMethod === 201) {
          if (!/^([1-9]|[1-9]\d)$/.test(value)) {
            callback(new Error(this.$t('请输入1~99的数')))
          }
        }
        callback()
      }
    }

    const validparamer3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入')))
      } else {
        if (!/^(?!00)(?:[0-9]{1,5})$/.test(value)) {
          callback(new Error(this.$t('请输入0~99999的数，且最多有3位小数')))
        } else if (this.ruleForm.killNumberParamer4 !== '') {
          this.$refs.validateForm.validateField('killNumberParamer4')
        }
        callback()
      }
    }

    const validparamer4 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请输入')))
      } else {
        if (!/^(?!00)(?:[0-9]{1,5})$/.test(value)) {
          callback(new Error(this.$t('请输入0~99999的数，且最多有3位小数')))
        } else if (value < this.ruleForm.killNumberParamer3) {
          callback(new Error(this.$t('最高可中金额需大于最低可中金额')))
        }
        callback()
      }
    }

    return {
      loading: false,
      params: {},
      emitName: 'update',
      queryLoading: false,
      rules: {
        killNumberParamer1: [
          { required: true, messages: this.$t('请输入'), trigger: 'blur' },
          {
            pattern: /^[1-7]$/,
            message: this.$t('请输入1~7的数值'),
            trigger: 'blur'
          }
        ],
        killNumberParamer2: [{ validator: validparamer2, trigger: 'blur' }],
        killNumberParamer3: [{ validator: validparamer3, trigger: 'blur' }],
        killNumberParamer4: [{ validator: validparamer4, trigger: 'blur' }]
      }
    }
  },
  computed: {
    isKillMethod() {
      return (
        this.ruleForm.lotteryMethod === 201 ||
        this.ruleForm.lotteryMethod === 301
      )
    },
    isPlatformMethod() {
      return (
        this.ruleForm.lotteryMethod === 100 ||
        this.ruleForm.lotteryMethod === 200
      )
    }
  },
  methods: {
    open(lotteryId) {
      this.params.lotteryId = lotteryId
      this.visible = true
    },
    onOpen() {
      this.loading = true
      getProfitStatic({ ...this.params }, this.$parent.operatorType).then(
        ([data, err]) => {
          this.loading = false
          if (!err) {
            this.ruleForm = data
            for (let key in this.ruleForm) {
              this.ruleForm[key] = data[key]
            }
          }
        }
      )
    },
    getMember() {
      this.queryLoading = true
      killNumberUserByName({ memberName: this.ruleForm.memberName }).then(([data, err]) => {
        if (!err) {
          if (data) {
            this.ruleForm.memberId = data.memberId
          } else {
            this.$message.error('用户账号不存在')
          }
        }
      }).finally(() => {
        this.queryLoading = false
      })
    },
    handleSubmitBefor() {
      if (this.ruleForm.killSwitch === 1) {
        if (!this.ruleForm.memberName) {
          return this.$message.error('请输入杀号用户查询')
        }
        if (!this.ruleForm.memberId) {
          return this.$message.error('请先查询，以确认杀号用户')
        }
      }
      this.handleSubmit()
    }
  }
}
</script>
<style lang="scss" scoped>
.platformTipText {
  color: red;
  margin-left: 120px;
  font-size: 12px;
}
</style>
