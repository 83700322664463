var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "730px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("支付名称"), prop: "accountName" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                model: {
                  value: _vm.ruleForm.accountName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "accountName", $$v)
                  },
                  expression: "ruleForm.accountName",
                },
              }),
              _c("span", { staticClass: "validate-tip" }, [
                _vm._v(
                  _vm._s(_vm.$t("便于区分和记忆，用于前端配置收款账户使用"))
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("支付方式图标"), prop: "paywayId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: { placeholder: _vm.$t("请选择收款方式") },
                  model: {
                    value: _vm.ruleForm.paywayId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "paywayId", $$v)
                    },
                    expression: "ruleForm.paywayId",
                  },
                },
                _vm._l(_vm.paymentWayConfList, function (item) {
                  return _c("el-option", {
                    key: item.paywayId,
                    attrs: { label: item.paywayName, value: item.paywayId },
                  })
                }),
                1
              ),
              _c("span", { staticClass: "validate-tip" }, [
                _vm._v(_vm._s(_vm.$t("前端展示图标"))),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("类型"), prop: "receivingTypeId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: { placeholder: _vm.$t("请选择类型") },
                  on: { change: _vm.onChangeType },
                  model: {
                    value: _vm.ruleForm.receivingTypeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "receivingTypeId", $$v)
                    },
                    expression: "ruleForm.receivingTypeId",
                  },
                },
                _vm._l(_vm.receivingTypes, function (value, type) {
                  return _c("el-option", {
                    key: type,
                    attrs: { label: value, value: type },
                  })
                }),
                1
              ),
              _c("span", { staticClass: "validate-tip" }, [
                _vm._v(_vm._s(_vm.$t("数字货币钱包类型"))),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("地址"), prop: "receivingCardNo" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                model: {
                  value: _vm.ruleForm.receivingCardNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "receivingCardNo", $$v)
                  },
                  expression: "ruleForm.receivingCardNo",
                },
              }),
              _c("span", { staticClass: "validate-tip" }, [
                _vm._v(_vm._s(_vm.$t("数字货币钱包地址"))),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("充值金额类型"), prop: "isType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: { placeholder: _vm.$t("请选择充值金额类型") },
                  model: {
                    value: _vm.ruleForm.isType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "isType", $$v)
                    },
                    expression: "ruleForm.isType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: _vm.$t("充值范围金额"), value: 0 },
                  }),
                  _c("el-option", {
                    attrs: { label: _vm.$t("充值固定金额"), value: 1 },
                  }),
                ],
                1
              ),
              _c("span", { staticClass: "validate-tip" }, [
                _vm._v(_vm._s(_vm.$t("收款账户分配到那种方式"))),
              ]),
            ],
            1
          ),
          _vm.ruleForm.isType == 0
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("最低额度"), prop: "minAmount" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "240px" },
                      attrs: { placeholder: _vm.$t("默认100") },
                      model: {
                        value: _vm.ruleForm.minAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "minAmount", $$v)
                        },
                        expression: "ruleForm.minAmount",
                      },
                    }),
                    _c("span", { staticClass: "validate-tip" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("当前收款账户支持的最低充值金额，默认为100元")
                        )
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("最高额度"), prop: "maxAmount" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "240px" },
                      model: {
                        value: _vm.ruleForm.maxAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "maxAmount", $$v)
                        },
                        expression: "ruleForm.maxAmount",
                      },
                    }),
                    _c("span", { staticClass: "validate-tip" }, [
                      _vm._v(_vm._s(_vm.$t("当前收款账户收款最高额度"))),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.ruleForm.isType == 1
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: _vm.$t("固定额度"), prop: "periodAmount" },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "240px" },
                      model: {
                        value: _vm.ruleForm.periodAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "periodAmount", $$v)
                        },
                        expression: "ruleForm.periodAmount",
                      },
                    }),
                    _c("span", { staticClass: "validate-tip" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            '当前收款账户支持的固定金额，多个金额以","隔开'
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("上限额度"), prop: "limitAccount" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { placeholder: _vm.$t("设置0为不限制") },
                model: {
                  value: _vm.ruleForm.limitAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "limitAccount", $$v)
                  },
                  expression: "ruleForm.limitAccount",
                },
              }),
              _c("span", { staticClass: "validate-tip" }, [
                _vm._v(_vm._s(_vm.$t("当前收款账户收款上限额度"))),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("赠送比例"), prop: "givePercent" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "240px" },
                  attrs: { placeholder: "0-100" },
                  model: {
                    value: _vm.ruleForm.givePercent,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "givePercent", _vm._n($$v))
                    },
                    expression: "ruleForm.givePercent",
                  },
                },
                [_c("template", { slot: "suffix" }, [_vm._v("%")])],
                2
              ),
              _c("span", { staticClass: "validate-tip" }, [
                _vm._v(
                  _vm._s(_vm.$t("按照充值金额得比例额外赠送给用户的金额"))
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("序号"), prop: "sort" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sort", _vm._n($$v))
                  },
                  expression: "ruleForm.sort",
                },
              }),
              _c("span", { staticClass: "validate-tip" }, [
                _vm._v(_vm._s(_vm.$t("数值越小，排序越靠前"))),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("VIP等级"), prop: "vipLevel" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: { multiple: "", "collapse-tags": "" },
                  model: {
                    value: _vm.ruleForm.vipLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "vipLevel", $$v)
                    },
                    expression: "ruleForm.vipLevel",
                  },
                },
                _vm._l(_vm.vipLevels, function (item) {
                  return _c("el-option", {
                    key: item.vipLevel,
                    attrs: {
                      label: "VIP" + item.vipLevel,
                      value: item.vipLevel,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("货币种类"), prop: "rateType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: {
                    "collapse-tags": "",
                    placeholder: _vm.$t("请选择货币种类"),
                  },
                  model: {
                    value: _vm.ruleForm.rateType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "rateType", $$v)
                    },
                    expression: "ruleForm.rateType",
                  },
                },
                _vm._l(_vm.rateTypes, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("是否启用"), prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("启用"))),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("不启用"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }