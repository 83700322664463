var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "400px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "所属租户", prop: "tenantCode" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择租户" },
                  model: {
                    value: _vm.ruleForm.tenantCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "tenantCode", $$v)
                    },
                    expression: "ruleForm.tenantCode",
                  },
                },
                _vm._l(_vm.$parent.tenants, function (item) {
                  return _c("el-option", {
                    key: item.tenantCode,
                    attrs: { label: item.tenantName, value: item.tenantCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "线路名称", prop: "domainType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "域名类型" },
                  model: {
                    value: _vm.ruleForm.domainType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "domainType", $$v)
                    },
                    expression: "ruleForm.domainType",
                  },
                },
                _vm._l(_vm.domainTypes, function (val, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: val, value: +key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "线路名称", prop: "domainName" } },
            [
              _c("el-input", {
                attrs: { maxlength: "10", placeholder: "请输入线路名称" },
                model: {
                  value: _vm.ruleForm.domainName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "domainName", $$v)
                  },
                  expression: "ruleForm.domainName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "域名", prop: "domainUrl" } },
            [
              _c("el-input", {
                attrs: { placeholder: "域名格式必须以http或https开头" },
                model: {
                  value: _vm.ruleForm.domainUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "domainUrl", $$v)
                  },
                  expression: "ruleForm.domainUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "线路状态" } },
            [
              _c(
                "d-switch",
                {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                },
                [
                  _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                    _vm._v("启用"),
                  ]),
                  _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                    _vm._v("禁用"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "前端排序", prop: "sort" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入前端排序", type: "number" },
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sort", $$v)
                  },
                  expression: "ruleForm.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 4, maxRows: 6 },
                },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }