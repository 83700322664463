import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: '会员昵称',
      key: 'nickname',
      align: 'center'
    },
    {
      title: '会员账号',
      key: 'memberName',
      align: 'center'
    },
    {
      title: '会员等级',
      key: 'vipLevel',
      align: 'center'
    },
    {
      title: '会员余额',
      key: 'balance',
      align: 'center'
    },
    {
      title: '进入房间时间',
      key: 'enteringRoomTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.enteringRoomTime) }</span>)
        }
      }
    },
    {
      title: '当前房费金额',
      key: 'payroomAmount',
      align: 'center'
    },
    {
      title: '当前投注金额',
      key: 'betsAmount',
      align: 'center'
    },
    {
      title: '当前送礼金额',
      key: 'giftAmount',
      align: 'center'
    },
    {
      title: '行为限制',
      align: 'center',
      key: 'actions',
      width: 200,
      component: {
        render: (h, row) => {
          return (
            <span>
              <el-button type='warning' size='mini' onClick={() => this.$refs.DebarDialogControl.open(row)}>禁入</el-button>
              <el-button type='danger' size='mini' onClick={() => this.$refs.ForbiddenDialogControl.open(row)}>禁言</el-button>
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
