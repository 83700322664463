import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('房间主题'),
      key: 'roomName',
      align: 'center'
    },
    {
      title: this.$t('彩种类型'),
      key: 'lottypeId',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.livePreLotteryType.find(e => e.key === row.lottypeId)?.label }</span>)
        }
      }
    },
    {
      title: this.$t('彩种/赛事'),
      key: 'lotteryName',
      align: 'center'
    },
    {
      title: this.$t('分辨率'),
      key: 'displayStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.displayStatusList[row.displayStatus] }</span>)
        }
      }
    },
    {
      title: this.$t('主播昵称'),
      key: 'nickName',
      align: 'center'
    },
    {
      title: this.$t('所属家族'),
      key: 'familyName',
      align: 'center'
    },
    {
      title: this.$t('频道'),
      key: 'channelId',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.channelId) {
            const channelIdList = row.channelId.split(',')
            return (<span>{ this.channelList.filter(e => channelIdList.includes(e.labelId)).map(e => { return e.label }).toString() }</span>)
          }
          return (<span></span>)
        }
      }
    },
    {
      title: this.$t('开始直播时间'),
      key: 'liveStartTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.liveStartTime) }</span>)
        }
      }
    },
    {
      title: this.$t('预约状态'),
      key: 'liveStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.preStatus.find(e => e.key === row.liveStatus)?.label }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 280,
      component: {
        render: (h, row) => {
          const { Update, Detail, Cancel, Start } = this.permission
          return (
            <span>
              { Update && row.liveStatus === 1 &&
                <el-button
                  type='primary'
                  size='mini'
                  onClick={() => this.$refs.EditDialogControl.open(row, false)}>{this.$t('房间编辑')}</el-button>
              }
              { Detail && (row.liveStatus === 2 || row.liveStatus === 3) &&
                <el-button
                  type='primary'
                  size='mini'
                  onClick={() => this.$refs.EditDialogControl.open(row, true)}>{this.$t('查看详情')}</el-button>
              }
              { Cancel && row.liveStatus === 1 &&
                <el-button
                  type='danger'
                  size='mini'
                  onClick={this.handleCancelLive.bind(this, row)}
                >{this.$t('取消直播')}</el-button>
              }
              { Start && row.liveStatus === 1 &&
                <el-button
                  type='warning'
                  size='mini'
                  onClick={this.handleStartlLive.bind(this, row)}
                >上播</el-button>
              }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
