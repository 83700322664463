<template>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="240px">
    <el-row v-for="(item,index) in ruleForm.rateTypes" :key="index" v-loading="loading" class="basic_config_content">
      <el-col :span="14">
        <el-form-item :label="item.label" :rules="rules.rate" :prop="`rateTypes.${index}.rate`">
          <el-input v-model="item.rate" :placeholder="$t('输入0.001~99999999 输入汇率x平台币=当前货币金额')">
            <template slot="append">
              <d-switch v-model="item.isOpen" :active-value="0" :inactive-value="1">
                <span slot="open">{{ $t('开启') }}</span>
                <span slot="close">{{ $t('关闭') }}</span>
              </d-switch>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item class="action-button">
      <el-button type="danger" :loading="submitting" @click="handleSubmit">{{ $t('保 存') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getRateConfig, editRateConfig } from '@/api/config.js'
import { rateTypes } from '@/libs/options'

export default {
  name: 'CarshTab',
  componentName: 'CarshTab',
  data() {
    const validateRate = (rule, value, callback) => {
      let rate = parseFloat(value)
      if (isNaN(rate)) { rate = 0 }
      if (rate < 0.001 || rate > 99999999) { callback(new Error('0.001-99999999')) }
    }
    return {
      loading: false,
      submitting: false,
      ruleForm: {
        rateTypes: rateTypes
      },
      rules: {
        rate: [
          { required: true, message: this.$t('请输入'), trigger: 'blur' },
          { validator: validateRate, trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.loading = true
      getRateConfig().then(([data, err]) => {
        this.loading = false
        if (!err) {
          if (data) {
            this.ruleForm.rateTypes.forEach(e => {
              const item = data.find(v => v.rateType === e.key)
              if (item) {
                e.rate = item.typeRate
                e.isOpen = item.isOpen
              }
            })
          }
        }
      })
    },
    handleSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        // if (valid) this._updateConfig()
      })
      this._updateConfig()
    },
    _updateConfig() {
      this.submitting = true
      const params = []
      let isValidate = false
      this.ruleForm.rateTypes.forEach(e => {
        let rate = parseFloat(e.rate)
        if (isNaN(rate)) { rate = 0 }
        if (rate < 0.001 || rate > 99999999) {
          isValidate = true
          return false
        }
        params.push({ rateType: e.key, typeRate: e.rate, isOpen: e.isOpen })
      })
      if (isValidate) {
        this.submitting = false
        return
      }
      params.forEach((e, index) => {
        editRateConfig(e).then(([_, err]) => {
          if (index === params.length - 1) {
            this.submitting = false
            if (!err) {
              this.$message.success(this.$t('保存成功'))
            }
          }
        })
      })
    }
  }
}
</script>
