var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "1330px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动形式", prop: "redpacketType" } },
            _vm._l(_vm.$parent.redpacketType, function (val, key) {
              return _c(
                "el-radio",
                {
                  key: key,
                  attrs: {
                    disabled: _vm.emitName === "update" || _vm.disabled,
                    label: Number(key),
                  },
                  model: {
                    value: _vm.ruleForm.redpacketType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "redpacketType", $$v)
                    },
                    expression: "ruleForm.redpacketType",
                  },
                },
                [_vm._v(_vm._s(val))]
              )
            }),
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动标题", prop: "redpacketTitle" } },
            [
              _c("el-input", {
                staticStyle: { width: "500px" },
                attrs: {
                  maxlength: "6",
                  disabled: _vm.disabled,
                  placeholder: "6个字以内",
                },
                model: {
                  value: _vm.ruleForm.redpacketTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "redpacketTitle", $$v)
                  },
                  expression: "ruleForm.redpacketTitle",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动分类", prop: "activityTypeId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择活动分类",
                    clearable: "",
                  },
                  model: {
                    value: _vm.ruleForm.activityTypeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "activityTypeId", $$v)
                    },
                    expression: "ruleForm.activityTypeId",
                  },
                },
                _vm._l(_vm.$parent.activityCategory, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.typeName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动引导说明", prop: "redpacketContent" } },
            [
              _c("el-input", {
                staticStyle: { width: "500px" },
                attrs: {
                  disabled: _vm.disabled,
                  maxlength: "50",
                  placeholder: "50个字以内",
                },
                model: {
                  value: _vm.ruleForm.redpacketContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "redpacketContent", $$v)
                  },
                  expression: "ruleForm.redpacketContent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动时间", prop: "redpacketTime" } },
            [
              _c(
                "div",
                { attrs: { flex: "main:center" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "redpacketTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "daterange",
                          "value-format": "timestamp",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "default-time": ["00:00:00", "23:59:59"],
                        },
                        model: {
                          value: _vm.ruleForm.redpacketTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "redpacketTime", $$v)
                          },
                          expression: "ruleForm.redpacketTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "receipts", "label-width": "30px" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.ruleForm.receipts,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "receipts", $$v)
                            },
                            expression: "ruleForm.receipts",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 1, label: "每天限领1次" },
                          }),
                          _c("el-option", {
                            attrs: { value: 0, label: "仅领取1次" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.ruleForm.redpacketType === 3
            ? _c(
                "el-form-item",
                { attrs: { label: "开启时间", prop: "redpacketTime" } },
                [
                  _c(
                    "div",
                    { attrs: { flex: "main:center" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "showTime" } },
                        [
                          _c("el-time-picker", {
                            staticStyle: { width: "281px" },
                            attrs: {
                              disabled: _vm.disabled,
                              "is-range": "",
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              format: "HH:mm",
                              placeholder: "选择开启时间",
                            },
                            model: {
                              value: _vm.ruleForm.showTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "showTime", $$v)
                              },
                              expression: "ruleForm.showTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "showSeconds",
                            label: "下雨时长",
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "120px" },
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.ruleForm.showSeconds,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "showSeconds",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "ruleForm.showSeconds",
                              },
                            },
                            [_c("template", { slot: "append" }, [_vm._v("S")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "60px" },
              attrs: { label: "红包金额", prop: "robAmtMin" },
            },
            [
              _c("div", { staticClass: "robvip-range-wrapper" }, [
                _c(
                  "div",
                  { attrs: { flex: "cross:center" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "robAmtMin" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.ruleForm.robAmtMin,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "robAmtMin", $$v)
                            },
                            expression: "ruleForm.robAmtMin",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", { staticStyle: { padding: "0 5px" } }, [
                      _vm._v("~"),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "robAmtMax" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.ruleForm.robAmtMax,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "robAmtMax", $$v)
                            },
                            expression: "ruleForm.robAmtMax",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "robvip-range" },
                  [
                    _c("el-form-item", { attrs: { "label-width": "0" } }, [
                      _c(
                        "div",
                        { staticClass: "robvip-range-list" },
                        _vm._l(_vm.ruleForm.items, function (item, index) {
                          return _c(
                            "div",
                            { key: item.key, staticClass: "robvip-range-item" },
                            [
                              _vm.ruleForm.items.length > 1
                                ? _c("i", {
                                    staticClass:
                                      "el-icon-circle-close robvip-range-item__icon",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteItems(index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "robvip-range-item__child" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "items." + index + ".vipBegin",
                                        "show-message": false,
                                        rules: [
                                          {
                                            required: true,
                                            message: "此项必填",
                                            trigger: ["change", "blur"],
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _vm.ruleForm.redpacketType === 2
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "75px" },
                                              attrs: {
                                                disabled: _vm.disabled,
                                                placeholder: "",
                                              },
                                              model: {
                                                value: item.vipBegin,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "vipBegin",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.vipBegin",
                                              },
                                            },
                                            _vm._l(
                                              _vm.Levels,
                                              function (level) {
                                                return _c("el-option", {
                                                  key: level.id,
                                                  attrs: {
                                                    value: level.vipLevel,
                                                    label:
                                                      "VIP" + level.vipLevel,
                                                    disabled:
                                                      _vm.disabledBeginVip(
                                                        index,
                                                        level.vipLevel
                                                      ),
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm.ruleForm.redpacketType === 3
                                        ? _c("el-input", {
                                            staticStyle: { width: "75px" },
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value: item.vipBegin,
                                              callback: function ($$v) {
                                                _vm.$set(item, "vipBegin", $$v)
                                              },
                                              expression: "item.vipBegin",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { padding: "0 5px" } },
                                    [_vm._v("~")]
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "items." + index + ".vipEnd",
                                        "show-message": false,
                                        rules: [
                                          {
                                            required: true,
                                            message: "此项必填",
                                            trigger: ["change", "blur"],
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _vm.ruleForm.redpacketType === 2
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "75px" },
                                              attrs: {
                                                disabled:
                                                  typeof _vm.ruleForm.items[
                                                    index
                                                  ].vipBegin !== "number" ||
                                                  _vm.disabled,
                                              },
                                              model: {
                                                value: item.vipEnd,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "vipEnd", $$v)
                                                },
                                                expression: "item.vipEnd",
                                              },
                                            },
                                            _vm._l(
                                              _vm.Levels,
                                              function (level) {
                                                return _c("el-option", {
                                                  key: level.id,
                                                  attrs: {
                                                    value: level.vipLevel,
                                                    label:
                                                      "VIP" + level.vipLevel,
                                                    disabled:
                                                      _vm.disabledEndVip(
                                                        index,
                                                        level.vipLevel
                                                      ),
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm.ruleForm.redpacketType === 3
                                        ? _c("el-input", {
                                            staticStyle: { width: "75px" },
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value: item.vipEnd,
                                              callback: function ($$v) {
                                                _vm.$set(item, "vipEnd", $$v)
                                              },
                                              expression: "item.vipEnd",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "robvip-range-item__child" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "items." + index + ".rangeAMinAmount",
                                        "show-message": false,
                                        rules: _vm.rulesAmountFloatBlur,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "75px" },
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          blur: function (e) {
                                            return _vm.onAmountBlur(
                                              e,
                                              index,
                                              "AMin"
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.rangeAMinAmount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "rangeAMinAmount",
                                              $$v
                                            )
                                          },
                                          expression: "item.rangeAMinAmount",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "0 5px" } },
                                    [_vm._v("~")]
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "items." + index + ".rangeAMaxAmount",
                                        "show-message": false,
                                        rules: _vm.rulesAmountFloatBlur,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "75px" },
                                        attrs: {
                                          disabled:
                                            !item.rangeAMinAmount ||
                                            _vm.disabled,
                                        },
                                        on: {
                                          blur: function (e) {
                                            return _vm.onAmountBlur(
                                              e,
                                              index,
                                              "AMax"
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.rangeAMaxAmount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "rangeAMaxAmount",
                                              $$v
                                            )
                                          },
                                          expression: "item.rangeAMaxAmount",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        prop: "items." + index + ".rangeARate",
                                        "show-message": false,
                                        rules: _vm.rulesPercentBlur,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "75px" },
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          blur: function (e) {
                                            return _vm.onRangeBlur(
                                              e,
                                              index,
                                              "B"
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.rangeARate,
                                          callback: function ($$v) {
                                            _vm.$set(item, "rangeARate", $$v)
                                          },
                                          expression: "item.rangeARate",
                                        },
                                      }),
                                      _vm._v(" % "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "robvip-range-item__child" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "items." + index + ".rangeBMinAmount",
                                        "show-message": false,
                                        rules: _vm.rulesAmountFloatBlur,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "75px" },
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          blur: function (e) {
                                            return _vm.onAmountBlur(
                                              e,
                                              index,
                                              "BMin"
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.rangeBMinAmount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "rangeBMinAmount",
                                              $$v
                                            )
                                          },
                                          expression: "item.rangeBMinAmount",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "0 5px" } },
                                    [_vm._v("~")]
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "items." + index + ".rangeBMaxAmount",
                                        "show-message": false,
                                        rules: _vm.rulesAmountFloatBlur,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "75px" },
                                        attrs: {
                                          disabled:
                                            !item.rangeBMinAmount ||
                                            _vm.disabled,
                                        },
                                        on: {
                                          blur: function (e) {
                                            return _vm.onAmountBlur(
                                              e,
                                              index,
                                              "BMax"
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.rangeBMaxAmount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "rangeBMaxAmount",
                                              $$v
                                            )
                                          },
                                          expression: "item.rangeBMaxAmount",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        prop: "items." + index + ".rangeBRate",
                                        "show-message": false,
                                        rules: _vm.rulesPercentBlur,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "75px" },
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          blur: function (e) {
                                            return _vm.onRangeBlur(
                                              e,
                                              index,
                                              "A"
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.rangeBRate,
                                          callback: function ($$v) {
                                            _vm.$set(item, "rangeBRate", $$v)
                                          },
                                          expression: "item.rangeBRate",
                                        },
                                      }),
                                      _vm._v(" % "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "60px" },
              attrs: { label: "虚拟数据金额", prop: "showAmtMin" },
            },
            [
              _c(
                "div",
                { attrs: { flex: "main:center" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "showAmtMin" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.ruleForm.showAmtMin,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "showAmtMin", $$v)
                          },
                          expression: "ruleForm.showAmtMin",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", { staticStyle: { padding: "0 5px" } }, [
                    _vm._v("~"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "showAmtMax" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.ruleForm.showAmtMax,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "showAmtMax", $$v)
                          },
                          expression: "ruleForm.showAmtMax",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", [
                    _vm._v("（应用于C端虚拟数据领取红包金额随机范围）"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "领取条件", prop: "requireBet" } },
            [
              _c(
                "div",
                { attrs: { flex: "main:center" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "requireBetAmt",
                        label: "累计投注",
                        "label-width": "90px",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.ruleForm.requireBetAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "requireBetAmt", $$v)
                          },
                          expression: "ruleForm.requireBetAmt",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "requireRechargeAmt",
                        label: "累计充值",
                        "label-width": "90px",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.ruleForm.requireRechargeAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "requireRechargeAmt", $$v)
                          },
                          expression: "ruleForm.requireRechargeAmt",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "requireTime", "label-width": "40px" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { disabled: _vm.disabled, label: 2 },
                          model: {
                            value: _vm.ruleForm.requireTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "requireTime", $$v)
                            },
                            expression: "ruleForm.requireTime",
                          },
                        },
                        [_vm._v("活动期间每日")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { disabled: _vm.disabled, label: 0 },
                          model: {
                            value: _vm.ruleForm.requireTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "requireTime", $$v)
                            },
                            expression: "ruleForm.requireTime",
                          },
                        },
                        [_vm._v("活动期间内")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { disabled: _vm.disabled, label: 1 },
                          model: {
                            value: _vm.ruleForm.requireTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "requireTime", $$v)
                            },
                            expression: "ruleForm.requireTime",
                          },
                        },
                        [_vm._v("不限活动期间")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否弹窗", prop: "popup" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.ruleForm.popup,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "popup", $$v)
                    },
                    expression: "ruleForm.popup",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("语言选择"), prop: "lang" } },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.ruleForm.lang,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "lang", $$v)
                    },
                    expression: "ruleForm.lang",
                  },
                },
                _vm._l(_vm.$parent.allLang, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.lang, attrs: { label: item.lang } },
                    [_vm._v(_vm._s(_vm.$t(item.name)))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动Banner", prop: "redpacketBanner" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "banner-uploader",
                  attrs: {
                    disabled: _vm.disabled,
                    accept:
                      "image/jpeg,image/png,image/jpg,image/gif,image/svg,image/webp",
                    action: _vm.action,
                    data: { type: "image", folder: "ad-photo" },
                    headers: _vm.$headers(),
                    "show-file-list": false,
                    "on-success": _vm.handleBannerSuccess,
                  },
                },
                [
                  _vm.ruleForm.redpacketBanner
                    ? _c("img", {
                        staticClass: "banner",
                        attrs: { src: _vm.ruleForm.redpacketBanner },
                      })
                    : _c("div", { staticClass: "banner-uploader-txt" }, [
                        _c("p", [
                          _vm._v(
                            "提示：建议尺寸355*138，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。"
                          ),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass:
                              "el-button el-button--small el-button--orange",
                            on: {
                              click: function ($event) {
                                if ($event.target !== $event.currentTarget) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                          },
                          [_vm._v("点击上传")]
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动详情", prop: "redpacketBody" } },
            [
              _c("tinymce", {
                ref: "tinymce",
                attrs: { disabled: _vm.disabled, height: 200 },
                model: {
                  value: _vm.ruleForm.redpacketBody,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "redpacketBody", $$v)
                  },
                  expression: "ruleForm.redpacketBody",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.disabled
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }