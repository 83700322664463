
let websocket
let count = 1
let lockReconnect = false
// let globalCallback = null

function webSocketSend(data) {
  websocket.send(data)
}

function WaitForConnection(cb) {
  if (websocket.readyState === 1) {
    cb()
  } else {
    setTimeout(() => {
      WaitForConnection(cb)
    }, 1000)
  }
}

const heartCheck = {
  intelVal: null,
  time: 10000,
  clear: function() {
    this.intelVal && clearTimeout(this.intelVal)
    this.intelVal = null
  },
  start: function(pingMessage) {
    this.intelVal = setInterval(() => {
      WaitForConnection(() => {
        const ping = pingMessage()
        websocket.send(ping)
      })
    }, this.time)
  }
}

export default () => ({
  data() {
    return {
      socket: {
        isConnected: false,
        reconnectError: false,
        reconnectTimer: null,
        reconnectionAttempts: 3
      }
    }
  },
  methods: {
    createWebSocket(url, cb = null) {
      try {
        if ('WebSocket' in window) {
          websocket = new WebSocket(url)
        } else if ('MozWebSocket' in window) {
          websocket = new MozWebSocket(url) // eslint-disable-line
        } else {
          websocket = new SockJS(url) // eslint-disable-line
        }
        websocket.onclose = e => {
          heartCheck.clear()
          this.websocketclose(e, cb, url)
        }

        websocket.onerror = (e) => {
          this.websocketonerror(e, cb, url)
        }
        websocket.onopen = e => {
          this.websocketonopen(e, cb)
        }
        websocket.onmessage = (e) => {
          this.websocketonmessage(e, this.formatMessage)
        }
      } catch (e) {
        this.reconnect(url, cb)
      }
    },
    pingMessage() {
      return 'ping'
    },
    reconnect(url, cb) {
      if (lockReconnect || count > this.socket.reconnectionAttempts) {
        this.socket.reconnectError = true
        return
      }
      lockReconnect = true
      count++
      this.socket.reconnectTimer && clearTimeout(this.socket.reconnectTimer)
      this.socket.reconnectTimer = setTimeout(() => {
        this.createWebSocket(url, cb)
        lockReconnect = false
      }, Math.pow(2, count) * 1000)
    },

    _closewebsocket() {
      if (websocket && websocket.readyState === 1) {
        websocket.close()
        heartCheck.clear()
      }
    },

    startHeartCheck() {
      heartCheck.start(this.pingMessage)
    },

    websocketonmessage(e, formatMessage) {
      if (e.data instanceof Blob) {
        e.data.arrayBuffer().then(buffer => {
          formatMessage(buffer)
        })
      } else if (e.data instanceof ArrayBuffer) {
        formatMessage(e.data)
      }
    },

    websocketonopen(e) {
      this.socket.isConnected = true
    },

    websocketclose(e, cb) {
      this.socket.isConnected = false
      if (e.code === 1000) {
        this._closewebsocket(cb)
      } else {
        cb && cb()
        this.reconnect()
      }
    },

    websocketonerror(e, cb, url) {
      this.reconnect(url, cb)
    },
    websocketsendmessage(data) {
      WaitForConnection(() => {
        webSocketSend(data)
      })
    }
  }
})
