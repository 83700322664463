<template>
  <div>
    <cascader-select
      v-model="value"
      :options="playClass"
      :parent-label="$t('一级玩法')"
      :child-label="$t('二级玩法')"
      @change="onChangeSelect"
    />

    <d-table
      ref="DTable"
      v-loading="loading"
      row-key="attributeTag"
      :drag-row="permission.Sort"
      index-row
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <el-radio-group v-model="params.headerLang" style="margin-right: 6px;" @change="handleRefresh">
          <el-radio-button v-for="(item,index) in allLang" :key="index" :label="item.lang">{{ item.name }}</el-radio-button>
        </el-radio-group>
        <el-button type="success" plain @click="_handleSave">{{ $t('全部保存') }}</el-button>
      </template>
    </d-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CascaderSelect from '@/components/CascaderSelect'
import { getPlayGroupList, updatePlayClassGroup } from '@/api/lottery'
import TableSortMixin from '@/mixins/table-sort-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'

export default {
  name: 'GroupTab',
  componentName: 'GroupTab',
  components: { CascaderSelect },
  mixins: [TableSortMixin(), TablePageMixin(getPlayGroupList, false)],
  props: {
    lotteryId: {
      type: String,
      default: ''
    },
    playClass: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      params: {},
      value: [],
      columns: [],
      defaultColumns: GetColumns.call(this)
    }
  },
  computed: {
    ...mapGetters(['operatorType', 'allLang'])
  },
  created() {
    this.value = [this.playClass[0].value, this.playClass[0].children[0].value]
    this.params.lotteryId = this.lotteryId
    this.params.playClassTag = this.value[1]
    if (this.allLang.length > 0) {
      this.params.headerLang = this.allLang[0].lang
    }
    this.handleRefresh()
  },
  methods: {
    onChangeSelect(val) {
      this.params.playClassTag = val[1]
      this.handleRefresh()
    },
    _handleUpdate({ groupName, groupTag, sort, status, example, playRule }) {
      this.$confirm(this.$t('确认修改属性值为{groupName}的信息', { groupName: groupName }), { type: 'warning' }).then(() => {
        updatePlayClassGroup([{
          lotteryId: this.lotteryId,
          groupTag,
          sort,
          status,
          example,
          playRule
          // headerLang: this.params.headerLang
        }], this.operatorType).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('修改成功'))
          }
        })
      }).catch(() => {})
    },

    _handleSave() {
      this.$confirm(this.$t('确认修改所有属性值信息'), { type: 'warning' }).then(() => {
        const tmp = this.items.map((o, i) => ({
          lotteryId: this.lotteryId,
          groupTag: o.groupTag,
          sort: i,
          status: o.status,
          example: o.example,
          playRule: o.playRule
          // headerLang: this.params.headerLang
        }))
        updatePlayClassGroup(tmp, this.operatorType).then(([_, err]) => {
          if (!err) {
            this.handleRefresh()
          }
        })
      }).catch(() => {})
    },

    implementationGetParams() {
      return { ...this.params }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
