import { getPayReceivingAccountList, getPayWayThirdConfList, getPaymentTypeConfList } from '@/api/cash'
import { queryWithdrawTypeConf } from '@/api/withdraw'
import { getList } from '@/api/card.js'

// 映射关系
export const mapping = {
  paywayList: "paywayList",
  thirdPaywayList: "thirdPaywayList",
  withdrawList1: "withdrawList1",
  withdrawList2: "withdrawList2",
  taskRangeFocusChecked: "关注",
  taskRangeFatieChecked: "发帖",
  taskRangeRemarkChecked: "评论",
  taskRangeRewardChecked: "打赏",
  taskRangeBrowseChecked: "浏览",
};

export const newArr = [
  {"value": "01","label": "推单投注","children": [{checked:false,label:"推单投注",value: '05'}]},
  {"value": "02","label": "跟单投注","children": [{checked:false,label:"跟单投注",value: '06'}]},
  {"value": "03","label": "直播间投注","children": [{checked:false,label:"直播间投注",value: '07'}]},
  {"value": "04","label": "聊天室跟投","children": [{checked:false,label:"聊天室跟投",value: '08'}]},
];

// 投注-过滤数据
export const filterMap = {
  '1000005': '1000005',
  '2000002': '2000002',
  '1000002': '1000002',
  '1000006': '1000006',
  '1000007': '1000007',
  '1000008': '1000008',
  '1000009': '1000009',
  '1000010': '1000010',
  '1000011': '1000011',
  '01': '01',
  '02': '02',
  '03': '03',
  '04': '04'
};

// API 统一请求
export const DATA_SOURCES = [
  {
    key: 'companyAccountList',
    api: getPayReceivingAccountList,
    params: { currentPage: 1, pageSize: 100, status: 0 }
  },
  {
    key: 'thirdAccountList',
    api: getPayWayThirdConfList,
    params: { currentPage: 1, pageSize: 100, status: 0 }
  },
  {
    key: 'withdrawTypeList',
    api: queryWithdrawTypeConf,
    params: { currentPage: 1, pageSize: 100 },
    processor: items => items.filter(item => item.id != 5)
  },
  {
    key: 'paymentTypeList',
    api: getPaymentTypeConfList,
    params: { currentPage: 1, pageSize: 100 }
  },
  {
    key: 'couponList',
    api: () => getList({ currentPage: 1, pageSize: 500, couponStatus: 1 }),
    processor: items => items.filter(e => e.couponStatus === 1)
  }
];
