var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      attrs: { trigger: "click", placement: "bottom" },
      on: { command: _vm.handleSetLanguage },
    },
    [
      _c("div", [_c("svg-icon", { attrs: { "icon-class": "language" } })], 1),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.language, function (item, index) {
          return _c(
            "el-dropdown-item",
            {
              key: index,
              attrs: { disabled: item.lang === _vm.lang, command: item.lang },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/locale/" + item.lang + ".png"),
                },
              }),
              _vm._v(" " + _vm._s(item.name) + " "),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }