var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        width: "800px",
        visible: _vm.visible,
        "close-on-click-modal": false,
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          staticClass: "table-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("广告标题"), prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "16",
                  placeholder: _vm.$t("请输入广告标题"),
                },
                model: {
                  value: _vm.ruleForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("展示位置"), prop: "position" } },
            [
              _c(
                "el-select",
                {
                  style: { width: "100%" },
                  attrs: { placeholder: _vm.$t("请选择展示位置") },
                  on: { change: _vm.handleChangePostion },
                  model: {
                    value: _vm.ruleForm.position,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "position", $$v)
                    },
                    expression: "ruleForm.position",
                  },
                },
                _vm._l(_vm.$parent.positionOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("展示时间"), prop: "dateTime" } },
            [
              _c("el-date-picker", {
                style: { width: "100%" },
                attrs: {
                  "default-time": ["00:00:00", "23:59:59"],
                  type: "datetimerange",
                  "range-separator": _vm.$t("至"),
                  "start-placeholder": _vm.$t("开始时间"),
                  "end-placeholder": _vm.$t("结束时间"),
                },
                model: {
                  value: _vm.ruleForm.dateTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "dateTime", $$v)
                  },
                  expression: "ruleForm.dateTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("展示终端"), prop: "displayTerminal" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.displayTerminal,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "displayTerminal", $$v)
                    },
                    expression: "ruleForm.displayTerminal",
                  },
                },
                _vm._l(_vm.terminalList, function (item) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.attributeName,
                      style: { "vertical-align": "top" },
                      attrs: { label: item.attributeName },
                    },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("是否启用"), prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !_vm.$parent.permission.StartStop },
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("启用"))),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v(_vm._s(_vm.$t("关闭"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("跳转方式"), prop: "jumpType" } },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function ($event) {
                      _vm.ruleForm.appPageId = []
                    },
                  },
                  model: {
                    value: _vm.ruleForm.jumpType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "jumpType", $$v)
                    },
                    expression: "ruleForm.jumpType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("链接"))),
                  ]),
                  _c("el-radio", { attrs: { label: 5 } }, [
                    _vm._v(_vm._s(_vm.$t("APP功能页"))),
                  ]),
                  _c("el-radio", { attrs: { label: 4 } }, [
                    _vm._v(_vm._s(_vm.$t("签到"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.jumpType === 1
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("跳转链接") } },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("请输入跳转链接地址") },
                    model: {
                      value: _vm.ruleForm.outUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "outUrl", $$v)
                      },
                      expression: "ruleForm.outUrl",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.ruleForm.jumpType === 5
            ? _c(
                "el-form-item",
                { attrs: { label: "APP功能页", prop: "appPageId" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "300px" },
                    attrs: { options: _vm.appPageList, clearable: "" },
                    on: { change: _vm.handleChangeAppPage },
                    model: {
                      value: _vm.ruleForm.appPageId,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "appPageId", $$v)
                      },
                      expression: "ruleForm.appPageId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("语言"), prop: "lang" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.lang,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "lang", $$v)
                    },
                    expression: "ruleForm.lang",
                  },
                },
                _vm._l(_vm.allLang, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.lang, attrs: { label: item.lang } },
                    [_vm._v(_vm._s(_vm.$t(item.name)))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("广告详情"), prop: "detailType" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.detailTypeDisabled },
                  model: {
                    value: _vm.ruleForm.detailType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "detailType", $$v)
                    },
                    expression: "ruleForm.detailType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("富文本"))),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v(_vm._s(_vm.$t("图片"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ruleForm.detailType === 1,
                  expression: "ruleForm.detailType === 1",
                },
              ],
              attrs: { prop: "richText" },
            },
            [
              _c("tinymce", {
                ref: "tinymce",
                attrs: { height: 250 },
                model: {
                  value: _vm.ruleForm.richText,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "richText", $$v)
                  },
                  expression: "ruleForm.richText",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ruleForm.detailType === 2,
                  expression: "ruleForm.detailType === 2",
                },
              ],
              attrs: { prop: "pic" },
            },
            [
              _c("SingleImage2", {
                style: { marginTop: "10px" },
                attrs: {
                  value: _vm.ruleForm.pic,
                  accept: "image/jpeg,image/png,image/jpg,image/gif,image/svg",
                  folder: "ad-photo",
                },
                on: { input: _vm.output },
              }),
              _c(
                "div",
                [
                  _c("i", { staticClass: "el-icon-warning" }),
                  _vm._v(" 提示：建议尺寸 "),
                  _vm.ruleForm.position == 8 || _vm.ruleForm.position == 9
                    ? [_vm._v(" 108*35 ")]
                    : [10, 11, 12, 13].includes(_vm.ruleForm.position)
                    ? [_vm._v(" 70*70 ")]
                    : [_vm._v(" 358*70 ")],
                  _vm._v(
                    " ，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。 "
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("关 闭"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmitBefor },
            },
            [_vm._v(_vm._s(_vm.$t("保 存")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }