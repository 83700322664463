var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "60%",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            disabled: _vm.emitName === "details",
            "label-width": "170px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("语言"), prop: "languageSet" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.ruleForm.languageSet,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "languageSet", $$v)
                    },
                    expression: "ruleForm.languageSet",
                  },
                },
                _vm._l(_vm.allLang, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.lang, attrs: { label: item.lang } },
                    [_vm._v(_vm._s(_vm.$t(item.name)))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("活动标题"), prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  maxlength: "20",
                  placeholder: _vm.$t("限20字以内"),
                  clearable: "",
                },
                model: {
                  value: _vm.ruleForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("活动分类"), prop: "category" } },
            [
              _c(
                "el-select",
                {
                  style: { width: "300px" },
                  attrs: { placeholder: _vm.$t("活动分类"), clearable: "" },
                  model: {
                    value: _vm.ruleForm.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "category", $$v)
                    },
                    expression: "ruleForm.category",
                  },
                },
                _vm._l(_vm.$parent.categoryList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.typeName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("排序"), prop: "sortOrder" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  placeholder: "请输入1-999的整数，由大到小",
                  clearable: "",
                },
                model: {
                  value: _vm.ruleForm.sortOrder,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "sortOrder", _vm._n($$v))
                  },
                  expression: "ruleForm.sortOrder",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("活动时间"), prop: "isContinuous" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.isContinuous,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "isContinuous", $$v)
                    },
                    expression: "ruleForm.isContinuous",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("定时开启"))),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("持续开启"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.isContinuous === 0
            ? _c(
                "el-form-item",
                { attrs: { prop: "activityDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "range-separator": _vm.$t("至"),
                      "start-placeholder": _vm.$t("开始时间"),
                      "end-placeholder": _vm.$t("结束时间"),
                      "picker-options": _vm.pickerOptions,
                    },
                    model: {
                      value: _vm.ruleForm.activityDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "activityDate", $$v)
                      },
                      expression: "ruleForm.activityDate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("活动Banner"), prop: "banner" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "banner-uploader",
                  attrs: {
                    accept: "image/jpeg,image/png,image/jpg,image/webp",
                    action: _vm.action,
                    data: { type: "image", folder: "ad-photo" },
                    headers: _vm.$headers(),
                    "show-file-list": false,
                    "on-success": function (res) {
                      return _vm.handleUploadSuccess(res, "banner")
                    },
                  },
                },
                [
                  _vm.ruleForm.banner
                    ? _c("img", {
                        staticClass: "banner",
                        attrs: { src: _vm.ruleForm.banner },
                      })
                    : _c("div", { staticClass: "banner-uploader-txt" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "el-button el-button--small el-button--orange",
                            on: {
                              click: function ($event) {
                                if ($event.target !== $event.currentTarget) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("点击上传")))]
                        ),
                      ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning-outline" }),
                      _vm._v(
                        "提示：建议尺寸355*138，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。"
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("活动类型"), prop: "activityType" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeActivityType },
                  model: {
                    value: _vm.ruleForm.activityType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "activityType", $$v)
                    },
                    expression: "ruleForm.activityType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("任务活动"))),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v(_vm._s(_vm.$t("其他活动"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.activityType == 2
            ? _c(
                "el-form-item",
                {
                  key: "jumpType",
                  attrs: { label: _vm.$t("跳转方式"), prop: "jumpType" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeJumpType },
                      model: {
                        value: _vm.ruleForm.jumpType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "jumpType", $$v)
                        },
                        expression: "ruleForm.jumpType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(_vm._s(_vm.$t("活动详情"))),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v(_vm._s(_vm.$t("链接"))),
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v(_vm._s(_vm.$t("APP功能页"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.ruleForm.activityType == 2
            ? [
                _c(
                  "div",
                  { key: "jumpFields" + _vm.ruleForm.jumpType },
                  [
                    _vm.ruleForm.jumpType === 3
                      ? _c(
                          "el-form-item",
                          { attrs: { prop: "appPageId" } },
                          [
                            _c("el-cascader", {
                              staticStyle: { width: "300px" },
                              attrs: {
                                options: _vm.appPageList,
                                clearable: "",
                              },
                              on: { change: _vm.handleChangeAppPage },
                              model: {
                                value: _vm.ruleForm.appPageId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "appPageId", $$v)
                                },
                                expression: "ruleForm.appPageId",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ruleForm.jumpType === 2
                      ? _c(
                          "el-form-item",
                          { attrs: { prop: "jumpLink" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "300px" },
                              attrs: {
                                placeholder: _vm.$t("请输入链接地址信息"),
                              },
                              on: {
                                input: function () {
                                  return _vm.$refs.validateForm.validateField(
                                    "jumpLink"
                                  )
                                },
                              },
                              model: {
                                value: _vm.ruleForm.jumpLink,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "jumpLink", $$v)
                                },
                                expression: "ruleForm.jumpLink",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showBanner,
                  expression: "showBanner",
                },
              ],
              key: "banner" + _vm.bannerKey,
              attrs: { label: _vm.$t("活动详情Banner"), prop: "detailBanner" },
            },
            [
              _c(
                "el-upload",
                {
                  key: "upload" + _vm.bannerKey,
                  staticClass: "banner-uploader",
                  attrs: {
                    accept: "image/jpeg,image/png,image/jpg,image/webp",
                    action: _vm.action,
                    data: { type: "image", folder: "ad-photo" },
                    headers: _vm.$headers(),
                    "show-file-list": false,
                    "on-success": function (res) {
                      return _vm.handleUploadSuccess(res, "detailBanner")
                    },
                  },
                  on: {
                    change: function () {
                      return _vm.$refs.validateForm.validateField(
                        "detailBanner"
                      )
                    },
                  },
                },
                [
                  _vm.ruleForm.detailBanner
                    ? _c("img", {
                        staticClass: "banner",
                        attrs: { src: _vm.ruleForm.detailBanner },
                      })
                    : _c("div", { staticClass: "banner-uploader-txt" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "el-button el-button--small el-button--orange",
                            on: {
                              click: function ($event) {
                                if ($event.target !== $event.currentTarget) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("点击上传")))]
                        ),
                      ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning-outline" }),
                      _vm._v(
                        "提示：建议尺寸355*138，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。"
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.ruleForm.activityType === 2
            ? [
                _vm.ruleForm.jumpType === 1
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("VIP等级"), prop: "vipLimit" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.changeVipLimit },
                              model: {
                                value: _vm.ruleForm.vipLimit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "vipLimit", $$v)
                                },
                                expression: "ruleForm.vipLimit",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v(_vm._s(_vm.$t("不限制"))),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("限制"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.vipLimit === 1
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "vipLevels" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.vipLevels,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "vipLevels", $$v)
                                    },
                                    expression: "ruleForm.vipLevels",
                                  },
                                },
                                _vm._l(
                                  _vm.formData.vipLevelList,
                                  function (vipLevel) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: vipLevel,
                                        attrs: { label: vipLevel },
                                      },
                                      [_vm._v(_vm._s("VIP" + vipLevel))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          key: "venue",
                          attrs: { label: _vm.$t("活动场馆"), prop: "venue" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "500px",
                              "max-width": "100%",
                            },
                            attrs: {
                              maxlength: "50",
                              placeholder: _vm.$t("限50字以内"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.ruleForm.venue,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "venue", $$v)
                              },
                              expression: "ruleForm.venue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          key: "guide",
                          attrs: {
                            label: _vm.$t("活动引导说明"),
                            prop: "guide",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "500px",
                              "max-width": "100%",
                            },
                            attrs: {
                              maxlength: "50",
                              placeholder: _vm.$t("限50字以内"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.ruleForm.guide,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "guide", $$v)
                              },
                              expression: "ruleForm.guide",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("任务与奖励"),
                            prop: "rewardContent",
                          },
                        },
                        [
                          _c("tinymce", {
                            ref: "tinymce",
                            attrs: {
                              disabled: _vm.emitName === "details",
                              height: 200,
                            },
                            model: {
                              value: _vm.ruleForm.rewardContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "rewardContent", $$v)
                              },
                              expression: "ruleForm.rewardContent",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("活动规则"),
                            prop: "ruleDisplayType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.changeRuleDisplayType },
                              model: {
                                value: _vm.ruleForm.ruleDisplayType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "ruleDisplayType", $$v)
                                },
                                expression: "ruleForm.ruleDisplayType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("富文本"))),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v(_vm._s(_vm.$t("图片"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "ruleContent" } },
                            [
                              _vm.ruleForm.ruleDisplayType === 1
                                ? _c("tinymce", {
                                    ref: "tinymceRule",
                                    attrs: {
                                      disabled: _vm.emitName === "details",
                                      height: 200,
                                    },
                                    model: {
                                      value: _vm.ruleForm.ruleContent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "ruleContent",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.ruleContent",
                                    },
                                  })
                                : _c(
                                    "el-upload",
                                    {
                                      staticClass: "banner-uploader",
                                      attrs: {
                                        accept:
                                          "image/jpeg,image/png,image/jpg,image/webp",
                                        action: _vm.action,
                                        data: {
                                          type: "image",
                                          folder: "ad-photo",
                                        },
                                        headers: _vm.$headers(),
                                        "show-file-list": false,
                                        "on-success": function (res) {
                                          return _vm.handleUploadSuccess(
                                            res,
                                            "ruleContent"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.ruleForm.ruleContent
                                        ? _c("img", {
                                            staticClass: "banner",
                                            attrs: {
                                              src: _vm.ruleForm.ruleContent,
                                            },
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "banner-uploader-txt",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "el-button el-button--small el-button--orange",
                                                  on: {
                                                    click: function ($event) {
                                                      if (
                                                        $event.target !==
                                                        $event.currentTarget
                                                      ) {
                                                        return null
                                                      }
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("点击上传"))
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          attrs: { slot: "tip" },
                                          slot: "tip",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-warning-outline",
                                          }),
                                          _vm._v(
                                            "提示：建议尺寸355*138，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.ruleForm.activityType === 1
            ? [
                _c(
                  "el-form-item",
                  { staticClass: "tiaojian" },
                  [
                    _c("strong", [_vm._v("参与资格：")]),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-error",
                        attrs: {
                          label: "总充值金额大于等于",
                          prop: "minTotalRecharge",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "120px",
                            "margin-right": "100px",
                          },
                          attrs: { type: "text", clearable: "" },
                          on: {
                            input: function ($event) {
                              return _vm.handleInput("minTotalRecharge")
                            },
                          },
                          model: {
                            value: _vm.ruleForm.minTotalRecharge,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "minTotalRecharge", $$v)
                            },
                            expression: "ruleForm.minTotalRecharge",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-error",
                        attrs: {
                          label: "总投注金额大于等于",
                          prop: "minTotalBet",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: { type: "text", clearable: "" },
                          on: {
                            input: function ($event) {
                              return _vm.handleInput("minTotalBet")
                            },
                          },
                          model: {
                            value: _vm.ruleForm.minTotalBet,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "minTotalBet", $$v)
                            },
                            expression: "ruleForm.minTotalBet",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "tiaojian" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-error",
                        attrs: {
                          label: "今日充值金额大于等于",
                          prop: "minTodayRecharge",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "120px",
                            "margin-right": "100px",
                          },
                          attrs: { type: "text", clearable: "" },
                          on: {
                            input: function ($event) {
                              return _vm.handleInput("minTodayRecharge")
                            },
                          },
                          model: {
                            value: _vm.ruleForm.minTodayRecharge,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "minTodayRecharge", $$v)
                            },
                            expression: "ruleForm.minTodayRecharge",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-error",
                        attrs: {
                          label: "今日投注金额大于等于",
                          prop: "minTodayBet",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: { type: "text", clearable: "" },
                          on: {
                            input: function ($event) {
                              return _vm.handleInput("minTodayBet")
                            },
                          },
                          model: {
                            value: _vm.ruleForm.minTodayBet,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "minTodayBet", $$v)
                            },
                            expression: "ruleForm.minTodayBet",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("VIP等级"), prop: "vipLimit" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.vipLimit,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "vipLimit", $$v)
                          },
                          expression: "ruleForm.vipLimit",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v(_vm._s(_vm.$t("不限制"))),
                        ]),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("限制"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.ruleForm.vipLimit === 1
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "vipLevels" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.ruleForm.vipLevels,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "vipLevels", $$v)
                              },
                              expression: "ruleForm.vipLevels",
                            },
                          },
                          _vm._l(
                            _vm.formData.vipLevelList,
                            function (vipLevel) {
                              return _c(
                                "el-checkbox",
                                { key: vipLevel, attrs: { label: vipLevel } },
                                [_vm._v(_vm._s("VIP" + vipLevel))]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  {
                    key: "venue",
                    attrs: { label: _vm.$t("活动场馆"), prop: "venue" },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "500px", "max-width": "100%" },
                      attrs: {
                        maxlength: "50",
                        placeholder: _vm.$t("限50字以内"),
                        clearable: "",
                      },
                      model: {
                        value: _vm.ruleForm.venue,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "venue", $$v)
                        },
                        expression: "ruleForm.venue",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    key: "guide",
                    attrs: { label: _vm.$t("活动引导说明"), prop: "guide" },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "500px", "max-width": "100%" },
                      attrs: {
                        maxlength: "50",
                        placeholder: _vm.$t("限50字以内"),
                        clearable: "",
                      },
                      model: {
                        value: _vm.ruleForm.guide,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "guide", $$v)
                        },
                        expression: "ruleForm.guide",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("任务类型"), prop: "taskType" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.changeTaskType },
                        model: {
                          value: _vm.ruleForm.taskType,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "taskType", $$v)
                          },
                          expression: "ruleForm.taskType",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("日任务"))),
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v(_vm._s(_vm.$t("周任务"))),
                        ]),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v(_vm._s(_vm.$t("长期任务"))),
                        ]),
                        _c("el-radio", { attrs: { label: 4 } }, [
                          _vm._v(_vm._s(_vm.$t("新手任务"))),
                        ]),
                      ],
                      1
                    ),
                    _vm.ruleForm.taskType === 2
                      ? _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "130px",
                              "margin-left": "50px",
                            },
                            on: { change: _vm.changeRewardMode },
                            model: {
                              value: _vm.ruleForm.rewardMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "rewardMode", $$v)
                              },
                              expression: "ruleForm.rewardMode",
                            },
                          },
                          _vm._l(_vm.formData.awardsOptions, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("任务行为"), prop: "taskAction" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.changeTaskAction },
                        model: {
                          value: _vm.ruleForm.taskAction,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "taskAction", $$v)
                          },
                          expression: "ruleForm.taskAction",
                        },
                      },
                      [
                        _vm.ruleForm.taskType === 1
                          ? _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v(_vm._s(_vm.$t("签到"))),
                            ])
                          : _vm._e(),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v(_vm._s(_vm.$t("充值"))),
                        ]),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v(_vm._s(_vm.$t("提现"))),
                        ]),
                        _c("el-radio", { attrs: { label: 4 } }, [
                          _vm._v(_vm._s(_vm.$t("投注"))),
                        ]),
                        _c("el-radio", { attrs: { label: 5 } }, [
                          _vm._v(_vm._s(_vm.$t("关注"))),
                        ]),
                        _c("el-radio", { attrs: { label: 6 } }, [
                          _vm._v(_vm._s(_vm.$t("发帖"))),
                        ]),
                        _c("el-radio", { attrs: { label: 7 } }, [
                          _vm._v(_vm._s(_vm.$t("评论"))),
                        ]),
                        _c("el-radio", { attrs: { label: 8 } }, [
                          _vm._v(_vm._s(_vm.$t("打赏"))),
                        ]),
                        _c("el-radio", { attrs: { label: 9 } }, [
                          _vm._v(_vm._s(_vm.$t("浏览"))),
                        ]),
                        _c("el-radio", { attrs: { label: 10 } }, [
                          _vm._v(_vm._s(_vm.$t("邀请"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.ruleForm.taskAction === 1
                  ? _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("任务目标与奖励") } },
                      [
                        _c("span", [_vm._v("每日奖励")]),
                        _c("table", { staticClass: "signTable" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("签到日期")]),
                              _c("th", [_vm._v("奖励类型")]),
                              _c("th", [_vm._v("奖励内容")]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.day31, function (n, index) {
                              return _c("tr", { key: n }, [
                                _c("td", [_vm._v(" " + _vm._s(n) + "号 ")]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "form-item-error",
                                        attrs: {
                                          prop:
                                            "rewardRules4." +
                                            index +
                                            ".awardsCheckedType_4",
                                          rules: _vm.rules.awardsCheckedType_4,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            on: {
                                              change: function (value) {
                                                return _vm.handleChange(
                                                  value,
                                                  4,
                                                  index
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.ruleForm.rewardRules4[index]
                                                  .awardsCheckedType_4,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ruleForm.rewardRules4[
                                                    index
                                                  ],
                                                  "awardsCheckedType_4",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ruleForm.rewardRules4[index].awardsCheckedType_4",
                                            },
                                          },
                                          _vm._l(
                                            _vm.awardsType,
                                            function (item, i) {
                                              return _c(
                                                "el-checkbox",
                                                {
                                                  key: item.type,
                                                  attrs: { label: item.type },
                                                },
                                                [_vm._v(_vm._s(item.type))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "form-item-error",
                                        attrs: {
                                          prop:
                                            "rewardRules4." +
                                            index +
                                            ".bonusCheck",
                                          rules: _vm.getBonusRules(index),
                                        },
                                      },
                                      [
                                        _vm.awardsCheckedType_4[index].includes(
                                          "彩金"
                                        )
                                          ? _c(
                                              "span",
                                              [
                                                _vm._v(" 彩金 "),
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.handleInput2(
                                                        "bonusAmount",
                                                        index,
                                                        "rewardRules4"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ruleForm.rewardRules4[
                                                        index
                                                      ].bonusAmount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ruleForm
                                                          .rewardRules4[index],
                                                        "bonusAmount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ruleForm.rewardRules4[index].bonusAmount",
                                                  },
                                                }),
                                                _vm._v(" 打码"),
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.handleInput2(
                                                        "bonusWagerMultiplier",
                                                        index,
                                                        "rewardRules4"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ruleForm.rewardRules4[
                                                        index
                                                      ].bonusWagerMultiplier,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ruleForm
                                                          .rewardRules4[index],
                                                        "bonusWagerMultiplier",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ruleForm.rewardRules4[index].bonusWagerMultiplier",
                                                  },
                                                }),
                                                _vm._v(" 倍 "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "form-item-error",
                                        attrs: {
                                          prop:
                                            "rewardRules4." +
                                            index +
                                            ".couponCheck",
                                          rules: _vm.getCouponRules(index),
                                        },
                                      },
                                      [
                                        _vm.awardsCheckedType_4[index].includes(
                                          "优惠券"
                                        )
                                          ? _c(
                                              "span",
                                              { staticClass: "ml" },
                                              [
                                                _vm._v(" 优惠券 "),
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "filter-item",
                                                    staticStyle: {
                                                      width: "220px",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        _vm.$t("商品类目"),
                                                      clearable: "",
                                                      filterable: "",
                                                      multiple: "",
                                                      "multiple-limit": 5,
                                                      "collapse-tags": "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules4[index]
                                                          .couponId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules4[
                                                            index
                                                          ],
                                                          "couponId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules4[index].couponId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.couponList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.couponId,
                                                        attrs: {
                                                          value: item.couponId,
                                                          label:
                                                            item.couponTitle,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "form-item-error",
                                        attrs: {
                                          prop:
                                            "rewardRules4." +
                                            index +
                                            ".pointsCheck",
                                          rules: _vm.getPointsRules(index),
                                        },
                                      },
                                      [
                                        _vm.awardsCheckedType_4[index].includes(
                                          "积分"
                                        )
                                          ? _c(
                                              "span",
                                              { staticClass: "ml" },
                                              [
                                                _vm._v(" 积分"),
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.handleInput2(
                                                        "points",
                                                        index,
                                                        "rewardRules4"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ruleForm.rewardRules4[
                                                        index
                                                      ].points,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ruleForm
                                                          .rewardRules4[index],
                                                        "points",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ruleForm.rewardRules4[index].points",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                        _c("span", [_vm._v("阶梯奖励")]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "add",
                            attrs: { type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAdd("num0")
                              },
                            },
                          },
                          [_vm._v("添加")]
                        ),
                        _c("table", [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("任务目标")]),
                              _c("th", [_vm._v("奖励类型")]),
                              _c("th", [_vm._v("奖励内容")]),
                              _c("th", [_vm._v("操作")]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.num0, function (n, index) {
                              return _c("tr", { key: n }, [
                                _c(
                                  "td",
                                  [
                                    _vm._v(" 累计签到 "),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "form-item-error",
                                        attrs: {
                                          prop:
                                            "rewardRules3." +
                                            index +
                                            ".conditionValue",
                                          rules: _vm.rules.conditionValue,
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            type: "text",
                                            clearable: "",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.handleInput2(
                                                "conditionValue",
                                                index,
                                                "rewardRules3",
                                                "num"
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.ruleForm.rewardRules3[index]
                                                .conditionValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm.rewardRules3[
                                                  index
                                                ],
                                                "conditionValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.rewardRules3[index].conditionValue",
                                          },
                                        }),
                                        _vm._v(" 天 "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "form-item-error",
                                        attrs: {
                                          prop:
                                            "rewardRules3." +
                                            index +
                                            ".awardsCheckedType_3",
                                          rules: _vm.rules.awardsCheckedType_3,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            on: {
                                              change: function (value) {
                                                return _vm.handleChange(
                                                  value,
                                                  3,
                                                  index
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.ruleForm.rewardRules3[index]
                                                  .awardsCheckedType_3,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ruleForm.rewardRules3[
                                                    index
                                                  ],
                                                  "awardsCheckedType_3",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ruleForm.rewardRules3[index].awardsCheckedType_3",
                                            },
                                          },
                                          _vm._l(
                                            _vm.awardsType,
                                            function (item, i) {
                                              return _c(
                                                "el-checkbox",
                                                {
                                                  key: item.type,
                                                  attrs: { label: item.type },
                                                },
                                                [_vm._v(_vm._s(item.type))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _vm.awardsCheckedType_3[index].includes(
                                      "彩金"
                                    )
                                      ? [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "form-item-error",
                                              attrs: {
                                                prop:
                                                  "rewardRules3." +
                                                  index +
                                                  ".bonusAmount",
                                                rules: _vm.awardsCheckedType_3[
                                                  index
                                                ].includes("彩金")
                                                  ? _vm.rules.bonusAmount
                                                  : [],
                                              },
                                            },
                                            [
                                              _vm._v(" 彩金 "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  type: "text",
                                                  clearable: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.handleInput2(
                                                      "bonusAmount",
                                                      index,
                                                      "rewardRules3"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.ruleForm.rewardRules3[
                                                      index
                                                    ].bonusAmount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleForm.rewardRules3[
                                                        index
                                                      ],
                                                      "bonusAmount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ruleForm.rewardRules3[index].bonusAmount",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "form-item-error",
                                              attrs: {
                                                prop:
                                                  "rewardRules3." +
                                                  index +
                                                  ".bonusWagerMultiplier",
                                                rules: _vm.awardsCheckedType_3[
                                                  index
                                                ].includes("彩金")
                                                  ? _vm.rules.bonusMultiplier
                                                  : [],
                                              },
                                            },
                                            [
                                              _vm._v(" 打码"),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  type: "text",
                                                  clearable: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.handleInput2(
                                                      "bonusWagerMultiplier",
                                                      index,
                                                      "rewardRules3"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.ruleForm.rewardRules3[
                                                      index
                                                    ].bonusWagerMultiplier,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleForm.rewardRules3[
                                                        index
                                                      ],
                                                      "bonusWagerMultiplier",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ruleForm.rewardRules3[index].bonusWagerMultiplier",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v("倍 "),
                                        ]
                                      : _vm._e(),
                                    _vm.awardsCheckedType_3[index].includes(
                                      "优惠券"
                                    )
                                      ? [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "form-item-error",
                                              attrs: {
                                                prop:
                                                  "rewardRules3." +
                                                  index +
                                                  ".couponId",
                                                rules: _vm.awardsCheckedType_3[
                                                  index
                                                ].includes("优惠券")
                                                  ? _vm.rules.couponId
                                                  : [],
                                              },
                                            },
                                            [
                                              _vm._v(" 优惠券 "),
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "filter-item",
                                                  staticStyle: {
                                                    width: "220px",
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      _vm.$t("商品类目"),
                                                    clearable: "",
                                                    filterable: "",
                                                    multiple: "",
                                                    "multiple-limit": 5,
                                                    "collapse-tags": "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ruleForm.rewardRules3[
                                                        index
                                                      ].couponId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ruleForm
                                                          .rewardRules3[index],
                                                        "couponId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ruleForm.rewardRules3[index].couponId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.couponList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.couponId,
                                                      attrs: {
                                                        value: item.couponId,
                                                        label: item.couponTitle,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm.awardsCheckedType_3[index].includes(
                                      "积分"
                                    )
                                      ? [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "form-item-error",
                                              attrs: {
                                                prop:
                                                  "rewardRules3." +
                                                  index +
                                                  ".points",
                                                rules: _vm.awardsCheckedType_3[
                                                  index
                                                ].includes("积分")
                                                  ? _vm.rules.points
                                                  : [],
                                              },
                                            },
                                            [
                                              _vm._v(" 积分"),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  type: "text",
                                                  clearable: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.handleInput2(
                                                      "points",
                                                      index,
                                                      "rewardRules3"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.ruleForm.rewardRules3[
                                                      index
                                                    ].points,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleForm.rewardRules3[
                                                        index
                                                      ],
                                                      "points",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ruleForm.rewardRules3[index].points",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handeldelete(
                                              index,
                                              _vm.ruleForm.rewardRules3[index],
                                              "num0"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm.ruleForm.taskAction === 2
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("任务范围"),
                            prop: "taskScope",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.taskScope,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "taskScope", $$v)
                                },
                                expression: "ruleForm.taskScope",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v(_vm._s(_vm.$t("不限制"))),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("限制方式"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.taskScope === 1
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "taskRange",
                                  attrs: { prop: "paywayList" },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 1 },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleRadioChange(
                                            1,
                                            "paywayList",
                                            "thirdPaywayList"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.ruleForm.accountType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "accountType",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.accountType",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("公司账号")))]
                                  ),
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.ruleForm.accountType !== 1,
                                      },
                                      model: {
                                        value: _vm.ruleForm.paywayList,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "paywayList",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.paywayList",
                                      },
                                    },
                                    _vm._l(
                                      _vm.companyAccountList,
                                      function (item) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: item.id,
                                            attrs: { label: item.id },
                                          },
                                          [_vm._v(_vm._s(item.accountName))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "taskRange",
                                  attrs: { prop: "thirdPaywayList" },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 2 },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleRadioChange(
                                            2,
                                            "thirdPaywayList",
                                            "paywayList"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.ruleForm.accountType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "accountType",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.accountType",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("第三方充值")))]
                                  ),
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.ruleForm.accountType !== 2,
                                      },
                                      model: {
                                        value: _vm.ruleForm.thirdPaywayList,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "thirdPaywayList",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.thirdPaywayList",
                                      },
                                    },
                                    _vm._l(
                                      _vm.thirdAccountList,
                                      function (item) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: item.payPlatformCode,
                                            attrs: {
                                              label: item.payPlatformCode,
                                            },
                                          },
                                          [_vm._v(_vm._s(item.payPlatformName))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("任务目标与奖励") } },
                        [
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-error",
                                attrs: { prop: "participationCondition1" },
                              },
                              [
                                _vm._v(" 充值金额 >= "),
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: { type: "text", clearable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.handleInput(
                                        "participationCondition1"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.participationCondition1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "participationCondition1",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.participationCondition1",
                                  },
                                }),
                                _vm._v(" 才算有效充值单数 "),
                              ],
                              1
                            ),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 2
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 充值金额 >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition3",
                                      },
                                    },
                                    [
                                      _vm._v(" 且 有效单数 >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition3"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition3",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition3",
                                        },
                                      }),
                                      _vm._v(" 才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 1
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 有效单数 >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                      _vm._v(" 才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          _vm.ruleForm.taskType !== 2
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ruleForm.taskType == 2 &&
                          _vm.ruleForm.rewardMode === 1
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                  _vm._v(
                                    " 每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期 "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ruleForm.taskType === 2 &&
                          _vm.ruleForm.rewardMode === 2
                            ? _c("p", [_c("span", [_vm._v("任务奖励")])])
                            : _vm._e(),
                          _c(
                            "table",
                            {
                              staticClass: "rechargeTable",
                              class: { weekTable: _vm.ruleForm.taskType === 2 },
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("任务目标")])
                                    : _vm._e(),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("th", [_vm._v("累计天数")])
                                    : _vm._e(),
                                  _c("th", [_vm._v("奖励类型")]),
                                  _c("th", [_vm._v("奖励内容")]),
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("操作")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.num, function (n, index) {
                                    return _vm.ruleForm.taskType !== 2 ||
                                      (_vm.ruleForm.taskType === 2 &&
                                        _vm.ruleForm.rewardMode === 1)
                                      ? _c("tr", { key: n }, [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue",
                                                    rules:
                                                      _vm.rules.conditionValue,
                                                  },
                                                },
                                                [
                                                  _vm._v(" 充值金额"),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue",
                                                          index,
                                                          "rewardRules3"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue2",
                                                    rules:
                                                      _vm.rules.conditionValue,
                                                  },
                                                },
                                                [
                                                  _vm.ruleForm.taskType !== 2
                                                    ? _c("span", [
                                                        _vm._v("有效单数"),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.ruleForm.taskType === 2 &&
                                                  _vm.ruleForm.rewardMode === 1
                                                    ? _c("span", [
                                                        _vm._v("有效天数"),
                                                      ])
                                                    : _vm._e(),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue2",
                                                          index,
                                                          "rewardRules3"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".awardsCheckedType_3",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_3,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            3,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules3[index]
                                                            .awardsCheckedType_3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules3[
                                                              index
                                                            ],
                                                            "awardsCheckedType_3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules3[index].awardsCheckedType_3",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v("倍 "),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules3[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules3[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "danger" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handeldelete(
                                                        index,
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm._l(_vm.days, function (n, index) {
                                    return _vm.ruleForm.taskType === 2 &&
                                      _vm.ruleForm.rewardMode === 2
                                      ? _c("tr", { key: n }, [
                                          _c("td", [_vm._v(_vm._s(n) + "天")]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules1." +
                                                      index +
                                                      ".awardsCheckedType_1",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_1,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            1,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules1[index]
                                                            .awardsCheckedType_1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules1[
                                                              index
                                                            ],
                                                            "awardsCheckedType_1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules1[index].awardsCheckedType_1",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                        _vm._v("倍 "),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules1[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules1[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("tr", [
                                        _c("td", [_vm._v("全勤奖")]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-error",
                                                attrs: {
                                                  prop: "rewardRules2.awardsCheckedType_2",
                                                  rules:
                                                    _vm.rules
                                                      .awardsCheckedType_2,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    on: {
                                                      change: function (value) {
                                                        return _vm.handleChange(
                                                          value,
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules2
                                                          .awardsCheckedType_2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules2,
                                                          "awardsCheckedType_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules2.awardsCheckedType_2",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.awardsType,
                                                    function (item, i) {
                                                      return _c(
                                                        "el-checkbox",
                                                        {
                                                          key: item.type,
                                                          attrs: {
                                                            label: item.type,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.type)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _vm.awardsCheckedType_2.includes(
                                              "彩金"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusAmount",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusAmount
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 彩金 "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusAmount"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusAmount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusAmount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusAmount",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusWagerMultiplier",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusMultiplier
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 打码"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusWagerMultiplier"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusWagerMultiplier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusWagerMultiplier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusWagerMultiplier",
                                                        },
                                                      }),
                                                      _vm._v("倍 "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "优惠券"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".couponId",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "优惠券"
                                                          )
                                                            ? _vm.rules.couponId
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 优惠券 "),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "filter-item",
                                                          staticStyle: {
                                                            width: "220px",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "商品类目"
                                                              ),
                                                            clearable: "",
                                                            filterable: "",
                                                            multiple: "",
                                                            "multiple-limit": 5,
                                                            "collapse-tags": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules2
                                                                .couponId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules2,
                                                                "couponId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules2.couponId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.couponList,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.couponId,
                                                                attrs: {
                                                                  value:
                                                                    item.couponId,
                                                                  label:
                                                                    item.couponTitle,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "积分"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".points",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "积分"
                                                          )
                                                            ? _vm.rules.points
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 积分"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "points"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .points,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "points",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.points",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  : _vm.ruleForm.taskAction === 3
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("任务范围"),
                            prop: "taskScope",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.taskScope,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "taskScope", $$v)
                                },
                                expression: "ruleForm.taskScope",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v(_vm._s(_vm.$t("不限制"))),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("限制方式")) +
                                    "（提现方式限制不包含数字钱包类型）"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.taskScope === 1
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "taskRange",
                                  attrs: { prop: "withdrawList1" },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 1 },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleRadioChange(
                                            1,
                                            "withdrawList1",
                                            "withdrawList2"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.ruleForm.withdrawAccountType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "withdrawAccountType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.withdrawAccountType",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("提现方式")))]
                                  ),
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.ruleForm.withdrawAccountType !==
                                          1,
                                      },
                                      model: {
                                        value: _vm.ruleForm.withdrawList1,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "withdrawList1",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.withdrawList1",
                                      },
                                    },
                                    _vm._l(
                                      _vm.withdrawTypeList,
                                      function (item) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: item.id,
                                            attrs: { label: item.id },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "taskRange",
                                  attrs: { prop: "withdrawList2" },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 2 },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleRadioChange(
                                            2,
                                            "withdrawList2",
                                            "withdrawList1"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.ruleForm.withdrawAccountType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "withdrawAccountType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.withdrawAccountType",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("提现钱包")))]
                                  ),
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.ruleForm.withdrawAccountType !==
                                          2,
                                      },
                                      model: {
                                        value: _vm.ruleForm.withdrawList2,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "withdrawList2",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.withdrawList2",
                                      },
                                    },
                                    _vm._l(
                                      _vm.paymentTypeList,
                                      function (item) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: item.id,
                                            attrs: { label: item.id },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("任务目标与奖励") } },
                        [
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-error",
                                attrs: { prop: "participationCondition1" },
                              },
                              [
                                _vm._v(" 提现金额 >= "),
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: { type: "text", clearable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.handleInput(
                                        "participationCondition1"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.participationCondition1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "participationCondition1",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.participationCondition1",
                                  },
                                }),
                                _vm._v(" 才算有效提现单数 "),
                              ],
                              1
                            ),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 2
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 提现金额 >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition3",
                                      },
                                    },
                                    [
                                      _vm._v(" 且 有效单数>= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition3"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition3",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition3",
                                        },
                                      }),
                                      _vm._v(" 才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 1
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 有效单数 >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                      _vm._v(" 才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          _vm.ruleForm.taskType !== 2
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ruleForm.taskType === 2 &&
                          _vm.ruleForm.rewardMode === 2
                            ? _c("p", [_c("span", [_vm._v("任务奖励")])])
                            : _vm._e(),
                          _vm.ruleForm.taskType == 2 &&
                          _vm.ruleForm.rewardMode === 1
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                  _vm._v(
                                    " 每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期 "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "table",
                            {
                              staticClass: "rechargeTable",
                              class: { weekTable: _vm.ruleForm.taskType === 2 },
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("任务目标")])
                                    : _vm._e(),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("th", [_vm._v("累计天数")])
                                    : _vm._e(),
                                  _c("th", [_vm._v("奖励类型")]),
                                  _c("th", [_vm._v("奖励内容")]),
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("操作")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.num, function (n, index) {
                                    return _vm.ruleForm.taskType !== 2 ||
                                      (_vm.ruleForm.taskType === 2 &&
                                        _vm.ruleForm.rewardMode === 1)
                                      ? _c("tr", { key: n }, [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue",
                                                    rules:
                                                      _vm.rules.conditionValue,
                                                  },
                                                },
                                                [
                                                  _vm._v(" 提现金额 "),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue",
                                                          index,
                                                          "rewardRules3"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue2",
                                                    rules:
                                                      _vm.rules.conditionValue2,
                                                  },
                                                },
                                                [
                                                  _vm.ruleForm.taskType !== 2
                                                    ? _c("span", [
                                                        _vm._v("有效单数"),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.ruleForm.taskType === 2 &&
                                                  _vm.ruleForm.rewardMode === 1
                                                    ? _c("span", [
                                                        _vm._v("有效天数"),
                                                      ])
                                                    : _vm._e(),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue2",
                                                          index,
                                                          "rewardRules3"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".awardsCheckedType_3",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_3,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            3,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules3[index]
                                                            .awardsCheckedType_3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules3[
                                                              index
                                                            ],
                                                            "awardsCheckedType_3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules3[index].awardsCheckedType_3",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v("倍 "),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules3[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules3[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "danger" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handeldelete(
                                                        index,
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm._l(_vm.days, function (n, index) {
                                    return _vm.ruleForm.taskType === 2 &&
                                      _vm.ruleForm.rewardMode === 2
                                      ? _c("tr", { key: n }, [
                                          _c("td", [_vm._v(_vm._s(n) + "天")]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules1." +
                                                      index +
                                                      ".awardsCheckedType_1",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_1,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            1,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules1[index]
                                                            .awardsCheckedType_1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules1[
                                                              index
                                                            ],
                                                            "awardsCheckedType_1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules1[index].awardsCheckedType_1",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                        _vm._v("倍 "),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules1[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules1[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("tr", [
                                        _c("td", [_vm._v("全勤奖")]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-error",
                                                attrs: {
                                                  prop: "rewardRules2.awardsCheckedType_2",
                                                  rules:
                                                    _vm.rules
                                                      .awardsCheckedType_2,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    on: {
                                                      change: function (value) {
                                                        return _vm.handleChange(
                                                          value,
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules2
                                                          .awardsCheckedType_2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules2,
                                                          "awardsCheckedType_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules2.awardsCheckedType_2",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.awardsType,
                                                    function (item, i) {
                                                      return _c(
                                                        "el-checkbox",
                                                        {
                                                          key: item.type,
                                                          attrs: {
                                                            label: item.type,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.type)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _vm.awardsCheckedType_2.includes(
                                              "彩金"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusAmount",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusAmount
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 彩金 "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusAmount"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusAmount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusAmount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusAmount",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusWagerMultiplier",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusMultiplier
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 打码"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusWagerMultiplier"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusWagerMultiplier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusWagerMultiplier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusWagerMultiplier",
                                                        },
                                                      }),
                                                      _vm._v("倍 "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "优惠券"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".couponId",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "优惠券"
                                                          )
                                                            ? _vm.rules.couponId
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 优惠券 "),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "filter-item",
                                                          staticStyle: {
                                                            width: "220px",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "商品类目"
                                                              ),
                                                            clearable: "",
                                                            filterable: "",
                                                            multiple: "",
                                                            "multiple-limit": 5,
                                                            "collapse-tags": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules2
                                                                .couponId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules2,
                                                                "couponId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules2.couponId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.couponList,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.couponId,
                                                                attrs: {
                                                                  value:
                                                                    item.couponId,
                                                                  label:
                                                                    item.couponTitle,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "积分"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".points",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "积分"
                                                          )
                                                            ? _vm.rules.points
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 积分"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "points"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .points,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "points",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.points",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  : _vm.ruleForm.taskAction === 4
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("任务范围"),
                            prop: "taskScope",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.taskScope,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "taskScope", $$v)
                                },
                                expression: "ruleForm.taskScope",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v(_vm._s(_vm.$t("不限制"))),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("限制方式"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.taskScope === 1
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "gameSelection" } },
                            [
                              _c(
                                "el-form-item",
                                _vm._l(_vm.ruleForm.gameList, function (item) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: item.value,
                                      attrs: { label: item.value },
                                      model: {
                                        value: _vm.taskMethod,
                                        callback: function ($$v) {
                                          _vm.taskMethod = $$v
                                        },
                                        expression: "taskMethod",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                }),
                                1
                              ),
                              _c(
                                "el-tabs",
                                { attrs: { type: "border-card" } },
                                _vm._l(
                                  _vm.filterGameList,
                                  function (gameType, index) {
                                    return _c(
                                      "el-tab-pane",
                                      {
                                        key: gameType.value,
                                        attrs: { label: gameType.label },
                                      },
                                      _vm._l(
                                        gameType.children,
                                        function (category, cIndex) {
                                          return _c(
                                            "el-collapse",
                                            {
                                              key: "category_" + category.value,
                                              model: {
                                                value: category.collapse,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    category,
                                                    "collapse",
                                                    $$v
                                                  )
                                                },
                                                expression: "category.collapse",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-collapse-item",
                                                {
                                                  attrs: {
                                                    name: category.value,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "title",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "el-checkbox",
                                                              {
                                                                attrs: {
                                                                  indeterminate:
                                                                    category.indeterminate,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleCheckedChange(
                                                                        category
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    category.checked,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        category,
                                                                        "checked",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "category.checked",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      category.label
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  category.children
                                                    ? [
                                                        gameType.value ===
                                                        "1000005"
                                                          ? _vm._l(
                                                              category.children,
                                                              function (
                                                                subCategory,
                                                                sIndex
                                                              ) {
                                                                return _c(
                                                                  "el-collapse",
                                                                  {
                                                                    key:
                                                                      "sub_" +
                                                                      subCategory.value,
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "20px",
                                                                      },
                                                                    model: {
                                                                      value:
                                                                        subCategory.collapse,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            subCategory,
                                                                            "collapse",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "subCategory.collapse",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-collapse-item",
                                                                      {
                                                                        attrs: {
                                                                          name: subCategory.value,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "title",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _c(
                                                                                      "el-checkbox",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            indeterminate:
                                                                                              subCategory.indeterminate,
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.handleCheckedChange(
                                                                                                subCategory
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              subCategory.checked,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  subCategory,
                                                                                                  "checked",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "subCategory.checked",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              subCategory.label
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      },
                                                                      [
                                                                        subCategory.children
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-left":
                                                                                      "20px",
                                                                                  },
                                                                              },
                                                                              _vm._l(
                                                                                subCategory.children,
                                                                                function (
                                                                                  item
                                                                                ) {
                                                                                  return _c(
                                                                                    "el-checkbox",
                                                                                    {
                                                                                      key: item.value,
                                                                                      attrs:
                                                                                        {
                                                                                          indeterminate:
                                                                                            item.indeterminate,
                                                                                        },
                                                                                      on: {
                                                                                        change:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.handleCheckedChange(
                                                                                              item
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            item.checked,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                item,
                                                                                                "checked",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "item.checked",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            item.label
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                },
                                                              },
                                                              _vm._l(
                                                                category.children,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-checkbox",
                                                                    {
                                                                      key: item.value,
                                                                      attrs: {
                                                                        indeterminate:
                                                                          item.indeterminate,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleCheckedChange(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.checked,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "checked",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "item.checked",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.label
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("任务目标与奖励") } },
                        [
                          _c("span", [_vm._v("计算方式")]),
                          _c("br"),
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.initFormData },
                              model: {
                                value: _vm.ruleForm.calculationMethod,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "calculationMethod",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.calculationMethod",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("投注金额"))),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v(_vm._s(_vm.$t("盈利金额"))),
                              ]),
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v(_vm._s(_vm.$t("亏损金额"))),
                              ]),
                              _c("el-radio", { attrs: { label: 4 } }, [
                                _vm._v(_vm._s(_vm.$t("连赢加奖"))),
                              ]),
                              _c("el-radio", { attrs: { label: 5 } }, [
                                _vm._v(_vm._s(_vm.$t("投注单量"))),
                              ]),
                              _c("el-radio", { attrs: { label: 6 } }, [
                                _vm._v(_vm._s(_vm.$t("单注派奖"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "form-item-error",
                                  attrs: { prop: "participationCondition1" },
                                },
                                [
                                  _vm._v(" 投注金额 >= "),
                                  _c("el-input", {
                                    staticStyle: { width: "100px" },
                                    attrs: { type: "text", clearable: "" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.handleInput(
                                          "participationCondition1"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.ruleForm.participationCondition1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "participationCondition1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ruleForm.participationCondition1",
                                    },
                                  }),
                                  _vm._v(" 才算有效投注单数 "),
                                ],
                                1
                              ),
                              _vm.ruleForm.taskType === 2 &&
                              _vm.ruleForm.rewardMode === 2
                                ? [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "form-item-error",
                                        attrs: {
                                          prop: "participationCondition2",
                                        },
                                      },
                                      [
                                        _vm.ruleForm.calculationMethod === 1
                                          ? _c("span", [_vm._v("投注金额 >= ")])
                                          : _vm.ruleForm.calculationMethod === 2
                                          ? _c("span", [_vm._v("盈利金额 >= ")])
                                          : _vm.ruleForm.calculationMethod === 3
                                          ? _c("span", [_vm._v("亏损金额 >= ")])
                                          : _vm.ruleForm.calculationMethod === 4
                                          ? _c("span", [_vm._v("连赢期数 >= ")])
                                          : _vm.ruleForm.calculationMethod === 5
                                          ? _c("span", [_vm._v("投注单量 >= ")])
                                          : _c("span", [
                                              _vm._v("中奖金额 >= "),
                                            ]),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            type: "text",
                                            clearable: "",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.handleInput(
                                                "participationCondition2"
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.ruleForm
                                                .participationCondition2,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "participationCondition2",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.participationCondition2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.ruleForm.calculationMethod !== 5 &&
                                    _vm.ruleForm.calculationMethod !== 6
                                      ? [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass: "form-item-error",
                                              attrs: {
                                                prop: "participationCondition3",
                                              },
                                            },
                                            [
                                              _vm._v(" 且 有效单数 >= "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  type: "text",
                                                  clearable: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.handleInput1(
                                                      "participationCondition3"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.ruleForm
                                                      .participationCondition3,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleForm,
                                                      "participationCondition3",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ruleForm.participationCondition3",
                                                },
                                              }),
                                              _vm._v(" 才算有效天数 "),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm.ruleForm.taskType === 2 &&
                              _vm.ruleForm.rewardMode === 1
                                ? [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "form-item-error",
                                        attrs: {
                                          prop: "participationCondition2",
                                        },
                                      },
                                      [
                                        _vm._v(" 有效单数 >= "),
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            type: "text",
                                            clearable: "",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.handleInput1(
                                                "participationCondition2"
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.ruleForm
                                                .participationCondition2,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "participationCondition2",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.participationCondition2",
                                          },
                                        }),
                                        _vm._v(" 才算有效天数 "),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.ruleForm.taskType !== 2
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ruleForm.taskType === 2 &&
                          _vm.ruleForm.rewardMode === 2
                            ? _c("p", [_c("span", [_vm._v("任务奖励")])])
                            : _vm._e(),
                          _vm.ruleForm.taskType == 2 &&
                          _vm.ruleForm.rewardMode === 1
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                  _vm._v(
                                    " 每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期 "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "table",
                            {
                              staticClass: "rechargeTable",
                              class: { weekTable: _vm.ruleForm.taskType === 2 },
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("任务目标")])
                                    : _vm._e(),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("th", [_vm._v("累计天数")])
                                    : _vm._e(),
                                  _c("th", [_vm._v("奖励类型")]),
                                  _c("th", [_vm._v("奖励内容")]),
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("操作")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.num, function (n, index) {
                                    return _vm.ruleForm.taskType !== 2 ||
                                      (_vm.ruleForm.taskType === 2 &&
                                        _vm.ruleForm.rewardMode === 1)
                                      ? _c("tr", { key: n }, [
                                          _c(
                                            "td",
                                            [
                                              _vm.ruleForm.calculationMethod ===
                                              1
                                                ? _c("span", [
                                                    _vm._v("投注金额"),
                                                  ])
                                                : _vm.ruleForm
                                                    .calculationMethod === 2
                                                ? _c("span", [
                                                    _vm._v("盈利金额"),
                                                  ])
                                                : _vm.ruleForm
                                                    .calculationMethod === 3
                                                ? _c("span", [
                                                    _vm._v("亏损金额"),
                                                  ])
                                                : _vm.ruleForm
                                                    .calculationMethod === 4
                                                ? _c("span", [
                                                    _vm._v("连赢期数"),
                                                  ])
                                                : _vm.ruleForm
                                                    .calculationMethod === 5
                                                ? _c("span", [
                                                    _vm._v("投注单量"),
                                                  ])
                                                : _c("span", [
                                                    _vm._v("中奖金额"),
                                                  ]),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue",
                                                    rules:
                                                      _vm.rules.conditionValue,
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue",
                                                          index,
                                                          "rewardRules3"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.ruleForm.rewardMode === 2
                                                ? [
                                                    _vm.ruleForm
                                                      .calculationMethod !==
                                                      5 &&
                                                    _vm.ruleForm
                                                      .calculationMethod !== 6
                                                      ? _c(
                                                          "p",
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticClass:
                                                                  "form-item-error",
                                                                attrs: {
                                                                  prop:
                                                                    "rewardRules3." +
                                                                    index +
                                                                    ".conditionValue2",
                                                                  rules:
                                                                    _vm.rules
                                                                      .conditionValue2,
                                                                },
                                                              },
                                                              [
                                                                _vm.ruleForm
                                                                  .taskType !==
                                                                2
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "有效单数"
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                _vm.ruleForm
                                                                  .taskType ===
                                                                  2 &&
                                                                _vm.ruleForm
                                                                  .rewardMode ===
                                                                  1
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "有效天数"
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100px",
                                                                  },
                                                                  attrs: {
                                                                    type: "text",
                                                                    clearable:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleInput2(
                                                                          "conditionValue2",
                                                                          index,
                                                                          "rewardRules3"
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .ruleForm
                                                                        .rewardRules3[
                                                                        index
                                                                      ]
                                                                        .conditionValue2,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .ruleForm
                                                                            .rewardRules3[
                                                                            index
                                                                          ],
                                                                          "conditionValue2",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "ruleForm.rewardRules3[index].conditionValue2",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                              _vm.ruleForm.rewardMode != 2
                                                ? [
                                                    _c(
                                                      "p",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            staticClass:
                                                              "form-item-error",
                                                            attrs: {
                                                              prop:
                                                                "rewardRules3." +
                                                                index +
                                                                ".conditionValue2",
                                                              rules:
                                                                _vm.rules
                                                                  .conditionValue2,
                                                            },
                                                          },
                                                          [
                                                            _vm.ruleForm
                                                              .taskType !== 2
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "有效单数"
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _vm.ruleForm
                                                              .taskType === 2 &&
                                                            _vm.ruleForm
                                                              .rewardMode === 1
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "有效天数"
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "100px",
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleInput2(
                                                                      "conditionValue2",
                                                                      index,
                                                                      "rewardRules3"
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.ruleForm
                                                                    .rewardRules3[
                                                                    index
                                                                  ]
                                                                    .conditionValue2,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .ruleForm
                                                                        .rewardRules3[
                                                                        index
                                                                      ],
                                                                      "conditionValue2",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "ruleForm.rewardRules3[index].conditionValue2",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".awardsCheckedType_3",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_3,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            3,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules3[index]
                                                            .awardsCheckedType_3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules3[
                                                              index
                                                            ],
                                                            "awardsCheckedType_3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules3[index].awardsCheckedType_3",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v("倍 "),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules3[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules3[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "danger" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handeldelete(
                                                        index,
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm._l(_vm.days, function (n, index) {
                                    return _vm.ruleForm.taskType === 2 &&
                                      _vm.ruleForm.rewardMode === 2
                                      ? _c("tr", { key: n }, [
                                          _c("td", [_vm._v(_vm._s(n) + "天")]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules1." +
                                                      index +
                                                      ".awardsCheckedType_1",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_1,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            1,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules1[index]
                                                            .awardsCheckedType_1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules1[
                                                              index
                                                            ],
                                                            "awardsCheckedType_1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules1[index].awardsCheckedType_1",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                        _vm._v("倍 "),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules1[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules1[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("tr", [
                                        _c("td", [_vm._v("全勤奖")]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-error",
                                                attrs: {
                                                  prop: "rewardRules2.awardsCheckedType_2",
                                                  rules:
                                                    _vm.rules
                                                      .awardsCheckedType_2,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    on: {
                                                      change: function (value) {
                                                        return _vm.handleChange(
                                                          value,
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules2
                                                          .awardsCheckedType_2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules2,
                                                          "awardsCheckedType_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules2.awardsCheckedType_2",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.awardsType,
                                                    function (item, i) {
                                                      return _c(
                                                        "el-checkbox",
                                                        {
                                                          key: item.type,
                                                          attrs: {
                                                            label: item.type,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.type)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _vm.awardsCheckedType_2.includes(
                                              "彩金"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusAmount",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusAmount
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 彩金 "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusAmount"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusAmount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusAmount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusAmount",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusWagerMultiplier",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusMultiplier
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 打码"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusWagerMultiplier"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusWagerMultiplier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusWagerMultiplier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusWagerMultiplier",
                                                        },
                                                      }),
                                                      _vm._v("倍 "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "优惠券"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".couponId",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "优惠券"
                                                          )
                                                            ? _vm.rules.couponId
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 优惠券 "),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "filter-item",
                                                          staticStyle: {
                                                            width: "220px",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "商品类目"
                                                              ),
                                                            clearable: "",
                                                            filterable: "",
                                                            multiple: "",
                                                            "multiple-limit": 5,
                                                            "collapse-tags": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules2
                                                                .couponId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules2,
                                                                "couponId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules2.couponId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.couponList,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.couponId,
                                                                attrs: {
                                                                  value:
                                                                    item.couponId,
                                                                  label:
                                                                    item.couponTitle,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "积分"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".points",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "积分"
                                                          )
                                                            ? _vm.rules.points
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 积分"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "points"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .points,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "points",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.points",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm.ruleForm.taskAction === 5
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("任务范围"),
                            prop: "taskScope",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.taskScope,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "taskScope", $$v)
                                },
                                expression: "ruleForm.taskScope",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v(_vm._s(_vm.$t("不限制"))),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("限制方式"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.taskScope === 1
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "taskRangeFocusChecked" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.taskRangeFocusChecked,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "taskRangeFocusChecked",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.taskRangeFocusChecked",
                                  },
                                },
                                _vm._l(
                                  _vm.formData.taskRangeFocusList,
                                  function (taskRange) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: taskRange,
                                        attrs: { label: taskRange },
                                      },
                                      [_vm._v(_vm._s(taskRange))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("任务目标与奖励") } },
                        [
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-error",
                                attrs: { prop: "participationCondition1" },
                              },
                              [
                                _vm._v(" 投注金额 >= "),
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: { type: "text", clearable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.handleInput(
                                        "participationCondition1"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.participationCondition1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "participationCondition1",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.participationCondition1",
                                  },
                                }),
                                _vm._v(" 才算有效投注单数 "),
                              ],
                              1
                            ),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 2
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 关注人数 >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition3",
                                      },
                                    },
                                    [
                                      _vm._v(" 且 有效单数 "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition3"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition3",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition3",
                                        },
                                      }),
                                      _vm._v(" 才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 1
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 有效单数 >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                      _vm._v(" 才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          _vm.ruleForm.taskType !== 2
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ruleForm.taskType === 2 &&
                          _vm.ruleForm.rewardMode === 2
                            ? _c("p", [_c("span", [_vm._v("任务奖励")])])
                            : _vm._e(),
                          _vm.ruleForm.taskType == 2 &&
                          _vm.ruleForm.rewardMode === 1
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                  _vm._v(
                                    " 每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期 "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "table",
                            {
                              staticClass: "rechargeTable",
                              class: { weekTable: _vm.ruleForm.taskType === 2 },
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("任务目标")])
                                    : _vm._e(),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("th", [_vm._v("累计天数")])
                                    : _vm._e(),
                                  _c("th", [_vm._v("奖励类型")]),
                                  _c("th", [_vm._v("奖励内容")]),
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("操作")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.num, function (n, index) {
                                    return _vm.ruleForm.taskType !== 2 ||
                                      (_vm.ruleForm.taskType === 2 &&
                                        _vm.ruleForm.rewardMode === 1)
                                      ? _c("tr", { key: n }, [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue",
                                                    rules:
                                                      _vm.rules.conditionValue,
                                                  },
                                                },
                                                [
                                                  _vm._v(" 关注人数 "),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue",
                                                          index,
                                                          "rewardRules3",
                                                          "num"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue2",
                                                    rules:
                                                      _vm.rules.conditionValue2,
                                                  },
                                                },
                                                [
                                                  _vm.ruleForm.taskType !== 2
                                                    ? _c("span", [
                                                        _vm._v("有效单数"),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.ruleForm.taskType === 2 &&
                                                  _vm.ruleForm.rewardMode === 1
                                                    ? _c("span", [
                                                        _vm._v("有效天数"),
                                                      ])
                                                    : _vm._e(),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue2",
                                                          index,
                                                          "rewardRules3",
                                                          "num"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".awardsCheckedType_3",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_3,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            3,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules3[index]
                                                            .awardsCheckedType_3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules3[
                                                              index
                                                            ],
                                                            "awardsCheckedType_3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules3[index].awardsCheckedType_3",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v("倍 "),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules3[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules3[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "danger" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handeldelete(
                                                        index,
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm._l(_vm.days, function (n, index) {
                                    return _vm.ruleForm.taskType === 2 &&
                                      _vm.ruleForm.rewardMode === 2
                                      ? _c("tr", { key: n }, [
                                          _c("td", [_vm._v(_vm._s(n) + "天")]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules1." +
                                                      index +
                                                      ".awardsCheckedType_1",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_1,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            1,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules1[index]
                                                            .awardsCheckedType_1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules1[
                                                              index
                                                            ],
                                                            "awardsCheckedType_1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules1[index].awardsCheckedType_1",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                        _vm._v("倍 "),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules1[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules1[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("tr", [
                                        _c("td", [_vm._v("全勤奖")]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-error",
                                                attrs: {
                                                  prop: "rewardRules2.awardsCheckedType_2",
                                                  rules:
                                                    _vm.rules
                                                      .awardsCheckedType_2,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    on: {
                                                      change: function (value) {
                                                        return _vm.handleChange(
                                                          value,
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules2
                                                          .awardsCheckedType_2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules2,
                                                          "awardsCheckedType_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules2.awardsCheckedType_2",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.awardsType,
                                                    function (item, i) {
                                                      return _c(
                                                        "el-checkbox",
                                                        {
                                                          key: item.type,
                                                          attrs: {
                                                            label: item.type,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.type)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _vm.awardsCheckedType_2.includes(
                                              "彩金"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusAmount",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusAmount
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 彩金 "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusAmount"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusAmount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusAmount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusAmount",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusWagerMultiplier",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusMultiplier
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 打码"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusWagerMultiplier"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusWagerMultiplier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusWagerMultiplier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusWagerMultiplier",
                                                        },
                                                      }),
                                                      _vm._v("倍 "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "优惠券"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".couponId",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "优惠券"
                                                          )
                                                            ? _vm.rules.couponId
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 优惠券 "),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "filter-item",
                                                          staticStyle: {
                                                            width: "220px",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "商品类目"
                                                              ),
                                                            clearable: "",
                                                            filterable: "",
                                                            multiple: "",
                                                            "multiple-limit": 5,
                                                            "collapse-tags": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules2
                                                                .couponId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules2,
                                                                "couponId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules2.couponId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.couponList,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.couponId,
                                                                attrs: {
                                                                  value:
                                                                    item.couponId,
                                                                  label:
                                                                    item.couponTitle,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "积分"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".points",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "积分"
                                                          )
                                                            ? _vm.rules.points
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 积分"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "points"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .points,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "points",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.points",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  : _vm.ruleForm.taskAction === 6
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("任务范围"),
                            prop: "taskScope",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.taskScope,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "taskScope", $$v)
                                },
                                expression: "ruleForm.taskScope",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v(_vm._s(_vm.$t("不限制"))),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("限制方式"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.taskScope === 1
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "taskRangeFatieChecked" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.taskRangeFatieChecked,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "taskRangeFatieChecked",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.taskRangeFatieChecked",
                                  },
                                },
                                _vm._l(
                                  _vm.formData.taskRangeFatieList,
                                  function (taskRange) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: taskRange,
                                        attrs: { label: taskRange },
                                      },
                                      [_vm._v(_vm._s(taskRange))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("任务目标与奖励") } },
                        [
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-error",
                                attrs: { prop: "participationCondition1" },
                              },
                              [
                                _vm._v(" 帖子字数 >= "),
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: { type: "text", clearable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.handleInput1(
                                        "participationCondition1"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.participationCondition1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "participationCondition1",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.participationCondition1",
                                  },
                                }),
                                _vm._v(" 才算有效帖子 "),
                              ],
                              1
                            ),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 2
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 有效帖子数量 >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                      _vm._v(" 才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 1
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 有效帖子数量>= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                      _vm._v("才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          _vm.ruleForm.taskType !== 2
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ruleForm.taskType === 2 &&
                          _vm.ruleForm.rewardMode === 2
                            ? _c("p", [_c("span", [_vm._v("任务奖励")])])
                            : _vm._e(),
                          _vm.ruleForm.taskType == 2 &&
                          _vm.ruleForm.rewardMode === 1
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                  _vm._v(
                                    " 每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期 "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "table",
                            {
                              staticClass: "rechargeTable",
                              class: { weekTable: _vm.ruleForm.taskType === 2 },
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("任务目标")])
                                    : _vm._e(),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("th", [_vm._v("累计天数")])
                                    : _vm._e(),
                                  _c("th", [_vm._v("奖励类型")]),
                                  _c("th", [_vm._v("奖励内容")]),
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("操作")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.num, function (n, index) {
                                    return _vm.ruleForm.taskType !== 2 ||
                                      (_vm.ruleForm.taskType === 2 &&
                                        _vm.ruleForm.rewardMode === 1)
                                      ? _c("tr", { key: n }, [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue",
                                                    rules:
                                                      _vm.rules.conditionValue,
                                                  },
                                                },
                                                [
                                                  _vm._v(" 有效帖子数量 "),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue",
                                                          index,
                                                          "rewardRules3",
                                                          "num"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.ruleForm.taskType === 2 &&
                                              _vm.ruleForm.rewardMode === 1
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".conditionValue2",
                                                          rules:
                                                            _vm.rules
                                                              .conditionValue2,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 有效天数"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "conditionValue2",
                                                                index,
                                                                "rewardRules3",
                                                                "num"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].conditionValue2,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "conditionValue2",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].conditionValue2",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".awardsCheckedType_3",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_3,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            3,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules3[index]
                                                            .awardsCheckedType_3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules3[
                                                              index
                                                            ],
                                                            "awardsCheckedType_3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules3[index].awardsCheckedType_3",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v("倍 "),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules3[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules3[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "danger" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handeldelete(
                                                        index,
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm._l(_vm.days, function (n, index) {
                                    return _vm.ruleForm.taskType === 2 &&
                                      _vm.ruleForm.rewardMode === 2
                                      ? _c("tr", { key: n }, [
                                          _c("td", [_vm._v(_vm._s(n) + "天")]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules1." +
                                                      index +
                                                      ".awardsCheckedType_1",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_1,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            1,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules1[index]
                                                            .awardsCheckedType_1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules1[
                                                              index
                                                            ],
                                                            "awardsCheckedType_1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules1[index].awardsCheckedType_1",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                        _vm._v("倍 "),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules1[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules1[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("tr", [
                                        _c("td", [_vm._v("全勤奖")]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-error",
                                                attrs: {
                                                  prop: "rewardRules2.awardsCheckedType_2",
                                                  rules:
                                                    _vm.rules
                                                      .awardsCheckedType_2,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    on: {
                                                      change: function (value) {
                                                        return _vm.handleChange(
                                                          value,
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules2
                                                          .awardsCheckedType_2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules2,
                                                          "awardsCheckedType_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules2.awardsCheckedType_2",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.awardsType,
                                                    function (item, i) {
                                                      return _c(
                                                        "el-checkbox",
                                                        {
                                                          key: item.type,
                                                          attrs: {
                                                            label: item.type,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.type)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _vm.awardsCheckedType_2.includes(
                                              "彩金"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusAmount",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusAmount
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 彩金 "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusAmount"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusAmount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusAmount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusAmount",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusWagerMultiplier",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusMultiplier
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 打码"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusWagerMultiplier"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusWagerMultiplier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusWagerMultiplier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusWagerMultiplier",
                                                        },
                                                      }),
                                                      _vm._v("倍 "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "优惠券"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".couponId",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "优惠券"
                                                          )
                                                            ? _vm.rules.couponId
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 优惠券 "),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "filter-item",
                                                          staticStyle: {
                                                            width: "220px",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "商品类目"
                                                              ),
                                                            clearable: "",
                                                            filterable: "",
                                                            multiple: "",
                                                            "multiple-limit": 5,
                                                            "collapse-tags": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules2
                                                                .couponId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules2,
                                                                "couponId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules2.couponId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.couponList,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.couponId,
                                                                attrs: {
                                                                  value:
                                                                    item.couponId,
                                                                  label:
                                                                    item.couponTitle,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "积分"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".points",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "积分"
                                                          )
                                                            ? _vm.rules.points
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 积分"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "points"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .points,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "points",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.points",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  : _vm.ruleForm.taskAction === 7
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("任务范围"),
                            prop: "taskScope",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.taskScope,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "taskScope", $$v)
                                },
                                expression: "ruleForm.taskScope",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v(_vm._s(_vm.$t("不限制"))),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("限制方式"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.taskScope === 1
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "taskRangeRemarkChecked" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.taskRangeRemarkChecked,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "taskRangeRemarkChecked",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.taskRangeRemarkChecked",
                                  },
                                },
                                _vm._l(
                                  _vm.formData.taskRangeRemarkList,
                                  function (taskRange) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: taskRange,
                                        attrs: { label: taskRange },
                                      },
                                      [_vm._v(_vm._s(taskRange))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("任务目标与奖励") } },
                        [
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-error",
                                attrs: { prop: "participationCondition1" },
                              },
                              [
                                _vm._v(" 评论字数 >= "),
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: { type: "text", clearable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.handleInput1(
                                        "participationCondition1"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.participationCondition1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "participationCondition1",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.participationCondition1",
                                  },
                                }),
                                _vm._v(" 才算有效评论 "),
                              ],
                              1
                            ),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 2
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 有效评论数量 >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                      _vm._v(" 才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 1
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 有效评论数量>= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                      _vm._v("才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          _vm.ruleForm.taskType !== 2
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ruleForm.taskType === 2 &&
                          _vm.ruleForm.rewardMode === 2
                            ? _c("p", [_c("span", [_vm._v("任务奖励")])])
                            : _vm._e(),
                          _vm.ruleForm.taskType == 2 &&
                          _vm.ruleForm.rewardMode === 1
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                  _vm._v(
                                    " 每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期 "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "table",
                            {
                              staticClass: "rechargeTable",
                              class: { weekTable: _vm.ruleForm.taskType === 2 },
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("任务目标")])
                                    : _vm._e(),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("th", [_vm._v("累计天数")])
                                    : _vm._e(),
                                  _c("th", [_vm._v("奖励类型")]),
                                  _c("th", [_vm._v("奖励内容")]),
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("操作")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.num, function (n, index) {
                                    return _vm.ruleForm.taskType !== 2 ||
                                      (_vm.ruleForm.taskType === 2 &&
                                        _vm.ruleForm.rewardMode === 1)
                                      ? _c("tr", { key: n }, [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue",
                                                    rules:
                                                      _vm.rules.conditionValue,
                                                  },
                                                },
                                                [
                                                  _vm._v(" 有效评论数量 "),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue",
                                                          index,
                                                          "rewardRules3",
                                                          "num"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.ruleForm.taskType === 2 &&
                                              _vm.ruleForm.rewardMode === 1
                                                ? _c(
                                                    "p",
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          staticClass:
                                                            "form-item-error",
                                                          attrs: {
                                                            prop:
                                                              "rewardRules3." +
                                                              index +
                                                              ".conditionValue2",
                                                            rules:
                                                              _vm.rules
                                                                .conditionValue2,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(" 有效天数"),
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "100px",
                                                            },
                                                            attrs: {
                                                              type: "text",
                                                              clearable: "",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleInput2(
                                                                  "conditionValue2",
                                                                  index,
                                                                  "rewardRules3",
                                                                  "num"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ]
                                                                  .conditionValue2,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules3[
                                                                      index
                                                                    ],
                                                                    "conditionValue2",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules3[index].conditionValue2",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".awardsCheckedType_3",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_3,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            3,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules3[index]
                                                            .awardsCheckedType_3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules3[
                                                              index
                                                            ],
                                                            "awardsCheckedType_3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules3[index].awardsCheckedType_3",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v("倍 "),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules3[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules3[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "danger" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handeldelete(
                                                        index,
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm._l(_vm.days, function (n, index) {
                                    return _vm.ruleForm.taskType === 2 &&
                                      _vm.ruleForm.rewardMode === 2
                                      ? _c("tr", { key: n }, [
                                          _c("td", [_vm._v(_vm._s(n) + "天")]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules1." +
                                                      index +
                                                      ".awardsCheckedType_1",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_1,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            1,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules1[index]
                                                            .awardsCheckedType_1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules1[
                                                              index
                                                            ],
                                                            "awardsCheckedType_1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules1[index].awardsCheckedType_1",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                        _vm._v("倍 "),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules1[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules1[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("tr", [
                                        _c("td", [_vm._v("全勤奖")]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-error",
                                                attrs: {
                                                  prop: "rewardRules2.awardsCheckedType_2",
                                                  rules:
                                                    _vm.rules
                                                      .awardsCheckedType_2,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    on: {
                                                      change: function (value) {
                                                        return _vm.handleChange(
                                                          value,
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules2
                                                          .awardsCheckedType_2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules2,
                                                          "awardsCheckedType_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules2.awardsCheckedType_2",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.awardsType,
                                                    function (item, i) {
                                                      return _c(
                                                        "el-checkbox",
                                                        {
                                                          key: item.type,
                                                          attrs: {
                                                            label: item.type,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.type)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _vm.awardsCheckedType_2.includes(
                                              "彩金"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusAmount",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusAmount
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 彩金 "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusAmount"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusAmount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusAmount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusAmount",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusWagerMultiplier",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusMultiplier
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 打码"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusWagerMultiplier"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusWagerMultiplier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusWagerMultiplier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusWagerMultiplier",
                                                        },
                                                      }),
                                                      _vm._v("倍 "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "优惠券"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".couponId",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "优惠券"
                                                          )
                                                            ? _vm.rules.couponId
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 优惠券 "),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "filter-item",
                                                          staticStyle: {
                                                            width: "220px",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "商品类目"
                                                              ),
                                                            clearable: "",
                                                            filterable: "",
                                                            multiple: "",
                                                            "multiple-limit": 5,
                                                            "collapse-tags": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules2
                                                                .couponId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules2,
                                                                "couponId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules2.couponId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.couponList,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.couponId,
                                                                attrs: {
                                                                  value:
                                                                    item.couponId,
                                                                  label:
                                                                    item.couponTitle,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "积分"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".points",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "积分"
                                                          )
                                                            ? _vm.rules.points
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 积分"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "points"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .points,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "points",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.points",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  : _vm.ruleForm.taskAction === 8
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("任务范围"),
                            prop: "taskScope",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.taskScope,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "taskScope", $$v)
                                },
                                expression: "ruleForm.taskScope",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v(_vm._s(_vm.$t("不限制"))),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("限制方式"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.taskScope === 1
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "taskRangeRewardChecked" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.taskRangeRewardChecked,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "taskRangeRewardChecked",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.taskRangeRewardChecked",
                                  },
                                },
                                _vm._l(
                                  _vm.formData.taskRangeRewardList,
                                  function (taskRange) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: taskRange,
                                        attrs: { label: taskRange },
                                      },
                                      [_vm._v(_vm._s(taskRange))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("任务目标与奖励") } },
                        [
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-error",
                                attrs: { prop: "participationCondition1" },
                              },
                              [
                                _vm._v(" 打赏金额 >= "),
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: { type: "text", clearable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.handleInput(
                                        "participationCondition1"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.participationCondition1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "participationCondition1",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.participationCondition1",
                                  },
                                }),
                                _vm._v(" 才算有效打赏单数 "),
                              ],
                              1
                            ),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 2
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 打赏金额 >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition3",
                                      },
                                    },
                                    [
                                      _vm._v(" 且 有效单数 "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition3"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition3",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition3",
                                        },
                                      }),
                                      _vm._v(" 才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 1
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 有效单数>= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                      _vm._v("才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          _vm.ruleForm.taskType !== 2
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ruleForm.taskType === 2 &&
                          _vm.ruleForm.rewardMode === 2
                            ? _c("p", [_c("span", [_vm._v("任务奖励")])])
                            : _vm._e(),
                          _vm.ruleForm.taskType == 2 &&
                          _vm.ruleForm.rewardMode === 1
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                  _vm._v(
                                    " 每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期 "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "table",
                            {
                              staticClass: "rechargeTable",
                              class: { weekTable: _vm.ruleForm.taskType === 2 },
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("任务目标")])
                                    : _vm._e(),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("th", [_vm._v("累计天数")])
                                    : _vm._e(),
                                  _c("th", [_vm._v("奖励类型")]),
                                  _c("th", [_vm._v("奖励内容")]),
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("操作")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.num, function (n, index) {
                                    return _vm.ruleForm.taskType !== 2 ||
                                      (_vm.ruleForm.taskType === 2 &&
                                        _vm.ruleForm.rewardMode === 1)
                                      ? _c("tr", { key: n }, [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue",
                                                    rules:
                                                      _vm.rules.conditionValue,
                                                  },
                                                },
                                                [
                                                  _vm._v(" 打赏金额 "),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue",
                                                          index,
                                                          "rewardRules3"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue2",
                                                    rules:
                                                      _vm.rules.conditionValue2,
                                                  },
                                                },
                                                [
                                                  _vm.ruleForm.taskType !== 2
                                                    ? _c("span", [
                                                        _vm._v("有效单数"),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.ruleForm.taskType === 2 &&
                                                  _vm.ruleForm.rewardMode === 1
                                                    ? _c("span", [
                                                        _vm._v("有效天数"),
                                                      ])
                                                    : _vm._e(),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue2",
                                                          index,
                                                          "rewardRules3"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".awardsCheckedType_3",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_3,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            3,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules3[index]
                                                            .awardsCheckedType_3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules3[
                                                              index
                                                            ],
                                                            "awardsCheckedType_3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules3[index].awardsCheckedType_3",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v("倍 "),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules3[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules3[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "danger" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handeldelete(
                                                        index,
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm._l(_vm.days, function (n, index) {
                                    return _vm.ruleForm.taskType === 2 &&
                                      _vm.ruleForm.rewardMode === 2
                                      ? _c("tr", { key: n }, [
                                          _c("td", [_vm._v(_vm._s(n) + "天")]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules1." +
                                                      index +
                                                      ".awardsCheckedType_1",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_1,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            1,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules1[index]
                                                            .awardsCheckedType_1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules1[
                                                              index
                                                            ],
                                                            "awardsCheckedType_1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules1[index].awardsCheckedType_1",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                        _vm._v("倍 "),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules1[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules1[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("tr", [
                                        _c("td", [_vm._v("全勤奖")]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-error",
                                                attrs: {
                                                  prop: "rewardRules2.awardsCheckedType_2",
                                                  rules:
                                                    _vm.rules
                                                      .awardsCheckedType_2,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    on: {
                                                      change: function (value) {
                                                        return _vm.handleChange(
                                                          value,
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules2
                                                          .awardsCheckedType_2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules2,
                                                          "awardsCheckedType_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules2.awardsCheckedType_2",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.awardsType,
                                                    function (item, i) {
                                                      return _c(
                                                        "el-checkbox",
                                                        {
                                                          key: item.type,
                                                          attrs: {
                                                            label: item.type,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.type)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _vm.awardsCheckedType_2.includes(
                                              "彩金"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusAmount",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusAmount
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 彩金 "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusAmount"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusAmount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusAmount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusAmount",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusWagerMultiplier",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusMultiplier
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 打码"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusWagerMultiplier"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusWagerMultiplier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusWagerMultiplier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusWagerMultiplier",
                                                        },
                                                      }),
                                                      _vm._v("倍 "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "优惠券"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".couponId",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "优惠券"
                                                          )
                                                            ? _vm.rules.couponId
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 优惠券 "),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "filter-item",
                                                          staticStyle: {
                                                            width: "220px",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "商品类目"
                                                              ),
                                                            clearable: "",
                                                            filterable: "",
                                                            multiple: "",
                                                            "multiple-limit": 5,
                                                            "collapse-tags": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules2
                                                                .couponId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules2,
                                                                "couponId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules2.couponId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.couponList,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.couponId,
                                                                attrs: {
                                                                  value:
                                                                    item.couponId,
                                                                  label:
                                                                    item.couponTitle,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "积分"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".points",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "积分"
                                                          )
                                                            ? _vm.rules.points
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 积分"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "points"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .points,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "points",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.points",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  : _vm.ruleForm.taskAction === 9
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("任务范围"),
                            prop: "taskScope",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.taskScope,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "taskScope", $$v)
                                },
                                expression: "ruleForm.taskScope",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v(_vm._s(_vm.$t("不限制"))),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("限制方式"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.taskScope === 1
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "taskRangeBrowseChecked" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.taskRangeBrowseChecked,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "taskRangeBrowseChecked",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.taskRangeBrowseChecked",
                                  },
                                },
                                _vm._l(
                                  _vm.formData.taskRangeBrowseList,
                                  function (taskRange) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: taskRange,
                                        attrs: { label: taskRange },
                                      },
                                      [_vm._v(_vm._s(taskRange))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("任务目标与奖励") } },
                        [
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-error",
                                attrs: { prop: "participationCondition1" },
                              },
                              [
                                _vm._v(" 观看时间 >= "),
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: { type: "text", clearable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.handleInput1(
                                        "participationCondition1"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.participationCondition1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "participationCondition1",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.participationCondition1",
                                  },
                                }),
                                _vm._v(" S，才算有效观看 "),
                              ],
                              1
                            ),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 2
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 观看时长（min） >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition3",
                                      },
                                    },
                                    [
                                      _vm._v(" 且 有效观看 "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition3"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition3",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition3",
                                        },
                                      }),
                                      _vm._v(" 才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 1
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition2",
                                      },
                                    },
                                    [
                                      _vm._v(" 有效观看>= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition2"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition2",
                                        },
                                      }),
                                      _vm._v("才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          _vm.ruleForm.taskType !== 2
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ruleForm.taskType === 2 &&
                          _vm.ruleForm.rewardMode === 2
                            ? _c("p", [_c("span", [_vm._v("任务奖励")])])
                            : _vm._e(),
                          _vm.ruleForm.taskType == 2 &&
                          _vm.ruleForm.rewardMode === 1
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                  _vm._v(
                                    " 每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期 "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "table",
                            {
                              staticClass: "browseTable",
                              class: { weekTable: _vm.ruleForm.taskType === 2 },
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("任务目标")])
                                    : _vm._e(),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("th", [_vm._v("累计天数")])
                                    : _vm._e(),
                                  _c("th", [_vm._v("奖励类型")]),
                                  _c("th", [_vm._v("奖励内容")]),
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("操作")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.num, function (n, index) {
                                    return _vm.ruleForm.taskType !== 2 ||
                                      (_vm.ruleForm.taskType === 2 &&
                                        _vm.ruleForm.rewardMode === 1)
                                      ? _c("tr", { key: n }, [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue",
                                                    rules:
                                                      _vm.rules.conditionValue,
                                                  },
                                                },
                                                [
                                                  _vm._v(" 观看时长（min）"),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue",
                                                          index,
                                                          "rewardRules3",
                                                          "num"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".conditionValue2",
                                                    rules:
                                                      _vm.rules.conditionValue2,
                                                  },
                                                },
                                                [
                                                  _vm.ruleForm.taskType !== 2
                                                    ? _c("span", [
                                                        _vm._v("有效观看"),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.ruleForm.taskType === 2 &&
                                                  _vm.ruleForm.rewardMode === 1
                                                    ? _c("span", [
                                                        _vm._v("有效天数"),
                                                      ])
                                                    : _vm._e(),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleInput2(
                                                          "conditionValue2",
                                                          index,
                                                          "rewardRules3",
                                                          "num"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                          .conditionValue2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules3[
                                                            index
                                                          ],
                                                          "conditionValue2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules3[index].conditionValue2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".awardsCheckedType_3",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_3,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            3,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules3[index]
                                                            .awardsCheckedType_3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules3[
                                                              index
                                                            ],
                                                            "awardsCheckedType_3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules3[index].awardsCheckedType_3",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v("倍 "),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules3[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules3[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "danger" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handeldelete(
                                                        index,
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm._l(_vm.days, function (n, index) {
                                    return _vm.ruleForm.taskType === 2 &&
                                      _vm.ruleForm.rewardMode === 2
                                      ? _c("tr", { key: n }, [
                                          _c("td", [_vm._v(_vm._s(n) + "天")]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules1." +
                                                      index +
                                                      ".awardsCheckedType_1",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_1,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            1,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules1[index]
                                                            .awardsCheckedType_1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules1[
                                                              index
                                                            ],
                                                            "awardsCheckedType_1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules1[index].awardsCheckedType_1",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                        _vm._v("倍 "),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules1[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules1[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("tr", [
                                        _c("td", [_vm._v("全勤奖")]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-error",
                                                attrs: {
                                                  prop: "rewardRules2.awardsCheckedType_2",
                                                  rules:
                                                    _vm.rules
                                                      .awardsCheckedType_2,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    on: {
                                                      change: function (value) {
                                                        return _vm.handleChange(
                                                          value,
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules2
                                                          .awardsCheckedType_2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules2,
                                                          "awardsCheckedType_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules2.awardsCheckedType_2",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.awardsType,
                                                    function (item, i) {
                                                      return _c(
                                                        "el-checkbox",
                                                        {
                                                          key: item.type,
                                                          attrs: {
                                                            label: item.type,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.type)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _vm.awardsCheckedType_2.includes(
                                              "彩金"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusAmount",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusAmount
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 彩金 "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusAmount"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusAmount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusAmount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusAmount",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusWagerMultiplier",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusMultiplier
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 打码"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusWagerMultiplier"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusWagerMultiplier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusWagerMultiplier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusWagerMultiplier",
                                                        },
                                                      }),
                                                      _vm._v("倍 "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "优惠券"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".couponId",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "优惠券"
                                                          )
                                                            ? _vm.rules.couponId
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 优惠券 "),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "filter-item",
                                                          staticStyle: {
                                                            width: "220px",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "商品类目"
                                                              ),
                                                            clearable: "",
                                                            filterable: "",
                                                            multiple: "",
                                                            "multiple-limit": 5,
                                                            "collapse-tags": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules2
                                                                .couponId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules2,
                                                                "couponId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules2.couponId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.couponList,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.couponId,
                                                                attrs: {
                                                                  value:
                                                                    item.couponId,
                                                                  label:
                                                                    item.couponTitle,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "积分"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".points",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "积分"
                                                          )
                                                            ? _vm.rules.points
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 积分"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "points"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .points,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "points",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.points",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  : [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("任务目标与奖励") } },
                        [
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-error",
                                attrs: { prop: "participationCondition1" },
                              },
                              [
                                _vm._v(" 充值金额 >= "),
                                _c("el-input", {
                                  staticStyle: {
                                    width: "100px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { type: "text", clearable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.handleInput(
                                        "participationCondition1"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.participationCondition1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "participationCondition1",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.participationCondition1",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-error",
                                attrs: { prop: "participationCondition2" },
                              },
                              [
                                _vm._v(" 且 投注金额 >= "),
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: { type: "number", clearable: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.handleInput(
                                        "participationCondition2"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.participationCondition2,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "participationCondition2",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.participationCondition2",
                                  },
                                }),
                                _vm._v(" 才算有效好友 "),
                              ],
                              1
                            ),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 2
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition3",
                                      },
                                    },
                                    [
                                      _vm._v(" 有效好友 >= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition3"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition3",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition3",
                                        },
                                      }),
                                      _vm._v(" 才算有天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm.ruleForm.taskType === 2 &&
                            _vm.ruleForm.rewardMode === 1
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-error",
                                      attrs: {
                                        prop: "participationCondition3",
                                      },
                                    },
                                    [
                                      _vm._v(" 有效好友>= "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "text", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInput1(
                                              "participationCondition3"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.ruleForm
                                              .participationCondition3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "participationCondition3",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.participationCondition3",
                                        },
                                      }),
                                      _vm._v("才算有效天数 "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          _vm.ruleForm.taskType !== 2
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ruleForm.taskType === 2 &&
                          _vm.ruleForm.rewardMode === 2
                            ? _c("p", [_c("span", [_vm._v("任务奖励")])])
                            : _vm._e(),
                          _vm.ruleForm.taskType == 2 &&
                          _vm.ruleForm.rewardMode === 1
                            ? _c(
                                "p",
                                [
                                  _c("span", [_vm._v("阶梯奖励")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "add",
                                      attrs: { type: "success" },
                                      on: { click: _vm.handleAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                  _vm._v(
                                    " 每周一结算，按照最高目标发放奖励，本周内随时可领，下次结算时过期 "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "table",
                            {
                              class: { weekTable: _vm.ruleForm.taskType === 2 },
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("任务目标")])
                                    : _vm._e(),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("th", [_vm._v("累计天数")])
                                    : _vm._e(),
                                  _c("th", [_vm._v("奖励类型")]),
                                  _c("th", [_vm._v("奖励内容")]),
                                  _vm.ruleForm.taskType !== 2 ||
                                  (_vm.ruleForm.taskType === 2 &&
                                    _vm.ruleForm.rewardMode === 1)
                                    ? _c("th", [_vm._v("操作")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.num, function (n, index) {
                                    return _vm.ruleForm.taskType !== 2 ||
                                      (_vm.ruleForm.taskType === 2 &&
                                        _vm.ruleForm.rewardMode === 1)
                                      ? _c("tr", { key: n }, [
                                          _c(
                                            "td",
                                            [
                                              _vm.ruleForm.taskType !== 2
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".conditionValue",
                                                          rules:
                                                            _vm.rules
                                                              .conditionValue,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 有效好友 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "conditionValue",
                                                                index,
                                                                "rewardRules3",
                                                                "num"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].conditionValue,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "conditionValue",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].conditionValue",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.ruleForm.taskType === 2 &&
                                              _vm.ruleForm.rewardMode === 1
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".conditionValue",
                                                          rules:
                                                            _vm.rules
                                                              .conditionValue,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 邀请人数"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "conditionValue",
                                                                index,
                                                                "rewardRules3",
                                                                "num"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].conditionValue,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "conditionValue",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].conditionValue",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.ruleForm.taskType === 2 &&
                                              _vm.ruleForm.rewardMode === 1
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".conditionValue2",
                                                          rules:
                                                            _vm.rules
                                                              .conditionValue2,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 有效天数"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "conditionValue2",
                                                                index,
                                                                "rewardRules3",
                                                                "num"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].conditionValue2,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "conditionValue2",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].conditionValue2",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules3." +
                                                      index +
                                                      ".awardsCheckedType_3",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_3,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            3,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules3[index]
                                                            .awardsCheckedType_3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules3[
                                                              index
                                                            ],
                                                            "awardsCheckedType_3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules3[index].awardsCheckedType_3",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v("倍 "),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules3[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules3[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_3[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules3." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_3[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules3"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules3[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules3[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules3[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "danger" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handeldelete(
                                                        index,
                                                        _vm.ruleForm
                                                          .rewardRules3[index]
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm._l(_vm.days, function (n, index) {
                                    return _vm.ruleForm.taskType === 2 &&
                                      _vm.ruleForm.rewardMode === 2
                                      ? _c("tr", { key: n }, [
                                          _c("td", [_vm._v(_vm._s(n) + "天")]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "form-item-error",
                                                  attrs: {
                                                    prop:
                                                      "rewardRules1." +
                                                      index +
                                                      ".awardsCheckedType_1",
                                                    rules:
                                                      _vm.rules
                                                        .awardsCheckedType_1,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.handleChange(
                                                            value,
                                                            1,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .rewardRules1[index]
                                                            .awardsCheckedType_1,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm
                                                              .rewardRules1[
                                                              index
                                                            ],
                                                            "awardsCheckedType_1",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.rewardRules1[index].awardsCheckedType_1",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.awardsType,
                                                      function (item, i) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: item.type,
                                                            attrs: {
                                                              label: item.type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.type)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("彩金")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusAmount",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusAmount
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 彩金 "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusAmount",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].bonusAmount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusAmount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusAmount",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".bonusWagerMultiplier",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("彩金")
                                                              ? _vm.rules
                                                                  .bonusMultiplier
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 打码"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "bonusWagerMultiplier",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ]
                                                                .bonusWagerMultiplier,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "bonusWagerMultiplier",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].bonusWagerMultiplier",
                                                          },
                                                        }),
                                                        _vm._v("倍 "),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("优惠券")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".couponId",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("优惠券")
                                                              ? _vm.rules
                                                                  .couponId
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 优惠券 "),
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticClass:
                                                              "filter-item",
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "商品类目"
                                                                ),
                                                              clearable: "",
                                                              filterable: "",
                                                              multiple: "",
                                                              "multiple-limit": 5,
                                                              "collapse-tags":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ].couponId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .rewardRules1[
                                                                      index
                                                                    ],
                                                                    "couponId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.rewardRules1[index].couponId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.couponList,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.couponId,
                                                                  attrs: {
                                                                    value:
                                                                      item.couponId,
                                                                    label:
                                                                      item.couponTitle,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.awardsCheckedType_1[
                                                index
                                              ].includes("积分")
                                                ? [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass:
                                                          "form-item-error",
                                                        attrs: {
                                                          prop:
                                                            "rewardRules1." +
                                                            index +
                                                            ".points",
                                                          rules:
                                                            _vm.awardsCheckedType_1[
                                                              index
                                                            ].includes("积分")
                                                              ? _vm.rules.points
                                                              : [],
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 积分"),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInput2(
                                                                "points",
                                                                index,
                                                                "rewardRules1"
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules1[
                                                                index
                                                              ].points,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules1[
                                                                  index
                                                                ],
                                                                "points",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules1[index].points",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm.ruleForm.taskType === 2 &&
                                  _vm.ruleForm.rewardMode === 2
                                    ? _c("tr", [
                                        _c("td", [_vm._v("全勤奖")]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-error",
                                                attrs: {
                                                  prop: "rewardRules2.awardsCheckedType_2",
                                                  rules:
                                                    _vm.rules
                                                      .awardsCheckedType_2,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    on: {
                                                      change: function (value) {
                                                        return _vm.handleChange(
                                                          value,
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm
                                                          .rewardRules2
                                                          .awardsCheckedType_2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm
                                                            .rewardRules2,
                                                          "awardsCheckedType_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.rewardRules2.awardsCheckedType_2",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.awardsType,
                                                    function (item, i) {
                                                      return _c(
                                                        "el-checkbox",
                                                        {
                                                          key: item.type,
                                                          attrs: {
                                                            label: item.type,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.type)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _vm.awardsCheckedType_2.includes(
                                              "彩金"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusAmount",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusAmount
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 彩金 "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusAmount"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusAmount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusAmount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusAmount",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".bonusWagerMultiplier",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "彩金"
                                                          )
                                                            ? _vm.rules
                                                                .bonusMultiplier
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 打码"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "bonusWagerMultiplier"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .bonusWagerMultiplier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "bonusWagerMultiplier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.bonusWagerMultiplier",
                                                        },
                                                      }),
                                                      _vm._v("倍 "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "优惠券"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".couponId",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "优惠券"
                                                          )
                                                            ? _vm.rules.couponId
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 优惠券 "),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "filter-item",
                                                          staticStyle: {
                                                            width: "220px",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "商品类目"
                                                              ),
                                                            clearable: "",
                                                            filterable: "",
                                                            multiple: "",
                                                            "multiple-limit": 5,
                                                            "collapse-tags": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .rewardRules2
                                                                .couponId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .rewardRules2,
                                                                "couponId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.rewardRules2.couponId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.couponList,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.couponId,
                                                                attrs: {
                                                                  value:
                                                                    item.couponId,
                                                                  label:
                                                                    item.couponTitle,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.awardsCheckedType_2.includes(
                                              "积分"
                                            )
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "form-item-error",
                                                      attrs: {
                                                        prop:
                                                          "rewardRules2" +
                                                          ".points",
                                                        rules:
                                                          _vm.awardsCheckedType_2.includes(
                                                            "积分"
                                                          )
                                                            ? _vm.rules.points
                                                            : [],
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" 积分"),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput3(
                                                              "points"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .rewardRules2
                                                              .points,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .rewardRules2,
                                                              "points",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.rewardRules2.points",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("温馨提示"), prop: "warmTips" } },
                  [
                    _c("tinymce", {
                      ref: "tinymce",
                      attrs: {
                        disabled: _vm.emitName === "details",
                        height: 200,
                      },
                      model: {
                        value: _vm.ruleForm.warmTips,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "warmTips", $$v)
                        },
                        expression: "ruleForm.warmTips",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("活动规则"),
                      prop: "ruleDisplayType",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.changeRuleDisplayType },
                        model: {
                          value: _vm.ruleForm.ruleDisplayType,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "ruleDisplayType", $$v)
                          },
                          expression: "ruleForm.ruleDisplayType",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("富文本"))),
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v(_vm._s(_vm.$t("图片"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "ruleContent" } },
                      [
                        _vm.ruleForm.ruleDisplayType === 1
                          ? _c("tinymce", {
                              ref: "tinymceRule",
                              attrs: {
                                disabled: _vm.emitName === "details",
                                height: 200,
                              },
                              model: {
                                value: _vm.ruleForm.ruleContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "ruleContent", $$v)
                                },
                                expression: "ruleForm.ruleContent",
                              },
                            })
                          : _c(
                              "el-upload",
                              {
                                staticClass: "banner-uploader",
                                attrs: {
                                  accept:
                                    "image/jpeg,image/png,image/jpg,image/webp",
                                  action: _vm.action,
                                  data: { type: "image", folder: "ad-photo" },
                                  headers: _vm.$headers(),
                                  "show-file-list": false,
                                  "on-success": function (res) {
                                    return _vm.handleUploadSuccess(
                                      res,
                                      "ruleContent"
                                    )
                                  },
                                },
                              },
                              [
                                _vm.ruleForm.ruleContent
                                  ? _c("img", {
                                      staticClass: "banner",
                                      attrs: { src: _vm.ruleForm.ruleContent },
                                    })
                                  : _c(
                                      "div",
                                      { staticClass: "banner-uploader-txt" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "el-button el-button--small el-button--orange",
                                            on: {
                                              click: function ($event) {
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("点击上传")))]
                                        ),
                                      ]
                                    ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-warning-outline",
                                    }),
                                    _vm._v(
                                      "提示：建议尺寸355*138，支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                (_vm.ruleForm.taskType === 1 &&
                  _vm.ruleForm.taskAction !== 1) ||
                (_vm.ruleForm.taskType === 2 &&
                  _vm.ruleForm.rewardMode !== 1) ||
                _vm.ruleForm.taskType === 3 ||
                _vm.ruleForm.taskType === 4
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("选择显示模板"),
                            prop: "rewardDisplayType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.rewardDisplayType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "rewardDisplayType",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.rewardDisplayType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 4 } }, [
                                _vm._v(_vm._s(_vm.$t("红包领取"))),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v(_vm._s(_vm.$t("宝箱领取"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.rewardDisplayType === 4
                        ? _c("el-form-item", [
                            _c("img", {
                              staticClass: "template-img",
                              attrs: {
                                src: require("./images/template_4.png"),
                                alt: "红包领取模板",
                              },
                            }),
                          ])
                        : _vm.ruleForm.rewardDisplayType === 2
                        ? _c("el-form-item", [
                            _c("img", {
                              staticClass: "template-img",
                              attrs: {
                                src: require("./images/template_2.png"),
                                alt: "宝箱领取模板",
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.ruleForm.activityType === 2 && _vm.ruleForm.jumpType === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("联系客服入口"),
                    prop: "showContactService",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.showContactService,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "showContactService", $$v)
                        },
                        expression: "ruleForm.showContactService",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(_vm._s(_vm.$t("显示"))),
                      ]),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v(_vm._s(_vm.$t("不显示"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("关闭")) + " "),
          ]),
          _vm.emitName !== "details"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }