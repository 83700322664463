<template>
  <el-dialog
    title="抽成配置"
    :visible.sync="visible"
    width="420px"
    top="5vh"
    :close-on-click-modal="false"
    class="border-form-dialog"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :show-message="false"
      label-width="120px"
      label-suffix=":"
    >
      <el-form-item
        label="维护费用"
        prop="maintenanceCost"
        :rules="amountRules"
      >
        <el-input v-model="ruleForm.maintenanceCost" placeholder="请输入金额" />
      </el-form-item>
      <el-form-item
        label="美颜费用"
        prop="beautyCost"
        :rules="amountRules"
      >
        <el-input v-model="ruleForm.beautyCost" placeholder="请输入金额" />
      </el-form-item>
      <el-form-item
        label="服务器费用"
        prop="serverCost"
        :rules="amountRules"
      >
        <el-input v-model="ruleForm.serverCost" placeholder="请输入金额" />
      </el-form-item>
      <el-form-item v-for="config in ladderConfig" :key="config.key" :label="config.label">
        <div v-for="(name, i) in ladderOptions" :key="i">
          <div style="font-size: 12px">{{ i + 1 }}级阶梯抽成 <template v-if="i === 0">(起抽线)</template></div>
          <el-row type="flex" :gutter="12">
            <el-col :span="12">
              <el-form-item :prop="`${name}Level${config.key}Charges`" :rules="amountRules">
                <el-input v-model="ruleForm[`${name}Level${config.key}Charges`]" placeholder="请输入金额" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :prop="`${name}Level${config.key}Percent`" :rules="percentRules">
                <el-input v-model="ruleForm[`${name}Level${config.key}Percent`]" placeholder="请输入比例">
                  <template slot="suffix">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        确认
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { ladderConfig, ladderOptions, amountRules, percentRules } from './utils'

const getDefaultForm = () => ({
  maintenanceCost: '',
  beautyCost: '',
  serverCost: '',
  oneLevelBetsCharges: '',
  oneLevelBetsPercent: '',
  oneLevelGiftCharges: '',
  oneLevelGiftPercent: '',
  oneLevelPayroomCharges: '',
  oneLevelPayroomPercent: '',
  twoLevelBetsCharges: '',
  twoLevelBetsPercent: '',
  twoLevelGiftCharges: '',
  twoLevelGiftPercent: '',
  twoLevelPayroomCharges: '',
  twoLevelPayroomPercent: '',
  threeLevelBetsCharges: '',
  threeLevelBetsPercent: '',
  threeLevelGiftCharges: '',
  threeLevelGiftPercent: '',
  threeLevelPayroomCharges: '',
  threeLevelPayroomPercent: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      ladderConfig,
      ladderOptions,
      amountRules,
      percentRules
    }
  },
  methods: {
    open(row) {
      this.ruleForm = { ...row }
      this.visible = true
    }
  }
}
</script>

