import request from '@/plugins/axios'

// 查询选中平台的所有vip等级
export const getAllVipLevels = (data) => {
  return request({
    url: '/userCenter/getAllVipLevels',
    method: 'post',
    data
  })
}

export function getOperatorSocketToken() {
  return request({
    url: '/push/token',
    method: 'get'
  })
}

export function getNoticeList(params) {
  return request({
    url: '/notice/list',
    method: 'get',
    params
  })
}

export function getNewNotice() {
  return request({
    url: '/notice/new',
    method: 'get'
  })
}

export function updateNoticeRead(id) {
  return request({
    url: '/notice/change/read',
    method: 'post',
    data: { id }
  })
}

export function getTopData() {
  return request({
    url: '/topData',
    method: 'post'
  })
}

export function backmanagerAllLang() {
  return request({
    url: '/backmanager/allLang',
    method: 'get'
  })
}