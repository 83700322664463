<template>
  <el-dialog
    title="卡券发放"
    width="500px"
    :visible.sync="visible"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item :label="$t('会员账号')" prop="memberIds">
        <el-input type="textarea" rows="10" v-model="ruleForm.memberIds" :placeholder="$t('数据格式：会员1,会员2, 使用逗号隔开;每次修改的数量不得超过100条')" />
      </el-form-item>
      <el-form-item :label="$t('优惠券名称')">
        {{ this.ruleForm.couponName }}
      </el-form-item>
      <el-form-item :label="$t('发放数量')">
        1
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('关 闭') }}</el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">{{ $t('保 存') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  memberIds: '',
  couponId: '',
  couponName: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'send',
      rules: {
        memberIds: [{ required: true, message: '请输入会员账号', trigger: 'blur' }]
      }
    }
  },
  methods: {
    open(row) {
      this.ruleForm.couponId = row.couponId
      this.ruleForm.couponName = row.couponTitle
      this.visible = true
    },
    handleSubmitBefor() {
      if (this.ruleForm.memberIds && this.ruleForm.memberIds.split(',').length > 100) {
        return this.$message.error('每次发放的会员账号不能超过100个')
      }
      this.handleSubmit()
    }
  }
}
</script>
