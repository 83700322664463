<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="times"

        type="datetimerange"
        class="filter-item"
        style="width: 400px;"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetTimes($event,0)">{{ $t('今天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,1)">{{ $t('昨天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,7)">{{ $t('_7天') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,30)">{{ $t('本月') }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event,60)">{{ $t('上月') }}</el-button>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="searchList">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>
    <div class="filter-container">
      <!-- <el-select
        v-if="Array.isArray(tranTypes) && tranTypes.length"
        v-model="search.transTypeList"
        multiple
        collapse-tags
        :placeholder="$t('变动类型')"

        class="filter-item"
        style="width: 200px"
      >
        <el-option-group
          v-for="group in tranTypes"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="item in group.options"
            :key="item.desc+item.value"
            :label="item.desc"
            :value="item.value"
          />
        </el-option-group>
      </el-select> -->
      <el-cascader
        :placeholder="$t('变动类型')"
        v-if="Array.isArray(tranTypesOptions) && tranTypesOptions.length"
        :options="tranTypesOptions"
        :props="{ multiple: true }"
        collapse-tags
        @change="onChangeCascaderType"
      >
      </el-cascader>
      <el-input v-model="search.memberName" clearable :placeholder="$t('会员账号')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.operatorName" clearable :placeholder="$t('操作员')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.operatorRemark" clearable :placeholder="$t('备注')" class="filter-item" style="width: 140px" />
      <el-input v-model="search.transDetail" clearable :placeholder="$t('变动说明')" class="filter-item" style="width: 140px" />
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template slot="left">
        <span v-if="sumAmount !== null" class="danger-type">{{ $t('账变金额') }}：{{ sumAmount | toThousandFilter }}</span>
      </template>
      <template #right>
        <el-button plain type="primary" :loading="excelLoading1" icon="el-icon-download" @click="_handleExport">{{ $t('导出EXCEL') }}</el-button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <UserDialog ref="UserDialogControl" />

  </div>
</template>
<script>
import { getTranTypesChipRecords, getAccountChangeList, getAccountChangeSum, exportAccountChangeList,getAccountRollingList,getAccountRollingSum } from '@/api/cash'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import UserDialog from '@/views/components/UserDialog'
import { getDefaultTimes } from '@/utils'
import { exportExcel } from '@/utils'

export default {
  name: 'CashMemberChange',
  components: { UserDialog },
  mixins: [TablePageMixin(getAccountRollingList, false)],
  data() {
    return {
      timer: null,
      columns: [],
      defaultColumns: GetColumns.call(this),
      excelLoading1:false,
      times: getDefaultTimes(),
      search: {
        transTypeList: []
      },
      tranTypes: [],
      tranTypesOptions:[],
      sumAmount: null
    }
  },
  async created() {
    await this._getTranTypes()
    await this._getFetchWithMember()
  },

  mounted() {

  },

  // async activated() {
  //   await this._getTranTypes()
  //   await this._getFetchWithMember()
  // },

  methods: {
    onChangeCascaderType(value) {
      this.search.transTypeList = value.map(array => array[1]);
      // this.search.type=+value?.[1]
      // this.handleRefresh()
    },
    beforeFetch() {
      if (!this.times) {
        this.$message.info(this.$t('请选择查询时间'))
        return false
      }
      const span = (this.times[1] - this.times[0]) / 1000 / 60 / 60 / 24
      const { transTypeList, memberName } = this.search
      if (span > 7 && transTypeList.length < 1 && !memberName) {
        this.$message.info(this.$t('查询时间大于7天，必须填写变动类型或会员账号'))
        return false
      }
      return true
    },
    async searchList() {
      if(this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(async() => {
        // 获取账变统计
        this.handleFetch()
        let res = await getAccountRollingSum(this.implementationGetParams())
        if (res[0]) this.sumAmount = res[0].sumAmount
      }, 500)   
    },
    async _getFetchWithMember() {
      const { memberName } = this.$route.query
      if (memberName) this.search.memberName = memberName

      this.handleRefresh()
    },

    _onDetails({ memberId }) {
      if (!this.permission.Details) return
      this.$refs.UserDialogControl.open(memberId)
    },

    _handleExport() {
      const query = {
        ...this.implementationGetParams(),
        transTypeList: this.search.transTypeList.join(',')
      }
      this.excelLoading1 = true
      exportAccountChangeList(query).then(([data, error, blobData]) => {
        if (!error) {
          exportExcel(blobData, `会员账变记录-${this.$parseTime(new Date())}.csv`)
        }
      }).finally(()=>{
        this.excelLoading1 = false
      })
    },
    modifyObject(obj){
      for (let key in obj) {
        if (key === 'desc') {
            obj['label'] = obj[key];
        } else if (key === 'subBmResponseList') {
            obj['children'] = obj[key];
            obj['children'].forEach(child => this.modifyObject(child));
        } else if (key === 'bmResponseList') {
            obj['children'] = obj[key];
            obj['children'].forEach(child => this.modifyObject(child));
        }
      }
      return obj;
    },
    _getTranTypes() {
      getTranTypesChipRecords().then(([items, err]) => {
        if (!err) {
          items.forEach(e => {
            this.tranTypes.push({ label: e.desc, options: e.bmResponseList })
            this.tranTypesOptions.push(this.modifyObject(e))
          })
        }
      })
    },

    implementationGetParams() {
      return this.formatIntervalTime()
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
