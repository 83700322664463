<template>
  <el-dialog
    title="编辑"
    :visible.sync="visible"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="150px" label-suffix=":">
      <el-form-item :label="$t('排版样式')" prop="layoutStyle">
        <el-radio-group v-model="ruleForm.layoutStyle"  @change="getMyGamePlayList(ruleForm.layoutStyle)" style="width: 680px;" >
          <div class="style-list">
            <el-radio-button :label="'1'">
                <div class="style-title">样式1</div>
                <div class="style-inner">
                  <div class="style-item item1">
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                </div >
                </div>
            </el-radio-button>
            <el-radio-button :label="'2'">
              <div class="style-title">样式2</div>
              <div class="style-inner">
                <div class="style-item item2">
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                </div >
              </div>
            </el-radio-button>
            <el-radio-button :label="'3'">
              <div class="style-title">样式3</div>
              <div class="style-inner">
                <div class="style-item item3">
                  <i class="el-icon-arrow-left arr lt"></i>
                  <i class="el-icon-arrow-right arr rt"></i>
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                </div >
                </div>
            </el-radio-button>
            <el-radio-button :label="'4'">
              <div class="style-title">样式4</div>
              <div class="style-inner">
                <div class="style-item item4">
                  <i class="el-icon-arrow-left arr lt"></i>
                  <i class="el-icon-arrow-right arr rt"></i>
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                </div >
                </div>
            </el-radio-button>
            <el-radio-button :label="'5'">
              <div class="style-title">样式5</div>
              <div class="style-inner">
                <div class="style-item item5">
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                  <div>
                    <i class="el-icon-arrow-left arr lt"></i>
                    <i class="el-icon-arrow-right arr rt"></i>
                    <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                    <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                    <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                    </div>
                </div >
                </div>
            </el-radio-button>
            <el-radio-button :label="'6'">
              <div class="style-title">样式6</div>
              <div class="style-inner">
                <div class="style-item item5">
                  <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                  <div>
                    <i class="el-icon-arrow-left arr lt"></i>
                    <i class="el-icon-arrow-right arr rt"></i>
                    <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                    <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                    <div><el-image><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></div>
                    </div>
                </div >
                </div>
            </el-radio-button>
        </div>
        </el-radio-group>
        <p v-if="['5','6'].includes(ruleForm.layoutStyle)">提示：选择样式{{ ruleForm.layoutStyle }}需要配置banner。</p>
      </el-form-item>
      <el-form-item label="banner图" v-if="['5','6'].includes(ruleForm.layoutStyle)" prop="bannerImage">
        <el-upload
        class="banner-uploader"
        accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
        :action="action"
        :data="{ type: 'image', folder: 'activity' }"
        :headers="$headers()"
        :show-file-list="false"
        :on-success="handleSuccess"
      >
      <div v-if="!ruleForm.bannerImage">
        <div tabindex="0" class="el-upload el-upload--text">
          <div class="avatar-uploader-icon" style="width: 120px; height: 120px;">
            <i class="el-icon-plus" ></i>
            <p>上传图片</p>
          </div>
        </div>
      </div>
      <img v-else :src="ruleForm.bannerImage" class="banner">
      </el-upload>
      <p>提示：图片支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。</p>
    </el-form-item>
      <el-form-item label="banner跳转地址" v-if="['5','6'].includes(ruleForm.layoutStyle)" prop="bannerJumpUrl">
        <el-input v-model="ruleForm.bannerJumpUrl" style="width: 90%" :placeholder="$t('请输入banner跳转地址')" />
      </el-form-item>
      <el-form-item label="是否显示奖金" v-if="['5','6'].includes(ruleForm.layoutStyle)" prop="displayPrizeStatus">
        <d-switch v-model="ruleForm.displayPrizeStatus" active-text="是" inactive-text="否" :active-value="1" :inactive-value="0"></d-switch>
      </el-form-item>
      <el-form-item label="虚拟金额设置" v-if="['5','6'].includes(ruleForm.layoutStyle) && ruleForm.displayPrizeStatus" prop="virtualAmountConfig">
        <el-input type="number" v-model.number="ruleForm.virtualAmountConfig" style="width: 90%" :placeholder="$t('请输入金额')" />
        <p>提示：中奖金额=虚拟金额+实际中奖金额</p>
      </el-form-item>
      <el-form-item label="奖金颜色" v-if="ruleForm.layoutStyle === '6' && ruleForm.displayPrizeStatus" prop="virtualAmountConfig">
        <el-color-picker v-model="ruleForm.styleColor"></el-color-picker>
      </el-form-item>
      <el-form-item label="游戏图片" prop="myGamePlayList">
        <el-table
          ref="elTable"
          border
          :data="ruleForm.myGamePlayList"
          row-key="rowKey"
          :drag-row="true"
        >
          <el-table-column type="index" align="center" width="60">
            <template #header>{{ $t("排序") }}</template>
            <svg-icon icon-class="drag" class-name="drag-handler" />
          </el-table-column>
          <el-table-column label="玩法系列" align="center">
            <template slot-scope="{ row }">
              <!-- <el-form-item :prop="'myGamePlayList[' + scope.$index + '].seriesTag'" :rules="rules.seriesTag"> -->
              <el-select v-model="row.seriesTag" style="width: 100%" @change="handleChangeSeries(row)">
                <el-option
                  v-for="item in seriesList.filter(e => includeTag.includes(e.seriesTag))"
                  :key="item.seriesTag"
                  :value="item.seriesTag"
                  :label="item.seriesName"
                />
              </el-select>
            <!-- </el-form-item> -->
            </template>
          </el-table-column>
          <el-table-column label="游戏" align="center">
            <template slot-scope="{ row }">
              <!-- <el-form-item :prop="'myGamePlayList[' + scope.$index + '].lotteryId'" :rules="rules.lotteryId"> -->
                <el-select
                v-model="row.lotteryId"
                style="width: 100%"
                @change="handleChangeLottery(row)"
              >
                <el-option
                  v-for="item in (seriesList.some(e => e.seriesTag === row.seriesTag) ? seriesList.find(e => e.seriesTag === row.seriesTag).list : [])"
                  :key="item.lotteryId"
                  :value="item.lotteryId"
                  :label="item.name"
                />
              </el-select>
            <!-- </el-form-item> -->
            </template>
          </el-table-column>
          <el-table-column :label="$t('图标')" align="center" width="140">
            <template slot-scope="{ row }">
              <d-upload
                v-model="row.icon"
                folder="icon"
                :on-success="(...args) => handleUploadSuccess(args, row)"
                :width="86"
                :height="86"
              />
            </template>
          </el-table-column>
          <el-table-column :label="$t('操作')" align="center" width="140">
            <template slot-scope="{ row }">
              <el-button @click="row.lotteryId = null;row.lotteryName = null;row.seriesTag = null;row.seriesName = null;row.icon = null">重置</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <p>提示：不选择游戏时默认显示玩法，图片支持上传PNG、JPG、GIF、SVG图片，单张大小不超过2MB。</p>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="myClose">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import DUpload from '@/components/DUpload'
import FormDialogMixin from '@/mixins/form-dialog'
import { getLotteryIcon } from '@/api/config'
import Sortable from 'sortablejs'
const getDefaultForm = () => ({
  id: null,
  type: '',
  layoutStyle: '',
  bannerImage: '',
  bannerJumpUrl: '',
  displayPrizeStatus: 0,
  virtualAmountConfig: 0,
  sort: '',
  status: '',
  gamePlayList: [],
  styleColor: '#ffd016',
  myGamePlayList:[],//自用字段
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  components: { DUpload },
  data() {
    return {
      emitName: 'update',
      rules: {
        layoutStyle: [{ required: true, message: this.$t('请选择样式类型'), trigger: 'blur' }],
        bannerImage: [{ required: true, message: this.$t('请上传banner图'), trigger: 'blur' }],
        displayPrizeStatus: [{ required: true, message: this.$t('请选择是否显示奖金'), trigger: 'blur' }],
      },
      seriesList: [],
      includeTag: []
    }
  },
  computed: {
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    },
  },
  created() {
    this.getLotteries()
  },
  methods: {
    pushGamePlayList(num, arr, obj){
      if(arr.length !== num){
        for(let i = arr.length ; i < num ; i++ ){
          arr.push({...obj, rowKey: i})
        }
      }
      arr = arr.map((e, i) => ({ ...e, rowKey: i }));
      return arr
    },
    getMyGamePlayList(layoutStyle){
      const lotteryInfo = {
        icon: '',
        lotteryId: '',
        seriesTag: '',
        sort: '',
      }
      switch (layoutStyle) {
          case '1':
            this.ruleForm.myGamePlayList = this.pushGamePlayList(3, this.ruleForm.gamePlayList.slice(0,3), lotteryInfo)
            break;

          case '2':
          case '3':
            this.ruleForm.myGamePlayList = this.pushGamePlayList(4, this.ruleForm.gamePlayList.slice(0,4), lotteryInfo)
            break;

          default:
            this.ruleForm.myGamePlayList = this.pushGamePlayList(6, this.ruleForm.gamePlayList.slice(0,6), lotteryInfo)
            break;
        }
    },
    open(row, includeTag) {
      this.includeTag = includeTag
      if (row) {
        for(let key in this.ruleForm) {
          if(key in row) {
            this.ruleForm[key] = row[key]
          }
        }
      }
      if(this.ruleForm.layoutStyle){
        this.getMyGamePlayList(this.ruleForm.layoutStyle)
      }
      this.visible = true
      this.initSortTable()
    },
    getLotteries() {
      getLotteryIcon().then(([items, err]) => {
        if (!err) {
          this.seriesList = items
        }
      })
    },
    handleSuccess(res) {0
      
      this.ruleForm.bannerImage = res.data[0];
    },
    handleUploadSuccess([[data]], row) {
      row.icon = data
    },
    myClose(){
      this.$confirm('确定关闭页面吗?', '提示', {
        confirmButtonText: '保存',
        cancelButtonText: '确定',
        type: 'warning'
      }).then(() => {
        this.handleSubmit();
      }).catch(() => {     
        this.close();     
      });
    },
    initSortTable() {
      this.$nextTick(() => {
        // 解决火狐浏览器默认拖拽打开新页面
        document.body.ondrop = function(event) {
          event.preventDefault()
          event.stopPropagation()
        }
        this.setSort()
      })
    },
    setSort() {
      const el = this.$refs.elTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      this.sortable = Sortable.create(el, {
        animation: 180,
        delay: 0,
        handle: '.drag-handler',
        ghostClass: 'sortable-ghost',
        setData: function(dataTransfer) {
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
          dataTransfer.setData('Text', '')
        },
        onEnd: ({ newIndex, oldIndex }) => {
          const targetRow = this.ruleForm.myGamePlayList.splice(oldIndex, 1)[0]
          this.ruleForm.myGamePlayList.splice(newIndex, 0, targetRow)
        }
      })
    },
    handleChangeSeries(row) {
      row.lotteryId = null
      row.lotteryName = null
      row.seriesName = this.seriesList.filter(e => this.includeTag.includes(e.seriesTag)).find(e => e.seriesTag === row.seriesTag).seriesName
    },
    handleChangeLottery(row) {
      row.lotteryName = this.seriesList.find(e => e.seriesTag === row.seriesTag).list.find(e => e.lotteryId === row.lotteryId).name
    }
  }
}
</script>
<style lang="scss" scoped>
.avatar-uploader-icon {
  padding: 10px 0;
  text-align: center;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  i {
    font-size: 28px;
    color: #8c939d;
  }
}

.banner-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.border_ {
  padding: 5px 10px;
  box-sizing: border-box;
}

.banner-uploader .el-upload:hover {
  border-color: #409eff;
}

.banner {
  width: 300px;
  height: 128px;
  display: block;
}
.style-list {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 3px;

  .style-title {
    margin-bottom: 10px;
    height: 30px;
    line-height: 30px;
    color: #333;
  }
  ::v-deep .el-radio-button__inner {
    padding: 0;
    border-radius: 0;
    background-color: #fff;
    border: 0;
    color: #fff;
    box-shadow: none;
  }
  .is-active {
    .style-item {
      border: 1px solid #3894ff;
    }
  }
  .style-inner {
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .style-item {
    background-color: #f8f8f8;
    border-radius: 2px;
    padding: 5px 10px;
    border: 1px solid #fff;
    width: 94px;
    &.item1 {
      > div {
        margin: 5px 0;
        background-color: #99a9bf;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 70px;
        height: 28px;
        .image-slot {
          color: #000;
        }
      }
    }
    &.item2 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      > div {
        margin: 5px 0;
        background-color: #99a9bf;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        .image-slot {
          color: #000;
        }
      }
    }
    &.item3 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .arr {
        position: absolute;
        top: 20px;
        color: #99a9bf;
        &.lt {
          left: -1px;
        }
        &.rt {
          right: -1px;
        }
      }
      > div {
        margin: 5px 0;
        background-color: #99a9bf;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        .image-slot {
          color: #000;
        }
      }
    }
    &.item4 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .arr {
        position: absolute;
        top: 20px;
        color: #99a9bf;
        &.lt {
          left: -3px;
        }
        &.rt {
          right: -3px;
        }
      }
      > div {
        margin: 5px 0;
        background-color: #99a9bf;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 20px;
        height: 32px;
        .image-slot {
          color: #000;
        }
      }
    }
    &.item5 {
      > div {
        &:first-child {
          margin: 2px 0;
          background-color: #99a9bf;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          justify-content: center;
          width: 70px;
          height: 28px;
        }
        &:last-child {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          position: relative;
          .arr {
            position: absolute;
            top: 16px;
            color: #99a9bf;
            &.lt {
              left: -12px;
            }
            &.rt {
              right: -12px;
            }
          }
          > div {
            margin: 5px 0;
            background-color: #99a9bf;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
            justify-content: center;
            width: 20px;
            height: 32px;
            .image-slot {
              color: #000;
            }
          }
        }
      }
    }
  }
}

.el-select.is-error .el-input__inner {
  border-color: #f56c6c;
}
.top-tab {
  width: 800px;
}
</style>
