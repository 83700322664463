<template>
  <div>
    <el-dialog
      :title="title"
      width="700px"
      :visible.sync="visible"
      @open="onOpen"
      @closed="onClosed"
    >
      <lottery-before
        v-if="params"
        ref="lotteryBeforehand"
        :series-tag="params.seriesTag"
        :show-result-compared-list="showResultComparedList"
        :lottery-id="params.lotteryId"
        :result="result"
        :isManualMode="isManualMode"
        :issue="params.issue"
        @handleCompared="getShowResultCompared"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">{{ $t("取消") }}</el-button>
        <el-button
          type="primary"
          @click="handleSubmit"
        >{{ $t("确认结算") }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="`确定进行${title}结算吗？`"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <div v-if="lotteryNo" class="result">
        <div>
          开奖结果：<span
            v-for="(item, index) of lotteryNo.split(',')"
            :key="index"
          >{{ item
          }}<span
            v-if="index < lotteryNo.split(',').length - 1"
          >,</span></span>
        </div>
        <div
          v-if="showResultComparedList.every((e) => e.lotteryNo === lotteryNo)"
          class="info"
        >
          开奖信息与其他平台开奖结果对比一致
        </div>
        <template v-else>
          <div v-for="(item, index) of showResultComparedList" :key="index">
            {{ item.sourceName }}：<span
              v-for="(item2, index2) of item.lotteryNo.split(',')"
              :key="index2"
              :class="{ red: item2 !== lotteryNo.split(',')[index2] }"
            >{{ item2
            }}<span
              v-if="index2 < item.lotteryNo.split(',').length - 1"
            >,</span></span>
          </div>
        </template>
        <div class="tips">tips：确认开奖结算后，将会按照开奖结果进行派奖。</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          :loading="submitting"
          type="primary"
          @click="handleDialogSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import LotteryBefore from './LotteryBefore'
import { showResultCompared } from '@/api/lottery.js'
export default {
  components: { LotteryBefore },
  mixins: [VDialogMixin],
  data() {
    return {
      params: null, // 请求参数
      title: '',
      submitting: false,
      result: '',
      visible: false,
      dialogVisible: false,
      showResultComparedList: [],
      lotteryNo: '',
      lotteryId: '',
      issue: '',
      isManualMode:false,//手动开奖，预开奖
    }
  },
  methods: {
    open({ issue, lotteryTime, lotteryId, result,lotteryType }, lotteryName, seriesTag,isManualMode) {
      // 初始化
      this.title = `${lotteryName} ${issue} ${this.$t('期开奖')}`
      this.result = result
      this.isManualMode = isManualMode
      this.params = { issue, lotteryId, lotteryTime, seriesTag }
      // if (seriesTag === '10') {
        this.lotteryId = lotteryId
        this.issue = issue
        this.getShowResultCompared()
      // }
      this.visible = true
    },
    onOpen() {
      this.$nextTick(() => {
        this.$refs.lotteryBeforehand.init()
      })
    },
    onClosed() {
      this.dialogVisible = false
      this.submitting = false
      this.params = null
    },
    handleSubmit() {
      this.$refs.lotteryBeforehand
        .validateNumber()
        .then((lotteryNo) => {
          this.lotteryNo = lotteryNo
          if (this.seriesTag === '10') {
            this.dialogVisible = true
          } else {
            this.handleDialogSubmit()
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    handleDialogSubmit() {
      this.submitting = true
      this.$emit('update', {
        field: { ...this.params, lotteryNo: this.lotteryNo },
        cancel: () => {
          this.submitting = false
        },
        close: this.close
      })
    },
    getShowResultCompared() {
      showResultCompared({ lotteryId: this.lotteryId, issue: this.issue }, this.operatorType).then(
        ([items, err]) => {
          if (!err && items && items.length > 0) {
            this.showResultComparedList = items.filter((e) => e.lotteryNo)
          } else {
            this.$message('未获取到结果对比')
            this.showResultComparedList = []
          }
          // this.showResultComparedList.push({
          //   'sourceName': '六号平台',
          //   'sourceUrl': '',
          //   'issue': '',
          //   'openTime': '日期',
          //   'lotteryNo': '01,02,03,04,05,06,07'
          // }, {
          //   'sourceName': '六号平台',
          //   'sourceUrl': '',
          //   'issue': '',
          //   'openTime': '日期',
          //   'lotteryNo': '01,02,03,04,05,06,07'
          // })
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.result {
  text-align: center;
  font-size: 16px;
  > .info {
    color: #79a235;
  }
  > .tips {
    color: rgba(217, 0, 27, 0.6470588235294118);
  }

  .red {
    color: #f04134;
  }
  > div:not(:first-child) {
    margin-top: 20px;
  }
}
</style>
