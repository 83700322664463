import request from '@/plugins/axios'

// 用户管理
export function getAllUsersByCondition(data) { // 根据条件查询会员信息
  return request({
    url: '/userCenter/getAllUsersByCondition',
    method: 'post',
    data,
    timeout: 20000
  })
}
// 根据条件查询所有会员统计信息-租户账号
export function getAllUsersByConditionCount(data) {
  return request({
    url: '/userCenter/getAllUsersByCondition/count',
    method: 'post',
    data,
    timeout: 20000
  })
}



export function getUserDetails(params) {
  return request({
    url: '/userCenter/getUserDetails',
    method: 'get',
    params,
    timeout: 20000
  })
}

export function queryThirdGame(params) {
  return request({
    url: '/thirdgame/query',
    method: 'get',
    params
  })
}

export function updateThirdGame(data) {
  return request({
    url: '/thirdgame/update',
    method: 'post',
    data
  })
}

export function getBanks(params) { // 获取会员银行卡
  return request({
    url: 'userCenter/getBanks',
    method: 'get',
    params
  })
}

export function updateVipLevel(data) {
  return request({
    url: '/userCenter/updateVipLevel',
    method: 'post',
    data
  })
}

export function updateUserInfo(data) {
  return request({
    url: '/userCenter/updateUserInfo',
    method: 'post',
    data
  })
}

export function updatePassword(data) {
  return request({
    url: '/userCenter/updatePassword',
    method: 'post',
    data
  })
}

export function updateFreezeStatus(params) {
  return request({
    url: '/userCenter/updateFreezeStatus',
    method: 'get',
    params
  })
}

export function batchUpdateFreezeStatus(data) {
  return request({
    url: '/userCenter/batchUpdateFreezeStatus',
    method: 'post',
    data
  })
}

export function batchUpdateVipLevel(data) {
  return request({
    url: '/userCenter/batchUpdateVipLevel',
    method: 'post',
    data
  })
}

export function batchUpdateAgent(data) {
  return request({
    url: '/userCenter/batchUpdateAgent',
    method: 'post',
    data
  })
}

export function downloadUserInfo(params) {
  return request({
    url: '/userCenter/downloadUserInfo',
    method: 'get',
    params,
    responseType: 'blob',
    timeout: 600000
  })
}

export function updateAgent(params) {
  return request({
    url: '/userCenter/updateAgent',
    method: 'get',
    params
  })
}

export function addRemark(params) {
  return request({
    url: '/userCenter/addRemark',
    method: 'get',
    params
  })
}

export function getBusinessStatus(params) {
  return request({
    url: '/userCenter/getBusinessStatus',
    method: 'get',
    params
  })
}

// VIP等级管理
export function getVipLevelManagerList(params) { // 根据条件查询会员信息
  return request({
    url: '/vipLevelManager/getAllVipLevels',
    method: 'get',
    params
  })
}

export function addManagerVipLevel(data) {
  return request({
    url: '/vipLevelManager/addVipLevel',
    method: 'post',
    data
  })
}

export function updateManagerVipLevel(data) {
  return request({
    url: '/vipLevelManager/updateVipLevel',
    method: 'post',
    data
  })
}

export function deleteManagerVipLevel(data) {
  return request({
    url: '/vipLevelManager/deleteVipLevel',
    method: 'post',
    data
  })
}

// 银行卡管理
export function getBankCardList(data) { // 根据条件查询会员信息
  return request({
    url: '/bankCard/bankCardList',
    method: 'post',
    data
  })
}

export function updateBankCard(data) {
  return request({
    url: '/bankCard/editbankCard',
    method: 'post',
    data
  })
}

export function delBankCard(data) {
  return request({
    url: '/bankCard/deletBankCard',
    method: 'post',
    data
  })
}

// 重置IP，解锁切换IP登录需要手机短信验证
export function updateMemberLastLoginIp(data) {
  return request({
    url: '/userCenter/updateMemberLastLoginIp',
    method: 'post',
    data
  })
}

// 注册内部账号入口
export function userCenterRegister(data) {
  return request({
    url: '/userCenter/register',
    method: 'post',
    data
  })
}

export const getMemberAwards = (data) => {
  return request({
    url: '/memberAward/page',
    method: 'post',
    data
  })
}

export const getVIPAwards = (data) => {
  return request({
    url: '/vipAward/page',
    method: 'post',
    data
  })
}

export const getAppAward = (data) => {
  return request({
    url: '/appAward/page',
    method: 'post',
    data
  })
}


export const updateAppAward = (data) => {
  return request({
    url: '/appAward/update',
    method: 'post',
    data
  })
}

export const createAppAward = (data) => {
  return request({
    url: '/appAward/create',
    method: 'post',
    data
  })
}

export const deleteAppAward = (data) => {
  return request({
    url: '/appAward/delete',
    method: 'post',
    data
  })
}

export const getAllAppAwardList = (data) => {
  return request({
    url: '/appAward/list',
    method: 'post',
    data
  })
}


export const EditVipReward = (data) => {
  return request({
    url: '/vipAward/create',
    method: 'post',
    data
  })
}


export const getMemberAwardsDetails = (data) => {
  return request({
    url: '/memberAward/detail',
    method: 'post',
    data
  })
}
export const updateMemberAwards = (data) => {
  return request({
    url: '/memberAward/create',
    method: 'post',
    data
  })
}


export const getAppMemberInfo = (data) => {
  return request({
    url: '/userCenter/getAppMember',
    method: 'post',
    data
  })
}


export const getAwardConfig = (params) => {
  return request({
    url: '/vipAwardConfig/getConfig',
    method: 'get',
    params
  })
}
