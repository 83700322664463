<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="search.timeType"
        placeholder="时间类型"
        class="filter-item"
        style="width: 140px"
      >
        <el-option label="投注时间" :value="0" />
        <el-option label="结算时间" :value="1" />
      </el-select>
      <el-date-picker
        v-model="times"
        type="datetimerange"
        class="filter-item"
        style="width: 400px"
        :range-separator="$t('至')"
        :start-placeholder="$t('开始日期')"
        :end-placeholder="$t('结束日期')"
        value-format="timestamp"
        clearable
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      />
      <el-button class="filter-item" @click="handleSetTimes($event, 0)">{{
        $t("今天")
      }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 1)">{{
        $t("昨天")
      }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 7)">{{
        $t("_7天")
      }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 30)">{{
        $t("本月")
      }}</el-button>
      <el-button class="filter-item" @click="handleSetTimes($event, 60)">{{
        $t("上月")
      }}</el-button>
    </div>

    <div class="filter-container">
      <el-select
        v-model="search.seriesTag"
        clearable
        :placeholder="$t('系列')"
        class="filter-item"
        style="width: 140px"
        @change="_getLotterys"
      >
        <el-option
          v-for="item in seriesList"
          :key="item.seriesTag"
          :label="item.seriesName"
          :value="item.seriesTag"
        />
      </el-select>
      <el-select
        v-model="search.lotteryId"
        clearable
        :placeholder="$t('彩种')"
        class="filter-item"
        style="width: 140px"
        :disabled="!search.seriesTag"
        @change="_getPlayList"
      >
        <el-option
          v-for="item in lotterysList"
          :key="item.lotteryId"
          :label="item.name"
          :value="item.lotteryId"
        />
      </el-select>
      <el-select
        v-model="search.playClassTag"
        clearable
        :placeholder="$t('玩法')"
        class="filter-item"
        style="width: 140px"
        :disabled="!search.lotteryId"
      >
        <el-option
          v-for="item in playsList"
          :key="item.playClassTag"
          :label="item.playClassName"
          :value="item.playClassTag"
        />
      </el-select>
      <el-input
        v-model="search.issue"
        clearable
        :placeholder="$t('期号')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model="search.orderNo"
        clearable
        :placeholder="$t('订单号')"
        class="filter-item"
        style="width: 140px"
      />
      <el-select
        v-model="search.memberType"
        multiple
        collapse-tags
        clearable
        :placeholder="$t('会员类型')"
        class="filter-item"
        style="width: 140px"
      >
        <el-option
          v-for="item in memberTypes"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="search.isPush"
        clearable
        placeholder="是否推单订单"
        class="filter-item"
        style="width: 140px"
      >
        <el-option label="是" :value="1" />
        <el-option label="否" :value="0" />
      </el-select>
    </div>
    <div class="filter-container">
      <el-select
        v-model="search.status"
        clearable
        :placeholder="$t('状态')"
        class="filter-item"
        style="width: 140px"
      >
        <el-option
          v-for="item in bettingStatus"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
      <el-input
        v-model="search.memberName"
        clearable
        :placeholder="$t('会员账号')"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model.number="search.amount"
        clearable
        placeholder="投注超过额度"
        class="filter-item"
        style="width: 140px"
      />
      <el-input
        v-model.number="search.profit"
        clearable
        placeholder="盈利超过额度"
        class="filter-item"
        style="width: 140px"
      />
      <el-select
        v-model="search.paymentType"
        clearable
        :placeholder="$t('订单状态')"
        class="filter-item"
        style="width: 140px"
      >
        <el-option
          v-for="(val, key) in paymentType"
          :key="key"
          :label="val"
          :value="key"
        />
      </el-select>
      <el-input
        v-model="search.agencyMemberName"
        clearable
        :placeholder="$t('所属上级')"
        class="filter-item"
        style="width: 140px"
      />
      <el-select
        v-model="search.isBackgroundPush"
        clearable
        placeholder="是否后台推单"
        class="filter-item"
        style="width: 140px"
      >
        <el-option label="是" :value="1" />
        <el-option label="否" :value="0" />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleSearchWrap"
        >{{ $t("查询") }}</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-refresh-right"
        @click="resetSearch"
        >{{ $t("重置") }}</el-button
      >
    </div>
    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="{ ...options, size: 'small' }"
      row-key="key"
      selection-row
      :default-sort="{ prop: 'createTime', order: 'descending' }"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
      @sort-change="handleSortChange"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      lazy
      :load="loadDetail"
      @select="handleSelect"
      @select-all="handleSelect"
      :selectable="selectable"
    >
      <template slot="left">
        {{ $t("总投注额") }}：<span>{{ statistical ? statistical.countAmount : '***' }}</span
        >&nbsp;&nbsp; {{ $t("有效投注额") }}：<span>{{
          statistical ? statistical.realAmount : '***'
        }}</span
        >&nbsp;&nbsp; {{ $t("中奖金额") }}：<span>{{
          statistical ? statistical.countWin : '***'
        }}</span
        >&nbsp;&nbsp; {{ $t("盈亏") }}：<span>{{
          statistical ? statistical.countProfit : '***'
        }}</span
        >&nbsp;&nbsp; {{ $t("总用户") }}：<span>{{
          statistical ? statistical.countMember : '***'
        }}</span>&nbsp;&nbsp; 
        <el-link type="primary" @click="_getBetsTotal">查看结果</el-link>
      </template>
      <template #right>
        <el-button type="primary" v-if="permission.Push" @click="handleOpenPush"
          >六合推单</el-button
        >
        <el-button
          plain
          type="primary"
          :loading="excelLoading1"
          icon="el-icon-download"
          @click="_handleExport"
          >{{ $t("导出Excel") }}</el-button
        >
        <el-button
          plain
          type="primary"
          :loading="excelLoading2"
          icon="el-icon-download"
          @click="_handleCountExport"
          >{{ $t("汇总导出Excel") }}</el-button
        >
      </template>
    </d-table>

    <pagination
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      :total="total"
      @pagination="handleFetch"
    />
    <UserDialog ref="UserDialogControl" />
    <VisibleDialog ref="VisibleDialogControl" />
    <SettlementDialog
      ref="SettlementDialogControl"
      @settle="handleSettle"
      @issueSettle="handleIssueSettle"
    />
    <CancelDialog
      ref="CancelDialogControl"
      @cancel="handleCancel"
      @issueCancel="handleIssueCancel"
    />
    <OrderPushDialog ref="OrderPushDialogControl" @push="handleOrderPush" />
  </div>
</template>

<script>
import TablePageMixin from "@/mixins/table-page-mixin";
import { bettingStatus, paymentType } from "@/libs/options";
import { SettlementDialog, CancelDialog, VisibleDialog } from "./components";
import UserDialog from "@/views/components/UserDialog";
import OrderPushDialog from "./components/OrderPushDialog";
import GetColumns from "./columns";
import { getDefaultTimes, exportExcel } from "@/utils";
import {
  getBetList,
  betsDetail,
  getBetsTotal,
  getSeries,
  getLotterys,
  getPlayList,
  cancelBet,
  cancelIssueBets,
  settlementBets,
  settlementIssueBets,
  exportBetList,
  exportCountBetList,
  guruOrderLotteryOrderCreate,
} from "@/api/orders";

const SORT_NO_MESSAGE = {
  createTime: {
    descending: 0,
    ascending: 1,
  },
  amount: {
    descending: 2,
    ascending: 3,
  },
  profit: {
    descending: 4,
    ascending: 5,
  },
};

export default {
  name: "LotteryOrders",
  components: {
    UserDialog,
    CancelDialog,
    SettlementDialog,
    VisibleDialog,
    OrderPushDialog,
  },
  mixins: [TablePageMixin(getBetList, false)],
  data() {
    return {
      excelLoading1: false,
      excelLoading2: false,
      times: getDefaultTimes(),
      search: { timeType: 0 },
      cascaderOptions: [],
      columns: [],
      defaultColumns: GetColumns.call(this),
      memberTypes: this.$t("memberTypes"),
      bettingStatus,
      seriesList: [],
      lotterysList: [],
      playsList: [],
      statistical: null,
      paymentType,
      selectList: [],
      isCreated: false,
      timer: null
    }
  },
  created() {
    if (!this.isCreated) {
      this._getFetchWithMember()
      setTimeout(() => {
        this.isCreated = true
      }, 500);
    }
  },
  mounted() {
    this.implementationTenantChanged()
  },
  activated() {
    if (this.isCreated) {
      this._getFetchWithMember()
    }
  },
  methods: {
    _getFetchWithMember() {
      const { memberName, startTime, orderNo } = this.$route.query
      if (memberName) {
        this.search.memberName = memberName
      }
      if (startTime) {
        this.search.startTime = startTime
        this.times[0] = startTime
      }
      if (orderNo) {
        this.search.orderNo = orderNo
      }
      this.handleSearch();
    },
    handleSearchWrap() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.handleSearch()
      }, 500)      
    },
    _onDetails({ memberId }) {
      if (!this.permission.Details) return;
      this.$refs.UserDialogControl.open(memberId);
    },

    handleRefresh() {
      this.params.currentPage = 1;
      this.handleFetch();
    },

    handleSortChange({ prop, order }) {
      this.search.orderSort = SORT_NO_MESSAGE[prop][order];
      this.handleSearch();
    },

    _getBetsTotal() {
      // 获取统计详情
      getBetsTotal({ ...this.implementationGetParams() }).then(
        ([data, err]) => {
          if (!err) {
            const {
              countAmount,
              realAmount,
              countWin,
              countProfit,
              countMember,
            } = data;
            this.statistical = {
              countAmount,
              realAmount,
              countWin,
              countProfit,
              countMember,
            };
          }
        }
      );
    },

    implementationTenantChanged() {
      getSeries().then(([data, err]) => {
        if (!err) {
          this.seriesList = data.filter((o) => +o.seriesTag < 100);
        }
      });
    },

    loadDetail(tree, treeNode, resolve) {
      const search = this.formatIntervalTime();
      const params = {
        startTime: search.startTime,
        endTime: search.endTime,
        lotteryId: tree.lotteryId,
        issue: tree.issue,
        playClassTag: tree.playClassTag,
        deductBatchNumber: tree.deductBatchNumber,
      };
      betsDetail(params).then(([items, err]) => {
        if (!err) {
          items.forEach((e) => {
            e.key = "detal" + e.orderNo;
          });
          resolve(items);
        }
      });
    },

    handleSettle({ field, cancel, close }) {
      settlementBets({
        orderNo: field.orderNo,
        lotteryNo: field.lotteryNo,
      }).then(([_, err]) => {
        if (!err) {
          close();
          this.$message.success(this.$t("结算成功"));
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },

    handleIssueSettle({ field, cancel, close }) {
      settlementIssueBets({
        orderNo: field.orderNo,
        lotteryNo: field.lotteryNo,
      }).then(([_, err]) => {
        if (!err) {
          close();
          this.$message.success(this.$t("期号结算成功"));
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },

    handleCancel({ field, cancel, close }) {
      cancelBet({ ...field }).then(([_, err]) => {
        if (!err) {
          close();
          this.$message.success(this.$t("撤单成功"));
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },

    handleIssueCancel({ field, cancel, close }) {
      cancelIssueBets({ orderNo: field.orderNo }).then(([_, err]) => {
        if (!err) {
          close();
          this.$message.success(this.$t("期号撤单成功"));
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },

    _getLotterys(seriesTag) {
      if (this.search.lotteryId !== undefined) this.search.lotteryId = "";
      let params = { seriesTag };
      if (!seriesTag) return;
      getLotterys(params).then(([data, err]) => {
        if (!err) {
          this.lotterysList = data;
        }
      });
    },

    _getPlayList(lotteryId) {
      if (this.search.playClassTag !== undefined) this.search.playClassTag = "";
      let params = { lotteryId };
      if (!lotteryId) return;
      getPlayList(params).then(([data, err]) => {
        if (!err) {
          if (data && Array.isArray(data)) {
            this.playsList = data.reduce((p, v) => {
              p = p.concat(v.playClassList);
              return p;
            }, []);
          }
        }
      });
    },

    // implementationFetched({ countAmount, realAmount, countWin, countProfit, countMember }) {
    //   this.statistical = { countAmount, realAmount, countWin, countProfit, countMember }
    // },

    implementationGetParams() {
      let memberType = "";
      if (this.search.memberType) {
        memberType = this.search.memberType.toString();
      }
      // memberType = memberType.replace('2', '1,2')
      return { ...this.formatIntervalTime(), memberType };
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys);
    },
    implementationFetched() {
      this.items.forEach((e) => {
        e.children = [];
        e.hasChildren = true;
        e.key = "list" + e.orderNo;
      });
      this.statistical = null
    },
    _handleExport() {
      this.excelLoading1 = true;
      exportBetList({ ...this.implementationGetParams() })
        .then(([data, error, blobData]) => {
          if (!error) {
            exportExcel(
              blobData,
              `彩票注单-${this.$parseTime(new Date())}.csv`
            );
          }
        })
        .finally(() => {
          this.excelLoading1 = false;
        });
    },
    _handleCountExport() {
      this.excelLoading2 = true;
      exportCountBetList({ ...this.implementationGetParams() })
        .then(([data, error, blobData]) => {
          if (!error) {
            exportExcel(
              blobData,
              `彩票注单汇总-${this.$parseTime(new Date())}.csv`
            );
          }
        })
        .finally(() => {
          this.excelLoading2 = false;
        });
    },

    handleSelect(selection) {
      this.selectList = selection;
    },
    selectable(row) {
      if (
        [5, 6, 7].includes(row.status) &&
        ["40", "41"].includes(row.lotteryId)
      ) {
        return true;
      }
      return false;
    },
    handleOpenPush() {
      if (this.selectList.length === 0) {
        this.$message.warning("请选择要推单的注单");
        return;
      }
      this.$refs.OrderPushDialogControl.open(this.selectList);
    },

    handleOrderPush({ field, cancel, close }) {
      guruOrderLotteryOrderCreate(field).then(([_, err]) => {
        if (!err) {
          close();
          this.$message.success(this.$t("推单创建成功"));
          this.selectList = [];
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table .el-table__row:not(.el-table__row--level-0) .el-checkbox {
  display: none;
}
</style>
