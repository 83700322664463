<template>
  <div>
    <d-table
      ref="DTable"
      v-loading="loading"
      row-key="playTag"
      :drag-row="permission.Sort"
      index-row
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    >
      <el-button slot="left" type="success" plain @click="_handleSave">{{ $t('全部保存') }}</el-button>
    </d-table>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import TableSortMixin from '@/mixins/table-sort-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import { getPlayList, batchUpdatePlay } from '@/api/lottery'
import GetColumns from './columns'

export default {
  name: 'PlayTab',
  componentName: 'PlayTab',
  mixins: [TableSortMixin(), TablePageMixin(getPlayList)],
  props: {
    lotteryId: {
      type: String,
      default: ''
    },
    playClass: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  computed: {
    ...mapGetters(['operatorType'])
  },
  mounted() {
    this.setSort()
  },
  methods: {
    _handleUpdate({ playName, playTag, sort, status, lotteryId }) {
      this.$confirm(this.$t('确认修改玩法playName的信息', { text: playName }), { type: 'warning' }).then(() => {
        batchUpdatePlay([{
          playTag,
          sort,
          status,
          lotteryId
        }], this.operatorType).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('修改成功'))
          }
        })
      }).catch(() => {})
    },
    _handleSave() {
      this.$confirm(this.$t('确认修改所有玩法信息'), { type: 'warning' }).then(() => {
        const tmp = this.items.map((o, i) => ({
          playTag: o.playTag,
          sort: i,
          status: o.status,
          lotteryId: this.lotteryId
        }))
        batchUpdatePlay(tmp, this.operatorType).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('保存成功'))
            this.handleRefresh()
          }
        })
      }).catch(() => {})
    },
    implementationGetParams() {
      return { lotteryId: this.lotteryId }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
