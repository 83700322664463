const CanChooseColumns = function() {
  return [
    {
      title: this.$t('活动ID'),
      key: 'activityId',
      align: 'center'
    },
    {
      title: '排序',
      key: 'sortOrder',
      align: 'center'
    },
    {
      title: this.$t('活动标题'),
      key: 'title',
      align: 'center'
    },
    {
      title: this.$t('活动分类'),
      key: 'category',
      align: 'center',
      component: {
        render: (h, row) => {
          const activityType = this.categoryList.find(e => e.id === row.category)
          if (activityType) {
            const vnodes = []
            activityType.typeNameLang.split(',').forEach(e => {
              const typeLang = e.split(':')[0]
              const typeName = e.split(':')[1]
              const lang = this.allLang.find(v => v.lang === typeLang)
              if (lang) {
                // vnodes.push(<div>{ this.$t(lang.name) + ':' + typeName }</div>)
                vnodes.push(<div>{ typeName }</div>)
              }
            })
            return (<span>{ vnodes }</span>)
          }
          return ''
        }
      }
    },
    {
      title: this.$t('活动任务类型'),
      key: 'taskType',
      align: 'center',
      component: {
        render: (h, row) => {
          const activityType = this.taskCategory.find(e => e.key === row.taskType)
          if (activityType) {
            return (<span>{ activityType.label }</span>)
          }
          return ''
        }
      }
    },
    {
      title: this.$t('任务行为'),
      key: 'taskAction',
      align: 'center',
      component: {
        render: (h, row) => {
          const taskAction = this.taskAction.find(e => e.key === row.taskAction)
          if (taskAction) {
            return (<span>{ taskAction.label }</span>)
          } else {
            return (<span>--</span>)
          }
        }
      }
    },
    {
      title: this.$t('活动引导'),
      key: 'guide',
      align: 'center'
    },
    {
      title: this.$t('活动Banner'),
      key: 'banner',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image src={ row.banner } lazy></el-image>)
        }
      }
    },
    {
      title: this.$t('内页Banner'),
      key: 'detailBanner',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<el-image src={ row.detailBanner } lazy></el-image>)
        }
      }
    },
    {
      title: this.$t('语言'),
      key: 'languageSet',
      align: 'center',
      component: {
        render: (h, row) => {
          let langArray = []
          if (row.languageSet) {
            const allLang = this.$store.getters.allLang
            row.languageSet.split(',').forEach(e => {
              const lang = allLang.find(v => v.lang === e)
              if (lang) {
                langArray.push(lang.name)
              }
            })
          }
          return (<span>{ langArray.toString() }</span>)
        }
      }
    },
    {
      title: this.$t('跳转页面'),
      key: 'menuName',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ row.menuName || row.jumpUrl }</span>)
        }
      }
    },
    {
      title: this.$t('活动时段'),
      key: 'activityDate',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.isContinuous === 1) {
            return (<span>{ this.$t('持续开启') }</span>)
          } else {
            return (<span>{ this.$parseTime(row.startTime, '{y}-{m}-{d}') }~{ this.$parseTime(row.endTime, '{y}-{m}-{d}') }</span>)
          }
        }
      }
    },
    {
      title: this.$t('活动状态'),
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          const status = this.activityStatus.find(o => o.key === row.status)
          return (<span style={{ color: row.status === 0 ? '#34C758' : '' }}>{ status ? status.label : '' }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 290,
      component: {
        render: (h, row) => {
          const { Update, Close } = this.permission
          return (
            <div>
              {/* { Close && row.status == 0 && <el-button type='success' size='small' icon='el-icon-check' onClick={this.handleCloseActivity.bind(this, row, 1)}>{ this.$t('开启') }</el-button> } */}
              { Close && row.status == 1 && <el-button type='danger' size='small' icon='el-icon-close' onClick={this.handleCloseActivity.bind(this, row, 0)}>{ this.$t('关闭') }</el-button> }
              { Update && (row.status == 0 || row.status == 1) && <el-button type='primary' size='small' icon='el-icon-edit' onClick={ () => { this.$refs.ActionDialogControl.open(row) } }>{ this.$t('编辑') }</el-button> }
              { row.status == 0 && <el-button type='primary' size='small' icon='el-icon-tickets' onClick={ () => { this.$refs.ActionDialogControl.open(row, true) } }>{ this.$t('查看详情') }</el-button> }
            </div>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
