var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("创建巡查"),
        visible: _vm.visible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
        open: _vm.onOpen,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("区域号"), prop: "region" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: _vm.$t("请选择") },
                  model: {
                    value: _vm.ruleForm.region,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "region", $$v)
                    },
                    expression: "ruleForm.region",
                  },
                },
                _vm._l(_vm.Regins, function (item, i) {
                  return _c(
                    "el-option",
                    { key: item.code + i, attrs: { value: item.code } },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(item.city)),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#909399",
                            "font-size": "12px",
                          },
                        },
                        [_vm._v(_vm._s(item.code))]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("账号"), prop: "memberName" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("6-11位数字") },
                model: {
                  value: _vm.ruleForm.memberName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "memberName", $$v)
                  },
                  expression: "ruleForm.memberName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("昵称"), prop: "nickname" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("2-12位") },
                model: {
                  value: _vm.ruleForm.nickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "nickname", $$v)
                  },
                  expression: "ruleForm.nickname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("密码"), prop: "password", required: "" },
            },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("6-12位") },
                model: {
                  value: _vm.ruleForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "password", $$v)
                  },
                  expression: "ruleForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("再次输入新密码"),
                prop: "confirmPassword",
                required: "",
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("6-12位") },
                model: {
                  value: _vm.ruleForm.confirmPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "confirmPassword", $$v)
                  },
                  expression: "ruleForm.confirmPassword",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("取消")) + " "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("保存")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }