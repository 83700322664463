import { filterColumns } from "@/utils"

const CanChooseColumns = function() {
  return [
    {
      title: '租户组别',
      key: 'groupName',
      align: 'center'
    },
    {
      title: '创建时间',
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: '操作',
      align: 'center',
      key: 'actions',
      width: 280,
      component: {
        render: (h, row) => {
          const { Update, Delete } = this.permission
          return (
            <span>
              { Update && <el-link type='primary' onClick={ () => this.$refs.ActionDialogControl.open(row) }>编辑</el-link> }
              { Delete && <el-link type='primary' onClick={ () => this._handleDelete(row) }>删除</el-link> }
             
            </span>
          )
        }
      }
    }
  ]
}

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}