import { filterColumns } from '@/utils'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('发送方式'),
      key: 'sendType',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.sendTypeOptions[row.sendType] }</span>)
        }
      }
    },
    {
      title: this.$t('站内信标题'),
      key: 'title',
      align: 'center'
    },
    {
      title: this.$t('已读回执'),
      key: 'read',
      align: 'center',
      component: {
        render: (h, row) => {
          if (row.read === null) {
            return h('span', { class: { tips_: true }}, this.$t('单个会员显示回执'))
          } else {
            const $text = row.read ? this.$t('已读') : this.$t('未读')
            return (<span>{ $text }</span>)
          }
        }
      }
    },
    {
      title: this.$t('编辑时间'),
      key: 'updateTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.updateTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作员'),
      key: 'operatorName',
      align: 'center'
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      width: 200,
      component: {
        render: (h, row) => {
          const { Delete, Update } = this.permission
          return (
            <span>
              { Update && <el-button type='primary' icon='el-icon-edit' size='mini' onClick={ () => this.$refs.ActionDialogControl.open(row) }>{this.$t('编辑')}</el-button> }
              { Delete && <el-button type='danger' icon='el-icon-delete' size='mini' onClick={ () => this._handleDelete(row) }>{this.$t('删除')}</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
