const CanChooseColumns = function() {
  return [
    {
      title: '发帖昵称',
      key: 'nickname',
      align: 'center',
      width: 150,
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>[{ this.xsLottery[row.lotteryId] }]</p>
              <span class='primary-type'>{ row.nickname }</span>
            </div>
          )
        }
      }
    },
    {
      title: '标题',
      key: 'title',
      headerAlign: 'center'
    },
    {
      title: '评论昵称',
      key: 'commentNickname',
      align: 'center',
      width: 150,
      component: {
        render: (h, row) => {
          return (<span class='primary-type'>{ row.commentNickname }</span>)
        }
      }
    },
    {
      title: '评论内容',
      key: 'content',
      headerAlign: 'center'
    },
    {
      title: '评论时间',
      key: 'createTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.createTime) }</span>)
        }
      }
    },
    {
      title: '操作',
      align: 'center',
      width: 120,
      component: {
        render: (h, row) => {
          const { Reply, Del, Limit } = this.permission
          return (
            <el-dropdown trigger='click' class='cursor-pointer' onCommand={command => this._onCommand(command, row)}>
              <span class='el-dropdown-link'>
                <i class='el-icon-arrow-down el-icon--right'></i>
              </span>
              <el-dropdown-menu slot='dropdown'>
                { Reply && <el-dropdown-item command={0} icon='el-icon-edit'>评论帖子</el-dropdown-item> }
                { Del && <el-dropdown-item command={1} icon='el-icon-delete'>删除评论</el-dropdown-item> }
                { Limit && <el-dropdown-item command={2} icon='el-icon-video-pause'>限制心水</el-dropdown-item> }
              </el-dropdown-menu>
            </el-dropdown>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
