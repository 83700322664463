export  const rewardTypes = [
    "UPGRADE_BOUNS", // 晋级彩金
    "WEEK_RED_ENVELOPE", // 周红包
    "MONTH_RED_ENVELOPE", // 月红包
    "BORROW_MONEY", // 借呗
    "GAME_REBATE", // 游戏返水
    "WITHDRAWAL_LIMIT", // 提现额度
    "BIRTHDAY_RED_ENVELOPE", // 生日红包
    "POINTS_COLLECTION", // 积分领取
    "ACTIVITY_CENTER", // 活动中心
  ];