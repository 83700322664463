export function getPermissionPath(routers) {
  return routers.reduce((p, v) => {
    p.push(v.path)
    if (Array.isArray(v.children) && v.children.length) {
      p = p.concat(getPermissionPath(v.children))
    }
    return p
  }, [])
}

export function passRouter(path, routers) {
  if (path === '/dashboard') return true
  return getPermissionPath(routers).includes(path)
}
