<template>
  <el-dialog title="评论" :visible.sync="visible" width="700px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px" label-suffix=":">
      <el-form-item label="发帖昵称">
        {{ nickname }}
      </el-form-item>
      <el-form-item label="评论昵称" required prop="commentNickname">
        <el-input v-model="ruleForm.commentNickname" />
      </el-form-item>
      <el-form-item label="评论内容" prop="content">
        <el-input v-model="ruleForm.content" type="textarea" :rows="4" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        发送
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { validNickName } from '@/utils/validate'

const getDefaultForm = () => ({
  commentNickname: '',
  content: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    const validNickname = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入昵称'))
      } else if (!validNickName(value)) {
        callback(new Error('请输入2~6位中文'))
      } else {
        callback()
      }
    }

    return {
      emitName: 'update',
      nickname: '',
      rules: {
        commentNickname: [
          { validator: validNickname, trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入评论内容', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    open({ id, postId, nickname, commentNickname, tenantCode }) {
      this.nickname = nickname
      this.ruleForm.postId = postId
      this.ruleForm.parentId = id
      this.ruleForm.beCommentNickname = commentNickname
      this.visible = true
    }
  }
}
</script>
