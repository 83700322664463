<template>
  <el-dialog
    :title="$t('编辑房间')"
    :visible.sync="visible"
    width="520px"
    top="5vh"
    :close-on-click-modal="false"
    class="border-form-dialog"
    @open="onOpen"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      v-loading="loading"
      :model="ruleForm"
      :rules="rules"
      label-width="160px"
    >
      <el-form-item :label="$t('房间主题')" prop="roomName">
        <el-input v-model="ruleForm.roomName" :disabled="disabled" show-word-limit maxlength="12" />
      </el-form-item>
      <el-form-item :label="$t('分辨率')" prop="displayStatus">
        <el-select v-model="ruleForm.displayStatus" :disabled="disabled" style="width: 100%">
          <el-option v-for="(value, key) in $parent.displayStatusList" :key="key" :label="value" :value="+key" />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('彩种类型')" prop="lottypeId">
        <el-select
          v-model="ruleForm.lottypeId"
          :disabled="disabled"
          :placeholder="$t('彩种类型')"
          style="width: 100%"
          @change="handleChangeLottertType"
        >
          <el-option v-for="item in $parent.livePreLotteryType" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('彩种/赛事')" prop="lotkindId" id="lotkindId">
        <el-select
          v-model="ruleForm.lotkindId"
          filterable
          remote
          collapse-tags
          :disabled="disabled || ruleForm.lottypeId === undefined || ruleForm.lottypeId === '' || ruleForm.lottypeId === null || ruleForm.lottypeId === 4"
          reserve-keyword
          placeholder="彩种/赛事"
          style="width: 100%"
          :remote-method="remoteMethod"
          :loading="lotkindIdLoading"
        >
          <el-option
            v-for="item in lotteryList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('频道')" prop="channelUnionId">
        <el-checkbox-group
          v-model="ruleForm.channelUnionId"
          :disabled="disabled"
        >
          <el-checkbox v-for="item in $parent.channelList" :key="item.value" :label="item.value">{{ item.label }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="ruleForm.channelUnionId.length > 0" :label="$t('直播推荐')">
        <div v-for="item in $parent.channelList.filter(e => ruleForm.channelUnionId.includes(e.value))" :key="item.value" class="channel-type">
          {{ item.label }}频道
          <el-select
            v-model="item.categoryUnionId"
            :disabled="disabled"
            filterable
            clearable
            collapse-tags
            placeholder="请输入频道分类搜索"
          >
            <el-option
              v-for="category in $parent.categoryList.filter(e => e.channelUnionId === item.value)"
              :key="category.value"
              :label="category.label"
              :value="category.value"
            />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item :label="$t('人工热度')" prop="artificialHotNum">
        <el-input v-model.number="ruleForm.artificialHotNum" :placeholder="$t('最大可输入99999')" />
      </el-form-item>
      <el-form-item label="直播方式" prop="serverSource">
        <el-select
          v-model="ruleForm.serverSource"
          placeholder="直播方式"
          style="width: 100%"
        >
          <el-option v-for="(val, key) in serverSource" :key="key" :label="val" :value="Number(key)" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.serverSource === 2" :label="$t('流媒体地址')">
        <el-input v-model="streamMediaUrl" placeholder="输入https链接，http链接会导致h5无法显示"/>
      </el-form-item>
      <el-form-item v-if="ruleForm.serverSource === 4" label="点播视频地址">
        <el-input v-model="streamMediaUrl" placeholder="输入https链接，http链接会导致h5无法显示"/>
      </el-form-item>
      <el-form-item v-if="ruleForm.serverSource === 3" label="推流地址">
        {{ streamUrl }}
      </el-form-item>
      <el-form-item v-if="ruleForm.serverSource === 3" label="推流码">
        {{ streamKey }}
      </el-form-item>
      <el-form-item :label="$t('直播封面')" prop="cover">
        <img v-if="disabled" :src="ruleForm.cover" class="thumbnail">
        <el-upload
          v-else
          class="thumbnail-uploader"
          accept="image/jpeg,image/png,image/jpg,image/webp"
          :action="action"
          :data="{ type: 'image', folder: 'ad-photo' }"
          :headers="$headers()"
          :show-file-list="false"
          :on-success="handleThumbnailSuccess"
        >
          <img v-if="ruleForm.cover" :src="ruleForm.cover" class="thumbnail">
          <i v-else class="el-icon-plus thumbnail-uploader-icon" />
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmitBefor">
        {{ $t('确认') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { getLanguageKey } from '@/lang/index'
import { livePlanManagementDetail, liveManagementGenStreamKey } from '@/api/live'
import { footballThirdMatchList, basketballThirdMatchList } from '@/api/lottery'
import { serverSource } from '@/libs/options'
import dayjs from 'dayjs'
const getDefaultForm = () => ({
  planId: '',
  roomName: '',
  displayStatus: null,
  lottypeId: '',
  lotkindId: '',
  lotteryName: '',
  channelUnionId: [],
  artificialHotNum: 0,
  serverSource: '',
  clientAddress: '',
  cover: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      loading: false,
      lotkindIdLoading: false,
      lotteryList: [],
      disabled: false,
      serverSource,
      streamUrl: '',
      streamKey: '',
      clientAddress: '',
      streamMediaUrl: '',
      rules: {
        roomName: [{ required: true, message: this.$t('请输入房间主题'), trigger: 'blur' }],
        artificialHotNum: [
          { pattern: /^(0|\+?[1-9][0-9]{0,4})$/, message: this.$t('请输入0-99999'), trigger: 'blur' }
        ],
        displayStatus: [{ required: true, message: this.$t('请选择分辨率'), trigger: 'change' }],
        lottypeId: [{ required: true, message: this.$t('请选择彩种类型'), trigger: 'change' }],
        lotkindId: [{ required: true, message: this.$t('请选择彩种/赛事'), trigger: 'change' }],
        channelUnionId: [{ required: true, message: this.$t('请选择频道'), trigger: 'change' }],
        serverSource: [{ required: true, message: '请选择直播方式', trigger: 'change' }],
        cover: [{ required: true, message: this.$t('请上传直播封面'), trigger: 'change' }]
      },
      lotteryName: ''
    }
  },
  computed: {
    action() {
      return `${process.env.VUE_APP_FILE_UPLOAD}`
    }
  },
  methods: {
    getLanguageKey,
    open(row, disabled) {
      this.disabled = disabled
      this.ruleForm.planId = row.planId
      this.lotteryName = row.lotteryName
      liveManagementGenStreamKey({ anchorAccno: row.anchorAccno }).then(([data, err]) => {
        if (!err && data) {
          this.streamUrl = data.streamUrl
          this.streamKey = data.streamKey
          this.clientAddress = JSON.stringify(data)
        } 
      })
      this.visible = true
    },
    onOpen() {
      this.loading = true
      livePlanManagementDetail({ planId: this.ruleForm.planId }).then(async ([row, err]) => {
        if (!err && row) {
          this.ruleForm.roomName = row.roomName
          this.ruleForm.displayStatus = row.displayStatus
          this.ruleForm.lottypeId = row.lottypeId
          this.handleChangeLottertType(row.lottypeId)
          if (row.lotkindId) {
            await this.remoteMethod(row.lotteryName || this.lotteryName, row.lotkindId)
          }
          this.ruleForm.lotkindId = row.lotkindId
          this.ruleForm.lotteryName = row.lotteryName
          if (row.channelList && row.channelList.length > 0) {
            this.ruleForm.channelUnionId = row.channelList.filter(e => e.channelId).map(e => { return e.channelUnionId })
            this.$parent.channelList.forEach(e => {
              e.categoryUnionId = ''
              const channel = row.channelList.find(v => v.channelUnionId === e.value)
              if (channel) {
                e.id = channel.id
                e.categoryUnionId = channel.categoryUnionId
              }
            })
          }
          this.ruleForm.artificialHotNum = row.artificialHotNum
          this.ruleForm.serverSource = row.serverSource
          if (row.serverSource === 2 || row.serverSource === 4) {
            this.streamMediaUrl = row.clientAddress
          }
          this.ruleForm.cover = row.cover
        }
        this.loading = false
      })
    },
    handleChangeLottertType(val) {
      this.ruleForm.lotkindId = ''
      this.lotteryList = []
      if (val === 4) {
        const errorElement = document.querySelector('#lotkindId').querySelector('.el-form-item__error')
        if(errorElement) {
          errorElement.style.display = 'none'
        }
        this.rules.lotkindId = []
      } else {
        const errorElement = document.querySelector('#lotkindId').querySelector('.el-form-item__error')
        if(errorElement) {
          errorElement.style.display = 'block'
        }
        this.rules.lotkindId = [{ required: true, message: this.$t('请选择彩种/赛事'), trigger: 'change' }]
      }
    },
    handleThumbnailSuccess(res) {
      this.ruleForm.cover = res.data[0]
      this.$refs.validateForm.validateField('cover')
    },
    handleSubmitBefor() {
      // if (this.ruleForm.serverSource === 4 && this.streamMediaUrl) {
      //   const suffix = this.streamMediaUrl.split('.')[this.streamMediaUrl.split('.').length - 1]
      //   if (!['MP4','TS','MOV','MXF','MPG','FLV','WMV','AVI','M4V','F4V','MPEG','3GP','ASF','MKV'].includes(suffix.toUpperCase())) {
      //     this.$message.error('点播视频地址必须是【MP4,TS,MOV,MXF,MPG,FLV,WMV,AVI,M4V,F4V,MPEG,3GP,ASF,MKV】后缀名地址')
      //     return
      //   }
      // }
      if (this.ruleForm.serverSource === 2 || this.ruleForm.serverSource === 4) {
        this.ruleForm.clientAddress = this.streamMediaUrl
      }
      if (this.ruleForm.serverSource === 3) {
        this.ruleForm.clientAddress = this.clientAddress
      }
      this.handleSubmit()
    },
    async remoteMethod(query, lotkindId) {
      if (this.ruleForm.lottypeId === 1 && lotkindId) {
        this.lotteryList = this.$parent.lotteryOptions
        return
      }
      if (query !== '' && query !== null) {
        if (this.ruleForm.lottypeId === 1) {
          this.lotteryList = this.$parent.lotteryOptions.filter(e => e.label.toLowerCase().indexOf(query.toLowerCase()) > -1)
          return
        }
        this.lotkindIdLoading = true
        let service = footballThirdMatchList
        if (this.ruleForm.lottypeId === 3) {
          service = basketballThirdMatchList
        }
        const [items, err] = await service({ competitionName: query, currentPage: 1, pageSize: 100,startMatchTime:dayjs().format('YYYY-MM-DD 00:00:00'),endMatchTime:dayjs().add(7,'day').format('YYYY-MM-DD 00:00:00')  })
        if (!err && items && items.length) {
          this.lotteryList = items.map(e => {
            return {
              value: e.thirdMatchId,
              label: e.competitionNameZh + '-' + e.homeTeamNameZh + 'VS' + e.awayTeamNameZh
            }
          })
        }
        this.lotkindIdLoading = false
      } else {
        this.lotteryList = []
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.thumbnail-uploader {

  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409EFF;
    }
  }
  &-icon {
    font-size: 30px;
    color: #8c939d;
    width: 150px;
    height: 264px;
    line-height: 264px;
    text-align: center;
  }
}

.thumbnail {
  width: 150px;
  height: 264px;
  display: block;
}

.channel-type {
  display: flex;
  align-items: center;
  > .el-select {
    margin-left: 10px;
  }
  + .channel-type {
    margin-top: 10px;
  }
}
</style>
