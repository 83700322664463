<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="search.tenantCode"
        placeholder="请选择租户"
        @change="_handleChangeTenant"
      >
        <el-option
          value="DOMAINQR"
          label="域名二维码租户"
        />
        <el-option
          v-for="item in tenants"
          :key="item.tenantCode"
          :value="item.tenantCode"
          :label="item.tenantName"
        />
      </el-select>
    </div>
    <div class="filter-container">
      <el-select v-model="search.groupId" clearable placeholder="组别" class="filter-item" style="width: 140px">
        <el-option v-for="item in groupList" :key="item.id" :label="item.groupName" :value="item.id" />
      </el-select>
      <el-input v-model="search.operatorName" clearable placeholder="账号" class="filter-item" style="width: 140px" />
      <el-input v-model="search.createUser" clearable placeholder="创建人" class="filter-item" style="width: 140px" />
      <el-input v-model="search.updateUser" clearable placeholder="修改人" class="filter-item" style="width: 140px" />
      <el-button class="filter-item" :disabled="!search.tenantCode" type="primary" icon="el-icon-search" @click="handleSearch">
        查询
      </el-button>
      <el-button v-if="permission.Add" :disabled="!search.tenantCode" class="filter-item" type="success" icon="el-icon-plus" @click="onCreate">
        添加账号
      </el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleSearch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <CreateDialog ref="CreateDialogControl" @create="handleAddOperator" />
    <UpdateDialog ref="UpdateDialogControl" @update="handleUpdateOperator" />
    <RemarkDialog ref="RemarkDialogControl" @update="handleUpdateRemark" />
    <AccountValidDialog ref="AccountValidDialogControl" @validate="handleValid" @close="onValidClose" />
    <ValidGoogleVisible ref="ValidGoogleVisibleControl" />

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getOperatorList, addOperator, updateOperator, updateOperatorStatus, addOperatorRemark, getGroupList, checkGoogleKey } from '@/api/permission'
import TablePageMixin from '@/mixins/table-page-mixin'
import { CreateDialog, UpdateDialog } from './components'
import RemarkDialog from '@/views/components/RemarkDialog'
import AccountValidDialog from '@/views/components/AccountValidDialog'
import ValidGoogleVisible from '@/views/components/ValidGoogleVisible'
import GetColumns from './columns'

export default {
  name: 'TenantUsers',
  components: {
    UpdateDialog,
    CreateDialog,
    RemarkDialog,
    AccountValidDialog,
    ValidGoogleVisible
  },
  mixins: [TablePageMixin(getOperatorList, false)],
  data() {
    return {
      search: {},
      groupList: [],
      columns: [],
      defaultColumns: GetColumns.call(this)
    }
  },

  computed: {
    ...mapState('app', ['tenants'])
  },
  created() {
    this.getTenantList()
  },

  methods: {
    ...mapActions('app', ['getTenantList']),
    onCreate() {
      this.$refs.CreateDialogControl.open(this.search.tenantCode)
    },

    _handleChangeTenant() {
      this.fetchGroupList()
      this.handleRefresh()
    },

    fetchGroupList() {
      getGroupList({ currentPage: 1, pageSize: 100, tenantCode: this.search.tenantCode }).then(([data, err]) => {
        if (!err) {
          this.groupList = data || []
        }
      })
    },

    handleValid({ field, cancel, close }) {
      const { cb, ...params } = field
      checkGoogleKey({ ...params }).then(([_, err]) => {
        if (!err) {
          close()
          cb()
        } else {
          cancel()
        }
      })
    },

    onValidClose() {
      this.$refs.EditDialogControl.submitting = false
      this.$refs.CreateDialogControl.submitting = false
    },

    handleAddOperator({ field, cancel, close }) { // 新增账号
      delete field.checkPassword
      addOperator({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('新增成功'))
          this.handleRefresh()
          this.$refs.ValidGoogleVisibleControl.open(data)
        } else {
          cancel()
        }
      })
    },

    handleUpdateOperator({ field, cancel, close }) { // 编辑账号
      updateOperator({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('保存成功'))
          this.handleRefresh()
          this.$refs.ValidGoogleVisibleControl.open(data)
        } else {
          cancel()
        }
      })
    },

    handleChangeStatus({ operatorId, operatorName, status, tenantCode }) {
      return new Promise((resolve) => {
        this.$confirm(`确认${status === 1 ? '启用' : '凍結'}此賬戶`, '系统提示', { type: 'warning' }).then(() => {
          updateOperatorStatus({
            operatorId,
            operatorName,
            tenantCode,
            status: ~status + 2
          }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功!')
            }
          }).catch(() => {})
        })
      })
    },

    handleUpdateRemark({ field, cancel, close }) {
      addOperatorRemark({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success('操作成功!')
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },

    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
