<template>
  <div class="app-container">
    <template v-if="Array.isArray(permissionTabs) && permissionTabs.length">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="tab of permissionTabs" :key="tab.name" :label="tab.label" :name="tab.name" />
      </el-tabs>
      <keep-alive>
        <component :is="activeName" ref="AliveComponent" />
      </keep-alive>
    </template>
  </div>
</template>

<script>
import ConfigTab from './ConfigTab'
import RedTab from './RedTab'
import ClassificationTab from './ClassificationTab'
import NewConfigTab from './NewConfigTab'
import { parentMixin } from '@/mixins/tab-items'

export default {
  components: {
    ConfigTab,
    RedTab,
    ClassificationTab,
    NewConfigTab,
  },
  mixins: [parentMixin],
  data() {
    return {
      tenant: null,
      tabs: this.$t('activityTabs')
    }
  }
}
</script>

