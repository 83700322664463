<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.username" clearable :placeholder="$t('用户账户')" class="filter-item"
        style="width: 120px" />
      <el-input v-model="search.realName" clearable :placeholder="$t('真实姓名')" class="filter-item"
        style="width: 120px" />
      <el-input v-model="search.cardNumber" clearable :placeholder="$t('银行账户')" class="filter-item"
        style="width: 200px" />
      <el-select v-model="search.status" clearable :placeholder="$t('状态')" class="filter-item" style="width: 120px">
        <el-option :label="$t('正常')" :value="0" />
        <el-option :label="$t('黑名单')" :value="1" />
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">{{ $t('查询')
        }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置')
        }}</el-button>
      <el-button class="filter-item" type="primary" @click="onAddBankCard">{{ $t('绑定银行卡') }}</el-button>
    </div>

    <d-table ref="DTable" v-loading="loading" :data="items" :columns="columns" :original-columns="defaultColumns"
      :options="options" @refresh="handleFetch" @column-change="handleColumnChange" />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total"
      @pagination="handleFetch" />
    <ActionDialog ref="ActionDialogControl" @add="handleAddCard" />
    <EditDialog ref="EditDialogControl" @update="hanldeUpdateCard" />
  </div>
</template>
<script>
import { getBankCardList, updateBankCard, delBankCard } from '@/api/member'
import { addBankCard } from '@/api/finance'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import EditDialog from './EditDialog'
import ActionDialog from './ActionDialog'

export default {
  name: 'MemberBank',
  components: { EditDialog, ActionDialog },
  mixins: [TablePageMixin(getBankCardList)],
  data() {
    return {
      search: {},
      columns: [],
      defaultColumns: GetColumns.call(this),
    }
  },
  methods: {
    onAddBankCard() {
      this.$refs.ActionDialogControl.open()
    },
    handleSearch() {
      this.handleRefresh()
    },
    handleAddCard({ field, cancel, close }) {
      const params = {
        username: field.username,
        rateType: field.rateType,
        cardNumber: field.cardNumber,
        cardName: field.cardName,
        bankAddress: field.bankAddress
      }
      addBankCard(params).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('添加成功'))
        } else {
          cancel()
        }
      })

    },
    hanldeUpdateCard({ field, cancel, close }) {
      const params = {
        id: field.id,
        username: field.username,
        realName: field.realName,
        cardNumber: field.cardNumber,
        status: field.status,
        memberId: field.memberId,
        bank: field.bank,
        bankAddress: field.bankAddress
      }
      updateBankCard(params).then(([_, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success(this.$t('保存成功'))
        } else {
          cancel()
        }
      })
    },

    handleDeleteCard({ id, memberId }) {
      this.$confirm(this.$t('确认删除此银行'), this.$t('系统提示'), { type: 'error' }).then(() => {
        delBankCard({ id, memberId, deleted: 1 }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },
    onlineFilter(status) {
      const statusArray = ['success', 'danger']
      return statusArray[status]
    },

    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
