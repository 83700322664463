<template>
  <el-dialog title="回复评论" width="600px" :visible.sync="visible" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="160px" label-suffix=":">
      <el-form-item label="用户类型">
        <el-radio-group v-model="type">
          <el-radio :label="1">后台发评论</el-radio>
          <el-radio :label="0">代用户发评论</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="type === 0">
        <el-form-item label="用户账号" prop="memberName">
          <el-input v-model="ruleForm.memberName" placeholder="用户账号" />
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="后台用户" prop="nickName">
          <el-input v-model="ruleForm.nickName" placeholder="后台用户昵称" />
        </el-form-item>
      </template>
      <el-form-item label="评论内容" prop="content">
        <el-input v-model="ruleForm.content" type="textarea" rows="3" maxlength="25" show-word-limit placeholder="评论内容" />
      </el-form-item>
      <el-form-item>
        <el-button type="danger" @click="close">
          取消
        </el-button>
        <el-button type="success" @click="submit">发布</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import FormDialogMixin from '@/mixins/form-dialog'
import {
  sendReply,
  sendAgentReply
} from '@/api/interactive'

const getDefaultForm = () => ({
  memberName: '',
  nickName: '',
  content: '',
  articleId: '',
  parentId: '',
  parentNickname: ''
})

export default {
  name: 'ShareLapReply',
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'submit',
      type: 0,
      rules: {
        nickName: [
          { required: true, message: '请输入后台用户昵称', trigger: 'blur' }
        ],
        memberName: [
          { required: true, message: '请输入用户账号', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '评论内容', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(articleId, row, tenantCode) { // 初始化
      this.ruleForm.articleId = articleId
      if (row.parentId == '0') {
        this.ruleForm.parentId = row.id
      } else {
        this.ruleForm.parentId = row.parentId
      }
      this.ruleForm.parentNickname = row.replyNickname
      if (!tenantCode) {
        this.ruleForm.tenantCode = this.$store.getters.tenantCode
      } else {
        this.ruleForm.tenantCode = tenantCode
      }
      this.visible = true
    },
    submit() {
      this.$refs.validateForm.validate(valid => {
        if (valid) {
          const handle = this.type === 0 ? sendAgentReply : sendReply

          handle(this.ruleForm).then(([data, err]) => {
            if (!err) {
              this.$message.success(`评论成功`)
              this.visible = false
              this.handleSubmit()
            }
          })
        }
      })
    }
  }
}
</script>
