import { filterColumns } from '@/utils';
const CanChooseColumns = function () {
  return [
    {
      title: this.$t('平台用户账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('三方用户账号'),
      key: 'thirdMemberName',
      align: 'center'
    },
    {
      title: this.$t('三方下分请求单号'),
      key: 'orderNo',
      align: 'center'
    },
    {
      title: this.$t('三方游戏'),
      key: 'gameName',
      align: 'center'
    },
    {
      title: this.$t('三方游戏下分金额'),
      key: 'xfAmount',
      align: 'center'
    },
    {
      title: this.$t('下分失败时间'),
      key: 'createTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span> {this.$parseTime(row.createTime)} </span>
          );
        }
      }
    },
    {
      title: this.$t('最后更新时间'),
      key: 'updateTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span> {this.$parseTime(row.updateTime)} </span>
          );
        }
      }
    },
    {
      title: this.$t('最后操作用户'),
      key: 'operatorName',
      align: 'center'
    },
    {
      title: this.$t('下次重试时间'),
      key: 'nextRetryTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <span> {this.$parseTime(row.nextRetryTime)} </span>
          );
        }
      }
    },
    {
      title: this.$t('状态'),
      key: 'retryStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{this.tryPreStatus[row.retryStatus]}</span>);
        }
      }
    }
  ];
};

export default function (keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)];
}
