export const ruleForm = {
  oneLevelRakeBack: '',
  twoLevelRakeBack: '',
  settlementType: ''
}

const defaultRule = [
  { required: true, message: '请输入代理返点数值', trigger: 'blur' },
  { pattern: /^(0|[1-9][0-9]*)(\.\d+)?$/, message: '请输入正数数值', trigger: 'blur' }
]

export const defaultSettleType = { 1: '人工结算', 2: '系统结算' }

export const rules = {
  oneLevelRakeBack: [...defaultRule],
  twoLevelRakeBack: [...defaultRule],
  settlementType: [{ required: true, message: '请选择结算方式', trigger: 'change' }]
}
