var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "d-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "DTable",
          attrs: {
            "row-key": "playTag",
            "drag-row": _vm.permission.Sort,
            "index-row": "",
            data: _vm.items,
            columns: _vm.columns,
            options: _vm.options,
            "filter-column": _vm.filterColumn,
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { slot: "left", type: "success", plain: "" },
              on: { click: _vm._handleSave },
              slot: "left",
            },
            [_vm._v(_vm._s(_vm.$t("全部保存")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }