<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="search.transType"
        clearable
        :placeholder="$t('请选择发送类型')"
        class="filter-item"
      >
        <el-option v-for="(item, key) in defaultSendType" :key="key" :label="item" :value="key" />
      </el-select>
      <el-select
        v-model="search.sendResult"
        clearable
        :placeholder="$t('请选择状态')"
        class="filter-item"
      >
        <el-option v-for="(item, key) in defaultStatus" :key="key" :label="item" :value="key" />
      </el-select>
      <el-input
        v-model.trim="search.phone"
        onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
        maxlength="4"
        clearable
        :placeholder="$t('请输入行动电话后四位')"
        class="filter-item"
        style="width: 200px"
      />
      <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleSearch">{{ $t('查询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />
  </div>
</template>

<script>
import { getBriefList } from '@/api/announcement'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
export default {
  name: 'AnnouncementBrief',
  mixins: [TablePageMixin(getBriefList)],
  data() {
    return {
      defaultColumns: GetColumns.call(this),
      columns: [],
      search: { transType: '', sendResult: '', phone: '' },
      defaultSendType: { 1: this.$t('忘记密码'), 2: this.$t('注册'), 3: this.$t('短信登录') },
      defaultStatus: { 0: this.$t('发送成功'), 1: this.$t('发送失败'), 2: this.$t('已使用') }
    }
  },
  methods: {
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    }
  }
}
</script>
