<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="search.roomStatus"
        :placeholder="$t('直播状态')"
        clearable
        class="filter-item"
        style="width: 200px;"
      >
        <el-option
          v-for="(label, key) in liveRoomStatus"
          :key="key"
          :label="label"
          :value="key"
        />
      </el-select>

      <el-select
        multiple
        collapse-tags
        v-model="search.channelIds"
        :placeholder="$t('频道选择')"
        clearable
        class="filter-item"
        style="width: 200px;"
      >
        <el-option
          v-for="item in channelList"
          :key="item.labelId"
          :label="item.label"
          :value="item.labelId"
        />
      </el-select>

      <el-select
        v-model="search.lottypeId"
        :placeholder="$t('彩种类型')"
        clearable
        class="filter-item"
        style="width: 200px;"
        @change="search.lotkindId = '';lotteryList = []"
      >
        <el-option v-for="item in livePreLotteryType" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-select
        v-model="search.lotkindId"
        filterable
        remote
        collapse-tags
        clearable
        :disabled="search.lottypeId === undefined || search.lottypeId === '' || search.lottypeId === 4"
        reserve-keyword
        placeholder="彩种/赛事"
        class="filter-item"
        style="width: 200px;"
        :remote-method="remoteMethod"
        :loading="lotkindIdLoading"
      >
        <el-option
          v-for="item in lotteryList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="filter-container">
      <el-input v-model="search.nickName" :placeholder="$t('主播昵称')" clearable class="filter-item" style="width: 200px;" />
      <el-input v-model="search.roomName" type="search" :placeholder="$t('房间主题')" class="filter-item" style="width: 200px" />
      <el-input v-model="search.familyName" :placeholder="$t('所属家族')" clearable class="filter-item" style="width: 200px;" />
      <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleSearch">{{ $t('查 询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      row-key="roomId"
      :data="items"
      :columns="columns"
      :original-columns="defaultColumns"
      :options="options"
      @refresh="handleFetch"
      @column-change="handleColumnChange"
    >
      <template slot="left">{{ $t('当前房间合计人数') }}：{{ sumOnline }}</template>
      <el-button v-if="permission.HeatSet" slot="right" type="success" icon="el-icon-setting" @click="onDever">{{ $t('房间热度设定') }}</el-button>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <DeverDialog ref="DeverDialogControl" @update="_handleUpdateDever" />
    <EditDialog ref="EditDialogControl" @update="_handleUpdate" />
    <StreamDialog ref="StreamDialogControl" @update="_handleUpdateStream" />
  </div>

</template>

<script>
import {
  getLiveManagement,
  getSumOnlineNum,
  getLotteryAllList,
  getLiveChannelList,
  doUpdateRoom,
  doUpdateTopStatus,
  updateRoomHot,
  dokickLine,
  streamPlayback,
  closeStreamPlayback,
  liveCategoryList
} from '@/api/live'
import { mapGetters } from 'vuex'
import { liveRoomStatus, displayStatusList, livePreLotteryType } from '@/libs/options'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { EditDialog, DeverDialog, StreamDialog } from './components'
import { $t } from '@/lang'
import { footballThirdMatchList, basketballThirdMatchList } from '@/api/lottery'

export default {
  name: 'LiveManagement',
  components: { DeverDialog, EditDialog, StreamDialog },
  mixins: [TablePageMixin(getLiveManagement)],
  data() {
    return {
      search: {
        lotkindId: ''
      },
      sumOnline: null,
      liveRoomStatus,
      displayStatusList,
      livePreLotteryType,
      columns: [],
      defaultColumns: GetColumns.call(this),
      channelList: [],
      categoryList: [],
      lotteryOptions: [],
      lotkindIdLoading: false,
      lotteryList: [],
      childLotteryList: []
    }
  },
  computed: {
    ...mapGetters(['tenantCode'])
  },
  created() {
    this.getChannelList()
    this.getliveCategoryList()
    getLotteryAllList(this.tenantCode).then(([items, err]) => {
      if (!err) {
        this.lotteryOptions = items.filter(o => o.liveShowStatus === 1 && o.tenantShowStatus === 1).map(e => {
          return {
            value: e.lotteryId,
            label: e.name
          }
        })
      }
    })

    getSumOnlineNum({ ...this.search }).then(([data, err]) => {
      if (!err) {
        this.sumOnline = data
      }
    })
  },
  methods: {
    onDever() {
      this.$refs.DeverDialogControl.open()
    },
    goUser({ roomId, roomName, nickName, onlineDate, payAmount }) {
      this.$router.push({ name: 'LiveManagementUser', params: { roomId }, query: { roomName, nickName, onlineDate, payAmount }})
    },
    _handleUpdateDever({ field, cancel, close }) {
      updateRoomHot({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success($t('操作成功'))
        } else {
          cancel()
        }
      })
    },
    _handleUpdateStream({ field: { roomId, streamMediaUrl, lotkindId, labelId, cover, unionId }, cancel, close }) {
      streamPlayback({ roomId, streamMediaUrl, lotkindId, labelId, cover, unionId: unionId + '' }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success($t('添加成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleUpdate({ field, cancel, close }) {
      const { channelUnionId, ...other } = field
      const params = { ...other }
      params.channelList = []
      const channelIdList = []
      channelUnionId.forEach(e => {
        let category = null
        const channel = this.channelList.find(v => v.value === e)
        if (channel) {
          channelIdList.push(channel.labelId)
          category = this.categoryList.find(e => e.value === channel.categoryUnionId)
        }
        params.channelList.push({
          channelUnionId: e,
          channelId: channel?.labelId || '',
          categoryUnionId: channel?.categoryUnionId || '',
          categoryId: category?.labelId || ''
        })
      })
      params.channelId = channelIdList.toString()
      if (params.serverSource === 1) {
        params.clientAddress = ''
      }
      doUpdateRoom(params).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success($t('编辑成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleChangeTopStatus({ roomId, topStatus }) {
      return new Promise((resolve) => {
        this.$confirm(topStatus === 2 ? $t('确认置顶此直播间?') : $t('确认取消置顶此直播间?'), $t('系统提示'), { type: 'warning' }).then(() => {
          doUpdateTopStatus({
            roomId,
            topStatus: topStatus === 2 ? 1 : 2
          }).then(([_, err]) => {
            if (!err) {
              resolve()
              this.$message.success($t('操作成功'))
            }
          })
        }).catch(() => {})
      })
    },
    _handleKick({ anchorAccno }) {
      this.$confirm($t('是否将主播踢下线并禁播（可在主播管理解除禁播）'), $t('系统提示'), {
        center: true,
        type: 'warning'
      }).then(() => {
        dokickLine({ anchorAccno }).then(([_, err]) => {
          if (!err) {
            this.$message.success($t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    _handleEndPlayStream({ roomId }) {
      this.$confirm($t('是否结束当前流媒体播放'), $t('系统提示'), {
        center: true,
        type: 'warning'
      }).then(() => {
        closeStreamPlayback({ roomId }).then(([_, err]) => {
          if (!err) {
            this.$message.success($t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    getChannelList() {
      getLiveChannelList({ currentPage: 1, pageSize: 200 }).then(([items, err]) => {
        if (!err && items && items.length > 0) {
          this.channelList = items.map(e => {
            return {
              value: e.unionId,
              label: e.items.find(e => e.langCode === 'zh_CN').channelName,
              labelId: e.items.find(e => e.langCode === 'zh_CN').channelId,
              categoryUnionId: ''
            }
          })
        }
      })
    },
    getliveCategoryList() {
      liveCategoryList({ currentPage: 1, pageSize: 200 }).then(([items, err]) => {
        if (!err && items) {
          this.categoryList = items.map(e => {
            return {
              value: e.unionId,
              label: e.items.find(v => v.langCode === 'zh_CN')?.categoryName,
              labelId: e.items.find(v => v.langCode === 'zh_CN')?.categoryId,
              channelUnionId: e.channelUnionId
            }
          })
        }
      })
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationGetColumns(keys) {
      return GetColumns.call(this, keys)
    },
    remoteMethod(query) {
      if (query !== '') {
        if (this.search.lottypeId === 1) {
          this.lotteryList = this.lotteryOptions.filter(e => e.label.toLowerCase().indexOf(query.toLowerCase()) > -1)
          return
        }
        this.lotkindIdLoading = true
        let service = footballThirdMatchList
        if (this.search.lottypeId === 3) {
          service = basketballThirdMatchList
        }
        service({ competitionName: query, currentPage: 1, pageSize: 100 }).then(([items, err]) => {
          if (!err && items && items.length) {
            this.lotteryList = items.map(e => {
              return {
                value: e.thirdMatchId,
                label: e.competitionNameZh + '-' + e.homeTeamNameZh + 'VS' + e.awayTeamNameZh
              }
            })
          }
        }).finally(() => {
          this.lotkindIdLoading = false
        })
      } else {
        this.lotteryList = []
      }
    }
  }
}
</script>
