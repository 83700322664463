var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "订单详情", visible: _vm.dialogVisible, width: "650px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "ul",
        [
          _c("li", [
            _c("strong", [_vm._v("用户账号：")]),
            _c("span", [_vm._v(_vm._s(_vm.memberName))]),
          ]),
          _c(
            "li",
            { staticClass: "productCover" },
            [
              _c("strong", [_vm._v("商品封面：")]),
              _vm._v(" "),
              _c("el-image", { attrs: { src: _vm.productCover, lazy: "" } }),
            ],
            1
          ),
          _c("li", [
            _c("strong", [_vm._v("商品名称：")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.productName))]),
          ]),
          _c("li", [
            _c("strong", [_vm._v("商品价格：")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.productPrice) + "积分 ×1")]),
          ]),
          _c("li", [
            _c("strong", [_vm._v("订单总价：")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.amount) + "积分")]),
          ]),
          _c("li", [
            _c("strong", [_vm._v("商品类型：")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.productType))]),
          ]),
          _c("li", [
            _c("strong", [_vm._v("下单时间：")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.createTime))]),
          ]),
          _c("li", [
            _c("strong", [_vm._v("订单状态：")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.status))]),
          ]),
          _vm.productType === "实物商品" && _vm.status === "已完成"
            ? [
                _c("li", [
                  _c("strong", [_vm._v("收货地址：")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.deliveryAddress))]),
                ]),
                _c("li", [
                  _c("strong", [_vm._v("快递公司：")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.deliveryProvider))]),
                ]),
                _c("li", [
                  _c("strong", [_vm._v("订单编号：")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.deliveryOrder))]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关 闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }