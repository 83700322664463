import Layout from '@/layout'

const manageRouter = {
  path: '/Orders',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Orders',
  alwaysShow: true,
  meta: { title: '注单管理', icon: 'orders' },
  children: [
    {
      path: '/Orders_Lottery',
      component: () => import('@/views/orders/lottery'),
      name: 'LotteryOrders',
      meta: { title: '彩票注单' }
    },
    // {
    //   path: '/Orders_Carsh',
    //   component: () => import('@/views/orders/carsh'),
    //   name: 'CarshOrders',
    //   meta: { title: 'Carsh注单' }
    // },
    {
      path: '/Orders_Football',
      component: () => import('@/views/orders/football'),
      name: 'FootballOrders',
      meta: { title: '足彩注单' }
    },
    {
      path: '/Orders_Third',
      component: () => import('@/views/orders/third'),
      name: 'ThirdOrders',
      meta: { title: '第三方注单' }
    }
  ]
}

export default manageRouter
