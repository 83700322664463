<template>
  <el-dialog :title="title" :visible.sync="visible" width="600px" :close-on-click-modal="false" @closed="handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item label="彩种类型" prop="lotteryName">
        <el-input :value="ruleForm.seriesName + '-' + ruleForm.lotteryName" disabled />
      </el-form-item>
      <el-form-item label="官方直播" prop="officialStatus">
        <el-radio-group v-model="ruleForm.officialStatus">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="ruleForm.officialStatus == 1" label="直播视频" prop="officialVideoPath">
        <el-input v-model="ruleForm.officialVideoPath" :placeholder="$t('请输入直播视频url')" />
      </el-form-item>
      <el-form-item label="开奖说明" prop="explanationStatus">
        <el-radio-group v-model="ruleForm.explanationStatus">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="ruleForm.explanationStatus == 1">
        <el-form-item label="说明类型" prop="explanationType">
          <el-radio-group v-model="ruleForm.explanationType">
            <el-radio :label="1">彩票规则</el-radio>
            <el-radio :disabled="!explanationTypeArr.includes(ruleForm.seriesTag)" :label="2">号码详情
              <el-tooltip content="号码详情仅支持：时时彩系列，PK10系列，快三系列" placement="top">
                <i class="el-icon-question" />
              </el-tooltip>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="ruleForm.explanationType == 2">
          <el-form-item :label="$t('官方跳转')" prop="lotteryType">
            <el-radio-group v-model="ruleForm.lotteryType">
              <el-radio :label="10">{{ $t('官彩') }}</el-radio>
              <el-radio :label="20">{{ $t('平台私彩') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.lotteryType == 10" :label="$t('官网名称')" prop="officialName">
            <el-input v-model="ruleForm.officialName" :placeholder="$t('请输入官网名称,最多10个字符')" />
          </el-form-item>
          <el-form-item v-if="ruleForm.lotteryType == 10" :label="$t('官网地址')" prop="officialPath">
            <el-input v-model="ruleForm.officialPath" :placeholder="$t('请输入官网URL')" />
          </el-form-item>
        </template>
      </template>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        确认
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({

})

export default {
  name: 'LiveEditDialog',
  mixins: [FormDialogMixin(getDefaultForm)],
  components: {
  },
  data() {
    return {
      emitName: 'create',
      explanationTypeArr: ['11', '12', '13'], //  号码详情，仅支持时时彩系列、PK10系列，快三系列，其他类型置灰不可选择
      rules: {
        lotteryName: [
          { required: true, message: this.$t('请输入彩种名称'), trigger: 'change' }
        ],
        officialStatus: [
          { required: true, message: this.$t('选择是否开启官方直播'), trigger: 'change' }
        ],
        officialVideoPath: [
          { required: true, message: this.$t('请输入直播视频url'), trigger: 'change' }
        ],
        explanationStatus: [
          { required: true, message: this.$t('请选择', { text: this.$t('开奖说明') }), trigger: 'change' }
        ],
        explanationType: [
          { required: true, message: this.$t('请选择', { text: this.$t('说明类型') }), trigger: 'change' }
        ],
        lotteryType: [
          { required: true, message: this.$t('请选择', { text: this.$t('官方类型') }), trigger: 'change' }
        ]

      }
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? '直播配置' : '直播配置'
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm = row
        this.emitName = 'update'
      }
      this.visible = true
    },
    handleClosed() {
      this.visible = false
    }
  }
}
</script>
