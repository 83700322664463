import Cookies from 'js-cookie'
import { getTenantList } from '@/api/tenant'
import { backmanagerAllLang } from '@/api/app'
import { getLang, setLang } from '@/lang/index'

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  notice: {
    visible: false,
    isDot: false,
    data: null
  },
  device: 'desktop',
  language: getLang(),
  lang: getLang(),
  tenants: [],
  size: Cookies.get('size') || 'default',
  allLang: [],
  agencyDataConfigs: []
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  TOGGLE_NOTICE: (state) => {
    state.notice.visible = !state.notice.visible
  },
  TOGGLE_NOTICE_HOT: (state) => {
    state.notice.isDot = !state.notice.isDot
  },
  SET_NOTICE_DATA: (state, data) => {
    state.notice.data = data
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language
    Cookies.set('language', language)
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_TENANTS: (state, tenants) => {
    state.tenants = tenants
  },
  SET_ALLLANG: (state, allLang) => {
    state.allLang = allLang
  },
  SET_LANG: (state, lang) => {
    state.lang = lang
    setLang(lang)
  },
  SET_AGENCY_DATA_CONFIG: (state, agencyDataConfigs) => {
    state.agencyDataConfigs = agencyDataConfigs
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  toggleNotice({ commit }) {
    commit('TOGGLE_NOTICE')
  },
  toggleNoticeHot({ commit }) {
    commit('TOGGLE_NOTICE_HOT')
  },
  setNoticeData({ commit }, data) {
    commit('SET_NOTICE_DATA', data)
  },
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  getTenantList({ state, commit }) {
    return new Promise((resolve) => {
      if (state.tenants.length) {
        resolve(state.tenants)
      } else {
        getTenantList().then(([data, err]) => {
          if (!err) {
            commit('SET_TENANTS', data || [])
            resolve()
          }
        })
      }
    })
  },
  getAllLang({ commit }) {
    return new Promise((resolve) => {
      backmanagerAllLang().then(([data, err]) => {
        if (!err) {
          commit('SET_ALLLANG', data || [])
          resolve()
        }
      })
    })
  },
  setLang({ commit }, lang) {
    commit('SET_LANG', lang)
  },
  setAgencyDataConfig({ commit }, agencyDataConfigs) {
    commit('SET_AGENCY_DATA_CONFIG', agencyDataConfigs)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
