var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "一键锁帖", width: "300px", visible: _vm.visible },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.onClosed,
      },
    },
    [
      _c(
        "div",
        { staticClass: "lock-dialog" },
        _vm._l(_vm.buttons, function (button) {
          return _c(
            "el-button",
            {
              key: button.value,
              attrs: {
                type: button.type,
                loading: _vm.submitting && _vm.active === button.value,
                disabled: _vm.submitting && _vm.active !== button.value,
              },
              on: {
                click: function ($event) {
                  return _vm.handleSumbit(button.value)
                },
              },
            },
            [_vm._v(_vm._s(button.label))]
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }