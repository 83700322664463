<template>
  <el-dialog
    :title="$t('首充详情')"
    :visible.sync="visible"
    width="50%"
    :close-on-click-modal="false"
    @open="handleRefresh"
    @closed="handleClosed"
    top="8vh"
  >
    <div v-loading="loading">
      <el-table :data="items" v-bind="options" style="max-height: 60vh;overflow-y: auto">
        <el-table-column :label="$t('会员账号')" prop="memberName" align="center" />
        <el-table-column :label="$t('昵称')" prop="nickname" align="center" />
        <el-table-column :label="$t('首次充值金额')" prop="firstRechargeAmount" align="center" />
        <el-table-column :label="$t('首次充值时间')" prop="firstRechargeDate" align="center">
          <template slot-scope="{ row }">{{ row.firstRechargeDate | parseTime }}</template>
        </el-table-column>
      </el-table>
      <!-- <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" /> -->
    </div>
  </el-dialog>
</template>
<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
import TablePageMixin from '@/mixins/table-page-mixin'
import { firstRechargeList } from '@/api/statistical'
export default {
  mixins: [VDialogMixin, TablePageMixin(firstRechargeList, false)],
  data() {
    return {
      search: {}
    }
  },
  methods: {
    open(row, { channelId, lineId }) {
      this.search.day = row.day
      this.search.channelId = channelId
      this.search.lineId = lineId
      this.visible = true
    },
    handleClosed() {
      this.items = []
      this.search = {}
    },
    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>

