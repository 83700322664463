import request from '@/plugins/axios'

export function getLiveManagement(data) {
  return request({
    url: '/live/liveManagement/list',
    method: 'post',
    data,
    timeout: 20000
  })
}

export function getSumOnlineNum(data) {
  return request({
    url: '/live/liveManagement/sumOnlineNum',
    method: 'post',
    data
  })
}

export function getLotteryAllList(tenantCode) {
  return request({
    url: '/lottery/list/all',
    method: 'post',
    headers: { 'X-TENANT-CODE': tenantCode }
  })
}

// 获取房间热度设定
export function getRoomHot() {
  return request({
    url: '/live/liveManagement/getRoomHot',
    method: 'get'
  })
}

export function updateRoomHot(data) {
  return request({
    url: '/live/liveManagement/updateRoomHot',
    method: 'post',
    data
  })
}
// 踢下线
export function dokickLine(data) {
  return request({
    url: '/live/liveManagement/kickLine',
    method: 'post',
    data
  })
}
// 置顶状态启停
export function doUpdateTopStatus(data) {
  return request({
    url: '/live/liveManagement/topStatus',
    method: 'post',
    data
  })
}
// 编辑房间
export function doUpdateRoom(data) {
  return request({
    url: '/live/liveManagement/updateRoom',
    method: 'post',
    data
  })
}

export function getRoomDetail(params) {
  return request({
    url: '/live/liveManagement/detail',
    method: 'get',
    params
  })
}

export function streamPlayback(data) {
  return request({
    url: '/live/liveManagement/streamPlayback',
    method: 'post',
    data
  })
}

export function closeStreamPlayback(data) {
  return request({
    url: '/live/liveManagement/closeStreamPlayback',
    method: 'post',
    data
  })
}

export function getLiveManagementMemberList(data) {
  return request({
    url: '/live/liveManagement/memberList',
    method: 'post',
    data
  })
}

export function getLiveManagementMemberOnline(params) {
  return request({
    url: '/live/liveManagement/onlineNum',
    method: 'get',
    params
  })
}

export function forbidEnter(data) {
  return request({
    url: '/live/liveManagement/forbidEnter',
    method: 'post',
    data
  })
}

export function forbidWords(data) {
  return request({
    url: '/live/liveManagement/forbidWords',
    method: 'post',
    data
  })
}

// 频道管理
export function getLiveChannelList(data) {
  return request({
    url: '/live/liveChannel/list',
    method: 'post',
    data
  })
}

export function doUpdateLiveChannel(data) {
  return request({
    url: '/live/liveChannel/update',
    method: 'post',
    data
  })
}

export function doSaveLiveChannel(data) {
  return request({
    url: '/live/liveChannel/add',
    method: 'post',
    data
  })
}

export function doDelLiveChannel(params) {
  return request({
    url: '/live/liveChannel/del',
    method: 'get',
    params
  })
}

// 标签管理
// export function getLiveLabelList(data) {
//   return request({
//     url: '/live/liveLabel/list',
//     method: 'post',
//     data
//   })
// }

// export function doSaveLiveLabel(data) {
//   return request({
//     url: '/live/liveLabel/add',
//     method: 'post',
//     data
//   })
// }

// export function doUpdateLiveLabel(data) {
//   return request({
//     url: '/live/liveLabel/update',
//     method: 'post',
//     data
//   })
// }

// export function doDelLiveLabel(params) {
//   return request({
//     url: '/live/liveLabel/del',
//     method: 'get',
//     params
//   })
// }

// 礼物管理
export function getLiveGiftList(data) {
  return request({
    url: '/live/liveGift/list',
    method: 'post',
    data
  })
}

export function createLiveGift(data) {
  return request({
    url: '/live/liveGift/add',
    method: 'post',
    data
  })
}

export function updateLiveGift(data) {
  return request({
    url: '/live/liveGift/update',
    method: 'post',
    data
  })
}

export function updateLiveGiftStatus(params) {
  return request({
    url: '/live/liveGift/giftStatus',
    method: 'get',
    params
  })
}

export function updateLiveGiftActionStatus(params) {
  return request({
    url: '/live/liveGift/giftActionStatus',
    method: 'get',
    params
  })
}

export function updateLiveGiftLinkStatus(params) {
  return request({
    url: '/live/liveGift/giftLinkStatus',
    method: 'get',
    params
  })
}

// 主播管理

export function getAnchorList(data) {
  return request({
    url: '/live/liveAnchor/list',
    method: 'post',
    data
  })
}

export function doSaveAnchor(data) {
  return request({
    url: '/live/liveAnchor/add',
    method: 'post',
    data
  })
}

export function doUpdateAnchor(data) {
  return request({
    url: '/live/liveAnchor/update',
    method: 'post',
    data
  })
}

export function doUpdateAnchorPassword(data) {
  return request({
    url: '/live/liveAnchor/updatePassword',
    method: 'post',
    data
  })
}

export function doUpdateAnchorStatus(data) {
  return request({
    url: '/live/liveAnchor/accStatus',
    method: 'post',
    data
  })
}

export function doUpdateAnchorRoomStatus(data) {
  return request({
    url: '/live/liveAnchor/roomStatus',
    method: 'post',
    data
  })
}

// 家族管理

export function getLiveFamilyList(data) {
  return request({
    url: '/live/liveFamily/list',
    method: 'post',
    data
  })
}

export function doCreateFamily(data) {
  return request({
    url: '/live/liveFamily/add',
    method: 'post',
    data
  })
}

export function doUpdateFamily(data) {
  return request({
    url: '/live/liveFamily/update',
    method: 'post',
    data
  })
}

export function doDelFamily(params) {
  return request({
    url: '/live/liveFamily/del',
    method: 'get',
    params
  })
}

export function doUpdateFamilyPassword(data) {
  return request({
    url: '/live/liveFamily/updatePassword',
    method: 'post',
    data
  })
}

export function doUpdateFamilyPercent(data) {
  return request({
    url: '/live/liveFamily/updateFamilyPercent',
    method: 'post',
    data
  })
}

// 家族提现管理

export function getLiveFamilyWithdrawList(data) {
  return request({
    url: '/live/liveFamilyWithdraw/list',
    method: 'post',
    data
  })
}

export function doSettlementFamily(data) {
  return request({
    url: '/live/liveFamilyWithdraw/settlement',
    method: 'post',
    data
  })
}

export function doPaymentFamily(data) {
  return request({
    url: '/live/liveFamilyWithdraw/payment',
    method: 'post',
    data
  })
}

export function getLiveFamilyWithdrawInfo(data) {
  return request({
    url: '/live/liveFamilyWithdraw/info',
    method: 'post',
    data
  })
}

export function getPaidTotalAmount(data) {
  return request({
    url: '/live/liveFamilyWithdraw/getPaidTotalAmount',
    method: 'post',
    data
  })
}

export function getLiveFamilyWithdrawDetails(data) {
  return request({
    url: '/live/liveFamilyWithdraw/details',
    method: 'post',
    data
  })
}

export function getLiveFamilyWithdrawDetailsSum(data) {
  return request({
    url: '/live/liveFamilyWithdraw/detailsSum',
    method: 'post',
    data
  })
}

// 开播明细
export function getLiveBroadcastList(data) {
  return request({
    url: '/live/liveBroadcastDetails/list',
    method: 'post',
    data
  })
}

export function getLiveBroadcastSummary(data) {
  return request({
    url: '/live/liveBroadcastDetails/broadcastDetailsSum',
    method: 'post',
    data
  })
}

export function getLiveBroadcastPayroomRecordList(data) {
  return request({
    url: '/live/liveBroadcastDetails/payroomRecordList',
    method: 'post',
    data
  })
}

export function getLiveBroadcastPayroomRecordSummary(data) {
  return request({
    url: '/live/liveBroadcastDetails/payroomRecordSum',
    method: 'post',
    data
  })
}

export function getLiveBroadcastGiftRecordList(data) {
  return request({
    url: '/live/liveBroadcastDetails/giftRecordList',
    method: 'post',
    data
  })
}

export function getLiveBroadcastGiftRecordSummary(data) {
  return request({
    url: '/live/liveBroadcastDetails/giftRecordSum',
    method: 'post',
    data
  })
}

export function getLiveBroadcastBetsRecordList(data) {
  return request({
    url: '/live/liveBroadcastDetails/betsRecordList',
    method: 'post',
    data
  })
}

export function getLiveBroadcastBetsRecordSummary(data) {
  return request({
    url: '/live/liveBroadcastDetails/betsRecordSum',
    method: 'post',
    data
  })
}

// 巡查管理
export function getLiveInspectionList(data) {
  return request({
    url: '/live/liveInspection/list',
    method: 'post',
    data
  })
}

export function addInspection(data) {
  return request({
    url: '/live/liveInspection/add',
    method: 'post',
    data
  })
}

export function updateInspectionPassword(data) {
  return request({
    url: '/live/liveInspection/updatePassword',
    method: 'post',
    data
  })
}

export function updateInspectionStatus(data) {
  return request({
    url: '/live/liveInspection/accountStatus',
    method: 'post',
    data
  })
}

export function updateInspectionVisibleStatus(data) {
  return request({
    url: '/live/liveInspection/visibleStatus',
    method: 'post',
    data
  })
}

// 行为限制
export function getLiveForbidList(data) {
  return request({
    url: '/live/im/cmd/memberList',
    method: 'post',
    data
  })
}

export function addForbid(params) {
  return request({
    url: '/live/im/operatorCmd',
    method: 'get',
    params
  })
}

export function relieveForbid(params) {
  return request({
    url: '/live/im/operatorCmd',
    method: 'get',
    params
  })
}

export function getonLiveForbidLineRoom() {
  return request({
    url: '/live/liveForbid/onlineRoom',
    method: 'get'
  })
}

// 线路管理
export function getAllPushServers() {
  return request({
    url: '/getAllPushServers',
    method: 'get'
  })
}

export function getAllPullServers(params) {
  return request({
    url: '/getAllPullServers',
    method: 'get',
    params
  })
}

export function doAddServer(data) {
  return request({
    url: '/doAddServer',
    method: 'post',
    data
  })
}

export function doEditServer(data) {
  return request({
    url: '/doEditServer',
    method: 'post',
    data
  })
}

export function doDisableServer(data) {
  return request({
    url: '/doDisableServer',
    method: 'post',
    data
  })
}

export function doDelServer(params) {
  return request({
    url: '/doDelServer',
    method: 'post',
    params
  })
}

// export function doForbidPushStream(data) {
//   return request({
//     url: '/doForbidPushStream',
//     method: 'post',
//     data
//   })
// }
// 开奖直播配置列表查询（post）
export function lotteryConfigGetPage(data) {
  return request({
    url: '/lottery/config/getPage',
    method: 'post',
    data
  })
}
// 更新彩票开奖直播配置（post）
export function updateLotteryConfig(data) {
  return request({
    url: '/lottery/config/updateLotteryConfig',
    method: 'post',
    data
  })
}

// 频道分类列表
export function liveCategoryList(data) {
  return request({
    url: '/live/liveCategory/list',
    method: 'post',
    data
  })
}

// 频道分类新增
export function liveCategoryAdd(data) {
  return request({
    url: '/live/liveCategory/add',
    method: 'post',
    data
  })
}

// 频道分类修改
export function liveCategoryUpdate(data) {
  return request({
    url: '/live/liveCategory/update',
    method: 'post',
    data
  })
}

// 频道分类删除
export function liveCategoryDelete(params) {
  return request({
    url: '/live/liveCategory/del',
    method: 'get',
    params
  })
}

// 预开播管理列表
export function livePlanManagementList(data) {
  return request({
    url: '/live/livePlanManagement/list',
    method: 'post',
    data
  })
}

// 预开播详情
export function livePlanManagementDetail(params) {
  return request({
    url: '/live/livePlanManagement/detail',
    method: 'get',
    params
  })
}

// 预开播取消
export function livePlanManagementCancel(params) {
  return request({
    url: '/live/livePlanManagement/cancel',
    method: 'get',
    params
  })
}

// 预开播编辑
export function livePlanManagementUpdateLivePlan(data) {
  return request({
    url: '/live/livePlanManagement/updateLivePlan',
    method: 'post',
    data
  })
}

// 上播
export function livePlanManagementStart(params) {
  return request({
    url: '/live/livePlanManagement/start',
    method: 'get',
    params
  })
}

// 彩种封面分页
export function liveLotteryPage(data) {
  return request({
    url: '/live/liveLottery/page',
    method: 'post',
    data
  })
}

// 彩种封面编辑
export function liveLotteryUpdate(data) {
  return request({
    url: '/live/liveLottery/update',
    method: 'post',
    data
  })
}

// 批量修改在线直播数据
export function liveManagementBatchEditRoom(data) {
  return request({
    url: '/live/liveManagement/batchEditRoomCategory',
    method: 'post',
    data
  })
}

// 批量修改预告直播数据
export function livePlanManagementBatchEditRoom(data) {
  return request({
    url: '/live/liveManagement/batchEditPlanCategory',
    method: 'post',
    data
  })
}

// 查询obs推流码
export function liveManagementGenStreamKey(data) {
  return request({
    url: '/live/liveManagement/genStreamKey',
    method: 'post',
    data
  })
}
