const CanChooseColumns = function() {
  return [
    {
      title: this.$t('巡查账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('昵称'),
      key: 'nickname',
      align: 'center'
    },
    {
      title: this.$t('最后登录时间'),
      key: 'latestLoginTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.latestLoginTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作信息'),
      key: 'operator',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <div>
              <p>{ row.operatorName }</p>
              <p>{ this.$parseTime(row.createTime) }</p>
            </div>
          )
        }
      }
    },
    {
      title: this.$t('可见状态'),
      key: 'visibleStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.visibleStatus}
              activeValue={1}
              inactiveValue={0}
              beforeChange={this._handleChangeVisibleStatus.bind(this, row)}
              disabled={!this.permission.StartStop} />
          )
        }
      }
    },
    {
      title: this.$t('账号状态'),
      key: 'accountStatus',
      align: 'center',
      component: {
        render: (h, row) => {
          return (
            <d-switch
              v-model={row.accountStatus}
              beforeChange={this._handleChangeStatus.bind(this, row)}
              disabled={!this.permission.AccountStartStop} />
          )
        }
      }
    },
    {
      title: this.$t('操作'),
      align: 'center',
      key: 'actions',
      width: 220,
      component: {
        render: (h, row) => {
          const { UpdatePassword } = this.permission
          return (
            <span>
              { UpdatePassword &&
                <el-button
                  type='primary'
                  size='mini'
                  onClick={() => this.$refs.ChangeDialogControl.open(row)}>{this.$t('修改密码')}</el-button> }
              {/* { OperationLog &&
                <el-button
                  type='primary'
                  size='mini'
                  onClick={() => this.$refs.OplogDialogControl.open(row)}>操作日志</el-button> } */}
            </span>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
