<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.pictureName" class="filter-item" clearable placeholder="图库名称" style="width: 140px" />
      <el-select v-model="search.updateStatus" class="filter-item" placeholder="更新状态" style="width: 140px">
        <el-option label="未更新" :value="1" />
        <el-option label="已更新" :value="2" />
      </el-select>
      <el-select v-model="search.lotteryId" class="filter-item" placeholder="彩种" style="width: 140px" @change="handleSource">
        <el-option v-for="(val, key) in xsLottery" :key="key" :value="+key" :label="val" />
      </el-select>
      <el-select
        v-model="search.source"
        class="filter-item"
        placeholder="数据来源"
        style="width: 140px"
        @change="handleLottery"
      >
        <el-option v-for="item of sources.filter(e => e.lotteryId === search.lotteryId)" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </div>
    <div class="filter-container">
      <el-select v-model="search.year" class="filter-item" placeholder="年份" style="width: 140px" @change="handleLottery">
        <el-option v-for="item in [2024, 2023, 2022, 2021]" :key="item" :label="item" :value="item" />
      </el-select>
      <el-select v-model="search.issue" class="filter-item" placeholder="期号" style="width: 140px">
        <el-option v-for="issue in issuesOptions" :key="issue" :label="`${issue}期`" :value="issue" />
      </el-select>
      <!-- <el-select v-model="search.pictureCategoryName" class="filter-item" placeholder="图库类别" style="width: 140px">
        <el-option v-for="item in picList" :key="item.id" :label="item.categoryName" :value="item.id" />
      </el-select> -->
      <el-input v-model="search.pictureCategoryName" class="filter-item" clearable placeholder="图库类别" style="width: 140px" />
      <el-select v-model="search.status" clearable class="filter-item" placeholder="启停状态" style="width: 140px">
        <el-option v-for="(val, key) in commonStatus" :key="key" :label="val" :value="key" />
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
      selection-row
      row-key="id"
      @select="handleSelect"
      @select-all="handleSelect"
    >
      <template #left>
        <el-button v-if="permission.Add" class="filter-item" :disabled="selectList.length === 0" type="primary" @click="handleBatchChangeStatus">批量停用</el-button>
        <span class="filter-item">已选中图库：{{ selectList.length }}</span>
      </template>
      <template #right>
        <el-button v-if="permission.Add" class="filter-item" type="primary" icon="el-icon-plus" @click="onAddGallery">添加图库</el-button>
        <el-button v-if="permission.Add" class="filter-item" type="primary" icon="el-icon-plus" @click="onCreate">添加图库集合</el-button>
      </template>
    </d-table>

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total" @pagination="handleFetch" />

    <ImagePreview ref="ImagePreviewControl" />
    <!-- 添加图库集合 -->
    <CreateDialog ref="CreateDialogControl" @create="handleCreate" />
    <!-- 添加图库 -->
    <AddGallery ref="AddGalleryControl" @create="handleAddGalleryCreate"/>
    <UpdateDialog ref="UpdateDialogControl" @update="handleUpdate" />
    <CommentDialog ref="CommentDialogControl" @create="handleComment" />

  </div>
</template>
<script>
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import { xsLottery, sources, commonStatus } from '@/libs/options'
import { UpdateDialog, CreateDialog, CommentDialog } from './components'
import AddGallery from './components/AddGallery.vue'
import ImagePreview from '@/components/ImagePreview'
import { getPictureList, getPictureIssues, createPicture, updatePicture, deletePicture, updatePictureStatus, pictureCommentAdd,addPictureRecord, pictureCategoryPageList } from '@/api/interactive'

const insetparams = { updateStatus: 0 }

export default {
  name: 'StockTab',
  componentName: 'StockTab',
  components: { UpdateDialog, CreateDialog, ImagePreview, CommentDialog,AddGallery },
  mixins: [TablePageMixin(getPictureList, false, insetparams)],
  data() {
    return {
      columns: GetColumns.call(this),
      filterColumn: false,
      search: { source: 'HK49', issue: null, lotteryId: 40, year: 2024, pictureCategoryName: '' },
      defaultSearch: insetparams,
      issuesOptions: [],
      xsLottery,
      sources,
      commonStatus,
      picList: [],
      selectList: []
    }
  },
  created() {
    this.handlePictureCategoryPageList()
    this.getPictureIssues(true)
  },
  methods: {
    handlePictureCategoryPageList() {
      pictureCategoryPageList({ currentPage: 1, pageSize: 200 }).then(([items, err]) => {
        if (!err && items && items.length) {
          this.picList = items
        }
      })
    },
    setPictureCategoryName(categoryName, lotteryId, source) {
      this.search.pictureCategoryName = categoryName
      this.search.lotteryId = Number(lotteryId)
      this.search.source = source
      if (!this.search.source) {
        return
      }
      if (!this.search.issue) {
        return
      }
      this.handleRefresh()
    },
    handleSource() {
      this.search.source = null
      this.search.issue = null
      this.issuesOptions = []
    },
    handleLottery() {
      this.getPictureIssues()
    },
    getPictureIssues(search) {
      if (!this.search.source || !this.search.year) {
        return
      }
      this.search.issue = null
      this.issuesOptions = []
      getPictureIssues({ source: this.search.source, year: this.search.year }).then(([items, err]) => {
        if (!err) {
          this.issuesOptions = items
          if (this.issuesOptions.length > 0) {
            this.search.issue = this.issuesOptions[0]
            if (search) {
              this.handleSearch()
            }
          }
        }
      })
    },
    // 添加图库
    onAddGallery(){
      this.$refs.AddGalleryControl.open()
    },
    // 添加图库集合
    onCreate() {
      this.$refs.CreateDialogControl.open()
    },

    handleSearch() {
      if (!this.search.source) {
        this.$message.error('请选择类型')
        return
      }
      if (!this.search.issue) {
        this.$message.error('请选择期数')
        return
      }
      this.handleRefresh()
    },
    // 新增图库集合
    handleCreate({ field, cancel, close }) {
      createPicture({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          if (this.search.issue) this.handleRefresh()
          this.$message.success('创建成功')
          this.$refs.AddGalleryControl.getPictureList()
        } else {
          cancel()
        }
      })
    },
    // 新增图库
    async handleAddGalleryCreate({ field, cancel, close }){

     let [data, err] =  await addPictureRecord({ ...field })
        if (!err) {
          close()
          if (this.search.issue) this.handleRefresh()
          this.$message.success('创建成功')
        } else {
          cancel()
        }
    },
    handleUpdate({ field, cancel, close }) {
      updatePicture({ ...field }).then(([data, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success('操作成功')
        } else {
          cancel()
        }
      })
    },

    handleDelete({ pictureTag: tag, tenantCode }) {
      this.$confirm('确认删除此图库', '系统提示', { type: 'warning' }).then(() => {
        deletePicture({
          tag
        }).then(([data, err]) => {
          if (!err) {
            this.handleFetch()
            this.$message.success('删除成功')
          }
        })
      }).catch(() => { })
    },

    handleChangeStatus({ pictureTag: tag, id, status }) {
      return new Promise((resolve) => {
        this.$confirm(`${status === 0 ? '启用' : '停用'}图库后，图库的当期图片将${status === 0 ? '在' : '不在'}前端展示。`, `确认${status === 0 ? '启用' : '停用'}此图库？`, { type: 'warning' }).then(() => {
          updatePictureStatus({
            ids: [id],
            tag,
            status: ~status + 2
          }).then(([data, err]) => {
            if (!err) {
              resolve()
              this.$message.success('操作成功')
            }
          })
        })
      })
    },

    handleBatchChangeStatus() {
      const ids = []
      const tags = []
      this.selectList.forEach(e => {
        ids.push(e.id)
        tags.push(e.pictureTag)
      })
      this.$confirm(`停用图库后，图库的当期图片将不在前端展示。`, '确定批量停用选中图库吗？', { type: 'warning' }).then(() => {
        updatePictureStatus({
          ids,
          tag: tags.toString(),
          status: 0
        }).then(([data, err]) => {
          if (!err) {
            this.handleFetch()
            this.$message.success('操作成功')
          }
        })
      })
    },
    implementationGetParams() {
      return { ...this.search, updateStatus: this.search.updateStatus || 0 }
    },
    handleComment({ field, cancel, close }) {
      pictureCommentAdd(field).then(([data, err]) => {
        if (!err) {
          close()
          this.handleFetch()
          this.$message.success('操作成功')
        } else {
          cancel()
        }
      })
    },
    handleSelect(selection) {
      this.selectList = selection
    }
  }
}
</script>
