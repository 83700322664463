var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "border-form-dialog",
      attrs: {
        title: "禁言限制",
        visible: _vm.visible,
        width: "560px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "show-message": false,
            "hide-required-asterisk": "",
            "label-width": "180px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "限制类型", prop: "roomForbidType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.roomForbidType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "roomForbidType", $$v)
                    },
                    expression: "ruleForm.roomForbidType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("在该房间限时禁言"),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("在所有房间限时禁言"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "限制时间", prop: "expiryDatee" } },
            [
              _c(
                "div",
                { attrs: { flex: "cross:center" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "expiryDatee" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "190px" },
                        attrs: {
                          type: "datetime",
                          placeholder: "选择结束时间",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          disabled: _vm.ruleForm.forbidStatus === 2,
                        },
                        model: {
                          value: _vm.ruleForm.expiryDatee,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "expiryDatee", $$v)
                          },
                          expression: "ruleForm.expiryDatee",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { prop: "forbidStatus" },
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 2, "false-label": 1 },
                          on: { change: _vm.onChangeStatus },
                          model: {
                            value: _vm.ruleForm.forbidStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "forbidStatus", $$v)
                            },
                            expression: "ruleForm.forbidStatus",
                          },
                        },
                        [_vm._v("永久")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" 确认 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }