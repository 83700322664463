<template>
  <el-dialog :title="title" :visible.sync="visible" width="460px" :close-on-click-modal="false" @closed="_handleClosed">
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="160px" label-suffix=":">
      <!-- <el-form-item v-if="emitName === 'create'" label="代理ID" prop="agencyId">
        <el-input v-model="ruleForm.agencyId" style="width: 240px" />
      </el-form-item> -->
      <el-form-item :label="$t('代理等级')" prop="level">
        <el-select v-model="ruleForm.level" :placeholder="$t('请选择代理等级')" style="width: 240px">
          <el-option v-for="(item, index) in $parent.defaultAgentType" :key="index" :label="item + $t('级代理')" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('升级要求推广人数')" prop="requireSpreadNum">
        <el-input v-model="ruleForm.requireSpreadNum" style="width: 240px" :disabled="disabled" />
      </el-form-item>
      <el-form-item :label="$t('升级要求投注金额')" prop="requireBetAmount">
        <el-input v-model="ruleForm.requireBetAmount" style="width: 240px" :disabled="disabled" />
      </el-form-item>
      <el-form-item :label="$t('升级要求充值金额')" prop="requireRechargeAmount">
        <el-input v-model="ruleForm.requireRechargeAmount" style="width: 240px" :disabled="disabled" />
      </el-form-item>
      <el-form-item :label="$t('等级对应一级返佣点')" prop="oneLevelAgencyRebate">
        <el-input v-model="ruleForm.oneLevelAgencyRebate" style="width: 240px" />
      </el-form-item>
      <el-form-item :label="$t('等级对应二级返佣点')" prop="twoLevelAgencyRebate">
        <el-input v-model="ruleForm.twoLevelAgencyRebate" style="width: 240px" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
import { integer1, integer0_9999 } from '@/utils/validation.js'
const getDefaultForm = () => ({
  level: '',
  requireSpreadNum: '',
  requireBetAmount: '',
  requireRechargeAmount: '',
  oneLevelAgencyRebate: '',
  twoLevelAgencyRebate: ''
})
// 可以是0的最多4位小数的数
const regexNum4 = /^[0-9]+(\.[0-9]{1,4})?$/
export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        agencyId: [
          { required: true, message: this.$t('请输入代理ID'), trigger: 'blur' },
          { pattern: integer1, message: this.$t('代理ID格式不正确'), trigger: 'blur' }
        ],
        level: [
          { required: true, message: this.$t('请选择代理等级'), trigger: 'blur' }
        ],
        requireSpreadNum: [
          { required: true, message: this.$t('请输入升级要求推广人数'), trigger: 'blur' },
          { pattern: integer0_9999, message: this.$t('升级要求推广人数格式(0-9999)'), trigger: 'blur' }
        ],
        requireBetAmount: [
          { required: true, message: this.$t('请输入升级要求投注金额'), trigger: 'blur' },
          { pattern: regexNum4, message: this.$t('升级要求投注金额格式不正确'), trigger: 'blur' }
        ],
        requireRechargeAmount: [
          { required: true, message: this.$t('请输入升级要求充值金额'), trigger: 'blur' },
          { pattern: regexNum4, message: this.$t('升级要求充值金额格式不正确'), trigger: 'blur' }
        ],
        oneLevelAgencyRebate: [
          { required: true, message: this.$t('请输入等级对应一级返佣点'), trigger: 'blur' },
          { pattern: regexNum4, message: this.$t('等级对应一级返佣点格式不正确'), trigger: 'blur' }
        ],
        twoLevelAgencyRebate: [
          { required: true, message: this.$t('请输入等级对应二级返佣点'), trigger: 'blur' },
          { pattern: regexNum4, message: this.$t('等级对应二级返佣点格式不正确'), trigger: 'blur' }
        ]
      },
      currentRow: {},
      disabled: false
    }
  },
  computed: {
    title() {
      return this.emitName === 'create' ? this.$t('添加返佣配置') : this.$t('编辑返佣配置')
    }
  },
  watch:{
    'ruleForm.level'(val){
            const field = ['requireSpreadNum','requireBetAmount','requireRechargeAmount']
            field.forEach(item => { 
                const targetVal = val === 0 ?0:this.currentRow&&this.currentRow[item]
                this.$set(this.ruleForm, item, targetVal)
            })
            this.disabled = val === 0 ? true : false  
    }
  },
  methods: {
    open(row, tenantCode) {
      
      this.ruleForm.tenantCode = tenantCode
      if (row) {
        this.currentRow = row
        this.ruleForm.id = row.id
        this.ruleForm.level = row.level
        this.ruleForm.requireSpreadNum = row.requireSpreadNum
        this.ruleForm.requireBetAmount = row.requireBetAmount
        this.ruleForm.requireRechargeAmount = row.requireRechargeAmount
        this.ruleForm.oneLevelAgencyRebate = row.oneLevelAgencyRebate
        this.ruleForm.twoLevelAgencyRebate = row.twoLevelAgencyRebate
        this.emitName = 'update'
      } else {
        this.emitName = 'create'
      }
      this.visible = true
    },
    _handleClosed(){
      this.currentRow = {}
      this.handleClosed()
    }
  }
}
</script>
