<template>
  <el-dialog
    :title="$t('确认打款')"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form label-width="120px">
      <el-form-item :label="$t('家族名称')">{{ ruleForm.familyName }}</el-form-item>
      <el-form-item :label="$t('提现账号')">
        <div>{{ ruleForm.accountNo }}</div>
        <div>{{ ruleForm.bankName }}</div>
        <div>{{ ruleForm.accountName }}</div>
      </el-form-item>
      <el-form-item :label="$t('提现金额')">
        <span style="color: #ff0000; font-size: 14px">{{ ruleForm.apycGold }}</span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="success" :loading="submitting" @click="handleSubmit">
        {{ $t('确认打款') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import VDialogMixin from '@/mixins/v-dialog-mixin'
export default {
  mixins: [VDialogMixin],
  data() {
    return {
      submitting: false,
      ruleForm: {}
    }
  },
  methods: {
    open(row) {
      this.ruleForm.apycId = row.apycId
      this.ruleForm.familyName = row.familyName
      this.ruleForm.accountNo = row.accountNo
      this.ruleForm.bankName = row.bankName
      this.ruleForm.accountName = row.accountName
      this.ruleForm.apycGold = row.apycGold
      this.visible = true
    },
    handleClosed() {
      this.ruleForm = {}
    },
    handleSubmit() {
      this.submitting = true
      this.$emit('parment', {
        field: { apycId: this.ruleForm.apycId },
        cancel: () => {
          this.submitting = false
        },
        close: this.close
      })
    }
  }
}
</script>

