import { filterColumns } from '@/utils'
import { validURL } from '@/utils/validate'
const CanChooseColumns = function() {
  return [
    {
      title: this.$t('订单编号'),
      key: 'id',
      align: 'center'
    },
    {
      title: this.$t('会员账号'),
      key: 'memberName',
      align: 'center'
    },
    {
      title: this.$t('红包类型'),
      key: 'awardType',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{ this.awardTypes[row.awardType] }</span>
        }
      }
    },
    {
      title: this.$t('VIP等级'),
      key: 'memberVipLevel',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>VIP{ row.memberVipLevel }</span>
        }
      }
    },
    {
      title: this.$t('金额'),
      key: 'awardAmount',
      align: 'center'
    },
    {
      title: this.$t('红包状态'),
      key: 'status',
      align: 'center',
      component: {
        render: (h, row) => {
          return <span>{ this.recordStatus[row.status] }</span>
        }
      }
    },
    {
      title: this.$t('发放时间'),
      key: 'issueTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.issueTime) }</span>)
        }
      }
    },
    {
      title: this.$t('待领取时间'),
      key: 'expireTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.expireTime) }</span>)
        }
      }
    },
    {
      title: this.$t('领取时间'),
      key: 'createTime',
      align: 'center',
      width: 160,
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.receiveTime) }</span>)
        }
      }
    },
  ]
}

export default function(keys = []) {
  return [...filterColumns(CanChooseColumns.call(this), keys)]
}
