<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.familyName" :placeholder="$t('家族名称')" clearable class="filter-item" style="width: 160px;" />
      <el-button icon="el-icon-search" type="primary" class="filter-item" @click="handleRefresh">{{ $t('查 询') }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置') }}</el-button>
    </div>

    <d-table
      ref="DTable"
      v-loading="loading"
      :data="items"
      :columns="columns"
      :options="options"
      :filter-column="filterColumn"
    />

    <ActionDialog ref="ActionDialogControl" @parment="_handleParment" />
  </div>

</template>

<script>
import { getLiveFamilyWithdrawList, doPaymentFamily, doSettlementFamily } from '@/api/live'
import TablePageMixin from '@/mixins/table-page-mixin'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
export default {
  name: 'LiveFamilyOrders',
  components: { ActionDialog },
  mixins: [TablePageMixin(getLiveFamilyWithdrawList)],
  data() {
    return {
      search: {},
      filterColumn: false,
      columns: GetColumns.call(this)
    }
  },
  methods: {
    _goHistory(row) {
      this.$router.push({ name: 'LiveFamilyOrderHistory', query: { id: row.familyOwerAccno }})
    },
    _handleParment({ field, close, cancel }) {
      doPaymentFamily({ ...field }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('打款成功'))
          this.handleFetch()
        } else {
          cancel()
        }
      })
    },
    _handleSettlement({ familyOwerAccno }) {
      this.$confirm(this.$t('此操作会立即结算从上次提现到当前点击确认的时间范围内家族收入，如果该家族有主播还在开播，当次开播的收入会纳入下次提现'), this.$t('请谨慎操作'), {
        type: 'warning',
        center: true
      }).then(() => {
        doSettlementFamily({ familyOwerAccno }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('结算成功'))
            this.handleFetch()
          }
        })
      }).catch(() => {})
    },
    implementationGetParams() {
      return { ...this.search }
    },
    implementationFetched({ data }) {
      this.items = data || []
    }
  }
}
</script>
