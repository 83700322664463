<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="search.activityId" clearable maxlength="30" :placeholder="$t('活动ID')" class="filter-item"
        style="width: 200px" />
      <el-input v-model="search.title" clearable maxlength="30" :placeholder="$t('活动标题')" class="filter-item"
        style="width: 200px" />
      <el-select v-model="search.status" clearable :placeholder="$t('活动状态')" class="filter-item" style="width: 120px">
        <el-option v-for="item in activityStatus" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-select v-model="search.category" clearable :placeholder="$t('活动分类')" class="filter-item" style="width: 120px">
        <el-option v-for="item in categoryList" :key="item.id" :value="item.id" :label="item.typeName" />
      </el-select>
      <el-select v-model="search.taskType" @change="changeTaskType" clearable :placeholder="$t('任务分类')"
        class="filter-item" style="width: 160px">
        <el-option v-for="item in taskCategory" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-select v-model="search.taskAction" v-if="isShowTaskAction" clearable :placeholder="$t('任务行为')"
        class="filter-item" style="width: 120px">
        <el-option v-for="item in filteredTaskActionList" :key="item.key" :value="item.key" :label="item.label" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" class="filter-item" @click="handleSearch">{{ $t('查询')
      }}</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-refresh-right" @click="resetSearch">{{ $t('重置')
      }}</el-button>
      <el-button v-if="permission.Add" type="primary" class="filter-item"
        @click="$router.push('/Activity_Awards_Record')">{{ $t('活动奖励记录') }}</el-button>
      <el-button v-if="permission.Add" style="float:right" type="success" icon="el-icon-plus" class="filter-item"
        @click="onCreate">{{ $t('新建活动') }}</el-button>
    </div>

    <d-table ref="DTable" v-loading="loading" :data="items" :columns="columns" :options="options"
      :filter-column="filterColumn" />

    <pagination :page.sync="params.currentPage" :limit.sync="params.pageSize" :total="total"
      @pagination="handleFetch" />

    <ActionDialog ref="ActionDialogControl" @create="handleCreateActivity" @update="handleUpdateActivity" />
  </div>
</template>

<script>
import { activityStatus, taskCategory, taskAction } from '@/libs/options'
import TablePageMixin from '@/mixins/table-page-mixin'
import { activityNewDeleteRuleList, activityNewOpenOrClose, activityNewEdit, activityNewCreate, activityNewList, getActivityConfTypeList, deleteActivityCommon } from '@/api/activity'
import GetColumns from './columns'
import ActionDialog from './ActionDialog'
import { mapState } from 'vuex'
import moment from 'moment';
import store from '@/store'
import { mapping } from './utils'

export default {
  name: 'ConfigTab',
  components: { ActionDialog },
  mixins: [TablePageMixin(activityNewList)],
  data() {
    return {
      search: {},
      filterColumn: false,
      activityStatus,
      taskCategory,
      taskAction,
      columns: GetColumns.call(this),
      categoryList: [],
      isShowTaskAction: true,
    }
  },
  computed: {
    ...mapState('app', ['allLang']),
    filteredTaskActionList() {
      if (this.search.taskType == 1) {
        return this.taskAction
      } else if (this.search.taskType == 2 || this.search.taskType == 3 || this.search.taskType == 4) {
        return this.taskAction.filter(item => item.key !== 1)
      }
    }
  },
  created() {
    getActivityConfTypeList({ currentPage: 1, pageSize: 100 }).then(([items, err]) => {
      if (!err) {
        if (items.length > 0) {
          const list = []
          items.forEach(e => {
            const typeNameList = e.typeName.split(',')
            list.push({
              id: e.id,
              typeName: typeNameList[0].split(':')[1],
              typeNameLang: e.typeName
            })
          })
          this.categoryList = list
        }
      }
    })
  },
  methods: {
    getParams(field) {
      const { activityDate, appPageId, languageSet, vipLevels, ...other } = field
      console.log(field, '未处理的')
      let params = { ...other }
      if (params.isContinuous === 0) {
        params.startTime = activityDate[0]
        params.endTime = activityDate[1]
      } else {
        params.startTime = moment().format('YYYY-MM-DD HH:mm:ss')
        params.endTime = moment().add(10, 'years').format('YYYY-MM-DD HH:mm:ss')
      }
      params.tenantCode = store.getters.tenantCode
      params.languageSet = languageSet.toString()
      params.vipLevels = vipLevels.toString()
      // 任务目标与奖励-合并去重
      params.rewardRules = [
        ...params.rewardRules4
          .filter((rule) => rule.ruleType != null) // 过滤掉 ruleType 为 null 的项
          .map((rule) => ({
            ...rule,
            couponId: Array.isArray(rule.couponId) ? rule.couponId.join(",") : rule.couponId, // 转换 couponId
          })),
        ...params.rewardRules1
          .filter((rule) => rule.ruleType != null) // 过滤掉 ruleType 为 null 的项
          .map((rule) => ({
            ...rule,
            couponId: Array.isArray(rule.couponId) ? rule.couponId.join(",") : rule.couponId, // 转换 couponId
          })),
        ...params.rewardRules3
          .filter((rule) => rule.ruleType != null) // 过滤掉 ruleType 为 null 的项
          .map((rule) => ({
            ...rule,
            couponId: Array.isArray(rule.couponId) ? rule.couponId.join(",") : rule.couponId, // 转换 couponId
          }))
      ];
      // 单独处理全勤奖
      if (params.rewardRules2 && params.rewardRules2.ruleType) {
        if (Array.isArray(params.rewardRules2.couponId)) {
          params.rewardRules2.couponId = params.rewardRules2.couponId.join(',');
        }
        params.rewardRules.push(params.rewardRules2);
      }
      delete params.rewardRules4;
      delete params.rewardRules1;
      delete params.rewardRules3;
      delete params.rewardRules2;

      params.taskLimitChannels = {};
      if (params.taskAction == 4) {
        params.taskLimitChannels = this.getSelectedData(params.gameList, params.taskMethod_)
      } else {
        for (const key in params) {
          if (params.hasOwnProperty(key) && mapping[key]) {
            const value = params[key];
            if (value.length > 0) {
              params.taskLimitChannels[mapping[key]] = value;
            }
            // 删除原键
            delete params[key];
          }
        }
      }
      // 将 taskLimitChannels 转成 JSON 字符串
      params.taskLimitChannels = JSON.stringify(params.taskLimitChannels);
      delete params.gameList;
      params.rewardRules.forEach(rule => {
        ['awardsCheckedType_1', 'awardsCheckedType_2', 'awardsCheckedType_3', 'awardsCheckedType_4'].forEach(field => {
          if (rule.hasOwnProperty(field)) {
            delete rule[field];
          }
        });
      });
      return params
    },
    getSelectedData(gameList, taskMethod_) {
      return gameList.map(game => this.processGameTree(game))
        .filter(processed => {
          if (!processed) return false;
          return processed.value == taskMethod_;
        });
    },
    processGameTree(node) {
      const newNode = { ...node }
      if (node.children) {
        newNode.children = node.children
          .map(child => this.processGameTree(child))
          .filter(child => child !== null)
      }
      // 保留条件：当前节点被选中，或有被选中的子节点
      const shouldKeep = newNode.checked ||
        (newNode.children && newNode.children.length > 0)
      return shouldKeep ? newNode : null
    },
    onCreate() {
      this.$refs.ActionDialogControl.open()
    },
    changeTaskType(item) {
      if (item == 0) {
        this.isShowTaskAction = false
      } else {
        this.isShowTaskAction = true
      }
    },
    handleCreateActivity({ field, cancel, close }) {
      //创建默认开启
      const status = 1
      activityNewCreate({ ...this.getParams(field), status }).then(([_, err]) => {
        if (!err) {
          close()
          this.$message.success(this.$t('创建成功!'))
          this.handleRefresh()
        } else {
          cancel()
        }
      })
    },
    handleUpdateActivity({ field, cancel, close }) {
      const ruleIdList = this.$refs.ActionDialogControl?.deleteRewardRules3 || [];
      if (ruleIdList.length > 0) {
        // 删除规则
        activityNewDeleteRuleList({ ruleIdList }).then(([_, err]) => {
          if (!err) {
            // this.$message.success(this.$t('操作成功'))
          } else {
            // this.$message.error(this.$t('删除规则失败'))
          }
        });
      }
      // 编辑更新
      activityNewEdit({ ...this.getParams(field) }).then(([_, err]) => {
        if (!err) {
          close();
          this.$message.success(this.$t("修改成功"));
          this.handleFetch();
        } else {
          cancel();
        }
      });
    },

    handleCloseActivity({ templateId, status }) {
      // console.log(status, '状态A')
      this.$confirm(`${this.$t('确定')} ${status === 1 ? this.$t('关闭') : this.$t('开启')} ${this.$t('活动')}???`, this.$t('系统提示'), { type: 'warning' }).then(() => {
        activityNewOpenOrClose({ templateId, isOpen: status === 1 ? 0 : 1 }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('操作成功'))
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },

    handleDeleteActivity({ id }) {
      this.$confirm(this.$t('确定删除活动?'), this.$t('系统提示'), { type: 'warning' }).then(() => {
        deleteActivityCommon({ id }).then(([_, err]) => {
          if (!err) {
            this.$message.success(this.$t('删除成功'))
            this.handleFetch()
          }
        })
      }).catch(() => { })
    },

    implementationGetParams() {
      return { ...this.search }
    }
  }
}
</script>
