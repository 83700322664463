<template>
  <el-dialog
    :title="emitName === 'create' ? $t('创建线路'): $t('编辑线路')"
    :visible.sync="visible"
    width="430px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form ref="validateForm" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item :label="$t('线路名称')" prop="servername">
        <el-input v-model="ruleForm.servername" maxlength="10" />
      </el-form-item>
      <el-form-item :label="$t('推流域名')" prop="serverurl">
        <el-input v-model="ruleForm.serverurl" placeholder="" />
      </el-form-item>
      <el-form-item :label="$t('鉴权KEY(主)')" prop="primarykey">
        <el-input v-model="ruleForm.primarykey" :placeholder="$t('请勿输入空格')" />
      </el-form-item>
      <el-form-item :label="$t('鉴权KEY(备)')" prop="backupkey">
        <el-input v-model="ruleForm.backupkey" :placeholder="$t('请勿输入空格')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('取消') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'
const getDefaultForm = () => ({
  servername: '',
  serverurl: '',
  primarykey: '',
  backupkey: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'create',
      rules: {
        servername: [
          { required: true, message: this.$t('请输入线路名称'), trigger: 'blur' }
        ],
        serverurl: [
          { required: true, message: this.$t('请输入推流域名'), trigger: 'blur' }
        ],
        primarykey: [
          { required: true, message: this.$t('请输入'), trigger: 'blur' }
        ],
        backupkey: [
          { required: true, message: this.$t('请输入'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(row) {
      this.emitName = 'create'
      if (row) {
        this.ruleForm.tliveid = row.tliveid
        this.ruleForm.servername = row.servername
        this.ruleForm.attribute = row.attribute
        this.ruleForm.serverurl = row.serverurl
        this.ruleForm.primarykey = row.primarykey
        this.ruleForm.backupkey = row.backupkey
        this.emitName = 'update'
      }
      this.visible = true
    }
  }
}
</script>
