var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "title-box" },
        [
          _c("h3", [_vm._v("新版娱乐城推荐活动")]),
          _c(
            "d-switch",
            {
              attrs: {
                "active-value": 1,
                "inactive-value": 0,
                beforeChange: _vm.handleChangeStatus,
              },
              model: {
                value: _vm.promotionSwitch,
                callback: function ($$v) {
                  _vm.promotionSwitch = $$v
                },
                expression: "promotionSwitch",
              },
            },
            [
              _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                _vm._v("开启"),
              ]),
              _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                _vm._v("关闭"),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "title-box" },
        [
          _c("h4", [_vm._v("推荐游戏：")]),
          _c(
            "el-select",
            {
              on: { change: _vm.handleChangeGameKey },
              model: {
                value: _vm.gameKey,
                callback: function ($$v) {
                  _vm.gameKey = $$v
                },
                expression: "gameKey",
              },
            },
            [
              _c("el-option", { attrs: { value: undefined, label: "全部" } }),
              _vm._l(_vm.activityGameKeyList, function (item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { value: item.gameKey, label: item.name },
                })
              }),
            ],
            2
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd(_vm.gameKey)
                },
              },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "elTable",
          attrs: {
            border: "",
            data: _vm.items,
            "row-key": "index",
            "drag-row": true,
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { type: "index", align: "center", width: "60" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.$t("排序")))]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("svg-icon", {
                attrs: { "icon-class": "drag", "class-name": "drag-handler" },
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: {
              label: "活动分类",
              prop: "activityCategoryName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "活动标题", prop: "activityName", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("活动图片"), align: "center" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return row.activityImage
                      ? [_c("el-image", { attrs: { src: row.activityImage } })]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s({ 1: "启用", 0: "禁用" }[row.status]) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("操作"), align: "center", width: "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { padding: "10px 0" }, attrs: { align: "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", loading: _vm.submitting },
              on: {
                click: function ($event) {
                  return _vm.handleSubmit()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("保 存")))]
          ),
        ],
        1
      ),
      _c("ActionDialog", {
        ref: "ActionDialogContorl",
        on: { update: _vm.handleSave },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }