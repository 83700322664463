var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "tenant-lottery",
    },
    _vm._l(_vm.items, function (item) {
      return _c("div", { key: item.seriesTag, staticClass: "series-item" }, [
        _c(
          "div",
          { staticClass: "series-item__name" },
          [
            _vm._v(" " + _vm._s(item.seriesName) + " "),
            item.seriesTag !== "000"
              ? _c(
                  "el-radio-group",
                  {
                    model: {
                      value: item.status,
                      callback: function ($$v) {
                        _vm.$set(item, "status", $$v)
                      },
                      expression: "item.status",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                    _c("el-radio", { attrs: { label: 3 } }, [_vm._v("停用")]),
                    _c("el-radio", { attrs: { label: 0 } }, [
                      _vm._v("关闭隐藏"),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "series-item__body" },
          _vm._l(item.list, function (child) {
            return _c(
              "el-row",
              { key: child.lotteryId, staticClass: "series-item__list" },
              [
                _c(
                  "el-col",
                  { staticClass: "series-item--label", attrs: { span: 10 } },
                  [_c("label", [_vm._v(_vm._s(child.name))])]
                ),
                _c(
                  "el-col",
                  { staticClass: "series-item--status", attrs: { span: 14 } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: child.status,
                          callback: function ($$v) {
                            _vm.$set(child, "status", $$v)
                          },
                          expression: "child.status",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("开启"),
                        ]),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: item.seriesTag !== "000" ? 0 : 3 },
                          },
                          [
                            _vm._v(
                              _vm._s(item.seriesTag !== "000" ? "关闭" : "停用")
                            ),
                          ]
                        ),
                        item.seriesTag == "000"
                          ? _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("关闭隐藏"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }