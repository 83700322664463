/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout'

const nestedRouter = {
  path: '/Permission',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Permission',
  alwaysShow: true,
  meta: {
    title: '权限管理',
    icon: 'permission'
  },
  children: [
    {
      path: '/Permission_Users',
      name: 'PermissionUsers',
      component: () => import('@/views/permission/Users'),
      meta: { title: '管理列表', noCache: true }
    },
    {
      path: '/Permission_Assign',
      name: 'PermissionAssign',
      component: () => import('@/views/permission/Assign'),
      meta: { title: '权限分配', noCache: true }
    },
    {
      path: '/Permission_LangConfig',
      name: 'PermissionLangConfig',
      component: () => import('@/views/permission/LangConfig'),
      meta: { title: '国际化配置', noCache: true }
    },
    {
      path: '/Permission_Login_Log',
      component: () => import('@/views/permission/LoginLog'), // Parent router-view
      name: 'LoginLog',
      meta: { title: '登录日志', noCache: true }
    },
    {
      path: '/Permission_WhiteList',
      name: 'PermissionWhiteList',
      component: () => import('@/views/permission/WhiteList'),
      meta: { title: 'IP白名单', noCache: true }
    },
    {
      path: '/Permission_Line',
      name: 'PermissionLine',
      component: () => import('@/views/permission/Line'),
      meta: { title: '线路管理', noCache: true }
    },
    {
      path: '/Permission_Operation_Log',
      name: 'PermissionOperationLog',
      component: () => import('@/views/permission/OperateLog'),
      meta: { title: '操作日志', noCache: true }
    }
  ]
}

export default nestedRouter
