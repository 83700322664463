<template>
  <el-dialog
    :title="ruleForm.transType === 5 ? $t('扣款操作') : $t('加款操作')"
    :visible.sync="visible"
    width="400px"
    top="5vh"
    :close-on-click-modal="false"
    @closed="handleClosed"
  >
    <el-form
      ref="validateForm"
      :model="ruleForm"
      :rules="rules"
      label-position="right"
      label-width="120px"
      label-suffix=":"
    >
      <el-form-item :label="$t('会员账号')">
        <p>{{ ruleForm.memberName }}</p>
      </el-form-item>
      <el-form-item :label="$t('账户余额')">
        <p>{{ ruleForm.balance }}</p>
      </el-form-item>
      <template v-if="ruleForm.transType !== 5">
        <el-form-item :label="$t('增加金额')" prop="amount">
          <el-input v-model="ruleForm.amount" :min="1" :label="$t('请输入需要增加的金额')" />
        </el-form-item>
        <el-form-item :label="$t('计入打码量')" prop="effecRolling">
          <el-radio-group v-model="ruleForm.effecRolling">
            <el-radio :label="1">{{ $t('是') }}</el-radio>
            <el-radio :label="0">{{ $t('否') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('打码量倍数')" prop="rollingRate">
          <el-input v-model="ruleForm.rollingRate" :label="$t('请输入打码量倍数')" />
        </el-form-item>
        <el-form-item :label="$t('类型')" prop="transType">
          <el-select v-model="ruleForm.transType">
            <el-option :label="$t('礼金')" :value="174" />
            <el-option :label="$t('现金')" :value="4" />
            <el-option :label="$t('微信扫码')" :value="254" />
            <el-option :label="$t('其他补款')" :value="255" />
          </el-select>
          <div class="tip">
            <p v-if="ruleForm.transType==4">
              现金应用于线下充值，平台入款统计
            </p>
            <p v-if="ruleForm.transType==174">
              礼金应用于会员赠送，优惠活动统计
            </p>
          </div>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item :label="$t('扣款金额')" prop="amount">
          <el-input v-model="ruleForm.amount" :label="$t('请输入需要扣除的金额')" />
        </el-form-item>
      </template>

      <el-form-item :label="$t('操作原因')" prop="operatorRemark">
        <el-input
          v-model="ruleForm.operatorRemark"
          type="textarea"
          :rows="3"
          maxlength="30"
          show-word-limit
          :placeholder="$t('请输入操作原因')"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">
        {{ $t('关闭') }}
      </el-button>
      <el-button type="primary" :loading="submitting" @click="handleSubmit">
        {{ $t('保存') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import FormDialogMixin from '@/mixins/form-dialog'

const getDefaultForm = () => ({
  transType: 174,
  effecRolling: 1,
  amount: '',
  operatorRemark: ''
})

export default {
  mixins: [FormDialogMixin(getDefaultForm)],
  data() {
    return {
      emitName: 'update',
      rules: {
        amount: [
          { required: true, message: this.$t('请输入金额'), trigger: 'blur' },
          { pattern: /^(\d|[1-9][0-9]*)+(.?\d{1,3})?$/, message: this.$t('请输入正确金额'), trigger: 'blur' }
        ],
        rollingRate: [
          { pattern: /^\d+(.?\d)?$/, message: this.$t('请输入正确打码量倍数'), trigger: 'blur' }
        ],
        effecRolling: [
          { required: true, message: this.$t('请选择计入打码量'), trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    open({ memberName, tenantCode, balance }, transType) {
      this.ruleForm.transType = transType
      this.ruleForm.memberName = memberName
      this.ruleForm.tenantCode = tenantCode
      this.ruleForm.balance = balance
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.tip{
  p{
    line-height: 16px;
    margin: 8px auto;
    color:#ec5a5a;
    font-size: 12px;
  }
}
</style>
