var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "总开关修改", width: "550px", visible: _vm.visible },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.handleClosed,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "validateForm",
          attrs: { model: _vm.ruleForm, "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "总启停设置" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                ],
                1
              ),
              _c("p", { staticStyle: { color: "red", "font-size": "12px" } }, [
                _vm._v("设置开启/关闭，会影响所有平台彩种启停状态"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "总显示隐藏设置" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.showStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "showStatus", $$v)
                    },
                    expression: "ruleForm.showStatus",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("显示")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("隐藏")]),
                ],
                1
              ),
              _c("p", { staticStyle: { color: "red", "font-size": "12px" } }, [
                _vm._v("设置显示/隐藏，会影响所有平台彩种的显示状态"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitting },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }