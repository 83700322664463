<template>
  <el-form ref="ruleForm" v-loading="loading" :model="ruleForm" class="basic_config" :rules="rules" label-width="140px">
    <h2>红包开关配置</h2>
    <el-form-item label="聊天室红包开关" prop="ruleSwitch">
      <el-radio-group v-model="ruleForm.ruleSwitch">
        <el-radio :label="1">开启</el-radio>
        <el-radio :label="2">关闭</el-radio>
      </el-radio-group>
    </el-form-item>
    <h2>红包发放配置<p>未领取的红包，将于24小时后发起退款</p></h2>
    <el-form-item label="单个红包最大金额" prop="maxAmount">
      <el-input v-model.number="ruleForm.maxAmount">
        <template slot="append">元</template>
      </el-input>
    </el-form-item>
    <el-form-item label="单个红包最大个数" prop="maxNumber">
      <el-input v-model.number="ruleForm.maxNumber">
        <template slot="append">个</template>
      </el-input>
    </el-form-item>
    <el-form-item label="发放红包时间" prop="sendTime">
      <el-time-picker
        style="width: 281px;"
        is-range
        v-model="sendTime"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        format="HH:mm"
        placeholder="选择发放红包时间">
      </el-time-picker>
    </el-form-item>
    <h2>发放红包配置<p>满足条件会员才可以发红包，用户达到等级取最高配置值</p><el-button type="primary" style="margin-left: 10px" @click="addConfig">+ 添加</el-button></h2>
    <el-form-item v-for="(item, index) in ruleForm.confVipRequests" :key="index" class="flex-item">
      <el-form-item v-if="index === ruleForm.confVipRequests.length - 1" label="*VIP等级" prop="vipStart" class="flex-item">
        <el-select v-model="item.vipStart" @change="changeStartVip(item, index)" style="width: 119px;">
          <el-option
            v-for="level in Levels"
            :key="level.id"
            :value="level.vipLevel"
            :label="`VIP${level.vipLevel}`"
          />
        </el-select>
        <p>（含）以上才能发红包</p>
      </el-form-item>
      <el-form-item v-else label="*VIP等级" prop="vipStart" class="flex-item">
        <el-select v-model="item.vipStart" @change="changeStartVip(item, index)" style="width: 80px;">
          <el-option
            v-for="level in Levels"
            :key="level.id"
            :value="level.vipLevel"
            :label="`VIP${level.vipLevel}`"
            :disabled="ruleForm.confVipRequests[index + 1].vipStart <= level.vipLevel"
          />
        </el-select>
        <p>~</p>
        <el-form-item prop="vipEnd">
          <el-select v-model="item.vipEnd" disabled style="width: 80px;">
            <el-option
              v-for="level in Levels"
              :key="level.id"
              :value="level.vipLevel"
              :label="`VIP${level.vipLevel}`"
            />
          </el-select>
        </el-form-item>
        <p>才能发红包</p>
      </el-form-item>
      <el-form-item prop="vipSendNumber" class="fhbcs">
        <el-input v-model.number="item.vipSendNumber" style="width: 140px;">
          <template slot="append">次</template>
        </el-input>
      </el-form-item>
      <p>（-1代表不限制），</p>
      <el-form-item label="每日发红包总额" prop="vipSendTotalAmount" class="fhbze">
        <el-input v-model="item.vipSendTotalAmount" style="width: 180px;">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-button type="danger" @click="deleteConfig(index)" style="margin-left: 10px" v-if="index !== ruleForm.confVipRequests.length - 1">- 删除</el-button>
    </el-form-item>
    <h2>领红包配置<p>满足条件会员才可以参与领红包</p></h2>
    <el-form-item label="VIP等级" prop="vipReceiveMinLevel" class="flex-item">
      <el-select v-model="ruleForm.vipReceiveMinLevel">
        <el-option
          v-for="level in Levels"
          :key="level.id"
          :value="level.vipLevel"
          :label="`VIP${level.vipLevel}`"
        />
      </el-select>
      <p>以上才能领红包</p>
    </el-form-item>
    <el-form-item class="action-button">
      <el-button type="primary" :loading="submitting" @click="handleSubmit">保存配置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { roomGetConf, roomConfig } from '@/api/activity.js'
import { getAllVipLevels } from '@/api/app'
export default {
  name: 'BaseTab',
  componentName: 'BaseTab',
  data() {
    return {
      loading: false,
      submitting: false,
      ruleForm: {
        ruleSwitch: 1,
        maxAmount: '',
        maxNumber: '',
        startSendTime: '',
        endSendTime: '',
        confVipRequests: [{
            vipStart: '',
            vipEnd: '',
            vipSendNumber: '',
            vipSendTotalAmount: ''
        }],
        vipReceiveMinLevel: ''
      },
      rules: {},
      Levels: [],
      sendTime: null,
      maxNum: 999999
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      getAllVipLevels(this.$P$ && [this.$parent.$attrs.tenant.tenantCode] || {}).then(([data, err]) => {
        if (!err) {
          this.Levels = data
        }
      })
      this.loading = true
      roomGetConf().then(([data, err]) => {
        this.loading = false
        if (!err && data) {
          if(!data.confVipRequests || data.confVipRequests.length === 0) {
            data.confVipRequests = this.ruleForm.confVipRequests
          }
          this.ruleForm = { ...data }
          if (this.ruleForm.startSendTime && this.ruleForm.endSendTime) {
            this.sendTime = [this.ruleForm.startSendTime, this.ruleForm.endSendTime]
          }
        }
      })
    },
    handleSubmit() {
      let errMsg = ''
      this.ruleForm.confVipRequests.forEach((e,index) => {
        if(index === this.ruleForm.confVipRequests.length - 1) {
          e.vipEnd = '-1'
        }
        if (e.vipStart === '' || e.vipEnd === '') {
          errMsg = '请选择发放VIP等级'
          return
        }
        if (e.vipSendNumber === '') {
          errMsg = '请输入发放红包次数'
          return
        }
        if (Number(e.vipSendNumber) > this.maxNum) {
          errMsg = '发放红包次数不能大于' + this.maxNum
          return
        }
        if (e.vipSendTotalAmount === '') {
          errMsg = '请输入每日发红包总额'
          return
        }
        if (Number(e.vipSendTotalAmount) > this.maxNum) {
          errMsg = '每日发红包总额不能大于' + this.maxNum
          return
        }
      });
      if (errMsg !== '') {
        this.$message.error(errMsg)
        return
      }
      if (Number(this.ruleForm.maxAmount) > this.maxNum) {
        this.$message.error('单个红包最大金额不能大于' + this.maxNum)
        return
      }
      if (Number(this.ruleForm.maxNumber) > this.maxNum) {
        this.$message.error('单个红包最大个数不能大于' + this.maxNum)
        return
      }
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this._updateConfig()
        }
      })
    },
    _updateConfig() {
      this.submitting = true
      if (this.sendTime && this.sendTime.length === 2) {
        this.ruleForm.startSendTime = this.$parseTime(this.sendTime[0], '{h}:{i}')
        this.ruleForm.endSendTime = this.$parseTime(this.sendTime[1], '{h}:{i}')
      }
      roomConfig(this.ruleForm).then(([_, err]) => {
        this.submitting = false
        if (!err) {
          this.$message.success(this.$t('保存成功'))
        }
      })
    },
    disabledEndVip(index, level, item) {
      if (item.vipStart >= this.ruleForm.confVipRequests[index + 1].vipStart) {
        item.vipStart = ''
      }
      if (item.vipEnd >= this.ruleForm.confVipRequests[index + 1].vipStart) {
        item.vipEnd = ''
      }
      if (item.vipEnd < item.vipStart) {
        item.vipEnd = ''
      }
      return level < this.ruleForm.confVipRequests[index].vipStart || level >= this.ruleForm.confVipRequests[index + 1].vipStart
    },
    addConfig() {
      let vipStart = this.ruleForm.confVipRequests[0].vipStart
      if (vipStart !== '') {
        vipStart -= 1
      }
      this.ruleForm.confVipRequests.unshift({
        vipStart: '',
        vipEnd: vipStart,
        vipSendNumber: '',
        vipSendTotalAmount: ''
      })
    },
    deleteConfig(index) {
      this.ruleForm.confVipRequests.splice(index, 1)
      this.ruleForm.confVipRequests.forEach((e, index) => {
        if (index < this.ruleForm.confVipRequests.length - 1 &&  this.ruleForm.confVipRequests[index + 1].vipStart !== '') {
          e.vipEnd = this.ruleForm.confVipRequests[index + 1].vipStart - 1
        }
      })
    },
    changeStartVip(item, index) {
      if (index >= 1) {
        this.ruleForm.confVipRequests[index - 1].vipEnd = item.vipStart - 1 < 0 ? '' : item.vipStart - 1
        if (this.ruleForm.confVipRequests[index - 1].vipStart > this.ruleForm.confVipRequests[index - 1].vipEnd) {
          this.ruleForm.confVipRequests[index - 1].vipStart = ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.basic_config{
  padding-right: 48px;
  h2 {
    color: rgb(16, 16, 16);
    font-size: 14px;
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
    > p {
      margin-left: 10px;
      color: #8ca2aa;
    }
  }
  .el-input {
    width: 250px;
  }
  .flex-item {
    ::v-deep.el-form-item__content {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      margin-left: 0 !important;
    }
    >.el-input ::v-deep.el-input__inner {
      height: 55px;
    }
    .el-radio {
      margin-left: 10px;
      margin-right: 10px;
    }
    .el-input {
      width: 120px;
    }
    .fhbcs ::v-deep.el-form-item__label {
      width: 85px !important;
    }
    .fhbze ::v-deep.el-form-item__label {
      width: 110px !important;
    }
    p {
      margin: 0 10px;
      font-weight: 700;
      color: #606266;
    }
  }
}
</style>
