var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("statistics-member"),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [_c("statistics-lottery")], 1),
          _c("el-col", { attrs: { span: 12 } }, [_c("statistics-third")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }