const CanChooseColumns = function() {
  return [
    {
      title: this.$t('彩种'),
      key: 'lotteryName',
      align: 'center'
    },
    {
      title: this.$t('期号'),
      key: 'issue',
      align: 'center'
    },
    {
      title: this.$t('撤销时间'),
      key: 'cancelTime',
      align: 'center',
      component: {
        render: (h, row) => {
          return (<span>{ this.$parseTime(row.cancelTime) }</span>)
        }
      }
    },
    {
      title: this.$t('操作'),
      key: 'actions',
      align: 'center',
      component: {
        render: (h, row) => {
          const { DownLoad } = this.$parent.permission
          return (
            <span>
              { DownLoad && <el-button type='success' size='mini' onClick={() => this.downloadRecord(row)}>{ this.$t('下载文件') }</el-button> }
            </span>
          )
        }
      }
    }
  ]
}

export default function() {
  return CanChooseColumns.call(this)
}
